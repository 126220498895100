import { useEffect, useState } from 'react'

import { displayPrice } from '../../../utils/number'
import Notification from '../../newComponents/notification'

import styles from './Cell.module.css'

type Props = {
  pTags: Array<any>
  isOption: boolean
  currency: any
  decimals: any
}

const DisplayTag = ({ tag }: { tag: { name: string; price: string | null } }) => (
  <span className={styles.containerTags} id={tag.name}>
    {tag.name} {tag.price !== null && <span className={styles.tagPrice}>{tag.price}</span>}
  </span>
)

/**
 *
 * This element displays entity's tags in the list
 *
 * @usedIn - List
 *
 * @param tags -  An array of tags
 * @param isOption - A boolean to change the CSS
 * @param currency - Company's currency
 * @param decimals - Currency's decimals
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */

const Tags = ({ pTags, isOption, currency, decimals }: Props): JSX.Element => {
  const initialState: {
    displayPopHover: boolean
    shownTags: Array<any>
    hiddenTags: Array<any>
  } = {
    displayPopHover: false,
    shownTags: [],
    hiddenTags: [],
  }
  const [state, setState] = useState(initialState)

  const buildTag = (tag: any) => ({
    name: tag.name,
    price: tag.price !== undefined && tag.price > 0 ? displayPrice(tag.price, currency, decimals) : null,
  })

  useEffect(() => {
    state.hiddenTags = []
    if (isOption) {
      state.shownTags = pTags
        .slice(0, 2)
        .filter((tag) => tag)
        .map((tag) => buildTag(tag))
      state.hiddenTags = pTags
        .slice(2)
        .filter((tag) => tag)
        .map((tag) => buildTag(tag))
    } else {
      const parsedTags = pTags
        .filter((tag) => tag)
        .filter((tag) => tag.tag)
        .map((tag) => tag.tag)
      state.shownTags = parsedTags.slice(0, 2).map((tag) => buildTag(tag))
      state.hiddenTags = parsedTags.slice(2).map((tag) => buildTag(tag))
    }
      }, [pTags])

  const handlePopHover = (value: boolean) => setState({ ...state, displayPopHover: value })

  return (
    <>
      {state.shownTags && state.shownTags.length > 0 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {state.shownTags.map((tag: any, index: number) => (
            <DisplayTag key={index} tag={tag} />
          ))}
          {state.hiddenTags.length > 0 && (
            <span>
              <span onMouseEnter={() => handlePopHover(true)} onMouseLeave={() => handlePopHover(false)}>
                <Notification value={'+' + state.hiddenTags.length} />
              </span>
              {/* {state.displayPopHover && (
                <div className={styles.hiddenTags}>
                  {state.hiddenTags.map((tag: any, index: number) => (
                    <DisplayTag key={index} tag={tag} />
                  ))}
                </div>
              )} */}
            </span>
          )}
        </div>
      )}
    </>
  )
}

const TagCell = ({ pTags, isOption, currency, decimals }: Props): JSX.Element => (
  <Tags pTags={pTags} isOption={isOption} currency={currency} decimals={decimals} />
)

export default TagCell
