import { displayNumber } from '../../../utils/number'
import { getErrorIcon } from '../../newComponents/icon'
import Input from '../../newComponents/input'
import { ComponentStatus } from '../../newComponents/types'
import { CellProps } from '../../../types/cell'
import { Error } from '../../../assets/icons'

const NumberCell = ({ value, editable = true, onChange, onBlur, onFocusNext, error, dataCy }: CellProps): JSX.Element =>
  editable ? (
    <Input
      type={'number'}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocusNext={onFocusNext}
      style={{
        status: error?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
        trailIcons: getErrorIcon(Error, error),
      }}
      dataCy={dataCy}
    />
  ) : (
    <div>{displayNumber(value)}</div>
  )

export default NumberCell
