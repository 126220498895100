import { TypeCashbook } from '../../../../../types'
import {
  getTitleInfoLine,
  getIconTitleInfoLine,
  getIconTitleLine,
  generateIconTitleLine,
} from '../../../../../components/oldComponents/activity/lines'
import Title from '../../../../../components/oldComponents/activity/title'
import { displayDateLongMonth, displayDateWithHours } from '../../../../../utils/date'
import { displayPrice } from '../../../../../utils/number'
import { enumToStr } from '../../../../../utils/color'
import { ColorPalette, CustomFieldValueType } from '../../../../../API'
import { TypeCompanyCurrency } from '../../../../../types/currency'
import { customFieldValueTypeToComponent, paymentMethodToComponent } from '../../../../../utils/typeToType'
import { ComponentSize } from '../../../../../components/newComponents/types'
import Avatar from '../../../../../components/newComponents/avatar'
import DisplayIcon2 from '../../../../../components/newComponents/icons'

import { Shop, Euro, Shutdown, Till } from '../../../../../assets/icons'

const getOpenLine = (
  t: (value: string) => string,
  openedAt?: string,
  openingSeller?: {
    id: string
    color: ColorPalette
    iconText: string
  }
) =>
  openedAt
    ? openingSeller
      ? getIconTitleInfoLine(
          'openedAt',
          {
            icon: DisplayIcon2({
              Icon: Shutdown,
              color: 'var(--sys-color-content-secondary)',
            }),
          },
          <Title topText={t('activity.cashbook.OPENED_AT')} mainTexts={[displayDateWithHours(openedAt)]} />,
          Avatar({
            color: enumToStr(openingSeller.color),
            size: ComponentSize.SMALL,
            iconText: openingSeller.iconText,
          })
        )
      : getIconTitleLine(
          'openedAt',
          {
            icon: DisplayIcon2({
              Icon: Shutdown,
              color: 'var(--sys-color-content-secondary)',
            }),
          },
          <Title topText={t('activity.cashbook.OPENED_AT')} mainTexts={[displayDateWithHours(openedAt)]} />
        )
    : undefined

const getCloseLine = (
  t: (value: string) => string,
  closedAt?: string,
  closingSeller?: {
    id: string
    color: ColorPalette
    iconText: string
  }
) =>
  closedAt
    ? closingSeller
      ? getIconTitleInfoLine(
          'closedAt',
          {
            icon: DisplayIcon2({
              Icon: Shutdown,
              color: 'var(--sys-color-content-secondary)',
            }),
          },
          <Title topText={t('activity.cashbook.CLOSED_AT')} mainTexts={[displayDateWithHours(closedAt)]} />,
          Avatar({
            color: enumToStr(closingSeller.color),
            size: ComponentSize.SMALL,
            iconText: closingSeller.iconText,
          })
        )
      : getIconTitleLine(
          'closedAt',
          {
            icon: DisplayIcon2({
              Icon: Shutdown,
              color: 'var(--sys-color-content-secondary)',
            }),
          },
          <Title topText={t('activity.cashbook.CLOSED_AT')} mainTexts={[displayDateWithHours(closedAt)]} />
        )
    : undefined

const getTotalLine = (sales: number, total: number, company: TypeCompanyCurrency) =>
  sales && total
    ? getIconTitleInfoLine(
        'total',
        {
          icon: DisplayIcon2({
            Icon: Euro,
            color: 'var(--sys-color-content-secondary)',
          }),
        },
        <Title mainTexts={[sales + ' vente' + (sales > 1 ? 's' : '')]} />,
        <Title mainTexts={[displayPrice(total, company.currency, company.currencyDecimals)]} />
      )
    : undefined

const parseCustomField = (value: string, type: CustomFieldValueType) => {
  if (type === CustomFieldValueType.DATE) {
    if (displayDateLongMonth(value) !== 'Invalid Date') return displayDateLongMonth(value)
    return ''
  }
  return value
}

const mapCustomFieldToLine = (customField: any) =>
  customField
    ? getIconTitleLine(
        'customField',
        {
          icon: DisplayIcon2({
            Icon: customFieldValueTypeToComponent(customField.valueType),
            color: 'var(--sys-color-content-secondary)',
          }),
        },
        <Title topText={customField.name} mainTexts={[parseCustomField(customField.value, customField.valueType)]} />
      )
    : undefined

const mapCashFundToLine =
  (t: (field: string) => string, company: TypeCompanyCurrency) => (cashFund: any, index: number) =>
    cashFund
      ? getTitleInfoLine(
          'cashFund-' + index,
          <Title mainTexts={[cashFund.text]} />,
          <Title
            mainTexts={[displayPrice(cashFund.value, company.currency, company.currencyDecimals)]}
            negative={cashFund.text === t('activity.cashbook.TOTAL_DIFFERENCE') && cashFund.value !== 0}
          />
        )
      : undefined

const mapStatsToLine = (company: TypeCompanyCurrency) => (stat: { title: string; value: number }, index: number) =>
  stat
    ? getTitleInfoLine(
        'stat-' + index,
        <Title mainTexts={[stat.title]} />,
        <Title mainTexts={[displayPrice(stat.value, company.currency, company.currencyDecimals)]} />
      )
    : undefined

const mapPaymentsToLine = (company: TypeCompanyCurrency) => (payment: any, index: number) =>
  payment
    ? getIconTitleInfoLine(
        'payment-' + index,
        {
          icon: DisplayIcon2({
            Icon: paymentMethodToComponent(payment.type, payment.color).component,
            color: paymentMethodToComponent(payment.type, payment.color).color,
          }),
        },
        <Title mainTexts={[payment.number + ' ' + payment.name]} />,
        <Title mainTexts={[displayPrice(payment.total, company.currency, company.currencyDecimals)]} />
      )
    : undefined

const getAllLines = (
  t: (value: string) => string,
  cashbookState: TypeCashbook,
  company: TypeCompanyCurrency
): {
  infos: Array<any>
  customFields?: Array<any>
  cashback?: Array<any>
  totals?: Array<any>
  payments?: Array<any>
} => {
  const infos = [
    getOpenLine(t, cashbookState.openedAt, cashbookState.openingSeller),
    getCloseLine(t, cashbookState.closedAt, cashbookState.closingSeller),
    cashbookState.shop
      ? generateIconTitleLine('shop', cashbookState.shop.name, Shop, t, 'activity.cashbook.SHOP')
      : undefined,
    cashbookState.sourceName
      ? generateIconTitleLine('source', cashbookState.sourceName, Till, t, 'activity.cashbook.SOURCE')
      : undefined,
    getTotalLine(0, 0, company),
  ].filter((info) => info)
  const customFields = cashbookState.customFields?.map(mapCustomFieldToLine).filter((_) => _)
  const cashback = cashbookState.cashFund?.map(mapCashFundToLine(t, company)).filter((_) => _)
  const totals = cashbookState.stats?.map(mapStatsToLine(company)).filter((_) => _)
  const payments = cashbookState.payments?.map(mapPaymentsToLine(company)).filter((_) => _)
  return { infos, customFields, cashback, totals, payments }
}

export default getAllLines
