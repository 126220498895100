const AddCircle = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1614 2C6.64138 2 2.16138 6.48 2.16138 12C2.16138 17.52 6.64138 22 12.1614 22C17.6814 22 22.1614 17.52 22.1614 12C22.1614 6.48 17.6814 2 12.1614 2ZM16.1614 13H13.1614V16C13.1614 16.55 12.7114 17 12.1614 17C11.6114 17 11.1614 16.55 11.1614 16V13H8.16138C7.61138 13 7.16138 12.55 7.16138 12C7.16138 11.45 7.61138 11 8.16138 11H11.1614V8C11.1614 7.45 11.6114 7 12.1614 7C12.7114 7 13.1614 7.45 13.1614 8V11H16.1614C16.7114 11 17.1614 11.45 17.1614 12C17.1614 12.55 16.7114 13 16.1614 13Z"
      fill={color}
    />
  </svg>
)

export default AddCircle
