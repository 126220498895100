import { useState, useEffect, useRef, forwardRef, RefObject } from 'react'
import { useSelector } from 'react-redux'

import SearchList, { Entries } from '../searchList'
import { displayCurrency } from '../../../utils/number'
import { selectedCompany } from '../../../store/selector'
import generateDataCy from '../../../utils/cypress'
import { TypeFormError } from '../../../format/errors/types'
import UniqueDropDown from '../../newComponents/dropDown/unique'
import Input from '../../newComponents/input'
import { ComponentErrorText, ComponentStatus } from '../../newComponents/types'
import DisplayIcon2 from '../../newComponents/icons'

import {
  DeleteIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Add as AddIcon,
  DragHandle,
  Error as ErrorIcon,
  KeyboardArrowDown,
} from '../../../assets/icons'
import stylesInput from '../../newComponents/input/Input.module.css'
import styles from './DraggableList.module.css'

export type DraggableListState = {
  id: string
  name: string
  errorValue?: TypeFormError
  quantity?: number
  price?: number
  errorPrice?: TypeFormError
  values?: Array<{ name: string }>
}

type State = {
  addText: string | undefined
  entries: Array<Entries>
  editValue: Array<boolean>
  dragStart: number
  dragEnd: number
}

/**
 *
 * This element is encapsulated by the hook forwardRef who let the user has the focus
 * It displays every line from the list
 *
 * @param quantity - Quantity of entity
 * @param name - Name of entity (Table, Chair..)
 * @param price - Price of entity
 * @param values - A list of informations with no action required
 * @param disabled - A boolean to know if the input is disabled or not
 * @param showEdit - A boolean to know if we display an editable input or not
 * @param index - Index from the list
 * @param onBlur - A CTA when we leave the input
 * @param onKeyPress - A CTA useful if we press "Enter"
 * @param onChange - A CTA when we edit the input
 * @param dataCy - An unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const InputsLine = forwardRef(
  (
    {
      quantity,
      name,
      errorValue,
      errorPrice,
      price,
      subValues,
      disabled,
      isEditable,
      index,
      onBlur,
      onKeyPress,
      onChange,
      dataCy,
      isOption,
    }: {
      quantity?: number
      name: string
      errorValue?: TypeFormError
      errorPrice?: TypeFormError
      price?: number
      subValues?: Array<{ name: string }>
      disabled: boolean
      isEditable?: boolean
      index: number
      onBlur?: (field: string, index?: number) => (event?: any) => void
      onKeyPress?: (index?: number) => (event: any) => void
      onChange?: (field: string, index: number) => (event: any) => void
      dataCy?: string
      isOption: boolean
    },
    ref: any
  ): JSX.Element => {
    const company = useSelector(selectedCompany)

    const cyContextQuantity = generateDataCy({ scope: dataCy!, value: 'quantity' })

    const cyContextName = generateDataCy({ scope: dataCy!, value: 'name' })

    const cyContextPrice = generateDataCy({ scope: dataCy!, value: 'price' })

    return (
      <>
        {quantity !== undefined && (
          <Input
            value={quantity}
            onChange={onChange && onChange('quantity', index)}
            style={{
              width: '3em',
            }}
            dataCy={generateDataCy({
              scope: cyContextQuantity,
              value: index.toString(),
            })}
          />
        )}
        {isEditable ? (
          <Input
            value={name}
            onChange={onChange && onChange('value', index)}
            onBlur={(event) => onBlur && onBlur('value', index)(event)}
            style={{
              status: errorValue?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              width: '80%',
            }}
            dataCy={generateDataCy({
              scope: cyContextName,
              value: index.toString(),
            })}
            helperText={errorValue?.value ? errorValue?.message : undefined}
          />
        ) : (
          <span
            className={styles.optionName}
            data-cy={generateDataCy({
              scope: cyContextName,
              value: index.toString(),
            })}
          >
            {name}
          </span>
        )}
        {isOption && (
          <>
            <span>+</span>
            <Input
              type={'currency'}
              value={price ? price + (company ? ' ' + displayCurrency(company.currency) : '') : ''}
              onChange={onChange && onChange('price', index)}
              onBlur={(event) => onBlur && onBlur('price', index)(event)}
              suffix={company ? displayCurrency(company.currency) : undefined}
              style={{
                width: '5em',
                status: errorPrice?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              }}
              dataCy={generateDataCy({
                scope: cyContextPrice,
                value: index.toString(),
              })}
            />
          </>
        )}
        {subValues && (
          <div className={styles.optionValues}>
            (
            {subValues.map((value: { name: string }, index: number) => (
              <span key={index}>
                {value.name}
                {subValues && index < subValues.length - 1 ? ', ' : ''}
              </span>
            ))}
            )
          </div>
        )}
      </>
    )
  }
)

/**
 *
 * This element displays the icon of the line
 *
 *
 * @param id - The decimals of the company
 * @param type - The type of the icon (edit, close..)
 * @param index - Index of the line
 * @param iconColor - Name of entity (Table, Chair..)
 * @param disabled - A boolean to know if the input is disabled or not
 * @param onClickIcon - A CTA when we click on the icon
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const IconLine = ({
  id,
  type,
  index,
  iconColor,
  disabled,
  onClickIcon,
  dataCy,
}: {
  id: string
  type: string
  index: number
  disabled: boolean
  iconColor: string
  onClickIcon: (id: string | undefined, index: number) => void
  dataCy?: string
}): JSX.Element => (
  <div
    style={{
      cursor: disabled ? 'not-allowed' : 'pointer',
    }}
    onClick={() => onClickIcon(id, index)}
    data-cy={generateDataCy({ scope: generateDataCy({ scope: dataCy ?? '', value: type }), value: index.toString() })}
  >
    {type && (
      <DisplayIcon2
        Icon={type === 'edit' ? EditIcon : type === 'close' ? CloseIcon : type === 'remove' ? DeleteIcon : ''}
        color={disabled ? 'var(--sys-color-content-disabled)' : iconColor}
      />
    )}
  </div>
)

/**
 *
 * This element displays the input text and the drop down
 *
 * @param addText - Text's input
 * @param entries - The options showed in a drop down
 * @param onSelect - A CTA when we select an option
 * @param onCreate - A CTA when we create an option
 * @param onRedirect - A CTA when we also click on the add text
 * @param onSubmit - A CTA when we click on the icon
 * @param error - An error
 * @param dataCy - An unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const AddList = ({
  addText,
  entries,
  onSelect,
  onCreate,
  onRedirect,
  onSubmit,
  error,
  dataCy,
}: {
  addText: string
  entries?: Array<Entries>
  onSelect?: (e: any) => void
  onCreate?: (value: string) => void
  onRedirect?: () => void
  onSubmit?: (event: any) => void
  error?: TypeFormError
  dataCy?: string
}): JSX.Element => (
  <div
    className={styles.addContainer}
    onSubmit={onSubmit}
    onClick={() => {
      onRedirect && onRedirect()
    }}
  >
    <DisplayIcon2 Icon={AddIcon} color={'var(--sys-color-content-interactive)'} />
    <>
      {onRedirect ? (
        <div className={styles.addText} data-cy={dataCy}>
          {addText}
        </div>
      ) : (
        entries &&
        // Variations handle create
        (onCreate ? (
          <SearchList
            eventID="drawer"
            parentID={'searchListDraggable'}
            popUpID={'searchListPopUpDraggable'}
            placeholder={addText}
            onSelect={onSelect}
            onCreate={onCreate}
            entries={entries ?? []}
            onSubmit={onSubmit}
            dataCy={dataCy}
          />
        ) : (
          <UniqueDropDown
            Element={
              <Input
                placeholder={addText}
                style={{
                  trailIcons: [{ icon: KeyboardArrowDown }],
                }}
                dataCy={dataCy!}
              />
            }
            items={entries.map((entry) => ({
              id: entry.id,
              value: entry.val,
              onClick: onSelect!,
            }))}
            dataCy={dataCy!}
          />
        ))
      )}
    </>
    {error && error.value && (
      <DisplayIcon2
        Icon={ErrorIcon}
        color="var(--sys-color-content-danger)"
      // dataCy={generateDataCy({ scope: dataCy ?? '', value: 'icons' })}
      />
    )}
  </div>
)

/**
 *
 * This element has a huge amount of props..
 * It displays a list of options
 * We can add/remove them by clicking inside the drop down entries
 * We can also drag the list to change its order
 *
 * @usedIn - Form
 *
 * @param label - The input's label
 * @param list - The list of options selected
 * @param entries - The options showed in a drop down
 * @param onChange - A CTA when we selected an option
 * @param onCreate - A CTA when we create an option
 * @param onBlur - A CTA when we leave the selection
 * @param onKeyPress - A CTA when we press "Enter"
 * @param onSelect - A CTA when we select an option
 * @param onClickIcon - A CTA when we click on the icon at the end of an option
 * @param onRedirect - A CTA when we also click on the add text
 * @param onDragEnd - A CTA when we finished dragging the list
 * @param iconColor - Color of the icon at the input's end
 * @param isEditable - A boolean to know if the input is editable or not
 * @param pen - A boolean to know what icon to show (here it's pen)
 * @param close - A boolean to know what icon to show (here it's cross)
 * @param remove - A boolean to know what icon to show (here it's trash)
 * @param addText - The input's text
 * @param disabled - A boolean to know if the input is disabled or not
 * @param error - An error object
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const DraggableList = ({
  label,
  list,
  entries,
  onChange,
  onCreate,
  onBlur,
  onKeyPress,
  onSelect,
  onClickIcon,
  onRedirect,
  onDragEnd,
  onSubmit,
  updateState,
  stateVariation,
  iconColor,
  isEditable,
  pen,
  close,
  remove,
  addText,
  disabled = false,
  draggable = true,
  error,
  dataCy,
  isOption = false,
  helperText
}: {
  label: string
  list: Array<DraggableListState>
  entries?: Array<Entries>
  onChange?: (field: string, index: number) => (event: any) => void
  onCreate?: (value: string) => void
  onBlur?: (field: string, index?: number) => (event: any) => void
  onKeyPress?: (index?: number) => (event: any) => void
  onSelect?: (e: any) => void
  onClickIcon: (id: string | undefined, index: number) => void
  onRedirect?: () => void
  onDragEnd: (dragStart: number, dragEnd: number) => void
  onSubmit?: () => void
  updateState?: () => void
  stateVariation?: any
  iconColor: string
  isEditable?: boolean
  pen?: boolean
  close?: boolean
  remove?: boolean
  addText?: string
  disabled?: boolean
  draggable?: boolean
  error?: TypeFormError
  dataCy?: string
  isOption?: boolean
  helperText?: string | null
}): JSX.Element => {
  const initialeState: State = {
    addText: '',
    entries: [],
    editValue: [],
    dragStart: -1,
    dragEnd: -1,
  }
  const ref: RefObject<any> = useRef()
  const [state, setState] = useState(initialeState)

  useEffect(() => {
    setState({
      ...state,
      addText,
    })
  }, [addText])

  const onDragStart = (index: number) => {
    setState({ ...state, dragStart: index })
  }

  const onDragOver = (event: any, index: number) => {
    event.preventDefault()
    if (index !== state.dragEnd) setState({ ...state, dragEnd: index })
  }

  const handleClickIcon = (id: string | undefined, index: number) => {
    if (!disabled) {
      setState({ ...state })
      onClickIcon(id, index)
    }
  }

  const handleBlur = (field: string, index?: number) => (event: any) => {
    if (pen && (!event.relatedTarget || (event.relatedTarget.id && event.relatedTarget.id !== 'inputID'))) {
      state.editValue[index!] = false
      setState({ ...state })
    }
    onBlur && onBlur(field, index)(event)
  }

  const handleSubmit = (event: any) => {
    //@ts-ignore
    updateState({ ...stateVariation, helperText: '' })
    event.preventDefault()
  }

  return (
    <div className={styles.container} style={{
      border: `${helperText ? 'var(--sys-size-border-width-sm) solid var(--sys-color-border-danger)' : 'var(--sys-size-border-width-sm) solid var(--sys-color-border-default)'}`,
    }}>
      <label
        className={stylesInput.label}
        style={{
          color: `${helperText ? 'var(--sys-color-content-danger)' : 'var(--sys-color-content-secondary)'}`,
          transform: 'translateY(-14px)', zIndex: 2
        }}
      >
        {label}
      </label>
      {list &&
        list
          .filter((o: any) => o)
          .map((option, index) => (
            <div
              key={index}
              draggable={draggable}
              className={styles.containerOption}
              style={{
                cursor: disabled ? 'not-allowed' : 'grab',
              }}
              onDragStart={() => !disabled && draggable && onDragStart(index)}
              onDragOver={(event) => !disabled && draggable && onDragOver(event, index)}
              onDragEnd={() => !disabled && draggable && onDragEnd(state.dragStart, state.dragEnd)}
            >
              <div className={styles.option}>
                {draggable && <DisplayIcon2 Icon={DragHandle} color="var(--sys-color-content-interactive)" />}
                <InputsLine
                  quantity={option.quantity}
                  name={option.name}
                  errorValue={option.errorValue}
                  errorPrice={option.errorPrice}
                  price={option.price}
                  subValues={option.values}
                  disabled={disabled}
                  isEditable={isEditable}
                  index={index}
                  onBlur={handleBlur}
                  onKeyPress={onKeyPress}
                  onChange={onChange}
                  ref={ref}
                  dataCy={dataCy}
                  isOption={isOption}
                />
              </div>
              <IconLine
                id={option.id}
                type={pen ? 'edit' : close ? 'close' : remove ? 'remove' : ''}
                index={index}
                iconColor={iconColor}
                disabled={disabled}
                onClickIcon={handleClickIcon}
                dataCy={dataCy}
              />
            </div>
          ))}
      {addText && !disabled && (
        <AddList
          addText={addText}
          entries={entries}
          onSelect={onSelect}
          onCreate={(value) => value.replace(/\s/g, '').length > 0 && onCreate && onCreate(value)}
          onRedirect={onRedirect}
          onSubmit={handleSubmit}
          error={error}
          dataCy={dataCy}
        />
      )}
    </div>
  )
}

export default DraggableList
