const ArrowBackIOS = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2132 2.88502C16.7232 2.39502 15.9332 2.39502 15.4432 2.88502L7.03322 11.295C6.64322 11.685 6.64322 12.315 7.03322 12.705L15.4432 21.115C15.9332 21.605 16.7232 21.605 17.2132 21.115C17.7032 20.625 17.7032 19.835 17.2132 19.345L9.87322 12.005L17.2232 4.65502C17.7032 4.16502 17.7032 3.37502 17.2132 2.88502Z"
      fill={color}
    />
  </svg>
)

export default ArrowBackIOS
