const Circle = ({ color }: { color?: string }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 8.5C14 11.8281 11.3047 14.5 8 14.5C4.67188 14.5 2 11.8281 2 8.5C2 5.19531 4.67188 2.5 8 2.5C11.3047 2.5 14 5.19531 14 8.5ZM8 3.625C5.30469 3.625 3.125 5.82812 3.125 8.5C3.125 11.1953 5.30469 13.375 8 13.375C10.6719 13.375 12.875 11.1953 12.875 8.5C12.875 5.82812 10.6719 3.625 8 3.625Z"
      fill={color}
    />
  </svg>


)

export default Circle;