import { DayRange } from '@hassanmojab/react-modern-calendar-datepicker'
import MultipleDropDown, { SelectOptions } from '../../newComponents/dropDown/multiple'
import { Stats } from '../../../types'
import Input from '../../newComponents/input'
import { displayDateShortMonth, formatDate } from '../../../utils/date'
import UniqueDropDown from '../../newComponents/dropDown/unique'
import { KeyboardArrowDown } from '../../../assets/icons'
import { env } from '../../../services/auth'
import { DateRangeCompReduced } from '../../newComponents/datePicker/DateRange';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { interval } from '../../../store/selector'
import generateDataCy from '../../../utils/cypress'

const Selection = ({
  state,
  allShops,
  updateDate,
  updateState,
  updateShops,
  dataCy,
}: {
  state: Stats
  allShops: Array<any>
  updateDate: ({ datePicker, dropDown }: { datePicker?: DayRange; dropDown?: string | null }) => void
  updateState: (state: Stats) => void
  updateShops: (options: Array<SelectOptions>, value: string) => void
  dataCy?: string
}) => {


  return (
    <>
      {(env !== 'production') && (
        <div data-cy={generateDataCy({ scope: dataCy ?? '', value: 'date' })}>
          <p>Only on pre-prod and dev</p>
          <DateRangeCompReduced
            value={[state.datePicker.date.from, state.datePicker.date.to]}
            onChange={(date) => {
              state.datePicker.date = {
                from: date[0],
                to: date[1],
              }
              state.lastUsed = 'datePicker'
              updateState({ ...state })
              if (date) updateDate({ datePicker: date })
            }}
          />
        </div>
      )}
      <UniqueDropDown
        eventID="drawer"
        parentID={'select'}
        popUpID={'selectPopUp'}
        Element={
          <Input
            value={state.dropDown.date}
            style={{
              trailIcons: [{ icon: KeyboardArrowDown }],
              readOnly: true,
            }}
            dataCy={generateDataCy({ scope: dataCy ?? '', value: 'interval' })}
          />
        }
        items={state.dropDown.dates.map((date) => ({ ...date, onClick: () => updateDate({ dropDown: date.id }) }))}
        dataCy={dataCy ?? ''}
      />
      {state.shops.length > 1 && (
        <MultipleDropDown
          dataCy={generateDataCy({ scope: dataCy ?? '', value: 'shop' })}
          value={state.shopInput}
          options={state.shops}
          onChanges={updateShops}
          entities={allShops}
        />
      )}
    </>
  )
}

export default Selection
