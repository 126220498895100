import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Template from '../template'
import { ComponentSize, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import { DashboardView } from '../../../store/types'
import { getTestMode, setTestMode } from '../../../services/localStorage'
import Chip from '../../../components/newComponents/chip'
import { resetApp } from '../../../actions/app'
import { useSelector } from 'react-redux'
import { getDashboardView } from '../../../store/selector'
import Button from '../../../components/newComponents/button'

import styles from '../Template.module.css'
import generateDataCy from '../../../utils/cypress'

const TestMode = () => {
  const dashboardView = useSelector(getDashboardView)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'account', value: 'testMode' })

  const handleSubmit = () => {
    setTestMode(!getTestMode())
    resetApp(t, dashboardView === DashboardView.MOBILE, dispatch)
  }

  return (
    <Template>
      <div className={styles.form}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div className={styles.title}>{t('account.testMode.TITLE')}</div>
          <Chip
            size={ComponentSize.MEDIUM}
            status={getTestMode() ? ComponentStatus.SUCCESS : ComponentStatus.DEFAULT}
            text={getTestMode() ? t('account.testMode.ACTIVATE') : t('account.testMode.DESACTIVATE')}
            dataCy={generateDataCy({ scope: cyContext, value: 'state' })}
          />
        </div>
        <div className={styles.subTitle}>{t('account.testMode.SUBTITLE')}</div>
        <Button
          title={getTestMode() ? t('button.DESACTIVATE') : t('button.ACTIVATE')}
          size={ComponentSize.MEDIUM}
          type={getTestMode() ? ComponentType.SECONDARY : ComponentType.PRIMARY}
          status={getTestMode() ? ComponentStatus.DANGER : ComponentStatus.DEFAULT}
          onClick={handleSubmit}
          dataCy={generateDataCy({ scope: cyContext, value: 'toggle' })}
        />
        {getTestMode() && <div data-cy={generateDataCy({scope: cyContext, value: "info"})} className={styles.info}>{t('account.testMode.INFO')}</div>}
      </div>
    </Template>
  )
}

export default TestMode
