import { ComponentStatus } from '../components/newComponents/types';
import { Action } from '../store/types';

const updateModal = (
  dispatch: any,
  show: boolean,
  Element: () => JSX.Element,
  backgroundClick: () => void,
  type?: string,
) => {
  dispatch({
    type: Action.UPDATE_MODAL,
    payload: { show, Element, backgroundClick, type },
  });
};

const updateToaster = (
  dispatch: any,
  show: boolean,
  param?: {
    status?: ComponentStatus;
    text?: string;
    LeadIcon?: any;
    clearButton?: boolean;
  },
) => {
  dispatch({
    type: Action.UPDATE_TOASTER,
    payload: { show, param },
  });
};

const closeAndResetModal = (dispatch: any) => {
  dispatch({
    type: Action.UPDATE_MODAL,
    payload: { show: false, Element: () => null, backgroundClick: () => {} },
  });
};

const updateFlatFile = (show: boolean, flatfileData: any, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_FLATFILE_INFO,
    payload: { show, flatfileData },
  });
};

export { updateModal, updateToaster, closeAndResetModal, updateFlatFile };
