import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../components/requester/types'
import { dateEqualTransformer } from '../../components/requester/query'
import countries from '../../assets/countries.json'

const customerFieldsRequester: Array<TypeRequester> = [
  {
    type: TypePath.PLAIN,
    filterID: 'FIRST_NAME',
    field: 'firstName',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'LAST_NAME',
    field: 'lastName',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'EMAIL',
    field: 'email',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'PHONE',
    field: 'phone',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'COMPANY_NAME',
    field: 'companyName',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'ADDRESS',
    field: 'address',
    path: ['address'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'ZIP_CODE',
    field: 'zipcode',
    path: ['address'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'CITY',
    field: 'city',
    path: ['address'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'COUNTRY',
    field: 'country',
    path: ['address'],
    filters: [
      {
        type: TypeFilter.LIST,
        list: countries.map((country) => ({
          id: country.alpha2.toUpperCase(),
          value: country.name,
        })),
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.LIST,
        list: countries.map((country) => ({
          id: country.alpha2.toUpperCase(),
          value: country.name,
        })),
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'FIDELITY_CARD_NUMBER',
    field: 'fidelityCardNumber',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'CREATED_AT',
    field: 'createdAt',
    path: [],
    transformer: dateEqualTransformer,
    filters: [
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.LT,
      },
    ],
  },
]

export default customerFieldsRequester
