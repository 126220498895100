const Close = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4613 5.70997C18.0713 5.31997 17.4413 5.31997 17.0513 5.70997L12.1613 10.59L7.27135 5.69997C6.88135 5.30997 6.25135 5.30997 5.86135 5.69997C5.47135 6.08997 5.47135 6.71997 5.86135 7.10997L10.7513 12L5.86135 16.89C5.47135 17.28 5.47135 17.91 5.86135 18.3C6.25135 18.69 6.88135 18.69 7.27135 18.3L12.1613 13.41L17.0513 18.3C17.4413 18.69 18.0713 18.69 18.4613 18.3C18.8513 17.91 18.8513 17.28 18.4613 16.89L13.5713 12L18.4613 7.10997C18.8413 6.72997 18.8413 6.08997 18.4613 5.70997Z"
      fill={color}
    />
  </svg>
)

export default Close
