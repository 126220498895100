import { Action, TypeCol, TypeColumnConfiguration, objectType } from '../store/types'

const changeConf = (dispatch: any, type: objectType, confId: string) => {
  dispatch({ type: Action.CHANGE_COLS_CONF, payload: { type, confId } })
}

const updateColsConf = (dispatch: any, type: string, conf: TypeColumnConfiguration, change?: boolean) => {
  dispatch({
    type: Action.UPDATE_COLS_CONF,
    payload: { type, conf, change },
  })
}

const addCols = (dispatch: any, cols: Array<TypeCol>, confId: string) => {
  dispatch({ type: Action.ADD_COLS, payload: { cols, confId } })
}

const updateCols = (dispatch: any, cols: TypeColumnConfiguration) => {
  dispatch({
    type: Action.UPDATE_COLS,
    payload: { cols },
  })
}

const deleteCols = (dispatch: any, id: string, type: objectType) => {
  dispatch({
    type: Action.DELETE_COL,
    payload: { id, type },
  })
}

const resetCustomCols = (dispatch: any) => dispatch({ type: Action.RESET_CUSTOM_COLS })

export { changeConf, updateColsConf, addCols, updateCols, deleteCols, resetCustomCols }
