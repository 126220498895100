const setInvitation = (invitation: string) => localStorage.setItem('invitationID', JSON.stringify(invitation))

const getInvitation = () => JSON.parse(localStorage.getItem('invitationID') + '')

const removeInvitation = () => localStorage.removeItem('invitationID')

const popInvitation = () => {
  const inv = getInvitation()
  removeInvitation()
  return inv
}

const getCompanyID = () => {
  // @ts-ignore
  const companyID: string = localStorage.getItem('companyID')
  return companyID
}

const setCompanyID = (id: string) => localStorage.setItem('companyID', id)

const removeCompanyID = () => localStorage.removeItem('companyID')

const getTestMode = () => {
  // @ts-ignore
  const testMode: string = localStorage.getItem('testMode')
  return JSON.parse(testMode) === true
}

const setTestMode = (testMode: boolean) => localStorage.setItem('testMode', testMode ? 'true' : 'false')

export {
  setInvitation,
  getInvitation,
  removeInvitation,
  popInvitation,
  getCompanyID,
  setCompanyID,
  removeCompanyID,
  getTestMode,
  setTestMode,
}
