import { ComponentState, ComponentStatus } from '../types'
import DisplayIcon2 from '../icons'
import IconTouch from '../icons/iconTouch'

import { Close } from '../../../assets/icons'
import styles from './AlertBanner.module.css'

type Props = {
  title?: string
  state?: ComponentState
  status: ComponentStatus
  LeadIcon?: any
  clearButton?: boolean
  dataCy?: string
}

/**
 *
 * This element displays an alert banner
 *
 * @param title - Error's title
 * @param state - Error's state
 * @param status - Error's status
 * @param leadIcon - A boolean to display lead icon
 * @param clearButton - A boolean to display cross icon
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const AlertBanner = ({ title, state, status, LeadIcon, clearButton, dataCy }: Props): JSX.Element => (
  <div
    data-cy={dataCy}
    className={styles.alertBanner}
    style={{
      border: `var(--comp-alert-banner-border-width) solid var(--comp-alert-banner-color-border-${status})`,
      background: `var(--comp-alert-banner-color-background-${status})`,
      color: `var(--comp-alert-banner-color-foreground-${status})`,
    }}
  >
    {LeadIcon && <DisplayIcon2 Icon={LeadIcon} color={`var(--comp-alert-banner-color-border-${status})`} nonEditable />}
    {title && <div className={styles.title}>{title}</div>}
    {clearButton && <IconTouch Icon={Close} color={`var(--comp-alert-banner-color-border-${status})`} />}
  </div>
)

export default AlertBanner
