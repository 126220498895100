import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { DashboardView, DrawerState, DrawerType, objectType } from '../../../store/types'
import { TypeTab, TypeDrawer } from '../../../types/drawer'
import { TabIdsCatalog } from '..'
import Detail from './drawer/detail'
import { getRandomColor } from '../../../utils/color'
import { customFieldsInState } from '../../../services/setting/customField'
import {
  currentDrawer,
  getDashboardView,
  isDrawerOpen,
  listCustomFieldItems,
  listCategories,
  listCategoryItems,
} from '../../../store/selector'
import { Stats } from '../../../types/stat'
import DrawerMenu from '../../../components/oldComponents/drawer/menu'
import { Route } from '../../../utils/navigation'
import DrawerBulk, { TypeQuickActions } from '../../../components/oldComponents/drawer/quickAction'
import { drawerAddHistory, drawerResetHistory, updateDrawerState } from '../../../actions/drawer'
import { Delete, deleteCategories } from './drawer/bulk/delete'
import { updateModal } from '../../../actions/modal'
import TabList from '../../../components/newComponents/tabList'
import {
  ComponentDirection,
  ComponentSize,
  ComponentState,
  ComponentType,
} from '../../../components/newComponents/types'
import DrawerTop from '../../../components/oldComponents/drawer/top'
import { callService } from '../../../services'
import { DeleteCategoryInput, GetCategoryQueryVariables } from '../../../API';
import { TypeCategory } from '../../../types'
import { deleteCategory as deleteC } from '../../../graphql/custom/mutations'
import Statistic from '../../../components/oldComponents/drawer/statistic'
import { getCategoryStats } from '../../../graphql/custom/queries'
import { createPayload } from '../../../utils/dirties'
import generateDataCy from '../../../utils/cypress'
import Divider from '../../../components/newComponents/divider'
import {
  name as nameValidator,
  customFieldNumber as customFieldNumberValidator,
} from '../../../format/errors/filters/catalog/category'

import { Duplicate, DeleteIcon } from '../../../assets/icons'
import Loader from '../../../components/newComponents/loader'
import { getCategory as get } from '../../../graphql/queries'

export const MenuCategory = ({
  id,
  isDuplicate,
  addHistory,
  history,
  close,
}: {
  id?: string
  isDuplicate?: boolean
  addHistory: (route: Route) => void
  history: Array<Route>
  close: () => void
}) => {
  const { t } = useTranslation()
  const listCustomFields = useSelector(listCustomFieldItems)
  const drawerIsOpen = useSelector(isDrawerOpen)
  const allCategories = useSelector(listCategories)

  const initialState: {
    tabList: Array<TypeTab>
    detail: TypeCategory
    performance?: Stats
    dataError: boolean
  } = {
    tabList: [
      { id: TabIdsCatalog.DETAIL, name: t('drawer.tabs.DETAIL'), state: ComponentState.ACTIVE },
      { id: TabIdsCatalog.STAT, name: t('drawer.tabs.PERFORMANCE'), state: ComponentState.DEFAULT },
    ],
    detail: {
      actions: [
        {
          id: 'duplicate',
          value: t('drawer.menu.DUPLICATE'),
        },
        { id: 'delete', value: t('drawer.menu.DELETE') },
      ],
      selected: 'color',
      color: getRandomColor(),
      isUpdateIconText: false,
      customFields: customFieldsInState('CATEGORY', listCustomFields),
      showSubmit: false,
      dirties: id && !isDuplicate ? ['id'] : ['color'],
      errors: {
        customFields: customFieldsInState('CATEGORY', listCustomFields).map((_) => ({
          value: false,
          message: '',
        })),
      },
      validators: {
        name: nameValidator,
        customFields: customFieldNumberValidator,
      },
    },
    dataError: false,
  }
  const [state, setState] = useState(initialState)
  const mobileView = useSelector(getDashboardView)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({
    scope: generateDataCy({
      scope: 'catalog',
      value: 'category',
    }),
    value: 'drawer',
  })

  const setCategoryInState = (category: any) => {
    if (category) {
      const customFields = customFieldsInState('CATEGORY', listCustomFields, category.customFields)

      setState({
        ...state,
        detail: {
          ...state.detail,
          id: isDuplicate ? '' : category.id,
          name: category.name,
          color: category.color,
          iconText: category.iconText,
          isUpdateIconText: category.iconText !== category.name.substring(0, 4),
          photo: category.photo,
          selected: !!category.photo ? 'photo' : 'color',
          customFields,
          errors: {
            customFields: customFields.map((cf) => ({ value: false, message: '' })),
          },
          dirties: isDuplicate ? createPayload(category) : state.detail.dirties,
          showSubmit: !!isDuplicate,
        },
        dataError: false,
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else {
      setState({
        ...state,
        dataError: true,
      })
      updateDrawerState(DrawerState.ERROR, dispatch)
    }
  }

  useEffect(() => {
    if (id && drawerIsOpen) {
      const category = allCategories.items.items.find((c) => c.id === id)
      setCategoryInState(category)
    } else {
      setState(initialState)
    }
      }, [id, isDuplicate, listCustomFields, drawerIsOpen])

  const updateState = (newState: any) => setState({ ...state, detail: { ...state.detail, ...newState } })

  const ComponentToDisplay = () => {
    switch (state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id) {
      case TabIdsCatalog.DETAIL:
        return <Detail categoryState={state.detail} close={close} updateState={updateState} dataCy={cyContext} />
      case TabIdsCatalog.STAT:
        return (
          <Statistic
            id={state.detail.id!}
            name={state.detail.name ?? ''}
            query={getCategoryStats}
            queryName={'getCategory'}
            type={objectType.CATEGORY}
            close={close}
          />
        )
      default:
        return <></>
    }
  }

  const handleClickMenu = (action: string) => {
    switch (action) {
      case 'duplicate': {
        updateDrawerState(DrawerState.LOADING, dispatch)
        addHistory({
          component: MenuCategory,
          params: { id: state.detail.id!, isDuplicate: true },
        })
        break
      }
      case 'delete': {
        callService<{ input: DeleteCategoryInput }>(
          { input: { id: state.detail.id! } },
          deleteC,
          'deleteCategory'
        ).then((data) => {
          if (data.errors && data.errors.errorType === 'g-103') {
            console.log(data, "DATA DELETE")
            const modal = () => <Delete id={data.payload.input.id} />
            updateModal(dispatch, true, modal, () => { })
          } else {
            drawerResetHistory(dispatch)
            close()
          }
        })
        break
      }
      default:
        break
    }
  }

  return (
    <>
      {state.detail.id &&
        state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id !== TabIdsCatalog.STAT ? (
        <DrawerTop
          history={history}
          name={state.detail.name ?? ''}
          mobileView={mobileView === DashboardView.MOBILE}
          burgerMenu={{ actions: state.detail.actions!, onClick: handleClickMenu }}
          close={close}
          dataCy={cyContext}
        />
      ) : (
        <DrawerTop
          history={history}
          name={state.detail.id ? state.detail.name ?? ' ' : t('catalog.category.form.NEW')}
          mobileView={mobileView === DashboardView.MOBILE}
          close={close}
          dataCy={cyContext}
        />
      )}
      {id && !isDuplicate && (
        <>
          <TabList
            tabList={state.tabList}
            onClick={(newTab: TypeTab) => {
              if (newTab.id !== TabIdsCatalog.DETAIL) updateDrawerState(DrawerState.LOADING, dispatch)
              setState({
                ...state,
                tabList: state.tabList.map((sTab) => ({
                  ...sTab,
                  state: newTab.id === sTab.id ? ComponentState.ACTIVE : ComponentState.DEFAULT,
                })),
              })
            }}
            size={ComponentSize.LARGE}
            dataCy={cyContext}
          />
          <Divider type={ComponentType.FILL} direction={ComponentDirection.HORIZONTAL} />
        </>
      )}
      {state.dataError && <Loader />}
      <ComponentToDisplay />
    </>
  )
}

const Index = ({ selected = [], close }: TypeDrawer) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const drawer = useSelector(currentDrawer)
  const mobileView = useSelector(getDashboardView)
  const allCategories = useSelector(listCategories);


  useEffect(() => {
    selected.length === 0
      && allCategories.items.items.filter(c => c.selected).length === 0
      && drawer.type === DrawerType.BULK
      && close()
    if (selected.length !== allCategories.items.items.filter(c => c.selected).length) {
            selected = allCategories.items.items.filter(c => c.selected);
    }
      }, [selected])


  const getBulkActions = () => {
    let actions: TypeQuickActions = []
    if (selected.length === 1)
      actions = [
        {
          id: 'duplicate',
          icon: Duplicate,
          title: t('catalog.category.bulk.duplicate.TITLE'),
          text: t('catalog.category.bulk.duplicate.SUBTITLE'),
          onClick: () => {
            drawerAddHistory(
              {
                component: MenuCategory,
                params: {
                  id: selected[0].id!,
                  isDuplicate: selected[0].id,
                },
              },
              dispatch
            )
          },
        },
      ]
    actions = [
      ...actions,
      {
        id: 'delete',
        icon: DeleteIcon,
        title: t('catalog.category.bulk.DELETE'),
        onClick: deleteCategories(selected, close, dispatch),
      },
    ]
    return actions
  }

  return (
    <DrawerMenu
      component={
        drawer.type === DrawerType.BULK
          ? () =>
            DrawerBulk({
              bulkTitle: t('catalog.category.bulk.BUNDLED_SHARES'),
              title: t('catalog.category.bulk.SELECTED', {
                number: selected.length,
                plural: selected.length > 1 ? 's' : '',
              }),
              actions: getBulkActions(),
              mobileView: mobileView === DashboardView.MOBILE,
              close,
            })
          : MenuCategory
      }
    />
  )
}

export default Index
