const Number = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2964 13C16.2964 14 16.1964 14.85 15.9964 15.55C15.7964 16.25 15.5164 16.82 15.1664 17.25C14.8064 17.69 14.3764 18 13.8664 18.2C13.3564 18.4 12.7964 18.5 12.1664 18.5C11.5464 18.5 10.9864 18.4 10.4764 18.2C9.96636 18 9.52636 17.69 9.16636 17.25C8.80636 16.81 8.51636 16.24 8.31636 15.55C8.11636 14.85 8.01636 14 8.01636 13V10.96C8.01636 9.96 8.11636 9.11 8.31636 8.41C8.51636 7.71 8.79636 7.15 9.15636 6.72C9.51636 6.29 9.95636 5.98 10.4664 5.79C10.9664 5.6 11.5364 5.5 12.1564 5.5C12.7864 5.5 13.3464 5.6 13.8564 5.79C14.3664 5.98 14.8064 6.29 15.1664 6.72C15.5264 7.15 15.8064 7.71 16.0064 8.41C16.2064 9.11 16.3064 9.95 16.3064 10.96V13H16.2964ZM14.1864 10.64C14.1864 10 14.1364 9.46 14.0564 9.02C13.9664 8.58 13.8364 8.23 13.6564 7.96C13.4864 7.69 13.2664 7.5 13.0164 7.38C12.7664 7.25 12.4764 7.19 12.1564 7.19C11.8364 7.19 11.5464 7.25 11.2964 7.37C11.0464 7.49 10.8264 7.68 10.6564 7.95C10.4864 8.22 10.3464 8.57 10.2564 9.01C10.1664 9.45 10.1264 9.99 10.1264 10.63V13.3C10.1264 13.94 10.1764 14.48 10.2664 14.92C10.3564 15.37 10.4964 15.73 10.6664 16.01C10.8364 16.29 11.0564 16.49 11.3064 16.62C11.5564 16.75 11.8464 16.81 12.1764 16.81C12.5064 16.81 12.7964 16.75 13.0464 16.62C13.2964 16.49 13.5064 16.29 13.6764 16.01C13.8464 15.73 13.9764 15.37 14.0664 14.92C14.1564 14.47 14.1964 13.93 14.1964 13.3V10.64H14.1864Z"
      fill={color}
    />
  </svg>
)

export default Number
