import { useSelector, useDispatch } from 'react-redux'

import { updateModal } from '../../../actions/modal'
import { listUserItemsNextToken, listUsers } from '../../../store/selector'
import List from '../../../components/list'
import { ListState, objectType } from '../../../store/types'
import UserModal from './modal'
import { useEffect } from 'react'
import { listUsersAndInvitation } from '../../../graphql/custom/queries';
import { getData } from '../../../services'
import { fetchListElement, updateListState } from '../../../actions/list'
import { pluralize } from '../../../utils/typeToType'
import { AccountInvitationStatus } from '../../../API'
import { subscribeInvitations, unSubscribeInvitations } from '../../../getters/setting/user'

const Users = () => {
  const users = useSelector(listUsers)
  const { items, nextToken } = useSelector(listUserItemsNextToken)
  const dispatch = useDispatch()



  useEffect(() => {
    subscribeInvitations(dispatch)
    const getListAllUsers = async () => {
      const listAllUsers = await getData({ request: listUsersAndInvitation, limit: 100 });
      if (listAllUsers.listUsers && listAllUsers.listUsers.length > 0) {
        let users = listAllUsers.listUsers.filter((_: any) => _)

        if (listAllUsers.listCompanyInvitations.items && listAllUsers.listCompanyInvitations.items.length > 0) {
          users = [
            ...users,
            ...listAllUsers.listCompanyInvitations.items
              .filter((_: any) => _)
              .filter(
                (invitation: { status: AccountInvitationStatus }) => invitation.status === AccountInvitationStatus.SENT
              )
              .map((invitation: any) => ({
                ...invitation,
                firstName: '-',
                lastName: '-',
                company: { role: invitation.role },
              })),
          ]
        }
        fetchListElement(dispatch, users, listAllUsers.listUsers.nextToken, pluralize(objectType.USER))
        updateListState(
          { type: pluralize(objectType.USER), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.USER))
        updateListState(
          { type: pluralize(objectType.USER), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListAllUsers();

    return () => unSubscribeInvitations();

      }, [])

  const handleCreate = () =>
    updateModal(
      dispatch,
      true,
      () => <UserModal />,
      () => { }
    )

  return (
    <List
      cols={users.cols!}
      items={items}
      fullItems={items}
      nextToken={nextToken}
      type={objectType.USER}
      onCreate={handleCreate}
    />
  )
}

export default Users
