const SortUp = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.91033 14.3368H14.417L12.1637 16.5901L9.91033 14.3368ZM11.6903 17.5301C11.9503 17.7901 12.3703 17.7901 12.6303 17.5301L15.6903 14.4701C16.1103 14.0568 15.817 13.3368 15.2237 13.3368H9.10367C8.51033 13.3368 8.21033 14.0568 8.63033 14.4768L11.6903 17.5301ZM9.10367 10.6701H15.217C15.8103 10.6701 16.1103 9.9501 15.6903 9.5301L12.6303 6.4701C12.3703 6.2101 11.9503 6.2101 11.6903 6.4701L8.63033 9.5301C8.217 9.9501 8.51033 10.6701 9.10367 10.6701Z"
      fill={color}
    />
  </svg>
)

export default SortUp
