import { DrawerState } from '../store/types'

export type Route = {
  component: (props: any) => JSX.Element
  params: {
    id?: string
    productID?: string
    subId?: string
    isDuplicate?: boolean
    isSubDuplicate?: boolean
    isVariant?: boolean
    selected?: Array<any>
  }
}

export type Navigation = {
  current?: Route
  history: Array<Route>
}

/**
 *
 * This method adds current route inside the navigation history
 * and replaces the current route by the new one
 *
 * @param route - New route to add
 * @param navigation - Object of navigation
 *
 * @returns - An object of navigation
 *
 * @author - Jennifer Charlois
 *
 */
const addHistory = ({ route, navigation }: { route: Route; navigation: Navigation }) => ({
  current: route,
  history: [navigation.current, ...navigation.history].filter((r) => r),
})

/**
 *
 * This method resets the entire navigation history
 *
 * @param navigation - Object of navigation
 *
 * @returns - An object of navigation
 *
 * @author - Jennifer Charlois
 *
 */
const resetHistory = (route?: Route) => ({
  current: route,
  history: [],
  drawerState: undefined,
})

/**
 *
 * This method changes the navigation to get to the previous route
 * or reset the history if there is nothing to go back to
 *
 * @param navigation - Object of navigation
 *
 * @returns - An object of navigation
 *
 * @author - Jennifer Charlois
 *
 */
const back = (navigation: Navigation, drawerState: DrawerState) => {
  if (navigation.history.length > 0) {
    const [current, ...history] = navigation.history
    drawerState = DrawerState.LOADING
    return { history, current, drawerState }
  }
  return resetHistory()
}

export { addHistory, back, resetHistory }
