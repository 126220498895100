/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import DisplayIcon2 from '../icons'
import Layer from '../layer'

import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../types'

import styles from './Button.module.css'

type Props = {
  id?: string
  title?: string
  size: ComponentSize
  type: ComponentType
  state?: ComponentState
  status?: ComponentStatus
  onClick?: (ref: any, title?: string) => void
  LeadIcon?: any
  TrailIcon?: any
  dataCy?: string
  width?: string
  ref?: any,
}

/**
 *
 * This element displays a button
 *
 * @param title - Button's title
 * @param size - Button's size
 * @param type - Button's type
 * @param state - Button's state
 * @param status - Button's status
 * @param leadIcon - A lead icon to display
 * @param trailIcon - A trail icon to display
 * @param dataCy - An unique id
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */

const Button = forwardRef(({
  id,
  title,
  size,
  type,
  state,
  status,
  onClick,
  LeadIcon,
  TrailIcon,
  dataCy,
  width,
}: Props, ref: any): JSX.Element => {
  const getForegroundColor = () =>
    `var(--comp-button-color-foreground-${state === ComponentState.DISABLED
      ? 'disabled'
      : status === ComponentStatus.DANGER && type !== ComponentType.PRIMARY
        ? 'danger'
        : type
    })`

  const getStyle = () => ({
    width: width ?? 'fit-content',
    background:
      type !== ComponentType.TERTIARY
        ? `var(--comp-button-color-background-${state === ComponentState.DISABLED ? 'disabled' : status === ComponentStatus.DANGER ? 'danger' : 'default'
        }${state !== ComponentState.DISABLED ? '-' + type : ''})`
        : '',
    fontSize: `var(--sys-typography-label-${size}-font-size)`,
    textDecoration: `var(--sys-typography-label-${size}-text-decoration)`,
    letterSpacing: `var(--sys-typography-label-${size}-letter-spacing)`,
    fontWeight: `var(--sys-typography-label-${size}-font-weight)`,
    lineHeight: `var(--sys-typography-label-${size}-line-height)`,
    height: `var(--comp-button-size-height-${size})`,
  })

  return (
    <button
      id={id}
      ref={ref}
      // @ts-ignore
      style={{
        ...getStyle(),
        color: getForegroundColor(),
      }}
      className={styles.button}
      onClick={() => {
        state !== ComponentState.DISABLED && onClick && onClick(ref, title)
      }}
      //@ts-ignore
      onKeyPress={(key, e) => {
        if (key.key === 'Enter') state !== ComponentState.DISABLED && onClick && onClick(e)
      }}
      disabled={state === ComponentState.DISABLED}
      data-cy={dataCy}
    >
      <Layer
        isDanger={status === ComponentStatus.DANGER}
        isDisabled={state === ComponentState.DISABLED}
        style={{
          height: `var(--comp-button-size-height-${size})`,
          padding: `0 var(--comp-button-spacing-default-${size})`,
          borderRadius: `var(--comp-button-border-radius)`,
          gap: 'var(--comp-button-spacing-gap)',
        }}
      >
        {LeadIcon && (
          <DisplayIcon2
            Icon={LeadIcon}
            color={getForegroundColor()}
            width={`var(--comp-button-size-icon-${size})`}
            height={`var(--comp-button-size-icon-${size})`}
          />
        )}
        {title && <div>{title}</div>}
        {TrailIcon && <DisplayIcon2 Icon={TrailIcon} color={getForegroundColor()} />}
      </Layer>
    </button>
  )
})

export default Button
