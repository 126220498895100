const Percent = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.66138 4C5.73138 4 4.16138 5.57 4.16138 7.5C4.16138 9.43 5.73138 11 7.66138 11C9.59138 11 11.1614 9.43 11.1614 7.5C11.1614 5.57 9.59138 4 7.66138 4ZM7.66138 9C6.83138 9 6.16138 8.33 6.16138 7.5C6.16138 6.67 6.83138 6 7.66138 6C8.49138 6 9.16138 6.67 9.16138 7.5C9.16138 8.33 8.49138 9 7.66138 9ZM16.6614 13C14.7314 13 13.1614 14.57 13.1614 16.5C13.1614 18.43 14.7314 20 16.6614 20C18.5914 20 20.1614 18.43 20.1614 16.5C20.1614 14.57 18.5914 13 16.6614 13ZM16.6614 18C15.8314 18 15.1614 17.33 15.1614 16.5C15.1614 15.67 15.8314 15 16.6614 15C17.4914 15 18.1614 15.67 18.1614 16.5C18.1614 17.33 17.4914 18 16.6614 18ZM19.4514 4.71C19.8414 5.1 19.8414 5.73 19.4514 6.12L6.28138 19.29C5.89138 19.68 5.26138 19.68 4.87138 19.29C4.48138 18.9 4.48138 18.27 4.87138 17.88L18.0414 4.71C18.4314 4.32 19.0614 4.32 19.4514 4.71Z"
      fill={color}
    />
  </svg>
)

export default Percent
