import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Input from '../../../../components/newComponents/input'
import { ComponentErrorText, ComponentSize, ComponentStatus } from '../../../../components/newComponents/types'
import { DiscountType } from '../../../../API'
import ColorPicker from '../../../../components/newComponents/colorPicker'
import { displayCurrency, displayIconTextPrice } from '../../../../utils/number'
import { selectedCompany } from '../../../../store/selector'
import generateDataCy from '../../../../utils/cypress'
import { TypeDiscount } from '../../../../types'
import { TypeFormError } from '../../../../format/errors/types'
import { enumToStr } from '../../../../utils/color'
import Thumbnail from '../../../../components/newComponents/thumbnail'

import { Euro as EuroIcon, Percentage } from '../../../../assets/icons'
import styles from '../Discount.module.css'
import stylesDrawer from '../../../../components/oldComponents/drawer/Drawer.module.css'

const TaxType = ({
  onChange,
  value,
  error,
  dataCy,
}: {
  onChange: (val: DiscountType) => void
  value?: DiscountType
  error?: TypeFormError
  dataCy: string
}) => {
  const [state, setState] = useState({ val: DiscountType.NUMERIC })

  useEffect(() => {
    if (value) setState({ val: value })
  }, [value])

  const toggle = (val: DiscountType) => {
    setState({ val })
    onChange(val)
  }

  return (
    <div
      className={styles.containerType}
      style={{
        border: `1px solid ${error && error.value ? 'var(--sys-color-content-danger)' : 'var(--sys-color-content-interactive)'
          }`,
      }}
    >
      <div
        className={styles.toggle}
        style={{
          backgroundColor:
            state.val === DiscountType.NUMERIC
              ? error && error.value
                ? 'var(--sys-color-content-danger)'
                : 'var(--sys-color-content-interactive)'
              : '',
        }}
        onClick={() => toggle(DiscountType.NUMERIC)}
        data-cy={generateDataCy({
          scope: generateDataCy({ scope: dataCy, value: 'type' }),
          value: 'NUMERIC',
        })}
      >
        <EuroIcon
          color={
            state.val === DiscountType.PERCENTAGE
              ? error && error.value
                ? 'var(--sys-color-content-danger)'
                : 'var(--sys-color-content-interactive)'
              : 'var(--ref-color-pure-white)'
          }
        />
      </div>
      <div
        className={styles.toggle}
        style={{
          backgroundColor:
            state.val === DiscountType.PERCENTAGE
              ? error && error.value
                ? 'var(--sys-color-content-danger)'
                : 'var(--sys-color-content-interactive)'
              : '',
        }}
        onClick={() => toggle(DiscountType.PERCENTAGE)}
        data-cy={generateDataCy({
          scope: generateDataCy({ scope: dataCy, value: 'type' }),
          value: 'PERCENTAGE',
        })}
      >
        <Percentage
          color={
            state.val === DiscountType.NUMERIC
              ? error && error.value
                ? 'var(--sys-color-content-danger)'
                : 'var(--sys-color-content-interactive)'
              : 'var(--ref-color-pure-white)'
          }
        />
      </div>
    </div>
  )
}

const Form = ({
  state,
  handleChange,
  handleBlur,
  handleSubmit,
  dataCy,
}: {
  state: TypeDiscount
  handleChange: (type: string) => (event: any) => void
  handleBlur: (field: string) => void
  handleSubmit: () => void
  dataCy: string
}) => {
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()

  const handleChangeType = (event: any) => {
    state.validators.rate = event === DiscountType.NUMERIC ? state.validators.rateNumeric : state.validators.ratePercent

    handleChange('rate')(state.rate)
    handleChange('type')(event)
  }

  const getIconText = (): string => {
    if (state.fullRate) {
      return displayIconTextPrice(state.fullRate, state.type, company.currencyDecimals, company.currency)
    }
    return ''
  }

  const errorText = () => {
    if (state.errors?.name?.value) {
      return state.errors.name.message
    }

    if (state.errors.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <div className={stylesDrawer.overflow}>
      <div className={stylesDrawer.form}>
        <ColorPicker
          title={t('button.EDIT')}
          Element={
            <Thumbnail
              size={ComponentSize.LARGE}
              color={state.color ? enumToStr(state.color) : state.color}
              iconText={getIconText()}
            />
          }
          color={state.color}
          selected={state.selected}
          iconText={getIconText()}
          onSelect={handleChange('color')}
          dataCy={generateDataCy({ scope: dataCy, value: 'color' })}
          isIconText={false}
          isImage={false}
        />
        <Input
          label={t('catalog.discount.form.NAME')}
          value={state.name}
          onChange={handleChange('name')}
          onBlur={() => handleBlur('name')}
          onSubmit={handleSubmit}
          style={{
            status: state.errors.name?.value || state.errors.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={errorText()}
          dataCy={generateDataCy({ scope: dataCy, value: 'name' })}
        />
        <div style={{ display: 'flex' }}>
          <Input
            type={'currency'}
            label={
              state.type === DiscountType.PERCENTAGE
                ? t('catalog.discount.form.PERCENTAGE')
                : t('catalog.discount.form.NUMERIC')
            }
            value={
              state.rate
                ? state.rate +
                (state.type === DiscountType.PERCENTAGE
                  ? ' %'
                  : company
                    ? ' ' + displayCurrency(company.currency)
                    : '')
                : ''
            }
            onChange={handleChange('rate')}
            onBlur={() => handleBlur('rate')}
            onSubmit={handleSubmit}
            suffix={
              state.type === DiscountType.PERCENTAGE ? '%' : company ? displayCurrency(company.currency) : undefined
            }
            style={{
              status: state.errors.rate?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={state.errors?.rate?.value ? state.errors.rate.message : undefined}
            dataCy={generateDataCy({ scope: dataCy, value: 'rate' })}
          />
          <TaxType value={state.type} onChange={handleChangeType} error={state.errors?.rate} dataCy={dataCy} />
        </div>
      </div>
    </div>
  )
}

export default Form
