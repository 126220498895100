import { ComponentSize } from '../types';
import KeyBoardArrowDown from '../../../assets/icons/keyboardArrowDown';
import IconTouch from '../icons/iconTouch';

type Props = {
  onClick: () => void
}

const LoadMore = ({ onClick }: Props): JSX.Element => (
  <IconTouch
    Icon={KeyBoardArrowDown}
    size={ComponentSize.MEDIUM}
    color={'var(--sys-color-content-primary)'}
    onClick={onClick}
  />
)

export default LoadMore;