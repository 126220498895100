import { ArrowBack, ArrowForward } from '../../../assets/icons'
import DisplayIcon2 from '../../newComponents/icons'

import styles from './List.module.css'

/**
 *
 * This element is used to display a type from activity
 * For example, we can display the type for a payment
 * And this one may be "in" or "out"
 *
 * @usedIn - Activity views
 *
 * @param color - The color needed for the arrow
 * @param text - The text to display
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Type = ({ color, text, dataCy }: { color: string; text: string; dataCy?: string }): JSX.Element => (
  <div className={styles.status}>
    <DisplayIcon2 Icon={text === 'Entrée' ? ArrowForward : ArrowBack} color={color} />
    <div style={{ marginLeft: '10px' }} data-cy={dataCy ?? ''}>
      {text}
    </div>
  </div>
)

export default Type
