import { useSelector, useDispatch } from 'react-redux'

import { updateModal } from '../../../actions/modal'
import ShopModal from './modal'
import { listShops, listShopsItemsNextToken } from '../../../store/selector'
import List from '../../../components/list'
import { objectType } from '../../../store/types'

const Shops = () => {
  const shops = useSelector(listShops)
  const { items, nextToken } = useSelector(listShopsItemsNextToken)
  const dispatch = useDispatch()

  const handleCreate = () =>
    updateModal(
      dispatch,
      true,
      () => <ShopModal />,
      () => { }
    )

  return (
    <List
      cols={shops.cols!}
      items={items}
      fullItems={items}
      nextToken={nextToken}
      type={objectType.SHOP}
      onCreate={handleCreate}
    />
  )
}

export default Shops
