import TextLink from '../textLink'
import Notification from '../notification'
import { ComponentSize } from '../types'

import styles from './ListItem.module.css'
import generateDataCy from '../../../utils/cypress'

type Props = {
  name: string
  length: number
  onClick: () => void
  onIconClick: () => void
}

/**
 *
 * This element displays the product's name and the number of subValues inside the List component
 *
 * @usedIn - Product list
 *
 * @param name - The name of the product
 * @param length - Product subValues's length
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ListItemVariant = ({ name, length, onClick }: Props): JSX.Element => (
  <div className={styles.listItemVariant}>
    <div className={styles.variant}>
      <TextLink dataCy={generateDataCy({ scope: 'list-row-showVariants', value: name })} text={name} handleClick={onClick} maxWidth={`${250 - 40 - 20 - 8 - 8}px`} size={ComponentSize.SMALL} />
      <Notification dataCy={generateDataCy({ scope: 'list-row-variantsCount', value: name })} value={length ? length.toString() : "0"} />
    </div>
  </div>
)

export default ListItemVariant
