import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TypeProduct, APIOption } from '../../../../../types'
import Form from './form'
import { Route } from '../../../../../utils/navigation'
import { displayIconText } from '../../../../../utils/string'
import { setErrorsInState, findErrorsInState } from '../../../../../format/errors'
import { getDrawerState, listProducts, selectedCompany } from '../../../../../store/selector'
import { drawerActionType, drawerResetHistory, updateDrawerState } from '../../../../../actions/drawer'
import { DrawerState } from '../../../../../store/types'
import Loader, { displayLoader } from '../../../../../components/newComponents/loader'
import { CreateCategoryInput } from '../../../../../API'
import { getCompanyID } from '../../../../../services/localStorage'
import { createCategory } from '../../../../../graphql/custom/mutations'
import { callService } from '../../../../../services'
import MenuVariant from '../variant'
import callProductUpdate from './service/update'
import callProductCreation from './service/create'
import { handleBlur, handleBlurNested } from '../../../../../format/form'
import SubmitButton from '../../../../../components/oldComponents/drawer/submitButton'
import callVariantGeneration from './service/generate'
import { getDrawerHeight, getDrawerHeightNoTab } from '../../../../../utils'

import styles from '../../../../../components/oldComponents/drawer/Drawer.module.css'

type Props = {
  productState: TypeProduct
  listOptions: Array<APIOption>
  addHistory: (route: Route) => void
  updateState: (state: TypeProduct) => void
  close: () => void
  dataCy: string
}

const Detail = ({ productState, listOptions, addHistory, updateState, close, dataCy }: Props) => {
  const [state, setState] = useState(productState)
  const drawerState = useSelector(getDrawerState)
  const allProducts = useSelector(listProducts)
  const company = useSelector(selectedCompany)
  const dispatch = useDispatch()

  const handleChange = (field: string, index?: number) => (event: any) => {
    state.errors!.global = undefined

    state.dirties = [...state.dirties, field]

    if (field === 'photo') {
      setState({
        ...state,
        selected: !event ? 'color' : 'photo',
        [field]: event,
        showSubmit: !findErrorsInState(state.errors),
      })
    } else if (field === 'color') {
      setState({ ...state, selected: 'color', [field]: event, showSubmit: !findErrorsInState(state.errors) })
    } else if (field === 'iconText') {
      // @ts-ignore
      state.errors[field] = null
      state.isUpdateIconText = true

      setState({
        ...state,
        [field]: event,
        showSubmit: !findErrorsInState(state.errors),
      })
    } else if (field === 'customFields') {
      // @ts-ignore
      state.customFields[index].value = event
      setState({ ...state, showSubmit: true })
    } else {
      if (field === 'categoryToCreate') {
        // @ts-ignore
        state.errors.categoryID = null
        state.categoryID = ''
      } else if (field === 'categoryID') state.categoryToCreate = ''
      // @ts-ignore
      state.errors[field] = null
      const showSubmit = !findErrorsInState(state.errors)

      if (field === 'name' && !state.isUpdateIconText) {
        state.iconText = event ? displayIconText(event) : ''
        state.dirties = [...state.dirties, 'iconText']
      }

      setState({ ...state, [field]: event, showSubmit })
    }
  }

  const handleAddOption = (option: APIOption): void => {
    state.dirties = [...state.dirties, 'options']
    setState({
      ...state,
      options: [...state.options, option],
      showSubmit: true,
    })
  }

  const handleDeleteOption = (id: string): void => {
    state.dirties = [...state.dirties, 'options']
    setState({
      ...state,
      options: state.options.filter((option) => option.id !== id),
      showSubmit: true,
    })
  }

  const handleDragEndOption = (dragStart: number, dragEnd: number): void => {
    const optionInsert = state.options[dragStart]
    const draggedList = state.options.filter((_, index) => index !== dragStart)
    draggedList.splice(dragEnd, 0, optionInsert)
    setState({ ...state, options: draggedList, showSubmit: true })
  }

  const handleDragEnd = (dragStart: number, dragEnd: number): void => {
    if (state.variations) {
      const decliInsert = state.variations[dragStart]
      state.variations = state.variations.filter((_: {}, index: number) => index !== dragStart)
      state.variations.splice(dragEnd, 0, decliInsert)
      setState({ ...state, showSubmit: true })
    }
  }

  const openVariationDrawer = (_: string | undefined, index: number): void => {
    updateState({ ...state, variationView: true, indexOfVariation: index })
  }

  /**
   *
   * This method switchs between variation and variant view
   * If we have a declined product, we switch for variant view
   * rather than variation when nothing is yet associated
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  const openVariationOrVariantDrawer = (): void => {
    if (!state.isDeclined && state.variations) {
      openVariationDrawer('', state.variations.length)
    } else {
      drawerActionType('addVariation', dispatch)
      updateDrawerState(DrawerState.LOADING, dispatch)
      addHistory({
        //@ts-ignore
        component: MenuVariant,
        params: { productID: state.productID },
      })
    }
  }

  const handleResultVariant = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      updateState({ ...newState, isGenerate: true, isGenerating: false })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    } else {
      updateState({ ...state, isDeclined: true, isGenerate: true, isGenerating: false })
    }
  }

  const handleResultProduct = (res: any) => {
    //@ts-ignore
    if (!res.errors || res.errors.length === 0) {
      if (!state.isDeclined && state.variations && state.variations.length > 0) {
        updateState({ ...state, isGenerate: true, isGenerating: true })
        // @ts-ignore
        callVariantGeneration(state, dispatch).then(handleResultVariant)
      } else {
        drawerResetHistory(dispatch)
        close()
      }
    } else {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    }
  }

  const submitProduct = () => {
    // @ts-ignore
    const { customFields, ...errors } = state.errors
    if (!findErrorsInState(errors) && !customFields?.find((cf: any) => cf && cf.value)) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      if (state.productID && !state.isDuplicate) {
        if (state.categoryToCreate) {
          callService<{ input: CreateCategoryInput }>(
            { input: { name: state.categoryToCreate, catalogID: getCompanyID() } },
            createCategory,
            'createCategory'
          ).then((res) => {
            state.dirties.splice(
              state.dirties.findIndex((dirty) => dirty === 'categoryToCreate'),
              1,
              'categoryID'
            )
            state.categoryID = res.data.id
            callProductUpdate(state, company.currencyDecimals, allProducts).then(handleResultProduct)
          })
        } else if (state.categoryID) {
          callProductUpdate(state, company.currencyDecimals, allProducts).then(handleResultProduct)
        }
      } else {
        updateDrawerState(DrawerState.SEND_FORM, dispatch)
        if (state.categoryToCreate) {
          callService<{ input: CreateCategoryInput }>(
            { input: { name: state.categoryToCreate, catalogID: getCompanyID() } },
            createCategory,
            'createCategory'
          ).then((res) => {
            state.dirties.splice(
              state.dirties.findIndex((dirty) => dirty === 'categoryToCreate'),
              1,
              'categoryID'
            )
            state.categoryID = res.data.id
            callProductCreation(state, company.currencyDecimals).then(handleResultProduct)
          })
        } else if (state.categoryID) {
          callProductCreation(state, company.currencyDecimals).then(handleResultProduct)
        }
      }
    } else setState({ ...state, showSubmit: false })
  }

  const handleSubmit = () => {
    setState(handleBlur(state, 'name'))
    setState(handleBlur(state, 'taxID'))
    if (!state.categoryID && !state.categoryToCreate) setState(handleBlur(state, 'categoryID'))
    submitProduct()
  }

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={styles.containerForm}
          style={{
            height: state.productID && !state.variationView ? getDrawerHeight() : getDrawerHeightNoTab(),
          }}
          data-cy={dataCy}
        >
          <div id="drawer" className={styles.overflow}>
            <Form
              state={state}
              listOptions={listOptions}
              handleChange={handleChange}
              handleBlur={(field: string) => setState(handleBlur(state, field))}
              handleBlurNested={(index) => setState(handleBlurNested(state, 'customFields', index))}
              handleAddOption={handleAddOption}
              handleDeleteOption={handleDeleteOption}
              handleDragEndOption={handleDragEndOption}
              handleDragEnd={handleDragEnd}
              openVariationOrVariantDrawer={openVariationOrVariantDrawer}
              openVariationDrawer={openVariationDrawer}
              handleSubmit={handleSubmit}
              dataCy={dataCy}
            />
            <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Detail
