import { t } from 'i18next'
import { ComponentSize, ComponentStatus } from '../types'

import styles from './Chip.module.css'

const Chip = ({
  status,
  size,
  text,
  dataCy,
}: {
  status: ComponentStatus
  size: ComponentSize
  text: string
  dataCy?: string
}, data?: any) => {
  return (
    status === 'warning' ? <div className={styles.chip_container}>
      {data && data.message === "MIGRATION_CATALOG ended with errors" ? <p>{t('settings.import.modal.migration.ERROR_INFO_INVENTORY')}</p> : null}
      <div
        className={styles.chip}
        style={{
          padding: `0 var(--comp-chip-${size}-padding-right) 0 var(--comp-chip-${size}-padding-left)`,
          height: `var(--comp-chip-size-${size}-height)`,
          border: `var(--comp-chip-border-width) solid var(--comp-chip-color-border-${status})`,
          borderRadius: 'var(--comp-chip-border-radius)',
          color: `var(--comp-chip-color-foreground-${status})`,
          backgroundColor: `var(--comp-chip-color-background-${status})`
        }}
        data-cy={dataCy}
      >
        {text && text.replace(/^0\s/g, '')}
      </div>
    </div> :
      <div
        className={styles.chip}
        style={{
          padding: `0 var(--comp-chip-${size}-padding-right) 0 var(--comp-chip-${size}-padding-left)`,
          height: `var(--comp-chip-size-${size}-height)`,
          border: `var(--comp-chip-border-width) solid var(--comp-chip-color-border-${status})`,
          borderRadius: 'var(--comp-chip-border-radius)',
          color: `var(--comp-chip-color-foreground-${status})`,
          backgroundColor: `var(--comp-chip-color-background-${status})`
        }}
        data-cy={dataCy}
      >
        {text && text.replace(/^0\s/g, '')}
      </div>
  )
}

export default Chip
