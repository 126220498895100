const ShoppingCart = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.15747 18C7.05747 18 6.16747 18.9 6.16747 20C6.16747 21.1 7.05747 22 8.15747 22C9.25747 22 10.1575 21.1 10.1575 20C10.1575 18.9 9.25747 18 8.15747 18ZM2.15747 3C2.15747 3.55 2.60747 4 3.15747 4H4.15747L7.75747 11.59L6.40747 14.03C5.67747 15.37 6.63747 17 8.15747 17H19.1575C19.7075 17 20.1575 16.55 20.1575 16C20.1575 15.45 19.7075 15 19.1575 15H8.15747L9.25747 13H16.7075C17.4575 13 18.1175 12.59 18.4575 11.97L22.0375 5.48C22.4075 4.82 21.9275 4 21.1675 4H6.36747L5.69747 2.57C5.53747 2.22 5.17747 2 4.79747 2H3.15747C2.60747 2 2.15747 2.45 2.15747 3ZM18.1575 18C17.0575 18 16.1675 18.9 16.1675 20C16.1675 21.1 17.0575 22 18.1575 22C19.2575 22 20.1575 21.1 20.1575 20C20.1575 18.9 19.2575 18 18.1575 18Z"
      fill={color}
    />
  </svg>
)

export default ShoppingCart
