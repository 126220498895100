import { useState } from 'react'

import { TypeFormError } from '../../format/errors/types'

import { Error, EyeShow } from '../../assets/icons'

export const getIcon = ({ icon, message, onClick }: { icon: any; message?: string; onClick?: () => void }) => [
  { icon, message, onClick },
]

export const getErrorIcon = (icon: any, error?: TypeFormError) => {
  return error && error.value ? [{ icon }] : []
}

/// //////////////////////////////

export const DisplayIcon = ({
  icon,
  className,
  hoverMessage,
  onClick,
  dataCy,
}: {
  icon: JSX.Element
  className?: string
  hoverMessage?: string
  onClick?: () => void
  dataCy?: string
}): JSX.Element => {
  const [state, setState] = useState(false)

  return (
    <>
      <div
        onMouseEnter={() => {
          hoverMessage && setState(true)
        }}
        onMouseLeave={() => hoverMessage && setState(false)}
        onClick={onClick}
        data-cy={dataCy}
      >
        {icon}
      </div>
      {state && <div>{hoverMessage}</div>}
    </>
  )
}

export const getAlertIcon = (field: TypeFormError): Array<JSX.Element> => {
  if (field && field.value) {
    return [
      <DisplayIcon
        icon={<Error color="var(--sys-color-content-danger)" />}
        className="errorMessage"
        hoverMessage={field.message}
      />,
    ]
  }
  return []
}

export const getPasswordIcons = (color: string, onClick: () => void, error?: TypeFormError): Array<JSX.Element> => [
  <DisplayIcon icon={<EyeShow color={color} />} onClick={onClick} />,
  ...getAlertIcon(error!),
]
