import { CurrencyEditCell as CurrencyCell } from '../../components/list/cell';
import ListItemVariant from '../../components/newComponents/listItem/variant';
import { displayNumber } from '../../utils/number';
import { TypeColumnConfiguration } from '../types';
import LoadMore from '../../components/newComponents/listItem/loadMore';

const STAT_PRODUCT = 'statProduct';

const statProductsCols: TypeColumnConfiguration[] = [
  {
    id: 'statProductsCols',
    type: STAT_PRODUCT,
    cols: [
      {
        id: 'name',
        title: 'NAME',
        type: 'element',
        fieldType: 'string',
        active: true,
        sortable: true,
        function: ({ value, onClick, onIconClick }: any) =>
          value.variantsCount > 0
            ? ListItemVariant({ name: value.name, length: value.variantsCount, onClick, onIconClick })
            : value.name,
      },
      {
        id: 'icon',
        title: '',
        type: 'element',
        fieldType: 'string',
        active: true,
        sortable: false,
        notCustomable: true,
        function: ({ value, onClick }: any) => (value.variantsCount > 0 ? LoadMore({ onClick }) : undefined),
      },
      {
        id: 'totalQuantity',
        title: 'QUANTITY',
        type: 'function',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: value => (value.totalQuantity !== null ? displayNumber(value.totalQuantity) : ''),
      },
      {
        id: 'total',
        title: 'TOTAL',
        field: 'total',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'totalTaxFree',
        title: 'TOTAL_TAX_FREE',
        field: 'totalTaxFree',
        fieldType: 'number',
        type: 'element',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'totalTax',
        title: 'TOTAL_TAX',
        field: 'totalTax',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'margin',
        title: 'MARGIN',
        field: 'margin',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
    ],
    creatable: false,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: true,
    customable: false,
    checkboxes: false,
  },
];

export default statProductsCols;
