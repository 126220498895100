import { TypeColumnConfiguration, TagAssociations } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { DropDownCell, LinkToRequesterCell, TagCell } from '../../components/list/cell'
import { callService, getElementAssociations } from '../../services'
import { DeleteTagInput } from '../../API'
import { deleteTag } from '../../graphql/custom/mutations'
import { updateModal } from '../../actions/modal'
import { displayFullName } from '../../utils/string'
import TagModal from '../../views/setting/tag/modal'
import { TypeComparator, TypeFilter } from '../../components/requester/types'
import { MoreHoriz } from '../../assets/icons'
import IconTouch from '../../components/newComponents/icons/iconTouch'

const TAG = 'tag'

const tagsCols: TypeColumnConfiguration[] = [
  {
    id: 'listTags',
    type: TAG,
    cols: [
      {
        id: 'name',
        title: 'NAME',
        type: 'element',
        active: true,
        function: ({ value, currency, decimals }) =>
          TagCell({
            pTags: [{ tag: { name: value.name } }],
            isOption: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'createdByID',
        title: 'CREATED_BY',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, users }) => {
          const user = users.items.find((user: { id: string }) => user.id === value.createdByID)
          return user ? displayFullName(user.firstName, user.lastName) : ''
        },
      },
      {
        id: 'createdAt',
        title: 'CREATED_AT',
        type: 'function',
        field: 'createdAt',
        fieldType: 'date',
        active: true,
        function: (date) => displayDateShortMonthWithHours(date),
      },
      {
        id: 'products',
        title: 'ASSOCIATION',
        type: 'element',
        fieldType: 'number',
        active: true,
        function: ({ value, tagsAssociations, navigate, dispatch }) => {
          return LinkToRequesterCell({
            filterID: 'TAG_NAME',
            field: 'name',
            path: ['tags'],
            filters: [
              {
                type: TypeFilter.STRING,
                comparator: TypeComparator.EQ,
              },
            ],
            value: value.name,
            count: value.productCount,
            navigate,
            dispatch,
          })
        },
      },
      {
        id: 'more',
        title: '',
        type: 'element',
        active: true,
        function: ({ value, tagsAssociations, t, index, dispatch }: any) =>
          DropDownCell({
            fieldID: 'more',
            index,
            Element: IconTouch({
              Icon: MoreHoriz,
              color: 'var(--sys-color-content-secondary)',
            }),
            items: [
              {
                id: 'edit',
                value: t('settings.tag.EDIT'),
                onClick: () =>
                  updateModal(
                    dispatch,
                    true,
                    () => TagModal({ id: value.id }),
                    () => {}
                  ),
              },
              {
                id: 'delete',
                value: t('settings.tag.DELETE'),
                onClick: () => {
                  if (value.productCount === 0) {
                    callService<{ input: DeleteTagInput }>(
                      {
                        input: {
                          id: value.id,
                        },
                      },
                      deleteTag,
                      'deleteTag'
                    )
                  } else
                    updateModal(
                      dispatch,
                      true,
                      () => TagModal({ id: value.id, isDelete: true }),
                      () => {}
                    )
                },
              },
            ],
          }),
      },
    ],
    creatable: false,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
  },
]

export default tagsCols
