const ArrowForward = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.37012 13H16.5401L11.6601 17.88C11.2701 18.27 11.2701 18.91 11.6601 19.3C12.0501 19.69 12.6801 19.69 13.0701 19.3L19.6601 12.71C20.0501 12.32 20.0501 11.69 19.6601 11.3L13.0801 4.7C12.6901 4.31 12.0601 4.31 11.6701 4.7C11.2801 5.09 11.2801 5.72 11.6701 6.11L16.5401 11H5.37012C4.82012 11 4.37012 11.45 4.37012 12C4.37012 12.55 4.82012 13 5.37012 13Z"
      fill={color}
    />
  </svg>
)

export default ArrowForward
