import { SaleType } from '../../../API'
import { currencyTransformer, dateEqualTransformer } from '../../../components/requester/query'
import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../../components/requester/types'
import { saleStateToStr } from '../../../utils/typeToType'

const saleFieldsRequester = (t: (field: string) => string): Array<TypeRequester> => [
  {
    type: TypePath.PLAIN,
    filterID: 'NUMBER',
    field: 'number',
    path: [],
    filters: [
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'SALE_NAME',
    field: 'name',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'STATE',
    field: 'state',
    path: [],
    filters: [
      {
        type: TypeFilter.LIST,
        list: Object.values(SaleType).map((value) => ({
          id: value,
          value: saleStateToStr(value, t),
        })),
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.LIST,
        list: Object.values(SaleType).map((value) => ({
          id: value,
          value: saleStateToStr(value, t),
        })),
        comparator: TypeComparator.NE,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'TOTAL_DISCOUNT',
    field: 'totalDiscount',
    transformer: currencyTransformer,
    path: [],
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'TOTAL',
    field: 'total',
    transformer: currencyTransformer,
    path: [],
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'TOTAL_TAX_FREE',
    field: 'totalTaxFree',
    transformer: currencyTransformer,
    path: [],
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'TOTAL_TAX',
    field: 'totalTax',
    transformer: currencyTransformer,
    path: [],
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'SHOP_NAME',
    field: 'name',
    path: ['shop'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'SOURCE_NAME',
    field: 'sourceName',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'OPENED_AT',
    field: 'openedAt',
    path: [],
    transformer: dateEqualTransformer,
    filters: [
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'CLOSED_AT',
    field: 'closedAt',
    path: [],
    transformer: dateEqualTransformer,
    filters: [
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.LT,
      },
    ],
  },
]

export default saleFieldsRequester
