const ShutDown = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.163 3.00293C11.613 3.00293 11.163 3.45293 11.163 4.00293V12.0029C11.163 12.5529 11.613 13.0029 12.163 13.0029C12.713 13.0029 13.163 12.5529 13.163 12.0029V4.00293C13.163 3.45293 12.713 3.00293 12.163 3.00293ZM17.303 5.86293C16.913 6.25293 16.923 6.86293 17.293 7.25293C18.423 8.45293 19.123 10.0529 19.163 11.8229C19.253 15.6529 16.083 18.9529 12.253 18.9929C8.34302 19.0529 5.16302 15.9029 5.16302 12.0029C5.16302 10.1629 5.87302 8.49293 7.03302 7.24293C7.40302 6.85293 7.40302 6.24293 7.02302 5.86293C6.62302 5.46293 5.97302 5.47293 5.59302 5.88293C4.14302 7.42293 3.23302 9.47293 3.16302 11.7429C3.02302 16.6229 6.99302 20.8429 11.873 20.9929C16.973 21.1529 21.163 17.0629 21.163 11.9929C21.163 9.62293 20.243 7.48293 18.743 5.88293C18.363 5.47293 17.703 5.46293 17.303 5.86293Z"
      fill={color}
    />
  </svg>
)

export default ShutDown
