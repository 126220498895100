import { useTranslation } from 'react-i18next'

import { objectType } from '../../store/types'

import { Cactus } from '../../assets/illustrations'
import styles from './List.module.css'
import generateDataCy from '../../utils/cypress'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import toggleMenu from '../../actions/menu'
import { updateModal } from '../../actions/modal'
import CreateModal from '../newComponents/modal/inventoryMovement'

/**
 *
 * This element displays the empty list view
 *
 * @usedIn - List
 *
 * @param type - Entity type
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const EmptyList = ({ type }: { type: objectType }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cyContext = generateDataCy({ scope: 'list', value: 'empty' })

  const getEmptySubText = () => {
    if (type === objectType.CASHBOOK || type === objectType.PAYMENT || type === objectType.SALE) {
      return t('list.empty.ACTIVITY')
    }
    return t('list.empty.CATALOG')
  }

  const navigateProduct = () => {
    navigate("/catalog/products")
    toggleMenu(dispatch, { subSection: "products" })
  }

  const openInventoryDrawer = () => {
    updateModal(
      dispatch,
      true,
      () => <CreateModal navigate={navigate} />,
      () => { }
    )
  }


  if (type === objectType.INVENTORY_MOVEMENT) {
    return (
      <div className={styles.containerEmpty} data-cy={cyContext}>
        <div className={styles.icon}>📦</div>
        <div className={styles.titleEmpty}>{t('list.empty.inventory.TITLE')}</div>
        <div className={styles.subTextEmptyInventory}>{t('list.empty.inventory.TEXT_ONE')}</div>
        <div className={styles.subTextEmptyInventory}>{t('list.empty.inventory.TEXT_TWO')}
          <br />
          <span className={styles.subTextEmptyInventoryLink} onClick={() => openInventoryDrawer()}>{t('list.empty.inventory.MOVEMENT_NAVIGATION')}</span>
        </div>
        <div className={styles.subTextEmptyInventory}>Depuis <span onClick={() => navigateProduct()} className={styles.subTextEmptyInventoryLink}>Produits</span> en sélectionnant ceux qui vous intéressent <br />
          puis &quot;Faire un mouvement de stock&quot;</div>
      </div>
    )
  }

  return (
    <div className={styles.containerEmpty} data-cy={cyContext}>
      <Cactus height="250" width="250" />
      <div className={styles.textEmpty}>{t('list.empty.TITLE')}</div>
      <div className={styles.subTextEmpty}>{getEmptySubText()}</div>
    </div>
  )
}

export default EmptyList
