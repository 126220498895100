import { getMessage } from '..'
import { ValidationFunction } from '../types'

export const validPercent: (arg: any) => ValidationFunction = () => (val: any) =>
  val ? (val >= 0 && val <= 100 ? null : { value: true, message: getMessage('percent') }) : null

export const positive: (arg: any) => ValidationFunction = () => (val: any) =>
  val ? (val >= 0 ? null : { value: true, message: getMessage('positive') }) : null

export const greaterThan: (arg: any) => ValidationFunction = (arg: number) => (val: any) =>
  val ? (val > arg ? null : { value: true, message: getMessage('greaterThan', arg.toString()) }) : val

export const lowerThan: (arg: any) => ValidationFunction = (arg: number) => (val: any) =>
  val ? (val < arg ? null : { value: true, message: getMessage('lowerThan', arg.toString()) }) : val

export const hasMinimumLengthOf: (arg: any) => ValidationFunction = (arg: number) => (val: any) =>
  val
    ? val.toString().length >= arg
      ? null
      : {
          value: true,
          message: getMessage('hasMinimumLengthOf', arg.toString()),
        }
    : val

export const isNotDecimal: (arg: any) => ValidationFunction = () => (val: any) =>
  val
    ? val % 1 === 0
      ? null
      : {
          value: true,
          message: getMessage('decimal'),
        }
    : val

export const validCurrency: () => ValidationFunction = () => (value: string) =>
  value
    ? value.match(/^[. 0-9 ]+$/) && value.split('.').length < 3
      ? null
      : { value: true, message: getMessage('match') }
    : null

export const validNumber: () => ValidationFunction = () => (value: string) =>
  value ? (value.match(/^[ 0-9]+$/) ? null : { value: true, message: getMessage('match') }) : null
