import { objectType } from '../../store/types'
import { updateListElement, deleteListElement } from '../../actions/list'
import { getOption as get } from '../../graphql/custom/queries'
import { getCompanyID } from '../../services/localStorage'
import { getObservable } from '../../services/graphql'
import { onCreateOption, onDeleteOption, onUpdateOption } from '../../graphql/custom/subscriptions'
import { deleteOptionFromProduct, updateOptionsAssociations } from '../../actions/catalog'
import { callService } from '../../services'
import { GetOptionQueryVariables } from '../../API'

const getOption = async (id: string, dispatch: any) => {
  const option = await callService<GetOptionQueryVariables>({ id }, get, 'getOption')

  if (option.data) {
    updateListElement({ element: option.data, dispatch, type: objectType.OPTION })
  }
  return option
}

let onCreateOptionSubscription: any
let onDeleteOptionSubscription: any
let onUpdateOptionSubscription: any

const subscribeOptions = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreateOptionSubscription)
    onCreateOptionSubscription = getObservable(onCreateOption, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateOption) {
          updateListElement({ element: eventData.data.onCreateOption, dispatch, type: objectType.OPTION })
        }
      },
    })
  if (!onDeleteOptionSubscription)
    onDeleteOptionSubscription = getObservable(onDeleteOption, input).subscribe({
      next: (eventData: any) => {
        console.log(eventData.data.onDeleteOption, 'eventData.data.onDeleteOption')
        updateOptionsAssociations({ optionID: eventData.data.onDeleteOption.id, dispatch }, dispatch)
        deleteOptionFromProduct({ optionID: eventData.data.onDeleteOption.id }, dispatch)
        return deleteListElement({
          element: eventData.data.onDeleteOption,
          dispatch,
          type: objectType.OPTION,
        })
      },
    })
  if (!onUpdateOptionSubscription)
    onUpdateOptionSubscription = getObservable(onUpdateOption, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateOption) {
          console.log(eventData.data.onUpdateOption, 'eventData.data.onUpdateOption')
          updateListElement({ element: eventData.data.onUpdateOption, dispatch, type: objectType.OPTION })
          updateOptionsAssociations({ optionID: eventData.data.onUpdateOption.id, dispatch }, dispatch)
        }
      },
    })
}

const unSubscribeOptions = () => {
  if (onCreateOptionSubscription) {
    onCreateOptionSubscription.unsubscribe()
    onCreateOptionSubscription = null
  }

  if (onDeleteOptionSubscription) {
    onDeleteOptionSubscription.unsubscribe()
    onDeleteOptionSubscription = null
  }

  if (onUpdateOptionSubscription) {
    onUpdateOptionSubscription.unsubscribe()
    onUpdateOptionSubscription = null
  }
}

export { subscribeOptions, unSubscribeOptions, getOption }
