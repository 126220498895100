import { TypeTab } from '../../../types/drawer'
import Layer from '../layer'
import { ComponentSize, ComponentState } from '../types'

import styles from './Tab.module.css'

type Props = {
  tab: TypeTab
  onClick: (tab: TypeTab) => void
  size: ComponentSize
  state?: ComponentState
  dataCy: string
}

/**
 *
 * This element displays a tab
 *
 * @param tab - Actual tab
 * @param onClick - A CTA when we click on a tab
 * @param size - Tab's size
 * @param state - Tab's state
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Tab = ({ tab, onClick, size, state, dataCy }: Props): JSX.Element => {
  return (
    <div className={styles.name} onClick={() => onClick(tab)} data-cy={dataCy}>
      <Layer
        isSelected={tab.state === ComponentState.ACTIVE}
        isDisabled={state === ComponentState.DISABLED}
        style={{
          height: `var(--comp-tab-index-item-size-${size})`,
          borderRadius: `var(--comp-tab-index-item-border-radius)`,
          padding: 'var(--sys-size-4)',
          color:
            tab.state === ComponentState.ACTIVE
              ? 'var(--sys-color-content-interactive)'
              : 'var(--sys-color-content-secondary)',
        }}
      >
        {tab.name}
      </Layer>
    </div>
  )
}

export default Tab
