import styles from './ListItem.module.css'

type Props = {
  children?: any
  dataCy?: string
}

/**
 *
 * This element displays an item
 *
 * @param children - A component
 * @param dataCy - An unique id
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ListItemAvatar = ({ children, dataCy }: Props): JSX.Element => {
  return (
    <div className={styles.listItemIcon} data-cy={dataCy}>
      {children}
    </div>
  )
}

export default ListItemAvatar
