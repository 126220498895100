import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { closeAndResetModal } from '../../../actions/modal'
import Button from '../../../components/newComponents/button'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import { forgotPassword } from '../../../services/auth'

import styles from '../../../components/newComponents/modal/Modal.module.css'

const UserAccountModal = ({ email }: { email: string }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSubmit = () => {
    if (email) forgotPassword(email).then(() => closeAndResetModal(dispatch))
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalTitle}>{t('account.userAccount.modal.EDIT')}</div>
      <div className={styles.modalContent}>
        <div className={styles.modalInfo}>{t('account.userAccount.modal.SUBTITLE')}</div>
        <div className={styles.modalImportantInfo}>{email}</div>
      </div>
      <div className={styles.modalButtons}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          state={ComponentState.DEFAULT}
          status={ComponentStatus.DEFAULT}
          onClick={() => closeAndResetModal(dispatch)}
        />
        <Button
          title={t('button.SEND')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={ComponentState.DEFAULT}
          status={ComponentStatus.DEFAULT}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

export default UserAccountModal
