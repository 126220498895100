/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from "react";
import styles from "../../../../components/oldComponents/drawer/Drawer.module.css";
import { getValue, sortByDate, translationMap } from "../../../../utils/fusion";
import { Check } from "../../../../assets/icons";
import Circle from "../../../../assets/icons/circle";
import CircleCheck from "../../../../assets/icons/circleCheck";
import generateDataCy from '../../../../utils/cypress'
import countries from '../../../../assets/countries.json'


const FusionField = ({ fieldKey, selected, handleRadioChange, dataCy }: { fieldKey: string, selected: Array<any>, handleRadioChange: (fieldKey: string, value: string) => void , dataCy?: string}) => {

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const onValueSelected = (fieldKey: string, value: string) => {
    setSelectedValue(value);
    handleRadioChange(fieldKey, value);
  };

  //@ts-ignore
  const sortedSelected = sortByDate(selected)
  //@ts-ignore
  let uniqueValues = [...new Set(sortedSelected.map((item: any) => getValue(item, fieldKey, countries)))];

  const allValuesToBeHidden = sortedSelected.every((item: any) => getValue(item, fieldKey, countries) === '(Aucun)');

  if (allValuesToBeHidden) {
    return null;
  }

  if (uniqueValues.includes('(Aucun)') && uniqueValues.length > 1) {
    uniqueValues = uniqueValues.filter(val => val !== '(Aucun)');
  }

  if (uniqueValues.length === 1) {
    return (
      <div className={styles.fieldContainer}>
        <div className={styles.titleHeader}>
          <hr />
          <span className={styles.titleHeaderText}>
            {translationMap(fieldKey)}
          </span>
          <hr />
        </div>
        <div className={styles.valueContainerNoHover}>
        <span className={styles.valueText} data-cy={generateDataCy({scope: dataCy || "", value: fieldKey})}>{uniqueValues[0]}</span>
        {uniqueValues[0] !== '(Aucun)' && <span className={styles.valueRadio} data-cy={generateDataCy({scope: dataCy || "", value: fieldKey + '-checked'})}><Check color="var(--sys-color-content-success)" /></span>}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.fieldContainer}>
      <div className={styles.titleHeader}>
        <hr /><span className={styles.titleHeaderText}>{translationMap(fieldKey)}</span><hr />
      </div>
      {uniqueValues.map((value, index) => (
        <div key={index} className={styles.valueContainer} data-cy={generateDataCy({scope: dataCy || "", value: fieldKey + '-'+index.toString()})} onClick={() => onValueSelected(fieldKey, value)} >
          <span className={styles.valueText} style={{ color: value === selectedValue ? 'var(--sys-color-content-interactive)' : 'inherit' }}>{value}</span>
          {value === selectedValue ? 
          <div className={styles.valueRadio} data-cy={generateDataCy({scope: dataCy || "", value: fieldKey + '-'+index.toString() + '-' + "checkbox-checked"})}>
            <CircleCheck color="var(--sys-color-content-interactive)" />
          </div> 
          : <div className={styles.valueRadio} data-cy={generateDataCy({scope: dataCy || "", value: fieldKey + '-'+index.toString() + '-' + "checkbox"})}>
            <Circle color="black" />
          </div>}
        </div>
      ))}
    </div>
  );

}

export default FusionField