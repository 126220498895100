import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import SplitView from './splitView'
import { ComponentSize, ComponentType } from '../../components/newComponents/types'
import Button from '../../components/newComponents/button'

import { PostInvitation as PostInvitationIllustration } from '../../assets/illustrations'
import styles from './Connection.module.css'

const Form = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleSubmit = () => navigate('/catalog/products')

  return (
    <div className={styles.formPostSignUp}>
      <div className={styles.welcome}>{t('connection.postInvitation.WELCOME')} !</div>
      <div>{t('connection.postInvitation.DASHBOARD')}</div>
      <Button
        title={t('connection.postInvitation.GO_TO_DASHBOARD')}
        size={ComponentSize.MEDIUM}
        type={ComponentType.PRIMARY}
        onClick={handleSubmit}
      />
    </div>
  )
}

const PostInvitation = () => <SplitView left={<Form />} right={<PostInvitationIllustration />} />

export default PostInvitation
