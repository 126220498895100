import { getMessage } from '..'
import { ValidationFunction } from '../types'

export const isPresent: () => ValidationFunction = () => (value: any) =>
  value || value === 0
    ? value.toString().trim()
      ? null
      : { value: true, message: getMessage('present') }
    : { value: true, message: getMessage('present') }

export default isPresent
