import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { createPaymentMethod, updatePaymentMethod } from '../../../graphql/custom/mutations'
import { closeAndResetModal } from '../../../actions/modal'
import { name as nameValidator, type as typeValidator } from '../../../format/errors/filters/setting/paymentMethod'
import UniqueDropDown from '../../../components/newComponents/dropDown/unique'
import Input from '../../../components/newComponents/input'
import { ComponentErrorText, ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { getCompanyID } from '../../../services/localStorage'
import generateDataCy from '../../../utils/cypress'
import Button from '../../../components/newComponents/button'
import { handleBlur, handleChange } from '../../../format/form'
import { DataWithErrors } from '../../../services/types'
import {
  CreatePaymentMethodInput,
  GetStripeConnectionStatusQueryVariables,
  PaymentMethodType,
  UpdatePaymentMethodInput,
} from '../../../API'
import { callService } from '../../../services'
import ColorPicker from '../../../components/newComponents/colorPicker'
import { TypePaymentMethod } from '../../../types/setting/paymentMethod'
import { getPaymentMethod } from '../../../getters/setting/paymentmethod'
import { paymentMethodToStr } from '../../../utils/typeToType'
import Chip from '../../../components/newComponents/chip'
import IconPicker from '../../../components/newComponents/iconPicker'
import { enumToStr, getRandomColor } from '../../../utils/color'
import Switch from '../../../components/newComponents/switch'
import { getStripeConnectionStatus } from '../../../graphql/queries'
import AttentionBox from '../../../components/newComponents/attentionBox'

import { KeyboardArrowDown, Wallet, Warning } from '../../../assets/icons'
import styles from '../../../components/newComponents/modal/Modal.module.css'

const PaymentMethodModal = ({ id }: { id?: string }) => {
  const { t } = useTranslation()
  const initialState: TypePaymentMethod = {
    name: '',
    selected: 'color',
    color: getRandomColor(),
    enabled: true,
    opensCashDrawer: false,
    showSubmit: false,
    validators: {
      name: nameValidator,
      type: typeValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'settings', value: 'paymentMethod' })

  const getStripeConnection = async () => {
    const res = await callService<GetStripeConnectionStatusQueryVariables>(
      {
        companyID: getCompanyID(),
      },
      getStripeConnectionStatus,
      'getStripeConnectionStatus'
    )
    if (!res.errors && res.data) return res.data ?? undefined
  }

  const setPaymentMethodInState = async (data: DataWithErrors) => {
    if (data.data) {
      const paymentMethod = data.data
      setState({
        ...state,
        id: paymentMethod.id,
        color: paymentMethod.color,
        name: paymentMethod.name,
        type: paymentMethod.type,
        email: paymentMethod.type === PaymentMethodType.STRIPE ? await getStripeConnection() : undefined,
        enabled: paymentMethod.enabled,
        opensCashDrawer: paymentMethod.opensCashDrawer,
      })
    }
  }

  useEffect(() => {
    if (id) {
      getPaymentMethod(id, dispatch).then(setPaymentMethodInState)
    }
      }, [id])

  const handleType = async (event: any) => {
    if (event === PaymentMethodType.STRIPE) {
      setState({ ...state, showSubmit: false })
      state.email = await getStripeConnection()
    }
    setState(handleChange(state, 'type')(event))
  }

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
    } else {
      closeAndResetModal(dispatch)
    }
  }

  const handleSubmit = () => {
    state.showSubmit = false
    setState(handleBlur(state, 'name'))
    setState(handleBlur(state, 'type'))

    if (!findErrorsInState(state.errors) && state.type && state.name) {
      id
        ? callService<{ input: UpdatePaymentMethodInput }>(
            {
              input: {
                id: state.id!,
                color: state.color,
                name: state.name,
                enabled: state.enabled,
                opensCashDrawer: state.opensCashDrawer,
              },
            },
            updatePaymentMethod,
            'updatePaymentMethod'
          ).then(handleResult)
        : callService<{ input: CreatePaymentMethodInput }>(
            {
              input: {
                companyID: getCompanyID(),
                color: state.color,
                name: state.name,
                type: state.type,
                enabled: state.enabled,
                opensCashDrawer: state.opensCashDrawer,
              },
            },
            createPaymentMethod,
            'createPaymentMethod'
          ).then(handleResult)
    }
  }

  const errorText = () => {
    if (state.errors?.name?.value) {
      return state.errors.name.message
    }

    if (state.errors.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <div
      className={styles.modal}
      data-cy={generateDataCy({
        scope: cyContext,
        value: id ? 'update' : 'create',
      })}
    >
      <div className={styles.modalTitle}>
        {id ? t('settings.paymentMethod.modal.EDIT') : t('settings.paymentMethod.modal.CREATE')}
      </div>
      <div className={styles.modalContent}>
        <div className={styles.modalFlex}>
          <Input
            label={t('settings.paymentMethod.modal.NAME')}
            value={state.name}
            onChange={(event) => setState(handleChange(state, 'name')(event))}
            onBlur={() => setState(handleBlur(state, 'name'))}
            onSubmit={handleSubmit}
            style={{
              state:
                id && (state.type === PaymentMethodType.CASH || state.type === PaymentMethodType.CREDIT_CARD)
                  ? ComponentState.DISABLED
                  : ComponentState.DEFAULT,
              status: state.errors?.name?.value || state.errors.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={errorText()}
            dataCy={generateDataCy({ scope: cyContext, value: 'name' })}
          />
          <ColorPicker
            title={t('button.EDIT_COLOR')}
            Element={<IconPicker Icon={Wallet} color={enumToStr(state.color)} size={ComponentSize.SMALL} />}
            color={state.color}
            selected={state.selected}
            onSelect={(event) => setState(handleChange(state, 'color')(event))}
            isIconText={false}
            isImage={false}
          />
        </div>
        <div style={{ width: '50%' }}>
          <UniqueDropDown
            parentID={'select'}
            popUpID={'selectPopUp'}
            Element={
              <Input
                placeholder={t('settings.paymentMethod.modal.TYPE')}
                value={state.type ? paymentMethodToStr(state.type, t) : ''}
                style={{
                  trailIcons: [{ icon: KeyboardArrowDown }],
                  state: id ? ComponentState.DISABLED : ComponentState.DEFAULT,
                  status: state.errors?.type?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                  readOnly: true,
                }}
                dataCy={generateDataCy({ scope: cyContext, value: 'type' })}
              />
            }
            items={Object.values(PaymentMethodType)
              .filter((method) => method !== PaymentMethodType.CASH && method !== PaymentMethodType.CREDIT_CARD)
              .map((method) => ({
                id: method,
                value: paymentMethodToStr(method, t),
                onClick: handleType,
              }))}
            state={id ? ComponentState.DISABLED : ComponentState.DEFAULT}
            dataCy={generateDataCy({ scope: cyContext, value: 'type' })}
          />
        </div>
        <div>
          {state.type === PaymentMethodType.STRIPE &&
            (state.email ? (
              <div className={styles.modalFlex}>
                <div className={styles.modalHelperText}>
                  {t('settings.paymentMethod.modal.EMAIL', { email: state.email })}
                </div>
                <Chip
                  size={ComponentSize.MEDIUM}
                  text={t('settings.paymentMethod.modal.CONNECTED')}
                  status={ComponentStatus.SUCCESS}
                />
              </div>
            ) : (
              <AttentionBox
                text={t('settings.paymentMethod.modal.CONNECT')}
                LeadIcon={Warning}
                status={ComponentStatus.WARNING}
              />
            ))}
        </div>
        <div className={styles.modalFlex}>
          <Switch
            selected={state.enabled}
            size={ComponentSize.MEDIUM}
            onClick={() => setState(handleChange(state, 'enabled')(!state.enabled))}
          />
          <div className={styles.modalInfo}>{t('settings.paymentMethod.modal.ACTIVE')}</div>
        </div>
        <div className={styles.modalHelperText}>{t('settings.paymentMethod.modal.ACTIVE_INFO')}</div>
      </div>
      <div className={styles.modalContent}>
        <div className={styles.modalSubTitle}>{t('settings.paymentMethod.modal.CONFIGURATION')}</div>
        <div className={styles.modalFlex}>
          <Switch
            selected={state.opensCashDrawer}
            size={ComponentSize.MEDIUM}
            onClick={() => setState(handleChange(state, 'opensCashDrawer')(!state.opensCashDrawer))}
          />
          <div className={styles.modalInfo}>{t('settings.paymentMethod.modal.OPEN_CASH_DRAWER')}</div>
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button
          dataCy={'cancel'}
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
        />
        <Button
          title={t('button.SAVE')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default PaymentMethodModal
