import {
  productGet,
  discount,
  category,
  inventoryMovementGet,
  pack,
  customerGet,
  account,
  productTag,
  productCustomField,
  productOption,
  categoryCustomField,
  variation,
  option,
  packCustomField,
  customerCustomField,
  sellerCode,
  company,
  companySettings,
  customField,
  shop,
  slot,
  tag,
  tax,
  user,
  invitation,
  paymentMethod,
  inventoryProductMovement,
} from './content';

export const createPack = /* GraphQL */ `
  mutation CreatePack($input: CreatePackInput!, $test: Boolean!) {
    createPack(input: $input, test: $test) {
      ${pack}
    }
  }
`;

export const updatePack = /* GraphQL */ `
  mutation UpdatePack($input: UpdatePackInput!) {
    updatePack(input: $input) {
      ${pack}
    }
  }
`;

export const deletePack = /* GraphQL */ `
  mutation DeletePack($input: DeletePackInput!) {
    deletePack(input: $input) {
      ${pack}
    }
  }
`;

export const createPackCustomField = /* GraphQL */ `
  mutation CreatePackCustomField($input: CreatePackCustomFieldInput!) {
    createPackCustomField(input: $input) {
      ${packCustomField}
    }
  }
`;

export const updatePackCustomField = /* GraphQL */ `
  mutation UpdatePackCustomField($input: UpdatePackCustomFieldInput!) {
    updatePackCustomField(input: $input) {
      ${packCustomField}
    }
  }
`;

export const deletePackCustomField = /* GraphQL */ `
  mutation DeletePackCustomField($input: DeletePackCustomFieldInput!) {
    deletePackCustomField(input: $input) {
      ${packCustomField}
    }
  }
`;

export const createInventoryMovement = /* GraphQL */ `
  mutation CreateInventoryMovement($input: CreateInventoryMovementInput!, $test: Boolean!, $updatePrices: Boolean) {
    createInventoryMovement(input: $input, test: $test, updatePrices: $updatePrices) {
      id
      test
      companyID
      updatedAt
      createdAt
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          ${inventoryProductMovement}
        }
        nextToken
      }
      id
    products {
      nextToken
      items {
        createdAt
        hasVariants
        id
        inventoryMovementID
        price
        productID
        quantity
        updatedAt
        variantsSelectedAll
        variantsCountAll
        variantsCount
        details {
          barcode
          buyPrice
          category {
            name
          }
          name
          reference
          sellPrice
        }
        variants {
          nextToken
          items {
            createdAt
            deprecated
            details {
              barcode
              buyPrice
              nameShort
              reference
              sellPrice
            }
            id
            inventoryMovementProductID
            price
            productID
            quantity
            updatedAt
            variantID
          }
        }
        variantsAll {
          nextToken
          items {
            variantID
            productID
            details {
              barcode
              buyPrice
              nameShort
              reference
              sellPrice
            }
            line {
              createdAt
              id
              inventoryMovementProductID
              price
              productID
              quantity
              updatedAt
              variantID
              details {
                barcode
                buyPrice
                nameShort
                reference
                sellPrice
              }
            }
          }
        }
      }
    }
    }
  }
`;
export const updateInventoryMovement = /* GraphQL */ `
  mutation UpdateInventoryMovement($input: UpdateInventoryMovementInput!, $updatePrices: Boolean) {
    updateInventoryMovement(input: $input, updatePrices: $updatePrices) {
     ${inventoryMovementGet}
    }
  }
`;

export const cancelInventoryMovement = /* GraphQL */ `
  mutation CancelInventoryMovement($input: CancelInventoryMovementInput!) {
    cancelInventoryMovement(input: $input) {
      ${inventoryMovementGet}
    }
  }
`;

export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!, $test: Boolean!) {
    createProduct(input: $input, test: $test) {
      ${productGet}
    }
  }
`;

export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ${productGet}
    }
  }
`;

export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      ${productGet}
    }
  }
`;

export const createProductTag = /* GraphQL */ `
  mutation CreateProductTag($input: ProductTagInput!) {
    createProductTag(input: $input) {
      ${productTag}
    }
  }
`;

export const deleteProductTag = /* GraphQL */ `
  mutation DeleteProductTag($input: ProductTagInput!) {
    deleteProductTag(input: $input) {
      ${productTag}
    }
  }
`;

export const createProductCustomField = /* GraphQL */ `
  mutation CreateProductCustomField($input: CreateProductCustomFieldInput!) {
    createProductCustomField(input: $input) {
      ${productCustomField}
    }
  }
`;

export const updateProductCustomField = /* GraphQL */ `
  mutation UpdateProductCustomField($input: UpdateProductCustomFieldInput!) {
    updateProductCustomField(input: $input) {
      ${productCustomField}
    }
  }
`;

export const deleteProductCustomField = /* GraphQL */ `
  mutation DeleteProductCustomField($input: DeleteProductCustomFieldInput!) {
    deleteProductCustomField(input: $input) {
      ${productCustomField}
    }
  }
`;

export const createProductOption = /* GraphQL */ `
  mutation CreateProductOption($input: ProductOptionInput!) {
    createProductOption(input: $input) {
      ${productOption}
    }
  }
`;
export const deleteProductOption = /* GraphQL */ `
  mutation DeleteProductOption($input: ProductOptionInput!) {
    deleteProductOption(input: $input) {
      ${productOption}
    }
  }
`;

export const generateProductVariants = /* GraphQL */ `
  mutation GenerateProductVariants($input: GenerateProductVariantsInput!) {
    generateProductVariants(input: $input) {
      ${variation}
    }
  }
`;

export const updateProductVariationName = /* GraphQL */ `
  mutation UpdateProductVariationName($input: UpdateProductVariationNameInput!) {
    updateProductVariationName(input: $input) {
      ${variation}
    }
  }
`;

export const updateProductVariationOption = /* GraphQL */ `
  mutation UpdateProductVariationOption(
    $input: UpdateProductVariationOptionInput!
  ) {
    updateProductVariationOption(input: $input) {
      ${variation}
    }
  }
`;

export const createProductVariant = /* GraphQL */ `
  mutation CreateProductVariant($input: CreateProductVariantInput!) {
    createProductVariant(input: $input) {
      ${variation}
    }
  }
`;

export const updateProductVariant = /* GraphQL */ `
  mutation UpdateProductVariant($input: UpdateProductVariantInput!) {
    updateProductVariant(input: $input) {
      ${variation}
    }
  }
`;

export const deleteProductVariant = /* GraphQL */ `
  mutation DeleteProductVariant($input: DeleteProductVariantInput!) {
    deleteProductVariant(input: $input) {
      ${variation}
    }
  }
`;

export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount($input: CreateDiscountInput!, $test: Boolean!) {
    createDiscount(input: $input, test: $test) {
      ${discount}
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount($input: UpdateDiscountInput!) {
    updateDiscount(input: $input) {
      ${discount}
    }
  }
`;

export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount($input: DeleteDiscountInput!) {
    deleteDiscount(input: $input) {
      ${discount}
    }
  }
`;

export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!, $test: Boolean!) {
    createCategory(input: $input, test: $test) {
      ${category}
    }
  }
`;

export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      ${category}
    }
  }
`;

export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      ${category}
    }
  }
`;

export const createCategoryCustomField = /* GraphQL */ `
  mutation CreateCategoryCustomField($input: CreateCategoryCustomFieldInput!) {
    createCategoryCustomField(input: $input) {
      ${categoryCustomField}
    }
  }
`;

export const updateCategoryCustomField = /* GraphQL */ `
  mutation UpdateCategoryCustomField($input: UpdateCategoryCustomFieldInput!) {
    updateCategoryCustomField(input: $input) {
      ${categoryCustomField}
    }
  }
`;

export const deleteCategoryCustomField = /* GraphQL */ `
  mutation DeleteCategoryCustomField($input: DeleteCategoryCustomFieldInput!) {
    deleteCategoryCustomField(input: $input) {
      ${categoryCustomField}
    }
  }
`;

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!, $test: Boolean!) {
    createCustomer(input: $input, test: $test) {
     ${customerGet}
    }
  }
`;

export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
     ${customerGet}
    }
  }
`;

export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
     ${customerGet}
    }
  }
`;

export const createCustomerCustomField = /* GraphQL */ `
  mutation CreateCustomerCustomField($input: CreateCustomerCustomFieldInput!) {
    createCustomerCustomField(input: $input) {
      ${customerCustomField}
    }
  }
`;

export const updateCustomerCustomField = /* GraphQL */ `
  mutation UpdateCustomerCustomField($input: UpdateCustomerCustomFieldInput!) {
    updateCustomerCustomField(input: $input) {
      ${customerCustomField}
    }
  }
`;

export const deleteCustomerCustomField = /* GraphQL */ `
  mutation DeleteCustomerCustomField($input: DeleteCustomerCustomFieldInput!) {
    deleteCustomerCustomField(input: $input) {
      ${customerCustomField}
    }
  }
`;

export const createAccount = /* GraphQL */ `
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
     ${account}
    }
  }
`;

export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      ${account}
    }
  }
`;

export const createShop = /* GraphQL */ `
  mutation CreateShop($input: CreateShopInput!) {
    createShop(input: $input) {
      ${shop}
    }
  }
`;

export const updateShop = /* GraphQL */ `
  mutation UpdateShop($input: UpdateShopInput!) {
    updateShop(input: $input) {
      ${shop}
    }
  }
`;

export const createSellerCode = /* GraphQL */ `
  mutation CreateSellerCode($input: CreateSellerCodeInput!) {
    createSellerCode(input: $input) {
      ${sellerCode}
    }
  }
`;

export const updateSellerCode = /* GraphQL */ `
  mutation UpdateSellerCode($input: UpdateSellerCodeInput!) {
    updateSellerCode(input: $input) {
      ${sellerCode}
    }
  }
`;

export const deleteSellerCode = /* GraphQL */ `
  mutation DeleteSellerCode($input: DeleteSellerCodeInput!) {
    deleteSellerCode(input: $input) {
      ${sellerCode}
    }
  }
`;

export const createCompany = /* GraphQL */ `
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      ${company}
    }
  }
`;

export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ${company}
    }
  }
`;

export const createCompanySetting = /* GraphQL */ `
  mutation CreateCompanySetting($input: CreateCompanySettingInput) {
    createCompanySetting(input: $input) {
      ${companySettings}
    }
  }
`;

export const updateCompanySetting = /* GraphQL */ `
  mutation UpdateCompanySetting($input: UpdateCompanySettingInput) {
    updateCompanySetting(input: $input) {
      ${companySettings}
    }
  }
`;

export const createSlot = /* GraphQL */ `
  mutation CreateSlot($input: CreateSlotInput!) {
    createSlot(input: $input) {
      ${slot}
    }
  }
`;

export const updateSlot = /* GraphQL */ `
  mutation UpdateSlot($input: UpdateSlotInput!) {
    updateSlot(input: $input) {
      ${slot}
    }
  }
`;

export const sendSlotEmail = /* GraphQL */ `
  mutation SendSlotEmail($input: SendSlotEmailInput!) {
    sendSlotEmail(input: $input) {
      ${slot}
    }
  }
`;

export const createOption = /* GraphQL */ `
  mutation CreateOption($input: CreateOptionInput!, $test: Boolean!) {
    createOption(input: $input, test: $test) {
      ${option}
    }
  }
`;

export const updateOption = /* GraphQL */ `
  mutation UpdateOption($input: UpdateOptionInput!) {
    updateOption(input: $input) {
      ${option}
    }
  }
`;

export const deleteOption = /* GraphQL */ `
  mutation DeleteOption($input: DeleteOptionInput!) {
    deleteOption(input: $input) {
      ${option}
    }
  }
`;

export const createCustomField = /* GraphQL */ `
  mutation CreateCustomField($input: CreateCustomFieldInput!, $test: Boolean!) {
    createCustomField(input: $input, test: $test) {
      ${customField}
    }
  }
`;

export const updateCustomField = /* GraphQL */ `
  mutation UpdateCustomField($input: UpdateCustomFieldInput!) {
    updateCustomField(input: $input) {
      ${customField}
    }
  }
`;

export const deleteCustomField = /* GraphQL */ `
  mutation DeleteCustomField($input: DeleteCustomFieldInput!) {
    deleteCustomField(input: $input) {
      ${customField}
    }
  }
`;

export const createTag = /* GraphQL */ `
  mutation CreateTag($input: CreateTagInput!, $test: Boolean!) {
    createTag(input: $input, test: $test) {
      ${tag}
    }
  }
`;

export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      ${tag}
    }
  }
`;

export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      ${tag}
    }
  }
`;

export const createTax = /* GraphQL */ `
  mutation CreateTax($input: CreateTaxInput!, $test: Boolean!) {
    createTax(input: $input, test: $test) {
     ${tax}
    }
  }
`;

export const deleteTax = /* GraphQL */ `
  mutation DeleteTax($input: DeleteTaxInput!) {
    deleteTax(input: $input) {
      ${tax}
    }
  }
`;

export const inviteUser = /* GraphQL */ `
  mutation InviteUser($input: CreateAccountInvitationInput!) {
    inviteUser(input: $input) {
      ${invitation}
    }
  }
`;

export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation($input: UpdateAccountInvitationInput!) {
    acceptInvitation(input: $input) {
      ${invitation}
    }
  }
`;

export const rejectInvitation = /* GraphQL */ `
  mutation RejectInvitation($input: UpdateAccountInvitationInput!) {
    rejectInvitation(input: $input) {
      ${invitation}
    }
  }
`;

export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      ${user}
    }
  }
`;

export const removeUser = /* GraphQL */ `
  mutation RemoveUser($input: RemoveUserInput!) {
    removeUser(input: $input) {
      ${user}
    }
  }
`;

export const addUserToShop = /* GraphQL */ `
  mutation AddUserToShop($input: AddUserToShopInput!) {
    addUserToShop(input: $input) {
      ${user}
    }
  }
`;

export const removeUserFromShop = /* GraphQL */ `
  mutation RemoveUserFromShop($input: RemoveUserFromShopInput!) {
    removeUserFromShop(input: $input) {
      ${user}
    }
  }
`;

export const createPaymentMethod = /* GraphQL */ `
  mutation CreatePaymentMethod($input: CreatePaymentMethodInput!, $test: Boolean!) {
    createPaymentMethod(input: $input, test: $test) {
      ${paymentMethod}
    }
  }
`;

export const updatePaymentMethod = /* GraphQL */ `
  mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      ${paymentMethod}
    }
  }
`;
