const KeyBoardArrowDown = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.28647 9.00002L12.1665 12.88L16.0465 9.00002C16.4365 8.61002 17.0665 8.61002 17.4565 9.00002C17.8465 9.39002 17.8465 10.02 17.4565 10.41L12.8665 15C12.4765 15.39 11.8465 15.39 11.4565 15L6.86647 10.41C6.47647 10.02 6.47647 9.39002 6.86647 9.00002C7.25647 8.62002 7.89647 8.61002 8.28647 9.00002Z"
      fill={color}
    />
  </svg>
)

export default KeyBoardArrowDown
