import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { closeAndResetModal } from '../../../../actions/modal'
import Button from '../../../../components/newComponents/button'
import {
  ComponentSize,
  ComponentState,
  ComponentStatus,
  ComponentType,
} from '../../../../components/newComponents/types'
import generateDataCy from '../../../../utils/cypress'
import { getBackgroundJobs, listShopsItemsNextToken } from '../../../../store/selector'
import AttentionBox from '../../../../components/newComponents/attentionBox'
import DisplayIcon2 from '../../../../components/newComponents/icons'
import { email as mailValidator, password as passwordValidator } from '../../../../format/errors/filters/setting/import'
import IconTouch from '../../../../components/newComponents/icons/iconTouch'
import UniqueDropDown from '../../../../components/newComponents/dropDown/unique'
import Input from '../../../../components/newComponents/input'
import { handleBlur, handleChange } from '../../../../format/form'
import { getIcon } from '../../../../components/newComponents/icon'
import { callService } from '../../../../services'
import { BackgroundJobState, GetApiKeyTactillV4QueryVariables, MigrationType } from '../../../../API'
import { startMigration } from '../../../../graphql/mutations'
import { getApiKeyTactillV4, getMigrationPreview } from '../../../../graphql/queries'
import { getCompanyID } from '../../../../services/localStorage'
import Chip from '../../../../components/newComponents/chip'
import { importStateToStr } from '../../../../utils/typeToType'
import { findErrorsInState } from '../../../../format/errors'
import error from './error_img.jpg'
import inventory from './inventory.jpg'

import {
  EyeHide,
  EyeShow,
  KeyboardArrowDown,
  Success,
  Warning,
} from '../../../../assets/icons'
import stylesModal from '../../../../components/newComponents/modal/Modal.module.css'
import styles from './Migration.module.css'

const Comp = ({
  lines,
  padding,
  onClick,
}: {
  lines: Array<any>
  padding?: boolean
  isLoading?: boolean,
  onClick?: (id: string, value: boolean) => void
}) => {
  return (
    <div className={styles.lines}>
      {lines.map((line) => (
        <div
          className={styles.line}
          style={{
            padding: padding ? '0 16px' : '',
          }}
          key={line.id}
        >
          <div className={styles.line_container}>
            {line.Icon && (
              <IconTouch
                Icon={line.Icon}
                state={line.disabled ? ComponentState.DISABLED : ComponentState.DEFAULT}
                color={
                  line.disabled
                    ? 'var(--sys-color-content-disabled)'
                    : line.selected
                      ? 'var(--sys-color-content-interactive)'
                      : 'var(--sys-color-content-secondary)'
                }
                onClick={() => !line.disabled && onClick && onClick(line.id, !line.selected)}
              />
            )}
            <div
              className={styles.title}
              style={{
                color: line.disabled ? 'var(--sys-color-content-disabled)' : 'var(--sys-color-content-primary)',
              }}
            >
              {line.title}
            </div>
            {line.subTitle && line.subTitle}
            {line.info && <div>{line.info}</div>}
          </div>
          {line.end && <div className={styles.end_container}>
            <div className={styles.end}>{line.end}</div>
          </div>}
        </div>
      ))}
    </div>
  )
}

const Migration = ({ updateTitle }: { updateTitle: (title: string) => void }) => {
  const { t } = useTranslation()
  const initialState: any = {
    step: 1,
    lines: [
      {
        id: MigrationType.CATALOG,
        Icon: undefined,
        selected: true,
        title: t('settings.import.modal.import.type.CATALOG'),
        disabled: false,
        subTitle: <div className={styles.loader} />,
      },
      {
        id: MigrationType.INVENTORY,
        Icon: undefined,
        selected: true,
        disabled: false,
        title: t('settings.import.modal.import.type.INVENTORY'),
        subTitle: <div className={styles.loader} />,
      },
      {
        id: MigrationType.CUSTOMER,
        Icon: undefined,
        selected: true,
        disabled: false,
        title: t('settings.import.modal.import.type.CUSTOMER'),
        subTitle: <div className={styles.loader} />,
      },
      {
        id: MigrationType.ACTIVITY,
        Icon: undefined,
        selected: true,
        disabled: false,
        title: t('settings.import.modal.import.type.ACTIVITY'),
        subTitle: <div className={styles.loader} />,
      },
    ],
    shopID: '',
    passwordType: 'password',
    email: '',
    password: '',
    apiKey: '',
    backgroundJobs: [],
    showSubmit: false,
    isLogin: false,
    validators: {
      email: mailValidator,
      password: passwordValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const shops = useSelector(listShopsItemsNextToken)
  const backgroundJobs = useSelector(getBackgroundJobs(state.backgroundJobs))
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'settings', value: 'migration' })

  const handleStatsPreview = (stats: any) => {
    stats.forEach((stat: any) => {
      const variants: any = Object.entries(stat)
        .filter(([key1, _]) => key1 !== MigrationType.INVENTORY && key1 !== MigrationType.CUSTOMER)
        .find(([key, _]) => key === 'PRODUCT_VARIANT')

      const parsedCatalog = Object.entries(stat)
        .filter(
          ([key1, _]) =>
            key1 !== MigrationType.INVENTORY && key1 !== MigrationType.CUSTOMER && key1 !== 'PRODUCT_VARIANT'
        )
        .reduce((acc: any, [key, value]: any, index: number, arr) => {
          acc = acc.concat(
            `${key === 'PRODUCT' && variants ? variants[1].count + value.count : value.count} ${t(
              'settings.import.modal.import.type.' + key
            )}`
          )
          if (index !== arr.length - 1) acc = acc.concat(' - ')
          return acc
        }, '')

      Object.entries(stat).forEach(([key, value]: any) => {
        state.lines = state.lines.map((line: any) => {
          if (line.selected && key === 'PRODUCT') {
            return {
              ...line,
              Icon: undefined,
              disabled: false,
              subTitle: <div className={styles.subTitle}>{parsedCatalog}</div>,
            }
          }
          if (line.selected && line.id === key) {
            return {
              ...line,
              Icon: undefined,
              disabled: false,
              subTitle: (
                <div className={styles.subTitle}>
                  {t(`settings.import.modal.migration.STAT_${key}`, { count: value.count })}
                  {key === 'INVENTORY' && <span> {t(`settings.import.modal.migration.INVENTORY_INFO`)}</span>}
                </div>
              ),
            }
          }
          return line
        })
      })
    })
  }

  const handlePreview = () => {
    setState({ ...handleBlur(state, 'email') })
    setState({ ...handleBlur(state, 'password') })
    if (!findErrorsInState(state.errors)) {
      setState({ ...state, isLogin: true })

      callService<GetApiKeyTactillV4QueryVariables>(
        { email: state.email, password: state.password },
        getApiKeyTactillV4,
        'getApiKeyTactillV4'
      ).then((resKey) => {
        setState({ ...state, isLogin: false, })

        if (resKey.data && !resKey.errors) {
          const types = state.lines
            .filter((line: { selected: boolean }) => line.selected)
            .map((line: { id: string }) => line.id)

          state.step = state.step + 1
          setState({
            ...state,
            // step: state.step + 1,
            lines: state.lines.map((line: any) => ({
              ...line,
              Icon: undefined,
              disabled: false,
              subTitle: <div className={styles.loader} />,
            })),
            showSubmit: false,
          })

          callService<any>(
            { migrationTypes: types, apiKey: resKey.data },
            getMigrationPreview,
            'getMigrationPreview'
          ).then((res) => {
            if (res.data && !res.errors) {
              const stats = res.data.map((data: any) => data ? data : {
                ACTIVITY: {},
              })
              handleStatsPreview(stats)
              setState({
                ...state,
                showSubmit: true,
                apiKey: resKey.data,
              })
            }
          })
        } else
          setState({
            ...state,
            loginError: { value: true, message: t('settings.import.modal.migration.LOGIN_INCORRECT') },
            showSubmit: false,
          })
      })
    }
  }

  const handleNext = async () => {
    if (state.step === 1) {
      handlePreview()
    }
    if (state.step === 3) {
      const types = state.lines.map((line: { id: string }) => line.id)
      callService<any>(
        { input: { types, apiKey: state.apiKey, companyID: getCompanyID(), shopID: state.shopID } },
        startMigration,
        'startMigration'
      ).then((res) => {
        if (res.data && !res.errors)
          setState({
            ...state,
            lines: state.lines.map((line: any) => ({
              ...line,
              subTitle: '',
              end: t('settings.import.WAITING'),
            })),
            backgroundJobs: res.data.items.map((item: { id: string }) => item.id),
            step: state.step + 1,
            showSubmit: false,
          })
      })
    }
    else if (state.step === 5 || state.step === 6) closeAndResetModal(dispatch)
    else if (state.step === 4) setState({ ...state, step: state.step + 1, showSubmit: true })
    else setState({ ...state, step: state.step + 1, showSubmit: false })
  }

  useEffect(() => {
    if (backgroundJobs.length) {
      backgroundJobs.forEach((backgroundJob) => {
        // ACTIVITY
        if (backgroundJob.type.split('_')[1] === MigrationType.ACTIVITY) {
          const line = state.lines.find((line: any) => line.id === backgroundJob.type.split('_')[1])
          line.state = backgroundJob.reportUrl ? BackgroundJobState.ERROR : backgroundJob.state
          line.end = Chip(importStateToStr(backgroundJob.state, t, backgroundJob.reportUrl), backgroundJob)
        }
        // Catalog
        if (backgroundJob.type.split('_')[1] === MigrationType.CATALOG) {
          // Sum up each element of catalog
          const parsedCatalog = Object.entries(backgroundJob.details)
            .filter(([_, value]) => value)
            .reduce(
              (acc: { successCount: 0; errorCount: 0; total: 0 }, [_, value]: any) => ({
                successCount: acc.successCount + value.successCount,
                errorCount: acc.errorCount + value.errorCount,
                total: acc.total + value.total,
              }),
              { successCount: 0, errorCount: 0, total: 0 }
            )

          // Then calculate the progression
          const progression = ((parsedCatalog.successCount + parsedCatalog.errorCount) * 100) / parsedCatalog.total
          // Can not be anywyhere else since my array is fixed
          state.lines[0].state = backgroundJob.reportUrl ? BackgroundJobState.ERROR : backgroundJob.state
          state.lines[0].end =
            backgroundJob.state === BackgroundJobState.IN_PROGRESS
              ? Number.isNaN(Math.ceil(progression))
                ? Chip(importStateToStr(BackgroundJobState.STARTING, t, '', 0), backgroundJob)
                : Math.ceil(progression) + '%'
              : Chip(importStateToStr(backgroundJob.state, t, backgroundJob.reportUrl, parsedCatalog.errorCount), backgroundJob)
        }
        else if (backgroundJob.type.split('_')[1] === MigrationType.ACTIVITY) {
          const line = state.lines.find((line: any) => line.id === backgroundJob.type.split('_')[1])
          line.state = backgroundJob.reportUrl ? BackgroundJobState.ERROR : backgroundJob.state
          line.end = Chip(importStateToStr(backgroundJob.state, t, backgroundJob.reportUrl), backgroundJob)
        } else {
          // Inventory or Customer
          const line = state.lines.find((line: any) => line.id === backgroundJob.type.split('_')[1])
          line.state = backgroundJob.reportUrl ? BackgroundJobState.ERROR : backgroundJob.state

          if (line.state === BackgroundJobState.CANCELED) {
            line.end = Chip(importStateToStr(backgroundJob.state, t, backgroundJob.reportUrl, 0), backgroundJob)
          } else {
            Object.entries(backgroundJob.details)
              .filter(([_, value]) => value)
              .forEach(([_, value]: any) => {
                line.end =
                  backgroundJob.state === BackgroundJobState.IN_PROGRESS
                    ? Math.ceil(((value.successCount + value.errorCount)) * 100 / value.total) + '%'
                    : Chip(importStateToStr(backgroundJob.state, t, backgroundJob.reportUrl, value.errorCount), backgroundJob)
              })
          }
        }
      })

      if (
        state.lines.filter(
          (line: { state: BackgroundJobState }) =>
            line.state === BackgroundJobState.IN_PROGRESS || line.state === BackgroundJobState.STARTING
        ).length === 0
      )
        state.showSubmit = true
    }
      }, [backgroundJobs])

  useEffect(() => {
    if (state.step === 3 && shops.items.length === 1) {
      setState({ ...state, showSubmit: true, shopID: shops.items[0].id })
    }
  }, [state.step])


  return (
    <>
      {state.step === 1 && (
        <>
          <div className={stylesModal.modalInfo}>{t('settings.import.modal.migration.LOGIN')}</div>
          {state.isLogin && <div className={styles.logLoader}></div>}
          <Input
            label={t('connection.login.MAIL')}
            value={state.email}
            onChange={(event) => setState(handleChange(state, 'email')(event))}
            onBlur={() => setState(handleBlur(state, 'email'))}
            style={{
              status: state.errors?.email?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              state: state.isLogin ? ComponentState.DISABLED : ComponentState.DEFAULT,
            }}
            helperText={state.errors.email?.value ? state.errors.email.message : undefined}
            dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
          />
          <Input
            label={t('connection.login.PASSWORD')}
            type={state.passwordType}
            value={state.password}
            onChange={(event) => setState(handleChange(state, 'password')(event))}
            onBlur={() => setState(handleBlur(state, 'password'))}
            style={{
              status: state.errors?.password?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              trailIcons: getIcon({
                icon: state.passwordType === 'password' ? EyeHide : EyeShow,
                onClick: () =>
                  setState({
                    ...state,
                    passwordType: state.passwordType === 'password' ? 'text' : 'password',
                  }),
              }),
              state: state.isLogin ? ComponentState.DISABLED : ComponentState.DEFAULT,
            }}
            helperText={state.errors.password?.value ? state.errors.password.message : undefined}
            dataCy={generateDataCy({ scope: cyContext, value: 'password' })}
          />

          {state.loginError && (
            <AttentionBox
              status={ComponentStatus.DANGER}
              title={
                <div>
                  {t('settings.import.modal.migration.LOGIN_INCORRECT')}
                  <a
                    className={styles.link}
                    target="_blank"
                    rel="noreferrer"
                    href="https://app.tactill.com/#/login/signin"
                  >
                    {t(t('settings.import.modal.migration.OLD_TACTILL'))}
                  </a>
                  {t('settings.import.modal.migration.FIND')}
                </div>
              }
            />
          )}
        </>
      )}
      {state.step === 2 && (
        <>
          <div className={stylesModal.modalInfo}>{t('settings.import.modal.migration.ABOUT_TO')}</div>
          <Comp
            lines={state.lines}
            padding
          />
          <AttentionBox title={t('settings.import.modal.migration.TIME')} status={ComponentStatus.INFO} />
        </>
      )}
      {state.step === 3 && (
        <>
          <div className={stylesModal.modalInfo}>{t('settings.import.modal.migration.SELECT_SHOP')}</div>
          <UniqueDropDown
            parentID={'selectInventory'}
            popUpID={'selectInventoryPopUp'}
            Element={
              <Input
                label={t('settings.import.modal.migration.PLACEHOLDER_SHOP')}
                value={shops.items.length === 1 ? shops.items[0].name :
                  shops.items.find((shop) => shop.id === state.shopID)
                    ? shops.items.find((shop) => shop.id === state.shopID)?.name
                    : []
                }
                style={{
                  trailIcons: [{ icon: KeyboardArrowDown }],
                  width: '298px',
                  readOnly: true,
                }}
                dataCy={generateDataCy({ scope: cyContext, value: 'shop' })}
              />
            }
            items={shops.items.map((s: any) => ({
              id: s.id,
              value: s.name,
              onClick: (id: string) => setState({ ...state, shopID: id, showSubmit: true }),
            }))}
            dataCy={generateDataCy({ scope: cyContext, value: 'shop' })}
          />
          <AttentionBox title={t('settings.import.modal.migration.INFO_INVENTORY')} status={ComponentStatus.INFO} />
        </>
      )}
      {state.step === 4 && (
        <>
          <div className={stylesModal.modalInfo}>{t('settings.import.modal.migration.ABOUT_TO')}</div>
          <Comp
            lines={state.lines}
            padding
          />
        </>
      )}
      {state.step === 5 && (
        <>
          {state.lines.find((line: any) => line.state === BackgroundJobState.ERROR) ? (
            <>
              <AttentionBox
                LeadIcon={Warning}
                title={t('settings.import.modal.migration.ERROR')}
                status={ComponentStatus.DANGER}
              />
              <div className={stylesModal.modalSubTitle}>{t('settings.import.modal.migration.DOWNLOAD')}</div>
              <img width="464px" height="245px" src={error} alt=""></img>
              <div className={stylesModal.modalSubTitle}>{t('settings.import.modal.migration.SOLVE')}</div>
              <div className={stylesModal.modalSubTitle}>{t('settings.import.modal.migration.RELAUNCH')}</div>
            </>
          ) : (
            <div className={styles.success}>
              <DisplayIcon2 Icon={Success} color={'var(--sys-color-content-success)'} />
              <div>{t('settings.import.modal.migration.SUCCESS')}</div>
            </div>
          )}
        </>
      )}
      {state.step === 7 && (
        <>
          <div className={stylesModal.modalInfo}>{t('settings.import.modal.migration.DRAFT_INVENTORY')}</div>
          <img src={inventory} alt="" width="450px"></img>
          <div className={stylesModal.modalInfo}>{t('settings.import.modal.migration.DRAFT_VALIDATE')}</div>
        </>
      )}
      <div className={stylesModal.modalButtons} style={{ marginTop: '2rem' }}>
        {state.step < 5 && (
          <Button
            title={t('button.CANCEL')}
            size={ComponentSize.MEDIUM}
            type={ComponentType.TERTIARY}
            onClick={() => closeAndResetModal(dispatch)}
          />
        )}
        <Button
          title={state.step >= 5 ? t('button.CLOSE') : t('button.NEXT')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleNext}
          dataCy={'submit'}
        />
      </div>
    </>
  )
}

export default Migration
