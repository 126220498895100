import { useState } from 'react'

type Props = {
  id?: string
  Icon: any
  color?: string
  height?: string
  width?: string
  isDisabled?: boolean
  nonEditable?: boolean
  hoverMessage?: string
  onBlur?: (event?: any) => void
  onClick?: () => void
  dataCy?: string
}

const DisplayHoverableIcon = ({ Icon, color, height, width, isDisabled, hoverMessage, onBlur, onClick }: Props) => {
  const [state, setState] = useState(false)

  return (
    <>
      <div
        tabIndex={0}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          width: width ?? '24px',
          height: height ?? '24px',
          outline: 'none',
        }}
        onMouseEnter={() => {
          hoverMessage && setState(true)
        }}
        onMouseLeave={() => hoverMessage && setState(false)}
        onClick={onClick}
        onBlur={onBlur}
      >
        <Icon color={color} />
      </div>
      {state && <div>{hoverMessage}</div>}
    </>
  )
}

/**
 *
 * This method displays an icon with its hover message if needed
 *
 * @usedIn - Form
 *
 * @param icon - The actual icon to display
 * @param className - The class name used for the hover message
 * @param hoverMessage - A text to display during the hover
 * @param onClick - A CTA when we click on the icon
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const DisplayIcon2 = ({
  id,
  Icon,
  color,
  height,
  width,
  isDisabled,
  nonEditable,
  hoverMessage,
  onBlur,
  onClick,
  dataCy,
}: Props): JSX.Element => {
  return (
    <>
      {hoverMessage ? (
        <DisplayHoverableIcon
          Icon={Icon}
          color={color}
          height={height}
          width={width}
          isDisabled={isDisabled}
          hoverMessage={hoverMessage}
          onBlur={onBlur}
          onClick={onClick}
        />
      ) : (
        <div
          data-cy={dataCy}
          id={id}
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: isDisabled ? 'not-allowed' : nonEditable ? 'default' : 'pointer',
            width: width ?? '24px',
            height: height ?? '24px',
            outline: 'none',
          }}
          onClick={onClick}
          onBlur={onBlur}
          tabIndex={0}
        >
          <Icon color={color} />
        </div>
      )}
    </>
  )
}

export default DisplayIcon2
