import { useState } from 'react'

import { ComponentSize, ComponentState, ComponentStatus } from '../types'

import styles from './TextArea.module.css'

type Props = {
  label: string
  value?: string
  placeholder?: string
  onChange?: (event: any) => void
  onBlur?: () => void
  onSubmit?: () => void
  style?: { size?: ComponentSize; state?: ComponentState; status?: ComponentStatus }
  dataCy?: string
}

/**
 *
 * This element displays a textarea
 *
 * @param label - TextArea's size
 * @param value - TextArea's value
 * @param onChange - A CTA when we type on the field
 * @param onBlur - A CTA when we leave the field
 * @param onSubmit - A CTA when we submit the text
 * @param style - TextArea's style
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const TextArea = ({ label, value, placeholder, onChange, onBlur, onSubmit, style, dataCy }: Props): JSX.Element => {
  const [textAreaState, setState] = useState({
    state: style?.state ?? ComponentState.DEFAULT,
  })

  const getStyle = () => ({
    background: `var(--comp-textarea-color-background)`,
    border: `1px solid var(--comp-textarea-color-border-${
      style?.status === ComponentStatus.ERROR ? style.status : textAreaState.state
    })`,
  })

  const getTextStyle = () => ({
    cursor: style?.state === ComponentState.DISABLED ? 'not-allowed' : 'text',
    color: `var(--comp-textfield-color-foreground-${
      style?.state === ComponentState.DISABLED ? style.state : 'default'
    })`,
  })

  const updateState = (newState?: ComponentState) =>
    textAreaState.state !== ComponentState.DISABLED &&
    setState({ ...textAreaState, state: newState ?? ComponentState.DEFAULT })

  return (
    <div
      className={styles.textArea}
      onMouseEnter={() => updateState(ComponentState.HOVER)}
      onMouseLeave={() => updateState(style?.state)}
      onClick={() => updateState(ComponentState.FOCUSED)}
      style={getStyle()}
      onSubmit={(event: any) => {
        event.preventDefault()
        onSubmit && onSubmit()
      }}
    >
      <textarea
        rows={5}
        value={value}
        placeholder={placeholder}
        onChange={(event) => onChange && onChange(event.target.value)}
        onBlur={onBlur}
        className={styles.innerTextArea}
        style={getTextStyle()}
        autoComplete="off"
        disabled={style?.state === ComponentState.DISABLED}
        data-cy={dataCy}
      />
      {label && (
        <label
          className={styles.label}
          style={{
            color: `var(--comp-textfield-color-label-${
              style?.status === ComponentStatus.ERROR ? style.status : textAreaState.state
            })`,
          }}
        >
          {label}
        </label>
      )}
    </div>
  )
}

export default TextArea
