import { OptionAssociations, StateStore } from '../../store/types'
import { APIOption } from '../../types'
import { getProduct } from '../../getters/catalog/product'

/*
  Used for subscriptions
*/

const removeOptionFromProduct = (payload: { optionID: string; dispatch: any }, state: StateStore) => {
  const { optionID } = payload
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map((item) => ({
          ...item,
          options: {
            ...item.options,
            items:
              item.options &&
              item.options.items.filter((option: any) => option.option && option.option.id !== optionID),
          },
        })),
      },
    },
  }
}

const updateOptionsAssociations = (payload: { optionID: string; dispatch: any }, state: StateStore) => {
  const index = state.optionsAssociations.findIndex((option) => option.optionID === payload.optionID)
  if (index !== -1) {
    state.optionsAssociations[index].products.map((id) => getProduct(id, payload.dispatch))
  }
  return { ...state }
}

const getProductOptionIds = (items: Array<any>, id: string) =>
  items
    .filter((item) => item.options && item.options.items && item.options.items.length > 0)
    .filter((item) =>
      item.options.items
        .filter(({ option }: any) => option)
        .find((option: { option: { id: string } }) => option.option.id === id)
    )
    .map((item) => item.id)

const getOptionsAssociations = (items: Array<any>): Array<OptionAssociations> => {
  const optionIds = items
    .filter((ent) => ent.options && ent.options.items && ent.options.items.length > 0)
    .flatMap((ent: { options: { items: [{ option: APIOption }] } }) =>
      ent.options.items
        .filter(({ option }) => option)
        .map(({ option }) => option.id)
        .sort()
    )
    .filter((id: string, index: number, tab) => tab.indexOf(id) === index)
    .map((id: string) => id)

  return optionIds.map((id: string) => ({
    optionID: id,
    products: getProductOptionIds(items, id),
  }))
}

export { updateOptionsAssociations, getOptionsAssociations, removeOptionFromProduct }
