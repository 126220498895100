import { TypeCol, TypeColumnConfiguration } from '../types';
import { displayDateShortMonthWithHours } from '../../utils/date';
import { displayPercentage } from '../../utils/number';
import editableList from '../editableCols/productList';
import { CurrencyEditCell as CurrencyCell, TagCell, TextEditCell as TextCell } from '../../components/list/cell';
import { enumToStr } from '../../utils/color';
import { ComponentSize } from '../../components/newComponents/types';
import ListItemVariant from '../../components/newComponents/listItem/variant';
import Thumbnail from '../../components/newComponents/thumbnail';
import LoadMore from '../../components/newComponents/listItem/loadMore';

const PRODUCT = 'product';

export const productCols: Array<TypeCol> = [
  {
    id: 'color',
    title: '',
    type: 'element',
    active: true,
    function: ({ value }: any) =>
      value.photo || value.color
        ? Thumbnail({
            color: enumToStr(value.color),
            size: ComponentSize.SMALL,
            image: value.photo,
            iconText: value.iconText,
          })
        : null,
  },
  {
    id: 'name',
    title: 'NAME',
    type: 'element',
    fieldType: 'string',
    active: true,
    sortable: true,
    notCustomable: true,
    function: ({ value, onClick, onIconClick }: any) =>
      value.variantsCount > 0
        ? ListItemVariant({ name: value.name, length: value.variantsCount, onClick, onIconClick })
        : value.name,
  },
  {
    id: 'icon',
    title: '',
    type: 'element',
    fieldType: 'string',
    active: true,
    sortable: false,
    notCustomable: true,
    function: ({ value, onClick }: any) => (value.variantsCount > 0 ? LoadMore({ onClick }) : undefined),
  },
  {
    id: 'categoryName',
    title: 'CATEGORY',
    type: 'function',
    fieldType: 'string',
    active: true,
    sortable: true,
    function: ({ category }: { category: { name: string } }) => (category ? category.name : null),
  },
  {
    id: 'taxRate',
    title: 'TAX',
    type: 'element',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value }) => TextCell({ value: value.tax ? displayPercentage(value.tax.rate) : '', editable: false }),
  },
  {
    id: 'reference',
    title: 'REFERENCE',
    type: 'string',
    field: 'reference',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'sellPrice',
    title: 'SELL_PRICE',
    type: 'element',
    field: 'sellPrice',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }) =>
      CurrencyCell({
        value,
        param: { emptyValue: 'Variable' },
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'buyPrice',
    title: 'BUY_PRICE',
    type: 'element',
    field: 'buyPrice',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }) =>
      CurrencyCell({
        value,
        param: { emptyValue: '' },
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'barcode',
    title: 'BARCODE',
    type: 'string',
    field: 'barcode',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'createdAt',
    title: 'CREATED_AT',
    type: 'element',
    field: 'createdAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: ({ value }) => {
      return displayDateShortMonthWithHours(value);
    },
  },
  {
    id: 'tagValues',
    title: 'TAG_NAME',
    type: 'element',
    active: true,
    function: ({ value, currency, decimals }) =>
      TagCell({
        pTags: value.tags ? value.tags.items : [],
        isOption: false,
        currency,
        decimals,
      }),
  },
];

const productsColsConf: Array<TypeColumnConfiguration> = [
  {
    id: 'requestProducts',
    type: PRODUCT,
    cols: productCols,
    creatable: true,
    editable: false,
    exportable: true,
    clickable: true,
    shrinkrable: true,
    customable: true,
    checkboxes: true,
    searchBarPlaceholder: 'searchBar.placeholder.NAME_REFERENCE',
    creatablePlaceholder: 'catalog.product.form.NEW',
  },
  editableList,
];

export { productsColsConf };
