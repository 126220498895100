import {
  Growth,
  Catalog,
  Activity,
  Info,
  Shop,
  Edit,
  Tag,
  Tax,
  People,
  Company,
  Account,
  LockOpen,
  Till,
  Wallet,
  TestMode,
  MultipleMethodAlt,
} from '../../assets/icons'
import { env } from '../../services/auth'

export type Subsection = {
  id: string
  title: string
  sectionID: string
  action: (arg: any) => void
}

export type TypeSection = {
  id: string
  title: string
  LeadIcon: any
  subsections?: Array<Subsection>
  sectionID?: string
  action?: (arg: any) => void
}

const sections = (t: (field: string) => string): Array<TypeSection> => [
  {
    id: 'statistic',
    title: t('menu.sections.STATISTIC'),
    LeadIcon: Growth,
    // @ts-ignore
    subsections: [
      {
        sectionID: 'statistic',
        id: 'general',
        title: t('menu.subSections.GENERAL'),
        action: (navigate: any) => navigate('/statistic/general'),
      },
      {
        sectionID: 'statistic',
        id: 'products',
        title: t('menu.subSections.PRODUCTS'),
        action: (navigate: any) => navigate('/statistic/products'),
      },
      {
        sectionID: 'statistic',
        id: 'categories',
        title: t('menu.subSections.CATEGORIES'),
        action: (navigate: any) => navigate('/statistic/categories'),
      },
      {
        sectionID: 'statistic',
        id: 'values',
        title: t('menu.subSections.VALUES'),
        action: (navigate: any) => navigate('/statistic/values'),
      },
    ].filter((_) => _),
  },
  {
    id: 'activity',
    title: t('menu.sections.ACTIVITY'),
    LeadIcon: Activity,
    subsections: [
      {
        sectionID: 'activity',
        id: 'sales',
        title: t('menu.subSections.SALES'),
        action: (navigate) => navigate('/activity/sales'),
      },
      {
        sectionID: 'activity',
        id: 'payments',
        title: t('menu.subSections.PAYMENTS'),
        action: (navigate) => navigate('/activity/payments'),
      },
      {
        sectionID: 'activity',
        id: 'cashbooks',
        title: t('menu.subSections.CASHBOOKS'),
        action: (navigate) => navigate('/activity/cashbooks'),
      },
    ],
  },
  {
    id: 'catalog',
    title: t('menu.sections.CATALOG'),
    LeadIcon: Catalog,
    subsections: [
      {
        sectionID: 'catalog',
        id: 'products',
        title: t('menu.subSections.PRODUCTS'),
        action: (navigate) => navigate('/catalog/products'),
      },
      {
        sectionID: 'catalog',
        id: 'categories',
        title: t('menu.subSections.CATEGORIES'),
        action: (navigate) => navigate('/catalog/categories'),
      },
      {
        sectionID: 'catalog',
        id: 'options',
        title: t('menu.subSections.OPTIONS'),
        action: (navigate) => navigate('/catalog/options'),
      },
      {
        sectionID: 'catalog',
        id: 'packs',
        title: t('menu.subSections.PACKS'),
        action: (navigate) => navigate('/catalog/packs'),
      },
      {
        sectionID: 'catalog',
        id: 'movements',
        title: t('menu.subSections.MOVEMENTS'),
        action: (navigate) => navigate('/catalog/movements'),
      },
      {
        sectionID: 'catalog',
        id: 'discounts',
        title: t('menu.subSections.DISCOUNTS'),
        action: (navigate) => navigate('/catalog/discounts'),
      },
    ],
  },
  {
    id: '',
    title: t('menu.sections.CUSTOMERS'),
    LeadIcon: People,
    sectionID: 'customers',
    action: (navigate) => navigate('/customers'),
  },
]

const settings = (t: (field: string) => string): Array<TypeSection> => [
  {
    sectionID: 'settings',
    id: 'shops',
    LeadIcon: Shop,
    title: t('menu.subSections.SHOPS'),
    action: (navigate) => navigate('/settings/shops'),
  },
  {
    sectionID: 'settings',
    id: 'slots',
    LeadIcon: Till,
    title: t('menu.subSections.SLOTS'),
    action: (navigate) => navigate('/settings/slots'),
  },
  {
    sectionID: 'settings',
    id: 'users',
    LeadIcon: People,
    title: t('menu.subSections.USERS'),
    action: (navigate) => navigate('/settings/users'),
  },
  {
    sectionID: 'settings',
    id: 'customFields',
    LeadIcon: Edit,
    title: t('menu.subSections.CUSTOMFIELDS'),
    action: (navigate) => navigate('/settings/customFields'),
  },
  {
    sectionID: 'settings',
    id: 'tags',
    LeadIcon: Tag,
    title: t('menu.subSections.TAGS'),
    action: (navigate) => navigate('/settings/tags'),
  },
  {
    sectionID: 'settings',
    id: 'taxes',
    LeadIcon: Tax,
    title: t('menu.subSections.TAXES'),
    action: (navigate) => navigate('/settings/taxes'),
  },
  {
    sectionID: 'settings',
    id: 'paymentMethods',
    LeadIcon: Wallet,
    title: t('menu.subSections.PAYMENTMETHODS'),
    action: (navigate) => navigate('/settings/paymentMethods'),
  },
  {
    sectionID: 'settings',
    id: 'stripe',
    LeadIcon: MultipleMethodAlt,
    title: t('menu.subSections.STRIPE'),
    action: (navigate) => navigate('/settings/stripe'),
  },
  {
    sectionID: 'settings',
    id: 'import',
    LeadIcon: Info,
    title: t('menu.subSections.IMPORT'),
    action: (navigate) => navigate('/settings/import'),
  },
]

const account = (t: (field: string) => string): Array<TypeSection> => [
  {
    sectionID: 'account',
    id: 'userAccount',
    LeadIcon: Account,
    title: t('menu.subSections.USERACCOUNT'),
    action: (navigate) => navigate('/account/userAccount'),
  },
  {
    sectionID: 'account',
    id: 'sellerCode',
    LeadIcon: LockOpen,
    title: t('menu.subSections.SELLERCODE'),
    action: (navigate) => navigate('/account/sellerCode'),
  },
  {
    sectionID: 'account',
    id: 'companies',
    LeadIcon: Company,
    title: t('menu.subSections.COMPANIES'),
    action: (navigate) => navigate('/account/companies'),
  },
  {
    sectionID: 'account',
    id: 'testMode',
    LeadIcon: TestMode,
    title: t('menu.subSections.TESTMODE'),
    action: (navigate) => navigate('/account/testMode'),
  },
]

export { sections, settings, account }
