const Tag = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1614 9C20.1614 8.45 19.7114 8 19.1614 8H16.1614V5C16.1614 4.45 15.7114 4 15.1614 4C14.6114 4 14.1614 4.45 14.1614 5V8H10.1614V5C10.1614 4.45 9.71138 4 9.16138 4C8.61138 4 8.16138 4.45 8.16138 5V8H5.16138C4.61138 8 4.16138 8.45 4.16138 9C4.16138 9.55 4.61138 10 5.16138 10H8.16138V14H5.16138C4.61138 14 4.16138 14.45 4.16138 15C4.16138 15.55 4.61138 16 5.16138 16H8.16138V19C8.16138 19.55 8.61138 20 9.16138 20C9.71138 20 10.1614 19.55 10.1614 19V16H14.1614V19C14.1614 19.55 14.6114 20 15.1614 20C15.7114 20 16.1614 19.55 16.1614 19V16H19.1614C19.7114 16 20.1614 15.55 20.1614 15C20.1614 14.45 19.7114 14 19.1614 14H16.1614V10H19.1614C19.7114 10 20.1614 9.55 20.1614 9ZM14.1614 14H10.1614V10H14.1614V14Z"
      fill={color}
    />
  </svg>
)

export default Tag
