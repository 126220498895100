import DisplayIcon2 from '../icons'
import { ComponentSize } from '../types'

import { Check } from '../../../assets/icons'
import styles from './Picker.module.css'

type Props = {
  image?: any
  color?: string
  selected?: boolean
  size: ComponentSize
  onClick?: () => void
}

/**
 *
 * This element displays an avatar
 *
 * @param image - Avatar's image
 * @param color - Avatar's color
 * @param selected - Avatar selected
 * @param size - Avatar's size
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Picker = ({ image, color, selected, size, onClick }: Props): JSX.Element => (
  <div className={styles.container} onClick={onClick}>
    {image ? (
      <img
        src={image}
        className={styles.picker}
        alt=""
        style={{
          width: `var(--comp-picker-size-${size})`,
          height: `var(--comp-picker-size-${size})`,
        }}
      />
    ) : (
      <div
        className={styles.picker}
        style={{
          width: `var(--comp-picker-size-${size})`,
          height: `var(--comp-picker-size-${size})`,
          backgroundColor: color,
        }}
      />
    )}
    <div className={styles.selected}>
      <DisplayIcon2
        id="pickerID"
        Icon={Check}
        color={selected ? 'var(--comp-picker-color-foreground)' : ''}
        width={`var(--comp-picker-size-icon-${size})`}
        height={`var(--comp-picker-size-icon-${size})`}
      />
    </div>
  </div>
)

export default Picker
