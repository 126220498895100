import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ListState, objectType } from '../../../store/types'
import List from '../../../components/list'
import Drawer from '.'
import { listOptions, listOptionItems, listOptionItemsNextToken, getDashboardView } from '../../../store/selector'
import optionFieldsRequester from '../../../requester/catalog/option'
import { buildCols } from '../../../utils'
import { TypeComparator } from '../../../components/requester/types'
import { useDispatch } from 'react-redux'
import { getListOptions as getOptions } from '../../../graphql/custom/queries'
import { subscribeOptions, unSubscribeOptions } from '../../../getters/catalog/option'
import { getData } from '../../../services'
import { pluralize } from '../../../utils/typeToType'
import { fetchListElement, updateListState } from '../../../actions/list'

const ListOptions = () => {
  const res = useSelector(listOptions)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listOptionItems)
  const { items, nextToken, sort } = useSelector(listOptionItemsNextToken)
  const dashboardView = useSelector(getDashboardView)
  const dispatch = useDispatch();

  useEffect(() => {
    subscribeOptions(dispatch)
    const getListOptions = async () => {
      const listAllOptions = await getData({ request: getOptions, limit: 100 });
      if (listAllOptions.requestOptions && listAllOptions.requestOptions.items && listAllOptions.requestOptions.items.length > 0) {
        const allOptions = listAllOptions.requestOptions.items
          .filter((_: any) => _)
          .map((option: any) => ({
            ...option,
            selected: false,
          }))
        fetchListElement(dispatch, allOptions, listAllOptions.requestOptions.nextToken, pluralize(objectType.OPTION))
        updateListState(
          { type: pluralize(objectType.OPTION), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.OPTION))
        updateListState(
          { type: pluralize(objectType.OPTION), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListOptions();

    return () => unSubscribeOptions();

  }, []);

  useEffect(() => setCols(buildCols(res.cols!, 'name', 'usedIn', dashboardView)), [dashboardView, res.cols])

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={optionFieldsRequester}
      searchBarFields={[{ filter: TypeComparator.CONTAINS, field: 'name' }]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.OPTION}
      Drawer={Drawer}
    />
  )
}

export default ListOptions
