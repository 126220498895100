import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getExtraInfos } from '../../../../services/activity/payment'
import { DashboardView, DrawerState } from '../../../../store/types'
import Form from './form'
import { Route } from '../../../../utils/navigation'
import { getDrawerState, getDashboardView, listPaymentMethodsItemsNextToken, isDrawerOpen, listShops, listSales, listPaymentItems, listCashbooks } from '../../../../store/selector'
import Loader from '../../../../components/newComponents/loader'
import { updateDrawerState } from '../../../../actions/drawer'
import { movementTypeToStr, stateCashbookEnumToStr, salePaymentStateToStr } from '../../../../utils/typeToType'
import DrawerTop from '../../../../components/oldComponents/drawer/top'
import { TypeDrawer } from '../../../../types/drawer'
import DrawerMenu from '../../../../components/oldComponents/drawer/menu'
import { TypePayment } from '../../../../types'
import generateDataCy from '../../../../utils/cypress'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'
import { callService } from '../../../../services'
import { GetPaymentQueryVariables } from '../../../../API';
import { getPayment } from '../../../../graphql/custom/queries'

type Props = {
  id: string
  history: Array<Route>
  addHistory: (route: Route) => void
  close: () => void
}

export const MenuPayment = ({ id, history, addHistory, close }: Props) => {
  const mobileView = useSelector(getDashboardView)
  const drawerState = useSelector(getDrawerState)
  const { t } = useTranslation()
  const initialState: TypePayment = {
    isLoaded: false,
  }
  const [state, setState] = useState(initialState)
  const paymentMethods = useSelector(listPaymentMethodsItemsNextToken)
  const drawerIsOpen = useSelector(isDrawerOpen);
  const allPayments = useSelector(listPaymentItems)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'activity', value: generateDataCy({ scope: 'payment', value: 'drawer' }) })

  const setPaymentsInState = async (payment: any) => {
    if (payment) {
      const { shopName, saleNumber, saleTotal, cashbook } = await getExtraInfos(payment)

      let newCustomer
      let newCashbook
      if (payment.customer) {
        newCustomer = {
          id: payment.customer.id,
          firstName: payment.customer.firstName,
          lastName: payment.customer.lastName,
          color: payment.customer.color,
          iconText: payment.customer.iconText,
        }
      }
      if (cashbook) {
        newCashbook = {
          id: cashbook.id,
          number: cashbook.number,
          status: stateCashbookEnumToStr(cashbook.state, t),
        }
      }
      const paymentMethod = paymentMethods.items.find((item: any) => item.id === payment.paymentMethodID)
      setState({
        ...state,
        id: payment.id,
        number: payment.number,
        amount: payment.amount,
        date: payment.date,
        status: salePaymentStateToStr(payment.state, t),
        type: movementTypeToStr(payment.type, t),
        shop: {
          id: payment.shopID,
          name: shopName,
        },
        sourceName: payment.sourceName,
        sale: {
          id: payment.saleID,
          number: saleNumber,
          total: saleTotal,
        },
        payment: {
          name: payment.paymentMethodName,
          type: payment.paymentMethodType,
          color: paymentMethod.color,
        },
        customer: newCustomer,
        cashbook: newCashbook,
        isLoaded: true,
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    if (id && drawerIsOpen) {
      const payment = allPayments.find((payment) => payment.id === id)
      if (payment) {
        setPaymentsInState(payment);
      } else {
        callService<GetPaymentQueryVariables>({ id }, getPayment, 'getPayment').then((data: any) => setPaymentsInState(data.data))
      }
    }
  }, [id, drawerIsOpen])

  return (
    <>
      <DrawerTop
        history={history}
        name={`${t('activity.payment.PAYMENT')}${state.number ? " #" + state.number.toString() : "" }`}
        mobileView={mobileView === DashboardView.MOBILE}
        close={close}
        dataCy={cyContext}
      />
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : drawerState === DrawerState.LOADED ? (
        <div
          className={styles.containerForm}
          style={{
            height: `calc(100vh - 61px)`,
          }}
          data-cy={cyContext}
        >
          <Form paymentState={state} addHistory={addHistory} dataCy={cyContext} />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const Drawer = ({ selected = [], close }: TypeDrawer) => <DrawerMenu component={MenuPayment} />

export { Drawer }
