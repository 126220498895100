import { createRoot } from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { frFR } from '@mui/x-date-pickers/locales';
import App from './App'
import * as serviceWorker from './serviceWorker'
import { clientId, domain } from './services/auth'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import './components/oldComponents/DatePicker.css'
import './index.css'
import 'dayjs/locale/fr';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('16823555ba566d781a79ec32ccc0e4e6Tz02OTU2OCxFPTE3MTk1MDY3NDA2MDEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const frLocale = frFR.components.MuiLocalizationProvider.defaultProps.localeText;

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    useRefreshTokens
    cacheLocation="localstorage"
  >
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frLocale}>
      <App />
    </LocalizationProvider>
  </Auth0Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
