import { Action, objectType } from '../../store/types'
import { ListUsersQueryVariables, UpdateAccountInput } from '../../API'
import { getCompanyID, setCompanyID } from '../../services/localStorage'
import { callService } from '../../services'
import { updateAccount as update } from '../../graphql/custom/mutations'
import { getAccount as get, listUsers } from '../../graphql/custom/queries'
import { fetchListElement, sortListElement } from '../../actions/list'
import { pluralize } from '../../utils/typeToType'

const getAccount = async (dispatch: any) => {
  const account = await callService('', get, 'getAccount')

  if (account.data) {
    const companyID = getCompanyID()
    if (!companyID || !account.data.companies.items.find((item: any) => item.companyID === companyID))
      setCompanyID(account.data.companies.items[0].companyID)

    dispatch({
      type: Action.FETCH_ITEM,
      payload: {
        items: account.data,
        type: 'account',
      },
    })
  }
  return account
}

const updateAccount = async (input: UpdateAccountInput, dispatch: any) => {
  const account = await callService<{ input: UpdateAccountInput }>(
    // @ts-ignore
    { input, companyID: getCompanyID() },
    update,
    'updateAccount'
  )

  if (account.data) {
    dispatch({
      type: Action.FETCH_ITEM,
      payload: {
        items: account.data,
        type: 'account',
      },
    })

    const users = await callService<ListUsersQueryVariables>({ companyID: getCompanyID() }, listUsers, 'listUsers')

    if (users.data && users.data.length > 0) {
      fetchListElement(
        dispatch,
        users.data.filter((_: any) => _),
        users.data.nextToken,
        pluralize(objectType.USER)
      )
      sortListElement(dispatch, 'lastName', 'asc', objectType.USER)
    }
  }

  return account
}

export { getAccount, updateAccount }
