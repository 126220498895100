import { onCreateTax, onDeleteTax, onUpdateTax } from '../../graphql/custom/subscriptions'
import { getCompanyID } from '../../services/localStorage'
import { updateListElement, deleteListElement } from '../../actions/list'
import { getTax as get, getTotalProductByTax } from '../../graphql/custom/queries'
import { objectType } from '../../store/types'
import { getObservable } from '../../services/graphql'
import { callService } from '../../services'
import { GetTaxQueryVariables } from '../../API'

let onCreateTaxSubscription: any
let onDeleteTaxSubscription: any
let onUpdateTaxSubscription: any

export type GetTotalProductTaxVariables = {
  test: boolean
  catalogID: string
  tax: number
}

const getTax = async (id: string, dispatch: any) => {
  const tax = await callService<GetTaxQueryVariables>({ id }, get, 'getTax')

  if (tax.data) {
    updateListElement({ element: tax.data, dispatch, type: objectType.TAX })
  }
  return tax
}

const getTotalProductTax = async (rate: number, tax: any) => {
  const input = {
    catalogID: getCompanyID(),
    test: false,
    tax: rate,
  }
  const taxes = await callService<GetTotalProductTaxVariables>({ ...input }, getTotalProductByTax, 'requestProducts')
  const { total } = taxes.data
  return { ...tax, productCount: total }
}

const subscribeTaxes = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreateTaxSubscription)
    onCreateTaxSubscription = getObservable(onCreateTax, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateTax)
          updateListElement({ element: eventData.data.onCreateTax, dispatch, type: objectType.TAX })
      },
    })
  if (!onDeleteTaxSubscription)
    onDeleteTaxSubscription = getObservable(onDeleteTax, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeleteTax,
          dispatch,
          type: objectType.TAX,
        }),
    })
  if (!onUpdateTaxSubscription)
    onUpdateTaxSubscription = getObservable(onUpdateTax, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateTax)
          updateListElement({ element: eventData.data.onUpdateTax, dispatch, type: objectType.TAX })
      },
    })
}

const unSubscribeTaxes = () => {
  if (onCreateTaxSubscription) {
    onCreateTaxSubscription.unsubscribe()
    onCreateTaxSubscription = null
  }

  if (onDeleteTaxSubscription) {
    onDeleteTaxSubscription.unsubscribe()
    onDeleteTaxSubscription = null
  }

  if (onUpdateTaxSubscription) {
    onUpdateTaxSubscription.unsubscribe()
    onUpdateTaxSubscription = null
  }
}

export { subscribeTaxes, unSubscribeTaxes, getTax, getTotalProductTax }
