import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { forgotPassword } from '../../services/auth'
import Button from '../../components/newComponents/button'
import SplitView from './splitView'
import { findErrorsInState } from '../../format/errors'
import { TypeFormError, ValidationFunction } from '../../format/errors/types'
import { DashboardView, DrawerState } from '../../store/types'
import { email as mailValidator } from '../../format/errors/filters/connection'
import { updateDrawerState } from '../../actions/drawer'
import { getDashboardView, getIsIOS } from '../../store/selector'
import generateDataCy from '../../utils/cypress'
import LogoTactill from '../../components/newComponents/logoTactill'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../components/newComponents/types'
import Input from '../../components/newComponents/input'
import { handleBlur, handleChange } from '../../format/form'
import AlertBanner from '../../components/newComponents/alertBanner'
import { Check } from '../../assets/icons'

import { ForgotPassword as ForgotPasswordIllustration } from '../../assets/illustrations'
import styles from './Connection.module.css'
import stylesDrawer from '../../components/oldComponents/drawer/Drawer.module.css'
import stylesApp from '../../App.module.css'

type State = {
  email: string
  showSubmit: boolean
  isMailSent: boolean
  validators: {
    email?: ValidationFunction
  }
  errors: {
    global?: TypeFormError
    email?: TypeFormError
  }
}

const Form = () => {
  const initialState: State = {
    email: '',
    showSubmit: false,
    isMailSent: false,
    validators: {
      email: mailValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const dashboardView = useSelector(getDashboardView)
  const isIOS = useSelector(getIsIOS)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const cyContext = generateDataCy({
    scope: 'connection',
    value: 'forgotPassword',
  })

  const handleSubmit = () => {
    setState({ ...handleBlur(state, 'email'), showSubmit: false })

    if (!findErrorsInState(state.errors) && state.email) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      forgotPassword(state.email).then(() => {
        updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
        setState({ ...state, isMailSent: true })
      })
    }
  }

  return (
    <div
      className={stylesApp.grid}
      style={{
        gridTemplateColumns: dashboardView !== DashboardView.MOBILE ? 'repeat(6, 1fr)' : 'repeat(4, 1fr)',
      }}
    >
      <div
        className={styles.containerForm}
        style={{
          marginTop: isIOS
            ? '0em'
            : dashboardView === DashboardView.DESKTOP
            ? '5em'
            : dashboardView === DashboardView.TABLET
            ? '4em'
            : '',
          gridColumn: dashboardView !== DashboardView.MOBILE ? '2 / span 4' : '1 / span 4',
        }}
      >
        <LogoTactill type={ComponentType.LOGOICONTEXT} />
        <div className={styles.middleArea}>
          <div className={styles.title}>{t('connection.forgotPassword.FORGOT')}</div>
          <div className={styles.subTitle}>{t('connection.forgotPassword.ENTER_MAIL')}</div>
          {state.isMailSent ? (
            <AlertBanner
              LeadIcon={Check}
              title={t('connection.forgotPassword.MAIL_SENT', { email: state.email })}
              status={ComponentStatus.SUCCESS}
              dataCy={generateDataCy({ scope: cyContext, value: 'emailSent' })}
            />
          ) : (
            <Input
              label={t('connection.forgotPassword.MAIL')}
              value={state.email}
              onChange={(event) => setState(handleChange(state, 'email')(event))}
              onBlur={() => setState(handleBlur(state, 'email'))}
              onSubmit={handleSubmit}
              style={{
                status: state.errors?.email?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              }}
              helperText={state.errors.email?.value ? state.errors.email.message : undefined}
              dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
            />
          )}
        </div>
        {state.isMailSent ? (
          <div
            style={{ position: 'absolute', bottom: 0, left: 0 }}
            className={stylesDrawer.linkToRequester}
            onClick={() => navigate('/login')}
            data-cy={generateDataCy({ scope: cyContext, value: 'back' })}
          >
            {t('connection.forgotPassword.BACK')}
          </div>
        ) : (
          <div className={styles.forgotPasswordButtons}>
            <Button
              state={ComponentState.DEFAULT}
              status={ComponentStatus.DEFAULT}
              size={ComponentSize.LARGE}
              title={t('button.CANCEL')}
              type={ComponentType.TERTIARY}
              onClick={() => navigate('/login')}
            />
            <Button
              title={t('button.SEND')}
              size={ComponentSize.LARGE}
              type={ComponentType.PRIMARY}
              state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
              onClick={handleSubmit}
              dataCy="submit"
            />
          </div>
        )}
      </div>
    </div>
  )
}

const ForgotPassword = () => <SplitView left={<Form />} right={<ForgotPasswordIllustration />} />

export default ForgotPassword
