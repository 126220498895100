import { useSelector, useDispatch } from 'react-redux'

import { updateModal } from '../../../actions/modal'
import { currentAccount, listSlots, listSlotsItemsNextToken } from '../../../store/selector'
import List from '../../../components/list'
import { ListState, objectType } from '../../../store/types'
import SlotModal from './modal'
import { useEffect } from 'react'
import { getData } from '../../../services'
import { listSlotsByCompany } from '../../../graphql/custom/queries'
import { fetchListElement, updateListState } from '../../../actions/list'
import { pluralize } from '../../../utils/typeToType'
import { getCompanyID } from '../../../services/localStorage'
import { subscribeSlots, unSubscribeSlots } from '../../../getters/setting/slot'

const Slots = () => {
  const slots = useSelector(listSlots)
  const account = useSelector(currentAccount)
  const { items } = useSelector(listSlotsItemsNextToken)
  const dispatch = useDispatch()

  useEffect(() => {
    const shops = account.shops.items
      .filter((_: any) => _)
      .filter((shop: any) => shop.companyID === getCompanyID())
      .map((shop: any) => shop.shop)
    subscribeSlots(shops, dispatch)

    const getListSlots = async () => {
      const listSlots = await getData({ request: listSlotsByCompany, limit: 100 });
      if (listSlots.listSlotsByCompany && listSlots.listSlotsByCompany.items && listSlots.listSlotsByCompany.items.length > 0) {
        const slots = listSlots.listSlotsByCompany.items.filter((_: any) => _)
        fetchListElement(dispatch, slots, listSlots.listSlotsByCompany.nextToken, pluralize(objectType.SLOT))
        updateListState(
          { type: pluralize(objectType.SLOT), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.SLOT))
        updateListState(
          { type: pluralize(objectType.SLOT), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListSlots();

    return () => unSubscribeSlots();
      }, [])

  const handleCreate = () =>
    updateModal(
      dispatch,
      true,
      () => <SlotModal isCreate />,
      () => { }
    )

  return <List cols={slots.cols!} items={items} fullItems={items} type={objectType.SLOT} onCreate={handleCreate} />
}

export default Slots
