import { Route, Routes } from 'react-router-dom'

import ListProducts from './product/list'
import ListCategories from './category/list'
import ListDiscounts from './discount/list'
import ListPacks from './pack/list'
import ListOptions from './option/list'
import ListInventoryMovements from './inventoryMovement/list'
import EditableListInventoryMovements from './inventoryMovement/list/editable'

export enum TabIdsCatalog {
  DETAIL = 'DETAIL',
  STAT = 'STAT',
  INVENTORY = 'INVENTORY',
}

const CatalogRoutes = () => (
  <Routes>
    <Route path="products" element={<ListProducts />} />
    <Route path="categories" element={<ListCategories />} />
    <Route path="discounts" element={<ListDiscounts />} />
    <Route path="packs" element={<ListPacks />} />
    <Route path="options" element={<ListOptions />} />
    <Route path="movements" element={<ListInventoryMovements />} />
    <Route path="editableMovement/:id" element={<EditableListInventoryMovements />} />
  </Routes>
)

export default CatalogRoutes
