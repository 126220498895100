import { CurrencyEditCell as CurrencyCell } from '../../components/list/cell'
import { TypeColumnConfiguration } from '../types'

const STAT_PAYMENT = 'statPayment'

const statPaymentsCols: TypeColumnConfiguration[] = [
  {
    id: 'statPaymentsCols',
    type: STAT_PAYMENT,
    cols: [
      {
        id: 'paymentMethod',
        title: 'PAYMENT_METHOD',
        type: 'string',
        field: 'paymentMethod',
        fieldType: 'string',
        active: true,
        sortable: true,
      },
      {
        id: 'nbPositivePayments',
        title: 'NB_POSITIVE_PAYMENTS',
        type: 'element',
        field: 'nbPositivePayments',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value }) => value,
      },
      {
        id: 'nbNegativePayments',
        title: 'NB_NEGATIVE_PAYMENTS',
        type: 'element',
        field: 'nbNegativePayments',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value }) => value,
      },
      {
        id: 'sellTotals',
        title: 'TOTAL_PAID',
        field: 'sellTotals',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'totalRefund',
        title: 'TOTAL_REFUND',
        field: 'totalRefund',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'total',
        title: 'SELL_TOTALS',
        field: 'total',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
    ],
    customClassNames: {
      lastLine: 'bold',
    },
    creatable: false,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: true,
    customable: false,
    checkboxes: false,
  },
]

export default statPaymentsCols
