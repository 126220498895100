import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { closeAndResetModal } from '../../../actions/modal';
import Button from '../../../components/newComponents/button';
import Input from '../../../components/newComponents/input';
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types';
import { handleBlur, handleChange } from '../../../format/form';
import { CompanyData, TypeCompany } from '../../../types/account/company';
import generateDataCy from '../../../utils/cypress';
import nameValidator from '../../../format/errors/filters/company';
import { findErrorsInState, setErrorsInState } from '../../../format/errors';
import { DashboardView } from '../../../store/types';
import { callService } from '../../../services';
import {
  CreateCompanyInput,
  UpdateCompanyInput,
  CreateShopInput,
  CreateSlotInput,
  SendSlotEmailInput,
} from '../../../API';
import * as mutations from '../../../graphql/custom/mutations';
import * as queries from '../../../graphql/queries';
import { switchCompany, updateCompanyData } from '../../../actions/company';
import { getDashboardView } from '../../../store/selector';

import styles from '../../../components/newComponents/modal/Modal.module.css';

const CompanyModal = ({ navigate, value }: { navigate: any; value?: CompanyData }) => {
  const initialState: TypeCompany = {
    name: value ? value?.name : '',
    showSubmit: false,
    validators: {
      name: nameValidator,
    },
    errors: {},
  };
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const dashboardView = useSelector(getDashboardView);
  const { t } = useTranslation();

  const handleErrorsIfNeeded = (res: any) => {
    if (!res.errors) {
      return false;
    }

    const newState = setErrorsInState(state, res.errors);
    state.errors = newState.errors;
    state.showSubmit = newState.showSubmit;
    setState({ ...state });

    return true;
  };

  const handleCompanyUpdate = (res: any) => {
    // error
    if (handleErrorsIfNeeded(res)) return;

    // success
    updateCompanyData(res.data, dispatch);
    closeAndResetModal(dispatch);
  };

  const handleCompanyCreate = async (res: any) => {
    // error
    if (handleErrorsIfNeeded(res)) return;

    // success
    const companyID = res.data.id;

    const shopData = await createShop({ companyID, name: res.data.name });
    // shop create error
    if (handleErrorsIfNeeded(shopData)) return;

    // shop create sucess
    const slotData = await createSlot({ shopID: shopData.data.id });
    // slot create error
    if (handleErrorsIfNeeded(slotData)) return;

    // all sucess
    switchCompany(companyID, t, dashboardView === DashboardView.MOBILE, dispatch);
    navigate('/statistic/general');
  };

  const createCompany = ({ name }: { name: string }) =>
    callService<{ input: CreateCompanyInput }>({ input: { name } }, mutations.createCompany, 'createCompany');

  const updateCompany = ({ id, name }: { id: string; name: string }) =>
    callService<{ input: UpdateCompanyInput }>({ input: { id, name } }, mutations.updateCompany, 'updateCompany');

  const createShop = ({ companyID, name }: { companyID: string; name: string }) =>
    callService<{ input: CreateShopInput }>(
      { input: { companyID, name, brandName: name } },
      mutations.createShop,
      'createShop',
      false,
    );

  const createSlot = ({ shopID }: { shopID: string }) =>
    callService<{ input: CreateSlotInput }>(
      { input: { shopID, name: t('settings.slot.SLOT') } },
      mutations.createSlot,
      'createSlot',
      false,
    ).then((slotData: any) => {
      if (slotData.errors) return slotData;

      return callService('', queries.getAccount, 'getAccount').then((accountData: any) => {
        if (accountData.errors) return accountData;

        return callService<{ input: SendSlotEmailInput }>(
          { input: { id: slotData.data.id, email: accountData.data.email } },
          mutations.sendSlotEmail,
          'sendSlotEmail',
          false,
        );
      });
    });

  const handleSubmit = () => {
    setState({ ...handleBlur(state, 'name'), showSubmit: false });

    if (!findErrorsInState(state.errors)) {
      if (value?.id) {
        updateCompany({ id: value.id, name: state.name }).then(handleCompanyUpdate);
      } else {
        console.log('creating a company');
        createCompany({ name: state.name }).then(handleCompanyCreate);
      }
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalTitle}>
        {value?.id ? t('account.companies.modal.EDIT') : t('account.companies.modal.CREATE')}
      </div>
      <Input
        label={t('account.companies.modal.NAME')}
        value={state.name}
        onChange={event => setState(handleChange(state, 'name')(event ? event.toString() : ''))}
        onBlur={() => setState(handleBlur(state, 'name'))}
        style={{
          status: state.errors?.name?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
        }}
        helperText={state.errors?.name?.value ? state.errors.name.message : undefined}
        dataCy={generateDataCy({ scope: 'account', value: 'company' })}
      />
      <div className={styles.modalButtons}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          state={ComponentState.DEFAULT}
          status={ComponentStatus.DEFAULT}
          onClick={() => closeAndResetModal(dispatch)}
        />
        <Button
          title={t('button.SAVE')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default CompanyModal;
