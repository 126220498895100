import { TypeColumnConfiguration } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { DropDownCell } from '../../components/list/cell'
import { BackgroundJobState, GetImportFileQueryVariables } from '../../API'
import { MoreHoriz } from '../../assets/icons'
import IconTouch from '../../components/newComponents/icons/iconTouch'
import Chip from '../../components/newComponents/chip'
import { importStateToStr } from '../../utils/typeToType'
import { ComponentState } from '../../components/newComponents/types'
import { callService } from '../../services'
import { getImportFile } from '../../graphql/queries'

const IMPORT = 'import'

const importsCols: TypeColumnConfiguration[] = [
  {
    id: 'listImports',
    type: IMPORT,
    cols: [
      {
        id: 'createdAt',
        title: 'CREATED_AT',
        type: 'function',
        field: 'createdAt',
        fieldType: 'date',
        active: true,
        function: (date) => displayDateShortMonthWithHours(date),
      },
      {
        id: 'type',
        title: 'TYPE',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, t }) => {
          if (value.type.startsWith('MIGRATION'))
            return value.sourceEmail
              ? t('settings.import.V4') + ' (' + value.sourceEmail + ')'
              : t('settings.import.V4')
          return 'Excel'
        },
      },
      {
        id: 'type',
        title: 'TYPE',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, t }) =>
          t('settings.import.modal.import.type.' + value.type.substr(value.type.indexOf('_') + 1)),
      },
      {
        id: 'state',
        title: 'STATE',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, t }) => {
          let errors = 0
          if (value.details)
            Object.entries(value.details).forEach(([_, value]: any) => {
              if (value && value.errorCount) errors += value.errorCount
            })
          return Chip(importStateToStr(value.state, t, value.reportUrl, errors))
        },
      },
      {
        id: 'more',
        title: '',
        type: 'element',
        active: true,
        function: ({ value, t, index }: any) => {
          const items = []
          //! WARNING ajouter un if pour sheeid
          if (value.type.startsWith('IMPORT')) {
            items.push({
              id: 'downloadImport',
              value: t('settings.import.DOWNLOAD_IMPORT'),
              onClick: () => {
                callService<GetImportFileQueryVariables>(
                  {
                    sheetID: value.flatfileSheetId,
                  },
                  getImportFile,
                  'getImportFile'
                ).then((res) => {
                  // Create a Blob containing the CSV data
                  const csvBlob = new Blob([res.data], { type: "text/csv" });
                  // Create a URL for the Blob
                  const csvUrl = URL.createObjectURL(csvBlob);

                  // Create an invisible link element to trigger the download
                  const link = document.createElement("a");
                  link.href = csvUrl;
                  link.download = `${value.flatfileSheetId}.csv`;

                  link.click();

                  // Clean up by revoking the URL to release resources
                  URL.revokeObjectURL(csvUrl);
                })
              },
            })
          }
          if (value.reportUrl)
            items.push({
              id: 'downloadError',
              value: t('settings.import.DOWNLOAD_ERROR'),
              onClick: () => {
                const anchor = document.createElement('a')
                anchor.href = value.reportUrl
                anchor.download = value.reportUrl
                document.body.appendChild(anchor)
                anchor.click()
                document.body.removeChild(anchor)
              },
            })
          return DropDownCell({
            fieldID: 'more',
            index,
            state:
              value.reportUrl || (value.type.startsWith('IMPORT') && value.state === BackgroundJobState.SUCCESS)
                ? ComponentState.DEFAULT
                : ComponentState.INVISIBLE,
            Element: IconTouch({
              Icon: MoreHoriz,
              color: 'var(--sys-color-content-secondary)',
            }),
            items,
          })
        },
      },
    ],
    creatable: true,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
    creatablePlaceholder: 'settings.import.modal.NEW',
  },
]

export default importsCols
