import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import styles from "../../../../components/newComponents/modal/Modal.module.css";
import generateDataCy from "../../../../utils/cypress";
import { ComponentSize, ComponentType } from '../../../../components/newComponents/types';
import { closeAndResetModal } from "../../../../actions/modal";
import Button from "../../../../components/newComponents/button";
import { DataWithErrors } from "../../../../services/types";
import { mergeCustomer } from "../detail/service/create";
import { useState } from "react";
import { drawer } from "../../../../actions/drawer";
import { DrawerType } from '../../../../store/types';

export const FusionModal = ({ input, number, close }: { input: any, number: number, close: () => void }) => {


  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'customer', value: generateDataCy({scope: 'fusion', value: 'modal'}) })

  const handleSubmit = () => {
    setIsLoading(true)
    mergeCustomer(input).then((customer: DataWithErrors) => {
      if (!customer.errors) {
        setIsLoading(false)
        closeAndResetModal(dispatch)
        drawer(
          {
            type: DrawerType.EDIT,
            id: customer.data.id,
            open: true,
          },
          dispatch
        )
      }
    })
  }

  return (
    <div className={styles.modal} data-cy={cyContext}>
      {isLoading ? <div className={styles.modalLoader}><div className={styles.loader}></div></div> : <>
        <div className={styles.modalTitleContainer}>
          <div className={styles.modalTitleCustom}>
            {t('customer.modal.TITLE')}
          </div>
        </div>
        <div className={styles.modalContentCustom}>
          <div className={styles.modalImportantInfo}>
            {t('customer.modal.CONTENT_TEXT', {
              number,
            })}
          </div>
          <br />
          <div className={styles.modalImportantInfo}>
            {t('customer.modal.CONTENT_SUB_TEXT', {
              number,
            })}
          </div>
        </div>
        <div className={styles.modalButtonsCustom}>
          <Button
            title={t('customer.modal.BUTTON_CANCEL')}
            size={ComponentSize.LARGE}
            type={ComponentType.TERTIARY}
            onClick={close}
            dataCy={generateDataCy({scope: cyContext, value: "cancel"})}
          />
          <Button
            title={t('customer.modal.BUTTON_VALIDATE')}
            size={ComponentSize.LARGE}
            type={ComponentType.PRIMARY}
            onClick={() => handleSubmit()}
            dataCy={generateDataCy({scope: cyContext, value: "submit"})}
          />
        </div>
      </>}
    </div>
  )
}