import Stat from '.'
import { displayNumber } from '../../../utils/number'
import { TypeCard } from '../../../types'
import generateDataCy from '../../../utils/cypress'

import styles from './Stat.module.css'

const cyContext = generateDataCy({ scope: 'stat', value: 'card' })

const StatCard = ({ cards }: { cards: Array<TypeCard> }) => (
  <div className={styles.containerCard}>
    {cards?.map((card, index) => (
      <div className={styles.card} key={index} data-cy={generateDataCy({ scope: cyContext, value: card.id })}>
        <Stat key={index} text={card.text} value={card.value} />
      </div>
    ))}
  </div>
)

export default StatCard
