import { InventoryMovementOrigin, InventoryMovementDestination } from '../../API'

const getFromInventoryMovement = (shops: Array<any>, origin: string, originID: string, t: (field: string) => string) =>
  origin === InventoryMovementOrigin.SUPPLIER
    ? t('catalog.inventoryMovement.SUPPLIER')
    : shops.find((shop) => shop.id === originID)
    ? shops.find((shop) => shop.id === originID)?.name
    : t('catalog.inventoryMovement.ORIGIN')

const getToInventoryMovement = (
  shops: Array<any>,
  destination: string,
  destinationID: string,
  t: (field: string) => string
) =>
  destination === InventoryMovementDestination.TRASH
    ? t('catalog.inventoryMovement.LOSS')
    : shops.find((shop) => shop.id === destinationID)
    ? shops.find((shop) => shop.id === destinationID)?.name
    : t('catalog.inventoryMovement.DESTINATION')

export { getFromInventoryMovement, getToInventoryMovement }
