import {
  getIconTitleInfoLine,
  getIconTitleInfoActionLine,
  getIconTitleActionLine,
  generateIconTitleLine,
} from '../../../../components/oldComponents/activity/lines'
import { ColorPalette, PaymentMethodType } from '../../../../API'
import Title from '../../../../components/oldComponents/activity/title'
import { Route } from '../../../../utils/navigation'
import { displayFullName } from '../../../../utils/string'
import { displayDateWithHours } from '../../../../utils/date'
import { displayPrice } from '../../../../utils/number'
import { MenuCustomer } from '../../../customer/drawer'
import { TypeCompanyCurrency } from '../../../../types/currency'
import { MenuCashbook } from '../../cashbook/drawer'
import { paymentMethodToComponent } from '../../../../utils/typeToType'
import { TypePayment } from '../../../../types'
import { MenuSale } from '../../sale/drawer'
import { DrawerState } from '../../../../store/types'
import { updateDrawerState } from '../../../../actions/drawer'
import { ComponentSize, ComponentStatus } from '../../../../components/newComponents/types'
import Chip from '../../../../components/newComponents/chip'
import DisplayIcon2 from '../../../../components/newComponents/icons'

import { ArrowForward, Euro, Calendar, Shop, Till, Cashbook, People } from '../../../../assets/icons'

const getAmountLine = (
  company: TypeCompanyCurrency,
  amount?: number,
  payment?: {
    type: PaymentMethodType
    color: ColorPalette
    name: string
  }
) =>
  payment
    ? getIconTitleInfoLine(
      'amount',
      {
        icon: DisplayIcon2({
          Icon: paymentMethodToComponent(payment.type, payment.color).component,
          color: paymentMethodToComponent(payment.type, payment.color).color,
        }),
      },
      <Title mainTexts={[payment.name]} />,
      <Title mainTexts={[displayPrice(amount!, company.currency, company.currencyDecimals)]} />
    )
    : undefined

const getSaleLine = (
  t: (value: string) => string,
  addHistory: (route: Route) => void,
  dispatch: any,
  company: TypeCompanyCurrency,
  sale?: {
    id: string
    number: number
    total: number
  }
) =>{ 
  console.log("sale?.number", sale?.number)
  return sale
    ? getIconTitleInfoActionLine(
      'sale',
      {
        icon: DisplayIcon2({
          Icon: Euro,
          color: 'var(--sys-color-content-secondary)',
        }),
      },
      <Title
        topText={t('activity.payment.ASSOCIATED_SALE')}
        mainTexts={[
          t('activity.payment.SALE') +
          (sale.number ? (' #' + sale.number.toString()) : '')
        ]}
      />,
      <Title mainTexts={sale.total ? [displayPrice(sale.total, company.currency, company.currencyDecimals)] : [""]} />,
      {
        icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
        bulkToAction: () => {
          updateDrawerState(DrawerState.LOADING, dispatch)
          addHistory({
            component: MenuSale,
            params: { id: sale.id },
          })
        },
      }
    )
    : undefined
}


const getCashbookLine = (
  t: (value: string) => string,
  addHistory: (route: Route) => void,
  dispatch: any,
  cashbook?: {
    id: string
    number: number
    status: {
      status: ComponentStatus
      text: string
    }
  }
) =>
  cashbook
    ? getIconTitleInfoActionLine(
      'cashbook',
      {
        icon: DisplayIcon2({
          Icon: Cashbook,
          color: 'var(--sys-color-content-secondary)',
        }),
      },
      <Title topText={t('activity.payment.CASHBOOK')} mainTexts={['#' + cashbook.number]} />,
      <Chip size={ComponentSize.MEDIUM} {...cashbook.status} />,
      {
        icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
        bulkToAction: () => {
          updateDrawerState(DrawerState.LOADING, dispatch)
          addHistory({
            component: MenuCashbook,
            params: { id: cashbook.id },
          })
        },
      }
    )
    : undefined

const getCustomerLine = (
  t: (value: string) => string,
  addHistory: (route: Route) => void,
  dispatch: any,
  customer?: {
    id: string
    firstName: string
    lastName: string
    color: ColorPalette
    iconText: string
  }
) =>
  customer
    ? getIconTitleActionLine(
      'customer',
      {
        icon: DisplayIcon2({
          Icon: People,
          color: 'var(--sys-color-content-secondary)',
        }),
      },
      <Title
        topText={t('activity.payment.CUSTOMER')}
        mainTexts={[displayFullName(customer.firstName, customer.lastName)]}
      />,
      {
        icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
        bulkToAction: () => {
          updateDrawerState(DrawerState.LOADING, dispatch)
          addHistory({
            component: MenuCustomer,
            params: { id: customer.id },
          })
        },
      }
    )
    : undefined

const getAllLines = (
  t: (value: string) => string,
  paymentState: TypePayment,
  addHistory: (route: Route) => void,
  dispatch: any,
  company: TypeCompanyCurrency
): {
  infos: Array<any>
} => {
  const infos = [
    getAmountLine(company, paymentState.amount, paymentState.payment),
    getSaleLine(t, addHistory, dispatch, company, paymentState.sale),
    paymentState.date
      ? generateIconTitleLine('date', displayDateWithHours(paymentState.date), Calendar, t, 'activity.payment.DATE')
      : undefined,
    paymentState.shop
      ? generateIconTitleLine('shop', paymentState.shop.name, Shop, t, 'activity.payment.SHOP')
      : undefined,
    paymentState.sourceName
      ? generateIconTitleLine('source', paymentState.sourceName, Till, t, 'activity.payment.SOURCE')
      : undefined,
    getCashbookLine(t, addHistory, dispatch, paymentState.cashbook),
    getCustomerLine(t, addHistory, dispatch, paymentState.customer),
  ].filter((info) => info)
  return { infos }
}

export default getAllLines
