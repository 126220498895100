import { GetSaleQueryVariables, GetShopQueryVariables } from '../../API'
import { getCustomer as get } from '../../graphql/custom/queries'
import { displayDateWithHours } from '../../utils/date'
import { TypeMovement } from '../../types'
import { callService } from '..'

const getMovement = async (movement: any): Promise<TypeMovement | any> => {
  const sale = movement.saleID
    ? await callService<GetSaleQueryVariables>({ id: movement.saleID }, get, 'getSale')
    : null
  const shop = sale ? await callService<GetShopQueryVariables>({ id: sale.data.shopID }, get, 'getShop') : null
  return {
    amount: movement.amount,
    type: movement.type,
    createdAt: displayDateWithHours(movement.createdAt),
    shop: shop &&
      shop.data && {
        id: shop.data.id,
        name: shop.data.name,
      },
    sale: sale && {
      id: sale.data.id,
      number: sale.data.number,
      total: sale.data.total,
    },
    payment: movement.paymentID && {
      id: movement.paymentID,
      number: movement.paymentID.slice(-5), // Need to get the right number
    },
    paymentMethod: movement.movementInDetails && {
      type: movement.movementInDetails.paymentMethodType,
      name: movement.movementInDetails.paymentMethodName,
    },
  }
}

const getExtraInfos = (movements: any): Promise<Array<TypeMovement>> => Promise.all(movements.items.map(getMovement))

export default getExtraInfos
