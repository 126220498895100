import { Product } from '../API';
import { objectType, Action, ListState, ListSort, ProductWitSubValues, Movement } from '../store/types';

const fetchListElement = (dispatch: any, items: Array<any>, nextToken: string | undefined, type: string) => {
  dispatch({
    type: Action.FETCH_LIST_ITEM,
    payload: {
      items,
      nextToken,
      type,
    },
  });
};

const addListElement = (dispatch: any, items: Array<any>, nextToken: string, type: string, productID?: string) => {
  dispatch({
    type: Action.ADD_LIST_ITEM,
    payload: {
      items,
      nextToken,
      type,
      productID,
    },
  });
};

const updateListElement = ({ dispatch, element, type }: { dispatch: any; element: any; type: objectType }) => {
  if (element)
    dispatch({
      type: Action.UPDATE_LIST_ELEMENT,
      payload: {
        element,
        type,
      },
    });
};

const addVariantToInventoryMovement = ({
  element,
  id,
  dispatch,
  type,
}: {
  element: any;
  id: string;
  dispatch: any;
  type: objectType;
}) => {
  if (element)
    dispatch({
      type: Action.ADD_VARIANT_INVENTORY_MOVEMENT,
      payload: {
        element,
        id,
        type,
      },
    });
};

const addNewLinetoInventoryMovement = ({
  element,
  id,
  dispatch,
  type,
}: {
  element: any;
  id: string;
  dispatch: any;
  type: objectType;
}) => {
  if (element)
    dispatch({
      type: Action.ADD_LINES_INVENTORY_MOVEMENT,
      payload: {
        element,
        id,
        type,
      },
    });
};

const addProductToInventoryMovement = ({
  element,
  dispatch,
  type,
}: {
  element: any;
  dispatch: any;
  type: objectType;
}) => {
  if (element)
    dispatch({
      type: Action.ADD_INVENTORY_MOVEMENT,
      payload: {
        element,
        type,
      },
    });
};

const createCustomFieldValue = (dispatch: any, element: any, type: objectType) => {
  if (element)
    dispatch({
      type: Action.CREATE_CUSTOM_FIELDS_VALUES,
      payload: {
        element,
        type,
      },
    });
};

const updateCustomFieldValue = (dispatch: any, element: any, type: objectType) => {
  if (element)
    dispatch({
      type: Action.UPDATE_CUSTOM_FIELDS_VALUES,
      payload: {
        element,
        type,
      },
    });
};

const deleteCustomFieldValue = (dispatch: any, element: any, type: objectType) => {
  if (element)
    dispatch({
      type: Action.DELETE_CUSTOM_FIELDS_VALUES,
      payload: {
        element,
        type,
      },
    });
};
const updateVariantElementSubscription = (subscriptionResult: any, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_VARIANT_ELEMENT,
    payload: {
      subscriptionResult,
    },
  });
};

const deleteListElement = ({ dispatch, element, type }: { dispatch: any; element: any; type: objectType }) => {
  if (element)
    dispatch({
      type: Action.DELETE_LIST_ELEMENT,
      payload: {
        element,
        type,
      },
    });
};

const sortListElement = (dispatch: any, field: string, direction: string, objectType: objectType) =>
  dispatch({
    type: Action.SORT_LIST_ELEMENT,
    payload: {
      objectType,
      field,
      direction,
    },
  });

const openProductList = (dispatch: any) => {
  dispatch({
    type: Action.CHANGE_COLS_CONF,
    payload: { type: objectType.PRODUCT, confId: 'requestProducts' },
  });
};

const updateListState = (payload: { type: string; state: ListState }, dispatch: any) =>
  dispatch({
    type: Action.UPDATE_LIST_STATE,
    payload,
  });

const updateList = (payload: { type: string; editValues?: any; errors?: any }, dispatch: any) =>
  dispatch({
    type: Action.UPDATE_LIST,
    payload,
  });

const unSelectAllList = (dispatch: any) => {
  dispatch({ type: Action.UNSELECT_ALL_LIST });
};

const unSelectProductsById = (ids: Array<string>, dispatch: any) => {
  dispatch({
    type: Action.UNSELECT_PRODUCTS_BY_ID,
    payload: {
      ids,
    },
  });
};

const unSelectVariantById = (productID: string, subID: string, type: objectType, dispatch: any) => {
  dispatch({
    type: Action.UNSELECT_VARIANT_BY_ID,
    payload: {
      productID,
      subID,
      type,
    },
  });
};

const updateProductInventoryQuantities = (element: Product, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_INVENTORY_QUANTITY,
    payload: {
      element,
    },
  });
};

const updateElementItems = (fullItems: Array<any>, type: string, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_ELEMENT_ITEMS,
    payload: {
      fullItems,
      type,
    },
  });
};

const updateAllSelectedItems = (value: boolean, type: string, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_ALL_SELECTED_ITEMS,
    payload: {
      value,
      type,
    },
  });
};

const updateSelectedItem = (element: Product, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_SELECTED_ITEM,
    payload: {
      element,
    },
  });
};

const updateParentSelectedItem = (element: Product, count: number, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_PARENT_ITEM_ONLY,
    payload: {
      element,
      count,
    },
  });
};

const updateProducSubscription = (element: Product, id: string, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_PRODUCT_SUBSCRIPTION_ONLY,
    payload: {
      element,
      id,
    },
  });
};

const updateOnlySelectedItem = (element: Product, type: string, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_ITEM_ONLY,
    payload: {
      element,
      type,
    },
  });
};

const updateBuyPriceList = (element: Array<Movement>, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_BUY_PRICE,
    payload: element,
  });
};

const updateVariantAfterSubscription = ({
  element,
  type,
  dispatch,
}: {
  element: any;
  type: objectType;
  dispatch: any;
}) => {
  dispatch({
    type: Action.UPDATE_VARIANT_AFTER_SUBSCRIPTION,
    payload: {
      element,
      type,
    },
  });
};

const updateSelectedSubItem = (subElement: Product, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_SELECTED_SUB_ITEM,
    payload: {
      subElement,
    },
  });
};

/**
 *
 * This method sort a column
 *
 * @usedIn - List
 *
 * @param isSorted - A boolean used to know the incoming sort to apply
 * @param fieldType - Entity's field type (string, number..)
 * @param field - Entity's field (name, rate..)
 * @param items - Items to filter
 *
 * @returns - A sorted list of items
 *
 * @author - Jennifer Charlois
 *
 */
const sortItems = (isSorted: boolean, fieldType: string, field: string, items?: Array<any>): Array<any> => {
  if (items)
    // eslint-disable-next-line array-callback-return
    items.sort((itemA, itemB) => {
      if (isSorted) {
        if (itemB[field] === itemA[field] && itemB.id && itemA.id)
          return itemB.id.localeCompare(itemA.id, 'fr', { sensitivity: 'base' });

        if (fieldType === 'string') return itemB[field].localeCompare(itemA[field]);
        if (fieldType === 'number') return itemB[field] - itemA[field];
        if (fieldType === 'date') {
          const dateA = new Date(itemA[field]);
          const dateB = new Date(itemB[field]);
          return dateB.getTime() - dateA.getTime();
        }
      } else {
        if (itemA[field] === itemB[field] && itemA.id && itemB.id)
          return itemB.id.localeCompare(itemA.id, 'fr', { sensitivity: 'base' });

        if (fieldType === 'string') return itemA[field].localeCompare(itemB[field]);
        if (fieldType === 'number') return itemA[field] - itemB[field];
        if (fieldType === 'date') {
          const dateA = new Date(itemA[field]);
          const dateB = new Date(itemB[field]);
          return dateA.getTime() - dateB.getTime();
        }
      }
    });

  return items ?? [];
};

const sortSubItems = (
  isSorted: boolean,
  fieldType: string,
  field: string,
  subField: string,
  items?: Array<any>,
): Array<any> => {
  if (items)
    items.sort((itemA, itemB) => {
      if (isSorted) {
        if (itemB[field][subField] === itemA[field][subField] && itemB.id && itemA.id)
          return itemB.id.localeCompare(itemA.id, 'fr', { sensitivity: 'base' });

        if (fieldType === 'string') return itemB[field][subField].localeCompare(itemA[field][subField]);
        return itemB[field][subField] - itemA[field][subField];
      }
      if (itemA[field][subField] === itemB[field][subField] && itemA.id && itemB.id)
        return itemB.id.localeCompare(itemA.id, 'fr', { sensitivity: 'base' });

      if (fieldType === 'string') return itemA[field][subField].localeCompare(itemB[field][subField]);
      return itemA[field][subField] - itemB[field][subField];
    });

  return items ?? [];
};

const sortList = (cols: any, fullItems: { items?: Array<any>; sort?: ListSort }) => {
  let items = fullItems ? fullItems.items : [];
  let field = fullItems && fullItems.sort ? fullItems.sort.field : '';
  let direction = fullItems && fullItems.sort ? fullItems.sort.direction : '';
  let fieldType = '';

  if (field) fieldType = cols.cols.find((col: { id: string }) => col.id === field).fieldType;

  if (field && field === 'categoryName') {
    const excludeItems = items?.filter((item: any) => item.category.excludeFromSort || item.category.name === null);
    const includeItems = items?.filter((item: any) => !item.category.excludeFromSort && item.category.name !== null);
    return [
      ...sortSubItems(direction === 'desc', fieldType, 'category', 'name', includeItems),
      ...(excludeItems ?? []),
    ];
  }
  if (field && field === 'taxRate') {
    const excludeItems = items?.filter((item: any) => item.tax.excludeFromSort || item.tax.rate === null);
    const includeItems = items?.filter((item: any) => !item.tax.excludeFromSort && item.tax.rate !== null);
    return [...sortSubItems(direction === 'desc', fieldType, 'tax', 'rate', includeItems), ...(excludeItems ?? [])];
  }
  const excludeItems = items?.filter((item: any) => item.excludeFromSort || item[field] === null);
  const includeItems = items?.filter((item: any) => !item.excludeFromSort && item[field] !== null);

  return [
    ...sortItems(direction === 'desc', fieldType, field, includeItems).map(sItem => {
      const excludeSubItems = sItem.subValues ? sItem.subValues.filter((sValue: any) => sValue[field] === null) : [];
      const includeSubItems = sItem.subValues ? sItem.subValues.filter((sValue: any) => sValue[field] !== null) : [];

      return {
        ...sItem,
        subValues: sItem.subValues
          ? [...sortItems(direction === 'desc', fieldType, field, includeSubItems), ...excludeSubItems]
          : undefined,
      };
    }),
    ...(excludeItems ?? []),
  ];
};

const sortArrayOfObjects = (arr: Array<ProductWitSubValues>, field: string, order: string) => {
  return arr.sort((itemA, itemB) => {
    if (order === 'desc') {
      //@ts-ignore
      if (field === 'categoryName') return itemB.category.name.localeCompare(itemA.category.name);
      //@ts-ignore
      if (itemB[field] === itemA[field] && itemB.id && itemA.id)
        return itemB.id.localeCompare(itemA.id, 'fr', { sensitivity: 'base' });
      //@ts-ignore
      if (field === 'buyPrice' || field === 'sellPrice') return itemB[field] - itemA[field];
      //@ts-ignore
      if (typeof field === 'date') {
        const dateA = new Date(itemA[field]);
        const dateB = new Date(itemB[field]);
        return dateB.getTime() - dateA.getTime();
      }
      //@ts-ignore
      return itemB[field].localeCompare(itemA[field]);
    } else {
      //@ts-ignore
      if (field === 'categoryName') return itemA.category.name.localeCompare(itemB.category.name);
      //@ts-ignore
      if (itemA[field] === itemB[field] && itemA.id && itemB.id)
        return itemB.id.localeCompare(itemA.id, 'fr', { sensitivity: 'base' });
      //@ts-ignore
      if (field === 'buyPrice' || field === 'sellPrice') return itemA[field] - itemB[field];
      //@ts-ignore
      if (typeof field === 'date') {
        const dateA = new Date(itemA[field]);
        const dateB = new Date(itemB[field]);
        return dateA.getTime() - dateB.getTime();
      }
      //@ts-ignore
      return itemA[field].localeCompare(itemB[field]);
    }
  });
};

export {
  fetchListElement,
  addListElement,
  updateListElement,
  updateVariantElementSubscription,
  deleteListElement,
  sortListElement,
  openProductList,
  updateList,
  updateListState,
  unSelectAllList,
  unSelectProductsById,
  unSelectVariantById,
  updateElementItems,
  updateAllSelectedItems,
  sortItems,
  sortSubItems,
  sortList,
  sortArrayOfObjects,
  updateSelectedItem,
  updateSelectedSubItem,
  updateParentSelectedItem,
  updateOnlySelectedItem,
  updateVariantAfterSubscription,
  updateProducSubscription,
  updateProductInventoryQuantities,
  createCustomFieldValue,
  updateCustomFieldValue,
  deleteCustomFieldValue,
  addVariantToInventoryMovement,
  addProductToInventoryMovement,
  addNewLinetoInventoryMovement,
  updateBuyPriceList,
};
