import { ComponentSize, ComponentType } from '../types'
import styles from './ProgressBar.module.css'

type Props = {
  size: ComponentSize
  type: ComponentType
  progress: number
}

/**
 *
 * This element displays a progress bar
 *
 * @param size - Progress's size
 * @param type - Progress's type
 * @param progress - Progress's percentage
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ProgressBar = ({ size, type, progress }: Props): JSX.Element => {
  const getProgressBarSize = () => ({
    height: `var(--comp-progress-bar-height)`,
  })

  const getProgressBarFillSize = () => ({
    ...getProgressBarSize(),
    width: `${progress}%`,
    transition: `width 0.5s linear`
  })

  return (
    <div className={styles.progressBar} style={getProgressBarSize()}>
      <div className={styles.progressBarFill} style={getProgressBarFillSize()} />
    </div>
  )
}

export default ProgressBar
