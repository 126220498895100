import { currencyTransformer } from '../../../components/requester/query'
import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../../components/requester/types'

const optionFieldsRequester: Array<TypeRequester> = [
  {
    type: TypePath.PLAIN,
    filterID: 'NAME',
    field: 'name',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'OPTION_NAME',
    field: 'name',
    path: ['values'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'OPTION_PRICE',
    field: 'price',
    path: ['values'],
    transformer: currencyTransformer,
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
    ],
  },
]

export default optionFieldsRequester
