const ReceiptLong = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1614 9H10.1614C9.61138 9 9.16138 8.55 9.16138 8C9.16138 7.45 9.61138 7 10.1614 7H14.1614C14.7114 7 15.1614 7.45 15.1614 8C15.1614 8.55 14.7114 9 14.1614 9Z"
      fill={color}
    />
    <path
      d="M14.1614 12H10.1614C9.61138 12 9.16138 11.55 9.16138 11C9.16138 10.45 9.61138 10 10.1614 10H14.1614C14.7114 10 15.1614 10.45 15.1614 11C15.1614 11.55 14.7114 12 14.1614 12Z"
      fill={color}
    />
    <path
      d="M19.6614 3.5L18.1614 2L16.6614 3.5L15.1614 2L13.6614 3.5L12.1614 2L10.6614 3.5L9.16138 2L7.66138 3.5L6.16138 2V16H4.16138C3.61138 16 3.16138 16.45 3.16138 17V19C3.16138 20.66 4.50138 22 6.16138 22H18.1614C19.8214 22 21.1614 20.66 21.1614 19V2L19.6614 3.5ZM15.1614 20H6.16138C5.61138 20 5.16138 19.55 5.16138 19V18H15.1614V20ZM19.1614 19C19.1614 19.55 18.7114 20 18.1614 20C17.6114 20 17.1614 19.55 17.1614 19V17C17.1614 16.45 16.7114 16 16.1614 16H8.16138V5H19.1614V19Z"
      fill={color}
    />
    <path
      d="M17.1614 9C17.7137 9 18.1614 8.55228 18.1614 8C18.1614 7.44772 17.7137 7 17.1614 7C16.6091 7 16.1614 7.44772 16.1614 8C16.1614 8.55228 16.6091 9 17.1614 9Z"
      fill={color}
    />
    <path
      d="M17.1614 12C17.7137 12 18.1614 11.5523 18.1614 11C18.1614 10.4477 17.7137 10 17.1614 10C16.6091 10 16.1614 10.4477 16.1614 11C16.1614 11.5523 16.6091 12 17.1614 12Z"
      fill={color}
    />
  </svg>
)

export default ReceiptLong
