import store from '../store'
import { InventoryCell } from '../components/list/cell'
import { inventory as inventoryValidator } from '../format/errors/filters/editableList'
import { TypeFormError } from '../format/errors/types'
import { objectType, TypeCol, TypeColumnConfiguration } from '../store/types'
import { displayNumber } from '../utils/number'
import { capitalizeFirstLetter } from '../utils/string'
import { addCols, updateCols, deleteCols } from './cols'

export type inventoryAction = {
  shopID: string
  productID: string
  inventory: number
}

/**
 *
 * This method generates a inventory column
 *
 * @usedIn - List
 *
 * @param id - Shop id
 * @param name - Shop name
 *
 * @returns - An object of column
 *
 * @author - Arthur Escriou
 *
 */
const generateInventoryCol = (shop: { id: string; name: string }) => ({
  id: shop.id,
  title: `Stock : ${shop.name}`,
  type: 'function',
  fieldType: 'shop',
  active: true,
  function: (product: { inventoryQuantities: any[] }) => {
    if (product.inventoryQuantities) {
      const inventory = product.inventoryQuantities.find(({ shopID }: { shopID: string }) => shopID === shop.id)
      return inventory ? displayNumber(inventory.quantity) : null
    }
    return null
  },
})

/**
 *
 * This method generates an editable inventory column
 *
 * @usedIn - Editable list
 *
 * @param id - Shop id
 * @param name - Shop name
 *
 * @returns - An object of column
 *
 * @author - Arthur Escriou
 *
 */
const generateEditableInventoryCol = (shop: { id: string; name: string }) => ({
  id: 'inventory' + shop.id,
  title: `Stock : ${shop.name}`,
  type: 'element',
  fieldType: 'shop',
  active: true,
  editable: true,
  validator: inventoryValidator,
  function: ({
    value,
    onChange,
    onBlur,
    onFocusNext,
    editValues,
    error,
  }: {
    value: any
    onChange: (val: any) => void
    onBlur: () => void
    onFocusNext: () => void
    editValues?: any
    error: TypeFormError
  }) =>
    InventoryCell({
      value,
      onChange,
      onBlur,
      onFocusNext,
      shopID: shop.id,
      editValues,
      error,
    }),
})

const generateInventoryColsConfiguration = (dispatch: any, listShops: any[]) => {
  const colsForProduct = listShops.map(generateInventoryCol)
  addCols(dispatch, colsForProduct, 'requestProducts')

  const colsForEditableProduct = listShops.map(generateEditableInventoryCol)
  addCols(dispatch, colsForEditableProduct, 'editProducts')
}

const createInventoryCol = (shop: { id: string; name: string }, dispatch: any) => {
  addCols(dispatch, [generateInventoryCol(shop)], 'requestProducts')
  addCols(dispatch, [generateInventoryCol(shop)], 'editProducts')
}

const buildNewInventoryCol = (element: string, shop: any, dispatch: any) => {
  // @ts-ignore
  const elementCols = store.getState().cols[element]
  const elementCol: TypeCol = generateInventoryCol(shop)
  const confIndex: number = elementCols.findIndex(
    (c: TypeColumnConfiguration) => c.id === 'request' + capitalizeFirstLetter(element)
  )
  const conf = elementCols[confIndex]
  if (conf) {
    const TypeCol = conf.cols.find((c: TypeCol) => c.id === shop.id)
    if (TypeCol) {
      TypeCol.title = elementCol.title
      TypeCol.function = elementCol.function
      updateCols(dispatch, elementCols)
    }
  }

  const editConfIndex: number = elementCols.findIndex((c: TypeColumnConfiguration) => c.id === 'editProducts')
  const editConf = elementCols[editConfIndex]
  if (editConf) {
    const TypeCol = editConf.cols.find((c: TypeCol) => c.id === 'inventory' + shop.id)
    if (TypeCol) {
      TypeCol.title = elementCol.title
      TypeCol.function = elementCol.function
      updateCols(dispatch, elementCols)
    }
  }
}
const updateInventoryCol = (shop: { id: string; name: string }, dispatch: any) => {
  buildNewInventoryCol('products', shop, dispatch)
}

const deleteInventoryCol = (id: string, dispatch: any) => {
  deleteCols(dispatch, id, objectType.PRODUCT)
}

export {
  generateInventoryCol,
  generateEditableInventoryCol,
  generateInventoryColsConfiguration,
  createInventoryCol,
  updateInventoryCol,
  deleteInventoryCol,
}
