import { StateStore, objectType, entities } from './types'
import { pluralize } from '../utils/typeToType'

const switchFilteredItemsToItems = ({ type }: { type: objectType }, state: StateStore): StateStore => {
  // @ts-ignore
  let entityType = state[pluralize(type)]

  if (type && entityType.filteredItems) {
    entityType = {
      ...entityType,
      items: {
        ...entityType.items,
        items: [
          ...entityType.filteredItems.items.map((filteredItem: any) => ({
            ...filteredItem,
            selected: filteredItem.selected,
          })),
          ...entityType.items.items.filter(
            (fItem: { id: string }) =>
              entityType.filteredItems?.items.findIndex((rRtem: { id: string }) => fItem.id === rRtem.id) === -1
          ),
        ],
      },
      filteredItems: undefined,
      query: undefined,
    }
  }

  return { ...state, [pluralize(type)]: entityType }
}

const requestItems = ({ type, data }: { type: objectType; data: any }, state: StateStore): StateStore => {
  // const infiniteScroll = data.items.length > state[pluralize(type)].items.items.length
  // @ts-ignore
  let entityType = state[pluralize(type)]
  if (type) {
    entityType = {
      ...entityType,
      items: { ...data },
      filteredItems: undefined,
      query: undefined,
    }
  }

  return { ...state, [pluralize(type)]: entityType }
}

/**
 *
 * This method is called after we got API's response from the requester
 * We update the store with this new list
 *
 * @usedIn - Requester
 *
 * @param filter - Filters's used
 * @param data - New list received from the API
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Arthur Escriou
 *
 */
const requestFilteredItems = (
  { query, type, data }: { query: any; type: objectType; data: any },
  state: StateStore
): StateStore => {
  // @ts-ignore
  let entityType = state[pluralize(type)]
  entityType = { ...entityType, filteredItems: { ...data }, query }
  // @ts-ignore
  return { ...state, [pluralize(type)]: entityType }
}

const toggleFilters = (
  { type, filters }: { type: objectType; filters: { requesterOpen: boolean; searchbarOpen: boolean } },
  state: StateStore
): StateStore => {
  // @ts-ignore
  let entityType = state[pluralize(type)]
  if (type) {
    entityType = { ...entityType, filters }
  }
  return { ...state, [pluralize(type)]: entityType }
}

/**
 *
 * This method is called when we need to clear all requester's list
 *
 * @usedIn - Requester
 *
 * @param state - The current store
 *
 * @returns - A new store
 *
 * @author - Jennifer Charlois
 *
 */
const clearAllFilters = (state: StateStore): StateStore => {
  entities.forEach((entity) => {
    const plural = pluralize(entity)
    // @ts-ignore
    state[plural] = {
      // @ts-ignore
      ...state[plural],
      filteredItems: undefined,
      query: undefined,
      filters: { requesterOpen: false, searchbarOpen: false },
    }
  })

  return { ...state }
}

export { switchFilteredItemsToItems, requestItems, requestFilteredItems, toggleFilters, clearAllFilters }
