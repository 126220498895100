/* eslint-disable no-use-before-define */
import messages from './fr.json';
import { Validator, ValidationFunction, filters, FiltersType, TypeFormError } from './types';
import { APIErrorMessageToHumanMessage, findField } from '../../assets/errorCodes/helper';

export const getMessage = (...args: string[]) => {
  const code: string | undefined = args.shift();
  // @ts-ignore
  if (code && Object.prototype.hasOwnProperty.call(messages, code)) {
    // @ts-ignore
    const message = messages[code];
    const returnMessage: string = args.reduce(
      (acc: string, val: string, index: number) => acc.replace(`{${index}}`, args[index]),
      message,
    );
    return returnMessage;
  }
  return '';
};

const check: (functions: Array<ValidationFunction>) => ValidationFunction =
  (functions: Array<ValidationFunction>) => (val: any) =>
    functions.find((f: ValidationFunction) => f(val))?.(val);

/**
 *
 * This method creates a full validator object which is executed every time we need to validate a field
 *
 * @param functions - All needed functions
 *
 * @returns - A complete validator
 *
 * @author - Arthur Escriou
 *
 */
const validator: (functions?: Array<ValidationFunction>) => Validator = (functions: Array<ValidationFunction> = []) => {
  const res: any = { functions: [...functions], check: check([...functions]) };
  res.chain = chain(res);

  buildFilters(filters, res).forEach(
    ({ key, filter }: { key: string; filter: (any: any) => Validator }) => (res[key] = filter),
  );

  return res;
};

/**
 *
 * This method makes a copy of passed validator, more exactly his check method to make a validation function of current validator
 *
 * @param current - Current validator
 * @param toCopy - Passed validator
 *
 * @returns - The new concatened validator
 *
 * @author - Arthur Escriou
 *
 */
const chain = (current: Validator) => (toCopy: Validator) => validator(current.functions.concat(toCopy.check));

const buildFilter = ({ key, filter, v }: { key: string; filter: (val: any) => ValidationFunction; v: Validator }) => ({
  key,
  filter: (arg: any) => validator([v.check, filter(arg)]),
});

const buildFilters = (filters: FiltersType, v: Validator) =>
  Object.entries(filters).map(([key, filter]: [string, (val: any) => ValidationFunction]) =>
    buildFilter({ key, filter, v }),
  );

const setErrorsInState = (state: any, errors: any) => {
  if (errors) {
    return {
      ...state,
      showSubmit: false,
      errors: {
        ...state.errors,
        [findField(errors.errorInfo, errors.message)]: {
          value: true,
          message: APIErrorMessageToHumanMessage(errors.message, errors.errorType),
          errorType: errors.errorType,
        },
      },
    };
  }
};

const findValidError = (arr: Array<TypeFormError>) => arr.find((e: TypeFormError) => e.value);

const findErrorsInState = (errors: any): boolean | undefined =>
  Object.values(errors)
    .filter(e => e)
    // @ts-ignore
    .find((e: TypeFormError | Array<TypeFormError>) => {
      // @ts-ignore
      if (e.length) {
        // @ts-ignore
        return !!findValidError(e);
      }
      // @ts-ignore
      return e.value;
    });

export { validator, findErrorsInState, setErrorsInState };
