import { TypeTag } from '../../types'
import { StateStore, TagAssociations } from '../../store/types'

/* Used for subscriptions */

const removeTagFromProduct = (payload: { tagID: string; dispatch: any }, state: StateStore) => {
  const { tagID } = payload
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map((item) => ({
          ...item,
          tags: {
            ...item.tags,
            items: item.tags.items.filter((tag: any) => tag.tag && tag.tag.id !== tagID),
          },
        })),
      },
    },
    tagsAssociations: state.tagsAssociations.filter((tag) => tag.tagID !== tagID),
  }
}

const deleteTagsData = (payload: { tagID: string; productID: string; dispatch: any }, state: StateStore) => {
  const { tagID, productID } = payload
  const tagAssociationNumber = state.tagsAssociations.find((tag) => tag.tagID === tagID)?.products.length
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map((item) =>
          item.id === productID
            ? {
                ...item,
                tags: {
                  ...item.tags,
                  items: item.tags.items.filter((tag: any) => tag.tag && tag.tag.id !== tagID),
                },
              }
            : item
        ),
      },
    },
    tagsAssociations:
      // if there is only one tag association between this tagID and productID, just remove the association
      // else remove only the product and keep other associations
      tagAssociationNumber === 1
        ? state.tagsAssociations.filter((tag) => tag.tagID !== tagID)
        : state.tagsAssociations.map((tag) =>
            tag.tagID === tagID
              ? {
                  ...tag,
                  products: tag.products.filter((product) => product !== productID),
                }
              : tag
          ),
  }
}

const updateTagsData = (payload: { tagID: string; productID: string; dispatch: any }, state: StateStore) => {
  const { tagID, productID } = payload
  // add tags data to product
  const tagData = state.tags.items.items.find((tag) => tag.id === tagID)
  const existingTags = state.tagsAssociations.find((tag) => tag.tagID === tagID)
  // updateTagsAssociation
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map((item) =>
          item.id === productID
            ? {
                ...item,
                tags: {
                  ...item.tags,
                  items: [...item.tags.items, { tag: { ...tagData } }],
                },
              }
            : item
        ),
      },
    },
    tagsAssociations: existingTags
      ? state.tagsAssociations.map((tag) =>
          tag.tagID === tagID ? { ...tag, products: [...tag.products, productID] } : tag
        )
      : [...state.tagsAssociations, { tagID, products: [productID] }],
  }
}

const updateTagsAssociations = (payload: { tagID: string; dispatch: any }, state: StateStore) => {
  const tagDataToUpdate = state.tags.items.items.find((tag) => tag.id === payload.tagID)
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map((item) => ({
          ...item,
          tags: {
            ...item.tags,
            items: item.tags.items.map((tag: any) =>
              tag.tag && tag.tag.id === payload.tagID
                ? {
                    ...tag,
                    tag: {
                      ...tag.tag,
                      ...tagDataToUpdate,
                    },
                  }
                : tag
            ),
          },
        })),
      },
    },
  }
}

const getProductCount = (items: Array<any>, id: string) =>
  items
    .filter((item: any) => item.tags && item.tags.items && item.tags.items.length > 0)
    .filter((item: any) =>
      item.tags.items.filter((tag: { tag: TypeTag }) => tag.tag).find((tag: { tag: TypeTag }) => tag.tag.id === id)
    )
    .map((item) => item.id)

/**
 *
 * This method gets new tag associations related to product entity
 *
 * @usedIn - Subscription
 *
 * @param state - Store
 *
 * @returns - New associations
 *
 * @author - Jennifer Charlois
 *
 */
const getTagsAssociations = (items: Array<any>): Array<TagAssociations> => {
  const tagIds = items
    .filter((item) => item.tags && item.tags.items)
    .flatMap((item: { tags: { items: [{ tag: { id: string } }] } }) =>
      item.tags.items.filter(({ tag }) => tag).map(({ tag }) => tag.id)
    )
    .sort()
    .filter((id: string, index: number, tab) => tab.indexOf(id) === index)
    .map((id: string) => id)

  return tagIds.map((id: string) => ({
    tagID: id,
    products: getProductCount(items, id),
  }))
}

const updateTagOnFilteredItems = (state: StateStore, element: TypeTag) => {
  const index = state.tags.filteredItems!.items.findIndex(({ id }: { id: string }) => id === element.id)
  if (index >= 0) {
    state.tags.filteredItems = {
      ...state.tags.filteredItems,
      items: [...state.tags.filteredItems!.items],
    }
    state.tags.filteredItems.items[index] = element
  } else {
    state.tags.filteredItems = {
      items: [element, ...state.tags.filteredItems!.items],
    }
  }

  return state
}

const deleteTagOnFilteredItems = (state: StateStore, id: string) => {
  state.tags.filteredItems = {
    items: state.tags.filteredItems!.items.filter((customField: { id: string }) => customField.id !== id),
  }
  return state
}

const filterTags = (payload: { search: string }, state: StateStore): StateStore => {
  state.tags = payload.search
    ? {
        ...state.tags,
        filteredItems: {
          items: state.tags.items.items.filter((tag) =>
            tag.name.toString().toLowerCase().includes(payload.search!.toString().toLowerCase())
          ),
        },
      }
    : { ...state.tags, filteredItems: undefined }
  return { ...state }
}

export {
  getTagsAssociations,
  updateTagsData,
  deleteTagsData,
  removeTagFromProduct,
  updateTagsAssociations,
  updateTagOnFilteredItems,
  deleteTagOnFilteredItems,
  filterTags,
}
