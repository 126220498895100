/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ListState, MenuState, objectType } from '../../../../store/types'
import List from '../../../../components/list'
import {
  listInventoryMovementProductItems,
  listInventoryMovementProductCols,
  listInventoryMovementProductNextToken,
  listInventoryMovementsProduct,
  listShopsItemsNextToken,
  listInventoryMovementItems
} from '../../../../store/selector'
import { InventoryMovementState } from '../../../../API'
import { fetchListElement, unSelectProductsById, unSelectVariantById, updateListState } from '../../../../actions/list'
import toggleMenu from '../../../../actions/menu'
import generateInventoryListConf from '../../../../store/editableCols/inventoryList'
import { updateColsConf } from '../../../../actions/cols'
import { createInventoryItems, getInventoryMovement } from '../../../../getters/catalog/inventory'
import { pluralize } from '../../../../utils/typeToType'

const EditableListInventoryMovements = () => {
  const [isEditable, setEditable] = useState(false)
  const [stateLoaded, setStateLoaded] = useState(false)
  const res = useSelector(listInventoryMovementsProduct)
  const items = useSelector(listInventoryMovementProductItems)
  const cols = useSelector(listInventoryMovementProductCols)
  const nextToken = useSelector(listInventoryMovementProductNextToken)
  const movementList = useSelector(listInventoryMovementItems);
  const shops = useSelector(listShopsItemsNextToken)
  const urlID = window.location.pathname.split('/').pop()
  const dispatch = useDispatch()
  const { t } = useTranslation()


  const setMovementInState = async (data: any) => {
    if (data.data) {
      setStateLoaded(true);
      const inventoryMovement = data.data
      const elements = createInventoryItems(inventoryMovement.products)

      setEditable(inventoryMovement.state === InventoryMovementState.DRAFT)
      //@ts-ignore
      fetchListElement(dispatch, elements.items, inventoryMovement.products.nextToken, objectType.INVENTORY_MOVE_PRODUCT)
      // fetchListElement(dispatch, newProducts, "", objectType.INVENTORY_MOVE_PRODUCT)
      updateListState(
        { type: pluralize(objectType.INVENTORY_MOVE_PRODUCT), state: ListState.LOADED },
        dispatch
      )
      const movementFromRedux = movementList.find((movement: any) => movement.id === urlID)

      // Open editable list
      toggleMenu(dispatch, { state: MenuState.CLOSE })
      const conf = generateInventoryListConf({
        type: objectType.INVENTORY_MOVE_PRODUCT,
        movement: {
          id: urlID,
          from: inventoryMovement.originID,
          to: inventoryMovement.destinationID,
          state: inventoryMovement.state,
          movements: movementFromRedux ? movementFromRedux.movements.items : inventoryMovement.movements.items,
        },
        t,
        shops: shops.items,
        isEditable: inventoryMovement.state === InventoryMovementState.DRAFT,
      })
      updateColsConf(dispatch, objectType.INVENTORY_MOVE_PRODUCT, conf, true)
    }
  }

  useEffect(() => {
    if (urlID && !stateLoaded) {
      getInventoryMovement(urlID, dispatch).then(setMovementInState);
    }
  }, [urlID])

  useEffect(() => {
    if (stateLoaded && urlID) {
      const movementFromRedux = movementList.find((movement: any) => movement.id === urlID)
      if (movementFromRedux) {
        const conf = generateInventoryListConf({
          type: objectType.INVENTORY_MOVE_PRODUCT,
          movement: {
            id: urlID,
            from: movementFromRedux.originID,
            to: movementFromRedux.destinationID,
            state: movementFromRedux.state,
            movements: movementFromRedux.movements.items,
          },
          t,
          shops: shops.items,
          isEditable: movementFromRedux.state === InventoryMovementState.DRAFT,
        })
        updateColsConf(dispatch, objectType.INVENTORY_MOVE_PRODUCT, conf, true)
      }
    }
  }, [stateLoaded, movementList])

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={items}
      nextToken={nextToken}
      editValues={res.editValues}
      errors={res.errors}
      type={objectType.INVENTORY_MOVE_PRODUCT}
      unSelectById={unSelectProductsById}
      unSelectSubById={unSelectVariantById}
      displayEditableActions={isEditable}
    />
  )
}

export default EditableListInventoryMovements
