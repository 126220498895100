import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { inviteUser } from '../../../graphql/custom/mutations'
import { closeAndResetModal, updateToaster } from '../../../actions/modal'
import UniqueDropDown from '../../../components/newComponents/dropDown/unique'
import Input from '../../../components/newComponents/input'
import { ComponentErrorText, ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { getCompanyID } from '../../../services/localStorage'
import generateDataCy from '../../../utils/cypress'
import Button from '../../../components/newComponents/button'
import { handleBlur, handleChange } from '../../../format/form'
import { AccountRole, CreateAccountInvitationInput } from '../../../API'
import { callService } from '../../../services'
import { TypeUser } from '../../../types/setting/user'
import { email as emailValidator, role as roleValidator } from '../../../format/errors/filters/setting/user'
import { removeUser, updateUserRole } from '../../../getters/setting/user'
import { listShopsItemsNextToken } from '../../../store/selector'
import { userRoleEnumToStr } from '../../../utils/typeToType'
import MultipleDropDown, { SelectOptions } from '../../../components/newComponents/dropDown/multiple'
import { DataWithErrors } from '../../../services/types'
import AttentionBox from '../../../components/newComponents/attentionBox'
import { getUser } from '../../../getters/setting/user'

import { KeyboardArrowDown, Success, Warning } from '../../../assets/icons'
import styles from '../../../components/newComponents/modal/Modal.module.css'

const UserModal = ({ id, email, isDelete }: { id?: string; email?: string; isDelete?: boolean }) => {
  const { t } = useTranslation()
  const initialState: TypeUser = {
    shopInput: t('settings.shop.NONE'),
    shops: [],
    email: '',
    displayInfos: false,
    showSubmit: false,
    validators: {
      email: emailValidator,
      role: roleValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const shops = useSelector(listShopsItemsNextToken)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'settings', value: 'user' })

  const setUserInState = async (data: DataWithErrors) => {
    if (data.data) {
      const user = data.data

      state.id = user.id
      state.firstName = user.firstName
      state.lastName = user.lastName
      state.email = user.email
      state.role = AccountRole.ADMIN
      // state.role = user.company.role
      state.shopInput = t('settings.shop.ALL')
      state.shops = shops.items.map((shop) => ({
        id: shop.id,
        value: shop.name,
        checked: true,
        // checked: user.shops.find((uShop: { shopID: string }) => uShop.shopID === shop.id),
      }))
      setState({ ...state })
      // const nbChecked = state.shops.filter((shop) => shop.checked).length
      // nbChecked === state.shops.length
      //   ? t('settings.shop.ALL')
      //   : nbChecked === 0
      //   ? t('settings.shop.NONE')
      //   : nbChecked === 1
      //   ? shops.items.find((shop: { id: string }) => shop.id === state.shops.find((shop) => shop.checked)?.id)?.name
      //   : t('settings.shop.SOME', { shop: nbChecked, plural: nbChecked > 1 ? 's' : '' })
    }
  }

  useEffect(() => {
    if (id && email) {
      getUser(email, dispatch).then(setUserInState)
    } else {
      state.role = AccountRole.ADMIN
      state.shopInput = t('settings.shop.ALL')
      state.shops = shops.items.map((shop) => ({
        id: shop.id,
        value: shop.name,
        checked: true,
        //checked:false
      }))
      setState({ ...state })
    }
      }, [id])

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = true
      setState({ ...state })
    } else {
      if (!id)
        updateToaster(dispatch, true, {
          text: t('settings.user.modal.SENT'),
          status: ComponentStatus.SUCCESS,
          LeadIcon: Success,
        })
      closeAndResetModal(dispatch)
    }
  }

  const handleSubmit = () => {
    if (state.errors.global) closeAndResetModal(dispatch)
    else if (isDelete && id) {
      removeUser({ id, companyID: getCompanyID() }, dispatch).then((res) => {
        if (res.data && !res.errors) {
          updateToaster(dispatch, true, {
            text: t('settings.user.modal.DELETED'),
            status: ComponentStatus.SUCCESS,
            LeadIcon: Success,
          })
          closeAndResetModal(dispatch)
        }
      })
    } else {
      state.showSubmit = false
      setState(handleBlur(state, 'email'))
      setState(handleBlur(state, 'role'))

      if (!findErrorsInState(state.errors) && state.email && state.role) {
        id
          ? updateUserRole(
              {
                id: state.id!,
                role: state.role,
                companyID: getCompanyID(),
              },
              dispatch
            ).then(handleResult)
          : callService<{ input: CreateAccountInvitationInput }>(
              {
                input: {
                  email: state.email,
                  role: state.role,
                  companyID: getCompanyID(),
                  shops: state.shops.filter((shop) => shop.checked).map((shop) => shop.id),
                },
              },
              inviteUser,
              'inviteUser'
            ).then(handleResult)
      }
    }
  }

  // const displayInfos = () => setState({ ...state, displayInfos: !state.displayInfos })

  const errorText = () => {
    if (state.errors?.email?.value) {
      return state.errors.email.message
    }

    if (state.errors.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <div
      className={styles.modal}
      data-cy={generateDataCy({
        scope: cyContext,
        value: isDelete ? 'delete' : id ? 'update' : 'invite',
      })}
    >
      <div className={isDelete ? styles.modalDangerTitle : styles.modalTitle}>
        {isDelete
          ? t('settings.user.modal.DELETE')
          : id
          ? t('settings.user.modal.EDIT')
          : t('settings.user.modal.INVITE')}
      </div>
      {isDelete ? (
        <div data-cy={generateDataCy({ scope: cyContext, value: 'deleteInfo' })} className={styles.modalInfo}>
          {t('settings.user.modal.DELETE_INFO', { mail: state.email })}
        </div>
      ) : state.errors?.global ? (
        <div className={styles.modalError}>{state.errors.global.message}</div>
      ) : (
        <>
          <div className={styles.modalContent}>
            {id && (
              <>
                <Input
                  label={t('settings.user.modal.FIRST_NAME')}
                  value={state.firstName}
                  style={{
                    state: ComponentState.DISABLED,
                  }}
                  dataCy={generateDataCy({ scope: cyContext, value: 'firstName' })}
                />
                <Input
                  label={t('settings.user.modal.LAST_NAME')}
                  value={state.lastName}
                  style={{
                    state: ComponentState.DISABLED,
                  }}
                  dataCy={generateDataCy({ scope: cyContext, value: 'lastName' })}
                />
              </>
            )}
            <Input
              label={t('settings.user.modal.EMAIL')}
              value={state.email}
              onChange={(event) => setState(handleChange(state, 'email')(event))}
              onBlur={() => setState(handleBlur(state, 'email'))}
              onSubmit={handleSubmit}
              style={{
                state: id ? ComponentState.DISABLED : ComponentState.DEFAULT,
                status: state.errors?.email?.value || state.errors.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              }}
              helperText={errorText()}
              dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
            />
            <UniqueDropDown
              parentID={'select'}
              popUpID={'selectPopUp'}
              Element={
                <Input
                  placeholder={'Sélectionner'}
                  value={state.role ? userRoleEnumToStr(state.role) : ''}
                  style={{
                    trailIcons: [{ icon: KeyboardArrowDown }],
                    state: ComponentState.DISABLED,
                    readOnly: true,
                  }}
                  dataCy={generateDataCy({ scope: cyContext, value: 'role' })}
                />
              }
              state={ComponentState.DISABLED}
              items={Object.values(AccountRole).map((role: AccountRole) => ({
                id: role,
                value: userRoleEnumToStr(role),
                onClick: (role: AccountRole) => {
                  if (role === AccountRole.ADMIN) {
                    state.shopInput = t('settings.shop.ALL')
                    state.shops = state.shops.map((shop) => ({ ...shop, checked: true }))
                  }
                  setState(handleChange(state, 'role')(role))
                },
              }))}
              dataCy={generateDataCy({ scope: cyContext, value: 'role' })}
            />
            {/* <TextLink
              text={state.displayInfos ? t('settings.user.modal.infos.HIDE') : t('settings.user.modal.infos.DISPLAY')}
              handleClick={displayInfos}
              size={ComponentSize.SMALL}
              dataCy={generateDataCy({ scope: cyContext, value: 'displayKey' })}
            />
            {state.displayInfos && (
              <ul style={{ paddingLeft: '1rem' }}>
                <li className={styles.modalHelperText}>{t('settings.user.modal.infos.ADMIN')}</li>
                <li className={styles.modalHelperText}>{t('settings.user.modal.infos.COMPTABLE')}</li>
                <li className={styles.modalHelperText}>{t('settings.user.modal.infos.MANAGER')}</li>
                <li className={styles.modalHelperText}>{t('settings.user.modal.infos.VENDEUR')}</li>
              </ul>
            )} */}
            <div className={styles.modalSubTitle}>{t('settings.user.modal.ASSOCIATION')}</div>
            <MultipleDropDown
              parentID={'selectShop'}
              popUpID={'selectShopPopUp'}
              value={state.shopInput}
              options={state.shops}
              entities={shops.items}
              state={state.role === AccountRole.ADMIN ? ComponentState.DISABLED : ComponentState.DEFAULT}
              onChanges={(options: Array<SelectOptions>, value: string) =>
                setState({ ...state, shopInput: value, shops: options, showSubmit: true })
              }
              dataCy={generateDataCy({ scope: cyContext, value: 'shop' })}
            />
            {id && (
              <AttentionBox
                text={t('settings.user.modal.WARNING')}
                LeadIcon={Warning}
                status={ComponentStatus.WARNING}
              />
            )}
          </div>
        </>
      )}
      <div className={styles.modalButtons}>
        {!state.errors?.global && (
          <Button
            title={t('button.CANCEL')}
            size={ComponentSize.MEDIUM}
            type={isDelete ? ComponentType.SECONDARY : ComponentType.TERTIARY}
            onClick={() => closeAndResetModal(dispatch)}
          />
        )}
        <Button
          title={
            isDelete
              ? t('button.DELETE')
              : state.errors?.global
              ? t('button.UNDERSTOOD')
              : id
              ? t('button.SAVE')
              : t('button.INVITE')
          }
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          status={isDelete ? ComponentStatus.DANGER : ComponentStatus.DEFAULT}
          state={isDelete || state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default UserModal
