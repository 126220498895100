import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  InventoryMovementState,
  InventoryMovementOrigin,
  InventoryMovementDestination,
  CreateInventoryMovementInput,
  ProductInventoryQuantity,
} from '../../../../../API';
import InventoryInfos from './inventoryInfos';
import InventoryMovements from './inventoryMovements';
import { createInventoryMovement } from '../../../../../getters/catalog/inventory';
import { displayDateNumeric } from '../../../../../utils/date';
import { setErrorsInState } from '../../../../../format/errors';
import { getDrawerState, listShopsItemsNextToken } from '../../../../../store/selector';
import Loader, { displayLoader } from '../../../../../components/newComponents/loader';
import { DrawerState } from '../../../../../store/types';
import { updateDrawerState } from '../../../../../actions/drawer';
import { TypeFormError, ValidationFunction } from '../../../../../format/errors/types';
import Divider from '../../../../../components/newComponents/divider';
import { ComponentDirection, ComponentSize, ComponentType } from '../../../../../components/newComponents/types';
import { getCompanyID } from '../../../../../services/localStorage';
import { callService } from '../../../../../services';
import { getProductVariantInventories, getProductInventories } from '../../../../../graphql/custom/queries';
import SubmitButton from '../../../../../components/oldComponents/drawer/submitButton';
import { quantity, quantity as shopValidator } from '../../../../../format/errors/filters/catalog/product';
import { handleBlurNested } from '../../../../../format/form';

import stylesDrawer from '../../../../../components/oldComponents/drawer/Drawer.module.css';

type Shop = {
  productID: string;
  variantID: string;
  id: string;
  name: string;
  quantity: number;
  newQuantity: number;
  disabled: boolean;
};

export type State = {
  focused: boolean;
  shops: Array<Shop>;
  inventoryQuantity: number;
  buyPrice: number;
  showSubmit: boolean;
  errors: {
    global?: TypeFormError;
    shops?: Array<TypeFormError>;
  };
  validators: {
    shops: ValidationFunction;
  };
};

const Inventory = ({ productID, variantID, close }: { productID: string; variantID?: string; close: () => void }) => {
  const initialState: State = {
    focused: false,
    inventoryQuantity: 0,
    buyPrice: 0,
    shops: [],
    showSubmit: false,
    errors: {},
    validators: {
      shops: shopValidator,
    },
  };
  const [state, setState] = useState(initialState);
  const shops = useSelector(listShopsItemsNextToken);
  const drawerState = useSelector(getDrawerState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getProduct = ({ productID }: { productID: string }) =>
    callService({ id: productID }, getProductInventories, 'getProduct');

  const getProductVariant = ({ productID, variantID }: { productID: string; variantID: string }) =>
    callService({ productID, variantID }, getProductVariantInventories, 'getProductVariant');

  const getShopByID = (id: string) => shops.items.find((shop: { id: string }) => shop.id === id);

  const setInventoryInState = async () => {
    const res = await (!variantID ? getProduct({ productID }) : getProductVariant({ productID, variantID }));

    if (res.errors) {
      updateDrawerState(DrawerState.ERROR, dispatch);
      return;
    }

    const inventoryQuantities: Array<ProductInventoryQuantity> = res.data?.inventoryQuantities || [];
    const buyPrice = res.data.buyPrice;

    const totalQuantity = inventoryQuantities
      .map(value => value.quantity || 0)
      .reduce((a: number, b: number) => a + b, 0);

    const quantitiesPerShop = shops.items.map(shop => {
      const { id, name } = shop;
      const { quantity } = inventoryQuantities.find(value => value.shopID === shop.id) || { quantity: undefined };
      const userEnteredQuantity = state.shops.find(value => value.id === shop.id)?.newQuantity; // avoid double flickering

      return {
        id,
        name,
        quantity: quantity || 0,
        newQuantity: userEnteredQuantity || quantity,
        disabled: false,
      };
    });

    setState({
      ...state,
      //@ts-ignore
      showSubmit: true,
      buyPrice,
      inventoryQuantity: totalQuantity,
      // srsly, wtf ce typage
      //@ts-ignore
      shops: quantitiesPerShop,
      errors: {
        shops: inventoryQuantities.map((_: any) => ({ value: false, message: '' })),
      },
    });

    updateDrawerState(DrawerState.LOADED, dispatch);
  };

  useEffect(() => {
    setInventoryInState();
  }, [productID, variantID]);

  const handleChange = (field: string) => (event: any) => {
    if (field === 'focused') {
      state.focused = event.focused;
    } else if (field === 'motive') {
      // @ts-ignore
      state[field] = event;
    } else {
      const index = state.shops.findIndex(shop => shop.id === field);
      // @ts-ignore
      state.shops[index].newQuantity = event;
    }

    setState({ ...state, showSubmit: true });
  };

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors);
      state.errors = newState.errors;
      state.showSubmit = newState.showSubmit;
      setState({ ...state });
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch);
    } else setInventoryInState();
  };

  const handleSubmit = () => {
    setState({ ...state, showSubmit: false });

    Promise.all(
      state.shops
        .filter(shop => typeof shop.newQuantity === 'string')
        .map(shop => {
          updateDrawerState(DrawerState.SEND_FORM, dispatch);
          const sgn = Number(shop.newQuantity) > shop.quantity;
          const inventoryMovementPayload: CreateInventoryMovementInput = {
            companyID: getCompanyID(),
            motive: sgn
              ? t('catalog.inventoryMovement.SUPPLIER_MOTIVE_DATE', {
                  date: displayDateNumeric(),
                })
              : t('catalog.inventoryMovement.TRASH_MOTIVE_DATE', {
                  date: displayDateNumeric(),
                }),
            state: InventoryMovementState.CLOSED,
            origin: sgn ? InventoryMovementOrigin.SUPPLIER : InventoryMovementOrigin.SHOP,
            destination: sgn ? InventoryMovementDestination.SHOP : InventoryMovementDestination.TRASH,
            destinationID: sgn ? shop.id : null,
            originID: sgn ? null : shop.id,
            movements: [
              {
                productID,
                variantID,
                quantity: (sgn ? 1 : -1) * (shop.newQuantity - shop.quantity),
                price: state.buyPrice,
              },
            ],
          };
          return createInventoryMovement(inventoryMovementPayload, dispatch).then(handleResult);
        }),
    );
  };

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={stylesDrawer.containerForm}
          style={{
            height: `calc(100vh - (61px + var(--comp-tab-list-item-height-lg) + var(--comp-button-size-height-lg) + 3em + 10px))`,
          }}>
          <div className={stylesDrawer.overflow}>
            <div className={stylesDrawer.form}>
              <InventoryInfos state={state} />
            </div>
            <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
            <div className={stylesDrawer.form}>
              <InventoryMovements
                state={state}
                onChange={handleChange}
                onBlur={index => setState(handleBlurNested(state, 'shops', index, 'newQuantity'))}
              />
            </div>
          </div>
          <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Inventory;
