import { ColorPalette } from '../API'

const colorList: string[] = [
  '#1E40AF',
  '#0EA5E9',
  '#166534',
  '#84CC16',
  '#EC4899',
  '#EF4444',
  '#F97316',
  '#FBBF24',
  '#86198F',
  '#92400E',
  '#8792A1',
  '#28313D',
]

const strToEnum = (str: string) => {
  switch (str) {
    case '#1E40AF':
      return ColorPalette.BLUE
    case '#0EA5E9':
      return ColorPalette.TURQUOISE
    case '#84CC16':
      return ColorPalette.LIME
    case '#EC4899':
      return ColorPalette.PINK
    case '#EF4444':
      return ColorPalette.RED
    case '#F97316':
      return ColorPalette.ORANGE
    case '#FBBF24':
      return ColorPalette.YELLOW
    case '#86198F':
      return ColorPalette.PURPLE
    case '#92400E':
      return ColorPalette.BROWN
    case '#8792A1':
      return ColorPalette.GREY
    case '#28313D':
      return ColorPalette.BLACK
    default:
      return ColorPalette.GREEN
  }
}

const enumToStr = (color: ColorPalette) => {
  if (color && color[0] === '#') return color
  switch (color) {
    case ColorPalette.BLUE:
      return '#1E40AF'
    case ColorPalette.TURQUOISE:
      return '#0EA5E9'
    case ColorPalette.LIME:
      return '#84CC16'
    case ColorPalette.PINK:
      return '#EC4899'
    case ColorPalette.RED:
      return '#EF4444'
    case ColorPalette.ORANGE:
      return '#F97316'
    case ColorPalette.YELLOW:
      return '#FBBF24'
    case ColorPalette.PURPLE:
      return '#86198F'
    case ColorPalette.BROWN:
      return '#92400E'
    case ColorPalette.GREY:
      return '#8792A1'
    case ColorPalette.BLACK:
      return '#28313D'
    default:
      return '#166534'
  }
}

const getRandomColor = (): ColorPalette => strToEnum(colorList[Math.floor(Math.random() * colorList.length)])

export { colorList, getRandomColor, enumToStr, strToEnum }
