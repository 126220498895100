import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import List from '../../../components/list'
import { ListState, objectType } from '../../../store/types'
import Drawer from '.'
import { listDiscountItemsNextToken, listDiscounts, listDiscountItems, getDashboardView } from '../../../store/selector'
import { buildCols } from '../../../utils'
import { TypeComparator } from '../../../components/requester/types'
import discountFieldsRequester from '../../../requester/catalog/discount'
import { useDispatch } from 'react-redux'
import { getAllDiscounts } from '../../../graphql/custom/queries'
import { pluralize } from '../../../utils/typeToType'
import { fetchListElement, updateListState } from '../../../actions/list'
import { getData } from '../../../services'
import { subscribeDiscounts, unSubscribeDiscounts } from '../../../getters/catalog/discount'

const ListDiscounts = () => {
  const res = useSelector(listDiscounts)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listDiscountItems)
  const { items, nextToken, sort } = useSelector(listDiscountItemsNextToken)
  const dashboardView = useSelector(getDashboardView)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    subscribeDiscounts(dispatch)
    const getListDiscounts = async () => {
      const listAllDiscounts = await getData({ request: getAllDiscounts, limit: 100 });
      if (listAllDiscounts.requestDiscounts && listAllDiscounts.requestDiscounts.items && listAllDiscounts.requestDiscounts.items.length > 0) {
        const allDiscounts = listAllDiscounts.requestDiscounts.items.filter((_: any) => _)
        fetchListElement(dispatch, allDiscounts, listAllDiscounts.requestDiscounts.nextToken, pluralize(objectType.DISCOUNT))
        updateListState(
          { type: pluralize(objectType.DISCOUNT), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.DISCOUNT))
        updateListState(
          { type: pluralize(objectType.DISCOUNT), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListDiscounts();

    return () => unSubscribeDiscounts();

      }, [])

  useEffect(() => setCols(buildCols(res.cols!, 'name', 'rate', dashboardView)), [dashboardView, res.cols])

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={discountFieldsRequester(t)}
      searchBarFields={[{ filter: TypeComparator.CONTAINS, field: 'name' }]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.DISCOUNT}
      Drawer={Drawer}
    />
  )
}

export default ListDiscounts
