import { NumberEditCell, DisabledCell } from '.'
import { TypeFormError } from '../../../format/errors/types'
import { getAlertIcon } from '../../newComponents/icon'

/**
 *
 * This element displays a inventory cell
 *
 * @usedIn - Inventory movement's list
 *
 * @param value - The entity
 * @param onChange - A CTA when we edit the cell
 * @param shopID - Id of the shop
 * @param editValues - A variable with all edited values (editable list)
 * @param validator - A method who check the error
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const InventoryCell = ({
  value,
  onChange,
  onBlur,
  onFocusNext,
  shopID,
  editValues,
  error,
}: {
  value: any
  onChange: (val: any) => void
  onBlur: () => void
  onFocusNext: () => void
  shopID: string
  editValues?: any
  error: TypeFormError
}): JSX.Element => {
  /**
   *
   * This method finds the edited cell while we are typing and returns its value
   * It also force to return 0 when we got no quantity
   *
   * @returns - The value to display
   *
   * @author - Arthur Escriou
   *
   */
  const valueToDisplay = () => {
    if (editValues) {
      if (editValues.subValues) {
        // let editValuesShopID = undefined
        // Object.entries(editValues.subValues).map(
        //   ([_, value]) =>
        //     // @ts-ignore
        //     (editValuesShopID = Object.entries(value).find(([key, _]) => key.split('inventory')[1] === shopID))
        // )
        // if (editValuesShopID) return editValuesShopID[1]
      } else {
        const editValuesShopID = Object.entries(editValues).find(([key, _]) => key.split('inventory')[1] === shopID)
        if (editValuesShopID) return editValuesShopID[1]
      }
    }
    if (value && value.inventoryQuantities && value.inventoryQuantities.length > 0) {
      const inventory = value.inventoryQuantities.find((shop: { shopID: string }) => shopID === shop.shopID)
      return inventory ? inventory.quantity : null
    }
    return null
  }

  /**
   *
   * This method calculates the total of product's sub values quantity
   *
   * @param value - The product
   *
   * @returns - Total of product's sub values quantity
   *
   * @author - Arthur Escriou
   *
   */
  const sumInventories = (value: any) =>
    value.subValues
      .map((val: any) => val.inventoryQuantities)
      .filter((a: any) => a)
      .map((v: any) => v.find((shop: { shopID: string }) => shopID === shop.shopID))
      .filter((sq: any) => sq)
      .map((sq: any) => sq.quantity)
      .reduce((acc: number, val: number) => acc + val, 0)

  if (value && value.subValues && value.subValues.length > 0) {
    return <DisabledCell value={sumInventories(value)} error={error!} icons={getAlertIcon(error!)} />
  }
  return (
    <NumberEditCell
      value={valueToDisplay()}
      onChange={onChange}
      onBlur={onBlur}
      onFocusNext={onFocusNext}
      error={error}
    />
  )
}

export default InventoryCell
