const Error = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1614 2C6.64138 2 2.16138 6.48 2.16138 12C2.16138 17.52 6.64138 22 12.1614 22C17.6814 22 22.1614 17.52 22.1614 12C22.1614 6.48 17.6814 2 12.1614 2ZM12.1614 13C11.6114 13 11.1614 12.55 11.1614 12V8C11.1614 7.45 11.6114 7 12.1614 7C12.7114 7 13.1614 7.45 13.1614 8V12C13.1614 12.55 12.7114 13 12.1614 13ZM13.1614 17H11.1614V15H13.1614V17Z"
      fill={color}
    />
  </svg>
)

export default Error
