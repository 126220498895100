import { useTranslation } from 'react-i18next'

import ProgressBar from '../../../../../../components/newComponents/progressBar'
import { ComponentSize, ComponentType } from '../../../../../../components/newComponents/types'
import { TypeProduct } from '../../../../../../types'
import Button from '../../../../../../components/newComponents/button'

import {
  GenerateVariantsDone as GeneratedVariantsIcon,
  GeneratingVariants as GeneratingVariantsIcon,
} from '../../../../../../assets/illustrations'
import styles from '../../../Product.module.css'
import { useEffect, useState } from 'react'

const GenerateVariants = ({ state, close }: { state: TypeProduct; close: () => void }) => {
  const { t } = useTranslation()
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progressInterval: any;

    if (state.isGenerating) {
      let currentProgress = 0;
      const expectedDuration = 2000;

      progressInterval = setInterval(() => {
        currentProgress += 5;
        if (currentProgress > 100) {
          currentProgress = 100;
          clearInterval(progressInterval);
        }
        setProgress(currentProgress);
      }, expectedDuration / 20);
    }

    return () => {
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [state.isGenerating]);

  return (
    <div className={styles.containerGenerate}>
      {state.isGenerating ? <GeneratingVariantsIcon /> : <GeneratedVariantsIcon />}
      <div className={styles.congratsMessage}>
        {state.isGenerating
          ? t('catalog.product.form.variant.GENERATING')
          : t('catalog.product.form.variant.GENERATED')}
      </div>
      <div className={styles.generateMessage}>
        {state.isGenerating
          ? t('catalog.product.form.variant.WARNING_CLOSE')
          : t('catalog.product.form.variant.NUMBER_OF_VARIANTS', {
            numberOfVariants: state.variantsLength,
            pluralOfVariant: state.variantsLength && state.variantsLength > 1 ? 's' : '',
            productName: state.name,
            verbOfVariant: state.variantsLength && state.variantsLength > 1 ? 'ont' : 'a',
          })}
      </div>
      {state.isGenerating ? (
        <ProgressBar size={ComponentSize.MEDIUM} type={ComponentType.LINEAR} progress={progress} />
      ) : (
        <div className={styles.generateMessage}>
          <Button
            title={t('catalog.product.form.variant.CLOSE')}
            size={ComponentSize.LARGE}
            type={ComponentType.PRIMARY}
            onClick={close}
          />
        </div>
      )}
    </div>
  )
}

export default GenerateVariants
