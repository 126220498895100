const Catalog = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6664 3.67749C15.7164 3.67749 13.6164 4.07749 12.1664 5.17749C10.7164 4.07749 8.61638 3.67749 6.66638 3.67749C5.21638 3.67749 3.67638 3.89749 2.38638 4.46749C1.65638 4.79749 1.16638 5.50749 1.16638 6.31749V17.5975C1.16638 18.8975 2.38638 19.8575 3.64638 19.5375C4.62638 19.2875 5.66638 19.1775 6.66638 19.1775C8.22638 19.1775 9.88638 19.4375 11.2264 20.0975C11.8264 20.3975 12.5064 20.3975 13.0964 20.0975C14.4364 19.4275 16.0964 19.1775 17.6564 19.1775C18.6564 19.1775 19.6964 19.2875 20.6764 19.5375C21.9364 19.8675 23.1564 18.9075 23.1564 17.5975V6.31749C23.1564 5.50749 22.6664 4.79749 21.9364 4.46749C20.6564 3.89749 19.1164 3.67749 17.6664 3.67749ZM21.1664 16.4075C21.1664 17.0375 20.5864 17.4975 19.9664 17.3875C19.2164 17.2475 18.4364 17.1875 17.6664 17.1875C15.9664 17.1875 13.5164 17.8375 12.1664 18.6875V7.17749C13.5164 6.32749 15.9664 5.67749 17.6664 5.67749C18.5864 5.67749 19.4964 5.76749 20.3664 5.95749C20.8264 6.05749 21.1664 6.46749 21.1664 6.93749V16.4075Z"
      fill={color}
    />
    <path
      d="M14.1464 10.1875C13.8264 10.1875 13.5364 9.98749 13.4364 9.66749C13.3064 9.27749 13.5264 8.84749 13.9164 8.72749C15.4564 8.22749 17.4464 8.06749 19.2764 8.27749C19.6864 8.32749 19.9864 8.69749 19.9364 9.10749C19.8864 9.51749 19.5164 9.81749 19.1064 9.76749C17.4864 9.57749 15.7164 9.72749 14.3764 10.1575C14.2964 10.1675 14.2164 10.1875 14.1464 10.1875Z"
      fill={color}
    />
    <path
      d="M14.1464 12.8475C13.8264 12.8475 13.5364 12.6475 13.4364 12.3275C13.3064 11.9375 13.5264 11.5075 13.9164 11.3875C15.4464 10.8875 17.4464 10.7275 19.2764 10.9375C19.6864 10.9875 19.9864 11.3575 19.9364 11.7675C19.8864 12.1775 19.5164 12.4775 19.1064 12.4275C17.4864 12.2375 15.7164 12.3875 14.3764 12.8175C14.2964 12.8375 14.2164 12.8475 14.1464 12.8475Z"
      fill={color}
    />
    <path
      d="M14.1464 15.5075C13.8264 15.5075 13.5364 15.3075 13.4364 14.9875C13.3064 14.5975 13.5264 14.1675 13.9164 14.0475C15.4464 13.5475 17.4464 13.3875 19.2764 13.5975C19.6864 13.6475 19.9864 14.0175 19.9364 14.4275C19.8864 14.8375 19.5164 15.1275 19.1064 15.0875C17.4864 14.8975 15.7164 15.0475 14.3764 15.4775C14.2964 15.4975 14.2164 15.5075 14.1464 15.5075Z"
      fill={color}
    />
  </svg>
)

export default Catalog
