import { createProductCustomField, callCustomFieldsCreation } from '../../../../../../services/setting/customField'
import { TypeTag, APIOption, TypeProduct } from '../../../../../../types'
import { DataWithErrors } from '../../../../../../services/types'
import { callTagToCreate } from './tag'
import { buildTemporaryErrors, createInput, isNestedDirty } from '../../../../../../utils/dirties'
import { getCompanyID } from '../../../../../../services/localStorage'
import { callService } from '../../../../../../services'
import { CreateProductInput, ImageModelType, UploadImageInput } from '../../../../../../API'
import { createProduct as create, createProductOption } from '../../../../../../graphql/custom/mutations'
import { getAExcludeBbyID } from '../../../../../../utils'
import { uploadImage } from '../../../../../../graphql/mutations'
import { removeParamsFromUrl } from '../../../../../../utils/string'

const callOptionCreation = (productID: string) => (option: APIOption) =>
  callService<{ input: any }>({ input: { productID, optionID: option.id } }, createProductOption, 'createProductOption')

const callOptionsCreation = (productID: string, options: Array<APIOption>) =>
  Promise.all(options.map(callOptionCreation(productID)))

const callProductCreation = async (payload: TypeProduct, decimals: number) => {
  const input: any = createInput(payload, decimals)

  if (input) {
    let res: DataWithErrors = {}
    if (payload.selected === 'color') input.photo = null
    const { selected, ...newInput } = input
    res = await callService<{ input: CreateProductInput }>(
      { input: { ...newInput, catalogID: getCompanyID() } },
      create,
      'createProduct'
    )
    if (res.errors) return res
    // In order to perhaps generate variants in the future, we need productID
    payload.productID = res.data.id

    const toWait: Array<Promise<any>> = []

    if (payload.selected === 'photo' && isNestedDirty(payload.dirties, 'photo')) {
      if (payload.photo.toString().startsWith('data:'))
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: payload.productID, type: ImageModelType.PRODUCT, base64Image: payload.photo } },
            uploadImage,
            'uploadImage'
          )
        )
      else {
        //! temporary, might be an import issue
        const newPhoto = removeParamsFromUrl(payload.photo)
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: payload.productID, type: ImageModelType.PRODUCT, urlImage: newPhoto } },
            uploadImage,
            'uploadImage'
          )
        )
      }
    }

    if (isNestedDirty(payload.dirties, 'customFields')) {
      toWait.push(
        callCustomFieldsCreation(payload.customFields, {
          id: { productID: res.data.id },
          create: createProductCustomField,
        })
      )
    }

    if (isNestedDirty(payload.dirties, 'tags')) {
      const existingTags = res.data.tags.items
        .filter((item: { tag: TypeTag }) => item.tag)
        .map((item: { tag: TypeTag }) => ({
          id: item.tag.id,
          name: item.tag.name,
        }))

      const tagsToCreate = getAExcludeBbyID(payload.tags, existingTags)
      toWait.push(callTagToCreate(res.data.id, tagsToCreate))
    }

    if (isNestedDirty(payload.dirties, 'options')) {
      toWait.push(callOptionsCreation(res.data.id, payload.options))
    }

    res.errors = await buildTemporaryErrors(toWait, res.errors)
    return res
  }
  return null
}

export default callProductCreation
