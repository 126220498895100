import DisplayIcon2 from '../../newComponents/icons'

const InventoryTypeCell = ({ Icon, text }: { Icon: any; text: string }): JSX.Element => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
    <DisplayIcon2 Icon={Icon} color={'var(--comp-list-item-color-foreground-text)'} />
    <span>{text}</span>
  </div>
)

export default InventoryTypeCell
