import { TypeCol, TypeColumnConfiguration } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { stateSaleToStr } from '../../utils/typeToType'
import { CurrencyEditCell as CurrencyCell } from '../../components/list/cell'
import Chip from '../../components/newComponents/chip'

const SALE = 'sale'

export const saleCols: Array<TypeCol> = [
  {
    id: 'number',
    title: 'NUMBER',
    type: 'function',
    fieldType: 'number',
    field: 'number',
    active: true,
    sortable: true,
    notCustomable: true,
    function: (number) => (number !== null ? '#' + number : ''),
  },
  {
    id: 'state',
    title: 'SITUATION',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value, t }) => Chip(stateSaleToStr(value.state, value.refundedStatus, t)),
  },
  {
    id: 'sourceID',
    title: 'SOURCE_ID',
    type: 'text',
    field: 'sourceID',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'sourceName',
    title: 'SOURCE_NAME',
    type: 'text',
    field: 'sourceName',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'totalDiscount',
    title: 'TOTAL_DISCOUNT',
    field: 'totalDiscount',
    type: 'element',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }) =>
      CurrencyCell({
        value,
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'totalTaxFree',
    title: 'TOTAL_TAX_FREE',
    field: 'totalTaxFree',
    fieldType: 'number',
    type: 'element',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }) =>
      CurrencyCell({
        value,
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'total',
    title: 'TOTAL',
    field: 'total',
    type: 'element',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }) =>
      CurrencyCell({
        value,
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'totalTax',
    title: 'TOTAL_TAX',
    field: 'totalTax',
    type: 'element',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }) =>
      CurrencyCell({
        value,
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'paymentMethodsTotal',
    title: 'PAYMENT_METHODS_TOTAL',
    type: 'element',
    fieldType: 'number',
    active: true,
    function: ({ value }) => (value.payments ? value.payments.length : null),
  },
  {
    id: 'openedAt',
    title: 'OPENED_AT',
    type: 'function',
    field: 'openedAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => displayDateShortMonthWithHours(date),
  },
  {
    id: 'closedAt',
    title: 'CLOSED_AT',
    type: 'function',
    field: 'closedAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => (date ? displayDateShortMonthWithHours(date) : 'Inconnue'),
  },
  {
    id: 'note',
    title: 'NOTE',
    type: 'text',
    field: 'note',
    fieldType: 'string',
    active: true,
  },
]

export const salesColsConf: TypeColumnConfiguration[] = [
  {
    id: 'requestSales',
    type: SALE,
    cols: saleCols,
    creatable: false,
    editable: false,
    exportable: true,
    clickable: true,
    shrinkrable: true,
    customable: true,
    checkboxes: false,
    searchBarPlaceholder: 'searchBar.placeholder.NUMBER',
  },
]
