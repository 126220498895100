import { useDispatch, useSelector } from 'react-redux'

import { ListState, objectType } from '../../../store/types'
import List from '../../../components/list'
import { listTaxItemsNextToken, listTaxes } from '../../../store/selector'
import TaxModal from './modal'
import { updateModal } from '../../../actions/modal'
import { getAllTaxes } from '../../../graphql/custom/queries'
import { getData } from '../../../services'
import { useEffect } from 'react'
import { fetchListElement, updateListState } from '../../../actions/list'
import { pluralize } from '../../../utils/typeToType'
import { getTotalProductTax, subscribeTaxes, unSubscribeTaxes } from '../../../getters/setting/tax'

const ListTaxes = () => {
  const { items, nextToken } = useSelector(listTaxItemsNextToken)
  const res = useSelector(listTaxes)
  const dispatch = useDispatch()

  useEffect(() => {
    subscribeTaxes(dispatch)
    const getListTaxes = async () => {
      // const listAllTaxes = await getData({ request: getAllTaxes, limit: 100 });
      // if (listAllTaxes.listTaxes && listAllTaxes.listTaxes.items && listAllTaxes.listTaxes.items.length > 0) {
      // const allTaxes = listAllTaxes.listTaxes.items.filter((_: any) => _)
      const updatedTaxes = await Promise.all(
        items.map(async (tax: any) => {
          const updatedTax = await getTotalProductTax(tax.rate, tax);
          return updatedTax;
        })
      );
      fetchListElement(dispatch, updatedTaxes, nextToken, pluralize(objectType.TAX))
      updateListState(
        { type: pluralize(objectType.TAX), state: ListState.LOADED },
        dispatch
      )
      // }
    }
    getListTaxes();

    return () => unSubscribeTaxes();

  }, []);

  const handleCreate = () =>
    updateModal(
      dispatch,
      true,
      () => <TaxModal />,
      () => { }
    )

  return (
    <List
      cols={res.cols!}
      items={items}
      fullItems={items}
      nextToken={nextToken}
      onCreate={handleCreate}
      type={objectType.TAX}
    />
  )
}

export default ListTaxes
