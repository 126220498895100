import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DraggableList, { DraggableListState } from '../../../../components/oldComponents/draggableList/draggableList'
import { Entries } from '../../../../components/oldComponents/searchList'
import { TypePackVariationOption } from '../../../../types'
import { listProductItemsNextToken } from '../../../../store/selector'
import { findErrorsInState } from '../../../../format/errors'
import { TypeFormError, ValidationFunction } from '../../../../format/errors/types'
import { packVariations as listPackVariationValidator } from '../../../../format/errors/filters/catalog/pack'
import { handleBlur } from '../../../../format/form'
import generateDataCy from '../../../../utils/cypress'
import SubmitButton from '../../../../components/oldComponents/drawer/submitButton'

import stylesDrawer from '../../../../components/oldComponents/drawer/Drawer.module.css'

type FormPackVariationProps = {
  packVariations?: Array<TypePackVariationOption>
  indexOfPack: number
  onSubmit: () => void
  savePackOption: (options: Array<TypePackVariationOption>, indexOfPack: number) => void
  deletePackOption: (indexOfPack: number) => void
  dataCy: string
}

type FormPackVariationtate = {
  listPackVariation: Array<DraggableListState>
  entries: Array<Entries>
  showSubmit: boolean
  errors: {
    global?: TypeFormError
    listPackVariation?: TypeFormError
  }
  validators: {
    listPackVariation?: ValidationFunction
  }
}

const FormPackOption = ({
  packVariations,
  indexOfPack,
  onSubmit,
  savePackOption,
  deletePackOption,
  dataCy,
}: FormPackVariationProps) => {
  const initialState: FormPackVariationtate = {
    listPackVariation: [],
    entries: [],
    showSubmit: true,
    errors: {},
    validators: {
      listPackVariation: listPackVariationValidator,
    },
  }
  const [state, setState] = useState(initialState)
  const products = useSelector(listProductItemsNextToken)
  const { t } = useTranslation()

  const getEntries = (ids?: Array<string>) => {
    const filteredProducts = products.items
      .filter((product: { id: string }) => {
        const index = ids?.findIndex((id) => id === product.id)
        return index === undefined || index === -1
      })
      .map((filteredProduct: { id: string; name: string }) => ({
        id: filteredProduct.id,
        val: filteredProduct.name,
      }))
    return filteredProducts
  }

  useEffect(() => {
    setState({
      ...state,
      listPackVariation: packVariations
        ? packVariations.map((packOption) => ({
            id: packOption.productID,
            name: packOption.name,
            quantity: packOption.quantity,
            price: packOption.price,
          }))
        : [],
      entries: getEntries(packVariations?.map((packOption) => packOption.productID)),
    })
      }, [packVariations, products])

  const handleChange =
    (field: string, index: number) =>
    (event: any): void => {
      // @ts-ignore
      state.listPackVariation[index][field] = event
      // @ts-ignore
      state.errors.listPackVariation = null
      setState({ ...state, showSubmit: true })
    }

  const handleSelect = (e: any): void => {
    state.listPackVariation = [
      ...state.listPackVariation,
      {
        id: e,
        price: 0,
        name: products.items.find((product: any) => product.id === e).name,
        quantity: 1,
      },
    ]
    setState({
      ...state,
      entries: getEntries(state.listPackVariation.map((packOption) => packOption.id)),
      isDisabled: false,
      errors: {
        ...state.errors,
        // @ts-ignore
        listPackVariation: null,
      },
    })
  }

  const handleDelete = (id: string | undefined, _: number): void => {
    state.listPackVariation = state.listPackVariation.filter((list: { id: string }) => list.id !== id)
    setState({
      ...state,
      entries: getEntries(state.listPackVariation.map((packOption) => packOption.id)),
      isDisabled: false,
      errors: {
        ...state.errors,
        // @ts-ignore
        listPackVariation: null,
      },
    })
  }

  const onDragEnd = (dragStart: number, dragEnd: number): void => {
    const packOptInsert = state.listPackVariation[dragStart]
    state.listPackVariation = state.listPackVariation.filter((_: {}, index: number) => index !== dragStart)
    state.listPackVariation.splice(dragEnd, 0, packOptInsert)
    setState({ ...state, showSubmit: false })
  }

  const handleSubmit = () => {
    setState(handleBlur(state, 'listPackVariation'))

    if (!findErrorsInState(state.errors))
      savePackOption(
        state
          .listPackVariation!.filter((listPackOption) => listPackOption)
          .map((listPackOption) => ({
            id: listPackOption.id,
            productID: listPackOption.id,
            name: listPackOption.name,
            quantity: listPackOption.quantity!,
            price: listPackOption.price!,
          })),
        indexOfPack
      )
  }

  return (
    <>
      <div className={stylesDrawer.overflow}>
        <div className={stylesDrawer.form}>
          <DraggableList
            label={t('catalog.pack.form.option.LIST')}
            list={state.listPackVariation ?? []}
            entries={state.entries}
            onChange={handleChange}
            onSelect={handleSelect}
            onClickIcon={handleDelete}
            onDragEnd={onDragEnd}
            onSubmit={onSubmit}
            error={state.errors.listPackVariation}
            iconColor={'var(--sys-color-content-danger)'}
            addText={t('catalog.pack.form.option.ADD')}
            dataCy={generateDataCy({ scope: dataCy, value: 'packOption' })}
            remove
            isOption
          />
          <div
            tabIndex={0}
            className={stylesDrawer.removeButton}
            onClick={() => deletePackOption(indexOfPack)}
            onKeyPress={(key) => {
              if (key.key === 'Enter') deletePackOption(indexOfPack)
            }}
            data-cy={generateDataCy({
              scope: generateDataCy({ scope: dataCy, value: 'packOption' }),
              value: 'delete',
            })}
          >
            {t('catalog.pack.form.option.DELETE')}
          </div>
        </div>
      </div>
      <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
    </>
  )
}

export default FormPackOption
