import { TypeCustomField } from '../../../types/setting/customField'
import { CustomFieldValueType } from '../../../API'

import generateDataCy from '../../../utils/cypress'
import { TypeFormError } from '../../../format/errors/types'
import Input from '../../newComponents/input'
import { ComponentState, ComponentStatus } from '../../newComponents/types'

import dayjs from 'dayjs';

import '../DatePicker.css'
import DatePickers from '../../newComponents/datePicker'

/**
 *
 * This element is a sub component from Custom Field and displays every line
 *
 * @usedIn - Catalog form views / Settings list views
 *
 * @param name - The name of the custom field/the input
 * @param type - The type of the custom field/the input (text, number..)
 * @param value - The value of the custom field/the input
 * @param disabled - A boolean who enable or disable the input
 * @param index - The index of where this custom field is located in the array
 * @param handleChange - The CTA triggered when we type the input
 * @param handleBlur - The CTA triggered when we leave the input
 * @param onSubmit - The CTA triggered when we press "Enter"
 * @param error - A TypeFormError to display if error.value is true
 * @param cyContext - The text to add to create an unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
export const CustomFieldLine = ({
  name,
  type,
  value,
  valueType,
  disabled,
  index,
  handleChange,
  handleBlur,
  onSubmit,
  error,
  cyContext,
}: {
  name: string
  type: string
  value: any
  valueType: string
  disabled?: boolean
  index: number
  handleChange?: (field: string, index?: number) => (event: any) => void
  handleBlur: (index: number) => void
  onSubmit: () => void
  error?: TypeFormError
  cyContext?: string
}): JSX.Element => (
  <Input
    type={type}
    label={name}
    value={value}
    onChange={handleChange && handleChange('customFields', index)}
    onBlur={() => {
      valueType === CustomFieldValueType.NUMBER && handleBlur(index)
    }}
    onSubmit={onSubmit}
    style={{
      state: disabled ? ComponentState.DISABLED : ComponentState.DEFAULT,
      status: error?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
    }}
    helperText={error?.value ? error.message : undefined}
    dataCy={generateDataCy({ scope: cyContext!, value: name })}
  />
)

/**
 *
 * This element displays a list of custom fields
 *
 * @usedIn - Catalog form views / Settings list views
 *
 * @param customFields - The actual custom fields to display
 * @param onChange - The CTA triggered when we type the input
 * @param onSubmit - The CTA triggered when we press "Enter"
 * @param disabled - A boolean who enable or disable the input
 * @param error - An array of TypeFormError to display if error.value is true
 * @param cyContext - The text to add to create an unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const CustomField = ({
  selectedCustomFields,
  onChange,
  onBlur,
  onSubmit,
  disabled = false,
  errors,
  cyContext,
}: {
  selectedCustomFields: Array<TypeCustomField>
  onChange?: (field: string, index?: number) => (event: any) => void
  onBlur: (index: number) => void
  onSubmit?: () => void
  disabled?: boolean
  errors?: Array<TypeFormError>
  cyContext?: string
}): JSX.Element => {


  return (
    <>
      {selectedCustomFields &&
        selectedCustomFields.length > 0 &&
        selectedCustomFields.map(({ id, name, valueType, value }, index) => (
          <div key={id} data-cy={generateDataCy({ scope: cyContext!, value: name })}>
            {valueType === CustomFieldValueType.DATE ? (
              <DatePickers name={name} value={dayjs(value)} index={index} field={'customFields'} onChange={onChange} />
            ) : (
              <CustomFieldLine
                name={name}
                type={valueType.toLowerCase()}
                value={value}
                valueType={valueType}
                disabled={disabled}
                index={index}
                handleChange={onChange}
                handleBlur={onBlur}
                onSubmit={onSubmit!}
                error={errors && errors[index]}
                cyContext={cyContext}
              />
            )}
          </div>
        ))}
    </>
  )
}

export default CustomField
