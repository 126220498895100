import { TypeColumnConfiguration } from '../types';
import { DropDownCell } from '../../components/list/cell';
import { updateModal } from '../../actions/modal';
import { slotStatusToStr } from '../../utils/typeToType';
import { SlotStatus } from '../../API';
import SlotModal from '../../views/setting/slot/modal';
import Chip from '../../components/newComponents/chip';
import { MoreHoriz } from '../../assets/icons';
import IconTouch from '../../components/newComponents/icons/iconTouch';
import SlotValidationModal from '../../views/setting/slot/validationModal';

const SLOT = 'slot';

const slotsColsConf: TypeColumnConfiguration[] = [
  {
    id: 'listSlots',
    type: SLOT,
    cols: [
      {
        id: 'name',
        title: 'NAME',
        field: 'name',
        type: 'text',
        fieldType: 'string',
        active: true,
      },
      {
        id: 'status',
        title: 'STATUS',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, t }) => Chip(slotStatusToStr(value.status, t)),
      },
      {
        id: 'associatedAt',
        title: 'ASSOCIATED_AT',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, shops }) =>
          shops.items && shops.items.find((shop: { id: string }) => shop.id === value.shopID)
            ? shops.items.find((shop: { id: string }) => shop.id === value.shopID).name
            : '',
      },
      {
        id: 'more',
        title: '',
        type: 'element',
        active: true,
        function: ({ value, t, index, dispatch }) => {
          let items = [
            {
              id: 'edit',
              value: t('settings.slot.EDIT'),
              onClick: () =>
                updateModal(
                  dispatch,
                  true,
                  () => SlotModal({ id: value.id, isUpdate: true }),
                  () => {},
                ),
            },
          ];

          if (value.status === SlotStatus.IDLE)
            items = [
              ...items,
              {
                id: 'connection',
                value: t('settings.slot.CONNECTION'),
                onClick: () =>
                  updateModal(
                    dispatch,
                    true,
                    () => SlotModal({ id: value.id, isEmail: true }),
                    () => {},
                  ),
              },
            ];

          if (value.status === SlotStatus.ACTIVE)
            items = [
              ...items,
              {
                id: 'disconnect',
                value: t('settings.slot.DISCONNECT'),
                onClick: () =>
                  updateModal(
                    dispatch,
                    true,
                    () => SlotValidationModal({ id: value.id }),
                    () => {},
                    'slot',
                  ),
              },
            ];
          return DropDownCell({
            fieldID: 'more',
            index,
            Element: IconTouch({
              Icon: MoreHoriz,
              color: 'var(--sys-color-content-secondary)',
            }),
            items,
          });
        },
      },
    ],
    creatable: true,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
    creatablePlaceholder: 'settings.slot.NEW',
  },
];

export default slotsColsConf;
