export enum ComponentSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  DEFAULT = 'dt',
  EXTRASMALL = 'xs',
}

export enum ComponentDirection {
  HORIZONTAL,
  VERTICAL,
}

export enum ComponentType {
  HEADER = 'header',
  CONTENT = 'content',
  FOOTER = 'footer',
  DEFAULT = 'default',
  FILL = 'fill',
  DASHED = 'dashed',
  LINEAR = 'linear',
  TEXT = 'text',
  ICON = 'icon',
  IMAGE = 'img',
  LOGOICON = 'logoicon',
  LOGOICONTEXT = 'logoicontext',
  LOGOTEXT = 'logotext',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum ComponentState {
  DEFAULT = 'default',
  HOVER = 'hover',
  FOCUSED = 'focused',
  SELECTED = 'selected',
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PRESSED = 'pressed',
  INVISIBLE = 'invisible',
}

export enum ComponentStatus {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}

export enum ComponentStyle {
  SQUARE = 'square',
  CIRCLE = 'circle',
  IMAGE = 'image',
}

export enum ComponentColor {
  PRIMARY,
  WHITE,
}

export enum ComponentSelection {
  NONE = 'none',
  ALL = 'all',
  SOME = 'some',
}

export enum ComponentErrorText {
  ALREADY_EXIST = 'Existe déjà',
  IS_NEEDED = 'Doit être présent',
  FOUR_DIGIT = 'Doit Contenir 4 chiffres',
  INVALIDE_FORMAT = 'Le format est invalide',
}
