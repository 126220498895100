import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../button'
import IconTouch from '../icons/iconTouch'
import { ComponentSize, ComponentStatus, ComponentType } from '../types'
import Picker from '../picker'

import { DeleteIcon, Photo } from '../../../assets/icons'
import styles from './ImagePicker.module.css'

const ImagePicker = ({
  text,
  resize,
  photo,
  onChange,
  isSelected,
  isLogo,
}: {
  text: string
  resize: number
  photo?: any
  onChange: (image: any) => void
  isSelected?: boolean
  isLogo?: boolean
}) => {
  const initialState: { photo: any; error?: string } = { photo: null }
  const [state, setState] = useState(initialState)
  const { t } = useTranslation()

  useEffect(() => {
    setState({ ...state, photo })
      }, [photo])

  const handleChange = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const dataUri = await getBase64(event.target.files[0])

      const imgEl = document.createElement('img') as any
      imgEl.src = dataUri

      imgEl.addEventListener('load', () => {
        const resizedDataUri = isLogo ? handleResizeLogo(imgEl, resize) : handleResize(imgEl, resize)

        onChange(resizedDataUri)
        event.target.value = ''
      })
    }
  }

  const handleResize = (imgEl: HTMLCanvasElement, wantedWidth: number) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const aspect = imgEl.width / imgEl.height
    canvas.width = wantedWidth
    canvas.height = wantedWidth / aspect

    ctx?.drawImage(imgEl, 0, 0, canvas.width, canvas.height)

    return canvas.toDataURL()
  }

  const handleResizeLogo = (imgEl: HTMLCanvasElement, wantedSize: number) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const aspect = imgEl.width / imgEl.height
    if (imgEl.width > imgEl.height) {
      canvas.width = wantedSize
      canvas.height = wantedSize / aspect
    } else {
      canvas.height = wantedSize
      canvas.width = wantedSize / aspect
    }

    ctx?.drawImage(imgEl, 0, 0, canvas.width, canvas.height)

    return canvas.toDataURL()
  }

  const getBase64 = (field: any) =>
    new Promise((resolve) => {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        resolve(reader.result)
      })

      reader.readAsDataURL(field)
    })

  return (
    <div id="containerImagePicker" tabIndex={0}>
      <div className={styles.container} style={{ gap: state.photo ? '8px' : '' }}>
        {state.photo && (
          <Picker
            size={ComponentSize.LARGE}
            image={state.photo}
            selected={isSelected}
            onClick={() => onChange(state.photo)}
          />
        )}
        <Button
          id={'imagePickerButton'}
          type={ComponentType.SECONDARY}
          size={ComponentSize.MEDIUM}
          title={state.photo ? t('components.imagePicker.EDIT') : t('components.imagePicker.ADD')}
          LeadIcon={!state.photo ? Photo : undefined}
          onClick={() => document.getElementById('imagePicker')?.click()}
        />
        <input
          id="imagePicker"
          type="file"
          accept="image/jpg, image/jpeg, image/png, image/svg+xml"
          onChange={handleChange}
          hidden
        />
        {state.photo && (
          <IconTouch
            id={'imagePickerDeleteButton'}
            Icon={DeleteIcon}
            status={ComponentStatus.DANGER}
            color="var(--sys-color-content-danger)"
            onClick={() => onChange(null)}
          />
        )}
      </div>
      {<div className={styles.text}>{text}</div>}
    </div>
  )
}

export default ImagePicker
