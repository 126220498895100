import {
  onCreateCategory,
  onDeleteCategory,
  onUpdateCategory,
  onCreateCategoryCustomField,
  onUpdateCategoryCustomField,
  onDeleteCategoryCustomField,
} from '../../graphql/custom/subscriptions'
import { getCompanyID } from '../../services/localStorage'
import {
  updateListElement,
  deleteListElement,
  updateCustomFieldValue,
  createCustomFieldValue,
  deleteCustomFieldValue,
} from '../../actions/list'
import { objectType } from '../../store/types'
import { getObservable } from '../../services/graphql'
import { DataWithErrors } from '../../services/types'
import { updateCategoriesAssociations } from '../../actions/catalog'
import { GetCategoryQueryVariables } from '../../API'
import { callService } from '../../services'
import { getCategory as get } from '../../graphql/custom/queries'

const getCategory = async (id: string, dispatch: any): Promise<DataWithErrors> => {
  const category = await callService<GetCategoryQueryVariables>({ id }, get, 'getCategory')

  if (category.data) {
    if (category.data.photo) category.data.photo = category.data.photo + '?' + Date.now()

    updateListElement({
      element: category.data,
      dispatch,
      type: objectType.CATEGORY,
    })
  }
  return category
}

let onCreateCategorySubscription: any
let onDeleteCategorySubscription: any
let onUpdateCategorySubscription: any

const subscribeCategories = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreateCategorySubscription)
    onCreateCategorySubscription = getObservable(onCreateCategory, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateCategory) {
          if (eventData.data.onCreateCategory.photo) {
            eventData.data.onCreateCategory.photo = eventData.data.onCreateCategory.photo + '?' + Date.now()
          }
          updateListElement({
            element: eventData.data.onCreateCategory,
            dispatch,
            type: objectType.CATEGORY,
          })
        }
      },
    })

  if (!onUpdateCategorySubscription)
    onUpdateCategorySubscription = getObservable(onUpdateCategory, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateCategory) {
          if (eventData.data.onUpdateCategory.photo) {
            eventData.data.onUpdateCategory.photo = eventData.data.onUpdateCategory.photo + '?' + Date.now()
          }
          updateListElement({
            element: eventData.data.onUpdateCategory,
            dispatch,
            type: objectType.CATEGORY,
          })
          updateCategoriesAssociations({ categoryID: eventData.data.onUpdateCategory.id, dispatch }, dispatch)
        }
      },
    })

  if (!onDeleteCategorySubscription)
    onDeleteCategorySubscription = getObservable(onDeleteCategory, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeleteCategory,
          dispatch,
          type: objectType.CATEGORY,
        }),
    })
}

const unSubscribeCategories = () => {
  if (onCreateCategorySubscription) {
    onCreateCategorySubscription.unsubscribe()
    onCreateCategorySubscription = null
  }

  if (onUpdateCategorySubscription) {
    onUpdateCategorySubscription.unsubscribe()
    onUpdateCategorySubscription = null
  }

  if (onDeleteCategorySubscription) {
    onDeleteCategorySubscription.unsubscribe()
    onDeleteCategorySubscription = null
  }
}

let onCreateCategoryCustomFieldSubscription: any
let onUpdateCategoryCustomFieldSubscription: any
let onDeleteCategoryCustomFieldSubscription: any

const subscribeCategoryCustomFields = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreateCategoryCustomFieldSubscription)
    onCreateCategoryCustomFieldSubscription = getObservable(onCreateCategoryCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateCategoryCustomField) {
          createCustomFieldValue(dispatch, eventData.data.onCreateCategoryCustomField, objectType.CATEGORY)
        }
      },
    })

  if (!onUpdateCategoryCustomFieldSubscription)
    onUpdateCategoryCustomFieldSubscription = getObservable(onUpdateCategoryCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateCategoryCustomField) {
          updateCustomFieldValue(dispatch, eventData.data.onUpdateCategoryCustomField, objectType.CATEGORY)
        }
      },
    })

  if (!onDeleteCategoryCustomFieldSubscription)
    onDeleteCategoryCustomFieldSubscription = getObservable(onDeleteCategoryCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onDeleteCategoryCustomField) {
          deleteCustomFieldValue(dispatch, eventData.data.onDeleteCategoryCustomField, objectType.CATEGORY)
        }
      },
    })
}

const unSubscribeCategoryCustomFields = () => {
  if (onCreateCategoryCustomFieldSubscription) {
    onCreateCategoryCustomFieldSubscription.unsubscribe()
    onCreateCategoryCustomFieldSubscription = null
  }

  if (onUpdateCategoryCustomFieldSubscription) {
    onUpdateCategoryCustomFieldSubscription.unsubscribe()
    onUpdateCategoryCustomFieldSubscription = null
  }

  if (onDeleteCategoryCustomFieldSubscription) {
    onDeleteCategoryCustomFieldSubscription.unsubscribe()
    onDeleteCategoryCustomFieldSubscription = null
  }
}

export {
  getCategory,
  subscribeCategories,
  unSubscribeCategories,
  subscribeCategoryCustomFields,
  unSubscribeCategoryCustomFields,
}
