import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { ComponentStatus } from '../types';

import stylesDrawer from '../../oldComponents/drawer/Drawer.module.css';
import stylesInput from '../input/Input.module.css';

const Switch = ({
  phone,
  onChange,
  status,
  helperText,
}: {
  phone?: string;
  onChange: (phone?: string) => void;
  status: ComponentStatus;
  helperText?: string;
}) => (
  <div>
    <PhoneInput
      value={phone}
      className={status == ComponentStatus.ERROR ? stylesDrawer.inputClassError : stylesDrawer.inputClass}
      // buttonClass={stylesDrawer.buttonClass}
      placeholder=".. .. .. .. .."
      onChange={(phone: any) => onChange(phone === undefined ? null : phone)}
      international={true}
      displayInitialValueAsLocalNumber={false}
      addInternationalOption={false}
      defaultCountry="FR"
      countryOptionsOrder={['FR', 'CH', 'BE', '|', '...']}
      style={{ paddingLeft: '18px' }}
    />
    {helperText && (
      <div
        className={stylesInput.helperText}
        style={{ color: `var(--comp-textfield-color-helper-text-error)` }}>
        {helperText}
      </div>
    )}
  </div>
);

export default Switch;
