import { DraggableListState } from '../../../../../../components/oldComponents/draggableList/draggableList'
import { generateProductVariants } from '../../../../../../getters/catalog/variant'

const formatVariation = (variation: DraggableListState) => ({
  name: variation.name,
  options: variation.values ? variation.values.map((value) => value.name) : [],
})

const formatVariations = (productID: string, variations: Array<DraggableListState>) => ({
  productID,
  variations: variations.map(formatVariation),
})

export const callGenerateVariants = (productID: string, variations: Array<DraggableListState>, dispatch: any) =>
  generateProductVariants(formatVariations(productID, variations), dispatch)

const callVariantGeneration = async (
  input: { productID: string; variations: Array<DraggableListState> },
  dispatch: any
) => callGenerateVariants(input.productID, input.variations, dispatch)

export default callVariantGeneration
