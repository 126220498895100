import { Product } from '../API';
import { ProductWitSubValues } from '../store/types';

export const formatToggleAll = (selected: boolean) => (product: ProductWitSubValues) => {
  if (product) {
    return {
      ...product,
      selected: !!selected,
      subValues: product.subValues.map(sValue => ({ ...sValue, selected: !!selected })),
    };
  }
  return product;
};

const formatWithSubvalues = (selected: boolean, allSelected: boolean) => (product: Product) => {
  if (product) {
    return {
      ...product,
      selected: !!selected,
      allSelected: !!allSelected,
      photo: product.photo ? product.photo + '?' + Date.now() : undefined,
      inventoryQuantities: product.inventoryQuantities ?? [],
      variantsCount: product.variantsCount ?? product.variants?.items?.length ?? 0,
      subvalueNextToken: product.variants?.nextToken,
      subValues:
        product.variants && product.variants.items && product.variants.items.length > 0
          ? product.variants.items.map(mapSubvalues(selected))
          : [],
    };
  }
  return product;
};

export const formatWithOnlySubValues = (selected: boolean) => (product: ProductWitSubValues) => {
  if (product) {
    return {
      ...product,
      selected: !!selected,
      photo: product.photo ? product.photo + '?' + Date.now() : undefined,
      inventoryQuantities: product.inventoryQuantities ?? [],
      variantsCount: product.variantsCount ?? product.variants?.items?.length ?? 0,
      subvalueNextToken: product.variants?.nextToken,
      subValues:
        product.subValues && product.subValues.length > 0 ? product.subValues.map(mapOnlySubValues(selected)) : [],
    };
  }
  return product;
};

export const mapOnlySubValues = (selected?: boolean) => (variant: any) => ({
  id: variant.variantID,
  productID: variant.productID,
  variantID: variant.variantID,
  createdAt: variant.createdAt,
  name: variant.name,
  buyPrice: variant.buyPrice,
  sellPrice: variant.sellPrice,
  barcode: variant.barcode,
  reference: variant.reference,
  inventoryTracking: variant.inventoryTracking,
  category: variant.category,
  tax: variant.tax,
  taxID: variant.tax ? variant.tax.id : null,
  customFields: variant.customFields,
  inventoryQuantities: variant.inventoryQuantities,
  selected: !!selected,
});

export const mapSubvalues = (selected?: boolean) => (variant: any) => ({
  id: variant.variantID,
  productID: variant.productID,
  variantID: variant.variantID,
  createdAt: variant.createdAt,
  name:
    variant.options &&
    variant.options
      .filter((option: any) => option)
      .map((option: any) => option.value)
      .join(', '),
  buyPrice: variant.buyPrice,
  sellPrice: variant.sellPrice,
  barcode: variant.barcode,
  reference: variant.reference,
  inventoryTracking: variant.inventoryTracking,
  category: variant.category,
  tax: variant.tax,
  taxID: variant.taxID ? variant.taxID : null,
  customFields: variant.customFields,
  inventoryQuantities: variant.inventoryQuantities,
  selected: !!selected,
});

export default formatWithSubvalues;
