import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TypeDrawer, TypeTab } from '../../../../types/drawer'
import { DashboardView, DrawerState, DrawerType, objectType } from '../../../../store/types'
import { TypeProduct, Stats, APIOption, TypeTag, TypeVariation } from '../../../../types'
import { getProduct } from '../../../../getters/catalog/product'
import { getRandomColor } from '../../../../utils/color'
import Bulk from './bulk'
import Detail from './product'
import Inventory from './inventory'
import { TabIdsCatalog } from '../..'
import { Route } from '../../../../utils/navigation'
import { DataWithErrors } from '../../../../services/types'
import { customFieldsInState } from '../../../../services/setting/customField'
import { drawerResetHistory, updateDrawerState } from '../../../../actions/drawer'
import {
  name as nameValidator,
  taxID as taxIDValidator,
  categoryID as categoryIDValidator,
  categoryToCreate as categoryToCreateValidator,
  buyPrice as buyPriceValidator,
  sellPrice as sellPriceValidator,
  customFieldNumber as customFieldNumberValidator,
} from '../../../../format/errors/filters/catalog/product'
import * as selectors from '../../../../store/selector'
import DrawerMenu from '../../../../components/oldComponents/drawer/menu'
import DrawerTop from '../../../../components/oldComponents/drawer/top'
import {
  ComponentDirection,
  ComponentSize,
  ComponentState,
  ComponentType,
} from '../../../../components/newComponents/types'
import { updateModal } from '../../../../actions/modal'
import Delete from './bulk/delete'
import TabList from '../../../../components/newComponents/tabList'
import { DeleteProductInput } from '../../../../API'
import { callService } from '../../../../services'
import { deleteProduct } from '../../../../graphql/custom/mutations'
import Statistic from '../../../../components/oldComponents/drawer/statistic'
import { getProductStats } from '../../../../graphql/custom/queries'
import GenerateVariants from './product/variation/generate'
import FormVariation from './product/variation'
import { createPayload } from '../../../../utils/dirties'
import MenuVariant from './variant'
import generateDataCy from '../../../../utils/cypress'
import Divider from '../../../../components/newComponents/divider'
import { displayNumber, displayPercentage } from '../../../../utils/number'

export const MenuProduct = ({
  id,
  isDuplicate,
  history,
  addHistory,
  close,
}: {
  id?: string
  isDuplicate?: boolean
  history?: Array<Route>
  addHistory: (route: Route) => void
  close: () => void
}) => {
  const { t } = useTranslation()
  const listCategories = useSelector(selectors.listCategoryItems)
  const listCustomFields = useSelector(selectors.listCustomFieldItems)
  const listOptions = useSelector(selectors.listOptionItems)
  const drawerOpen = useSelector(selectors.isDrawerOpen)
  const allProducts = useSelector(selectors.listProducts)

  const initialState: {
    tabList: Array<TypeTab>
    detail: TypeProduct
    performance?: Stats
    inventory?: any
  } = {
    tabList: [
      { id: TabIdsCatalog.DETAIL, name: t('drawer.tabs.DETAIL'), state: ComponentState.ACTIVE },
      { id: TabIdsCatalog.STAT, name: t('drawer.tabs.PERFORMANCE'), state: ComponentState.DEFAULT },
      { id: TabIdsCatalog.INVENTORY, name: t('drawer.tabs.INVENTORY'), state: ComponentState.DEFAULT },
    ],
    detail: {
      actions: [
        {
          id: 'duplicate',
          value: t('drawer.menu.DUPLICATE'),
        },
        { id: 'delete', value: t('drawer.menu.DELETE') },
      ],
      productID: '',
      color: getRandomColor(),
      selected: 'color',
      categoryToCreate: '',
      tags: [],
      customFields: customFieldsInState('PRODUCT', listCustomFields),
      options: [],
      variations: [],
      isUpdateIconText: false,
      indexOfVariation: -1,
      variantsLength: 0,
      variants: [],
      variationView: false,
      isDeclined: false,
      isGenerate: false,
      isGenerating: false,
      isDuplicate: isDuplicate,
      showSubmit: false,
      dirties: id && !isDuplicate ? ['id'] : ['color'],
      errors: {
        customFields: customFieldsInState('PRODUCT', listCustomFields).map((_) => ({ value: false, message: '' })),
      },
      validators: {
        name: nameValidator,
        categoryID: categoryIDValidator,
        categoryToCreate: categoryToCreateValidator,
        buyPrice: buyPriceValidator,
        sellPrice: sellPriceValidator,
        taxID: taxIDValidator,
        customFields: customFieldNumberValidator,
      },
    },
  }
  const [state, setState] = useState(initialState)
  const mobileView = useSelector(selectors.getDashboardView)
  const company = useSelector(selectors.selectedCompany)
  // const product = useSelector(selectors.product(id))
  const dispatch = useDispatch()

  const cyContext = generateDataCy({
    scope: generateDataCy({
      scope: 'catalog',
      value: 'product',
    }),
    value: 'drawer',
  })

  const setProductInState = (data: DataWithErrors) => {
    if (data.data) {
      const product = data.data
      const customFields = customFieldsInState('PRODUCT', listCustomFields, product.customFields)

      let tabList = [
        { id: TabIdsCatalog.DETAIL, name: t('drawer.tabs.DETAIL'), state: ComponentState.ACTIVE },
        { id: TabIdsCatalog.STAT, name: t('drawer.tabs.PERFORMANCE'), state: ComponentState.DEFAULT },
        { id: TabIdsCatalog.INVENTORY, name: t('drawer.tabs.INVENTORY'), state: ComponentState.DEFAULT },
      ]
      
      if (product.variantsCount) {
        // remove stock for parent products
        tabList = tabList.splice(0, 2)
      }

      setState({
        ...state,
        tabList,
        detail: {
          ...state.detail,
          productID: isDuplicate ? '' : product.id,
          categoryToCreate: '',
          color: product.color,
          iconText: product.iconText,
          isUpdateIconText: product.iconText !== product.name.substring(0, 4),
          photo: product.photo,
          selected: !!product.photo ? 'photo' : 'color',
          name: product.name,
          categoryID: product.categoryID,
          taxID: product.taxID,
          reference: product.reference,
          sellPrice: product.sellPrice ? displayNumber(product.sellPrice, company.currencyDecimals) : product.sellPrice === 0 ? 0 : '',
          buyPrice: displayNumber(product.buyPrice, company.currencyDecimals),
          barcode: product.barcode,
          customFields,
          tags:
            product.tags.items.length > 0
              ? product.tags.items
                .filter((tag: { tag: TypeTag }) => tag.tag)
                .map((tag: { tag: TypeTag }) => ({
                  id: tag.tag.id,
                  name: tag.tag.name,
                }))
              : [],
          options: product.options
            ? product.options.items
              .filter((opt: { option: APIOption }) => opt && opt.option)
              .map(({ option }: { option: APIOption }) => ({
                id: option.id,
                name: option.name,
                values: option.values,
              }))
            : [],
          variations: product.variations
            ? product.variations.map((variation: { name: string; options: [] }) => ({
              previousName: variation.name,
              name: variation.name,
              values: variation.options.map((option: string) => ({
                previousName: option,
                name: option,
              })),
            }))
            : [],
          variantsLength: product.variantsCount,
          variants: product.variantsCount
            ? product.variations.map((option: { name: string }) => ({
              name: option.name,
              value: '',
              created: true,
            }))
            : [],
          variationView: false,
          errors: {
            customFields: customFields.map((cf) => ({ value: false, message: '' })),
          },
          isDeclined: !isDuplicate && product.variantsCount !== 0,
          isGenerate: false,
          isGenerating: false,
          indexOfVariation: -1,
          dirties: isDuplicate ? createPayload(product) : state.detail.dirties,
          showSubmit: !!isDuplicate,
        },
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    if (id && drawerOpen) {
      const product = allProducts.items.items.find((item) => item.id === id);
      getProduct(id, dispatch, product ? product.selected : false).then(setProductInState)
    }

      }, [id, isDuplicate, listCustomFields, listOptions, listCategories, drawerOpen])

  const updateState = (newState: any) => setState({ ...state, detail: { ...state.detail, ...newState } })

  const getVariantsLength = () =>
    state.detail.variations && state.detail.variations.length > 0
      ? state.detail.variations.reduce(
        (accVariation, variation) => (accVariation *= variation.values ? variation.values.length : 0),
        1
      )
      : 0

  const handleAddVariation = (variation: TypeVariation) => {
    if (variation.name && variation.values.length > 0) {
      state.detail.dirties = [...state.detail.dirties, 'variations']
      if (state.detail.variations && state.detail.indexOfVariation === -1) {
        state.detail.variations = [...state.detail.variations, variation]
        state.detail.indexOfVariation = 0
      } else if (state.detail.variations && state.detail.indexOfVariation !== undefined) {
        state.detail.variations[state.detail.indexOfVariation] = variation
      }
    }
    setState({
      ...state,
      detail: { ...state.detail, variantsLength: getVariantsLength(), variationView: false, showSubmit: true },
    })
  }

  const handleDeleteVariation = () => {
    state.detail.variations = state.detail.variations?.filter((_, index) => index !== state.detail.indexOfVariation)
    setState({
      ...state,
      detail: {
        ...state.detail,
        variantsLength: getVariantsLength(),
        variationView: false,
        showSubmit: true,
      },
    })
  }

  const ComponentToDisplay = () => {
    switch (state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id) {
      case TabIdsCatalog.DETAIL:
        return (
          <>
            {state.detail.isGenerate || state.detail.isGenerating ? (
              <GenerateVariants
                close={() => {
                  drawerResetHistory(dispatch)
                  close()
                }}
                state={state.detail}
              />
            ) : state.detail.variationView ? (
              state.detail.variations &&
              state.detail.indexOfVariation !== undefined &&
              state.detail.isDeclined !== undefined && (
                <FormVariation
                  variation={state.detail.variations[state.detail.indexOfVariation]}
                  variationNames={state.detail.variations
                    .filter((_, index) => index !== state.detail.indexOfVariation)
                    .map((variation) => variation.name)}
                  handleDeleteVariation={handleDeleteVariation}
                  handleAddVariation={handleAddVariation}
                  isDeclined={state.detail.isDeclined}
                  dataCy={cyContext}
                />
              )
            ) : (
              <Detail
                productState={state.detail}
                listOptions={listOptions}
                updateState={updateState}
                addHistory={addHistory}
                close={close}
                dataCy={cyContext}
              />
            )}
          </>
        )
      case TabIdsCatalog.STAT:
        return (
          <Statistic
            id={state.detail.productID!}
            name={state.detail.name ?? ''}
            query={getProductStats}
            queryName={'getProduct'}
            type={objectType.PRODUCT}
            close={close}
          />
        )
      case TabIdsCatalog.INVENTORY:
        return <Inventory productID={state.detail.productID} close={close} />
      default:
        return <></>
    }
  }

  const handleClickMenu = (action: string) => {
    switch (action) {
      case 'duplicate': {
        updateDrawerState(DrawerState.LOADING, dispatch)
        addHistory({
          component: MenuProduct,
          params: { id: state.detail.productID, isDuplicate: true },
        })
        break
      }
      case 'delete': {
        callService<{ input: DeleteProductInput }>(
          { input: { id: state.detail.productID } },
          deleteProduct,
          'deleteProduct'
        ).then((data) => {
          if (data.errors && data.errors.errorType === 'g-102') {
            const modal = () => <Delete id={data.payload.input.id} />
            updateModal(dispatch, true, modal, () => { })
          } else {
            drawerResetHistory(dispatch)
            close()
          }
        })
        break
      }
      default:
        break
    }
  }

  return (
    <>
      {state.detail.productID &&
        !state.detail.variationView &&
        !state.detail.isGenerate &&
        state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id !== TabIdsCatalog.STAT &&
        state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id !== TabIdsCatalog.INVENTORY ? (
        <DrawerTop
          history={history}
          name={state.detail.name ?? ''}
          mobileView={mobileView === DashboardView.MOBILE}
          burgerMenu={{
            actions: state.detail.actions!,
            onClick: handleClickMenu,
          }}
          close={close}
          dataCy={cyContext}
        />
      ) : (
        <DrawerTop
          history={state.detail.variationView ? [''] : history}
          name={state.detail.productID ? state.detail.name ?? '' : t('catalog.product.form.NEW')}
          mobileView={mobileView === DashboardView.MOBILE}
          close={() => {
            if (state.detail.variationView) setState({ ...state, detail: { ...state.detail, variationView: false } })
            else close()
          }}
          dataCy={cyContext}
        />
      )}
      {id && !isDuplicate && !state.detail.variationView && !state.detail.isGenerate && (
        <>
          <TabList
            tabList={state.tabList}
            onClick={(newTab: TypeTab) => {
              if (newTab.id !== TabIdsCatalog.DETAIL) updateDrawerState(DrawerState.LOADING, dispatch)
              setState({
                ...state,
                tabList: state.tabList.map((sTab) => ({
                  ...sTab,
                  state: newTab.id === sTab.id ? ComponentState.ACTIVE : ComponentState.DEFAULT,
                })),
              })
            }}
            size={ComponentSize.LARGE}
            dataCy={cyContext}
          />
          <Divider type={ComponentType.FILL} direction={ComponentDirection.HORIZONTAL} />
        </>
      )}
      <ComponentToDisplay />
    </>
  )
}

const Drawer = ({ selected = [], close }: TypeDrawer) => {
  const drawer = useSelector(selectors.currentDrawer)

  return (
    <DrawerMenu
      component={
        drawer.type === DrawerType.BULK
          ? () => Bulk({ close })
          : (param) =>
            drawer.subId
              ? MenuVariant({ ...param, productID: drawer.id, subId: drawer.subId, isDuplicate: undefined })
              : MenuProduct({
                ...param,
                id: drawer.id,
                isDuplicate: undefined,
              })
      }
    />
  )
}

export default Drawer
