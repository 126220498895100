const Company = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.16138 21C3.61138 21 3.14071 20.8043 2.74938 20.413C2.35738 20.021 2.16138 19.55 2.16138 19V5C2.16138 4.45 2.35738 3.979 2.74938 3.587C3.14071 3.19567 3.61138 3 4.16138 3H10.1614C10.7114 3 11.1824 3.19567 11.5744 3.587C11.9657 3.979 12.1614 4.45 12.1614 5V7H20.1614C20.7114 7 21.1824 7.19567 21.5744 7.587C21.9657 7.979 22.1614 8.45 22.1614 9V19C22.1614 19.55 21.9657 20.021 21.5744 20.413C21.1824 20.8043 20.7114 21 20.1614 21H4.16138ZM4.16138 19H6.16138V17H4.16138V19ZM4.16138 15H6.16138V13H4.16138V15ZM4.16138 11H6.16138V9H4.16138V11ZM4.16138 7H6.16138V5H4.16138V7ZM8.16138 19H10.1614V17H8.16138V19ZM8.16138 15H10.1614V13H8.16138V15ZM8.16138 11H10.1614V9H8.16138V11ZM8.16138 7H10.1614V5H8.16138V7ZM12.1614 19H20.1614V9H12.1614V11H14.1614V13H12.1614V15H14.1614V17H12.1614V19ZM16.1614 13V11H18.1614V13H16.1614ZM16.1614 17V15H18.1614V17H16.1614Z"
      fill={color}
    />
  </svg>
)

export default Company
