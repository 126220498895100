/**
 *
 * This method generates a data cy
 * Data cy is a unique id used for automatic testing
 *
 * @param scope - Global scope such as entity name (product..)
 * @param value - Suffix to add such as entity field (name..)
 *
 * @returns - A string
 *
 * @author - Arthur Escriou
 *
 */
const generateDataCy = ({ scope = '', value }: { scope: string; value: string }): string =>
  [scope, value].join('-').replace(' ', '')

export default generateDataCy
