import { TypeCol, TypeColumnConfiguration } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { enumToStr } from '../../utils/color'
import { ComponentSize } from '../../components/newComponents/types'
import ListItemAvatar from '../../components/newComponents/listItem/avatar'
import Thumbnail from '../../components/newComponents/thumbnail'

const CATEGORY = 'category'

export const categoryCols: Array<TypeCol> = [
  {
    id: 'color',
    title: '',
    type: 'element',
    active: true,
    function: ({ value }: any) =>
      value.photo || value.color
        ? ListItemAvatar({
            children: Thumbnail({
              color: enumToStr(value.color),
              size: ComponentSize.SMALL,
              image: value.photo,
              iconText: value.iconText,
            }),
          })
        : null,
  },
  {
    id: 'name',
    title: 'NAME',
    type: 'text',
    field: 'name',
    fieldType: 'string',
    active: true,
    sortable: true,
    notCustomable: true,
  },
  {
    id: 'createdAt',
    title: 'CREATED_AT',
    type: 'function',
    field: 'createdAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => displayDateShortMonthWithHours(date),
  },
]

export const categoriesColsConf: Array<TypeColumnConfiguration> = [
  {
    id: 'requestCategories',
    type: CATEGORY,
    cols: categoryCols,
    creatable: true,
    editable: false,
    clickable: true,
    exportable: false,
    shrinkrable: true,
    customable: true,
    checkboxes: true,
    searchBarPlaceholder: 'searchBar.placeholder.NAME',
    creatablePlaceholder: 'catalog.category.form.NEW',
  },
]
