import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../../components/requester/types'

const categoryFieldsRequester: Array<TypeRequester> = [
  {
    type: TypePath.PLAIN,
    filterID: 'CATEGORY_NAME',
    field: 'name',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
]

export default categoryFieldsRequester
