import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ListState, objectType } from '../../../store/types'
import List from '../../../components/list'
import Drawer from '.'
import { CustomFieldObjectType, CustomFieldValueType } from '../../../API'
import { TypeCustomField } from '../../../types'
import {
  listPackItemsNextToken,
  listPacks,
  listPackItems,
  listCustomFieldItems,
  getDashboardView,
} from '../../../store/selector'
import packFieldsRequester from '../../../requester/catalog/pack'
import { buildCols } from '../../../utils'
import { TypeComparator } from '../../../components/requester/types'
import addCustomFieldsInEntity from '../../../requester/catalog/customField'
import { useDispatch } from 'react-redux'
import { subscribePackCustomFields, subscribePacks, unSubscribePackCustomFields, unSubscribePacks } from '../../../getters/catalog/pack'
import { getAllPacks } from '../../../graphql/custom/queries'
import { getData } from '../../../services'
import { pluralize } from '../../../utils/typeToType'
import { fetchListElement, updateListState } from '../../../actions/list'

const ListPacks = () => {
  const res = useSelector(listPacks)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listPackItems)
  const { items, nextToken, sort } = useSelector(listPackItemsNextToken)
  const customFields: Array<TypeCustomField> = useSelector(listCustomFieldItems)
  const dashboardView = useSelector(getDashboardView)

  const dispatch = useDispatch()

  useEffect(() => {
    subscribePacks(dispatch)
    subscribePackCustomFields(dispatch)
    const getListPacks = async () => {
      const listAllPacks = await getData({ request: getAllPacks, limit: 100 });
      if (listAllPacks.requestPacks && listAllPacks.requestPacks.items && listAllPacks.requestPacks.items.length > 0) {
        const allPacks = listAllPacks.requestPacks.items
          .filter((_: any) => _)
          .map((pack: any) => ({
            ...pack,
            photo: pack.photo ? pack.photo + '?' + Date.now() : undefined,
            selected: false,
          }))
        fetchListElement(dispatch, allPacks, listAllPacks.requestPacks.nextToken, pluralize(objectType.PACK))
        updateListState(
          { type: pluralize(objectType.PACK), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.PACK))
        updateListState(
          { type: pluralize(objectType.PACK), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListPacks();

    return () => {
      unSubscribePacks()
      unSubscribePackCustomFields();
    };

      }, [])

  useEffect(() => setCols(buildCols(res.cols!, 'name', 'sellPrice', dashboardView)), [dashboardView, res.cols])

  const getAllFields = () => {
    const sortedCustomFields = [
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.TEXT),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.NUMBER),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.DATE),
    ]

    return [...packFieldsRequester, ...addCustomFieldsInEntity(sortedCustomFields, CustomFieldObjectType.PACK)]
  }

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={getAllFields()}
      searchBarFields={[
        { filter: TypeComparator.CONTAINS, field: 'name' },
        { filter: TypeComparator.CONTAINS, field: 'reference' },
      ]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.PACK}
      Drawer={Drawer}
    />
  )
}

export default ListPacks
