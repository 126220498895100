import { Action, Menu } from '../store/types'

const toggleMenu = (dispatch: any, payload: Menu) => {
  dispatch({
    type: Action.TOGGLE_MENU,
    payload,
  })
}

export default toggleMenu
