import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import generateDataCy from '../../../../utils/cypress'
import { selectedCompany } from '../../../../store/selector'
import DraggableList from '../../../../components/oldComponents/draggableList/draggableList'
import { ComponentErrorText, ComponentStatus } from '../../../../components/newComponents/types'
import Input from '../../../../components/newComponents/input'
import { TypeOption } from '../../../../types'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'

const Form = ({
  handleChange,
  handleBlur,
  handleSubmit,
  onDragEnd,
  addOption,
  deleteOption,
  state,
  dataCy,
}: {
  handleBlur: (field: string, index?: number) => (event: any) => void
  handleChange: (type: string) => (event: any) => void
  handleSubmit: () => void
  onDragEnd: (dragStart: number, dragEnd: number) => void
  addOption: (value: string) => void
  deleteOption: (id: string | undefined, indexSent: number) => void
  state: TypeOption
  dataCy: string
}) => {
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()

  const errorText = () => {
    if (state.errors?.name?.value) {
      return state.errors.name.message
    }

    if (state.errors.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <div className={styles.overflow}>
      <div className={styles.form}>
        <Input
          label={t('catalog.option.form.NAME')}
          value={state.name}
          onChange={handleChange('name')}
          onBlur={handleBlur('name')}
          onSubmit={handleSubmit}
          style={{
            status: state.errors.name?.value || state.errors.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={errorText()}
          dataCy={generateDataCy({ scope: dataCy, value: 'name' })}
        />
        <DraggableList
          label={t('catalog.option.form.VALUE')}
          // @ts-ignore
          list={state.values}
          entries={[]}
          decimals={company ? company.currencyDecimals : 0}
          currency={company ? company.currency : ''}
          onClickIcon={deleteOption}
          onChange={handleChange}
          onBlur={handleBlur}
          onDragEnd={onDragEnd}
          onCreate={addOption}
          iconColor="var(--sys-color-content-danger)"
          addText={t('catalog.option.form.ADD_VALUE')}
          onSubmit={handleSubmit}
          remove
          isEditable
          dataCy={generateDataCy({ scope: dataCy, value: 'values' })}
          isOption
        />
      </div>
    </div>
  )
}

export default Form
