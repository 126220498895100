/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryES = /* GraphQL */ `
  query QueryES(
    $operation: String!
    $path: String!
    $size: Int
    $query: AWSJSON!
  ) {
    queryES(operation: $operation, path: $path, size: $size, query: $query)
  }
`;
export const getApiKeyTactillV4 = /* GraphQL */ `
  query GetApiKeyTactillV4($email: AWSEmail!, $password: String!) {
    getApiKeyTactillV4(email: $email, password: $password)
  }
`;
export const getStripeConnectLink = /* GraphQL */ `
  query GetStripeConnectLink($companyID: ID!, $redirectPath: String!) {
    getStripeConnectLink(companyID: $companyID, redirectPath: $redirectPath)
  }
`;
export const getStripeConnectionStatus = /* GraphQL */ `
  query GetStripeConnectionStatus($companyID: ID!) {
    getStripeConnectionStatus(companyID: $companyID)
  }
`;
export const connectStripe = /* GraphQL */ `
  query ConnectStripe($companyID: ID!, $code: String!) {
    connectStripe(companyID: $companyID, code: $code)
  }
`;
export const disconnectStripe = /* GraphQL */ `
  query DisconnectStripe($companyID: ID!) {
    disconnectStripe(companyID: $companyID)
  }
`;
export const getStripeLocationID = /* GraphQL */ `
  query GetStripeLocationID($slotID: ID!) {
    getStripeLocationID(slotID: $slotID)
  }
`;
export const getStripeTerminalConnectionToken = /* GraphQL */ `
  query GetStripeTerminalConnectionToken($slotID: ID!) {
    getStripeTerminalConnectionToken(slotID: $slotID)
  }
`;
export const captureStripePaymentIntent = /* GraphQL */ `
  query CaptureStripePaymentIntent($companyID: ID!, $id: String!) {
    captureStripePaymentIntent(companyID: $companyID, id: $id)
  }
`;
export const refundStripePaymentIntent = /* GraphQL */ `
  query RefundStripePaymentIntent($companyID: ID!, $id: String!) {
    refundStripePaymentIntent(companyID: $companyID, id: $id)
  }
`;
export const getStripeCharge = /* GraphQL */ `
  query GetStripeCharge($companyID: ID!, $paymentIntentID: String!) {
    getStripeCharge(companyID: $companyID, paymentIntentID: $paymentIntentID)
  }
`;
export const getStripePaymentReceipt = /* GraphQL */ `
  query GetStripePaymentReceipt($companyID: ID!, $paymentIntentID: String!) {
    getStripePaymentReceipt(
      companyID: $companyID
      paymentIntentID: $paymentIntentID
    ) {
      cardNetwork
      contactlessInfo
      applicationID
      applicationName
      date
      merchantDescriptor
      merchantContractNumber
      merchantBankCode
      cardNumber
      applicationCryptogram
      transactionFileNumber
      amount
      transactionType
      merchantFooter
      destinationFooterMerchant
      destinationFooterCustomer
    }
  }
`;
export const getDashboardStats = /* GraphQL */ `
  query GetDashboardStats(
    $test: Boolean!
    $companyID: ID!
    $shopIDs: [ID]
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $interval: String
  ) {
    getDashboardStats(
      test: $test
      companyID: $companyID
      shopIDs: $shopIDs
      startDate: $startDate
      endDate: $endDate
      interval: $interval
    ) {
      content {
        general {
          content {
            nbSales
            index
            margin
          }
          histogram {
            id
          }
        }
        payments {
          totals {
            nbPayments
            nbPositivePayments
            nbNegativePayments
          }
          payments {
            id
            name
          }
        }
        taxes {
          totals {
            total
            totalTax
            totalTaxFree
          }
          taxes {
            id
          }
        }
        categories {
          totals {
            total
            totalTax
            totalTaxFree
          }
          quantity
          margin
          categories {
            id
            name
            quantity
            margin
          }
        }
        products {
          totals {
            total
            totalTax
            totalTaxFree
          }
          quantity
          margin
          products {
            id
            name
            quantity
            margin
          }
        }
      }
      byShop {
        id
        content {
          categories {
            quantity
            margin
          }
          products {
            quantity
            margin
          }
        }
      }
    }
  }
`;
export const getInventoryStats = /* GraphQL */ `
  query GetInventoryStats(
    $test: Boolean
    $companyID: ID!
    $shopIDs: [ID]
    $date: AWSDateTime
    $accountID: ID
  ) {
    getInventoryStats(
      test: $test
      companyID: $companyID
      shopIDs: $shopIDs
      date: $date
      accountID: $accountID
    ) {
      products {
        value
        products {
          id
          name
          quantity
          value
          variants {
            id
            name
            quantity
            value
          }
        }
      }
      byShop {
        id
        content {
          value
          products {
            id
            name
            quantity
            value
          }
        }
      }
    }
  }
`;
export const exportInventory = /* GraphQL */ `
  query ExportInventory(
    $test: Boolean
    $companyID: ID!
    $shopIDs: [ID]
    $date: AWSDateTime
    $email: AWSEmail
    $accountID: ID
  ) {
    exportInventory(
      test: $test
      companyID: $companyID
      shopIDs: $shopIDs
      date: $date
      email: $email
      accountID: $accountID
    )
  }
`;
export const getChargebeePortalSession = /* GraphQL */ `
  query GetChargebeePortalSession($companyID: ID!, $redirectPath: String) {
    getChargebeePortalSession(
      companyID: $companyID
      redirectPath: $redirectPath
    )
  }
`;
export const getImportFile = /* GraphQL */ `
  query GetImportFile($sheetID: String!) {
    getImportFile(sheetID: $sheetID)
  }
`;
export const getFlatfileWorkbookConfig = /* GraphQL */ `
  query GetFlatfileWorkbookConfig(
    $companyID: String!
    $test: Boolean!
    $metadata: AWSJSON
  ) {
    getFlatfileWorkbookConfig(
      companyID: $companyID
      test: $test
      metadata: $metadata
    )
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      slotID
      userID
      test
      version
      date
      type
      code
      objectType
      objectID
      action
      actionData
      hash
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $companyID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listEvents(
      companyID: $companyID
      createdAt: $createdAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        slotID
        userID
        test
        version
        date
        type
        code
        objectType
        objectID
        action
        actionData
        hash
      }
      nextToken
    }
  }
`;
export const getUserNotification = /* GraphQL */ `
  query GetUserNotification($id: ID!) {
    getUserNotification(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      eventObjectType
      eventAction
      action {
        type
        details {
          ... on NotificationActionDetailsWebhook {
            url
          }
        }
      }
      active
    }
  }
`;
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications($companyID: ID!) {
    listUserNotifications(companyID: $companyID) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        eventObjectType
        eventAction
        action {
          type
          details {
            ... on NotificationActionDetailsWebhook {
              url
            }
          }
        }
        active
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount {
    getAccount {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      phone
      locale
      companies {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          owner
          role
          read
          update
          billing
          billingExport
          shopWrite
          deviceSlotWrite
          deviceSlotUse
          paymentMethodRead
          paymentMethodCreate
          paymentMethodUpdate
          paymentMethodDelete
          customerRead
          customerCreate
          customerUpdate
          customerDelete
          customerExport
          inventoryRead
          cashbookRead
          saleRead
          paymentRead
          userInvite
          userRead
          userUpdate
          userRemove
          sell
          sync
          id
        }
        nextToken
      }
      catalog {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          catalogID
          catalog {
            updatedAt
            createdAt
            deprecated
            companyID
            id
          }
          read
          create
          update
          delete
          sync
          id
        }
        nextToken
      }
      shops {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          shopID
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
          read
          update
          delete
          inventoryRead
          inventoryWrite
          cashbookRead
          cashbookWrite
          saleRead
          saleWrite
          paymentRead
          paymentWrite
          userInvite
          userRead
          userUpdate
          userRemove
          activitiesExport
          tillCashbooksRead
          tillSalesSearch
          tillSaleSell
          tillSaleRefund
          tillSaleReturn
          tillCashbookOpen
          tillCashbookClose
          eventCreate
          sync
          id
        }
        nextToken
      }
      sellerCodes {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          code
          id
        }
        nextToken
      }
      intercomHMAC
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      name
      address
      country
      currency
      currencyDecimals
      locale
      vat
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      name
      address {
        address
        zipcode
        city
        country
      }
      siret
      vat
      phone
      footnote
      logo
      websiteURL
      brandName
      email
    }
  }
`;
export const getSellerCode = /* GraphQL */ `
  query GetSellerCode($companyID: ID!) {
    getSellerCode(companyID: $companyID) {
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      code
      id
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations {
    listInvitations {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      companyName
      email
      role
      shops
      expiresAt
      status
      senderID
      senderName
      senderEmail
    }
  }
`;
export const listCompanyInvitations = /* GraphQL */ `
  query ListCompanyInvitations($companyID: ID!) {
    listCompanyInvitations(companyID: $companyID) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        companyName
        email
        role
        shops
        expiresAt
        status
        senderID
        senderName
        senderEmail
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($companyID: ID!) {
    listUsers(companyID: $companyID) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      company {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
      shops {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($email: AWSEmail!, $companyID: ID!) {
    getUser(email: $email, companyID: $companyID) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      company {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
      shops {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
export const getCompanySetting = /* GraphQL */ `
  query GetCompanySetting($id: ID!) {
    getCompanySetting(id: $id) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const listCompanySettings = /* GraphQL */ `
  query ListCompanySettings($companyID: ID!) {
    listCompanySettings(companyID: $companyID) {
      items {
        id
        updatedAt
        createdAt
        companyID
        accountID
        key
        value
      }
      nextToken
    }
  }
`;
export const getSlot = /* GraphQL */ `
  query GetSlot($id: ID!) {
    getSlot(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      name
      status
    }
  }
`;
export const listSlots = /* GraphQL */ `
  query ListSlots(
    $shopID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listSlots(
      shopID: $shopID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        name
        status
      }
      nextToken
    }
  }
`;
export const listSlotsByCompany = /* GraphQL */ `
  query ListSlotsByCompany(
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listSlotsByCompany(
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        name
        status
      }
      nextToken
    }
  }
`;
export const getGridCell = /* GraphQL */ `
  query GetGridCell($id: ID!) {
    getGridCell(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      compositeID
      catalogID
      test
      deviceID
      page
      column
      row
      type
      objectID
    }
  }
`;
export const listGridCells = /* GraphQL */ `
  query ListGridCells(
    $test: Boolean!
    $catalogID: ID!
    $deviceID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listGridCells(
      test: $test
      catalogID: $catalogID
      deviceID: $deviceID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        compositeID
        catalogID
        test
        deviceID
        page
        column
        row
        type
        objectID
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      categoryID
      category {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      bulkType
      buyPrice
      sellPrice
      barcode
      reference
      variations {
        name
        options
      }
      variantsCount
      variants {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          id
          productID
          variantID
          options {
            name
            value
          }
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
          stats {
            margin
            quantity
          }
        }
        nextToken
      }
      options {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          optionID
          option {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
          }
          id
        }
        nextToken
      }
      tags {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          tagID
          tag {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            createdByID
          }
          id
        }
        nextToken
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $test: Boolean!
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listProducts(
      test: $test
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      nextToken
    }
  }
`;
export const requestProducts = /* GraphQL */ `
  query RequestProducts(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterProductFilterInput
    $sort: RequesterProductSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestProducts(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const exportProducts = /* GraphQL */ `
  query ExportProducts(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterProductFilterInput
    $sort: RequesterProductSortInput
    $email: AWSEmail
  ) {
    exportProducts(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getProductVariant = /* GraphQL */ `
  query GetProductVariant($productID: ID!, $variantID: ID!) {
    getProductVariant(productID: $productID, variantID: $variantID) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      id
      productID
      variantID
      options {
        name
        value
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      buyPrice
      sellPrice
      barcode
      reference
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          categoryID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $test: Boolean!
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listCategories(
      test: $test
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      nextToken
    }
  }
`;
export const requestCategories = /* GraphQL */ `
  query RequestCategories(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterCategoryFilterInput
    $sort: RequesterCategorySortInput
    $limit: Int
    $nextToken: String
  ) {
    requestCategories(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const exportCategories = /* GraphQL */ `
  query ExportCategories(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterCategoryFilterInput
    $sort: RequesterCategorySortInput
    $email: AWSEmail
  ) {
    exportCategories(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getCustomField = /* GraphQL */ `
  query GetCustomField($id: ID!) {
    getCustomField(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      key
      objectType
      valueType
      name
      createdByID
    }
  }
`;
export const listCustomFields = /* GraphQL */ `
  query ListCustomFields(
    $test: Boolean!
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listCustomFields(
      test: $test
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      nextToken
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      photo
      type
      rate
      stats {
        amount
        quantity
        histogram {
          id
          amount
        }
      }
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts(
    $test: Boolean!
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listDiscounts(
      test: $test
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        photo
        type
        rate
        stats {
          amount
          quantity
          histogram {
            id
            amount
          }
        }
      }
      nextToken
    }
  }
`;
export const requestDiscounts = /* GraphQL */ `
  query RequestDiscounts(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterDiscountFilterInput
    $sort: RequesterDiscountSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestDiscounts(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        photo
        type
        rate
        stats {
          amount
          quantity
          histogram {
            id
            amount
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const exportDiscounts = /* GraphQL */ `
  query ExportDiscounts(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterDiscountFilterInput
    $sort: RequesterDiscountSortInput
    $email: AWSEmail
  ) {
    exportDiscounts(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      values {
        name
        price
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $test: Boolean!
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listOptions(
      test: $test
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        values {
          name
          price
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      nextToken
    }
  }
`;
export const requestOptions = /* GraphQL */ `
  query RequestOptions(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterOptionFilterInput
    $sort: RequesterOptionSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestOptions(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        values {
          name
          price
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const exportOptions = /* GraphQL */ `
  query ExportOptions(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterOptionFilterInput
    $sort: RequesterOptionSortInput
    $email: AWSEmail
  ) {
    exportOptions(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getPack = /* GraphQL */ `
  query GetPack($id: ID!) {
    getPack(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      barcode
      reference
      sellPrice
      packVariations {
        options {
          productID
          quantity
          additionalPrice
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          packID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const listPacks = /* GraphQL */ `
  query ListPacks(
    $test: Boolean!
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listPacks(
      test: $test
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        barcode
        reference
        sellPrice
        packVariations {
          options {
            productID
            quantity
            additionalPrice
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            packID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      nextToken
    }
  }
`;
export const requestPacks = /* GraphQL */ `
  query RequestPacks(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterPackFilterInput
    $sort: RequesterPackSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestPacks(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        barcode
        reference
        sellPrice
        packVariations {
          options {
            productID
            quantity
            additionalPrice
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            packID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const exportPacks = /* GraphQL */ `
  query ExportPacks(
    $test: Boolean!
    $catalogID: ID!
    $filter: RequesterPackFilterInput
    $sort: RequesterPackSortInput
    $email: AWSEmail
  ) {
    exportPacks(
      test: $test
      catalogID: $catalogID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      createdByID
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $test: Boolean!
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listTags(
      test: $test
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        createdByID
      }
      nextToken
    }
  }
`;
export const getTax = /* GraphQL */ `
  query GetTax($id: ID!) {
    getTax(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      rate
    }
  }
`;
export const listTaxes = /* GraphQL */ `
  query ListTaxes(
    $test: Boolean!
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listTaxes(
      test: $test
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      nextToken
    }
  }
`;
export const getInventoryMovement = /* GraphQL */ `
  query GetInventoryMovement($id: ID!) {
    getInventoryMovement(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const getInventoryMovementLineProduct = /* GraphQL */ `
  query GetInventoryMovementLineProduct($id: ID!) {
    getInventoryMovementLineProduct(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      inventoryMovementID
      productID
      details {
        name
        sellPrice
        buyPrice
        bulkType
        barcode
        reference
        categoryID
        taxID
        category {
          name
        }
        tax {
          rate
        }
        tags {
          tagID
          name
        }
        customFields {
          customFieldID
          name
          objecType
          valueType
          value
        }
      }
      hasVariants
      variants {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementProductID
          productID
          variantID
          details {
            nameShort
            nameLong
            sellPrice
            buyPrice
            barcode
            reference
          }
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      variantsCount
      variantsSelectedAll
      variantsAll {
        items {
          productID
          variantID
          details {
            nameShort
            nameLong
            sellPrice
            buyPrice
            barcode
            reference
          }
          line {
            id
            updatedAt
            createdAt
            deprecated
            inventoryMovementProductID
            productID
            variantID
            quantity
            actualQuantity
            price
          }
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      variantsCountAll
      quantity
      actualQuantity
      price
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
    }
  }
`;
export const listInventoryMovements = /* GraphQL */ `
  query ListInventoryMovements(
    $test: Boolean!
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listInventoryMovements(
      test: $test
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        state
        stateDate
        motive
        origin
        originID
        destination
        destinationID
        totalQuantity
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            inventoryMovementID
            productID
            variantID
            quantity
            actualQuantity
            price
          }
          nextToken
        }
        products {
          items {
            id
            updatedAt
            createdAt
            deprecated
            inventoryMovementID
            productID
            hasVariants
            variantsCount
            variantsSelectedAll
            variantsCountAll
            quantity
            actualQuantity
            price
          }
          nextToken
        }
        history {
          items {
            id
            updatedAt
            createdAt
            deprecated
            inventoryMovementID
            state
            date
            motive
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const requestInventoryMovements = /* GraphQL */ `
  query RequestInventoryMovements(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterInventoryMovementFilterInput
    $sort: RequesterInventoryMovementSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestInventoryMovements(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        state
        stateDate
        motive
        origin
        originID
        destination
        destinationID
        totalQuantity
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            inventoryMovementID
            productID
            variantID
            quantity
            actualQuantity
            price
          }
          nextToken
        }
        products {
          items {
            id
            updatedAt
            createdAt
            deprecated
            inventoryMovementID
            productID
            hasVariants
            variantsCount
            variantsSelectedAll
            variantsCountAll
            quantity
            actualQuantity
            price
          }
          nextToken
        }
        history {
          items {
            id
            updatedAt
            createdAt
            deprecated
            inventoryMovementID
            state
            date
            motive
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const exportInventoryMovement = /* GraphQL */ `
  query ExportInventoryMovement($id: ID!, $email: AWSEmail) {
    exportInventoryMovement(id: $id, email: $email)
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      name
      color
      type
      changeType
      countOnCashbookOpeningClosing
      opensCashDrawer
      enabled
    }
  }
`;
export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods(
    $test: Boolean!
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listPaymentMethods(
      test: $test
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        name
        color
        type
        changeType
        countOnCashbookOpeningClosing
        opensCashDrawer
        enabled
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $test: Boolean!
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(
      test: $test
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      nextToken
    }
  }
`;
export const requestCustomers = /* GraphQL */ `
  query RequestCustomers(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterCustomerFilterInput
    $sort: RequesterCustomerSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestCustomers(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      nextToken
      total
    }
  }
`;
export const exportCustomers = /* GraphQL */ `
  query ExportCustomers(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterCustomerFilterInput
    $sort: RequesterCustomerSortInput
    $email: AWSEmail
  ) {
    exportCustomers(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getCustomerAccountMovement = /* GraphQL */ `
  query GetCustomerAccountMovement($id: ID!) {
    getCustomerAccountMovement(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      state
      type
      amount
      saleID
      paymentID
      movementInDetails {
        paymentMethodID
        paymentMethodName
        paymentMethodType
      }
    }
  }
`;
export const listCustomerAccountMovements = /* GraphQL */ `
  query ListCustomerAccountMovements(
    $test: Boolean!
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listCustomerAccountMovements(
      test: $test
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        customerID
        state
        type
        amount
        saleID
        paymentID
        movementInDetails {
          paymentMethodID
          paymentMethodName
          paymentMethodType
        }
      }
      nextToken
    }
  }
`;
export const getSale = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          minSaleNumber
          maxSaleNumber
          numberOfSales
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            minSaleNumber
            maxSaleNumber
            numberOfSales
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const listSales = /* GraphQL */ `
  query ListSales(
    $test: Boolean!
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listSales(
      test: $test
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            minSaleNumber
            maxSaleNumber
            numberOfSales
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      nextToken
    }
  }
`;
export const requestSales = /* GraphQL */ `
  query RequestSales(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterSaleFilterInput
    $sort: RequesterSaleSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestSales(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            minSaleNumber
            maxSaleNumber
            numberOfSales
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      nextToken
      total
    }
  }
`;
export const exportSales = /* GraphQL */ `
  query ExportSales(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterSaleFilterInput
    $sort: RequesterSaleSortInput
    $email: AWSEmail
  ) {
    exportSales(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getCashbook = /* GraphQL */ `
  query GetCashbook($id: ID!) {
    getCashbook(id: $id) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      number
      state
      total
      totalTaxFree
      openedAt
      closedAt
      openingSellerID
      closingSellerID
      openingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      closingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      note
      movements {
        type
        amount
        motive
        createdAt
      }
      openingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      closingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      totalExpected
      totalDifference
      customFields {
        customFieldID
        name
        valueType
        value
      }
      stats {
        minSaleNumber
        maxSaleNumber
        numberOfSales
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        payments {
          id
          detail {
            amount
            quantity
          }
          sellDetail {
            amount
            quantity
          }
          refundDetail {
            amount
            quantity
          }
        }
      }
    }
  }
`;
export const listCashbooks = /* GraphQL */ `
  query ListCashbooks(
    $test: Boolean!
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listCashbooks(
      test: $test
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          minSaleNumber
          maxSaleNumber
          numberOfSales
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      nextToken
    }
  }
`;
export const requestCashbooks = /* GraphQL */ `
  query RequestCashbooks(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterCashbookFilterInput
    $sort: RequesterCashbookSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestCashbooks(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          minSaleNumber
          maxSaleNumber
          numberOfSales
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const exportCashbooks = /* GraphQL */ `
  query ExportCashbooks(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterCashbookFilterInput
    $sort: RequesterCashbookSortInput
    $email: AWSEmail
  ) {
    exportCashbooks(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      saleID
      number
      date
      state
      type
      paymentMethodID
      paymentMethodName
      paymentMethodType
      sourcePaymentID
      customerMovementID
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          minSaleNumber
          maxSaleNumber
          numberOfSales
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      amount
      totalTaxFree
      totalTax
      taxes {
        taxID
        rate
        inclusive
        total
        totalTax
        totalTaxFree
      }
      integrationPaymentID
      integrationRefundID
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $test: Boolean!
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listPayments(
      test: $test
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        saleID
        number
        date
        state
        type
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            minSaleNumber
            maxSaleNumber
            numberOfSales
          }
        }
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        integrationPaymentID
        integrationRefundID
      }
      nextToken
    }
  }
`;
export const requestPayments = /* GraphQL */ `
  query RequestPayments(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterPaymentFilterInput
    $sort: RequesterPaymentSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestPayments(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        saleID
        number
        date
        state
        type
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            minSaleNumber
            maxSaleNumber
            numberOfSales
          }
        }
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        integrationPaymentID
        integrationRefundID
      }
      nextToken
      total
    }
  }
`;
export const exportPayments = /* GraphQL */ `
  query ExportPayments(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterPaymentFilterInput
    $sort: RequesterPaymentSortInput
    $email: AWSEmail
  ) {
    exportPayments(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      email: $email
    )
  }
`;
export const getBackgroundJob = /* GraphQL */ `
  query GetBackgroundJob($id: ID!) {
    getBackgroundJob(id: $id) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      shopID
      sourceEmail
      type
      state
      details {
        ... on CatalogMigrationDetails {
          PRODUCT {
            total
            successCount
            errorCount
          }
          PRODUCT_VARIANT {
            total
            successCount
            errorCount
          }
          CATEGORY {
            total
            successCount
            errorCount
          }
          TAX {
            total
            successCount
            errorCount
          }
          DISCOUNT {
            total
            successCount
            errorCount
          }
        }
        ... on CustomerMigrationDetails {
          CUSTOMER {
            total
            successCount
            errorCount
          }
        }
        ... on InventoryMigrationDetails {
          INVENTORY {
            total
            successCount
            errorCount
          }
        }
        ... on ActivityMigrationDetails {
          PAYMENT_METHOD {
            total
            successCount
            errorCount
          }
          SLOT {
            total
            successCount
            errorCount
          }
          CASHBOOK {
            total
            successCount
            errorCount
          }
          SALE {
            total
            successCount
            errorCount
          }
          PAYMENT {
            total
            successCount
            errorCount
          }
        }
        ... on ImportDetails {
          IMPORT {
            total
            successCount
            errorCount
          }
        }
      }
      message
      reportUrl
      flatfileBatchId
      flatfileSheetId
    }
  }
`;
export const listBackgroundJobs = /* GraphQL */ `
  query ListBackgroundJobs(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listBackgroundJobs(
      companyID: $companyID
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        shopID
        sourceEmail
        type
        state
        details {
          ... on CatalogMigrationDetails {
            PRODUCT {
              total
              successCount
              errorCount
            }
            PRODUCT_VARIANT {
              total
              successCount
              errorCount
            }
            CATEGORY {
              total
              successCount
              errorCount
            }
            TAX {
              total
              successCount
              errorCount
            }
            DISCOUNT {
              total
              successCount
              errorCount
            }
          }
          ... on CustomerMigrationDetails {
            CUSTOMER {
              total
              successCount
              errorCount
            }
          }
          ... on InventoryMigrationDetails {
            INVENTORY {
              total
              successCount
              errorCount
            }
          }
          ... on ActivityMigrationDetails {
            PAYMENT_METHOD {
              total
              successCount
              errorCount
            }
            SLOT {
              total
              successCount
              errorCount
            }
            CASHBOOK {
              total
              successCount
              errorCount
            }
            SALE {
              total
              successCount
              errorCount
            }
            PAYMENT {
              total
              successCount
              errorCount
            }
          }
          ... on ImportDetails {
            IMPORT {
              total
              successCount
              errorCount
            }
          }
        }
        message
        reportUrl
        flatfileBatchId
        flatfileSheetId
      }
      nextToken
    }
  }
`;
export const requestBackgroundJobs = /* GraphQL */ `
  query RequestBackgroundJobs(
    $test: Boolean!
    $companyID: ID!
    $filter: RequesterBackgroundJobFilterInput
    $sort: RequesterBackgroundJobSortInput
    $limit: Int
    $nextToken: String
  ) {
    requestBackgroundJobs(
      test: $test
      companyID: $companyID
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        shopID
        sourceEmail
        type
        state
        details {
          ... on CatalogMigrationDetails {
            PRODUCT {
              total
              successCount
              errorCount
            }
            PRODUCT_VARIANT {
              total
              successCount
              errorCount
            }
            CATEGORY {
              total
              successCount
              errorCount
            }
            TAX {
              total
              successCount
              errorCount
            }
            DISCOUNT {
              total
              successCount
              errorCount
            }
          }
          ... on CustomerMigrationDetails {
            CUSTOMER {
              total
              successCount
              errorCount
            }
          }
          ... on InventoryMigrationDetails {
            INVENTORY {
              total
              successCount
              errorCount
            }
          }
          ... on ActivityMigrationDetails {
            PAYMENT_METHOD {
              total
              successCount
              errorCount
            }
            SLOT {
              total
              successCount
              errorCount
            }
            CASHBOOK {
              total
              successCount
              errorCount
            }
            SALE {
              total
              successCount
              errorCount
            }
            PAYMENT {
              total
              successCount
              errorCount
            }
          }
          ... on ImportDetails {
            IMPORT {
              total
              successCount
              errorCount
            }
          }
        }
        message
        reportUrl
        flatfileBatchId
        flatfileSheetId
      }
      nextToken
      total
    }
  }
`;
export const getMigrationPreview = /* GraphQL */ `
  query GetMigrationPreview(
    $migrationTypes: [MigrationType!]
    $apiKey: String!
  ) {
    getMigrationPreview(migrationTypes: $migrationTypes, apiKey: $apiKey) {
      ... on CatalogPreviewDetails {
        PRODUCT {
          count
        }
        PRODUCT_VARIANT {
          count
        }
        CATEGORY {
          count
        }
        TAX {
          count
        }
        DISCOUNT {
          count
        }
      }
      ... on CustomerPreviewDetails {
        CUSTOMER {
          count
        }
      }
      ... on InventoryPreviewDetails {
        INVENTORY {
          count
        }
      }
      ... on ActivityPreviewDetails {
        PAYMENT_METHOD {
          count
        }
        SLOT {
          count
        }
        CASHBOOK {
          count
        }
        SALE {
          count
        }
      }
    }
  }
`;
export const syncCatalog = /* GraphQL */ `
  query SyncCatalog(
    $catalogID: ID!
    $test: Boolean!
    $lastSyncDate: AWSDateTime
    $limit: Int
    $nextToken: String
  ) {
    syncCatalog(
      catalogID: $catalogID
      test: $test
      lastSyncDate: $lastSyncDate
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ... on Product {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          categoryID
          category {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            color
            iconText
            photo
            customFields {
              nextToken
            }
            stats {
              quantity
            }
          }
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          bulkType
          buyPrice
          sellPrice
          barcode
          reference
          variations {
            name
            options
          }
          variantsCount
          variants {
            items {
              updatedAt
              createdAt
              deprecated
              catalogID
              test
              id
              productID
              variantID
              taxID
              buyPrice
              sellPrice
              barcode
              reference
            }
            nextToken
          }
          options {
            items {
              updatedAt
              createdAt
              deprecated
              catalogID
              test
              productID
              optionID
              id
            }
            nextToken
          }
          tags {
            items {
              updatedAt
              createdAt
              deprecated
              catalogID
              test
              productID
              tagID
              id
            }
            nextToken
          }
          customFields {
            items {
              updatedAt
              createdAt
              deprecated
              catalogID
              test
              productID
              customFieldID
              customFieldKey
              value
              id
            }
            nextToken
          }
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
            shop {
              id
              updatedAt
              createdAt
              deprecated
              companyID
              name
              siret
              vat
              phone
              footnote
              logo
              websiteURL
              brandName
              email
            }
          }
          stats {
            margin
            quantity
            histogram {
              id
            }
          }
          inventoryStats {
            content {
              quantity
              value
            }
            byShop {
              id
            }
          }
        }
      }
      nextToken
      lastChangedAt
    }
  }
`;
export const syncCompany = /* GraphQL */ `
  query SyncCompany(
    $companyID: ID!
    $test: Boolean!
    $lastSyncDate: AWSDateTime
    $limit: Int
    $nextToken: String
  ) {
    syncCompany(
      companyID: $companyID
      test: $test
      lastSyncDate: $lastSyncDate
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ... on Company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
      }
      nextToken
      lastChangedAt
    }
  }
`;
export const syncShop = /* GraphQL */ `
  query SyncShop(
    $shopID: ID!
    $test: Boolean!
    $lastSyncDate: AWSDateTime
    $limit: Int
    $nextToken: String
  ) {
    syncShop(
      shopID: $shopID
      test: $test
      lastSyncDate: $lastSyncDate
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ... on Shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      nextToken
      lastChangedAt
    }
  }
`;
export const syncSlot = /* GraphQL */ `
  query SyncSlot(
    $slotID: ID!
    $test: Boolean!
    $lastSyncDate: AWSDateTime
    $limit: Int
    $nextToken: String
  ) {
    syncSlot(
      slotID: $slotID
      test: $test
      lastSyncDate: $lastSyncDate
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ... on Sale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            openingSeller {
              id
              updatedAt
              createdAt
              deprecated
              firstName
              lastName
              color
              iconText
              photo
              email
            }
            closingSeller {
              id
              updatedAt
              createdAt
              deprecated
              firstName
              lastName
              color
              iconText
              photo
              email
            }
            note
            movements {
              type
              amount
              motive
              createdAt
            }
            openingCashFund {
              paymentMethodID
              paymentMethodName
              paymentMethodType
              total
            }
            closingCashFund {
              paymentMethodID
              paymentMethodName
              paymentMethodType
              total
            }
            totalExpected
            totalDifference
            customFields {
              customFieldID
              name
              valueType
              value
            }
            stats {
              minSaleNumber
              maxSaleNumber
              numberOfSales
            }
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            cashbook {
              id
              originalID
              updatedAt
              createdAt
              deprecated
              companyID
              shopID
              test
              sourceID
              sourceName
              number
              state
              total
              totalTaxFree
              openedAt
              closedAt
              openingSellerID
              closingSellerID
              note
              totalExpected
              totalDifference
            }
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            targetSale {
              id
              originalID
              updatedAt
              createdAt
              deprecated
              companyID
              shopID
              test
              sourceID
              sourceName
              cashbookID
              name
              number
              state
              refundStatus
              refundedStatus
              paymentStatus
              note
              targetID
              openedAt
              closedAt
              pendingAt
              customerID
              sellerID
              total
              totalDiscount
              totalTaxFree
              totalTax
              totalRest
            }
            refundSales {
              nextToken
            }
            openedAt
            closedAt
            pendingAt
            customerID
            customer {
              id
              originalID
              updatedAt
              createdAt
              deprecated
              companyID
              test
              firstName
              lastName
              email
              phone
              phoneCode
              companyName
              color
              iconText
              note
              fidelityCardNumber
            }
            sellerID
            seller {
              id
              updatedAt
              createdAt
              deprecated
              firstName
              lastName
              color
              iconText
              photo
              email
            }
            lines {
              quantity
              price
              refundedQuantity
              note
              operationType
              state
              targetSaleLineIndex
              motive
              total
              totalSellPrice
              totalDiscount
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            discounts {
              rate
              type
              discountID
              discountName
              amount
            }
            payments {
              state
              type
              date
              paymentMethodID
              paymentMethodName
              paymentMethodType
              sourcePaymentID
              customerMovementID
              cashbookID
              amount
              totalTaxFree
              totalTax
              paymentID
              integrationPaymentID
              integrationRefundID
            }
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
            stats {
              margin
            }
          }
          refundSales {
            items {
              id
              originalID
              updatedAt
              createdAt
              deprecated
              companyID
              shopID
              test
              sourceID
              sourceName
              cashbookID
              name
              number
              state
              refundStatus
              refundedStatus
              paymentStatus
              note
              targetID
              openedAt
              closedAt
              pendingAt
              customerID
              sellerID
              total
              totalDiscount
              totalTaxFree
              totalTax
              totalRest
            }
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            address {
              address
              zipcode
              city
              country
            }
            note
            fidelityCardNumber
            account {
              id
              updatedAt
              createdAt
              deprecated
              companyID
              test
              balance
            }
            customFields {
              nextToken
            }
            stats {
              nbSales
              index
              totalDiscount
            }
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            details {
              ... on SaleLinePackDetails {
                packID
                name
              }
              ... on SaleLineProductDetails {
                productID
                name
                bulkType
                total
                totalTax
                totalTaxFree
                totalGlobalDiscountFree
                totalTaxFreeGlobalDiscountFree
                totalTaxGlobalDiscountFree
                unitPriceGlobalDiscountFree
                totalDiscountFree
                totalTaxFreeDiscountFree
                totalTaxDiscountFree
                unitPriceDiscountFree
              }
            }
            quantity
            price
            refundedQuantity
            discounts {
              rate
              type
              discountID
              discountName
              amount
            }
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            taxes {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            paymentID
            payment {
              id
              originalID
              updatedAt
              createdAt
              deprecated
              companyID
              shopID
              test
              sourceID
              sourceName
              saleID
              number
              date
              state
              type
              paymentMethodID
              paymentMethodName
              paymentMethodType
              sourcePaymentID
              customerMovementID
              cashbookID
              amount
              totalTaxFree
              totalTax
              integrationPaymentID
              integrationRefundID
            }
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            taxes {
              id
            }
            margin
          }
        }
      }
      nextToken
      lastChangedAt
    }
  }
`;
