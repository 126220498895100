const List = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1614 9H16.1614C16.7114 9 17.1614 8.55 17.1614 8C17.1614 7.45 16.7114 7 16.1614 7H12.1614C11.6114 7 11.1614 7.45 11.1614 8C11.1614 8.55 11.6114 9 12.1614 9ZM12.1614 13H16.1614C16.7114 13 17.1614 12.55 17.1614 12C17.1614 11.45 16.7114 11 16.1614 11H12.1614C11.6114 11 11.1614 11.45 11.1614 12C11.1614 12.55 11.6114 13 12.1614 13ZM12.1614 17H16.1614C16.7114 17 17.1614 16.55 17.1614 16C17.1614 15.45 16.7114 15 16.1614 15H12.1614C11.6114 15 11.1614 15.45 11.1614 16C11.1614 16.55 11.6114 17 12.1614 17ZM7.16138 7H9.16138V9H7.16138V7ZM7.16138 11H9.16138V13H7.16138V11ZM7.16138 15H9.16138V17H7.16138V15ZM20.1614 3H4.16138C3.61138 3 3.16138 3.45 3.16138 4V20C3.16138 20.55 3.61138 21 4.16138 21H20.1614C20.7114 21 21.1614 20.55 21.1614 20V4C21.1614 3.45 20.7114 3 20.1614 3ZM19.1614 19H5.16138V5H19.1614V19Z"
      fill={color}
    />
  </svg>
)

export default List
