import { Stat as StatIcon } from '../../../assets/illustrations'
import styles from './Stat.module.css'

/**
 *
 * This element displays a stat's card
 *
 * @usedIn - Statistic views
 *
 * @param text - The name on the top of the card
 * @param value - The value to display in the middle
 * @param extension - The variable to display in the end if necessary
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Stat = ({
  text,
  value,
  extension,
  dataCy,
}: {
  text: string
  value: string
  extension?: string

  dataCy?: string
}): JSX.Element => (
  <div className={styles.container} data-cy={dataCy}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className={styles.text}>{text}</div>{' '}
    </div>
    <div className={styles.value}>{value}</div>
    {extension && <div className={styles.extension}>{extension}</div>}
    <div className={styles.icon}>
      <StatIcon />
    </div>
  </div>
)

export default Stat
