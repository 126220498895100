const Wallet = ({ color }: { color?: string }) => (
  <svg width="100%" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.66138 16.5996V8.59961C9.66138 7.49961 10.5514 6.59961 11.6614 6.59961H20.6614V5.59961C20.6614 4.49961 19.7614 3.59961 18.6614 3.59961H4.66138C3.55138 3.59961 2.66138 4.49961 2.66138 5.59961V19.5996C2.66138 20.6996 3.55138 21.5996 4.66138 21.5996H18.6614C19.7614 21.5996 20.6614 20.6996 20.6614 19.5996V18.5996H11.6614C10.5514 18.5996 9.66138 17.6996 9.66138 16.5996ZM12.6614 8.59961C12.1114 8.59961 11.6614 9.04961 11.6614 9.59961V15.5996C11.6614 16.1496 12.1114 16.5996 12.6614 16.5996H21.6614V8.59961H12.6614ZM15.6614 14.0996C14.8314 14.0996 14.1614 13.4296 14.1614 12.5996C14.1614 11.7696 14.8314 11.0996 15.6614 11.0996C16.4914 11.0996 17.1614 11.7696 17.1614 12.5996C17.1614 13.4296 16.4914 14.0996 15.6614 14.0996Z"
      fill={color}
    />
  </svg>
)

export default Wallet
