import { useDispatch, useSelector } from 'react-redux'

import CustomFieldModal from './modal'
import { updateModal } from '../../../actions/modal'
import { objectType } from '../../../store/types'
import List from '../../../components/list'
import { listCustomFieldCols, listCustomFieldItems, listCustomFieldItemsNextToken } from '../../../store/selector'

const ListCustomFields = () => {
  const cols = useSelector(listCustomFieldCols)
  const items = useSelector(listCustomFieldItems)
  const { nextToken } = useSelector(listCustomFieldItemsNextToken)
  const dispatch = useDispatch()

  const handleCreate = () =>
    updateModal(
      dispatch,
      true,
      () => <CustomFieldModal />,
      () => {}
    )

    return (
    <List
      cols={cols!}
      items={items}
      fullItems={items}
      onCreate={handleCreate}
      nextToken={nextToken}
      type={objectType.CUSTOM_FIELD}
    />
  )
}

export default ListCustomFields
