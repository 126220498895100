import { useState, useEffect } from 'react'

import SearchList, { Entries } from '../searchList'
import { TypeTag } from '../../../types/setting/tag'
import { TypeFormError } from '../../../format/errors/types'
import generateDataCy from '../../../utils/cypress'
import DisplayIcon2 from '../../newComponents/icons'

import { Close as CloseIcon } from '../../../assets/icons'
import stylesInput from '../../newComponents/input/Input.module.css'
import styles from './Tag.module.css'

type State = {
  tags: Array<TypeTag>
  listTags: Array<{ val: string; id: string }>
  showErrorMessage: boolean
}

/**
 *
 * This element displays a list of tags
 *
 * @usedIn - Catalog form views / Settings list views
 *
 * @param tags - The actual tags to display
 * @param handleSave - The CTA triggered when we press "Enter" or select a tag
 * @param handleDelete - The CTA triggered when we press the cross
 * @param disabled - A boolean who enable or disable changements of the list
 * @param error - An array of TypeFormError to display if error.value is true
 * @param dataCy - An unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Tag = ({
  selectedTags,
  entries,
  handleSave,
  handleDelete,
  disabled = false,
  error,
  dataCy,
}: {
  selectedTags: Array<TypeTag>
  entries: Array<Entries>
  handleSave?: (tag: TypeTag) => void
  handleDelete?: (name: string) => void
  disabled?: boolean
  error?: Array<TypeFormError>
  dataCy?: string
}): JSX.Element => {
  const initialState: State = {
    tags: [],
    listTags: [],
    showErrorMessage: false,
  }
  const [state, setState] = useState(initialState)

  /**
   *
   * This useEffect allows us to get the separated list of selectable tags
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  useEffect(() => {
    state.tags = []
    state.listTags = []
    const newTags = [
      ...entries
        .filter((entry) => selectedTags.findIndex((sTag) => entry.id === sTag.id) !== -1)
        .map((entry) => ({ id: entry.id, name: entry.val })),
      ...selectedTags.filter((sTag) => sTag.id === ''),
    ]
    const newEntries = entries.filter((entry) => selectedTags.findIndex((sTag) => entry.id === sTag.id) === -1)
    setState({
      ...state,
      listTags: newEntries,
      tags: newTags,
    })
      }, [entries, selectedTags.length])

  /**
   *
   * This method calls handleSave then add a cross inside the array of showDelete
   *
   * @param value - The value of the tag
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  const handleCreate = (value: string) => {
    if (!selectedTags.find((tag) => tag.name === value)) {
      handleSave && handleSave({ id: '', name: value })
      setState({ ...state })
    } else setState({ ...state })
  }

  /**
   *
   * This method calls handleSave, add a cross inside the array of showDelete
   * then remove the tag from the list of selectable ones
   *
   * @param value - The id of the tag
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  const handleSelect = (id: string) => {
    const index = state.listTags.findIndex((tag) => tag.id === id)
    handleSave && handleSave({ id, name: state.listTags[index].val })
    setState({
      ...state,
      listTags: state.listTags.filter((tag) => tag.id !== id),
    })
  }

  /**
   *
   * This method calls handleDelete and add the deleted tag inside the list of selectable ones
   *
   * @param id - The id of the tag
   * @param indexSent - The index of where this tag is located at inside the array
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  const onDelete = (id: string, indexSent: number): void => {
    if (id !== '') {
      state.listTags = [...state.listTags, { val: state.tags[indexSent].name, id }]
    }
    handleDelete && handleDelete(state.tags.find((_, index) => indexSent === index)!.name)
    setState({ ...state })
  }

  return (
    <div className={styles.container} data-cy={generateDataCy({ scope: dataCy ?? '', value: 'parent' })}>
      <label
        className={stylesInput.label}
        style={{ color: 'var(--sys-color-content-secondary)', transform: 'translateY(-25px)' }}
      >
        Tag
      </label>
      <div className={styles.containerTag}>
        {state.tags.map((tag: TypeTag, index: number) => (
          <span
            data-cy={generateDataCy({ scope: dataCy ?? '', value: index.toString() })}
            key={index}
            className={error ? styles.tagError : styles.tag}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          >
            <div className={disabled ? styles.tagDisabled : styles.tagName}>{tag.name}</div>
            <DisplayIcon2
             dataCy={generateDataCy({ scope: dataCy ?? '', value: index.toString() + "-delete" })}
              onClick={() => onDelete(tag.id, index)}
              Icon={CloseIcon}
              color={
                error
                  ? 'var(--sys-color-content-danger)'
                  : disabled
                    ? 'var(--sys-color-content-disabled)'
                    : 'var(--sys-color-content-interactive)'
              }
            // dataCy={dataCy}
            />
          </span>
        ))}
        {!disabled && (
          // Soon replace with Dropdown - I hope
          <SearchList
            eventID="drawer"
            parentID={'searchListTag'}
            popUpID={'searchListPopUpTag'}
            onSelect={handleSelect}
            onCreate={handleCreate}
            entries={state.listTags}
            allEntries={state.tags}
            dataCy={dataCy}
          />
        )}
      </div>
    </div>
  )
}

export default Tag
