import { useEffect, useState, createRef, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Table from './table'
import {
  TypeColumnConfiguration,
  DashboardView,
  DrawerState,
  DrawerType,
  ListState,
  MenuState,
  objectType,
} from '../../store/types'
import SearchBar from '../newComponents/searchBar'
import { clearAllFilters, filterList, toggleFilters } from '../../actions/requester'
import { drawer, drawerOpen as drawerO, updateDrawerState } from '../../actions/drawer'
import generateDataCy from '../../utils/cypress'
import EmptyList from './emptyList'
import { updateCols } from '../../actions/cols'
import { isDrawerOpen, getDashboardView, getListState, getMenu, selectedCompany, listProductsItems } from '../../store/selector'
import { updateListState, updateList, unSelectAllList, updateAllSelectedItems, fetchListElement, addProductToInventoryMovement } from '../../actions/list'
import { TypeFormError, ValidationFunction } from '../../format/errors/types'
import { pluralize } from '../../utils/typeToType'
import Button from '../newComponents/button'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../newComponents/types'
import { TypeList } from '../../types/list'
import { TypePath, TypeRequester } from '../requester/types'
import { buildQuery } from '../requester/query'
import TopBar from '../newComponents/topBar'
import Path from '../newComponents/topBar/path'
import ExportList from '../oldComponents/export'
import { updateModal } from '../../actions/modal'
import IconButton from '../newComponents/button/iconButton'
import toggleMenu from '../../actions/menu'

import { Add, Download, Menu } from '../../assets/icons'
import styles from './List.module.css'
import Loader from '../newComponents/loader'
import { createInventoryItems, getInventoryMovement } from '../../getters/catalog/inventory'
import { InventoryMovementState } from '../../API'

type State = {
  cols: TypeColumnConfiguration
  items?: Array<any>
  back?: () => void
  editableList?: boolean
  columnSettings: boolean
  requester?: TypeRequester
  searchBar?: TypeRequester
  isScrolled: boolean
  isAllChecked: boolean,
}

/**
 *
 * This element displays a list of usable tools on editable list
 *
 * @usedIn - Editable list
 *
 * @param handleCancel - A CTA when you click on "Cancel" button
 * @param handleSave - A CTA when you click on "Save" button
 * @param addProducts - A CTA when you need to add more products in the editable list
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
export const EditableRightToolBar = ({
  handleCancel,
  handleSave,
  displayEditableActions,
  errors,
  type,
  cols,
}: {
  handleCancel: () => void
  handleSave: () => void
  displayEditableActions: boolean
  errors: Array<{
    rowID: string
    errors: Array<{
      variants?: [
        {
          colID: string
          variantID: string
          error: TypeFormError | void | null
        }
      ]
      colID: string
      error?: TypeFormError | void | null
    } | null>
  }>
  type: objectType,
  cols: TypeColumnConfiguration,
}): JSX.Element => {
  const { t } = useTranslation()

  const getButtonTitle = () => {
    if (type === objectType.INVENTORY_MOVE_PRODUCT && cols.status === InventoryMovementState.CLOSED || cols.status === InventoryMovementState.CANCELLED) {
      return t('button.CLOSE')
    }
    return t('button.CANCEL')

  }

  const getButtonState = () => {
    let status = ComponentState.DEFAULT
    if (errors && errors.length > 0) {
      errors.forEach((error) =>
        error.errors.forEach((err) => {
          if (err?.error) status = ComponentState.DISABLED
          if (err?.variants && err.variants.length > 0) {
            err.variants.forEach((variant) => {
              if (variant.error) status = ComponentState.DISABLED
            })
          }
        })
      )
    }
    return status
  }

  return (
    <div className={styles.headbarRight}>
      <Button
        title={getButtonTitle()}
        onClick={handleCancel}
        size={ComponentSize.MEDIUM}
        type={ComponentType.TERTIARY}
        status={ComponentStatus.DEFAULT}
        state={ComponentState.DEFAULT}
        dataCy="cancel"
      />
      {displayEditableActions && (
        <Button
          title={t('button.SAVE')}
          onClick={handleSave}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={getButtonState()}
          dataCy="submit"
        />
      )}
    </div>
  )
}

/**
 *
 * This element displays a "Add" button where we create a new entity
 *
 * @usedIn - List
 *
 * @param newItems - A CTA when you click on "Cancel" button
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
export const ListRightToolBar = ({
  newItem,
  t,
  mobileView,
}: {
  newItem: () => void
  t: (field: string) => string
  mobileView: boolean
}): JSX.Element => (
  <div className={styles.headbarRight}>
    <Button
      title={mobileView ? '' : t('list.ADD')}
      onClick={newItem}
      size={mobileView ? ComponentSize.MEDIUM : ComponentSize.LARGE}
      type={ComponentType.PRIMARY}
      status={ComponentStatus.DEFAULT}
      state={ComponentState.DEFAULT}
      LeadIcon={Add}
      dataCy={generateDataCy({ scope: 'drawer', value: 'open' })}
    />
  </div>
)

/**
 *
 * This element displays the whole container list
 *
 * @usedIn - List view
 *
 * @param cols - Cols to display
 * @param items - Entity's items to display
 * @param isEmpty - A boolean to display the EmptyList component
 * @param fieldsToFilter - Entity's fields to use when type in the search bar
 * @param selected - An array of boolean when we select entities
 * @param nextToken - String used for the API to display next pages
 * @param type - Entity's type
 * @param Drawer - Component to display when we click on a line
 * @param requesterAllFields - Entity's fields to use when we filter with the requester
 * @param displayNewOpen - A boolean to display a "Add" button (to create a new entity)
 * @param moreList - Elements to display in the actions menu
 * @param searchBarSide - A component to display next to the search bar
 * @param searchBarPlaceholder - Search bar's placeholder
 * @param unSelectById - A method to call when we unselect entity while on editing mode
 * @param unSelectSubById - A method to call when we unselect entity's sub values while on editing mode
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const List = ({
  cols,
  items,
  fullItems,
  nextToken,
  sort,
  editValues,
  errors,
  type,
  Drawer = () => <></>,
  requesterFields,
  searchBarFields,
  onCreate,
  onSearch,
  searchBarSide,
  unSelectById,
  unSelectSubById,
  displayEditableActions = true,
}: TypeList): JSX.Element => {
  const myRef: RefObject<any> = createRef()
  const drawerOpen = useSelector(isDrawerOpen)
  const listState = useSelector(getListState(pluralize(type)))
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const menu = useSelector(getMenu)
  const company = useSelector(selectedCompany)
  const dashboardView = useSelector(getDashboardView)
  const requesterQuery = new URLSearchParams(location.search).get('r')
  const products = useSelector(listProductsItems)
  const initialState: State = {
    cols,
    editableList: false,
    isScrolled: false,
    columnSettings: false,
    isAllChecked: false,
  }
  const [state, setState] = useState(initialState)
  const { t } = useTranslation()
  const getSelectedItems = () => (fullItems ? fullItems.filter((fItem) => fItem.selected) : [])

  /**
   *
   * This method is called when we scroll on the list
   * When we are at 80% of the page, we call the API and filter the list
   *
   * @param event - Event related to the scroll
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const handleScroll = (event: any) => {
    const node = event.target

    if (type === objectType.INVENTORY_MOVE_PRODUCT && nextToken &&
      !state.isScrolled &&
      node.clientHeight + node.scrollTop > node.scrollHeight * 0.8
    ) {
      state.isScrolled = true;
      const urlID = window.location.pathname.split('/').pop()
      if (urlID)
        getInventoryMovement(urlID, dispatch, nextToken).then((data) => {
          if (data.data) {
            const inventoryMovement = data.data
            const elements = createInventoryItems(inventoryMovement.products)
            addProductToInventoryMovement({ element: elements, dispatch, type: objectType.INVENTORY_MOVE_PRODUCT })
          }
        })
    }

    if (
      !cols.editable &&
      nextToken &&
      !state.isScrolled &&
      node.clientHeight + node.scrollTop > node.scrollHeight * 0.8
    ) {
      // setState((state) => ({ ...state, isScrolled: true }))
      state.isScrolled = true;

      let query = {}
      if (state.requester && state.searchBar)
        query = {
          or: [
            buildQuery(state.requester, company.currencyDecimals),
            buildQuery(state.searchBar, company.currencyDecimals),
          ],
        }
      else if (state.requester) query = buildQuery(state.requester, company.currencyDecimals)
      else if (state.searchBar) query = buildQuery(state.searchBar, company.currencyDecimals)

      filterList({
        query,
        isEditable: false,
        selectedItems: getSelectedItems(),
        allSelected: getSelectedItems().length === items?.length,
        // Because we display this + requester data
        items: items!,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      })
    }
  }

  /**
   *
   * This method is called when we use the requester, it calls the method related to each changements
   *
   * @param requester - An object used to request API
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const handleRequesterChange = (requester: TypeRequester) => {
    state.requester = requester

    let query = buildQuery(state.requester, company.currencyDecimals)
    if (state.searchBar) query = { and: [query, buildQuery(state.searchBar, company.currencyDecimals)] }

    filterList({
      query,
      isEditable: cols.editable,
      items: fullItems!,
      selectedItems: getSelectedItems(),
      dispatch,
      type,
      cols,
      sort,
    })
  }

  const initRequester = () => {
    if (requesterQuery) {
      state.requester = JSON.parse(requesterQuery)
      toggleFilters(dispatch, { type, filters: { requesterOpen: true, searchbarOpen: true } })
      navigate(location.pathname)
      handleRequesterChange(state.requester!)
    }
    setState({ ...state, cols })
  }

  /**
   *
   * This useEffect does 4 things :
   * - Set up listener for the scroll
   * - Set up requester when filters are coming from the URL
   * - Update entities if some of them are selected
   * - Update filtred variable to take items
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  useEffect(() => {
    state.isScrolled = false
    // setState((state) => ({ ...state, isScrolled: false }))

    //! DELETE QUAND TOUTES LES LISTES UPDATES
    // updateListState(
    //   { type: pluralize(type), state: items?.length === 0 ? ListState.EMPTY : ListState.LOADED },
    //   dispatch
    // )
    initRequester()
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('popstate', () => {
      /* 
        Executing the same comportement as cancel button when editing an inventory movement
      */
      if (location.pathname.includes('/catalog/editableMovement/')) {
        clearAllFilters(dispatch)
        unSelectAllList(dispatch)
        toggleMenu(dispatch, { state: MenuState.EXPAND })
        updateList({ type: pluralize(type), editValues: {}, errors: [] }, dispatch)
      }
    })
    return () => {
      window.removeEventListener('popstate', () => { })
      window.removeEventListener('scroll', handleScroll)
    }
  }, [items, cols, requesterQuery])



  const setDrawerOpen = (open: boolean) => {
    if (open !== drawerOpen) {
      drawerO(dispatch, { open })
    }
  }

  /**
   *
   * This method is called when we check the "all" checkbox
   * - Change the "selected" value of all entities
   * - Calling the method to open drawer
   *
   * @param value - A boolean to check or uncheck the checkbox
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const toggleAll = (value: boolean) => {
    updateAllSelectedItems(value, type, dispatch);
    let { back } = state
    if (value) {
      drawer(
        {
          type: DrawerType.BULK,
        },
        dispatch
      )
      back = undefined
    }
    setState((state) => ({ ...state, isAllChecked: value, back }))
    setDrawerOpen(value)
  }

  /**
   *
   * This method is called when we check the checkbox on a single line
   * Its goal is to open the drawer and get a bulk actions
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */

  const checkLine = (element: any) => {
    const openDrawer = items.find((fItem) => fItem.selected)
    drawer({ type: DrawerType.BULK }, dispatch)
    setDrawerOpen(openDrawer ? openDrawer : !element.selected)
    setState({
      ...state,
      back: undefined,
    })
  }

  /**
   *
   * This method is called when we click on a single line
   * Its goal is to open the drawer and get entity's form
   *
   * @param val - The entity
   * @param subId - Id related to the entity sub values
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const clickLine = (val: any, subId?: string, dropdown?: boolean) => {
    drawer(
      {
        type: DrawerType.EDIT,
        id: val.id,
        subId,
      },
      dispatch
    )
    if (!drawerOpen || dropdown) {
      updateDrawerState(DrawerState.LOADING, dispatch)
      setDrawerOpen(true)
    }
  }

  /**
   *
   * This method is called when we change order and appearance of columns
   *
   * @param cols - New column configuration
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const filterColumns = (cols: TypeColumnConfiguration) => {
    setState({ ...state, columnSettings: false })
    updateCols(dispatch, cols)
  }

  const toggleColumnSettings = () => {
    setState({ ...state, columnSettings: !state.columnSettings })
  }

  /**
   *
   * This method is called when we click on the "Add" button
   * Its goal is to open the drawer to create a new entity
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const newItem = (title?: string) => {

    console.log(title, "TITLE")

    if (onCreate) onCreate(title)
    else {
      drawer(
        {
          type: DrawerType.NEW,
        },
        dispatch
      )
      updateDrawerState(DrawerState.LOADED, dispatch)
      setDrawerOpen(true)
    }
  }

  /**
   *
   * This method is called when we click the "Save" button inside the editable list
   * It calls the save method related to the columns of each entity
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const handleSave = async () => {
    if (cols.editActions) {
      cols.editActions.save({
        editValues,
        items: items.filter((item) => item.selected),
        navigate,
        dispatch,
        decimals: company.currencyDecimals,
      })
    }
  }

  /**
   *
   * This method is called when we click the "Cancel" button inside the editable list
   * It calls the cancel method related to the columns of each entity
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const handleCancel = () => {
    if (cols.editActions) {
      cols.editActions.cancel({ dispatch, navigate })
    }
  }

  /**
   *
   * This method is called when we edit a cell in the editable list
   * It updates the editValues variable with the related cell id
   *
   * @param cellId - Entity's id
   * @param colName - TypeCol name
   * @param val - Value typed in the cell
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const onEditCell = (cellId: string) => (colName: string) => (val: any) => {
    const colNameValues = editValues[cellId] || {};

    let newColNameValues;
    if (colName === 'subValues') {
      newColNameValues = {
        ...colNameValues,
        [colName]: { ...(colNameValues[colName] || {}), ...val }
      };
    } else {
      newColNameValues = { ...colNameValues, [colName]: val };
    }

    updateList(
      {
        type: pluralize(type),
        editValues: {
          ...editValues,
          [cellId]: newColNameValues,
        },
      },
      dispatch
    )
  }

  const onBlurCell = (rowID: string) => (colName: string) => (variantID?: string) => {
    if (editValues && editValues[rowID] && editValues[rowID][colName] !== undefined) {
      const rowIndex = errors?.findIndex((error) => error.rowID === rowID)

      // @ts-ignore
      const validator: ValidationFunction = cols.cols.find((col) => col.id === colName)?.validator
      const value = editValues[rowID][colName]

      // Already blur
      if (rowIndex !== -1 && errors) {
        // @ts-ignore
        const colIndex = errors[rowIndex].errors.findIndex((error) => error?.colID === colName)

        // Already this cell
        if (colIndex !== -1) {
          // @ts-ignore
          errors[rowIndex].errors[colIndex]!.error = validator ? validator(value) : null
          // Never this cell
        } else {
          // @ts-ignore
          errors[rowIndex].errors = [
            // @ts-ignore
            ...errors[rowIndex].errors,
            {
              colID: colName,
              error: validator ? validator(value) : null,
            },
          ]
        }
        // Never blur
      } else {
        errors = [
          // @ts-ignore
          ...errors,
          {
            rowID: rowID,
            errors: [
              {
                colID: colName,
                error: validator ? validator(value) : null,
              },
            ],
          },
        ]
      }
      updateList(
        {
          type: pluralize(type),
          errors,
        },
        dispatch
      )
    } else if (
      editValues &&
      editValues[rowID] &&
      variantID &&
      editValues[rowID].subValues &&
      editValues[rowID].subValues[variantID] &&
      editValues[rowID].subValues[variantID][colName] !== undefined
    ) {
      const rowIndex = errors?.findIndex((error) => error.rowID === rowID)

      // @ts-ignore
      const validator: ValidationFunction = cols.cols.find((col) => col.id === colName)?.validator
      const value = editValues[rowID].subValues[variantID][colName]

      // Already blur
      if (rowIndex !== -1 && errors) {
        // @ts-ignore
        const colIndex = errors[rowIndex].errors.findIndex((error) => error?.colID === colName)
        // Already this col
        if (colIndex !== -1) {
          // @ts-ignore
          const variantIndex = errors[rowIndex]!.errors[colIndex]!.variants!.findIndex(
            (error: any) => error?.variantID === variantID
          )
          // Already this variant
          if (variantIndex !== -1) {
            // @ts-ignore
            errors[rowIndex].errors[colIndex].variants[variantIndex].error = validator ? validator(value) : null
            // Never this variant
          } else {
            // @ts-ignore
            errors[rowIndex].errors[colIndex].variants = [
              // @ts-ignore
              ...errors[rowIndex].errors[colIndex]?.variants,
              {
                colID: colName,
                variantID,
                error: validator ? validator(value) : null,
              },
            ]
          }
          // Never this col
        } else {
          // @ts-ignore
          errors[rowIndex].errors = [
            // @ts-ignore
            ...errors[rowIndex].errors,
            {
              colID: colName,
              variants: [
                {
                  colID: colName,
                  variantID,
                  error: validator ? validator(value) : null,
                },
              ],
            },
          ]
        }
        // Never blur
      } else {
        errors = [
          // @ts-ignore
          ...errors,
          {
            rowID: rowID,
            errors: [
              {
                colID: colName,
                variants: [
                  {
                    colID: colName,
                    variantID,
                    error: validator ? validator(value) : null,
                  },
                ],
              },
            ],
          },
        ]
      }
      updateList(
        {
          type: pluralize(type),
          errors,
        },
        dispatch
      )
    }
  }

  const handleSearchChange = (val: string) => {
    if (onSearch) onSearch(val)
    else {
      state.searchBar = {
        filterID: 'uuid_1',
        type: TypePath.OPERATOR,
        field: 'or',
        path: [],
        filters: [],
        fields: !val
          ? []
          : searchBarFields!.map((field) => ({
              filterID: field.field,
              type: TypePath.PLAIN,
              field: field.field,
              path: [],
              filters: [],
              comparator: field.filter,
              value: val,
            })),
      }

      let query = buildQuery(state.searchBar, company.currencyDecimals)
      if (state.requester) query = { and: [query, buildQuery(state.requester, company.currencyDecimals)] }

      filterList({
        query,
        isEditable: cols.editable,
        items: fullItems!,
        selectedItems: getSelectedItems(),
        dispatch,
        type,
        cols,
        sort,
      })
    }
  }

  const openModalExportList = (type: string) => {
    updateModal(
      dispatch,
      true,
      () => <ExportList type={type} />,
      () => { },
      "exportList"
    )
  }

  return (
    <div className={styles.page}>
      <TopBar
        type={type}
        leftPart={
          <>
            {cols.shrinkrable && (
              <div style={{ marginRight: 'var(--sys-size-2)' }}>
                <IconButton
                  type={ComponentType.TERTIARY}
                  size={ComponentSize.MEDIUM}
                  Icon={Menu}
                  onClick={() =>
                    toggleMenu(dispatch, {
                      state: menu.state === MenuState.EXPAND ? MenuState.SHRINK : MenuState.EXPAND,
                      tmpSection: undefined,
                    })
                  }
                />
              </div>
            )}
            {dashboardView !== DashboardView.MOBILE && <Path />}
          </>
        }
        middlePart={
          // Temporary
          <>
            {!cols.hideFilters && searchBarFields && (
              <SearchBar
                type={type}
                placeholder={dashboardView === DashboardView.MOBILE ? 'Rechercher' : t(`${cols.searchBarPlaceholder}`)}
                onSubmit={handleSearchChange}
              />
            )}
            {searchBarSide && <div>{searchBarSide}</div>}
          </>
        }
        rightPart={
          <>
            {cols.creatable && (
              <div style={{ marginLeft: 'var(--sys-size-2)' }}>
                <Button
                  title={dashboardView === DashboardView.MOBILE ? '' : t(`${cols.creatablePlaceholder}`)}
                  type={ComponentType.PRIMARY}
                  size={ComponentSize.MEDIUM}
                  onClick={() => newItem(t(`${cols.creatablePlaceholder}`))}
                  LeadIcon={dashboardView === DashboardView.MOBILE ? Add : undefined}
                  status={ComponentStatus.DEFAULT}
                  state={ComponentState.DEFAULT}
                  dataCy={generateDataCy({ scope: 'topbar', value: 'add' })}
                />
              </div>
            )}
            {dashboardView !== DashboardView.MOBILE && cols.exportable && (
              <IconButton
                type={ComponentType.SECONDARY}
                size={ComponentSize.MEDIUM}
                Icon={Download}
                onClick={() => openModalExportList(state.cols.type)}
                dataCy={generateDataCy({ scope: 'topbar', value: 'export' })}
              />
            )}
            {cols.editable && (
              <EditableRightToolBar
                handleCancel={handleCancel}
                handleSave={handleSave}
                displayEditableActions={displayEditableActions}
                errors={errors!}
                type={type}
                cols={cols}
              />
            )}
          </>
        }
        requester={state.requester}
        // Temporary
        requesterFields={!cols.hideFilters ? requesterFields : undefined}
        onChangeRequester={handleRequesterChange}
      />
      {listState === ListState.EMPTY ? (
        <EmptyList type={type} />
      ) : listState === ListState.LOADED ? (
        <CSSTransition in={drawerOpen} timeout={300} classNames="content-transition">
          <div
            id="list"
            className={styles.content}
            style={{
              width: `calc(100vw - ${menu.state === MenuState.EXPAND ? '248px' : menu.state === MenuState.SHRINK ? '4.5em' : '0px'
              })`,
              height: 'calc(100vh - 72px)',
            }}
            onScroll={handleScroll}
            ref={myRef}
            data-cy={generateDataCy({ scope: 'list', value: 'product' + (cols.editable ? '-editable' : '') })}
          >
            <Table
              editValues={editValues}
              onEditCell={onEditCell}
              onBlurCell={onBlurCell}
              cols={state.cols}
              fullItems={fullItems}
              items={items}
              updateState={setState}
              clickLine={clickLine}
              checkLine={checkLine}
              filterColumns={filterColumns}
              toggleColumnSettings={toggleColumnSettings}
              columnSettings={state.columnSettings}
              selected={fullItems?.map((i: { selected: boolean }) => i.selected)}
              toggleAll={toggleAll}
              errors={errors}
              unSelectById={unSelectById}
              unSelectSubById={unSelectSubById}
              type={type}
              requester={state.requester}
              searchBar={state.searchBar}
              displayEditableActions={displayEditableActions}
              localSort={false}
              sort={sort}
            />
          </div>
        </CSSTransition>
      ) : listState === ListState.LOADING ? (
        <Loader />
      ) : (
        <></>
      )}
      <>
        <TransitionGroup component={null}>
          {drawerOpen && (
            <CSSTransition in={drawerOpen} timeout={300} classNames="drawer-transition">
              <div className={styles.drawer}>
                <Drawer
                  selected={fullItems?.filter((item) => item.selected)}
                  close={() => setDrawerOpen(false)}
                  back={state.back}
                />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </>
    </div>
  )
}

export default List
export { Table }
