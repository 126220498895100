import countries from '../../../assets/countries.json'

const Country = (value: any) => (
  <>
    {value.country && countries.find((country) => country.alpha2 === value.country.toLowerCase())
      ? countries.find((country) => country.alpha2 === value.country.toLowerCase())!.name
      : ''}
  </>
)

export default Country
