const EyeHide = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9914 9L15.1614 12.16C15.1614 12.11 15.1614 12.05 15.1614 12C15.1614 11.2044 14.8453 10.4413 14.2827 9.87868C13.7201 9.31607 12.957 9 12.1614 9C12.1014 9 12.0514 9 11.9914 9ZM7.69138 9.8L9.24138 11.35C9.19138 11.56 9.16138 11.77 9.16138 12C9.16138 12.7956 9.47745 13.5587 10.0401 14.1213C10.6027 14.6839 11.3657 15 12.1614 15C12.3814 15 12.6014 14.97 12.8114 14.92L14.3614 16.47C13.6914 16.8 12.9514 17 12.1614 17C10.8353 17 9.56353 16.4732 8.62584 15.5355C7.68816 14.5979 7.16138 13.3261 7.16138 12C7.16138 11.21 7.36138 10.47 7.69138 9.8ZM2.16138 4.27L4.44138 6.55L4.89138 7C3.24138 8.3 1.94138 10 1.16138 12C2.89138 16.39 7.16138 19.5 12.1614 19.5C13.7114 19.5 15.1914 19.2 16.5414 18.66L16.9714 19.08L19.8914 22L21.1614 20.73L3.43138 3L2.16138 4.27ZM12.1614 7C13.4875 7 14.7592 7.52678 15.6969 8.46447C16.6346 9.40215 17.1614 10.6739 17.1614 12C17.1614 12.64 17.0314 13.26 16.8014 13.82L19.7314 16.75C21.2314 15.5 22.4314 13.86 23.1614 12C21.4314 7.61 17.1614 4.5 12.1614 4.5C10.7614 4.5 9.42138 4.75 8.16138 5.2L10.3314 7.35C10.9014 7.13 11.5114 7 12.1614 7Z"
      fill={color}
    />
  </svg>
)

export default EyeHide
