/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://uc3tlcffqnbtfet5fle7rjy3mm.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "OPENID_CONNECT",
    "aws_appsync_apiKey": "da2-ng7lxzmkizfollqgbdgfhn3tta",
    "aws_cloud_logic_custom": [
        {
            "name": "Public",
            "endpoint": "https://7tf3ujy651.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "TacBack",
            "endpoint": "https://rp9i6tl3t2.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "Webhooks",
            "endpoint": "https://hi3iw6ulx9.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
