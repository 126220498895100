// tslint:disable
import {
  productGet,
  discount,
  pack,
  category,
  saleGet,
  cashbookGet,
  customerGet,
  productCustomField,
  categoryCustomField,
  packCustomField,
  productTag,
  shop,
  customField,
  tag,
  tax,
  option,
  paymentGet,
  customerCustomField,
  inventoryMovementGet,
  inventoryQuantities,
  productSubscription,
  variation,
} from './content'

export const onCreateProduct = /* GraphQL */ `
    subscription OnCreateProduct($catalogID: ID!, $test: Boolean!) {
      onCreateProduct(catalogID: $catalogID, test: $test) {
        ${productGet}
      }
    }
  `

export const onUpdateProduct = /* GraphQL */ `
    subscription OnUpdateProduct($catalogID: ID!, $test: Boolean!) {
      onUpdateProduct(catalogID: $catalogID, test: $test) {
        ${productSubscription}
      }
    }
  `

export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct($catalogID: ID!, $test: Boolean!) {
    onDeleteProduct(catalogID: $catalogID, test: $test) {
      ${productGet}
    }
  }
`

export const onUpdateProductVariants = /* GraphQL */ `
  subscription OnUpdateProductVariants($catalogID: ID!, $test: Boolean!) {
    onUpdateProductVariants(catalogID: $catalogID, test: $test) {
      ${variation}
    }
  }
`

export const onCreateDiscount = /* GraphQL */ `
  subscription OnCreateDiscount($catalogID: ID!, $test: Boolean!) {
    onCreateDiscount(catalogID: $catalogID, test: $test) {
      ${discount}
    }
  }
`
export const onUpdateDiscount = /* GraphQL */ `
  subscription OnUpdateDiscount($catalogID: ID!, $test: Boolean!) {
    onUpdateDiscount(catalogID: $catalogID, test: $test) {
      ${discount}
    }
  }
`
export const onDeleteDiscount = /* GraphQL */ `
  subscription OnDeleteDiscount($catalogID: ID!, $test: Boolean!) {
    onDeleteDiscount(catalogID: $catalogID, test: $test) {
      ${discount}
    }
  }
`

export const onCreatePack = /* GraphQL */ `
  subscription OnCreatePack($catalogID: ID!, $test: Boolean!) {
    onCreatePack(catalogID: $catalogID, test: $test) {
      ${pack}
    }
  }
`

export const onUpdatePack = /* GraphQL */ `
  subscription OnUpdatePack($catalogID: ID!, $test: Boolean!) {
    onUpdatePack(catalogID: $catalogID, test: $test) {
      ${pack}
    }
  }
`

export const onDeletePack = /* GraphQL */ `
  subscription OnDeletePack($catalogID: ID!, $test: Boolean!) {
    onDeletePack(catalogID: $catalogID, test: $test) {
      ${pack}
    }
  }
`

export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($catalogID: ID!, $test: Boolean!) {
    onCreateCategory(catalogID: $catalogID, test: $test) {
      ${category}
    }
  }
`

export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($catalogID: ID!, $test: Boolean!) {
    onUpdateCategory(catalogID: $catalogID, test: $test) {
      ${category}
    }
  }
`

export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($catalogID: ID!, $test: Boolean!) {
    onDeleteCategory(catalogID: $catalogID, test: $test) {
      ${category}
    }
  }
`

export const onCreateSale = /* GraphQL */ `
  subscription OnCreateSale($companyID: ID!, $test: Boolean!) {
    onCreateSale(companyID: $companyID, test: $test) {
      ${saleGet}
    }
  }
`

export const onUpdateSale = /* GraphQL */ `
  subscription OnUpdateSale($companyID: ID!, $test: Boolean!) {
    onUpdateSale(companyID: $companyID, test: $test) {
      ${saleGet}
    }
  }
`

export const onDeleteSale = /* GraphQL */ `
  subscription OnDeleteSale($companyID: ID!, $test: Boolean!) {
    onDeleteSale(companyID: $companyID, test: $test) {
      ${saleGet}
    }
  }
`

export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment($companyID: ID!, $test: Boolean!) {
    onCreatePayment(companyID: $companyID, test: $test) {
      ${paymentGet}
    }
  }
`

export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment($companyID: ID!, $test: Boolean!) {
    onUpdatePayment(companyID: $companyID, test: $test) {
      ${paymentGet}
    }
  }
`

export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment($companyID: ID!, $test: Boolean!) {
    onDeletePayment(companyID: $companyID, test: $test) {
      ${paymentGet}
    }
  }
`

export const onCreateCashbook = /* GraphQL */ `
  subscription OnCreateCashbook($companyID: ID!, $test: Boolean!) {
    onCreateCashbook(companyID: $companyID, test: $test) {
      ${cashbookGet}
    }
  }
`

export const onUpdateCashbook = /* GraphQL */ `
  subscription OnUpdateCashbook($companyID: ID!, $test: Boolean!) {
    onUpdateCashbook(companyID: $companyID, test: $test) {
      ${cashbookGet}
    }
  }
`

export const onDeleteCashbook = /* GraphQL */ `
  subscription OnDeleteCashbook($companyID: ID!, $test: Boolean!) {
    onDeleteCashbook(companyID: $companyID, test: $test) {
      ${cashbookGet}
    }
  }
`

export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer($companyID: ID!, $test: Boolean!) {
    onCreateCustomer(companyID: $companyID, test: $test) {
      ${customerGet}
    }
  }
`

export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer($companyID: ID!, $test: Boolean!) {
    onUpdateCustomer(companyID: $companyID, test: $test) {
      ${customerGet}
    }
  }
`

export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer($companyID: ID!, $test: Boolean!) {
    onDeleteCustomer(companyID: $companyID, test: $test) {
      ${customerGet}
    }
  }
`

export const onCreateProductCustomField = /* GraphQL */ `
  subscription OnCreateProductCustomField($catalogID: ID!, $test: Boolean!) {
    onCreateProductCustomField(catalogID: $catalogID, test: $test) {
      ${productCustomField}
    }
  }
`

export const onUpdateProductCustomField = /* GraphQL */ `
  subscription OnUpdateProductCustomField($catalogID: ID!, $test: Boolean!) {
    onUpdateProductCustomField(catalogID: $catalogID, test: $test) {
      ${productCustomField}
    }
  }
`

export const onDeleteProductCustomField = /* GraphQL */ `
  subscription OnDeleteProductCustomField($catalogID: ID!, $test: Boolean!) {
    onDeleteProductCustomField(catalogID: $catalogID, test: $test) {
      ${productCustomField}
    }
  }
`

export const onCreateCategoryCustomField = /* GraphQL */ `
  subscription OnCreateCategoryCustomField($catalogID: ID!, $test: Boolean!) {
    onCreateCategoryCustomField(catalogID: $catalogID, test: $test) {
      ${categoryCustomField}
    }
  }
`

export const onUpdateCategoryCustomField = /* GraphQL */ `
  subscription OnUpdateCategoryCustomField($catalogID: ID!, $test: Boolean!) {
    onUpdateCategoryCustomField(catalogID: $catalogID, test: $test) {
      ${categoryCustomField}
    }
  }
`

export const onDeleteCategoryCustomField = /* GraphQL */ `
  subscription OnDeleteCategoryCustomField($catalogID: ID!, $test: Boolean!) {
    onDeleteCategoryCustomField(catalogID: $catalogID, test: $test) {
      ${categoryCustomField}
    }
  }
`

export const onCreatePackCustomField = /* GraphQL */ `
  subscription OnCreatePackCustomField($catalogID: ID!, $test: Boolean!) {
    onCreatePackCustomField(catalogID: $catalogID, test: $test) {
      ${packCustomField}
    }
  }
`

export const onUpdatePackCustomField = /* GraphQL */ `
  subscription OnUpdatePackCustomField($catalogID: ID!, $test: Boolean!) {
    onUpdatePackCustomField(catalogID: $catalogID, test: $test) {
      ${packCustomField}
    }
  }
`

export const onDeletePackCustomField = /* GraphQL */ `
  subscription OnDeletePackCustomField($catalogID: ID!, $test: Boolean!) {
    onDeletePackCustomField(catalogID: $catalogID, test: $test) {
      ${packCustomField}
    }
  }
`

export const onCreateCustomerCustomField = /* GraphQL */ `
  subscription OnCreateCustomerCustomField($companyID: ID!, $test: Boolean!) {
    onCreateCustomerCustomField(companyID: $companyID, test: $test) {
      ${customerCustomField}
    }
  }
`

export const onUpdateCustomerCustomField = /* GraphQL */ `
  subscription OnUpdateCustomerCustomField($companyID: ID!, $test: Boolean!) {
    onUpdateCustomerCustomField(companyID: $companyID, test: $test) {
      ${customerCustomField}
    }
  }
`

export const onDeleteCustomerCustomField = /* GraphQL */ `
  subscription OnDeleteCustomerCustomField($companyID: ID!, $test: Boolean!) {
    onDeleteCustomerCustomField(companyID: $companyID, test: $test) {
      ${customerCustomField}
    }
  }
`

export const onCreateProductTag = /* GraphQL */ `
  subscription OnCreateProductTag($catalogID: ID!, $test: Boolean!) {
    onCreateProductTag(catalogID: $catalogID, test: $test) {
      ${productTag}
    }
  }
`

export const onDeleteProductTag = /* GraphQL */ `
  subscription OnDeleteProductTag($catalogID: ID!, $test: Boolean!) {
    onDeleteProductTag(catalogID: $catalogID, test: $test) {
      ${productTag}
    }
  }
`

export const onUpdateProductInventoryQuantity = /* GraphQL */ `
  subscription OnUpdateProductInventoryQuantity($shopID: ID!, $test: Boolean!) {
    onUpdateProductInventoryQuantity(shopID: $shopID, test: $test) {
      ${inventoryQuantities}
      shop { ${shop} }
    }
  }
`

export const onCreateOriginInventoryMovement = /* GraphQL */ `
  subscription OnCreateOriginInventoryMovement($originID: ID!, $test: Boolean!) {
    onCreateOriginInventoryMovement(originID: $originID, test: $test) {
      ${inventoryMovementGet}
    }
  }
`

export const onUpdateOriginInventoryMovement = /* GraphQL */ `
  subscription OnUpdateOriginInventoryMovement($originID: ID!, $test: Boolean!) {
    onUpdateOriginInventoryMovement(originID: $originID, test: $test) {
      ${inventoryMovementGet}
    }
  }
`

export const onCreateDestinationInventoryMovement = /* GraphQL */ `
  subscription OnCreateDestinationInventoryMovement($destinationID: ID!, $test: Boolean!) {
    onCreateDestinationInventoryMovement(destinationID: $destinationID, test: $test) {
      ${inventoryMovementGet}
    }
  }
`

export const onUpdateDestinationInventoryMovement = /* GraphQL */ `
  subscription OnUpdateDestinationInventoryMovement($destinationID: ID!, $test: Boolean!) {
    onUpdateDestinationInventoryMovement(destinationID: $destinationID, test: $test) {
      ${inventoryMovementGet}
    }
  }
`

export const onCancelOriginInventoryMovement = /* GraphQL */ `
  subscription OnCancelOriginInventoryMovement($originID: ID!, $test: Boolean!) {
    onCancelOriginInventoryMovement(originID: $originID, test: $test) {
      ${inventoryMovementGet}
    }
  }
`

export const onCancelDestinationInventoryMovement = /* GraphQL */ `
  subscription OnCancelDestinationInventoryMovement($destinationID: ID!, $test: Boolean!) {
    onCancelDestinationInventoryMovement(destinationID: $destinationID, test: $test) {
      ${inventoryMovementGet}
    }
  }
`

export const onCreateCustomField = /* GraphQL */ `
  subscription OnCreateCustomField($catalogID: ID!, $test: Boolean!) {
    onCreateCustomField(catalogID: $catalogID, test: $test) {
      ${customField}
    }
  }
`

export const onUpdateCustomField = /* GraphQL */ `
  subscription OnUpdateCustomField($catalogID: ID!, $test: Boolean!) {
    onUpdateCustomField(catalogID: $catalogID, test: $test) {
      ${customField}
    }
  }
`

export const onDeleteCustomField = /* GraphQL */ `
  subscription OnDeleteCustomField($catalogID: ID!, $test: Boolean!) {
    onDeleteCustomField(catalogID: $catalogID, test: $test) {
      ${customField}
    }
  }
`

export const onCreateShop = /* GraphQL */ `
  subscription OnCreateShop($companyID: ID!) {
    onCreateShop(companyID: $companyID) {
      ${shop}
    }
  }
`

export const onUpdateShop = /* GraphQL */ `
  subscription OnUpdateShop($companyID: ID!) {
    onUpdateShop(companyID: $companyID) {
      ${shop}
    }
  }
`

export const onDeleteShop = /* GraphQL */ `
  subscription OnDeleteShop($companyID: ID!) {
    onDeleteShop(companyID: $companyID) {
      shop {
        ${shop}
      }
    }
  }
`

export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag($catalogID: ID!, $test: Boolean!) {
    onCreateTag(catalogID: $catalogID, test: $test) {
      ${tag}
    }
  }
`

export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag($catalogID: ID!, $test: Boolean!) {
    onUpdateTag(catalogID: $catalogID, test: $test) {
      ${tag}
    }
  }
`

export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag($catalogID: ID!, $test: Boolean!) {
    onDeleteTag(catalogID: $catalogID, test: $test) {
      ${tag}
    }
  }
`

export const onCreateTax = /* GraphQL */ `
  subscription OnCreateTax($catalogID: ID!, $test: Boolean!) {
    onCreateTax(catalogID: $catalogID, test: $test) {
      ${tax}
    }
  }
`

export const onUpdateTax = /* GraphQL */ `
  subscription OnUpdateTax($catalogID: ID!, $test: Boolean!) {
    onUpdateTax(catalogID: $catalogID, test: $test) {
      ${tax}
    }
  }
`

export const onDeleteTax = /* GraphQL */ `
  subscription OnDeleteTax($catalogID: ID!, $test: Boolean!) {
    onDeleteTax(catalogID: $catalogID, test: $test) {
      ${tax}
    }
  }
`

export const onCreateOption = /* GraphQL */ `
  subscription OnCreateOption($catalogID: ID!, $test: Boolean!) {
    onCreateOption(catalogID: $catalogID, test: $test) {
      ${option}
    }
  }
`

export const onUpdateOption = /* GraphQL */ `
  subscription OnUpdateOption($catalogID: ID!, $test: Boolean!) {
    onUpdateOption(catalogID: $catalogID, test: $test) {
      ${option}
    }
  }
`

export const onDeleteOption = /* GraphQL */ `
  subscription OnDeleteOption($catalogID: ID!, $test: Boolean!) {
    onDeleteOption(catalogID: $catalogID, test: $test) {
      ${option}
    }
  }
`
