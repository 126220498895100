/* eslint-disable react/display-name */
import { objectType, DrawerType, MenuState, TypeColumnConfiguration } from '../types'
import { changeConf } from '../../actions/cols'
import { updateModal } from '../../actions/modal'
import { drawer } from '../../actions/drawer'
import {
  TextEditCell,
  NumberEditCell,
  DisabledCell,
  CurrencyEditCell,
  SearchListCell,
  DropDownCell,
} from '../../components/list/cell'
import { getAlertIcon } from '../../components/newComponents/icon'
import { TypeFormError, ValidationFunction } from '../../format/errors/types'
import { convertPrice, displayNumber, displayPercentage, toCurrencyNumber } from '../../utils/number'
import toggleMenu from '../../actions/menu'
import Input from '../../components/newComponents/input'
import ValidationModal from '../../components/newComponents/modal/list/productList'
import ListItemVariant from '../../components/newComponents/listItem/variant'
import { clearAllFilters } from '../../actions/requester'
import {
  buyPrice as buyPriceValidator,
  sellPrice as sellPriceValidator,
} from '../../format/errors/filters/editableList'
import { updateList } from '../../actions/list'
import { pluralize } from '../../utils/typeToType'

import { KeyboardArrowDown } from '../../assets/icons'
import { Category } from '../../API'
import { isValidDate } from '../../utils/date'
import LoadMore from '../../components/newComponents/listItem/loadMore';

/**
 *
 * This method generates whatever field column
 * - Displaying editValues if the user edited the cell
 * - Displaying the value inside parent object by default
 * Depending of field's type we display text input, number input, currency input..
 *
 * We can not edit parent object
 *
 * @usedIn - Editable list
 *
 * @param field - Entity' field
 * @param type - Entity's type
 * @param value - Entity object
 * @param onChange - A CTA when we edit the input
 * @param editValues - A variable with all edited values
 * @param validator - A pre validation API method
 * @param error - A TypeFormError to display if error.value is true
 *
 * @returns - A JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const FieldInput =
  (field: string, type: string) =>
    ({
      value,
      onChange,
      onBlur,
      onFocusNext,
      editValues,
      validator,
      error,
      currency,
      decimals,
    }: {
      value: any
      onChange: (val: any) => void
      onBlur: () => void
      onFocusNext: () => void
      editValues?: any
      validator: ValidationFunction
      error: TypeFormError
      currency: string
      decimals: number
    }): JSX.Element => {
      const valueToDisplay = () => {
        if ((field === 'reference' || field === 'barcode') && typeof value === 'string') {
          if (isValidDate(value)) {
            return null;
          }
        }
        if (!value) {
          return ''
        }
        if (!value.id) {
          return value
        }
        if (editValues) {
          if (value.productID && value.variantID) {
            if (editValues.subValues && editValues.subValues[value.id] && editValues.subValues[value.id][field]) {
              return editValues.subValues[value.id][field]
            }
          } else if (editValues[field] !== undefined) {
            return editValues[field]
          }
        }
        if (type === 'currency') return toCurrencyNumber(value[field], decimals)
        else return value[field]
      }

      const param = { onChange, onBlur, onFocusNext, error, icons: getAlertIcon(error), validator }

      if (value?.variantsCount && type !== 'currency' && field !== 'barcode' && field !== 'reference') {
        return <DisabledCell value={value[field]} {...param} />
      }

      switch (type) {
        case 'number':
          return <NumberEditCell value={valueToDisplay()} {...param} />
        case 'currency':
          return (
            <CurrencyEditCell
              value={valueToDisplay()}
              currency={currency}
              decimals={decimals}
              disabled={value?.variantsCount}
              {...param}
            />
          )
        default:
          return <TextEditCell value={valueToDisplay()} {...param} />
      }
    }

/**
 *
 * This method generates tax column
 * - Displaying editValues if the user edited the cell
 * - Displaying the value inside parent object by default
 *
 * We can edit parent object
 *
 * @usedIn - Editable list
 *
 * @param value - Entity object
 * @param onChange - A CTA when we edit the input
 * @param editValues - A variable with all edited values
 *
 * @returns - A JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const TaxInput = ({
  index,
  value,
  onChange,
  editValues,
  error,
  validator,
  taxes,
}: {
  index: number
  value: any
  onChange: (val: any) => void
  editValues?: any
  error: TypeFormError
  validator: ValidationFunction
  taxes: any
}): JSX.Element => {
  const field = 'taxRate'

  const valueToDisplay = () => {
    if (editValues) {
      if (value.productID && value.variantID) {
        if (editValues.subValues && editValues.subValues[value.id] && editValues.subValues[value.id][field]) {
          return editValues.subValues[value.id][field]
        }
      } else if (editValues[field]) {
        return editValues[field]
      }
    }
    if (!value.taxID) {
      return value
    }
    return value.taxID
  }
  if (value?.variantsCount) {
    return <DisabledCell value={''} error={error} validator={validator} icons={getAlertIcon(error)} />
  }

  const translateValue = () =>
    displayPercentage(taxes.items.items.find((tax: any) => tax.id === valueToDisplay())?.rate)

  return (
    <DropDownCell
      fieldID={field}
      index={index}
      Element={
        <Input
          value={translateValue()}
          style={{
            trailIcons: [{ icon: KeyboardArrowDown }],
            readOnly: true,
          }}
          dataCy={'searchCell'}
        />
      }
      items={taxes.items.items.map((tax: any) => ({
        id: tax.id,
        value: displayPercentage(tax.rate.toString()),
        onClick: onChange,
      }))}
    />
  )
}

/**
 *
 * This method generates category column
 * - Displaying editValues if the user edited the cell
 * - Displaying the value inside parent object by default
 *
 * We can not edit child object
 *
 * @usedIn - Editable list
 *
 * @param value - Entity object
 * @param onChange - A CTA when we edit the input
 * @param editValues - A variable with all edited values
 *
 * @returns - A JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const CategoryInput = ({
  index,
  value,
  onChange,
  editValues,
  error,
  validator,
  categories,
}: {
  index: number
  value: any
  onChange: (val: any) => void
  editValues?: any
  error: TypeFormError
  validator: ValidationFunction
  categories: any
}): JSX.Element => {
  const field = 'categoryName'

  const valueToDisplay = () => {
    if (editValues && editValues[field]) {
      return editValues[field]
    }
    if (!value.categoryID) {
      return value
    }
    return value.categoryID
  }

  if (!value || value.variantID) {
    return <DisabledCell value={''} error={error} icons={getAlertIcon(error)} validator={validator} />
  }

  const getEntries = () => {
    const entries = categories.items.items.map((cat: any) => ({
      id: cat.id,
      value: cat.name,
    }));

    if (!value.categoryID) {
      return entries;
    }

    const categoryLoaded = entries.find((e: Category) => e.id === value.categoryID);

    //@ts-ignore
    // async function fetchAllCategories(accumulatedData = [], nextToken: string) {
    //   const data = await getPromise(listCategoriesVeryLight, {
    //     catalogID: getCompanyID(),
    //     test: getTestMode(),
    //     nextToken: nextToken ? nextToken : null,
    //   });

    //@ts-ignore
    //   accumulatedData.push(...data.data.requestCategories.items);

    //   if (data.data.requestCategories.nextToken) {
    //     return await fetchAllCategories(accumulatedData, data.data.requestCategories.nextToken);
    //   } else {
    //     return accumulatedData;
    //   }
    // }

    if (!categoryLoaded) {
      const entriesUpdated = [...entries, { id: value && value.categoryID, value: value && value.category.name }]
      return entriesUpdated;
      // fetchAllCategories([], categories.items.nextToken).then((finalData: any) => {
      //   const entriesUpdated = [...entries, ...finalData]
      //   console.log(entriesUpdated, "FINAL ALL UPDATED")
      //   return entriesUpdated;
      // }).catch((error: any) => {
      //   console.error('Error fetching categories:', error);
      // });
    }
    return entries;

  }

  return (
    <SearchListCell
      fieldID={field}
      index={index}
      value={valueToDisplay()}
      //@ts-ignore
      entries={getEntries()}
      onChange={onChange}
    />
  )
}

const PRODUCT = 'product'

const editProductCols: TypeColumnConfiguration = {
  id: 'editProducts',
  type: PRODUCT,
  cols: [
    {
      id: 'name',
      title: 'NAME',
      type: 'element',
      active: true,
      function: ({ value, onClick, onIconClick }: { value: any; onClick: () => void, onIconClick: () => void }) =>
        value.variantsCount > 0
          ? ListItemVariant({
            name: value.name,
            length:
              value.subValues.length ===
                value.subValues.filter((subValue: { selected: boolean }) => subValue.selected).length
                ? value.variantsCount
                : value.subValues.filter((subValue: { selected: boolean }) => subValue.selected).length,
            onClick,
            onIconClick
          })
          : value.name,
    },
    {
      id: 'icon',
      title: '',
      type: 'element',
      fieldType: 'string',
      active: true,
      sortable: false,
      notCustomable: true,
      function: ({ value, onClick }: any) => (value.variantsCount > 0 ? LoadMore({ onClick }) : undefined),
    },
    {
      id: 'reference',
      title: 'REFERENCE',
      type: 'element',
      active: true,
      editable: true,
      function: FieldInput('reference', 'text'),
    },
    {
      id: 'sellPrice',
      title: 'SELL_PRICE',
      type: 'element',
      active: true,
      editable: true,
      function: FieldInput('sellPrice', 'currency'),
      validator: sellPriceValidator,
    },
    {
      id: 'buyPrice',
      title: 'BUY_PRICE',
      type: 'element',
      active: true,
      editable: true,
      function: FieldInput('buyPrice', 'currency'),
      validator: buyPriceValidator,
    },
    {
      id: 'barcode',
      title: 'BARCODE',
      type: 'element',
      active: true,
      editable: true,
      function: FieldInput('barcode', 'string'),
    },
    {
      id: 'categoryName',
      title: 'CATEGORY',
      type: 'element',
      active: true,
      editable: true,
      function: CategoryInput,
    },
    {
      id: 'taxRate',
      title: 'TAX',
      type: 'element',
      active: true,
      editable: true,
      function: TaxInput,
    },
  ],
  creatable: false,
  editable: true,
  exportable: false,
  shrinkrable: false,
  checkboxes: false,
  customable: false,
  clickable: false,
  displaySelectedOnly: true,
  searchBarPlaceholder: 'searchBar.placeholder.NAME_REFERENCE',
  editActions: {
    cancel: ({ dispatch }: { dispatch: any }) => {
      changeConf(dispatch, objectType.PRODUCT, 'requestProducts')
      clearAllFilters(dispatch)
      updateList({ type: pluralize(objectType.PRODUCT), editValues: {}, errors: [] }, dispatch)
      toggleMenu(dispatch, { state: MenuState.EXPAND })
      drawer(
        {
          type: DrawerType.BULK,
          open: true,
        },
        dispatch
      )
    },
    save: ({ editValues, dispatch, decimals }: { editValues: any; dispatch: any; decimals: number }) => {
      updateModal(
        dispatch,
        true,
        () =>
          ValidationModal({
            editValues,
            dispatch,
            decimals,
          }),
        () => { }
      )
    },
  },
}

export default editProductCols
