import { TypeActions } from '../../../types/drawer'
import UniqueDropDown from '../../newComponents/dropDown/unique'
import generateDataCy from '../../../utils/cypress'
import IconTouch from '../../newComponents/icons/iconTouch'
import DisplayIcon2 from '../../newComponents/icons'

import { ArrowBack, Close, MoreHoriz } from '../../../assets/icons'
import styles from './Drawer.module.css'

const DrawerTop = ({
  name,
  mobileView,
  burgerMenu,
  close,
  history,
  dataCy,
  icon
}: {
  name: string
  mobileView: boolean
  burgerMenu?: {
    actions: TypeActions
    onClick: (action: string) => void
  }
  close: () => void
  history?: any
  dataCy: string
  icon?: string
}) => {
  return (
    <div
      className={styles.header}
      style={{
        width: mobileView ? '100vw' : '400px',
      }}
      data-cy={generateDataCy({ scope: dataCy, value: 'header' })}
    >
      <div className={styles.headerSideButton}>
        <DisplayIcon2
          onClick={close}
          Icon={history && history.length > 0 ? ArrowBack : Close}
          color="var(--sys-color-content-interactive)"
          dataCy={generateDataCy({ scope: dataCy, value: 'back' })}
        />
      </div>
      <div className={styles.headerTitle} data-cy={generateDataCy({ scope: dataCy, value: 'title' })}>
        {name}
      </div>
      <div className={styles.headerSideButton}>
        {burgerMenu ? (
          <UniqueDropDown
            parentID="drawerTop"
            popUpID="drawerTopPopUp"
            Element={<IconTouch Icon={MoreHoriz} color={'var(--sys-color-content-secondary)'} />}
            items={burgerMenu.actions.map((a) => ({ ...a, onClick: burgerMenu.onClick }))}
            dataCy={generateDataCy({ scope: dataCy, value: 'action' })}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default DrawerTop
