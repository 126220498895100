import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { updateDrawerState } from '../../../actions/drawer'
import Input from '../../../components/newComponents/input'
import {
  ComponentSize,
  ComponentState,
  ComponentStatus,
  ComponentStyle,
  ComponentType,
} from '../../../components/newComponents/types'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { handleBlur, handleChange } from '../../../format/form'
import { getSelectedUser } from '../../../store/selector'
import { DrawerState } from '../../../store/types'
import { TypeUserAccount } from '../../../types/account/userAccount'
import generateDataCy from '../../../utils/cypress'
import { displayConcatIconText } from '../../../utils/string'
import { updateModal } from '../../../actions/modal'
import Template from '../template'
import {
  firstName as firstNameValidator,
  lastName as lastNameValidator,
} from '../../../format/errors/filters/userAccount'
import callUpdateUserAccount from './service'
import { ColorPalette } from '../../../API'
import UserAccountModal from './modal'
import Button from '../../../components/newComponents/button'
import ColorPicker from '../../../components/newComponents/colorPicker'
import { enumToStr } from '../../../utils/color'
import Avatar from '../../../components/newComponents/avatar'

import styles from '../Template.module.css'

const UserAccount = () => {
  const initialState: TypeUserAccount = {
    selected: 'color',
    firstName: '',
    lastName: '',
    email: '',
    color: ColorPalette.BLUE,
    showSubmit: false,
    validators: {
      firstName: firstNameValidator,
      lastName: lastNameValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const user = useSelector(getSelectedUser)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (user)
      setState({
        ...state,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        color: user.color,
      })
      }, [user])

  const cyContext = generateDataCy({ scope: 'account', value: 'userAccount' })

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    } else {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
      setState({ ...state, showSubmit: false })
    }
  }

  const handleSubmit = () => {
    state.showSubmit = false
    setState(handleBlur(state, 'firstName'))
    setState(handleBlur(state, 'lastName'))

    if (!findErrorsInState(state.errors)) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      callUpdateUserAccount(state, dispatch).then(handleResult)
    }
  }

  return (
    <Template>
      <div className={styles.containerForm}>
        <div className={styles.form}>
          <div className={styles.title}>{t('account.userAccount.PERSONAL_INFO')}</div>
          <div className={styles.avatar}>
            <ColorPicker
              title={t('button.EDIT')}
              Element={
                <Avatar
                  size={ComponentSize.LARGE}
                  color={state.color ? enumToStr(state.color) : state.color}
                  iconText={displayConcatIconText(state.firstName, state.lastName)}
                />
              }
              selected={state.selected}
              color={state.color}
              iconText={displayConcatIconText(state.firstName, state.lastName)}
              onSelect={(event) => setState(handleChange(state, 'color')(event))}
              dataCy={generateDataCy({ scope: cyContext, value: 'color' })}
              style={ComponentStyle.CIRCLE}
              isIconText={false}
              isImage={false}
            />
          </div>
          <Input
            label={t('account.userAccount.FIRST_NAME')}
            value={state.firstName}
            onChange={(event) => setState(handleChange(state, 'firstName')(event))}
            style={{
              status: state.errors.firstName?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={state.errors?.firstName?.value ? state.errors.firstName.message : undefined}
            dataCy={generateDataCy({ scope: cyContext, value: 'firstName' })}
          />
          <Input
            label={t('account.userAccount.LAST_NAME')}
            value={state.lastName}
            onChange={(event) => setState(handleChange(state, 'lastName')(event))}
            style={{
              status: state.errors.lastName?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={state.errors?.lastName?.value ? state.errors.lastName.message : undefined}
            dataCy={generateDataCy({ scope: cyContext, value: 'lastName' })}
          />
          <Button
            title={t('button.SAVE')}
            size={ComponentSize.MEDIUM}
            type={ComponentType.PRIMARY}
            state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
            onClick={handleSubmit}
          />
        </div>

        <div className={styles.form}>
          <div className={styles.title}>{t('account.userAccount.CONNECTION_INFO')}</div>
          <div className={styles.email}>{state.email}</div>
          <div
            onClick={() =>
              updateModal(
                dispatch,
                true,
                () => <UserAccountModal email={state.email} />,
                () => {}
              )
            }
          >
            <div className={styles.action}>{t('account.userAccount.EDIT_PASSWORD')}</div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default UserAccount
