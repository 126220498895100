import { createPackCustomField, callCustomFieldsCreation } from '../../../../../services/setting/customField'
import { TypePack } from '../../../../../types'
import { buildTemporaryErrors, createInput, isNestedDirty } from '../../../../../utils/dirties'
import { DataWithErrors } from '../../../../../services/types'
import { getCompanyID } from '../../../../../services/localStorage'
import { callService } from '../../../../../services'
import { createPack } from '../../../../../graphql/custom/mutations'
import { CreatePackInput, ImageModelType, UploadImageInput } from '../../../../../API'
import { convertPrice } from '../../../../../utils/number'
import { uploadImage } from '../../../../../graphql/mutations'
import { removeParamsFromUrl } from '../../../../../utils/string'

const callPackCreation = async (payload: TypePack, decimals: number) => {
  const input: any = createInput(payload, decimals)

  if (input) {
    let res: DataWithErrors = {}
    if (payload.selected === 'color') input.photo = null
    input.selected = undefined;
    input.packVariations = input.packVariations
      ? input.packVariations.map((packOption: any) => ({
          options: packOption!.options.map((option: any) => ({
            productID: option.productID,
            quantity: parseInt(option.quantity, 10),
            additionalPrice: option.price ? convertPrice(decimals, option.price) : 0,
          })),
        }))
      : []
    res = await callService<{ input: CreatePackInput }>(
      { input: { ...input, catalogID: getCompanyID() } },
      createPack,
      'createPack'
    )
    if (res.errors) return res

    const toWait: Array<Promise<any>> = []

    if (payload.selected === 'photo' && isNestedDirty(payload.dirties, 'photo')) {
      if (payload.photo.toString().startsWith('data:'))
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: res.data.id, type: ImageModelType.PACK, base64Image: payload.photo } },
            uploadImage,
            'uploadImage'
          )
        )
      else {
        const newPhoto = removeParamsFromUrl(payload.photo)
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: res.data.id, type: ImageModelType.PACK, urlImage: newPhoto } },
            uploadImage,
            'uploadImage'
          )
        )
      }
    }

    if (isNestedDirty(payload.dirties, 'customFields')) {
      toWait.push(
        callCustomFieldsCreation(payload.customFields, {
          id: { packID: res.data.id },
          create: createPackCustomField,
        })
      )
    }

    res.errors = await buildTemporaryErrors(toWait, res.errors)

    return res
  }
}

export default callPackCreation
