import { Route, Routes } from 'react-router-dom'

import Login from './login'
import SignUp from './signUp'
import ForgotPassword from './forgotPassword'

const ConnectionRoutes = () => (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route path="signUp" element={<SignUp />} />
    <Route path="forgotPassword" element={<ForgotPassword />} />
  </Routes>
)

export default ConnectionRoutes
