const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

const displayFullName = (firstName?: string, lastName?: string) =>
  firstName && lastName ? firstName + ' ' + lastName : firstName || lastName || ''

const parseName = (name: string) => (navigator.language.substring(0, 2) === 'fr' ? name.trim() : name)

const parseAccountName = (name: string) =>
  navigator.language.substring(0, 2) === 'fr' ? name.trim().replace(/\s\s+/g, ' ').replace(/\s/g, '-') : name

const displayConcatIconText = (firstName?: string, lastName?: string) => {
  const parsedFirstName = firstName?.trim().replace(/\s\s+/g, '')
  const parsedLastName = lastName?.trim().replace(/\s\s+/g, '')
  return parsedFirstName && parsedLastName
    ? parsedFirstName.substring(0, 1) + parsedLastName.substring(0, 1)
    : parsedFirstName
    ? parsedFirstName.substring(0, 1)
    : parsedLastName
    ? parsedLastName.substring(0, 1)
    : ''
}

const displayIconText = (value: string) => value.trim().replace(/\s\s+/g, '').substring(0, 4)

const removeParamsFromUrl = (url: string) => {
  // Liste des extensions d'image
  const extensions = 'heic|heif|avif|jpeg|jpg|jpe|tile|dz|png|raw|tiff|tif|webp|gif|jp2|jpx|j2k|j2c|jxl'

  // Expression régulière pour trouver l'extension et supprimer tout après
  const regex = new RegExp(`(?<=\\.(${extensions}))\\?.*`, 'gi')

  // Remplacer les caractères indésirables par une chaîne vide
  return url.replace(regex, '')
}

export {
  capitalizeFirstLetter,
  displayFullName,
  parseName,
  displayConcatIconText,
  displayIconText,
  parseAccountName,
  removeParamsFromUrl,
}
