import { AccountInvitationStatus, RemoveUserInput, UpdateUserRoleInput } from '../../API'
import { objectType } from '../../store/types'
import { callService } from '../../services'
import { updateUserRole as updateUR, removeUser as removeU } from '../../graphql/custom/mutations'
import { deleteListElement, updateListElement } from '../../actions/list'
import { getObservable, getPromise } from '../../services/graphql'
import { getCompanyID, getTestMode } from '../../services/localStorage'
import { onChangeAccountInvitation } from '../../graphql/subscriptions'
import { DataWithErrors } from '../../services/types'
import { getUser as get } from '../../graphql/queries'

const getUser = async (email: string, dispatch: any): Promise<DataWithErrors> => {
  const res = await getPromise(get, { email, companyID: getCompanyID() })
  const errors = res.errors ? res.errors[0] : null
  const user = { data: res.data?.getUser, errors, payload: { email, companyID: getCompanyID() } }

  if (user.data) {
    updateListElement({
      element: user.data,
      dispatch,
      type: objectType.USER,
    })
  }
  return user
}

let onChangeAccountInvitationSubscription: any

const subscribeInvitations = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
    test: getTestMode(),
  }
  if (!onChangeAccountInvitationSubscription)
    onChangeAccountInvitationSubscription = getObservable(onChangeAccountInvitation, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onChangeAccountInvitation) {
          if (eventData.data.onChangeAccountInvitation.deprecated) {
            deleteListElement({
              element: eventData.data.onChangeAccountInvitation,
              dispatch,
              type: objectType.USER,
            })
          } else if (eventData.data.onChangeAccountInvitation.status === AccountInvitationStatus.SENT) {
            updateListElement({
              element: {
                ...eventData.data.onChangeAccountInvitation,
                firstName: '-',
                lastName: '-',
                company: { role: eventData.data.onChangeAccountInvitation.role },
              },
              dispatch,
              type: objectType.USER,
            })
          } else {
            deleteListElement({
              element: eventData.data.onChangeAccountInvitation,
              dispatch,
              type: objectType.USER,
            })
            // getUser(eventData.data.onChangeAccountInvitation.email, dispatch)
          }
        }
      },
    })
}

const unSubscribeInvitations = () => {
  if (onChangeAccountInvitationSubscription) {
    onChangeAccountInvitationSubscription.unsubscribe()
    onChangeAccountInvitationSubscription = null
  }
}

const updateUserRole = async (input: UpdateUserRoleInput, dispatch: any) => {
  const user = await callService<{ input: UpdateUserRoleInput }>({ input }, updateUR, 'updateUserRole')

  if (user.data) {
    updateListElement({
      element: user.data,
      dispatch,
      type: objectType.USER,
    })
  }
  return user
}

const removeUser = async (input: RemoveUserInput, dispatch: any) => {
  const user = await callService<{ input: RemoveUserInput }>({ input }, removeU, 'removeUser')

  if (user.data) {
    deleteListElement({
      element: user.data,
      dispatch,
      type: objectType.USER,
    })
  }
  return user
}

export { getUser, updateUserRole, removeUser, subscribeInvitations, unSubscribeInvitations }
