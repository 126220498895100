const CheckBoxChecked = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1614 3H5.16138C4.06138 3 3.16138 3.9 3.16138 5V19C3.16138 20.1 4.06138 21 5.16138 21H19.1614C20.2614 21 21.1614 20.1 21.1614 19V5C21.1614 3.9 20.2614 3 19.1614 3ZM10.8714 16.29C10.4814 16.68 9.85138 16.68 9.46138 16.29L5.87138 12.7C5.48138 12.31 5.48138 11.68 5.87138 11.29C6.26138 10.9 6.89138 10.9 7.28138 11.29L10.1614 14.17L17.0414 7.29C17.4314 6.9 18.0614 6.9 18.4514 7.29C18.8414 7.68 18.8414 8.31 18.4514 8.7L10.8714 16.29Z"
      fill={color}
    />
  </svg>
)

export default CheckBoxChecked
