import { StateStore, CategoryAssociations } from '../../store/types'
/*
  Used for subscriptions
*/

const updateCategoriesAssociations = (payload: { categoryID: string; dispatch: any }, state: StateStore) => {
  // to avoid a new getProduct graphQL call
  // we just get the updated category data from the subscription result it's already been updated so we get the id
  // we loop through every products item and check the category ID updating only the updated data
  // the thing is we have more info from the category than we have in the category part of the product's item
  // but this will be addressed later on when the getProduct call itself will be optimized
  const categoryDataToUpdate = state.categories.items.items.find((category) => category.id === payload.categoryID)
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map((item) =>
          item.categoryID === payload.categoryID
            ? {
                ...item,
                category: {
                  ...item.category,
                  ...categoryDataToUpdate,
                },
              }
            : item
        ),
      },
    },
  }
}

const getCategoriesAssociations = (items: Array<any>): Array<CategoryAssociations> => {
  const categoryIds = items
    .flatMap((product: { categoryID: string }) => product.categoryID)
    .sort()
    .filter((id: string, index: number, tab) => tab.indexOf(id) === index)
    .map((id: string) => id)

  return categoryIds.map((id: string) => ({
    categoryID: id,
    products: items.filter((product) => product.categoryID === id).map((item) => item.id),
  }))
}

export { updateCategoriesAssociations, getCategoriesAssociations }
