import { ComponentType } from '../types'

import { TactillLogo, TactillSmile } from '../../../assets/illustrations'
import styles from './LogoTactill.module.css'

type Props = { type: ComponentType; dataCy?: string }

/**
 *
 * This element displays Tactill's logo
 *
 * @param type - Logo's type

 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const LogoTactill = ({ type, dataCy }: Props): JSX.Element => (
  <div className={styles.logoTactill} data-cy={dataCy}>
    {type === ComponentType.LOGOICON ? (
      <TactillSmile color={'var(--ref-color-brand-tactill-green)'} />
    ) : type === ComponentType.LOGOTEXT ? (
      <TactillLogo color={'var(--ref-color-brand-tactill-green)'} />
    ) : (
      <div style={{ display: 'flex' }}>
        <TactillSmile color={'var(--ref-color-brand-tactill-green)'} />
        <div style={{ marginLeft: '8px' }}>
          <TactillLogo color={'var(--ref-color-brand-tactill-green)'} />
        </div>
      </div>
    )}
  </div>
)

export default LogoTactill
