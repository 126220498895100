import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { closeAndResetModal } from '../../../actions/modal'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { getSelectedTaxAssociations, listTaxes, selectedCompany } from '../../../store/selector'
import { ComponentErrorText, ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import Input from '../../../components/newComponents/input'
import { callService } from '../../../services'
import { CreateTaxInput } from '../../../API'
import { createTax } from '../../../graphql/custom/mutations'
import { handleBlur, handleChange } from '../../../format/form'
import generateDataCy from '../../../utils/cypress'
import Button from '../../../components/newComponents/button'
import { DataWithErrors } from '../../../services/types'
import TypeTaxState from '../../../types/setting/tax'
import { getTax } from '../../../getters/setting/tax'
import { getCompanyID } from '../../../services/localStorage'
import { convertPrice, displayNumber } from '../../../utils/number'
import ratePercentValidator from '../../../format/errors/filters/setting/tax'

import styles from '../../../components/newComponents/modal/Modal.module.css'

const TaxModal = ({ id }: { id?: string }) => {
  const initialState: TypeTaxState = {
    showSubmit: false,
    errors: {},
    validators: {
      rate: ratePercentValidator,
    },
  }
  const [state, setState] = useState(initialState)
  const association = useSelector(getSelectedTaxAssociations(id))
  const allTaxes = useSelector(listTaxes)
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'settings', value: 'tax' })

  const setTaxdInState = (tax: any) => {
    if (tax) {
      setState({
        ...state,
        rate: displayNumber(tax.rate, company.currencyDecimals),
        productCount: tax.productCount
      })
    }
  }

  useEffect(() => {
    if (id) {
      const tax = allTaxes.items.items.find((tax) => tax.id === id);
      setTaxdInState(tax);
      // getTax(id, dispatch).then(setTaxdInState)
    }
  }, [id])

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
    } else {
      closeAndResetModal(dispatch)
    }
  }

  const isTaxAlreadyExist = (rate: string | number, allTaxes: Array<any>) => {
    const numberRate = Number(rate) * 100;
    return allTaxes.find(t => t.rate === numberRate);
  }

  const handleSubmit = () => {
    if (id) closeAndResetModal(dispatch)
    else {
      setState({ ...handleBlur(state, 'rate'), showSubmit: false })

      if (state.rate) {

      }

      if (!findErrorsInState(state.errors) && state.rate) {
        const isTaxeExisting = isTaxAlreadyExist(state.rate, allTaxes.items.items)
        if (isTaxeExisting) {
          setState({
            ...state,
            errors: {
              ...state.errors,
              global: {
                value: true,
                message: "tax already exist"
              }
            },
            showSubmit: false,
          })
        } else {
          callService<{ input: CreateTaxInput }>(
            {
              input: {
                catalogID: getCompanyID(),
                rate: convertPrice(company.currencyDecimals, state.rate)!,
              },
            },
            createTax,
            'createTax'
          ).then(handleResult)
        }
      }
    }
  }

  const errorText = () => {
    if (state.errors.rate?.value) {
      return state.errors.rate.message
    }

    if (state.errors.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <div
      className={styles.modal}
      data-cy={generateDataCy({
        scope: cyContext,
        value: id ? 'delete' : 'create',
      })}
    >
      <div className={styles.modalTitle}>{id ? t('settings.tax.modal.DELETE') : t('settings.tax.modal.CREATE')}</div>
      {id ? (
        <div className={styles.modalContent}>
          <div
            className={styles.modalImportantInfo}
            data-cy={generateDataCy({ scope: cyContext, value: 'associations' })}
          >
            {t('settings.tax.modal.ASSOCIATION', {
              taxRate: state.rate + ' %',
              taxAssociations: state.productCount,
              pluralAssociation: state.productCount && state.productCount > 0 ? 's' : '',
            })}
          </div>
          <div className={styles.modalInfo}>{t('settings.tax.modal.EDIT')}</div>
        </div>
      ) : (
        <div className={styles.modalContent}>
          <Input
            type={'currency'}
            label={t('settings.tax.modal.RATE')}
            value={state.rate ? state.rate + ' %' : ''}
            suffix={'%'}
            onChange={(event) => setState(handleChange(state, 'rate')(event))}
            onBlur={() => setState(handleBlur(state, 'rate'))}
            onSubmit={handleSubmit}
            style={{
              status: state.errors.rate?.value || state.errors.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={errorText()}
            dataCy={generateDataCy({ scope: cyContext, value: 'rate' })}
          />
        </div>
      )}
      <div className={styles.modalButtons}>
        {!id && (
          <Button
            title={t('button.CANCEL')}
            size={ComponentSize.MEDIUM}
            type={ComponentType.TERTIARY}
            onClick={() => closeAndResetModal(dispatch)}
          />
        )}
        <Button
          title={id ? t('button.UNDERSTOOD') : t('button.SAVE')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit || id ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default TaxModal
