import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { List } from '../../../../components/oldComponents/activity/list'
import TextArea from '../../../../components/newComponents/textArea'
import getAllLines from './config'
import { selectedCompany } from '../../../../store/selector'
import { TypeSale } from '../../../../types'
import { Route } from '../../../../utils/navigation'
import generateDataCy from '../../../../utils/cypress'
import { ComponentSize, ComponentState, ComponentStatus } from '../../../../components/newComponents/types'
import Chip from '../../../../components/newComponents/chip'

import stylesDrawer from '../../../../components/oldComponents/drawer/Drawer.module.css'

type FormState = {
  infos: Array<any>
  lines: Array<any>
  refunds?: Array<any>
  origin?: Array<any>
  paiements?: Array<any>
  totals?: Array<any>
  nbOfProducts: number
}

const Form = ({
  saleState,
  addHistory,
  dataCy,
}: {
  saleState: TypeSale
  addHistory: (route: Route) => void
  dataCy: string
}) => {
  const initialeState: FormState = {
    infos: [],
    lines: [],
    refunds: [],
    paiements: [],
    nbOfProducts: 0,
  }
  const [state, setState] = useState(initialeState)
  const company = useSelector(selectedCompany)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const cyContext = generateDataCy({ scope: 'activity', value: 'sale' })

  useEffect(() => {
    if (saleState.id) {
      const { infos, lines, refunds, origin, paiements, totals } = getAllLines(
        t,
        saleState,
        addHistory,
        dispatch,
        company
      )
      state.nbOfProducts = saleState.lines?.reduce((acc, line) => {
        return (acc += line.quantity)
      }, 0)
      setState({ ...state, infos, lines, refunds, origin, paiements, totals })
    }
      }, [saleState])

  return (
    <div className={stylesDrawer.overflow}>
      <div className={stylesDrawer.form}>
        {saleState.status && (
          // <div className={styles.status} style={{ justifyContent: 'center' }}>
          <Chip
            size={ComponentSize.MEDIUM}
            {...saleState.status}
            dataCy={generateDataCy({ scope: dataCy, value: 'status' })}
          />
          // </div>
        )}
        <List datas={state.infos} dataCy={dataCy} />
        {state.lines.length > 0 && (
          <List title={`${t('activity.sale.PRODUCT')} (${state.nbOfProducts})`} datas={state.lines} dataCy={dataCy} />
        )}
        {state.refunds && state.refunds.length > 0 && (
          <List
            title={`${t('activity.sale.REFUND')} (${state.refunds.length})`}
            datas={state.refunds}
            dataCy={dataCy}
          />
        )}
        {state.origin && state.origin.length > 0 && (
          <List title={t('activity.sale.ORIGIN')} datas={state.origin} dataCy={dataCy} />
        )}
        {state.paiements && state.paiements.length > 0 && (
          <List
            title={`${t('activity.sale.PAYMENT')} (${state.paiements.length})`}
            datas={state.paiements}
            dataCy={dataCy}
          />
        )}
        {state.totals && state.totals.length > 0 && (
          <List title={`${t('activity.sale.TOTAL')} (${state.totals.length})`} datas={state.totals} dataCy={dataCy} />
        )}
        {saleState.note && (
          <TextArea
            label={t('activity.sale.NOTE')}
            value={saleState.note}
            dataCy={generateDataCy({ scope: dataCy, value: 'note' })}
            style={{ status: ComponentStatus.DEFAULT, state: ComponentState.DISABLED }}
          />
        )}
      </div>
    </div>
  )
}

export default Form
