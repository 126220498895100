import {
  Category,
  Tax,
  BulkType,
  ColorPalette,
  ProductVariation,
  ModelProductVariantConnection,
  ModelProductOptionConnection,
  ModelProductTagConnection,
  ModelProductCustomFieldConnection,
  ProductInventoryQuantity,
  ProductStats,
  ProductInventoryStats,
} from '../API';
import { ComponentStatus } from '../components/newComponents/types';
import { TypeFormError, ValidationFunction } from '../format/errors/types';
import { TypeListEditActions } from '../types/list';
import { Navigation } from '../utils/navigation';
import { InventoryMovementState } from '../API';

export enum DashboardView {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
}

export enum AppState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export enum ListState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  EMPTY = 'EMPTY',
  EMPTY_REQUEST = 'EMPTY_REQUEST',
  ERROR = 'ERROR',
}

export enum DrawerState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  SEND_FORM = 'SEND_FORM',
  RESPONSE_FORM = 'RESPONSE_FORM',
  ERROR = 'ERROR',
}

export enum MenuState {
  EXPAND = 'EXPAND',
  SHRINK = 'SHRINK',
  CLOSE = 'CLOSE',
}

export type ListSort = { field: string; direction: string };

export enum objectType {
  INVENTORY_MOVE_PRODUCT = 'inventoryMoveProducts',
  INVENTORY_MOVEMENT_PRODUCT = 'inventoryMovementProduct',
  CUSTOM_FIELD = 'customField',
  INVENTORY_MOVEMENT = 'inventory',
  CATEGORY = 'category',
  DISCOUNT = 'discount',
  COMPANY = 'company',
  SALE = 'sale',
  PAYMENT = 'payment',
  CASHBOOK = 'cashbook',
  CUSTOMER = 'customer',
  PRODUCT = 'product',
  OPTION = 'option',
  SHOP = 'shop',
  SLOT = 'slot',
  USER = 'user',
  PACK = 'pack',
  TAG = 'tag',
  TAX = 'tax',
  IMPORT = 'import',
  INVITATION = 'invitation',
  PAYMENT_METHOD = 'paymentMethod',
  STAT_PAYMENT = 'statPayment',
  STAT_TAX = 'statTax',
  STAT_PRODUCT = 'statProduct',
  STAT_CATEGORY = 'statCategory',
  STAT_VALUE = 'statValue',
  VARIANT = 'variant',
}

export enum InventoryType {
  IN = 'IN',
  OUT = 'OUT',
  TRANSFER = 'TRANSFER',
}

export type ItemsNextToken = {
  type?: objectType;
  items: Array<any>;
  nextToken?: string;
  sort?: ListSort;
};

export type SubValues = {
  id: string;
  productID: string;
  variantID: string;
  createdAt: string;
  name: string;
  buyPrice?: number | null;
  sellPrice?: number | null;
  barcode?: string | null;
  reference?: string | null;
  taxID: string;
  inventoryQuantities: Array<ProductInventoryQuantity | null> | null;
  selected: boolean;
};

export type ProductWitSubValues = {
  __typename: 'Product';
  id: string;
  updatedAt: string;
  createdAt: string;
  deprecated?: boolean | null;
  catalogID: string;
  test?: boolean | null;
  name: string;
  color?: ColorPalette | null;
  iconText?: string | null;
  photo?: string | null;
  categoryID: string;
  category?: Category | null;
  taxID: string;
  tax?: Tax | null;
  bulkType?: BulkType | null;
  buyPrice?: number | null;
  sellPrice?: number | null;
  barcode?: string | null;
  reference?: string | null;
  variations?: Array<ProductVariation | null> | null;
  variantsCount?: number | null;
  variants?: ModelProductVariantConnection | null;
  options?: ModelProductOptionConnection | null;
  subValues: Array<SubValues | null>;
  tags?: ModelProductTagConnection | null;
  customFields?: ModelProductCustomFieldConnection | null;
  inventoryQuantities?: Array<ProductInventoryQuantity | null> | null;
  stats?: ProductStats | null;
  inventoryStats?: ProductInventoryStats | null;
};

export type TypeCol = {
  id: string;
  title: string;
  type: string;
  field?: string;
  fieldType?: string;
  active: boolean;
  function?: (value: any | any[]) => any;
  editable?: boolean;
  sortable?: boolean;
  notCustomable?: boolean;
  validator?: ValidationFunction;
  error?: TypeFormError;
};

/*
  creatable: Display Add button
  editable: Display editable cells
  exportable: Display Export button
  clickable: Display Drawer
  shrinkable: Display Menu button
  customable: Display ColumnSettings
*/
export type TypeColumnConfiguration = {
  id: string;
  type: objectType | string;
  cols: TypeCol[];
  status?: InventoryMovementState;
  creatable: boolean;
  editable: boolean;
  exportable: boolean;
  clickable: boolean;
  shrinkrable: boolean;
  customable: boolean;
  checkboxes: boolean;
  hideFilters?: boolean;
  searchBarPlaceholder?: string;
  creatablePlaceholder?: string;
  displaySelectedOnly?: boolean;
  addProducts?: boolean;
  editActions?: TypeListEditActions;
  noRightToolbar?: boolean;
  customClassNames?: {
    lastLine?: string;
  };
};

export type List = {
  state: ListState;
  cols?: TypeColumnConfiguration;
  items: ItemsNextToken;
  query?: any;
  editValues: any;
  errors?: Array<{
    rowID: string;
    errors: Array<{
      variants?: [
        {
          colID: string;
          variantID: string;
          error: TypeFormError | void | null;
        },
      ];
      colID: string;
      error?: TypeFormError | void | null;
    } | null>;
  }>;
  filteredItems?: ItemsNextToken;
  filters: { requesterOpen: boolean; searchbarOpen: boolean };
};

export type Icon = ({ color }: { color: string }) => JSX.Element;

export enum DrawerType {
  BULK = 'BULK',
  EDIT = 'EDIT',
  NEW = 'NEW',
}

export type Drawer = {
  type: DrawerType;
  id?: string;
  subId?: string;
  open?: boolean;
  navigation: Navigation;
  action?: string;
  param?: {
    view: string;
    to: string;
    from: string;
    motive: string;
  };
};

export type CategoryAssociations = {
  categoryID: string;
  products: Array<string>;
};

export type TaxAssociations = {
  taxID: string;
  products: Array<string>;
};

export type CustomFieldAssociations = {
  customFieldID: string;
  entity: { type: string; ids: Array<string> };
};

export type TagAssociations = {
  tagID: string;
  products: Array<string>;
};

export type OptionAssociations = {
  optionID: string;
  products: Array<string>;
};

export type PackAssociations = {
  productID: string;
  packs: Array<string>;
};

export enum CustomFieldObjectType_VALUE {
  PRODUCT = 'Produits',
  PACK = 'Packs',
  CATEGORY = 'Catégories',
  CASHBOOK = 'Journaux',
  CUSTOMER = 'Clients',
}

export enum CustomFieldValueType_VALUE {
  TEXT = 'Texte',
  NUMBER = 'Nombre',
  DATE = 'Date',
}

export enum IntervaleType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export type Menu = {
  pathWidth?: number;
  state?: MenuState;
  section?: string;
  subSection?: string;
  tmpSection?: string;
};

export type Movement = {
  price: number;
  productID: string;
  quantity: number | null;
  variantID?: string;
};

export const entities = [
  'product',
  'category',
  'discount',
  'option',
  'pack',
  'inventory',
  'sale',
  'payment',
  'cashbook',
  'customer',
];

export const CUSTOM_FIELD = 'customField';
export const INVENTORY_MOVEMENT = 'inventory';
export const CUSTOMER = 'customer';
export const CASHBOOK = 'cashbook';
export const CATEGORY = 'category';
export const DISCOUNT = 'discount';
export const PAYMENT = 'payment';
export const COMPANY = 'company';
export const PRODUCT = 'product';
export const SALE = 'sale';
export const OPTION = 'option';
export const SHOP = 'shop';
export const SLOT = 'slot';
export const USER = 'user';
export const PACK = 'pack';
export const TAG = 'tag';
export const TAX = 'tax';
export const PAYMENT_METHOD = 'paymentMethod';

export enum objectPlural {
  INVENTORY_MOVE_PRODUCT = 'inventoryMoveProducts',
  CUSTOM_FIELD = 'customFields',
  INVENTORY_MOVEMENT = 'inventories',
  CATEGORY = 'categories',
  DISCOUNT = 'discounts',
  COMPANY = 'companies',
  CUSTOMER = 'customers',
  PRODUCT = 'products',
  OPTION = 'options',
  PAYMENT = 'payments',
  CASHBOOK = 'cashbooks',
  SALE = 'sales',
  SHOP = 'shops',
  SLOT = 'slots',
  USER = 'users',
  PACK = 'packs',
  TAG = 'tags',
  TAX = 'taxes',
  IMPORT = 'imports',
  INVITATION = 'invitation',
  PAYMENT_METHOD = 'paymentMethods',
  STAT_PAYMENTS = 'statPayments',
  STAT_TAXES = 'statTaxes',
  STAT_PRODUCTS = 'statProducts',
  STAT_CATEGORIES = 'statCategories',
  STAT_VALUES = 'statValues',
  VARIANT = 'variants',
}

export enum Action {
  TEST_MODE = 'TEST_MODE',
  UPDATE_APP_STATE = 'UPDATE_APP_STATE',
  DISCONNECT = 'DISCONNECT',
  UPDATE_LIST_ELEMENT = 'UPDATE_LIST_ELEMENT',
  UPDATE_VARIANT_ELEMENT = 'UPDATE_VARIANT_ELEMENT',
  DELETE_LIST_ELEMENT = 'DELETE_LIST_ELEMENT',
  SORT_LIST_ELEMENT = 'SORT_LIST_ELEMENT',
  UPDATE_LIST_STATE = 'UPDATE_LIST_STATE',
  UPDATE_LIST = 'UPDATE_LIST',
  FETCH_LIST = 'FETCH_LIST',
  FETCH_LIST_ITEM = 'FETCH_LIST_ITEM',
  ADD_LIST_ITEM = 'ADD_LIST_ITEM',
  FETCH_STAT_ITEM = 'FETCH_STAT_ITEM',
  FETCH_ITEM = 'FETCH_ITEM',
  ADD_COLS = 'ADD_COLS',
  UPDATE_COLS = 'UPDATE_COLS',
  DELETE_COL = 'DELETE_COL',
  CHANGE_COLS_CONF = 'CHANGE_COLS_CONF',
  UPDATE_COLS_CONF = 'UPDATE_COLS_CONF',
  RESET_CUSTOM_COLS = 'RESET_CUSTOM_COLS',
  UPDATE_MODAL = 'UPDATE_MODAL',
  UPDATE_TOASTER = 'UPDATE_TOASTER',
  REQUEST_ITEMS = 'REQUEST_ITEMS',
  REQUEST_FILTERERED_ITEMS = 'REQUEST_FILTERED_ITEMS',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  TOGGLE_FILTERS = 'TOGGLE_FILTERS',
  SWITCH_FILTERED_ITEMS_TO_ITEMS = 'SWITCH_FILTERED_ITEMS_TO_ITEMS',
  FILTER_CUSTOM_FIELD_ITEMS = 'FILTER_CUSTOM_FIELD_ITEMS',
  FILTER_TAG_ITEMS = 'FILTER_TAG_ITEMS',
  UPDATE_CATEGORIES_ASSOCIATIONS = 'UPDATE_CATEGORIES_ASSOCIATIONS',
  UPDATE_TAGS_ASSOCIATIONS = 'UPDATE_TAGS_ASSOCIATIONS',
  UPDATE_TAGS_DATA = 'UPDATE_TAGS_DATA',
  DELETE_TAGS_DATA = 'DELETE_TAGS_DATA',
  DELETE_TAG_FROM_PRODUCT = 'DELETE_TAG_FROM_PRODUCT',
  UPDATE_CUSTOM_FIELDS_ASSOCIATIONS = 'UPDATE_CUSTOM_FIELDS_ASSOCIATIONS',
  UPDATE_OPTIONS_ASSOCIATIONS = 'UPDATE_OPTIONS_ASSOCIATIONS',
  UPDATE_PACKS_ASSOCIATIONS = 'UPDATE_PACKS_ASSOCIATIONS',
  DELETE_OPTION_FROM_PRODUCT = 'DELETE_OPTION_FROM_PRODUCT',
  DRAWER = 'DRAWER',
  TOGGLE_DRAWER = 'TOGGLE_DRAWER',
  TOGGLE_MENU = 'TOGGLE_MENU',
  UPDATE_DRAWER_ACTION = 'UPDATE_DRAWER_ACTION',
  UPDATE_DRAWER_STATE = 'UPDATE_DRAWER_STATE',
  DRAWER_BACK = 'DRAWER_BACK',
  DRAWER_ADD_HISTORY = 'DRAWER_ADD_HISTORY',
  DRAWER_RESET_HISTORY = 'DRAWER_RESET_HISTORY',
  UNSELECT_ALL_LIST = 'UNSELECT_ALL_LIST',
  UNSELECT_PRODUCTS_BY_ID = 'UNSELECT_PRODUCTS_BY_ID',
  UNSELECT_VARIANT_BY_ID = 'UNSELECT_VARIANT_BY_ID',
  UPDATE_ELEMENT_ITEMS = 'UPDATE_ELEMENT_ITEMS',
  UPDATE_ALL_SELECTED_ITEMS = 'UPDATE_ALL_SELECTED_ITEMS',
  UPDATE_PARENT_ITEM_ONLY = 'UPDATE_PARENT_ITEM_ONLY',
  UPDATE_ITEM_ONLY = 'UPDATE_ITEM_ONLY',
  UPDATE_PRODUCT_SUBSCRIPTION_ONLY = 'UPDATE_PRODUCT_SUBSCRIPTION_ONLY',
  UPDATE_SELECTED_SUB_ITEM = 'UPDATE_SELECTED_SUB_ITEM',
  UPDATE_SELECTED_ITEM = 'UPDATE_SELECTED_ITEM',
  UPDATE_VARIANT_AFTER_SUBSCRIPTION = 'UPDATE_VARIANT_AFTER_SUBSCRIPTION',
  ADD_ELEMENT_COMPANY_SETTINGS = 'ADD_ELEMENT_COMPANY_SETTINGS',
  DELETE_ELEMENT_COMPANY_SETTINGS = 'DELETE_ELEMENT_COMPANY_SETTINGS',
  UPDATE_COMPANY = 'UPDATE_COMPANY',
  UPDATE_FLATFILE_INFO = 'UPDATE_FLATFILE_INFO',
  UPDATE_INTERVAL = 'UPDATE_INTERVAL',
  UPDATE_STATS_FILTERS = 'UPDATE_STSTS_FILTERS',
  UPDATE_INVENTORY_QUANTITY = 'UPDATE_INVENTORY_QUANTITY',
  CREATE_CUSTOM_FIELDS_VALUES = 'CREATE_CUSTOM_FIELDS_VALUES',
  UPDATE_CUSTOM_FIELDS_VALUES = 'UPDATE_CUSTOM_FIELDS_VALUES',
  DELETE_CUSTOM_FIELDS_VALUES = 'DELETE_CUSTOM_FIELDS_VALUES',
  ADD_VARIANT_INVENTORY_MOVEMENT = 'ADD_VARIANT_INVENTORY_MOVEMENT',
  ADD_INVENTORY_MOVEMENT = 'ADD_INVENTORY_MOVEMENT',
  ADD_LINES_INVENTORY_MOVEMENT = 'ADD_LINES_INVENTORY_MOVEMENT',
  UPDATE_BUY_PRICE = 'UPDATE_BUY_PRICE',
}

export type StateStore = {
  isIOS: boolean;
  app: { state: AppState };
  flatfile: {
    show: boolean;
    flatfileData: any;
  };
  modal: {
    show: boolean;
    type?: string;
    Element: () => JSX.Element | null;
  };
  toaster: {
    show: boolean;
    param: {
      status?: ComponentStatus;
      text?: string;
      LeadIcon?: any;
      clearButton?: boolean;
    };
  };
  drawerState: DrawerState;
  drawer: Drawer;
  menu: Menu;
  cols: {
    products: TypeColumnConfiguration[];
    categories: TypeColumnConfiguration[];
    discounts: TypeColumnConfiguration[];
    packs: TypeColumnConfiguration[];
    options: TypeColumnConfiguration[];
    inventories: TypeColumnConfiguration[];
    inventoryMoveProducts: TypeColumnConfiguration[];
    sales: TypeColumnConfiguration[];
    payments: TypeColumnConfiguration[];
    cashbooks: TypeColumnConfiguration[];
    customers: TypeColumnConfiguration[];
    companies: TypeColumnConfiguration[];
    shops: TypeColumnConfiguration[];
    slots: TypeColumnConfiguration[];
    users: TypeColumnConfiguration[];
    taxes: TypeColumnConfiguration[];
    customFields: TypeColumnConfiguration[];
    tags: TypeColumnConfiguration[];
    imports: TypeColumnConfiguration[];
    paymentMethods: TypeColumnConfiguration[];
    statPayments: TypeColumnConfiguration[];
    statTaxes: TypeColumnConfiguration[];
    statProducts: TypeColumnConfiguration[];
    statCategories: TypeColumnConfiguration[];
    statValues: TypeColumnConfiguration[];
  };
  variants: List;
  products: List;
  categories: List;
  categoriesAssociations: Array<CategoryAssociations>;
  discounts: List;
  inventories: List;
  inventoryMoveProducts: List;
  packs: List;
  packsAssociations: Array<PackAssociations>;
  options: List;
  optionsAssociations: Array<OptionAssociations>;
  sales: List;
  payments: List;
  cashbooks: List;
  customers: List;
  companies: List;
  shops: List;
  slots: List;
  users: List;
  taxes: List;
  taxesAssociations: Array<TaxAssociations>;
  customFields: List;
  customFieldsAssociations: Array<CustomFieldAssociations>;
  tags: List;
  tagsAssociations: Array<TagAssociations>;
  paymentMethods: List;
  imports: List;
  statPayments: List;
  statTaxes: List;
  statProducts: List;
  statCategories: List;
  statValues: List;
  account: {
    id?: string;
    intercomHMAC?: string;
    firstName?: string;
    lastName?: string;
    color?: ColorPalette;
    email?: string;
    shops?: any;
    companies: {
      items: Array<any>;
      nextoken?: string;
    };
  };
  companySettings: Array<any>;
  openMenu: boolean;
  dashboardView: DashboardView;
  interval: IntervaleType | null;
  stats: {
    shopIDs?: Array<string>;
    dashboard: {
      startDateAPI?: string;
      endDateAPI?: string;
    }
    stockValue: {
      date?: string;
    }
  }
};
