import { displayPercentage } from '../../utils/number'
import { TypeColumnConfiguration } from '../types'
import { CurrencyEditCell as CurrencyCell } from '../../components/list/cell'

const STAT_TAX = 'statTax'

const statTaxesCols: TypeColumnConfiguration[] = [
  {
    id: 'statTaxesCols',
    type: STAT_TAX,
    cols: [
      {
        id: 'rate',
        title: 'RATE',
        type: 'function',
        field: 'rate',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: (rate: number) => displayPercentage(rate),
      },
      {
        id: 'total',
        title: 'TOTAL_BASE',
        field: 'total',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'totalTaxFree',
        title: 'TAX_FREE_BASE',
        field: 'totalTaxFree',
        fieldType: 'number',
        type: 'element',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'totalTax',
        title: 'TOTAL_TAX',
        field: 'totalTax',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
    ],
    creatable: false,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: true,
    customable: false,
    checkboxes: false,
  },
]

export default statTaxesCols
