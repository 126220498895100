const Star = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1612 17.5198L16.3112 20.0298C17.0712 20.4898 18.0012 19.8098 17.8012 18.9498L16.7012 14.2298L20.3712 11.0498C21.0412 10.4698 20.6812 9.36977 19.8012 9.29977L14.9712 8.88977L13.0812 4.42977C12.7412 3.61977 11.5812 3.61977 11.2412 4.42977L9.3512 8.87977L4.52121 9.28977C3.64121 9.35977 3.28121 10.4598 3.95121 11.0398L7.62121 14.2198L6.52121 18.9398C6.32121 19.7998 7.25121 20.4798 8.01121 20.0198L12.1612 17.5198Z"
      fill={color}
    />
  </svg>
)

export default Star
