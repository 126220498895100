const SortDown = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.417 9.66343H9.91033L12.1637 7.4101L14.417 9.66343ZM12.637 6.4701C12.377 6.2101 11.957 6.2101 11.697 6.4701L8.637 9.5301C8.217 9.94343 8.51033 10.6634 9.10367 10.6634H15.217C15.8103 10.6634 16.1103 9.94343 15.6903 9.52343L12.637 6.4701ZM15.2237 13.3301H9.10367C8.51033 13.3301 8.21033 14.0501 8.63033 14.4701L11.6903 17.5301C11.9503 17.7901 12.3703 17.7901 12.6303 17.5301L15.6903 14.4701C16.1103 14.0501 15.817 13.3301 15.2237 13.3301Z"
      fill={color}
    />
  </svg>
)

export default SortDown
