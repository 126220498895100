const CircleCheck = ({ color }: { color?: string }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 8.5C2 5.19531 4.67188 2.5 8 2.5C11.3047 2.5 14 5.19531 14 8.5C14 11.8281 11.3047 14.5 8 14.5C4.67188 14.5 2 11.8281 2 8.5ZM10.6953 7.46875C10.9531 7.21094 10.9531 6.8125 10.6953 6.55469C10.4375 6.29688 10.0391 6.29688 9.78125 6.55469L7.25 9.08594L6.19531 8.05469C5.9375 7.79688 5.53906 7.79688 5.28125 8.05469C5.02344 8.3125 5.02344 8.71094 5.28125 8.96875L6.78125 10.4688C7.03906 10.7266 7.4375 10.7266 7.69531 10.4688L10.6953 7.46875Z"
      fill={color}
    />
  </svg>




)

export default CircleCheck;