const EuroSymbol = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4836 19.5C12.9736 19.5 10.8036 18.08 9.72364 16H14.4836C15.0336 16 15.4836 15.55 15.4836 15C15.4836 14.45 15.0336 14 14.4836 14H9.06364C9.01364 13.67 8.98364 13.34 8.98364 13C8.98364 12.66 9.01364 12.33 9.06364 12H14.4836C15.0336 12 15.4836 11.55 15.4836 11C15.4836 10.45 15.0336 10 14.4836 10H9.72364C10.8036 7.92 12.9836 6.5 15.4836 6.5C16.7336 6.5 17.9036 6.86 18.9036 7.47C19.4036 7.78 20.0536 7.73 20.4736 7.31C21.0536 6.73 20.9236 5.78 20.2236 5.35C18.8436 4.5 17.2136 4 15.4836 4C11.5636 4 8.24364 6.51 7.00364 10H4.48364C3.93364 10 3.48364 10.45 3.48364 11C3.48364 11.55 3.93364 12 4.48364 12H6.54364C6.50364 12.33 6.48364 12.66 6.48364 13C6.48364 13.34 6.50364 13.67 6.54364 14H4.48364C3.93364 14 3.48364 14.45 3.48364 15C3.48364 15.55 3.93364 16 4.48364 16H7.00364C8.24364 19.49 11.5636 22 15.4836 22C17.2236 22 18.8436 21.51 20.2236 20.65C20.9136 20.22 21.0436 19.26 20.4636 18.68C20.0436 18.26 19.3936 18.21 18.8936 18.53C17.9036 19.15 16.7436 19.5 15.4836 19.5Z"
      fill={color}
    />
  </svg>
)

export default EuroSymbol
