import { ColorPalette, DiscountType, SaleLineState } from '../../../../API'
import { MenuCustomer } from '../../../customer/drawer'
import {
  getIconTitleLine,
  generateIconTitleLine,
  getIconTitleInfoActionLine,
  getIconTitleActionLine,
  getLineActionLine,
  getTitleInfoLine,
  getLine,
} from '../../../../components/oldComponents/activity/lines'
import Title from '../../../../components/oldComponents/activity/title'
import { MenuProduct } from '../../../catalog/product/drawer'
import { MenuPack } from '../../../catalog/pack'
import { Route } from '../../../../utils/navigation'
import { displayDateWithHours } from '../../../../utils/date'
import { displayFullName } from '../../../../utils/string'
import { displayPercentage, displayPrice } from '../../../../utils/number'
import { MenuPayment } from '../../payment/drawer'
import { MenuCashbook } from '../../cashbook/drawer'
import { TypeCompanyCurrency } from '../../../../types/currency'
import Product from '../../../../components/oldComponents/activity/productLine'
import { paymentMethodToComponent } from '../../../../utils/typeToType'
import { MenuSale } from '.'
import { TypeSale } from '../../../../types'
import { DrawerState } from '../../../../store/types'
import { updateDrawerState } from '../../../../actions/drawer'
import { ComponentSize, ComponentStatus } from '../../../../components/newComponents/types'
import Chip from '../../../../components/newComponents/chip'
import DisplayIcon2 from '../../../../components/newComponents/icons'
import Sellers from '../../../../assets/icons/sellers'

import { Euro, Shop, Calendar, ArrowForward, Cashbook, Till, People } from '../../../../assets/icons'

const getCashbookLine = (
  t: (value: string) => string,
  addHistory: (route: Route) => void,
  dispatch: any,
  cashbook?: {
    id: string
    number: number
    status: {
      status: ComponentStatus
      text: string
    }
  }
) =>
  cashbook
    ? getIconTitleInfoActionLine(
      'cashbook',
      {
        icon: DisplayIcon2({ Icon: Cashbook, color: 'var(--sys-color-content-secondary)' }),
      },
      <Title topText={t('activity.sale.CASHBOOK')} mainTexts={['#' + cashbook.number]} />,
      <Chip size={ComponentSize.MEDIUM} {...cashbook.status} />,
      {
        icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
        bulkToAction: () => {
          updateDrawerState(DrawerState.LOADING, dispatch)
          addHistory({
            component: MenuCashbook,
            params: { id: cashbook.id },
          })
        },
      }
    )
    : undefined

const getSellerLine = (
  t: (value: string) => string,
  seller?: {
    id: string
    firstName: string
    lastName: string
    color: ColorPalette
    iconText: string
  }
) =>
  seller
    ? getIconTitleLine(
      'seller',
      {
        icon: DisplayIcon2({ Icon: Sellers, color: 'var(--sys-color-content-secondary)' }),
      },
      <Title topText={t('activity.sale.SELLER')} mainTexts={[displayFullName(seller.firstName, seller.lastName)]} />
    )
    : undefined

const getCustomerLine = (
  t: (value: string) => string,
  addHistory: (route: Route) => void,
  dispatch: any,
  customer?: {
    id: string
    firstName: string
    lastName: string
    color: ColorPalette
    iconText: string
  }
) =>
  customer
    ? getIconTitleActionLine(
      'customer',
      {
        icon: DisplayIcon2({ Icon: People, color: 'var(--sys-color-content-secondary)' }),
      },
      <Title
        topText={t('activity.sale.CUSTOMER')}
        mainTexts={[displayFullName(customer.firstName, customer.lastName)]}
      />,
      {
        icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
        bulkToAction: () => {
          updateDrawerState(DrawerState.LOADING, dispatch)
          addHistory({
            component: MenuCustomer,
            params: { id: customer.id },
          })
        },
      }
    )
    : undefined

const mapSaleStateToRefundLine =
  (t: (value: string) => string, addHistory: (route: Route) => void, dispatch: any, company: TypeCompanyCurrency) =>
    (refund: any, index: number) =>
      refund
        ? getIconTitleInfoActionLine(
          'refund-' + index,
          {
            icon: DisplayIcon2({ Icon: Euro, color: 'var(--sys-color-content-secondary)' }),
          },
          <Title mainTexts={[`${t('activity.sale.SALE')} #` + refund.number]} />,
          <Title mainTexts={[displayPrice(refund.total, company.currency, company.currencyDecimals)]} />,
          {
            icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
            bulkToAction: () => {
              updateDrawerState(DrawerState.LOADING, dispatch)
              addHistory({
                component: MenuSale,
                params: { id: refund.id },
              })
            },
          }
        )
        : undefined

const getOriginSale = (
  t: (value: string) => string,
  addHistory: (route: Route) => void,
  dispatch: any,
  target: any,
  company: TypeCompanyCurrency
) =>
  target
    ? getIconTitleInfoActionLine(
      'origin',
      {
        icon: DisplayIcon2({ Icon: Euro, color: 'var(--sys-color-content-secondary)' }),
      },
      <Title mainTexts={[`${t('activity.sale.SALE')} #` + target.number]} />,
      <Title mainTexts={[displayPrice(target.total, company.currency, company.currencyDecimals)]} />,
      {
        icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
        bulkToAction: () => {
          updateDrawerState(DrawerState.LOADING, dispatch)
          addHistory({ component: MenuSale, params: { id: target.id } })
        },
      }
    )
    : undefined

const mapSaleStateToProductLine =
  (t: (value: string) => string, addHistory: (route: Route) => void, dispatch: any, company: TypeCompanyCurrency) =>
    (line: any, index: number) => {
      let infos: Array<{ name?: string; variant: string; options: Array<string> }> | undefined
      let discountsName: Array<string> | undefined = []
      let discountsPrice: Array<string> | undefined = []
      let refund: string | undefined

      if (line.details.__typename === 'SaleLineProductDetails') {
        infos = [
          {
            variant: line.details.variant
              ? line.details.variant.options.map((opt: { value: string }) => opt.value).join(', ')
              : undefined,
            options: line.details.options
              ? line.details.options.map(
                (opt: any) =>
                  opt.name
                    .concat(' : ' + opt.value.name)
                    .concat(' (' + displayPrice(opt.total, company.currency, company.currencyDecimals)) + ')'
              )
              : undefined,
          },
        ]
      } else if (line.details.__typename === 'SaleLinePackDetails') {
        infos = line.details.packVariant.map((packOption: any) => ({
          name: packOption.details.name
            .concat(packOption.quantity > 1 ? ' x ' + packOption.quantity : '')
            .concat(
              packOption.additionalPrice !== null
                ? ' (' + displayPrice(packOption.additionalPrice, company.currency, company.currencyDecimals) + ')'
                : ''
            ),
          variant: packOption.details.variant
            ? packOption.details.variant.options.map((opt: { value: string }) => opt.value).join(', ')
            : undefined,
          options: packOption.details.options
            ? packOption.details.options.map((opt: any) => {
              return opt.name
                .concat(' : ' + opt.value.name)
                .concat(' (' + displayPrice(opt.total, company.currency, company.currencyDecimals) + ')')
            })
            : undefined,
        }))
      }

      if (line.discounts) {
        discountsName = line.discounts.map((dis: any) =>
          dis.discountName.concat(
            dis.type && dis.type === DiscountType.PERCENTAGE
              ? ' (' + displayPercentage(dis.rate) + ')'
              : ' (' + displayPrice(dis.rate, company.currency, company.currencyDecimals) + ')'
          )
        )
        discountsPrice = line.discounts.map(
          (dis: any) =>
            `${Math.sign(dis.amount) !== -1 ? '-' : ''}  ${displayPrice(
              dis.amount,
              company.currency,
              company.currencyDecimals
            )}`
        )
      }

      if (line.state === SaleLineState.REFUNDED || line.motive) {
        refund = ''

        if (line.refundedQuantity) {
          refund += t('activity.sale.REFUNDED')
          if (line.refundedQuantity !== line.quantity)
            refund = refund.concat(` ${line.refundedQuantity}/${line.quantity}`)
        }

        if (line.motive) {
          if (line.refundedQuantity) refund += ' - '
          refund = refund.concat(`${t('activity.sale.MOTIVE')} : ${line.motive}`)
        }
      }

      return getLineActionLine(
        'line-' + index,
        <Product
          name={{
            name: {
              text: line.details.name,
              style: {
                fontWeight: '500',
                overflowWrap: 'break-word',
              },
            },
            quantity: line.quantity > 1 ? line.quantity : undefined,
            price: {
              text: displayPrice(line.totalSellPrice, company.currency, company.currencyDecimals),
              style: {
                fontWeight: '500',
                overflowWrap: 'break-word',
              },
            },
          }}
          infos={infos}
          discounts={{ name: discountsName, price: discountsPrice }}
          note={line.note ? { title: 'Note : ' + line.note } : undefined}
          refund={refund}
          isNegative={!!refund}
        />,
        line.details.doesExist && {
          icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
          bulkToAction: () => {
            updateDrawerState(DrawerState.LOADING, dispatch)
            addHistory({
              component: line.details.__typename === 'SaleLinePackDetails' ? MenuPack : MenuProduct,
              params: {
                id: line.details.__typename === 'SaleLinePackDetails' ? line.details.packID : line.details.productID,
              },
            })
          },
        }
      )
    }

const getDiscountLine = (company: TypeCompanyCurrency, discounts: any) =>
  discounts && discounts.length > 0
    ? getLine(
      <Product
        discounts={{
          name: discounts.map(
            (discount: { discountName: string; rate: number }) =>
              discount.discountName +
              ' (' +
              displayPrice(discount.rate, company.currency, company.currencyDecimals) +
              ')'
          ),
          price: discounts.map(
            (discount: { rate: string; amount: number }) =>
              `- ${displayPrice(discount.amount, company.currency, company.currencyDecimals)}`
          ),
          global: true,
        }}
      />
    )
    : undefined

const mapSaleStateToPaymentLine =
  (
    t: (value: string) => string,
    isRefund: boolean,
    addHistory: (route: Route) => void,
    dispatch: any,
    company: TypeCompanyCurrency
  ) =>
    (payment: any, index: number) =>
      payment
        ? getIconTitleInfoActionLine(
          'payment-' + index,
          {
            icon: DisplayIcon2({
              Icon: paymentMethodToComponent(payment.type, payment.color).component,
              color: paymentMethodToComponent(payment.type, payment.color).color,
            }),
          },
          <Title mainTexts={[payment.name]} redTexts={isRefund ? [t('activity.sale.CANCELED')] : []} />,
          <Title mainTexts={[displayPrice(payment.amount, company.currency, company.currencyDecimals)]} />,
          {
            icon: DisplayIcon2({ Icon: ArrowForward, color: 'var(--sys-color-content-interactive)' }),
            bulkToAction: () => {
              updateDrawerState(DrawerState.LOADING, dispatch)
              addHistory({
                component: MenuPayment,
                params: { id: payment.id },
              })
            },
          }
        )
        : undefined

const mapStatsToLine = (company: TypeCompanyCurrency) => (stat: any) =>
  stat
    ? getTitleInfoLine(
      'stat',
      <Title mainTexts={[stat.title]} />,
      <Title mainTexts={[displayPrice(stat.value, company.currency, company.currencyDecimals)]} />
    )
    : undefined

const getAllLines = (
  t: (value: string) => string,
  saleState: TypeSale,
  addHistory: (route: Route) => void,
  dispatch: any,
  company: TypeCompanyCurrency
): {
  infos: Array<any>
  lines: Array<any>
  refunds?: Array<any>
  origin?: any
  paiements?: Array<any>
  totals?: Array<any>
} => {
  const infos: Array<any> = [
    saleState.total
      ? generateIconTitleLine(
        'total',
        displayPrice(saleState.total, company.currency, company.currencyDecimals),
        Euro,
        t,
        'activity.sale.TOTAL'
      )
      : undefined,
    generateIconTitleLine(
      'margin',
      displayPrice(saleState.margin ?? 0, company.currency, company.currencyDecimals),
      Euro,
      t,
      'activity.sale.MARGIN'
    ),
    saleState.totalRest
      ? generateIconTitleLine(
        'rest',
        displayPrice(saleState.totalRest, company.currency, company.currencyDecimals),
        Euro,
        t,
        'activity.sale.REST'
      )
      : undefined,
    saleState.closedAt
      ? generateIconTitleLine(
        'closedAt',
        displayDateWithHours(saleState.closedAt),
        Calendar,
        t,
        'activity.sale.CLOSED_AT'
      )
      : undefined,
    saleState.shop ? generateIconTitleLine('shop', saleState.shop.name, Shop, t, 'activity.sale.SHOP') : undefined,
    saleState.sourceName
      ? generateIconTitleLine('source', saleState.sourceName, Till, t, 'activity.sale.SOURCE')
      : undefined,
    getCashbookLine(t, addHistory, dispatch, saleState.cashbook),
    getSellerLine(t, saleState.seller),
    getCustomerLine(t, addHistory, dispatch, saleState.customer),
  ].filter((_) => _)
  const lines = [
    ...saleState.lines!.map(mapSaleStateToProductLine(t, addHistory, dispatch, company)),
    getDiscountLine(company!, saleState.discounts!),
  ].filter((_: any) => _)
  const refunds = saleState.refundSales?.items
    .map(mapSaleStateToRefundLine(t, addHistory, dispatch, company))
    .filter((_) => _)
  const origin = [getOriginSale(t, addHistory, dispatch, saleState.target, company)].filter((_) => _)
  const paiements = saleState.payments
    ?.map(
      mapSaleStateToPaymentLine(
        t,
        saleState.status?.text === t('activity.sale.CANCELED'),
        addHistory,
        dispatch,
        company
      )
    )
    .filter((_) => _)
  const totals = saleState.stats?.map(mapStatsToLine(company)).filter((stat) => stat)
  return { infos, lines, refunds, origin, paiements, totals }
}

export default getAllLines
