import { TypeCol, TypeColumnConfiguration } from '../types'
import { enumToStr } from '../../utils/color'
import { displayDateShortMonthWithHours } from '../../utils/date'
import Country from '../../components/list/cell/country'
import { ComponentSize } from '../../components/newComponents/types'
import Avatar from '../../components/newComponents/avatar'
import ListItemAvatar from '../../components/newComponents/listItem/avatar'

const CUSTOMER = 'customer'

export const customerCols: Array<TypeCol> = [
  {
    id: 'color',
    title: '',
    type: 'element',
    active: true,
    function: ({ value }) =>
      ListItemAvatar({
        children: Avatar({
          color: enumToStr(value.color),
          size: ComponentSize.SMALL,
          iconText: value.iconText,
        }),
      }),
  },
  {
    id: 'firstName',
    title: 'FIRST_NAME',
    field: 'firstName',
    type: 'text',
    fieldType: 'string',
    active: true,
    sortable: true,
    notCustomable: true,
  },
  {
    id: 'lastName',
    title: 'LAST_NAME',
    field: 'lastName',
    type: 'text',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'email',
    title: 'EMAIL',
    field: 'email',
    type: 'text',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'phone',
    title: 'PHONE',
    field: 'phone',
    type: 'text',
    fieldType: 'string',
    active: true,
  },
  {
    id: 'companyName',
    title: 'COMPANY_NAME',
    field: 'companyName',
    type: 'text',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'address',
    title: 'ADDRESS',
    type: 'function',
    fieldType: 'string',
    active: true,
    function: ({ address }: { address: { address: string } }) => (address ? address.address : null),
  },
  {
    id: 'city',
    title: 'CITY',
    type: 'function',
    fieldType: 'string',
    active: true,
    function: ({ address }: { address: { city: string } }) => (address ? address.city : null),
  },
  {
    id: 'zipcode',
    title: 'ZIP_CODE',
    type: 'function',
    fieldType: 'string',
    active: true,
    function: ({ address }: { address: { zipcode: string } }) => (address ? address.zipcode : null),
  },
  {
    id: 'country',
    title: 'COUNTRY',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value }: any) => (value.address ? Country(value.address) : ''),
  },
  {
    id: 'fidelityCardNumber',
    title: 'FIDELITY_CARD_NUMBER',
    field: 'fidelityCardNumber',
    type: 'text',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'note',
    title: 'CLIENT_NOTE',
    field: 'note',
    type: 'text',
    fieldType: 'string',
    active: true,
  },
  {
    id: 'createdAt',
    title: 'CREATED_AT',
    type: 'function',
    field: 'createdAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => displayDateShortMonthWithHours(date),
  },
]

export const customersColsConf: TypeColumnConfiguration[] = [
  {
    id: 'requestCustomers',
    type: CUSTOMER,
    cols: customerCols,
    creatable: true,
    editable: false,
    clickable: true,
    exportable: true,
    shrinkrable: true,
    customable: true,
    checkboxes: true,
    searchBarPlaceholder: 'searchBar.placeholder.NAME_MAIL_PHONE',
    creatablePlaceholder: 'customer.detail.form.NEW',
  },
]
