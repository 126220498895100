import { Product } from '../API';
import { sortList } from '../actions/list';
import { pluralize } from '../utils/typeToType';
import { elementMatchesQuery } from './subscriptions';
import { StateStore, entities, objectType, objectPlural, Movement } from './types';

const sortListElement = (
  payload: {
    objectType: objectType;
    field: string;
    direction: string;
  },
  state: StateStore,
): StateStore => {
  // @ts-ignore
  const { cols } = state[pluralize(payload.objectType)];
  // @ts-ignore
  const { items } = state[pluralize(payload.objectType)].items;
  // @ts-ignore
  state[pluralize(payload.objectType)] = {
    // @ts-ignore
    ...state[pluralize(payload.objectType)],
    items: {
      // @ts-ignore
      ...state[pluralize(payload.objectType)].items,
      items: sortList(cols, { items, sort: { field: payload.field, direction: payload.direction } }),
      sort: { field: payload.field, direction: payload.direction },
    },
  };

  return { ...state };
};

/**
 *
 * This method unselect all selected items on every elements
 *
 * @usedIn - List
 *
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Jennifer Charlois
 *
 */
const unSelectAllList = (state: StateStore): StateStore => {
  entities.forEach(entity => {
    const plural = pluralize(entity);
    // @ts-ignore
    state[plural] = {
      // @ts-ignore
      ...state[plural],
      items: {
        // @ts-ignore
        ...state[plural].items,
        // @ts-ignore
        items: state[plural].items.items.map((element: { selected: boolean; subValues?: Array<any> }) => {
          element.selected = false;
          if (element.subValues) {
            element.subValues = element.subValues.map((value: { selected: boolean }) => {
              value.selected = false;
              return value;
            });
          }
          return element;
        }),
      },
    };
  });

  return state;
};

/**
 *
 * This method is nearly the same than above except that it unselects products by id
 *
 * @usedIn - Editable list
 *
 * @param payload - An array of ids
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Jennifer Charlois
 *
 */
const unSelectProductsById = (payload: { ids: Array<string> }, state: StateStore): StateStore => ({
  ...state,
  products: {
    ...state.products,
    items: {
      ...state.products.items,
      items: state.products.items.items.map(product => {
        if (payload.ids.find(id => id === product.id)) {
          product.selected = false;
          if (product.subValues)
            product.subValues = product.subValues.map((value: { selected: boolean }) => ({
              ...value,
              selected: false,
            }));
        }
        return product;
      }),
    },
  },
  inventoryMoveProducts: {
    ...state.inventoryMoveProducts,
    items: {
      ...state.inventoryMoveProducts.items,
      items: state.inventoryMoveProducts.items.items.map(product => {
        if (payload.ids.find(id => id === product.id)) {
          product.selected = false;
          if (product.subValues)
            product.subValues = product.subValues.map((value: { selected: boolean }) => ({
              ...value,
              selected: false,
            }));
        }
        return product;
      }),
    },
  },
});

/**
 *
 * This method is nearly the same than above except that it unselects product's sub values by id
 *
 * @usedIn - Editable list
 *
 * @param payload - An array of productID and subID
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Jennifer Charlois
 *
 */
const unSelectVariantById = (
  payload: { productID: string; subID: string; type: objectType },
  state: StateStore,
): StateStore => {
  const modifiedID = payload.productID.split('__').pop();
  if (payload.type === objectType.INVENTORY_MOVE_PRODUCT) {
    const allUnselected = state.inventoryMoveProducts.items.items
      .find(product => product.productID === modifiedID)
      .subValues.filter((s: any) => !s.selected).length;

    return {
      ...state,
      inventoryMoveProducts: {
        ...state.inventoryMoveProducts,
        items: {
          ...state.inventoryMoveProducts.items,
          items: state.inventoryMoveProducts.items.items.map(product => {
            if (modifiedID === product.productID) {
              return {
                ...product,
                selected: product.variantsSelectedAll
                  ? allUnselected !== product.variantsCountAll - 1
                  : allUnselected !== product.variantsCount - 1,
                subValues: product.subValues.map((subValue: any) => {
                  if (subValue.id === payload.subID) {
                    subValue.selected = false;
                    return subValue;
                  }
                  return subValue;
                }),
              };
            }
            return product;
          }),
        },
      },
    };
  } else {
    const allUnselected = state.products.items.items
      .find(product => product.id === modifiedID)
      .subValues.filter((s: any) => !s.selected).length;

    return {
      ...state,
      products: {
        ...state.products,
        editValues: { ...state.products.editValues },
        items: {
          ...state.products.items,
          items: state.products.items.items.map(product => {
            if (modifiedID === product.id) {
              return {
                ...product,
                selected: product.allSelected
                  ? allUnselected !== product.variantsCount - 1
                  : allUnselected !== product.subValues.length - 1,
                subValues: product.subValues.map((subValue: any) => {
                  if (subValue.id === payload.subID) {
                    subValue.selected = false;
                    return subValue;
                  }
                  return subValue;
                }),
              };
            }
            return product;
          }),
        },
      },
    };
  }
};

/**
 *
 * This method update a store element items
 *
 * @usedIn - List
 *
 * @param payload - An object of full items and element type
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Jennifer Charlois
 *
 */
const updateElementItems = (payload: { fullItems: Array<any>; type: string }, state: StateStore): StateStore => {
  // @ts-ignore
  let entityType = state[pluralize(payload.type)];

  entityType = {
    ...entityType,
    items: { ...entityType.items, items: payload.fullItems },
  };

  return { ...state, [pluralize(payload.type)]: entityType };
};

const updateVariantAfterSubscription = (payload: { element: Product; type: string }, state: StateStore): StateStore => {
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map(item =>
          item.id === payload.element.id
            ? {
                ...payload.element,
                //@ts-ignore
                selected: item.selected !== payload.element.selected ? item.selected : payload.element.selected,
              }
            : item,
        ),
      },
    },
  };
};

const updateAllSelectedItemsAndVariant = (payload: { value: boolean; type: string }, state: StateStore): StateStore => {
  const entityType = pluralize(payload.type);

  if (state[entityType].filteredItems) {
    //@ts-ignore
    const updatedFilteredItems = state[entityType].filteredItems.items.map((fItem: any) => ({
      ...fItem,
      selected: payload.value,
      allSelected: payload.value,
      subValues:
        fItem.subValues && fItem.subValues.length > 0
          ? fItem.subValues.map((s: any) => {
              s.selected = payload.value;
              return s;
            })
          : [],
    }));

    let updatedItems = state[entityType].items.items.map((item: any) => {
      const filteredItem = updatedFilteredItems.find(fItem => fItem.id === item.id);
      return filteredItem ? filteredItem : item;
    });

    updatedFilteredItems.forEach(fItem => {
      if (!updatedItems.some(item => item.id === fItem.id)) {
        updatedItems.push(fItem);
      }
    });

    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        filteredItems: {
          ...state[entityType].filteredItems,
          items: updatedFilteredItems,
        },
        items: {
          ...state[entityType].items,
          items: updatedItems,
        },
      },
    };
  } else {
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        items: {
          ...state[entityType].items,
          items: state[entityType].items.items.map(item => ({
            ...item,
            selected: payload.value,
            allSelected: payload.value,
            subValues:
              item.subValues && item.subValues.length > 0
                ? item.subValues.map((s: any) => {
                    s.selected = payload.value;
                    return s;
                  })
                : [],
          })),
        },
      },
    };
  }
};

const updateItemWithoutSubValue = (payload: { element: Product; type: string }, state: StateStore): StateStore => {
  const entityType = pluralize(payload.type);
  if (state[entityType].filteredItems) {
    const missingItemLoaded = state[entityType].items.items.find(item => item.id === payload.element.id);
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        items: {
          ...state[entityType].items,
          items: missingItemLoaded
            ? state[entityType].items.items.map(item =>
                item.id === payload.element.id
                  ? {
                      ...item,
                      selected: !item.selected,
                    }
                  : item,
              )
            : [
                ...state[entityType].items.items,
                {
                  ...state[entityType].filteredItems?.items.find(item => item.id === payload.element.id),
                  selected: true,
                },
              ],
        },
        filteredItems: {
          ...state[entityType].filteredItems,
          //@ts-ignore
          items: state[entityType].filteredItems?.items.map(item =>
            item.id === payload.element.id
              ? {
                  ...item,
                  selected: !item.selected,
                }
              : item,
          ),
        },
      },
    };
  }

  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      items: {
        ...state[entityType].items,
        items: state[entityType].items.items.map(item =>
          item.id === payload.element.id
            ? {
                ...item,
                selected: !item.selected,
              }
            : item,
        ),
      },
    },
  };
};

const updateSelectedItemAndVariant = (payload: { element: Product }, state: StateStore): StateStore => {
  if (state.products.filteredItems) {
    const missingItemLoaded = state.products.items.items.find(item => item.id === payload.element.id);
    return {
      ...state,
      products: {
        ...state.products,
        items: {
          ...state.products.items,
          items: missingItemLoaded
            ? state.products.items.items.map(item =>
                item.id === payload.element.id
                  ? {
                      ...item,
                      selected: !item.selected,
                      allSelected: !item.allSelected,
                      subValues: item.subValues.map((sub: { selected: boolean }) => {
                        sub.selected = !item.selected;
                        return sub;
                      }),
                    }
                  : item,
              )
            : [
                ...state.products.items.items,
                {
                  ...state.products.filteredItems?.items.find(item => item.id === payload.element.id),
                  selected: true,
                  allSelected: true,
                },
              ],
        },
        filteredItems: {
          ...state.products.filteredItems,
          items: state.products.filteredItems.items.map(item =>
            item.id === payload.element.id
              ? {
                  ...item,
                  selected: !item.selected,
                  allSelected: !item.allSelected,
                  subValues: item.subValues.map((sub: { selected: boolean }) => {
                    sub.selected = !item.selected;
                    return sub;
                  }),
                }
              : item,
          ),
        },
      },
    };
  }
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map(item =>
          item.id === payload.element.id
            ? {
                ...item,
                selected: !item.selected,
                allSelected: !item.allSelected,
                subValues: item.subValues.map((sub: { selected: boolean }) => {
                  sub.selected = !item.selected;
                  return sub;
                }),
              }
            : item,
        ),
      },
    },
  };
};

const updateSelectedItemVariant = (payload: { subElement: any }, state: StateStore): StateStore => {
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map(item => ({
          ...item,
          subValues: item.subValues.map((sub: any) => {
            if (sub.id === payload.subElement.id) sub.selected = !sub.selected;
            return sub;
          }),
        })),
      },
    },
  };
};

// only when users manually uncheck every subValues to update parent selected state

const updateOnlyParentItem = (payload: { element: Product; count: number }, state: StateStore): StateStore => {
  if (state.products.filteredItems) {
    return {
      ...state,
      products: {
        ...state.products,
        items: {
          ...state.products.items,
          items: state.products.items.items.map(item =>
            item.id === payload.element.id
              ? {
                  ...item,
                  selected: payload.count !== 0,
                  allSelected: payload.count === item.variantsCount,
                }
              : item,
          ),
        },
        filteredItems: {
          ...state.products.filteredItems,
          // @ts-ignore
          items: state.products.filteredItems?.items.map(item =>
            item.id === payload.element.id
              ? {
                  ...item,
                  selected: payload.count !== 0,
                  allSelected: payload.count === item.variantsCount,
                }
              : item,
          ),
        },
      },
    };
  }
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map(item =>
          item.id === payload.element.id
            ? {
                ...item,
                selected: payload.count !== 0,
                allSelected: payload.count === item.variantsCount,
              }
            : item,
        ),
      },
    },
  };
};

const updateBuyPrice = (payload: Array<Movement>, state: StateStore): StateStore => {
  const updatedProducts = state.products.items.items.map((product: Product) => {
    const movement = payload.find(m => m.productID === product.id);

    if (movement) {
      if (movement.variantID) {
        const updatedSubValues =
          //@ts-expect-error
          product.subValues.length > 0 &&
          //@ts-expect-error
          product.subValues.map(variant => {
            return variant.id === movement.variantID ? { ...variant, buyPrice: movement.price } : variant;
          });

        return { ...product, subValues: updatedSubValues };
      } else {
        return { ...product, buyPrice: movement.price };
      }
    }

    return product;
  });

  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: updatedProducts,
      },
    },
  };
};

/**
 *
 * This method update a product items and filtered items if existing
 *
 * @usedIn - List
 *
 * @param payload - An object of element o update and product id
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - David Faure
 *
 */

const updateParentProduct = (payload: { element: Product; id: string }, state: StateStore): StateStore => {
  const updateItem = (item: Product): Product => {
    if (item.id !== payload.element.id) {
      return item;
    }

    // Retrieve the existing product from the store
    const existingProduct = state.products.items.items.find(p => p.id === payload.element.id);

    // If the existing product is not found, just return the payload element
    if (!existingProduct) {
      return payload.element;
    }

    // Check and replace properties if they are null in the payload
    const updatedProduct = {
      ...payload.element,
      tax: payload.element.taxID && !payload.element.tax ? existingProduct.tax : payload.element.tax,
      category:
        payload.element.categoryID && !payload.element.category ? existingProduct.category : payload.element.category,
      tags: payload.element.tags || existingProduct.tags,
      options: payload.element.options || existingProduct.options,
      //@ts-ignore
      customField: payload.element.customField || existingProduct.customField,
      selected: existingProduct.selected,
      subValues: existingProduct.subValues,
      subvalueNextToken: existingProduct.subvalueNextToken,
      variantsCount: existingProduct.variantsCount,
      variants: existingProduct.variants,
    };

    return updatedProduct;
  };

  const updatedItems = state.products.items.items.map(updateItem);

  let updatedFilteredItems: Product[] | undefined = undefined;

  if (state.products.filteredItems) {
    updatedFilteredItems = state.products.filteredItems.items.map(updateItem);

    // If there's an active filter/query, check if the product matches.
    if (state.products.query) {
      updatedFilteredItems = updatedFilteredItems.filter(item => elementMatchesQuery(item, state.products.query));
    }

    return {
      ...state,
      products: {
        ...state.products,
        items: {
          ...state.products.items,
          items: state.products.items.items.map(updateItem),
        },
        filteredItems: updatedFilteredItems
          ? {
              ...state.products.filteredItems,
              items: updatedFilteredItems,
            }
          : undefined,
      },
    };
  }
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: updatedItems,
      },
    },
  };
};

const updateInventoryQuantities = (payload: { element: any }, state: StateStore): StateStore => {
  const { productID, shopID, quantity, variantID } = payload.element;
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map(product => {
          if (product.id === productID) {
            if (variantID && product.subValues && product.subValues.length > 0) {
              const updatedSubValues = product.subValues.map((subValue: any) => {
                if (subValue.variantID === variantID) {
                  const updatedInventoryQuantities = subValue.inventoryQuantities.map((inventoryItem: any) => {
                    if (inventoryItem.productID === productID && inventoryItem.shopID === shopID) {
                      return {
                        ...inventoryItem,
                        quantity,
                      };
                    }
                    return inventoryItem;
                  });
                  return {
                    ...subValue,
                    inventoryQuantities: updatedInventoryQuantities,
                  };
                }
                return subValue;
              });
              return {
                ...product,
                subValues: updatedSubValues,
              };
            } else {
              const updatedInventoryQuantities = product.inventoryQuantities.map((inventoryItem: any) => {
                if (inventoryItem.productID === productID && inventoryItem.shopID === shopID) {
                  return {
                    ...inventoryItem,
                    quantity,
                  };
                }
                return inventoryItem;
              });
              return {
                ...product,
                inventoryQuantities: updatedInventoryQuantities,
              };
            }
          }
          return product;
        }),
      },
    },
  };
};

const createCustomFieldValue = (payload: { element: any; type: objectType }, state: StateStore): StateStore => {
  const { customFieldID } = payload.element;
  const objectPlural = pluralize(payload.type);
  const typeIDProperty = `${payload.type}ID`;

  const relatedCustomField = state.customFields.items.items.find((field: any) => field.id === customFieldID);

  return {
    ...state,
    [objectPlural]: {
      ...state[objectPlural],
      items: {
        ...state[objectPlural].items,
        items: state[objectPlural].items.items.map(item => {
          if (item.id === payload.element[typeIDProperty]) {
            const customFieldExists = item.customFields.items.some((e: any) => e.customFieldID === customFieldID);
            console.log(customFieldExists, 'EXIST ?');
            if (!customFieldExists) {
              return {
                ...item,
                customFields: {
                  ...item.customFields,
                  items: [
                    ...item.customFields.items,
                    {
                      customField: relatedCustomField,
                      ...payload.element,
                    },
                  ],
                },
              };
            }
          }
          return item;
        }),
      },
    },
  };
};

const updateCustomFieldsValue = (payload: { element: any; type: objectType }, state: StateStore): StateStore => {
  const { customFieldID } = payload.element;
  const objectPlural = pluralize(payload.type);
  const typeIDProperty = `${payload.type}ID`;

  return {
    ...state,
    [objectPlural]: {
      ...state[objectPlural],
      items: {
        ...state[objectPlural].items,
        items: state[objectPlural].items.items.map(item => {
          if (item.id === payload.element[typeIDProperty]) {
            return {
              ...item,
              customFields: {
                ...item.customFields,
                items: item.customFields.items.map((e: any) => {
                  if (e.customFieldID === customFieldID) {
                    return {
                      ...e,
                      ...payload.element,
                    };
                  }
                  return e;
                }),
              },
            };
          }
          return item;
        }),
      },
    },
  };
};

const deleteCustomFieldValue = (payload: { element: any; type: objectType }, state: StateStore): StateStore => {
  const { customFieldID } = payload.element;
  const objectPlural = pluralize(payload.type);
  const typeIDProperty = `${payload.type}ID`;

  return {
    ...state,
    [objectPlural]: {
      ...state[objectPlural],
      items: {
        ...state[objectPlural].items,
        items: state[objectPlural].items.items.map(item => {
          if (item.id === payload.element[typeIDProperty]) {
            return {
              ...item,
              customFields: {
                ...item.customFields,
                items: item.customFields.items.filter((e: any) => e.customFieldID !== customFieldID),
              },
            };
          }
          return item;
        }),
      },
    },
  };
};

export {
  sortListElement,
  unSelectAllList,
  unSelectProductsById,
  unSelectVariantById,
  updateElementItems,
  updateAllSelectedItemsAndVariant,
  updateSelectedItemAndVariant,
  updateSelectedItemVariant,
  updateOnlyParentItem,
  updateItemWithoutSubValue,
  updateVariantAfterSubscription,
  updateParentProduct,
  updateInventoryQuantities,
  updateCustomFieldsValue,
  createCustomFieldValue,
  deleteCustomFieldValue,
  updateBuyPrice,
};
