const CreditCard = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1614 4H4.16138C3.05138 4 2.17138 4.89 2.17138 6L2.16138 18C2.16138 19.11 3.05138 20 4.16138 20H20.1614C21.2714 20 22.1614 19.11 22.1614 18V6C22.1614 4.89 21.2714 4 20.1614 4ZM19.1614 18H5.16138C4.61138 18 4.16138 17.55 4.16138 17V12H20.1614V17C20.1614 17.55 19.7114 18 19.1614 18ZM20.1614 8H4.16138V6H20.1614V8Z"
      fill={color}
    />
  </svg>
)

export default CreditCard
