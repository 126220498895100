import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { APIOption, Stats, TypeVariant, TypeTag } from '../../../../../types'
import Detail from './form'
import { DeleteProductVariantInput } from '../../../../../API'
import { getDashboardView, isDrawerOpen, listCustomFieldItems, listProducts, selectedCompany, currentDrawer } from '../../../../../store/selector'
import { drawerResetHistory, updateDrawerState } from '../../../../../actions/drawer'
import { DashboardView, DrawerState, ProductWitSubValues, objectType } from '../../../../../store/types'
import { callService } from '../../../../../services'
import {
  taxID as taxIDValidator,
  buyPrice as buyPriceValidator,
  sellPrice as sellPriceValidator,
  oldVariantValues as oldVariantValuesValidator,
} from '../../../../../format/errors/filters/catalog/product'
import { TabIdsCatalog } from '../../..'
import { Route } from '../../../../../utils/navigation'
import { TypeTab } from '../../../../../types/drawer'
import {
  ComponentDirection,
  ComponentSize,
  ComponentState,
  ComponentType,
} from '../../../../../components/newComponents/types'
import { getVariant } from '../../../../../getters/catalog/product'
import { DataWithErrors } from '../../../../../services/types'
import DrawerTop from '../../../../../components/oldComponents/drawer/top'
import TabList from '../../../../../components/newComponents/tabList'
import { deleteProductVariant } from '../../../../../graphql/custom/mutations'
import { customFieldsInState } from '../../../../../services/setting/customField'
import Statistic from '../../../../../components/oldComponents/drawer/statistic'
import Inventory from '../inventory'
import { getProductVariantStats } from '../../../../../graphql/custom/queries';
import generateDataCy from '../../../../../utils/cypress'
import Divider from '../../../../../components/newComponents/divider'
import { displayNumber } from '../../../../../utils/number'

function extractSubID(subID: string): string {
  if (subID.includes('__')) {
    return subID.split('__')[1];
  }
  return subID;
}

const MenuVariant = ({
  productID,
  subId,
  isDuplicate,
  history,
  addHistory,
  close,
}: {
  productID: string
  subId: string
  isDuplicate?: boolean
  history?: Array<Route>
  addHistory: (route: Route) => void
  close: () => void
}) => {
  const { t } = useTranslation()

  const initialState: {
    tabList: Array<TypeTab>
    detail: TypeVariant
    performance?: Stats
    inventory?: any
  } = {
    tabList: [
      { id: TabIdsCatalog.DETAIL, name: t('drawer.tabs.DETAIL'), state: ComponentState.ACTIVE },
      { id: TabIdsCatalog.STAT, name: t('drawer.tabs.PERFORMANCE'), state: ComponentState.DEFAULT },
      { id: TabIdsCatalog.INVENTORY, name: t('drawer.tabs.INVENTORY'), state: ComponentState.DEFAULT },
    ],
    detail: {
      actions: [{ id: 'delete', value: t('drawer.menu.DELETE') }],
      id: '',
      productName: '',
      variants: [],
      showSubmit: false,
      productInfos: {
        name: '',
        categoryID: '',
      },
      errors: {
        variations: [],
      },
      validators: {
        taxID: taxIDValidator,
        buyPrice: buyPriceValidator,
        sellPrice: sellPriceValidator,
        variations: [{ name: oldVariantValuesValidator, value: oldVariantValuesValidator }],
      },
    },
  }
  const [state, setState] = useState(initialState)
  const mobileView = useSelector(getDashboardView)
  const listCustomFields = useSelector(listCustomFieldItems)
  const company = useSelector(selectedCompany)
  const drawerOpen = useSelector(isDrawerOpen);
  const drawer = useSelector(currentDrawer)
  const allProducts = useSelector(listProducts)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'drawer', value: 'variant' })

  const setVariantInState = (data: DataWithErrors, newSubID: string) => {
    if (data.data) {
      const product = data.data
      const variant = product.variants.items.find((decl: { variantID: string }) => decl.variantID === newSubID)
      let newVariant = state.detail
      if (variant) {
        newVariant = {
          ...newVariant,
          id: isDuplicate ? '' : variant.variantID,
          productName: product.name,
          variants: variant.options.map((option: { name: string; value: string }) => ({
            name: option.name,
            value: option.value,
            created: true,
          })),
          taxID: variant.taxID,
          reference: variant.reference,
          sellPrice: displayNumber(variant.sellPrice, company.currencyDecimals),
          buyPrice: displayNumber(variant.buyPrice, company.currencyDecimals),
          barcode: variant.barcode,
          showSubmit: !!isDuplicate,
          errors: {
            ...newVariant.errors,
            variations: variant.options?.map((_: any) => ({
              name: { value: false, message: '' },
              value: { value: false, message: '' },
            })),
          },
          validators: {
            ...newVariant.validators,
            variations: variant.options?.map((_: any) => ({
              name: oldVariantValuesValidator,
              value: oldVariantValuesValidator,
            })),
          },
        }
      }
      setState({
        ...state,
        detail: {
          ...newVariant,
          productInfos: {
            name: product.name,
            categoryID: product.categoryID,
            tags:
              product.tags.items.length > 0
                ? product.tags.items
                  .filter((tag: { tag: TypeTag }) => tag.tag)
                  .map((tag: { tag: TypeTag }) => ({
                    id: tag.tag.id,
                    name: tag.tag.name,
                  }))
                : [],
            customFields: customFieldsInState('PRODUCT', listCustomFields, product.customFields),
            options: product.options.items
              .filter((opt: { option: APIOption }) => opt && opt.option)
              .map(({ option }: { option: APIOption }) => ({
                id: option.id,
                name: option.name,
                values: option.values,
              })),
          },
        },
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  const addProductInfo = (product: any) => {
    setState({
      ...state,
      detail: {
        ...state.detail,
        productInfos: {
          name: product.name,
          categoryID: product.categoryID,
          tags:
            product.tags.items.length > 0
              ? product.tags.items
                .filter((tag: { tag: TypeTag }) => tag.tag)
                .map((tag: { tag: TypeTag }) => ({
                  id: tag.tag.id,
                  name: tag.tag.name,
                }))
              : [],
          customFields: customFieldsInState('PRODUCT', listCustomFields, product.customFields),
          options: product.options.items
            .filter((opt: { option: APIOption }) => opt && opt.option)
            .map(({ option }: { option: APIOption }) => ({
              id: option.id,
              name: option.name,
              values: option.values,
            })),
        },
      },
    })
    updateDrawerState(DrawerState.LOADED, dispatch)
  }

  useEffect(() => {
    if (productID && drawerOpen) {
      if (drawer.action === 'addVariation') {
        const product = allProducts.items.items.find((item) => item.id === productID);
        addProductInfo(product)
      } else {
        const newSubID = extractSubID(subId)
        getVariant(productID, newSubID).then((data) => setVariantInState(data, newSubID));
      }
    }
  }, [productID, subId, isDuplicate, drawerOpen, drawer])

  const ComponentToDisplay = () => {
    switch (state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id) {
      case TabIdsCatalog.DETAIL:
        return <Detail productID={productID} productState={state.detail} close={close} />
      case TabIdsCatalog.STAT:
        return (
          <Statistic
            id={productID}
            name={state.detail.productName ?? ''}
            variantID={state.detail.id}
            query={getProductVariantStats}
            queryName={'getProduct'}
            type={objectType.PRODUCT}
            close={close}
          />
        )
      case TabIdsCatalog.INVENTORY:
        return <Inventory productID={productID} variantID={state.detail.id} close={close} />
      default:
        return <></>
    }
  }

  const handleClickMenu = (action: string) => {
    switch (action) {
      case 'delete': {
        callService<{ input: DeleteProductVariantInput }>(
          {
            input: {
              productID,
              variantID: state.detail.id!,
            },
          },
          deleteProductVariant,
          'deleteProductVariant'
        )
        drawerResetHistory(dispatch)
        close()
        break
      }
      default:
        break
    }
  }

  return (
    <>
      {state.detail.id &&
        state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id !== TabIdsCatalog.STAT &&
        state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id !== TabIdsCatalog.INVENTORY ? (
        <DrawerTop
          history={history}
          name={state.detail.productName ?? ''}
          mobileView={mobileView === DashboardView.MOBILE}
          burgerMenu={{
            actions: state.detail.actions!,
            onClick: handleClickMenu,
          }}
          close={close}
          dataCy={cyContext}
        />
      ) : (
        <DrawerTop
          history={history}
          name={state.detail.productName}
          mobileView={mobileView === DashboardView.MOBILE}
          close={close}
          dataCy={cyContext}
        />
      )}
      {subId && !isDuplicate && (
        <>
          <TabList
            tabList={state.tabList}
            onClick={(newTab: TypeTab) => {
              if (newTab.id !== TabIdsCatalog.DETAIL) updateDrawerState(DrawerState.LOADING, dispatch)
              setState({
                ...state,
                tabList: state.tabList.map((sTab) => ({
                  ...sTab,
                  state: newTab.id === sTab.id ? ComponentState.ACTIVE : ComponentState.DEFAULT,
                })),
              })
            }}
            size={ComponentSize.LARGE}
            dataCy={cyContext}
          />
          <Divider type={ComponentType.FILL} direction={ComponentDirection.HORIZONTAL} />
        </>
      )}
      <ComponentToDisplay />
    </>
  )
}

export default MenuVariant
