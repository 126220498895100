import { Action } from '../store/types'
import Auth0 from 'auth0-js'
import { setTestMode } from './localStorage'

export const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'auth.infinite.tactill.com'
export const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '8NZQZujLE9eOG0LjrCxescb3Imo3a0Wl'
export const databaseName = process.env.REACT_APP_AUTH0_DATABASE_NAME || 'Username-Password-preprod'
export const env = process.env.REACT_APP_BUILD_ENV || 'preprod'

type connection = { email: string; password: string }

export const webAuth = new Auth0.WebAuth({
  domain,
  clientID: clientId,
  responseType: 'token id_token',
  redirectUri: window.location.origin,
})

export const signOut = async (dispatch: any, logout: any) => {
  logout({
    client_id: clientId,
    returnTo: window.location.origin,
  })
  setTestMode(false)
  dispatch({ type: Action.DISCONNECT })
}

export const login = ({ email, password }: connection) =>
  new Promise((resolve, reject) => {
    webAuth.login(
      {
        username: email,
        password: password,
        realm: databaseName,
        audience: 'https://tactill.infinite.com',
      },
      (err: any, result: any) => {
        if (err) reject(err)
        else resolve(result)
      }
    )
  })

export const forgotPassword = (email: string) =>
  new Promise((resolve, reject) =>
    webAuth.changePassword(
      {
        connection: databaseName,
        email,
      },
      (err: any, res: any) => {
        if (err) reject(err)
        else resolve(res)
      }
    )
  )

export const signup = ({ email, password }: connection) =>
  new Promise((resolve, reject) =>
    webAuth.signupAndAuthorize(
      {
        connection: databaseName,
        email: email,
        password: password,
      },
      (err: any, res: any) => {
        if (err) reject(err)
        else resolve(res)
      }
    )
  )
