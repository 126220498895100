import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { closeAndResetModal, updateModal } from '../../../../../actions/modal'
import { getSelectedCategoryAssociations, listCategories } from '../../../../../store/selector'
import { deleteCategory as deleteC } from '../../../../../graphql/custom/mutations'
import { callService } from '../../../../../services'
import { DeleteCategoryInput } from '../../../../../API'
import generateDataCy from '../../../../../utils/cypress'
import Button from '../../../../../components/newComponents/button'
import { ComponentSize, ComponentType } from '../../../../../components/newComponents/types'

import styles from '../../../../../components/newComponents/modal/Modal.module.css'
import { getTotalProductByCategory } from '../../../../../graphql/custom/queries'
import { getCompanyID } from '../../../../../services/localStorage'

type State = {
  id: string
  name: string
  total: number | null
}

export const Delete = ({ id }: { id: string }) => {
  const initialState: State = {
    id: '',
    name: '',
    total: null,
  }
  const [state, setState] = useState(initialState)
  const categories = useSelector(listCategories)
  const association = useSelector(getSelectedCategoryAssociations(id))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'catalog', value: 'category' })

  useEffect(() => {
    const category = categories.items.items.find((category: { id: string }) => category.id === id);
    if (category) {
      setState(prevState => ({
        ...prevState,
        id: category.id,
        name: category.name,
      }));

      const input = {
        catalogID: getCompanyID(),
        category: category.name
      };

      callService(input, getTotalProductByCategory, 'requestProducts')
        .then(productData => {
          //@ts-ignore
          const totalProducts = productData.data.total;
          setState(prevState => ({
            ...prevState,
            total: totalProducts,
          }));
        })
        .catch(error => {
          console.error('Error getting product count:', error);
        });
    }
  }, [association]);

  return (
    <div className={styles.modal} data-cy={generateDataCy({ scope: cyContext, value: 'modal' })}>
      <div className={styles.modalTitle}>{t('catalog.category.bulk.DELETE_CATEGORY')}</div>
      <div className={styles.modalContent}>
        <div
          className={styles.modalImportantInfo}
          data-cy={generateDataCy({ scope: cyContext, value: 'associations' })}
        >
          {t('catalog.category.bulk.WARNING_DELETE', {
            name: state.name,
            association: state.total,
            pluralAssociation: state?.total && state.total > 1 ? 's' : '',
          })}
        </div>
        <div className={styles.modalInfo}>{t('catalog.category.bulk.EDIT')}</div>
      </div>
      <Button
        title={t('button.UNDERSTOOD')}
        size={ComponentSize.LARGE}
        type={ComponentType.PRIMARY}
        onClick={() => closeAndResetModal(dispatch)}
      />
    </div>
  )
}

export const deleteCategories = (selected: Array<{ id: string }>, close: () => void, dispatch: any) => () => {
  Promise.all(
    selected.map(({ id }: { id: string }) =>
      callService<{ input: DeleteCategoryInput }>({ input: { id } }, deleteC, 'deleteCategory')
    )
  ).then(([data]) => {
    if (data.errors && data.errors.errorType === 'g-103') {
      const modal = () => <Delete id={data.payload.input.id} />
      updateModal(dispatch, true, modal, () => { })
    }
    close()
  })
}
