import styles from './List.module.css'

/**
 *
 * This element is used when configuring each row of the List component
 * It is displayed as a column
 *
 * @usedIn - Drawer for Activity views
 *
 * @param topText - The text display on top of the component
 * @param mainTexts - The text display in the middle
 * @param greyTexts - The text display in grey
 * @param orangeTexts - The text display in orange
 * @param redTexts - The text display in red
 * @param left - The text to display
 * @param negative - A boolean who changes the style to red if true
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Title = ({
  topText,
  mainTexts,
  greyTexts,
  orangeTexts,
  redTexts,
  left,
  negative,
}: {
  topText?: string
  mainTexts?: Array<string | number>
  greyTexts?: Array<string>
  orangeTexts?: Array<string>
  redTexts?: Array<string>
  left?: boolean
  negative?: boolean
}): JSX.Element => (
  <div className={styles.titleContainer}>
    {topText && <div className={styles.text}>{topText}</div>}
    {mainTexts &&
      mainTexts.map((text, index) => (
        <div
          key={index}
          className={styles.ellipsis}
          style={{
            color: !left && negative ? 'var(--sys-color-content-danger)' : 'var(--sys-color-content-primary)',
          }}
        >
          {text}
        </div>
      ))}
    {greyTexts &&
      greyTexts.map((text, index) => (
        <div key={index} className={styles.text}>
          {text}
        </div>
      ))}
    {orangeTexts &&
      orangeTexts.map((text, index) => (
        <div
          key={index}
          className={styles.orangeText}
          style={{
            backgroundColor: left ? 'var(--yellow-light)' : '',
            fontSize: left ? '10px' : '12px',
          }}
        >
          {text}
        </div>
      ))}
    {redTexts &&
      redTexts.map((text, index) => (
        <div
          key={index}
          className={styles.text}
          style={{
            color: 'var(--sys-color-content-danger)',
          }}
        >
          {text}
        </div>
      ))}
  </div>
)

export default Title
