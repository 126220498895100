import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { TypePack } from '../../../types'
import { DashboardView, DrawerState, DrawerType, objectType } from '../../../store/types'
import { getRandomColor } from '../../../utils/color'
import { getPack } from '../../../getters/catalog/pack'
import {
  name as nameValidator,
  sellPrice as sellPriceValidator,
  packVariations as packVariationValidator,
  customFieldNumber as customFieldNumberValidator,
} from '../../../format/errors/filters/catalog/pack'
import { Stats } from '../../../types/stat'
import { customFieldsInState } from '../../../services/setting/customField'
import { TypeTab, TypeDrawer } from '../../../types/drawer'
import Detail from './drawer'
import { TabIdsCatalog } from '..'
import { currentDrawer, getDashboardView, isDrawerOpen, listCustomFieldItems, listPackItems, listPacks, listProducts, selectedCompany } from '../../../store/selector'
import DrawerMenu from '../../../components/oldComponents/drawer/menu'
import { Route } from '../../../utils/navigation'
import { getPackInfos } from '../../../services/catalog/pack'
import { DataWithErrors } from '../../../services/types'
import { drawerAddHistory, drawerResetHistory, updateDrawerState } from '../../../actions/drawer'
import DrawerBulk, { TypeQuickActions } from '../../../components/oldComponents/drawer/quickAction'
import DrawerTop from '../../../components/oldComponents/drawer/top'
import {
  ComponentDirection,
  ComponentSize,
  ComponentState,
  ComponentType,
} from '../../../components/newComponents/types'
import TabList from '../../../components/newComponents/tabList'
import { deletePack as deleteP } from '../../../graphql/custom/mutations'
import { DeletePackInput } from '../../../API'
import { callService } from '../../../services'
import { getPackStats } from '../../../graphql/custom/queries'
import Statistic from '../../../components/oldComponents/drawer/statistic'
import { createPayload } from '../../../utils/dirties'
import generateDataCy from '../../../utils/cypress'
import Divider from '../../../components/newComponents/divider'
import { displayNumber } from '../../../utils/number'

import { Duplicate, DeleteIcon } from '../../../assets/icons'

export const MenuPack = ({
  id,
  isDuplicate,
  history,
  addHistory,
  close,
}: {
  id?: string
  isDuplicate?: boolean
  history?: Array<Route>
  addHistory: (route: Route) => void
  close: () => void
}) => {
  const listCustomFields = useSelector(listCustomFieldItems)
  const { t } = useTranslation()
  const drawerIsOpen = useSelector(isDrawerOpen);
  const allPacks = useSelector(listPackItems)

  const initialState: {
    tabList: Array<TypeTab>
    detail: TypePack
    performance?: Stats
  } = {
    tabList: [
      { id: TabIdsCatalog.DETAIL, name: t('drawer.tabs.DETAIL'), state: ComponentState.ACTIVE },
      { id: TabIdsCatalog.STAT, name: t('drawer.tabs.PERFORMANCE'), state: ComponentState.DEFAULT },
    ],
    detail: {
      actions: [
        {
          id: 'duplicate',
          value: t('drawer.menu.DUPLICATE'),
        },
        { id: 'delete', value: t('drawer.menu.DELETE') },
      ],
      color: getRandomColor(),
      selected: 'color',
      customFields: customFieldsInState('PACK', listCustomFields),
      packVariations: [],
      packOptionView: false,
      isUpdateIconText: false,
      indexOfPack: -1,
      showSubmit: false,
      dirties: id && !isDuplicate ? ['id'] : ['color'],
      errors: {
        customFields: customFieldsInState('PACK', listCustomFields).map((_) => ({ value: false, message: '' })),
      },
      validators: {
        name: nameValidator,
        sellPrice: sellPriceValidator,
        packVariations: packVariationValidator,
        customFields: customFieldNumberValidator,
      },
    },
  }
  const [state, setState] = useState(initialState)
  const mobileView = useSelector(getDashboardView)
  const allProducts = useSelector(listProducts);
  const company = useSelector(selectedCompany)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({
    scope: generateDataCy({
      scope: 'catalog',
      value: 'pack',
    }),
    value: 'drawer',
  })

  const setPackInState = async (pack: any) => {
    if (pack) {
      const packVariations = await getPackInfos(pack.packVariations, company.currencyDecimals, allProducts)
      const customFields = customFieldsInState('PACK', listCustomFields, pack.customFields)

      setState({
        ...state,
        detail: {
          ...state.detail,
          id: isDuplicate ? '' : pack.id,
          name: pack.name,
          color: pack.color,
          iconText: pack.iconText,
          isUpdateIconText: pack.iconText !== pack.name.substring(0, 4),
          photo: pack.photo,
          selected: pack.photo ? 'photo' : 'color',
          sellPrice: displayNumber(pack.sellPrice, company.currencyDecimals),
          barcode: pack.barcode,
          reference: pack.reference,
          packVariations,
          customFields,
          errors: {
            customFields: customFields.map((_) => ({ value: false, message: '' })),
          },
          dirties: isDuplicate ? createPayload(pack) : state.detail.dirties,
          showSubmit: !!isDuplicate,
        },
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    if (id && drawerIsOpen) {
      const pack = allPacks.find((pack) => pack.id === id);
      setPackInState(pack);
    }
      }, [id, isDuplicate, listCustomFields, drawerIsOpen])

  const updateState = (newState: any) => setState({ ...state, detail: { ...state.detail, ...newState } })

  const ComponentToDisplay = () => {
    switch (state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id) {
      case TabIdsCatalog.DETAIL:
        return <Detail packState={state.detail} updateState={updateState} close={close} dataCy={cyContext} />
      case TabIdsCatalog.STAT:
        return (
          <Statistic
            id={state.detail.id!}
            name={state.detail.name ?? ''}
            query={getPackStats}
            queryName={'getPack'}
            type={objectType.PACK}
            close={close}
          />
        )
      default:
        return <></>
    }
  }

  const handleClickMenu = (action: string) => {
    switch (action) {
      case 'duplicate': {
        updateDrawerState(DrawerState.LOADING, dispatch)
        addHistory({
          component: MenuPack,
          params: { id: state.detail.id!, isDuplicate: true },
        })
        break
      }
      case 'delete': {
        callService<{ input: DeletePackInput }>({ input: { id: state.detail.id! } }, deleteP, 'deletePack')
        drawerResetHistory(dispatch)
        close()
        break
      }
      default:
        break
    }
  }

  return (
    <>
      {state.detail.id &&
        state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id !== TabIdsCatalog.STAT &&
        !state.detail.packOptionView ? (
        <DrawerTop
          history={state.detail.packOptionView ? [''] : history}
          name={state.detail.packOptionView ? t('catalog.pack.form.NEW_CHOICE') : state.detail.name ?? ''}
          mobileView={mobileView === DashboardView.MOBILE}
          burgerMenu={{ actions: state.detail.actions!, onClick: handleClickMenu }}
          close={() => {
            if (state.detail.packOptionView) setState({ ...state, detail: { ...state.detail, packOptionView: false } })
            else close()
          }}
          dataCy={cyContext}
        />
      ) : (
        <DrawerTop
          history={state.detail.packOptionView ? [''] : history}
          name={
            state.detail.packOptionView
              ? t('catalog.pack.form.NEW_CHOICE')
              : state.detail.id
                ? state.detail.name ?? ''
                : t('catalog.pack.form.NEW')
          }
          mobileView={mobileView === DashboardView.MOBILE}
          close={() => {
            if (state.detail.packOptionView) setState({ ...state, detail: { ...state.detail, packOptionView: false } })
            else close()
          }}
          dataCy={cyContext}
        />
      )}
      {id && !isDuplicate && !state.detail.packOptionView && (
        <>
          <TabList
            tabList={state.tabList}
            onClick={(newTab: TypeTab) => {
              if (newTab.id !== TabIdsCatalog.DETAIL) updateDrawerState(DrawerState.LOADING, dispatch)
              setState({
                ...state,
                tabList: state.tabList.map((sTab) => ({
                  ...sTab,
                  state: newTab.id === sTab.id ? ComponentState.ACTIVE : ComponentState.DEFAULT,
                })),
              })
            }}
            size={ComponentSize.LARGE}
            dataCy={cyContext}
          />
          <Divider type={ComponentType.FILL} direction={ComponentDirection.HORIZONTAL} />
        </>
      )}
      <ComponentToDisplay />
    </>
  )
}

const deletePacks = (selected: { id: string }[], close: () => void) => () => {
  Promise.all(
    selected.map(({ id }: { id: string }) =>
      callService<{ input: DeletePackInput }>({ input: { id } }, deleteP, 'deletePack').then(() => close())
    )
  )
}

const Index = ({ selected = [], close }: TypeDrawer) => {
  const drawer = useSelector(currentDrawer)
  const dispatch = useDispatch()
  const mobileView = useSelector(getDashboardView)
  const { t } = useTranslation()
  const allPacks = useSelector(listPacks);


  useEffect(() => {
    selected.length === 0
      && allPacks.items.items.filter(p => p.selected).length === 0
      && drawer.type === DrawerType.BULK
      && !drawer.navigation.current?.params.isDuplicate
      && close()
    if (selected.length !== allPacks.items.items.filter(p => p.selected).length) {
            selected = allPacks.items.items.filter(p => p.selected);
    }
      }, [selected])

  const getBulkActions = () => {
    let actions: TypeQuickActions = []
    if (selected.length === 1)
      actions = [
        {
          id: 'duplicate',
          icon: Duplicate,
          title: t('catalog.pack.bulk.duplicate.TITLE'),
          text: t('catalog.pack.bulk.duplicate.SUBTITLE'),
          onClick: () => {
            drawerAddHistory(
              {
                component: MenuPack,
                params: {
                  id: selected[0].id!,
                  isDuplicate: true,
                },
              },
              dispatch
            )
          },
        },
      ]
    actions = [
      ...actions,
      {
        id: 'delete',
        icon: DeleteIcon,
        title: t('catalog.pack.bulk.DELETE'),
        onClick: deletePacks(selected, close),
      },
    ]
    return actions
  }

  return (
    <DrawerMenu
      component={
        drawer.type === DrawerType.BULK
          ? () =>
            DrawerBulk({
              bulkTitle: t('catalog.pack.bulk.BUNDLED_SHARES'),
              title: t('catalog.pack.bulk.SELECTED', {
                number: selected.length,
                plural: selected.length > 1 ? 's' : '',
              }),
              actions: getBulkActions(),
              mobileView: mobileView === DashboardView.MOBILE,
              close,
            })
          : MenuPack
      }
    />
  )
}

export default Index
