import { getObservable } from '../../services/graphql'
import { getCompanyID } from '../../services/localStorage'
import { onCreateBackgroundJob, onUpdateBackgroundJob } from '../../graphql/subscriptions'
import { callService } from '../../services'
import { GetBackgroundJobQueryVariables } from '../../API'
import { updateListElement } from '../../actions/list'
import { objectType } from '../../store/types'
import { getBackgroundJob as get } from '../../graphql/queries'

const getBackgroundJob = async (id: string, dispatch: any) => {
  const backgroundJob = await callService<GetBackgroundJobQueryVariables>({ id }, get, 'getBackgroundJob')

  if (backgroundJob.data) {
    updateListElement({
      element: backgroundJob.data,
      dispatch,
      type: objectType.IMPORT,
    })
  }
  return backgroundJob
}

let onCreateBackgroundJobSubscription: any
let onUpdateBackgroundJobSubscription: any

const subscribeBackgroundJobs = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
  }
  if (!onCreateBackgroundJobSubscription)
    onCreateBackgroundJobSubscription = getObservable(onCreateBackgroundJob, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateBackgroundJob) {
          updateListElement({
            element: eventData.data.onCreateBackgroundJob,
            dispatch,
            type: objectType.IMPORT,
          })
        }
      },
    })
  if (!onUpdateBackgroundJobSubscription)
    onUpdateBackgroundJobSubscription = getObservable(onUpdateBackgroundJob, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateBackgroundJob) {
          updateListElement({
            element: eventData.data.onUpdateBackgroundJob,
            dispatch,
            type: objectType.IMPORT,
          })
        }
      },
    })
}

const unSubscribeBackgroundJobs = () => {
  if (onCreateBackgroundJobSubscription) {
    onCreateBackgroundJobSubscription.unsubscribe()
    onCreateBackgroundJobSubscription = null
  }

  if (onUpdateBackgroundJobSubscription) {
    onUpdateBackgroundJobSubscription.unsubscribe()
    onUpdateBackgroundJobSubscription = null
  }
}

export { subscribeBackgroundJobs, unSubscribeBackgroundJobs }
