import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getDashboardView, getIsIOS } from '../../store/selector'
import SplitView from './splitView'
import { findErrorsInState, setErrorsInState } from '../../format/errors'
import { TypeFormError, ValidationFunction } from '../../format/errors/types'
import { DashboardView } from '../../store/types'
import {
  name as nameValidator,
  email as mailValidator,
  password as passwordValidator,
  phone as phoneValidator,
} from '../../format/errors/filters/connection'
import generateDataCy from '../../utils/cypress'
import {
  CheckPhoneVerificationCodeMutationVariables,
  CreateAccountInput,
  CreateCompanyInput,
  CreateShopInput,
  CreateSlotInput,
  SendPhoneVerificationCodeMutationVariables,
  SendSlotEmailInput,
  UpdateAccountInput,
  UpdateCompanyInput,
} from '../../API'
import {
  createAccount,
  createCompany,
  createShop,
  createSlot,
  sendSlotEmail,
  updateAccount,
} from '../../graphql/custom/mutations'
import { checkPhoneVerificationCode, sendPhoneVerificationCode, updateCompany } from '../../graphql/mutations'
import { callService } from '../../services'

import { handleBlur } from '../../format/form'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../components/newComponents/types'
import ProgressBar from '../../components/newComponents/progressBar'
import { ArrowBackIOS, CheckBoxChecked, CheckBoxUnchecked, EyeHide, EyeShow } from '../../assets/icons'
import Button from '../../components/newComponents/button'
import LogoTactill from '../../components/newComponents/logoTactill'
import AttentionBox from '../../components/newComponents/attentionBox'
import PhoneInput from '../../components/newComponents/phoneInput'
import { getIcon } from '../../components/newComponents/icon'
import { getRandomColor } from '../../utils/color'
import { setToken } from '../../services/graphql'
import { env, login, signup } from '../../services/auth'
import { parseName, parseAccountName } from '../../utils/string'
import ErrorLabel from '../../components/oldComponents/error'
import Input from '../../components/newComponents/input'
import IconTouch from '../../components/newComponents/icons/iconTouch'
import TextLink from '../../components/newComponents/textLink'
import { getInvitation } from '../../services/localStorage'

import { SignUp as SignUpIllustration } from '../../assets/illustrations'
import styles from './Connection.module.css'
import stylesInput from '../../components/newComponents/input/Input.module.css'
import stylesApp from '../../App.module.css'

type State = {
  step: number
  firstName?: string
  lastName?: string
  email?: string
  accountID?: string
  companyName?: string
  companyID?: string
  shopID?: string
  password?: string
  passwordType: string
  phone?: string
  phoneCode?: string
  slotID?: string
  selectedTOU: boolean
  showSubmit: boolean
  validators: {
    email?: ValidationFunction
    password?: ValidationFunction
    firstName?: ValidationFunction
    lastName?: ValidationFunction
    companyName?: ValidationFunction
    phone?: ValidationFunction
  }
  errors: {
    global?: TypeFormError
    email?: TypeFormError
    password?: TypeFormError
    firstName?: TypeFormError
    lastName?: TypeFormError
    companyName?: TypeFormError
    phone?: TypeFormError
  }
}

const Form = ({ hiddenRight }: { hiddenRight: (value: boolean) => void }) => {
  const initialState: State = {
    step: 0,
    passwordType: 'password',
    selectedTOU: false,
    showSubmit: false,
    validators: {
      email: mailValidator,
      password: passwordValidator,
      firstName: nameValidator,
      lastName: nameValidator,
      companyName: nameValidator,
      phone: phoneValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const dashboardView = useSelector(getDashboardView)
  const isIOS = useSelector(getIsIOS)
  const dispatch = useDispatch()
  const { getAccessTokenSilently, logout } = useAuth0()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const cyContext = generateDataCy({ scope: 'connection', value: 'signUp' })

  const suffix = env === 'production' ? '' : env === 'dev' ? '.develop' : `.${env}`
  const APP_URL = `infinite.caisse${suffix}://initialise_slot?id=${state.slotID}`

  const handleError = (res: any) => {
    const newState = setErrorsInState(state, res.errors)
    setState({ ...state, showSubmit: false, errors: newState.errors })
  }

  const handleClickIcon = () =>
    setState({ ...state, passwordType: state.passwordType === 'password' ? 'text' : 'password' })

  const handleChange = (field: string) => (event: any) => {
    state.errors.global = undefined
    // @ts-ignore
    state.errors[field] = null
    const showSubmit = !findErrorsInState(state.errors) && state.selectedTOU
    setState({ ...state, [field]: event, showSubmit })
  }

  const callCompany = () => {
    if (getInvitation()) setState({ ...state, showSubmit: false, step: state.step + 1 })
    else {
      if (!state.companyID) {
        callService<{ input: CreateCompanyInput }>(
          { input: { name: state.companyName! } },
          createCompany,
          'createCompany',
          true
        ).then((data) => {
          if (data.data && !data.errors) {
            setState({ ...state, companyID: data.data.id, showSubmit: false, step: state.step + 1 })
          } else handleError(data)
        })
      } else {
        callService<{ input: UpdateCompanyInput }>(
          { input: { id: state.companyID, name: state.companyName! } },
          updateCompany,
          'updateCompany',
          true
        ).then((data) => {
          if (data.data && !data.errors) {
            setState({ ...state, showSubmit: false, step: state.step + 1 })
          } else handleError(data)
        })
      }
    }
  }

  const callSendPhoneCode = () => {
    callService<SendPhoneVerificationCodeMutationVariables>(
      { phone: '+' + state.phone!.replace('+', '') },
      sendPhoneVerificationCode,
      'sendPhoneVerificationCode',
      true
    ).then((data) => {
      if (data.data && !data.errors) {
        setState({ ...state, showSubmit: false, step: state.step + 1 })
      } else handleError(data)
    })
  }

  const handleSubmit = () => {
    if (state.step === 0) {
      state.showSubmit = false
      setState(handleBlur(state, 'email'))
      setState(handleBlur(state, 'password'))

      if (!findErrorsInState(state.errors) && state.selectedTOU) {
        signup({ email: state.email!, password: state.password! })
          .then((res: any) => {
            setToken(res.accessToken, getAccessTokenSilently, logout, dispatch)
            hiddenRight(false)
            setState({ ...state, step: state.step + 1 })
          })
          .catch(() => {
            setState({
              ...state,
              errors: { email: { value: true, message: t('connection.signUp.IMPOSSIBLE') } },
            })
          })
      }
    }
    if (state.step === 1) {
      state.showSubmit = false
      setState(handleBlur(state, 'firstName'))
      setState(handleBlur(state, 'lastName'))
      !getInvitation() && setState(handleBlur(state, 'companyName'))

      if (!findErrorsInState(state.errors) && state.firstName && state.lastName) {
        if (!state.accountID) {
          callService<{ input: CreateAccountInput }>(
            {
              input: {
                firstName: parseAccountName(state.firstName),
                lastName: parseName(state.lastName),
                color: getRandomColor(),
              },
            },
            createAccount,
            'createAccount',
            true
          ).then((data) => {
            if (data.data && !data.errors) {
              state.accountID = data.data.id
              callCompany()
            } else handleError(data)
          })
        } else {
          callService<{ input: UpdateAccountInput }>(
            {
              input: {
                firstName: parseAccountName(state.firstName),
                lastName: parseName(state.lastName),
              },
            },
            updateAccount,
            'updateAccount',
            true
          ).then((data) => {
            if (data.data && !data.errors) {
              callCompany()
            } else handleError(data)
          })
        }
      }
    }
    if (state.step === 2) {
      setState({ ...handleBlur(state, 'phone'), showSubmit: false })

      if (!findErrorsInState(state.errors) && state.phone) {
        if (!state.shopID) {
          callService<{ input: UpdateAccountInput }>(
            {
              input: {
                phone: '+' + state.phone.replace('+', ''),
              },
            },
            updateAccount,
            'updateAccount',
            true
          ).then((aData) => {
            if (getInvitation()) callSendPhoneCode()
            else if (aData.data && !aData.errors && state.companyID && state.companyName) {
              callService<{ input: CreateShopInput }>(
                {
                  input: {
                    companyID: state.companyID,
                    name: parseName(state.companyName),
                    brandName: parseName(state.companyName),
                  },
                },
                createShop,
                'createShop',
                true
              ).then((sData) => {
                if (sData.data && !sData.errors) {
                  state.shopID = sData.data.id
                  callSendPhoneCode()
                } else handleError(sData)
              })
            }
          })
        } else {
          callSendPhoneCode()
        }
      }
    }
    if (state.step === 3) {
      setState({ ...handleBlur(state, 'phoneCode'), showSubmit: false })

      if (!findErrorsInState(state.errors) && state.phone && state.phoneCode) {
        callService<CheckPhoneVerificationCodeMutationVariables>(
          { phone: '+' + state.phone.replace('+', ''), code: state.phoneCode },
          checkPhoneVerificationCode,
          'checkPhoneVerificationCode',
          true
        ).then((data) => {
          if (getInvitation()) setState({ ...state, step: state.step + 1 })
          else if (data.data && !data.errors && state.shopID) {
            callService<{ input: CreateSlotInput }>(
              { input: { shopID: state.shopID, name: t('settings.slot.SLOT') } },
              createSlot,
              'createSlot',
              true
            ).then((sData) => {
              if (sData.data && !sData.errors && state.email) {
                callService<{ input: SendSlotEmailInput }>(
                  { input: { id: sData.data.id, email: state.email } },
                  sendSlotEmail,
                  'sendSlotEmail',
                  true
                ).then((eData) => {
                  if (eData.data && !eData.errors) {
                    setState({ ...state, slotID: sData.data.id, step: state.step + 1 })
                  } else handleError(data)
                })
              } else handleError(data)
            })
          } else handleError(data)
        })
      }
    }
  }

  const goBack = () => {
    state.step -= 1

    if (state.step === 0) {
      hiddenRight(true)
      setState(initialState)
    } else {
      hiddenRight(false)
      setState({ ...state, errors: {}, showSubmit: true })
    }
  }

  const getTemplateColumns = () => {
    if (state.step === 0 && dashboardView === DashboardView.DESKTOP) return 'repeat(12, 1fr)'
    if (dashboardView === DashboardView.DESKTOP || dashboardView === DashboardView.TABLET) return 'repeat(6, 1fr)'
    if (dashboardView === DashboardView.MOBILE) return 'repeat(4, 1fr)'
  }

  const getGridColumn = () => {
    if (state.step === 0) {
      if (dashboardView === DashboardView.DESKTOP) return '5 / span 4'
      if (dashboardView === DashboardView.TABLET) return '3 / span 2'
      if (dashboardView === DashboardView.MOBILE) return '1 / span 4'
    }
    if (dashboardView === DashboardView.DESKTOP || dashboardView === DashboardView.TABLET) return '2 / span 4'
    if (dashboardView === DashboardView.MOBILE) return '1 / span 4'
  }

  return (
    <div
      className={stylesApp.grid}
      style={{
        alignItems: state.step === 0 && dashboardView !== DashboardView.MOBILE ? 'center' : '',
        gridTemplateColumns: getTemplateColumns(),
      }}
    >
      {state.step > 0 && state.step < 4 && (
        <div className={styles.previous}>
          <Button
            title={t('connection.signUp.PREVIOUS')}
            LeadIcon={ArrowBackIOS}
            onClick={goBack}
            size={ComponentSize.MEDIUM}
            type={ComponentType.TERTIARY}
          />
        </div>
      )}
      <div
        className={styles.containerForm}
        style={{
          marginTop:
            state.step !== 0
              ? isIOS
                ? '0em'
                : dashboardView === DashboardView.DESKTOP
                ? '5em'
                : dashboardView === DashboardView.TABLET
                ? '4em'
                : ''
              : undefined,
          gridColumn: getGridColumn(),
        }}
      >
        {state.step > 0 && state.step < 4 && (
          <div className={styles.steps}>
            <ProgressBar size={ComponentSize.MEDIUM} type={ComponentType.LINEAR} progress={state.step * 33} />
            <div className={styles.previousStep}>{t('connection.signUp.STEP', { step: state.step })}</div>
          </div>
        )}
        {state.errors?.global && <ErrorLabel text={state.errors.global.message} />}
        {state.step === 0 && (
          <>
            <LogoTactill type={ComponentType.LOGOICONTEXT} />
            <div className={styles.middleArea}>
              <div>
                <div className={styles.title}>{t('connection.signUp.WELCOME')}</div>
                <div className={styles.subTitle} style={{ marginTop: 'var(--sys-size-2)' }}>
                  {t('connection.signUp.JOIN')}
                </div>
              </div>
              <div className={styles.form}>
                <Input
                  label={t('connection.signUp.MAIL')}
                  value={state.email}
                  onChange={handleChange('email')}
                  onBlur={() => setState(handleBlur(state, 'email'))}
                  style={{
                    status: state.errors?.email?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                  }}
                  helperText={state.errors.email?.value ? state.errors.email.message : undefined}
                  dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
                />
                <Input
                  label={t('connection.signUp.PASSWORD')}
                  type={state.passwordType}
                  value={state.password}
                  onChange={handleChange('password')}
                  onBlur={() => setState(handleBlur(state, 'password'))}
                  style={{
                    status: state.errors?.password?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                    trailIcons: getIcon({
                      icon: state.passwordType === 'password' ? EyeHide : EyeShow,
                      onClick: handleClickIcon,
                    }),
                  }}
                  helperText={state.errors.password?.value ? state.errors.password.message : undefined}
                  dataCy={generateDataCy({ scope: cyContext, value: 'password' })}
                />
                <AttentionBox text={t('connection.signUp.HELP_PASSWORD')} status={ComponentStatus.INFO} />
              </div>
              <div
                className={styles.gcs}
                onClick={() => setState({ ...state, selectedTOU: !state.selectedTOU, showSubmit: true })}
              >
                <IconTouch
                  Icon={state.selectedTOU ? CheckBoxChecked : CheckBoxUnchecked}
                  size={ComponentSize.LARGE}
                  color={
                    state.selectedTOU ? 'var(--sys-color-content-interactive)' : 'var(--sys-color-content-secondary)'
                  }
                  dataCy={generateDataCy({ scope: cyContext, value: 'checkbox' })}
                />
                <div className={styles.text}>{t('connection.signUp.TOU')}</div>
              </div>
            </div>
            <div className={styles.form}>
              <div
                className={styles.submitButton}
                style={{
                  width: dashboardView !== DashboardView.MOBILE ? '88px' : '100%',
                  bottom: dashboardView === DashboardView.MOBILE ? '40px' : '0',
                }}
              >
                <Button
                  title={t('connection.signUp.NEXT')}
                  size={ComponentSize.LARGE}
                  type={ComponentType.PRIMARY}
                  state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
                  onClick={handleSubmit}
                  dataCy={generateDataCy({ scope: cyContext, value: 'submit' })}
                />
              </div>
              <div
                style={{
                  position: dashboardView === DashboardView.MOBILE ? 'absolute' : 'inherit',
                  bottom: dashboardView === DashboardView.MOBILE ? '0' : '',
                }}
              >
                <TextLink
                  text={t('connection.signUp.CONNECT')}
                  handleClick={() => navigate('/login')}
                  size={ComponentSize.SMALL}
                />
              </div>
            </div>
          </>
        )}
        {state.step === 1 && (
          <>
            <div className={styles.middleArea}>
              <div className={styles.subTitle} style={{ color: 'var(--sys-color-content-primary)' }}>
                {t('connection.signUp.FILL_ACCOUNT')}
              </div>
              <div className={styles.form}>
                <Input
                  label={t('connection.signUp.FIRST_NAME')}
                  value={state.firstName}
                  onChange={handleChange('firstName')}
                  onBlur={() => setState(handleBlur(state, 'firstName'))}
                  onSubmit={handleSubmit}
                  style={{
                    status: state.errors?.firstName?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                  }}
                  helperText={state.errors.firstName?.value ? state.errors.firstName.message : undefined}
                  dataCy={generateDataCy({ scope: cyContext, value: 'firstName' })}
                />
                <Input
                  label={t('connection.signUp.LAST_NAME')}
                  value={state.lastName}
                  onChange={handleChange('lastName')}
                  onBlur={() => setState(handleBlur(state, 'lastName'))}
                  onSubmit={handleSubmit}
                  style={{
                    status: state.errors?.lastName?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                  }}
                  helperText={state.errors.lastName?.value ? state.errors.lastName.message : undefined}
                  dataCy={generateDataCy({ scope: cyContext, value: 'lastName' })}
                />
                {!getInvitation() && (
                  <Input
                    label={t('connection.signUp.COMPANY_NAME')}
                    value={state.companyName}
                    onChange={handleChange('companyName')}
                    onBlur={() => setState(handleBlur(state, 'companyName'))}
                    onSubmit={handleSubmit}
                    style={{
                      status: state.errors?.companyName?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                    }}
                    helperText={state.errors.companyName?.value ? state.errors.companyName.message : undefined}
                    dataCy={generateDataCy({
                      scope: cyContext,
                      value: 'companyName',
                    })}
                  />
                )}
              </div>
            </div>
            <div
              className={styles.submitButton}
              style={{ width: dashboardView !== DashboardView.MOBILE ? '88px' : '100%' }}
            >
              <Button
                title={t('connection.signUp.NEXT')}
                size={ComponentSize.LARGE}
                type={ComponentType.PRIMARY}
                state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
                onClick={handleSubmit}
              />
            </div>
          </>
        )}
        {state.step === 2 && (
          <>
            <div className={styles.middleArea}>
              <div className={styles.subTitle} style={{ color: 'var(--sys-color-content-primary)' }}>
                {t('connection.signUp.PHONE_TITLE')}
              </div>
              <div className={styles.form}>
                <AttentionBox title={t('connection.signUp.PHONE_BOX')} status={ComponentStatus.INFO} />
                
                <PhoneInput
                  phone={state.phone}
                  onChange={(phone: any) => handleChange('phone')(phone)}
                  status={state.errors.phone?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT}
                  helperText={state.errors.phone?.message}
                />
              </div>
            </div>
            <div
              className={styles.submitButton}
              style={{ width: dashboardView !== DashboardView.MOBILE ? '147px' : '100%' }}
            >
              <Button
                title={t('connection.signUp.SEND_CODE')}
                size={ComponentSize.LARGE}
                type={ComponentType.PRIMARY}
                state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
                onClick={handleSubmit}
              />
            </div>
          </>
        )}
        {state.step === 3 && (
          <>
            <div className={styles.middleArea}>
              <div className={styles.subTitle} style={{ color: 'var(--sys-color-content-primary)' }}>
                {t('connection.signUp.PHONE_CODE_TITLE')}
              </div>
              <Input
                label={t('connection.signUp.PHONE_CODE')}
                value={state.phoneCode}
                onChange={handleChange('phoneCode')}
                onBlur={() => setState(handleBlur(state, 'phoneCode'))}
                onSubmit={handleSubmit}
                style={{
                  // status: state.errors?.phoneCode?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                  status: ComponentStatus.DEFAULT,
                }}
                // helperText={state.errors.phoneCode?.value ? state.errors.phoneCode.message : undefined}
                dataCy={generateDataCy({ scope: cyContext, value: 'phoneCode' })}
              />
              <div>
                <TextLink
                  text={t('connection.signUp.NOT_RECEIVE_CODE')}
                  handleClick={() =>
                    callService<SendPhoneVerificationCodeMutationVariables>(
                      { phone: '+' + state.phone!.replace('+', '') },
                      sendPhoneVerificationCode,
                      'sendPhoneVerificationCode',
                      true
                    )
                  }
                  size={ComponentSize.SMALL}
                />
                <TextLink
                  text={t('connection.signUp.WRONG_PHONE')}
                  handleClick={() => setState({ ...state, step: 2 })}
                  size={ComponentSize.SMALL}
                />
              </div>
            </div>
            <div
              className={styles.submitButton}
              style={{ width: dashboardView !== DashboardView.MOBILE ? '141px' : '100%' }}
            >
              <Button
                title={t('connection.signUp.VALID_CODE')}
                size={ComponentSize.LARGE}
                type={ComponentType.PRIMARY}
                state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
                onClick={handleSubmit}
              />
            </div>
          </>
        )}
        {state.step === 4 && (
          <>
            <div>
              <div className={styles.title}>{t('connection.postSignUp.SUCCESS')}</div>
              <div className={styles.subTitle}>{t('connection.postSignUp.SUCCESS_CREATE')}</div>
              {isIOS ? (
                <div className={styles.signUpSubTitle}>
                  {t('connection.postSignUp.SEND_MAIL', { email: state.email })}
                </div>
              ) : (
                ''
              )}
            </div>
            <div>{}</div>
            <div
              className={styles.submitButton}
              style={{ width: dashboardView !== DashboardView.MOBILE ? '261px' : '100%' }}
            >
              <Button
                title={isIOS ? t('connection.postSignUp.APP') : t('connection.postSignUp.DASHBOARD')}
                size={ComponentSize.LARGE}
                type={ComponentType.PRIMARY}
                state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
                onClick={() => {
                  if (isIOS) {
                    return window.location.replace(APP_URL)
                  }
                  login({ email: state.email!, password: state.password! })
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const SignUp = () => {
  const [state, setState] = useState(true)

  return <SplitView left={<Form hiddenRight={setState} />} right={<SignUpIllustration />} hiddenRight={state} />
}

export default SignUp
