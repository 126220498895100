const ToggleOn = ({ color }: { color?: string }) => (
  <svg width="100%" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1614 7.59961H7.16138C4.40138 7.59961 2.16138 9.83961 2.16138 12.5996C2.16138 15.3596 4.40138 17.5996 7.16138 17.5996H17.1614C19.9214 17.5996 22.1614 15.3596 22.1614 12.5996C22.1614 9.83961 19.9214 7.59961 17.1614 7.59961ZM17.1614 15.5996C15.5014 15.5996 14.1614 14.2596 14.1614 12.5996C14.1614 10.9396 15.5014 9.59961 17.1614 9.59961C18.8214 9.59961 20.1614 10.9396 20.1614 12.5996C20.1614 14.2596 18.8214 15.5996 17.1614 15.5996Z"
      fill={color}
    />
  </svg>
)

export default ToggleOn
