import { Route, Routes } from 'react-router-dom'

import Shops from './shop'
import Slots from './slot'
import Users from './user'
import ListTaxes from './tax'
import ListCustomFields from './customField'
import ListTags from './tag'
import ListPaymentMethods from './paymentMethod'
import Import from './import'
import Stripe from './stripe'

const SettingRoutes = () => (
  <Routes>
    <Route path="shops" element={<Shops />} />
    <Route path="slots" element={<Slots />} />
    <Route path="users" element={<Users />} />
    <Route path="customFields" element={<ListCustomFields />} />
    <Route path="tags" element={<ListTags />} />
    <Route path="taxes" element={<ListTaxes />} />
    <Route path="paymentMethods" element={<ListPaymentMethods />} />
    <Route path="stripe" element={<Stripe />} />
    <Route path="import" element={<Import />} />
  </Routes>
)

export default SettingRoutes
