import { CashbookState } from '../../API';
import { getSaleInfos } from '../../getters/activity/sale';
import { getShopName } from '../../getters/setting/shop';
import { List } from '../../store/types';

type cashbookType = {
  id: string;
  number: number;
  state: CashbookState;
};

const getExtraInfos = (
  payment: any,
): Promise<{ shopName: string; saleNumber: number; saleTotal: number; cashbook: cashbookType }> =>
  Promise.all([getShopName(payment.shopID), getSaleInfos(payment.saleID)]).then(([dataShop, dataSale]) => {
    console.log(dataShop, 'dataShop');
    console.log(dataSale, 'dataSale');

    return {
      shopName: dataShop.data.name,
      saleNumber: dataSale.data.number/* ?? dataSale.data.id.substring(0, 4) + '...'*/,
      saleTotal: dataSale.data.total,
      cashbook: dataSale.data.cashbook,
    };
  });

/**
 *
 * This method calls the store for extra informations about a payment used in a cashbook
 * It gets payment method infos and returns them
 *
 * @param payments - List of payments
 * @param allPaymentMethods - List of paymentMethods from redux's store
 *
 * @returns - A promise of cashbook payment infos
 *
 * @author - both Jennifer Charlois and David Faure
 *
 */
const getPaymentsInfos = (payments: Array<{ id: string }>, allPaymentMethods: List) => {
  return payments.map((payment: any) => {
    const paymentMethod = allPaymentMethods.items.items.find(pm => pm.id === payment.id);
    return {
      type: paymentMethod.type,
      number: payment.detail.quantity,
      name: paymentMethod.name,
      total: payment.detail.amount,
      color: paymentMethod.color,
    };
  });
};

export { getPaymentsInfos, getExtraInfos };
