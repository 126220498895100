import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { closeAndResetModal, updateFlatFile } from '../../../../actions/modal';
import { ComponentSize, ComponentState, ComponentType } from '../../../../components/newComponents/types';
import Input from '../../../../components/newComponents/input';
import generateDataCy from '../../../../utils/cypress';
import Button from '../../../../components/newComponents/button';
import TypeImport from '../../../../types/setting/import';
import UniqueDropDown from '../../../../components/newComponents/dropDown/unique';
import Migration from './migration';
import { GetFlatfileWorkbookConfigQueryVariables } from '../../../../API';
import { callService } from '../../../../services';
import { getCompanyID, getTestMode } from '../../../../services/localStorage';
import { getFlatfileWorkbookConfig } from '../../../../graphql/queries';

import { KeyboardArrowDown } from '../../../../assets/icons';
import styles from '../../../../components/newComponents/modal/Modal.module.css';

const ImportModal = () => {
  const initialState: TypeImport = {
    next: false,
    type: null,
    title: '',
    source: [
      { name: 'IMPORT', selected: false },
      { name: 'MIGRATION', selected: false },
    ],
    showSubmit: false,
    show: false,
    flatfileData: null,
  };
  const [state, setState] = useState(initialState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cyContext = generateDataCy({ scope: 'settings', value: 'import' });

  useEffect(() => updateTitle(t('settings.import.modal.NEW')), []);

  const updateTitle = (title: string) => setState({ ...state, title });

  const getConfV3 = async () => {
    const { data: spaceProps } = await callService<GetFlatfileWorkbookConfigQueryVariables>(
      {
        companyID: getCompanyID(),
        test: getTestMode()
        // use metadata to initialise inventory movement import
        // metadata: JSON.stringify({
        //   originID: '...',
        //   destinationID: '...',
        // }),
      },
      getFlatfileWorkbookConfig,
      'getFlatfileWorkbookConfig',
    );

    const flatFileData = { spaceProps: JSON.parse(spaceProps) };

    updateFlatFile(true, flatFileData, dispatch);
    closeAndResetModal(dispatch);
  };

  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalTitle}>{state.title}</div>
        {!state.next && (
          <>
            <div className={styles.modalContent}>
              <UniqueDropDown
                parentID={'selectSource'}
                popUpID={'selectPopUpSource'}
                Element={
                  <Input
                    label={t('settings.import.modal.SOURCE')}
                    value={
                      state.source.find(src => src.selected) &&
                      t('settings.import.modal.source.' + state.source.find(src => src.selected).name)
                    }
                    style={{
                      trailIcons: [{ icon: KeyboardArrowDown }],
                      width: '298px',
                      readOnly: true,
                    }}
                    dataCy={generateDataCy({ scope: cyContext, value: 'source' })}
                  />
                }
                items={state.source.map(source => ({
                  id: source.name,
                  value: t('settings.import.modal.source.' + source.name),
                  onClick: (source: any) =>
                    setState({
                      ...state,
                      source: state.source.map(src => ({ ...src, selected: source === src.name })),
                      showSubmit: true,
                    }),
                }))}
                dataCy={generateDataCy({ scope: cyContext, value: 'source' })}
              />
            </div>
            <div className={styles.modalButtons}>
              <Button
                title={t('button.CANCEL')}
                size={ComponentSize.MEDIUM}
                type={ComponentType.TERTIARY}
                onClick={() => closeAndResetModal(dispatch)}
              />
              <Button
                title={t('button.NEXT')}
                size={ComponentSize.MEDIUM}
                type={ComponentType.PRIMARY}
                state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
                onClick={() => {
                  if (state.source.find(src => src.selected).name === 'MIGRATION') setState({ ...state, next: true });
                  else getConfV3();
                }}
                dataCy={'submit'}
              />
            </div>
          </>
        )}
        {state.next && <Migration updateTitle={updateTitle} />}
      </div>
    </>
  );
};

export default ImportModal;