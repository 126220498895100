import { createInput } from '../../../../../utils/dirties'
import { TypeDiscount } from '../../../../../types'
import { getCompanyID } from '../../../../../services/localStorage'
import { callService } from '../../../../../services'
import { CreateDiscountInput } from '../../../../../API'
import { createDiscount } from '../../../../../graphql/custom/mutations'

const callDiscountCreation = async (payload: TypeDiscount, decimals: number) => {
  const input: any = createInput(payload, decimals)

  if (input) {
    return callService<{ input: CreateDiscountInput }>(
      { input: { ...input, catalogID: getCompanyID() } },
      createDiscount,
      'createDiscount'
    )
  }
  return null
}

export default callDiscountCreation
