import { ComponentErrorText } from '../../components/newComponents/types';
import errors from './fr.json';

//! temporarily for phone need better error management later
const findField = (field: string, message?: string) => {
  if (field) {
    if (field === "firstName, lastName, email, phone") return 'name';
    return field;
  }
  
  if (message === "Slot with this name already exists") {
    // can not believe that I've to do this...
    return 'global'
  }

  if (message?.includes('phone')) return 'phone';
  if (message?.includes('name')) return 'name';

  return 'global';
};

const APIErrorMessageToHumanMessage = (message: string, code?: string) => {
  if (code) {
    // @ts-ignore
    const text = errors[code];
    if (text) return text;
    return message;
  }

  if (message.includes("has an invalid value")) {
    return ComponentErrorText.INVALIDE_FORMAT
  }

  return message;
};

export { findField, APIErrorMessageToHumanMessage };
