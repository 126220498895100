const Cash = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1614 16H18.1614C18.7114 16 19.1614 15.55 19.1614 15V9C19.1614 8.45 18.7114 8 18.1614 8H15.1614C14.6114 8 14.1614 8.45 14.1614 9V15C14.1614 15.55 14.6114 16 15.1614 16ZM16.1614 10H17.1614V14H16.1614V10ZM9.16138 16H12.1614C12.7114 16 13.1614 15.55 13.1614 15V9C13.1614 8.45 12.7114 8 12.1614 8H9.16138C8.61138 8 8.16138 8.45 8.16138 9V15C8.16138 15.55 8.61138 16 9.16138 16ZM10.1614 10H11.1614V14H10.1614V10ZM6.16138 8C5.61138 8 5.16138 8.45 5.16138 9V15C5.16138 15.55 5.61138 16 6.16138 16C6.71138 16 7.16138 15.55 7.16138 15V9C7.16138 8.45 6.71138 8 6.16138 8ZM2.16138 6V18C2.16138 19.1 3.06138 20 4.16138 20H20.1614C21.2614 20 22.1614 19.1 22.1614 18V6C22.1614 4.9 21.2614 4 20.1614 4H4.16138C3.06138 4 2.16138 4.9 2.16138 6ZM19.1614 18H5.16138C4.61138 18 4.16138 17.55 4.16138 17V7C4.16138 6.45 4.61138 6 5.16138 6H19.1614C19.7114 6 20.1614 6.45 20.1614 7V17C20.1614 17.55 19.7114 18 19.1614 18Z"
      fill={color}
    />
  </svg>
)

export default Cash
