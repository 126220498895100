import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { closeAndResetModal } from '../../../actions/modal'
import Button from '../button'
import { ComponentSize, ComponentType } from '../types'

import { Cactus } from '../../../assets/illustrations'
import styles from './Modal.module.css'
import generateDataCy from '../../../utils/cypress'

/**
 *
 * This element displays a modal with a specific error design
 *
 * @usedIn - Everywhere
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ErrorModal = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cyContext = generateDataCy({ scope: 'modal', value: 'error' })

  return (
    <div className={styles.modal} data-cy={cyContext}>
      <div className={styles.modalTitle}>{t('modal.error.TITLE')}</div>
      <div className={styles.modalContent}>
        <div style={{ marginLeft: '30%' }}>
          <Cactus />
        </div>
        <div className={styles.modalError}>{t('modal.error.ERROR')}</div>
      </div>
      <Button
        title={t('modal.error.ERROR_BUTTON')}
        size={ComponentSize.LARGE}
        type={ComponentType.PRIMARY}
        onClick={() => closeAndResetModal(dispatch)}
        dataCy={generateDataCy({ scope: cyContext, value: 'close' })}
      />
    </div>
  )
}

export default ErrorModal
