import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { objectType, DashboardView, MenuState } from '../../../../../store/types'
import { changeConf } from '../../../../../actions/cols'
import { updateModal } from '../../../../../actions/modal'
import { drawerAddHistory } from '../../../../../actions/drawer'
import { getDashboardView, listProducts } from '../../../../../store/selector'
import { switchFilteredItemsToItems, clearAllFilters } from '../../../../../actions/requester'
import { MenuProduct } from '..'
import Delete from './delete'
import DrawerBulk, { TypeQuickActions } from '../../../../../components/oldComponents/drawer/quickAction'
import { DeleteProductVariantInput, DeleteProductInput } from '../../../../../API'
import { deleteProduct as deleteA, deleteProductVariant } from '../../../../../graphql/custom/mutations'
import { callService } from '../../../../../services'
import toggleMenu from '../../../../../actions/menu'
import CreateModal from '../../../../../components/newComponents/modal/inventoryMovement'

import { Edit, SwitchIcon, DeleteIcon, Duplicate } from '../../../../../assets/icons'
import { useEffect, useState } from 'react'

export enum TypeQuickAction {
  BASE = 'BASE',
  INVENTORY_MOVE = 'INVENTORY_MOVE',
}
const Index = ({ close }: { close: () => void }) => {
  const dispatch = useDispatch()
  const mobileView = useSelector(getDashboardView)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const allProducts = useSelector(listProducts)
  const [selected, updateSelected] = useState(allProducts.items.items.filter(item => item.selected));

  useEffect(() => {
    selected.length === 0 && close()
    updateSelected(allProducts.items.items.filter(item => item.selected))
  }, [allProducts.items.items])

  const isPartiallySelected = (product: any): boolean =>
    product.subValues &&
    product.subValues.filter((subValue: { selected: boolean }) => subValue.selected).length !== product.subValues.length

  const handleDelete = () => {
    Promise.all(
      selected.map((product) =>
        isPartiallySelected(product)
          ? product.subValues
            .filter((subValue: any) => subValue.selected)
            .map((subValue: any) =>
              callService<{ input: DeleteProductVariantInput }>(
                {
                  input: {
                    productID: product.id,
                    variantID: subValue.id,
                  },
                },
                deleteProductVariant,
                'deleteProductVariant'
              )
            )
          : callService<{ input: DeleteProductInput }>({ input: { id: product.id } }, deleteA, 'deleteProduct')
      )
    ).then(([data]) => {
      if (data.errors && data.errors.errorType === 'g-102') {
        const modal = () => <Delete id={data.payload.input.id} />
        updateModal(dispatch, true, modal, () => { })
      }
      close()
    })
  }

  const openInventoryDrawer = () =>
    updateModal(
      dispatch,
      true,
      () => <CreateModal navigate={navigate} type={t('catalog.inventoryMovement.CREATE')} />,
      () => { },
      "inventoryMovement"
    )

  const openProductList = () => {
    switchFilteredItemsToItems(objectType.PRODUCT, dispatch)
    changeConf(dispatch, objectType.PRODUCT, 'editProducts')
    clearAllFilters(dispatch)
    toggleMenu(dispatch, { state: MenuState.CLOSE })
    close()
  }

  const getParams = () => {
    return selected[0].subValues.length > 0
      ? {
        id: selected[0].id,
        subId: selected[0].subValues.filter((subValue: any) => subValue.selected).find((subValue: any) => subValue)
          .id,
        isSubDuplicate: true,
        isDuplicate: true,
      }
      : {
        id: selected[0].id,
        isDuplicate: true,
      }
  }

  const duplicateProduct = () => {
    drawerAddHistory(
      {
        component: MenuProduct,
        params: getParams(),
      },
      dispatch
    )
  }

  // const displayDuplicate = () =>
  //   selected.length === 1 &&
  //   (selected[0].subValues.length === 0 ||
  //     selected[0].subValues.filter((subValue: { selected: boolean }) => subValue.selected).length === 1)
  const getBulkActions = () => {
    let actions: TypeQuickActions = [
      {
        id: 'edit',
        icon: Edit,
        title: t('catalog.product.bulk.edit.TITLE'),
        text: t('catalog.product.bulk.edit.SUBTITLE'),
        onClick: openProductList,
      },
    ]
    if (selected.length === 1)
      actions = [
        ...actions,
        {
          id: 'duplicate',
          icon: Duplicate,
          title: t('catalog.product.bulk.duplicate.TITLE'),
          text: t('catalog.product.bulk.duplicate.SUBTITLE'),
          onClick: duplicateProduct,
        },
      ]

    actions = [
      ...actions,
      {
        id: 'inventoryMove',
        icon: SwitchIcon,
        title: t('catalog.inventoryMovement.CREATE'),
        text: t('catalog.inventoryMovement.SUBTITLE'),
        onClick: openInventoryDrawer,
      },
      {
        id: 'delete',
        icon: DeleteIcon,
        title: t('catalog.product.bulk.DELETE'),
        onClick: handleDelete,
      },
    ]
    return actions
  }

  return (
    <DrawerBulk
      bulkTitle={t('catalog.product.bulk.BUNDLED_SHARES')}
      title={t('catalog.product.bulk.SELECTED', {
        number: selected.length,
        plural: selected.length > 1 ? 's' : '',
      })}
      actions={getBulkActions()}
      mobileView={mobileView === DashboardView.MOBILE}
      close={close}
    />
  )
}

export default Index
