import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { findErrorsInState, setErrorsInState } from '../../../../format/errors';
import Form from './form';
import callCustomerCreation from './service/create';
import callCustomerUpdate from './service/update';
import { getDrawerState } from '../../../../store/selector';
import { drawerResetHistory, updateDrawerState } from '../../../../actions/drawer';
import Loader, { displayLoader } from '../../../../components/newComponents/loader';
import { displayConcatIconText } from '../../../../utils/string';
import { TypeCustomer } from '../../../../types';
import { handleBlur, handleBlurNested } from '../../../../format/form';
import { DrawerState } from '../../../../store/types';
import SubmitButton from '../../../../components/oldComponents/drawer/submitButton';
import { getDrawerHeight, getDrawerHeightNoTab } from '../../../../utils';

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css';

type Props = {
  customerState: TypeCustomer;
  close: () => void;
  dataCy: string;
};

const Detail = ({ customerState, close, dataCy }: Props) => {
  const [state, setState] = useState(customerState);
  const drawerState = useSelector(getDrawerState);
  const dispatch = useDispatch();

  const handleChange = (field: string, index?: number, subField?: string) => (event: any) => {
    state.errors.global = undefined;
    state.dirties = [...state.dirties, field];

    if (field === 'address') {
      // @ts-ignore
      state.address[subField] = event;
    } else if (field === 'customFields') {
      // @ts-ignore
      state.customFields[index].value = event;
    } else {
      // @ts-ignore
      state[field] = event;
    }

    if (['firstName', 'lastName', 'email', 'phone'].includes(field)) {
      state.errors.name = undefined;
    }
    
    // @ts-ignore
    state.errors[field] = null;

    if (field === 'firstName' || field === 'lastName') {
      state.iconText = displayConcatIconText(state.firstName, state.lastName);
      state.dirties = [...state.dirties, 'iconText'];
    }

    const showSubmit = !findErrorsInState(state.errors);

    setState({ ...state, showSubmit });
  };

  const handleResult = (res: any) => {
    if (!res.errors || res.errors.length === 0) {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch);
      drawerResetHistory(dispatch);
      close();
    } else {
      const newState = setErrorsInState(state, res.errors);
      state.errors = newState.errors;
      state.showSubmit = newState.showSubmit;
      setState({ ...state });
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch);
    }
  };

  const handleSubmit = () => {
    const { customFields, ...errors } = state.errors;
    if (!findErrorsInState(errors) && !customFields?.find(cf => cf && cf.value)) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch);
      if (!state.id) {
        callCustomerCreation(state).then(handleResult);
      } else {
        callCustomerUpdate(state).then(handleResult);
      }
    }
  };

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={styles.containerForm}
          style={{
            height: state.id ? getDrawerHeight() : getDrawerHeightNoTab(),
          }}
          data-cy={dataCy}>
          <Form
            state={state}
            handleChange={handleChange}
            handleBlur={field => setState(handleBlur(state, field))}
            handleBlurNested={index => setState(handleBlurNested(state, 'customFields', index))}
            handleSubmit={handleSubmit}
            dataCy={dataCy}
          />
          <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Detail;
