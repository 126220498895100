const Calendar = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1614 3H19.1614V2C19.1614 1.45 18.7114 1 18.1614 1C17.6114 1 17.1614 1.45 17.1614 2V3H7.16138V2C7.16138 1.45 6.71138 1 6.16138 1C5.61138 1 5.16138 1.45 5.16138 2V3H4.16138C3.06138 3 2.16138 3.9 2.16138 5V21C2.16138 22.1 3.06138 23 4.16138 23H20.1614C21.2614 23 22.1614 22.1 22.1614 21V5C22.1614 3.9 21.2614 3 20.1614 3ZM19.1614 21H5.16138C4.61138 21 4.16138 20.55 4.16138 20V8H20.1614V20C20.1614 20.55 19.7114 21 19.1614 21Z"
      fill={color}
    />
  </svg>
)

export default Calendar
