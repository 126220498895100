import { DataWithErrors } from '../../services/types'
import { objectType } from '../../store/types'
import { deleteListElement, updateListElement } from '../../actions/list'
import { getPayment as get } from '../../graphql/custom/queries'
import { getCompanyID } from '../../services/localStorage'
import { getObservable } from '../../services/graphql'
import { onCreatePayment, onDeletePayment, onUpdatePayment } from '../../graphql/custom/subscriptions'
import { GetPaymentMethodQueryVariables } from '../../API'
import { callService } from '../../services'

const getPayment = async (id: string, dispatch: any): Promise<DataWithErrors> => {
  const payment = await callService<GetPaymentMethodQueryVariables>({ id }, get, 'getPayment')

  if (payment.data) {
    updateListElement({
      element: payment.data,
      dispatch,
      type: objectType.PAYMENT,
    })
  }
  return payment
}

let onCreatePaymentSubscription: any
let onDeletePaymentSubscription: any
let onUpdatePaymentSubscription: any

const subscribePayments = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
  }
  if (!onCreatePaymentSubscription)
    onCreatePaymentSubscription = getObservable(onCreatePayment, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreatePayment)
          updateListElement({
            element: eventData.data.onCreatePayment,
            dispatch,
            type: objectType.PAYMENT,
          })
      },
    })
  if (!onDeletePaymentSubscription)
    onDeletePaymentSubscription = getObservable(onDeletePayment, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeletePayment,
          dispatch,
          type: objectType.PAYMENT,
        }),
    })
  if (!onUpdatePaymentSubscription)
    onUpdatePaymentSubscription = getObservable(onUpdatePayment, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdatePayment)
          updateListElement({
            element: eventData.data.onUpdatePayment,
            dispatch,
            type: objectType.PAYMENT,
          })
      },
    })
}

const unSubscribePayments = () => {
  if (onCreatePaymentSubscription) {
    onCreatePaymentSubscription.unsubscribe()
    onCreatePaymentSubscription = null
  }

  if (onDeletePaymentSubscription) {
    onDeletePaymentSubscription.unsubscribe()
    onDeletePaymentSubscription = null
  }

  if (onUpdatePaymentSubscription) {
    onUpdatePaymentSubscription.unsubscribe()
    onUpdatePaymentSubscription = null
  }
}

export { subscribePayments, unSubscribePayments, getPayment }
