import { UpdateDiscountInput } from '../../../../../API'
import { callService } from '../../../../../services'
import { TypeDiscount } from '../../../../../types'
import { createInput } from '../../../../../utils/dirties'
import { updateDiscount } from '../../../../../graphql/custom/mutations'

const callDiscountUpdate = async (payload: TypeDiscount, decimals: number) => {
  if (payload.id) {
    const input: any = createInput(payload, decimals)

    return callService<{ input: UpdateDiscountInput }>({ input }, updateDiscount, 'updateDiscount')
  }
  return null
}

export default callDiscountUpdate
