const Photo = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1614 3H3.16138C2.16138 3 1.16138 4 1.16138 5V19C1.16138 20.1 2.06138 21 3.16138 21H21.1614C22.1614 21 23.1614 20 23.1614 19V5C23.1614 4 22.1614 3 21.1614 3ZM5.79138 16.19L8.28138 12.99C8.48138 12.74 8.86138 12.73 9.06138 12.98L11.1614 15.51L14.2614 11.52C14.4614 11.26 14.8614 11.26 15.0614 11.53L18.5714 16.21C18.8214 16.54 18.5814 17.01 18.1714 17.01H6.18138C5.77138 17 5.53138 16.52 5.79138 16.19Z"
      fill={color}
    />
  </svg>
)

export default Photo
