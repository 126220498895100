import {
  CashbookState,
  CustomFieldObjectType,
  CustomFieldValueType,
  DiscountType,
  PaymentMethodType,
  PaymentState,
  InventoryMovementDestination,
  InventoryMovementOrigin,
  InventoryMovementState,
  SaleType,
  SaleStatus,
  SlotStatus,
  AccountRole,
  ColorPalette,
  BackgroundJobState,
} from '../API'
import {
  Cash,
  CreditCard,
  Calendar,
  Number,
  Text,
  Wallet,
  InventoryIn,
  InventoryTransfer,
  InventoryOut,
} from '../assets/icons'
import { ComponentSize, ComponentStatus } from '../components/newComponents/types'
import { CustomFieldObjectType_VALUE, CustomFieldValueType_VALUE, objectPlural, objectType } from '../store/types'
import { enumToStr } from './color'

export enum AccountRole_VALUE {
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  SELLER = 'Vendeur',
}

export const userRoleEnumToStr = (role: AccountRole): string => {
  const list = [
    {
      upperObject: AccountRole.ADMIN,
      lowerObject: AccountRole_VALUE.ADMIN,
    },
    {
      upperObject: AccountRole.MANAGER,
      lowerObject: AccountRole_VALUE.MANAGER,
    },
    {
      upperObject: AccountRole.SELLER,
      lowerObject: AccountRole_VALUE.SELLER,
    },
  ]
  const res = list.find((l) => l.upperObject === role)
  if (res) {
    return res.lowerObject
  }
  throw new Error('Role ' + role + ' does not exist')
}

/**
 *
 * This method translate a import state into understandable text
 *
 * @usedIn - Import list
 *
 * @param status - Import state
 *
 * @returns - A string
 *
 * @author - Jennifer Charlois
 *
 */
export const importStateToStr = (
  state: BackgroundJobState,
  t: (field: string, options?: any) => string,
  reportUrl?: string,
  errors?: number
): { text: string; status: ComponentStatus; size: ComponentSize } => {
  if (state === BackgroundJobState.STARTING)
    return { text: t('settings.import.STARTING'), status: ComponentStatus.INFO, size: ComponentSize.MEDIUM }
  if (state === BackgroundJobState.IN_PROGRESS)
    return { text: t('settings.import.PROGRESS'), status: ComponentStatus.INFO, size: ComponentSize.MEDIUM }
  if (state === BackgroundJobState.SUCCESS) {
    if (reportUrl)
      return {
        text: t('settings.import.ERROR', { errors, plural: errors && errors > 1 ? 's' : '' }),
        status: ComponentStatus.DANGER,
        size: ComponentSize.MEDIUM,
      }
    else return { text: t('settings.import.SUCCESS'), status: ComponentStatus.SUCCESS, size: ComponentSize.MEDIUM }
  }
  if (state === BackgroundJobState.CANCELED)
    return { text: t('settings.import.CANCELED'), status: ComponentStatus.WARNING, size: ComponentSize.MEDIUM }
  if (state === BackgroundJobState.ERROR || reportUrl) {
    return {
      text: t('settings.import.ERROR', { errors, plural: errors && errors > 1 ? 's' : '' }),
      status: ComponentStatus.DANGER,
      size: ComponentSize.MEDIUM,
    }
  }
  return state
}

/**
 *
 * This method translate a slot status into understandable text
 *
 * @usedIn - Slot list
 *
 * @param status - Slot status
 *
 * @returns - A string
 *
 * @author - Jennifer Charlois
 *
 */
export const slotStatusToStr = (
  status: SlotStatus,
  t: (field: string) => string
): { text: string; status: ComponentStatus; size: ComponentSize } => {
  if (status === SlotStatus.IDLE)
    return { text: t('settings.slot.IDLE'), status: ComponentStatus.DEFAULT, size: ComponentSize.MEDIUM }
  if (status === SlotStatus.ACTIVE)
    return { text: t('settings.slot.ACTIVE'), status: ComponentStatus.SUCCESS, size: ComponentSize.MEDIUM }
  if (status === SlotStatus.MIGRATED_V4)
    return { text: t('settings.slot.MIGRATED_V4'), status: ComponentStatus.SUCCESS, size: ComponentSize.MEDIUM }
  if (status === SlotStatus.DELETED)
    return { text: t('settings.slot.DELETED'), status: ComponentStatus.DANGER, size: ComponentSize.MEDIUM }
  return status
}

/**
 *
 * This method translate a inventory movement state into usable color and text
 *
 * @usedIn - Inventory list
 *
 * @param type - Inventory movement state
 *
 * @returns - An object of color and text
 *
 * @author - Jennifer Charlois
 *
 */
export const inventoryMoveStateToStr = (
  type: InventoryMovementState,
  t?: (field: string) => string
): {
  status: ComponentStatus
  text: string
  size: ComponentSize
} => {
  switch (type) {
    case InventoryMovementState.DRAFT:
      return {
        text: t ? t('catalog.inventoryMovement.state.DRAFT') : 'catalog.inventoryMovement.state.DRAFT',
        status: ComponentStatus.DEFAULT,
        size: ComponentSize.MEDIUM,
      }
    case InventoryMovementState.CLOSED:
      return {
        text: t ? t('catalog.inventoryMovement.state.CLOSED') : 'catalog.inventoryMovement.state.CLOSED',
        status: ComponentStatus.SUCCESS,
        size: ComponentSize.MEDIUM,
      }
    default:
      return {
        text: t ? t('catalog.inventoryMovement.state.CANCELLED') : 'catalog.inventoryMovement.state.CANCELLED',
        status: ComponentStatus.WARNING,
        size: ComponentSize.MEDIUM,
      }
  }
}

export const inventoryMoveTypeToStr = (
  origin: InventoryMovementOrigin,
  dest: InventoryMovementDestination,
  t?: (field: string) => string
): { Icon: any; text: string } => {
  if (origin === InventoryMovementOrigin.SUPPLIER && dest === InventoryMovementDestination.SHOP)
    return {
      Icon: InventoryIn,
      text: t ? t('catalog.inventoryMovement.IN') : 'catalog.inventoryMovement.IN',
    }
  else if (origin === InventoryMovementOrigin.SHOP && dest === InventoryMovementDestination.TRASH)
    return {
      Icon: InventoryOut,
      text: t ? t('catalog.inventoryMovement.OUT') : 'catalog.inventoryMovement.OUT',
    }
  return {
    Icon: InventoryTransfer,
    text: t ? t('catalog.inventoryMovement.TRANSFER') : 'catalog.inventoryMovement.TRANSFER',
  }
}

export const discountTypeToStr = (type: DiscountType, t: (field: string) => string): string => {
  if (type === DiscountType.NUMERIC) return t('catalog.discount.form.NUMERIC')
  if (type === DiscountType.PERCENTAGE) return t('catalog.discount.form.PERCENTAGE')
  return ''
}

/**
 *
 * This method translate a sale state into usable color and text
 *
 * @usedIn - Activity list
 *
 * @param state - Sale state
 * @param refundedStatus - Refunded sale status
 *
 * @returns - An object of color and text
 *
 * @author - Jennifer Charlois
 *
 */
export const stateSaleToStr = (
  state: SaleType,
  refundedStatus: SaleStatus,
  t?: (field: string) => string
): { text: string; status: ComponentStatus; size: ComponentSize } => {
  if (state === SaleType.OPEN)
    return {
      text: t ? t('activity.sale.state.OPEN') : 'activity.sale.state.OPEN',
      status: ComponentStatus.INFO,
      size: ComponentSize.MEDIUM,
    }
  if (state === SaleType.DELETED)
    return {
      text: t ? t('activity.sale.state.DELETED') : 'activity.sale.state.DELETED',
      status: ComponentStatus.DANGER,
      size: ComponentSize.MEDIUM,
    }
  if (state === SaleType.AVAILABLE)
    return {
      text: t ? t('activity.sale.state.AVAILABLE') : 'activity.sale.state.AVAILABLE',
      status: ComponentStatus.INFO,
      size: ComponentSize.MEDIUM,
    }
  if (state === SaleType.CLOSED) {
    if (refundedStatus === SaleStatus.NONE)
      return {
        text: t ? t('activity.sale.state.NONE') : 'activity.sale.state.NONE',
        status: ComponentStatus.SUCCESS,
        size: ComponentSize.MEDIUM,
      }
    if (refundedStatus === SaleStatus.PARTIAL)
      return {
        text: t ? t('activity.sale.state.PARTIAL') : 'activity.sale.state.PARTIAL',
        status: ComponentStatus.WARNING,
        size: ComponentSize.MEDIUM,
      }
    if (refundedStatus === SaleStatus.FULL)
      return {
        text: t ? t('activity.sale.state.FULL') : 'activity.sale.state.FULL',
        status: ComponentStatus.DANGER,
        size: ComponentSize.MEDIUM,
      }
  }
  return { text: '', status: ComponentStatus.DEFAULT, size: ComponentSize.MEDIUM }
}

/**
 *
 * This method translate a sale status into understandable text
 *
 * @usedIn - Activity list
 *
 * @param status - Sale status
 *
 * @returns - A string
 *
 * @author - Jennifer Charlois
 *
 */
export const saleStatusToStr = (status: SaleStatus, t: (field: string) => string): string => {
  if (status === SaleStatus.NONE) return t('activity.sale.state.NONE')
  if (status === SaleStatus.PARTIAL) return t('activity.sale.state.PARTIAL')
  if (status === SaleStatus.FULL) return t('activity.sale.state.FULL')
  return ''
}

/**
 *
 * This method translate a sale state into understandable text
 *
 * @usedIn - Activity list
 *
 * @param state - Sale state
 *
 * @returns - A string
 *
 * @author - Jennifer Charlois
 *
 */
export const saleStateToStr = (state: SaleType, t: (field: string) => string): string => {
  if (state === SaleType.OPEN) return t('activity.sale.state.OPEN')
  if (state === SaleType.DELETED) return t('activity.sale.state.DELETED')
  if (state === SaleType.AVAILABLE) return t('activity.sale.state.AVAILABLE')
  if (state === SaleType.CLOSED) return t('activity.sale.state.CLOSED')
  return ''
}

/**
 *
 * This method translate a sale payment type into usable text and color
 *
 * @usedIn - Activity list
 *
 * @param type - Sale payment type
 *
 * @returns - An object of text and color
 *
 * @author - Jennifer Charlois
 *
 */
export const movementTypeToStr = (type: string, t?: (field: string) => string): { text: string; color: string } => {
  switch (type) {
    case 'IN':
      return {
        color: 'var(--sys-color-content-interactive)',
        text: t ? t('activity.payment.state.IN') : 'activity.payment.state.IN',
      }
    case 'OUT':
      return {
        color: 'var(--sys-color-content-danger)',
        text: t ? t('activity.payment.state.OUT') : 'activity.payment.state.OUT',
      }
    default:
      return { color: '', text: '' }
  }
}

/**
 *
 * This method translate a sale payment state into usable text and color
 *
 * @usedIn - Activity list
 *
 * @param state - Sale payment state
 *
 * @returns - An object of text and color
 *
 * @author - Jennifer Charlois
 *
 */
export const salePaymentStateToStr = (
  state: PaymentState,
  t?: (field: string) => string
): { text: string; status: ComponentStatus; size: ComponentSize } => {
  switch (state) {
    case PaymentState.VALID:
      return {
        text: t ? t('activity.payment.state.VALID') : 'activity.payment.state.VALID',
        status: ComponentStatus.SUCCESS,
        size: ComponentSize.MEDIUM,
      }
    case PaymentState.DELETED:
      return {
        text: t ? t('activity.sale.state.DELETED') : 'activity.payment.state.DELETED',
        status: ComponentStatus.DANGER,
        size: ComponentSize.MEDIUM,
      }
    default:
      return { text: '', status: ComponentStatus.DEFAULT, size: ComponentSize.MEDIUM }
  }
}

/**
 *
 * This method translate a cashbook state into usable text and color
 *
 * @usedIn - Activity list
 *
 * @param state - Cashbook state
 *
 * @returns - An object of text and color
 *
 * @author - Jennifer Charlois
 *
 */
export const stateCashbookEnumToStr = (
  state: CashbookState,
  t?: (field: string) => string
): { text: string; status: ComponentStatus; size: ComponentSize } => {
  switch (state) {
    case CashbookState.OPEN:
      return {
        text: t ? t('activity.cashbook.state.OPEN') : 'activity.cashbook.state.OPEN',
        status: ComponentStatus.INFO,
        size: ComponentSize.MEDIUM,
      }
    case CashbookState.CLOSED:
      return {
        text: t ? t('activity.cashbook.state.CLOSED') : 'activity.cashbook.state.CLOSED',
        status: ComponentStatus.DEFAULT,
        size: ComponentSize.MEDIUM,
      }
    default:
      return { text: '', status: ComponentStatus.DEFAULT, size: ComponentSize.MEDIUM }
  }
}

export const statePaymentMethodToStr = (
  state: boolean,
  t?: (field: string) => string
): { text: string; status: ComponentStatus; size: ComponentSize } => {
  switch (state) {
    case true:
      return {
        text: t ? t('settings.paymentMethod.ACTIVE') : 'settings.paymentMethod.ACTIVE',
        status: ComponentStatus.SUCCESS,
        size: ComponentSize.MEDIUM,
      }
    default:
      return {
        text: t ? t('settings.paymentMethod.INACTIVE') : 'settings.paymentMethod.INACTIVE',
        status: ComponentStatus.DEFAULT,
        size: ComponentSize.MEDIUM,
      }
  }
}

/**
 *
 * This method translate the PaymentMethodType enum into a
 * usable object to use for views purpose
 *
 * @usedIn - Activity views
 *
 * @enum PaymentMethodType
 *
 * @param method - The enum PaymentMethodType
 * @param width - The width of the icon related to the payment method type
 * @param height - The height of the icon related to the payment method type
 *
 * @returns - An object of component and color
 *
 * @author - Jennifer Charlois
 *
 */
export const paymentMethodToComponent = (
  method: PaymentMethodType,
  color?: ColorPalette
): { component: any; color: string } => {
  switch (method) {
    case PaymentMethodType.CREDIT_CARD:
      return {
        color: color ? enumToStr(color) : 'var(--ref-color-blue-800)',
        component: CreditCard,
      }
    case PaymentMethodType.CASH:
      return {
        color: color ? enumToStr(color) : 'var(--ref-color-amber-400)',
        component: Cash,
      }
    default:
      return {
        color: color ? enumToStr(color) : 'var(--sys-color-content-interactive)',
        component: Wallet,
      }
  }
}

export const paymentMethodToStr = (method: PaymentMethodType, t?: (field: string) => string): string => {
  switch (method) {
    case PaymentMethodType.CREDIT_CARD:
      return t ? t('settings.paymentMethod.TACTILL') : 'settings.paymentMethod.TACTILL'
    case PaymentMethodType.CASH:
      return t ? t('settings.paymentMethod.TACTILL') : 'settings.paymentMethod.TACTILL'
    case PaymentMethodType.CUSTOM:
      return t ? t('settings.paymentMethod.CUSTOM') : 'settings.paymentMethod.CUSTOM'
    default:
      return t ? t('settings.paymentMethod.STRIPE') : 'settings.paymentMethod.STRIPE'
  }
}

/**
 *
 * This method translate a custom field value into understandable text
 *
 * @usedIn - Activity list
 *
 * @param value - API enum
 *
 * @returns - A CustomFieldValueType_VALUE
 *
 * @author - Jennifer Charlois
 *
 */
export const valueCustomFieldEnumToStr = (value: CustomFieldValueType): CustomFieldValueType_VALUE => {
  const list = [
    {
      upperType: CustomFieldValueType.TEXT,
      lowerType: CustomFieldValueType_VALUE.TEXT,
    },
    {
      upperType: CustomFieldValueType.NUMBER,
      lowerType: CustomFieldValueType_VALUE.NUMBER,
    },
    {
      upperType: CustomFieldValueType.DATE,
      lowerType: CustomFieldValueType_VALUE.DATE,
    },
  ]
  const res = list.find((l) => l.upperType === value)
  if (res) {
    return res.lowerType
  }
  throw new Error('Type ' + value + ' does not exist')
}

export const customFieldValueTypeToComponent = (value: CustomFieldValueType): any => {
  const list = [
    {
      type: CustomFieldValueType.TEXT,
      icon: Text,
    },
    {
      type: CustomFieldValueType.NUMBER,
      icon: Number,
    },
    {
      type: CustomFieldValueType.DATE,
      icon: Calendar,
    },
  ]
  const res = list.find((l) => l.type === value)
  if (res) {
    return res.icon
  }
  throw new Error('Type ' + value + ' does not exist')
}

/**
 *
 * This method translate a custom field value into API variable
 *
 * @usedIn - Catalog list
 *
 * @param value - A CustomFieldValueType_VALUE
 *
 * @returns - API enum
 *
 * @author - Jennifer Charlois
 *
 */
export const valueCustomFieldStrToEnum = (value: string): CustomFieldValueType => {
  const list = [
    {
      upperType: CustomFieldValueType.TEXT,
      lowerType: CustomFieldValueType_VALUE.TEXT,
    },
    {
      upperType: CustomFieldValueType.NUMBER,
      lowerType: CustomFieldValueType_VALUE.NUMBER,
    },
    {
      upperType: CustomFieldValueType.DATE,
      lowerType: CustomFieldValueType_VALUE.DATE,
    },
  ]
  const res = list.find((l) => l.lowerType === value)
  if (res) {
    return res.upperType
  }
  throw new Error('Type ' + value + ' does not exist')
}

/**
 *
 * This method translate an entity name into API variable
 *
 * @usedIn - Catalog list
 *
 * @param object - An objectPlural
 *
 * @returns - API enum
 *
 * @author - Jennifer Charlois
 *
 */
export const objectPluralToCustomFieldObjectType = (object: objectPlural): CustomFieldObjectType => {
  const list = [
    {
      upperObject: objectPlural.PRODUCT,
      lowerObject: CustomFieldObjectType.PRODUCT,
    },
    {
      upperObject: objectPlural.PACK,
      lowerObject: CustomFieldObjectType.PACK,
    },
    {
      upperObject: objectPlural.CATEGORY,
      lowerObject: CustomFieldObjectType.CATEGORY,
    },
    {
      upperObject: objectPlural.CASHBOOK,
      lowerObject: CustomFieldObjectType.CASHBOOK,
    },
    {
      upperObject: objectPlural.CUSTOMER,
      lowerObject: CustomFieldObjectType.CUSTOMER,
    },
  ]
  const res = list.find((l) => l.upperObject === object)
  if (res) {
    return res.lowerObject
  }

  throw new Error('Object ' + object + ' does not exist')
}

/**
 *
 * This method translate an API variable into an understandable variable
 *
 * @usedIn - Catalog list
 *
 * @param object - A CustomFieldObjectType
 *
 * @returns - A CustomFieldObjectType_VALUE
 *
 * @author - Jennifer Charlois
 *
 */
export const objectCustomFieldEnumToStr = (object: CustomFieldObjectType | string): CustomFieldObjectType_VALUE => {
  const list = [
    {
      upperObject: CustomFieldObjectType.PRODUCT,
      lowerObject: CustomFieldObjectType_VALUE.PRODUCT,
    },
    {
      upperObject: CustomFieldObjectType.PACK,
      lowerObject: CustomFieldObjectType_VALUE.PACK,
    },
    {
      upperObject: CustomFieldObjectType.CATEGORY,
      lowerObject: CustomFieldObjectType_VALUE.CATEGORY,
    },
    {
      upperObject: CustomFieldObjectType.CASHBOOK,
      lowerObject: CustomFieldObjectType_VALUE.CASHBOOK,
    },
    {
      upperObject: CustomFieldObjectType.CUSTOMER,
      lowerObject: CustomFieldObjectType_VALUE.CUSTOMER,
    },
  ]

  const res = list.find((l) => l.upperObject === object)
  if (res) {
    return res.lowerObject
  }
  throw new Error('Object ' + object + ' does not exist')
}

export const objectCustomFieldStrToObjectType = (object: CustomFieldObjectType_VALUE): objectType => {
  const list = [
    {
      upperObject: CustomFieldObjectType_VALUE.PRODUCT,
      lowerObject: objectType.PRODUCT,
    },
    {
      upperObject: CustomFieldObjectType_VALUE.PACK,
      lowerObject: objectType.PACK,
    },
    {
      upperObject: CustomFieldObjectType_VALUE.CATEGORY,
      lowerObject: objectType.CATEGORY,
    },
    {
      upperObject: CustomFieldObjectType_VALUE.CASHBOOK,
      lowerObject: objectType.CASHBOOK,
    },
    {
      upperObject: CustomFieldObjectType_VALUE.CUSTOMER,
      lowerObject: objectType.CUSTOMER,
    },
  ]

  const res = list.find((l) => l.upperObject === object)
  if (res) {
    return res.lowerObject
  }
  throw new Error('Object ' + object + ' does not exist')
}

/**
 *
 * This method translate an entity name into API variable
 *
 * @usedIn - Catalog list
 *
 * @param object - A string
 *
 * @returns - A CustomFieldObjectType
 *
 * @author - Jennifer Charlois
 *
 */
export const objectCustomFieldStrToEnum = (object: string): CustomFieldObjectType => {
  const list = [
    {
      upperObject: CustomFieldObjectType.PRODUCT,
      lowerObject: CustomFieldObjectType_VALUE.PRODUCT,
    },
    {
      upperObject: CustomFieldObjectType.PACK,
      lowerObject: CustomFieldObjectType_VALUE.PACK,
    },
    {
      upperObject: CustomFieldObjectType.CATEGORY,
      lowerObject: CustomFieldObjectType_VALUE.CATEGORY,
    },
    {
      upperObject: CustomFieldObjectType.CASHBOOK,
      lowerObject: CustomFieldObjectType_VALUE.CASHBOOK,
    },
    {
      upperObject: CustomFieldObjectType.CUSTOMER,
      lowerObject: CustomFieldObjectType_VALUE.CUSTOMER,
    },
  ]

  const res = list.find((l) => l.lowerObject === object)
  if (res) {
    return res.upperObject
  }
  throw new Error('Object ' + object + ' does not exist')
}

/**
 *
 * This method translate an API variable into understandable value
 *
 * @usedIn - Catalog list
 *
 * @param value - A CustomFieldObjectType
 *
 * @returns - A objectType
 *
 * @author - Jennifer Charlois
 *
 */
export const customFieldObjectTypeToObjectType = (value: CustomFieldObjectType): objectType => {
  const list = [
    {
      customFieldObjectType: CustomFieldObjectType.PRODUCT,
      objectType: objectType.PRODUCT,
    },
    {
      customFieldObjectType: CustomFieldObjectType.CATEGORY,
      objectType: objectType.CATEGORY,
    },
    {
      customFieldObjectType: CustomFieldObjectType.PACK,
      objectType: objectType.PACK,
    },
    {
      customFieldObjectType: CustomFieldObjectType.CASHBOOK,
      objectType: objectType.CASHBOOK,
    },
    {
      customFieldObjectType: CustomFieldObjectType.CUSTOMER,
      objectType: objectType.CUSTOMER,
    },
  ]
  const res = list.find((l) => l.customFieldObjectType === value)
  if (res) {
    return res.objectType
  }
  throw new Error('Type ' + value + ' does not exist')
}

export const pluralize = (single: string | objectType) => {
  switch (single) {
    case objectType.INVENTORY_MOVE_PRODUCT:
      return objectPlural.INVENTORY_MOVE_PRODUCT
    case objectType.PRODUCT:
      return objectPlural.PRODUCT
    case objectType.INVENTORY_MOVEMENT:
      return objectPlural.INVENTORY_MOVEMENT
    case objectType.CATEGORY:
      return objectPlural.CATEGORY
    case objectType.OPTION:
      return objectPlural.OPTION
    case objectType.COMPANY:
      return objectPlural.COMPANY
    case objectType.CUSTOM_FIELD:
      return objectPlural.CUSTOM_FIELD
    case objectType.DISCOUNT:
      return objectPlural.DISCOUNT
    case objectType.SHOP:
      return objectPlural.SHOP
    case objectType.SLOT:
      return objectPlural.SLOT
    case objectType.USER:
      return objectPlural.USER
    case objectType.PACK:
      return objectPlural.PACK
    case objectType.TAG:
      return objectPlural.TAG
    case objectType.TAX:
      return objectPlural.TAX
    case objectType.IMPORT:
      return objectPlural.IMPORT
    case objectType.PAYMENT_METHOD:
      return objectPlural.PAYMENT_METHOD
    case objectType.SALE:
      return objectPlural.SALE
    case objectType.PAYMENT:
      return objectPlural.PAYMENT
    case objectType.CASHBOOK:
      return objectPlural.CASHBOOK
    case objectType.CUSTOMER:
      return objectPlural.CUSTOMER
    case objectType.STAT_PAYMENT:
      return objectPlural.STAT_PAYMENTS
    case objectType.STAT_TAX:
      return objectPlural.STAT_TAXES
    case objectType.STAT_PRODUCT:
      return objectPlural.STAT_PRODUCTS
    case objectType.STAT_CATEGORY:
      return objectPlural.STAT_CATEGORIES
    case objectType.STAT_VALUE:
      return objectPlural.STAT_VALUES
    case objectType.VARIANT:
      return objectPlural.VARIANT
    default:
      throw new Error('Type ' + single + ' does not exist')
  }
}
