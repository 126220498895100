import { onCreateShop, onDeleteShop, onUpdateShop } from '../../graphql/custom/subscriptions';
import { getCompanyID } from '../../services/localStorage';
import { objectType } from '../../store/types';
import { getObservable } from '../../services/graphql';
import { GetShopQueryVariables } from '../../API';
import { getShop as get, getShopName as getSN } from '../../graphql/custom/queries';
import { callService } from '../../services';
import { deleteListElement, updateListElement } from '../../actions/list';
import { addElementCompanySettings, deleteElementCompanySettings } from '../../actions/company';
import { createInventoryCol, deleteInventoryCol, updateInventoryCol } from '../../actions/inventory';

let onCreateShopSubscription: any;
let onDeleteShopSubscription: any;
let onUpdateShopSubscription: any;

const getShop = async (id: string, dispatch: any) => {
  const shop = await callService<GetShopQueryVariables>({ id }, get, 'getShop');

  if (shop.data) {
    if (shop.data.logo) shop.data.logo = shop.data.logo + '?' + Date.now();

    updateListElement({
      element: shop.data,
      dispatch,
      type: objectType.SHOP,
    });
  }

  return shop;
};

const getShopName = async (id: string) => {
  const shop = await callService<GetShopQueryVariables>({ id }, getSN, 'getShop');
  return shop;
};

const subscribeShops = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
  };
  if (!onCreateShopSubscription)
    onCreateShopSubscription = getObservable(onCreateShop, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateShop) {
          if (eventData.data.onCreateShop.logo)
            eventData.data.onCreateShop.logo = eventData.data.onCreateShop.logo + '?' + Date.now();

          updateListElement({
            element: eventData.data.onCreateShop,
            dispatch,
            type: objectType.SHOP,
          });
          createInventoryCol(eventData.data.onCreateShop, dispatch);
          addElementCompanySettings({ id: eventData.data.onCreateShop.id, type: objectType.PRODUCT }, dispatch);
        }
      },
    });
  if (!onDeleteShopSubscription)
    onDeleteShopSubscription = getObservable(onDeleteShop, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onDeleteShop) {
          deleteListElement({
            element: eventData.data.onDeleteShop,
            dispatch,
            type: objectType.SHOP,
          });
          deleteInventoryCol(eventData.data.onDeleteShop.id, dispatch);
          deleteElementCompanySettings({ id: eventData.data.onDeleteShop.id, type: objectType.PRODUCT }, dispatch);
        }
      },
    });
  if (!onUpdateShopSubscription)
    onUpdateShopSubscription = getObservable(onUpdateShop, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateShop) {
          if (eventData.data.onUpdateShop.logo)
            eventData.data.onUpdateShop.logo = eventData.data.onUpdateShop.logo + '?' + Date.now();

          updateListElement({
            element: eventData.data.onUpdateShop,
            dispatch,
            type: objectType.SHOP,
          });
          updateInventoryCol(eventData.data.onUpdateShop, dispatch);
        }
      },
    });
};

const unSubscribeShops = () => {
  if (onCreateShopSubscription) {
    onCreateShopSubscription.unsubscribe();
    onCreateShopSubscription = null;
  }

  if (onDeleteShopSubscription) {
    onDeleteShopSubscription.unsubscribe();
    onDeleteShopSubscription = null;
  }

  if (onUpdateShopSubscription) {
    onUpdateShopSubscription.unsubscribe();
    onUpdateShopSubscription = null;
  }
};

export { subscribeShops, unSubscribeShops, getShop, getShopName };
