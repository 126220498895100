import { DiscountType } from '../../../API'
import { discountTypeToStr } from '../../../utils/typeToType'
import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../../components/requester/types'
import { currencyTransformer } from '../../../components/requester/query'

const discountFieldsRequester = (t: (field: string) => string): Array<TypeRequester> => [
  {
    type: TypePath.PLAIN,
    filterID: 'NAME',
    field: 'name',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'DISCOUNT',
    field: 'rate',
    transformer: currencyTransformer,
    path: [],
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'TYPE',
    field: 'type',
    path: [],
    filters: [
      {
        type: TypeFilter.LIST,
        list: Object.values(DiscountType).map((value) => ({ id: value, value: discountTypeToStr(value, t) })),
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.LIST,
        list: Object.values(DiscountType).map((value) => ({ id: value, value: discountTypeToStr(value, t) })),
        comparator: TypeComparator.NE,
      },
    ],
  },
]

export default discountFieldsRequester
