import { TypeFormError, ValidationFunction } from '../../../format/errors/types'
import { DatePickerCustom } from '../../newComponents/datePicker'
import dayjs from 'dayjs';

const DateCell = ({
  value,
  onChange,
}: {
  value: any
  onChange: any
  error: TypeFormError
  icons: Array<JSX.Element>
  validator: ValidationFunction
}): JSX.Element => {
  return (
    <div>
      <DatePickerCustom value={dayjs(value)} field={'customFields'} onChange={onChange} />
    </div>
  )
}

export default DateCell
