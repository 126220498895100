import { deleteListElement, updateListElement } from '../../actions/list'
import { objectType } from '../../store/types'
import { getObservable } from '../../services/graphql'
import { getCompanyID } from '../../services/localStorage'
import { onCreateCashbook, onDeleteCashbook, onUpdateCashbook } from '../../graphql/custom/subscriptions'
import { DataWithErrors } from '../../services/types'
import { callService } from '../../services'
import { GetCashbookQueryVariables } from '../../API'
import { getCashbook as get } from '../../graphql/custom/queries'

const getCashbook = async (id: string, dispatch: any): Promise<DataWithErrors> => {
  const cashbook = await callService<GetCashbookQueryVariables>({ id }, get, 'getCashbook')

  if (cashbook.data) {
    updateListElement({
      element: cashbook.data,
      dispatch,
      type: objectType.CASHBOOK,
    })
  }
  return cashbook
}

let onCreateCashbookSubscription: any
let onDeleteCashbookSubscription: any
let onUpdateCashbookSubscription: any

const subscribeCashbooks = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
  }
  if (!onCreateCashbookSubscription)
    onCreateCashbookSubscription = getObservable(onCreateCashbook, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateCashbook)
          updateListElement({
            element: eventData.data.onCreateCashbook,
            dispatch,
            type: objectType.CASHBOOK,
          })
      },
    })
  if (!onDeleteCashbookSubscription)
    onDeleteCashbookSubscription = getObservable(onDeleteCashbook, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeleteCashbook,
          dispatch,
          type: objectType.CASHBOOK,
        }),
    })
  if (!onUpdateCashbookSubscription)
    onUpdateCashbookSubscription = getObservable(onUpdateCashbook, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateCashbook)
          updateListElement({
            element: eventData.data.onUpdateCashbook,
            dispatch,
            type: objectType.CASHBOOK,
          })
      },
    })
}

const unSubscribeCashbooks = () => {
  if (onCreateCashbookSubscription) {
    onCreateCashbookSubscription.unsubscribe()
    onCreateCashbookSubscription = null
  }

  if (onDeleteCashbookSubscription) {
    onDeleteCashbookSubscription.unsubscribe()
    onDeleteCashbookSubscription = null
  }

  if (onUpdateCashbookSubscription) {
    onUpdateCashbookSubscription.unsubscribe()
    onUpdateCashbookSubscription = null
  }
}

export { subscribeCashbooks, unSubscribeCashbooks, getCashbook }
