import generateDataCy from '../../../../../utils/cypress'
import { TypeFormError } from '../../../../../format/errors/types'
import { ComponentState, ComponentStatus } from '../../../../../components/newComponents/types'
import Input from '../../../../../components/newComponents/input'
import DisplayIcon2 from '../../../../../components/newComponents/icons'

import { DeleteIcon } from '../../../../../assets/icons'
import stylesDrawer from '../../../../../components/oldComponents/drawer/Drawer.module.css'
import styles from '../../Product.module.css'

type Props = {
  index: number
  name: string
  value: string
  created?: boolean
  declined?: boolean
  onChange?: (field: string, index?: number) => (value: string) => void
  onBlur: (field: string, index?: number) => (event: any) => void
  onRemove: (index: number) => void
  error: { value: TypeFormError; name: TypeFormError }
  dataCy?: string
}

const VariationLine = ({
  index,
  name,
  value,
  created = false,
  declined,
  onChange,
  onBlur,
  onRemove,
  error,
  dataCy,
}: Props) => (
  <div className={styles.containerVariants}>
    <div className={stylesDrawer.oneLine}>
      <Input
        value={name}
        onChange={onChange && onChange('name', index)}
        onBlur={() => onBlur('name', index)}
        style={{
          status: error.name?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          state: created ? ComponentState.DISABLED : ComponentState.DEFAULT,
        }}
        dataCy={generateDataCy({ scope: dataCy!, value: 'name' })}
      />
      <Input
        value={value}
        onChange={onChange && onChange('value', index)}
        onBlur={() => onBlur('value', index)}
        style={{
          status: error.value?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          state: declined ? ComponentState.DISABLED : ComponentState.DEFAULT,
        }}
        dataCy={generateDataCy({ scope: dataCy!, value: 'value' })}
      />
    </div>
    {!created && (
      <DisplayIcon2 Icon={DeleteIcon} color="var(--sys-color-content-danger)" onClick={() => onRemove(index)} />
    )}
  </div>
)

export default VariationLine
