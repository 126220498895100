import { DiscountType } from '../../API'
import { TypeCol, TypeColumnConfiguration } from '../types'
import { displayCurrency, displayIconTextPrice } from '../../utils/number'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { CurrencyEditCell as CurrencyCell } from '../../components/list/cell'
import { enumToStr } from '../../utils/color'
import { ComponentSize } from '../../components/newComponents/types'
import ListItemAvatar from '../../components/newComponents/listItem/avatar'
import Thumbnail from '../../components/newComponents/thumbnail'

const DISCOUNT = 'discount'

export const discountCols: Array<TypeCol> = [
  {
    id: 'color',
    title: '',
    type: 'element',
    active: true,
    function: ({ value, decimals, currency }: any) =>
      value.color
        ? ListItemAvatar({
            children: Thumbnail({
              //@ts-ignore
              iconText: displayIconTextPrice(value.rate, value.type, decimals, currency),
              color: enumToStr(value.color),
              size: ComponentSize.SMALL,
            }),
          })
        : null,
  },
  {
    id: 'name',
    title: 'NAME',
    type: 'string',
    field: 'name',
    fieldType: 'string',
    active: true,
    sortable: true,
    notCustomable: true,
  },
  {
    id: 'rate',
    title: 'DISCOUNT',
    type: 'element',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }: any) =>
      CurrencyCell({
        value: value.rate,
        param: { emptyValue: 'Variable', type: value.type },
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'type',
    title: 'TYPE',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value, currency }) => (value.type === DiscountType.PERCENTAGE ? '%' : displayCurrency(currency)),
  },
  {
    id: 'createdAt',
    title: 'CREATED_AT',
    type: 'function',
    field: 'createdAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => displayDateShortMonthWithHours(date),
  },
]

export const discountsColsConf: Array<TypeColumnConfiguration> = [
  {
    id: 'requestDiscounts',
    type: DISCOUNT,
    cols: discountCols,
    creatable: true,
    editable: false,
    exportable: false,
    shrinkrable: true,
    clickable: true,
    customable: true,
    checkboxes: true,
    searchBarPlaceholder: 'searchBar.placeholder.NAME',
    creatablePlaceholder: 'catalog.discount.form.NEW',
  },
]
