import { InventoryMovementState } from '../../../API'
import { dateEqualTransformer } from '../../../components/requester/query'
import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../../components/requester/types'
import { inventoryMoveStateToStr } from '../../../utils/typeToType'

const inventoryMovementFieldsRequester = (t: (field: string) => string): Array<TypeRequester> => [
  {
    type: TypePath.PLAIN,
    filterID: 'STATE',
    field: 'state',
    path: [],
    filters: [
      {
        type: TypeFilter.LIST,
        list: Object.values(InventoryMovementState).map((value: InventoryMovementState) => ({
          id: value,
          value: inventoryMoveStateToStr(value, t).text,
        })),
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.LIST,
        list: Object.values(InventoryMovementState).map((value: InventoryMovementState) => ({
          id: value,
          value: inventoryMoveStateToStr(value, t).text,
        })),
        comparator: TypeComparator.NE,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'QUANTITY',
    field: 'totalQuantity',
    path: [],
    filters: [
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'CREATED_AT',
    field: 'createdAt',
    path: [],
    transformer: dateEqualTransformer,
    filters: [
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'VALID_AT',
    field: 'stateDate',
    path: [],
    transformer: dateEqualTransformer,
    filters: [
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.LT,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'PRODUCT',
    section: true,
    field: '',
    path: [],
    filters: [],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'PRODUCT_NAME',
    field: 'name',
    path: ['movement', 'details', 'product'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'REFERENCE',
    field: 'reference',
    path: ['movement', 'details', 'product'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'BARCODE',
    field: 'barcode',
    path: ['movement', 'details', 'product'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'TAG_NAME',
    field: 'name',
    path: ['movement', 'details', 'product', 'tags'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
]

export default inventoryMovementFieldsRequester
