const CheckBoxSome = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1614 3H5.16138C4.06138 3 3.16138 3.9 3.16138 5V19C3.16138 20.1 4.06138 21 5.16138 21H19.1614C20.2614 21 21.1614 20.1 21.1614 19V5C21.1614 3.9 20.2614 3 19.1614 3ZM16.1614 13H8.16138C7.61138 13 7.16138 12.55 7.16138 12C7.16138 11.45 7.61138 11 8.16138 11H16.1614C16.7114 11 17.1614 11.45 17.1614 12C17.1614 12.55 16.7114 13 16.1614 13Z"
      fill={color}
    />
  </svg>
)

export default CheckBoxSome
