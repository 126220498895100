import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TypeDrawer } from '../../../../types/drawer'
import { InventoryMovementDestination, InventoryMovementOrigin, InventoryMovementState } from '../../../../API'
import { currentDrawer, getDashboardView, getDrawerState, listShopsItemsNextToken } from '../../../../store/selector'
import Form from './form'
import { displayDateShortMonth } from '../../../../utils/date'
import {
  cancelInventoryMovement,
  getInventoryMovement,
  updateInventoryMovement,
} from '../../../../getters/catalog/inventory'
import { updateDrawerState } from '../../../../actions/drawer'
import { DashboardView, DrawerState } from '../../../../store/types'
import Loader, { displayLoader } from '../../../../components/newComponents/loader'
import DrawerTop from '../../../../components/oldComponents/drawer/top'
import generateDataCy from '../../../../utils/cypress'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'
import { updateModal } from '../../../../actions/modal'
import ValidationModal from '../../../../components/newComponents/modal/list/inventoryList'

export type MovementState = {
  id?: string
  brutState?: InventoryMovementState
  origin?: InventoryMovementOrigin
  originID?: string
  destination?: InventoryMovementDestination
  destinationID?: string
  createdAt?: string
  quantity?: number
  motive?: string
  items?: Array<any>
}

const Detail = ({ close }: TypeDrawer) => {
  const drawer = useSelector(currentDrawer)
  const shops = useSelector(listShopsItemsNextToken)
  const drawerState = useSelector(getDrawerState)
  const initialState: MovementState = {}
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const mobileView = useSelector(getDashboardView)
  const { t } = useTranslation()

  const cyContext = generateDataCy({
    scope: generateDataCy({
      scope: 'catalog',
      value: 'inventory',
    }),
    value: 'drawer',
  })

  const checkForBuyPrice = (items: any) => {
    return items?.some((value: any) => value.price !== null);
  }

  const setMovementInState = async (data: any) => {
    const inventoryMovement = data.data;

    if (inventoryMovement) {
      setState({
        ...state,
        id: inventoryMovement.id,
        brutState: inventoryMovement.state,
        createdAt: inventoryMovement.createdAt,
        quantity: inventoryMovement.totalQuantity,
        origin: inventoryMovement.origin,
        originID: inventoryMovement.originID,
        destination: inventoryMovement.destination,
        destinationID: inventoryMovement.destinationID,
        motive: inventoryMovement.motive,
        items: inventoryMovement.movements.items,
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  const openList = () => {
    close()
    navigate('/catalog/editableMovement/' + drawer.id)
  }

  const handleCancel = () => {
    if (state.id) cancelInventoryMovement({ id: state.id, motive: state.motive }, dispatch).then(() => close())
  }

  const handleSubmit = () => {
    const checkMovementType = state.origin === 'SUPPLIER';
    if (checkMovementType) {
      const buyPriceChange = checkForBuyPrice(state.items);
      if (buyPriceChange) {
        updateModal(
          dispatch,
          true,
          () => (
            //@ts-ignore
            <ValidationModal
              draftState={state}
              dispatch={dispatch}
              validateDraft={true}
            />
          ),
          () => { }
        )
      } else {
        if (state.id) {
          updateDrawerState(DrawerState.SEND_FORM, dispatch)
          updateInventoryMovement(
            {
              id: state.id,
              state: InventoryMovementState.CLOSED,
              motive: state.motive,
            },
            dispatch
          ).then(() => {
            updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
            close()
          })
        }

      }

    } else {
      if (state.id) {
        updateDrawerState(DrawerState.SEND_FORM, dispatch)
        updateInventoryMovement(
          {
            id: state.id,
            state: InventoryMovementState.CLOSED,
            motive: state.motive,
          },
          dispatch
        ).then(() => {
          updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
          close()
        })
      }
    }

  }

  useEffect(() => {
    if (drawer.id) {
      getInventoryMovement(drawer.id, dispatch).then(setMovementInState)
    }
  }, [drawer.id])

  return (
    <>
      <DrawerTop
        history={[]}
        name={t('catalog.inventoryMovement.MOVE', { date: displayDateShortMonth(state.createdAt!) })}
        mobileView={mobileView === DashboardView.MOBILE}
        close={close}
        dataCy={cyContext}
      />
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={styles.containerForm}
          style={{
            height: `calc(100vh - (61px + ${state.brutState === InventoryMovementState.DRAFT
              ? 'var(--comp-button-size-height-lg) + 3em + 24px + 16px'
              : '0'
              } )`,
          }}
          data-cy={cyContext}
        >
          <Form
            state={state}
            shops={shops.items}
            openList={openList}
            dataCy={cyContext}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Detail
