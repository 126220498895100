import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'

import Stat from '.'
import { selectedCompany } from '../../../store/selector'
import { Stats } from '../../../types'
import { displayPrice } from '../../../utils/number'
import generateDataCy from '../../../utils/cypress'

const ChartBar = ({ state }: { state: Stats }) => {
  const company = useSelector(selectedCompany)
  const cyContext = generateDataCy({ scope: 'tab', value: 'performance' })

  return (
    <>
      {state.stats!.map((stat, index) => (
        <Stat
          key={index}
          text={stat.text}
          value={stat.value}
          dataCy={generateDataCy({ scope: cyContext, value: stat.text.split('.')[stat.text.split('.').length - 2] })}
        />
      ))}
      <Chart
        type="bar"
        height={250}
        series={[
          {
            name: 'CA Total HT',
            data: state.histogram?.map((histo) => histo.total),
          },
        ]}
        width="100%"
        options={{
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          yaxis: {
            labels: {
              formatter: (value: number) => company && displayPrice(value, company.currency, company.currencyDecimals),
            },
          },
          fill: {
            colors: ['var(--sys-color-content-interactive)'],
          },
          xaxis: {
            categories: state.histogram?.map((histo) => histo.date),
          },
          dataLabels: {
            enabled: false,
          },
          states: {
            hover: {
              filter: {
                type: 'darken',
                value: 0.7,
              },
            },
            active: {
              filter: {
                type: 'darken',
                value: 0.5,
              },
            },
          },
        }}
      />
    </>
  )
}

export default ChartBar
