import { objectType } from '../store/types'

const parseKey = (value: string) =>
  value
    ?.replace(/ /g, '_')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9_]/g, '')
    .toLowerCase()

const generateCustomFieldKey = (type: objectType, value?: string) => {
  let key = type.toLowerCase()
  if (value) key += '_' + parseKey(value)
  return key
}

export { parseKey, generateCustomFieldKey }
