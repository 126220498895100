import { CustomFieldObjectType } from '../API'
import { Action } from '../store/types'

const filterTagItems = (payload: { search: string }, dispatch: any) => {
  dispatch({
    type: Action.FILTER_TAG_ITEMS,
    payload,
  })
}

const filterCustomFieldItems = (payload: { type: CustomFieldObjectType; search?: string }, dispatch: any) => {
  dispatch({
    type: Action.FILTER_CUSTOM_FIELD_ITEMS,
    payload,
  })
}

const updateCategoriesAssociations = (payload: { categoryID: string; dispatch: any }, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_CATEGORIES_ASSOCIATIONS,
    payload,
  })
}

const updateTagsAssociations = (payload: { tagID: string; dispatch: any }, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_TAGS_ASSOCIATIONS,
    payload,
  })
}

const updateTagsData = (payload: { tagID: string; productID: string }, dispatch: any) => {
  /* to replace the getProduct big call we need to update product with new tag */
  dispatch({
    type: Action.UPDATE_TAGS_DATA,
    payload,
  })
}

const deleteTagsData = (payload: { tagID: string; productID: string }, dispatch: any) => {
  /* to replace the getProduct big call we need to remove tag from product  */
  dispatch({
    type: Action.DELETE_TAGS_DATA,
    payload,
  })
}

const deleteTagFromProduct = (payload: { tagID: string }, dispatch: any) => {
  /* to update store UI and remove tag from product associated to it and update tagAssociation  */
  dispatch({
    type: Action.DELETE_TAG_FROM_PRODUCT,
    payload,
  })
}

const updateCustomFieldsAssociations = (payload: { customFieldID: string; dispatch: any }, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_CUSTOM_FIELDS_ASSOCIATIONS,
    payload,
  })
}

const updateOptionsAssociations = (payload: { optionID: string; dispatch: any }, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_OPTIONS_ASSOCIATIONS,
    payload,
  })
}

const deleteOptionFromProduct = (payload: { optionID: string }, dispatch: any) => {
  /* to update store UI and remove option from product associated to it and update tagAssociation  */
  dispatch({
    type: Action.DELETE_OPTION_FROM_PRODUCT,
    payload,
  })
}

const updatePacksAssociations = (payload: { productID: string; dispatch: any }, dispatch: any) => {
  dispatch({
    type: Action.UPDATE_PACKS_ASSOCIATIONS,
    payload,
  })
}

export {
  filterTagItems,
  filterCustomFieldItems,
  updateCategoriesAssociations,
  updateTagsAssociations,
  updateTagsData,
  deleteTagsData,
  deleteTagFromProduct,
  deleteOptionFromProduct,
  updateCustomFieldsAssociations,
  updateOptionsAssociations,
  updatePacksAssociations,
}
