import { UpdateCompanySettingInput } from '../API'
import { updateCompanySetting } from '../graphql/custom/mutations'
import { callService } from '../services'
import { StateStore } from './types'

const addElementCompanySettings = (id: string, type: string, state: StateStore) => {
  const setting = state.companySettings.find((company) => (type + 'Cols').toLowerCase() === company.key.toLowerCase())
  if (setting) {
    // eslint-disable-next-line no-eval
    const parse = eval(JSON.parse(setting.value))
    callService<{ input: UpdateCompanySettingInput }>(
      {
        input: {
          id: setting.id,
          key: setting.key,
          value: JSON.stringify([...parse, { id, active: true }]),
        },
      },
      updateCompanySetting,
      'updateCompanySetting'
    )
  }
  return state
}

const deleteElementCompanySettings = (id: string, type: string, state: StateStore) => {
  const setting = state.companySettings.find((company) => (type + 'Cols').toLowerCase() === company.key.toLowerCase())
  if (setting) {
    // eslint-disable-next-line no-eval
    const parse = eval(JSON.parse(setting.value))
    callService<{ input: UpdateCompanySettingInput }>(
      {
        input: {
          id: setting.id,
          key: setting.key,
          value: JSON.stringify(parse.filter((col: { id: string }) => col.id !== id)),
        },
      },
      updateCompanySetting,
      'updateCompanySetting'
    )
  }
  return state
}

export { addElementCompanySettings, deleteElementCompanySettings }
