/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type MigrationInput = {
  types?: Array< MigrationType > | null,
  apiKey: string,
  companyID: string,
  shopID: string,
};

export enum MigrationType {
  CATALOG = "CATALOG",
  INVENTORY = "INVENTORY",
  CUSTOMER = "CUSTOMER",
  ACTIVITY = "ACTIVITY",
}


export type ModelBackgroundJobConnection = {
  __typename: "ModelBackgroundJobConnection",
  items?:  Array<BackgroundJob | null > | null,
  nextToken?: string | null,
};

export type BackgroundJob = {
  __typename: "BackgroundJob",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  test?: boolean | null,
  shopID?: string | null,
  sourceEmail?: string | null,
  type: BackgroundJobType,
  state: BackgroundJobState,
  details?: BackgroundJobDetails | null,
  message?: string | null,
  reportUrl?: string | null,
  flatfileBatchId?: string | null,
  flatfileSheetId?: string | null,
};

export type Node = {
  __typename: "Node",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  originalID?: string | null,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  shopID?: string | null,
  slotID?: string | null,
  userID?: string | null,
  test?: boolean | null,
  version: string,
  date: string,
  type: EventType,
  code: number,
  objectType: EventObjectType,
  objectID: string,
  action: string,
  actionData?: string | null,
  hash?: string | null,
};

export enum EventType {
  CERTIFICATION = "CERTIFICATION",
  INFO = "INFO",
}


export enum EventObjectType {
  SALE = "SALE",
  PAYMENT = "PAYMENT",
  CASHBOOK = "CASHBOOK",
  CUSTOMER = "CUSTOMER",
  PRODUCT = "PRODUCT",
}


export type UserNotification = {
  __typename: "UserNotification",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  accountID: string,
  companyID: string,
  eventObjectType: EventObjectType,
  eventAction: string,
  action: NotificationAction,
  active: boolean,
};

export type NotificationAction = {
  __typename: "NotificationAction",
  type?: NotificationActionType | null,
  details?: NotificationActionTypeDetails | null,
};

export enum NotificationActionType {
  WEBHOOK = "WEBHOOK",
  EMAIL = "EMAIL",
}


export type NotificationActionTypeDetails = NotificationActionDetailsWebhook


export type NotificationActionDetailsWebhook = {
  __typename: "NotificationActionDetailsWebhook",
  url: string,
};

export type AccountCompany = {
  __typename: "AccountCompany",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  accountID: string,
  companyID: string,
  company?: Company | null,
  owner?: boolean | null,
  role?: AccountRole | null,
  read?: boolean | null,
  update?: boolean | null,
  billing?: boolean | null,
  billingExport?: boolean | null,
  shopWrite?: boolean | null,
  deviceSlotWrite?: boolean | null,
  deviceSlotUse?: boolean | null,
  paymentMethodRead?: boolean | null,
  paymentMethodCreate?: boolean | null,
  paymentMethodUpdate?: boolean | null,
  paymentMethodDelete?: boolean | null,
  customerRead?: boolean | null,
  customerCreate?: boolean | null,
  customerUpdate?: boolean | null,
  customerDelete?: boolean | null,
  customerExport?: boolean | null,
  inventoryRead?: boolean | null,
  cashbookRead?: boolean | null,
  saleRead?: boolean | null,
  paymentRead?: boolean | null,
  userInvite?: boolean | null,
  userRead?: boolean | null,
  userUpdate?: boolean | null,
  userRemove?: boolean | null,
  sell?: boolean | null,
  sync?: boolean | null,
  id: string,
};

export type Company = {
  __typename: "Company",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  name?: string | null,
  address?: string | null,
  country?: CountryCode | null,
  currency: CurrencyCode,
  currencyDecimals: number,
  vat?: string | null,
};

export enum CountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}


export enum CurrencyCode {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYR = "BYR",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STD = "STD",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XCD = "XCD",
  XOF = "XOF",
  XPF = "XPF",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}


export enum AccountRole {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SELLER = "SELLER",
}


export type AccountCatalog = {
  __typename: "AccountCatalog",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  accountID: string,
  companyID: string,
  catalogID: string,
  catalog?: Catalog | null,
  read?: boolean | null,
  create?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  sync?: boolean | null,
  id: string,
};

export type Catalog = {
  __typename: "Catalog",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  company?: Company | null,
  id: string,
};

export type AccountShop = {
  __typename: "AccountShop",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  accountID: string,
  companyID: string,
  shopID: string,
  shop?: Shop | null,
  read?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  inventoryRead?: boolean | null,
  inventoryWrite?: boolean | null,
  cashbookRead?: boolean | null,
  cashbookWrite?: boolean | null,
  saleRead?: boolean | null,
  saleWrite?: boolean | null,
  paymentRead?: boolean | null,
  paymentWrite?: boolean | null,
  userInvite?: boolean | null,
  userRead?: boolean | null,
  userUpdate?: boolean | null,
  userRemove?: boolean | null,
  activitiesExport?: boolean | null,
  tillCashbooksRead?: boolean | null,
  tillSalesSearch?: boolean | null,
  tillSaleSell?: boolean | null,
  tillSaleRefund?: boolean | null,
  tillSaleReturn?: boolean | null,
  tillCashbookOpen?: boolean | null,
  tillCashbookClose?: boolean | null,
  eventCreate?: boolean | null,
  sync?: boolean | null,
  id: string,
};

export type Shop = {
  __typename: "Shop",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  company?: Company | null,
  name: string,
  address?: Address | null,
  siret?: string | null,
  vat?: string | null,
  phone?: string | null,
  footnote?: string | null,
  logo?: string | null,
  websiteURL?: string | null,
  brandName: string,
  email?: string | null,
};

export type Address = {
  __typename: "Address",
  address?: string | null,
  zipcode?: string | null,
  city?: string | null,
  country?: string | null,
};

export type SellerCode = {
  __typename: "SellerCode",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  accountID: string,
  companyID: string,
  company?: Company | null,
  code: string,
  id: string,
};

export type GridCell = {
  __typename: "GridCell",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  compositeID: string,
  catalogID: string,
  test?: boolean | null,
  deviceID?: string | null,
  page: number,
  column: number,
  row: number,
  type: GridCellType,
  objectID: string,
};

export enum GridCellType {
  EMPTY = "EMPTY",
  PRODUCT = "PRODUCT",
  PACK = "PACK",
  CATEGORY = "CATEGORY",
  TAG = "TAG",
  DISCOUNT = "DISCOUNT",
}


export type Product = {
  __typename: "Product",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  name: string,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
  categoryID: string,
  category?: Category | null,
  taxID: string,
  tax?: Tax | null,
  bulkType?: BulkType | null,
  buyPrice?: number | null,
  sellPrice?: number | null,
  barcode?: string | null,
  reference?: string | null,
  variations?:  Array<ProductVariation | null > | null,
  variantsCount?: number | null,
  variants?: ModelProductVariantConnection | null,
  options?: ModelProductOptionConnection | null,
  tags?: ModelProductTagConnection | null,
  customFields?: ModelProductCustomFieldConnection | null,
  inventoryQuantities?:  Array<ProductInventoryQuantity | null > | null,
  stats?: ProductStats | null,
  inventoryStats?: ProductInventoryStats | null,
};

export enum ColorPalette {
  GREEN = "GREEN",
  LIME = "LIME",
  RED = "RED",
  BLUE = "BLUE",
  YELLOW = "YELLOW",
  ORANGE = "ORANGE",
  PINK = "PINK",
  PURPLE = "PURPLE",
  TURQUOISE = "TURQUOISE",
  GREY = "GREY",
  BROWN = "BROWN",
  BLACK = "BLACK",
}


export type Category = {
  __typename: "Category",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  name: string,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
  customFields?: ModelCategoryCustomFieldConnection | null,
  stats?: CategoryStats | null,
};

export type ModelCategoryCustomFieldConnection = {
  __typename: "ModelCategoryCustomFieldConnection",
  items?:  Array<CategoryCustomField | null > | null,
  nextToken?: string | null,
};

export type CategoryCustomField = {
  __typename: "CategoryCustomField",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  categoryID: string,
  customFieldID: string,
  customFieldKey: string,
  customField?: CustomField | null,
  value: string,
  id: string,
};

export type CustomField = {
  __typename: "CustomField",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  key: string,
  objectType: CustomFieldObjectType,
  valueType: CustomFieldValueType,
  name: string,
  createdByID?: string | null,
};

export enum CustomFieldObjectType {
  PRODUCT = "PRODUCT",
  PACK = "PACK",
  CATEGORY = "CATEGORY",
  CASHBOOK = "CASHBOOK",
  CUSTOMER = "CUSTOMER",
}


export enum CustomFieldValueType {
  TEXT = "TEXT",
  DATE = "DATE",
  NUMBER = "NUMBER",
}


export type CategoryStats = {
  __typename: "CategoryStats",
  totalsDetail?: TotalsDetail | null,
  quantity?: number | null,
  histogram?:  Array<GroupedTotalsDetail | null > | null,
};

export type TotalsDetail = {
  __typename: "TotalsDetail",
  totals?: Totals | null,
  sellTotals?: Totals | null,
  refundTotals?: Totals | null,
};

export type Totals = {
  __typename: "Totals",
  total?: number | null,
  totalTax?: number | null,
  totalTaxFree?: number | null,
};

export type GroupedTotalsDetail = {
  __typename: "GroupedTotalsDetail",
  id: string,
  totalsDetail?: TotalsDetail | null,
};

export type Tax = {
  __typename: "Tax",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  rate?: number | null,
};

export enum BulkType {
  KG = "KG",
  M = "M",
  L = "L",
  MIN = "MIN",
}


export type ProductVariation = {
  __typename: "ProductVariation",
  name?: string | null,
  options?: Array< string | null > | null,
};

export type ModelProductVariantConnection = {
  __typename: "ModelProductVariantConnection",
  items?:  Array<ProductVariant | null > | null,
  nextToken?: string | null,
};

export type ProductVariant = {
  __typename: "ProductVariant",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  id: string,
  productID: string,
  variantID: string,
  options:  Array<VariationOption | null >,
  taxID: string,
  tax?: Tax | null,
  buyPrice?: number | null,
  sellPrice?: number | null,
  barcode?: string | null,
  reference?: string | null,
  inventoryQuantities?:  Array<ProductInventoryQuantity | null > | null,
  stats?: ProductStats | null,
  inventoryStats?: ProductInventoryStats | null,
};

export type VariationOption = {
  __typename: "VariationOption",
  name: string,
  value: string,
};

export type ProductInventoryQuantity = {
  __typename: "ProductInventoryQuantity",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  shopID: string,
  test?: boolean | null,
  productID: string,
  variantID?: string | null,
  quantity?: number | null,
  resetDate?: string | null,
  shop?: Shop | null,
};

export type ProductStats = {
  __typename: "ProductStats",
  totalsDetail?: TotalsDetail | null,
  margin?: number | null,
  quantity?: number | null,
  histogram?:  Array<GroupedTotalsDetail | null > | null,
};

export type ProductInventoryStats = {
  __typename: "ProductInventoryStats",
  content?: ProductInventoryStatsContent | null,
  byShop?:  Array<GroupedProductInventoryStats | null > | null,
};

export type ProductInventoryStatsContent = {
  __typename: "ProductInventoryStatsContent",
  quantity?: number | null,
  value?: number | null,
};

export type GroupedProductInventoryStats = {
  __typename: "GroupedProductInventoryStats",
  id: string,
  content?: ProductInventoryStatsContent | null,
};

export type ModelProductOptionConnection = {
  __typename: "ModelProductOptionConnection",
  items?:  Array<ProductOption | null > | null,
  nextToken?: string | null,
};

export type ProductOption = {
  __typename: "ProductOption",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  productID: string,
  optionID: string,
  option?: Option | null,
  id: string,
};

export type Option = {
  __typename: "Option",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  name: string,
  values:  Array<OptionValue | null >,
  stats?: OptionStats | null,
};

export type OptionValue = {
  __typename: "OptionValue",
  name: string,
  price: number,
};

export type OptionStats = {
  __typename: "OptionStats",
  totalsDetail?: TotalsDetail | null,
  quantity?: number | null,
  histogram?:  Array<GroupedTotalsDetail | null > | null,
};

export type ModelProductTagConnection = {
  __typename: "ModelProductTagConnection",
  items?:  Array<ProductTag | null > | null,
  nextToken?: string | null,
};

export type ProductTag = {
  __typename: "ProductTag",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  productID: string,
  tagID: string,
  tag?: Tag | null,
  id: string,
};

export type Tag = {
  __typename: "Tag",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  name: string,
  createdByID?: string | null,
};

export type ModelProductCustomFieldConnection = {
  __typename: "ModelProductCustomFieldConnection",
  items?:  Array<ProductCustomField | null > | null,
  nextToken?: string | null,
};

export type ProductCustomField = {
  __typename: "ProductCustomField",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  productID: string,
  customFieldID: string,
  customFieldKey: string,
  customField?: CustomField | null,
  value: string,
  id: string,
};

export type Discount = {
  __typename: "Discount",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  name: string,
  color?: ColorPalette | null,
  photo?: string | null,
  type: DiscountType,
  rate?: number | null,
  stats?: DiscountStats | null,
};

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  NUMERIC = "NUMERIC",
}


export type DiscountStats = {
  __typename: "DiscountStats",
  amount?: number | null,
  quantity?: number | null,
  histogram?:  Array<GroupedAmount | null > | null,
};

export type GroupedAmount = {
  __typename: "GroupedAmount",
  id?: string | null,
  amount?: number | null,
};

export type Pack = {
  __typename: "Pack",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  name: string,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
  barcode?: string | null,
  reference?: string | null,
  sellPrice?: number | null,
  packVariations:  Array<PackVariation >,
  customFields?: ModelPackCustomFieldConnection | null,
  stats?: PackStats | null,
};

export type PackVariation = {
  __typename: "PackVariation",
  options:  Array<PackVariationOption >,
};

export type PackVariationOption = {
  __typename: "PackVariationOption",
  productID: string,
  quantity: number,
  additionalPrice: number,
};

export type ModelPackCustomFieldConnection = {
  __typename: "ModelPackCustomFieldConnection",
  items?:  Array<PackCustomField | null > | null,
  nextToken?: string | null,
};

export type PackCustomField = {
  __typename: "PackCustomField",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  catalogID: string,
  test?: boolean | null,
  packID: string,
  customFieldID: string,
  customFieldKey: string,
  customField?: CustomField | null,
  value: string,
  id: string,
};

export type PackStats = {
  __typename: "PackStats",
  totalsDetail?: TotalsDetail | null,
  quantity?: number | null,
  histogram?:  Array<GroupedTotalsDetail | null > | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  test?: boolean | null,
  name: string,
  color?: ColorPalette | null,
  type: PaymentMethodType,
  changeType?: PaymentMethodChangeType | null,
  countOnCashbookOpeningClosing?: boolean | null,
  opensCashDrawer?: boolean | null,
  enabled?: boolean | null,
};

export enum PaymentMethodType {
  CUSTOM = "CUSTOM",
  CASH = "CASH",
  CREDIT_CARD = "CREDIT_CARD",
  STRIPE = "STRIPE",
}


export enum PaymentMethodChangeType {
  NONE = "NONE",
  CASH = "CASH",
}


export type Customer = {
  __typename: "Customer",
  id: string,
  originalID?: string | null,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  test?: boolean | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  phoneCode?: string | null,
  companyName?: string | null,
  color?: ColorPalette | null,
  iconText?: string | null,
  address?: Address | null,
  note?: string | null,
  fidelityCardNumber?: string | null,
  account?: CustomerAccount | null,
  customFields?: ModelCustomerCustomFieldConnection | null,
  stats?: CustomerStats | null,
};

export type CustomerAccount = {
  __typename: "CustomerAccount",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  test?: boolean | null,
  balance: number,
  movements?: ModelCustomerAccountMovementConnection | null,
};

export type ModelCustomerAccountMovementConnection = {
  __typename: "ModelCustomerAccountMovementConnection",
  items?:  Array<CustomerAccountMovement | null > | null,
  nextToken?: string | null,
};

export type CustomerAccountMovement = {
  __typename: "CustomerAccountMovement",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  test?: boolean | null,
  customerID: string,
  state: CustomerAccountMovementState,
  type: CustomerAccountMovementType,
  amount: number,
  saleID?: string | null,
  paymentID?: string | null,
  movementInDetails?: CustomerAccountMovementInDetails | null,
};

export enum CustomerAccountMovementState {
  DELETED = "DELETED",
  VALID = "VALID",
}


export enum CustomerAccountMovementType {
  IN = "IN",
  OUT = "OUT",
}


export type CustomerAccountMovementInDetails = {
  __typename: "CustomerAccountMovementInDetails",
  paymentMethodID: string,
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
};

export type ModelCustomerCustomFieldConnection = {
  __typename: "ModelCustomerCustomFieldConnection",
  items?:  Array<CustomerCustomField | null > | null,
  nextToken?: string | null,
};

export type CustomerCustomField = {
  __typename: "CustomerCustomField",
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  test?: boolean | null,
  customerID: string,
  customFieldID: string,
  customFieldKey: string,
  customField?: CustomField | null,
  value: string,
  id: string,
};

export type CustomerStats = {
  __typename: "CustomerStats",
  nbSales?: number | null,
  average?: Totals | null,
  index?: number | null,
  totals?: Totals | null,
  totalDiscount?: number | null,
};

export type Sale = {
  __typename: "Sale",
  id: string,
  originalID?: string | null,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  shopID: string,
  test?: boolean | null,
  sourceID: string,
  sourceName: string,
  cashbookID?: string | null,
  cashbook?: Cashbook | null,
  name?: string | null,
  number?: number | null,
  state: SaleType,
  refundStatus: SaleStatus,
  refundedStatus: SaleStatus,
  paymentStatus: SaleStatus,
  note?: string | null,
  targetID?: string | null,
  targetSale?: Sale | null,
  refundSales?: ModelSaleConnection | null,
  openedAt: string,
  closedAt?: string | null,
  pendingAt?: string | null,
  customerID?: string | null,
  customer?: Customer | null,
  sellerID?: string | null,
  seller?: Seller | null,
  lines?:  Array<SaleLine | null > | null,
  discounts?:  Array<SaleDiscount | null > | null,
  payments?:  Array<SalePayment | null > | null,
  total: number,
  totalDiscount: number,
  totalTaxFree: number,
  totalTax: number,
  totalRest: number,
  stats?: SaleStats | null,
};

export type Cashbook = {
  __typename: "Cashbook",
  id: string,
  originalID?: string | null,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  shopID: string,
  test?: boolean | null,
  sourceID: string,
  sourceName: string,
  number: number,
  state: CashbookState,
  total?: number | null,
  totalTaxFree?: number | null,
  openedAt: string,
  closedAt?: string | null,
  openingSellerID?: string | null,
  closingSellerID?: string | null,
  openingSeller?: Seller | null,
  closingSeller?: Seller | null,
  note?: string | null,
  movements?:  Array<CashbookMovement | null > | null,
  openingCashFund?:  Array<CashbookPaymentMethodCashFund | null > | null,
  closingCashFund?:  Array<CashbookPaymentMethodCashFund | null > | null,
  totalExpected?: number | null,
  totalDifference?: number | null,
  customFields?:  Array<CashbookCustomField | null > | null,
  stats?: CashbookStats | null,
};

export enum CashbookState {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}


export type Seller = {
  __typename: "Seller",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  firstName?: string | null,
  lastName?: string | null,
  color: ColorPalette,
  iconText?: string | null,
  photo?: string | null,
  email: string,
};

export type CashbookMovement = {
  __typename: "CashbookMovement",
  type: CashbookMovementType,
  amount: number,
  motive?: string | null,
  createdAt: string,
};

export enum CashbookMovementType {
  IN = "IN",
  OUT = "OUT",
}


export type CashbookPaymentMethodCashFund = {
  __typename: "CashbookPaymentMethodCashFund",
  paymentMethodID: string,
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
  total: number,
};

export type CashbookCustomField = {
  __typename: "CashbookCustomField",
  customFieldID: string,
  name: string,
  valueType: CustomFieldValueType,
  value: string,
};

export type CashbookStats = {
  __typename: "CashbookStats",
  minSaleNumber?: number | null,
  maxSaleNumber?: number | null,
  numberOfSales?: number | null,
  totalsDetail?: TotalsDetail | null,
  taxes?:  Array<GroupedTotals | null > | null,
  payments?:  Array<CashbookPaymentStats | null > | null,
};

export type GroupedTotals = {
  __typename: "GroupedTotals",
  id: string,
  totals?: Totals | null,
};

export type CashbookPaymentStats = {
  __typename: "CashbookPaymentStats",
  id?: string | null,
  detail?: CashbookPaymentStatsDetail | null,
  sellDetail?: CashbookPaymentStatsDetail | null,
  refundDetail?: CashbookPaymentStatsDetail | null,
};

export type CashbookPaymentStatsDetail = {
  __typename: "CashbookPaymentStatsDetail",
  amount?: number | null,
  quantity?: number | null,
};

export enum SaleType {
  OPEN = "OPEN",
  AVAILABLE = "AVAILABLE",
  CLOSED = "CLOSED",
  DELETED = "DELETED",
}


export enum SaleStatus {
  NONE = "NONE",
  PARTIAL = "PARTIAL",
  FULL = "FULL",
}


export type ModelSaleConnection = {
  __typename: "ModelSaleConnection",
  items?:  Array<Sale | null > | null,
  nextToken?: string | null,
};

export type SaleLine = {
  __typename: "SaleLine",
  details: SaleLineDetails,
  quantity: number,
  price: number,
  refundedQuantity?: number | null,
  discounts?:  Array<SaleDiscount | null > | null,
  note?: string | null,
  operationType: SaleLineOperationType,
  state: SaleLineState,
  targetSaleLineIndex?: number | null,
  motive?: string | null,
  total: number,
  totalSellPrice: number,
  totalDiscount: number,
  totalTaxFree: number,
  totalTax: number,
  totalGlobalDiscountFree?: number | null,
  totalTaxFreeGlobalDiscountFree?: number | null,
  totalTaxGlobalDiscountFree?: number | null,
  unitPriceGlobalDiscountFree?: number | null,
  totalDiscountFree?: number | null,
  totalTaxFreeDiscountFree?: number | null,
  totalTaxDiscountFree?: number | null,
  unitPriceDiscountFree?: number | null,
};

export type SaleLineDetails = SaleLinePackDetails | SaleLineProductDetails


export type SaleLinePackDetails = {
  __typename: "SaleLinePackDetails",
  packID: string,
  name: string,
  packVariant:  Array<SaleLinePackOption | null >,
  customFields?:  Array<SaleLineCustomField | null > | null,
};

export type SaleLinePackOption = {
  __typename: "SaleLinePackOption",
  details: SaleLineProductDetails,
  quantity: number,
  additionalPrice: number,
  basePrice: number,
  total: number,
  totalTaxFree: number,
  totalTax: number,
  totalGlobalDiscountFree?: number | null,
  totalTaxFreeGlobalDiscountFree?: number | null,
  totalTaxGlobalDiscountFree?: number | null,
  unitPriceGlobalDiscountFree?: number | null,
  totalDiscountFree?: number | null,
  totalTaxFreeDiscountFree?: number | null,
  totalTaxDiscountFree?: number | null,
  unitPriceDiscountFree?: number | null,
};

export type SaleLineProductDetails = {
  __typename: "SaleLineProductDetails",
  productID: string,
  name: string,
  bulkType?: BulkType | null,
  variant?: SaleLineVariant | null,
  category: SaleLineCategory,
  tax: SaleTax,
  tags?:  Array<SaleLineTag | null > | null,
  customFields?:  Array<SaleLineCustomField | null > | null,
  options?:  Array<SaleLineOption | null > | null,
  total: number,
  totalTax: number,
  totalTaxFree: number,
  totalGlobalDiscountFree?: number | null,
  totalTaxFreeGlobalDiscountFree?: number | null,
  totalTaxGlobalDiscountFree?: number | null,
  unitPriceGlobalDiscountFree?: number | null,
  totalDiscountFree?: number | null,
  totalTaxFreeDiscountFree?: number | null,
  totalTaxDiscountFree?: number | null,
  unitPriceDiscountFree?: number | null,
  virtualDiscounts?:  Array<SaleDiscount | null > | null,
};

export type SaleLineVariant = {
  __typename: "SaleLineVariant",
  variantID: string,
  options:  Array<VariationOption | null >,
};

export type SaleLineCategory = {
  __typename: "SaleLineCategory",
  categoryID: string,
  name: string,
  tags?:  Array<SaleLineTag | null > | null,
};

export type SaleLineTag = {
  __typename: "SaleLineTag",
  tagID: string,
  name: string,
};

export type SaleTax = {
  __typename: "SaleTax",
  taxID: string,
  rate: number,
  inclusive: boolean,
  total: number,
  totalTax: number,
  totalTaxFree: number,
};

export type SaleLineCustomField = {
  __typename: "SaleLineCustomField",
  customFieldID: string,
  name: string,
  value: string,
};

export type SaleLineOption = {
  __typename: "SaleLineOption",
  optionID: string,
  name: string,
  value: SaleLineOptionValue,
  total: number,
  totalTax: number,
  totalTaxFree: number,
  totalGlobalDiscountFree?: number | null,
  totalTaxFreeGlobalDiscountFree?: number | null,
  totalTaxGlobalDiscountFree?: number | null,
  unitPriceGlobalDiscountFree?: number | null,
  totalDiscountFree?: number | null,
  totalTaxFreeDiscountFree?: number | null,
  totalTaxDiscountFree?: number | null,
  unitPriceDiscountFree?: number | null,
};

export type SaleLineOptionValue = {
  __typename: "SaleLineOptionValue",
  name: string,
  price: number,
};

export type SaleDiscount = {
  __typename: "SaleDiscount",
  rate: number,
  type: DiscountType,
  discountID: string,
  discountName: string,
  amount: number,
};

export enum SaleLineOperationType {
  SALE = "SALE",
  REFUND = "REFUND",
}


export enum SaleLineState {
  OK = "OK",
  REFUNDED = "REFUNDED",
}


export type SalePayment = {
  __typename: "SalePayment",
  state: PaymentState,
  type: PaymentType,
  date: string,
  paymentMethodID: string,
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
  sourcePaymentID?: string | null,
  customerMovementID?: string | null,
  cashbookID?: string | null,
  amount: number,
  totalTaxFree?: number | null,
  totalTax?: number | null,
  taxes?:  Array<SaleTax | null > | null,
  paymentID: string,
  payment?: Payment | null,
  integrationPaymentID?: string | null,
  integrationRefundID?: string | null,
};

export enum PaymentState {
  DELETED = "DELETED",
  VALID = "VALID",
}


export enum PaymentType {
  IN = "IN",
  OUT = "OUT",
}


export type Payment = {
  __typename: "Payment",
  id: string,
  originalID?: string | null,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  shopID: string,
  test?: boolean | null,
  sourceID: string,
  sourceName: string,
  saleID: string,
  number?: number | null,
  date: string,
  state: PaymentState,
  type: PaymentType,
  paymentMethodID: string,
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
  sourcePaymentID?: string | null,
  customerMovementID?: string | null,
  cashbookID?: string | null,
  cashbook?: Cashbook | null,
  customer?: Customer | null,
  amount: number,
  totalTaxFree?: number | null,
  totalTax?: number | null,
  taxes?:  Array<SaleTax | null > | null,
  integrationPaymentID?: string | null,
  integrationRefundID?: string | null,
};

export type SaleStats = {
  __typename: "SaleStats",
  taxes?:  Array<GroupedTotals | null > | null,
  margin?: number | null,
};

export type MigrationReference = {
  __typename: "MigrationReference",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  test?: boolean | null,
  objectID: string,
  objectType: MigrationObjectType,
  reference: string,
  sourceDependencyID: string,
};

export enum MigrationObjectType {
  PRODUCT = "PRODUCT",
  CATEGORY = "CATEGORY",
  DISCOUNT = "DISCOUNT",
  TAX = "TAX",
  SALE = "SALE",
  ACTIVITY = "ACTIVITY",
  CUSTOMER = "CUSTOMER",
}


export enum BackgroundJobType {
  MIGRATION_CATALOG = "MIGRATION_CATALOG",
  MIGRATION_INVENTORY = "MIGRATION_INVENTORY",
  MIGRATION_CUSTOMER = "MIGRATION_CUSTOMER",
  MIGRATION_ACTIVITY = "MIGRATION_ACTIVITY",
  IMPORT_CATALOG = "IMPORT_CATALOG",
  IMPORT_INVENTORYMOVEMENT = "IMPORT_INVENTORYMOVEMENT",
  IMPORT_PRODUCT = "IMPORT_PRODUCT",
  IMPORT_PRODUCT_VARIANT = "IMPORT_PRODUCT_VARIANT",
  IMPORT_CATEGORY = "IMPORT_CATEGORY",
  IMPORT_DISCOUNT = "IMPORT_DISCOUNT",
  IMPORT_TAX = "IMPORT_TAX",
  IMPORT_TAG = "IMPORT_TAG",
  IMPORT_CUSTOM_FIELD = "IMPORT_CUSTOM_FIELD",
  IMPORT_OPTION = "IMPORT_OPTION",
  IMPORT_CUSTOMER = "IMPORT_CUSTOMER",
  IMPORT_PRODUCT_CUSTOM_FIELD = "IMPORT_PRODUCT_CUSTOM_FIELD",
  IMPORT_PRODUCT_OPTION = "IMPORT_PRODUCT_OPTION",
  IMPORT_PRODUCT_TAG = "IMPORT_PRODUCT_TAG",
  IMPORT_CATEGORY_CUSTOM_FIELD = "IMPORT_CATEGORY_CUSTOM_FIELD",
  IMPORT_CUSTOMER_CUSTOM_FIELD = "IMPORT_CUSTOMER_CUSTOM_FIELD",
}


export enum BackgroundJobState {
  SUCCESS = "SUCCESS",
  CANCELED = "CANCELED",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  STARTING = "STARTING",
}


export type BackgroundJobDetails = CatalogMigrationDetails | CustomerMigrationDetails | InventoryMigrationDetails | ActivityMigrationDetails | ImportDetails


export type CatalogMigrationDetails = {
  __typename: "CatalogMigrationDetails",
  PRODUCT?: ModelMigrationDetail | null,
  PRODUCT_VARIANT?: ModelMigrationDetail | null,
  CATEGORY?: ModelMigrationDetail | null,
  TAX?: ModelMigrationDetail | null,
  DISCOUNT?: ModelMigrationDetail | null,
};

export type ModelMigrationDetail = {
  __typename: "ModelMigrationDetail",
  total?: number | null,
  successCount?: number | null,
  errorCount?: number | null,
};

export type CustomerMigrationDetails = {
  __typename: "CustomerMigrationDetails",
  CUSTOMER?: ModelMigrationDetail | null,
};

export type InventoryMigrationDetails = {
  __typename: "InventoryMigrationDetails",
  INVENTORY?: ModelMigrationDetail | null,
};

export type ActivityMigrationDetails = {
  __typename: "ActivityMigrationDetails",
  PAYMENT_METHOD?: ModelMigrationDetail | null,
  SLOT?: ModelMigrationDetail | null,
  CASHBOOK?: ModelMigrationDetail | null,
  SALE?: ModelMigrationDetail | null,
  PAYMENT?: ModelMigrationDetail | null,
};

export type ImportDetails = {
  __typename: "ImportDetails",
  IMPORT?: ModelMigrationDetail | null,
};

export type CreateAccountInput = {
  firstName?: string | null,
  lastName?: string | null,
  color: ColorPalette,
  photo?: string | null,
  phone?: string | null,
  locale?: LanguageCode | null,
};

export enum LanguageCode {
  af = "af",
  af_ZA = "af_ZA",
  ar = "ar",
  ar_AE = "ar_AE",
  ar_BH = "ar_BH",
  ar_DZ = "ar_DZ",
  ar_EG = "ar_EG",
  ar_IQ = "ar_IQ",
  ar_JO = "ar_JO",
  ar_KW = "ar_KW",
  ar_LB = "ar_LB",
  ar_LY = "ar_LY",
  ar_MA = "ar_MA",
  ar_OM = "ar_OM",
  ar_QA = "ar_QA",
  ar_SA = "ar_SA",
  ar_SY = "ar_SY",
  ar_TN = "ar_TN",
  ar_YE = "ar_YE",
  az = "az",
  az_AZ = "az_AZ",
  be = "be",
  be_BY = "be_BY",
  bg = "bg",
  bg_BG = "bg_BG",
  bs_BA = "bs_BA",
  ca = "ca",
  ca_ES = "ca_ES",
  cs = "cs",
  cs_CZ = "cs_CZ",
  cy = "cy",
  cy_GB = "cy_GB",
  da = "da",
  da_DK = "da_DK",
  de = "de",
  de_AT = "de_AT",
  de_CH = "de_CH",
  de_DE = "de_DE",
  de_LI = "de_LI",
  de_LU = "de_LU",
  dv = "dv",
  dv_MV = "dv_MV",
  el = "el",
  el_GR = "el_GR",
  en = "en",
  en_AU = "en_AU",
  en_BZ = "en_BZ",
  en_CA = "en_CA",
  en_CB = "en_CB",
  en_GB = "en_GB",
  en_IE = "en_IE",
  en_JM = "en_JM",
  en_NZ = "en_NZ",
  en_PH = "en_PH",
  en_TT = "en_TT",
  en_US = "en_US",
  en_ZA = "en_ZA",
  en_ZW = "en_ZW",
  eo = "eo",
  es = "es",
  es_AR = "es_AR",
  es_BO = "es_BO",
  es_CL = "es_CL",
  es_CO = "es_CO",
  es_CR = "es_CR",
  es_DO = "es_DO",
  es_ES = "es_ES",
  es_GT = "es_GT",
  es_HN = "es_HN",
  es_MX = "es_MX",
  es_NI = "es_NI",
  es_PA = "es_PA",
  es_PE = "es_PE",
  es_PR = "es_PR",
  es_PY = "es_PY",
  es_SV = "es_SV",
  es_UY = "es_UY",
  es_VE = "es_VE",
  et = "et",
  et_EE = "et_EE",
  eu = "eu",
  eu_ES = "eu_ES",
  fa = "fa",
  fa_IR = "fa_IR",
  fi = "fi",
  fi_FI = "fi_FI",
  fo = "fo",
  fo_FO = "fo_FO",
  fr = "fr",
  fr_BE = "fr_BE",
  fr_CA = "fr_CA",
  fr_CH = "fr_CH",
  fr_FR = "fr_FR",
  fr_LU = "fr_LU",
  fr_MC = "fr_MC",
  gl = "gl",
  gl_ES = "gl_ES",
  gu = "gu",
  gu_IN = "gu_IN",
  he = "he",
  he_IL = "he_IL",
  hi = "hi",
  hi_IN = "hi_IN",
  hr = "hr",
  hr_BA = "hr_BA",
  hr_HR = "hr_HR",
  hu = "hu",
  hu_HU = "hu_HU",
  hy = "hy",
  hy_AM = "hy_AM",
  id = "id",
  id_ID = "id_ID",
  is = "is",
  is_IS = "is_IS",
  it = "it",
  it_CH = "it_CH",
  it_IT = "it_IT",
  ja = "ja",
  ja_JP = "ja_JP",
  ka = "ka",
  ka_GE = "ka_GE",
  kk = "kk",
  kk_KZ = "kk_KZ",
  kn = "kn",
  kn_IN = "kn_IN",
  ko = "ko",
  ko_KR = "ko_KR",
  kok = "kok",
  kok_IN = "kok_IN",
  ky = "ky",
  ky_KG = "ky_KG",
  lt = "lt",
  lt_LT = "lt_LT",
  lv = "lv",
  lv_LV = "lv_LV",
  mi = "mi",
  mi_NZ = "mi_NZ",
  mk = "mk",
  mk_MK = "mk_MK",
  mn = "mn",
  mn_MN = "mn_MN",
  mr = "mr",
  mr_IN = "mr_IN",
  ms = "ms",
  ms_BN = "ms_BN",
  ms_MY = "ms_MY",
  mt = "mt",
  mt_MT = "mt_MT",
  nb = "nb",
  nb_NO = "nb_NO",
  nl = "nl",
  nl_BE = "nl_BE",
  nl_NL = "nl_NL",
  nn_NO = "nn_NO",
  ns = "ns",
  ns_ZA = "ns_ZA",
  pa = "pa",
  pa_IN = "pa_IN",
  pl = "pl",
  pl_PL = "pl_PL",
  ps = "ps",
  ps_AR = "ps_AR",
  pt = "pt",
  pt_BR = "pt_BR",
  pt_PT = "pt_PT",
  qu = "qu",
  qu_BO = "qu_BO",
  qu_EC = "qu_EC",
  qu_PE = "qu_PE",
  ro = "ro",
  ro_RO = "ro_RO",
  ru = "ru",
  ru_RU = "ru_RU",
  sa = "sa",
  sa_IN = "sa_IN",
  se = "se",
  se_FI = "se_FI",
  se_NO = "se_NO",
  se_SE = "se_SE",
  sk = "sk",
  sk_SK = "sk_SK",
  sl = "sl",
  sl_SI = "sl_SI",
  sq = "sq",
  sq_AL = "sq_AL",
  sr_BA = "sr_BA",
  sr_SP = "sr_SP",
  sv = "sv",
  sv_FI = "sv_FI",
  sv_SE = "sv_SE",
  sw = "sw",
  sw_KE = "sw_KE",
  syr = "syr",
  syr_SY = "syr_SY",
  ta = "ta",
  ta_IN = "ta_IN",
  te = "te",
  te_IN = "te_IN",
  th = "th",
  th_TH = "th_TH",
  tl = "tl",
  tl_PH = "tl_PH",
  tn = "tn",
  tn_ZA = "tn_ZA",
  tr = "tr",
  tr_TR = "tr_TR",
  tt = "tt",
  tt_RU = "tt_RU",
  ts = "ts",
  uk = "uk",
  uk_UA = "uk_UA",
  ur = "ur",
  ur_PK = "ur_PK",
  uz = "uz",
  uz_UZ = "uz_UZ",
  vi = "vi",
  vi_VN = "vi_VN",
  xh = "xh",
  xh_ZA = "xh_ZA",
  zh = "zh",
  zh_CN = "zh_CN",
  zh_HK = "zh_HK",
  zh_MO = "zh_MO",
  zh_SG = "zh_SG",
  zh_TW = "zh_TW",
  zu = "zu",
  zu_ZA = "zu_ZA",
}


export type Account = {
  __typename: "Account",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  firstName?: string | null,
  lastName?: string | null,
  color: ColorPalette,
  iconText?: string | null,
  photo?: string | null,
  email: string,
  phone?: string | null,
  locale?: LanguageCode | null,
  companies?: ModelAccountCompanyConnection | null,
  catalog?: ModelAccountCatalogConnection | null,
  shops?: ModelAccountShopConnection | null,
  sellerCodes?: ModelSellerCodeConnection | null,
  intercomHMAC?: string | null,
};

export type ModelAccountCompanyConnection = {
  __typename: "ModelAccountCompanyConnection",
  items?:  Array<AccountCompany | null > | null,
  nextToken?: string | null,
};

export type ModelAccountCatalogConnection = {
  __typename: "ModelAccountCatalogConnection",
  items?:  Array<AccountCatalog | null > | null,
  nextToken?: string | null,
};

export type ModelAccountShopConnection = {
  __typename: "ModelAccountShopConnection",
  items?:  Array<AccountShop | null > | null,
  nextToken?: string | null,
};

export type ModelSellerCodeConnection = {
  __typename: "ModelSellerCodeConnection",
  items?:  Array<SellerCode | null > | null,
  nextToken?: string | null,
};

export type UpdateAccountInput = {
  firstName?: string | null,
  lastName?: string | null,
  color?: ColorPalette | null,
  photo?: string | null,
  phone?: string | null,
  locale?: LanguageCode | null,
};

export type CreateSellerCodeInput = {
  companyID: string,
  code: string,
};

export type UpdateSellerCodeInput = {
  companyID: string,
  code: string,
};

export type DeleteSellerCodeInput = {
  companyID: string,
};

export type CreateCompanyInput = {
  name: string,
  address?: string | null,
  country?: CountryCode | null,
  currency?: CurrencyCode | null,
  vat?: string | null,
};

export type UpdateCompanyInput = {
  id: string,
  name?: string | null,
  address?: string | null,
  vat?: string | null,
};

export type DeleteCompanyInput = {
  id: string,
};

export type CompanyResults = {
  __typename: "CompanyResults",
  company?: Company | null,
  catalog?: Catalog | null,
  shops?:  Array<Shop | null > | null,
  companyAccounts?:  Array<AccountCompany | null > | null,
  shopAccounts?:  Array<AccountShop | null > | null,
  catalogAccounts?:  Array<AccountCatalog | null > | null,
};

export type CreateShopInput = {
  companyID: string,
  name: string,
  address?: AddressInput | null,
  siret?: string | null,
  vat?: string | null,
  phone?: string | null,
  footnote?: string | null,
  logo?: string | null,
  websiteURL?: string | null,
  brandName: string,
  email?: string | null,
};

export type AddressInput = {
  address?: string | null,
  zipcode?: string | null,
  city?: string | null,
  country?: CountryCode | null,
};

export type UpdateShopInput = {
  id: string,
  name?: string | null,
  address?: AddressInput | null,
  siret?: string | null,
  vat?: string | null,
  phone?: string | null,
  footnote?: string | null,
  logo?: string | null,
  websiteURL?: string | null,
  brandName?: string | null,
  email?: string | null,
};

export type DeleteShopInput = {
  id: string,
};

export type ShopResults = {
  __typename: "ShopResults",
  shop?: Shop | null,
  shopAccounts?:  Array<AccountShop | null > | null,
};

export type CreateCompanySettingInput = {
  companyID: string,
  key: string,
  value?: string | null,
};

export type CompanySetting = {
  __typename: "CompanySetting",
  id: string,
  updatedAt: string,
  createdAt: string,
  companyID: string,
  accountID?: string | null,
  key: string,
  value: string,
};

export type UpdateCompanySettingInput = {
  id: string,
  key: string,
  value?: string | null,
};

export type DeleteCompanySettingInput = {
  id: string,
};

export type CreateAccountInvitationInput = {
  companyID: string,
  email: string,
  role: AccountRole,
  shops: Array< string >,
};

export type AccountInvitation = {
  __typename: "AccountInvitation",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  companyName: string,
  email: string,
  role: AccountRole,
  shops: Array< string | null >,
  expiresAt: string,
  status: AccountInvitationStatus,
  senderID: string,
  senderName: string,
  senderEmail: string,
};

export enum AccountInvitationStatus {
  SENT = "SENT",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}


export type UpdateAccountInvitationInput = {
  id: string,
};

export type UpdateUserRoleInput = {
  id: string,
  companyID: string,
  role: AccountRole,
};

export type User = {
  __typename: "User",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  firstName?: string | null,
  lastName?: string | null,
  color: ColorPalette,
  iconText?: string | null,
  photo?: string | null,
  email: string,
  company?: AccountCompany | null,
  catalog?: AccountCatalog | null,
  shops?:  Array<AccountShop | null > | null,
};

export type AddUserToShopInput = {
  id: string,
  shopID: string,
};

export type RemoveUserFromShopInput = {
  id: string,
  shopID: string,
};

export type RemoveUserInput = {
  id: string,
  companyID: string,
};

export type CreateSlotInput = {
  shopID: string,
  name: string,
  status?: SlotStatus | null,
};

export enum SlotStatus {
  IDLE = "IDLE",
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  MIGRATED_V4 = "MIGRATED_V4",
}


export type Slot = {
  __typename: "Slot",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  shopID: string,
  name: string,
  status?: SlotStatus | null,
};

export type UpdateSlotInput = {
  id: string,
  name?: string | null,
  status?: SlotStatus | null,
};

export type SendSlotEmailInput = {
  id: string,
  email: string,
};

export type CreateEventInput = {
  id?: string | null,
  originalID?: string | null,
  updatedAt: string,
  createdAt: string,
  companyID: string,
  shopID?: string | null,
  slotID?: string | null,
  userID?: string | null,
  test: boolean,
  date: string,
  type: EventType,
  code: number,
  objectType: EventObjectType,
  objectID: string,
  action: string,
  actionData?: string | null,
  hash?: string | null,
  version: string,
};

export type CreateUserNotificationInput = {
  companyID: string,
  eventObjectType: EventObjectType,
  eventAction: string,
  action: NotificationActionInput,
  active?: boolean | null,
};

export type NotificationActionInput = {
  actionType: NotificationActionType,
  webhook?: NotificationActionDetailsWebhookInput | null,
};

export type NotificationActionDetailsWebhookInput = {
  url: string,
};

export type UpdateUserNotificationInput = {
  id: string,
  active?: boolean | null,
};

export type DeleteUserNotificationInput = {
  id: string,
};

export type GridCellInput = {
  updatedAt?: string | null,
  catalogID: string,
  deviceID?: string | null,
  page: number,
  column: number,
  row: number,
  type: GridCellType,
  objectID: string,
};

export type DeleteGridCellInput = {
  id: string,
};

export type CreateProductInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  catalogID: string,
  name: string,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
  categoryID: string,
  taxID: string,
  bulkType?: BulkType | null,
  buyPrice?: number | null,
  sellPrice?: number | null,
  barcode?: string | null,
  reference?: string | null,
};

export type UpdateProductInput = {
  id: string,
  updatedAt?: string | null,
  name?: string | null,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
  categoryID?: string | null,
  taxID?: string | null,
  buyPrice?: number | null,
  sellPrice?: number | null,
  barcode?: string | null,
  reference?: string | null,
};

export type DeleteProductInput = {
  id: string,
};

export type ProductOptionInput = {
  productID: string,
  optionID: string,
};

export type ProductTagInput = {
  productID: string,
  tagID: string,
};

export type CreateProductCustomFieldInput = {
  productID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
  value: string,
};

export type UpdateProductCustomFieldInput = {
  productID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
  value?: string | null,
};

export type DeleteProductCustomFieldInput = {
  productID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
};

export type GenerateProductVariantsInput = {
  productID: string,
  variations: Array< ProductVariationInput | null >,
};

export type ProductVariationInput = {
  name: string,
  options: Array< string | null >,
};

export type ProductVariationResults = {
  __typename: "ProductVariationResults",
  catalogID: string,
  test?: boolean | null,
  productID: string,
  product?: Product | null,
  variations?:  Array<ProductVariation | null > | null,
  insertions?:  Array<ProductVariant | null > | null,
  modifications?:  Array<ProductVariant | null > | null,
  deletions?:  Array<ProductVariant | null > | null,
};

export type CreateProductVariantInput = {
  productID: string,
  variantID?: string | null,
  options: Array< VariationOptionInput | null >,
  taxID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  buyPrice?: number | null,
  sellPrice?: number | null,
  barcode?: string | null,
  reference?: string | null,
};

export type VariationOptionInput = {
  name: string,
  value: string,
};

export type UpdateProductVariantInput = {
  productID: string,
  variantID: string,
  options?: Array< VariationOptionInput | null > | null,
  updatedAt?: string | null,
  taxID?: string | null,
  buyPrice?: number | null,
  sellPrice?: number | null,
  barcode?: string | null,
  reference?: string | null,
};

export type DeleteProductVariantInput = {
  productID: string,
  variantID: string,
};

export type BulkCreateProductVariantsInput = {
  productID: string,
  variants: Array< BulkCreateProductVariantsContentInput | null >,
};

export type BulkCreateProductVariantsContentInput = {
  variantID?: string | null,
  options?: Array< VariationOptionInput | null > | null,
  taxID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  buyPrice?: number | null,
  sellPrice?: number | null,
  barcode?: string | null,
  reference?: string | null,
};

export type CreateProductVariationOptionInput = {
  productID: string,
  name: string,
  option: string,
};

export type UpdateProductVariationOptionInput = {
  productID: string,
  name: string,
  option: string,
  newOption: string,
};

export type DeleteProductVariationOptionInput = {
  productID: string,
  name: string,
  option: string,
};

export type UpdateProductVariationNameInput = {
  productID: string,
  name: string,
  newName: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  catalogID: string,
  name: string,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
};

export type UpdateCategoryInput = {
  id: string,
  updatedAt?: string | null,
  name?: string | null,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateCategoryCustomFieldInput = {
  categoryID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
  value: string,
};

export type UpdateCategoryCustomFieldInput = {
  categoryID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
  value?: string | null,
};

export type DeleteCategoryCustomFieldInput = {
  categoryID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
};

export type CreateCustomFieldInput = {
  createdAt?: string | null,
  updatedAt?: string | null,
  catalogID: string,
  key: string,
  objectType: CustomFieldObjectType,
  valueType: CustomFieldValueType,
  name: string,
};

export type UpdateCustomFieldInput = {
  id: string,
  updatedAt?: string | null,
  name?: string | null,
};

export type DeleteCustomFieldInput = {
  id: string,
};

export type CreateDiscountInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  catalogID: string,
  name: string,
  color?: ColorPalette | null,
  photo?: string | null,
  type: DiscountType,
  rate?: number | null,
};

export type UpdateDiscountInput = {
  id: string,
  updatedAt?: string | null,
  name?: string | null,
  color?: ColorPalette | null,
  photo?: string | null,
  type?: DiscountType | null,
  rate?: number | null,
};

export type DeleteDiscountInput = {
  id: string,
};

export type CreateOptionInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  catalogID: string,
  name: string,
  values: Array< OptionValueInput | null >,
};

export type OptionValueInput = {
  name: string,
  price: number,
};

export type UpdateOptionInput = {
  id: string,
  updatedAt?: string | null,
  name?: string | null,
  values?: Array< OptionValueInput | null > | null,
};

export type DeleteOptionInput = {
  id: string,
};

export type CreatePackInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  catalogID: string,
  name: string,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
  barcode?: string | null,
  reference?: string | null,
  sellPrice?: number | null,
  packVariations: Array< PackVariationInput | null >,
};

export type PackVariationInput = {
  options: Array< PackVariationOptionInput >,
};

export type PackVariationOptionInput = {
  productID: string,
  quantity: number,
  additionalPrice: number,
};

export type UpdatePackInput = {
  id: string,
  updatedAt?: string | null,
  name?: string | null,
  color?: ColorPalette | null,
  iconText?: string | null,
  photo?: string | null,
  barcode?: string | null,
  reference?: string | null,
  sellPrice?: number | null,
  packVariations?: Array< PackVariationInput | null > | null,
};

export type DeletePackInput = {
  id: string,
};

export type CreatePackCustomFieldInput = {
  packID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
  value: string,
};

export type UpdatePackCustomFieldInput = {
  packID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
  value?: string | null,
};

export type DeletePackCustomFieldInput = {
  packID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
};

export type CreateTagInput = {
  catalogID: string,
  name: string,
};

export type UpdateTagInput = {
  id: string,
  name?: string | null,
};

export type DeleteTagInput = {
  id: string,
};

export type CreateTaxInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  catalogID: string,
  rate: number,
};

export type UpdateTaxInput = {
  id: string,
  updatedAt?: string | null,
  rate?: number | null,
};

export type DeleteTaxInput = {
  id: string,
};

export type CreateInventoryMovementInput = {
  companyID: string,
  motive?: string | null,
  state: InventoryMovementState,
  origin?: InventoryMovementOrigin | null,
  originID?: string | null,
  destination?: InventoryMovementDestination | null,
  destinationID?: string | null,
  movements?: Array< CreateInventoryMovementLineInput | null > | null,
};

export enum InventoryMovementState {
  DRAFT = "DRAFT",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
}


export enum InventoryMovementOrigin {
  SHOP = "SHOP",
  SUPPLIER = "SUPPLIER",
}


export enum InventoryMovementDestination {
  SHOP = "SHOP",
  TRASH = "TRASH",
}


export type CreateInventoryMovementLineInput = {
  productID: string,
  variantID?: string | null,
  quantity?: number | null,
  price?: number | null,
};

export type InventoryMovement = {
  __typename: "InventoryMovement",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  test?: boolean | null,
  state: InventoryMovementState,
  stateDate: string,
  motive?: string | null,
  origin?: InventoryMovementOrigin | null,
  originID?: string | null,
  destination?: InventoryMovementDestination | null,
  destinationID?: string | null,
  totalQuantity?: number | null,
  movements?: ModelInventoryMovementLineConnection | null,
  products?: ModelInventoryMovementLineProductConnection | null,
  history?: ModelInventoryMovementHistoryConnection | null,
};

export type ModelInventoryMovementLineConnection = {
  __typename: "ModelInventoryMovementLineConnection",
  items?:  Array<InventoryMovementLine | null > | null,
  nextToken?: string | null,
};

export type InventoryMovementLine = {
  __typename: "InventoryMovementLine",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  inventoryMovementID: string,
  productID: string,
  variantID?: string | null,
  details?: InventoryMovementLineDetails | null,
  quantity?: number | null,
  actualQuantity?: number | null,
  price?: number | null,
  inventoryQuantities?:  Array<ProductInventoryQuantity | null > | null,
};

export type InventoryMovementLineDetails = {
  __typename: "InventoryMovementLineDetails",
  product?: InventoryMovementLineDetailsProduct | null,
  variant?: InventoryMovementLineDetailsProductVariant | null,
};

export type InventoryMovementLineDetailsProduct = {
  __typename: "InventoryMovementLineDetailsProduct",
  name?: string | null,
  sellPrice?: number | null,
  buyPrice?: number | null,
  bulkType?: string | null,
  barcode?: string | null,
  reference?: string | null,
  categoryID?: string | null,
  taxID?: string | null,
  category?: InventoryMovementLineDetailsCategory | null,
  tax?: InventoryMovementLineDetailsTax | null,
  tags?:  Array<InventoryMovementLineDetailsProductTag | null > | null,
  customFields?:  Array<InventoryMovementLineDetailsProductCustomField | null > | null,
};

export type InventoryMovementLineDetailsCategory = {
  __typename: "InventoryMovementLineDetailsCategory",
  name?: string | null,
};

export type InventoryMovementLineDetailsTax = {
  __typename: "InventoryMovementLineDetailsTax",
  rate?: number | null,
};

export type InventoryMovementLineDetailsProductTag = {
  __typename: "InventoryMovementLineDetailsProductTag",
  tagID?: string | null,
  name?: string | null,
};

export type InventoryMovementLineDetailsProductCustomField = {
  __typename: "InventoryMovementLineDetailsProductCustomField",
  customFieldID?: string | null,
  name?: string | null,
  objecType?: string | null,
  valueType?: string | null,
  value?: string | null,
};

export type InventoryMovementLineDetailsProductVariant = {
  __typename: "InventoryMovementLineDetailsProductVariant",
  nameShort?: string | null,
  nameLong?: string | null,
  sellPrice?: number | null,
  buyPrice?: number | null,
  barcode?: string | null,
  reference?: string | null,
  options?:  Array<InventoryMovementLineDetailsProductVariantionOption | null > | null,
};

export type InventoryMovementLineDetailsProductVariantionOption = {
  __typename: "InventoryMovementLineDetailsProductVariantionOption",
  name?: string | null,
  value?: string | null,
};

export type ModelInventoryMovementLineProductConnection = {
  __typename: "ModelInventoryMovementLineProductConnection",
  items:  Array<InventoryMovementLineProduct | null >,
  nextToken?: string | null,
};

export type InventoryMovementLineProduct = {
  __typename: "InventoryMovementLineProduct",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  inventoryMovementID: string,
  productID: string,
  details?: InventoryMovementLineDetailsProduct | null,
  hasVariants?: boolean | null,
  variants?: ModelInventoryMovementLineProductVariantConnection | null,
  variantsCount?: number | null,
  variantsSelectedAll?: boolean | null,
  variantsAll?: ModelInventoryMovementProductVariantConnection | null,
  variantsCountAll?: number | null,
  quantity?: number | null,
  actualQuantity?: number | null,
  price?: number | null,
  inventoryQuantities?:  Array<ProductInventoryQuantity | null > | null,
};

export type ModelInventoryMovementLineProductVariantConnection = {
  __typename: "ModelInventoryMovementLineProductVariantConnection",
  items:  Array<InventoryMovementLineProductVariant | null >,
  nextToken?: string | null,
};

export type InventoryMovementLineProductVariant = {
  __typename: "InventoryMovementLineProductVariant",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  inventoryMovementProductID: string,
  productID: string,
  variantID?: string | null,
  details?: InventoryMovementLineDetailsProductVariant | null,
  quantity?: number | null,
  actualQuantity?: number | null,
  price?: number | null,
  inventoryQuantities?:  Array<ProductInventoryQuantity | null > | null,
};

export type ModelInventoryMovementProductVariantConnection = {
  __typename: "ModelInventoryMovementProductVariantConnection",
  items?:  Array<InventoryMovementProductVariant | null > | null,
  nextToken?: string | null,
};

export type InventoryMovementProductVariant = {
  __typename: "InventoryMovementProductVariant",
  productID: string,
  variantID: string,
  details?: InventoryMovementLineDetailsProductVariant | null,
  line?: InventoryMovementLineProductVariant | null,
  inventoryQuantities?:  Array<ProductInventoryQuantity | null > | null,
};

export type ModelInventoryMovementHistoryConnection = {
  __typename: "ModelInventoryMovementHistoryConnection",
  items?:  Array<InventoryMovementHistory | null > | null,
  nextToken?: string | null,
};

export type InventoryMovementHistory = {
  __typename: "InventoryMovementHistory",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  inventoryMovementID: string,
  state: InventoryMovementState,
  date: string,
  motive?: string | null,
};

export type UpdateInventoryMovementInput = {
  id: string,
  state: InventoryMovementState,
  motive?: string | null,
  origin?: InventoryMovementOrigin | null,
  originID?: string | null,
  destination?: InventoryMovementDestination | null,
  destinationID?: string | null,
  deletions?: Array< DeleteInventoryMovementLineInput | null > | null,
  insertions?: Array< CreateInventoryMovementLineInput | null > | null,
  modifications?: Array< UpdateInventoryMovementLineInput | null > | null,
};

export type DeleteInventoryMovementLineInput = {
  id: string,
};

export type UpdateInventoryMovementLineInput = {
  id: string,
  quantity?: number | null,
  price?: number | null,
};

export type CancelInventoryMovementInput = {
  id: string,
  motive?: string | null,
};

export type ResetProductInventoryInput = {
  shopID: string,
  productID: string,
  variantID?: string | null,
};

export type ResetProductInventoryHistory = {
  __typename: "ResetProductInventoryHistory",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  shopID: string,
  test?: boolean | null,
  productID: string,
  variantID?: string | null,
  salablePK: string,
  shopSalablePK: string,
  date: string,
  quantity?: number | null,
  buyPrice?: number | null,
};

export type ResetInventoryInput = {
  shopID: string,
};

export type ResetInventoryHistory = {
  __typename: "ResetInventoryHistory",
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  companyID: string,
  shopID: string,
  test?: boolean | null,
  date: string,
};

export type ProductInventoryQuantityInput = {
  id: string,
  updatedAt: string,
  createdAt: string,
  deprecated?: boolean | null,
  shopID: string,
  test?: boolean | null,
  productID: string,
  variantID?: string | null,
  quantity?: number | null,
  resetDate?: string | null,
};

export type CreatePaymentMethodInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  companyID: string,
  name: string,
  color: ColorPalette,
  type: PaymentMethodType,
  changeType?: PaymentMethodChangeType | null,
  opensCashDrawer: boolean,
  enabled: boolean,
};

export type UpdatePaymentMethodInput = {
  id: string,
  updatedAt?: string | null,
  name?: string | null,
  color?: ColorPalette | null,
  changeType?: PaymentMethodChangeType | null,
  opensCashDrawer?: boolean | null,
  enabled?: boolean | null,
};

export type DeletePaymentMethodInput = {
  id: string,
};

export type CreateCustomerInput = {
  id?: string | null,
  originalID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  companyID: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  phoneCode?: string | null,
  companyName?: string | null,
  color?: ColorPalette | null,
  iconText?: string | null,
  address?: AddressInput | null,
  note?: string | null,
  fidelityCardNumber?: string | null,
  externalSaleTotal?: number | null,
  externalSaleCount?: number | null,
};

export type UpdateCustomerInput = {
  id: string,
  updatedAt?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  phoneCode?: string | null,
  companyName?: string | null,
  color?: ColorPalette | null,
  iconText?: string | null,
  address?: AddressInput | null,
  note?: string | null,
  fidelityCardNumber?: string | null,
};

export type MergeCustomersInput = {
  id: string,
  updatedAt?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  phoneCode?: string | null,
  companyName?: string | null,
  color?: ColorPalette | null,
  iconText?: string | null,
  address?: AddressInput | null,
  note?: string | null,
  fidelityCardNumber?: string | null,
  customFields?: Array< UpdateCustomerCustomFieldInput | null > | null,
  toMerge?: Array< string > | null,
};

export type UpdateCustomerCustomFieldInput = {
  customerID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
  value?: string | null,
};

export type MergeCustomersAutoInput = {
  id: string,
  toMerge?: Array< string > | null,
};

export type DeleteCustomerInput = {
  id: string,
};

export type CreateCustomerAccountMovementInput = {
  id?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  customerID: string,
  state: CustomerAccountMovementState,
  type: CustomerAccountMovementType,
  amount: number,
  saleID?: string | null,
  paymentID?: string | null,
  movementInDetails?: CustomerAccountMovementInDetailsInput | null,
};

export type CustomerAccountMovementInDetailsInput = {
  paymentMethodID: string,
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
};

export type UpdateCustomerAccountMovementInput = {
  id: string,
  updatedAt?: string | null,
  state?: CustomerAccountMovementState | null,
};

export type CreateCustomerCustomFieldInput = {
  customerID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
  value: string,
};

export type DeleteCustomerCustomFieldInput = {
  customerID: string,
  customFieldID?: string | null,
  customFieldKey?: string | null,
};

export type CreateSaleInput = {
  id?: string | null,
  originalID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  shopID: string,
  sourceID: string,
  sourceName: string,
  cashbookID?: string | null,
  name?: string | null,
  number?: number | null,
  state: SaleType,
  refundStatus?: SaleStatus | null,
  refundedStatus?: SaleStatus | null,
  paymentStatus?: SaleStatus | null,
  note?: string | null,
  targetID?: string | null,
  openedAt: string,
  closedAt?: string | null,
  pendingAt?: string | null,
  customerID?: string | null,
  sellerID?: string | null,
  lines?: Array< SaleLineInput | null > | null,
  discounts?: Array< SaleDiscountInput | null > | null,
  payments?: Array< SalePaymentInput | null > | null,
  total: number,
  totalDiscount: number,
  totalTaxFree: number,
  totalTax: number,
  totalRest: number,
  migrationV4?: boolean | null,
};

export type SaleLineInput = {
  details: SaleLineDetailsInput,
  quantity: number,
  price: number,
  refundedQuantity?: number | null,
  discounts?: Array< SaleDiscountInput | null > | null,
  note?: string | null,
  operationType: SaleLineOperationType,
  state: SaleLineState,
  targetSaleLineIndex?: number | null,
  motive?: string | null,
  totalSellPrice: number,
  totalDiscount: number,
  total: number,
  totalTaxFree: number,
  totalTax: number,
  totalGlobalDiscountFree?: number | null,
  totalTaxFreeGlobalDiscountFree?: number | null,
  totalTaxGlobalDiscountFree?: number | null,
  unitPriceGlobalDiscountFree?: number | null,
  totalDiscountFree?: number | null,
  totalTaxFreeDiscountFree?: number | null,
  totalTaxDiscountFree?: number | null,
  unitPriceDiscountFree?: number | null,
};

export type SaleLineDetailsInput = {
  salableType: SalableType,
  product?: SaleLineProductDetailsInput | null,
  pack?: SaleLinePackDetailsInput | null,
};

export enum SalableType {
  PRODUCT = "PRODUCT",
  PACK = "PACK",
}


export type SaleLineProductDetailsInput = {
  productID: string,
  name: string,
  bulkType?: BulkType | null,
  variant?: SaleLineVariantInput | null,
  category: SaleLineCategoryInput,
  tax: SaleTaxInput,
  tags?: Array< SaleLineTagInput | null > | null,
  customFields?: Array< SaleLineCustomFieldInput | null > | null,
  options?: Array< SaleLineOptionInput | null > | null,
  total: number,
  totalTax: number,
  totalTaxFree: number,
  totalGlobalDiscountFree?: number | null,
  totalTaxFreeGlobalDiscountFree?: number | null,
  totalTaxGlobalDiscountFree?: number | null,
  unitPriceGlobalDiscountFree?: number | null,
  totalDiscountFree?: number | null,
  totalTaxFreeDiscountFree?: number | null,
  totalTaxDiscountFree?: number | null,
  unitPriceDiscountFree?: number | null,
  virtualDiscounts?: Array< SaleDiscountInput | null > | null,
};

export type SaleLineVariantInput = {
  variantID: string,
  options: Array< VariationOptionInput | null >,
};

export type SaleLineCategoryInput = {
  categoryID: string,
  name: string,
  tags?: Array< SaleLineTagInput | null > | null,
};

export type SaleLineTagInput = {
  tagID: string,
  name: string,
};

export type SaleTaxInput = {
  taxID: string,
  rate: number,
  inclusive: boolean,
  total: number,
  totalTax: number,
  totalTaxFree: number,
};

export type SaleLineCustomFieldInput = {
  customFieldID: string,
  name: string,
  value: string,
};

export type SaleLineOptionInput = {
  optionID: string,
  name: string,
  value: SaleLineOptionValueInput,
  total: number,
  totalTax: number,
  totalTaxFree: number,
  totalGlobalDiscountFree?: number | null,
  totalTaxFreeGlobalDiscountFree?: number | null,
  totalTaxGlobalDiscountFree?: number | null,
  unitPriceGlobalDiscountFree?: number | null,
  totalDiscountFree?: number | null,
  totalTaxFreeDiscountFree?: number | null,
  totalTaxDiscountFree?: number | null,
  unitPriceDiscountFree?: number | null,
};

export type SaleLineOptionValueInput = {
  name: string,
  price: number,
};

export type SaleDiscountInput = {
  rate: number,
  type: DiscountType,
  discountID: string,
  discountName: string,
  amount: number,
};

export type SaleLinePackDetailsInput = {
  packID: string,
  name: string,
  packVariant: Array< SaleLinePackOptionInput | null >,
  customFields?: Array< SaleLineCustomFieldInput | null > | null,
};

export type SaleLinePackOptionInput = {
  details: SaleLineProductDetailsInput,
  quantity: number,
  additionalPrice: number,
  basePrice: number,
  total: number,
  totalTaxFree: number,
  totalTax: number,
  totalGlobalDiscountFree?: number | null,
  totalTaxFreeGlobalDiscountFree?: number | null,
  totalTaxGlobalDiscountFree?: number | null,
  unitPriceGlobalDiscountFree?: number | null,
  totalDiscountFree?: number | null,
  totalTaxFreeDiscountFree?: number | null,
  totalTaxDiscountFree?: number | null,
  unitPriceDiscountFree?: number | null,
};

export type SalePaymentInput = {
  state: PaymentState,
  type: PaymentType,
  date: string,
  paymentMethodID: string,
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
  sourcePaymentID?: string | null,
  customerMovementID?: string | null,
  cashbookID?: string | null,
  amount: number,
  totalTaxFree?: number | null,
  totalTax?: number | null,
  taxes?: Array< SaleTaxInput | null > | null,
  paymentID?: string | null,
  integrationPaymentID?: string | null,
  integrationRefundID?: string | null,
};

export type UpdateSaleInput = {
  id: string,
  updatedAt?: string | null,
  sourceID?: string | null,
  sourceName?: string | null,
  cashbookID?: string | null,
  name?: string | null,
  number?: number | null,
  state?: SaleType | null,
  refundStatus?: SaleStatus | null,
  refundedStatus?: SaleStatus | null,
  paymentStatus?: SaleStatus | null,
  note?: string | null,
  targetID?: string | null,
  closedAt?: string | null,
  pendingAt?: string | null,
  customerID?: string | null,
  sellerID?: string | null,
  lines?: Array< SaleLineInput | null > | null,
  discounts?: Array< SaleDiscountInput | null > | null,
  payments?: Array< SalePaymentInput | null > | null,
  total?: number | null,
  totalDiscount?: number | null,
  totalTaxFree?: number | null,
  totalTax?: number | null,
  totalRest?: number | null,
};

export type DeleteSaleInput = {
  id: string,
};

export type CreateCashbookInput = {
  id?: string | null,
  originalID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  shopID: string,
  sourceID: string,
  sourceName: string,
  number: number,
  state: CashbookState,
  total?: number | null,
  totalTaxFree?: number | null,
  openedAt: string,
  closedAt?: string | null,
  note?: string | null,
  openingSellerID?: string | null,
  closingSellerID?: string | null,
  movements?: Array< CashbookMovementInput | null > | null,
  openingCashFund?: Array< CashbookPaymentMethodCashFundInput | null > | null,
  closingCashFund?: Array< CashbookPaymentMethodCashFundInput | null > | null,
  totalExpected?: number | null,
  totalDifference?: number | null,
  customFields?: Array< CashbookCustomFieldInput | null > | null,
  migrationV4?: boolean | null,
};

export type CashbookMovementInput = {
  type: CashbookMovementType,
  amount: number,
  motive?: string | null,
  createdAt: string,
};

export type CashbookPaymentMethodCashFundInput = {
  paymentMethodID: string,
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
  total: number,
};

export type CashbookCustomFieldInput = {
  customFieldID: string,
  name: string,
  valueType: CustomFieldValueType,
  value: string,
};

export type UpdateCashbookInput = {
  id: string,
  updatedAt?: string | null,
  state?: CashbookState | null,
  total?: number | null,
  totalTaxFree?: number | null,
  closedAt?: string | null,
  note?: string | null,
  closingSellerID?: string | null,
  movements?: Array< CashbookMovementInput | null > | null,
  closingCashFund?: Array< CashbookPaymentMethodCashFundInput | null > | null,
  totalExpected?: number | null,
  totalDifference?: number | null,
  customFields?: Array< CashbookCustomFieldInput | null > | null,
};

export type DeleteCashbookInput = {
  id: string,
};

export type CreatePaymentInput = {
  id: string,
  originalID?: string | null,
  shopID: string,
  saleID: string,
  sourceID: string,
  sourceName: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  state: PaymentState,
  type: PaymentType,
  date: string,
  number?: number | null,
  paymentMethodID: string,
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
  sourcePaymentID?: string | null,
  customerMovementID?: string | null,
  cashbookID?: string | null,
  amount: number,
  totalTaxFree?: number | null,
  totalTax?: number | null,
  taxes?: Array< SaleTaxInput | null > | null,
  integrationPaymentID?: string | null,
  integrationRefundID?: string | null,
  migrationV4?: boolean | null,
};

export type UpdatePaymentInput = {
  id: string,
  updatedAt?: string | null,
  state?: PaymentState | null,
  amount: number,
  totalTaxFree?: number | null,
  totalTax?: number | null,
  taxes?: Array< SaleTaxInput | null > | null,
};

export type DeletePaymentInput = {
  id: string,
};

export type UploadImageInput = {
  id: string,
  type: ImageModelType,
  base64Image?: string | null,
  urlImage?: string | null,
};

export enum ImageModelType {
  PRODUCT = "PRODUCT",
  PACK = "PACK",
  SHOP = "SHOP",
  CATEGORY = "CATEGORY",
}


export type DeleteImageInput = {
  id: string,
  type: ImageModelType,
};

export type StripePaymentReceipt = {
  __typename: "StripePaymentReceipt",
  cardNetwork: string,
  contactlessInfo?: string | null,
  applicationID: string,
  applicationName: string,
  date: string,
  merchantDescriptor: string,
  merchantContractNumber: string,
  merchantBankCode: string,
  cardNumber: string,
  applicationCryptogram: string,
  transactionFileNumber: string,
  amount: number,
  transactionType: string,
  merchantFooter: string,
  destinationFooterMerchant: string,
  destinationFooterCustomer: string,
};

export type DashboardStats = {
  __typename: "DashboardStats",
  content?: DashboardStatsContent | null,
  byShop?:  Array<GroupedDashboardStats | null > | null,
};

export type DashboardStatsContent = {
  __typename: "DashboardStatsContent",
  general?: DashboardGeneral | null,
  payments?: DashboardPayments | null,
  taxes?: DashboardTaxes | null,
  categories?: DashboardCategories | null,
  products?: DashboardProducts | null,
};

export type DashboardGeneral = {
  __typename: "DashboardGeneral",
  content?: DashboardGeneralContent | null,
  histogram?:  Array<GroupedDashboardGeneralContent | null > | null,
};

export type DashboardGeneralContent = {
  __typename: "DashboardGeneralContent",
  nbSales?: number | null,
  totals?: Totals | null,
  average?: Totals | null,
  index?: number | null,
  margin?: number | null,
};

export type GroupedDashboardGeneralContent = {
  __typename: "GroupedDashboardGeneralContent",
  id: string,
  content?: DashboardGeneralContent | null,
};

export type DashboardPayments = {
  __typename: "DashboardPayments",
  totals?: DashboardPaymentTotals | null,
  payments?:  Array<GroupedDashboardPaymentTotals | null > | null,
};

export type DashboardPaymentTotals = {
  __typename: "DashboardPaymentTotals",
  nbPayments?: number | null,
  nbPositivePayments?: number | null,
  nbNegativePayments?: number | null,
  totalsDetail?: TotalsDetail | null,
};

export type GroupedDashboardPaymentTotals = {
  __typename: "GroupedDashboardPaymentTotals",
  id: string,
  name?: string | null,
  totals?: DashboardPaymentTotals | null,
};

export type DashboardTaxes = {
  __typename: "DashboardTaxes",
  totals?: Totals | null,
  taxes?:  Array<DashboardTaxContent | null > | null,
};

export type DashboardTaxContent = {
  __typename: "DashboardTaxContent",
  id: string,
  totals?: Totals | null,
};

export type DashboardCategories = {
  __typename: "DashboardCategories",
  totals?: Totals | null,
  quantity?: number | null,
  margin?: number | null,
  categories?:  Array<DashboardCategoryContent | null > | null,
};

export type DashboardCategoryContent = {
  __typename: "DashboardCategoryContent",
  id: string,
  name?: string | null,
  totals?: Totals | null,
  quantity?: number | null,
  margin?: number | null,
};

export type DashboardProducts = {
  __typename: "DashboardProducts",
  totals?: Totals | null,
  quantity?: number | null,
  margin?: number | null,
  products?:  Array<DashboardProductContent | null > | null,
};

export type DashboardProductContent = {
  __typename: "DashboardProductContent",
  id: string,
  name?: string | null,
  totals?: Totals | null,
  quantity?: number | null,
  margin?: number | null,
  variants?:  Array<DashboardProductVariantContent | null > | null,
};

export type DashboardProductVariantContent = {
  __typename: "DashboardProductVariantContent",
  id: string,
  name?: string | null,
  totals?: Totals | null,
  quantity?: number | null,
  margin?: number | null,
};

export type GroupedDashboardStats = {
  __typename: "GroupedDashboardStats",
  id: string,
  content?: DashboardStatsContent | null,
};

export type InventoryStats = {
  __typename: "InventoryStats",
  products?: InventoryStatsProducts | null,
  byShop?:  Array<GroupedInventoryStats | null > | null,
};

export type InventoryStatsProducts = {
  __typename: "InventoryStatsProducts",
  value?: number | null,
  products?:  Array<InventoryStatsProductContent | null > | null,
};

export type InventoryStatsProductContent = {
  __typename: "InventoryStatsProductContent",
  id: string,
  name?: string | null,
  quantity?: number | null,
  value?: number | null,
  variants?:  Array<InventoryStatsProductVariantContent | null > | null,
};

export type InventoryStatsProductVariantContent = {
  __typename: "InventoryStatsProductVariantContent",
  id: string,
  name?: string | null,
  quantity?: number | null,
  value?: number | null,
};

export type GroupedInventoryStats = {
  __typename: "GroupedInventoryStats",
  id: string,
  content?: InventoryStatsProducts | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items?:  Array<Event | null > | null,
  nextToken?: string | null,
};

export type ModelUserNotificationConnection = {
  __typename: "ModelUserNotificationConnection",
  items?:  Array<UserNotification | null > | null,
  nextToken?: string | null,
};

export type ModelAccountInvitationConnection = {
  __typename: "ModelAccountInvitationConnection",
  items?:  Array<AccountInvitation | null > | null,
  nextToken?: string | null,
};

export type ModelCompanySettingConnection = {
  __typename: "ModelCompanySettingConnection",
  items?:  Array<CompanySetting | null > | null,
  nextToken?: string | null,
};

export type ModelSlotConnection = {
  __typename: "ModelSlotConnection",
  items?:  Array<Slot | null > | null,
  nextToken?: string | null,
};

export type ModelGridCellConnection = {
  __typename: "ModelGridCellConnection",
  items?:  Array<GridCell | null > | null,
  nextToken?: string | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items?:  Array<Product | null > | null,
  nextToken?: string | null,
};

export type RequesterProductFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  name?: RequesterStringFilterInput | null,
  buyPrice?: RequesterIntFilterInput | null,
  sellPrice?: RequesterIntFilterInput | null,
  bulkType?: RequesterEnumFilterInput | null,
  barcode?: RequesterStringFilterInput | null,
  reference?: RequesterStringFilterInput | null,
  variant?: RequesterProductVariantFilterInput | null,
  hasVariants?: RequesterBooleanFilterInput | null,
  category?: RequesterProductCategoryFilterInput | null,
  tax?: RequesterProductTaxFilterInput | null,
  inventoryQuantity?: RequesterProductInventoryQuantityFilterInput | null,
  totalInventoryQuantity?: RequesterIntFilterInput | null,
  tags?: RequesterConnectionTagFilterInput | null,
  options?: RequesterConnectionOptionFilterInput | null,
  customFields?: RequesterConnectionCustomFieldFilterInput | null,
  and?: Array< RequesterProductFilterInput | null > | null,
  or?: Array< RequesterProductFilterInput | null > | null,
  not?: RequesterProductFilterInput | null,
};

export type RequesterDateFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  exists?: boolean | null,
};

export type RequesterStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  contains?: string | null,
  does_not_contain?: string | null,
  starts_with?: string | null,
  ends_with?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
};

export type RequesterIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  exists?: boolean | null,
};

export type RequesterEnumFilterInput = {
  eq?: string | null,
  ne?: string | null,
  exists?: boolean | null,
};

export type RequesterProductVariantFilterInput = {
  name?: RequesterStringFilterInput | null,
  tax?: RequesterProductTaxFilterInput | null,
  buyPrice?: RequesterIntFilterInput | null,
  sellPrice?: RequesterIntFilterInput | null,
  barcode?: RequesterStringFilterInput | null,
  reference?: RequesterStringFilterInput | null,
};

export type RequesterProductTaxFilterInput = {
  rate?: RequesterIntFilterInput | null,
};

export type RequesterBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
  exists?: boolean | null,
};

export type RequesterProductCategoryFilterInput = {
  name?: RequesterStringFilterInput | null,
  customFields?: RequesterConnectionCustomFieldFilterInput | null,
};

export type RequesterConnectionCustomFieldFilterInput = {
  customFieldID: RequesterIDFilterInput,
  textValue?: RequesterStringFilterInput | null,
  dateValue?: RequesterDateFilterInput | null,
  numberValue?: RequesterIntFilterInput | null,
};

export type RequesterIDFilterInput = {
  ne?: string | null,
  eq?: string | null,
  exists?: boolean | null,
};

export type RequesterProductInventoryQuantityFilterInput = {
  shopID?: RequesterIDFilterInput | null,
  quantity?: RequesterIntFilterInput | null,
};

export type RequesterConnectionTagFilterInput = {
  name?: RequesterStringFilterInput | null,
};

export type RequesterConnectionOptionFilterInput = {
  optionID: RequesterIDFilterInput,
};

export type RequesterProductSortInput = {
  field?: RequesterProductSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterProductSortableFields {
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  name = "name",
  buyPrice = "buyPrice",
  sellPrice = "sellPrice",
  bulkType = "bulkType",
  barcode = "barcode",
  reference = "reference",
  categoryName = "categoryName",
  taxRate = "taxRate",
  variantBuyPrice = "variantBuyPrice",
  variantSellPrice = "variantSellPrice",
  totalInventoryQuantity = "totalInventoryQuantity",
}


export enum RequesterSortDirection {
  asc = "asc",
  desc = "desc",
}


export type RequesterProductConnection = {
  __typename: "RequesterProductConnection",
  items?:  Array<Product | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items?:  Array<Category | null > | null,
  nextToken?: string | null,
};

export type RequesterCategoryFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  name?: RequesterStringFilterInput | null,
  customFields?: RequesterConnectionCustomFieldFilterInput | null,
  and?: Array< RequesterCategoryFilterInput | null > | null,
  or?: Array< RequesterCategoryFilterInput | null > | null,
  not?: RequesterCategoryFilterInput | null,
};

export type RequesterCategorySortInput = {
  field?: RequesterCategorySortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterCategorySortableFields {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  name = "name",
}


export type RequesterCategoryConnection = {
  __typename: "RequesterCategoryConnection",
  items?:  Array<Category | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCustomFieldConnection = {
  __typename: "ModelCustomFieldConnection",
  items?:  Array<CustomField | null > | null,
  nextToken?: string | null,
};

export type ModelDiscountConnection = {
  __typename: "ModelDiscountConnection",
  items?:  Array<Discount | null > | null,
  nextToken?: string | null,
};

export type RequesterDiscountFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  name?: RequesterStringFilterInput | null,
  type?: RequesterEnumFilterInput | null,
  rate?: RequesterIntFilterInput | null,
  and?: Array< RequesterDiscountFilterInput | null > | null,
  or?: Array< RequesterDiscountFilterInput | null > | null,
  not?: RequesterDiscountFilterInput | null,
};

export type RequesterDiscountSortInput = {
  field?: RequesterDiscountSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterDiscountSortableFields {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  name = "name",
  type = "type",
  rate = "rate",
}


export type RequesterDiscountConnection = {
  __typename: "RequesterDiscountConnection",
  items?:  Array<Discount | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOptionConnection = {
  __typename: "ModelOptionConnection",
  items?:  Array<Option | null > | null,
  nextToken?: string | null,
};

export type RequesterOptionFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  name?: RequesterStringFilterInput | null,
  values?: RequesterOptionValuesFilterInput | null,
  and?: Array< RequesterOptionFilterInput | null > | null,
  or?: Array< RequesterOptionFilterInput | null > | null,
  not?: RequesterOptionFilterInput | null,
};

export type RequesterOptionValuesFilterInput = {
  name?: RequesterStringFilterInput | null,
  price?: RequesterIntFilterInput | null,
};

export type RequesterOptionSortInput = {
  field?: RequesterOptionSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterOptionSortableFields {
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  name = "name",
}


export type RequesterOptionConnection = {
  __typename: "RequesterOptionConnection",
  items?:  Array<Option | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPackConnection = {
  __typename: "ModelPackConnection",
  items?:  Array<Pack | null > | null,
  nextToken?: string | null,
};

export type RequesterPackFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  name?: RequesterStringFilterInput | null,
  sellPrice?: RequesterIntFilterInput | null,
  barcode?: RequesterStringFilterInput | null,
  reference?: RequesterStringFilterInput | null,
  customFields?: RequesterConnectionCustomFieldFilterInput | null,
  and?: Array< RequesterPackFilterInput | null > | null,
  or?: Array< RequesterPackFilterInput | null > | null,
  not?: RequesterPackFilterInput | null,
};

export type RequesterPackSortInput = {
  field?: RequesterPackSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterPackSortableFields {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  name = "name",
  sellPrice = "sellPrice",
  barcode = "barcode",
  reference = "reference",
}


export type RequesterPackConnection = {
  __typename: "RequesterPackConnection",
  items?:  Array<Pack | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection",
  items?:  Array<Tag | null > | null,
  nextToken?: string | null,
};

export type ModelTaxConnection = {
  __typename: "ModelTaxConnection",
  items?:  Array<Tax | null > | null,
  nextToken?: string | null,
};

export type ModelInventoryMovementConnection = {
  __typename: "ModelInventoryMovementConnection",
  items?:  Array<InventoryMovement | null > | null,
  nextToken?: string | null,
};

export type RequesterInventoryMovementFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  originID?: RequesterIDFilterInput | null,
  destinationID?: RequesterIDFilterInput | null,
  state?: RequesterEnumFilterInput | null,
  stateDate?: RequesterDateFilterInput | null,
  motive?: RequesterStringFilterInput | null,
  totalQuantity?: RequesterIntFilterInput | null,
  movement?: RequesterInventoryMovementMovementFilterInput | null,
  and?: Array< RequesterInventoryMovementFilterInput | null > | null,
  or?: Array< RequesterInventoryMovementFilterInput | null > | null,
  not?: RequesterInventoryMovementFilterInput | null,
};

export type RequesterInventoryMovementMovementFilterInput = {
  productID?: RequesterIDFilterInput | null,
  variantID?: RequesterIDFilterInput | null,
  quantity?: RequesterIntFilterInput | null,
  price?: RequesterIntFilterInput | null,
  details?: RequesterInventoryMovementMovementDetailsFilterInput | null,
};

export type RequesterInventoryMovementMovementDetailsFilterInput = {
  product?: RequesterInventoryMovementMovementDetailsProductFilterInput | null,
  variant?: RequesterInventoryMovementMovementDetailsProductVariantFilterInput | null,
};

export type RequesterInventoryMovementMovementDetailsProductFilterInput = {
  name?: RequesterStringFilterInput | null,
  bulkType?: RequesterEnumFilterInput | null,
  barcode?: RequesterStringFilterInput | null,
  reference?: RequesterStringFilterInput | null,
  category?: RequesterInventoryMovementCategoryFilterInput | null,
  tax?: RequesterInventoryMovementTaxFilterInput | null,
  tags?: RequesterConnectionTagFilterInput | null,
  customFields?: RequesterConnectionCustomFieldFilterInput | null,
};

export type RequesterInventoryMovementCategoryFilterInput = {
  name?: RequesterStringFilterInput | null,
};

export type RequesterInventoryMovementTaxFilterInput = {
  rate?: RequesterIntFilterInput | null,
};

export type RequesterInventoryMovementMovementDetailsProductVariantFilterInput = {
  nameShort?: RequesterStringFilterInput | null,
  nameLong?: RequesterStringFilterInput | null,
  barcode?: RequesterStringFilterInput | null,
  reference?: RequesterStringFilterInput | null,
};

export type RequesterInventoryMovementSortInput = {
  field?: RequesterInventoryMovementSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterInventoryMovementSortableFields {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  stateDate = "stateDate",
  totalQuantity = "totalQuantity",
}


export type RequesterInventoryMovementConnection = {
  __typename: "RequesterInventoryMovementConnection",
  items?:  Array<InventoryMovement | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPaymentMethodConnection = {
  __typename: "ModelPaymentMethodConnection",
  items?:  Array<PaymentMethod | null > | null,
  nextToken?: string | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items?:  Array<Customer | null > | null,
  nextToken?: string | null,
};

export type RequesterCustomerFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  firstName?: RequesterStringFilterInput | null,
  lastName?: RequesterStringFilterInput | null,
  email?: RequesterStringFilterInput | null,
  phone?: RequesterStringFilterInput | null,
  phoneCode?: RequesterStringFilterInput | null,
  companyName?: RequesterStringFilterInput | null,
  address?: RequesterAddressFilterInput | null,
  fidelityCardNumber?: RequesterStringFilterInput | null,
  customFields?: RequesterConnectionCustomFieldFilterInput | null,
  and?: Array< RequesterCustomerFilterInput | null > | null,
  or?: Array< RequesterCustomerFilterInput | null > | null,
  not?: RequesterCustomerFilterInput | null,
};

export type RequesterAddressFilterInput = {
  address?: RequesterStringFilterInput | null,
  zipcode?: RequesterStringFilterInput | null,
  city?: RequesterStringFilterInput | null,
  country?: RequesterEnumFilterInput | null,
};

export type RequesterCustomerSortInput = {
  field?: RequesterCustomerSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterCustomerSortableFields {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  companyName = "companyName",
  fidelityCardNumber = "fidelityCardNumber",
}


export type RequesterCustomerConnection = {
  __typename: "RequesterCustomerConnection",
  items?:  Array<Customer | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type RequesterSaleFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  shopID?: RequesterIDFilterInput | null,
  shop?: RequesterConnectionShopNameFilterInput | null,
  sourceID?: RequesterIDFilterInput | null,
  sourceName?: RequesterStringFilterInput | null,
  cashbookID?: RequesterIDFilterInput | null,
  name?: RequesterStringFilterInput | null,
  number?: RequesterIntFilterInput | null,
  state?: RequesterEnumFilterInput | null,
  customerID?: RequesterIDFilterInput | null,
  sellerID?: RequesterIDFilterInput | null,
  openedAt?: RequesterDateFilterInput | null,
  closedAt?: RequesterDateFilterInput | null,
  pendingAt?: RequesterDateFilterInput | null,
  linesCount?: RequesterIntFilterInput | null,
  saleLinesCount?: RequesterIntFilterInput | null,
  refundLinesCount?: RequesterIntFilterInput | null,
  total?: RequesterIntFilterInput | null,
  totalDiscount?: RequesterIntFilterInput | null,
  totalTaxFree?: RequesterIntFilterInput | null,
  totalTax?: RequesterIntFilterInput | null,
  totalRest?: RequesterIntFilterInput | null,
  and?: Array< RequesterSaleFilterInput | null > | null,
  or?: Array< RequesterSaleFilterInput | null > | null,
  not?: RequesterSaleFilterInput | null,
};

export type RequesterConnectionShopNameFilterInput = {
  name?: RequesterStringFilterInput | null,
};

export type RequesterSaleSortInput = {
  field?: RequesterSaleSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterSaleSortableFields {
  sourceID = "sourceID",
  sourceName = "sourceName",
  cashbookName = "cashbookName",
  name = "name",
  number = "number",
  openedAt = "openedAt",
  closedAt = "closedAt",
  pendingAt = "pendingAt",
  total = "total",
  totalDiscount = "totalDiscount",
  totalTaxFree = "totalTaxFree",
  totalTax = "totalTax",
  totalRest = "totalRest",
  linesCount = "linesCount",
  saleLinesCount = "saleLinesCount",
  refundLinesCount = "refundLinesCount",
}


export type RequesterSaleConnection = {
  __typename: "RequesterSaleConnection",
  items?:  Array<Sale | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCashbookConnection = {
  __typename: "ModelCashbookConnection",
  items?:  Array<Cashbook | null > | null,
  nextToken?: string | null,
};

export type RequesterCashbookFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  shopID?: RequesterIDFilterInput | null,
  shop?: RequesterConnectionShopNameFilterInput | null,
  sourceID?: RequesterIDFilterInput | null,
  sourceName?: RequesterStringFilterInput | null,
  number?: RequesterIntFilterInput | null,
  state?: RequesterEnumFilterInput | null,
  openedAt?: RequesterDateFilterInput | null,
  closedAt?: RequesterDateFilterInput | null,
  total?: RequesterIntFilterInput | null,
  customFields?: RequesterCashbookCustomFieldFilterInput | null,
  and?: Array< RequesterCashbookFilterInput | null > | null,
  or?: Array< RequesterCashbookFilterInput | null > | null,
  not?: RequesterCashbookFilterInput | null,
};

export type RequesterCashbookCustomFieldFilterInput = {
  customFieldID: RequesterIDFilterInput,
  textValue?: RequesterStringFilterInput | null,
  dateValue?: RequesterDateFilterInput | null,
  numberValue?: RequesterIntFilterInput | null,
};

export type RequesterCashbookSortInput = {
  field?: RequesterCashbookSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterCashbookSortableFields {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  sourceID = "sourceID",
  sourceName = "sourceName",
  number = "number",
  total = "total",
  totalTaxFree = "totalTaxFree",
  state = "state",
  openedAt = "openedAt",
  closedAt = "closedAt",
}


export type RequesterCashbookConnection = {
  __typename: "RequesterCashbookConnection",
  items?:  Array<Cashbook | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection",
  items?:  Array<Payment | null > | null,
  nextToken?: string | null,
};

export type RequesterPaymentFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  shopID?: RequesterIDFilterInput | null,
  shop?: RequesterConnectionShopNameFilterInput | null,
  sourceID?: RequesterIDFilterInput | null,
  sourceName?: RequesterStringFilterInput | null,
  number?: RequesterIntFilterInput | null,
  type?: RequesterEnumFilterInput | null,
  state?: RequesterEnumFilterInput | null,
  date?: RequesterDateFilterInput | null,
  paymentMethodID?: RequesterIDFilterInput | null,
  paymentMethodName?: RequesterStringFilterInput | null,
  paymentMethodType?: RequesterEnumFilterInput | null,
  cashbookID?: RequesterIDFilterInput | null,
  amount?: RequesterIntFilterInput | null,
  and?: Array< RequesterPaymentFilterInput | null > | null,
  or?: Array< RequesterPaymentFilterInput | null > | null,
  not?: RequesterPaymentFilterInput | null,
};

export type RequesterPaymentSortInput = {
  field?: RequesterPaymentSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterPaymentSortableFields {
  state = "state",
  type = "type",
  date = "date",
  number = "number",
  paymentMethodName = "paymentMethodName",
  paymentMethodType = "paymentMethodType",
  cashbookNumber = "cashbookNumber",
  amount = "amount",
}


export type RequesterPaymentConnection = {
  __typename: "RequesterPaymentConnection",
  items?:  Array<Payment | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type RequesterBackgroundJobFilterInput = {
  createdAt?: RequesterDateFilterInput | null,
  updatedAt?: RequesterDateFilterInput | null,
  and?: Array< RequesterBackgroundJobFilterInput | null > | null,
  or?: Array< RequesterBackgroundJobFilterInput | null > | null,
  not?: RequesterBackgroundJobFilterInput | null,
};

export type RequesterBackgroundJobSortInput = {
  field?: RequesterBackgroundJobSortableFields | null,
  direction?: RequesterSortDirection | null,
};

export enum RequesterBackgroundJobSortableFields {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type RequesterBackgroundJobConnection = {
  __typename: "RequesterBackgroundJobConnection",
  items?:  Array<BackgroundJob | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type MigrationPreviewDetails = CatalogPreviewDetails | CustomerPreviewDetails | InventoryPreviewDetails | ActivityPreviewDetails


export type CatalogPreviewDetails = {
  __typename: "CatalogPreviewDetails",
  PRODUCT?: ModelPreviewDetail | null,
  PRODUCT_VARIANT?: ModelPreviewDetail | null,
  CATEGORY?: ModelPreviewDetail | null,
  TAX?: ModelPreviewDetail | null,
  DISCOUNT?: ModelPreviewDetail | null,
};

export type ModelPreviewDetail = {
  __typename: "ModelPreviewDetail",
  count?: number | null,
};

export type CustomerPreviewDetails = {
  __typename: "CustomerPreviewDetails",
  CUSTOMER?: ModelPreviewDetail | null,
};

export type InventoryPreviewDetails = {
  __typename: "InventoryPreviewDetails",
  INVENTORY?: ModelPreviewDetail | null,
};

export type ActivityPreviewDetails = {
  __typename: "ActivityPreviewDetails",
  PAYMENT_METHOD?: ModelPreviewDetail | null,
  SLOT?: ModelPreviewDetail | null,
  CASHBOOK?: ModelPreviewDetail | null,
  SALE?: ModelPreviewDetail | null,
};

export type CatalogObjectConnection = {
  __typename: "CatalogObjectConnection",
  items?:  Array<CatalogObject | null > | null,
  nextToken?: string | null,
};

export type CatalogObject = Product


export type CompanyObjectConnection = {
  __typename: "CompanyObjectConnection",
  items?:  Array<CompanyObject | null > | null,
  nextToken?: string | null,
};

export type CompanyObject = Company


export type ShopObjectConnection = {
  __typename: "ShopObjectConnection",
  items?:  Array<ShopObject | null > | null,
  nextToken?: string | null,
};

export type ShopObject = Shop


export type SlotSyncObjectConnection = {
  __typename: "SlotSyncObjectConnection",
  items?:  Array<SlotSyncObject | null > | null,
  nextToken?: string | null,
};

export type SlotSyncObject = Sale


export type StartCatalogMigrationMutationVariables = {
  apiKey: string,
  companyID: string,
  shopID: string,
};

export type StartCatalogMigrationMutation = {
  startCatalogMigration?: boolean | null,
};

export type StartInventoryMigrationMutationVariables = {
  apiKey: string,
  companyID: string,
  shopID: string,
};

export type StartInventoryMigrationMutation = {
  startInventoryMigration?: boolean | null,
};

export type StartCustomerMigrationMutationVariables = {
  apiKey: string,
  companyID: string,
  shopID: string,
};

export type StartCustomerMigrationMutation = {
  startCustomerMigration?: boolean | null,
};

export type StartActivityMigrationMutationVariables = {
  apiKey: string,
  companyID: string,
  shopID: string,
};

export type StartActivityMigrationMutation = {
  startActivityMigration?: boolean | null,
};

export type StartMigrationMutationVariables = {
  input: MigrationInput,
  test: boolean,
};

export type StartMigrationMutation = {
  startMigration?:  {
    __typename: "ModelBackgroundJobConnection",
    items?:  Array< {
      __typename: "BackgroundJob",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      shopID?: string | null,
      sourceEmail?: string | null,
      type: BackgroundJobType,
      state: BackgroundJobState,
      details: ( {
          __typename: "CatalogMigrationDetails",
          PRODUCT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          PRODUCT_VARIANT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          CATEGORY?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          TAX?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          DISCOUNT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "CustomerMigrationDetails",
          CUSTOMER?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "InventoryMigrationDetails",
          INVENTORY?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "ActivityMigrationDetails",
          PAYMENT_METHOD?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          SLOT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          CASHBOOK?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          SALE?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          PAYMENT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "ImportDetails",
          IMPORT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        }
      ) | null,
      message?: string | null,
      reportUrl?: string | null,
      flatfileBatchId?: string | null,
      flatfileSheetId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SendPhoneVerificationCodeMutationVariables = {
  phone: string,
};

export type SendPhoneVerificationCodeMutation = {
  sendPhoneVerificationCode?: boolean | null,
};

export type CheckPhoneVerificationCodeMutationVariables = {
  phone: string,
  code: string,
};

export type CheckPhoneVerificationCodeMutation = {
  checkPhoneVerificationCode?: boolean | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    phone?: string | null,
    locale?: LanguageCode | null,
    companies?:  {
      __typename: "ModelAccountCompanyConnection",
      items?:  Array< {
        __typename: "AccountCompany",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        owner?: boolean | null,
        role?: AccountRole | null,
        read?: boolean | null,
        update?: boolean | null,
        billing?: boolean | null,
        billingExport?: boolean | null,
        shopWrite?: boolean | null,
        deviceSlotWrite?: boolean | null,
        deviceSlotUse?: boolean | null,
        paymentMethodRead?: boolean | null,
        paymentMethodCreate?: boolean | null,
        paymentMethodUpdate?: boolean | null,
        paymentMethodDelete?: boolean | null,
        customerRead?: boolean | null,
        customerCreate?: boolean | null,
        customerUpdate?: boolean | null,
        customerDelete?: boolean | null,
        customerExport?: boolean | null,
        inventoryRead?: boolean | null,
        cashbookRead?: boolean | null,
        saleRead?: boolean | null,
        paymentRead?: boolean | null,
        userInvite?: boolean | null,
        userRead?: boolean | null,
        userUpdate?: boolean | null,
        userRemove?: boolean | null,
        sell?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    catalog?:  {
      __typename: "ModelAccountCatalogConnection",
      items?:  Array< {
        __typename: "AccountCatalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        catalogID: string,
        catalog?:  {
          __typename: "Catalog",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          id: string,
        } | null,
        read?: boolean | null,
        create?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    shops?:  {
      __typename: "ModelAccountShopConnection",
      items?:  Array< {
        __typename: "AccountShop",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        shopID: string,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        inventoryRead?: boolean | null,
        inventoryWrite?: boolean | null,
        cashbookRead?: boolean | null,
        cashbookWrite?: boolean | null,
        saleRead?: boolean | null,
        saleWrite?: boolean | null,
        paymentRead?: boolean | null,
        paymentWrite?: boolean | null,
        userInvite?: boolean | null,
        userRead?: boolean | null,
        userUpdate?: boolean | null,
        userRemove?: boolean | null,
        activitiesExport?: boolean | null,
        tillCashbooksRead?: boolean | null,
        tillSalesSearch?: boolean | null,
        tillSaleSell?: boolean | null,
        tillSaleRefund?: boolean | null,
        tillSaleReturn?: boolean | null,
        tillCashbookOpen?: boolean | null,
        tillCashbookClose?: boolean | null,
        eventCreate?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    sellerCodes?:  {
      __typename: "ModelSellerCodeConnection",
      items?:  Array< {
        __typename: "SellerCode",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        code: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    intercomHMAC?: string | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    phone?: string | null,
    locale?: LanguageCode | null,
    companies?:  {
      __typename: "ModelAccountCompanyConnection",
      items?:  Array< {
        __typename: "AccountCompany",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        owner?: boolean | null,
        role?: AccountRole | null,
        read?: boolean | null,
        update?: boolean | null,
        billing?: boolean | null,
        billingExport?: boolean | null,
        shopWrite?: boolean | null,
        deviceSlotWrite?: boolean | null,
        deviceSlotUse?: boolean | null,
        paymentMethodRead?: boolean | null,
        paymentMethodCreate?: boolean | null,
        paymentMethodUpdate?: boolean | null,
        paymentMethodDelete?: boolean | null,
        customerRead?: boolean | null,
        customerCreate?: boolean | null,
        customerUpdate?: boolean | null,
        customerDelete?: boolean | null,
        customerExport?: boolean | null,
        inventoryRead?: boolean | null,
        cashbookRead?: boolean | null,
        saleRead?: boolean | null,
        paymentRead?: boolean | null,
        userInvite?: boolean | null,
        userRead?: boolean | null,
        userUpdate?: boolean | null,
        userRemove?: boolean | null,
        sell?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    catalog?:  {
      __typename: "ModelAccountCatalogConnection",
      items?:  Array< {
        __typename: "AccountCatalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        catalogID: string,
        catalog?:  {
          __typename: "Catalog",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          id: string,
        } | null,
        read?: boolean | null,
        create?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    shops?:  {
      __typename: "ModelAccountShopConnection",
      items?:  Array< {
        __typename: "AccountShop",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        shopID: string,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        inventoryRead?: boolean | null,
        inventoryWrite?: boolean | null,
        cashbookRead?: boolean | null,
        cashbookWrite?: boolean | null,
        saleRead?: boolean | null,
        saleWrite?: boolean | null,
        paymentRead?: boolean | null,
        paymentWrite?: boolean | null,
        userInvite?: boolean | null,
        userRead?: boolean | null,
        userUpdate?: boolean | null,
        userRemove?: boolean | null,
        activitiesExport?: boolean | null,
        tillCashbooksRead?: boolean | null,
        tillSalesSearch?: boolean | null,
        tillSaleSell?: boolean | null,
        tillSaleRefund?: boolean | null,
        tillSaleReturn?: boolean | null,
        tillCashbookOpen?: boolean | null,
        tillCashbookClose?: boolean | null,
        eventCreate?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    sellerCodes?:  {
      __typename: "ModelSellerCodeConnection",
      items?:  Array< {
        __typename: "SellerCode",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        code: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    intercomHMAC?: string | null,
  } | null,
};

export type CreateSellerCodeMutationVariables = {
  input: CreateSellerCodeInput,
};

export type CreateSellerCodeMutation = {
  createSellerCode?:  {
    __typename: "SellerCode",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    code: string,
    id: string,
  } | null,
};

export type UpdateSellerCodeMutationVariables = {
  input: UpdateSellerCodeInput,
};

export type UpdateSellerCodeMutation = {
  updateSellerCode?:  {
    __typename: "SellerCode",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    code: string,
    id: string,
  } | null,
};

export type DeleteSellerCodeMutationVariables = {
  input: DeleteSellerCodeInput,
};

export type DeleteSellerCodeMutation = {
  deleteSellerCode?:  {
    __typename: "SellerCode",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    code: string,
    id: string,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    name?: string | null,
    address?: string | null,
    country?: CountryCode | null,
    currency: CurrencyCode,
    currencyDecimals: number,
    vat?: string | null,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    name?: string | null,
    address?: string | null,
    country?: CountryCode | null,
    currency: CurrencyCode,
    currencyDecimals: number,
    vat?: string | null,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "CompanyResults",
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    catalog?:  {
      __typename: "Catalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "Shop",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      name: string,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      siret?: string | null,
      vat?: string | null,
      phone?: string | null,
      footnote?: string | null,
      logo?: string | null,
      websiteURL?: string | null,
      brandName: string,
      email?: string | null,
    } | null > | null,
    companyAccounts?:  Array< {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
    shopAccounts?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
    catalogAccounts?:  Array< {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type CreateShopMutationVariables = {
  input: CreateShopInput,
};

export type CreateShopMutation = {
  createShop?:  {
    __typename: "Shop",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    name: string,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    siret?: string | null,
    vat?: string | null,
    phone?: string | null,
    footnote?: string | null,
    logo?: string | null,
    websiteURL?: string | null,
    brandName: string,
    email?: string | null,
  } | null,
};

export type UpdateShopMutationVariables = {
  input: UpdateShopInput,
};

export type UpdateShopMutation = {
  updateShop?:  {
    __typename: "Shop",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    name: string,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    siret?: string | null,
    vat?: string | null,
    phone?: string | null,
    footnote?: string | null,
    logo?: string | null,
    websiteURL?: string | null,
    brandName: string,
    email?: string | null,
  } | null,
};

export type DeleteShopMutationVariables = {
  input: DeleteShopInput,
};

export type DeleteShopMutation = {
  deleteShop?:  {
    __typename: "ShopResults",
    shop?:  {
      __typename: "Shop",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      name: string,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      siret?: string | null,
      vat?: string | null,
      phone?: string | null,
      footnote?: string | null,
      logo?: string | null,
      websiteURL?: string | null,
      brandName: string,
      email?: string | null,
    } | null,
    shopAccounts?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type CreateCompanySettingMutationVariables = {
  input?: CreateCompanySettingInput | null,
};

export type CreateCompanySettingMutation = {
  createCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type CreatePersonalCompanySettingMutationVariables = {
  input?: CreateCompanySettingInput | null,
};

export type CreatePersonalCompanySettingMutation = {
  createPersonalCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type UpdateCompanySettingMutationVariables = {
  input?: UpdateCompanySettingInput | null,
};

export type UpdateCompanySettingMutation = {
  updateCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type DeleteCompanySettingMutationVariables = {
  input?: DeleteCompanySettingInput | null,
};

export type DeleteCompanySettingMutation = {
  deleteCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type InviteUserMutationVariables = {
  input: CreateAccountInvitationInput,
};

export type InviteUserMutation = {
  inviteUser?:  {
    __typename: "AccountInvitation",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    companyName: string,
    email: string,
    role: AccountRole,
    shops: Array< string | null >,
    expiresAt: string,
    status: AccountInvitationStatus,
    senderID: string,
    senderName: string,
    senderEmail: string,
  } | null,
};

export type ResendInvitationMutationVariables = {
  input: UpdateAccountInvitationInput,
};

export type ResendInvitationMutation = {
  resendInvitation?:  {
    __typename: "AccountInvitation",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    companyName: string,
    email: string,
    role: AccountRole,
    shops: Array< string | null >,
    expiresAt: string,
    status: AccountInvitationStatus,
    senderID: string,
    senderName: string,
    senderEmail: string,
  } | null,
};

export type CancelInvitationMutationVariables = {
  input: UpdateAccountInvitationInput,
};

export type CancelInvitationMutation = {
  cancelInvitation?:  {
    __typename: "AccountInvitation",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    companyName: string,
    email: string,
    role: AccountRole,
    shops: Array< string | null >,
    expiresAt: string,
    status: AccountInvitationStatus,
    senderID: string,
    senderName: string,
    senderEmail: string,
  } | null,
};

export type AcceptInvitationMutationVariables = {
  input: UpdateAccountInvitationInput,
};

export type AcceptInvitationMutation = {
  acceptInvitation?:  {
    __typename: "AccountInvitation",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    companyName: string,
    email: string,
    role: AccountRole,
    shops: Array< string | null >,
    expiresAt: string,
    status: AccountInvitationStatus,
    senderID: string,
    senderName: string,
    senderEmail: string,
  } | null,
};

export type RejectInvitationMutationVariables = {
  input: UpdateAccountInvitationInput,
};

export type RejectInvitationMutation = {
  rejectInvitation?:  {
    __typename: "AccountInvitation",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    companyName: string,
    email: string,
    role: AccountRole,
    shops: Array< string | null >,
    expiresAt: string,
    status: AccountInvitationStatus,
    senderID: string,
    senderName: string,
    senderEmail: string,
  } | null,
};

export type UpdateUserRoleMutationVariables = {
  input: UpdateUserRoleInput,
};

export type UpdateUserRoleMutation = {
  updateUserRole?:  {
    __typename: "User",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    company?:  {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    catalog?:  {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type AddUserToShopMutationVariables = {
  input: AddUserToShopInput,
};

export type AddUserToShopMutation = {
  addUserToShop?:  {
    __typename: "User",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    company?:  {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    catalog?:  {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type RemoveUserFromShopMutationVariables = {
  input: RemoveUserFromShopInput,
};

export type RemoveUserFromShopMutation = {
  removeUserFromShop?:  {
    __typename: "User",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    company?:  {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    catalog?:  {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type RemoveUserMutationVariables = {
  input: RemoveUserInput,
};

export type RemoveUserMutation = {
  removeUser?:  {
    __typename: "User",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    company?:  {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    catalog?:  {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type CreateSlotMutationVariables = {
  input: CreateSlotInput,
};

export type CreateSlotMutation = {
  createSlot?:  {
    __typename: "Slot",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    name: string,
    status?: SlotStatus | null,
  } | null,
};

export type UpdateSlotMutationVariables = {
  input: UpdateSlotInput,
};

export type UpdateSlotMutation = {
  updateSlot?:  {
    __typename: "Slot",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    name: string,
    status?: SlotStatus | null,
  } | null,
};

export type SendSlotEmailMutationVariables = {
  input: SendSlotEmailInput,
};

export type SendSlotEmailMutation = {
  sendSlotEmail?:  {
    __typename: "Slot",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    name: string,
    status?: SlotStatus | null,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID?: string | null,
    slotID?: string | null,
    userID?: string | null,
    test?: boolean | null,
    version: string,
    date: string,
    type: EventType,
    code: number,
    objectType: EventObjectType,
    objectID: string,
    action: string,
    actionData?: string | null,
    hash?: string | null,
  } | null,
};

export type CreateUserNotificationMutationVariables = {
  input: CreateUserNotificationInput,
};

export type CreateUserNotificationMutation = {
  createUserNotification?:  {
    __typename: "Event",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID?: string | null,
    slotID?: string | null,
    userID?: string | null,
    test?: boolean | null,
    version: string,
    date: string,
    type: EventType,
    code: number,
    objectType: EventObjectType,
    objectID: string,
    action: string,
    actionData?: string | null,
    hash?: string | null,
  } | null,
};

export type UpdateUserNotificationMutationVariables = {
  input: UpdateUserNotificationInput,
};

export type UpdateUserNotificationMutation = {
  updateUserNotification?:  {
    __typename: "Event",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID?: string | null,
    slotID?: string | null,
    userID?: string | null,
    test?: boolean | null,
    version: string,
    date: string,
    type: EventType,
    code: number,
    objectType: EventObjectType,
    objectID: string,
    action: string,
    actionData?: string | null,
    hash?: string | null,
  } | null,
};

export type DeleteUserNotificationMutationVariables = {
  input: DeleteUserNotificationInput,
};

export type DeleteUserNotificationMutation = {
  deleteUserNotification?:  {
    __typename: "Event",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID?: string | null,
    slotID?: string | null,
    userID?: string | null,
    test?: boolean | null,
    version: string,
    date: string,
    type: EventType,
    code: number,
    objectType: EventObjectType,
    objectID: string,
    action: string,
    actionData?: string | null,
    hash?: string | null,
  } | null,
};

export type UpdateGridCellMutationVariables = {
  input: GridCellInput,
  test: boolean,
};

export type UpdateGridCellMutation = {
  updateGridCell?:  {
    __typename: "GridCell",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    compositeID: string,
    catalogID: string,
    test?: boolean | null,
    deviceID?: string | null,
    page: number,
    column: number,
    row: number,
    type: GridCellType,
    objectID: string,
  } | null,
};

export type DeleteGridCellMutationVariables = {
  input: DeleteGridCellInput,
};

export type DeleteGridCellMutation = {
  deleteGridCell?:  {
    __typename: "GridCell",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    compositeID: string,
    catalogID: string,
    test?: boolean | null,
    deviceID?: string | null,
    page: number,
    column: number,
    row: number,
    type: GridCellType,
    objectID: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  test: boolean,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    categoryID: string,
    category?:  {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    bulkType?: BulkType | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    variantsCount?: number | null,
    variants?:  {
      __typename: "ModelProductVariantConnection",
      items?:  Array< {
        __typename: "ProductVariant",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        id: string,
        productID: string,
        variantID: string,
        options:  Array< {
          __typename: "VariationOption",
          name: string,
          value: string,
        } | null >,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    options?:  {
      __typename: "ModelProductOptionConnection",
      items?:  Array< {
        __typename: "ProductOption",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        optionID: string,
        option?:  {
          __typename: "Option",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelProductTagConnection",
      items?:  Array< {
        __typename: "ProductTag",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        tagID: string,
        tag?:  {
          __typename: "Tag",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          createdByID?: string | null,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    customFields?:  {
      __typename: "ModelProductCustomFieldConnection",
      items?:  Array< {
        __typename: "ProductCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    categoryID: string,
    category?:  {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    bulkType?: BulkType | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    variantsCount?: number | null,
    variants?:  {
      __typename: "ModelProductVariantConnection",
      items?:  Array< {
        __typename: "ProductVariant",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        id: string,
        productID: string,
        variantID: string,
        options:  Array< {
          __typename: "VariationOption",
          name: string,
          value: string,
        } | null >,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    options?:  {
      __typename: "ModelProductOptionConnection",
      items?:  Array< {
        __typename: "ProductOption",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        optionID: string,
        option?:  {
          __typename: "Option",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelProductTagConnection",
      items?:  Array< {
        __typename: "ProductTag",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        tagID: string,
        tag?:  {
          __typename: "Tag",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          createdByID?: string | null,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    customFields?:  {
      __typename: "ModelProductCustomFieldConnection",
      items?:  Array< {
        __typename: "ProductCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    categoryID: string,
    category?:  {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    bulkType?: BulkType | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    variantsCount?: number | null,
    variants?:  {
      __typename: "ModelProductVariantConnection",
      items?:  Array< {
        __typename: "ProductVariant",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        id: string,
        productID: string,
        variantID: string,
        options:  Array< {
          __typename: "VariationOption",
          name: string,
          value: string,
        } | null >,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    options?:  {
      __typename: "ModelProductOptionConnection",
      items?:  Array< {
        __typename: "ProductOption",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        optionID: string,
        option?:  {
          __typename: "Option",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelProductTagConnection",
      items?:  Array< {
        __typename: "ProductTag",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        tagID: string,
        tag?:  {
          __typename: "Tag",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          createdByID?: string | null,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    customFields?:  {
      __typename: "ModelProductCustomFieldConnection",
      items?:  Array< {
        __typename: "ProductCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateProductOptionMutationVariables = {
  input: ProductOptionInput,
};

export type CreateProductOptionMutation = {
  createProductOption?:  {
    __typename: "ProductOption",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    optionID: string,
    option?:  {
      __typename: "Option",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      values:  Array< {
        __typename: "OptionValue",
        name: string,
        price: number,
      } | null >,
      stats?:  {
        __typename: "OptionStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    id: string,
  } | null,
};

export type DeleteProductOptionMutationVariables = {
  input: ProductOptionInput,
};

export type DeleteProductOptionMutation = {
  deleteProductOption?:  {
    __typename: "ProductOption",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    optionID: string,
    option?:  {
      __typename: "Option",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      values:  Array< {
        __typename: "OptionValue",
        name: string,
        price: number,
      } | null >,
      stats?:  {
        __typename: "OptionStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    id: string,
  } | null,
};

export type CreateProductTagMutationVariables = {
  input: ProductTagInput,
};

export type CreateProductTagMutation = {
  createProductTag?:  {
    __typename: "ProductTag",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    tagID: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      createdByID?: string | null,
    } | null,
    id: string,
  } | null,
};

export type DeleteProductTagMutationVariables = {
  input: ProductTagInput,
};

export type DeleteProductTagMutation = {
  deleteProductTag?:  {
    __typename: "ProductTag",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    tagID: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      createdByID?: string | null,
    } | null,
    id: string,
  } | null,
};

export type CreateProductCustomFieldMutationVariables = {
  input: CreateProductCustomFieldInput,
};

export type CreateProductCustomFieldMutation = {
  createProductCustomField?:  {
    __typename: "ProductCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type UpdateProductCustomFieldMutationVariables = {
  input: UpdateProductCustomFieldInput,
};

export type UpdateProductCustomFieldMutation = {
  updateProductCustomField?:  {
    __typename: "ProductCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type DeleteProductCustomFieldMutationVariables = {
  input: DeleteProductCustomFieldInput,
};

export type DeleteProductCustomFieldMutation = {
  deleteProductCustomField?:  {
    __typename: "ProductCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type GenerateProductVariantsMutationVariables = {
  input: GenerateProductVariantsInput,
};

export type GenerateProductVariantsMutation = {
  generateProductVariants?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type CreateProductVariantMutationVariables = {
  input: CreateProductVariantInput,
};

export type CreateProductVariantMutation = {
  createProductVariant?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type UpdateProductVariantMutationVariables = {
  input: UpdateProductVariantInput,
};

export type UpdateProductVariantMutation = {
  updateProductVariant?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type DeleteProductVariantMutationVariables = {
  input: DeleteProductVariantInput,
};

export type DeleteProductVariantMutation = {
  deleteProductVariant?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type BulkCreateProductVariantsMutationVariables = {
  input: BulkCreateProductVariantsInput,
};

export type BulkCreateProductVariantsMutation = {
  bulkCreateProductVariants?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type CreateProductVariationOptionMutationVariables = {
  input: CreateProductVariationOptionInput,
};

export type CreateProductVariationOptionMutation = {
  createProductVariationOption?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type UpdateProductVariationOptionMutationVariables = {
  input: UpdateProductVariationOptionInput,
};

export type UpdateProductVariationOptionMutation = {
  updateProductVariationOption?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type DeleteProductVariationOptionMutationVariables = {
  input: DeleteProductVariationOptionInput,
};

export type DeleteProductVariationOptionMutation = {
  deleteProductVariationOption?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type UpdateProductVariationNameMutationVariables = {
  input: UpdateProductVariationNameInput,
};

export type UpdateProductVariationNameMutation = {
  updateProductVariationName?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  test: boolean,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    customFields?:  {
      __typename: "ModelCategoryCustomFieldConnection",
      items?:  Array< {
        __typename: "CategoryCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        categoryID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CategoryStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    customFields?:  {
      __typename: "ModelCategoryCustomFieldConnection",
      items?:  Array< {
        __typename: "CategoryCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        categoryID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CategoryStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    customFields?:  {
      __typename: "ModelCategoryCustomFieldConnection",
      items?:  Array< {
        __typename: "CategoryCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        categoryID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CategoryStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateCategoryCustomFieldMutationVariables = {
  input: CreateCategoryCustomFieldInput,
};

export type CreateCategoryCustomFieldMutation = {
  createCategoryCustomField?:  {
    __typename: "CategoryCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    categoryID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type UpdateCategoryCustomFieldMutationVariables = {
  input: UpdateCategoryCustomFieldInput,
};

export type UpdateCategoryCustomFieldMutation = {
  updateCategoryCustomField?:  {
    __typename: "CategoryCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    categoryID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type DeleteCategoryCustomFieldMutationVariables = {
  input: DeleteCategoryCustomFieldInput,
};

export type DeleteCategoryCustomFieldMutation = {
  deleteCategoryCustomField?:  {
    __typename: "CategoryCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    categoryID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type CreateCustomFieldMutationVariables = {
  input: CreateCustomFieldInput,
  test: boolean,
};

export type CreateCustomFieldMutation = {
  createCustomField?:  {
    __typename: "CustomField",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    key: string,
    objectType: CustomFieldObjectType,
    valueType: CustomFieldValueType,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type UpdateCustomFieldMutationVariables = {
  input: UpdateCustomFieldInput,
};

export type UpdateCustomFieldMutation = {
  updateCustomField?:  {
    __typename: "CustomField",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    key: string,
    objectType: CustomFieldObjectType,
    valueType: CustomFieldValueType,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type DeleteCustomFieldMutationVariables = {
  input: DeleteCustomFieldInput,
};

export type DeleteCustomFieldMutation = {
  deleteCustomField?:  {
    __typename: "CustomField",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    key: string,
    objectType: CustomFieldObjectType,
    valueType: CustomFieldValueType,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type CreateDiscountMutationVariables = {
  input: CreateDiscountInput,
  test: boolean,
};

export type CreateDiscountMutation = {
  createDiscount?:  {
    __typename: "Discount",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    photo?: string | null,
    type: DiscountType,
    rate?: number | null,
    stats?:  {
      __typename: "DiscountStats",
      amount?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedAmount",
        id?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdateDiscountMutationVariables = {
  input: UpdateDiscountInput,
};

export type UpdateDiscountMutation = {
  updateDiscount?:  {
    __typename: "Discount",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    photo?: string | null,
    type: DiscountType,
    rate?: number | null,
    stats?:  {
      __typename: "DiscountStats",
      amount?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedAmount",
        id?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type DeleteDiscountMutationVariables = {
  input: DeleteDiscountInput,
};

export type DeleteDiscountMutation = {
  deleteDiscount?:  {
    __typename: "Discount",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    photo?: string | null,
    type: DiscountType,
    rate?: number | null,
    stats?:  {
      __typename: "DiscountStats",
      amount?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedAmount",
        id?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateOptionMutationVariables = {
  input: CreateOptionInput,
  test: boolean,
};

export type CreateOptionMutation = {
  createOption?:  {
    __typename: "Option",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    values:  Array< {
      __typename: "OptionValue",
      name: string,
      price: number,
    } | null >,
    stats?:  {
      __typename: "OptionStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdateOptionMutationVariables = {
  input: UpdateOptionInput,
};

export type UpdateOptionMutation = {
  updateOption?:  {
    __typename: "Option",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    values:  Array< {
      __typename: "OptionValue",
      name: string,
      price: number,
    } | null >,
    stats?:  {
      __typename: "OptionStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type DeleteOptionMutationVariables = {
  input: DeleteOptionInput,
};

export type DeleteOptionMutation = {
  deleteOption?:  {
    __typename: "Option",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    values:  Array< {
      __typename: "OptionValue",
      name: string,
      price: number,
    } | null >,
    stats?:  {
      __typename: "OptionStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type CreatePackMutationVariables = {
  input: CreatePackInput,
  test: boolean,
};

export type CreatePackMutation = {
  createPack?:  {
    __typename: "Pack",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    barcode?: string | null,
    reference?: string | null,
    sellPrice?: number | null,
    packVariations:  Array< {
      __typename: "PackVariation",
      options:  Array< {
        __typename: "PackVariationOption",
        productID: string,
        quantity: number,
        additionalPrice: number,
      } >,
    } >,
    customFields?:  {
      __typename: "ModelPackCustomFieldConnection",
      items?:  Array< {
        __typename: "PackCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        packID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "PackStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdatePackMutationVariables = {
  input: UpdatePackInput,
};

export type UpdatePackMutation = {
  updatePack?:  {
    __typename: "Pack",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    barcode?: string | null,
    reference?: string | null,
    sellPrice?: number | null,
    packVariations:  Array< {
      __typename: "PackVariation",
      options:  Array< {
        __typename: "PackVariationOption",
        productID: string,
        quantity: number,
        additionalPrice: number,
      } >,
    } >,
    customFields?:  {
      __typename: "ModelPackCustomFieldConnection",
      items?:  Array< {
        __typename: "PackCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        packID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "PackStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type DeletePackMutationVariables = {
  input: DeletePackInput,
};

export type DeletePackMutation = {
  deletePack?:  {
    __typename: "Pack",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    barcode?: string | null,
    reference?: string | null,
    sellPrice?: number | null,
    packVariations:  Array< {
      __typename: "PackVariation",
      options:  Array< {
        __typename: "PackVariationOption",
        productID: string,
        quantity: number,
        additionalPrice: number,
      } >,
    } >,
    customFields?:  {
      __typename: "ModelPackCustomFieldConnection",
      items?:  Array< {
        __typename: "PackCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        packID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "PackStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type CreatePackCustomFieldMutationVariables = {
  input: CreatePackCustomFieldInput,
};

export type CreatePackCustomFieldMutation = {
  createPackCustomField?:  {
    __typename: "PackCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    packID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type UpdatePackCustomFieldMutationVariables = {
  input: UpdatePackCustomFieldInput,
};

export type UpdatePackCustomFieldMutation = {
  updatePackCustomField?:  {
    __typename: "PackCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    packID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type DeletePackCustomFieldMutationVariables = {
  input: DeletePackCustomFieldInput,
};

export type DeletePackCustomFieldMutation = {
  deletePackCustomField?:  {
    __typename: "PackCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    packID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type CreateTagMutationVariables = {
  input: CreateTagInput,
  test: boolean,
};

export type CreateTagMutation = {
  createTag?:  {
    __typename: "Tag",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type UpdateTagMutationVariables = {
  input: UpdateTagInput,
};

export type UpdateTagMutation = {
  updateTag?:  {
    __typename: "Tag",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
};

export type DeleteTagMutation = {
  deleteTag?:  {
    __typename: "Tag",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type CreateTaxMutationVariables = {
  input: CreateTaxInput,
  test: boolean,
};

export type CreateTaxMutation = {
  createTax?:  {
    __typename: "Tax",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    rate?: number | null,
  } | null,
};

export type UpdateTaxMutationVariables = {
  input: UpdateTaxInput,
};

export type UpdateTaxMutation = {
  updateTax?:  {
    __typename: "Tax",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    rate?: number | null,
  } | null,
};

export type DeleteTaxMutationVariables = {
  input: DeleteTaxInput,
};

export type DeleteTaxMutation = {
  deleteTax?:  {
    __typename: "Tax",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    rate?: number | null,
  } | null,
};

export type CreateInventoryMovementMutationVariables = {
  input: CreateInventoryMovementInput,
  updatePrices?: boolean | null,
  test: boolean,
};

export type CreateInventoryMovementMutation = {
  createInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateInventoryMovementMutationVariables = {
  input: UpdateInventoryMovementInput,
  updatePrices?: boolean | null,
};

export type UpdateInventoryMovementMutation = {
  updateInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CancelInventoryMovementMutationVariables = {
  input: CancelInventoryMovementInput,
};

export type CancelInventoryMovementMutation = {
  cancelInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type EchoCreateInventoryMovementMutationVariables = {
  input: string,
};

export type EchoCreateInventoryMovementMutation = {
  echoCreateInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ResetProductInventoryMutationVariables = {
  input: ResetProductInventoryInput,
  test: boolean,
};

export type ResetProductInventoryMutation = {
  resetProductInventory?:  {
    __typename: "ResetProductInventoryHistory",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    productID: string,
    variantID?: string | null,
    salablePK: string,
    shopSalablePK: string,
    date: string,
    quantity?: number | null,
    buyPrice?: number | null,
  } | null,
};

export type ResetInventoryMutationVariables = {
  input: ResetInventoryInput,
  test: boolean,
};

export type ResetInventoryMutation = {
  resetInventory?:  {
    __typename: "ResetInventoryHistory",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    date: string,
  } | null,
};

export type CreateOrUpdateProductInventoryQuantityMutationVariables = {
  input: ProductInventoryQuantityInput,
};

export type CreateOrUpdateProductInventoryQuantityMutation = {
  createOrUpdateProductInventoryQuantity?:  {
    __typename: "ProductInventoryQuantity",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    shopID: string,
    test?: boolean | null,
    productID: string,
    variantID?: string | null,
    quantity?: number | null,
    resetDate?: string | null,
    shop?:  {
      __typename: "Shop",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      name: string,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      siret?: string | null,
      vat?: string | null,
      phone?: string | null,
      footnote?: string | null,
      logo?: string | null,
      websiteURL?: string | null,
      brandName: string,
      email?: string | null,
    } | null,
  } | null,
};

export type CreatePaymentMethodMutationVariables = {
  input: CreatePaymentMethodInput,
  test: boolean,
};

export type CreatePaymentMethodMutation = {
  createPaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    type: PaymentMethodType,
    changeType?: PaymentMethodChangeType | null,
    countOnCashbookOpeningClosing?: boolean | null,
    opensCashDrawer?: boolean | null,
    enabled?: boolean | null,
  } | null,
};

export type UpdatePaymentMethodMutationVariables = {
  input: UpdatePaymentMethodInput,
};

export type UpdatePaymentMethodMutation = {
  updatePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    type: PaymentMethodType,
    changeType?: PaymentMethodChangeType | null,
    countOnCashbookOpeningClosing?: boolean | null,
    opensCashDrawer?: boolean | null,
    enabled?: boolean | null,
  } | null,
};

export type DeletePaymentMethodMutationVariables = {
  input: DeletePaymentMethodInput,
};

export type DeletePaymentMethodMutation = {
  deletePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    type: PaymentMethodType,
    changeType?: PaymentMethodChangeType | null,
    countOnCashbookOpeningClosing?: boolean | null,
    opensCashDrawer?: boolean | null,
    enabled?: boolean | null,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  test: boolean,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type MergeCustomersMutationVariables = {
  input: MergeCustomersInput,
};

export type MergeCustomersMutation = {
  mergeCustomers?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type MergeCustomersAutoMutationVariables = {
  input: MergeCustomersAutoInput,
};

export type MergeCustomersAutoMutation = {
  mergeCustomersAuto?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type CreateCustomerAccountMovementMutationVariables = {
  input: CreateCustomerAccountMovementInput,
};

export type CreateCustomerAccountMovementMutation = {
  createCustomerAccountMovement?:  {
    __typename: "CustomerAccountMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    state: CustomerAccountMovementState,
    type: CustomerAccountMovementType,
    amount: number,
    saleID?: string | null,
    paymentID?: string | null,
    movementInDetails?:  {
      __typename: "CustomerAccountMovementInDetails",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
    } | null,
  } | null,
};

export type UpdateCustomerAccountMovementMutationVariables = {
  input: UpdateCustomerAccountMovementInput,
};

export type UpdateCustomerAccountMovementMutation = {
  updateCustomerAccountMovement?:  {
    __typename: "CustomerAccountMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    state: CustomerAccountMovementState,
    type: CustomerAccountMovementType,
    amount: number,
    saleID?: string | null,
    paymentID?: string | null,
    movementInDetails?:  {
      __typename: "CustomerAccountMovementInDetails",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
    } | null,
  } | null,
};

export type CreateCustomerCustomFieldMutationVariables = {
  input: CreateCustomerCustomFieldInput,
};

export type CreateCustomerCustomFieldMutation = {
  createCustomerCustomField?:  {
    __typename: "CustomerCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type UpdateCustomerCustomFieldMutationVariables = {
  input: UpdateCustomerCustomFieldInput,
};

export type UpdateCustomerCustomFieldMutation = {
  updateCustomerCustomField?:  {
    __typename: "CustomerCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type DeleteCustomerCustomFieldMutationVariables = {
  input: DeleteCustomerCustomFieldInput,
};

export type DeleteCustomerCustomFieldMutation = {
  deleteCustomerCustomField?:  {
    __typename: "CustomerCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type CreateSaleMutationVariables = {
  input: CreateSaleInput,
  test: boolean,
};

export type CreateSaleMutation = {
  createSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type UpdateSaleMutationVariables = {
  input: UpdateSaleInput,
};

export type UpdateSaleMutation = {
  updateSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type ObtainPendingSaleMutationVariables = {
  input: UpdateSaleInput,
};

export type ObtainPendingSaleMutation = {
  obtainPendingSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type DeleteSaleMutationVariables = {
  input: DeleteSaleInput,
};

export type DeleteSaleMutation = {
  deleteSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type SendSaleByEmailMutationVariables = {
  id: string,
  email: string,
};

export type SendSaleByEmailMutation = {
  sendSaleByEmail?: boolean | null,
};

export type CreateCashbookMutationVariables = {
  input: CreateCashbookInput,
  test: boolean,
};

export type CreateCashbookMutation = {
  createCashbook?:  {
    __typename: "Cashbook",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    number: number,
    state: CashbookState,
    total?: number | null,
    totalTaxFree?: number | null,
    openedAt: string,
    closedAt?: string | null,
    openingSellerID?: string | null,
    closingSellerID?: string | null,
    openingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    closingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    note?: string | null,
    movements?:  Array< {
      __typename: "CashbookMovement",
      type: CashbookMovementType,
      amount: number,
      motive?: string | null,
      createdAt: string,
    } | null > | null,
    openingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    closingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    totalExpected?: number | null,
    totalDifference?: number | null,
    customFields?:  Array< {
      __typename: "CashbookCustomField",
      customFieldID: string,
      name: string,
      valueType: CustomFieldValueType,
      value: string,
    } | null > | null,
    stats?:  {
      __typename: "CashbookStats",
      minSaleNumber?: number | null,
      maxSaleNumber?: number | null,
      numberOfSales?: number | null,
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      payments?:  Array< {
        __typename: "CashbookPaymentStats",
        id?: string | null,
        detail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        sellDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        refundDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdateCashbookMutationVariables = {
  input: UpdateCashbookInput,
};

export type UpdateCashbookMutation = {
  updateCashbook?:  {
    __typename: "Cashbook",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    number: number,
    state: CashbookState,
    total?: number | null,
    totalTaxFree?: number | null,
    openedAt: string,
    closedAt?: string | null,
    openingSellerID?: string | null,
    closingSellerID?: string | null,
    openingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    closingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    note?: string | null,
    movements?:  Array< {
      __typename: "CashbookMovement",
      type: CashbookMovementType,
      amount: number,
      motive?: string | null,
      createdAt: string,
    } | null > | null,
    openingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    closingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    totalExpected?: number | null,
    totalDifference?: number | null,
    customFields?:  Array< {
      __typename: "CashbookCustomField",
      customFieldID: string,
      name: string,
      valueType: CustomFieldValueType,
      value: string,
    } | null > | null,
    stats?:  {
      __typename: "CashbookStats",
      minSaleNumber?: number | null,
      maxSaleNumber?: number | null,
      numberOfSales?: number | null,
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      payments?:  Array< {
        __typename: "CashbookPaymentStats",
        id?: string | null,
        detail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        sellDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        refundDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type DeleteCashbookMutationVariables = {
  input: DeleteCashbookInput,
};

export type DeleteCashbookMutation = {
  deleteCashbook?:  {
    __typename: "Cashbook",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    number: number,
    state: CashbookState,
    total?: number | null,
    totalTaxFree?: number | null,
    openedAt: string,
    closedAt?: string | null,
    openingSellerID?: string | null,
    closingSellerID?: string | null,
    openingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    closingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    note?: string | null,
    movements?:  Array< {
      __typename: "CashbookMovement",
      type: CashbookMovementType,
      amount: number,
      motive?: string | null,
      createdAt: string,
    } | null > | null,
    openingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    closingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    totalExpected?: number | null,
    totalDifference?: number | null,
    customFields?:  Array< {
      __typename: "CashbookCustomField",
      customFieldID: string,
      name: string,
      valueType: CustomFieldValueType,
      value: string,
    } | null > | null,
    stats?:  {
      __typename: "CashbookStats",
      minSaleNumber?: number | null,
      maxSaleNumber?: number | null,
      numberOfSales?: number | null,
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      payments?:  Array< {
        __typename: "CashbookPaymentStats",
        id?: string | null,
        detail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        sellDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        refundDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type SendCashbookByEmailMutationVariables = {
  id: string,
  email: string,
};

export type SendCashbookByEmailMutation = {
  sendCashbookByEmail?: boolean | null,
};

export type CreatePaymentMutationVariables = {
  input: CreatePaymentInput,
  test: boolean,
};

export type CreatePaymentMutation = {
  createPayment?:  {
    __typename: "Payment",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    saleID: string,
    number?: number | null,
    date: string,
    state: PaymentState,
    type: PaymentType,
    paymentMethodID: string,
    paymentMethodName: string,
    paymentMethodType: PaymentMethodType,
    sourcePaymentID?: string | null,
    customerMovementID?: string | null,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    amount: number,
    totalTaxFree?: number | null,
    totalTax?: number | null,
    taxes?:  Array< {
      __typename: "SaleTax",
      taxID: string,
      rate: number,
      inclusive: boolean,
      total: number,
      totalTax: number,
      totalTaxFree: number,
    } | null > | null,
    integrationPaymentID?: string | null,
    integrationRefundID?: string | null,
  } | null,
};

export type UpdatePaymentMutationVariables = {
  input: UpdatePaymentInput,
};

export type UpdatePaymentMutation = {
  updatePayment?:  {
    __typename: "Payment",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    saleID: string,
    number?: number | null,
    date: string,
    state: PaymentState,
    type: PaymentType,
    paymentMethodID: string,
    paymentMethodName: string,
    paymentMethodType: PaymentMethodType,
    sourcePaymentID?: string | null,
    customerMovementID?: string | null,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    amount: number,
    totalTaxFree?: number | null,
    totalTax?: number | null,
    taxes?:  Array< {
      __typename: "SaleTax",
      taxID: string,
      rate: number,
      inclusive: boolean,
      total: number,
      totalTax: number,
      totalTaxFree: number,
    } | null > | null,
    integrationPaymentID?: string | null,
    integrationRefundID?: string | null,
  } | null,
};

export type DeletePaymentMutationVariables = {
  input: DeletePaymentInput,
};

export type DeletePaymentMutation = {
  deletePayment?:  {
    __typename: "Payment",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    saleID: string,
    number?: number | null,
    date: string,
    state: PaymentState,
    type: PaymentType,
    paymentMethodID: string,
    paymentMethodName: string,
    paymentMethodType: PaymentMethodType,
    sourcePaymentID?: string | null,
    customerMovementID?: string | null,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    amount: number,
    totalTaxFree?: number | null,
    totalTax?: number | null,
    taxes?:  Array< {
      __typename: "SaleTax",
      taxID: string,
      rate: number,
      inclusive: boolean,
      total: number,
      totalTax: number,
      totalTaxFree: number,
    } | null > | null,
    integrationPaymentID?: string | null,
    integrationRefundID?: string | null,
  } | null,
};

export type UploadImageMutationVariables = {
  input: UploadImageInput,
};

export type UploadImageMutation = {
  uploadImage?: string | null,
};

export type DeleteImageMutationVariables = {
  input: DeleteImageInput,
};

export type DeleteImageMutation = {
  deleteImage?: string | null,
};

export type EchoCreateBackgroundJobMutationVariables = {
  input: string,
};

export type EchoCreateBackgroundJobMutation = {
  echoCreateBackgroundJob?:  {
    __typename: "BackgroundJob",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    shopID?: string | null,
    sourceEmail?: string | null,
    type: BackgroundJobType,
    state: BackgroundJobState,
    details: ( {
        __typename: "CatalogMigrationDetails",
        PRODUCT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PRODUCT_VARIANT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CATEGORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        TAX?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        DISCOUNT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "CustomerMigrationDetails",
        CUSTOMER?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "InventoryMigrationDetails",
        INVENTORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ActivityMigrationDetails",
        PAYMENT_METHOD?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SLOT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CASHBOOK?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SALE?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PAYMENT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ImportDetails",
        IMPORT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      }
    ) | null,
    message?: string | null,
    reportUrl?: string | null,
    flatfileBatchId?: string | null,
    flatfileSheetId?: string | null,
  } | null,
};

export type EchoUpdateBackgroundJobMutationVariables = {
  input: string,
};

export type EchoUpdateBackgroundJobMutation = {
  echoUpdateBackgroundJob?:  {
    __typename: "BackgroundJob",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    shopID?: string | null,
    sourceEmail?: string | null,
    type: BackgroundJobType,
    state: BackgroundJobState,
    details: ( {
        __typename: "CatalogMigrationDetails",
        PRODUCT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PRODUCT_VARIANT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CATEGORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        TAX?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        DISCOUNT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "CustomerMigrationDetails",
        CUSTOMER?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "InventoryMigrationDetails",
        INVENTORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ActivityMigrationDetails",
        PAYMENT_METHOD?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SLOT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CASHBOOK?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SALE?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PAYMENT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ImportDetails",
        IMPORT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      }
    ) | null,
    message?: string | null,
    reportUrl?: string | null,
    flatfileBatchId?: string | null,
    flatfileSheetId?: string | null,
  } | null,
};

export type RunInventoryCalculationsMutationVariables = {
  companyID: string,
};

export type RunInventoryCalculationsMutation = {
  runInventoryCalculations?: boolean | null,
};

export type QueryESQueryVariables = {
  operation: string,
  path: string,
  size?: number | null,
  query: string,
};

export type QueryESQuery = {
  queryES?: string | null,
};

export type GetApiKeyTactillV4QueryVariables = {
  email: string,
  password: string,
};

export type GetApiKeyTactillV4Query = {
  getApiKeyTactillV4?: string | null,
};

export type GetStripeConnectLinkQueryVariables = {
  companyID: string,
  redirectPath: string,
};

export type GetStripeConnectLinkQuery = {
  getStripeConnectLink?: string | null,
};

export type GetStripeConnectionStatusQueryVariables = {
  companyID: string,
};

export type GetStripeConnectionStatusQuery = {
  getStripeConnectionStatus?: string | null,
};

export type ConnectStripeQueryVariables = {
  companyID: string,
  code: string,
};

export type ConnectStripeQuery = {
  connectStripe?: boolean | null,
};

export type DisconnectStripeQueryVariables = {
  companyID: string,
};

export type DisconnectStripeQuery = {
  disconnectStripe?: boolean | null,
};

export type GetStripeLocationIDQueryVariables = {
  slotID: string,
};

export type GetStripeLocationIDQuery = {
  getStripeLocationID?: string | null,
};

export type GetStripeTerminalConnectionTokenQueryVariables = {
  slotID: string,
};

export type GetStripeTerminalConnectionTokenQuery = {
  getStripeTerminalConnectionToken?: string | null,
};

export type CaptureStripePaymentIntentQueryVariables = {
  companyID: string,
  id: string,
};

export type CaptureStripePaymentIntentQuery = {
  captureStripePaymentIntent?: boolean | null,
};

export type RefundStripePaymentIntentQueryVariables = {
  companyID: string,
  id: string,
};

export type RefundStripePaymentIntentQuery = {
  refundStripePaymentIntent?: string | null,
};

export type GetStripeChargeQueryVariables = {
  companyID: string,
  paymentIntentID: string,
};

export type GetStripeChargeQuery = {
  getStripeCharge?: string | null,
};

export type GetStripePaymentReceiptQueryVariables = {
  companyID: string,
  paymentIntentID: string,
};

export type GetStripePaymentReceiptQuery = {
  getStripePaymentReceipt?:  {
    __typename: "StripePaymentReceipt",
    cardNetwork: string,
    contactlessInfo?: string | null,
    applicationID: string,
    applicationName: string,
    date: string,
    merchantDescriptor: string,
    merchantContractNumber: string,
    merchantBankCode: string,
    cardNumber: string,
    applicationCryptogram: string,
    transactionFileNumber: string,
    amount: number,
    transactionType: string,
    merchantFooter: string,
    destinationFooterMerchant: string,
    destinationFooterCustomer: string,
  } | null,
};

export type GetDashboardStatsQueryVariables = {
  test: boolean,
  companyID: string,
  shopIDs?: Array< string | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  interval?: string | null,
};

export type GetDashboardStatsQuery = {
  getDashboardStats?:  {
    __typename: "DashboardStats",
    content?:  {
      __typename: "DashboardStatsContent",
      general?:  {
        __typename: "DashboardGeneral",
        content?:  {
          __typename: "DashboardGeneralContent",
          nbSales?: number | null,
          index?: number | null,
          margin?: number | null,
        } | null,
        histogram?:  Array< {
          __typename: "GroupedDashboardGeneralContent",
          id: string,
        } | null > | null,
      } | null,
      payments?:  {
        __typename: "DashboardPayments",
        totals?:  {
          __typename: "DashboardPaymentTotals",
          nbPayments?: number | null,
          nbPositivePayments?: number | null,
          nbNegativePayments?: number | null,
        } | null,
        payments?:  Array< {
          __typename: "GroupedDashboardPaymentTotals",
          id: string,
          name?: string | null,
        } | null > | null,
      } | null,
      taxes?:  {
        __typename: "DashboardTaxes",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        taxes?:  Array< {
          __typename: "DashboardTaxContent",
          id: string,
        } | null > | null,
      } | null,
      categories?:  {
        __typename: "DashboardCategories",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        quantity?: number | null,
        margin?: number | null,
        categories?:  Array< {
          __typename: "DashboardCategoryContent",
          id: string,
          name?: string | null,
          quantity?: number | null,
          margin?: number | null,
        } | null > | null,
      } | null,
      products?:  {
        __typename: "DashboardProducts",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        quantity?: number | null,
        margin?: number | null,
        products?:  Array< {
          __typename: "DashboardProductContent",
          id: string,
          name?: string | null,
          quantity?: number | null,
          margin?: number | null,
        } | null > | null,
      } | null,
    } | null,
    byShop?:  Array< {
      __typename: "GroupedDashboardStats",
      id: string,
      content?:  {
        __typename: "DashboardStatsContent",
        categories?:  {
          __typename: "DashboardCategories",
          quantity?: number | null,
          margin?: number | null,
        } | null,
        products?:  {
          __typename: "DashboardProducts",
          quantity?: number | null,
          margin?: number | null,
        } | null,
      } | null,
    } | null > | null,
  } | null,
};

export type GetInventoryStatsQueryVariables = {
  test?: boolean | null,
  companyID: string,
  shopIDs?: Array< string | null > | null,
  date?: string | null,
  accountID?: string | null,
};

export type GetInventoryStatsQuery = {
  getInventoryStats?:  {
    __typename: "InventoryStats",
    products?:  {
      __typename: "InventoryStatsProducts",
      value?: number | null,
      products?:  Array< {
        __typename: "InventoryStatsProductContent",
        id: string,
        name?: string | null,
        quantity?: number | null,
        value?: number | null,
        variants?:  Array< {
          __typename: "InventoryStatsProductVariantContent",
          id: string,
          name?: string | null,
          quantity?: number | null,
          value?: number | null,
        } | null > | null,
      } | null > | null,
    } | null,
    byShop?:  Array< {
      __typename: "GroupedInventoryStats",
      id: string,
      content?:  {
        __typename: "InventoryStatsProducts",
        value?: number | null,
        products?:  Array< {
          __typename: "InventoryStatsProductContent",
          id: string,
          name?: string | null,
          quantity?: number | null,
          value?: number | null,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type ExportInventoryQueryVariables = {
  test?: boolean | null,
  companyID: string,
  shopIDs?: Array< string | null > | null,
  date?: string | null,
  email?: string | null,
  accountID?: string | null,
};

export type ExportInventoryQuery = {
  exportInventory?: boolean | null,
};

export type GetChargebeePortalSessionQueryVariables = {
  companyID: string,
  redirectPath?: string | null,
};

export type GetChargebeePortalSessionQuery = {
  getChargebeePortalSession?: string | null,
};

export type GetImportFileQueryVariables = {
  sheetID: string,
};

export type GetImportFileQuery = {
  getImportFile?: string | null,
};

export type GetFlatfileWorkbookConfigQueryVariables = {
  companyID: string,
  test: boolean,
  metadata?: string | null,
};

export type GetFlatfileWorkbookConfigQuery = {
  getFlatfileWorkbookConfig?: string | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID?: string | null,
    slotID?: string | null,
    userID?: string | null,
    test?: boolean | null,
    version: string,
    date: string,
    type: EventType,
    code: number,
    objectType: EventObjectType,
    objectID: string,
    action: string,
    actionData?: string | null,
    hash?: string | null,
  } | null,
};

export type ListEventsQueryVariables = {
  companyID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID?: string | null,
      slotID?: string | null,
      userID?: string | null,
      test?: boolean | null,
      version: string,
      date: string,
      type: EventType,
      code: number,
      objectType: EventObjectType,
      objectID: string,
      action: string,
      actionData?: string | null,
      hash?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserNotificationQueryVariables = {
  id: string,
};

export type GetUserNotificationQuery = {
  getUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    eventObjectType: EventObjectType,
    eventAction: string,
    action:  {
      __typename: "NotificationAction",
      type?: NotificationActionType | null,
      details: ( {
          __typename: "NotificationActionDetailsWebhook",
          url: string,
        }
      ) | null,
    },
    active: boolean,
  } | null,
};

export type ListUserNotificationsQueryVariables = {
  companyID: string,
};

export type ListUserNotificationsQuery = {
  listUserNotifications?:  {
    __typename: "ModelUserNotificationConnection",
    items?:  Array< {
      __typename: "UserNotification",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      eventObjectType: EventObjectType,
      eventAction: string,
      action:  {
        __typename: "NotificationAction",
        type?: NotificationActionType | null,
        details: ( {
            __typename: "NotificationActionDetailsWebhook",
            url: string,
          }
        ) | null,
      },
      active: boolean,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    phone?: string | null,
    locale?: LanguageCode | null,
    companies?:  {
      __typename: "ModelAccountCompanyConnection",
      items?:  Array< {
        __typename: "AccountCompany",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        owner?: boolean | null,
        role?: AccountRole | null,
        read?: boolean | null,
        update?: boolean | null,
        billing?: boolean | null,
        billingExport?: boolean | null,
        shopWrite?: boolean | null,
        deviceSlotWrite?: boolean | null,
        deviceSlotUse?: boolean | null,
        paymentMethodRead?: boolean | null,
        paymentMethodCreate?: boolean | null,
        paymentMethodUpdate?: boolean | null,
        paymentMethodDelete?: boolean | null,
        customerRead?: boolean | null,
        customerCreate?: boolean | null,
        customerUpdate?: boolean | null,
        customerDelete?: boolean | null,
        customerExport?: boolean | null,
        inventoryRead?: boolean | null,
        cashbookRead?: boolean | null,
        saleRead?: boolean | null,
        paymentRead?: boolean | null,
        userInvite?: boolean | null,
        userRead?: boolean | null,
        userUpdate?: boolean | null,
        userRemove?: boolean | null,
        sell?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    catalog?:  {
      __typename: "ModelAccountCatalogConnection",
      items?:  Array< {
        __typename: "AccountCatalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        catalogID: string,
        catalog?:  {
          __typename: "Catalog",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          id: string,
        } | null,
        read?: boolean | null,
        create?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    shops?:  {
      __typename: "ModelAccountShopConnection",
      items?:  Array< {
        __typename: "AccountShop",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        shopID: string,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        inventoryRead?: boolean | null,
        inventoryWrite?: boolean | null,
        cashbookRead?: boolean | null,
        cashbookWrite?: boolean | null,
        saleRead?: boolean | null,
        saleWrite?: boolean | null,
        paymentRead?: boolean | null,
        paymentWrite?: boolean | null,
        userInvite?: boolean | null,
        userRead?: boolean | null,
        userUpdate?: boolean | null,
        userRemove?: boolean | null,
        activitiesExport?: boolean | null,
        tillCashbooksRead?: boolean | null,
        tillSalesSearch?: boolean | null,
        tillSaleSell?: boolean | null,
        tillSaleRefund?: boolean | null,
        tillSaleReturn?: boolean | null,
        tillCashbookOpen?: boolean | null,
        tillCashbookClose?: boolean | null,
        eventCreate?: boolean | null,
        sync?: boolean | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    sellerCodes?:  {
      __typename: "ModelSellerCodeConnection",
      items?:  Array< {
        __typename: "SellerCode",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        accountID: string,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        code: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    intercomHMAC?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    name?: string | null,
    address?: string | null,
    country?: CountryCode | null,
    currency: CurrencyCode,
    currencyDecimals: number,
    vat?: string | null,
  } | null,
};

export type GetShopQueryVariables = {
  id: string,
};

export type GetShopQuery = {
  getShop?:  {
    __typename: "Shop",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    name: string,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    siret?: string | null,
    vat?: string | null,
    phone?: string | null,
    footnote?: string | null,
    logo?: string | null,
    websiteURL?: string | null,
    brandName: string,
    email?: string | null,
  } | null,
};

export type GetSellerCodeQueryVariables = {
  companyID: string,
};

export type GetSellerCodeQuery = {
  getSellerCode?:  {
    __typename: "SellerCode",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    code: string,
    id: string,
  } | null,
};

export type ListInvitationsQuery = {
  listInvitations?:  Array< {
    __typename: "AccountInvitation",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    companyName: string,
    email: string,
    role: AccountRole,
    shops: Array< string | null >,
    expiresAt: string,
    status: AccountInvitationStatus,
    senderID: string,
    senderName: string,
    senderEmail: string,
  } | null > | null,
};

export type ListCompanyInvitationsQueryVariables = {
  companyID: string,
};

export type ListCompanyInvitationsQuery = {
  listCompanyInvitations?:  {
    __typename: "ModelAccountInvitationConnection",
    items?:  Array< {
      __typename: "AccountInvitation",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      companyName: string,
      email: string,
      role: AccountRole,
      shops: Array< string | null >,
      expiresAt: string,
      status: AccountInvitationStatus,
      senderID: string,
      senderName: string,
      senderEmail: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  companyID: string,
};

export type ListUsersQuery = {
  listUsers?:  Array< {
    __typename: "User",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    company?:  {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    catalog?:  {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null > | null,
};

export type GetUserQueryVariables = {
  email: string,
  companyID: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    company?:  {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    catalog?:  {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type GetCompanySettingQueryVariables = {
  id: string,
};

export type GetCompanySettingQuery = {
  getCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type ListCompanySettingsQueryVariables = {
  companyID: string,
};

export type ListCompanySettingsQuery = {
  listCompanySettings?:  {
    __typename: "ModelCompanySettingConnection",
    items?:  Array< {
      __typename: "CompanySetting",
      id: string,
      updatedAt: string,
      createdAt: string,
      companyID: string,
      accountID?: string | null,
      key: string,
      value: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSlotQueryVariables = {
  id: string,
};

export type GetSlotQuery = {
  getSlot?:  {
    __typename: "Slot",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    name: string,
    status?: SlotStatus | null,
  } | null,
};

export type ListSlotsQueryVariables = {
  shopID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSlotsQuery = {
  listSlots?:  {
    __typename: "ModelSlotConnection",
    items?:  Array< {
      __typename: "Slot",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      name: string,
      status?: SlotStatus | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSlotsByCompanyQueryVariables = {
  companyID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSlotsByCompanyQuery = {
  listSlotsByCompany?:  {
    __typename: "ModelSlotConnection",
    items?:  Array< {
      __typename: "Slot",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      name: string,
      status?: SlotStatus | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetGridCellQueryVariables = {
  id: string,
};

export type GetGridCellQuery = {
  getGridCell?:  {
    __typename: "GridCell",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    compositeID: string,
    catalogID: string,
    test?: boolean | null,
    deviceID?: string | null,
    page: number,
    column: number,
    row: number,
    type: GridCellType,
    objectID: string,
  } | null,
};

export type ListGridCellsQueryVariables = {
  test: boolean,
  catalogID: string,
  deviceID?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGridCellsQuery = {
  listGridCells?:  {
    __typename: "ModelGridCellConnection",
    items?:  Array< {
      __typename: "GridCell",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      compositeID: string,
      catalogID: string,
      test?: boolean | null,
      deviceID?: string | null,
      page: number,
      column: number,
      row: number,
      type: GridCellType,
      objectID: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    categoryID: string,
    category?:  {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    bulkType?: BulkType | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    variantsCount?: number | null,
    variants?:  {
      __typename: "ModelProductVariantConnection",
      items?:  Array< {
        __typename: "ProductVariant",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        id: string,
        productID: string,
        variantID: string,
        options:  Array< {
          __typename: "VariationOption",
          name: string,
          value: string,
        } | null >,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    options?:  {
      __typename: "ModelProductOptionConnection",
      items?:  Array< {
        __typename: "ProductOption",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        optionID: string,
        option?:  {
          __typename: "Option",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelProductTagConnection",
      items?:  Array< {
        __typename: "ProductTag",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        tagID: string,
        tag?:  {
          __typename: "Tag",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          createdByID?: string | null,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    customFields?:  {
      __typename: "ModelProductCustomFieldConnection",
      items?:  Array< {
        __typename: "ProductCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type ListProductsQueryVariables = {
  test: boolean,
  catalogID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items?:  Array< {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestProductsQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterProductFilterInput | null,
  sort?: RequesterProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestProductsQuery = {
  requestProducts?:  {
    __typename: "RequesterProductConnection",
    items?:  Array< {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportProductsQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterProductFilterInput | null,
  sort?: RequesterProductSortInput | null,
  email?: string | null,
};

export type ExportProductsQuery = {
  exportProducts?: boolean | null,
};

export type GetProductVariantQueryVariables = {
  productID: string,
  variantID: string,
};

export type GetProductVariantQuery = {
  getProductVariant?:  {
    __typename: "ProductVariant",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    id: string,
    productID: string,
    variantID: string,
    options:  Array< {
      __typename: "VariationOption",
      name: string,
      value: string,
    } | null >,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    customFields?:  {
      __typename: "ModelCategoryCustomFieldConnection",
      items?:  Array< {
        __typename: "CategoryCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        categoryID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CategoryStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  test: boolean,
  catalogID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items?:  Array< {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestCategoriesQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterCategoryFilterInput | null,
  sort?: RequesterCategorySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestCategoriesQuery = {
  requestCategories?:  {
    __typename: "RequesterCategoryConnection",
    items?:  Array< {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportCategoriesQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterCategoryFilterInput | null,
  sort?: RequesterCategorySortInput | null,
  email?: string | null,
};

export type ExportCategoriesQuery = {
  exportCategories?: boolean | null,
};

export type GetCustomFieldQueryVariables = {
  id: string,
};

export type GetCustomFieldQuery = {
  getCustomField?:  {
    __typename: "CustomField",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    key: string,
    objectType: CustomFieldObjectType,
    valueType: CustomFieldValueType,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type ListCustomFieldsQueryVariables = {
  test: boolean,
  catalogID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomFieldsQuery = {
  listCustomFields?:  {
    __typename: "ModelCustomFieldConnection",
    items?:  Array< {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDiscountQueryVariables = {
  id: string,
};

export type GetDiscountQuery = {
  getDiscount?:  {
    __typename: "Discount",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    photo?: string | null,
    type: DiscountType,
    rate?: number | null,
    stats?:  {
      __typename: "DiscountStats",
      amount?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedAmount",
        id?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type ListDiscountsQueryVariables = {
  test: boolean,
  catalogID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDiscountsQuery = {
  listDiscounts?:  {
    __typename: "ModelDiscountConnection",
    items?:  Array< {
      __typename: "Discount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      photo?: string | null,
      type: DiscountType,
      rate?: number | null,
      stats?:  {
        __typename: "DiscountStats",
        amount?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedAmount",
          id?: string | null,
          amount?: number | null,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestDiscountsQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterDiscountFilterInput | null,
  sort?: RequesterDiscountSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestDiscountsQuery = {
  requestDiscounts?:  {
    __typename: "RequesterDiscountConnection",
    items?:  Array< {
      __typename: "Discount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      photo?: string | null,
      type: DiscountType,
      rate?: number | null,
      stats?:  {
        __typename: "DiscountStats",
        amount?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedAmount",
          id?: string | null,
          amount?: number | null,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportDiscountsQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterDiscountFilterInput | null,
  sort?: RequesterDiscountSortInput | null,
  email?: string | null,
};

export type ExportDiscountsQuery = {
  exportDiscounts?: boolean | null,
};

export type GetOptionQueryVariables = {
  id: string,
};

export type GetOptionQuery = {
  getOption?:  {
    __typename: "Option",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    values:  Array< {
      __typename: "OptionValue",
      name: string,
      price: number,
    } | null >,
    stats?:  {
      __typename: "OptionStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type ListOptionsQueryVariables = {
  test: boolean,
  catalogID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOptionsQuery = {
  listOptions?:  {
    __typename: "ModelOptionConnection",
    items?:  Array< {
      __typename: "Option",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      values:  Array< {
        __typename: "OptionValue",
        name: string,
        price: number,
      } | null >,
      stats?:  {
        __typename: "OptionStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestOptionsQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterOptionFilterInput | null,
  sort?: RequesterOptionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestOptionsQuery = {
  requestOptions?:  {
    __typename: "RequesterOptionConnection",
    items?:  Array< {
      __typename: "Option",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      values:  Array< {
        __typename: "OptionValue",
        name: string,
        price: number,
      } | null >,
      stats?:  {
        __typename: "OptionStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportOptionsQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterOptionFilterInput | null,
  sort?: RequesterOptionSortInput | null,
  email?: string | null,
};

export type ExportOptionsQuery = {
  exportOptions?: boolean | null,
};

export type GetPackQueryVariables = {
  id: string,
};

export type GetPackQuery = {
  getPack?:  {
    __typename: "Pack",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    barcode?: string | null,
    reference?: string | null,
    sellPrice?: number | null,
    packVariations:  Array< {
      __typename: "PackVariation",
      options:  Array< {
        __typename: "PackVariationOption",
        productID: string,
        quantity: number,
        additionalPrice: number,
      } >,
    } >,
    customFields?:  {
      __typename: "ModelPackCustomFieldConnection",
      items?:  Array< {
        __typename: "PackCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        packID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "PackStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type ListPacksQueryVariables = {
  test: boolean,
  catalogID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPacksQuery = {
  listPacks?:  {
    __typename: "ModelPackConnection",
    items?:  Array< {
      __typename: "Pack",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      barcode?: string | null,
      reference?: string | null,
      sellPrice?: number | null,
      packVariations:  Array< {
        __typename: "PackVariation",
        options:  Array< {
          __typename: "PackVariationOption",
          productID: string,
          quantity: number,
          additionalPrice: number,
        } >,
      } >,
      customFields?:  {
        __typename: "ModelPackCustomFieldConnection",
        items?:  Array< {
          __typename: "PackCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          packID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "PackStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestPacksQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterPackFilterInput | null,
  sort?: RequesterPackSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestPacksQuery = {
  requestPacks?:  {
    __typename: "RequesterPackConnection",
    items?:  Array< {
      __typename: "Pack",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      barcode?: string | null,
      reference?: string | null,
      sellPrice?: number | null,
      packVariations:  Array< {
        __typename: "PackVariation",
        options:  Array< {
          __typename: "PackVariationOption",
          productID: string,
          quantity: number,
          additionalPrice: number,
        } >,
      } >,
      customFields?:  {
        __typename: "ModelPackCustomFieldConnection",
        items?:  Array< {
          __typename: "PackCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          packID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "PackStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportPacksQueryVariables = {
  test: boolean,
  catalogID: string,
  filter?: RequesterPackFilterInput | null,
  sort?: RequesterPackSortInput | null,
  email?: string | null,
};

export type ExportPacksQuery = {
  exportPacks?: boolean | null,
};

export type GetTagQueryVariables = {
  id: string,
};

export type GetTagQuery = {
  getTag?:  {
    __typename: "Tag",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type ListTagsQueryVariables = {
  test: boolean,
  catalogID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsQuery = {
  listTags?:  {
    __typename: "ModelTagConnection",
    items?:  Array< {
      __typename: "Tag",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      createdByID?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTaxQueryVariables = {
  id: string,
};

export type GetTaxQuery = {
  getTax?:  {
    __typename: "Tax",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    rate?: number | null,
  } | null,
};

export type ListTaxesQueryVariables = {
  test: boolean,
  catalogID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaxesQuery = {
  listTaxes?:  {
    __typename: "ModelTaxConnection",
    items?:  Array< {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetInventoryMovementQueryVariables = {
  id: string,
};

export type GetInventoryMovementQuery = {
  getInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetInventoryMovementLineProductQueryVariables = {
  id: string,
};

export type GetInventoryMovementLineProductQuery = {
  getInventoryMovementLineProduct?:  {
    __typename: "InventoryMovementLineProduct",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    inventoryMovementID: string,
    productID: string,
    details?:  {
      __typename: "InventoryMovementLineDetailsProduct",
      name?: string | null,
      sellPrice?: number | null,
      buyPrice?: number | null,
      bulkType?: string | null,
      barcode?: string | null,
      reference?: string | null,
      categoryID?: string | null,
      taxID?: string | null,
      category?:  {
        __typename: "InventoryMovementLineDetailsCategory",
        name?: string | null,
      } | null,
      tax?:  {
        __typename: "InventoryMovementLineDetailsTax",
        rate?: number | null,
      } | null,
      tags?:  Array< {
        __typename: "InventoryMovementLineDetailsProductTag",
        tagID?: string | null,
        name?: string | null,
      } | null > | null,
      customFields?:  Array< {
        __typename: "InventoryMovementLineDetailsProductCustomField",
        customFieldID?: string | null,
        name?: string | null,
        objecType?: string | null,
        valueType?: string | null,
        value?: string | null,
      } | null > | null,
    } | null,
    hasVariants?: boolean | null,
    variants?:  {
      __typename: "ModelInventoryMovementLineProductVariantConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProductVariant",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementProductID: string,
        productID: string,
        variantID?: string | null,
        details?:  {
          __typename: "InventoryMovementLineDetailsProductVariant",
          nameShort?: string | null,
          nameLong?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    variantsCount?: number | null,
    variantsSelectedAll?: boolean | null,
    variantsAll?:  {
      __typename: "ModelInventoryMovementProductVariantConnection",
      items?:  Array< {
        __typename: "InventoryMovementProductVariant",
        productID: string,
        variantID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProductVariant",
          nameShort?: string | null,
          nameLong?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null,
        line?:  {
          __typename: "InventoryMovementLineProductVariant",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          inventoryMovementProductID: string,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          actualQuantity?: number | null,
          price?: number | null,
        } | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    variantsCountAll?: number | null,
    quantity?: number | null,
    actualQuantity?: number | null,
    price?: number | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type ListInventoryMovementsQueryVariables = {
  test: boolean,
  companyID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoryMovementsQuery = {
  listInventoryMovements?:  {
    __typename: "ModelInventoryMovementConnection",
    items?:  Array< {
      __typename: "InventoryMovement",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      state: InventoryMovementState,
      stateDate: string,
      motive?: string | null,
      origin?: InventoryMovementOrigin | null,
      originID?: string | null,
      destination?: InventoryMovementDestination | null,
      destinationID?: string | null,
      totalQuantity?: number | null,
      movements?:  {
        __typename: "ModelInventoryMovementLineConnection",
        items?:  Array< {
          __typename: "InventoryMovementLine",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          inventoryMovementID: string,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          actualQuantity?: number | null,
          price?: number | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelInventoryMovementLineProductConnection",
        items:  Array< {
          __typename: "InventoryMovementLineProduct",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          inventoryMovementID: string,
          productID: string,
          hasVariants?: boolean | null,
          variantsCount?: number | null,
          variantsSelectedAll?: boolean | null,
          variantsCountAll?: number | null,
          quantity?: number | null,
          actualQuantity?: number | null,
          price?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelInventoryMovementHistoryConnection",
        items?:  Array< {
          __typename: "InventoryMovementHistory",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          inventoryMovementID: string,
          state: InventoryMovementState,
          date: string,
          motive?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestInventoryMovementsQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterInventoryMovementFilterInput | null,
  sort?: RequesterInventoryMovementSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestInventoryMovementsQuery = {
  requestInventoryMovements?:  {
    __typename: "RequesterInventoryMovementConnection",
    items?:  Array< {
      __typename: "InventoryMovement",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      state: InventoryMovementState,
      stateDate: string,
      motive?: string | null,
      origin?: InventoryMovementOrigin | null,
      originID?: string | null,
      destination?: InventoryMovementDestination | null,
      destinationID?: string | null,
      totalQuantity?: number | null,
      movements?:  {
        __typename: "ModelInventoryMovementLineConnection",
        items?:  Array< {
          __typename: "InventoryMovementLine",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          inventoryMovementID: string,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          actualQuantity?: number | null,
          price?: number | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelInventoryMovementLineProductConnection",
        items:  Array< {
          __typename: "InventoryMovementLineProduct",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          inventoryMovementID: string,
          productID: string,
          hasVariants?: boolean | null,
          variantsCount?: number | null,
          variantsSelectedAll?: boolean | null,
          variantsCountAll?: number | null,
          quantity?: number | null,
          actualQuantity?: number | null,
          price?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelInventoryMovementHistoryConnection",
        items?:  Array< {
          __typename: "InventoryMovementHistory",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          inventoryMovementID: string,
          state: InventoryMovementState,
          date: string,
          motive?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportInventoryMovementQueryVariables = {
  id: string,
  email?: string | null,
};

export type ExportInventoryMovementQuery = {
  exportInventoryMovement?: boolean | null,
};

export type GetPaymentMethodQueryVariables = {
  id: string,
};

export type GetPaymentMethodQuery = {
  getPaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    type: PaymentMethodType,
    changeType?: PaymentMethodChangeType | null,
    countOnCashbookOpeningClosing?: boolean | null,
    opensCashDrawer?: boolean | null,
    enabled?: boolean | null,
  } | null,
};

export type ListPaymentMethodsQueryVariables = {
  test: boolean,
  companyID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentMethodsQuery = {
  listPaymentMethods?:  {
    __typename: "ModelPaymentMethodConnection",
    items?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      type: PaymentMethodType,
      changeType?: PaymentMethodChangeType | null,
      countOnCashbookOpeningClosing?: boolean | null,
      opensCashDrawer?: boolean | null,
      enabled?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type ListCustomersQueryVariables = {
  test: boolean,
  companyID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items?:  Array< {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestCustomersQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterCustomerFilterInput | null,
  sort?: RequesterCustomerSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestCustomersQuery = {
  requestCustomers?:  {
    __typename: "RequesterCustomerConnection",
    items?:  Array< {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportCustomersQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterCustomerFilterInput | null,
  sort?: RequesterCustomerSortInput | null,
  email?: string | null,
};

export type ExportCustomersQuery = {
  exportCustomers?: boolean | null,
};

export type GetCustomerAccountMovementQueryVariables = {
  id: string,
};

export type GetCustomerAccountMovementQuery = {
  getCustomerAccountMovement?:  {
    __typename: "CustomerAccountMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    state: CustomerAccountMovementState,
    type: CustomerAccountMovementType,
    amount: number,
    saleID?: string | null,
    paymentID?: string | null,
    movementInDetails?:  {
      __typename: "CustomerAccountMovementInDetails",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
    } | null,
  } | null,
};

export type ListCustomerAccountMovementsQueryVariables = {
  test: boolean,
  companyID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerAccountMovementsQuery = {
  listCustomerAccountMovements?:  {
    __typename: "ModelCustomerAccountMovementConnection",
    items?:  Array< {
      __typename: "CustomerAccountMovement",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      customerID: string,
      state: CustomerAccountMovementState,
      type: CustomerAccountMovementType,
      amount: number,
      saleID?: string | null,
      paymentID?: string | null,
      movementInDetails?:  {
        __typename: "CustomerAccountMovementInDetails",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSaleQueryVariables = {
  id: string,
};

export type GetSaleQuery = {
  getSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type ListSalesQueryVariables = {
  test: boolean,
  companyID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSalesQuery = {
  listSales?:  {
    __typename: "ModelSaleConnection",
    items?:  Array< {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestSalesQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterSaleFilterInput | null,
  sort?: RequesterSaleSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestSalesQuery = {
  requestSales?:  {
    __typename: "RequesterSaleConnection",
    items?:  Array< {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportSalesQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterSaleFilterInput | null,
  sort?: RequesterSaleSortInput | null,
  email?: string | null,
};

export type ExportSalesQuery = {
  exportSales?: boolean | null,
};

export type GetCashbookQueryVariables = {
  id: string,
};

export type GetCashbookQuery = {
  getCashbook?:  {
    __typename: "Cashbook",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    number: number,
    state: CashbookState,
    total?: number | null,
    totalTaxFree?: number | null,
    openedAt: string,
    closedAt?: string | null,
    openingSellerID?: string | null,
    closingSellerID?: string | null,
    openingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    closingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    note?: string | null,
    movements?:  Array< {
      __typename: "CashbookMovement",
      type: CashbookMovementType,
      amount: number,
      motive?: string | null,
      createdAt: string,
    } | null > | null,
    openingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    closingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    totalExpected?: number | null,
    totalDifference?: number | null,
    customFields?:  Array< {
      __typename: "CashbookCustomField",
      customFieldID: string,
      name: string,
      valueType: CustomFieldValueType,
      value: string,
    } | null > | null,
    stats?:  {
      __typename: "CashbookStats",
      minSaleNumber?: number | null,
      maxSaleNumber?: number | null,
      numberOfSales?: number | null,
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      payments?:  Array< {
        __typename: "CashbookPaymentStats",
        id?: string | null,
        detail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        sellDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        refundDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type ListCashbooksQueryVariables = {
  test: boolean,
  companyID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCashbooksQuery = {
  listCashbooks?:  {
    __typename: "ModelCashbookConnection",
    items?:  Array< {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestCashbooksQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterCashbookFilterInput | null,
  sort?: RequesterCashbookSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestCashbooksQuery = {
  requestCashbooks?:  {
    __typename: "RequesterCashbookConnection",
    items?:  Array< {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportCashbooksQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterCashbookFilterInput | null,
  sort?: RequesterCashbookSortInput | null,
  email?: string | null,
};

export type ExportCashbooksQuery = {
  exportCashbooks?: boolean | null,
};

export type GetPaymentQueryVariables = {
  id: string,
};

export type GetPaymentQuery = {
  getPayment?:  {
    __typename: "Payment",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    saleID: string,
    number?: number | null,
    date: string,
    state: PaymentState,
    type: PaymentType,
    paymentMethodID: string,
    paymentMethodName: string,
    paymentMethodType: PaymentMethodType,
    sourcePaymentID?: string | null,
    customerMovementID?: string | null,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    amount: number,
    totalTaxFree?: number | null,
    totalTax?: number | null,
    taxes?:  Array< {
      __typename: "SaleTax",
      taxID: string,
      rate: number,
      inclusive: boolean,
      total: number,
      totalTax: number,
      totalTaxFree: number,
    } | null > | null,
    integrationPaymentID?: string | null,
    integrationRefundID?: string | null,
  } | null,
};

export type ListPaymentsQueryVariables = {
  test: boolean,
  companyID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentsQuery = {
  listPayments?:  {
    __typename: "ModelPaymentConnection",
    items?:  Array< {
      __typename: "Payment",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      saleID: string,
      number?: number | null,
      date: string,
      state: PaymentState,
      type: PaymentType,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestPaymentsQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterPaymentFilterInput | null,
  sort?: RequesterPaymentSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestPaymentsQuery = {
  requestPayments?:  {
    __typename: "RequesterPaymentConnection",
    items?:  Array< {
      __typename: "Payment",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      saleID: string,
      number?: number | null,
      date: string,
      state: PaymentState,
      type: PaymentType,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ExportPaymentsQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterPaymentFilterInput | null,
  sort?: RequesterPaymentSortInput | null,
  email?: string | null,
};

export type ExportPaymentsQuery = {
  exportPayments?: boolean | null,
};

export type GetBackgroundJobQueryVariables = {
  id: string,
};

export type GetBackgroundJobQuery = {
  getBackgroundJob?:  {
    __typename: "BackgroundJob",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    shopID?: string | null,
    sourceEmail?: string | null,
    type: BackgroundJobType,
    state: BackgroundJobState,
    details: ( {
        __typename: "CatalogMigrationDetails",
        PRODUCT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PRODUCT_VARIANT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CATEGORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        TAX?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        DISCOUNT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "CustomerMigrationDetails",
        CUSTOMER?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "InventoryMigrationDetails",
        INVENTORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ActivityMigrationDetails",
        PAYMENT_METHOD?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SLOT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CASHBOOK?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SALE?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PAYMENT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ImportDetails",
        IMPORT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      }
    ) | null,
    message?: string | null,
    reportUrl?: string | null,
    flatfileBatchId?: string | null,
    flatfileSheetId?: string | null,
  } | null,
};

export type ListBackgroundJobsQueryVariables = {
  companyID: string,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBackgroundJobsQuery = {
  listBackgroundJobs?:  {
    __typename: "ModelBackgroundJobConnection",
    items?:  Array< {
      __typename: "BackgroundJob",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      shopID?: string | null,
      sourceEmail?: string | null,
      type: BackgroundJobType,
      state: BackgroundJobState,
      details: ( {
          __typename: "CatalogMigrationDetails",
          PRODUCT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          PRODUCT_VARIANT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          CATEGORY?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          TAX?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          DISCOUNT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "CustomerMigrationDetails",
          CUSTOMER?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "InventoryMigrationDetails",
          INVENTORY?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "ActivityMigrationDetails",
          PAYMENT_METHOD?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          SLOT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          CASHBOOK?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          SALE?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          PAYMENT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "ImportDetails",
          IMPORT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        }
      ) | null,
      message?: string | null,
      reportUrl?: string | null,
      flatfileBatchId?: string | null,
      flatfileSheetId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type RequestBackgroundJobsQueryVariables = {
  test: boolean,
  companyID: string,
  filter?: RequesterBackgroundJobFilterInput | null,
  sort?: RequesterBackgroundJobSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestBackgroundJobsQuery = {
  requestBackgroundJobs?:  {
    __typename: "RequesterBackgroundJobConnection",
    items?:  Array< {
      __typename: "BackgroundJob",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      shopID?: string | null,
      sourceEmail?: string | null,
      type: BackgroundJobType,
      state: BackgroundJobState,
      details: ( {
          __typename: "CatalogMigrationDetails",
          PRODUCT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          PRODUCT_VARIANT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          CATEGORY?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          TAX?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          DISCOUNT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "CustomerMigrationDetails",
          CUSTOMER?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "InventoryMigrationDetails",
          INVENTORY?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "ActivityMigrationDetails",
          PAYMENT_METHOD?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          SLOT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          CASHBOOK?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          SALE?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
          PAYMENT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        } | {
          __typename: "ImportDetails",
          IMPORT?:  {
            __typename: string,
            total?: number | null,
            successCount?: number | null,
            errorCount?: number | null,
          } | null,
        }
      ) | null,
      message?: string | null,
      reportUrl?: string | null,
      flatfileBatchId?: string | null,
      flatfileSheetId?: string | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetMigrationPreviewQueryVariables = {
  migrationTypes?: Array< MigrationType > | null,
  apiKey: string,
};

export type GetMigrationPreviewQuery = {
  getMigrationPreview:  Array<( {
      __typename: "CatalogPreviewDetails",
      PRODUCT?:  {
        __typename: string,
        count?: number | null,
      } | null,
      PRODUCT_VARIANT?:  {
        __typename: string,
        count?: number | null,
      } | null,
      CATEGORY?:  {
        __typename: string,
        count?: number | null,
      } | null,
      TAX?:  {
        __typename: string,
        count?: number | null,
      } | null,
      DISCOUNT?:  {
        __typename: string,
        count?: number | null,
      } | null,
    } | {
      __typename: "CustomerPreviewDetails",
      CUSTOMER?:  {
        __typename: string,
        count?: number | null,
      } | null,
    } | {
      __typename: "InventoryPreviewDetails",
      INVENTORY?:  {
        __typename: string,
        count?: number | null,
      } | null,
    } | {
      __typename: "ActivityPreviewDetails",
      PAYMENT_METHOD?:  {
        __typename: string,
        count?: number | null,
      } | null,
      SLOT?:  {
        __typename: string,
        count?: number | null,
      } | null,
      CASHBOOK?:  {
        __typename: string,
        count?: number | null,
      } | null,
      SALE?:  {
        __typename: string,
        count?: number | null,
      } | null,
    }
  ) | null > | null,
};

export type SyncCatalogQueryVariables = {
  catalogID: string,
  test: boolean,
  lastSyncDate?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SyncCatalogQuery = {
  syncCatalog?:  {
    __typename: "CatalogObjectConnection",
    items:  Array<( {
        __typename: "Product",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        categoryID: string,
        category?:  {
          __typename: "Category",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          color?: ColorPalette | null,
          iconText?: string | null,
          photo?: string | null,
          customFields?:  {
            __typename: "ModelCategoryCustomFieldConnection",
            nextToken?: string | null,
          } | null,
          stats?:  {
            __typename: "CategoryStats",
            quantity?: number | null,
          } | null,
        } | null,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        bulkType?: BulkType | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        variations?:  Array< {
          __typename: "ProductVariation",
          name?: string | null,
          options?: Array< string | null > | null,
        } | null > | null,
        variantsCount?: number | null,
        variants?:  {
          __typename: "ModelProductVariantConnection",
          items?:  Array< {
            __typename: "ProductVariant",
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            catalogID: string,
            test?: boolean | null,
            id: string,
            productID: string,
            variantID: string,
            taxID: string,
            buyPrice?: number | null,
            sellPrice?: number | null,
            barcode?: string | null,
            reference?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        options?:  {
          __typename: "ModelProductOptionConnection",
          items?:  Array< {
            __typename: "ProductOption",
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            catalogID: string,
            test?: boolean | null,
            productID: string,
            optionID: string,
            id: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        tags?:  {
          __typename: "ModelProductTagConnection",
          items?:  Array< {
            __typename: "ProductTag",
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            catalogID: string,
            test?: boolean | null,
            productID: string,
            tagID: string,
            id: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        customFields?:  {
          __typename: "ModelProductCustomFieldConnection",
          items?:  Array< {
            __typename: "ProductCustomField",
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            catalogID: string,
            test?: boolean | null,
            productID: string,
            customFieldID: string,
            customFieldKey: string,
            value: string,
            id: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
          shop?:  {
            __typename: "Shop",
            id: string,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            companyID: string,
            name: string,
            siret?: string | null,
            vat?: string | null,
            phone?: string | null,
            footnote?: string | null,
            logo?: string | null,
            websiteURL?: string | null,
            brandName: string,
            email?: string | null,
          } | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
          histogram?:  Array< {
            __typename: "GroupedTotalsDetail",
            id: string,
          } | null > | null,
        } | null,
        inventoryStats?:  {
          __typename: "ProductInventoryStats",
          content?:  {
            __typename: "ProductInventoryStatsContent",
            quantity?: number | null,
            value?: number | null,
          } | null,
          byShop?:  Array< {
            __typename: "GroupedProductInventoryStats",
            id: string,
          } | null > | null,
        } | null,
      }
    ) | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SyncCompanyQueryVariables = {
  companyID: string,
  test: boolean,
  lastSyncDate?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SyncCompanyQuery = {
  syncCompany?:  {
    __typename: "CompanyObjectConnection",
    items:  Array<( {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      }
    ) | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SyncShopQueryVariables = {
  shopID: string,
  test: boolean,
  lastSyncDate?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SyncShopQuery = {
  syncShop?:  {
    __typename: "ShopObjectConnection",
    items:  Array<( {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      }
    ) | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SyncSlotQueryVariables = {
  slotID: string,
  test: boolean,
  lastSyncDate?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SyncSlotQuery = {
  syncSlot?:  {
    __typename: "SlotSyncObjectConnection",
    items:  Array<( {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          openingSeller?:  {
            __typename: "Seller",
            id: string,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            firstName?: string | null,
            lastName?: string | null,
            color: ColorPalette,
            iconText?: string | null,
            photo?: string | null,
            email: string,
          } | null,
          closingSeller?:  {
            __typename: "Seller",
            id: string,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            firstName?: string | null,
            lastName?: string | null,
            color: ColorPalette,
            iconText?: string | null,
            photo?: string | null,
            email: string,
          } | null,
          note?: string | null,
          movements?:  Array< {
            __typename: "CashbookMovement",
            type: CashbookMovementType,
            amount: number,
            motive?: string | null,
            createdAt: string,
          } | null > | null,
          openingCashFund?:  Array< {
            __typename: "CashbookPaymentMethodCashFund",
            paymentMethodID: string,
            paymentMethodName: string,
            paymentMethodType: PaymentMethodType,
            total: number,
          } | null > | null,
          closingCashFund?:  Array< {
            __typename: "CashbookPaymentMethodCashFund",
            paymentMethodID: string,
            paymentMethodName: string,
            paymentMethodType: PaymentMethodType,
            total: number,
          } | null > | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
          customFields?:  Array< {
            __typename: "CashbookCustomField",
            customFieldID: string,
            name: string,
            valueType: CustomFieldValueType,
            value: string,
          } | null > | null,
          stats?:  {
            __typename: "CashbookStats",
            minSaleNumber?: number | null,
            maxSaleNumber?: number | null,
            numberOfSales?: number | null,
          } | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          cashbook?:  {
            __typename: "Cashbook",
            id: string,
            originalID?: string | null,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            companyID: string,
            shopID: string,
            test?: boolean | null,
            sourceID: string,
            sourceName: string,
            number: number,
            state: CashbookState,
            total?: number | null,
            totalTaxFree?: number | null,
            openedAt: string,
            closedAt?: string | null,
            openingSellerID?: string | null,
            closingSellerID?: string | null,
            note?: string | null,
            totalExpected?: number | null,
            totalDifference?: number | null,
          } | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          targetSale?:  {
            __typename: "Sale",
            id: string,
            originalID?: string | null,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            companyID: string,
            shopID: string,
            test?: boolean | null,
            sourceID: string,
            sourceName: string,
            cashbookID?: string | null,
            name?: string | null,
            number?: number | null,
            state: SaleType,
            refundStatus: SaleStatus,
            refundedStatus: SaleStatus,
            paymentStatus: SaleStatus,
            note?: string | null,
            targetID?: string | null,
            openedAt: string,
            closedAt?: string | null,
            pendingAt?: string | null,
            customerID?: string | null,
            sellerID?: string | null,
            total: number,
            totalDiscount: number,
            totalTaxFree: number,
            totalTax: number,
            totalRest: number,
          } | null,
          refundSales?:  {
            __typename: "ModelSaleConnection",
            nextToken?: string | null,
          } | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          customer?:  {
            __typename: "Customer",
            id: string,
            originalID?: string | null,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            companyID: string,
            test?: boolean | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phone?: string | null,
            phoneCode?: string | null,
            companyName?: string | null,
            color?: ColorPalette | null,
            iconText?: string | null,
            note?: string | null,
            fidelityCardNumber?: string | null,
          } | null,
          sellerID?: string | null,
          seller?:  {
            __typename: "Seller",
            id: string,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            firstName?: string | null,
            lastName?: string | null,
            color: ColorPalette,
            iconText?: string | null,
            photo?: string | null,
            email: string,
          } | null,
          lines?:  Array< {
            __typename: "SaleLine",
            quantity: number,
            price: number,
            refundedQuantity?: number | null,
            note?: string | null,
            operationType: SaleLineOperationType,
            state: SaleLineState,
            targetSaleLineIndex?: number | null,
            motive?: string | null,
            total: number,
            totalSellPrice: number,
            totalDiscount: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          discounts?:  Array< {
            __typename: "SaleDiscount",
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
          payments?:  Array< {
            __typename: "SalePayment",
            state: PaymentState,
            type: PaymentType,
            date: string,
            paymentMethodID: string,
            paymentMethodName: string,
            paymentMethodType: PaymentMethodType,
            sourcePaymentID?: string | null,
            customerMovementID?: string | null,
            cashbookID?: string | null,
            amount: number,
            totalTaxFree?: number | null,
            totalTax?: number | null,
            paymentID: string,
            integrationPaymentID?: string | null,
            integrationRefundID?: string | null,
          } | null > | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
          stats?:  {
            __typename: "SaleStats",
            margin?: number | null,
          } | null,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          items?:  Array< {
            __typename: "Sale",
            id: string,
            originalID?: string | null,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            companyID: string,
            shopID: string,
            test?: boolean | null,
            sourceID: string,
            sourceName: string,
            cashbookID?: string | null,
            name?: string | null,
            number?: number | null,
            state: SaleType,
            refundStatus: SaleStatus,
            refundedStatus: SaleStatus,
            paymentStatus: SaleStatus,
            note?: string | null,
            targetID?: string | null,
            openedAt: string,
            closedAt?: string | null,
            pendingAt?: string | null,
            customerID?: string | null,
            sellerID?: string | null,
            total: number,
            totalDiscount: number,
            totalTaxFree: number,
            totalTax: number,
            totalRest: number,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          address?:  {
            __typename: "Address",
            address?: string | null,
            zipcode?: string | null,
            city?: string | null,
            country?: string | null,
          } | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
          account?:  {
            __typename: "CustomerAccount",
            id: string,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            companyID: string,
            test?: boolean | null,
            balance: number,
          } | null,
          customFields?:  {
            __typename: "ModelCustomerCustomFieldConnection",
            nextToken?: string | null,
          } | null,
          stats?:  {
            __typename: "CustomerStats",
            nbSales?: number | null,
            index?: number | null,
            totalDiscount?: number | null,
          } | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          details: ( {
              __typename: "SaleLinePackDetails",
              packID: string,
              name: string,
            } | {
              __typename: "SaleLineProductDetails",
              productID: string,
              name: string,
              bulkType?: BulkType | null,
              total: number,
              totalTax: number,
              totalTaxFree: number,
              totalGlobalDiscountFree?: number | null,
              totalTaxFreeGlobalDiscountFree?: number | null,
              totalTaxGlobalDiscountFree?: number | null,
              unitPriceGlobalDiscountFree?: number | null,
              totalDiscountFree?: number | null,
              totalTaxFreeDiscountFree?: number | null,
              totalTaxDiscountFree?: number | null,
              unitPriceDiscountFree?: number | null,
            }
          ),
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          discounts?:  Array< {
            __typename: "SaleDiscount",
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          taxes?:  Array< {
            __typename: "SaleTax",
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          } | null > | null,
          paymentID: string,
          payment?:  {
            __typename: "Payment",
            id: string,
            originalID?: string | null,
            updatedAt: string,
            createdAt: string,
            deprecated?: boolean | null,
            companyID: string,
            shopID: string,
            test?: boolean | null,
            sourceID: string,
            sourceName: string,
            saleID: string,
            number?: number | null,
            date: string,
            state: PaymentState,
            type: PaymentType,
            paymentMethodID: string,
            paymentMethodName: string,
            paymentMethodType: PaymentMethodType,
            sourcePaymentID?: string | null,
            customerMovementID?: string | null,
            cashbookID?: string | null,
            amount: number,
            totalTaxFree?: number | null,
            totalTax?: number | null,
            integrationPaymentID?: string | null,
            integrationRefundID?: string | null,
          } | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          taxes?:  Array< {
            __typename: "GroupedTotals",
            id: string,
          } | null > | null,
          margin?: number | null,
        } | null,
      }
    ) | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCompanySubscriptionVariables = {
  companyID: string,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    name?: string | null,
    address?: string | null,
    country?: CountryCode | null,
    currency: CurrencyCode,
    currencyDecimals: number,
    vat?: string | null,
  } | null,
};

export type OnUpdateCompanySubscriptionVariables = {
  companyID: string,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    name?: string | null,
    address?: string | null,
    country?: CountryCode | null,
    currency: CurrencyCode,
    currencyDecimals: number,
    vat?: string | null,
  } | null,
};

export type OnDeleteCompanySubscriptionVariables = {
  companyID: string,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "CompanyResults",
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    catalog?:  {
      __typename: "Catalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "Shop",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      name: string,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      siret?: string | null,
      vat?: string | null,
      phone?: string | null,
      footnote?: string | null,
      logo?: string | null,
      websiteURL?: string | null,
      brandName: string,
      email?: string | null,
    } | null > | null,
    companyAccounts?:  Array< {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
    shopAccounts?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
    catalogAccounts?:  Array< {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type OnCreateShopSubscriptionVariables = {
  companyID: string,
};

export type OnCreateShopSubscription = {
  onCreateShop?:  {
    __typename: "Shop",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    name: string,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    siret?: string | null,
    vat?: string | null,
    phone?: string | null,
    footnote?: string | null,
    logo?: string | null,
    websiteURL?: string | null,
    brandName: string,
    email?: string | null,
  } | null,
};

export type OnUpdateShopSubscriptionVariables = {
  companyID: string,
};

export type OnUpdateShopSubscription = {
  onUpdateShop?:  {
    __typename: "Shop",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    name: string,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    siret?: string | null,
    vat?: string | null,
    phone?: string | null,
    footnote?: string | null,
    logo?: string | null,
    websiteURL?: string | null,
    brandName: string,
    email?: string | null,
  } | null,
};

export type OnDeleteShopSubscriptionVariables = {
  companyID: string,
};

export type OnDeleteShopSubscription = {
  onDeleteShop?:  {
    __typename: "ShopResults",
    shop?:  {
      __typename: "Shop",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      name: string,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      siret?: string | null,
      vat?: string | null,
      phone?: string | null,
      footnote?: string | null,
      logo?: string | null,
      websiteURL?: string | null,
      brandName: string,
      email?: string | null,
    } | null,
    shopAccounts?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};

export type OnCreateCompanySettingSubscriptionVariables = {
  companyID: string,
};

export type OnCreateCompanySettingSubscription = {
  onCreateCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type OnUpdateCompanySettingSubscriptionVariables = {
  companyID: string,
};

export type OnUpdateCompanySettingSubscription = {
  onUpdateCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type OnDeleteCompanySettingSubscriptionVariables = {
  companyID: string,
};

export type OnDeleteCompanySettingSubscription = {
  onDeleteCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type OnChangeCompanySettingSubscriptionVariables = {
  companyID: string,
};

export type OnChangeCompanySettingSubscription = {
  onChangeCompanySetting?:  {
    __typename: "CompanySetting",
    id: string,
    updatedAt: string,
    createdAt: string,
    companyID: string,
    accountID?: string | null,
    key: string,
    value: string,
  } | null,
};

export type OnCreateSlotSubscriptionVariables = {
  shopID: string,
};

export type OnCreateSlotSubscription = {
  onCreateSlot?:  {
    __typename: "Slot",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    name: string,
    status?: SlotStatus | null,
  } | null,
};

export type OnUpdateSlotSubscriptionVariables = {
  shopID: string,
};

export type OnUpdateSlotSubscription = {
  onUpdateSlot?:  {
    __typename: "Slot",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    name: string,
    status?: SlotStatus | null,
  } | null,
};

export type OnChangeSlotSubscriptionVariables = {
  shopID: string,
};

export type OnChangeSlotSubscription = {
  onChangeSlot?:  {
    __typename: "Slot",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    name: string,
    status?: SlotStatus | null,
  } | null,
};

export type OnCreateSellerCodeSubscriptionVariables = {
  companyID: string,
};

export type OnCreateSellerCodeSubscription = {
  onCreateSellerCode?:  {
    __typename: "SellerCode",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    code: string,
    id: string,
  } | null,
};

export type OnUpdateSellerCodeSubscriptionVariables = {
  companyID: string,
};

export type OnUpdateSellerCodeSubscription = {
  onUpdateSellerCode?:  {
    __typename: "SellerCode",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    code: string,
    id: string,
  } | null,
};

export type OnDeleteSellerCodeSubscriptionVariables = {
  companyID: string,
};

export type OnDeleteSellerCodeSubscription = {
  onDeleteSellerCode?:  {
    __typename: "SellerCode",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    code: string,
    id: string,
  } | null,
};

export type OnChangeSellerCodeSubscriptionVariables = {
  companyID: string,
};

export type OnChangeSellerCodeSubscription = {
  onChangeSellerCode?:  {
    __typename: "SellerCode",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    accountID: string,
    companyID: string,
    company?:  {
      __typename: "Company",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      name?: string | null,
      address?: string | null,
      country?: CountryCode | null,
      currency: CurrencyCode,
      currencyDecimals: number,
      vat?: string | null,
    } | null,
    code: string,
    id: string,
  } | null,
};

export type OnUpdateGridCellSubscriptionVariables = {
  catalogID: string,
  deviceID?: string | null,
  test: boolean,
};

export type OnUpdateGridCellSubscription = {
  onUpdateGridCell?:  {
    __typename: "GridCell",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    compositeID: string,
    catalogID: string,
    test?: boolean | null,
    deviceID?: string | null,
    page: number,
    column: number,
    row: number,
    type: GridCellType,
    objectID: string,
  } | null,
};

export type OnDeleteGridCellSubscriptionVariables = {
  catalogID: string,
  deviceID?: string | null,
  test: boolean,
};

export type OnDeleteGridCellSubscription = {
  onDeleteGridCell?:  {
    __typename: "GridCell",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    compositeID: string,
    catalogID: string,
    test?: boolean | null,
    deviceID?: string | null,
    page: number,
    column: number,
    row: number,
    type: GridCellType,
    objectID: string,
  } | null,
};

export type OnChangeGridCellSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeGridCellSubscription = {
  onChangeGridCell?:  {
    __typename: "GridCell",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    compositeID: string,
    catalogID: string,
    test?: boolean | null,
    deviceID?: string | null,
    page: number,
    column: number,
    row: number,
    type: GridCellType,
    objectID: string,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    categoryID: string,
    category?:  {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    bulkType?: BulkType | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    variantsCount?: number | null,
    variants?:  {
      __typename: "ModelProductVariantConnection",
      items?:  Array< {
        __typename: "ProductVariant",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        id: string,
        productID: string,
        variantID: string,
        options:  Array< {
          __typename: "VariationOption",
          name: string,
          value: string,
        } | null >,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    options?:  {
      __typename: "ModelProductOptionConnection",
      items?:  Array< {
        __typename: "ProductOption",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        optionID: string,
        option?:  {
          __typename: "Option",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelProductTagConnection",
      items?:  Array< {
        __typename: "ProductTag",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        tagID: string,
        tag?:  {
          __typename: "Tag",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          createdByID?: string | null,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    customFields?:  {
      __typename: "ModelProductCustomFieldConnection",
      items?:  Array< {
        __typename: "ProductCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    categoryID: string,
    category?:  {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    bulkType?: BulkType | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    variantsCount?: number | null,
    variants?:  {
      __typename: "ModelProductVariantConnection",
      items?:  Array< {
        __typename: "ProductVariant",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        id: string,
        productID: string,
        variantID: string,
        options:  Array< {
          __typename: "VariationOption",
          name: string,
          value: string,
        } | null >,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    options?:  {
      __typename: "ModelProductOptionConnection",
      items?:  Array< {
        __typename: "ProductOption",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        optionID: string,
        option?:  {
          __typename: "Option",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelProductTagConnection",
      items?:  Array< {
        __typename: "ProductTag",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        tagID: string,
        tag?:  {
          __typename: "Tag",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          createdByID?: string | null,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    customFields?:  {
      __typename: "ModelProductCustomFieldConnection",
      items?:  Array< {
        __typename: "ProductCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    categoryID: string,
    category?:  {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    bulkType?: BulkType | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    variantsCount?: number | null,
    variants?:  {
      __typename: "ModelProductVariantConnection",
      items?:  Array< {
        __typename: "ProductVariant",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        id: string,
        productID: string,
        variantID: string,
        options:  Array< {
          __typename: "VariationOption",
          name: string,
          value: string,
        } | null >,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    options?:  {
      __typename: "ModelProductOptionConnection",
      items?:  Array< {
        __typename: "ProductOption",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        optionID: string,
        option?:  {
          __typename: "Option",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelProductTagConnection",
      items?:  Array< {
        __typename: "ProductTag",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        tagID: string,
        tag?:  {
          __typename: "Tag",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          createdByID?: string | null,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    customFields?:  {
      __typename: "ModelProductCustomFieldConnection",
      items?:  Array< {
        __typename: "ProductCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnChangeProductSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeProductSubscription = {
  onChangeProduct?:  {
    __typename: "Product",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    categoryID: string,
    category?:  {
      __typename: "Category",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      customFields?:  {
        __typename: "ModelCategoryCustomFieldConnection",
        items?:  Array< {
          __typename: "CategoryCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          categoryID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CategoryStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    taxID: string,
    tax?:  {
      __typename: "Tax",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      rate?: number | null,
    } | null,
    bulkType?: BulkType | null,
    buyPrice?: number | null,
    sellPrice?: number | null,
    barcode?: string | null,
    reference?: string | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    variantsCount?: number | null,
    variants?:  {
      __typename: "ModelProductVariantConnection",
      items?:  Array< {
        __typename: "ProductVariant",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        id: string,
        productID: string,
        variantID: string,
        options:  Array< {
          __typename: "VariationOption",
          name: string,
          value: string,
        } | null >,
        taxID: string,
        tax?:  {
          __typename: "Tax",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          rate?: number | null,
        } | null,
        buyPrice?: number | null,
        sellPrice?: number | null,
        barcode?: string | null,
        reference?: string | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
        stats?:  {
          __typename: "ProductStats",
          margin?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    options?:  {
      __typename: "ModelProductOptionConnection",
      items?:  Array< {
        __typename: "ProductOption",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        optionID: string,
        option?:  {
          __typename: "Option",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelProductTagConnection",
      items?:  Array< {
        __typename: "ProductTag",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        tagID: string,
        tag?:  {
          __typename: "Tag",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          name: string,
          createdByID?: string | null,
        } | null,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    customFields?:  {
      __typename: "ModelProductCustomFieldConnection",
      items?:  Array< {
        __typename: "ProductCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        productID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inventoryQuantities?:  Array< {
      __typename: "ProductInventoryQuantity",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      shopID: string,
      test?: boolean | null,
      productID: string,
      variantID?: string | null,
      quantity?: number | null,
      resetDate?: string | null,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
    } | null > | null,
    stats?:  {
      __typename: "ProductStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      margin?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
    inventoryStats?:  {
      __typename: "ProductInventoryStats",
      content?:  {
        __typename: "ProductInventoryStatsContent",
        quantity?: number | null,
        value?: number | null,
      } | null,
      byShop?:  Array< {
        __typename: "GroupedProductInventoryStats",
        id: string,
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnUpdateProductVariantsSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateProductVariantsSubscription = {
  onUpdateProductVariants?:  {
    __typename: "ProductVariationResults",
    catalogID: string,
    test?: boolean | null,
    productID: string,
    product?:  {
      __typename: "Product",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      color?: ColorPalette | null,
      iconText?: string | null,
      photo?: string | null,
      categoryID: string,
      category?:  {
        __typename: "Category",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        name: string,
        color?: ColorPalette | null,
        iconText?: string | null,
        photo?: string | null,
        customFields?:  {
          __typename: "ModelCategoryCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CategoryStats",
          quantity?: number | null,
        } | null,
      } | null,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      bulkType?: BulkType | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      variations?:  Array< {
        __typename: "ProductVariation",
        name?: string | null,
        options?: Array< string | null > | null,
      } | null > | null,
      variantsCount?: number | null,
      variants?:  {
        __typename: "ModelProductVariantConnection",
        items?:  Array< {
          __typename: "ProductVariant",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          id: string,
          productID: string,
          variantID: string,
          taxID: string,
          buyPrice?: number | null,
          sellPrice?: number | null,
          barcode?: string | null,
          reference?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      options?:  {
        __typename: "ModelProductOptionConnection",
        items?:  Array< {
          __typename: "ProductOption",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          optionID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      tags?:  {
        __typename: "ModelProductTagConnection",
        items?:  Array< {
          __typename: "ProductTag",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          tagID: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      customFields?:  {
        __typename: "ModelProductCustomFieldConnection",
        items?:  Array< {
          __typename: "ProductCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          productID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    variations?:  Array< {
      __typename: "ProductVariation",
      name?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
    insertions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    modifications?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    deletions?:  Array< {
      __typename: "ProductVariant",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      id: string,
      productID: string,
      variantID: string,
      options:  Array< {
        __typename: "VariationOption",
        name: string,
        value: string,
      } | null >,
      taxID: string,
      tax?:  {
        __typename: "Tax",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        rate?: number | null,
      } | null,
      buyPrice?: number | null,
      sellPrice?: number | null,
      barcode?: string | null,
      reference?: string | null,
      inventoryQuantities?:  Array< {
        __typename: "ProductInventoryQuantity",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        shopID: string,
        test?: boolean | null,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        resetDate?: string | null,
        shop?:  {
          __typename: "Shop",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          name: string,
          siret?: string | null,
          vat?: string | null,
          phone?: string | null,
          footnote?: string | null,
          logo?: string | null,
          websiteURL?: string | null,
          brandName: string,
          email?: string | null,
        } | null,
      } | null > | null,
      stats?:  {
        __typename: "ProductStats",
        margin?: number | null,
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
      inventoryStats?:  {
        __typename: "ProductInventoryStats",
        content?:  {
          __typename: "ProductInventoryStatsContent",
          quantity?: number | null,
          value?: number | null,
        } | null,
        byShop?:  Array< {
          __typename: "GroupedProductInventoryStats",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type OnCreateProductOptionSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateProductOptionSubscription = {
  onCreateProductOption?:  {
    __typename: "ProductOption",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    optionID: string,
    option?:  {
      __typename: "Option",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      values:  Array< {
        __typename: "OptionValue",
        name: string,
        price: number,
      } | null >,
      stats?:  {
        __typename: "OptionStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    id: string,
  } | null,
};

export type OnDeleteProductOptionSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteProductOptionSubscription = {
  onDeleteProductOption?:  {
    __typename: "ProductOption",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    optionID: string,
    option?:  {
      __typename: "Option",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      values:  Array< {
        __typename: "OptionValue",
        name: string,
        price: number,
      } | null >,
      stats?:  {
        __typename: "OptionStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    id: string,
  } | null,
};

export type OnChangeProductOptionSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeProductOptionSubscription = {
  onChangeProductOption?:  {
    __typename: "ProductOption",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    optionID: string,
    option?:  {
      __typename: "Option",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      values:  Array< {
        __typename: "OptionValue",
        name: string,
        price: number,
      } | null >,
      stats?:  {
        __typename: "OptionStats",
        quantity?: number | null,
        histogram?:  Array< {
          __typename: "GroupedTotalsDetail",
          id: string,
        } | null > | null,
      } | null,
    } | null,
    id: string,
  } | null,
};

export type OnCreateProductTagSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateProductTagSubscription = {
  onCreateProductTag?:  {
    __typename: "ProductTag",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    tagID: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      createdByID?: string | null,
    } | null,
    id: string,
  } | null,
};

export type OnDeleteProductTagSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteProductTagSubscription = {
  onDeleteProductTag?:  {
    __typename: "ProductTag",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    tagID: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      createdByID?: string | null,
    } | null,
    id: string,
  } | null,
};

export type OnChangeProductTagSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeProductTagSubscription = {
  onChangeProductTag?:  {
    __typename: "ProductTag",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    tagID: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      name: string,
      createdByID?: string | null,
    } | null,
    id: string,
  } | null,
};

export type OnCreateProductCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateProductCustomFieldSubscription = {
  onCreateProductCustomField?:  {
    __typename: "ProductCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnUpdateProductCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateProductCustomFieldSubscription = {
  onUpdateProductCustomField?:  {
    __typename: "ProductCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnDeleteProductCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteProductCustomFieldSubscription = {
  onDeleteProductCustomField?:  {
    __typename: "ProductCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnChangeProductCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeProductCustomFieldSubscription = {
  onChangeProductCustomField?:  {
    __typename: "ProductCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    productID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    customFields?:  {
      __typename: "ModelCategoryCustomFieldConnection",
      items?:  Array< {
        __typename: "CategoryCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        categoryID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CategoryStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    customFields?:  {
      __typename: "ModelCategoryCustomFieldConnection",
      items?:  Array< {
        __typename: "CategoryCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        categoryID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CategoryStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    customFields?:  {
      __typename: "ModelCategoryCustomFieldConnection",
      items?:  Array< {
        __typename: "CategoryCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        categoryID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CategoryStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnChangeCategorySubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeCategorySubscription = {
  onChangeCategory?:  {
    __typename: "Category",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    customFields?:  {
      __typename: "ModelCategoryCustomFieldConnection",
      items?:  Array< {
        __typename: "CategoryCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        categoryID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CategoryStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnCreateCategoryCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateCategoryCustomFieldSubscription = {
  onCreateCategoryCustomField?:  {
    __typename: "CategoryCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    categoryID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnUpdateCategoryCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateCategoryCustomFieldSubscription = {
  onUpdateCategoryCustomField?:  {
    __typename: "CategoryCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    categoryID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnDeleteCategoryCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteCategoryCustomFieldSubscription = {
  onDeleteCategoryCustomField?:  {
    __typename: "CategoryCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    categoryID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnChangeCategoryCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeCategoryCustomFieldSubscription = {
  onChangeCategoryCustomField?:  {
    __typename: "CategoryCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    categoryID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnCreateCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateCustomFieldSubscription = {
  onCreateCustomField?:  {
    __typename: "CustomField",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    key: string,
    objectType: CustomFieldObjectType,
    valueType: CustomFieldValueType,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type OnUpdateCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateCustomFieldSubscription = {
  onUpdateCustomField?:  {
    __typename: "CustomField",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    key: string,
    objectType: CustomFieldObjectType,
    valueType: CustomFieldValueType,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type OnDeleteCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteCustomFieldSubscription = {
  onDeleteCustomField?:  {
    __typename: "CustomField",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    key: string,
    objectType: CustomFieldObjectType,
    valueType: CustomFieldValueType,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type OnChangeCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeCustomFieldSubscription = {
  onChangeCustomField?:  {
    __typename: "CustomField",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    key: string,
    objectType: CustomFieldObjectType,
    valueType: CustomFieldValueType,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type OnCreateDiscountSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateDiscountSubscription = {
  onCreateDiscount?:  {
    __typename: "Discount",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    photo?: string | null,
    type: DiscountType,
    rate?: number | null,
    stats?:  {
      __typename: "DiscountStats",
      amount?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedAmount",
        id?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnUpdateDiscountSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateDiscountSubscription = {
  onUpdateDiscount?:  {
    __typename: "Discount",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    photo?: string | null,
    type: DiscountType,
    rate?: number | null,
    stats?:  {
      __typename: "DiscountStats",
      amount?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedAmount",
        id?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnDeleteDiscountSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteDiscountSubscription = {
  onDeleteDiscount?:  {
    __typename: "Discount",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    photo?: string | null,
    type: DiscountType,
    rate?: number | null,
    stats?:  {
      __typename: "DiscountStats",
      amount?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedAmount",
        id?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnChangeDiscountSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeDiscountSubscription = {
  onChangeDiscount?:  {
    __typename: "Discount",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    photo?: string | null,
    type: DiscountType,
    rate?: number | null,
    stats?:  {
      __typename: "DiscountStats",
      amount?: number | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedAmount",
        id?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnCreateOptionSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateOptionSubscription = {
  onCreateOption?:  {
    __typename: "Option",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    values:  Array< {
      __typename: "OptionValue",
      name: string,
      price: number,
    } | null >,
    stats?:  {
      __typename: "OptionStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnUpdateOptionSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateOptionSubscription = {
  onUpdateOption?:  {
    __typename: "Option",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    values:  Array< {
      __typename: "OptionValue",
      name: string,
      price: number,
    } | null >,
    stats?:  {
      __typename: "OptionStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnDeleteOptionSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteOptionSubscription = {
  onDeleteOption?:  {
    __typename: "Option",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    values:  Array< {
      __typename: "OptionValue",
      name: string,
      price: number,
    } | null >,
    stats?:  {
      __typename: "OptionStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnChangeOptionSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeOptionSubscription = {
  onChangeOption?:  {
    __typename: "Option",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    values:  Array< {
      __typename: "OptionValue",
      name: string,
      price: number,
    } | null >,
    stats?:  {
      __typename: "OptionStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnCreatePackSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreatePackSubscription = {
  onCreatePack?:  {
    __typename: "Pack",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    barcode?: string | null,
    reference?: string | null,
    sellPrice?: number | null,
    packVariations:  Array< {
      __typename: "PackVariation",
      options:  Array< {
        __typename: "PackVariationOption",
        productID: string,
        quantity: number,
        additionalPrice: number,
      } >,
    } >,
    customFields?:  {
      __typename: "ModelPackCustomFieldConnection",
      items?:  Array< {
        __typename: "PackCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        packID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "PackStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnUpdatePackSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdatePackSubscription = {
  onUpdatePack?:  {
    __typename: "Pack",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    barcode?: string | null,
    reference?: string | null,
    sellPrice?: number | null,
    packVariations:  Array< {
      __typename: "PackVariation",
      options:  Array< {
        __typename: "PackVariationOption",
        productID: string,
        quantity: number,
        additionalPrice: number,
      } >,
    } >,
    customFields?:  {
      __typename: "ModelPackCustomFieldConnection",
      items?:  Array< {
        __typename: "PackCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        packID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "PackStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnDeletePackSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeletePackSubscription = {
  onDeletePack?:  {
    __typename: "Pack",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    barcode?: string | null,
    reference?: string | null,
    sellPrice?: number | null,
    packVariations:  Array< {
      __typename: "PackVariation",
      options:  Array< {
        __typename: "PackVariationOption",
        productID: string,
        quantity: number,
        additionalPrice: number,
      } >,
    } >,
    customFields?:  {
      __typename: "ModelPackCustomFieldConnection",
      items?:  Array< {
        __typename: "PackCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        packID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "PackStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnChangePackSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangePackSubscription = {
  onChangePack?:  {
    __typename: "Pack",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    iconText?: string | null,
    photo?: string | null,
    barcode?: string | null,
    reference?: string | null,
    sellPrice?: number | null,
    packVariations:  Array< {
      __typename: "PackVariation",
      options:  Array< {
        __typename: "PackVariationOption",
        productID: string,
        quantity: number,
        additionalPrice: number,
      } >,
    } >,
    customFields?:  {
      __typename: "ModelPackCustomFieldConnection",
      items?:  Array< {
        __typename: "PackCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        catalogID: string,
        test?: boolean | null,
        packID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "PackStats",
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      quantity?: number | null,
      histogram?:  Array< {
        __typename: "GroupedTotalsDetail",
        id: string,
      } | null > | null,
    } | null,
  } | null,
};

export type OnCreatePackCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreatePackCustomFieldSubscription = {
  onCreatePackCustomField?:  {
    __typename: "PackCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    packID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnUpdatePackCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdatePackCustomFieldSubscription = {
  onUpdatePackCustomField?:  {
    __typename: "PackCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    packID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnDeletePackCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeletePackCustomFieldSubscription = {
  onDeletePackCustomField?:  {
    __typename: "PackCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    packID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnChangePackCustomFieldSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangePackCustomFieldSubscription = {
  onChangePackCustomField?:  {
    __typename: "PackCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    packID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnCreateTagSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateTagSubscription = {
  onCreateTag?:  {
    __typename: "Tag",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type OnUpdateTagSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateTagSubscription = {
  onUpdateTag?:  {
    __typename: "Tag",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type OnDeleteTagSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteTagSubscription = {
  onDeleteTag?:  {
    __typename: "Tag",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type OnChangeTagSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeTagSubscription = {
  onChangeTag?:  {
    __typename: "Tag",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    name: string,
    createdByID?: string | null,
  } | null,
};

export type OnCreateTaxSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnCreateTaxSubscription = {
  onCreateTax?:  {
    __typename: "Tax",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    rate?: number | null,
  } | null,
};

export type OnUpdateTaxSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnUpdateTaxSubscription = {
  onUpdateTax?:  {
    __typename: "Tax",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    rate?: number | null,
  } | null,
};

export type OnDeleteTaxSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnDeleteTaxSubscription = {
  onDeleteTax?:  {
    __typename: "Tax",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    rate?: number | null,
  } | null,
};

export type OnChangeTaxSubscriptionVariables = {
  catalogID: string,
  test: boolean,
};

export type OnChangeTaxSubscription = {
  onChangeTax?:  {
    __typename: "Tax",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    catalogID: string,
    test?: boolean | null,
    rate?: number | null,
  } | null,
};

export type OnCreateOriginInventoryMovementSubscriptionVariables = {
  originID: string,
  test: boolean,
};

export type OnCreateOriginInventoryMovementSubscription = {
  onCreateOriginInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateDestinationInventoryMovementSubscriptionVariables = {
  destinationID: string,
  test: boolean,
};

export type OnCreateDestinationInventoryMovementSubscription = {
  onCreateDestinationInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOriginInventoryMovementSubscriptionVariables = {
  originID: string,
  test: boolean,
};

export type OnUpdateOriginInventoryMovementSubscription = {
  onUpdateOriginInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateDestinationInventoryMovementSubscriptionVariables = {
  destinationID: string,
  test: boolean,
};

export type OnUpdateDestinationInventoryMovementSubscription = {
  onUpdateDestinationInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCancelOriginInventoryMovementSubscriptionVariables = {
  originID: string,
  test: boolean,
};

export type OnCancelOriginInventoryMovementSubscription = {
  onCancelOriginInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCancelDestinationInventoryMovementSubscriptionVariables = {
  destinationID: string,
  test: boolean,
};

export type OnCancelDestinationInventoryMovementSubscription = {
  onCancelDestinationInventoryMovement?:  {
    __typename: "InventoryMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    state: InventoryMovementState,
    stateDate: string,
    motive?: string | null,
    origin?: InventoryMovementOrigin | null,
    originID?: string | null,
    destination?: InventoryMovementDestination | null,
    destinationID?: string | null,
    totalQuantity?: number | null,
    movements?:  {
      __typename: "ModelInventoryMovementLineConnection",
      items?:  Array< {
        __typename: "InventoryMovementLine",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        variantID?: string | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelInventoryMovementLineProductConnection",
      items:  Array< {
        __typename: "InventoryMovementLineProduct",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        productID: string,
        details?:  {
          __typename: "InventoryMovementLineDetailsProduct",
          name?: string | null,
          sellPrice?: number | null,
          buyPrice?: number | null,
          bulkType?: string | null,
          barcode?: string | null,
          reference?: string | null,
          categoryID?: string | null,
          taxID?: string | null,
        } | null,
        hasVariants?: boolean | null,
        variants?:  {
          __typename: "ModelInventoryMovementLineProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCount?: number | null,
        variantsSelectedAll?: boolean | null,
        variantsAll?:  {
          __typename: "ModelInventoryMovementProductVariantConnection",
          nextToken?: string | null,
        } | null,
        variantsCountAll?: number | null,
        quantity?: number | null,
        actualQuantity?: number | null,
        price?: number | null,
        inventoryQuantities?:  Array< {
          __typename: "ProductInventoryQuantity",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          shopID: string,
          test?: boolean | null,
          productID: string,
          variantID?: string | null,
          quantity?: number | null,
          resetDate?: string | null,
        } | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelInventoryMovementHistoryConnection",
      items?:  Array< {
        __typename: "InventoryMovementHistory",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        inventoryMovementID: string,
        state: InventoryMovementState,
        date: string,
        motive?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnResetProductInventorySubscriptionVariables = {
  shopID: string,
  test: boolean,
};

export type OnResetProductInventorySubscription = {
  onResetProductInventory?:  {
    __typename: "ResetProductInventoryHistory",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    productID: string,
    variantID?: string | null,
    salablePK: string,
    shopSalablePK: string,
    date: string,
    quantity?: number | null,
    buyPrice?: number | null,
  } | null,
};

export type OnResetInventorySubscriptionVariables = {
  shopID: string,
  test: boolean,
};

export type OnResetInventorySubscription = {
  onResetInventory?:  {
    __typename: "ResetInventoryHistory",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    date: string,
  } | null,
};

export type OnUpdateProductInventoryQuantitySubscriptionVariables = {
  shopID: string,
  test: boolean,
};

export type OnUpdateProductInventoryQuantitySubscription = {
  onUpdateProductInventoryQuantity?:  {
    __typename: "ProductInventoryQuantity",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    shopID: string,
    test?: boolean | null,
    productID: string,
    variantID?: string | null,
    quantity?: number | null,
    resetDate?: string | null,
    shop?:  {
      __typename: "Shop",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      name: string,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      siret?: string | null,
      vat?: string | null,
      phone?: string | null,
      footnote?: string | null,
      logo?: string | null,
      websiteURL?: string | null,
      brandName: string,
      email?: string | null,
    } | null,
  } | null,
};

export type OnChangeProductInventoryQuantitySubscriptionVariables = {
  shopID: string,
  test: boolean,
};

export type OnChangeProductInventoryQuantitySubscription = {
  onChangeProductInventoryQuantity?:  {
    __typename: "ProductInventoryQuantity",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    shopID: string,
    test?: boolean | null,
    productID: string,
    variantID?: string | null,
    quantity?: number | null,
    resetDate?: string | null,
    shop?:  {
      __typename: "Shop",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      name: string,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      siret?: string | null,
      vat?: string | null,
      phone?: string | null,
      footnote?: string | null,
      logo?: string | null,
      websiteURL?: string | null,
      brandName: string,
      email?: string | null,
    } | null,
  } | null,
};

export type OnCreatePaymentMethodSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnCreatePaymentMethodSubscription = {
  onCreatePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    type: PaymentMethodType,
    changeType?: PaymentMethodChangeType | null,
    countOnCashbookOpeningClosing?: boolean | null,
    opensCashDrawer?: boolean | null,
    enabled?: boolean | null,
  } | null,
};

export type OnUpdatePaymentMethodSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnUpdatePaymentMethodSubscription = {
  onUpdatePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    type: PaymentMethodType,
    changeType?: PaymentMethodChangeType | null,
    countOnCashbookOpeningClosing?: boolean | null,
    opensCashDrawer?: boolean | null,
    enabled?: boolean | null,
  } | null,
};

export type OnDeletePaymentMethodSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnDeletePaymentMethodSubscription = {
  onDeletePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    type: PaymentMethodType,
    changeType?: PaymentMethodChangeType | null,
    countOnCashbookOpeningClosing?: boolean | null,
    opensCashDrawer?: boolean | null,
    enabled?: boolean | null,
  } | null,
};

export type OnChangePaymentMethodSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnChangePaymentMethodSubscription = {
  onChangePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    name: string,
    color?: ColorPalette | null,
    type: PaymentMethodType,
    changeType?: PaymentMethodChangeType | null,
    countOnCashbookOpeningClosing?: boolean | null,
    opensCashDrawer?: boolean | null,
    enabled?: boolean | null,
  } | null,
};

export type OnCreateCustomerSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type OnDeleteCustomerSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type OnChangeCustomerSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnChangeCustomerSubscription = {
  onChangeCustomer?:  {
    __typename: "Customer",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneCode?: string | null,
    companyName?: string | null,
    color?: ColorPalette | null,
    iconText?: string | null,
    address?:  {
      __typename: "Address",
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      country?: string | null,
    } | null,
    note?: string | null,
    fidelityCardNumber?: string | null,
    account?:  {
      __typename: "CustomerAccount",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      balance: number,
      movements?:  {
        __typename: "ModelCustomerAccountMovementConnection",
        items?:  Array< {
          __typename: "CustomerAccountMovement",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          state: CustomerAccountMovementState,
          type: CustomerAccountMovementType,
          amount: number,
          saleID?: string | null,
          paymentID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    customFields?:  {
      __typename: "ModelCustomerCustomFieldConnection",
      items?:  Array< {
        __typename: "CustomerCustomField",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        customerID: string,
        customFieldID: string,
        customFieldKey: string,
        customField?:  {
          __typename: "CustomField",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          catalogID: string,
          test?: boolean | null,
          key: string,
          objectType: CustomFieldObjectType,
          valueType: CustomFieldValueType,
          name: string,
          createdByID?: string | null,
        } | null,
        value: string,
        id: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    stats?:  {
      __typename: "CustomerStats",
      nbSales?: number | null,
      average?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      index?: number | null,
      totals?:  {
        __typename: "Totals",
        total?: number | null,
        totalTax?: number | null,
        totalTaxFree?: number | null,
      } | null,
      totalDiscount?: number | null,
    } | null,
  } | null,
};

export type OnCreateCustomerAccountMovementSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnCreateCustomerAccountMovementSubscription = {
  onCreateCustomerAccountMovement?:  {
    __typename: "CustomerAccountMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    state: CustomerAccountMovementState,
    type: CustomerAccountMovementType,
    amount: number,
    saleID?: string | null,
    paymentID?: string | null,
    movementInDetails?:  {
      __typename: "CustomerAccountMovementInDetails",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
    } | null,
  } | null,
};

export type OnUpdateCustomerAccountMovementSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnUpdateCustomerAccountMovementSubscription = {
  onUpdateCustomerAccountMovement?:  {
    __typename: "CustomerAccountMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    state: CustomerAccountMovementState,
    type: CustomerAccountMovementType,
    amount: number,
    saleID?: string | null,
    paymentID?: string | null,
    movementInDetails?:  {
      __typename: "CustomerAccountMovementInDetails",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
    } | null,
  } | null,
};

export type OnChangeCustomerAccountMovementSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnChangeCustomerAccountMovementSubscription = {
  onChangeCustomerAccountMovement?:  {
    __typename: "CustomerAccountMovement",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    state: CustomerAccountMovementState,
    type: CustomerAccountMovementType,
    amount: number,
    saleID?: string | null,
    paymentID?: string | null,
    movementInDetails?:  {
      __typename: "CustomerAccountMovementInDetails",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
    } | null,
  } | null,
};

export type OnCreateCustomerCustomFieldSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnCreateCustomerCustomFieldSubscription = {
  onCreateCustomerCustomField?:  {
    __typename: "CustomerCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnUpdateCustomerCustomFieldSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnUpdateCustomerCustomFieldSubscription = {
  onUpdateCustomerCustomField?:  {
    __typename: "CustomerCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnDeleteCustomerCustomFieldSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnDeleteCustomerCustomFieldSubscription = {
  onDeleteCustomerCustomField?:  {
    __typename: "CustomerCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnChangeCustomerCustomFieldSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnChangeCustomerCustomFieldSubscription = {
  onChangeCustomerCustomField?:  {
    __typename: "CustomerCustomField",
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    customerID: string,
    customFieldID: string,
    customFieldKey: string,
    customField?:  {
      __typename: "CustomField",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      catalogID: string,
      test?: boolean | null,
      key: string,
      objectType: CustomFieldObjectType,
      valueType: CustomFieldValueType,
      name: string,
      createdByID?: string | null,
    } | null,
    value: string,
    id: string,
  } | null,
};

export type OnCreateSaleSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnCreateSaleSubscription = {
  onCreateSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type OnUpdateSaleSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnUpdateSaleSubscription = {
  onUpdateSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type OnDeleteSaleSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnDeleteSaleSubscription = {
  onDeleteSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type OnChangeSaleSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnChangeSaleSubscription = {
  onChangeSale?:  {
    __typename: "Sale",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    name?: string | null,
    number?: number | null,
    state: SaleType,
    refundStatus: SaleStatus,
    refundedStatus: SaleStatus,
    paymentStatus: SaleStatus,
    note?: string | null,
    targetID?: string | null,
    targetSale?:  {
      __typename: "Sale",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      cashbookID?: string | null,
      cashbook?:  {
        __typename: "Cashbook",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        number: number,
        state: CashbookState,
        total?: number | null,
        totalTaxFree?: number | null,
        openedAt: string,
        closedAt?: string | null,
        openingSellerID?: string | null,
        closingSellerID?: string | null,
        openingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        closingSeller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        note?: string | null,
        movements?:  Array< {
          __typename: "CashbookMovement",
          type: CashbookMovementType,
          amount: number,
          motive?: string | null,
          createdAt: string,
        } | null > | null,
        openingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        closingCashFund?:  Array< {
          __typename: "CashbookPaymentMethodCashFund",
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          total: number,
        } | null > | null,
        totalExpected?: number | null,
        totalDifference?: number | null,
        customFields?:  Array< {
          __typename: "CashbookCustomField",
          customFieldID: string,
          name: string,
          valueType: CustomFieldValueType,
          value: string,
        } | null > | null,
        stats?:  {
          __typename: "CashbookStats",
          minSaleNumber?: number | null,
          maxSaleNumber?: number | null,
          numberOfSales?: number | null,
        } | null,
      } | null,
      name?: string | null,
      number?: number | null,
      state: SaleType,
      refundStatus: SaleStatus,
      refundedStatus: SaleStatus,
      paymentStatus: SaleStatus,
      note?: string | null,
      targetID?: string | null,
      targetSale?:  {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null,
      refundSales?:  {
        __typename: "ModelSaleConnection",
        items?:  Array< {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      openedAt: string,
      closedAt?: string | null,
      pendingAt?: string | null,
      customerID?: string | null,
      customer?:  {
        __typename: "Customer",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        phoneCode?: string | null,
        companyName?: string | null,
        color?: ColorPalette | null,
        iconText?: string | null,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        note?: string | null,
        fidelityCardNumber?: string | null,
        account?:  {
          __typename: "CustomerAccount",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          balance: number,
        } | null,
        customFields?:  {
          __typename: "ModelCustomerCustomFieldConnection",
          nextToken?: string | null,
        } | null,
        stats?:  {
          __typename: "CustomerStats",
          nbSales?: number | null,
          index?: number | null,
          totalDiscount?: number | null,
        } | null,
      } | null,
      sellerID?: string | null,
      seller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      lines?:  Array< {
        __typename: "SaleLine",
        details: ( {
            __typename: "SaleLinePackDetails",
            packID: string,
            name: string,
          } | {
            __typename: "SaleLineProductDetails",
            productID: string,
            name: string,
            bulkType?: BulkType | null,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          }
        ),
        quantity: number,
        price: number,
        refundedQuantity?: number | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        note?: string | null,
        operationType: SaleLineOperationType,
        state: SaleLineState,
        targetSaleLineIndex?: number | null,
        motive?: string | null,
        total: number,
        totalSellPrice: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalGlobalDiscountFree?: number | null,
        totalTaxFreeGlobalDiscountFree?: number | null,
        totalTaxGlobalDiscountFree?: number | null,
        unitPriceGlobalDiscountFree?: number | null,
        totalDiscountFree?: number | null,
        totalTaxFreeDiscountFree?: number | null,
        totalTaxDiscountFree?: number | null,
        unitPriceDiscountFree?: number | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      payments?:  Array< {
        __typename: "SalePayment",
        state: PaymentState,
        type: PaymentType,
        date: string,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        paymentID: string,
        payment?:  {
          __typename: "Payment",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          saleID: string,
          number?: number | null,
          date: string,
          state: PaymentState,
          type: PaymentType,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null > | null,
      total: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalRest: number,
      stats?:  {
        __typename: "SaleStats",
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        margin?: number | null,
      } | null,
    } | null,
    refundSales?:  {
      __typename: "ModelSaleConnection",
      items?:  Array< {
        __typename: "Sale",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        name?: string | null,
        number?: number | null,
        state: SaleType,
        refundStatus: SaleStatus,
        refundedStatus: SaleStatus,
        paymentStatus: SaleStatus,
        note?: string | null,
        targetID?: string | null,
        targetSale?:  {
          __typename: "Sale",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          cashbookID?: string | null,
          name?: string | null,
          number?: number | null,
          state: SaleType,
          refundStatus: SaleStatus,
          refundedStatus: SaleStatus,
          paymentStatus: SaleStatus,
          note?: string | null,
          targetID?: string | null,
          openedAt: string,
          closedAt?: string | null,
          pendingAt?: string | null,
          customerID?: string | null,
          sellerID?: string | null,
          total: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalRest: number,
        } | null,
        refundSales?:  {
          __typename: "ModelSaleConnection",
          nextToken?: string | null,
        } | null,
        openedAt: string,
        closedAt?: string | null,
        pendingAt?: string | null,
        customerID?: string | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        sellerID?: string | null,
        seller?:  {
          __typename: "Seller",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          color: ColorPalette,
          iconText?: string | null,
          photo?: string | null,
          email: string,
        } | null,
        lines?:  Array< {
          __typename: "SaleLine",
          quantity: number,
          price: number,
          refundedQuantity?: number | null,
          note?: string | null,
          operationType: SaleLineOperationType,
          state: SaleLineState,
          targetSaleLineIndex?: number | null,
          motive?: string | null,
          total: number,
          totalSellPrice: number,
          totalDiscount: number,
          totalTaxFree: number,
          totalTax: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
        } | null > | null,
        discounts?:  Array< {
          __typename: "SaleDiscount",
          rate: number,
          type: DiscountType,
          discountID: string,
          discountName: string,
          amount: number,
        } | null > | null,
        payments?:  Array< {
          __typename: "SalePayment",
          state: PaymentState,
          type: PaymentType,
          date: string,
          paymentMethodID: string,
          paymentMethodName: string,
          paymentMethodType: PaymentMethodType,
          sourcePaymentID?: string | null,
          customerMovementID?: string | null,
          cashbookID?: string | null,
          amount: number,
          totalTaxFree?: number | null,
          totalTax?: number | null,
          paymentID: string,
          integrationPaymentID?: string | null,
          integrationRefundID?: string | null,
        } | null > | null,
        total: number,
        totalDiscount: number,
        totalTaxFree: number,
        totalTax: number,
        totalRest: number,
        stats?:  {
          __typename: "SaleStats",
          margin?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    openedAt: string,
    closedAt?: string | null,
    pendingAt?: string | null,
    customerID?: string | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    sellerID?: string | null,
    seller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    lines?:  Array< {
      __typename: "SaleLine",
      details: ( {
          __typename: "SaleLinePackDetails",
          packID: string,
          name: string,
          packVariant:  Array< {
            __typename: string,
            quantity: number,
            additionalPrice: number,
            basePrice: number,
            total: number,
            totalTaxFree: number,
            totalTax: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null >,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
        } | {
          __typename: "SaleLineProductDetails",
          productID: string,
          name: string,
          bulkType?: BulkType | null,
          variant?:  {
            __typename: string,
            variantID: string,
          } | null,
          category:  {
            __typename: string,
            categoryID: string,
            name: string,
          },
          tax:  {
            __typename: string,
            taxID: string,
            rate: number,
            inclusive: boolean,
            total: number,
            totalTax: number,
            totalTaxFree: number,
          },
          tags?:  Array< {
            __typename: string,
            tagID: string,
            name: string,
          } | null > | null,
          customFields?:  Array< {
            __typename: string,
            customFieldID: string,
            name: string,
            value: string,
          } | null > | null,
          options?:  Array< {
            __typename: string,
            optionID: string,
            name: string,
            total: number,
            totalTax: number,
            totalTaxFree: number,
            totalGlobalDiscountFree?: number | null,
            totalTaxFreeGlobalDiscountFree?: number | null,
            totalTaxGlobalDiscountFree?: number | null,
            unitPriceGlobalDiscountFree?: number | null,
            totalDiscountFree?: number | null,
            totalTaxFreeDiscountFree?: number | null,
            totalTaxDiscountFree?: number | null,
            unitPriceDiscountFree?: number | null,
          } | null > | null,
          total: number,
          totalTax: number,
          totalTaxFree: number,
          totalGlobalDiscountFree?: number | null,
          totalTaxFreeGlobalDiscountFree?: number | null,
          totalTaxGlobalDiscountFree?: number | null,
          unitPriceGlobalDiscountFree?: number | null,
          totalDiscountFree?: number | null,
          totalTaxFreeDiscountFree?: number | null,
          totalTaxDiscountFree?: number | null,
          unitPriceDiscountFree?: number | null,
          virtualDiscounts?:  Array< {
            __typename: string,
            rate: number,
            type: DiscountType,
            discountID: string,
            discountName: string,
            amount: number,
          } | null > | null,
        }
      ),
      quantity: number,
      price: number,
      refundedQuantity?: number | null,
      discounts?:  Array< {
        __typename: "SaleDiscount",
        rate: number,
        type: DiscountType,
        discountID: string,
        discountName: string,
        amount: number,
      } | null > | null,
      note?: string | null,
      operationType: SaleLineOperationType,
      state: SaleLineState,
      targetSaleLineIndex?: number | null,
      motive?: string | null,
      total: number,
      totalSellPrice: number,
      totalDiscount: number,
      totalTaxFree: number,
      totalTax: number,
      totalGlobalDiscountFree?: number | null,
      totalTaxFreeGlobalDiscountFree?: number | null,
      totalTaxGlobalDiscountFree?: number | null,
      unitPriceGlobalDiscountFree?: number | null,
      totalDiscountFree?: number | null,
      totalTaxFreeDiscountFree?: number | null,
      totalTaxDiscountFree?: number | null,
      unitPriceDiscountFree?: number | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "SaleDiscount",
      rate: number,
      type: DiscountType,
      discountID: string,
      discountName: string,
      amount: number,
    } | null > | null,
    payments?:  Array< {
      __typename: "SalePayment",
      state: PaymentState,
      type: PaymentType,
      date: string,
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      sourcePaymentID?: string | null,
      customerMovementID?: string | null,
      cashbookID?: string | null,
      amount: number,
      totalTaxFree?: number | null,
      totalTax?: number | null,
      taxes?:  Array< {
        __typename: "SaleTax",
        taxID: string,
        rate: number,
        inclusive: boolean,
        total: number,
        totalTax: number,
        totalTaxFree: number,
      } | null > | null,
      paymentID: string,
      payment?:  {
        __typename: "Payment",
        id: string,
        originalID?: string | null,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        shopID: string,
        test?: boolean | null,
        sourceID: string,
        sourceName: string,
        saleID: string,
        number?: number | null,
        date: string,
        state: PaymentState,
        type: PaymentType,
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        sourcePaymentID?: string | null,
        customerMovementID?: string | null,
        cashbookID?: string | null,
        cashbook?:  {
          __typename: "Cashbook",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          shopID: string,
          test?: boolean | null,
          sourceID: string,
          sourceName: string,
          number: number,
          state: CashbookState,
          total?: number | null,
          totalTaxFree?: number | null,
          openedAt: string,
          closedAt?: string | null,
          openingSellerID?: string | null,
          closingSellerID?: string | null,
          note?: string | null,
          totalExpected?: number | null,
          totalDifference?: number | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          id: string,
          originalID?: string | null,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phone?: string | null,
          phoneCode?: string | null,
          companyName?: string | null,
          color?: ColorPalette | null,
          iconText?: string | null,
          note?: string | null,
          fidelityCardNumber?: string | null,
        } | null,
        amount: number,
        totalTaxFree?: number | null,
        totalTax?: number | null,
        taxes?:  Array< {
          __typename: "SaleTax",
          taxID: string,
          rate: number,
          inclusive: boolean,
          total: number,
          totalTax: number,
          totalTaxFree: number,
        } | null > | null,
        integrationPaymentID?: string | null,
        integrationRefundID?: string | null,
      } | null,
      integrationPaymentID?: string | null,
      integrationRefundID?: string | null,
    } | null > | null,
    total: number,
    totalDiscount: number,
    totalTaxFree: number,
    totalTax: number,
    totalRest: number,
    stats?:  {
      __typename: "SaleStats",
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      margin?: number | null,
    } | null,
  } | null,
};

export type OnCreateCashbookSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnCreateCashbookSubscription = {
  onCreateCashbook?:  {
    __typename: "Cashbook",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    number: number,
    state: CashbookState,
    total?: number | null,
    totalTaxFree?: number | null,
    openedAt: string,
    closedAt?: string | null,
    openingSellerID?: string | null,
    closingSellerID?: string | null,
    openingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    closingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    note?: string | null,
    movements?:  Array< {
      __typename: "CashbookMovement",
      type: CashbookMovementType,
      amount: number,
      motive?: string | null,
      createdAt: string,
    } | null > | null,
    openingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    closingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    totalExpected?: number | null,
    totalDifference?: number | null,
    customFields?:  Array< {
      __typename: "CashbookCustomField",
      customFieldID: string,
      name: string,
      valueType: CustomFieldValueType,
      value: string,
    } | null > | null,
    stats?:  {
      __typename: "CashbookStats",
      minSaleNumber?: number | null,
      maxSaleNumber?: number | null,
      numberOfSales?: number | null,
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      payments?:  Array< {
        __typename: "CashbookPaymentStats",
        id?: string | null,
        detail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        sellDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        refundDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnUpdateCashbookSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnUpdateCashbookSubscription = {
  onUpdateCashbook?:  {
    __typename: "Cashbook",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    number: number,
    state: CashbookState,
    total?: number | null,
    totalTaxFree?: number | null,
    openedAt: string,
    closedAt?: string | null,
    openingSellerID?: string | null,
    closingSellerID?: string | null,
    openingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    closingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    note?: string | null,
    movements?:  Array< {
      __typename: "CashbookMovement",
      type: CashbookMovementType,
      amount: number,
      motive?: string | null,
      createdAt: string,
    } | null > | null,
    openingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    closingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    totalExpected?: number | null,
    totalDifference?: number | null,
    customFields?:  Array< {
      __typename: "CashbookCustomField",
      customFieldID: string,
      name: string,
      valueType: CustomFieldValueType,
      value: string,
    } | null > | null,
    stats?:  {
      __typename: "CashbookStats",
      minSaleNumber?: number | null,
      maxSaleNumber?: number | null,
      numberOfSales?: number | null,
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      payments?:  Array< {
        __typename: "CashbookPaymentStats",
        id?: string | null,
        detail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        sellDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        refundDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnDeleteCashbookSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnDeleteCashbookSubscription = {
  onDeleteCashbook?:  {
    __typename: "Cashbook",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    number: number,
    state: CashbookState,
    total?: number | null,
    totalTaxFree?: number | null,
    openedAt: string,
    closedAt?: string | null,
    openingSellerID?: string | null,
    closingSellerID?: string | null,
    openingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    closingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    note?: string | null,
    movements?:  Array< {
      __typename: "CashbookMovement",
      type: CashbookMovementType,
      amount: number,
      motive?: string | null,
      createdAt: string,
    } | null > | null,
    openingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    closingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    totalExpected?: number | null,
    totalDifference?: number | null,
    customFields?:  Array< {
      __typename: "CashbookCustomField",
      customFieldID: string,
      name: string,
      valueType: CustomFieldValueType,
      value: string,
    } | null > | null,
    stats?:  {
      __typename: "CashbookStats",
      minSaleNumber?: number | null,
      maxSaleNumber?: number | null,
      numberOfSales?: number | null,
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      payments?:  Array< {
        __typename: "CashbookPaymentStats",
        id?: string | null,
        detail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        sellDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        refundDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnChangeCashbookSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnChangeCashbookSubscription = {
  onChangeCashbook?:  {
    __typename: "Cashbook",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    number: number,
    state: CashbookState,
    total?: number | null,
    totalTaxFree?: number | null,
    openedAt: string,
    closedAt?: string | null,
    openingSellerID?: string | null,
    closingSellerID?: string | null,
    openingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    closingSeller?:  {
      __typename: "Seller",
      id: string,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      color: ColorPalette,
      iconText?: string | null,
      photo?: string | null,
      email: string,
    } | null,
    note?: string | null,
    movements?:  Array< {
      __typename: "CashbookMovement",
      type: CashbookMovementType,
      amount: number,
      motive?: string | null,
      createdAt: string,
    } | null > | null,
    openingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    closingCashFund?:  Array< {
      __typename: "CashbookPaymentMethodCashFund",
      paymentMethodID: string,
      paymentMethodName: string,
      paymentMethodType: PaymentMethodType,
      total: number,
    } | null > | null,
    totalExpected?: number | null,
    totalDifference?: number | null,
    customFields?:  Array< {
      __typename: "CashbookCustomField",
      customFieldID: string,
      name: string,
      valueType: CustomFieldValueType,
      value: string,
    } | null > | null,
    stats?:  {
      __typename: "CashbookStats",
      minSaleNumber?: number | null,
      maxSaleNumber?: number | null,
      numberOfSales?: number | null,
      totalsDetail?:  {
        __typename: "TotalsDetail",
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        sellTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        refundTotals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null,
      taxes?:  Array< {
        __typename: "GroupedTotals",
        id: string,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
      } | null > | null,
      payments?:  Array< {
        __typename: "CashbookPaymentStats",
        id?: string | null,
        detail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        sellDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
        refundDetail?:  {
          __typename: "CashbookPaymentStatsDetail",
          amount?: number | null,
          quantity?: number | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OnCreatePaymentSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnCreatePaymentSubscription = {
  onCreatePayment?:  {
    __typename: "Payment",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    saleID: string,
    number?: number | null,
    date: string,
    state: PaymentState,
    type: PaymentType,
    paymentMethodID: string,
    paymentMethodName: string,
    paymentMethodType: PaymentMethodType,
    sourcePaymentID?: string | null,
    customerMovementID?: string | null,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    amount: number,
    totalTaxFree?: number | null,
    totalTax?: number | null,
    taxes?:  Array< {
      __typename: "SaleTax",
      taxID: string,
      rate: number,
      inclusive: boolean,
      total: number,
      totalTax: number,
      totalTaxFree: number,
    } | null > | null,
    integrationPaymentID?: string | null,
    integrationRefundID?: string | null,
  } | null,
};

export type OnUpdatePaymentSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnUpdatePaymentSubscription = {
  onUpdatePayment?:  {
    __typename: "Payment",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    saleID: string,
    number?: number | null,
    date: string,
    state: PaymentState,
    type: PaymentType,
    paymentMethodID: string,
    paymentMethodName: string,
    paymentMethodType: PaymentMethodType,
    sourcePaymentID?: string | null,
    customerMovementID?: string | null,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    amount: number,
    totalTaxFree?: number | null,
    totalTax?: number | null,
    taxes?:  Array< {
      __typename: "SaleTax",
      taxID: string,
      rate: number,
      inclusive: boolean,
      total: number,
      totalTax: number,
      totalTaxFree: number,
    } | null > | null,
    integrationPaymentID?: string | null,
    integrationRefundID?: string | null,
  } | null,
};

export type OnDeletePaymentSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnDeletePaymentSubscription = {
  onDeletePayment?:  {
    __typename: "Payment",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    saleID: string,
    number?: number | null,
    date: string,
    state: PaymentState,
    type: PaymentType,
    paymentMethodID: string,
    paymentMethodName: string,
    paymentMethodType: PaymentMethodType,
    sourcePaymentID?: string | null,
    customerMovementID?: string | null,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    amount: number,
    totalTaxFree?: number | null,
    totalTax?: number | null,
    taxes?:  Array< {
      __typename: "SaleTax",
      taxID: string,
      rate: number,
      inclusive: boolean,
      total: number,
      totalTax: number,
      totalTaxFree: number,
    } | null > | null,
    integrationPaymentID?: string | null,
    integrationRefundID?: string | null,
  } | null,
};

export type OnChangePaymentSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnChangePaymentSubscription = {
  onChangePayment?:  {
    __typename: "Payment",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID: string,
    test?: boolean | null,
    sourceID: string,
    sourceName: string,
    saleID: string,
    number?: number | null,
    date: string,
    state: PaymentState,
    type: PaymentType,
    paymentMethodID: string,
    paymentMethodName: string,
    paymentMethodType: PaymentMethodType,
    sourcePaymentID?: string | null,
    customerMovementID?: string | null,
    cashbookID?: string | null,
    cashbook?:  {
      __typename: "Cashbook",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      shopID: string,
      test?: boolean | null,
      sourceID: string,
      sourceName: string,
      number: number,
      state: CashbookState,
      total?: number | null,
      totalTaxFree?: number | null,
      openedAt: string,
      closedAt?: string | null,
      openingSellerID?: string | null,
      closingSellerID?: string | null,
      openingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      closingSeller?:  {
        __typename: "Seller",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        color: ColorPalette,
        iconText?: string | null,
        photo?: string | null,
        email: string,
      } | null,
      note?: string | null,
      movements?:  Array< {
        __typename: "CashbookMovement",
        type: CashbookMovementType,
        amount: number,
        motive?: string | null,
        createdAt: string,
      } | null > | null,
      openingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      closingCashFund?:  Array< {
        __typename: "CashbookPaymentMethodCashFund",
        paymentMethodID: string,
        paymentMethodName: string,
        paymentMethodType: PaymentMethodType,
        total: number,
      } | null > | null,
      totalExpected?: number | null,
      totalDifference?: number | null,
      customFields?:  Array< {
        __typename: "CashbookCustomField",
        customFieldID: string,
        name: string,
        valueType: CustomFieldValueType,
        value: string,
      } | null > | null,
      stats?:  {
        __typename: "CashbookStats",
        minSaleNumber?: number | null,
        maxSaleNumber?: number | null,
        numberOfSales?: number | null,
        taxes?:  Array< {
          __typename: "GroupedTotals",
          id: string,
        } | null > | null,
        payments?:  Array< {
          __typename: "CashbookPaymentStats",
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    customer?:  {
      __typename: "Customer",
      id: string,
      originalID?: string | null,
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      companyID: string,
      test?: boolean | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      phoneCode?: string | null,
      companyName?: string | null,
      color?: ColorPalette | null,
      iconText?: string | null,
      address?:  {
        __typename: "Address",
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        country?: string | null,
      } | null,
      note?: string | null,
      fidelityCardNumber?: string | null,
      account?:  {
        __typename: "CustomerAccount",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        test?: boolean | null,
        balance: number,
        movements?:  {
          __typename: "ModelCustomerAccountMovementConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      customFields?:  {
        __typename: "ModelCustomerCustomFieldConnection",
        items?:  Array< {
          __typename: "CustomerCustomField",
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          companyID: string,
          test?: boolean | null,
          customerID: string,
          customFieldID: string,
          customFieldKey: string,
          value: string,
          id: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      stats?:  {
        __typename: "CustomerStats",
        nbSales?: number | null,
        average?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        index?: number | null,
        totals?:  {
          __typename: "Totals",
          total?: number | null,
          totalTax?: number | null,
          totalTaxFree?: number | null,
        } | null,
        totalDiscount?: number | null,
      } | null,
    } | null,
    amount: number,
    totalTaxFree?: number | null,
    totalTax?: number | null,
    taxes?:  Array< {
      __typename: "SaleTax",
      taxID: string,
      rate: number,
      inclusive: boolean,
      total: number,
      totalTax: number,
      totalTaxFree: number,
    } | null > | null,
    integrationPaymentID?: string | null,
    integrationRefundID?: string | null,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  companyID: string,
  objectID?: string | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    originalID?: string | null,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    shopID?: string | null,
    slotID?: string | null,
    userID?: string | null,
    test?: boolean | null,
    version: string,
    date: string,
    type: EventType,
    code: number,
    objectType: EventObjectType,
    objectID: string,
    action: string,
    actionData?: string | null,
    hash?: string | null,
  } | null,
};

export type OnCreateBackgroundJobSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnCreateBackgroundJobSubscription = {
  onCreateBackgroundJob?:  {
    __typename: "BackgroundJob",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    shopID?: string | null,
    sourceEmail?: string | null,
    type: BackgroundJobType,
    state: BackgroundJobState,
    details: ( {
        __typename: "CatalogMigrationDetails",
        PRODUCT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PRODUCT_VARIANT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CATEGORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        TAX?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        DISCOUNT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "CustomerMigrationDetails",
        CUSTOMER?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "InventoryMigrationDetails",
        INVENTORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ActivityMigrationDetails",
        PAYMENT_METHOD?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SLOT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CASHBOOK?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SALE?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PAYMENT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ImportDetails",
        IMPORT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      }
    ) | null,
    message?: string | null,
    reportUrl?: string | null,
    flatfileBatchId?: string | null,
    flatfileSheetId?: string | null,
  } | null,
};

export type OnUpdateBackgroundJobSubscriptionVariables = {
  companyID: string,
  test: boolean,
};

export type OnUpdateBackgroundJobSubscription = {
  onUpdateBackgroundJob?:  {
    __typename: "BackgroundJob",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    test?: boolean | null,
    shopID?: string | null,
    sourceEmail?: string | null,
    type: BackgroundJobType,
    state: BackgroundJobState,
    details: ( {
        __typename: "CatalogMigrationDetails",
        PRODUCT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PRODUCT_VARIANT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CATEGORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        TAX?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        DISCOUNT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "CustomerMigrationDetails",
        CUSTOMER?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "InventoryMigrationDetails",
        INVENTORY?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ActivityMigrationDetails",
        PAYMENT_METHOD?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SLOT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        CASHBOOK?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        SALE?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
        PAYMENT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      } | {
        __typename: "ImportDetails",
        IMPORT?:  {
          __typename: string,
          total?: number | null,
          successCount?: number | null,
          errorCount?: number | null,
        } | null,
      }
    ) | null,
    message?: string | null,
    reportUrl?: string | null,
    flatfileBatchId?: string | null,
    flatfileSheetId?: string | null,
  } | null,
};

export type OnChangeAccountInvitationSubscriptionVariables = {
  companyID: string,
};

export type OnChangeAccountInvitationSubscription = {
  onChangeAccountInvitation?:  {
    __typename: "AccountInvitation",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    companyID: string,
    companyName: string,
    email: string,
    role: AccountRole,
    shops: Array< string | null >,
    expiresAt: string,
    status: AccountInvitationStatus,
    senderID: string,
    senderName: string,
    senderEmail: string,
  } | null,
};

export type OnChangeUserSubscriptionVariables = {
  companyID: string,
};

export type OnChangeUserSubscription = {
  onChangeUser?:  {
    __typename: "User",
    id: string,
    updatedAt: string,
    createdAt: string,
    deprecated?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    color: ColorPalette,
    iconText?: string | null,
    photo?: string | null,
    email: string,
    company?:  {
      __typename: "AccountCompany",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      company?:  {
        __typename: "Company",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        name?: string | null,
        address?: string | null,
        country?: CountryCode | null,
        currency: CurrencyCode,
        currencyDecimals: number,
        vat?: string | null,
      } | null,
      owner?: boolean | null,
      role?: AccountRole | null,
      read?: boolean | null,
      update?: boolean | null,
      billing?: boolean | null,
      billingExport?: boolean | null,
      shopWrite?: boolean | null,
      deviceSlotWrite?: boolean | null,
      deviceSlotUse?: boolean | null,
      paymentMethodRead?: boolean | null,
      paymentMethodCreate?: boolean | null,
      paymentMethodUpdate?: boolean | null,
      paymentMethodDelete?: boolean | null,
      customerRead?: boolean | null,
      customerCreate?: boolean | null,
      customerUpdate?: boolean | null,
      customerDelete?: boolean | null,
      customerExport?: boolean | null,
      inventoryRead?: boolean | null,
      cashbookRead?: boolean | null,
      saleRead?: boolean | null,
      paymentRead?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      sell?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    catalog?:  {
      __typename: "AccountCatalog",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      catalogID: string,
      catalog?:  {
        __typename: "Catalog",
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        id: string,
      } | null,
      read?: boolean | null,
      create?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null,
    shops?:  Array< {
      __typename: "AccountShop",
      updatedAt: string,
      createdAt: string,
      deprecated?: boolean | null,
      accountID: string,
      companyID: string,
      shopID: string,
      shop?:  {
        __typename: "Shop",
        id: string,
        updatedAt: string,
        createdAt: string,
        deprecated?: boolean | null,
        companyID: string,
        company?:  {
          __typename: "Company",
          id: string,
          updatedAt: string,
          createdAt: string,
          deprecated?: boolean | null,
          name?: string | null,
          address?: string | null,
          country?: CountryCode | null,
          currency: CurrencyCode,
          currencyDecimals: number,
          vat?: string | null,
        } | null,
        name: string,
        address?:  {
          __typename: "Address",
          address?: string | null,
          zipcode?: string | null,
          city?: string | null,
          country?: string | null,
        } | null,
        siret?: string | null,
        vat?: string | null,
        phone?: string | null,
        footnote?: string | null,
        logo?: string | null,
        websiteURL?: string | null,
        brandName: string,
        email?: string | null,
      } | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      inventoryRead?: boolean | null,
      inventoryWrite?: boolean | null,
      cashbookRead?: boolean | null,
      cashbookWrite?: boolean | null,
      saleRead?: boolean | null,
      saleWrite?: boolean | null,
      paymentRead?: boolean | null,
      paymentWrite?: boolean | null,
      userInvite?: boolean | null,
      userRead?: boolean | null,
      userUpdate?: boolean | null,
      userRemove?: boolean | null,
      activitiesExport?: boolean | null,
      tillCashbooksRead?: boolean | null,
      tillSalesSearch?: boolean | null,
      tillSaleSell?: boolean | null,
      tillSaleRefund?: boolean | null,
      tillSaleReturn?: boolean | null,
      tillCashbookOpen?: boolean | null,
      tillCashbookClose?: boolean | null,
      eventCreate?: boolean | null,
      sync?: boolean | null,
      id: string,
    } | null > | null,
  } | null,
};
