import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  ComponentDirection,
  ComponentErrorText,
  ComponentSize,
  ComponentStatus,
  ComponentType,
} from '../../../../components/newComponents/types'
import { displayCurrency } from '../../../../utils/number'
import Input from '../../../../components/newComponents/input'
import Divider from '../../../../components/newComponents/divider'
import ColorPicker from '../../../../components/newComponents/colorPicker'
import CustomField from '../../../../components/oldComponents/catalog/customField'
import DraggableList from '../../../../components/oldComponents/draggableList/draggableList'
import { selectedCompany } from '../../../../store/selector'
import { TypePack } from '../../../../types'
import SubmitButton from '../../../../components/oldComponents/drawer/submitButton'
import generateDataCy from '../../../../utils/cypress'
import { enumToStr } from '../../../../utils/color'
import Thumbnail from '../../../../components/newComponents/thumbnail'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'

const Form = ({
  handleChange,
  handleBlur,
  handleBlurNested,
  handleSubmit,
  onDragEnd,
  openPackOptionView,
  state,
  dataCy,
}: {
  handleChange: (type: string, index?: number) => (event: any) => void
  handleBlur: (field: string) => void
  handleBlurNested: (index: number) => void
  handleSubmit: () => void
  onDragEnd: (dragStart: number, dragEnd: number) => void
  openPackOptionView: (_: string | undefined, index: number) => void
  state: TypePack
  dataCy: string
}) => {
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()

  const errorText = () => {
    if (state.errors?.name?.value) {
      return state.errors.name.message
    }

    if (state.errors.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <>
      <div className={styles.overflow}>
        <div className={styles.form}>
          <ColorPicker
            title={t('button.EDIT')}
            Element={
              <Thumbnail
                size={ComponentSize.LARGE}
                color={state.color ? enumToStr(state.color) : state.color}
                iconText={state.iconText}
                image={state.selected === 'color' ? undefined : state.photo}
              />
            }
            iconText={state.iconText}
            color={state.color}
            photo={state.photo}
            resize={600}
            selected={state.selected}
            onSelect={handleChange('color')}
            onChange={handleChange('iconText')}
            onBlur={() => {
              if (state.iconText === '') handleChange('iconText')(state.name?.substring(0, 4))
            }}
            onUpload={handleChange('photo')}
            dataCy={generateDataCy({ scope: dataCy, value: 'color' })}
            isIconText
            isImage
          />
          <Input
            label={t('catalog.pack.form.NAME')}
            value={state.name}
            onChange={handleChange('name')}
            onBlur={() => handleBlur('name')}
            onSubmit={handleSubmit}
            style={{
              status: state.errors.name?.value || state.errors.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={errorText()}
            dataCy={generateDataCy({ scope: dataCy, value: 'name' })}
          />
          <div className={styles.oneLine}>
            <Input
              type={'currency'}
              label={t('catalog.pack.form.SELLPRICE')}
              value={state.sellPrice ? state.sellPrice + (company ? ' ' + displayCurrency(company.currency) : '') : ''}
              onChange={handleChange('sellPrice')}
              onBlur={() => handleBlur('sellPrice')}
              onSubmit={handleSubmit}
              style={{
                status: state.errors.sellPrice?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              }}
              helperText={state.errors?.sellPrice?.value ? state.errors.sellPrice.message : undefined}
              suffix={company ? displayCurrency(company.currency) : undefined}
              dataCy={generateDataCy({ scope: dataCy, value: 'sellPrice' })}
            />
            <Input
              label={t('catalog.pack.form.REFERENCE')}
              value={state.reference}
              onChange={handleChange('reference')}
              onSubmit={handleSubmit}
              dataCy={generateDataCy({ scope: dataCy, value: 'reference' })}
            />
          </div>
          <Input
            label={t('catalog.pack.form.BARCODE')}
            value={state.barcode}
            onChange={handleChange('barcode')}
            onSubmit={handleSubmit}
            dataCy={generateDataCy({ scope: dataCy, value: 'barcode' })}
          />
          <CustomField
            selectedCustomFields={state.customFields}
            onChange={handleChange}
            onBlur={handleBlurNested}
            onSubmit={handleSubmit}
            errors={state.errors?.customFields}
            cyContext={generateDataCy({ scope: dataCy, value: 'customField' })}
          />
        </div>
        <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
        <div className={styles.form}>
          <DraggableList
            label={t('catalog.pack.form.option.PACK')}
            list={state.packVariations}
            onClickIcon={openPackOptionView}
            onRedirect={() => openPackOptionView('', -1)}
            onDragEnd={onDragEnd}
            iconColor={'var(--sys-color-content-interactive)'}
            addText={t('catalog.pack.form.option.ADD_CHOICE')}
            error={state.errors.packVariations}
            pen
            dataCy={generateDataCy({ scope: dataCy, value: 'redirectPackVariation' })}
          />
        </div>
        <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
      </div>
    </>
  )
}

export default Form
