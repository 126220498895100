import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { getToaster } from '../../../store/selector'
import DisplayIcon2 from '../icons'
import { updateToaster } from '../../../actions/modal'

import { Close, Success } from '../../../assets/icons'
import styles from './Toaster.module.css'

const Toaster = (): JSX.Element => {
  const { show, param } = useSelector(getToaster)
  const dispatch = useDispatch()

  const triggerAnimation = (action: string) => (document.getElementById('toaster')!.style.animationPlayState = action)

  console.log(show, "SHOW")

  useEffect(() => {
    if (show) {
      //! setTimeout(() => triggerAnimation('paused'), 2250)
      //! setTimeout(() => triggerAnimation('running'), 5000)
      // setTimeout(() => triggerAnimation('running'), 10)
      // setTimeout(() => triggerAnimation('paused'), 2000)
      // setTimeout(() => triggerAnimation('running'), 2200)
      setTimeout(() => updateToaster(dispatch, false, { LeadIcon: Success, }), 5200)
    }
      }, [show])

  return (
    <div className={show ? styles.container : styles.hidden}>
      <div
        id="toaster"
        className={styles.toaster}
        onMouseEnter={() => triggerAnimation('paused')}
        onMouseLeave={() => triggerAnimation('running')}
      >
        {param.LeadIcon && (
          <DisplayIcon2 Icon={param.LeadIcon} color={`var(--comp-toaster-color-foreground-icon-${param.status})`} />
        )}
        {param.text && <div className={styles.text}>{param.text}</div>}
        {param.clearButton && (
          <DisplayIcon2
            Icon={Close}
            color={`var(--sys-color-content-secondary)`}
            onClick={() => updateToaster(dispatch, false)}
          />
        )}
      </div>
    </div>
  )
}

export default Toaster
