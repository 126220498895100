import { onCreateTag, onDeleteTag, onUpdateTag } from '../../graphql/custom/subscriptions'
import { getCompanyID } from '../../services/localStorage'
import { getTag as get, getTotalProductByTag } from '../../graphql/custom/queries'
import { updateListElement, deleteListElement } from '../../actions/list'
import { objectType } from '../../store/types'
import { getObservable } from '../../services/graphql'
import { deleteTagFromProduct, updateTagsAssociations } from '../../actions/catalog'
import { GetTagQueryVariables } from '../../API'
import { callService } from '../../services'

let onCreateTagSubscription: any
let onDeleteTagSubscription: any
let onUpdateTagSubscription: any

export type GetTotalProductTagsVariables = {
  test: boolean
  catalogID: string
  tag: string
}

const getTag = async (id: string, dispatch: any) => {
  const tag = await callService<GetTagQueryVariables>({ id }, get, 'getTag')

  if (tag.data) {
    updateListElement({ element: tag.data, dispatch, type: objectType.TAG })
  }
  return tag
}

const getTotalProductTag = async (name: string, tag: any) => {
  const input = {
    catalogID: getCompanyID(),
    test: false,
    tag: name,
  }
  const tags = await callService<GetTotalProductTagsVariables>({ ...input }, getTotalProductByTag, 'requestProducts')
  const { total } = tags.data
  return { ...tag, productCount: total }
}

const subscribeTags = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreateTagSubscription)
    onCreateTagSubscription = getObservable(onCreateTag, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateTag)
          updateListElement({ element: eventData.data.onCreateTag, dispatch, type: objectType.TAG })
      },
    })
  if (!onDeleteTagSubscription)
    onDeleteTagSubscription = getObservable(onDeleteTag, input).subscribe({
      next: (eventData: any) => {
        deleteTagFromProduct({ tagID: eventData.data.onDeleteTag.id }, dispatch)
        deleteListElement({
          element: eventData.data.onDeleteTag,
          dispatch,
          type: objectType.TAG,
        })
      },
    })
  if (!onUpdateTagSubscription)
    onUpdateTagSubscription = getObservable(onUpdateTag, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateTag) {
          updateListElement({ element: eventData.data.onUpdateTag, dispatch, type: objectType.TAG })
          updateTagsAssociations({ tagID: eventData.data.onUpdateTag.id, dispatch }, dispatch)
        }
      },
    })
}

const unSubscribeTags = () => {
  if (onCreateTagSubscription) {
    onCreateTagSubscription.unsubscribe()
    onCreateTagSubscription = null
  }

  if (onDeleteTagSubscription) {
    onDeleteTagSubscription.unsubscribe()
    onDeleteTagSubscription = null
  }

  if (onUpdateTagSubscription) {
    onUpdateTagSubscription.unsubscribe()
    onUpdateTagSubscription = null
  }
}

export { getTag, subscribeTags, unSubscribeTags, getTotalProductTag }
