import { TypeColumnConfiguration, CustomFieldAssociations } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { DropDownCell } from '../../components/list/cell'
import { objectCustomFieldEnumToStr, valueCustomFieldEnumToStr } from '../../utils/typeToType'
import { callService, getElementAssociations } from '../../services'
import { DeleteCustomFieldInput } from '../../API'
import { deleteCustomField } from '../../graphql/custom/mutations'
import { updateModal } from '../../actions/modal'
import { displayFullName } from '../../utils/string'
import CustomFieldModal from '../../views/setting/customField/modal'
import IconTouch from '../../components/newComponents/icons/iconTouch'
import { MoreHoriz } from '../../assets/icons'

const CUSTOM_FIELD = 'customField'

const customFieldsCols: TypeColumnConfiguration[] = [
  {
    id: 'listCustomFields',
    type: CUSTOM_FIELD,
    cols: [
      {
        id: 'name',
        title: 'NAME',
        type: 'text',
        field: 'name',
        fieldType: 'string',
        active: true,
      },
      {
        id: 'valueType',
        title: 'VALUE_TYPE',
        type: 'function',
        fieldType: 'string',
        active: true,
        function: (value) => valueCustomFieldEnumToStr(value.valueType),
      },
      {
        id: 'objectType',
        title: 'OBJECT_TYPE',
        type: 'function',
        fieldType: 'string',
        active: true,
        function: (value) => objectCustomFieldEnumToStr(value.objectType),
      },
      {
        id: 'createdByID',
        title: 'CREATED_BY',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, users }) => {
          const user = users.items.find((user: { id: string }) => user.id === value.createdByID)
          return user ? displayFullName(user.firstName, user.lastName) : ''
        },
      },
      {
        id: 'createdAt',
        title: 'CREATED_AT',
        type: 'function',
        field: 'createdAt',
        fieldType: 'date',
        active: true,
        function: (date) => displayDateShortMonthWithHours(date),
      },
      {
        id: 'more',
        title: '',
        type: 'element',
        active: true,
        function: ({ value, customFieldsAssociations, t, index, dispatch }) =>
          DropDownCell({
            fieldID: 'more',
            index,
            Element: IconTouch({
              Icon: MoreHoriz,
              color: 'var(--sys-color-content-secondary)',
            }),
            items: [
              {
                id: 'edit',
                value: t('settings.customField.EDIT'),
                onClick: () =>
                  updateModal(
                    dispatch,
                    true,
                    () => CustomFieldModal({ id: value.id }),
                    () => {}
                  ),
              },
              {
                id: 'delete',
                value: t('settings.customField.DELETE'),
                // status: ComponentStatus.DANGER,
                onClick: () => {
                  if (
                    !getElementAssociations<CustomFieldAssociations>(
                      customFieldsAssociations,
                      'customFieldID',
                      value.id
                    )
                  ) {
                    callService<{ input: DeleteCustomFieldInput }>(
                      {
                        input: {
                          id: value.id,
                        },
                      },
                      deleteCustomField,
                      'deleteCustomField'
                    )
                  } else
                    updateModal(
                      dispatch,
                      true,
                      () => CustomFieldModal({ id: value.id, isDelete: true }),
                      () => {}
                    )
                },
              },
            ],
          }),
      },
    ],
    creatable: true,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
    creatablePlaceholder: 'settings.customField.NEW',
  },
]

export default customFieldsCols
