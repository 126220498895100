/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTranslation } from 'react-i18next'
import DrawerTop from '../../../../components/oldComponents/drawer/top'
import { useSelector } from 'react-redux'
import { getDashboardView } from '../../../../store/selector'
import { DashboardView } from '../../../../store/types';
import generateDataCy from '../../../../utils/cypress'
import countries from '../../../../assets/countries.json'
import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'
import SubmitButton from '../../../../components/oldComponents/drawer/submitButton'
import CancelButton from '../../../../components/oldComponents/drawer/cancelButton'
import { closeAndResetModal, updateModal } from '../../../../actions/modal'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { MergeCustomersInput } from '../../../../API'
import { isValidDate, toDateISOString } from '../../../../utils/date'
import { FusionModal } from './modal'
import FusionField from './fusionField'
import { createUpdatedField, gatherAllKeys, getCustomFieldInfo, getUniqueNonNullValuesExcludingNull, getValue, isCustomKey, sortByDate, translateValueForAPI } from '../../../../utils/fusion'

export const MenuFusion = ({
  selected,
  close,
}: {
  selected: Array<any>,
  close: () => void
}) => {

  const { t } = useTranslation();
  const mobileView = useSelector(getDashboardView)
  const dispatch = useDispatch();
  const [fusionObject, setFusionObject] = useState({});
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
  const [toMerge, setToMerge] = useState<string[]>([]);
  const allKeys = gatherAllKeys(selected);
  const cyContext = generateDataCy({scope: "customer", value:generateDataCy({scope: "drawer", value: "fusion"}) })

  const handleRadioChange = (fieldKey: string, value: string) => {
    setFusionObject(prevState => {

      if (isCustomKey(fieldKey)) {
        //@ts-ignore
        const customFields = prevState.customFields || [];
        //@ts-ignore
        const sortedSelected = sortByDate(selected)
        const oldestId = sortedSelected[0]?.id;

        const customInfo = getCustomFieldInfo(fieldKey, selected);

        if (customInfo?.valueType === 'DATE' && isValidDate(value)) {
          value = toDateISOString(value)
        }

        const existingIndex = customFields.findIndex((cf: any) => cf.name === fieldKey);
        if (existingIndex !== -1) {
          customFields[existingIndex].value = value === '(Aucun)' ? null : value;
        } else {
          if (customInfo) {
            const newField = createUpdatedField(fieldKey, customInfo, value, oldestId)
            customFields.push(newField);
          }
        }

        return {
          ...prevState,
          customFields
        };
      }

      return {
        ...prevState,
        [fieldKey]: translateValueForAPI(fieldKey, value)
      };
    });
  };



  useEffect(() => {
    const notNull = (e: any) => e !== null
    const allFieldsSelected =
      Object.values(fusionObject).every(notNull) &&
      //@ts-ignore
      (!fusionObject.customFields || fusionObject.customFields.every(cf => Object.values(cf).every(notNull)))

    setIsFormComplete(allFieldsSelected);
  }, [fusionObject, allKeys]);


  useEffect(() => {

    const initialObject: MergeCustomersInput = { id: "" };

    const sortedSelected = sortByDate(selected);
    const oldestId = sortedSelected[0]?.id;
    // const oldestCreatedAt = sortedSelected[0]?.createdAt;
    if (oldestId) {
      initialObject['id'] = oldestId;
      // initialObject['updatedAt'] = oldestCreatedAt;
    }
    const otherIds = sortedSelected.slice(1).map(item => item.id);
    setToMerge(otherIds);

    allKeys.forEach(fieldKey => {
      const values = sortedSelected.map(item => getValue(item, fieldKey, countries));
      const uniqueNonNullValues = getUniqueNonNullValuesExcludingNull(values);

      if (uniqueNonNullValues.every(e => e === "Aucun")) return

      if (uniqueNonNullValues.length === 1) {
        if (isCustomKey(fieldKey)) {
          const customInfo = getCustomFieldInfo(fieldKey, selected);

          if (customInfo) {
            if (customInfo?.valueType === 'DATE' && isValidDate(uniqueNonNullValues[0])) {
              uniqueNonNullValues[0] = toDateISOString(uniqueNonNullValues[0])
            }
            const updatedField = createUpdatedField(fieldKey, customInfo, uniqueNonNullValues[0], oldestId)

            if (!initialObject.customFields) {
              initialObject.customFields = [];
            }

            const existingIndex = initialObject.customFields.findIndex((cf: any) => cf.customFieldKey === fieldKey);
            if (existingIndex !== -1) {
              initialObject.customFields[existingIndex] = updatedField;
            } else {
              initialObject.customFields.push(updatedField);
            }
          }
        } else {
          //@ts-ignore
          initialObject[fieldKey] = translateValueForAPI(fieldKey, uniqueNonNullValues[0])
        }
      } else if (isCustomKey(fieldKey)) {
        const customInfo = getCustomFieldInfo(fieldKey, selected);
        if (customInfo) {
          const updatedField = createUpdatedField(fieldKey, customInfo, null, oldestId)

          if (!initialObject.customFields) {
            initialObject.customFields = [];
          }

          const existingIndex = initialObject.customFields.findIndex((cf: any) => cf.customFieldKey === fieldKey);
          if (existingIndex !== -1) {
            initialObject.customFields[existingIndex] = updatedField;
          } else {
            initialObject.customFields.push(updatedField);
          }
        }
      } else {
        //@ts-ignore
        initialObject[fieldKey] = null;
      }
    });

    setFusionObject(initialObject);
  }, [selected]);


  const closeModal = () => {
    setIsFormComplete(true)
    closeAndResetModal(dispatch)
  }

  const handleSubmit = () => {
    const input = {
      ...fusionObject,
      toMerge,
    }

    setIsFormComplete(false)

    const modal = () => <FusionModal input={input} number={selected.length} close={closeModal} />
    updateModal(dispatch, true, modal, () => { }, "customer")
  }

  return (
    <>
      <DrawerTop
        name={t('customer.detail.form.FUSION')}
        mobileView={mobileView === DashboardView.MOBILE}
        close={close}
        dataCy={cyContext}
        icon="close"
      />
      <div className={styles.fusionHeader}>
        <div className={styles.fusionContentWrapper} >
          <div className={styles.iconContainer}>
            <div className={styles.iconWrapper}>
              {selected.slice(0, 4).map((icon, index) => (
                <div
                data-cy={generateDataCy({scope: cyContext, value: "icon-" + index.toString()})}
                  key={icon.id}
                  className={styles.icon}
                  style={{
                    backgroundColor: icon.color,
                    right: `calc(25% + ${(index + 1) * 20}px - ${selected.length > 3 ? 20 : 0}px)`,
                    zIndex: 3 - index
                  }}
                >
                  {icon.iconText}
                </div>
              ))}

              {selected.length > 3 && (
                <div
                data-cy={generateDataCy({scope: cyContext, value: "iconMore"})}
                  className={styles.icon}
                  style={{
                    backgroundColor: 'var(--sys-color-background-accent)',
                    right: `calc(25%)`,
                    zIndex: 4
                  }}
                >
                  +{selected.length - 3}
                </div>
              )}
            </div>
          </div>
          <div data-cy={generateDataCy({scope: cyContext, value: isFormComplete ? "sameCustomerText" : "differentCustomerText" })} className={styles.descriptionFusion}>{isFormComplete ? t('customer.detail.form.FUSION_SUTITLE_SAME') : t('customer.detail.form.FUSION_SUTITLE_MULTIPLE')}</div>
          <div>
            {allKeys.map((key) => (
              <FusionField key={key} fieldKey={key} selected={selected} handleRadioChange={handleRadioChange} dataCy={cyContext}/>
            ))}
          </div>
        </div>
        <div className={styles.buttonFusionContainer}>
          <CancelButton cancel={close} />
          <SubmitButton text="Fusionner" showSubmit={isFormComplete} handleSubmit={handleSubmit} width />
        </div>
      </div>
    </>
  )
}