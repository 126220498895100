/* eslint-disable no-prototype-builtins */

import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { DataWithErrors } from '../../../../services/types'
import { TypeFormError } from '../../../../format/errors/types'
import { APIErrorMessageToHumanMessage } from '../../../../assets/errorCodes/helper'
import {
  CreateProductCustomFieldInput,
  DeleteProductCustomFieldInput,
  GetProductQueryVariables,
  InventoryMovementDestination,
  InventoryMovementOrigin,
  InventoryMovementState,
  UpdateProductCustomFieldInput,
  UpdateProductInput,
  UpdateProductVariantInput,
} from '../../../../API'
import { callService } from '../../../../services'
import {
  createProductCustomField,
  deleteProductCustomField,
  updateProduct,
  updateProductCustomField,
  updateProductVariant,
} from '../../../../graphql/custom/mutations'
import { getCompanyID } from '../../../../services/localStorage'
import { displayDateNumeric } from '../../../../utils/date'
import { createInventoryMovement } from '../../../../getters/catalog/inventory'
import formatWithSubvalues from '../../../../format/product'
import { getProduct } from '../../../../graphql/custom/queries'
import { closeAndResetModal } from '../../../../actions/modal'
import { clearAllFilters } from '../../../../actions/requester'
import { unSelectAllList, updateList } from '../../../../actions/list'
import toggleMenu from '../../../../actions/menu'
import { List, MenuState, objectType, SubValues } from '../../../../store/types';
import { changeConf } from '../../../../actions/cols'
import Button from '../../button'
import { convertPrice } from '../../../../utils/number'
import { ComponentSize, ComponentState, ComponentType } from '../../types'
import { pluralize } from '../../../../utils/typeToType'

import styles from '../Modal.module.css'
import { useSelector } from 'react-redux'
import { listProducts } from '../../../../store/selector'
import generateDataCy from '../../../../utils/cypress'

type ResLine = {
  product: DataWithErrors
  cf: Array<DataWithErrors>
  inventory: Array<DataWithErrors>
}

const buildErrorMessage = (data: DataWithErrors): TypeFormError | void => {
  if (data.errors) {
    const error = data.errors
    return {
      value: true,
      message: APIErrorMessageToHumanMessage(error.errorType),
    }
  }
}

const buildProductError = (val: {
  id: string
  res: ResLine
}): {
  id: string
  errors: Array<{
    col: string
    error: TypeFormError | void
  } | null>
} => {
  const { product, cf } = val.res

  const productError = buildErrorMessage(product)

  const productErrorMessage = productError
    ? {
      col: product.errors.errorInfo,
      error: productError,
    }
    : null

  const cfErrors = cf
    .filter((customField) => buildErrorMessage(customField))
    .map((customField) => ({
      col: cf + ('cf' + customField.payload.customFieldID),
      error: buildErrorMessage(customField),
    }))

  return {
    id: val.id,
    errors: [productErrorMessage, ...cfErrors].filter((a) => a),
  }
}

const buildErrors = (res: Array<{ id: string; res: ResLine }>) =>
  res.map(buildProductError).filter((a) => a.errors.length !== 0)

const submitVariant = async ({
  t,
  product,
  subValues,
  dispatch,
  decimals,
}: {
  t: (field: string, option: { date: string }) => string
  product: any
  subValues: any
  dispatch: any
  decimals: number
}) => {
  Object.entries(subValues).map(async ([id, payload]: [string, any]) => {
    const input: UpdateProductVariantInput = {
      productID: product.id,
      variantID: id,
      taxID: payload.hasOwnProperty('taxRate') ? payload.taxRate : product.subValues.find((subValue: { id: string }) => subValue.id === id).taxID,
    };

    input.barcode = payload.hasOwnProperty('barcode') ? payload.barcode : product.subValues.find((subValue: { id: string }) => subValue.id === id).barcode;
    input.reference = payload.hasOwnProperty('reference') ? payload.reference : product.subValues.find((subValue: { id: string }) => subValue.id === id).reference;
    input.buyPrice = payload.hasOwnProperty('buyPrice') ? convertPrice(decimals, payload.buyPrice) : product.subValues.find((subValue: { id: string }) => subValue.id === id).buyPrice;
    input.sellPrice = payload.hasOwnProperty('sellPrice') ? convertPrice(decimals, payload.sellPrice) : product.subValues.find((subValue: { id: string }) => subValue.id === id).sellPrice;

    return callService<{ input: UpdateProductVariantInput }>(
      { input: JSON.parse(JSON.stringify(input)) },
      updateProductVariant,
      'updateProductVariant'
    )
  })
}

const submitLine = async (product: any, payload: any, decimals: number) => {
  const { sellPrice, reference, buyPrice, barcode, taxRate, categoryName, ...customCols } = payload
  const input: UpdateProductInput = {
    id: product.id,
    reference,
    barcode,
    taxID: taxRate,
    categoryID: categoryName,
  }
  if (buyPrice !== undefined) input.buyPrice = convertPrice(decimals, buyPrice)
  if (sellPrice !== undefined) input.sellPrice = convertPrice(decimals, sellPrice)

  const customFields = Object.entries(customCols)
    .filter(([key, _value]) => key.startsWith('cf'))
    .map(([key, value]: [string, any]) => ({
      value,
      customFieldID: key.slice(2),
      productID: product.id,
    }))

  const cfRes = Promise.all(
    customFields.map((input: CreateProductCustomFieldInput) => {
      const item = product.customFields.items.find(
        (cf: { customFieldID: string }) => cf.customFieldID === input.customFieldID
      )

      return item
        ? input.value !== ''
          ? callService<{ input: UpdateProductCustomFieldInput }>(
            { input },
            updateProductCustomField,
            'updateProductCustomField'
          )
          : callService<{ input: DeleteProductCustomFieldInput }>(
            { input: { productID: product.id, customFieldID: item.customFieldID } },
            deleteProductCustomField,
            'deleteProductCustomField'
          )
        : callService<{ input: CreateProductCustomFieldInput }>(
          { input },
          createProductCustomField,
          'createProductCustomField'
        )
    })
  )

  const updateRes = callService<{ input: UpdateProductInput }>({ input }, updateProduct, 'updateProduct')
  return Promise.all([updateRes, cfRes])
}

const getInventoryData = (payload: any): any =>
  Object.entries(payload)
    .filter(([keyShop, _]) => keyShop.startsWith('inventory'))
    .filter(([_, quantity]) => quantity !== undefined)

const submitInventory = (
  allProducts: List,
  t: (field: string, { options }: any) => string,
  dispatch: any,
  decimals: number
) => {
  let shops: Array<{
    id: string
    productIDS?: Array<{ id: string; quantity: number; currentQuantity: number, price: number | null; }>
    variantIDS?: Array<{ id: string; productID: string; quantity: number; currentQuantity: number, price: number | null; }>
  }> = []
  const editValues = allProducts.editValues
  const products = allProducts.items.items

  Object.entries(editValues).forEach(([productID, payload]: [string, any]) => {
    getInventoryData(payload).forEach(([keyShopID, quantity]: [string, any]) => {
      const shopID = keyShopID.slice(9)
      const shop = shops.find((shop: { id: string }) => shop.id === shopID)
      const product = products.find((product) => product.id === productID)

      const currentQuantity = product
        .inventoryQuantities
        .find((inventory: { shopID: string }) => inventory.shopID === shopID)?.quantity || 0

      const price = payload.buyPrice !== undefined ? convertPrice(decimals, payload.buyPrice) : product.buyPrice

      if (shop !== undefined)
        shop?.productIDS?.push({
          id: productID,
          quantity: parseInt(quantity, 10),
          currentQuantity,
          price
        })
      else
        shops = [
          ...shops,
          {
            id: shopID,
            variantIDS: [],
            productIDS: [
              {
                id: productID,
                quantity: parseInt(quantity, 10),
                currentQuantity,
                price
              },
            ],
          },
        ]
    })
  })

  Object.entries(editValues).forEach(([productID, subValues]: [string, any]) => {
    if (subValues.subValues)
      Object.entries(subValues).forEach(([_, subPayload]: any) => {
        Object.entries(subPayload).forEach(([rawVariantID, values]) => {
          const variantID = rawVariantID.split('__').pop() as string;
          getInventoryData(values).forEach(([keyShopID, quantity]: [string, any]) => {
            const shopID = keyShopID.slice(9)
            const shop = shops.find((shop: { id: string }) => shop.id === shopID)
            const product = products.find((product) => product.id === productID)
            const variant = product.subValues.find((variant: { variantID: string }) => variant.variantID === variantID)

            const currentQuantity = variant
              .inventoryQuantities
              .find((inventory: { shopID: string }) => inventory.shopID === shopID)?.quantity || 0

            // @ts-ignore
            const price = values.buyPrice !== undefined ? convertPrice(decimals, values.buyPrice) : variant.buyPrice

            if (shop !== undefined) {
              shop?.variantIDS?.push({
                id: variantID,
                productID,
                quantity: parseInt(quantity, 10),
                currentQuantity,                
                price
              })
            } else {
              shops = [
                ...shops,
                {
                  id: shopID,
                  productIDS: [],
                  variantIDS: [
                    {
                      id: variantID,
                      productID,
                      quantity: parseInt(quantity, 10),
                      currentQuantity,
                      price
                    },
                  ],
                },
              ]
            }
          })
        })
      })
  })

  return Promise.all(
    shops.flatMap((shop) => {
      const productMovementsIn = shop.productIDS
        ? shop.productIDS.filter((product) => product.currentQuantity < product.quantity)
        : []
      const variantMovementsIn = shop.variantIDS
        ? shop.variantIDS?.filter((variant) => variant.currentQuantity < variant.quantity)
        : []
      const productMovementsOut = shop.productIDS
        ? shop.productIDS?.filter((product) => product.currentQuantity > product.quantity)
        : []
      const variantMovementsOut = shop.variantIDS
        ? shop.variantIDS?.filter((variant) => variant.currentQuantity > variant.quantity)
        : []

      return (productMovementsIn.length > 0 || variantMovementsIn.length > 0) &&
        (productMovementsOut.length > 0 || variantMovementsOut.length > 0)
        ? [
          createInventoryMovement(
            {
              companyID: getCompanyID(),
              motive: t('catalog.inventoryMovement.SUPPLIER_MOTIVE_DATE', {
                date: displayDateNumeric(),
              }),
              state: InventoryMovementState.CLOSED,
              origin: InventoryMovementOrigin.SUPPLIER,
              destination: InventoryMovementDestination.SHOP,
              destinationID: shop.id,
              movements: [
                ...productMovementsIn.map((product) => ({
                  productID: product.id,
                  quantity: product.quantity - product.currentQuantity,
                  price: product.price
                })),
                ...variantMovementsIn.map((variant) => ({
                  variantID: variant.id,
                  productID: variant.productID,
                  quantity: variant.quantity - variant.currentQuantity,
                  price: variant.price
                })),
              ],
            },
            dispatch
          ),
          createInventoryMovement(
            {
              companyID: getCompanyID(),
              motive: t('catalog.inventoryMovement.TRASH_MOTIVE_DATE', {
                date: displayDateNumeric(),
              }),
              state: InventoryMovementState.CLOSED,
              origin: InventoryMovementOrigin.SHOP,
              destination: InventoryMovementDestination.TRASH,
              originID: shop.id,
              movements: [
                ...productMovementsOut.map((product) => ({
                  productID: product.id,
                  quantity: product.currentQuantity - product.quantity,
                })),
                ...variantMovementsOut.map((variant) => ({
                  variantID: variant.id,
                  productID: variant.productID,
                  quantity: variant.currentQuantity - variant.quantity,
                })),
              ],
            },
            dispatch
          ),
        ]
        : productMovementsIn.length > 0 || variantMovementsIn.length > 0
          ? createInventoryMovement(
            {
              companyID: getCompanyID(),
              motive: t('catalog.inventoryMovement.SUPPLIER_MOTIVE_DATE', {
                date: displayDateNumeric(),
              }),
              state: InventoryMovementState.CLOSED,
              origin: InventoryMovementOrigin.SUPPLIER,
              destination: InventoryMovementDestination.SHOP,
              destinationID: shop.id,
              movements: [
                ...productMovementsIn.map((product) => ({
                  productID: product.id,
                  quantity: product.quantity - product.currentQuantity,
                  price: product.price
                })),
                ...variantMovementsIn.map((variant) => ({
                  variantID: variant.id,
                  productID: variant.productID,
                  quantity: variant.quantity - variant.currentQuantity,
                  price: variant.price
                })),
              ],
            },
            dispatch
          )
          : createInventoryMovement(
            {
              companyID: getCompanyID(),
              motive: t('catalog.inventoryMovement.TRASH_MOTIVE_DATE', {
                date: displayDateNumeric(),
              }),
              state: InventoryMovementState.CLOSED,
              origin: InventoryMovementOrigin.SHOP,
              destination: InventoryMovementDestination.TRASH,
              originID: shop.id,
              movements: [
                ...productMovementsOut.map((product) => ({
                  productID: product.id,
                  quantity: product.currentQuantity - product.quantity,
                })),
                ...variantMovementsOut.map((variant) => ({
                  variantID: variant.id,
                  productID: variant.productID,
                  quantity: variant.currentQuantity - variant.quantity,
                })),
              ],
            },
            dispatch
          )
    })
  )
}

const submitLines = (
  allProducts: List,
  t: (field: string) => string,
  dispatch: any,
  decimals: number
) => {
  const editValues = allProducts.editValues
  const products = allProducts.items.items

  return Promise.all(
    Object.entries(editValues).map(([id, payload]: [string, any]) => {
      return submitLine(products.find((product) => product.id === id)!, payload, decimals).then(
        async ([productRes, cfRes]) => {
          const res: any = { product: productRes, cf: cfRes };
          const productInfo = allProducts.items.items.find((item: any) => item.id === productRes.data.id);
          const allSubValueSelected = productInfo.subValues.filter((s: any) => s.selected).length === productInfo.subValues.length;
          const product = allSubValueSelected ? { ...productInfo, selected: true, allSelected: true, } : productInfo;
          // const product = formatWithSubvalues(false)(productRes.data);
          if (payload.subValues)
            return {
              id,
              res: {
                ...res,
                subValues: await submitVariant({
                  t,
                  product,
                  subValues: payload.subValues,
                  dispatch,
                  decimals,
                }),
              },
            }
          return { res, id }
        }
      )
    })
  )
}

const ValidationModal = ({ editValues, dispatch, decimals }: { editValues: any; dispatch: any; decimals: number }) => {
  const [state, setState] = useState({ total: 0, showSubmit: true })
  const { t } = useTranslation()
  const allProducts = useSelector(listProducts);
  const cyContext = generateDataCy({scope: "editable",value: "modal"})

  useEffect(() => {
    Object.entries(editValues).forEach(([_, value]: any) => {
      if (!value.subValues) state.total += 1
      else {
        state.total += 1
        Object.entries(value.subValues).forEach((_) => (state.total += 1))
      }
    })
    setState({ ...state })
  }, [editValues])

  const handleSubmit = () => {
    // Promise.all(
    //   Object.entries(editValues).map(([id, _]) =>
    //     callService<GetProductQueryVariables>({ id }, getProduct, 'getProduct')
    //   )
    // ).then((products) => {
      setState({ ...state, showSubmit: false })
      Promise.all([
        submitLines(allProducts, t, dispatch, decimals),
        submitInventory(allProducts, t, dispatch, decimals),
      ]).then(([resProduct]: any) => {
        const errors = buildErrors(resProduct)
        if (errors.length > 0) {
          closeAndResetModal(dispatch)
        } else {
          changeConf(dispatch, objectType.PRODUCT, 'requestProducts')
          clearAllFilters(dispatch)
          unSelectAllList(dispatch)
          updateList({ type: pluralize(objectType.PRODUCT), editValues: {}, errors: [] }, dispatch)
          toggleMenu(dispatch, { state: MenuState.EXPAND })
          closeAndResetModal(dispatch)
        }
      })
    // })
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalTitle}>{t('list.editable.TITLE')}</div>
      <div className={styles.modalContent}>
        <div className={styles.modalInfo} data-cy={generateDataCy({scope: "submitModal" ,value: "editedNumber"})}>
          {t('list.editable.CHANGES', {
            number: state.total,
            numberPlural: state.total > 1 ? 's' : '',
          })}
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
          dataCy={generateDataCy({scope: cyContext, value: "cancel"})}
        />
        <Button
          title={t('button.SAVE')}
          size={ComponentSize.MEDIUM}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          type={ComponentType.PRIMARY}
          onClick={handleSubmit}
          dataCy={generateDataCy({scope: cyContext, value: "submit"})}
        />
      </div>
    </div>
  )
}

export default ValidationModal
