import { displayPercentage } from '../../utils/number'
import { TypeColumnConfiguration } from '../types'
import { DropDownCell, LinkToRequesterCell } from '../../components/list/cell'
import { callService } from '../../services'
import { DeleteTaxInput } from '../../API'
import { deleteTax } from '../../graphql/custom/mutations'
import { updateModal } from '../../actions/modal'
import TaxModal from '../../views/setting/tax/modal'
import { TypeComparator, TypeFilter } from '../../components/requester/types'
import IconTouch from '../../components/newComponents/icons/iconTouch'
import { MoreHoriz } from '../../assets/icons'

const TAX = 'tax'

const taxesCols: TypeColumnConfiguration[] = [
  {
    id: 'listTaxes',
    type: TAX,
    cols: [
      {
        id: 'rate',
        title: 'RATE',
        type: 'function',
        field: 'rate',
        fieldType: 'number',
        active: true,
        function: (rate: number) => displayPercentage(rate),
      },
      {
        id: 'products',
        title: 'ASSOCIATION',
        type: 'element',
        fieldType: 'number',
        active: true,
        function: ({ value, taxesAssociations, navigate, dispatch }) => {
          return LinkToRequesterCell({
            filterID: 'RATE',
            field: 'rate',
            path: ['tax'],
            filters: [
              {
                type: TypeFilter.PERCENTAGE,
                comparator: TypeComparator.EQ,
              },
            ],
            value: value.rate,
            count: value.productCount,
            navigate,
            dispatch,
          })
        },
      },
      {
        id: 'more',
        title: '',
        type: 'element',
        active: true,
        function: ({ value, taxesAssociations, t, index, dispatch }: any) =>
          DropDownCell({
            fieldID: 'more',
            index,
            Element: IconTouch({
              Icon: MoreHoriz,
              color: 'var(--sys-color-content-secondary)',
            }),
            items: [
              {
                id: 'delete',
                value: t('settings.tax.DELETE'),
                onClick: () => {
                  if (value.productCount === 0) {
                    callService<{ input: DeleteTaxInput }>(
                      {
                        input: {
                          id: value.id,
                        },
                      },
                      deleteTax,
                      'deleteTax'
                    ).then((res) => {
                      if (res.errors.message) {
                        updateModal(
                          dispatch,
                          true,
                          () => TaxModal({ id: value.id }),
                          () => {}
                        )
                      }
                    })
                  } else
                    updateModal(
                      dispatch,
                      true,
                      () => TaxModal({ id: value.id }),
                      () => {}
                    )
                },
              },
            ],
          }),
      },
    ],
    creatable: true,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
    creatablePlaceholder: 'settings.tax.NEW',
  },
]

export default taxesCols
