import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { toggleFilters } from '../../../actions/requester'

import { getDashboardView, getMenu, getFiltersState } from '../../../store/selector'
import { DashboardView, MenuState, objectType } from '../../../store/types'
import { pluralize } from '../../../utils/typeToType'
import Requester, { AddFilter } from '../../requester'
import { TypePath, TypeRequester } from '../../requester/types'
import Button from '../button'
import { ComponentSize, ComponentType } from '../types'

import styles from './TopBar.module.css'

const TopBar = ({
  type,
  leftPart,
  middlePart,
  rightPart,
  requester,
  requesterFields,
  onChangeRequester,
}: {
  type?: objectType
  leftPart?: JSX.Element
  middlePart?: JSX.Element
  rightPart?: JSX.Element
  requester?: TypeRequester
  requesterFields?: Array<TypeRequester>
  onChangeRequester?: (requester: TypeRequester) => void
}) => {
  const filters = useSelector(getFiltersState(pluralize(type ?? 'product')))
  const menu = useSelector(getMenu)
  const dashboardView = useSelector(getDashboardView)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <div
      className={styles.topBar}
      style={{
        width:
          dashboardView === DashboardView.MOBILE
            ? '100vw'
            : `calc(100vw - ${menu.state === MenuState.SHRINK ? '4.5em' : menu.state === MenuState.CLOSE ? '0px' : '248px'
            })`,
      }}
    >
      <div className={styles.containerPart}>
        <div className={styles.part}>
          {leftPart}
          {dashboardView !== DashboardView.MOBILE && requesterFields && !filters.requesterOpen && (
            <div style={{ marginLeft: 'var(--sys-size-6)' }}>
              <AddFilter
                index={-1}
                operatorID={'uuid_1'}
                Element={
                  <Button
                    title={t('list.requester.FILTER')}
                    type={ComponentType.TERTIARY}
                    size={ComponentSize.MEDIUM}
                  />
                }
                fields={requesterFields}
                onAddFilter={(filter: TypeRequester) => {
                  type && toggleFilters(dispatch, { type, filters: { requesterOpen: true, searchbarOpen: true } })
                  onChangeRequester &&
                    onChangeRequester({
                      filterID: 'uuid_1',
                      type: TypePath.OPERATOR,
                      field: 'and',
                      path: [],
                      filters: [],
                      fields: [filter],
                    })
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.part}>{middlePart}</div>
        <div className={styles.part}>{rightPart}</div>
      </div>
      {requesterFields && filters.requesterOpen && (
        <Requester
          requester={requester}
          fields={requesterFields}
          onChange={(requester) => {
            if (requester?.fields?.length === 0 && type)
              toggleFilters(dispatch, { type, filters: { requesterOpen: false, searchbarOpen: true } })
            onChangeRequester && onChangeRequester(requester)
          }}
        />
      )}
    </div>
  )
}

export default TopBar
