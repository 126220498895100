import { updateAccount } from '../../../getters/setting/account'
import { TypeUserAccount } from '../../../types/account/userAccount'
import { parseName, parseAccountName } from '../../../utils/string'

const callUpdateUserAccount = async (state: TypeUserAccount, dispatch: any) => {
  const res = await updateAccount(
    {
      firstName: parseAccountName(state.firstName),
      lastName: parseName(state.lastName),
      color: state.color,
    },
    dispatch
  )
  return res
}

export default callUpdateUserAccount
