import { ComponentSize } from '../types'

import styles from './Thumbnail.module.css'

type Props = {
  image?: any
  color?: string
  iconText?: string
  size: ComponentSize
}

/**
 *
 * This element displays a thumbnail
 *
 * @param image - Thumbnail's image
 * @param color - Thumbnail's color
 * @param iconText - Thumbnail's iconText
 * @param size - Thumbnail's size
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Thumbnail = ({ image, color, iconText, size }: Props): JSX.Element =>
  image ? (
    <img
      src={image}
      className={styles.thumbnail}
      alt=""
      style={{
        width: `var(--comp-thumbnail-image-size-${size})`,
        height: `var(--comp-thumbnail-image-size-${size})`,
      }}
    />
  ) : (
    <div
      className={styles.thumbnail}
      style={{
        width: `var(--comp-thumbnail-image-size-${size})`,
        height: `var(--comp-thumbnail-image-size-${size})`,
        fontSize: `var(--sys-typography-body-${size}-font-size)`,
        backgroundColor: color,
        padding: `var(--comp-thumbnail-text-padding-${size}-top) var(--comp-thumbnail-text-padding-${size}-right) var(--comp-thumbnail-text-padding-${size}-bottom) var(--comp-thumbnail-text-padding-${size}-left)`,
      }}
    >
      <div className={styles.text}>{iconText}</div>
    </div>
  )

export default Thumbnail
