import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getRequesterQuery, getSelectedUser } from '../../../store/selector'
import { closeAndResetModal, updateModal } from '../../../actions/modal'
import { TypeFormError, ValidationFunction } from '../../../format/errors/types'
import emailValidator from '../../../format/errors/filters/export'
import ErrorModal from '../../newComponents/modal/error'
import { findErrorsInState } from '../../../format/errors'
import { objectType } from '../../../store/types'
import { callService } from '../../../services'
import {
  RequesterCustomerSortableFields,
  RequesterCashbookSortableFields,
  RequesterPaymentSortableFields,
  RequesterSaleSortableFields,
  RequesterOptionSortableFields,
  RequesterCategorySortableFields,
  RequesterSortDirection,
  RequesterDiscountSortableFields,
  RequesterProductSortableFields,
} from '../../../API'
import {
  exportProducts,
  exportCashbooks,
  exportCategories,
  exportCustomers,
  exportDiscounts,
  exportOptions,
  exportPayments,
  exportSales,
} from '../../../graphql/custom/queries'
import { getCompanyID } from '../../../services/localStorage'
import Input from '../../newComponents/input'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../newComponents/types'
import { handleBlur } from '../../../format/form'
import generateDataCy from '../../../utils/cypress'
import { pluralize } from '../../../utils/typeToType'
import Button from '../../newComponents/button'

import styles from '../../newComponents/modal/Modal.module.css'
import { exportInventoryMovement } from '../../../graphql/queries'

type ExportState = {
  email: string
  showSubmit: boolean
  errors?: {
    email?: TypeFormError
  }
  validators: {
    email?: ValidationFunction
  }
}

const ExportList = ({ type, inventoryId }: { type: string, inventoryId?: string }) => {
  const initialState: ExportState = {
    email: '',
    showSubmit: true,
    errors: {},
    validators: {
      email: emailValidator,
    },
  }
  const [state, setState] = useState(initialState)
  const user = useSelector(getSelectedUser)
  const query = useSelector(getRequesterQuery(pluralize(type)))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'requester', value: 'export' })

  useEffect(() => {
    if (user) setState({ ...state, email: user.email })
  }, [])

  const handleChange = (field: string) => (event: any) => {
    // @ts-ignore
    state.errors[field] = null
    setState({ ...state, [field]: event, showSubmit: true })
  }

  const handleResult = (res: any) => {
    if (res.errors) {
      updateModal(dispatch, true, ErrorModal, () => { }, "export")
    } else {
      closeAndResetModal(dispatch)
    }
  }

  const handleSubmit = () => {
    setState({ ...handleBlur(state, 'email'), showSubmit: false })

    if (!findErrorsInState(state.errors)) {
      const param = { filter: query, email: state.email }
      const inventoryParam = { id: inventoryId, email: state.email }
      
      switch (type) {
        case objectType.INVENTORY_MOVEMENT:
          return callService(
            {
              companyID: getCompanyID(),
              sort: {
                field: RequesterCashbookSortableFields.openedAt,
                direction: RequesterSortDirection.desc,
              },
              ...inventoryParam,
            },
            exportInventoryMovement,
            'exportInventoryMovement'
          ).then(handleResult)
        case objectType.PRODUCT:
          return callService(
            {
              catalogID: getCompanyID(),
              sort: {
                field: RequesterProductSortableFields.name,
                direction: RequesterSortDirection.asc,
              },
              ...param,
            },
            exportProducts,
            'exportProducts'
          ).then(handleResult)
        case objectType.DISCOUNT:
          return callService(
            {
              catalogID: getCompanyID(),
              sort: {
                field: RequesterDiscountSortableFields.name,
                direction: RequesterSortDirection.asc,
              },
              ...param,
            },
            exportDiscounts,
            'exportDiscounts'
          ).then(handleResult)
        case objectType.CATEGORY:
          return callService(
            {
              catalogID: getCompanyID(),
              sort: {
                field: RequesterCategorySortableFields.name,
                direction: RequesterSortDirection.asc,
              },
              ...param,
            },
            exportCategories,
            'exportCategories'
          ).then(handleResult)
        case objectType.OPTION:
          return callService(
            {
              catalogID: getCompanyID(),
              sort: {
                field: RequesterOptionSortableFields.name,
                direction: RequesterSortDirection.asc,
              },
              ...param,
            },
            exportOptions,
            'exportOptions'
          ).then(handleResult)
        case objectType.SALE:
          return callService(
            {
              companyID: getCompanyID(),
              sort: {
                field: RequesterSaleSortableFields.openedAt,
                direction: RequesterSortDirection.desc,
              },
              ...param,
            },
            exportSales,
            'exportSales'
          ).then(handleResult)
        case objectType.PAYMENT:
          return callService(
            {
              companyID: getCompanyID(),
              sort: {
                field: RequesterPaymentSortableFields.date,
                direction: RequesterSortDirection.desc,
              },
              ...param,
            },
            exportPayments,
            'exportPayments'
          ).then(handleResult)
        case objectType.CASHBOOK:
          return callService(
            {
              companyID: getCompanyID(),
              sort: {
                field: RequesterCashbookSortableFields.openedAt,
                direction: RequesterSortDirection.desc,
              },
              ...param,
            },
            exportCashbooks,
            'exportCashbooks'
          ).then(handleResult)
        case objectType.CUSTOMER:
          return callService(
            {
              companyID: getCompanyID(),
              sort: {
                field: RequesterCustomerSortableFields.firstName,
                direction: RequesterSortDirection.asc,
              },
              ...param,
            },
            exportCustomers,
            'exportCustomers'
          ).then(handleResult)
        default:
          return () => { }
      }
    }
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalTitleContainer}>
        <div className={styles.modalTitleCustom}>{t('list.export.TITLE')}</div>
      </div>
      <div className={styles.modalContentCustomFlex}>
        <div className={styles.modalInfo}>{t('list.export.EMAIL')}</div>
        <div className={styles.modalInfoExport}>
          <span className={styles.modalImportantInfoExport}>{t('list.export.EMAIL_SPAN')}</span>{" "}
          {t('list.export.EMAIL_SUB')}
        </div>
        <div style={{ paddingTop: '8px' }} >
        <Input
          label={t('list.export.LABEL')}
          value={state.email}
          onChange={handleChange('email')}
          onBlur={() => setState(handleBlur(state, 'email'))}
          onSubmit={handleSubmit}
          style={{
            status: state.errors?.email?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={state.errors?.email?.value ? state.errors.email.message : undefined}
          dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
        />
        </div>
      </div>
      <div className={styles.modalButtonsCustom}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
          dataCy={'cancel'}
        />
        <Button
          title={t('button.SEND')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default ExportList
