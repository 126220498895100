import Layer from '../layer'
import { ComponentSize, ComponentState } from '../types'

import styles from './TextLink.module.css'

const TextLink = ({
  text,
  state = ComponentState.DEFAULT,
  handleClick,
  size,
  maxWidth,
  dataCy,
}: {
  text: string
  state?: ComponentState
  size: ComponentSize
  handleClick?: () => void
  maxWidth?: string
  dataCy?: string
}) => {
  const getStyle = {
    color: `var(--comp-text-link-color-foreground-${state})`,
    textDecoration:
      state === ComponentState.DISABLED ? 'none' : `var(--sys-typography-label-${size}-underline-text-decoration)`,
    fontSize: `var(--sys-typography-label-${size}-underline-font-size)`,
    letterSpacing: `var(--sys-typography-label-${size}-underline-letter-spacing)`,
    lineHeight: `var(--sys-typography-label-${size}-underline-line-height)`,
    fontWeight: `var(--sys-typography-label-${size}-underline-font-weight)`,
  }

  return (
    // @ts-ignore
    <div style={getStyle} className={styles.textLink} onClick={handleClick} data-cy={dataCy}>
      {state === ComponentState.DISABLED ? (
        <>{text}</>
      ) : (
        <Layer
          style={{
            display: 'inline',
            borderRadius: 'var(--comp-text-link-border-radius)',
            height: `var(--comp-text-link-size-height-${size})`,
            padding: `4px var(--comp-text-link-padding-right) 0 var(--comp-text-link-padding-left)`,
            maxWidth: maxWidth ?? 'inherit',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {text}
        </Layer>
      )}
    </div>
  )
}

export default TextLink
