import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Template from '../template'
import { ComponentSize, ComponentType } from '../../../components/newComponents/types'
import { updateModal } from '../../../actions/modal'
import { listCompanies, listCompaniesItemsNextToken } from '../../../store/selector'
import List from '../../../components/list'
import { ListState, objectType } from '../../../store/types'
import CompanyModal from './modal'
import Button from '../../../components/newComponents/button'

import styles from '../Template.module.css'
import { useEffect } from 'react'
import { pluralize } from '../../../utils/typeToType'
import { updateListState } from '../../../actions/list'

const Companies = () => {
  const companies = useSelector(listCompanies)
  const { items } = useSelector(listCompaniesItemsNextToken)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (companies.items && companies.items.items && companies.items.items.length > 0) {
      updateListState({ type: pluralize(objectType.COMPANY), state: ListState.LOADED }, dispatch)
    } else {
      updateListState({ type: pluralize(objectType.COMPANY), state: ListState.EMPTY }, dispatch)
    }
  }, [])

  const handleCreate = () =>
    updateModal(
      dispatch,
      true,
      () => <CompanyModal navigate={navigate} />,
      () => { }
    )

  return (
    <>
      {items.length > 0 ? (
        <List cols={companies.cols!} items={items} type={objectType.COMPANY} onCreate={handleCreate} />
      ) : (
        <Template background={items.length > 0 ? 'var(--sys-color-surface-01)' : 'var(--sys-color-surface-00)'}>
          <div className={styles.form}>
            <div className={styles.title}>{t('account.companies.TITLE')}</div>
            <div className={styles.subTitle}>{t('account.companies.SUBTITLE')}</div>
            <Button
              title={t('account.companies.NEW')}
              size={ComponentSize.MEDIUM}
              type={ComponentType.PRIMARY}
              onClick={handleCreate}
            />
          </div>
        </Template>
      )}
    </>
  )
}

export default Companies
