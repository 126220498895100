const Shop = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.3214 7.8C20.2314 7.34 19.8214 7 19.3414 7H4.98138C4.50138 7 4.09138 7.34 4.00138 7.8L3.16138 12V13C3.16138 13.55 3.61138 14 4.16138 14V19C4.16138 19.55 4.61138 20 5.16138 20H13.1614C13.7114 20 14.1614 19.55 14.1614 19V14H18.1614V19C18.1614 19.55 18.6114 20 19.1614 20C19.7114 20 20.1614 19.55 20.1614 19V14C20.7114 14 21.1614 13.55 21.1614 13V12L20.3214 7.8ZM12.1614 18H6.16138V14H12.1614V18ZM5.16138 6H19.1614C19.7114 6 20.1614 5.55 20.1614 5C20.1614 4.45 19.7114 4 19.1614 4H5.16138C4.61138 4 4.16138 4.45 4.16138 5C4.16138 5.55 4.61138 6 5.16138 6Z"
      fill={color}
    />
  </svg>
)

export default Shop
