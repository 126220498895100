import { getIconTitleLine } from '../../../../components/oldComponents/activity/lines'
import { displayPrice } from '../../../../utils/number'
import { TypeCompanyCurrency } from '../../../../types/currency'
import { TypeFidelity } from '../../../../types'
import Title from '../../../../components/oldComponents/activity/title'
import DisplayIcon2 from '../../../../components/newComponents/icons'

import { Euro, Shopping, Calendar, Star } from '../../../../assets/icons'

const getCreatedAtLine = (t: (value: string) => string, createdAt?: string) =>
  createdAt
    ? getIconTitleLine(
        'createdAt',
        {
          icon: DisplayIcon2({
            Icon: Star,
            color: 'var(--sys-color-content-secondary)',
          }),
        },
        <Title topText={t('customer.fidelity.CREATED_AT')} mainTexts={[createdAt]} />
      )
    : undefined

const getLastVisitLine = (t: (value: string) => string, lastVisit?: string) =>
  lastVisit
    ? getIconTitleLine(
        'lastVisit',
        {
          icon: DisplayIcon2({
            Icon: Calendar,
            color: 'var(--sys-color-content-secondary)',
          }),
        },
        <Title topText={t('customer.fidelity.LAST_VISIT')} mainTexts={[lastVisit]} />
      )
    : undefined

const getPurchasesNumberLine = (t: (value: string) => string, purchasesNumber: number) =>
  getIconTitleLine(
    'purchase',
    {
      icon: DisplayIcon2({
        Icon: Euro,
        color: 'var(--sys-color-content-secondary)',
      }),
    },
    <Title topText={t('customer.fidelity.PURCHASE')} mainTexts={[purchasesNumber]} />
  )

const getAverageBasketLine = (t: (value: string) => string, company: TypeCompanyCurrency, averageBasket: number) =>
  getIconTitleLine(
    'averageBasket',
    {
      icon: DisplayIcon2({
        Icon: Shopping,
        color: 'var(--sys-color-content-secondary)',
      }),
    },
    <Title
      topText={t('customer.fidelity.AVERAGE_BASKET')}
      mainTexts={[displayPrice(averageBasket, company.currency, company.currencyDecimals)]}
    />
  )

const getTotalSpentLine = (t: (value: string) => string, company: TypeCompanyCurrency, totalSpent: number) =>
  getIconTitleLine(
    'totalSpent',
    {
      icon: DisplayIcon2({
        Icon: Euro,
        color: 'var(--sys-color-content-secondary)',
      }),
    },
    <Title
      topText={t('customer.fidelity.TOTAL_SPENT')}
      mainTexts={[displayPrice(totalSpent, company.currency, company.currencyDecimals)]}
    />
  )

const getInfoLines = (t: (value: string) => string, customer: TypeFidelity, company: TypeCompanyCurrency) =>
  Promise.all(
    [
      getCreatedAtLine(t, customer.createdAt),
      getLastVisitLine(t, customer.lastVisit),
      getPurchasesNumberLine(t, customer.purchasesNumber),
      getAverageBasketLine(t, company, customer.averageBasket),
      getTotalSpentLine(t, company, customer.totalSpent),
    ].filter((info) => info)
  )

export default getInfoLines
