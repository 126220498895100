import { useSelector } from 'react-redux'

import { getDrawerState } from '../../../../store/selector'
import Loader from '../../../../components/newComponents/loader'
import { DrawerState } from '../../../../store/types'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'

const Cashbook = ({ children, dataCy }: { children: JSX.Element; dataCy: string }) => {
  const drawerState = useSelector(getDrawerState)

  return (
    <div>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : drawerState === DrawerState.LOADED ? (
        <div
          className={styles.containerForm}
          style={{
            height: `calc(100vh - (61px + var(--comp-tab-list-item-height-lg) + 10px))`,
          }}
          data-cy={dataCy}
        >
          {children}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Cashbook
