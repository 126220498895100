const SortNone = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.164 7.4099L9.91068 9.66324H14.4173L12.164 7.4099ZM11.6973 6.4699C11.9573 6.2099 12.3773 6.2099 12.6373 6.4699L15.6907 9.52324C16.1107 9.94324 15.8107 10.6632 15.2173 10.6632H9.10402C8.51068 10.6632 8.21735 9.94324 8.63735 9.5299L11.6973 6.4699ZM12.1637 16.5899L14.417 14.3366H9.91033L12.1637 16.5899ZM12.6303 17.5299C12.3703 17.7899 11.9503 17.7899 11.6903 17.5299L8.63033 14.4766C8.21033 14.0566 8.51033 13.3366 9.10367 13.3366H15.2237C15.817 13.3366 16.1103 14.0566 15.6903 14.4699L12.6303 17.5299Z"
      fill={color}
    />
  </svg>
)

export default SortNone
