import { getProduct, getProductWithVariant } from './product';
import { updateListElement, addListElement, updateVariantElementSubscription } from '../../actions/list';
import { ProductWitSubValues, objectType } from '../../store/types';
import formatWithSubvalues from '../../format/product';
import { GenerateProductVariantsInput } from '../../API';
import { getCompanyID } from '../../services/localStorage';
import { getObservable } from '../../services/graphql';
import { onUpdateProductVariants } from '../../graphql/custom/subscriptions';
import { generateProductVariants as generateAD } from '../../graphql/custom/mutations';
import { callService } from '../../services';
import * as variationsService from '../../services/catalog/variants';
import { mapSubvalues } from '../../format/product';
import { pluralize } from '../../utils/typeToType';

let onUpdateProductVariantsSubscription: any;

const subscribeProductVariants = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  };

  if (!onUpdateProductVariantsSubscription)
    onUpdateProductVariantsSubscription = getObservable(onUpdateProductVariants, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateProductVariants.productID) {
          updateVariantElementSubscription(eventData.data.onUpdateProductVariants, dispatch);
        }
      },
    });
};

const unSubscribeProductVariants = () => {
  if (onUpdateProductVariantsSubscription) {
    onUpdateProductVariantsSubscription.unsubscribe();
    onUpdateProductVariantsSubscription = null;
  }
};

const generateProductVariants = async (input: GenerateProductVariantsInput, dispatch: any) => {
  const variant = await callService<{ input: GenerateProductVariantsInput }>(
    { input },
    generateAD,
    'generateProductVariants',
  );

  if (variant.data) {
    const product = await getProduct(variant.data.productID, dispatch);
    if (product.data) {
      updateListElement({
        element: formatWithSubvalues(false, false)(product.data),
        dispatch,
        type: objectType.PRODUCT,
      });
    }
  }
  return variant;
};

//@ts-ignore
const recursiveVariationsCall = async (
  payload: {
    productID: string;
    selected: boolean;
    nextToken: string;
    limit?: number;
  },
  product: any,
  isEditing: boolean | undefined,
  allSelected?: boolean,
) => {
  const response = await variationsService.loadVariations(payload);
  let newVariants: any;

  //temporary to handle stockMovement view broken
  if (isEditing && allSelected) {
    newVariants = response.data.getProduct.variants.items.map(mapSubvalues(allSelected));
  }

  if (isEditing && !allSelected) {
    newVariants = response.data.getProduct.variants.items.map(mapSubvalues(!allSelected));
  }

  if (!isEditing && allSelected) {
    newVariants = response.data.getProduct.variants.items.map(mapSubvalues(allSelected));
  }

  if (!isEditing && !allSelected) {
    newVariants = response.data.getProduct.variants.items.map(mapSubvalues(allSelected));
  }

  const variantAlreadyInSubValues = newVariants.some((variant: any) =>
    product.subValues.some((subV: any) => subV.id === variant.id),
  );

  if (variantAlreadyInSubValues && newVariants.length >= 10) {
    payload.nextToken = response.data.getProduct.variants.nextToken;
    return await recursiveVariationsCall(payload, product, isEditing, allSelected);
  } else {
    return response;
  }
};

const loadVariations = async (
  payload: { productID: string; selected: boolean; nextToken: string; limit?: number },
  dispatch: any,
  productList: Array<ProductWitSubValues>,
  allSelected?: boolean,
  isEditing?: boolean,
) => {


  const res = await variationsService.loadVariations(payload);
  if (!payload.nextToken) {
    payload.nextToken = res.data.getProduct.variants.nextToken;
    const product = productList.find(p => p.id === payload.productID);
    let newVariants: any;

    //temporary to handle stockMovement view broken
    if (isEditing && allSelected) {
      newVariants = res.data.getProduct.variants.items.map(mapSubvalues(allSelected));
    }

    if (isEditing && !allSelected) {
      newVariants = res.data.getProduct.variants.items.map(mapSubvalues(!allSelected));
    }

    if (!isEditing && allSelected) {
      newVariants = res.data.getProduct.variants.items.map(mapSubvalues(allSelected));
    }

    if (!isEditing && !allSelected) {
      newVariants = res.data.getProduct.variants.items.map(mapSubvalues(allSelected));
    }

    const existingVariantIDs = (product?.subValues || []).map(e => e?.id)
    newVariants = newVariants.filter((variant: { id: string }) => !existingVariantIDs.includes(variant.id))

    //! ça marche faire la même chose !!
    const subvalueNextToken = res.data.getProduct.variants.nextToken;
    const newProduct = {
      ...product,
      selected: allSelected ? allSelected : payload.selected,
      subValues: [
        //@ts-ignore
        ...product.subValues,
        ...newVariants,
      ],
      subvalueNextToken,
    };

    addListElement(dispatch, newVariants, '', pluralize(objectType.VARIANT));
    updateListElement({
      element: newProduct,
      dispatch,
      type: objectType.PRODUCT,
    });
    return newProduct;
  } else {
    const product = productList.find(p => p.id === payload.productID);
    if (product && res.data.getProduct.variants.items) {
      let newVariants: any;

      //temporary to handle stockMovement view broken
      if (isEditing && allSelected) {
        newVariants = res.data.getProduct.variants.items.map(mapSubvalues(allSelected));
      }

      if (isEditing && !allSelected) {
        newVariants = res.data.getProduct.variants.items.map(mapSubvalues(!allSelected));
      }

      if (!isEditing && allSelected) {
        newVariants = res.data.getProduct.variants.items.map(mapSubvalues(allSelected));
      }

      if (!isEditing && !allSelected) {
        newVariants = res.data.getProduct.variants.items.map(mapSubvalues(allSelected));
      }

      const existingVariantIDs = (product?.subValues || []).map(e => e?.id)
      newVariants = newVariants.filter((variant: { id: string }) => !existingVariantIDs.includes(variant.id))

      const subvalueNextToken = res.data.getProduct.variants.nextToken;
      const newProduct = {
        ...product,
        selected: allSelected ? allSelected : payload.selected,
        subValues: [
          //@ts-ignore
          ...product.subValues,
          ...newVariants,
        ],
        subvalueNextToken,
      };

      addListElement(dispatch, newVariants, '', pluralize(objectType.VARIANT));
      updateListElement({
        element: newProduct,
        dispatch,
        type: objectType.PRODUCT,
      });
      // updateListElement({
      //   element: newProduct,
      //   dispatch,
      //   type: objectType.INVENTORY_MOVEMENT,
      // })
      // updateListElement({
      //   element: newProduct,
      //   dispatch,
      //   type: objectType.INVENTORY_MOVE_PRODUCT,
      // })
      return newProduct;
    }
    return res;
  }
};

export { subscribeProductVariants, unSubscribeProductVariants, generateProductVariants, loadVariations };
