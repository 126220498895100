import { TypeCol, TypeColumnConfiguration } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { stateCashbookEnumToStr } from '../../utils/typeToType'
import Chip from '../../components/newComponents/chip'

const CASHBOOK = 'cashbook'

export const cashbookCols: Array<TypeCol> = [
  {
    id: 'number',
    title: 'NUMBER',
    type: 'function',
    field: 'number',
    fieldType: 'number',
    active: true,
    sortable: true,
    notCustomable: true,
    function: (number) => '#' + number,
  },
  {
    id: 'state',
    title: 'SITUATION',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value, t }) => Chip(stateCashbookEnumToStr(value.state, t)),
  },
  {
    id: 'sourceID',
    title: 'SOURCE_ID',
    type: 'text',
    field: 'sourceID',
    fieldType: 'string',
    active: true,
  },
  {
    id: 'sourceName',
    title: 'SOURCE_NAME',
    type: 'text',
    field: 'sourceName',
    fieldType: 'string',
    active: true,
  },
  {
    id: 'openedAt',
    title: 'OPENED_AT',
    type: 'function',
    field: 'openedAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => displayDateShortMonthWithHours(date),
  },
  {
    id: 'closedAt',
    title: 'CLOSED_AT',
    type: 'function',
    field: 'closedAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => (date ? displayDateShortMonthWithHours(date) : 'Inconnue'),
  },
  {
    id: 'note',
    title: 'CASHBOOK_NOTE',
    type: 'text',
    field: 'note',
    fieldType: 'string',
    active: true,
  },
]

export const cashbooksColsConf: TypeColumnConfiguration[] = [
  {
    id: 'requestCashbooks',
    type: CASHBOOK,
    cols: cashbookCols,
    creatable: false,
    editable: false,
    exportable: true,
    clickable: true,
    shrinkrable: true,
    customable: true,
    checkboxes: false,
    searchBarPlaceholder: 'searchBar.placeholder.NUMBER',
  },
]
