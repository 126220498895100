import {
  createCategoryCustomField,
  updateCategoryCustomField,
  deleteCategoryCustomField,
  callCustomFieldsUpdate,
} from '../../../../../../services/setting/customField'
import { TypeCategory } from '../../../../../../types'
import { DataWithErrors } from '../../../../../../services/types'
import { buildTemporaryErrors, createInput, isNestedDirty } from '../../../../../../utils/dirties'
import { DeleteImageInput, ImageModelType, UpdateCategoryInput, UploadImageInput } from '../../../../../../API'
import { updateCategory as update } from '../../../../../../graphql/custom/mutations'
import { callService } from '../../../../../../services'
import { deleteImage, uploadImage } from '../../../../../../graphql/mutations'

const callCategoryUpdate = async (payload: TypeCategory) => {
  if (payload.id) {
    let res: DataWithErrors = {}
    const input: any = createInput(payload)

    if (Object.keys(input).length > 1) {
      res = await callService<{ input: UpdateCategoryInput }>({ input }, update, 'updateCategory')
      if (res.errors) return res
    }

    const toWait: Array<Promise<any>> = []

    if (isNestedDirty(payload.dirties, 'photo') || isNestedDirty(payload.dirties, 'color')) {
      if (payload.photo && payload.selected === 'photo')
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: payload.id, type: ImageModelType.CATEGORY, base64Image: payload.photo } },
            uploadImage,
            'uploadImage'
          )
        )
      else
        toWait.push(
          callService<{ input: DeleteImageInput }>(
            { input: { id: payload.id, type: ImageModelType.CATEGORY } },
            deleteImage,
            'deleteImage'
          )
        )
    }

    if (isNestedDirty(payload.dirties, 'customFields')) {
      toWait.push(
        callCustomFieldsUpdate(payload.customFields, {
          id: { categoryID: payload.id },
          create: createCategoryCustomField,
          update: updateCategoryCustomField,
          delete: deleteCategoryCustomField,
        })
      )
    }

    res.errors = await buildTemporaryErrors(toWait, res.errors)
    return res
  }
  return null
}

export default callCategoryUpdate
