import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  CreateCustomFieldInput,
  CustomFieldObjectType,
  CustomFieldValueType,
  DeleteCustomFieldInput,
  UpdateCustomFieldInput,
} from '../../../API'
import { closeAndResetModal } from '../../../actions/modal'
import {
  name as nameValidator,
  objectType as objectTypeValidator,
  valueType as valueTypeValidator,
  key as keyValidator,
} from '../../../format/errors/filters/setting/customField'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { generateCustomFieldKey, parseKey } from '../../../utils/key'
import {
  objectCustomFieldEnumToStr,
  objectCustomFieldStrToEnum,
  objectCustomFieldStrToObjectType,
  valueCustomFieldEnumToStr,
  valueCustomFieldStrToEnum,
} from '../../../utils/typeToType'
import Input from '../../../components/newComponents/input'
import {
  ComponentDirection,
  ComponentErrorText,
  ComponentSize,
  ComponentState,
  ComponentStatus,
  ComponentType,
} from '../../../components/newComponents/types'
import UniqueDropDown from '../../../components/newComponents/dropDown/unique'
import { callService } from '../../../services'
import { createCustomField, deleteCustomField, updateCustomField } from '../../../graphql/custom/mutations'
import { getCompanyID } from '../../../services/localStorage'
import generateDataCy from '../../../utils/cypress'
import { handleBlur } from '../../../format/form'
import { TypeCustomFieldState } from '../../../types'
import Divider from '../../../components/newComponents/divider'
import Button from '../../../components/newComponents/button'
import { DataWithErrors } from '../../../services/types'
import { getCustomField } from '../../../getters/setting/customField'
import { displayFullName, parseName } from '../../../utils/string'
import { getSelectedCustomFieldAssociations, listUserItemsNextToken } from '../../../store/selector'
import { displayDateShortMonthWithHours } from '../../../utils/date'
import TextLink from '../../../components/newComponents/textLink'

import { KeyboardArrowDown } from '../../../assets/icons'
import styles from '../../../components/newComponents/modal/Modal.module.css'

const CustomFieldModal = ({ id, isDelete }: { id?: string; isDelete?: boolean }) => {
  const initialState: TypeCustomFieldState = {
    name: '',
    displayKey: false,
    keyError: false,
    showSubmit: false,
    key: '',
    errors: {},
    validators: {
      name: nameValidator,
      objectType: objectTypeValidator,
      valueType: valueTypeValidator,
      key: keyValidator,
    },
  }
  const [state, setState] = useState(initialState)
  const users = useSelector(listUserItemsNextToken)
  const association = useSelector(getSelectedCustomFieldAssociations(id))
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cyContext = generateDataCy({ scope: 'settings', value: 'customField' })

  const setCustomFieldInState = (data: DataWithErrors) => {
    if (data.data) {
      const customField = data.data
      const user = users.items.find((user: { id: string }) => user.id === customField.createdByID)

      setState({
        ...state,
        id: customField.id,
        name: customField.name,
        objectType: objectCustomFieldEnumToStr(customField.objectType),
        valueType: valueCustomFieldEnumToStr(customField.valueType),
        key: customField.key,
        createdBy: user ? displayFullName(user.firstName, user.lastName) : '',
        createdAt: displayDateShortMonthWithHours(customField.createdAt),
        showSubmit: !!isDelete,
      })
    }
  }

  useEffect(() => {
    if (id) {
      getCustomField(id, dispatch).then(setCustomFieldInState)
    }
      }, [id])

  const handleChange = (field: string) => (event: any) => {
    state.errors!.global = undefined
    state.errors!.key = undefined

    if (event && field === 'valueType') state.valueType = valueCustomFieldEnumToStr(event.toUpperCase())
    else if (event && field === 'objectType') {
      if (!id) state.key = generateCustomFieldKey(event, state.name)
      // @ts-ignore
      state.objectType = objectCustomFieldEnumToStr(event.toUpperCase())
    } else if (field === 'name') {
      if (state.objectType) {
        if (!id)
          state.key = generateCustomFieldKey(
            // @ts-ignore
            objectCustomFieldStrToObjectType(state.objectType),
            event
          )
      }
      state.name = event
    } else if (field === 'key') {
      state.key = parseKey(event)
    }

    // @ts-ignore
    state.errors[field] = null
    const showSubmit = !findErrorsInState(state.errors)

    setState({ ...state, showSubmit })
  }

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
      setState({ ...state, displayKey: newState.errors?.global.errorType === "g-003", keyError: newState.errors?.global.errorType === "g-003" })
    } else {
      closeAndResetModal(dispatch)
    }
  }

  const handleSubmit = () => {
    if (isDelete && state.id) {
      callService<{ input: DeleteCustomFieldInput }>(
        {
          input: {
            id: state.id,
          },
        },
        deleteCustomField,
        'deleteCustomField'
      ).then(() => closeAndResetModal(dispatch))
    } else {
      state.showSubmit = false
      setState(handleBlur(state, 'objectType'))
      setState(handleBlur(state, 'valueType'))
      setState(handleBlur(state, 'name'))
      setState(handleBlur(state, 'key'))

      if (!findErrorsInState(state.errors) && state.name && state.objectType && state.valueType && state.key) {
        state.name = parseName(state.name)
        state.key = state.keyError || state.key ? state.key : generateCustomFieldKey(
                // @ts-ignore
                objectCustomFieldStrToObjectType(state.objectType),
                state.name
              )

        id
          ? callService<{ input: UpdateCustomFieldInput }>(
              {
                input: {
                  id: state.id!,
                  name: state.name,
                },
              },
              updateCustomField,
              'updateCustomField'
            ).then(handleResult)
          : callService<{ input: CreateCustomFieldInput }>(
              {
                input: {
                  catalogID: getCompanyID(),
                  name: state.name,
                  objectType: objectCustomFieldStrToEnum(state.objectType),
                  valueType: valueCustomFieldStrToEnum(state.valueType),
                  key: state.key,
                },
              },
              createCustomField,
              'createCustomField'
            ).then(handleResult)
      }
    }
  }

  const displayKey = () => setState({ ...state, displayKey: !state.displayKey })

  const errorText = () => {
    if (state.errors?.key?.value) {
      return state.errors?.key?.message
    }

    if (state.errors?.global?.errorType) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <div
      className={styles.modal}
      data-cy={generateDataCy({
        scope: cyContext,
        value: isDelete ? 'delete' : id ? 'update' : 'create',
      })}
    >
      <div className={isDelete ? styles.modalDangerTitle : styles.modalTitle}>
        {isDelete
          ? t('settings.customField.modal.DELETE')
          : id
          ? t('settings.customField.modal.EDIT')
          : t('settings.customField.modal.CREATE')}
      </div>
      <div className={styles.modalContent}>
        {isDelete ? (
          <>
            <div
              className={styles.modalImportantInfo}
              data-cy={generateDataCy({
                scope: cyContext,
                value: generateDataCy({ scope: 'delete', value: 'associations' }),
              })}
            >
              {t('settings.customField.modal.ASSOCIATION', {
                customFieldName: state.name,
                customFieldAssociations: association && association?.entity.ids.length,
                customFieldEntity: association && association?.entity.type,
                pluralAssociation: association && association?.entity.ids.length > 1 ? 's' : '',
              })}
            </div>
            <div className={styles.modalInfo}>{t('settings.customField.modal.CONFIRM')}</div>
          </>
        ) : (
          <>
            <Input
              label={t('settings.customField.modal.NAME')}
              value={state.name}
              onChange={handleChange('name')}
              onBlur={() => setState(handleBlur(state, 'name'))}
              onSubmit={handleSubmit}
              style={{
                status: state.errors?.name?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              }}
              helperText={state.errors?.name?.value ? state.errors.name.message : undefined}
              dataCy={generateDataCy({ scope: cyContext, value: 'name' })}
            />
            <UniqueDropDown
              parentID={'selectElement'}
              popUpID={'selectElementPopUp'}
              Element={
                <Input
                  placeholder={t('settings.customField.modal.ASSOCIATED_AT')}
                  value={state.objectType}
                  style={{
                    trailIcons: [{ icon: KeyboardArrowDown }],
                    state: id ? ComponentState.DISABLED : ComponentState.DEFAULT,
                    readOnly: true,
                  }}
                  dataCy={generateDataCy({ scope: cyContext, value: 'element' })}
                />
              }
              items={[
                ...Object.values(CustomFieldObjectType).map((objectType) => ({
                  // handle error
                  id: objectType.toString().toLowerCase(),
                  value: objectCustomFieldEnumToStr(objectType),
                  onClick: handleChange('objectType'),
                })),
              ]}
              state={id ? ComponentState.DISABLED : ComponentState.DEFAULT}
              dataCy={generateDataCy({ scope: cyContext, value: 'element' })}
              // icons={getAlertIcon(state.errors?.objectType!)}
              // error={state.errors?.objectType?.value}
            />
            <UniqueDropDown
              parentID={'selectType'}
              popUpID={'selectTypePopUp'}
              Element={
                <Input
                  placeholder={t('settings.customField.modal.FORMAT')}
                  value={state.valueType}
                  style={{
                    trailIcons: [{ icon: KeyboardArrowDown }],
                    state: id ? ComponentState.DISABLED : ComponentState.DEFAULT,
                    readOnly: true,
                  }}
                  dataCy={generateDataCy({ scope: cyContext, value: 'type' })}
                />
              }
              items={[
                ...Object.values(CustomFieldValueType).map((valueType) => ({
                  // handle error
                  id: valueType.toString().toLowerCase(),
                  value: valueCustomFieldEnumToStr(valueType),
                  onClick: handleChange('valueType'),
                })),
              ]}
              state={id ? ComponentState.DISABLED : ComponentState.DEFAULT}
              dataCy={generateDataCy({ scope: cyContext, value: 'type' })}
              // icons={getAlertIcon(state.errors?.valueType!)}
              // error={state.errors?.valueType?.value}
            />
            {id && (
              <>
                <Input
                  label={t('settings.customField.modal.CREATED_BY')}
                  value={state.createdBy}
                  style={{
                    state: ComponentState.DISABLED,
                  }}
                  dataCy={generateDataCy({ scope: cyContext, value: 'createdBy' })}
                />
                <Input
                  label={t('settings.customField.modal.CREATED_AT')}
                  value={state.createdAt}
                  style={{
                    state: ComponentState.DISABLED,
                  }}
                  dataCy={generateDataCy({ scope: cyContext, value: 'createdAt' })}
                />
              </>
            )}
            <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
            <TextLink
              text={
                state.displayKey
                  ? t('settings.customField.modal.key.HIDE')
                  : t('settings.customField.modal.key.DISPLAY')
              }
              handleClick={displayKey}
              size={ComponentSize.SMALL}
              dataCy={generateDataCy({ scope: cyContext, value: 'displayKey' })}
            />
            {state.displayKey && (
              <>
                <div className={styles.modalInfo}>
                  {id
                    ? t('settings.customField.modal.key.EXPLICATION_EDIT')
                    : t('settings.customField.modal.key.EXPLICATION_CREATE')}
                </div>
                <Input
                  label={t('settings.customField.modal.key.NAME')}
                  value={state.key}
                  onChange={handleChange('key')}
                  onBlur={() => setState(handleBlur(state, 'key'))}
                  onSubmit={handleSubmit}
                  style={{
                    state: id ? ComponentState.DISABLED : ComponentState.DEFAULT,
                    // status: state.errors?.key?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                    status: state.errors?.key?.value || state.errors?.global?.errorType ? ComponentStatus.ERROR : ComponentStatus.DEFAULT
                  }}
                  helperText={errorText()}
                  dataCy={generateDataCy({ scope: cyContext, value: 'key' })}
                />
              </>
            )}
          </>
        )}
      </div>
      <div className={styles.modalButtons}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={isDelete ? ComponentType.SECONDARY : ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
          dataCy={'cancel'}
        />
        <Button
          title={isDelete ? t('button.DELETE') : t('button.SAVE')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          status={isDelete ? ComponentStatus.DANGER : ComponentStatus.DEFAULT}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default CustomFieldModal
