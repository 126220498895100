const ArrowBack = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9526 11.005H7.78262L12.6626 6.12501C13.0526 5.73501 13.0526 5.09501 12.6626 4.70501C12.2726 4.31501 11.6426 4.31501 11.2526 4.70501L4.66262 11.295C4.27262 11.685 4.27262 12.315 4.66262 12.705L11.2526 19.295C11.6426 19.685 12.2726 19.685 12.6626 19.295C13.0526 18.905 13.0526 18.275 12.6626 17.885L7.78262 13.005H18.9526C19.5026 13.005 19.9526 12.555 19.9526 12.005C19.9526 11.455 19.5026 11.005 18.9526 11.005Z"
      fill={color}
    />
  </svg>
)

export default ArrowBack
