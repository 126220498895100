import {
  displayDayDate,
  displayHourDate,
  displayMonthDate,
  formatEndDay,
  formatEndMonth,
  formatEndWeek,
  formatEndYear,
  formatStartDay,
  formatStartMonth,
  formatStartWeek,
  formatStartYear,
} from '../../../utils/date'
import { SelectOptions } from '../../newComponents/dropDown/multiple'
import dayjs from 'dayjs'

dayjs.locale('fr')

const getExplicitDate = (date: string, interval?: string) => {
  if (interval === '2h') {
    const nextDate = new Date(date).getHours() + 2
    return displayHourDate(date)
      .concat(' -> ')
      .concat(nextDate + ' h')
  }
  if (interval === '1M') return displayMonthDate(date)
  if (interval === '1w') return 'Sem du ' + displayDayDate(date)
  return displayDayDate(date)
}

const getShopIDs = (options: Array<SelectOptions>) => {
  const shopIDs = options
    .filter((option) => option.id !== 'all')
    .filter((option) => option.checked)
    .map((option) => option.id)
  return shopIDs
}

const translateInterval = (interval: string) =>
  interval === '2h'
    ? 'day'
    : interval === '1d'
    ? 'week'
    : interval === '1w'
    ? 'month'
    : interval === '1M'
    ? 'year'
    : 'year'

const getDateValues = (interval: string) => {
  const today = new Date()

  const startDate =
    interval === 'day'
      ? formatStartDay(today)
      : interval === 'week'
      ? formatStartWeek(today)
      : interval === 'month'
      ? formatStartMonth(today)
      : interval === 'year'
      ? formatStartYear(today)
      : ''

  const endDate =
    interval === 'day'
      ? formatEndDay(today)
      : interval === 'week'
      ? formatEndWeek(today)
      : interval === 'month'
      ? formatEndMonth(today)
      : interval === 'year'
      ? formatEndYear(today)
      : ''

  const newInterval =
    interval === 'day'
      ? '2h'
      : interval === 'week'
      ? '1d'
      : interval === 'month'
      ? '1w'
      : interval === 'year'
      ? '1M'
      : '1y'

  return { startDate, endDate, newInterval }
}

const getDateValuesDayJs = (interval: string) => {
  const today = dayjs()

  const startDate =
    interval === 'day'
      ? today.startOf('day')
      : interval === 'week'
      ? today.startOf('week')
      : interval === 'month'
      ? today.startOf('month')
      : interval === 'year'
      ? today.startOf('year')
      : ''

  const endDate =
    interval === 'day'
      ? today.endOf('day')
      : interval === 'week'
      ? today.endOf('week')
      : interval === 'month'
      ? today.endOf('month')
      : interval === 'year'
      ? today.endOf('year')
      : ''

  const newInterval =
    interval === 'day'
      ? '2h'
      : interval === 'week'
      ? '1d'
      : interval === 'month'
      ? '1w'
      : interval === 'year'
      ? '1M'
      : '1y'

  return { startDate, endDate, newInterval }
}

export { getExplicitDate, getShopIDs, translateInterval, getDateValues, getDateValuesDayJs }
