import { forwardRef, RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ColorPalette } from '../../../API'
import { ComponentDirection, ComponentSize, ComponentStyle, ComponentType } from '../types'
import { enumToStr, colorList, strToEnum } from '../../../utils/color'
import Button from '../button'
import IconTouch from '../icons/iconTouch'
import Divider from '../divider'
import ImagePicker from '../imagePicker'
import Input from '../input'
import Picker from '../picker'

import { Close } from '../../../assets/icons'
import styles from './ColorPicker.module.css'

/**
 *
 * This element displays the color pop up
 *
 * @usedIn - Form
 *
 * @param color - The current color
 * @param onSelect - A CTA when we select another color
 * @param onBlur - A CTA when we click outside the pop up
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ColorList = forwardRef(
  (
    {
      color,
      iconText,
      resize,
      photo,
      selected,
      onSelect,
      onChange,
      onBlur,
      onBlurInput,
      onUpload,
      displayColorList,
      isIconText,
      isImage,
    }: {
      color?: string
      iconText?: string
      resize?: number
      photo?: any
      selected?: string
      onSelect: (color: string) => void
      onChange: (iconText: string) => void
      onBlur: (event: any) => void
      onBlurInput?: () => void
      onUpload: (photo: any) => void
      displayColorList: () => void
      isIconText: boolean
      isImage: boolean
    },
    ref: any
  ): JSX.Element => {
    const { t } = useTranslation()
    return (
      <div className={styles.containerColorList}>
        <div ref={ref} onBlur={onBlur} tabIndex={0} id="containerColorList" className={styles.containerColorList2}>
          <div className={styles.colorClose}>
            <IconTouch
              Icon={Close}
              color="var(--sys-color-content-secondary)"
              size={ComponentSize.SMALL}
              onClick={displayColorList}
            />
          </div>
          <label className={styles.label}>{t('components.colorPicker.EDIT_COLOR')}</label>
          <div className={styles.colorList}>
            {colorList.map((list) => (
              <Picker
                key={list}
                size={ComponentSize.LARGE}
                color={list}
                // @ts-ignore
                selected={selected === 'color' && color && enumToStr(color) === list}
                onClick={() => onSelect(list)}
              />
            ))}
          </div>
          {isIconText && (
            <>
              <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
              <div className={styles.containerIconText}>
                <div>
                  <label className={styles.label}>{t('components.colorPicker.EDIT_ICON_TEXT')}</label>
                  <div className={styles.iconText}>({t('components.colorPicker.MAX')})</div>
                </div>
                <Input
                  value={iconText}
                  max={4}
                  onChange={onChange}
                  onBlur={onBlurInput}
                  style={{ width: '72px' }}
                  isSelectOnFocus
                  dataCy="text"
                />
              </div>
            </>
          )}
        </div>
        {isImage && (
          <>
            <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
            <ImagePicker
              text={t('components.imagePicker.FORMAT')}
              resize={resize!}
              photo={photo}
              isSelected={selected === 'photo'}
              onChange={onUpload}
            />
          </>
        )}
      </div>
    )
  }
)

/**
 *
 * This element displays ColorPicker component
 *
 * @usedIn - Form
 *
 * @param color - The current color
 * @param iconText - The first letters of the name
 * @param onChange - A CTA when we select another color
 * @param notEditable - A boolean to let edition
 * @param isImage - A boolean to let editing image
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ColorPicker = ({
  Element,
  title,
  color,
  iconText,
  resize,
  photo,
  selected,
  onSelect,
  onChange,
  onBlur,
  onUpload,
  notEditable,
  isImage,
  isIconText,
  dataCy,
}: {
  Element: any
  title?: string
  color?: ColorPalette
  iconText?: string
  resize?: number
  selected?: string
  photo?: any
  onSelect?: (color: string) => void
  onChange?: (iconText: string) => void
  onBlur?: () => void
  onUpload?: (photo: string) => void
  style?: ComponentStyle
  notEditable?: boolean
  isImage: boolean
  isIconText: boolean
  dataCy?: string
}): JSX.Element => {
  const [state, setState] = useState({ display: false })
  const ref: RefObject<any> = useRef()

  useEffect(
    () => {
      if (state.display && ref.current) ref.current.focus()
    },
        [state.display]
  )

  const displayColorList = () => {
    setState({ ...state, display: !state.display })
  }

  const handleBlur = (event: any): void => {
    if (
      !event.relatedTarget ||
      (event.relatedTarget &&
        event.relatedTarget.id !== 'inputID' &&
        event.relatedTarget.id !== 'pickerID' &&
        event.relatedTarget.id !== 'colorPickerButton' &&
        event.relatedTarget.id !== 'containerColorList' &&
        event.relatedTarget.id !== 'imagePickerButton' &&
        event.relatedTarget.id !== 'containerImagePicker' &&
        event.relatedTarget.id !== 'imagePickerDeleteButton')
    ) {
      setState({ ...state, display: false })
    }
  }

  return (
    <div className={styles.container}>
      {Element}
      {!notEditable && (
        <Button
          id={'colorPickerButton'}
          type={ComponentType.TERTIARY}
          size={ComponentSize.SMALL}
          title={title}
          onClick={displayColorList}
        />
      )}
      {state.display && (
        <ColorList
          ref={ref}
          color={color}
          iconText={iconText}
          resize={resize}
          photo={photo}
          onSelect={(event) => onSelect && onSelect(strToEnum(event))}
          onChange={(event) => onChange && onChange(event)}
          onUpload={(event) => {
            ref.current.focus()
            onUpload && onUpload(event)
          }}
          displayColorList={displayColorList}
          onBlur={handleBlur}
          onBlurInput={onBlur}
          selected={selected}
          isIconText={isIconText}
          isImage={isImage}
        />
      )}
    </div>
  )
}

export default ColorPicker
