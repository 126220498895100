import generateDataCy from '../../../utils/cypress'
import styles from './List.module.css'

export type TypeList = Array<any>

type Props = {
  id?: string
  items: TypeList
  minWidth?: string
  dataCy?: string
}

/**
 *
 * This element displays a list of items
 *
 * @param id - Parent id
 * @param items - An array of items to display
 * @param minWidth - An optional min width
 * @param className - ClassName to use
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const List = ({ id, items, minWidth, dataCy }: Props): JSX.Element => (
  <div
    id={id}
    tabIndex={0}
    className={styles.list}
    style={{ minWidth: minWidth ?? 'inherit' }}
    data-cy={generateDataCy({ scope: dataCy ?? '', value: 'dropdownContainer' })}
  >
    {items.map((item) => item)}
  </div>
)

export default List
