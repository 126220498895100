const Switch = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.29677 11.855L3.51677 14.645C3.32677 14.845 3.32677 15.155 3.51677 15.355L6.29677 18.145C6.60677 18.465 7.14677 18.235 7.14677 17.795V15.995H13.1568C13.7068 15.995 14.1568 15.545 14.1568 14.995C14.1568 14.445 13.7068 13.995 13.1568 13.995H7.14677V12.205C7.14677 11.755 6.60677 11.535 6.29677 11.855ZM20.8068 8.64496L18.0268 5.85496C17.7168 5.53496 17.1768 5.76496 17.1768 6.20496V7.99496H11.1568C10.6068 7.99496 10.1568 8.44496 10.1568 8.99496C10.1568 9.54496 10.6068 9.99496 11.1568 9.99496H17.1668V11.785C17.1668 12.235 17.7068 12.455 18.0168 12.135L20.7968 9.34496C20.9968 9.15496 20.9968 8.83496 20.8068 8.64496Z"
      fill={color}
    />
  </svg>
)

export default Switch
