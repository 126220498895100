import { onCreateCustomField, onDeleteCustomField, onUpdateCustomField } from '../../graphql/custom/subscriptions'
import { getCompanyID } from '../../services/localStorage'
import { updateListElement, deleteListElement } from '../../actions/list'
import { objectType } from '../../store/types'
import { getObservable } from '../../services/graphql'
import { getCustomField as get } from '../../graphql/custom/queries'
import { updateCustomFieldCol, createCustomFieldCol, deleteCustomFieldCol } from '../../actions/customField'
import { updateCustomFieldsAssociations } from '../../actions/catalog'
import { GetCustomFieldQueryVariables } from '../../API'
import { callService } from '../../services'
import { addElementCompanySettings, deleteElementCompanySettings } from '../../actions/company'

const getCustomField = async (id: string, dispatch: any) => {
  const customField = await callService<GetCustomFieldQueryVariables>({ id }, get, 'getCustomField')

  if (customField.data) {
    updateListElement({
      element: customField.data,
      dispatch,
      type: objectType.CUSTOM_FIELD,
    })
  }
  return customField
}

let onCreateCustomFieldSubscription: any
let onDeleteCustomFieldSubscription: any
let onUpdateCustomFieldSubscription: any

const subscribeCustomFields = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreateCustomFieldSubscription)
    onCreateCustomFieldSubscription = getObservable(onCreateCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateCustomField) {
          updateListElement({
            element: eventData.data.onCreateCustomField,
            dispatch,
            type: objectType.CUSTOM_FIELD,
          })
          createCustomFieldCol(eventData.data.onCreateCustomField, dispatch)
          addElementCompanySettings(
            { id: eventData.data.onCreateCustomField.id, type: eventData.data.onCreateCustomField.objectType },
            dispatch
          )
        }
      },
    })
  if (!onDeleteCustomFieldSubscription)
    onDeleteCustomFieldSubscription = getObservable(onDeleteCustomField, input).subscribe({
      next: (eventData: any) => {
        deleteListElement({
          element: eventData.data.onDeleteCustomField,
          dispatch,
          type: objectType.CUSTOM_FIELD,
        })
        deleteCustomFieldCol(
          eventData.data.onDeleteCustomField.id,
          eventData.data.onDeleteCustomField.objectType,
          dispatch
        )
        deleteElementCompanySettings(
          { id: eventData.data.onDeleteCustomField.id, type: eventData.data.onDeleteCustomField.objectType },
          dispatch
        )
      },
    })
  if (!onUpdateCustomFieldSubscription)
    onUpdateCustomFieldSubscription = getObservable(onUpdateCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateCustomField) {
          updateListElement({
            element: eventData.data.onUpdateCustomField,
            dispatch,
            type: objectType.CUSTOM_FIELD,
          })
          updateCustomFieldCol(eventData.data.onUpdateCustomField, dispatch)
          updateCustomFieldsAssociations({ customFieldID: eventData.data.onUpdateCustomField.id, dispatch }, dispatch)
        }
      },
    })
}

const unSubscribeCustomFields = () => {
  if (onCreateCustomFieldSubscription) {
    onCreateCustomFieldSubscription.unsubscribe()
    onCreateCustomFieldSubscription = null
  }

  if (onDeleteCustomFieldSubscription) {
    onDeleteCustomFieldSubscription.unsubscribe()
    onDeleteCustomFieldSubscription = null
  }

  if (onUpdateCustomFieldSubscription) {
    onUpdateCustomFieldSubscription.unsubscribe()
    onUpdateCustomFieldSubscription = null
  }
}

export { subscribeCustomFields, unSubscribeCustomFields, getCustomField }
