import { DataWithErrors } from '../../services/types';
import { deleteListElement, updateListElement } from '../../actions/list';
import { objectType } from '../../store/types';
import { getSale as get, getSaleInfo } from '../../graphql/custom/queries';
import { getObservable } from '../../services/graphql';
import { getCompanyID } from '../../services/localStorage';
import { onCreateSale, onDeleteSale, onUpdateSale } from '../../graphql/custom/subscriptions';
import { callService } from '../../services';
import { GetSaleQueryVariables } from '../../API';

const getSale = async (id: string, dispatch: any): Promise<DataWithErrors> => {
  const sale = await callService<GetSaleQueryVariables>({ id }, get, 'getSale');

  if (sale.data) {
    updateListElement({
      element: sale.data,
      dispatch,
      type: objectType.SALE,
    });
  }
  return sale;
};

const getSaleInfos = async (id: string): Promise<DataWithErrors> => {
  const sale = await callService<GetSaleQueryVariables>({ id }, getSaleInfo, 'getSale');
  return sale;
};

let onCreateSaleSubscription: any;
let onDeleteSaleSubscription: any;
let onUpdateSaleSubscription: any;

const subscribeSales = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
  };
  if (!onCreateSaleSubscription)
    onCreateSaleSubscription = getObservable(onCreateSale, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateSale)
          updateListElement({
            element: eventData.data.onCreateSale,
            dispatch,
            type: objectType.SALE,
          });
      },
    });
  if (!onDeleteSaleSubscription)
    onDeleteSaleSubscription = getObservable(onDeleteSale, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeleteSale,
          dispatch,
          type: objectType.SALE,
        }),
    });
  if (!onUpdateSaleSubscription)
    onUpdateSaleSubscription = getObservable(onUpdateSale, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateSale)
          updateListElement({
            element: eventData.data.onUpdateSale,
            dispatch,
            type: objectType.SALE,
          });
      },
    });
};

const unSubscribeSales = () => {
  if (onCreateSaleSubscription) {
    onCreateSaleSubscription.unsubscribe();
    onCreateSaleSubscription = null;
  }

  if (onDeleteSaleSubscription) {
    onDeleteSaleSubscription.unsubscribe();
    onDeleteSaleSubscription = null;
  }

  if (onUpdateSaleSubscription) {
    onUpdateSaleSubscription.unsubscribe();
    onUpdateSaleSubscription = null;
  }
};

export { subscribeSales, unSubscribeSales, getSale, getSaleInfos };
