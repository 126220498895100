const DragHandle = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1614 9H5.16138C4.61138 9 4.16138 9.45 4.16138 10C4.16138 10.55 4.61138 11 5.16138 11H19.1614C19.7114 11 20.1614 10.55 20.1614 10C20.1614 9.45 19.7114 9 19.1614 9ZM5.16138 15H19.1614C19.7114 15 20.1614 14.55 20.1614 14C20.1614 13.45 19.7114 13 19.1614 13H5.16138C4.61138 13 4.16138 13.45 4.16138 14C4.16138 14.55 4.61138 15 5.16138 15Z"
      fill={color}
    />
  </svg>
)

export default DragHandle
