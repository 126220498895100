import { useState } from 'react'

import generateDataCy from '../../../utils/cypress'

import stylesDrawer from '../drawer/Drawer.module.css'
import styles from './List.module.css'

/**
 *
 * Sometimes we might need to show a text from the image when we hover it
 * IconLeft does this job
 *
 * @usedIn - Drawer for Activity views
 *
 * @param props - An object which contains informations from the icon
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const IconLeft = (props: any): JSX.Element => {
  const [state, setState] = useState({
    displayHover: false,
  })

  const displayHoverred = () => setState({ ...state, displayHover: !state.displayHover })

  return (
    <div style={{ position: 'relative' }}>
      {state.displayHover && (
        <div className={styles.iconHoverLeft}>
          {props.children.hoverText}
          <span className={styles.arrowIconLeft} />
        </div>
      )}
      <div
        onMouseEnter={() => props.children.icon && props.children.hoverText && displayHoverred()}
        onMouseLeave={() => props.children.icon && props.children.hoverText && displayHoverred()}
      >
        {props.children.icon}
      </div>
    </div>
  )
}

/**
 *
 * This element displays every row from the List component above
 *
 * @usedIn - Drawer for Activity views
 *
 * @param props - The data object to display
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Row = (props: any): JSX.Element => {
  const [state, setState] = useState({
    displayDrawer: false,
  })

  /**
   *
   * This method has multiple possibilities, it may either :
   * - Change the current state to display an other drawer below the actual row
   * - Execute the CTA action from the variable "bulkToAction"
   * - Does nothing if not necessary
   *
   * @usedIn - Drawer for Activity views
   *
   * @param props - The data object
   *
   * @returns - The action to execute
   *
   * @author - Jennifer Charlois
   *
   */
  const bulkToAction = () => {
    if (props.children.action) {
      return props.children.action && props.children.action.drawer
        ? setState({ ...state, displayDrawer: !state.displayDrawer })
        : props.children.action.bulkToAction
          ? props.children.action.bulkToAction()
          : () => { }
    }
  }
  // const cyContext = generateDataCy({ scope: 'list', value: 'row' })

  return (
    <>
      <div
        className={styles.rowContainer}
        style={{
          cursor:
            props.children.action && (props.children.action.bulkToAction || props.children.action.drawer)
              ? 'pointer'
              : 'default',
        }}
        onClick={bulkToAction}
        data-cy={generateDataCy({
          scope: props.dataCy,
          value: generateDataCy({ scope: props.children.id, value: 'action' }),
        })}
      >
        <div
          className={styles.leftPart}
          data-cy={generateDataCy({
            scope: props.dataCy,
            value: props.children.id,
          })}
        >
          {props.children.icon && <IconLeft>{props.children.icon}</IconLeft>}
          {props.children.title}
        </div>
        <div className={styles.rightPart}>
          <div
            data-cy={generateDataCy({
              scope: props.dataCy,
              value: props.children.id,
            })}
          >
            {props.children.info}
          </div>
          {props.children.action && (
            <div style={{ margin: '0 10px', cursor: 'pointer' }}>{props.children.action.icon}</div>
          )}
          {props.children.menu && props.children.menu}
        </div>
      </div>
      {state.displayDrawer &&
        props.children.action &&
        props.children.action.drawer &&
        props.children.action.drawer.map((drawer: any) => <Row>{drawer}</Row>)}
    </>
  )
}

/**
 *
 * This element displays a list of datas with a specific design
 *
 * @usedIn - Drawer for Activity views
 *
 * @param props - An object which contains the title and the object datas to display
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const List = ({
  title,
  datas,
  dataCy,
}: {
  title?: string
  datas: Array<{
    icon?: { icon: JSX.Element; hoverText?: string }
    title?: JSX.Element
    info?: JSX.Element
    action?: { icon: JSX.Element; bulkToAction: () => void } | { icon: JSX.Element; drawer: Array<any> }
  }>
  dataCy: string
}): JSX.Element => (
  <>
    {title && <div className={styles.title}>{title.toUpperCase()}</div>}
    <div className={styles.listContainer}>
      {datas.map((data, index) => (
        <span key={index} data-cy={generateDataCy({ scope: dataCy, value: index.toString() })}>
          <Row dataCy={dataCy}>{data}</Row>
          {index < datas.length - 1 && <div className={stylesDrawer.divider} />}
        </span>
      ))}
    </div>
  </>
)

export { List, Row, IconLeft }
