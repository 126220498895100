import IconTouch from '../icons/iconTouch'
import { ComponentSize } from '../types'

import styles from './ListItem.module.css'

type Props = {
  item?: string
  trailIcons?: Array<any>
  onClick?: (event?: any) => void
  maxWidth?: string
  dataCy?: string
}

/**
 *
 * This element displays an item
 *
 * @param item - Item to display
 * @param state - Item's state
 * @param LeadIcon - A lead icon to display
 * @param trailIcons - An array of trail icons to display
 * @param onClick - A CTA when we click on an item
 * @param dataCy - An unique id
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ListItemHeader = ({ item, trailIcons, onClick, dataCy, maxWidth }: Props): JSX.Element => {
  const getStyle = () => ({
    color: 'var(--comp-list-item-color-foreground-text)',
    borderBottom: '1px solid var(--sys-color-border-default)',
    maxWidth: maxWidth ?? '',
    maxHeight: maxWidth ?? '',
    justifyContent: maxWidth ? 'center' : '',
  })

  return (
    <div className={styles.listItemHeader} style={getStyle()} data-cy={dataCy}>
      {item && item}
      {trailIcons &&
        trailIcons.length > 0 &&
        trailIcons &&
        trailIcons
          .filter((_) => _)
          .map((TrailIcon, index) => (
            <IconTouch
              key={index}
              Icon={TrailIcon}
              color={`var(--comp-list-item-color-foreground-icon)`}
              size={ComponentSize.MEDIUM}
              onClick={() => {
                if (onClick) item ? onClick(item) : onClick()
              }}
            />
          ))}
    </div>
  )
}

export default ListItemHeader
