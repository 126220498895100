export enum TypePath {
  PLAIN = 'PLAIN',
  OPERATOR = 'OPERATOR',
  CUSTOM = 'CUSTOM',
}

export enum TypeFilter {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  LIST = 'LIST',
}

export enum TypeComparator {
  EQ = 'eq',
  NE = 'ne',
  EXISTS = 'exists',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does_not_contain',
  STARTS_WITH = 'starts_with',
  GT = 'gt',
  LT = 'lt',
  OR = 'or',
  AND = 'and',
}

export type TypeRequesterFilters = {
  type: TypeFilter;
  list?: Array<any>;
  comparator: TypeComparator;
};

/*
  type: Filter's type, may be an operator or a field who needs transformer
  filterID: Field to translate with i18n
  field: Operator or field
  section: Use for front use-case, display a section
  path: Necessary path to match graphQL model
  fields: itself
  filters: Filter's array used for each field
  comparator: Filter's comparator
  value: Input's value
  transformer: A method who format a value for API
*/
export type TypeRequester = {
  type: TypePath;
  filterID: string;
  field: string; // OPERATOR ? -> and, or, not; sinon -> nom de champ
  section?: boolean;
  path: Array<string>;
  fields?: Array<TypeRequester>;
  filters: Array<TypeRequesterFilters>;
  comparator?: TypeComparator | null;
  value?: string | number | boolean | null;
  transformer?: (requester: TypeRequester, currencyDecimals: number) => void;
};
