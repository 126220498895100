import DisplayIcon2 from '../icons'
import { ComponentSize } from '../types'

import styles from './IconPicker.module.css'

const IconPicker = ({ Icon, color, size }: { Icon: any; color: string; size: ComponentSize }) => (
  <div
    className={styles.container}
    style={{
      width: `var(--comp-picker-size-${size})`,
      height: `var(--comp-picker-size-${size})`,
    }}
  >
    <DisplayIcon2
      Icon={Icon}
      height={`var(--comp-picker-size-icon-${size})`}
      width={`var(--comp-picker-size-icon-${size})`}
      color={color}
    />
  </div>
)

export default IconPicker
