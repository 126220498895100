import { switchCompany } from '../../actions/company'
import { updateModal } from '../../actions/modal'
import { MoreHoriz } from '../../assets/icons'
import { DropDownCell } from '../../components/list/cell'
import IconTouch from '../../components/newComponents/icons/iconTouch'
import { ComponentState } from '../../components/newComponents/types'
import { getCompanyID } from '../../services/localStorage'
import { userRoleEnumToStr } from '../../utils/typeToType'
import CompanyModal from '../../views/account/companies/modal'
import { TypeCol, TypeColumnConfiguration } from '../types'

const COMPANY = 'company'

export const companyCols: Array<TypeCol> = [
  {
    id: 'name',
    title: 'NAME',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value }) => value.name,
  },
  {
    id: 'address',
    title: 'ADDRESS',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value }) => value.address,
  },
  {
    id: 'country',
    title: 'COUNTRY',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value }) => value.country,
  },
  {
    id: 'subscription',
    title: 'SUBSCRIPTION',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value }) => value.subscription,
  },
  {
    id: 'role',
    title: 'ROLE',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value }) => userRoleEnumToStr(value.role),
  },
  {
    id: 'more',
    title: '',
    type: 'element',
    active: true,
    function: ({ value, t, index, dispatch, isMobileView, navigate }: any) =>
      DropDownCell({
        fieldID: 'more',
        index,
        Element: IconTouch({
          Icon: MoreHoriz,
          // state: getCompanyID() === value.id ? ComponentState.DISABLED : ComponentState.DEFAULT,
          state: ComponentState.DEFAULT,
          color: 'var(--sys-color-content-secondary)',
        }),
        state: ComponentState.DEFAULT,
        items: [
          {
            id: 'edit',
            value: t('account.companies.EDIT'),
            onClick: () => {
              updateModal(
                dispatch,
                true,
                () => CompanyModal({ navigate, value }),
                () => {}
              )
            },
          },
          {
            id: value.id,
            value: t('account.companies.GO_DASHBOARD'),
            onClick: () => {
              switchCompany(value.id, t, isMobileView, dispatch)
              navigate('/statistic/general')
            },
          },
        ],
      }),
  },
]

export const companiesColsConf: TypeColumnConfiguration[] = [
  {
    id: 'getAccount',
    type: COMPANY,
    cols: companyCols,
    creatable: true,
    editable: false,
    exportable: false,
    clickable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
    creatablePlaceholder: 'account.companies.NEW',
  },
]
