import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { login } from '../../services/auth'
import { findErrorsInState } from '../../format/errors'
import { TypeFormError, ValidationFunction } from '../../format/errors/types'
import { DashboardView, DrawerState } from '../../store/types'
import { email as mailValidator, password as passwordValidator } from '../../format/errors/filters/connection'
import { getIcon } from '../../components/newComponents/icon'
import generateDataCy from '../../utils/cypress'
import { updateDrawerState } from '../../actions/drawer'
import Input from '../../components/newComponents/input'
import { handleBlur, handleChange } from '../../format/form'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../components/newComponents/types'
import { getDashboardView } from '../../store/selector'
import { EyeHide, EyeShow } from '../../assets/icons'
import LogoTactill from '../../components/newComponents/logoTactill'
import TextLink from '../../components/newComponents/textLink'
import Button from '../../components/newComponents/button'
import ErrorLabel from '../../components/oldComponents/error'

import styles from './Connection.module.css'
import stylesApp from '../../App.module.css'

type State = {
  email: string
  password: string
  passwordType: string
  showSubmit: boolean
  validators: {
    email?: ValidationFunction
    password?: ValidationFunction
  }
  errors: {
    global?: TypeFormError
    email?: TypeFormError
    password?: TypeFormError
  }
}

const Login = () => {
  const initialState: State = {
    email: '',
    password: '',
    passwordType: 'password',
    showSubmit: false,
    validators: {
      email: mailValidator,
      password: passwordValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const dashboardView = useSelector(getDashboardView)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const cyContext = generateDataCy({ scope: 'connection', value: 'login' })

  const handleClickIcon = () => {
    setState({
      ...state,
      passwordType: state.passwordType === 'password' ? 'text' : 'password',
    })
  }

  const handleSubmit = () => {
    state.showSubmit = false
    setState(handleBlur(state, 'email'))
    setState(handleBlur(state, 'password'))

    if (!findErrorsInState(state.errors) && state.email && state.password) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      login({ email: state.email, password: state.password }).catch((error) => {
        updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
        setState({
          ...state,
          showSubmit: false,
          errors: {
            global: {
              value: true,
              message:
                error.description === 'Wrong email or password.'
                  ? t('connection.login.ERROR')
                  : t('connection.login.IMPOSSIBLE'),
            },
          },
        })
      })
    }
  }

  return (
    <div className={stylesApp.grid} style={{ alignItems: dashboardView !== DashboardView.MOBILE ? 'center' : '' }}>
      <div
        className={styles.containerForm}
        style={{
          gridColumn:
            dashboardView === DashboardView.DESKTOP
              ? '5 / span 4'
              : dashboardView === DashboardView.TABLET
              ? '3 / span 2'
              : '1 / span 4',
        }}
      >
        <LogoTactill type={ComponentType.LOGOICONTEXT} dataCy={generateDataCy({ scope: cyContext, value: 'logo' })} />
        <div
          className={styles.middleArea}
          style={{
            marginBottom:
              dashboardView === DashboardView.MOBILE ? '0' : dashboardView === DashboardView.DESKTOP ? '4em' : '2em',
          }}
        >
          <div>
            <div className={styles.title}>{t('connection.WELCOME')}</div>
            <div className={styles.subTitle}>{t('connection.login.GET_STARTED')}</div>
          </div>
          <div className={styles.form}>
            <Input
              label={t('connection.login.MAIL')}
              value={state.email}
              onChange={(event) => setState(handleChange(state, 'email')(event))}
              onBlur={() => setState(handleBlur(state, 'email'))}
              onSubmit={handleSubmit}
              style={{
                status: state.errors?.email?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              }}
              helperText={state.errors.email?.value ? state.errors.email.message : undefined}
              dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
            />
            <Input
              label={t('connection.login.PASSWORD')}
              type={state.passwordType}
              value={state.password}
              onChange={(event) => setState(handleChange(state, 'password')(event))}
              onBlur={() => setState(handleBlur(state, 'password'))}
              onSubmit={handleSubmit}
              style={{
                status: state.errors?.password?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                trailIcons: getIcon({
                  icon: state.passwordType === 'password' ? EyeHide : EyeShow,
                  onClick: handleClickIcon,
                }),
              }}
              helperText={state.errors.password?.value ? state.errors.password.message : undefined}
              dataCy={generateDataCy({ scope: cyContext, value: 'password' })}
            />
            {state.errors?.global && (
              <ErrorLabel
                dataCy={generateDataCy({ scope: cyContext, value: 'errorMessage' })}
                text={state.errors.global.message}
              />
            )}
          </div>
          <div
            className={styles.submitButton}
            style={{ width: dashboardView !== DashboardView.MOBILE ? '128px' : '100%' }}
          >
            <Button
              title={t('button.CONNECT')}
              size={ComponentSize.LARGE}
              type={ComponentType.PRIMARY}
              state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
              onClick={handleSubmit}
              dataCy={generateDataCy({ scope: cyContext, value: 'submit' })}
            />
          </div>
        </div>
        <div className={styles.bottomArea}>
          <TextLink
            text={t('connection.login.FORGOT_PASSWORD')}
            handleClick={() => navigate('/forgotPassword')}
            size={ComponentSize.SMALL}
            dataCy={generateDataCy({ scope: cyContext, value: 'resetPwd' })}
          />
          <TextLink
            text={t('connection.login.CREATE_ACCOUNT')}
            handleClick={() => navigate('/signUp')}
            size={ComponentSize.SMALL}
            dataCy={generateDataCy({ scope: cyContext, value: 'signup' })}
          />
        </div>
      </div>
    </div>
  )
}

export default Login
