import { DrawerState, MenuState, objectType } from '../../store/types';
import {
  addNewLinetoInventoryMovement,
  addVariantToInventoryMovement,
  unSelectAllList,
  updateBuyPriceList,
  updateList,
  updateListElement,
  updateProductInventoryQuantities,
} from '../../actions/list';
import {
  InventoryMovementState,
  GetInventoryStatsQueryVariables,
  CancelInventoryMovementInput,
  UpdateInventoryMovementInput,
  InventoryMovementDestination,
  InventoryMovementOrigin,
  CreateInventoryMovementInput,
  UpdateInventoryMovementMutationVariables,
} from '../../API';
import { DataWithErrors } from '../../services/types';
import { closeAndResetModal, updateModal } from '../../actions/modal';
import { clearAllFilters } from '../../actions/requester';
import { changeConf } from '../../actions/cols';
import ErrorModal from '../../components/newComponents/modal/error';
import { updateDrawerState } from '../../actions/drawer';
import { callService } from '../../services';
import { getCompanyID, getTestMode } from '../../services/localStorage';
import {
  createInventoryMovement as createSM,
  updateInventoryMovement as updateSM,
  cancelInventoryMovement as cancelSM,
} from '../../graphql/custom/mutations';
import { getInventoryStats as getIS } from '../../graphql/queries';
import {
  getInventoryMovement as getIM,
  getInventoryMovementVariant as getIMV,
  getInventoryMovementAllVariants as getAllIMV,
} from '../../graphql/custom/queries';
import { getObservable } from '../../services/graphql';
import {
  onCancelDestinationInventoryMovement,
  onCancelOriginInventoryMovement,
  onCreateDestinationInventoryMovement,
  onCreateOriginInventoryMovement,
  onUpdateProductInventoryQuantity,
  onUpdateDestinationInventoryMovement,
  onUpdateOriginInventoryMovement,
} from '../../graphql/custom/subscriptions';
import toggleMenu from '../../actions/menu';
import { convertPrice } from '../../utils/number';
import { pluralize } from '../../utils/typeToType';
import { CreateInventoryMovementMutationVariables, ModelInventoryMovementLineProductConnection } from '../../API';

const createInventoryVariants = (element: ModelInventoryMovementLineProductConnection) => {
  const newItems = element.items.map((variant: any) => ({
    id: variant.variantID,
    updatedAt: variant.updatedAt,
    createdAt: variant.createdAt,
    inventoryMovementID: variant.inventoryMovementID,
    inventoryQuantities: variant.inventoryQuantities,
    productID: variant.productID,
    variantID: variant.variantID,
    barcode: variant.details.barcode,
    name: variant.details.nameShort,
    reference: variant.details.reference,
    quantity: variant.quantity,
    price: variant.price,
    selected: true,
  }));

  return {
    items: newItems,
    nextToken: element.nextToken,
  };
};

const createInventoryAllVariants = (element: ModelInventoryMovementLineProductConnection) => {
  const newItems = element.items.map((variant: any) => {
    const { productID, variantID, details, line, inventoryQuantities } = variant;

    const { barcode, buyPrice, nameShort, reference } = details || {};

    if (line) {
      return {
        id: `${productID}_${variantID}`,
        productID,
        variantID,
        price: line.price,
        barcode,
        name: nameShort,
        reference,
        quantity: line.quantity,
        inventoryQuantities: line.inventoryQuantities,
        selected: true,
      };
    } else {
      return {
        id: `${productID}_${variantID}`,
        productID,
        variantID,
        price: buyPrice,
        barcode,
        name: nameShort,
        inventoryQuantities,
        reference,
        quantity: null,
        selected: true,
      };
    }
  });
  return {
    items: newItems,
    nextToken: element.nextToken,
  };
};

const createInventoryItems = (element: ModelInventoryMovementLineProductConnection) => {
  //@ts-ignore
  const newItems = element.items.map((item: InventoryMovementLineProduct) => {
    const {
      productID,
      price,
      quantity,
      details,
      updatedAt,
      createdAt,
      inventoryMovementID,
      inventoryQuantities,
      id,
      variantsCount,
      variantsCountAll,
      variantsSelectedAll,
      hasVariants,
    } = item;
    const { barcode, category, name, reference } = details || {};

    let subValues = [];

    if (variantsSelectedAll) {
      subValues = item.variantsAll.items.map((variant: any) => {
        if (variant.line) {
          return {
            id: variant.variantID,
            updatedAt: null,
            createdAt: null,
            inventoryMovementID: variant.line.inventoryMovementID,
            inventoryQuantities: variant.line.inventoryQuantities,
            productID,
            variantID: variant.variantID,
            barcode: variant.line.details.barcode,
            name: variant.line.details.nameShort,
            reference: variant.line.details.reference,
            quantity: variant.line.quantity,
            price: variant.line.price,
            selected: true,
          };
        } else {
          return {
            id: variant.variantID,
            updatedAt: null,
            createdAt: null,
            inventoryMovementID,
            inventoryQuantities: variant.inventoryQuantities,
            productID,
            variantID: variant.variantID,
            barcode: variant.details.barcode,
            name: variant.details.nameShort,
            reference: variant.details.reference,
            quantity: null,
            price: variant.details.buyPrice,
            selected: true,
          };
        }
      });
    } else {
      subValues = item.variants?.items?.map((variant: any) => ({
        id: variant.variantID,
        updatedAt: variant.updatedAt,
        createdAt: variant.createdAt,
        inventoryMovementID: variant.inventoryMovementID,
        inventoryQuantities: variant.inventoryQuantities,
        productID,
        variantID: variant.variantID,
        barcode: variant.details.barcode,
        name: variant.details.nameShort,
        reference: variant.details.reference,
        quantity: variant.quantity,
        price: variant.price,
        selected: true,
      }));
    }
    return {
      id,
      updatedAt,
      createdAt,
      inventoryMovementID,
      inventoryQuantities,
      productID,
      price,
      quantity,
      barcode,
      category: category?.name,
      name,
      reference,
      subvalueNextToken: variantsSelectedAll ? item.variantsAll.nextToken : item.variants?.nextToken,
      subValues,
      variantsCount,
      variantsCountAll,
      variantsSelectedAll,
      hasVariants,
      selected: true,
    };
  });

  return {
    items: newItems,
    nextToken: element.nextToken,
  };
};

type GetInventoryMovementQueryVariablesCustom = {
  id: string;
  nextToken?: string | null;
};

const getInventoryMovement = async (id: string, dispatch: any, nextToken?: string | undefined) => {
  const inventoryMovement = await callService<GetInventoryMovementQueryVariablesCustom>(
    { id, nextToken },
    getIM,
    'getInventoryMovement',
  );

  if (inventoryMovement.data) {
    updateListElement({ element: inventoryMovement.data, dispatch, type: objectType.INVENTORY_MOVEMENT });
  }
  return inventoryMovement;
};

const getInventoryMovementVariant = async (id: string, dispatch: any, nextToken?: string | undefined) => {
  const inventoryVariant = await callService<GetInventoryMovementQueryVariablesCustom>(
    { id, nextToken },
    getIMV,
    'getInventoryMovementLineProduct',
  );

  if (inventoryVariant.data) {
    const subValues = createInventoryVariants(inventoryVariant.data.variants);
    addVariantToInventoryMovement({ element: subValues, id, dispatch, type: objectType.INVENTORY_MOVE_PRODUCT });
  }
};

const getInventoryMovementAllVariants = async (id: string, dispatch: any, nextToken?: string | undefined) => {
  const inventoryVariant = await callService<GetInventoryMovementQueryVariablesCustom>(
    { id, nextToken },
    getAllIMV,
    'getInventoryMovementLineProduct',
  );

  if (inventoryVariant.data) {
    const subValues = createInventoryAllVariants(inventoryVariant.data.variantsAll);
    addVariantToInventoryMovement({ element: subValues, id, dispatch, type: objectType.INVENTORY_MOVE_PRODUCT });
    // addNewLinetoInventoryMovement({
    //   element: inventoryVariant.data,
    //   id,
    //   dispatch,
    //   type: objectType.INVENTORY_MOVEMENT,
    // });
  }
};

let onCreateOriginInventoryMovementSubscriptions: Array<any> = [];
let onUpdateOriginInventoryMovementSubscriptions: Array<any> = [];
let onCreateDestinationInventoryMovementSubscriptions: Array<any> = [];
let onUpdateDestinationInventoryMovementSubscriptions: Array<any> = [];
let onCancelOriginInventoryMovementSubscriptions: Array<any> = [];
let onCancelDestinationInventoryMovementSubscriptions: Array<any> = [];
let onUpdateProductInventoryQuantitySubscriptions: Array<any> = [];

const subscribeInventoryMovements = (shops: Array<any>, dispatch: any) => {
  shops.forEach((shop, index) => {
    onCreateOriginInventoryMovementSubscriptions[index] = getObservable(onCreateOriginInventoryMovement, {
      originID: shop.id,
      test: getTestMode(),
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateOriginInventoryMovement) {
          updateListElement({
            dispatch,
            element: eventData.data.onCreateOriginInventoryMovement,
            type: objectType.INVENTORY_MOVEMENT,
          });
        }
      },
    });
    onUpdateOriginInventoryMovementSubscriptions[index] = getObservable(onUpdateOriginInventoryMovement, {
      originID: shop.id,
      test: getTestMode(),
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateOriginInventoryMovement) {
          updateListElement({
            dispatch,
            element: eventData.data.onUpdateOriginInventoryMovement,
            type: objectType.INVENTORY_MOVEMENT,
          });
        }
      },
    });
    onCreateDestinationInventoryMovementSubscriptions[index] = getObservable(onCreateDestinationInventoryMovement, {
      destinationID: shop.id,
      test: getTestMode(),
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateDestinationInventoryMovement) {
          updateListElement({
            dispatch,
            element: eventData.data.onCreateDestinationInventoryMovement,
            type: objectType.INVENTORY_MOVEMENT,
          });
        }
      },
    });
    onUpdateDestinationInventoryMovementSubscriptions[index] = getObservable(onUpdateDestinationInventoryMovement, {
      destinationID: shop.id,
      test: getTestMode(),
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateDestinationInventoryMovement) {
          updateListElement({
            dispatch,
            element: eventData.data.onUpdateDestinationInventoryMovement,
            type: objectType.INVENTORY_MOVEMENT,
          });
        }
      },
    });
    onCancelOriginInventoryMovementSubscriptions[index] = getObservable(onCancelOriginInventoryMovement, {
      test: getTestMode(),
      originID: shop.id,
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCancelOriginInventoryMovement) {
          updateListElement({
            dispatch,
            element: eventData.data.onCancelOriginInventoryMovement,
            type: objectType.INVENTORY_MOVEMENT,
          });
        }
      },
    });
    onCancelDestinationInventoryMovementSubscriptions[index] = getObservable(onCancelDestinationInventoryMovement, {
      test: getTestMode(),
      destinationID: shop.id,
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCancelDestinationInventoryMovement) {
          updateListElement({
            dispatch,
            element: eventData.data.onCancelDestinationInventoryMovement,
            type: objectType.INVENTORY_MOVEMENT,
          });
        }
      },
    });
    onUpdateProductInventoryQuantitySubscriptions[index] = getObservable(onUpdateProductInventoryQuantity, {
      shopID: shop.id,
      test: getTestMode(),
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateProductInventoryQuantity) {
          updateProductInventoryQuantities(eventData.data.onUpdateProductInventoryQuantity, dispatch);
        }
        // getProduct(eventData.data.onUpdateProductInventoryQuantity.productID, dispatch)
      },
    });
  });
};

const unSubscribeInventoryMovements = () => {
  if (onCreateOriginInventoryMovementSubscriptions) {
    onCreateOriginInventoryMovementSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
      subscription = null;
    });
    onCreateOriginInventoryMovementSubscriptions = [];
  }
  if (onUpdateOriginInventoryMovementSubscriptions) {
    onUpdateOriginInventoryMovementSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
      subscription = null;
    });
    onUpdateOriginInventoryMovementSubscriptions = [];
  }
  if (onCreateDestinationInventoryMovementSubscriptions) {
    onCreateDestinationInventoryMovementSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
      subscription = null;
    });
    onCreateDestinationInventoryMovementSubscriptions = [];
  }
  if (onUpdateDestinationInventoryMovementSubscriptions) {
    onUpdateDestinationInventoryMovementSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
      subscription = null;
    });
    onUpdateDestinationInventoryMovementSubscriptions = [];
  }
  if (onCancelOriginInventoryMovementSubscriptions) {
    onCancelOriginInventoryMovementSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
      subscription = null;
    });
    onCancelOriginInventoryMovementSubscriptions = [];
  }
  if (onCancelDestinationInventoryMovementSubscriptions) {
    onCancelDestinationInventoryMovementSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
      subscription = null;
    });
    onCancelDestinationInventoryMovementSubscriptions = [];
  }
  if (onUpdateProductInventoryQuantitySubscriptions) {
    onUpdateProductInventoryQuantitySubscriptions.forEach(subscription => {
      subscription.unsubscribe();
      subscription = null;
    });
    onUpdateProductInventoryQuantitySubscriptions = [];
  }
};

const createInventoryMovement = async (
  createInput: CreateInventoryMovementInput,
  dispatch: any,
  updateBuyPrice?: boolean,
  movementType?: string | null,
  items?: any,
): Promise<DataWithErrors> => {
  const inventoryMovement = await callService<CreateInventoryMovementMutationVariables>(
    { input: createInput, updatePrices: updateBuyPrice, test: false },
    createSM,
    'createInventoryMovement',
  );

  if (inventoryMovement.data) {
    updateListElement({
      element: inventoryMovement.data,
      dispatch,
      type: objectType.INVENTORY_MOVEMENT,
    });
  }
  return inventoryMovement;
};

const updateInventoryMovement = async (
  updateInput: UpdateInventoryMovementInput,
  dispatch: any,
  updateBuyPrice?: boolean,
): Promise<DataWithErrors> => {
  const inventoryMovement = await callService<UpdateInventoryMovementMutationVariables>(
    { input: updateInput, updatePrices: updateBuyPrice },
    updateSM,
    'updateInventoryMovement',
  );

  if (inventoryMovement.data) {
    updateListElement({
      element: inventoryMovement.data,
      dispatch,
      type: objectType.INVENTORY_MOVEMENT,
    });
  }
  return inventoryMovement;
};

const cancelInventoryMovement = async (input: CancelInventoryMovementInput, dispatch: any): Promise<DataWithErrors> => {
  const inventoryMovement = await callService<{ input: CancelInventoryMovementInput }>(
    { input },
    cancelSM,
    'cancelInventoryMovement',
  );

  if (inventoryMovement.data) {
    updateListElement({
      element: inventoryMovement.data,
      dispatch,
      type: objectType.INVENTORY_MOVEMENT,
    });
  }
  return inventoryMovement;
};

const getInventoryStats = async (input: GetInventoryStatsQueryVariables): Promise<DataWithErrors> =>
  callService<GetInventoryStatsQueryVariables>(input, getIS, 'getInventoryStats');

const createVariantMovements = (
  productID: string,
  variantID: string,
  editValues: { transit?: number | null; buyPrice?: number | null },
) => ({
  productID,
  variantID,
  quantity: editValues.transit,
  price: editValues.buyPrice,
});

/**
 *
 * This method builds a necessary object for the API based of informations coming from
 * the inventory movement list. We start by creating an object with null values, then, there is two cases :
 * - If the cell got edited, we return the editValues value related to the variant
 * - If not and if a previous value existed, we return it
 *
 * @param editValues - Variable used while editing the list
 * @param variant - Actual variant
 *
 * @returns - An object of transit and price
 *
 * @author - Jennifer Charlois
 *
 */
export const getVariantValues = (editValues: any, variant: any, decimals: number) => {
  if (!editValues || !editValues.subValues || !editValues.subValues[variant.id]) {
    return null
  }

  const editValue = editValues.subValues[variant.id];
  const newValues = { transit: null, buyPrice: null };

  if (editValue.transit !== undefined) newValues.transit = editValue.transit;

  // @ts-ignore
  if (editValue.buyPrice !== undefined) newValues.buyPrice = convertPrice(decimals, editValue.buyPrice);
  else if (variant.buyPrice !== null) newValues.buyPrice = variant.buyPrice;

  return newValues;
};

const createProductMovements = (
  productID: string,
  editValues: { transit?: number | null; buyPrice?: number | null },
) => ({
  productID,
  quantity: editValues.transit,
  price: editValues.buyPrice,
});

/**
 *
 * This method builds a necessary object for the API based of informations coming from
 * the inventory movement list. We start by creating an object with null values, then, there is two cases :
 * - If the cell got edited, we return the editValues value related to the product
 * - If not and if a previous value existed, we return it
 *
 * @param editValues - Variable used while editing the list
 * @param product - Actual product
 *
 * @returns - An object of transit and price
 *
 * @author - Jennifer Charlois
 *
 */
export const getProductValues = (editValues: any, product: any, decimals: number) => {
  const newValues = { transit: null, buyPrice: null };

  if (editValues.transit !== undefined && editValues.transit !== '') newValues.transit = editValues.transit;

  // @ts-ignore
  if (editValues.buyPrice !== undefined) newValues.buyPrice = convertPrice(decimals, editValues.buyPrice);
  else if (product.buyPrice !== null) newValues.buyPrice = product.buyPrice;

  return newValues;
};

const createEmptyProductLine = (product: any) => {
  return {
    productID: product.id,
    variantID: null,
    quantity: null,
    price: product.buyPrice,
  };
};

/**
 *
 * This method creates a inventory movement
 * We get movements and variantMovements, these are necessary for the API request
 * Once we got both of them, we call the API createInventoryMovement then we display product list
 *
 * @usedIn - Inventory movements list
 *
 * @param inventoryMove - Every changes made
 * @param items - All selected products
 * @param state - Informations about the movement (motive, origin..)
 * @param dispatch - A hook to call the store
 * @param isDraft - A boolean to create a draft or closed inventory movement
 * @param t - A method to internationalize field's name
 *
 * @returns - void
 *
 * @author - Arthur Escriou x Jennifer Charlois
 *
 */
const createInventoryMove =
  ({
    items,
    state,
    dispatch,
    decimals,
    movementType,
  }: {
    items: any;
    state: { motive?: string; from?: any; to?: any };
    dispatch: any;
    decimals: number;
    movementType: string | null;
  }) =>
  (editValues: any, isDraft: boolean, t: (field: string) => string, updateBuyPrice?: boolean) => {
    console.log("itemsmmsmsmsmsms", JSON.stringify(items, null, 2))
    console.log("editValues", JSON.stringify(editValues, null, 2))

    const productOnlyToAdd = items
      .filter((product: any) => product.variantsCount > 0 && product.allSelected)
      .map((product: any) => createEmptyProductLine(product))
      .flat();

    console.log("productOnlyToAdd", JSON.stringify(productOnlyToAdd, null, 2))

    const movements = items
      .filter((product: any) => !(product.hasVariants || product.variantsCount > 0))
      .map((product: any) =>
        createProductMovements(product.id, getProductValues(editValues[product.id] ?? {}, product, decimals)),
      )
      .flat();

    const variantMovements = items
      .filter((product: any) => product.variantsCount > 0)
      .map((product: any) =>
        product.subValues
          .filter((subValue: { selected: boolean }) => subValue.selected)
          .flat()
          .map((variant: any) => {
            const variantValues = getVariantValues(editValues[product.id] ?? { subValues: {} }, variant, decimals) ?? {}
            // if (!variantValues) return

            return createVariantMovements(
              product.id,
              variant.variantID,
              variantValues,
            )
          })
      )
      .flat()
      .filter((e: any) => e)

    const allMovements = [...productOnlyToAdd, ...movements, ...variantMovements];

    allMovements.forEach((m: any) => (m.quantity === '' ? (m.quantity = null) : m.quantity));

    const stateMovement = isDraft ? InventoryMovementState.DRAFT : InventoryMovementState.CLOSED;
    const input: any = {
      motive: state.motive,
      state: stateMovement,
      origin: state.from !== '' ? InventoryMovementOrigin.SHOP : InventoryMovementOrigin.SUPPLIER,
      originID: state.from !== '' ? state.from : undefined,
      destination: state.to !== '' ? InventoryMovementOrigin.SHOP : InventoryMovementDestination.TRASH,
      destinationID: state.to !== '' ? state.to : undefined,
      movements: allMovements,
    };

    const newItem = items.map((item: any) => ({
      ...item,
      variantsCountAll: item.variantsCount,
      variantsSelectedAll: item.allSelected,
    }));

    createInventoryMovement(
      { ...input, companyID: getCompanyID() },
      dispatch,
      updateBuyPrice,
      movementType,
      newItem,
    ).then(data => {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch);
      if (data.errors) {
        updateModal(dispatch, true, ErrorModal, () => {});
      } else {
        if (updateBuyPrice) {
          updateBuyPriceList(input.movements, dispatch);
        }
        clearAllFilters(dispatch);
        unSelectAllList(dispatch);
        changeConf(dispatch, objectType.PRODUCT, 'requestProducts');
        updateList({ type: pluralize(objectType.PRODUCT), editValues: {}, errors: [] }, dispatch);
        toggleMenu(dispatch, { state: MenuState.EXPAND });
        closeAndResetModal(dispatch);
      }
    });
  };

const updateMovements = (id: string, editValues: { transit?: number | null; buyPrice?: number | null }) => ({
  id,
  quantity: editValues.transit,
  price: editValues.buyPrice,
});

const additionToMovements = (
  productID: string,
  variantID: string,
  editValues: { transit?: number | null; buyPrice?: number | null },
) => ({
  productID,
  variantID,
  quantity: editValues.transit,
  price: editValues.buyPrice,
});

/**
 *
 * This method builds a necessary object for the API based of informations coming from
 * the inventory movement list. We start by creating an object with null values, then, there is two cases :
 * - If the cell got edited, we return the editValues value related to the product
 * - If not and if a previous value existed, we return it
 *
 * @param editValues - Variable used while editing the list
 * @param product - Actual product
 *
 * @returns - An object of transit and price
 *
 * @author - Jennifer Charlois
 *
 */
export const getProductUpdateValues = (editValues: any, product: any, decimals: number) => {
  const newValues = { transit: null, buyPrice: null };

  if (editValues.transit === '') {
    newValues.transit = null;
  } else if (editValues.transit !== undefined) {
    newValues.transit = editValues.transit;
  } else if (product.quantity !== null) {
    newValues.transit = product.quantity;
  }

  if (editValues.buyPrice !== undefined) {
    // @ts-ignore
    newValues.buyPrice = convertPrice(decimals, editValues.buyPrice);
  } else if (product.price !== null) {
    newValues.buyPrice = product.price;
  }

  return newValues;
};

/**
 *
 * This method builds a necessary object for the API based of informations coming from
 * the inventory movement list. We start by creating an object with null values, then, there is two cases :
 * - If the cell got edited, we return the editValues value related to the variant
 * - If not and if a previous value existed, we return it
 *
 * @param editValues - Variable used while editing the list
 * @param variant - Actual variant
 *
 * @returns - An object of transit and price
 *
 * @author - Jennifer Charlois
 *
 */
export const getVariantUpdateValues = (editValues: any, variant: any, decimals: number) => {
  if (!editValues.subValues || !editValues.subValues[variant.id]){
    return null
  }

  const editValue = editValues.subValues[variant.id];
  const newValues = { transit: null, buyPrice: null };

  if (editValue.transit === '') {
    newValues.transit = null;
  } else if (editValue.transit !== undefined) {
    //@ts-ignore
    newValues.transit = Number(editValue.transit);
  } else if (variant.quantity !== undefined) {
    //@ts-ignore
    newValues.transit = (variant.quantity || variant.quantity === 0) ? Number(variant.quantity) : null;
  }

  if (editValue.buyPrice !== undefined) {
    // @ts-ignore
    newValues.buyPrice = convertPrice(decimals, editValue.buyPrice);
  } else if (variant.price !== undefined) {
    newValues.buyPrice = variant.price;
  }

  return newValues;
};

/**
 *
 * This method updates a inventory movement when editing one
 * We get modifications and deletions value, for both simple and parent object
 * These are needed for the API request
 * Once we go both of them, we call the API updateInventoryMovement then we navigate
 *
 * Reminder :
 * When "Add products" feature will be implemented
 * We will be looking for products who actually match with state.movements
 * and not all products selected
 *
 * @usedIn - Inventory movements list
 *
 * @param inventoryMove - Every changes made
 * @param items - All selected products
 * @param state - Informations about the movement (motive, origin..)
 * @param dispatch - A hook to call the store
 * @param history - A hook to navigate
 * @param isDraft - A boolean to create a draft or closed inventory movement
 * @param t - A method to internationalize field's name
 *
 * @returns - void
 *
 * @author - Jennifer Charlois
 *
 */
const updateInventoryMove =
  ({
    items,
    state,
    dispatch,
    navigate,
    decimals,
  }: {
    items: any;
    state: { id?: string; movements?: Array<any> };
    dispatch: any;
    navigate: any;
    decimals: number;
  }) =>
  (editValues: any, isDraft: boolean, t: (field: string) => string, updateBuyPrice?: boolean) => {
    //! SOON WE WILL NEED THIS TO ADD A PRODUCT DURING A DRAFT
    //  const additions = items
    //    .filter((product: any) => {
    //      const modifiedID = product.id.split('__').pop();
    //      return !state.movements?.some((movement: any) => movement.productID === modifiedID);
    //    })
    //    .map((product: any) => {
    //     return updateMovements('', getProductUpdateValues(editValues[product.id] ?? {}, product, decimals));
    //    });

    const additionsVariations = items
      .filter((product: any) => product.subValues && product.subValues.length > 0)
      .flatMap((product: any) =>
        product.subValues
          .filter((subValue: any) => {
            const variantID = subValue.variantID;
            return !state.movements?.some((movement: any) => movement.variantID === variantID);
          })
          .map((subValue: any) => {
            const variantValues = getVariantUpdateValues(editValues[product.id] ?? { subValues: {} }, subValue, decimals)
            if (!variantValues) return

            return additionToMovements(
              product.productID,
              subValue.variantID,
              variantValues,
            );
          }),
      )
      .flat()
      .filter((e: any) => e)

    const modifications = items
      .filter((product: any) => !product.hasVariants)
      .map((product: any) => {
        const modifiedID = product.productID;
        const movement = state.movements?.find((movement) => modifiedID === movement.productID);

        return updateMovements(
          movement ? movement.id : null,
          getProductUpdateValues(editValues[product.id] ?? {}, product, decimals),
        );
      })
      .flat();

    const variantModifications = items
      .filter((product: any) => product.subValues && product.subValues.length > 0)
      .flatMap((product: any) =>
        product.subValues
          .filter((subValue: { selected: boolean; variantID: string }) => {
            const variantID = subValue.variantID;
            const movementExists = state.movements?.some(
              (movement: { variantID: string }) => movement.variantID === variantID,
            );
            return subValue.selected && movementExists; // Only include if selected and the movement already exists
          })
          .map((subValue: any) => {
            const modifiedID = subValue.variantID;
            const movement = state.movements?.find(
              (movement: { variantID: string }) => movement.variantID === modifiedID,
            );

            const variantValues = getVariantUpdateValues(editValues[product.id] ?? { subValues: {} }, subValue, decimals)
            if (!variantValues) return

            return updateMovements(
              movement.id,
              variantValues,
            );
          }),
      )
      .flat()
      .filter((e: any) => e)

    const deletions = state.movements
      ?.filter(
        (movement: any) => !items.some((product: { productID: string }) => product.productID === movement.productID),
      )
      .map((movement: { productID: string }) => ({ id: movement.productID }));

    const variants = items
      .filter((product: any) => product.subValues && product.subValues.length > 0)
      .map((product: any) => product.subValues)
      .flat();

    const variantDeletions = state.movements
      ?.filter((movement: { variantID: string; id: string }) => {
        const variant = variants.find((variant: { id: string }) => variant.id === movement.variantID);
        return variant && !variant.selected;
      })
      .map((movement: { variantID: string; id: string }) => ({
        id: movement.id,
      }));

    updateInventoryMovement(
      {
        id: state.id!,
        state: isDraft ? InventoryMovementState.DRAFT : InventoryMovementState.CLOSED,
        insertions: additionsVariations,
        modifications: modifications.concat(variantModifications),
        deletions: variantDeletions ? deletions?.concat(variantDeletions) : deletions,
      },
      dispatch,
      updateBuyPrice,
    ).then(data => {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch);
      if (data.errors) {
        updateModal(dispatch, true, ErrorModal, () => {});
      } else {
        clearAllFilters(dispatch);
        unSelectAllList(dispatch);
        navigate('/catalog/movements');
        updateList({ type: pluralize(objectType.INVENTORY_MOVE_PRODUCT), editValues: {}, errors: [] }, dispatch);
        toggleMenu(dispatch, { state: MenuState.EXPAND });
        closeAndResetModal(dispatch);
      }
    });
  };

export {
  subscribeInventoryMovements,
  unSubscribeInventoryMovements,
  createInventoryMovement,
  updateInventoryMovement,
  cancelInventoryMovement,
  getInventoryMovement,
  getInventoryMovementVariant,
  getInventoryMovementAllVariants,
  getInventoryStats,
  createInventoryMove,
  updateInventoryMove,
  createInventoryItems,
};
