import { TypeCol, TypeColumnConfiguration } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { movementTypeToStr, paymentMethodToComponent, salePaymentStateToStr } from '../../utils/typeToType'
import { CurrencyEditCell as CurrencyCell } from '../../components/list/cell'
import DisplayIcon2 from '../../components/newComponents/icons'

import Chip from '../../components/newComponents/chip'

const PAYMENT = 'payment'

export const paymentCols: Array<TypeCol> = [
  {
    id: 'color',
    title: '',
    type: 'element',
    active: true,
    function: ({ value, paymentMethods }) => {
      const paymentMethod = paymentMethods.items.find((item: any) => item.id === value.paymentMethodID)

      return DisplayIcon2({
        Icon: paymentMethodToComponent(value.paymentMethodType, paymentMethod ? paymentMethod.color : '').component,
        color: paymentMethodToComponent(value.paymentMethodType, paymentMethod ? paymentMethod.color : '').color,
      })
    },
  },
  {
    id: 'number',
    title: 'NUMBER',
    type: 'function',
    fieldType: 'number',
    field: 'number',
    active: true,
    sortable: true,
    notCustomable: true,
    function: (number) => number ? ('#' + number) : "",
  },
  {
    id: 'amount',
    title: 'AMOUNT',
    field: 'amount',
    type: 'element',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }) =>
      CurrencyCell({
        value,
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'type',
    title: 'IN_OUT',
    type: 'element',
    fieldType: 'string',
    active: true,
    sortable: true,
    function: ({ value, t }) => movementTypeToStr(value.type, t).text,
  },
  {
    id: 'state',
    title: 'SITUATION',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value, t }) => Chip(salePaymentStateToStr(value.state, t)),
  },
  {
    id: 'date',
    title: 'DATE',
    type: 'function',
    field: 'date',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => displayDateShortMonthWithHours(date),
  },
]

export const paymentsColsConf: TypeColumnConfiguration[] = [
  {
    id: 'requestPayments',
    type: PAYMENT,
    cols: paymentCols,
    creatable: false,
    editable: false,
    exportable: true,
    clickable: true,
    shrinkrable: true,
    customable: true,
    checkboxes: false,
    searchBarPlaceholder: 'searchBar.placeholder.NUMBER',
  },
]
