import { useSelector } from 'react-redux'

import { getDashboardView } from '../../store/selector'
import { DashboardView } from '../../store/types'

import styles from './Connection.module.css'

type Props = {
  left: JSX.Element
  right: JSX.Element
  hiddenRight?: boolean
}

/**
 *
 * This element splits the view in two parts (left and right)
 *
 * @usedIn - Connection views
 *
 * @param props - An object which contains the elements to display and their width
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const SplitView = (props: Props): JSX.Element => {
  const dashboardView = useSelector(getDashboardView)
  return (
    <div className={styles.container}>
      <div
        className={styles.part}
        style={{ width: dashboardView !== DashboardView.MOBILE && !props.hiddenRight ? '50%' : '100%' }}
      >
        {props.left}
      </div>
      {dashboardView !== DashboardView.MOBILE && !props.hiddenRight && (
        <div className={styles.grid}>
          <div className={styles.content}>{props.right}</div>
        </div>
      )}
    </div>
  )
}

export default SplitView
