import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { List } from '../../../../../components/oldComponents/activity/list'
import { selectedCompany } from '../../../../../store/selector'
import getEventLines from './config'
import { TypeCashbookEvent } from '../../../../../types'
import generateDataCy from '../../../../../utils/cypress'

import styles from '../../../../../components/oldComponents/drawer/Drawer.module.css'

type State = {
  infos?: Array<any>
}

const Event = ({ cashbookState, dataCy }: { cashbookState: TypeCashbookEvent; dataCy: string }) => {
  const initialeState: State = {
    infos: [],
  }
  const [state, setState] = useState(initialeState)
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()

  useEffect(() => {
    const { infos } = getEventLines(t, cashbookState, company)
    setState({ ...state, infos })
      }, [cashbookState])

  return (
    <div className={styles.overflow}>
      <div className={styles.form}>
        {state.infos && <List datas={state.infos} dataCy={generateDataCy({ scope: dataCy, value: 'event' })} />}
      </div>
    </div>
  )
}

export default Event
