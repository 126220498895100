import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ListState, objectType } from '../../store/types'
import List from '../../components/list'
import Drawer from './drawer'
import { TypeCustomField } from '../../types'
import {
  listCustomerItemsNextToken,
  listCustomers,
  listCustomerItems,
  listCustomFieldItems,
  getDashboardView,
} from '../../store/selector'
import { buildCols } from '../../utils'
import { TypeComparator } from '../../components/requester/types'
import customerFieldsRequester from '../../requester/customer'
import addCustomFieldsInEntity from '../../requester/catalog/customField'
import { CustomFieldObjectType, CustomFieldValueType } from '../../API'

import { getData } from '../../services'
import { getListCustomers } from '../../graphql/custom/queries'
import { useDispatch } from 'react-redux'
import { pluralize } from '../../utils/typeToType'
import { fetchListElement, updateListState } from '../../actions/list'
import { subscribeCustomerCustomFields, subscribeCustomers, unSubscribeCustomerCustomFields, unSubscribeCustomers } from '../../getters/customer/customer'

const ListCustomers = () => {
  const res = useSelector(listCustomers)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listCustomerItems)
  const { items, nextToken, sort } = useSelector(listCustomerItemsNextToken)
  const customFields: Array<TypeCustomField> = useSelector(listCustomFieldItems)
  const dashboardView = useSelector(getDashboardView)

  const dispatch = useDispatch();

  useEffect(() => {
    subscribeCustomers(dispatch);
    subscribeCustomerCustomFields(dispatch)
    const getListCustomersItems = async () => {
      const listCustomers = await getData({ request: getListCustomers, limit: 100 });
      if (listCustomers.requestCustomers && listCustomers.requestCustomers.items && listCustomers.requestCustomers.items.length > 0) {
        const customers = listCustomers.requestCustomers.items.filter((_: any) => _)
        fetchListElement(dispatch, customers, listCustomers.requestCustomers.nextToken, pluralize(objectType.CUSTOMER))
        updateListState(
          { type: pluralize(objectType.CUSTOMER), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.CUSTOMER))
        updateListState(
          { type: pluralize(objectType.CUSTOMER), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListCustomersItems();

    return () => {
      unSubscribeCustomers();
      unSubscribeCustomerCustomFields();
    };
      }, [])

  useEffect(() => setCols(buildCols(res.cols!, 'firstName', 'lastName', dashboardView)), [dashboardView, res.cols])

  const getAllFields = () => {
    const sortedCustomFields = [
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.TEXT),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.NUMBER),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.DATE),
    ]
    const fieldsCopy = [...customerFieldsRequester]

    const index = fieldsCopy.findIndex((field) => field.filterID === 'CREATED_AT')
    fieldsCopy.splice(index, 0, ...addCustomFieldsInEntity(sortedCustomFields, CustomFieldObjectType.CUSTOMER))

    return fieldsCopy
  }

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={getAllFields()}
      searchBarFields={[
        { filter: TypeComparator.CONTAINS, field: 'firstName' },
        { filter: TypeComparator.CONTAINS, field: 'lastName' },
        { filter: TypeComparator.CONTAINS, field: 'email' },
        { filter: TypeComparator.CONTAINS, field: 'phone' },
      ]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.CUSTOMER}
      Drawer={Drawer}
    />
  )
}

export default ListCustomers
