import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import List from '../../../components/list'
import { ListState, objectType } from '../../../store/types'
import Drawer from './drawer'
import { TypeCustomField } from '../../../types/setting/customField'
import { fetchListElement, unSelectProductsById, unSelectVariantById, updateListState } from '../../../actions/list'
import {
  getDashboardView,
  listProductItemsNextToken,
  listProducts,
  listProductsItems,
  listCustomFieldItems,
  listShopsItemsNextToken,
  currentAccount,
} from '../../../store/selector'
import { buildCols } from '../../../utils'
import productFieldsRequester from '../../../requester/catalog/product'
import { TypeComparator } from '../../../components/requester/types'
import addCustomFieldsInEntity from '../../../requester/catalog/customField'
import { CustomFieldObjectType, CustomFieldValueType } from '../../../API'
import { addInventoryQuantityGlobalFields } from '../../../requester'
import { useDispatch } from 'react-redux'
import { getAllProducts } from '../../../graphql/custom/queries'
import { getData } from '../../../services'
import formatWithSubvalues from '../../../format/product'
import { pluralize } from '../../../utils/typeToType'
import {
  subscribeProductCustomFields,
  subscribeProducts,
  subscribeProductTags,
  unSubscribeProducts,
  unSubscribeProductCustomFields,
  unSubscribeProductTags,
  subscribeProductInventoryQuantities,
  unSubscribeProductInventoryQuantities
} from '../../../getters/catalog/product'
import { subscribeProductVariants, unSubscribeProductVariants } from '../../../getters/catalog/variant'
import { getCompanyID } from '../../../services/localStorage'
import { subscribeCategories, unSubscribeCategories } from '../../../getters/catalog/category'
import { subscribeTags } from '../../../getters/setting/tag'
import { subscribeTaxes, unSubscribeTaxes } from '../../../getters/setting/tax'
import { subscribeOptions, unSubscribeOptions } from '../../../getters/catalog/option'
import { subscribeInventoryMovements, unSubscribeInventoryMovements } from '../../../getters/catalog/inventory'

const ListProducts = () => {
  const res = useSelector(listProducts)
  const account = useSelector(currentAccount);
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listProductsItems)
  const { items, nextToken, sort } = useSelector(listProductItemsNextToken)
  const customFields: Array<TypeCustomField> = useSelector(listCustomFieldItems)
  const shops = useSelector(listShopsItemsNextToken)
  const dashboardView = useSelector(getDashboardView)
  const { t } = useTranslation()

  const dispatch = useDispatch();

  useEffect(() => {

    const shops = account.shops.items
      .filter((_: any) => _)
      .filter((shop: any) => shop.companyID === getCompanyID())
      .map((shop: any) => shop.shop)

    subscribeProductInventoryQuantities(shops, dispatch);
    subscribeInventoryMovements(shops, dispatch);
    subscribeProducts(dispatch)
    subscribeProductCustomFields(dispatch)
    subscribeProductTags(dispatch)
    subscribeProductVariants(dispatch)
    subscribeCategories(dispatch);
    subscribeProductTags(dispatch);
    subscribeTags(dispatch);
    subscribeTaxes(dispatch);
    subscribeOptions(dispatch);
    const getListProducts = async () => {
      updateListState({ type: pluralize(objectType.PRODUCT), state: ListState.LOADING }, dispatch)
      const listAllProducts = await getData({ request: getAllProducts, limit: 100 });
      if (listAllProducts.requestProducts && listAllProducts.requestProducts.items && listAllProducts.requestProducts.items.length > 0) {
        const products = listAllProducts.requestProducts.items
          .filter((_: any) => _)
          .map((item: any) => formatWithSubvalues(false, false)(item))
        fetchListElement(dispatch, products, listAllProducts.requestProducts.nextToken, pluralize(objectType.PRODUCT))
        updateListState({ type: pluralize(objectType.PRODUCT), state: ListState.LOADED }, dispatch)
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.PRODUCT))
        updateListState({ type: pluralize(objectType.PRODUCT), state: ListState.EMPTY }, dispatch)
      }
    }
    getListProducts();

    return () => {
      unSubscribeProductCustomFields()
      unSubscribeProducts()
      unSubscribeProductTags();
      unSubscribeProductVariants();
      unSubscribeProductInventoryQuantities();
      unSubscribeCategories();
      unSubscribeProductTags();
      unSubscribeProductTags();
      unSubscribeTaxes();
      unSubscribeOptions();
      unSubscribeInventoryMovements();
    };

  }, []);


  useEffect(() => setCols(buildCols(res.cols!, 'name', 'sellPrice', dashboardView)), [dashboardView, res.cols])

  const getAllFields = () => {
    const sortedCustomFields = [
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.TEXT),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.NUMBER),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.DATE),
    ]
    const fieldsCopy = [...productFieldsRequester]

    let index = fieldsCopy.findIndex((field) => field.filterID === 'INVENTORY_QUANTITY_1SHOP')
    fieldsCopy.splice(index, 0, ...addInventoryQuantityGlobalFields(shops.items, t))

    index = fieldsCopy.findIndex((field) => field.filterID === 'TAG_NAME')
    fieldsCopy.splice(index + 1, 0, ...addCustomFieldsInEntity(sortedCustomFields, CustomFieldObjectType.PRODUCT))

    return fieldsCopy
  }

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={getAllFields()}
      searchBarFields={[
        { filter: TypeComparator.CONTAINS, field: 'name' },
        { filter: TypeComparator.CONTAINS, field: 'reference' },
      ]}
      nextToken={nextToken}
      sort={sort}
      editValues={res.editValues}
      errors={res.errors}
      type={objectType.PRODUCT}
      unSelectById={unSelectProductsById}
      unSelectSubById={unSelectVariantById}
      Drawer={Drawer}
    />
  )
}

export default ListProducts
