import { PERCENT_DECIMAL } from '.'
import { DiscountType } from '../API'

const { floor, log10, abs } = Math

const convertPrice = (decimals?: number, value?: number) => {
  if ((value || value === 0) && decimals !== null && decimals !== undefined) {
    // seems like that this function is sometimes called with non-number value
    // ex: '1 234.32'
    const numberValue = Number(value.toString().replace(/[^0-9.-]+/g,""))
    const floatValue = parseFloat(toFixedWithoutRounding(numberValue, decimals))
    const precision = Math.max(floor(log10(abs(floatValue))), 0) + 1 + decimals
    return parseInt((floatValue * 10 ** decimals).toPrecision(precision), 10)
  }
  return null
}

const displayPercentage = (value: number) =>
  new Intl.NumberFormat(navigator.language, {
    style: 'percent',
    maximumFractionDigits: PERCENT_DECIMAL,
  })
    .format(value / 10 ** (PERCENT_DECIMAL + 2))
    .replace(/\s/g, ' ')

const displayPrice = (value: number, currency: string, decimals: number) =>
  new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  })
    .format(value / 10 ** decimals)
    .replace(/\s/g, ' ')

const displayCurrency = (currency: string) =>
  new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  })
    .format(0)
    .replace(/[\d\\.,\s]/g, '')

const isNumber = (value?: string | number): boolean => value != null && value !== '' && !isNaN(Number(value.toString()))
const toCurrencyNumber = (value: any, decimals: number = 0) => <any>((isNumber(value)) ? (value / 10 ** decimals) : null)
const displayNumber = (value: any, decimals: number = 0) => <any>toCurrencyNumber(value, decimals)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ?? ''

// const displayNumber = (value: any) => ((value !== null && value !== undefined) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : null)

const toFixedWithoutRounding = (value: any, decimals: number) => {
  const epsilon = (decimals: number) => 10 ** -(decimals + 10)
  const eps = epsilon(decimals)
  return (Math.floor(value * 10 ** decimals + eps) / 10 ** decimals + eps).toFixed(decimals)
}

/**
 *
 * This element parses discount rate in purpose to display the wanted iconText
 *
 * First we map the reversed abbreviations array indexes, we are looking for the coeff
 * who is bigger to our multiplied number
 *
 * @usedIn - Icon text for Discount
 *
 * @param rate - Discount's rate
 * @param type - Discount's type
 * @param decimals - Company decimals
 *
 * @author - Jennifer Charlois
 *
 */
const displayIconTextPrice = (rate: number, type: DiscountType, decimals: number, currency: string) => {
  if (!rate) {
    return type === DiscountType.PERCENTAGE ? '%' : displayCurrency(currency)
  }
  if (type === DiscountType.PERCENTAGE) {
    return (rate / 10 ** PERCENT_DECIMAL).toString().concat('%')
  }
  const abbreviations = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
  const multiplier = 1 / 10 ** decimals
  let num = rate * multiplier
  let abbreviation = ''
  const parseRate = (number: number, quantity: number) => toFixedWithoutRounding(number, quantity) + abbreviation

  if (num < 100) return parseRate(num, 2)
  if (num < 1000) return parseRate(num, 1)

  return abbreviations
    .map((_, index) => index)
    .reverse()
    .reduce((acc, index) => {
      const coeff = 10 ** ((index + 1) * 3)
      abbreviation = abbreviations[index]

      if (coeff <= num) {
        num /= coeff
        if (num < 10) {
          return parseRate(num, 2)
        }
        if (num < 100) {
          return parseRate(num, 1)
        }
        return parseRate(num, 0)
      }

      return acc
    }, '')
}

export {
  convertPrice,
  displayPrice,
  displayCurrency,
  displayNumber,
  isNumber,
  toCurrencyNumber,
  displayPercentage,
  displayIconTextPrice,
  toFixedWithoutRounding,
}
