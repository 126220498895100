/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import countries from '../../../assets/countries.json'

import { createShop as createS, updateShop as updateS } from '../../../graphql/custom/mutations'
import { closeAndResetModal } from '../../../actions/modal'
import { name as nameValidator, email as emailValidator } from '../../../format/errors/filters/setting/shop'
import UniqueDropDown from '../../../components/newComponents/dropDown/unique'
import Input from '../../../components/newComponents/input'
import {
  ComponentDirection,
  ComponentErrorText,
  ComponentSize,
  ComponentState,
  ComponentStatus,
  ComponentType,
} from '../../../components/newComponents/types'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { getShop } from '../../../getters/setting/shop'
import { getCompanyID } from '../../../services/localStorage'
import { TypeShop } from '../../../types'
import generateDataCy from '../../../utils/cypress'
import { createInput, isNestedDirty } from '../../../utils/dirties'
import { parseName } from '../../../utils/string'
import Button from '../../../components/newComponents/button'
import { handleBlur } from '../../../format/form'
import { DataWithErrors } from '../../../services/types'
import { CreateShopInput, DeleteImageInput, ImageModelType, UpdateShopInput, UploadImageInput } from '../../../API'
import { callService } from '../../../services'
import TextArea from '../../../components/newComponents/textArea'
import Divider from '../../../components/newComponents/divider'
import { deleteImage, uploadImage } from '../../../graphql/mutations'
import ImagePicker from '../../../components/newComponents/imagePicker'
import PhoneInput from '../../../components/newComponents/phoneInput'

import { KeyboardArrowDown, Shop } from '../../../assets/icons'
import styles from '../../../components/newComponents/modal/Modal.module.css'
import stylesDrawer from '../../../components/oldComponents/drawer/Drawer.module.css'
import Ticket from '../../../assets/icons/ticket'

const ShopModal = ({ id }: { id?: string }) => {
  const initialState: TypeShop = {
    name: '',
    dirties: id ? ['id'] : [],
    address: {},
    showSubmit: false,
    validators: {
      name: nameValidator,
      brandName: nameValidator,
      email: emailValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cyContext = generateDataCy({ scope: 'settings', value: 'shop' })

  const setShopInState = (data: DataWithErrors) => {
    if (data.data) {
      const shop = data.data
      setState({
        ...state,
        id: shop.id,
        name: shop.name,
        logo: shop.logo,
        brandName: shop.brandName,
        address: shop.address ?? {},
        phone: shop.phone,
        email: shop.email,
        siret: shop.siret,
        vat: shop.vat,
        footnote: shop.footnote,
      })
    }
  }

  useEffect(() => {
    if (id) getShop(id, dispatch).then(setShopInState)
  }, [id])

  const handleChange = (field: string, subField?: string) => (event: any) => {
    state.errors!.global = undefined
    setIsLoading(false);

    if (field === 'address') {
      // @ts-ignore
      state.address[subField] = event
      setState({ ...state, showSubmit: true })
    } else {
      state.dirties = [...state.dirties!, field]
      // @ts-ignore
      state.errors[field] = null
      const showSubmit = !findErrorsInState(state.errors)

      setState({ ...state, [field]: event !== '' ? event : null, showSubmit })
    }
  }

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
    } else {
      closeAndResetModal(dispatch)
    }
  }
  const handleSubmit = () => {
    if (isLoading) return;

    setIsLoading(true);
    state.showSubmit = false
    setState(handleBlur(state, 'name'))
    setState(handleBlur(state, 'brandName'))


    if (!findErrorsInState(state.errors)) {

      const input: any = createInput(state)

      input.address = {}

      if (state.address?.address) input.address.address = parseName(state.address.address)
      if (state.address?.city) input.address.city = parseName(state.address?.city)
      if (state.address?.zipcode) input.address.zipcode = parseName(state.address?.zipcode)
      if (state.address?.country) input.address.country = state.address?.country
      if (state.siret) input.siret = parseName(state.siret)
      if (state.vat) input.vat = parseName(state.vat)
      if (state.phone) input.phone = '+' + state.phone.replace('+', '')

      id
        ? callService<{ input: UpdateShopInput }>({ input }, updateS, 'updateShop').then((res) => {
          if (isNestedDirty(state.dirties, 'logo')) {
            if (state.logo)
              callService<{ input: UploadImageInput }>(
                { input: { id, type: ImageModelType.SHOP, base64Image: state.logo } },
                uploadImage,
                'uploadImage'
              ).then(handleResult).catch((err) => console.log(err)).finally(() => {
                setIsLoading(false);
              })
            else
              callService<{ input: DeleteImageInput }>(
                { input: { id, type: ImageModelType.SHOP } },
                deleteImage,
                'deleteImage'
              ).then(handleResult).catch((err) => console.log(err)).finally(() => {
                setIsLoading(false);
              })
          } else handleResult(res)
        })
        : callService<{ input: CreateShopInput }>(
          {
            input: { companyID: getCompanyID(), ...input },
          },
          createS,
          'createShop'
        ).then((res) => {
          if (state.logo)
            callService<{ input: UploadImageInput }>(
              { input: { id: res.data.id, type: ImageModelType.SHOP, base64Image: state.logo } },
              uploadImage,
              'uploadImage'
            ).then(handleResult)
          else handleResult(res)
        }).catch((err) => console.log(err)).finally(() => {
          setIsLoading(false);
        })
    }
  }

  const errorText = () => {
    if (state.errors?.name?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return t('settings.shop.modal.NAME_INFO');
  }

  return (
    <div 
      className={styles.modal}
      data-cy={generateDataCy({
        scope: cyContext,
        value: id ? 'edit' : 'create',
      })}
    >
      <div className={styles.modalTitleContainer}>
        <div className={styles.modalTitleIcon}>
          <Shop color={"#000"} />
        </div>
        <div className={styles.modalTitle}>
          {id ? t('settings.shop.modal.EDIT') : t('settings.shop.modal.CREATE')}
        </div>
      </div>
      <div className={styles.modalContent}>
        <Input
          label={t('settings.shop.modal.NAME')}
          value={state.name}
          onChange={handleChange('name')}
          onBlur={() => setState(handleBlur(state, 'name'))}
          onSubmit={handleSubmit}
          style={{
            status: state.errors.name?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={errorText()}
          dataCy={generateDataCy({ scope: cyContext, value: 'name' })}
        />
      </div>
      <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
      <br />
      <div className={styles.modalContent}>
        <div className={styles.modalSubTitleContainer}>
          <div className={styles.modalTitleContainer}>
            <div className={styles.modalTitleIcon}>
              <Ticket color={"#000"} />
            </div>
            <div className={styles.modalTitle}>{t('settings.shop.modal.TICKET')}</div>
          </div>
          <div className={styles.modalSubTitle}>{t('settings.shop.modal.INFO')} </div>
        </div>
        <div className={styles.modalImagePickerContainer}>
          <div className={styles.modalHelperText}>{t('settings.shop.modal.LOGO')}</div>
          <ImagePicker
            text={t('settings.shop.modal.FORMAT')}
            photo={state.logo}
            onChange={handleChange('logo')}
            resize={1024}
            isLogo
          />
        </div>
      </div>
      <div className={styles.modalContent}>
        <Input
          label={t('settings.shop.modal.BRAND_NAME')}
          value={state.brandName}
          onChange={handleChange('brandName')}
          onBlur={() => setState(handleBlur(state, 'brandName'))}
          onSubmit={handleSubmit}
          style={{
            status: state.errors?.brandName?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={state.errors?.brandName?.value ? state.errors.brandName.message : t('settings.shop.modal.BRAND_NAME_HELPER')}
          dataCy={generateDataCy({ scope: cyContext, value: 'brandName' })}
        />
        <Input
          label={t('settings.shop.modal.ADDRESS')}
          value={state.address?.address}
          onChange={handleChange('address', 'address')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: cyContext, value: 'address' })}
        />
        <Input
          label={t('settings.shop.modal.CITY')}
          value={state.address?.city}
          onChange={handleChange('address', 'city')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: cyContext, value: 'city' })}
        />
        <Input
          label={t('settings.shop.modal.ZIP_CODE')}
          value={state.address?.zipcode}
          onChange={handleChange('address', 'zipcode')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: cyContext, value: 'zipcode' })}
        />
        <UniqueDropDown
          parentID={'select'}
          popUpID={'selectPopUp'}
          Element={
            <Input
              placeholder={t('settings.shop.modal.COUNTRY')}
              value={countries.find((country) => country.alpha2 === state.address?.country?.toLowerCase())?.name}
              style={{
                trailIcons: [{ icon: KeyboardArrowDown }],
                readOnly: true,
              }}
              dataCy={generateDataCy({ scope: cyContext, value: 'country' })}
            />
          }
          items={countries.map((country) => ({
            id: country.alpha2.toUpperCase(),
            value: country.name,
            onClick: handleChange('address', 'country'),
          }))}
          dataCy={generateDataCy({ scope: cyContext, value: 'country' })}
        />
        <PhoneInput
          phone={state.phone}
          onChange={(phone: any) => handleChange('phone')(phone)}
          status={state.errors.phone?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT}
          helperText={state.errors.phone?.message}
        />
        <Input
          label={t('settings.shop.modal.EMAIL')}
          value={state.email}
          onChange={handleChange('email')}
          onBlur={() => setState(handleBlur(state, 'email'))}
          onSubmit={handleSubmit}
          style={{
            status: state.errors?.email?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={state.errors?.email?.value ? state.errors.email.message : undefined}
          dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
        />
        <Input
          label={t('settings.shop.modal.SIRET')}
          value={state.siret}
          onChange={handleChange('siret')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: cyContext, value: 'siret' })}
        />
        <Input
          label={t('settings.shop.modal.VAT')}
          value={state.vat}
          onChange={handleChange('vat')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: cyContext, value: 'vat' })}
        />
        <TextArea
          label={t('settings.shop.modal.FOOTNOTE')}
          value={state.footnote}
          onChange={handleChange('footnote')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: cyContext, value: 'footnote' })}
        />
      </div>
      <div className={styles.modalButtons}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
        />
        <Button
          title={t('button.SAVE')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default ShopModal
