import { Action, ListSort, objectType } from '../store/types';
import {
  listFilterProduct,
  listFilterCategory,
  filterListFunction,
  listFilterDiscount,
  listFilterPack,
  listFilterOption,
  listFilterSale,
  listFilterPayment,
  listFilterCashbook,
  listFilterCustomer,
  listFilterInventoryMovement,
  listFilterBackgroundJob,
} from '../services/requester';
import { sortList } from './list';

type Props = {
  query: any;
  isEditable: boolean;
  items: Array<any>;
  selectedItems: Array<any>;
  allSelected?: boolean;
  dispatch: any;
  type: objectType;
  cols: any;
  sort?: ListSort;
  nextToken?: string;
};

type RequesterProps = Props & {
  query: any;
  fun: filterListFunction;
  selectedItems: Array<any>;
  items: Array<any>;
  dispatch: any;
  type: objectType;
  cols: any;
  sort?: ListSort;
  nextToken?: string;
};

const toggleFilters = (
  dispatch: any,
  { type, filters }: { type: objectType; filters: { requesterOpen: boolean; searchbarOpen: boolean } },
) => dispatch({ type: Action.TOGGLE_FILTERS, payload: { type, filters } });

const clearAllFilters = (dispatch: any) => dispatch({ type: Action.CLEAR_ALL_FILTERS });

const switchFilteredItemsToItems = (type: objectType, dispatch: any) =>
  dispatch({ type: Action.SWITCH_FILTERED_ITEMS_TO_ITEMS, payload: { type } });

const requestItems = ({ type, data }: { type: objectType; data: any }, dispatch: any) =>
  dispatch({
    type: Action.REQUEST_ITEMS,
    payload: {
      type,
      data,
    },
  });

const requestFilteredItems = ({ query, type, data }: { query: any; type: objectType; data: any }, dispatch: any) =>
  dispatch({
    type: Action.REQUEST_FILTERERED_ITEMS,
    payload: {
      query,
      type,
      data,
    },
  });

/**
 *
 * This method filters the selected elements list and remove from them non existing elements from the requester response
 * For example :
 * - Full items : 1, 2, 3
 * - Requester items : 2
 * We need to delete 1 and 3 in order to filter the list
 *
 * @usedIn - Requester
 *
 * @param fullItems - All items
 * @param requesterItems - Requester's items
 *
 * @returns - An array of items
 *
 * @author - Jennifer Charlois
 *
 */
const getSelectedFilteredItemsEditableList = (fullItems: Array<any>, requesterItems: Array<any>) =>
  fullItems
    .filter((fItem: { selected: boolean }) => fItem.selected)
    .filter(
      (fItem: { id: string }) => requesterItems.findIndex((rRtem: { id: string }) => fItem.id === rRtem.id) !== -1,
    );

const removeSelectedOnFilteredItems = (selectedItems: Array<any>, requesterItems: Array<any>) =>
  requesterItems.filter(
    (rItem: { id: string }) => selectedItems.findIndex((sItem: { id: string }) => sItem.id === rItem.id) === -1,
  );

/**
 *
 * This method filters selected items and add requester items minus already existing requester items from selected items
 *
 * For example :
 * - Full items : 1, 2, 3, 4
 * - Selected items : 1, 3
 * - Requester items : 2, 3
 * We need to create a new array with 1, 3 because they are selected
 * Then we add 2 and delete 3 (because it's already present in the selected list)
 *
 * Final array : [1 (from fullItems), 2 (from requesterItems), 3 (from fullItems)]
 *
 * Then calling sortList to sort both item's list
 *
 * @usedIn - Requester
 *
 * @param fullItems - All items
 * @param requesterItems - Requester's items
 * @param cols - List's cols
 * @param sort - List's sort
 *
 * @returns - An array of items
 *
 * @author - Jennifer Charlois
 *
 */
const handleDeleteFilter = (
  items: Array<any>,
  selectedItems: Array<any>,
  requesterItems: Array<any>,
  cols: any,
  sort: any,
) => {
  const filteredRequesterItems = removeSelectedOnFilteredItems(selectedItems, requesterItems);

  items.forEach(item => {
    // Rechercher l'objet correspondant dans requesterItems par l'identifiant unique
    const matchingItem = filteredRequesterItems.find(reqItem => reqItem.id === item.id);

    if (matchingItem) {
      // Vérifier si subValues est différent dans les deux objets
      if (JSON.stringify(item.subValues) !== JSON.stringify(matchingItem.subValues)) {
        // Mettre à jour subValues dans matchingItem avec la valeur de items
        matchingItem.subValues = item.subValues;
      }

      // Vérifier si subValuesNextToken est différent dans les deux objets
      if (item.subvalueNextToken !== matchingItem.subvalueNextToken) {
        // Mettre à jour subvalueNextToken dans matchingItem avec la valeur de items
        matchingItem.subvalueNextToken = item.subvalueNextToken;
      }
    }
  });

  return sortList(cols, { items: [...selectedItems, ...filteredRequesterItems], sort });
};

/**
 *
 * This method remove selected items that do not match requester response
 * Keep selected items that match requester response
 *
 * Then calling sortList to sort both item's list
 *
 * @usedIn - Requester
 *
 * @param fullItems - All items
 * @param requesterItems - Requester's items
 * @param cols - List's cols
 * @param sort - List's sort
 *
 * @returns - An array of items
 *
 * @author - Jennifer Charlois
 *
 */
const handleAddFilter = (selectedItems: Array<any>, requesterItems: Array<any>, cols: any, sort?: ListSort) => {
  const filteredSelectedItems = selectedItems.filter(
    (sItem: { id: string }) => requesterItems.findIndex((rItem: { id: string }) => rItem.id === sItem.id) !== -1,
  );
  const filteredRequesterItems = removeSelectedOnFilteredItems(selectedItems, requesterItems);

  return sortList(cols, { items: [...filteredSelectedItems, ...filteredRequesterItems], sort: sort! });
};

/**
 *
 * This method do 2 things :
 * - Filter selectedItems in order to take only those who match the items (items is the result of previous requester response)
 * - Remove filteredSelectedItems that already exist in the final list we will display
 *
 * Then calling sortList to sort both item's list
 *
 * @usedIn - Requester
 *
 * @param items - List's items
 * @param selectedItems - Selected items
 * @param requesterItems - Requester's items
 * @param cols - List's cols
 * @param sort - List's sort
 *
 * @returns - An array of items
 *
 * @author - Jennifer Charlois
 *
 */
const handleNextToken = (
  items: Array<any>,
  selectedItems: Array<any>,
  requesterItems: Array<any>,
  cols: any,
  sort?: ListSort,
) => {
  const filteredSelectedItems = selectedItems.filter(sItem => requesterItems.find(rItem => rItem.id === sItem.id));
  const itemsMap = new Map(items.map(item => [item.id, item]));

  requesterItems.forEach(requesterItem => {
    if (!itemsMap.has(requesterItem.id)) {
      itemsMap.set(requesterItem.id, requesterItem);
    }
  });

  const mergedItems = Array.from(itemsMap.values());
  const filteredRequesterItems = removeSelectedOnFilteredItems(filteredSelectedItems, mergedItems);

  return sortList(cols, { items: [...filteredSelectedItems, ...filteredRequesterItems], sort: sort! });
};

const isRequestFilteredItems = (query: { and: Array<any>; or: Array<any> }) =>
  !!(query && ((query.and && query.and.length > 0) || (query.or && query.or.length > 0)));

/**
 *
 * This method calls the Requester API in order to filter the list
 * We generate the query, calling the API, then dispatching the result inside the store
 *
 * @usedIn - Requester
 *
 * @param fun - Requester API method
 * @param payload - Requester API payload
 * @param dispatch - A hook to call the store
 * @param previousItems - List previous items
 * @param nextToken - String used for the API used to request next items
 *
 * @returns - void
 *
 * @author - Jennifer Charlois x Arthur Escriou
 *
 */
const filterListElement = async ({
  query,
  isEditable,
  fun,
  selectedItems,
  items,
  dispatch,
  type,
  cols,
  sort,
  nextToken,
}: RequesterProps) => {
  const data = await fun(query, sort, nextToken);

  const isFilteredItems = isRequestFilteredItems(query);

  // Not editable list
  if (!isEditable) {
    // Add a filter or scrolling or sorting a column
    if (isFilteredItems) {
      requestFilteredItems(
        {
          query,
          type,
          data: {
            ...data,
            items: nextToken
              ? // Display selected elements (if match) + delete duplicate items
                handleNextToken(items, selectedItems, data.items, cols, sort)
              : // Delete selected elements to requester response if no match
                handleAddFilter(selectedItems, data.items, cols, sort),
            sort,
          },
        },
        dispatch,
      );
    } else {
      // Delete a filter or scrolling or sorting a column
      requestItems(
        {
          type,
          data: {
            ...data,
            items: nextToken
              ? // Display selected elements (if match) + delete duplicate items
                handleNextToken(items, selectedItems, data.items, cols, sort)
              : // Add selected elements to requester response
                handleDeleteFilter(items, selectedItems, data.items, cols, sort),
            sort,
          },
        },
        dispatch,
      );
    }
  } else if (isEditable) {
    if (isFilteredItems) {
      // Add a filter
      requestFilteredItems(
        {
          query,
          type,
          data: {
            ...data,
            items: getSelectedFilteredItemsEditableList(items, data.items),
            sort,
          },
        },
        dispatch,
      );
    } else {
      selectedItems.forEach(sItem => {
        const item = data.items.find((item: any) => item.id === sItem.id);
        if (item && item.selected !== sItem.selected) {
          item.selected = sItem.selected;
        }
      });
      // Delete a filter
      requestItems(
        {
          type,
          data: {
            ...data,
            sort,
          },
        },
        dispatch,
      );
    }
  }
};

const filterList = ({
  query,
  isEditable,
  items,
  selectedItems,
  allSelected,
  dispatch,
  type,
  cols,
  sort,
  nextToken,
}: Props) => {
  switch (type) {
    case objectType.PRODUCT:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterProduct,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.CATEGORY:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterCategory,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.DISCOUNT:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterDiscount,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.PACK:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterPack,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.OPTION:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterOption,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.INVENTORY_MOVEMENT:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterInventoryMovement,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.SALE:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterSale,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.PAYMENT:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterPayment,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.CASHBOOK:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterCashbook,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.CUSTOMER:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterCustomer,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    case objectType.IMPORT:
      return filterListElement({
        query,
        isEditable,
        fun: listFilterBackgroundJob,
        items,
        selectedItems,
        dispatch,
        type,
        cols,
        sort,
        nextToken,
      });
    default:
      break;
  }
};

export { switchFilteredItemsToItems, toggleFilters, clearAllFilters, filterListElement, filterList };
