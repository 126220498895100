import { useSelector, useDispatch } from 'react-redux'

import { updateModal } from '../../../actions/modal'
import { listPaymentMethods, listPaymentMethodsItemsNextToken } from '../../../store/selector'
import List from '../../../components/list'
import { objectType } from '../../../store/types'
import PaymentMethodModal from './modal'

const PaymentMethods = () => {
  const paymentMethods = useSelector(listPaymentMethods)
  const { items, nextToken } = useSelector(listPaymentMethodsItemsNextToken)
  const dispatch = useDispatch()

  const handleCreate = () =>
    updateModal(
      dispatch,
      true,
      () => <PaymentMethodModal />,
      () => {}
    )

  return (
    <List
      cols={paymentMethods.cols!}
      items={items}
      fullItems={items}
      nextToken={nextToken}
      type={objectType.PAYMENT_METHOD}
      onCreate={handleCreate}
    />
  )
}

export default PaymentMethods
