import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getSelectedUser, getStatsFilters } from '../../../store/selector'
import { closeAndResetModal, updateModal } from '../../../actions/modal'
import { TypeFormError, ValidationFunction } from '../../../format/errors/types'
import emailValidator from '../../../format/errors/filters/export'
import ErrorModal from '../../newComponents/modal/error'
import { findErrorsInState } from '../../../format/errors'
import { callService } from '../../../services'
import { exportInventory } from '../../../graphql/queries'
import { getCompanyID } from '../../../services/localStorage'
import Input from '../../newComponents/input'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../newComponents/types'
import { handleBlur } from '../../../format/form'
import generateDataCy from '../../../utils/cypress'
import Button from '../../newComponents/button'

import styles from '../../newComponents/modal/Modal.module.css'

type ExportState = {
  email: string
  showSubmit: boolean
  errors?: {
    email?: TypeFormError
  }
  validators: {
    email?: ValidationFunction
  }
}

const ExportList = () => {
  const initialState: ExportState = {
    email: '',
    showSubmit: true,
    errors: {},
    validators: {
      email: emailValidator,
    },
  }
  const [state, setState] = useState(initialState)
  const user = useSelector(getSelectedUser)
  const statsFilters = useSelector(getStatsFilters)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'requester', value: 'export' })

  useEffect(() => {
    if (user) setState({ ...state, email: user.email })
  }, [])

  const handleChange = (field: string) => (event: any) => {
    // @ts-ignore
    state.errors[field] = null
    setState({ ...state, [field]: event, showSubmit: true })
  }

  const handleResult = (res: any) => {
    if (res.errors) {
      updateModal(dispatch, true, ErrorModal, () => { }, "export")
    } else {
      closeAndResetModal(dispatch)
    }
  }

  const handleSubmit = () => {
    setState({ ...handleBlur(state, 'email'), showSubmit: false })

    if (!findErrorsInState(state.errors)) {      
      return callService(
        {
          companyID: getCompanyID(),
          shopIDs: statsFilters.shopIDs ?? undefined,
          date: statsFilters.stockValue.date ?? undefined,
          email: state.email,
        },
        exportInventory,
        'exportInventory'
      ).then(handleResult)
    }
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalTitleContainer}>
        <div className={styles.modalTitleCustom}>{t('list.export.TITLE')}</div>
      </div>
      <div className={styles.modalContentCustomFlex}>
        <div className={styles.modalInfo}>{t('list.export.EMAIL')}</div>
        <div className={styles.modalInfoExport}>
          <span className={styles.modalImportantInfoExport}>{t('list.export.EMAIL_SPAN')}</span>{" "}
          {t('list.export.EMAIL_SUB')}
        </div>
        <div style={{ paddingTop: '8px' }} >
        <Input
          label={t('list.export.LABEL')}
          value={state.email}
          onChange={handleChange('email')}
          onBlur={() => setState(handleBlur(state, 'email'))}
          onSubmit={handleSubmit}
          style={{
            status: state.errors?.email?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={state.errors?.email?.value ? state.errors.email.message : undefined}
          dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
        />
        </div>
      </div>
      <div className={styles.modalButtonsCustom}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
          dataCy={'cancel'}
        />
        <Button
          title={t('button.SEND')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default ExportList
