import { UpdateOptionInput } from '../../../../../API'
import { updateOption } from '../../../../../graphql/custom/mutations'
import { callService } from '../../../../../services'
import { APIOptionValues, TypeOption } from '../../../../../types'
import { createInput } from '../../../../../utils/dirties'
import { convertPrice } from '../../../../../utils/number'
import { parseName } from '../../../../../utils/string'

const callOptionUpdate = async (payload: TypeOption, decimals: number) => {
  let input
  if (payload.id) {
    const inputOption: any = createInput(payload)
    if (inputOption.values) {
      inputOption.values = inputOption.values.map((value: APIOptionValues) => ({
        name: parseName(value.name),
        price: value.price ? convertPrice(decimals, value.price) : 0,
      }))
      input = inputOption
    } else {
      input = {
        ...inputOption,
        name: payload.name,
        values: payload.values?.map((value: APIOptionValues) => ({
          name: parseName(value.name),
          price: value.price ? convertPrice(decimals, value.price) : 0,
        })),
      }
    }

    return callService<{ input: UpdateOptionInput }>({ input }, updateOption, 'updateOption')
  }
  return null
}

export default callOptionUpdate
