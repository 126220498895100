/* eslint-disable no-use-before-define */
import { useState, useEffect, useRef, RefObject, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import dayjs from 'dayjs';

import ValidButton from '../oldComponents/buttons/validButton'
import UniqueDropDown, { isOutsideViewport } from '../newComponents/dropDown/unique'
import Input from '../newComponents/input'
import { TypeComparator, TypeFilter, TypeRequesterFilters, TypePath, TypeRequester } from './types'
import ListItem from '../newComponents/listItem'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../newComponents/types'
import Button from '../newComponents/button'
import Layer from '../newComponents/layer'
import { selectedCompany } from '../../store/selector'
import DisplayIcon2 from '../newComponents/icons'
import IconTouch from '../newComponents/icons/iconTouch'
import { displayCurrency } from '../../utils/number'
import { displayDateShortMonth, formatDate } from '../../utils/date'
import { TypeFormError, ValidationFunction } from '../../format/errors/types'
import {
  percentage as percentageValidator,
  currency as currencyValidator,
  number as numberValidator,
} from '../../format/errors/filters/requester'
import { findErrorsInState } from '../../format/errors'

import {
  Add,
  List as ListIcon,
  Calendar,
  Close,
  NumberOne,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Text,
  ToggleOn,
  AddCircle,
  KeyboardArrowDown,
} from '../../assets/icons'
import styles from './Requester.module.css'
import stylesList from '../newComponents/list/List.module.css'
import generateDataCy from '../../utils/cypress'
import { DatePickerRequester } from '../newComponents/datePicker'

const translateFilterID = (filter: any, t: (field: string) => string): string => {
  if (filter.filterID === 'IS_DECLINED' && filter.comparator === TypeComparator.NE) return t('list.col.IS_NOT_DECLINED')

  return t('list.col.' + filter.filterID) !== 'list.col.' + filter.filterID
    ? t('list.col.' + filter.filterID)
    : filter.filterID
}

const translateFilterComparator = (filter: any, t: (field: string) => string): string => {
  if (filter.filterID !== 'IS_DECLINED') {
    if (filter.comparator === TypeComparator.EXISTS)
      return t('list.requester.boolean.' + filter.comparator.toUpperCase())

    if (filter.type === TypeFilter.BOOLEAN || filter.type === TypeFilter.DATE)
      return t('list.requester.' + filter.type.toString().toLowerCase() + '.' + filter.comparator.toUpperCase())

    return t('list.requester.' + filter.comparator.toUpperCase())
  }
  return ''
}

const translateFilterValue = (
  filter: any,
  t: (field: string) => string,
  currency?: string,
  decimals?: number
): string => {
  if (filter.filters.length > 0) {
    if (filter.filters[0].type === TypeFilter.CURRENCY && typeof filter.value !== 'boolean' && decimals !== null && decimals !== undefined && currency)
      return filter.value + ' ' + displayCurrency(currency)

    if (filter.filters[0].type === TypeFilter.PERCENTAGE && decimals !== null && decimals !== undefined) return filter.value + ' %'

    if (filter.filters[0].type === TypeFilter.DATE) return displayDateShortMonth(filter.value)

    if (filter.filters[0].type === TypeFilter.LIST) {
      if (filter.filterID === 'DESTINATION' && filter.value === 'TRASH') return t('list.col.TRASH')
      if (filter.filterID === 'ORIGIN' && filter.value === 'SUPPLIER' && filter.value === 'SUPPLIER')
        return t('list.col.SUPPLIER')

      const element = filter.filters[0].list.find((element: { id: string }) => element.id === filter.value)
      return element ? element.value : filter.value
    }
  }

  return filter.value
}

const DisplayFilter = ({
  index,
  operatorID,
  isFirst,
  isLast,
  fields,
  filter,
  onAddFilter,
  onCreateFilter,
  onRemoveFilter,
}: {
  index: number
  operatorID: string
  isFirst?: boolean
  isLast?: boolean
  fields: Array<TypeRequester>
  filter: TypeRequester
  onAddFilter: (filterID: string) => (filter: TypeRequester) => void
  onCreateFilter: (linkedFilter: TypeRequester, newFilter: TypeRequester | TypeRequester, filterIndex: number) => void
  onRemoveFilter: (filterIndex: number, operatorID: string) => void
}) => {
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'requester', value: 'filter' })

  return (
    <div className={styles.containerFilters}>
      <div
        className={styles.filter}
        style={{
          borderTopLeftRadius: isFirst ? '16px' : 'inherit',
          borderBottomLeftRadius: isFirst ? '16px' : 'inherit',
        }}
      >
        <div className={styles.filterName} data-cy={generateDataCy({ scope: cyContext, value: filter.filterID })}>
          {translateFilterID(filter, t)} {translateFilterComparator(filter, t)}{' '}
          {translateFilterValue(filter, t, company.currency, company.currencyDecimals)}
        </div>
        <IconTouch
          Icon={Close}
          onClick={() => onRemoveFilter(index!, operatorID!)}
          color="var(--sys-color-content-secondary)"
          dataCy={generateDataCy({scope: cyContext, value: filter.field + '-' + filter.comparator + '-' + 'remove'})}
        />
      </div>
      {isLast && (
        <div
          style={{
            marginRight: '1em',
          }}
        >
          <AddFilter
            index={index}
            operatorID={operatorID}
            Element={
              <div className={styles.filterAdd}>
                <DisplayIcon2 Icon={AddCircle} color="var(--sys-color-content-secondary)" />
              </div>
            }
            fields={fields}
            onAddFilter={(newFilter) => {
              isFirst ? onCreateFilter(filter, newFilter, index!) : onAddFilter(operatorID!)(newFilter)
            }}
          />
        </div>
      )}
    </div>
  )
}

const DisplayRequester = ({
  hasParent = false,
  index,
  operatorID,
  isFirst,
  isLast,
  fields,
  requester,
  onAddFilter,
  onCreateFilter,
  onUpdateOperator,
  onRemoveFilter,
}: {
  index: number
  operatorID: string
  hasParent?: boolean
  isFirst?: boolean
  isLast?: boolean
  fields: Array<TypeRequester>
  requester: TypeRequester
  onAddFilter: (filterID: string) => (filter: TypeRequester) => void
  onCreateFilter: (linkedFilter: TypeRequester, newFilter: TypeRequester | TypeRequester, filterIndex: number) => void
  onUpdateOperator: (filterID: string, operator: string) => void
  onRemoveFilter: (filterIndex: number, operatorID: string) => void
}) => {
  const [state, setState] = useState({
    requester,
    operators: [
      { comparator: TypeComparator.AND, value: TypeComparator.EQ },
      { comparator: TypeComparator.OR, value: TypeComparator.EQ },
    ],
    displayOperators: false,
  })
  const { t } = useTranslation()

    useEffect(() => setState({ ...state, requester }), [requester])

  const handleSubmitOperator = ({
    comparator,
    value,
  }: {
    comparator: TypeComparator
    value: string | number | boolean
  }) => {
    onUpdateOperator(state.requester.filterID, comparator)
    setState({ ...state, displayOperators: false })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: hasParent ? 'nowrap' : 'wrap',
      }}
    >
      {state.requester.type === TypePath.OPERATOR ? (
        <>
          {state.requester.fields?.map((field, index, tab) => (
            <div style={{ display: 'flex' }} key={index}>
              <DisplayRequester
                index={index}
                hasParent={true}
                isFirst={index === 0 || !hasParent}
                isLast={index === tab.length - 1 || !hasParent}
                fields={fields}
                requester={field}
                operatorID={state.requester.filterID}
                onAddFilter={onAddFilter}
                onCreateFilter={onCreateFilter}
                onUpdateOperator={onUpdateOperator}
                onRemoveFilter={onRemoveFilter}
              />
              {index < tab.length - 1 && (
                <div
                  style={{
                    margin: !hasParent ? '0 0.5em 0.5em -0.5em' : '0 0 0.5em 0',
                  }}
                >
                  <UniqueDropDown
                    eventID={'requester'}
                    parentID={'operator' + state.requester.filterID + index}
                    popUpID={'operatorPopUp' + state.requester.filterID + index}
                    Element={
                      <div
                        className={styles.operator}
                        style={{
                          borderRadius: hasParent ? '0' : '4px',
                        }}
                      >
                        <Layer
                          style={{
                            padding: '12px 8px',
                            borderRadius: hasParent ? '0' : '4px',
                            borderTop: hasParent ? '1px solid var(--sys-color-border-default)' : 'none',
                            borderBottom: hasParent ? '1px solid var(--sys-color-border-default)' : 'none',
                          }}
                        >
                          {t('list.requester.' + state.requester.field.toUpperCase())}
                        </Layer>
                      </div>
                    }
                    items={state.operators.map((operator) => ({
                      id: operator.comparator,
                      value: t('list.requester.' + operator.comparator.toUpperCase()),
                      size: ComponentSize.MEDIUM,
                      onClick: (id: any) => {
                        const operator = state.operators.find((operator) => operator.comparator === id)
                        if (operator) handleSubmitOperator(operator)
                      },
                    }))}
                    dataCy="fer"
                  />
                </div>
              )}
            </div>
          ))}
        </>
      ) : (
        <DisplayFilter
          index={index}
          operatorID={operatorID}
          isFirst={isFirst}
          isLast={isLast}
          fields={fields}
          filter={state.requester}
          onAddFilter={onAddFilter}
          onCreateFilter={onCreateFilter}
          onRemoveFilter={onRemoveFilter}
        />
      )}
    </div>
  )
}

const DatePickerPortal = ({ children }: any) => {
  const [element, setElement] = useState<HTMLElement>()

  useEffect(() => {
    const portal = document.getElementById('portal')
    if (portal) {
      const dateElement = document.getElementById('dateID')?.getBoundingClientRect()
      if (dateElement) {
        portal.style.position = 'absolute'
        portal.style.top = 8 + dateElement?.top + 'px'
        portal.style.left = 16 + dateElement?.left + 'px'
        setElement(portal)
      }
    }
  }, [])

  if (!element) {
    return null
  }

  return createPortal(children, element)
}

const DisplayFilters = ({
  parentID,
  popUpID,
  filters,
  onSubmit,
  onBlur,
}: {
  parentID: string
  popUpID: string
  filters: Array<TypeRequesterFilters>
  onSubmit: ({ comparator, value }: { comparator: TypeComparator; value: string | number | boolean }) => void
  onBlur: (event: any) => void
}) => {
  const initialState: {
    filters: Array<TypeRequesterFilters>
    comparator?: TypeComparator
    value?: string | number | boolean
    displayInput: boolean
    showSubmit: boolean
    errors: {
      value?: TypeFormError
    }
    validators: {
      currency: ValidationFunction
      percentage: ValidationFunction
      number: ValidationFunction
    }
  } = {
    filters,
    displayInput: false,
    showSubmit: false,
    errors: {},
    validators: {
      currency: currencyValidator,
      percentage: percentageValidator,
      number: numberValidator,
    },
  }
  const [state, setState] = useState(initialState)
  const company = useSelector(selectedCompany)
  const ref: RefObject<any> = useRef()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'topbar', value: 'filter' })

  const handlePosition = () => {
    if (document.getElementById(parentID) && document.getElementById(popUpID)) {
      const popupElement = document.getElementById(popUpID)

      if (popupElement) {
        popupElement.style.top = isOutsideViewport(parentID, popUpID).top

        if (isOutsideViewport(parentID, popUpID).left)
          popupElement.style.left = isOutsideViewport(parentID, popUpID).left
        else popupElement.style.right = isOutsideViewport(parentID, popUpID).right
      }
    }
  }

  useEffect(() => {
    if (state.comparator && ref.current) {
      ref.current.focus()
    }
  }, [state.comparator])

  useLayoutEffect(() => {
    handlePosition()

    document.getElementById('requester')?.addEventListener('scroll', handlePosition)
    return () => window.removeEventListener('scroll', () => { })
      }, [])

  const handleClickRadio = (id: TypeComparator, type: TypeFilter) => {
    if (type === TypeFilter.BOOLEAN) {
      state.value = true
      state.showSubmit = true
      state.displayInput = false
    } else {
      state.value = ''
      state.displayInput = true
      state.showSubmit = false
    }

    setState({ ...state, comparator: id })
  }

  const handleChange = (_: string) => (value: string) => {
    setState({ ...state, showSubmit: !!value, errors: {}, value })
  }

  const handleBlur = (filter: any) => {
    if (filter.type === TypeFilter.PERCENTAGE || filter.type === TypeFilter.CURRENCY) {
      let validator: ValidationFunction
      if (filter.type === TypeFilter.PERCENTAGE) validator = state.validators.percentage
      else validator = state.validators.currency
      // @ts-ignore
      state.errors.value = validator ? validator(state.value) : null
      setState({ ...state, showSubmit: !findErrorsInState(state.errors) })
    } else if (filter.type === TypeFilter.NUMBER) {
      // @ts-ignore
      const validator: ValidationFunction = state.validators.number
      // @ts-ignore
      state.errors.value = validator ? validator(state.value) : null
      setState({ ...state, showSubmit: !findErrorsInState(state.errors) })
    }
  }

  const handleSubmit = () => {
    if (state.comparator && state.value) onSubmit({ comparator: state.comparator, value: state.value })
    setState(initialState)
  }

  const parseDate = () =>
    state.value &&
    // @ts-ignore
    (displayDateShortMonth(state.value) !== 'Invalid Date' ? displayDateShortMonth(state.value) : 'jj/mm/aaaa')

  return (
    <div
      id={popUpID}
      tabIndex={0}
      className={stylesList.list}
      style={{
        width: '265px',
      }}
    >
      {state.filters.map((filter) => (
        <div key={filter.comparator}>
          <ListItem
            item={translateFilterComparator(filter, t)}
            onClick={() => handleClickRadio(filter.comparator, filter.type)}
            LeadIcon={filter.comparator === state.comparator ? RadioButtonChecked : RadioButtonUnchecked}
            dataCy={generateDataCy({
              scope: cyContext,
              value: filter.comparator,
            })}
          />
          {state.displayInput &&
            filter.comparator === state.comparator &&
            (filter.type === TypeFilter.LIST ? (
              <div className={styles.filterTextField}>
                <UniqueDropDown
                  parentID={'select'}
                  popUpID={'selectPopUp'}
                  Element={
                    <Input
                      value={
                        typeof state.value === 'string' &&
                        filter.list?.find((l: { id: string }) => l.id === state.value)
                          ? filter.list.find((l: { id: string }) => l.id === state.value).value
                          : ''
                      }
                      style={{
                        trailIcons: [{ icon: KeyboardArrowDown }],
                        readOnly: true,
                      }}
                      dataCy={generateDataCy({ scope: cyContext, value: 'input' })}
                    />
                  }
                  items={filter.list?.map((l: any) => ({ ...l, onClick: handleChange('') }))}
                  dataCy="test"
                />
              </div>
            ) : filter.type === TypeFilter.DATE ? (
              <div
                id="dateID"
                style={{
                  height: '56px',
                }}
                data-cy={generateDataCy({ scope: 'requester', value: 'date' })}
              >
                <DatePickerRequester value={null} onChange={(date) => {
                    if (date) {
                      state.value = date // dayjs(date).format()
                      setState({ ...state, showSubmit: true })
                    }
                  }}
                />
              </div>
            ) : (
              <div className={styles.filterTextField}>
                <Input
                  ref={ref}
                  type={filter.type === TypeFilter.PERCENTAGE ? 'currency' : filter.type.toLowerCase()}
                  value={
                    state.value && filter.type === TypeFilter.PERCENTAGE
                      ? state.value + ' %'
                      : state.value && filter.type === TypeFilter.CURRENCY
                      ? state.value + (company ? ' ' + displayCurrency(company.currency) : '')
                      : state.value
                  }
                  onChange={handleChange('')}
                  onBlur={() => handleBlur(filter)}
                  style={{
                    status: state.errors.value?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                  }}
                  helperText={state.errors?.value?.value ? state.errors.value.message : undefined}
                  suffix={
                    filter.type === TypeFilter.PERCENTAGE
                      ? '%'
                      : filter.type === TypeFilter.CURRENCY && company
                      ? displayCurrency(company.currency)
                      : undefined
                  }
                  dataCy={generateDataCy({ scope: cyContext, value: 'input' })}
                />
              </div>
            ))}
        </div>
      ))}
      <ValidButton
        onClick={handleSubmit}
        disabled={!state.showSubmit}
        dataCy={generateDataCy({ scope: cyContext, value: 'submit' })}
      />
    </div>
  )
}

/**
 *
 * This component displays a custom add button and the dropdown with all fields
 *
 * @usedIn - Requester
 *
 * @param index - Index in current object
 * @param operatorID - An id to dynamically adapt dropdown's position
 * @param Element - Dynamic add button displayed to trigger the dropdown
 * @param fields - All fields
 * @param onAddFilter - A method, createFilter ou addFilter depending of the depth
 *
 *
 * @author - Jennifer Charlois
 *
 */
export const AddFilter = ({
  index,
  operatorID,
  Element,
  fields,
  onAddFilter,
}: {
  index: number
  operatorID: string
  Element: any
  fields: Array<TypeRequester>
  onAddFilter: (filter: TypeRequester) => void
}) => {
  const initialState: {
    field?: TypeRequester
  } = {}
  const [state, setState] = useState(initialState)
  const ref: RefObject<any> = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    if (state.field && ref.current) {
      ref.current.focus()
    }
  }, [state.field])

  const selectField = (field: TypeRequester | undefined) => setState({ ...state, field })

  const handleSubmit = ({ comparator, value }: { comparator: TypeComparator; value: string | number | boolean }) => {
    onAddFilter({ ...state.field!, comparator, value })
    setState({ ...state, field: undefined })
  }

  const displayLeadIcon = (field: any) => {
    switch (field.filters[0].type) {
      case TypeFilter.STRING:
        return Text
      case TypeFilter.CURRENCY:
        return NumberOne
      case TypeFilter.NUMBER:
        return NumberOne
      case TypeFilter.LIST:
        return ListIcon
      case TypeFilter.DATE:
        return Calendar
      default:
        return ToggleOn
    }
  }

  const handleBlur = (event: any) => {
    if (
      !event.relatedTarget ||
      (event.relatedTarget &&
        event.relatedTarget.id !== 'addFilterPopUp' + operatorID + index &&
        event.relatedTarget.id !== 'listItemIconID')
    ) {
      selectField(undefined)
    }
  }

  return (
    <>
      {!state.field && (
        <UniqueDropDown
          eventID="requester"
          parentID={'addFilter' + operatorID + index}
          popUpID={'addFilterPopUp' + operatorID + index}
          Element={Element}
          items={fields.map((field) => ({
            id: field.filterID,
            value:
              t('list.col.' + field.filterID) !== 'list.col.' + field.filterID
                ? t('list.col.' + field.filterID)
                : field.filterID,
            onClick: (id: any) => {
              const field = fields.find((field) => field.filterID === id)
              if (field) selectField(field)
            },
            icon: !field.section ? displayLeadIcon(field) : undefined,
            state: field.section ? ComponentState.DISABLED : ComponentState.DEFAULT,
            size: ComponentSize.LARGE,
          }))}
          minWidth="256px"
          dataCy={generateDataCy({ scope: 'topbar', value: 'filter' })}
        />
      )}
      {state.field && (
        <>
          <div
            id={'addFilter' + operatorID + index}
            ref={ref}
            tabIndex={0}
            onClick={() => selectField(undefined)}
            onBlur={handleBlur}
          >
            {Element}
          </div>
          <DisplayFilters
            parentID={'addFilter' + operatorID + index}
            popUpID={'addFilterPopUp' + operatorID + index}
            filters={state.field.filters}
            onSubmit={handleSubmit}
            onBlur={handleBlur}
          />
        </>
      )}
    </>
  )
}

const Requester = ({
  requester,
  fields,
  onChange,
}: {
  requester?: TypeRequester
  fields: Array<TypeRequester>
  onChange: (query: any) => void
}) => {
  const initialState: {
    requester: TypeRequester
  } = {
    requester: {
      filterID: 'uuid_1',
      type: TypePath.OPERATOR,
      field: 'and',
      path: [],
      filters: [],
      fields: [],
    },
  }
  const [state, setState] = useState(initialState)
  const { t } = useTranslation()

  useEffect(() => {
    setState({ ...state, requester: requester ?? initialState.requester })
      }, [requester])

  const createFilter = (linkedFilter: TypeRequester, newFilter: TypeRequester | TypeRequester, filterIndex: number) => {
    const parsedFilter: TypeRequester = {
      filterID: newFilter.filterID,
      type: newFilter.type,
      field: newFilter.field,
      path: newFilter.path,
      filters: newFilter.filters,
      comparator: newFilter.comparator,
      value: newFilter.value,
      transformer: newFilter.transformer,
    }

    const newDepth: TypeRequester = {
      filterID: uuidv4(),
      type: TypePath.OPERATOR,
      field: 'and',
      path: [],
      filters: [],
      fields: [linkedFilter, parsedFilter],
    }

    if (state.requester.fields) state.requester.fields.splice(filterIndex, 1, newDepth)

    onChange(state.requester)
  }

  const addFilter = (filterID: string) => (filter: TypeRequester | TypeRequester) => {
    const parsedFilter: TypeRequester = {
      filterID: filter.filterID,
      type: filter.type,
      field: filter.field,
      path: filter.path,
      filters: filter.filters,
      comparator: filter.comparator,
      value: filter.value,
      transformer: filter.transformer,
    }

    if (state.requester.filterID === filterID && state.requester.fields) {
      state.requester.fields = [...state.requester.fields, parsedFilter]
    } else if (state.requester.fields) {
      const index = state.requester.fields.findIndex((field) => field.filterID === filterID)

      if (index !== -1) {
        state.requester.fields[index].fields = [...state.requester.fields[index].fields!, parsedFilter]
      }
    }

    onChange(state.requester)
  }

  const updateOperator = (filterID: string, operator: string) => {
    if (state.requester.filterID === filterID) {
      state.requester.field = operator
    } else if (state.requester.fields) {
      const index = state.requester.fields.findIndex((field) => field.filterID === filterID)

      if (index !== -1) {
        state.requester.fields[index].field = operator
      }
    }

    onChange(state.requester)
  }

  const removeFilter = (filterIndex: number, operatorID: string) => {
    if (state.requester.filterID === operatorID && state.requester.fields) {
      state.requester.fields = state.requester.fields.filter((_, index) => filterIndex !== index)
    } else if (state.requester.fields) {
      const index = state.requester.fields.findIndex((field) => field.filterID === operatorID)

      if (index !== -1 && state.requester.fields[index].fields) {
        state.requester.fields[index].fields = state.requester.fields[index].fields!.filter(
          (_, index) => filterIndex !== index
        )

        /*
          After deleting the filter, we check if there is only one element left in his group (depth 1)
          If so, we move the filter in the group above (depth 0) and delete the group (depth 1)
        */
        if (state.requester.fields[index].fields?.length === 1) {
          const removedFilter = state.requester.fields[index].fields![0]
          state.requester.fields.splice(index, 1, removedFilter)
        }
      }
    }

    onChange(state.requester)
  }

  return (
    <>
      <div id="requester" className={styles.requester}>
        <DisplayRequester
          index={-1}
          operatorID={'uuid_1'}
          fields={fields}
          requester={state.requester}
          onAddFilter={addFilter}
          onCreateFilter={createFilter}
          onUpdateOperator={updateOperator}
          onRemoveFilter={removeFilter}
        />
      </div>
      <div className={styles.addFilter}>
        <AddFilter
          index={-1}
          operatorID={'uuid_1'}
          Element={
            <Button
              title={t('list.requester.ADD')}
              type={ComponentType.TERTIARY}
              size={ComponentSize.SMALL}
              LeadIcon={Add}
            />
          }
          fields={fields}
          onAddFilter={addFilter('uuid_1')}
        />
      </div>
    </>
  )
}

export default Requester
