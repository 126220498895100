import i18nEn from './assets/i18n/en.json'
import i18nFr from './assets/i18n/fr.json'

const config = {
  resources: {
    en: {
      translation: i18nEn,
    },
    fr: {
      translation: i18nFr,
    },
  },
  lng: navigator.language.substring(0, 2),
  fallbackLng: 'en',
  interpolation: { escapeValue: true },
  nsSeparator: 'nsSeparator',
}

export default config
