import { ProductTagInput, CreateTagInput } from '../../../../../../API'
import { createProductTag, createTag, deleteProductTag } from '../../../../../../graphql/custom/mutations'
import { callService } from '../../../../../../services'
import { getCompanyID } from '../../../../../../services/localStorage'
import { DataWithErrors } from '../../../../../../services/types'
import { TypeTag } from '../../../../../../types'

const callTagDetach = (productID: string, tag: TypeTag) =>
  callService<{ input: ProductTagInput }>({ input: { productID, tagID: tag.id } }, deleteProductTag, 'deleteProductTag')

const callTagToDetach = async (productID: string, tagsToDetach: Array<TypeTag>): Promise<Array<DataWithErrors>> => {
  const results = []
  while (tagsToDetach.length > 0) {
    const tag = tagsToDetach.pop()
    const res = await callTagDetach(productID, tag!)
    results.push(res)
  }
  return results
}

const callCreationAndlinkToProductTag = (productID: string, name: string) =>
  callService<{ input: CreateTagInput }>(
    { input: { catalogID: getCompanyID(), name } },
    createTag,
    'createTag'
  ).then(({ data }) =>
    callService<{ input: ProductTagInput }>(
      { input: { productID, tagID: data.id } },
      createProductTag,
      'createProductTag'
    )
  )

const callTagCreation = (productID: string, { id: tagID, name }: { id: string; name: string }) =>
  tagID === ''
    ? callCreationAndlinkToProductTag(productID, name)
    : callService<{ input: ProductTagInput }>({ input: { productID, tagID } }, createProductTag, 'createProductTag')

const callTagToCreate = async (productID: string, tagsToCreate: Array<TypeTag>): Promise<Array<DataWithErrors>> => {
  const results = []
  while (tagsToCreate.length > 0) {
    const tag = tagsToCreate.pop()
    const res = await callTagCreation(productID, tag!)
    results.push(res)
  }
  return results
}

export { callTagToCreate, callTagToDetach }
