const EyeShow = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1614 4.5C7.16138 4.5 2.89138 7.61 1.16138 12C2.89138 16.39 7.16138 19.5 12.1614 19.5C17.1614 19.5 21.4314 16.39 23.1614 12C21.4314 7.61 17.1614 4.5 12.1614 4.5ZM12.1614 17C9.40138 17 7.16138 14.76 7.16138 12C7.16138 9.24 9.40138 7 12.1614 7C14.9214 7 17.1614 9.24 17.1614 12C17.1614 14.76 14.9214 17 12.1614 17ZM12.1614 9C10.5014 9 9.16138 10.34 9.16138 12C9.16138 13.66 10.5014 15 12.1614 15C13.8214 15 15.1614 13.66 15.1614 12C15.1614 10.34 13.8214 9 12.1614 9Z"
      fill={color}
    />
  </svg>
)

export default EyeShow
