import { forwardRef, useEffect, useState } from 'react'

import generateDataCy from '../../../utils/cypress'
import DisplayIcon2 from '../icons'
import { ComponentState, ComponentStatus } from '../types'

import styles from './Input.module.css'
import { useSelector } from 'react-redux'
import { selectedCompany } from '../../../store/selector'

type Props = {
  id?: string
  type?: string
  label?: string
  value?: any
  max?: number
  onChange?: (event: any) => void
  onBlur?: (event?: any) => void
  onFocusNext?: () => void
  onClick?: () => void
  onSubmit?: () => void
  placeholder?: string
  helperText?: string
  suffix?: string
  style?: {
    state?: ComponentState
    status?: ComponentStatus
    LeadIcon?: any
    trailIcons?: Array<{ icon: any; message?: string; onClick?: () => void }>
    readOnly?: boolean
    width?: string
  }
  isSelectOnFocus?: boolean
  dataCy?: string
}

/**
 *
 * This element displays a textField
 *
 * @param type - TextField's type
 * @param label - TextField's label
 * @param value - TextField's value
 * @param max - A max number used when textField's type is number
 * @param onChange - A CTA when we type the field
 * @param placeholder - TextField's placeholder
 * @param helperText - TextField's helper text
 * @param suffix - A value's suffix
 * @param decimals - Currency's decimals
 * @param state - TextField's state
 * @param status - TextField's status
 * @param LeadIcon - A Leadicon
 * @param trailIcons - An array of trail icons
 * @param dataCy - An unique id
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Input = forwardRef(
  (
    {
      id,
      type = 'text',
      label,
      max,
      value,
      onChange,
      onBlur,
      onFocusNext,
      onClick,
      onSubmit,
      placeholder,
      helperText,
      suffix,
      style,
      isSelectOnFocus,
      dataCy,
    }: Props,
    ref: any
  ): JSX.Element => {
    const initialState: {
      state: ComponentState
    } = {
      state: ref ? ComponentState.HOVER : style?.state ?? ComponentState.DEFAULT,
    }
    const [state, setState] = useState(initialState)
    // const company = useSelector(selectedCompany)

    const getCursorStyle = (value: string) => ({
      cursor: state.state === ComponentState.DISABLED ? 'not-allowed' : value,
    })

    const getColorStyle = (value: string) => ({
      color: `var(--sys-color-content-${state.state === ComponentState.DISABLED ? 'disabled' : value})`,
    })

    const getTextFieldStyle = () => ({
      border: `var(--sys-size-border-width-sm) solid var(--sys-color-border-${style && style.status === ComponentStatus.ERROR ? 'danger' : state.state
        })`,
      padding: style?.width ? '0 var(--sys-size-2)' : '0 var(--sys-size-4)',
    })

    const getInputStyle = () => ({
      ...getCursorStyle(style?.readOnly ? 'pointer' : 'text'),
      ...getColorStyle('primary'),
    })

    useEffect(
      () => {
        setState({
          ...state,
          state: ref ? ComponentState.HOVER : style?.state ?? ComponentState.DEFAULT,
        })
      },
      [value, ref, style?.state]
    )

    const updateState = (newState: ComponentState) =>
      state.state !== ComponentState.DISABLED && setState({ ...state, state: newState })

    const handleChange = (event: any) => {
      if (type === 'currency') {
        if (value && value.replace(suffix, '').trim() === event.target.value.trim()) {
          onChange && onChange(event.target.value.trim().slice(0, -1))
        } else {
          onChange && onChange(event.target.value.toString().replace(suffix, ' ').replace(/\s/g, '').replace(/,/g, '.'))
        }
      } else if (type === 'number') {
        onChange && onChange(event.target.value.toString().replace(/\s/g, ''))
      } else {
        if (max && event.target.value !== '') {
          event.target.value = event.target.value.substring(0, max)
        }
        onChange && onChange(event.target.value)
      }
    }

    // const handleNextFocus = (event: any) => {
    //   if (event.keyCode === 13 || event.keyCode === 9) {
    //     event.preventDefault()
    //     onFocusNext && onFocusNext()
    //   }
    // }

    return (
      <div
        style={{
          width: style?.width ?? '100%',
          ...getCursorStyle('default'),
        }}
      >
        <div
          id={id}
          // tabIndex={0}
          onMouseEnter={() => updateState(ComponentState.HOVER)}
          onMouseLeave={() => updateState(style?.state ?? ComponentState.DEFAULT)}
          // onKeyDown={handleNextFocus}
          onSubmit={(event: any) => {
            event.preventDefault()
            onSubmit && onSubmit()
          }}
          onClick={() => {
            onClick && onClick()
            updateState(ComponentState.FOCUSED)
            // setTimeout(() => (window.onscroll = () => (document.activeElement as HTMLElement)?.blur()), 500)
          }}
          onBlur={onBlur}
          className={styles.container}
          style={{ ...getTextFieldStyle() }}
          data-cy={generateDataCy({ scope: dataCy!, value: 'icon' })}
        >
          {style?.LeadIcon && (
            <DisplayIcon2 Icon={style.LeadIcon} color={getColorStyle('secondary').color} width="16px" height="16px" />
          )}
          <input
              id="inputID"
              type={type === 'number' || type === 'currency' ? 'text' : type}
              value={value === 'undefined €' ? '' : value}
              placeholder={placeholder}
              onChange={handleChange}
              autoComplete="off"
              onFocus={(e) => {
                isSelectOnFocus && e.target.select()
                // onClick && onClick()
                // updateState(ComponentState.FOCUSED)
                // setTimeout(() => (window.onscroll = () => (document.activeElement as HTMLElement)?.blur()), 500)
              }}
              ref={ref}
              disabled={state.state === ComponentState.DISABLED}
              style={getInputStyle()}
              data-cy={dataCy}
              readOnly={style?.readOnly}
              maxLength={type === 'number' || type === 'currency' ? 12 : undefined}
            />
          {label && (
            <label className={styles.label} style={{ ...getColorStyle('secondary'), ...getCursorStyle('default') }}>
              {label}
            </label>
          )}
          {style?.trailIcons &&
            style.trailIcons.map((trailIcon, index) => (
              <div key={index} style={{ gap: 'var(--sys-size-1)' }}>
                <DisplayIcon2
                  Icon={trailIcon.icon}
                  hoverMessage={trailIcon.message}
                  onClick={trailIcon.onClick}
                  color={getColorStyle(style && style.status === ComponentStatus.ERROR ? 'danger' : 'secondary').color}
                  width="var(--comp-textfield-size-icon)"
                  height="var(--comp-textfield-size-icon)"
                />
              </div>
            ))}
        </div>
        {helperText && (
          <div
            className={styles.helperText}
            style={{
              color:
                style?.status === ComponentStatus.ERROR
                  ? 'var(--comp-textfield-color-helper-text-error)'
                  : 'var(--sys-color-content-secondary)',
            }}
            data-cy={generateDataCy({ scope: dataCy!, value: 'helperText' })}
          >
            {helperText}
          </div>
        )}
      </div>
    )
  }
)

export default Input
