import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import DraggableList from '../../../../../../components/oldComponents/draggableList/draggableList'
import {
  variationName as variationNameValidator,
  variationValues as variationValuesValidator,
} from '../../../../../../format/errors/filters/catalog/product'
import { TypeFormError, ValidationFunction } from '../../../../../../format/errors/types'
import generateDataCy from '../../../../../../utils/cypress'
import { TypeVariation } from '../../../../../../types'
import { findErrorsInState } from '../../../../../../format/errors'
import { ComponentStatus } from '../../../../../../components/newComponents/types'
import Input from '../../../../../../components/newComponents/input'
import SubmitButton from '../../../../../../components/oldComponents/drawer/submitButton'

import stylesDrawer from '../../../../../../components/oldComponents/drawer/Drawer.module.css'
import styles from '../../../../../../components/newComponents/input/Input.module.css';

type State = {
  id: string
  previousName: string
  name: string
  list: Array<{
    id: string
    previousName: string
    name: string
    errorValue: TypeFormError
  }>
  showSubmit: boolean
  errors: {
    name: TypeFormError
  }
  validators: {
    name?: ValidationFunction
    list?: Array<{ errorValue: ValidationFunction }>
  }
  helperText: string | null
}

const FormVariation = ({
  variation,
  variationNames,
  handleDeleteVariation,
  handleAddVariation,
  isDeclined,
  dataCy,
}: {
  variation: TypeVariation
  variationNames: Array<string>
  handleDeleteVariation: () => void
  handleAddVariation: (variation: TypeVariation) => void
  isDeclined: boolean
  dataCy: string
}) => {
  const initialeState: State = {
    id: '',
    previousName: '',
    name: '',
    list: [],
    showSubmit: false,
    errors: {
      name: { value: false, message: '' },
    },
    validators: {
      name: variationNameValidator,
      list: [],
    },
    helperText: null,
  }
  const [state, setState] = useState(initialeState)
  const { t } = useTranslation()

  const cyContext = generateDataCy({ scope: dataCy, value: 'variation' })

  useEffect(() => {
    if (variation) {
      setState({
        ...state,
        id: variation.id,
        previousName: variation.previousName,
        name: variation.name,
        list: variation.values.map((value: any, index) => ({
          id: index.toString(),
          previousName: value.previousName,
          name: value.name,
          errorValue: { value: false, message: '' },
        })),
        validators: {
          name: variationNameValidator,
          list: variation.values.map(() => ({
            errorValue: variationValuesValidator,
          })),
        },
      })
    }
      }, [variation])

  const isValueError = () => {
    state.list?.forEach((value, index) => {
      const validator: ValidationFunction =
        // @ts-ignore
        state.validators.list[index].errorValue
      // @ts-ignore
      state.list[index].errorValue = validator
        ? // @ts-ignore
        validator(
          {
            item: value.name,
            items: state.list.map((value) => value.name),
          } ?? {
            value: false,
            message: '',
          }
        )
        : null
    })
    return !!state.list?.find((value) => value.errorValue && value.errorValue.value)
  }

  const handleBlur = (field: string) => {
    if (field === 'value') {
      state.showSubmit = !isValueError()
    } else {
      // @ts-ignore
      const validator: ValidationFunction = state.validators[field]
      // @ts-ignore
      state.errors[field] = validator
        ? validator(
          {
            item: state.name,
            items: [state.name, ...variationNames],
          } ?? {
            value: false,
            message: '',
          }
        )
        : null

      state.showSubmit = !findErrorsInState(state.errors)
    }
    setState({ ...state })
  }

  const handleChange = (field: string, index?: number) => (event: any) => {
    setState({ ...state, helperText: '' })
    if (field === 'name') {
      setState({
        ...state,
        name: event,
        errors: {
          ...state.errors,
          name: {
            value: false,
            message: '',
          },
        },
        showSubmit: true,
      })
    } else if (field === 'value') {
      // @ts-ignore
      state.list[index].name = event
      setState({
        ...state,
        list: state.list.map((value) => ({
          ...value,
          errorValue: {
            value: false,
            message: '',
          },
        })),
        showSubmit: true,
      })
    }
  }
  const handleCreate = (value: string) => {
    state.validators.list!.push({
      errorValue: variationValuesValidator,
    })
    if (value) {
      setState({
        ...state,
        list: [
          ...state.list,
          {
            id: state.list.length.toString(),
            previousName: value,
            name: value,
            errorValue: { value: false, message: '' },
          },
        ],
        showSubmit: true,
        helperText: ''
      })
    }
  }

  const handleDelete = (id: string | undefined, indexSent: number) => {
    if (!isDeclined) {
      setState({
        ...state,
        list: state.list
          .filter((value) => value.id !== id)
          .map((value) => ({
            ...value,
            errorValue: {
              value: false,
              message: '',
            },
          })),
        validators: {
          ...state.validators,
          list: state.validators.list!.filter((_, vIndex) => vIndex !== indexSent),
        },
        showSubmit: true,
        helperText: ''
      })
    }
  }

  const onDragEnd = (dragStart: number, dragEnd: number): void => {
    const valueInsert = state.list[dragStart]
    state.list = state.list.filter((_: {}, index: number) => index !== dragStart)
    state.list.splice(dragEnd, 0, valueInsert)
    setState({ ...state, showSubmit: true })
  }

  const onSubmit = () => {
    if (state.name === '' || state.list.length === 0) {
      setState({ ...state, helperText: "une valeur doit être présente" })
    } else {
      handleBlur('name')
      if (!isValueError() && !findErrorsInState(state.errors)) {
        handleAddVariation({
          id: state.id,
          previousName: state.previousName,
          name: state.name,
          values: state.list.map((l) => ({
            id: l.id,
            previousName: l.previousName,
            name: l.name,
          })),
        })
      } else {
        setState({ ...state, showSubmit: false, helperText: '' })
      }
    }
  }

  return (
    <div
      className={stylesDrawer.containerForm}
      style={{
        height: 'calc(100vh - (61px + var(--comp-button-size-height-lg) + 3em + 24px + 16px))',
      }}
    >
      <div className={stylesDrawer.overflow}>
        <div className={stylesDrawer.form}>
          <Input
            label={t('catalog.product.form.variation.NAME')}
            value={state.name}
            onChange={handleChange('name')}
            onBlur={() => handleBlur('name')}
            style={{
              status: state.errors?.name?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={state.errors?.name?.value ? state.errors.name.message : undefined}
            dataCy={generateDataCy({ scope: cyContext, value: 'name' })}
          />
          <DraggableList
            label={t('catalog.product.form.variation.VALUES')}
            list={state.list}
            entries={[]}
            onClickIcon={handleDelete}
            onDragEnd={onDragEnd}
            onChange={handleChange}
            onCreate={handleCreate}
            onBlur={(field) => (_: any) => handleBlur(field)}
            remove={isDeclined ? undefined : true}
            iconColor={isDeclined ? 'var(--sys-color-content-interactive)' : 'var(--sys-color-content-danger)'}
            addText={!isDeclined ? t('catalog.product.form.variation.ADD') : ''}
            dataCy={generateDataCy({ scope: cyContext, value: 'values' })}
            isEditable
            draggable={false}
            helperText={state.helperText}
            //@ts-ignore
            updateState={setState}
            stateVariation={state}
          />
          {state.helperText && (
            <div
              className={styles.helperText}
              style={{
                color: 'var(--comp-textfield-color-helper-text-error)',
                margin: 0,
              }}
              data-cy={generateDataCy({ scope: dataCy!, value: 'helperText' })}
            >
              {state.helperText}
            </div>
          )}
          {!isDeclined && (
            <div
              tabIndex={0}
              className={stylesDrawer.removeButton}
              onClick={handleDeleteVariation}
              onKeyPress={(key) => {
                if (key.key === 'Enter') handleDeleteVariation()
              }}
              data-cy={generateDataCy({
                scope: cyContext,
                value: 'remove_button',
              })}
            >
              {t('catalog.product.form.variation.DELETE')}
            </div>
          )}
        </div>
      </div>
      <SubmitButton showSubmit={state.showSubmit} helperText={state.helperText} handleSubmit={onSubmit} />
    </div>
  )
}

export default FormVariation
