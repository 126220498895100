import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import ImportModal from './modal'
import Button from '../../../components/newComponents/button'
import { ComponentSize, ComponentType } from '../../../components/newComponents/types'
import Template from '../../account/template'
import { listImportItemsNextToken, listImports } from '../../../store/selector'
import List from '../../../components/list'
import { ListState, objectType } from '../../../store/types'
import { updateModal } from '../../../actions/modal'

import styles from '../../account/Template.module.css'
import { useEffect } from 'react'
import { subscribeBackgroundJobs, unSubscribeBackgroundJobs } from '../../../getters/setting/import'
import { requestBackgroundJobs } from '../../../graphql/custom/queries'
import { getData } from '../../../services'
import { pluralize } from '../../../utils/typeToType'
import { fetchListElement, updateListState } from '../../../actions/list'

const Import = () => {
  const res = useSelector(listImports)
  const { items, nextToken, sort } = useSelector(listImportItemsNextToken)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    subscribeBackgroundJobs(dispatch)
    const getListBackgroundJobs = async () => {
      const listAllImport = await getData({ request: requestBackgroundJobs, limit: 100 });
      if (listAllImport.requestBackgroundJobs && listAllImport.requestBackgroundJobs.items && listAllImport.requestBackgroundJobs.items.length > 0) {
        const backgroundJobs = listAllImport.requestBackgroundJobs.items.filter((_: any) => _)
        fetchListElement(dispatch, backgroundJobs, listAllImport.requestBackgroundJobs.nextToken, pluralize(objectType.IMPORT))
        updateListState(
          { type: pluralize(objectType.IMPORT), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.IMPORT))
        updateListState(
          { type: pluralize(objectType.IMPORT), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListBackgroundJobs();

    return () => unSubscribeBackgroundJobs();

  }, []);

  const handleCreate = () =>
    updateModal(
      dispatch,
      true,
      () => <ImportModal />,
      () => { }
    )

  return (
    <>
      {items.length > 0 ? (
        <List
          cols={res.cols!}
          items={items}
          fullItems={items}
          nextToken={nextToken}
          sort={sort}
          type={objectType.IMPORT}
          onCreate={handleCreate}
        />
      ) : (
        <Template>
          <div className={styles.form}>
            <div className={styles.title}>{t('settings.import.TITLE')}</div>
            <div className={styles.subTitle}>
              {t('settings.import.SUBTITLE')}
              <ul>
                <li className={styles.subTitle}>{t('settings.import.EXCEL')}</li>
                <li className={styles.subTitle}>{t('settings.import.TACTILL')}</li>
              </ul>
            </div>
            <Button
              title={t('settings.import.modal.NEW')}
              size={ComponentSize.MEDIUM}
              type={ComponentType.PRIMARY}
              onClick={handleCreate}
              dataCy={'submit'}
            />
          </div>
        </Template>
      )}
    </>
  )
}

export default Import
