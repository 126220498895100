const Duplicate = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6614 1H4.66138C3.56138 1 2.66138 1.9 2.66138 3V16C2.66138 16.55 3.11138 17 3.66138 17C4.21138 17 4.66138 16.55 4.66138 16V4C4.66138 3.45 5.11138 3 5.66138 3H15.6614C16.2114 3 16.6614 2.55 16.6614 2C16.6614 1.45 16.2114 1 15.6614 1ZM16.2514 5.59L21.0814 10.42C21.4514 10.79 21.6614 11.3 21.6614 11.83V21C21.6614 22.1 20.7614 23 19.6614 23H8.65138C7.55138 23 6.66138 22.1 6.66138 21L6.67138 7C6.67138 5.9 7.56138 5 8.66138 5H14.8314C15.3614 5 15.8714 5.21 16.2514 5.59ZM15.6614 12H20.1614L14.6614 6.5V11C14.6614 11.55 15.1114 12 15.6614 12Z"
      fill={color}
    />
  </svg>
)

export default Duplicate
