import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'
import callOptionCreation from './service/create'
import callOptionUpdate from './service/update'
import { setErrorsInState, findErrorsInState } from '../../../../format/errors'
import { getDrawerState, selectedCompany } from '../../../../store/selector'
import { values as valuesValidator, price as priceValidator } from '../../../../format/errors/filters/catalog/option'
import { ValidationFunction } from '../../../../format/errors/types'
import { drawerResetHistory, updateDrawerState } from '../../../../actions/drawer'
import { DrawerState } from '../../../../store/types'
import { TypeOption } from '../../../../types'
import Loader, { displayLoader } from '../../../../components/newComponents/loader'
import { handleBlur } from '../../../../format/form'
import SubmitButton from '../../../../components/oldComponents/drawer/submitButton'
import { getDrawerHeight, getDrawerHeightNoTab } from '../../../../utils'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'

type Props = {
  optionState: TypeOption
  close: () => void
  dataCy: string
}

const Detail = ({ optionState, close, dataCy }: Props) => {
  const [state, setState] = useState(optionState)
  const drawerState = useSelector(getDrawerState)
  const company = useSelector(selectedCompany)
  const dispatch = useDispatch()

  const handleChange = (field: string, index?: number) => (event: any) => {
    state.errors.global = undefined

    if (field === 'value' || field === 'price') {
      state.dirties = [...state.dirties, 'values']
      if (field === 'value') {
        state.values![index!].name = event
        state.values = state.values!.map((value) => ({
          ...value,
          errorValue: {
            value: false,
            message: '',
          },
        }))
      } else if (field === 'price') {
        state.values![index!].price = event
        state.values = state.values!.map((value) => ({
          ...value,
          errorPrice: {
            value: false,
            message: '',
          },
        }))
      }
    } else {
      // @ts-ignore
      state.errors[field] = null
      // @ts-ignore
      state[field] = event
      state.dirties = [...state.dirties, field]
    }

    const showSubmit = !findErrorsInState(state.errors)
    setState({ ...state, showSubmit })
  }

  const isValueError = () => {
    if (state.values!.length > 0) {
      state.values?.forEach((value, index) => {
        // @ts-ignore
        const validatorA: ValidationFunction = state.validators.values[index].errorValue
        // @ts-ignore
        const validatorB: ValidationFunction =
          // @ts-ignore
          state.validators.values[index].errorPrice
        // @ts-ignore
        state.values[index].errorValue = validatorA
          ? // @ts-ignore
          validatorA(
            {
              item: value.name,
              items: state.values?.map((value) => value.name),
            } ?? {
              value: false,
              message: '',
            }
          )
          : null
        // @ts-ignore
        state.values[index].errorPrice = validatorB
          ? // @ts-ignore
          validatorB(value.price)
          : null
      })
      return !!state.values?.find(
        (value) => (value.errorValue && value.errorValue.value) || (value.errorPrice && value.errorPrice.value)
      )
    }
    return true
  }

  const dHandleBlur = (field: string, index?: number) => (event: any) => {
    if (field === 'value' || field === 'price') {
      setState({ ...state, showSubmit: !isValueError() })
    } else {
      setState(handleBlur(state, field))
    }
  }

  const addOption = (value: string) => {
    const optionExists = state.values?.some(option => option.name === value);
    state.validators.values!.push({
      errorValue: valuesValidator,
      errorPrice: priceValidator,
    })
    state.dirties = [...state.dirties, 'values']

    if (optionExists) {
      setState({
        ...state,
        showSubmit: false,
        values: [
          ...state.values!.map((option) => ({
            name: option.name,
            errorValue: option.errorValue,
            price: option.price,
            errorPrice: option.errorPrice,
          })),
          { name: value, errorValue: { value: true, message: 'Existe déjà' }, price: 0, errorPrice: { value: false, message: '' } },
        ],
      })
    } else {
      setState({
        ...state,
        showSubmit: true,
        values: [
          ...state.values!.map((option) => ({
            name: option.name,
            errorValue: option.errorValue,
            price: option.price,
            errorPrice: option.errorPrice,
          })),
          { name: value, errorValue: { value: false, message: '' }, price: 0, errorPrice: { value: false, message: '' } },
        ],
      })
    }
  }

  const removeOption = (_: string | undefined, indexSent: number) =>
    setState({
      ...state,
      values: state
        .values!.filter((_, index) => indexSent !== index)
        .map((value) => ({
          name: value.name,
          errorValue: value.errorValue,
          price: value.price,
          errorPrice: value.errorPrice,
        })),
      validators: {
        ...state.validators,
        values: state.validators.values!.filter((_, vIndex) => vIndex !== indexSent),
      },
      showSubmit: true,
    })

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    } else {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
      drawerResetHistory(dispatch)
      close()
    }
  }

  const handleSubmit = () => {
    setState(handleBlur(state, 'name'))
    if (state.values?.length === 0) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          name: {
            value: true,
            message: 'Veuillez ajouter un moins une valeur'
          }
        },
        showSubmit: false,
      })
    } else {

      if (!isValueError() && !findErrorsInState(state.errors)) {
        updateDrawerState(DrawerState.SEND_FORM, dispatch)
        if (!state.id) {
          callOptionCreation(state, company.currencyDecimals).then(handleResult)
        } else {
          callOptionUpdate(state, company.currencyDecimals).then(handleResult)
        }
      }
    }

  }

  const onDragEnd = (dragStart: number, dragEnd: number): void => {
    if (state.values) {
      const optionInsert = state.values[dragStart]
      state.values = state.values.filter((_: {}, index: number) => index !== dragStart)
      state.values.splice(dragEnd, 0, optionInsert)
      handleChange('options')(state.values)
    }
  }

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={styles.containerForm}
          style={{
            height: state.id ? getDrawerHeight() : getDrawerHeightNoTab(),
          }}
          data-cy={dataCy}
        >
          <Form
            state={state}
            handleChange={handleChange}
            handleBlur={dHandleBlur}
            handleSubmit={handleSubmit}
            onDragEnd={onDragEnd}
            addOption={addOption}
            deleteOption={removeOption}
            dataCy={dataCy}
          />
          <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Detail
