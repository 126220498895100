import {
  productQuery,
  categoryQuery,
  discountQuery,
  packQuery,
  optionQuery,
  inventoryMovementQuery,
  saleQuery,
  paymentQuery,
  cashbookQuery,
  customerQuery,
  backgroundJobQuery,
} from '../graphql/requester/query';
import formatWithSubvalues from '../format/product';
import { getPromise } from './graphql';
import { getCompanyID, getTestMode } from './localStorage';
import { ListSort, objectType } from '../store/types';

export type filterListFunction = (
  sort: ListSort,
  filter: any,
  nextToken?: string,
) => Promise<{ items: any; nextToken: any }>;

const listElement =
  ({ type, field, query, id }: { type: objectType; field: string; query: string; id: string }) =>
  async (filter: any, sort: ListSort, nextToken?: string) => {
    const requesterRes = await getPromise(query, {
      filter,
      [id]: getCompanyID(),
      test: getTestMode(),
      nextToken,
      sort,
    });
    if (requesterRes && requesterRes.data && requesterRes.data[field] && requesterRes.data[field].items) {
      return {
        items: requesterRes.data[field].items
          .filter((_: any) => _)
          .map((item: any) => (type === objectType.PRODUCT ? formatWithSubvalues(false, false)(item) : item)),
        nextToken: requesterRes.data[field].nextToken,
      };
    }
    return { items: [], nextToken: null };
  };

export const listFilterProduct = listElement({
  type: objectType.PRODUCT,
  field: 'requestProducts',
  query: productQuery,
  id: 'catalogID',
});

export const listFilterCategory = listElement({
  type: objectType.CATEGORY,
  field: 'requestCategories',
  query: categoryQuery,
  id: 'catalogID',
});

export const listFilterDiscount = listElement({
  type: objectType.DISCOUNT,
  field: 'requestDiscounts',
  query: discountQuery,
  id: 'catalogID',
});

export const listFilterPack = listElement({
  type: objectType.PACK,
  field: 'requestPacks',
  query: packQuery,
  id: 'catalogID',
});

export const listFilterOption = listElement({
  type: objectType.OPTION,
  field: 'requestOptions',
  query: optionQuery,
  id: 'catalogID',
});

export const listFilterInventoryMovement = listElement({
  type: objectType.INVENTORY_MOVEMENT,
  field: 'requestInventoryMovements',
  query: inventoryMovementQuery,
  id: 'companyID',
});

export const listFilterSale = listElement({
  type: objectType.SALE,
  field: 'requestSales',
  query: saleQuery,
  id: 'companyID',
});

export const listFilterPayment = listElement({
  type: objectType.PAYMENT,
  field: 'requestPayments',
  query: paymentQuery,
  id: 'companyID',
});

export const listFilterCashbook = listElement({
  type: objectType.CASHBOOK,
  field: 'requestCashbooks',
  query: cashbookQuery,
  id: 'companyID',
});

export const listFilterCustomer = listElement({
  type: objectType.CUSTOMER,
  field: 'requestCustomers',
  query: customerQuery,
  id: 'companyID',
});

export const listFilterBackgroundJob = listElement({
  type: objectType.IMPORT,
  field: 'listBackgroundJobs',
  query: backgroundJobQuery,
  id: 'companyID',
});
