import {
  CustomFieldObjectType,
  CreateProductCustomFieldInput,
  UpdateProductCustomFieldInput,
  DeleteProductCustomFieldInput,
  CreateCategoryCustomFieldInput,
  UpdateCategoryCustomFieldInput,
  DeleteCategoryCustomFieldInput,
  CreatePackCustomFieldInput,
  UpdatePackCustomFieldInput,
  DeletePackCustomFieldInput,
  CreateCustomerCustomFieldInput,
  UpdateCustomerCustomFieldInput,
  DeleteCustomerCustomFieldInput,
} from '../../API'
import {
  createProductCustomField as createAC,
  updateProductCustomField as updateAC,
  deleteProductCustomField as deleteAC,
  createCategoryCustomField as createCC,
  updateCategoryCustomField as updateCC,
  deleteCategoryCustomField as deleteCC,
  createPackCustomField as createPC,
  updatePackCustomField as updatePC,
  deletePackCustomField as deletePC,
  createCustomerCustomField as createCCC,
  updateCustomerCustomField as updateCCC,
  deleteCustomerCustomField as deleteCCC,
} from '../../graphql/custom/mutations'
import { StateStore } from '../../store/types'
import { TypeCustomField } from '../../types/setting/customField'
import { getPromise } from '../graphql'
import { DataWithErrors } from '../types'
import { customFieldObjectTypeToObjectType } from '../../utils/typeToType'
import { TypeUpdateNestedRequest, TypeCreateNestedRequest } from '../../types'
import { getProduct } from '../../getters/catalog/product'
import { getCustomer } from '../../getters/customer/customer'
import { getPack } from '../../getters/catalog/pack'
import { getCategory } from '../../getters/catalog/category'
import { getCashbook } from '../../getters/activity/cashbook'

const createProductCustomField = async (input: CreateProductCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(createAC, { input })
  const errors = res.errors ? res.errors[0] : null
  return {
    data: res.data?.createProductCustomField,
    errors,
    payload: input,
  }
}

const updateProductCustomField = async (input: UpdateProductCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(updateAC, { input })
  const errors = res.errors ? res.errors[0] : null
  return {
    data: res.data?.updateProductCustomField,
    errors,
    payload: input,
  }
}

const deleteProductCustomField = async (input: DeleteProductCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(deleteAC, { input })
  const errors = res.errors ? res.errors[0] : null
  return {
    data: res.data?.deleteProductCustomField,
    errors,
    payload: input,
  }
}

const createCategoryCustomField = async (input: CreateCategoryCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(createCC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.createCategoryCustomField, errors, payload: input }
}

const updateCategoryCustomField = async (input: UpdateCategoryCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(updateCC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.updateCategoryCustomField, errors, payload: input }
}

const deleteCategoryCustomField = async (input: DeleteCategoryCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(deleteCC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.deleteCategoryCustomField, errors, payload: input }
}

const createPackCustomField = async (input: CreatePackCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(createPC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.createPackCustomField, errors, payload: input }
}

const updatePackCustomField = async (input: UpdatePackCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(updatePC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.updatePackCustomField, errors, payload: input }
}

const deletePackCustomField = async (input: DeletePackCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(deletePC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.deletePackCustomField, errors, payload: input }
}

const createCustomerCustomField = async (input: CreateCustomerCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(createCCC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.createCustomerCustomField, errors, payload: input }
}

const updateCustomerCustomField = async (input: UpdateCustomerCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(updateCCC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.updateCustomerCustomField, errors, payload: input }
}

const deleteCustomerCustomField = async (input: DeleteCustomerCustomFieldInput): Promise<DataWithErrors> => {
  const res = await getPromise(deleteCCC, { input })
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.deleteCustomerCustomField, errors, payload: input }
}

const customFieldsInState = (
  type: string,
  allCustomFields: Array<any>,
  currentCustomFields?: {
    items: [{ customField: TypeCustomField; customFieldID: string; value: string }]
  }
) =>
  allCustomFields
    .filter((cf: { objectType: string }) => cf.objectType === type)
    .map((listCf: TypeCustomField) => {
      let associatedCf
      if (currentCustomFields && currentCustomFields.items && currentCustomFields.items.length > 0) {
        associatedCf = currentCustomFields.items
          .filter((item) => item)
          .find((cf: { customFieldID: string }) => listCf.id === cf.customFieldID)
      }
      return {
        id: listCf.id,
        valueType: listCf.valueType,
        objectType: listCf.objectType,
        name: listCf.name,
        value: associatedCf ? associatedCf.value : '',
        created: !!associatedCf,
      }
    })

const selectGet = (type: string, id: string, dispatch: any) => {
  if (type === 'products') return getProduct(id, dispatch)
  if (type === 'customers') return getCustomer(id, dispatch)
  if (type === 'packs') return getPack(id, dispatch)
  if (type === 'categories') return getCategory(id, dispatch)
  if (type === 'cashbooks') return getCashbook(id, dispatch)
}

const updateCustomFieldsAssociations = (payload: { customFieldID: string; dispatch: any }, state: StateStore) => {
  const index = state.customFieldsAssociations.findIndex(
    (customField) => customField.customFieldID === payload.customFieldID
  )
  if (index !== -1) {
    const { type } = state.customFieldsAssociations[index].entity
    state.customFieldsAssociations[index].entity.ids.map((id) => selectGet(type, id, payload.dispatch))
  }
  return { ...state }
}

const getEntityCustomFieldIds = (entities: Array<{ type: string; items: Array<any> }>, type: string, id: string) => {
  const index = entities.findIndex((entity: any) => entity.type === type)
  return entities[index].items
    .filter((item) => item.customFields && item.customFields.items && item.customFields.items.length > 0)
    .filter((item) =>
      item.customFields.items
        .filter((item: any) => item)
        .find((customField: { customFieldID: string }) => customField.customFieldID === id)
    )
    .map((item) => item.id)
}

/**
 *
 * This method gets new custom field associations related to all entities
 *
 * @usedIn - Subscription
 *
 * @param state - Store
 *
 * @returns - New associations
 *
 * @author - Jennifer Charlois
 *
 */
const getCustomFieldsAssociations = (entities: Array<{ type: string; items: Array<any> }>): Array<any> => {
  const customFieldsIds = entities
    .flatMap((entity) =>
      entity.items
        .filter((ent) => ent.customFields && ent.customFields.items && ent.customFields.items.length > 0)
        .flatMap((ent: { customFields: { items: [{ customField: TypeCustomField }] } }) =>
          ent.customFields.items
            .filter((item) => item)
            .filter(({ customField }) => customField)
            .map(({ customField }) => ({ type: entity.type, id: customField.id }))
            .sort()
        )
    )
    .filter(
      (customField: { id: string }, index: number, tab: Array<{ id: string }>) =>
        tab.findIndex((t: { id: string }) => t.id === customField.id) === index
    )

  return customFieldsIds.map((customField) => ({
    customFieldID: customField.id,
    entity: { type: customField.type, ids: getEntityCustomFieldIds(entities, customField.type, customField.id) },
  }))
}

/**
 *
 * This method updates a custom field inside customField.filteredItems variable
 *
 * @usedIn - Subscriptions x List custom field
 *
 * @param state - Store
 * @param element - Updated custom field
 *
 * @returns - New store
 *
 * @author - Jennifer Charlois
 *
 */
const updateCustomFieldOnFilteredItems = (state: StateStore, element: TypeCustomField) => {
  const index = state.customFields.filteredItems!.items.findIndex(({ id }: { id: string }) => id === element.id)
  if (index >= 0) {
    state.customFields.filteredItems = {
      ...state.customFields.filteredItems,
      items: [...state.customFields.filteredItems!.items],
    }
    state.customFields.filteredItems.items[index] = element
  } else {
    state.customFields.filteredItems = {
      ...state.customFields.filteredItems,
      items: [element, ...state.customFields.filteredItems!.items],
    }
  }
  return state
}

/**
 *
 * This method deletes a custom field inside customField.filteredItems variable
 *
 * @usedIn - Subscriptions x List custom field
 *
 * @param state - Store
 * @param objectType - Custom field object type
 * @param id - Custom field id
 *
 * @returns - New store
 *
 * @author - Jennifer Charlois
 *
 */
const deleteCustomFieldOnFilteredItems = (state: StateStore, id: string) => {
  state.customFields.filteredItems = {
    ...state.customFields.filteredItems,
    items: state.customFields.filteredItems!.items.filter((customField: { id: string }) => customField.id !== id),
  }
  return state
}

const getCustomFieldItems = (payload: { type: CustomFieldObjectType; search?: string }, state: StateStore) =>
  state.customFields.items.items
    .filter((customField) =>
      payload.search
        ? customField.name.toString().toLowerCase().includes(payload.search!.toString().toLowerCase())
        : true
    )
    .filter((customField) => (payload.type ? customField.objectType === payload.type : true))

/**
 *
 * This method filters custom fields filteredItems from requested type (product, discount..)
 *
 * @usedIn - Settings custom field list
 *
 * @param payload - Custom field's type and search value
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Jennifer Charlois
 *
 */
const filterCustomFields = (
  payload: { type: CustomFieldObjectType; search?: string },
  state: StateStore
): StateStore => {
  state.customFields =
    payload.type || payload.search
      ? {
          ...state.customFields,
          filteredItems: {
            type: payload.type ? customFieldObjectTypeToObjectType(payload.type) : undefined,
            items: getCustomFieldItems(payload, state),
          },
        }
      : { ...state.customFields, filteredItems: undefined }
  return { ...state }
}

const callCustomFieldsUpdate = (customFields: Array<TypeCustomField>, request: TypeUpdateNestedRequest) =>
  Promise.all(
    // eslint-disable-next-line array-callback-return
    customFields.map((customField) => {
      if (customField.created) {
        if (customField.value)
          return request.update({
            ...request.id,
            customFieldID: customField.id,
            value: customField.value,
          })
        return request.delete({
          ...request.id,
          customFieldID: customField.id,
        })
      }
      if (!customField.created && customField.value) {
        return request.create({
          ...request.id,
          customFieldID: customField.id,
          value: customField.value,
        })
      }
    })
  )

const callCustomFieldsCreation = (customFields: Array<TypeCustomField>, request: TypeCreateNestedRequest) =>
  Promise.all(
    customFields
      .filter((customField) => customField.value)
      .map((customField) => request.create({ ...request.id, customFieldID: customField.id, value: customField.value }))
  )

export {
  createProductCustomField,
  updateProductCustomField,
  deleteProductCustomField,
  createCategoryCustomField,
  updateCategoryCustomField,
  deleteCategoryCustomField,
  createPackCustomField,
  updatePackCustomField,
  deletePackCustomField,
  createCustomerCustomField,
  updateCustomerCustomField,
  deleteCustomerCustomField,
  customFieldsInState,
  updateCustomFieldsAssociations,
  getCustomFieldsAssociations,
  updateCustomFieldOnFilteredItems,
  deleteCustomFieldOnFilteredItems,
  filterCustomFields,
  callCustomFieldsUpdate,
  callCustomFieldsCreation,
}
