const InventoryTransfer = ({ color }: { color: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.83 12L8.29 9.53999C8.68 9.14999 8.68 8.51999 8.29 8.12999C7.9 7.73999 7.27 7.73999 6.88 8.12999L3.7 11.3C3.31 11.69 3.31 12.32 3.7 12.71L6.88 15.88C7.27 16.27 7.9 16.27 8.29 15.88C8.68 15.49 8.68 14.86 8.29 14.47L5.83 12ZM18.17 12L15.71 14.46C15.32 14.85 15.32 15.48 15.71 15.87C16.1 16.26 16.73 16.26 17.12 15.87L20.3 12.7C20.69 12.31 20.69 11.68 20.3 11.29L17.13 8.11999C16.74 7.72999 16.11 7.72999 15.72 8.11999C15.33 8.50999 15.33 9.13998 15.72 9.52998L18.17 12Z"
      fill={color}
    />
  </svg>
)

export default InventoryTransfer
