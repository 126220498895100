import { useLayoutEffect, useState } from 'react'

import generateDataCy from '../../../utils/cypress'
import List, { TypeList } from '../list'
import ListItem from '../listItem'
import { ComponentState, ComponentStatus } from '../types'

type Props = {
  Element?: any
  eventID?: string
  parentID?: string
  popUpID?: string
  items?: TypeList
  state?: ComponentState
  dataCy: string
  minWidth?: string
}

export const isOutsideViewport = (parentID: string, popUpID: string): any => {
  const parentInfos = document.getElementById(parentID)?.getBoundingClientRect()
  const popUpInfos = document.getElementById(popUpID)?.getBoundingClientRect()

  if (parentInfos && popUpInfos) {
    const widther =
      popUpInfos.width >= parentInfos.width && popUpInfos.width < 400 ? popUpInfos.width : parentInfos.width

    if (parentInfos.left + popUpInfos.width >= (window.innerWidth || document.documentElement.clientWidth))
      return {
        top: parentInfos.top + parentInfos.height + 'px',
        right: '0px',
        width: widther + 'px',
      }

    return {
      top: parentInfos.top + parentInfos.height + 'px',
      left: parentInfos.left + 'px',
      width: widther + 'px',
    }
  }
}

/**
 *
 * This element displays an element who displays a dropdown
 *
 * @param Element - UniqueDropDown's element where to click
 * @param items - A list of items to display in the dropdown
 * @param state - Dropdown's state
 * @param dataCy - An unique id
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const UniqueDropDown = ({
  Element,
  eventID,
  parentID,
  popUpID,
  items,
  state,
  dataCy,
  minWidth,
}: Props): JSX.Element => {
  const [dropDownState, setState] = useState({ displayPopUp: false })

  const handlePosition = () => {
    if (parentID && popUpID) {
      const popupElement = document.getElementById(popUpID)

      if (popupElement) {
        popupElement.style.top = isOutsideViewport(parentID, popUpID).top
        if (!minWidth) popupElement.style.width = isOutsideViewport(parentID, popUpID).width

        if (isOutsideViewport(parentID, popUpID).left)
          popupElement.style.left = isOutsideViewport(parentID, popUpID).left
        else popupElement.style.right = isOutsideViewport(parentID, popUpID).right
      }
    }
  }

  useLayoutEffect(() => {
    handlePosition()

    if (eventID) document.getElementById(eventID)?.addEventListener('scroll', handlePosition)
    return () => window.removeEventListener('scroll', () => { })
      }, [eventID, dropDownState.displayPopUp])

  const handleClick = () => {
    if (!state || (state && state !== ComponentState.DISABLED)) {
      dropDownState.displayPopUp = !dropDownState.displayPopUp
      setState({ ...dropDownState })
    }
  }

  const handleBlur = (event: any) => {
    if (
      !event.relatedTarget ||
      (event.relatedTarget && event.relatedTarget.id !== popUpID && event.relatedTarget.id !== 'listItemIconID')
    ) {
      setState({ ...dropDownState, displayPopUp: false })
    }
  }

  return (
    <div>
      {(!state || (state && state !== ComponentState.INVISIBLE)) && (
        <div
          data-cy={generateDataCy({ scope: dataCy, value: 'container' })}
          tabIndex={0}
          id={parentID}
          onClick={handleClick}
          onBlur={handleBlur}>
          {Element}
        </div>
      )}
      {dropDownState.displayPopUp && (
        <List
          dataCy={dataCy}
          id={popUpID}
          items={
            items
              ? items.map((item, index) => (
                  <ListItem
                    key={index}
                    item={item.value}
                    LeadIcon={item.icon}
                    trailIcons={item.trailIcons}
                    onClick={() => {
                      setState({ ...dropDownState, displayPopUp: false })
                      item.onClick && item.onClick(item.id)
                    }}
                    state={item.state ?? ComponentState.DEFAULT}
                    status={item.status ?? ComponentStatus.DEFAULT}
                    isFirst={item.isFirst}
                    isLast={item.isLast}
                    dataCy={generateDataCy({ scope: dataCy, value: item.id.toLowerCase() })}
                  />
                ))
              : []
          }
          minWidth={minWidth}
        />
      )}
    </div>
  )
}

export default UniqueDropDown
