import { Action, DrawerState, DrawerType, IntervaleType } from '../store/types'
import { Route } from '../utils/navigation'

type DrawerStateWithoutNavigation = {
  type: DrawerType
  id?: string
  subId?: string
  open?: boolean
  param?: { view: string; to?: any; from?: any; motive?: string }
}

const drawer = (payload: DrawerStateWithoutNavigation, dispatch: any) => {
  dispatch({ type: Action.DRAWER, payload })
}

const drawerOpen = (dispatch: any, payload: { open: boolean }) => {
  dispatch({ type: Action.TOGGLE_DRAWER, payload })
}

const drawerBack = (dispatch: any) => {
  dispatch({ type: Action.DRAWER_BACK })
}

const updateDrawerState = (payload: DrawerState, dispatch: any) => {
  dispatch({ type: Action.UPDATE_DRAWER_STATE, payload })
}

const drawerAddHistory = (payload: Route, dispatch: any) => {
  dispatch({ type: Action.DRAWER_ADD_HISTORY, payload })
}

const drawerResetHistory = (dispatch: any) => {
  dispatch({ type: Action.DRAWER_RESET_HISTORY })
}

const updateInterval = (payload: IntervaleType | null, dispatch: any) => {
  dispatch({ type: Action.UPDATE_INTERVAL, payload })
}

const drawerActionType = (payload: string, dispatch: any) => {
  dispatch({ type: Action.UPDATE_DRAWER_ACTION, payload })
}

export { drawer, drawerOpen, drawerBack, updateDrawerState, drawerAddHistory, drawerResetHistory, drawerActionType, updateInterval }
