import { useState, useEffect, forwardRef, useRef, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'

import ValidButton from '../../oldComponents/buttons/validButton'
import { TypeCol, TypeColumnConfiguration } from '../../../store/types'
import generateDataCy from '../../../utils/cypress'
import { getCompanyID } from '../../../services/localStorage'
import { CreateCompanySettingInput, ListCompanySettingsQueryVariables, UpdateCompanySettingInput } from '../../../API'
import { callService } from '../../../services'
import { createCompanySetting as createC, updateCompanySetting as updateC } from '../../../graphql/custom/mutations'
import { listCompanySettings } from '../../../graphql/custom/queries'
import { ComponentState } from '../types'
import DisplayIcon2 from '../icons'

import { EyeShow, EyeHide, DragIndicator } from '../../../assets/icons'
import styles from './ColumnSettings.module.css'

type ColumnListState = {
  dragActive: boolean
  showSubmit: boolean
}

const DisplayCol = ({ col, dragActive, dataCy }: { col: TypeCol; dragActive: boolean; dataCy?: string }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: col.id,
  })
  const [state, setState] = useState(ComponentState.DEFAULT)
  const { t } = useTranslation()

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    background: !dragActive && state === ComponentState.HOVER ? 'var(--st-layer-color-default-hover)' : '',
  }

  return (
    <div
      id={'col'}
      ref={setNodeRef}
      className={styles.content}
      onMouseEnter={() => setState(ComponentState.HOVER)}
      onMouseLeave={() => setState(ComponentState.DEFAULT)}
      style={style}
      {...attributes}
      {...listeners}
      data-cy={generateDataCy({ scope: dataCy ?? '', value: col.title.toLowerCase() })}
    >
      <DisplayIcon2
        id={'listItemIconID'}
        Icon={col.active ? EyeShow : EyeHide}
        color={col.active ? 'var(--sys-color-content-interactive)' : 'var(--comp-list-item-color-foreground-icon)'}
        height={'var(--comp-list-item-size-icon)'}
        width={'var(--comp-list-item-size-icon)'}
      />
      <div className={styles.flex}>
        <div className={styles.name}>
          {t('list.col.' + col.title) !== 'list.col.' + col.title ? t('list.col.' + col.title) : col.title}
        </div>
        <DisplayIcon2
          id={'listItemIconID'}
          Icon={DragIndicator}
          color={'var(--comp-list-item-color-foreground-text)'}
          height={'var(--comp-list-item-size-icon)'}
          width={'var(--comp-list-item-size-icon)'}
        />
      </div>
    </div>
  )
}

/**
 *
 * This element displays list of columns
 *
 * @param cols - Cols to display
 * @param submitCols - A CTA when we click on "Validate" button
 * @param onChangeDisplay - A CTA when we change columns's appearance
 * @param onChangeOrder - A CTA when we change columns's order
 * @param onBlur - A CTA when we click outside the pop up
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ColumnList = forwardRef(
  (
    {
      cols,
      submitCols,
      onDragEnd,
      onBlur,
    }: {
      cols: TypeColumnConfiguration
      submitCols: () => void
      onDragEnd: (event: any) => void
      onBlur: (event: any) => void
    },
    ref: any
  ): JSX.Element => {
    const initialState: ColumnListState = {
      dragActive: false,
      showSubmit: false,
    }
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    )
    const [state, setState] = useState(initialState)
    const cyContext = generateDataCy({ scope: 'requester', value: 'colselector' })

    return (
      <div ref={ref} onBlur={onBlur} tabIndex={0} id={'containerColumnList'} className={styles.container}>
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
          collisionDetection={closestCenter}
          onDragOver={() => setState({ ...state, dragActive: true })}
          onDragEnd={(event) => {
            setState({ ...state, dragActive: false, showSubmit: true })
            onDragEnd(event)
          }}
        >
          <SortableContext
            items={cols.cols.filter((col) => col.notCustomable === undefined)}
            strategy={verticalListSortingStrategy}
          >
            {cols.cols
              .filter((col) => col.notCustomable === undefined)
              .map((col, index) => (
                <DisplayCol key={index} col={col} dragActive={state.dragActive} dataCy={cyContext} />
              ))}
          </SortableContext>
        </DndContext>
        <ValidButton
          disabled={!state.showSubmit}
          onClick={submitCols}
          dataCy={generateDataCy({
            scope: cyContext,
            value: 'submit',
          })}
        />
      </div>
    )
  }
)

/**
 *
 * This element let the user change columns's order and appearance
 *
 * @usedIn - List
 *
 * @param cols - Column configuration
 * @param onChange - A CTA when we change columns's order and/or appearance
 * @param onBlur - A CTA when we click outside the popup
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ColumnSettings = ({
  cols,
  onChange,
  onBlur,
}: {
  cols: TypeColumnConfiguration
  onChange: (cols: TypeColumnConfiguration) => void
  onBlur?: () => void
}): JSX.Element => {
  const initialState: {
    excludedCols: Array<TypeCol>
    cols: TypeColumnConfiguration
  } = {
    excludedCols: [],
    cols,
  }
  const [state, setState] = useState(initialState)
  const ref: RefObject<any> = useRef()

  const buildCol = (col: TypeCol): TypeCol => ({
    id: col.id,
    title: col.title,
    type: col.type,
    field: col.field,
    fieldType: col.fieldType,
    function: col.function,
    active: col.active,
    editable: col.editable,
    sortable: col.sortable,
    notCustomable: col.notCustomable,
    validator: col.validator,
    error: col.error,
  })

  const buildCols = (): TypeColumnConfiguration => ({
    id: cols.id,
    type: cols.type,
    cols: cols.cols.filter((col) => col.id !== 'color').map(buildCol),
    creatable: cols.creatable,
    editable: cols.editable,
    exportable: cols.exportable,
    clickable: cols.clickable,
    shrinkrable: cols.shrinkrable,
    customable: cols.customable,
    checkboxes: cols.checkboxes,
    searchBarPlaceholder: cols.searchBarPlaceholder,
    creatablePlaceholder: cols.creatablePlaceholder,
    displaySelectedOnly: cols.displaySelectedOnly,
    addProducts: cols.addProducts,
    editActions: cols.editActions,
    noRightToolbar: cols.noRightToolbar,
    customClassNames: cols.customClassNames,
  })

  useEffect(() => {
    if (ref.current) ref.current.focus()
    setState({
      ...state,
      excludedCols: cols.cols.filter((col) => col.id === 'color'),
      cols: buildCols(),
    })
      }, [cols])

  /**
   *
   * This method is called when we make a new column configuration
   * We send it to the API, then when we reload the page, that new configuration
   * is saved and recovered
   *
   * @param key - API company settings key (productCols, packCols..)
   * @param value - API company settings value (new configuration stringify)
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  const handleCompanySettings = (key: string, value: string) => {
    callService<ListCompanySettingsQueryVariables>(
      { companyID: getCompanyID() },
      listCompanySettings,
      'listCompanySettings'
    ).then((data) => {
      if (data.data) {
        const companySettings = data.data.items
        const companySetting = companySettings.find((setting: { key: string }) => setting.key === key)
        if (companySetting) {
          const { id } = companySettings.find((setting: { key: string }) => setting.key === key)
          callService<{ input: UpdateCompanySettingInput }>(
            // @ts-ignore
            { input: { id, key, value } },
            updateC,
            'updateCompanySetting'
          )
        } else {
          callService<{ input: CreateCompanySettingInput }>(
            // @ts-ignore
            { input: { key, value, companyID: getCompanyID() } },
            createC,
            'createCompanySetting'
          )
        }
      }
    })
  }

  /**
   *
   * This method is called when we finished drag and drop the list
   *
   * @param event - Library event with all infos to reorder
   *
   * @author - Jennifer Charlois
   *
   */
  const onDragEnd = (event: any) => {
    const { active, over, delta } = event
    const parsedCols = state.cols.cols.filter((col) => col.notCustomable === undefined)

    if (active.id !== over.id) {
      const oldIndex = parsedCols.findIndex((col) => col.id === active.id)
      const newIndex = parsedCols.findIndex((col) => col.id === over.id)

      const activeColInsert = parsedCols[oldIndex]
      const newCols = parsedCols.filter((_: {}, index: number) => index !== oldIndex)
      newCols.splice(newIndex, 0, activeColInsert)

      setState({
        ...state,
        cols: { ...state.cols, cols: [...state.cols.cols.filter((col) => col.notCustomable === true), ...newCols] },
      })
    } else if (delta.x === 0) {
      const selectedCol = parsedCols.find((col) => col.id === active.id)

      if (selectedCol) onChangeDisplay(selectedCol)
    }
  }

  const onChangeDisplay = (col: TypeCol) => {
    const colIndex = state.cols.cols.findIndex((stateCol: { id: string }) => stateCol.id === col.id)
    state.cols.cols = [
      ...state.cols.cols.map((col, index) => ({
        ...buildCol(col),
        active: index === colIndex ? !col.active : col.active,
      })),
    ]
    setState({ ...state })
  }

  const handleBlur = (event: any) => {
    if (
      !event.relatedTarget ||
      (event.relatedTarget &&
        event.relatedTarget.id !== 'containerColumnList' &&
        event.relatedTarget.id !== 'col' &&
        event.relatedTarget.id !== 'listItemIconID' &&
        event.relatedTarget.id !== 'submitButton')
    ) {
      onBlur && onBlur()
    }
  }

  const submitCols = () => {
    onChange({
      ...state.cols,
      cols: [...state.excludedCols, ...state.cols.cols],
    })
    handleCompanySettings(
      state.cols.type + 'Cols',
      JSON.stringify([
        ...state.excludedCols.map((col) => ({ id: col.id, active: true })),
        ...state.cols.cols.map((col) => ({ id: col.id, active: col.active })),
      ])
    )
  }

  return (
    <>
      <div className={styles.hidden} onClick={onBlur} />
      <ColumnList ref={ref} cols={state.cols} onDragEnd={onDragEnd} submitCols={submitCols} onBlur={handleBlur} />
    </>
  )
}

export default ColumnSettings
