import { TypeColumnConfiguration } from '../types'
import { DropDownCell } from '../../components/list/cell'
import { updateModal, updateToaster } from '../../actions/modal'
import UserModal from '../../views/setting/user/modal'
import { ComponentState, ComponentStatus } from '../../components/newComponents/types'
import { userRoleEnumToStr } from '../../utils/typeToType'
import { MoreHoriz, Success } from '../../assets/icons'
import IconTouch from '../../components/newComponents/icons/iconTouch'
import { AccountInvitationStatus, UpdateAccountInvitationInput } from '../../API'
import { cancelInvitation, resendInvitation } from '../../graphql/mutations'
import { callService } from '../../services'

const USER = 'user'

const usersColsConf: TypeColumnConfiguration[] = [
  {
    id: 'listUsers',
    type: USER,
    cols: [
      {
        id: 'firstName',
        title: 'FIRSTNAME',
        field: 'firstName',
        type: 'text',
        fieldType: 'string',
        active: true,
      },
      {
        id: 'lastName',
        title: 'LASTNAME',
        field: 'lastName',
        type: 'text',
        fieldType: 'string',
        active: true,
      },
      {
        id: 'email',
        title: 'EMAIL',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, t }) => {
          const datum = Date.parse(value.expiresAt)

          return value.status && value.status === AccountInvitationStatus.SENT
            ? datum < Date.now()
              ? value.email + ' (' + t('settings.user.EXPIRED') + ')'
              : value.email + ' (' + t('settings.user.SENT') + ')'
            : value.email
        },
      },
      {
        id: 'role',
        title: 'ROLE',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value }) => userRoleEnumToStr(value.company.role),
      },
      {
        id: 'more',
        title: '',
        type: 'element',
        active: true,
        function: ({ value, t, index, dispatch }) =>
          DropDownCell({
            fieldID: 'more',
            index,
            Element: IconTouch({
              Icon: MoreHoriz,
              color: 'var(--sys-color-content-secondary)',
            }),
            state: value.company.owner ? ComponentState.INVISIBLE : ComponentState.DEFAULT,
            items:
              value.status === AccountInvitationStatus.SENT
                ? Date.parse(value.expiresAt) < Date.now()
                  ? [
                      {
                        id: 'resend',
                        value: t('settings.user.RESEND'),
                        onClick: () =>
                          callService<{ input: UpdateAccountInvitationInput }>(
                            {
                              input: {
                                id: value.id,
                              },
                            },
                            resendInvitation,
                            'resendInvitation'
                          ).then((res) => {
                            if (res.data && !res.errors)
                              updateToaster(dispatch, true, {
                                text: t('settings.user.modal.SENT'),
                                status: ComponentStatus.SUCCESS,
                                LeadIcon: Success,
                              })
                          }),
                      },
                      {
                        id: 'cancel',
                        value: t('settings.user.DELETE'),
                        onClick: () =>
                          callService<{ input: UpdateAccountInvitationInput }>(
                            {
                              input: {
                                id: value.id,
                              },
                            },
                            cancelInvitation,
                            'cancelInvitation'
                          ).then((res) => {
                            if (res.data && !res.errors)
                              updateToaster(dispatch, true, {
                                text: t('settings.user.modal.DELETED'),
                                status: ComponentStatus.SUCCESS,
                                LeadIcon: Success,
                              })
                          }),
                      },
                    ]
                  : [
                      {
                        id: 'resend',
                        value: t('settings.user.RESEND'),
                        onClick: () =>
                          callService<{ input: UpdateAccountInvitationInput }>(
                            {
                              input: {
                                id: value.id,
                              },
                            },
                            resendInvitation,
                            'resendInvitation'
                          ).then((res) => {
                            if (res.data && !res.errors)
                              updateToaster(dispatch, true, {
                                text: t('settings.user.modal.SENT'),
                                status: ComponentStatus.SUCCESS,
                                LeadIcon: Success,
                              })
                          }),
                      },
                      {
                        id: 'cancel',
                        value: t('settings.user.DELETE'),
                        onClick: () =>
                          callService<{ input: UpdateAccountInvitationInput }>(
                            {
                              input: {
                                id: value.id,
                              },
                            },
                            cancelInvitation,
                            'cancelInvitation'
                          ).then((res) => {
                            if (res.data && !res.errors)
                              updateToaster(dispatch, true, {
                                text: t('settings.user.modal.DELETED'),
                                status: ComponentStatus.SUCCESS,
                                LeadIcon: Success,
                              })
                          }),
                      },
                    ]
                : [
                    {
                      id: 'edit',
                      value: t('settings.user.EDIT'),
                      onClick: () =>
                        updateModal(
                          dispatch,
                          true,
                          () => UserModal({ id: value.id, email: value.email }),
                          () => {}
                        ),
                    },
                    {
                      id: 'delete',
                      value: t('settings.user.DELETE'),
                      status: ComponentStatus.DANGER,
                      onClick: () =>
                        updateModal(
                          dispatch,
                          true,
                          () => UserModal({ id: value.id, email: value.email, isDelete: true }),
                          () => {}
                        ),
                    },
                  ],
          }),
      },
    ],
    creatable: true,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
    creatablePlaceholder: 'settings.user.NEW',
  },
]

export default usersColsConf
