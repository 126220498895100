import { updateListElement } from '../../actions/list'
import { GetPaymentMethodQueryVariables } from '../../API'
import { callService } from '../../services'
import { objectType } from '../../store/types'
import { getPaymentMethod as get } from '../../graphql/custom/queries'
import { getObservable } from '../../services/graphql'
import { onCreatePaymentMethod, onUpdatePaymentMethod } from '../../graphql/subscriptions'
import { getCompanyID } from '../../services/localStorage'

const getPaymentMethod = async (id: string, dispatch: any) => {
  const paymentMethod = await callService<GetPaymentMethodQueryVariables>({ id }, get, 'getPaymentMethod')

  if (paymentMethod.data) {
    updateListElement({ element: paymentMethod.data, dispatch, type: objectType.PAYMENT_METHOD })
  }

  return paymentMethod
}

let onCreatePaymentMethodSubscription: any
let onUpdatePaymentMethodSubscription: any

const subscribePaymentMethods = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
  }
  if (!onCreatePaymentMethodSubscription)
    onCreatePaymentMethodSubscription = getObservable(onCreatePaymentMethod, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreatePaymentMethod)
          updateListElement({
            element: eventData.data.onCreatePaymentMethod,
            dispatch,
            type: objectType.PAYMENT_METHOD,
          })
      },
    })

  if (!onUpdatePaymentMethodSubscription)
    onUpdatePaymentMethodSubscription = getObservable(onUpdatePaymentMethod, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdatePaymentMethod)
          updateListElement({
            element: eventData.data.onUpdatePaymentMethod,
            dispatch,
            type: objectType.PAYMENT_METHOD,
          })
      },
    })
}

const unSubscribePaymentMethods = () => {
  if (onCreatePaymentMethodSubscription) {
    onCreatePaymentMethodSubscription.unsubscribe()
    onCreatePaymentMethodSubscription = null
  }

  if (onUpdatePaymentMethodSubscription) {
    onUpdatePaymentMethodSubscription.unsubscribe()
    onUpdatePaymentMethodSubscription = null
  }
}

export { getPaymentMethod, subscribePaymentMethods, unSubscribePaymentMethods }
