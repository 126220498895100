/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const startCatalogMigration = /* GraphQL */ `
  mutation StartCatalogMigration(
    $apiKey: String!
    $companyID: ID!
    $shopID: ID!
  ) {
    startCatalogMigration(
      apiKey: $apiKey
      companyID: $companyID
      shopID: $shopID
    )
  }
`;
export const startInventoryMigration = /* GraphQL */ `
  mutation StartInventoryMigration(
    $apiKey: String!
    $companyID: ID!
    $shopID: ID!
  ) {
    startInventoryMigration(
      apiKey: $apiKey
      companyID: $companyID
      shopID: $shopID
    )
  }
`;
export const startCustomerMigration = /* GraphQL */ `
  mutation StartCustomerMigration(
    $apiKey: String!
    $companyID: ID!
    $shopID: ID!
  ) {
    startCustomerMigration(
      apiKey: $apiKey
      companyID: $companyID
      shopID: $shopID
    )
  }
`;
export const startActivityMigration = /* GraphQL */ `
  mutation StartActivityMigration(
    $apiKey: String!
    $companyID: ID!
    $shopID: ID!
  ) {
    startActivityMigration(
      apiKey: $apiKey
      companyID: $companyID
      shopID: $shopID
    )
  }
`;
export const startMigration = /* GraphQL */ `
  mutation StartMigration($input: MigrationInput!, $test: Boolean!) {
    startMigration(input: $input, test: $test) {
      items {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        shopID
        sourceEmail
        type
        state
        details {
          ... on CatalogMigrationDetails {
            PRODUCT {
              total
              successCount
              errorCount
            }
            PRODUCT_VARIANT {
              total
              successCount
              errorCount
            }
            CATEGORY {
              total
              successCount
              errorCount
            }
            TAX {
              total
              successCount
              errorCount
            }
            DISCOUNT {
              total
              successCount
              errorCount
            }
          }
          ... on CustomerMigrationDetails {
            CUSTOMER {
              total
              successCount
              errorCount
            }
          }
          ... on InventoryMigrationDetails {
            INVENTORY {
              total
              successCount
              errorCount
            }
          }
          ... on ActivityMigrationDetails {
            PAYMENT_METHOD {
              total
              successCount
              errorCount
            }
            SLOT {
              total
              successCount
              errorCount
            }
            CASHBOOK {
              total
              successCount
              errorCount
            }
            SALE {
              total
              successCount
              errorCount
            }
            PAYMENT {
              total
              successCount
              errorCount
            }
          }
          ... on ImportDetails {
            IMPORT {
              total
              successCount
              errorCount
            }
          }
        }
        message
        reportUrl
        flatfileBatchId
        flatfileSheetId
      }
      nextToken
    }
  }
`;
export const sendPhoneVerificationCode = /* GraphQL */ `
  mutation SendPhoneVerificationCode($phone: AWSPhone!) {
    sendPhoneVerificationCode(phone: $phone)
  }
`;
export const checkPhoneVerificationCode = /* GraphQL */ `
  mutation CheckPhoneVerificationCode($phone: AWSPhone!, $code: String!) {
    checkPhoneVerificationCode(phone: $phone, code: $code)
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      phone
      locale
      companies {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          owner
          role
          read
          update
          billing
          billingExport
          shopWrite
          deviceSlotWrite
          deviceSlotUse
          paymentMethodRead
          paymentMethodCreate
          paymentMethodUpdate
          paymentMethodDelete
          customerRead
          customerCreate
          customerUpdate
          customerDelete
          customerExport
          inventoryRead
          cashbookRead
          saleRead
          paymentRead
          userInvite
          userRead
          userUpdate
          userRemove
          sell
          sync
          id
        }
        nextToken
      }
      catalog {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          catalogID
          catalog {
            updatedAt
            createdAt
            deprecated
            companyID
            id
          }
          read
          create
          update
          delete
          sync
          id
        }
        nextToken
      }
      shops {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          shopID
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
          read
          update
          delete
          inventoryRead
          inventoryWrite
          cashbookRead
          cashbookWrite
          saleRead
          saleWrite
          paymentRead
          paymentWrite
          userInvite
          userRead
          userUpdate
          userRemove
          activitiesExport
          tillCashbooksRead
          tillSalesSearch
          tillSaleSell
          tillSaleRefund
          tillSaleReturn
          tillCashbookOpen
          tillCashbookClose
          eventCreate
          sync
          id
        }
        nextToken
      }
      sellerCodes {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          code
          id
        }
        nextToken
      }
      intercomHMAC
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      phone
      locale
      companies {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          owner
          role
          read
          update
          billing
          billingExport
          shopWrite
          deviceSlotWrite
          deviceSlotUse
          paymentMethodRead
          paymentMethodCreate
          paymentMethodUpdate
          paymentMethodDelete
          customerRead
          customerCreate
          customerUpdate
          customerDelete
          customerExport
          inventoryRead
          cashbookRead
          saleRead
          paymentRead
          userInvite
          userRead
          userUpdate
          userRemove
          sell
          sync
          id
        }
        nextToken
      }
      catalog {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          catalogID
          catalog {
            updatedAt
            createdAt
            deprecated
            companyID
            id
          }
          read
          create
          update
          delete
          sync
          id
        }
        nextToken
      }
      shops {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          shopID
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
          read
          update
          delete
          inventoryRead
          inventoryWrite
          cashbookRead
          cashbookWrite
          saleRead
          saleWrite
          paymentRead
          paymentWrite
          userInvite
          userRead
          userUpdate
          userRemove
          activitiesExport
          tillCashbooksRead
          tillSalesSearch
          tillSaleSell
          tillSaleRefund
          tillSaleReturn
          tillCashbookOpen
          tillCashbookClose
          eventCreate
          sync
          id
        }
        nextToken
      }
      sellerCodes {
        items {
          updatedAt
          createdAt
          deprecated
          accountID
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          code
          id
        }
        nextToken
      }
      intercomHMAC
    }
  }
`;
export const createSellerCode = /* GraphQL */ `
  mutation CreateSellerCode($input: CreateSellerCodeInput!) {
    createSellerCode(input: $input) {
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      code
      id
    }
  }
`;
export const updateSellerCode = /* GraphQL */ `
  mutation UpdateSellerCode($input: UpdateSellerCodeInput!) {
    updateSellerCode(input: $input) {
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      code
      id
    }
  }
`;
export const deleteSellerCode = /* GraphQL */ `
  mutation DeleteSellerCode($input: DeleteSellerCodeInput!) {
    deleteSellerCode(input: $input) {
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      code
      id
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      name
      address
      country
      currency
      currencyDecimals
      locale
      vat
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      name
      address
      country
      currency
      currencyDecimals
      locale
      vat
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany($input: DeleteCompanyInput!) {
    deleteCompany(input: $input) {
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        id
      }
      shops {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        name
        address {
          address
          zipcode
          city
          country
        }
        siret
        vat
        phone
        footnote
        logo
        websiteURL
        brandName
        email
      }
      companyAccounts {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      shopAccounts {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
      catalogAccounts {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop($input: CreateShopInput!) {
    createShop(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      name
      address {
        address
        zipcode
        city
        country
      }
      siret
      vat
      phone
      footnote
      logo
      websiteURL
      brandName
      email
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop($input: UpdateShopInput!) {
    updateShop(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      name
      address {
        address
        zipcode
        city
        country
      }
      siret
      vat
      phone
      footnote
      logo
      websiteURL
      brandName
      email
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop($input: DeleteShopInput!) {
    deleteShop(input: $input) {
      shop {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        name
        address {
          address
          zipcode
          city
          country
        }
        siret
        vat
        phone
        footnote
        logo
        websiteURL
        brandName
        email
      }
      shopAccounts {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
export const createCompanySetting = /* GraphQL */ `
  mutation CreateCompanySetting($input: CreateCompanySettingInput) {
    createCompanySetting(input: $input) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const createPersonalCompanySetting = /* GraphQL */ `
  mutation CreatePersonalCompanySetting($input: CreateCompanySettingInput) {
    createPersonalCompanySetting(input: $input) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const updateCompanySetting = /* GraphQL */ `
  mutation UpdateCompanySetting($input: UpdateCompanySettingInput) {
    updateCompanySetting(input: $input) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const deleteCompanySetting = /* GraphQL */ `
  mutation DeleteCompanySetting($input: DeleteCompanySettingInput) {
    deleteCompanySetting(input: $input) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const inviteUser = /* GraphQL */ `
  mutation InviteUser($input: CreateAccountInvitationInput!) {
    inviteUser(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      companyName
      email
      role
      shops
      expiresAt
      status
      senderID
      senderName
      senderEmail
    }
  }
`;
export const resendInvitation = /* GraphQL */ `
  mutation ResendInvitation($input: UpdateAccountInvitationInput!) {
    resendInvitation(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      companyName
      email
      role
      shops
      expiresAt
      status
      senderID
      senderName
      senderEmail
    }
  }
`;
export const cancelInvitation = /* GraphQL */ `
  mutation CancelInvitation($input: UpdateAccountInvitationInput!) {
    cancelInvitation(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      companyName
      email
      role
      shops
      expiresAt
      status
      senderID
      senderName
      senderEmail
    }
  }
`;
export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation($input: UpdateAccountInvitationInput!) {
    acceptInvitation(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      companyName
      email
      role
      shops
      expiresAt
      status
      senderID
      senderName
      senderEmail
    }
  }
`;
export const rejectInvitation = /* GraphQL */ `
  mutation RejectInvitation($input: UpdateAccountInvitationInput!) {
    rejectInvitation(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      companyName
      email
      role
      shops
      expiresAt
      status
      senderID
      senderName
      senderEmail
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      company {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
      shops {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
export const addUserToShop = /* GraphQL */ `
  mutation AddUserToShop($input: AddUserToShopInput!) {
    addUserToShop(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      company {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
      shops {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
export const removeUserFromShop = /* GraphQL */ `
  mutation RemoveUserFromShop($input: RemoveUserFromShopInput!) {
    removeUserFromShop(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      company {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
      shops {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
export const removeUser = /* GraphQL */ `
  mutation RemoveUser($input: RemoveUserInput!) {
    removeUser(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      company {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
      shops {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
export const createSlot = /* GraphQL */ `
  mutation CreateSlot($input: CreateSlotInput!) {
    createSlot(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      name
      status
    }
  }
`;
export const updateSlot = /* GraphQL */ `
  mutation UpdateSlot($input: UpdateSlotInput!) {
    updateSlot(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      name
      status
    }
  }
`;
export const sendSlotEmail = /* GraphQL */ `
  mutation SendSlotEmail($input: SendSlotEmailInput!) {
    sendSlotEmail(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      name
      status
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      slotID
      userID
      test
      version
      date
      type
      code
      objectType
      objectID
      action
      actionData
      hash
    }
  }
`;
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification($input: CreateUserNotificationInput!) {
    createUserNotification(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      slotID
      userID
      test
      version
      date
      type
      code
      objectType
      objectID
      action
      actionData
      hash
    }
  }
`;
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification($input: UpdateUserNotificationInput!) {
    updateUserNotification(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      slotID
      userID
      test
      version
      date
      type
      code
      objectType
      objectID
      action
      actionData
      hash
    }
  }
`;
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification($input: DeleteUserNotificationInput!) {
    deleteUserNotification(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      slotID
      userID
      test
      version
      date
      type
      code
      objectType
      objectID
      action
      actionData
      hash
    }
  }
`;
export const updateGridCell = /* GraphQL */ `
  mutation UpdateGridCell($input: GridCellInput!, $test: Boolean!) {
    updateGridCell(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      compositeID
      catalogID
      test
      deviceID
      page
      column
      row
      type
      objectID
    }
  }
`;
export const deleteGridCell = /* GraphQL */ `
  mutation DeleteGridCell($input: DeleteGridCellInput!) {
    deleteGridCell(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      compositeID
      catalogID
      test
      deviceID
      page
      column
      row
      type
      objectID
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!, $test: Boolean!) {
    createProduct(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      categoryID
      category {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      bulkType
      buyPrice
      sellPrice
      barcode
      reference
      variations {
        name
        options
      }
      variantsCount
      variants {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          id
          productID
          variantID
          options {
            name
            value
          }
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
          stats {
            margin
            quantity
          }
        }
        nextToken
      }
      options {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          optionID
          option {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
          }
          id
        }
        nextToken
      }
      tags {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          tagID
          tag {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            createdByID
          }
          id
        }
        nextToken
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      categoryID
      category {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      bulkType
      buyPrice
      sellPrice
      barcode
      reference
      variations {
        name
        options
      }
      variantsCount
      variants {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          id
          productID
          variantID
          options {
            name
            value
          }
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
          stats {
            margin
            quantity
          }
        }
        nextToken
      }
      options {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          optionID
          option {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
          }
          id
        }
        nextToken
      }
      tags {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          tagID
          tag {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            createdByID
          }
          id
        }
        nextToken
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      categoryID
      category {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      bulkType
      buyPrice
      sellPrice
      barcode
      reference
      variations {
        name
        options
      }
      variantsCount
      variants {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          id
          productID
          variantID
          options {
            name
            value
          }
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
          stats {
            margin
            quantity
          }
        }
        nextToken
      }
      options {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          optionID
          option {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
          }
          id
        }
        nextToken
      }
      tags {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          tagID
          tag {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            createdByID
          }
          id
        }
        nextToken
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const createProductOption = /* GraphQL */ `
  mutation CreateProductOption($input: ProductOptionInput!) {
    createProductOption(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      optionID
      option {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        values {
          name
          price
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      id
    }
  }
`;
export const deleteProductOption = /* GraphQL */ `
  mutation DeleteProductOption($input: ProductOptionInput!) {
    deleteProductOption(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      optionID
      option {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        values {
          name
          price
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      id
    }
  }
`;
export const createProductTag = /* GraphQL */ `
  mutation CreateProductTag($input: ProductTagInput!) {
    createProductTag(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      tagID
      tag {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        createdByID
      }
      id
    }
  }
`;
export const deleteProductTag = /* GraphQL */ `
  mutation DeleteProductTag($input: ProductTagInput!) {
    deleteProductTag(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      tagID
      tag {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        createdByID
      }
      id
    }
  }
`;
export const createProductCustomField = /* GraphQL */ `
  mutation CreateProductCustomField($input: CreateProductCustomFieldInput!) {
    createProductCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const updateProductCustomField = /* GraphQL */ `
  mutation UpdateProductCustomField($input: UpdateProductCustomFieldInput!) {
    updateProductCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const deleteProductCustomField = /* GraphQL */ `
  mutation DeleteProductCustomField($input: DeleteProductCustomFieldInput!) {
    deleteProductCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const generateProductVariants = /* GraphQL */ `
  mutation GenerateProductVariants($input: GenerateProductVariantsInput!) {
    generateProductVariants(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const createProductVariant = /* GraphQL */ `
  mutation CreateProductVariant($input: CreateProductVariantInput!) {
    createProductVariant(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const updateProductVariant = /* GraphQL */ `
  mutation UpdateProductVariant($input: UpdateProductVariantInput!) {
    updateProductVariant(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const deleteProductVariant = /* GraphQL */ `
  mutation DeleteProductVariant($input: DeleteProductVariantInput!) {
    deleteProductVariant(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const bulkCreateProductVariants = /* GraphQL */ `
  mutation BulkCreateProductVariants($input: BulkCreateProductVariantsInput!) {
    bulkCreateProductVariants(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const createProductVariationOption = /* GraphQL */ `
  mutation CreateProductVariationOption(
    $input: CreateProductVariationOptionInput!
  ) {
    createProductVariationOption(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const updateProductVariationOption = /* GraphQL */ `
  mutation UpdateProductVariationOption(
    $input: UpdateProductVariationOptionInput!
  ) {
    updateProductVariationOption(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const deleteProductVariationOption = /* GraphQL */ `
  mutation DeleteProductVariationOption(
    $input: DeleteProductVariationOptionInput!
  ) {
    deleteProductVariationOption(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const updateProductVariationName = /* GraphQL */ `
  mutation UpdateProductVariationName(
    $input: UpdateProductVariationNameInput!
  ) {
    updateProductVariationName(input: $input) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!, $test: Boolean!) {
    createCategory(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          categoryID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          categoryID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          categoryID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const createCategoryCustomField = /* GraphQL */ `
  mutation CreateCategoryCustomField($input: CreateCategoryCustomFieldInput!) {
    createCategoryCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      categoryID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const updateCategoryCustomField = /* GraphQL */ `
  mutation UpdateCategoryCustomField($input: UpdateCategoryCustomFieldInput!) {
    updateCategoryCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      categoryID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const deleteCategoryCustomField = /* GraphQL */ `
  mutation DeleteCategoryCustomField($input: DeleteCategoryCustomFieldInput!) {
    deleteCategoryCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      categoryID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const createCustomField = /* GraphQL */ `
  mutation CreateCustomField($input: CreateCustomFieldInput!, $test: Boolean!) {
    createCustomField(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      key
      objectType
      valueType
      name
      createdByID
    }
  }
`;
export const updateCustomField = /* GraphQL */ `
  mutation UpdateCustomField($input: UpdateCustomFieldInput!) {
    updateCustomField(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      key
      objectType
      valueType
      name
      createdByID
    }
  }
`;
export const deleteCustomField = /* GraphQL */ `
  mutation DeleteCustomField($input: DeleteCustomFieldInput!) {
    deleteCustomField(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      key
      objectType
      valueType
      name
      createdByID
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount($input: CreateDiscountInput!, $test: Boolean!) {
    createDiscount(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      photo
      type
      rate
      stats {
        amount
        quantity
        histogram {
          id
          amount
        }
      }
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount($input: UpdateDiscountInput!) {
    updateDiscount(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      photo
      type
      rate
      stats {
        amount
        quantity
        histogram {
          id
          amount
        }
      }
    }
  }
`;
export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount($input: DeleteDiscountInput!) {
    deleteDiscount(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      photo
      type
      rate
      stats {
        amount
        quantity
        histogram {
          id
          amount
        }
      }
    }
  }
`;
export const createOption = /* GraphQL */ `
  mutation CreateOption($input: CreateOptionInput!, $test: Boolean!) {
    createOption(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      values {
        name
        price
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const updateOption = /* GraphQL */ `
  mutation UpdateOption($input: UpdateOptionInput!) {
    updateOption(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      values {
        name
        price
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const deleteOption = /* GraphQL */ `
  mutation DeleteOption($input: DeleteOptionInput!) {
    deleteOption(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      values {
        name
        price
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const createPack = /* GraphQL */ `
  mutation CreatePack($input: CreatePackInput!, $test: Boolean!) {
    createPack(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      barcode
      reference
      sellPrice
      packVariations {
        options {
          productID
          quantity
          additionalPrice
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          packID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const updatePack = /* GraphQL */ `
  mutation UpdatePack($input: UpdatePackInput!) {
    updatePack(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      barcode
      reference
      sellPrice
      packVariations {
        options {
          productID
          quantity
          additionalPrice
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          packID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const deletePack = /* GraphQL */ `
  mutation DeletePack($input: DeletePackInput!) {
    deletePack(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      barcode
      reference
      sellPrice
      packVariations {
        options {
          productID
          quantity
          additionalPrice
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          packID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const createPackCustomField = /* GraphQL */ `
  mutation CreatePackCustomField($input: CreatePackCustomFieldInput!) {
    createPackCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      packID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const updatePackCustomField = /* GraphQL */ `
  mutation UpdatePackCustomField($input: UpdatePackCustomFieldInput!) {
    updatePackCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      packID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const deletePackCustomField = /* GraphQL */ `
  mutation DeletePackCustomField($input: DeletePackCustomFieldInput!) {
    deletePackCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      packID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag($input: CreateTagInput!, $test: Boolean!) {
    createTag(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      createdByID
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      createdByID
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      createdByID
    }
  }
`;
export const createTax = /* GraphQL */ `
  mutation CreateTax($input: CreateTaxInput!, $test: Boolean!) {
    createTax(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      rate
    }
  }
`;
export const updateTax = /* GraphQL */ `
  mutation UpdateTax($input: UpdateTaxInput!) {
    updateTax(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      rate
    }
  }
`;
export const deleteTax = /* GraphQL */ `
  mutation DeleteTax($input: DeleteTaxInput!) {
    deleteTax(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      rate
    }
  }
`;
export const createInventoryMovement = /* GraphQL */ `
  mutation CreateInventoryMovement(
    $input: CreateInventoryMovementInput!
    $updatePrices: Boolean
    $test: Boolean!
  ) {
    createInventoryMovement(
      input: $input
      updatePrices: $updatePrices
      test: $test
    ) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const updateInventoryMovement = /* GraphQL */ `
  mutation UpdateInventoryMovement(
    $input: UpdateInventoryMovementInput!
    $updatePrices: Boolean
  ) {
    updateInventoryMovement(input: $input, updatePrices: $updatePrices) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const cancelInventoryMovement = /* GraphQL */ `
  mutation CancelInventoryMovement($input: CancelInventoryMovementInput!) {
    cancelInventoryMovement(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const echoCreateInventoryMovement = /* GraphQL */ `
  mutation EchoCreateInventoryMovement($input: AWSJSON!) {
    echoCreateInventoryMovement(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const resetProductInventory = /* GraphQL */ `
  mutation ResetProductInventory(
    $input: ResetProductInventoryInput!
    $test: Boolean!
  ) {
    resetProductInventory(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      productID
      variantID
      salablePK
      shopSalablePK
      date
      quantity
      buyPrice
    }
  }
`;
export const resetInventory = /* GraphQL */ `
  mutation ResetInventory($input: ResetInventoryInput!, $test: Boolean!) {
    resetInventory(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      date
    }
  }
`;
export const createOrUpdateProductInventoryQuantity = /* GraphQL */ `
  mutation CreateOrUpdateProductInventoryQuantity(
    $input: ProductInventoryQuantityInput!
  ) {
    createOrUpdateProductInventoryQuantity(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      shopID
      test
      productID
      variantID
      quantity
      resetDate
      shop {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        name
        address {
          address
          zipcode
          city
          country
        }
        siret
        vat
        phone
        footnote
        logo
        websiteURL
        brandName
        email
      }
    }
  }
`;
export const createPaymentMethod = /* GraphQL */ `
  mutation CreatePaymentMethod(
    $input: CreatePaymentMethodInput!
    $test: Boolean!
  ) {
    createPaymentMethod(input: $input, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      name
      color
      type
      changeType
      countOnCashbookOpeningClosing
      opensCashDrawer
      enabled
    }
  }
`;
export const updatePaymentMethod = /* GraphQL */ `
  mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      name
      color
      type
      changeType
      countOnCashbookOpeningClosing
      opensCashDrawer
      enabled
    }
  }
`;
export const deletePaymentMethod = /* GraphQL */ `
  mutation DeletePaymentMethod($input: DeletePaymentMethodInput!) {
    deletePaymentMethod(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      name
      color
      type
      changeType
      countOnCashbookOpeningClosing
      opensCashDrawer
      enabled
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!, $test: Boolean!) {
    createCustomer(input: $input, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const mergeCustomers = /* GraphQL */ `
  mutation MergeCustomers($input: MergeCustomersInput!) {
    mergeCustomers(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const mergeCustomersAuto = /* GraphQL */ `
  mutation MergeCustomersAuto($input: MergeCustomersAutoInput!) {
    mergeCustomersAuto(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const createCustomerAccountMovement = /* GraphQL */ `
  mutation CreateCustomerAccountMovement(
    $input: CreateCustomerAccountMovementInput!
  ) {
    createCustomerAccountMovement(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      state
      type
      amount
      saleID
      paymentID
      movementInDetails {
        paymentMethodID
        paymentMethodName
        paymentMethodType
      }
    }
  }
`;
export const updateCustomerAccountMovement = /* GraphQL */ `
  mutation UpdateCustomerAccountMovement(
    $input: UpdateCustomerAccountMovementInput!
  ) {
    updateCustomerAccountMovement(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      state
      type
      amount
      saleID
      paymentID
      movementInDetails {
        paymentMethodID
        paymentMethodName
        paymentMethodType
      }
    }
  }
`;
export const createCustomerCustomField = /* GraphQL */ `
  mutation CreateCustomerCustomField($input: CreateCustomerCustomFieldInput!) {
    createCustomerCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const updateCustomerCustomField = /* GraphQL */ `
  mutation UpdateCustomerCustomField($input: UpdateCustomerCustomFieldInput!) {
    updateCustomerCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const deleteCustomerCustomField = /* GraphQL */ `
  mutation DeleteCustomerCustomField($input: DeleteCustomerCustomFieldInput!) {
    deleteCustomerCustomField(input: $input) {
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const createSale = /* GraphQL */ `
  mutation CreateSale($input: CreateSaleInput!, $test: Boolean!) {
    createSale(input: $input, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          minSaleNumber
          maxSaleNumber
          salesCount
          minPaymentNumber
          maxPaymentNumber
          paymentsCount
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            numberOfSales
            minSaleNumber
            maxSaleNumber
            salesCount
            maxPaymentNumber
            minPaymentNumber
            paymentsCount
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const updateSale = /* GraphQL */ `
  mutation UpdateSale($input: UpdateSaleInput!) {
    updateSale(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          minSaleNumber
          maxSaleNumber
          salesCount
          minPaymentNumber
          maxPaymentNumber
          paymentsCount
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            numberOfSales
            minSaleNumber
            maxSaleNumber
            salesCount
            maxPaymentNumber
            minPaymentNumber
            paymentsCount
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const obtainPendingSale = /* GraphQL */ `
  mutation ObtainPendingSale($input: UpdateSaleInput!) {
    obtainPendingSale(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          minSaleNumber
          maxSaleNumber
          salesCount
          minPaymentNumber
          maxPaymentNumber
          paymentsCount
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            numberOfSales
            minSaleNumber
            maxSaleNumber
            salesCount
            maxPaymentNumber
            minPaymentNumber
            paymentsCount
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const deleteSale = /* GraphQL */ `
  mutation DeleteSale($input: DeleteSaleInput!) {
    deleteSale(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          minSaleNumber
          maxSaleNumber
          salesCount
          minPaymentNumber
          maxPaymentNumber
          paymentsCount
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            numberOfSales
            minSaleNumber
            maxSaleNumber
            salesCount
            maxPaymentNumber
            minPaymentNumber
            paymentsCount
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const sendSaleByEmail = /* GraphQL */ `
  mutation SendSaleByEmail($id: ID!, $email: AWSEmail!) {
    sendSaleByEmail(id: $id, email: $email)
  }
`;
export const createCashbook = /* GraphQL */ `
  mutation CreateCashbook($input: CreateCashbookInput!, $test: Boolean!) {
    createCashbook(input: $input, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      number
      state
      total
      totalTaxFree
      minSaleNumber
      maxSaleNumber
      salesCount
      minPaymentNumber
      maxPaymentNumber
      paymentsCount
      openedAt
      closedAt
      openingSellerID
      closingSellerID
      openingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      closingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      note
      movements {
        type
        amount
        motive
        createdAt
      }
      openingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      closingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      totalExpected
      totalDifference
      customFields {
        customFieldID
        name
        valueType
        value
      }
      stats {
        numberOfSales
        minSaleNumber
        maxSaleNumber
        salesCount
        maxPaymentNumber
        minPaymentNumber
        paymentsCount
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        payments {
          id
          detail {
            amount
            quantity
          }
          sellDetail {
            amount
            quantity
          }
          refundDetail {
            amount
            quantity
          }
        }
      }
    }
  }
`;
export const updateCashbook = /* GraphQL */ `
  mutation UpdateCashbook($input: UpdateCashbookInput!) {
    updateCashbook(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      number
      state
      total
      totalTaxFree
      minSaleNumber
      maxSaleNumber
      salesCount
      minPaymentNumber
      maxPaymentNumber
      paymentsCount
      openedAt
      closedAt
      openingSellerID
      closingSellerID
      openingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      closingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      note
      movements {
        type
        amount
        motive
        createdAt
      }
      openingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      closingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      totalExpected
      totalDifference
      customFields {
        customFieldID
        name
        valueType
        value
      }
      stats {
        numberOfSales
        minSaleNumber
        maxSaleNumber
        salesCount
        maxPaymentNumber
        minPaymentNumber
        paymentsCount
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        payments {
          id
          detail {
            amount
            quantity
          }
          sellDetail {
            amount
            quantity
          }
          refundDetail {
            amount
            quantity
          }
        }
      }
    }
  }
`;
export const deleteCashbook = /* GraphQL */ `
  mutation DeleteCashbook($input: DeleteCashbookInput!) {
    deleteCashbook(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      number
      state
      total
      totalTaxFree
      minSaleNumber
      maxSaleNumber
      salesCount
      minPaymentNumber
      maxPaymentNumber
      paymentsCount
      openedAt
      closedAt
      openingSellerID
      closingSellerID
      openingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      closingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      note
      movements {
        type
        amount
        motive
        createdAt
      }
      openingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      closingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      totalExpected
      totalDifference
      customFields {
        customFieldID
        name
        valueType
        value
      }
      stats {
        numberOfSales
        minSaleNumber
        maxSaleNumber
        salesCount
        maxPaymentNumber
        minPaymentNumber
        paymentsCount
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        payments {
          id
          detail {
            amount
            quantity
          }
          sellDetail {
            amount
            quantity
          }
          refundDetail {
            amount
            quantity
          }
        }
      }
    }
  }
`;
export const sendCashbookByEmail = /* GraphQL */ `
  mutation SendCashbookByEmail($id: ID!, $email: AWSEmail!) {
    sendCashbookByEmail(id: $id, email: $email)
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput!, $test: Boolean!) {
    createPayment(input: $input, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      saleID
      number
      date
      state
      type
      paymentMethodID
      paymentMethodName
      paymentMethodType
      sourcePaymentID
      customerMovementID
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      amount
      totalTaxFree
      totalTax
      taxes {
        taxID
        rate
        inclusive
        total
        totalTax
        totalTaxFree
      }
      integrationPaymentID
      integrationRefundID
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      saleID
      number
      date
      state
      type
      paymentMethodID
      paymentMethodName
      paymentMethodType
      sourcePaymentID
      customerMovementID
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      amount
      totalTaxFree
      totalTax
      taxes {
        taxID
        rate
        inclusive
        total
        totalTax
        totalTaxFree
      }
      integrationPaymentID
      integrationRefundID
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment($input: DeletePaymentInput!) {
    deletePayment(input: $input) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      saleID
      number
      date
      state
      type
      paymentMethodID
      paymentMethodName
      paymentMethodType
      sourcePaymentID
      customerMovementID
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      amount
      totalTaxFree
      totalTax
      taxes {
        taxID
        rate
        inclusive
        total
        totalTax
        totalTaxFree
      }
      integrationPaymentID
      integrationRefundID
    }
  }
`;
export const uploadImage = /* GraphQL */ `
  mutation UploadImage($input: UploadImageInput!) {
    uploadImage(input: $input)
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage($input: DeleteImageInput!) {
    deleteImage(input: $input)
  }
`;
export const echoCreateBackgroundJob = /* GraphQL */ `
  mutation EchoCreateBackgroundJob($input: AWSJSON!) {
    echoCreateBackgroundJob(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      shopID
      sourceEmail
      type
      state
      details {
        ... on CatalogMigrationDetails {
          PRODUCT {
            total
            successCount
            errorCount
          }
          PRODUCT_VARIANT {
            total
            successCount
            errorCount
          }
          CATEGORY {
            total
            successCount
            errorCount
          }
          TAX {
            total
            successCount
            errorCount
          }
          DISCOUNT {
            total
            successCount
            errorCount
          }
        }
        ... on CustomerMigrationDetails {
          CUSTOMER {
            total
            successCount
            errorCount
          }
        }
        ... on InventoryMigrationDetails {
          INVENTORY {
            total
            successCount
            errorCount
          }
        }
        ... on ActivityMigrationDetails {
          PAYMENT_METHOD {
            total
            successCount
            errorCount
          }
          SLOT {
            total
            successCount
            errorCount
          }
          CASHBOOK {
            total
            successCount
            errorCount
          }
          SALE {
            total
            successCount
            errorCount
          }
          PAYMENT {
            total
            successCount
            errorCount
          }
        }
        ... on ImportDetails {
          IMPORT {
            total
            successCount
            errorCount
          }
        }
      }
      message
      reportUrl
      flatfileBatchId
      flatfileSheetId
    }
  }
`;
export const echoUpdateBackgroundJob = /* GraphQL */ `
  mutation EchoUpdateBackgroundJob($input: AWSJSON!) {
    echoUpdateBackgroundJob(input: $input) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      shopID
      sourceEmail
      type
      state
      details {
        ... on CatalogMigrationDetails {
          PRODUCT {
            total
            successCount
            errorCount
          }
          PRODUCT_VARIANT {
            total
            successCount
            errorCount
          }
          CATEGORY {
            total
            successCount
            errorCount
          }
          TAX {
            total
            successCount
            errorCount
          }
          DISCOUNT {
            total
            successCount
            errorCount
          }
        }
        ... on CustomerMigrationDetails {
          CUSTOMER {
            total
            successCount
            errorCount
          }
        }
        ... on InventoryMigrationDetails {
          INVENTORY {
            total
            successCount
            errorCount
          }
        }
        ... on ActivityMigrationDetails {
          PAYMENT_METHOD {
            total
            successCount
            errorCount
          }
          SLOT {
            total
            successCount
            errorCount
          }
          CASHBOOK {
            total
            successCount
            errorCount
          }
          SALE {
            total
            successCount
            errorCount
          }
          PAYMENT {
            total
            successCount
            errorCount
          }
        }
        ... on ImportDetails {
          IMPORT {
            total
            successCount
            errorCount
          }
        }
      }
      message
      reportUrl
      flatfileBatchId
      flatfileSheetId
    }
  }
`;
export const runInventoryCalculations = /* GraphQL */ `
  mutation RunInventoryCalculations($companyID: ID!) {
    runInventoryCalculations(companyID: $companyID)
  }
`;
