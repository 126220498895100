const RadioButtonUnchecked = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1614 2C6.64138 2 2.16138 6.48 2.16138 12C2.16138 17.52 6.64138 22 12.1614 22C17.6814 22 22.1614 17.52 22.1614 12C22.1614 6.48 17.6814 2 12.1614 2ZM12.1614 20C7.74138 20 4.16138 16.42 4.16138 12C4.16138 7.58 7.74138 4 12.1614 4C16.5814 4 20.1614 7.58 20.1614 12C20.1614 16.42 16.5814 20 12.1614 20Z"
      fill={color}
    />
  </svg>
)

export default RadioButtonUnchecked
