import {
  onCreateProduct,
  onDeleteProduct,
  onUpdateProduct,
  onCreateProductCustomField,
  onUpdateProductCustomField,
  onDeleteProductCustomField,
  onCreateProductTag,
  onDeleteProductTag,
  onUpdateProductInventoryQuantity,
} from '../../graphql/custom/subscriptions';
import { getCompanyID, getTestMode } from '../../services/localStorage';
import {
  updateListElement,
  deleteListElement,
  addListElement,
  updateProducSubscription,
  updateProductInventoryQuantities,
} from '../../actions/list';
import { objectType } from '../../store/types';
import formatWithSubvalues from '../../format/product';
import { getObservable } from '../../services/graphql';
import { DataWithErrors } from '../../services/types';
import { deleteTagsData, updatePacksAssociations, updateTagsData } from '../../actions/catalog';
import { callService } from '../../services';
import { GetProductQueryVariables, GetProductVariantQueryVariables } from '../../API';
import {
  getProduct as get,
  getProductForInventoryMovement as getNoLimit,
  getProductVariant,
} from '../../graphql/custom/queries';
import { pluralize } from '../../utils/typeToType';

const getProduct = async (
  id: string,
  dispatch: any,
  selected?: boolean,
  variant?: boolean,
): Promise<DataWithErrors> => {
  const product = await callService<GetProductQueryVariables>({ id }, get, 'getProduct');
  if (product.data) {
    const element = formatWithSubvalues(!!selected, false)(product.data);
    if (element.photo) element.photo = element.photo + '?' + Date.now();
    //! to be tested and tested again
    if (!variant) {
      updateListElement({
        element,
        dispatch,
        type: objectType.PRODUCT,
      });
    }
    if (element.subValues) addListElement(dispatch, element.subValues, '', pluralize(objectType.VARIANT));

    return { ...product, data: formatWithSubvalues(!!selected, false)(product.data) };
  }
  return product;
};

const getVariant = async (id: string, variantID: string): Promise<DataWithErrors> => {
  const variant = await callService<GetProductVariantQueryVariables>(
    { productID: id, variantID },
    getProductVariant,
    'getProduct',
  );
  return variant;
};

const getProductForInventoryMovement = async (
  id: string,
  dispatch: any,
  selected?: boolean,
): Promise<DataWithErrors> => {
  const product = await callService<GetProductQueryVariables>({ id }, getNoLimit, 'getProduct');
  if (product.data) {
    const element = formatWithSubvalues(!!selected, false)(product.data);
    if (element.photo) element.photo = element.photo + '?' + Date.now();
    updateListElement({
      element,
      dispatch,
      type: objectType.PRODUCT,
    });
    if (element.subValues) addListElement(dispatch, element.subValues, '', pluralize(objectType.VARIANT));

    return { ...product, data: formatWithSubvalues(!!selected, false)(product.data) };
  }
  return product;
};

const getProductWithVariant = async (id: string, dispatch: any, selected?: boolean): Promise<DataWithErrors> => {
  const product = await callService<GetProductQueryVariables>({ id }, get, 'getProduct');
  if (product.data) {
    const element = formatWithSubvalues(!!selected, false)(product.data);
    if (element.photo) element.photo = element.photo + '?' + Date.now();
    if (element.subValues) addListElement(dispatch, element.subValues, '', pluralize(objectType.VARIANT));

    return { ...product, data: formatWithSubvalues(!!selected, false)(product.data) };
  }
  return product;
};

let onCreateProductSubscription: any;
let onDeleteProductSubscription: any;
let onUpdateProductSubscription: any;

const subscribeProducts = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  };
  if (!onCreateProductSubscription)
    onCreateProductSubscription = getObservable(onCreateProduct, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateProduct) {
          const element = formatWithSubvalues(false, false)(eventData.data.onCreateProduct);
          if (element.photo) element.photo = element.photo + '?' + Date.now();
          updateListElement({
            element,
            dispatch,
            type: objectType.PRODUCT,
          });
          if (element.subValues) addListElement(dispatch, element.subValues, '', pluralize(objectType.VARIANT));
        }
      },
    });

  if (!onUpdateProductSubscription)
    onUpdateProductSubscription = getObservable(onUpdateProduct, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateProduct) {
          if (eventData.data.onUpdateProduct.photo)
            eventData.data.onUpdateProduct.photo = eventData.data.onUpdateProduct.photo + '?' + Date.now();
          updateProducSubscription(eventData.data.onUpdateProduct, eventData.data.onUpdateProduct.id, dispatch);
          updatePacksAssociations({ productID: eventData.data.onUpdateProduct.id, dispatch }, dispatch);
        }
      },
    });

  if (!onDeleteProductSubscription)
    onDeleteProductSubscription = getObservable(onDeleteProduct, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeleteProduct,
          dispatch,
          type: objectType.PRODUCT,
        }),
    });
};

const unSubscribeProducts = () => {
  if (onCreateProductSubscription) {
    onCreateProductSubscription.unsubscribe();
    onCreateProductSubscription = null;
  }

  if (onUpdateProductSubscription) {
    onUpdateProductSubscription.unsubscribe();
    onUpdateProductSubscription = null;
  }

  if (onDeleteProductSubscription) {
    onDeleteProductSubscription.unsubscribe();
    onDeleteProductSubscription = null;
  }
};

let onCreateProductTagSubscription: any;
let onDeleteProductTagSubscription: any;

const subscribeProductTags = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  };
  if (!onCreateProductTagSubscription)
    onCreateProductTagSubscription = getObservable(onCreateProductTag, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateProductTag) {
          const { productID, tagID } = eventData.data.onCreateProductTag;
          updateTagsData({ tagID, productID }, dispatch);
        }
      },
    });

  if (!onDeleteProductTagSubscription)
    onDeleteProductTagSubscription = getObservable(onDeleteProductTag, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onDeleteProductTag) {
          const { productID, tagID } = eventData.data.onDeleteProductTag;
          deleteTagsData({ tagID, productID }, dispatch);
        }
      },
    });
};

const unSubscribeProductTags = () => {
  if (onCreateProductTagSubscription) {
    onCreateProductTagSubscription.unsubscribe();
    onCreateProductTagSubscription = null;
  }

  if (onDeleteProductTagSubscription) {
    onDeleteProductTagSubscription.unsubscribe();
    onDeleteProductTagSubscription = null;
  }
};

let onCreateProductCustomFieldSubscription: any;
let onUpdateProductCustomFieldSubscription: any;
let onDeleteProductCustomFieldSubscription: any;

const subscribeProductCustomFields = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  };
  if (!onCreateProductCustomFieldSubscription)
    onCreateProductCustomFieldSubscription = getObservable(onCreateProductCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateProductCustomField) {
          updateListElement({
            element: eventData.data.onCreateProductCustomField,
            dispatch,
            type: objectType.PRODUCT,
          });
          // getProduct(eventData.data.onCreateProductCustomField.productID, dispatch)
        }
      },
    });

  if (!onUpdateProductCustomFieldSubscription)
    onUpdateProductCustomFieldSubscription = getObservable(onUpdateProductCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateProductCustomField) {
          updateListElement({
            element: eventData.data.onUpdateProductCustomField,
            dispatch,
            type: objectType.PRODUCT,
          });
          // getProduct(eventData.data.onUpdateProductCustomField.productID, dispatch)
        }
      },
    });

  if (!onDeleteProductCustomFieldSubscription)
    onDeleteProductCustomFieldSubscription = getObservable(onDeleteProductCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onDeleteProductCustomField) {
          updateListElement({
            element: eventData.data.onDeleteProductCustomField,
            dispatch,
            type: objectType.PRODUCT,
          });
        }
        // getProduct(eventData.data.onDeleteProductCustomField.productID, dispatch)
      },
    });
};

const unSubscribeProductCustomFields = () => {
  if (onCreateProductCustomFieldSubscription) {
    onCreateProductCustomFieldSubscription.unsubscribe();
    onCreateProductCustomFieldSubscription = null;
  }

  if (onUpdateProductCustomFieldSubscription) {
    onUpdateProductCustomFieldSubscription.unsubscribe();
    onUpdateProductCustomFieldSubscription = null;
  }

  if (onDeleteProductCustomFieldSubscription) {
    onDeleteProductCustomFieldSubscription.unsubscribe();
    onDeleteProductCustomFieldSubscription = null;
  }
};

let onUpdateProductInventoryQuantitySubscriptions: Array<any> = [];

const subscribeProductInventoryQuantities = (shops: Array<any>, dispatch: any) => {
  shops.forEach((shop, index) => {
    onUpdateProductInventoryQuantitySubscriptions[index] = getObservable(onUpdateProductInventoryQuantity, {
      shopID: shop.id,
      test: getTestMode(),
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateProductInventoryQuantity)
          updateProductInventoryQuantities(eventData.data.onUpdateProductInventoryQuantity, dispatch);
      },
    });
  });
};

const unSubscribeProductInventoryQuantities = () => {
  if (onUpdateProductInventoryQuantitySubscriptions) {
    onUpdateProductInventoryQuantitySubscriptions.forEach(subscription => {
      subscription.unsubscribe();
      subscription = null;
    });
    onUpdateProductInventoryQuantitySubscriptions = [];
  }
};

export {
  subscribeProducts,
  unSubscribeProducts,
  getProduct,
  getVariant,
  getProductWithVariant,
  getProductForInventoryMovement,
  subscribeProductTags,
  unSubscribeProductTags,
  subscribeProductCustomFields,
  unSubscribeProductCustomFields,
  subscribeProductInventoryQuantities,
  unSubscribeProductInventoryQuantities,
};
