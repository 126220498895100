import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DrawerInventoryMove from '../drawer'
import List from '../../../../components/list'
import { ListState, objectType } from '../../../../store/types'
import {
  listInventoryMovementItems,
  listInventoryMovements,
  listInventoryMovementItemsNextToken,
  getDashboardView,
  listCustomFieldItems,
  listShopsItemsNextToken,
  currentAccount,
} from '../../../../store/selector'
import {
  addInventoryMovementShopsOriginFields,
  addInventoryMovementShopsDestinationFields,
} from '../../../../requester'
import inventoryMovementFieldsRequester from '../../../../requester/catalog/inventoryMovement'
import { buildCols } from '../../../../utils'
import addCustomFieldsInEntity from '../../../../requester/catalog/customField'
import { CustomFieldObjectType, CustomFieldValueType } from '../../../../API'
import { TypeCustomField } from '../../../../types'
import { TypeComparator } from '../../../../components/requester/types'
import { updateModal } from '../../../../actions/modal'
import CreateModal from '../../../../components/newComponents/modal/inventoryMovement'

const ListInventoryMovements = () => {
  const res = useSelector(listInventoryMovements)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listInventoryMovementItems)
  const { items, nextToken, sort } = useSelector(listInventoryMovementItemsNextToken)
  const dashboardView = useSelector(getDashboardView)
  const customFields: Array<TypeCustomField> = useSelector(listCustomFieldItems)
  const shops = useSelector(listShopsItemsNextToken)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => setCols(buildCols(res.cols!, 'createdAt', 'state', dashboardView)), [dashboardView, res.cols])

  const getAllFields = () => {
    const sortedCustomFields = [
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.TEXT),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.NUMBER),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.DATE),
    ]

    const fieldsCopy = [...inventoryMovementFieldsRequester(t)]
    let index = fieldsCopy.findIndex((field) => field.filterID === 'STATE')
    fieldsCopy.splice(index + 1, 0, addInventoryMovementShopsOriginFields(shops.items, t))

    index = fieldsCopy.findIndex((field) => field.filterID === 'ORIGIN')
    fieldsCopy.splice(index + 1, 0, addInventoryMovementShopsDestinationFields(shops.items, t))

    index = fieldsCopy.findIndex((field) => field.filterID === 'TAG_NAME')
    fieldsCopy.splice(
      index + 1,
      0,
      ...addCustomFieldsInEntity(sortedCustomFields, CustomFieldObjectType.PRODUCT, ['movement', 'details', 'product'])
    )

    return fieldsCopy
  }

  const handleCreate = (type?: string) =>
    updateModal(
      dispatch,
      true,
      () => <CreateModal navigate={navigate} type={type} />,
      () => { },
      "inventoryMovement"
    )

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={getAllFields()}
      searchBarFields={[{ filter: TypeComparator.CONTAINS, field: 'motive' }]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.INVENTORY_MOVEMENT}
      Drawer={DrawerInventoryMove}
      onCreate={handleCreate}
    />
  )
}

export default ListInventoryMovements
