import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getDashboardView, selectedCompany } from '../../../store/selector'
import { DashboardView } from '../../../store/types'
import { displayPrice } from '../../../utils/number'
import { TypeStat } from '../../../views/stat/types'

import styles from './Stat.module.css'
import generateDataCy from '../../../utils/cypress'

const ChartArea = ({ state }: { state: TypeStat }) => {
  const company = useSelector(selectedCompany)
  const dashboardView = useSelector(getDashboardView)
  const { t } = useTranslation()

  return (
    <>
      {dashboardView !== DashboardView.MOBILE ? (
        <div className={styles.containerChart}>
          <div data-cy={generateDataCy({ scope: 'stat', value: 'graph' })} className={styles.listChartName}>
            {t('stat.EVOLUTION')}
          </div>
          <Chart
            type={'area'}
            height={350}
            series={state.chart?.map((chart) => ({
              name: chart.name,
              data: chart.histo.map((data: { total: number }) => data.total),
            }))}
            options={{
              chart: {
                toolbar: { show: false },
                zoom: { enabled: false },
              },
              yaxis: {
                labels: {
                  formatter: (value: number) =>
                    company && displayPrice(value, company.currency, company.currencyDecimals),
                },
              },
              colors: state.chart?.map((chart) => chart.color),
              fill: {
                type: 'gradient',
                gradient: {
                  shadeIntensity: 1,
                  inverseColors: false,
                  opacityFrom: 0.15,
                  opacityTo: 0.02,
                },
              },
              xaxis: {
                categories: state.chart?.map((chart) => chart.histo.map((data: any) => data.date)).flat(),
              },
              stroke: {
                curve: 'smooth',
              },
              dataLabels: {
                enabled: false,
              },
              states: {
                hover: {
                  filter: {
                    type: 'darken',
                    value: 0.7,
                  },
                },
                active: {
                  filter: {
                    type: 'darken',
                    value: 0.5,
                  },
                },
              },
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default ChartArea
