import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { drawerAddHistory, drawerBack } from '../../../actions/drawer'
import { Route } from '../../../utils/navigation'
import { currentDrawer, currentNavigation } from '../../../store/selector'

type Props = {
  component: (props: any) => JSX.Element
}

const DrawerMenu = ({ component }: Props) => {
  const dispatch = useDispatch()
  const drawer = useSelector(currentDrawer)
  const route: Route | undefined = useSelector(currentNavigation)

  useEffect(() => {
    // si history n'est pas initialisé
    if (!drawer.navigation.current) {
      drawerAddHistory({ component, params: { id: drawer.id! } }, dispatch)
    }
      }, [drawer])

  const back = () => drawerBack(dispatch)

  const addHistory = (route: Route) => drawerAddHistory(route, dispatch)

  if (route) {
    const { component, params } = route
    const ComponentToDisplay = component
    return <ComponentToDisplay {...params} close={back} addHistory={addHistory} history={drawer.navigation.history} />
  }
  return <></>
}

export default DrawerMenu
