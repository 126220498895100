import { PaymentState } from '../../../API'
import { currencyTransformer, dateEqualTransformer } from '../../../components/requester/query'
import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../../components/requester/types'
import { salePaymentStateToStr } from '../../../utils/typeToType'

const paymentFieldsRequester = (t: (field: string) => string): Array<TypeRequester> => [
  {
    type: TypePath.PLAIN,
    filterID: 'NUMBER',
    field: 'number',
    path: [],
    filters: [
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'SITUATION',
    field: 'state',
    path: [],
    filters: [
      {
        type: TypeFilter.LIST,
        list: Object.values(PaymentState).map((value) => ({
          id: value,
          value: salePaymentStateToStr(value, t).text,
        })),
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.LIST,
        list: Object.values(PaymentState).map((value) => ({
          id: value,
          value: salePaymentStateToStr(value, t).text,
        })),
        comparator: TypeComparator.NE,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'PAYMENT_METHOD',
    field: 'paymentMethodName',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'AMOUNT',
    field: 'amount',
    transformer: currencyTransformer,
    path: [],
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'SHOP_NAME',
    field: 'name',
    path: ['shop'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'SOURCE_NAME',
    field: 'sourceName',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'DATE',
    field: 'date',
    path: [],
    transformer: dateEqualTransformer,
    filters: [
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.DATE,
        comparator: TypeComparator.LT,
      },
    ],
  },
]

export default paymentFieldsRequester
