import { unSubscribeToAll } from '../store/subscriptions'
import { Action, AppState, MenuState } from '../store/types'
import { resetCustomCols } from './cols'
import { drawerOpen } from './drawer'
import initApp from './initApp'
import { unSelectAllList } from './list'
import toggleMenu from './menu'
import { updateModal } from './modal'
import { clearAllFilters } from './requester'

const updateAppState = (payload: AppState, dispatch: any) => dispatch({ type: Action.UPDATE_APP_STATE, payload })

const resetApp = (t: (field: string) => string, isMobileView: boolean, dispatch: any) => {
  unSelectAllList(dispatch)
  drawerOpen(dispatch, { open: false })
  resetCustomCols(dispatch)
  unSubscribeToAll()
  updateAppState(AppState.LOADING, dispatch)
  updateModal(
    dispatch,
    false,
    () => <></>,
    () => { }
  )
  if (isMobileView) toggleMenu(dispatch, { state: MenuState.CLOSE })
  initApp(dispatch, t)
}

const resetActions = (dispatch: any) => {
  clearAllFilters(dispatch)
  unSelectAllList(dispatch)
  drawerOpen(dispatch, { open: false })
}

const updateTestMode = (dispatch: any) => dispatch({ type: Action.TEST_MODE })

const disconnect = (dispatch: any) => dispatch({ type: Action.DISCONNECT })

export { updateTestMode, updateAppState, disconnect, resetApp, resetActions }
