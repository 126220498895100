import SearchList from '../../oldComponents/searchList'

const SearchCell = ({
  fieldID,
  index,
  value,
  entries,
  onChange,
}: {
  fieldID: string
  index: number
  value: any
  entries: Array<any>
  onChange: (val: any) => void
}) => (
  <SearchList
    eventID="list"
    parentID={'searchList' + fieldID + index}
    popUpID={'searchListPopUp' + fieldID + index}
    valueId={value}
    onSelect={onChange}
    entries={entries.map((e) => ({ id: e.id, val: e.value }))}
    dataCy={'searchCell'}
  />
)

export default SearchCell
