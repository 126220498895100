import {
  updateListElement,
  deleteListElement,
  updateCustomFieldValue,
  createCustomFieldValue,
  deleteCustomFieldValue,
} from '../../actions/list'
import { objectType } from '../../store/types'
import { DataWithErrors } from '../../services/types'
import { getCustomer as get } from '../../graphql/custom/queries'
import { getObservable } from '../../services/graphql'
import {
  onUpdateCustomer,
  onDeleteCustomer,
  onCreateCustomer,
  onCreateCustomerCustomField,
  onUpdateCustomerCustomField,
  onDeleteCustomerCustomField,
} from '../../graphql/custom/subscriptions'
import { getCompanyID } from '../../services/localStorage'
import { GetCustomerQueryVariables } from '../../API'
import { callService } from '../../services'

const getCustomer = async (id: string, dispatch: any): Promise<DataWithErrors> => {
  const customer = await callService<GetCustomerQueryVariables>({ id }, get, 'getCustomer')

  if (customer.data) {
    updateListElement({
      element: customer.data,
      dispatch,
      type: objectType.CUSTOMER,
    })
  }
  return customer
}

let onCreateCustomerSubscription: any
let onUpdateCustomerSubscription: any
let onDeleteCustomerSubscription: any

const subscribeCustomers = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
  }
  if (!onCreateCustomerSubscription)
    onCreateCustomerSubscription = getObservable(onCreateCustomer, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateCustomer)
          updateListElement({
            element: eventData.data.onCreateCustomer,
            dispatch,
            type: objectType.CUSTOMER,
          })
      },
    })

  if (!onUpdateCustomerSubscription)
    onUpdateCustomerSubscription = getObservable(onUpdateCustomer, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateCustomer)
          updateListElement({
            element: eventData.data.onUpdateCustomer,
            dispatch,
            type: objectType.CUSTOMER,
          })
      },
    })

  if (!onDeleteCustomerSubscription)
    onDeleteCustomerSubscription = getObservable(onDeleteCustomer, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeleteCustomer,
          dispatch,
          type: objectType.CUSTOMER,
        }),
    })
}

const unSubscribeCustomers = () => {
  if (onCreateCustomerSubscription) {
    onCreateCustomerSubscription.unsubscribe()
    onCreateCustomerSubscription = null
  }

  if (onUpdateCustomerSubscription) {
    onUpdateCustomerSubscription.unsubscribe()
    onUpdateCustomerSubscription = null
  }

  if (onDeleteCustomerSubscription) {
    onDeleteCustomerSubscription.unsubscribe()
    onDeleteCustomerSubscription = null
  }
}

let onCreateCustomerCustomFieldSubscription: any
let onUpdateCustomerCustomFieldSubscription: any
let onDeleteCustomerCustomFieldSubscription: any

const subscribeCustomerCustomFields = (dispatch: any) => {
  const input = {
    companyID: getCompanyID(),
  }
  if (!onCreateCustomerCustomFieldSubscription)
    onCreateCustomerCustomFieldSubscription = getObservable(onCreateCustomerCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateCustomerCustomField) {
          createCustomFieldValue(dispatch, eventData.data.onCreateCustomerCustomField, objectType.CUSTOMER)
        }
      },
    })

  if (!onUpdateCustomerCustomFieldSubscription)
    onUpdateCustomerCustomFieldSubscription = getObservable(onUpdateCustomerCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateCustomerCustomField)
          updateCustomFieldValue(dispatch, eventData.data.onUpdateCustomerCustomField, objectType.CUSTOMER)
      },
    })

  if (!onDeleteCustomerCustomFieldSubscription)
    onDeleteCustomerCustomFieldSubscription = getObservable(onDeleteCustomerCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onDeleteCustomerCustomField)
          deleteCustomFieldValue(dispatch, eventData.data.onDeleteCustomerCustomField, objectType.CUSTOMER)
      },
    })
}

const unSubscribeCustomerCustomFields = () => {
  if (onCreateCustomerCustomFieldSubscription) {
    onCreateCustomerCustomFieldSubscription.unsubscribe()
    onCreateCustomerCustomFieldSubscription = null
  }

  if (onUpdateCustomerCustomFieldSubscription) {
    onUpdateCustomerCustomFieldSubscription.unsubscribe()
    onUpdateCustomerCustomFieldSubscription = null
  }

  if (onDeleteCustomerCustomFieldSubscription) {
    onDeleteCustomerCustomFieldSubscription.unsubscribe()
    onDeleteCustomerCustomFieldSubscription = null
  }
}

export {
  subscribeCustomers,
  unSubscribeCustomers,
  getCustomer,
  subscribeCustomerCustomFields,
  unSubscribeCustomerCustomFields,
}
