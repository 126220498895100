import { CreateOptionInput } from '../../../../../API'
import { createOption } from '../../../../../graphql/custom/mutations'
import { callService } from '../../../../../services'
import { getCompanyID } from '../../../../../services/localStorage'
import { APIOptionValues, TypeOption } from '../../../../../types'
import { createInput } from '../../../../../utils/dirties'
import { convertPrice } from '../../../../../utils/number'
import { parseName } from '../../../../../utils/string'

const callOptionCreation = async (payload: TypeOption, decimals: number) => {
  const input: any = createInput(payload)

  if (input) {
    input.values = input.values.map((value: APIOptionValues) => ({
      name: parseName(value.name),
      price: value.price ? convertPrice(decimals, value.price) : 0,
    }))

    return callService<{ input: CreateOptionInput }>(
      { input: { ...input, catalogID: getCompanyID() } },
      createOption,
      'createOption'
    )
  }
  return null
}

export default callOptionCreation
