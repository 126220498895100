const Account = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1614 2C6.64138 2 2.16138 6.48 2.16138 12C2.16138 17.52 6.64138 22 12.1614 22C17.6814 22 22.1614 17.52 22.1614 12C22.1614 6.48 17.6814 2 12.1614 2ZM12.1614 5C13.8214 5 15.1614 6.34 15.1614 8C15.1614 9.66 13.8214 11 12.1614 11C10.5014 11 9.16138 9.66 9.16138 8C9.16138 6.34 10.5014 5 12.1614 5ZM12.1614 19.2C9.66138 19.2 7.45138 17.92 6.16138 15.98C6.19138 13.99 10.1614 12.9 12.1614 12.9C14.1514 12.9 18.1314 13.99 18.1614 15.98C16.8714 17.92 14.6614 19.2 12.1614 19.2Z"
      fill={color}
    />
  </svg>
)

export default Account
