const Tax = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66138 11.8714V15.8714C4.66138 16.7014 5.33138 17.3714 6.16138 17.3714C6.99138 17.3714 7.66138 16.7014 7.66138 15.8714V11.8714C7.66138 11.0414 6.99138 10.3714 6.16138 10.3714C5.33138 10.3714 4.66138 11.0414 4.66138 11.8714ZM10.6614 11.8714V15.8714C10.6614 16.7014 11.3314 17.3714 12.1614 17.3714C12.9914 17.3714 13.6614 16.7014 13.6614 15.8714V11.8714C13.6614 11.0414 12.9914 10.3714 12.1614 10.3714C11.3314 10.3714 10.6614 11.0414 10.6614 11.8714ZM4.16138 22.3714H20.1614C20.9914 22.3714 21.6614 21.7014 21.6614 20.8714C21.6614 20.0414 20.9914 19.3714 20.1614 19.3714H4.16138C3.33138 19.3714 2.66138 20.0414 2.66138 20.8714C2.66138 21.7014 3.33138 22.3714 4.16138 22.3714ZM16.6614 11.8714V15.8714C16.6614 16.7014 17.3314 17.3714 18.1614 17.3714C18.9914 17.3714 19.6614 16.7014 19.6614 15.8714V11.8714C19.6614 11.0414 18.9914 10.3714 18.1614 10.3714C17.3314 10.3714 16.6614 11.0414 16.6614 11.8714ZM11.2314 1.86141L3.33138 6.02141C2.92138 6.23141 2.66138 6.66141 2.66138 7.12141C2.66138 7.81141 3.22138 8.37141 3.91138 8.37141H20.4214C21.1014 8.37141 21.6614 7.81141 21.6614 7.12141C21.6614 6.66141 21.4014 6.23141 20.9914 6.02141L13.0914 1.86141C12.5114 1.55141 11.8114 1.55141 11.2314 1.86141Z"
      fill={color}
    />
  </svg>
)

export default Tax
