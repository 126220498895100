import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'
import callCategoryUpdate from './service/udpate'
import callCategoryCreation from './service/create'
import { setErrorsInState, findErrorsInState } from '../../../../../format/errors'
import { getDrawerState } from '../../../../../store/selector'
import { drawerResetHistory, updateDrawerState } from '../../../../../actions/drawer'
import { DrawerState } from '../../../../../store/types'
import { displayIconText } from '../../../../../utils/string'
import Loader, { displayLoader } from '../../../../../components/newComponents/loader'
import { TypeCategory } from '../../../../../types'
import { handleBlur, handleBlurNested } from '../../../../../format/form'
import SubmitButton from '../../../../../components/oldComponents/drawer/submitButton'
import { getDrawerHeight, getDrawerHeightNoTab } from '../../../../../utils'

import styles from '../../../../../components/oldComponents/drawer/Drawer.module.css'
import generateDataCy from '../../../../../utils/cypress'

type Props = {
  categoryState: TypeCategory
  updateState: (state: TypeCategory) => void
  close: () => void
  dataCy?: string
}

const Detail = ({ categoryState, updateState, close, dataCy }: Props) => {
  const [state, setState] = useState(categoryState)
  const drawerState = useSelector(getDrawerState)
  const dispatch = useDispatch()

  const handleChange = (field: string, index?: number) => (event: any) => {
    state.errors.global = undefined

    state.dirties = [...state.dirties, field]

    if (field === 'photo') {
      setState({
        ...state,
        selected: !event ? 'color' : 'photo',
        [field]: event,
        showSubmit: !findErrorsInState(state.errors),
      })
    } else if (field === 'color') {
      setState({ ...state, selected: 'color', [field]: event, showSubmit: !findErrorsInState(state.errors) })
    } else if (field === 'iconText') {
      // @ts-ignore
      state.errors[field] = null
      state.isUpdateIconText = true

      setState({
        ...state,
        [field]: event,
        showSubmit: !findErrorsInState(state.errors),
      })
    } else if (field === 'customFields') {
      // @ts-ignore
      state.customFields[index].value = event
      setState({ ...state, showSubmit: true })
    } else {
      // @ts-ignore
      state.errors[field] = null
      const showSubmit = !findErrorsInState(state.errors)

      if (field === 'name' && !state.isUpdateIconText) {
        state.iconText = event ? displayIconText(event) : ''
        state.dirties = [...state.dirties, 'iconText']
      }

      setState({ ...state, [field]: event, showSubmit })
    }
  }

  const handleResult = (res: any) => {
    if (!res.errors || res.errors.length === 0) {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
      drawerResetHistory(dispatch)
      close()
    } else {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    }
  }

  const handleSubmit = () => {
    setState(handleBlur(state, 'name'))

    const { customFields, ...errors } = state.errors
    if (!findErrorsInState(errors) && !customFields?.find((cf) => cf && cf.value)) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      if (!state.id) {
        callCategoryCreation(state).then(handleResult)
      } else {
        callCategoryUpdate(state).then(handleResult)
      }
    }
  }

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={styles.containerForm}
          style={{
            height: state.id ? getDrawerHeight() : getDrawerHeightNoTab(),
          }}
          data-cy={generateDataCy({ scope: dataCy!, value: state.id ? 'edit' : 'create' })}
        >
          <Form
            state={state}
            handleChange={handleChange}
            handleBlur={(field) => setState(handleBlur(state, field))}
            handleBlurNested={(index) => setState(handleBlurNested(state, 'customFields', index))}
            handleSubmit={handleSubmit}
            dataCy={dataCy!}
          />
          <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Detail
