import { displayPercentage, displayPrice } from '../../../utils/number'
import { DiscountType } from '../../../API'

/**
 *
 * This element displays a parsed price
 *
 * @param type - Price's type
 * @param value - Price
 * @param emptyValue - An empty value text if price is not present
 * @param currency - Company's currency
 * @param decimals - Currency's decimals
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const DisplayPrice = ({
  type,
  value,
  emptyValue,
  currency,
  decimals,
  style,
}: {
  type?: DiscountType
  value: number
  emptyValue?: string
  currency: string
  decimals: number
  style?: {}
}): JSX.Element => (
  <div style={style}>
    {type && type === DiscountType.PERCENTAGE
      ? value !== null && value !== undefined
        ? displayPercentage(value)
        : emptyValue ?? ''
      : value !== null && value !== undefined
      ? displayPrice(value, currency, decimals)
      : emptyValue ?? displayPrice(0, currency, decimals)}
  </div>
)

export default DisplayPrice
