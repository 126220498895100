import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import List from '../../../components/list'
import Drawer from './drawer'
import { CustomFieldObjectType, CustomFieldValueType } from '../../../API'
import { TypeCustomField } from '../../../types'
import {
  getDashboardView,
  listCashbookItems,
  listCashbookItemsNextToken,
  listCashbooks,
  listCustomFieldItems,
} from '../../../store/selector'
import { objectType, ListState } from '../../../store/types'
import { buildCols } from '../../../utils'
import { TypeComparator } from '../../../components/requester/types'
import cashbookFieldsRequester from '../../../requester/activity/cashbook'
import addCustomFieldsInEntity from '../../../requester/catalog/customField'
import { getData } from '../../../services'
import { getListCashbooks } from '../../../graphql/custom/queries'
import { useDispatch } from 'react-redux'
import { pluralize } from '../../../utils/typeToType'
import { fetchListElement, updateListState } from '../../../actions/list'
import { subscribeCashbooks, unSubscribeCashbooks } from '../../../getters/activity/cashbook'

const ListCashbooks = () => {
  const res = useSelector(listCashbooks)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listCashbookItems)
  const { items, nextToken, sort } = useSelector(listCashbookItemsNextToken)
  const customFields: Array<TypeCustomField> = useSelector(listCustomFieldItems)
  const dashboardView = useSelector(getDashboardView)
  const { t } = useTranslation()

  const dispatch = useDispatch();

  useEffect(() => {
    subscribeCashbooks(dispatch)
    const getListCashbookItems = async () => {
      const listCashbooks = await getData({ request: getListCashbooks, limit: 100 });
      if (listCashbooks.requestCashbooks && listCashbooks.requestCashbooks.items && listCashbooks.requestCashbooks.items.length > 0) {
        const cashbooks = listCashbooks.requestCashbooks.items.filter((_: any) => _)
        fetchListElement(dispatch, cashbooks, listCashbooks.requestCashbooks.nextToken, pluralize(objectType.CASHBOOK))
        updateListState(
          { type: pluralize(objectType.CASHBOOK), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.CASHBOOK))
        updateListState(
          { type: pluralize(objectType.CASHBOOK), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListCashbookItems();

    return () => unSubscribeCashbooks();
      }, [])

  useEffect(() => setCols(buildCols(res.cols!, 'number', 'sourceName', dashboardView)), [dashboardView, res.cols])

  const getAllFields = () => {
    const sortedCustomFields = [
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.TEXT),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.NUMBER),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.DATE),
    ]
    const fieldsCopy = [...cashbookFieldsRequester(t)]

    const index = fieldsCopy.findIndex((field) => field.filterID === 'OPENED_AT')
    fieldsCopy.splice(index, 0, ...addCustomFieldsInEntity(sortedCustomFields, CustomFieldObjectType.CASHBOOK))

    return fieldsCopy
  }

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={getAllFields()}
      searchBarFields={[{ filter: TypeComparator.EQ, field: 'number' }]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.CASHBOOK}
      Drawer={Drawer}
    />
  )
}

export default ListCashbooks
