import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { DeleteDiscountInput, DiscountType } from '../../../API'
import { DashboardView, DrawerState, DrawerType, objectType } from '../../../store/types'
import { TypeTab, TypeDrawer } from '../../../types/drawer'
import Detail from './drawer'
import { getRandomColor } from '../../../utils/color'
import { getDiscount } from '../../../getters/catalog/discount'
import { TabIdsCatalog } from '..'
import { DataWithErrors } from '../../../services/types'
import {
  name as nameValidator,
  rate as rateValidator,
  rateNumeric as rateNumericValidator,
  ratePercent as ratePercentValidator,
} from '../../../format/errors/filters/catalog/discount'
import { Stats } from '../../../types/stat'
import { currentDrawer, getDashboardView, isDrawerOpen, listDiscountItems, listDiscounts, selectedCompany } from '../../../store/selector'
import DrawerMenu from '../../../components/oldComponents/drawer/menu'
import { drawerAddHistory, drawerResetHistory, updateDrawerState } from '../../../actions/drawer'
import { Route } from '../../../utils/navigation'
import {
  ComponentDirection,
  ComponentSize,
  ComponentState,
  ComponentType,
} from '../../../components/newComponents/types'
import DrawerTop from '../../../components/oldComponents/drawer/top'
import TabList from '../../../components/newComponents/tabList'
import { TypeDiscount } from '../../../types'
import { deleteDiscount } from '../../../graphql/custom/mutations'
import { callService } from '../../../services'
import DrawerBulk, { TypeQuickActions } from '../../../components/oldComponents/drawer/quickAction'
import Statistic from '../../../components/oldComponents/drawer/statistic'
import { getDiscountStats } from '../../../graphql/custom/queries'
import { createPayload } from '../../../utils/dirties'
import generateDataCy from '../../../utils/cypress'
import Divider from '../../../components/newComponents/divider'
import { displayNumber } from '../../../utils/number'

import { Duplicate, DeleteIcon } from '../../../assets/icons'

export const MenuDiscount = ({
  id,
  isDuplicate,
  addHistory,
  history,
  close,
}: {
  id?: string
  isDuplicate?: boolean
  addHistory: (route: Route) => void
  history: Array<Route>
  close: () => void
}) => {
  const { t } = useTranslation()
  const drawerIsOpen = useSelector(isDrawerOpen);
  const allDiscounts = useSelector(listDiscountItems);

  const initialState: {
    tabList: Array<TypeTab>
    detail: TypeDiscount
    performance?: Stats
  } = {
    tabList: [
      { id: TabIdsCatalog.DETAIL, name: t('drawer.tabs.DETAIL'), state: ComponentState.ACTIVE },
      { id: TabIdsCatalog.STAT, name: t('drawer.tabs.PERFORMANCE'), state: ComponentState.DEFAULT },
    ],
    detail: {
      actions: [
        {
          id: 'duplicate',
          value: t('drawer.menu.DUPLICATE'),
        },
        { id: 'delete', value: t('drawer.menu.DELETE') },
      ],
      selected: 'color',
      color: getRandomColor(),
      type: DiscountType.PERCENTAGE,
      showSubmit: false,
      dirties: id && !isDuplicate ? ['id'] : ['color', 'type'],
      errors: {},
      validators: {
        name: nameValidator,
        rate: rateValidator,
        rateNumeric: rateNumericValidator,
        ratePercent: ratePercentValidator,
      },
    },
  }
  const [state, setState] = useState(initialState)
  const mobileView = useSelector(getDashboardView)
  const company = useSelector(selectedCompany)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({
    scope: generateDataCy({
      scope: 'catalog',
      value: 'discount',
    }),
    value: 'drawer',
  })

  const setDiscountInState = (discount: any) => {
    if (discount) {
      setState({
        ...state,
        detail: {
          ...state.detail,
          id: isDuplicate ? '' : discount.id,
          color: discount.color,
          name: discount.name,
          fullRate: discount.rate,
          rate: displayNumber(discount.rate, company.currencyDecimals),
          type: discount.type,
          validators: {
            ...state.detail.validators,
            rate:
              discount.type === DiscountType.NUMERIC
                ? state.detail.validators.rateNumeric
                : state.detail.validators.ratePercent,
          },
          dirties: isDuplicate ? createPayload(discount) : state.detail.dirties,
          showSubmit: !!isDuplicate,
        },
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    if (id && drawerIsOpen) {
      const discount = allDiscounts.find(discount => discount.id === id);
      setDiscountInState(discount)
    } else {
      setState(initialState)
    }
      }, [id, isDuplicate, drawerIsOpen])

  const updateName = (value: string) => {
    // state.detail.name = value
    // setState({ ...state, detail: { ...state.detail, name: value } })
  }

  const ComponentToDisplay = () => {
    switch (state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id) {
      case TabIdsCatalog.DETAIL:
        return <Detail discountState={state.detail} updateName={updateName} close={close} dataCy={cyContext} />
      case TabIdsCatalog.STAT:
        return (
          <Statistic
            id={state.detail.id!}
            name={state.detail.name ?? ''}
            query={getDiscountStats}
            queryName={'getDiscount'}
            type={objectType.DISCOUNT}
            close={close}
          />
        )
      default:
        return <></>
    }
  }

  const handleClickMenu = (action: string) => {
    switch (action) {
      case 'duplicate': {
        updateDrawerState(DrawerState.LOADING, dispatch)
        addHistory({
          component: MenuDiscount,
          params: { id: state.detail.id!, isDuplicate: true },
        })
        break
      }
      case 'delete': {
        callService<{ input: DeleteDiscountInput }>(
          { input: { id: state.detail.id! } },
          deleteDiscount,
          'deleteDiscount'
        )
        drawerResetHistory(dispatch)
        close()
        break
      }
      default:
        break
    }
  }

  return (
    <>
      {state.detail.id &&
        state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id !== TabIdsCatalog.STAT ? (
        <DrawerTop
          history={history}
          name={state.detail.name ?? ''}
          mobileView={mobileView === DashboardView.MOBILE}
          burgerMenu={{ actions: state.detail.actions!, onClick: handleClickMenu }}
          close={close}
          dataCy={cyContext}
        />
      ) : (
        <DrawerTop
          history={history}
          name={state.detail.id ? state.detail.name ?? '' : t('catalog.discount.form.NEW')}
          mobileView={mobileView === DashboardView.MOBILE}
          close={close}
          dataCy={cyContext}
        />
      )}
      {id && !isDuplicate && (
        <>
          <TabList
            tabList={state.tabList}
            onClick={(newTab: TypeTab) => {
              if (newTab.id !== TabIdsCatalog.DETAIL) updateDrawerState(DrawerState.LOADING, dispatch)
              setState({
                ...state,
                tabList: state.tabList.map((sTab) => ({
                  ...sTab,
                  state: newTab.id === sTab.id ? ComponentState.ACTIVE : ComponentState.DEFAULT,
                })),
              })
            }}
            size={ComponentSize.LARGE}
            dataCy={cyContext}
          />
          <Divider type={ComponentType.FILL} direction={ComponentDirection.HORIZONTAL} />
        </>
      )}
      <ComponentToDisplay />
    </>
  )
}

const deleteDiscounts = (selected: Array<{ id: string }>, close: () => void) => () => {
  Promise.all(
    selected.map(({ id }: { id: string }) =>
      callService<{ input: DeleteDiscountInput }>({ input: { id } }, deleteDiscount, 'deleteDiscount').then(() =>
        close()
      )
    )
  )
}

const Index = ({ selected = [], close }: TypeDrawer) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const mobileView = useSelector(getDashboardView)
  const drawer = useSelector(currentDrawer)
  const allDiscount = useSelector(listDiscounts);

  useEffect(() => {
    selected.length === 0
      && allDiscount.items.items.filter(d => d.selected).length === 0
      && drawer.type === DrawerType.BULK
      && !drawer.navigation.current?.params.isDuplicate
      && close()

    if (selected.length !== allDiscount.items.items.filter(d => d.selected).length) {
            selected = allDiscount.items.items.filter(c => c.selected);
    }
      }, [selected])

  const getBulkActions = () => {
    let actions: TypeQuickActions = []
    if (selected.length === 1)
      actions = [
        {
          id: 'duplicate',
          icon: Duplicate,
          title: t('catalog.discount.bulk.duplicate.TITLE'),
          text: t('catalog.discount.bulk.duplicate.SUBTITLE'),
          onClick: () => {
            drawerAddHistory(
              {
                component: MenuDiscount,
                params: {
                  id: selected[0].id!,
                  isDuplicate: true,
                },
              },
              dispatch
            )
          },
        },
      ]
    actions = [
      ...actions,
      {
        id: 'delete',
        title: t('catalog.discount.bulk.DELETE'),
        icon: DeleteIcon,
        onClick: deleteDiscounts(selected, close),
      },
    ]
    return actions
  }

  return (
    <DrawerMenu
      component={
        drawer.type === DrawerType.BULK
          ? () =>
            DrawerBulk({
              bulkTitle: t('catalog.discount.bulk.BUNDLED_SHARES'),
              title: t('catalog.discount.bulk.SELECTED', {
                number: selected.length,
                plural: selected.length > 1 ? 's' : '',
              }),
              actions: getBulkActions(),
              mobileView: mobileView === DashboardView.MOBILE,
              close,
            })
          : MenuDiscount
      }
    />
  )
}

export default Index
