import { currencyTransformer } from '../../../components/requester/query'
import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../../components/requester/types'

const productFieldsRequester: Array<TypeRequester> = [
  {
    type: TypePath.PLAIN,
    filterID: 'NAME',
    field: 'name',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'SELL_PRICE',
    field: 'sellPrice',
    transformer: currencyTransformer,
    path: [],
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'BUY_PRICE',
    field: 'buyPrice',
    transformer: currencyTransformer,
    path: [],
    filters: [
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.CURRENCY,
        comparator: TypeComparator.LT,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'REFERENCE',
    field: 'reference',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'BARCODE',
    field: 'barcode',
    path: [],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'IS_DECLINED',
    field: 'hasVariants',
    path: [],
    filters: [
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.NE,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'TAG_NAME',
    field: 'name',
    path: ['tags'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'INVENTORY_QUANTITY_1SHOP',
    field: 'quantity',
    path: ['inventoryQuantity'],
    filters: [
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.LT,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'TOTAL_INVENTORY_QUANTITY',
    field: 'totalInventoryQuantity',
    path: [],
    filters: [
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.LT,
      },
      {
        type: TypeFilter.BOOLEAN,
        comparator: TypeComparator.EXISTS,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'CATEGORY',
    section: true,
    field: '',
    path: [],
    filters: [],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'CATEGORY_NAME',
    field: 'name',
    path: ['category'],
    filters: [
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.STARTS_WITH,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.CONTAINS,
      },
      {
        type: TypeFilter.STRING,
        comparator: TypeComparator.DOES_NOT_CONTAIN,
      },
    ],
  },
  {
    type: TypePath.PLAIN,
    filterID: 'TAX',
    section: true,
    field: '',
    path: [],
    filters: [],
  },
  {
    type: TypePath.CUSTOM,
    filterID: 'RATE',
    field: 'rate',
    transformer: currencyTransformer,
    path: ['tax'],
    filters: [
      {
        type: TypeFilter.PERCENTAGE,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.PERCENTAGE,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.PERCENTAGE,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.PERCENTAGE,
        comparator: TypeComparator.LT,
      },
    ],
  },
]

export default productFieldsRequester
