const Search = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1381 14.4713H15.3481L15.0681 14.2013C16.2681 12.8013 16.8881 10.8913 16.5481 8.86133C16.0781 6.08133 13.7581 3.86133 10.9581 3.52133C6.72808 3.00133 3.16808 6.56133 3.68808 10.7913C4.02808 13.5913 6.24808 15.9113 9.02808 16.3813C11.0581 16.7213 12.9681 16.1013 14.3681 14.9013L14.6381 15.1813V15.9713L18.8881 20.2213C19.2981 20.6313 19.9681 20.6313 20.3781 20.2213C20.7881 19.8113 20.7881 19.1413 20.3781 18.7313L16.1381 14.4713ZM10.1381 14.4713C7.64808 14.4713 5.63808 12.4613 5.63808 9.97133C5.63808 7.48133 7.64808 5.47133 10.1381 5.47133C12.6281 5.47133 14.6381 7.48133 14.6381 9.97133C14.6381 12.4613 12.6281 14.4713 10.1381 14.4713Z"
      fill={color}
    />
  </svg>
)

export default Search
