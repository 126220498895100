import DisplayIcon2 from '../../newComponents/icons'

import { ArrowForward } from '../../../assets/icons'

/**
 *
 * This element is used when we create/update a inventory movement
 * It indicates the user how many quantity is in transit
 *
 * @usedIn - Inventory movement's list
 *
 * @param color -  The color of the arrow
 * @param to - The destination's quantity
 * @param from - The origin's quantity
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const TransitionCell = ({ color, to, from }: { color: string; to?: any; from?: any }): JSX.Element => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {from}
    <DisplayIcon2 Icon={ArrowForward} color={color} />
    <span style={{ color }}>{to}</span>
  </div>
)

export default TransitionCell
