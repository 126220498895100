import { useState } from 'react'

import DisplayIcon2 from '.'
import Layer from '../layer'
import { ComponentSize, ComponentState, ComponentStatus } from '../types'

const IconTouch = ({
  id,
  Icon,
  color,
  size = ComponentSize.MEDIUM,
  state,
  status,
  hoverMessage,
  onBlur,
  onClick,
  dataCy,
}: {
  id?: string
  Icon: any
  color?: string
  size?: ComponentSize
  state?: ComponentState
  status?: ComponentStatus
  hoverMessage?: string
  onBlur?: (event?: any) => void
  onClick?: () => void
  dataCy?: string
}) => {
  const [iconTouchState, setState] = useState('')

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'var(--comp-icon-touch-size-bounds)',
        width: 'var(--comp-icon-touch-size-bounds)',
      }}
      onMouseEnter={() => state !== ComponentState.DISABLED && setState(ComponentState.HOVER)}
      onMouseLeave={() => state !== ComponentState.DISABLED && setState('')}
      onClick={() => {
        if (state !== ComponentState.DISABLED) {
          onClick && onClick()
          setState(ComponentState.FOCUSED)
        }
      }}
    >
      <Layer
        style={{
          borderRadius: 'var(--comp-icon-touch-border-radius)',
          width: `var(--comp-icon-touch-size-content)`,
          height: `var(--comp-icon-touch-size-content)`,
        }}
        isDisabled={state === ComponentState.DISABLED}
        isDanger={status === ComponentStatus.DANGER}
      >
        <DisplayIcon2
          id={id}
          Icon={Icon}
          color={
            iconTouchState === ComponentState.HOVER || iconTouchState === ComponentState.FOCUSED
              ? status === ComponentStatus.DANGER
                ? 'var(--comp-icon-touch-color-foreground-danger)'
                : 'var(--comp-icon-touch-color-foreground-interactive)'
              : color
          }
          height={`var(--comp-icon-touch-size-icon-${size})`}
          width={`var(--comp-icon-touch-size-icon-${size})`}
          hoverMessage={hoverMessage}
          isDisabled={state === ComponentState.DISABLED}
          onBlur={onBlur}
          dataCy={dataCy}
        />
      </Layer>
    </div>
  )
}

export default IconTouch
