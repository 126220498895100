import { TypeCategory } from '../../../../../../types'
import { createCategoryCustomField, callCustomFieldsCreation } from '../../../../../../services/setting/customField'
import { createInput, isNestedDirty, buildTemporaryErrors } from '../../../../../../utils/dirties'
import { DataWithErrors } from '../../../../../../services/types'
import { getCompanyID } from '../../../../../../services/localStorage'
import { createCategory as create } from '../../../../../../graphql/custom/mutations'
import { CreateCategoryInput, ImageModelType, UploadImageInput } from '../../../../../../API'
import { callService } from '../../../../../../services'
import { uploadImage } from '../../../../../../graphql/mutations'
import { removeParamsFromUrl } from '../../../../../../utils/string'

const callCategoryCreation = async (payload: TypeCategory) => {
  const input: any = createInput(payload)

  console.log(input, 'INPUT')

  if (input) {
    let res: DataWithErrors = {}
    if (payload.selected === 'color') input.photo = null
    const { selected, ...newInput } = input
    res = await callService<{ input: CreateCategoryInput }>(
      { input: { ...newInput, catalogID: getCompanyID() } },
      create,
      'createCategory'
    )
    if (res.errors) return res

    const toWait: Array<Promise<any>> = []

    if (payload.selected === 'photo' && isNestedDirty(payload.dirties, 'photo')) {
      if (payload.photo.toString().startsWith('data:'))
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: res.data.id, type: ImageModelType.CATEGORY, base64Image: payload.photo } },
            uploadImage,
            'uploadImage'
          )
        )
      else {
        const newPhoto = removeParamsFromUrl(payload.photo)
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: res.data.id, type: ImageModelType.CATEGORY, urlImage: newPhoto } },
            uploadImage,
            'uploadImage'
          )
        )
      }
    }

    if (isNestedDirty(payload.dirties, 'customFields')) {
      toWait.push(
        callCustomFieldsCreation(payload.customFields, {
          id: { categoryID: res.data.id },
          create: createCategoryCustomField,
        })
      )
    }

    res.errors = await buildTemporaryErrors(toWait, res.errors)
    return res
  }
  return null
}

export default callCategoryCreation
