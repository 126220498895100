import {
  discount,
  category,
  pack,
  option,
  saleList,
  paymentList,
  cashbookList,
  customerList,
  inventoryMovementList,
  tax,
  backgroundJob,
  productLight,
} from '../custom/content'

export const productQuery = `query RequestProducts(
    $catalogID: ID!
    $limit: Int
    $nextToken: String
    $filter: RequesterProductFilterInput
    $sort: RequesterProductSortInput,
    $test: Boolean!
  ) {
    requestProducts(
      nextToken: $nextToken,
      catalogID: $catalogID,
      filter: $filter,
      limit: $limit,
      sort: $sort,
      test: $test
    ){
      items { ${productLight} }
      nextToken
    }
  }`

export const categoryQuery = `query RequestCategories(
    $catalogID: ID!
    $limit: Int
    $nextToken: String
    $filter: RequesterCategoryFilterInput
    $sort: RequesterCategorySortInput,
    $test: Boolean!
  ) {
    requestCategories(
      nextToken: $nextToken,
      catalogID: $catalogID,
      filter: $filter,
      limit: $limit,
      sort: $sort,
      test: $test
    ){
      items { ${category} }
      nextToken
    }
  }`

export const taxQuery = `query RequestTaxes(
    $catalogID: ID!
    $limit: Int
    $nextToken: String
    $filter: RequesterTaxFilterInput
    $test: Boolean!
  ) {
    requestTaxes(
      nextToken: $nextToken,
      catalogID: $catalogID,
      filter: $filter,
      limit: $limit,
      test: $test
    ){
      items { ${tax} }
      nextToken
    }
  }`

export const discountQuery = `query RequestDiscounts(
    $catalogID: ID!
    $limit: Int
    $nextToken: String
    $filter: RequesterDiscountFilterInput
    $sort: RequesterDiscountSortInput,
    $test: Boolean!
  ) {
    requestDiscounts(
      nextToken: $nextToken,
      catalogID: $catalogID,
      filter: $filter,
      limit: $limit,
      sort: $sort,
      test: $test
    ){
      items { ${discount} }
      nextToken
    }
  }`

export const packQuery = `query RequestPacks(
    $catalogID: ID!
    $filter: RequesterPackFilterInput
    $limit: Int
    $nextToken: String
    $sort: RequesterPackSortInput,
    $test: Boolean!
  ) {
    requestPacks(
      catalogID: $catalogID,
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken,
      sort: $sort,
      test: $test
    ){
      items { ${pack} }
      nextToken
    }
  }`

export const optionQuery = `query RequestOptions(
    $catalogID: ID!
    $filter: RequesterOptionFilterInput
    $limit: Int
    $nextToken: String
    $sort: RequesterOptionSortInput,
    $test: Boolean!
  ) {
    requestOptions(
      catalogID: $catalogID,
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken,
      sort: $sort,
      test: $test
    ){
      items { ${option} }
      nextToken
    }
  }`

export const inventoryMovementQuery = `query RequestInventoryMovements(
    $companyID: ID!
    $filter: RequesterInventoryMovementFilterInput
    $limit: Int
    $nextToken: String
    $sort: RequesterInventoryMovementSortInput,
    $test: Boolean!
  ) {
    requestInventoryMovements(
      companyID: $companyID,
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken,
      sort: $sort,
      test: $test
    ){
      items { ${inventoryMovementList} }
      nextToken
    }
  }`

export const saleQuery = `query RequestSales(
    $companyID: ID!
    $filter: RequesterSaleFilterInput
    $limit: Int
    $nextToken: String
    $sort: RequesterSaleSortInput,
    $test: Boolean!
  ) {
    requestSales(
      companyID: $companyID,
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken
      sort: $sort,
      test: $test
    ){
      items { ${saleList} }
      nextToken
    }
  }`

export const paymentQuery = `query RequestPayments(
    $companyID: ID!
    $filter: RequesterPaymentFilterInput
    $limit: Int
    $nextToken: String
    $sort: RequesterPaymentSortInput,
    $test: Boolean!
  ) {
    requestPayments(
      companyID: $companyID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort,
      test: $test
    ){
      items { ${paymentList} }
      nextToken
    }
  }`

export const cashbookQuery = `query RequestCashbooks(
    $companyID: ID!
    $filter: RequesterCashbookFilterInput
    $limit: Int
    $nextToken: String
    $sort: RequesterCashbookSortInput,
    $test: Boolean!
  ) {
    requestCashbooks(
      companyID: $companyID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort,
      test: $test
    ){
      items { ${cashbookList} }
      nextToken
    }
  }`

export const customerQuery = `query RequestCustomers(
    $companyID: ID!
    $filter: RequesterCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sort: RequesterCustomerSortInput,
    $test: Boolean!
  ) {
    requestCustomers(
      companyID: $companyID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort,
      test: $test
    ){
      items { ${customerList} }
      nextToken
    }
  }`

export const backgroundJobQuery = `query RequestBackgroundJobs(
    $companyID: ID!
    $filter: RequesterBackgroundJobFilterInput
    $limit: Int
    $nextToken: String
    $sort: RequesterBackgroundJobSortInput,
    $test: Boolean!
  ) {
    requestBackgroundJobs(
      companyID: $companyID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort,
      test: $test
    ){
      items { ${backgroundJob} }
      nextToken
    }
  }`
