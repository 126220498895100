import { GetSlotQueryVariables } from '../../API'
import { getSlot as getS } from '../../graphql/queries'
import { getObservable } from '../../services/graphql'
import { callService } from '../../services'
import { onCreateSlot, onUpdateSlot } from '../../graphql/subscriptions'
import { updateListElement } from '../../actions/list'
import { objectType } from '../../store/types'

const getSlot = async (id: string, dispatch: any) => {
  const slot = await callService<GetSlotQueryVariables>({ id }, getS, 'getSlot')

  if (slot.data) {
    updateListElement({ element: slot.data, dispatch, type: objectType.SLOT })
  }

  return slot
}

let onCreateSlotSubscriptions: Array<any> = []
let onUpdateSlotSubscriptions: Array<any> = []

const subscribeSlots = (shops: Array<any>, dispatch: any) => {
  shops.forEach((shop, index) => {
    onCreateSlotSubscriptions[index] = getObservable(onCreateSlot, {
      shopID: shop.id,
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateSlot) {
          updateListElement({
            dispatch,
            element: eventData.data.onCreateSlot,
            type: objectType.SLOT,
          })
        }
      },
    })
    onUpdateSlotSubscriptions[index] = getObservable(onUpdateSlot, {
      shopID: shop.id,
    }).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateSlot) {
          updateListElement({
            dispatch,
            element: eventData.data.onUpdateSlot,
            type: objectType.SLOT,
          })
        }
      },
    })
  })
}

const unSubscribeSlots = () => {
  if (onCreateSlot) {
    onCreateSlotSubscriptions.forEach((subscription) => {
      subscription.unsubscribe()
      subscription = null
    })
    onCreateSlotSubscriptions = []
  }
  if (onUpdateSlot) {
    onUpdateSlotSubscriptions.forEach((subscription) => {
      subscription.unsubscribe()
      subscription = null
    })
    onUpdateSlotSubscriptions = []
  }
}

export { getSlot, subscribeSlots, unSubscribeSlots }
