import { TypeCol, TypeColumnConfiguration, OptionAssociations } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { getElementAssociations } from '../../services'
import { TagCell } from '../../components/list/cell'

const OPTION = 'option'

export const optionCols: Array<TypeCol> = [
  {
    id: 'name',
    title: 'NAME',
    type: 'string',
    field: 'name',
    fieldType: 'string',
    active: true,
    notCustomable: true,
    sortable: true,
  },
  {
    id: 'tagValues',
    title: 'VALUE',
    type: 'element',
    active: true,
    function: ({ value, currency, decimals }) => TagCell({ pTags: value.values, isOption: true, currency, decimals }),
  },
  // {
  //   id: 'usedIn',
  //   title: 'USED_IN',
  //   type: 'element',
  //   fieldType: 'string',
  //   active: true,
  //   function: ({ value, optionsAssociations }) => {
  //     const associations = getElementAssociations<OptionAssociations>(optionsAssociations, 'optionID', value.id)
  //     return `${associations ? associations.products.length : 0} product${
  //       associations && associations.products.length > 1 ? 's' : ''
  //     }`
  //   },
  // },
  {
    id: 'createdAt',
    title: 'CREATED_AT',
    type: 'function',
    field: 'createdAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => displayDateShortMonthWithHours(date),
  },
]

export const optionsColsConf: TypeColumnConfiguration[] = [
  {
    id: 'requestOptions',
    type: OPTION,
    cols: optionCols,
    creatable: true,
    editable: false,
    exportable: false,
    clickable: true,
    shrinkrable: true,
    customable: true,
    checkboxes: true,
    searchBarPlaceholder: 'searchBar.placeholder.NAME',
    creatablePlaceholder: 'catalog.option.form.NEW',
  },
]
