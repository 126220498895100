import { drawer } from '../../actions/drawer';
import { openProductList, unSelectAllList, updateList } from '../../actions/list';
import toggleMenu from '../../actions/menu';
import { updateModal } from '../../actions/modal';
import { clearAllFilters } from '../../actions/requester';
import { InventoryMovementDestination, InventoryMovementOrigin, InventoryMovementState } from '../../API';
import {
  CurrencyEditCell,
  DisabledCell,
  NumberEditCell,
  TextEditCell,
  TransitionCell,
} from '../../components/list/cell';
import ValidationModal from '../../components/newComponents/modal/list/inventoryList';
import { getAlertIcon } from '../../components/newComponents/icon';
import { TypeFormError, ValidationFunction } from '../../format/errors/types';
import { createInventoryMove, updateInventoryMove } from '../../getters/catalog/inventory';
import { TypeQuickAction } from '../../views/catalog/product/drawer/bulk';
import { DrawerType, InventoryType, MenuState, objectType, TypeColumnConfiguration } from '../types';
import ListItemVariant from '../../components/newComponents/listItem/variant';
import { displayNumber, isNumber, toCurrencyNumber } from '../../utils/number';
import {
  buyPrice as buyPriceValidator,
  inventory as inventoryValidator,
} from '../../format/errors/filters/editableList';
import { pluralize } from '../../utils/typeToType';
import LoadMore from '../../components/newComponents/listItem/loadMore';

/**
 *
 * This method generates inventory columns configuration
 *
 * @usedIn - Editable list x Inventory movements list
 *
 * @param type - Entity's type
 * @param cancel - A CTA when we click on the "Cancel" button
 * @param submitValues - A CTA when we click on "Validate" button
 * @param shops - API needed informations such as inventory movement's origin and destination
 *
 * @returns - void
 *
 * @author - Arthur Escriou
 *
 */
const generateInventoryListConf = ({
  type,
  movement,
  t,
  shops,
  isEditable,
  movementType,
}: {
  type: objectType;
  movement: {
    id?: string;
    from?: any; // InventoryMovementOrigin | string
    to?: any; // InventoryMovementDestination | string
    state?: InventoryMovementState;
    motive?: string;
    movements?: Array<any>;
  };
  t: any;
  shops: Array<any>;
  isEditable: boolean;
  movementType?: InventoryType;
}): TypeColumnConfiguration => {
  movementType =
    movementType || (movement.from ? (movement.to ? InventoryType.TRANSFER : InventoryType.OUT) : InventoryType.IN);

  const getInventoryQuantities = (shopId: string, product: { inventoryQuantities: any[] }) => {
    if (product.inventoryQuantities) {
      const inventory = product.inventoryQuantities.find(({ shopID }: { shopID: string }) => shopId === shopID);
      if (inventory && inventory.quantity !== null) {
        return inventory.quantity;
      }
    }
    return 0;
  };

  const getTransitColFunction =
    ({ isFrom }: { isFrom: boolean }) =>
    ({ editValues, value }: { editValues: any; value: any }) => {
      if (value.subValues && value.subValues.length > 0) {
        // product line with variants
        return TextEditCell({ value: '', editable: false });
      }

      const defaultIfUndefined = (val: any, def: any) => {
        if (val === null) return 0;
        if (val === undefined) return def || 0;
        return parseInt(val, 10) || 0;
      };

      const transit = value.variantID
        ? defaultIfUndefined(editValues?.subValues[value.variantID]?.transit, value.quantity) // variant line
        : defaultIfUndefined(editValues?.transit, value.quantity); // product line

      const from = getInventoryQuantities(isFrom ? movement.from : movement.to, value);
      const sgn = isFrom ? -1 : 1;
      const to = from + transit * sgn;

      if (to.toString() === from.toString()) return TextEditCell({ value: to, editable: false });
      return TransitionCell({ color: 'var(--sys-color-content-interactive)', from, to });
    };

  const getShopName = ({ id }: { id: string }) => shops.find(shop => shop.id === id)?.name;

  /**
   *
   * This method generates "from" column
   * Depending of where the new value is (editValues, object itself..), we calculate
   * the new from value
   *
   * @returns - From column
   *
   * @author - Arthur Escriou x Jennifer Charlois
   *
   */
  const fromCol =
    movement.from && movement.state === InventoryMovementState.DRAFT
      ? {
          id: 'origin',
          title: t('list.col.QTY_ORIGIN', { name: getShopName({ id: movement.from }) }),
          type: 'element',
          active: movement.from !== InventoryMovementOrigin.SUPPLIER,
          function: getTransitColFunction({ isFrom: true }),
        }
      : undefined;

  /**
   *
   * This method generates "to" column
   * Depending of where the new value is (editValues, object itself..), we calculate
   * the new to value
   *
   * @returns - To column
   *
   * @author - Arthur Escriou x Jennifer Charlois
   *
   */
  const toCol =
    movement.to && movement.state === InventoryMovementState.DRAFT
      ? {
          id: 'destination',
          title: t('list.col.QTY_DESTINATION', { name: getShopName({ id: movement.to }) }),
          type: 'element',
          active: movement.to !== InventoryMovementDestination.TRASH,
          function: getTransitColFunction({ isFrom: false }),
        }
      : undefined;

  /**
   *
   * This method generates "quantity" column depending of where the value typed is
   * We can not edit child object
   *
   * @returns - Quantity column
   *
   * @author - Arthur Escriou x Jennifer Charlois
   *
   */
  const editCol = {
    id: 'transit',
    title: 'QTY_TRANSIT',
    type: 'element',
    active: true,
    editable: true,
    validator: inventoryValidator,
    function: ({
      value,
      onChange,
      onBlur,
      onFocusNext,
      validator,
      error,
      editValues,
    }: {
      value: any;
      onChange: (val: any) => void;
      onBlur: () => void;
      onFocusNext: () => void;
      validator: ValidationFunction;
      error: TypeFormError;
      editValues?: any;
    }) => {
      // After validation cell
      if (!isEditable) {
        if (movement.state === InventoryMovementState.CANCELLED || InventoryMovementState.CLOSED) {
          return DisabledCell({
            value: value.quantity,
          });
        }
        return NumberEditCell({
          value: value.quantity,
          editable: false,
        });
      }
      // Parent product cell
      if (value && value.variantsCount > 0)
        return DisabledCell({
          value: null,
        });

      const param = { onChange, onBlur, onFocusNext, error, icons: getAlertIcon(error), validator };

      if (editValues) {
        if (value.productID && value.variantID) {
          if (editValues.subValues && editValues.subValues[value.id] && editValues.subValues[value.id]['transit']) {
            return NumberEditCell({
              value: editValues.subValues[value.id]['transit'],
              ...param,
            });
          }
          if (editValues.subValues && editValues.subValues[value.id] && !editValues.subValues[value.id]['transit']) {
            return NumberEditCell({
              value: null,
              ...param,
            });
          }
        }
      }

      // Removing cell
      if (!value)
        return NumberEditCell({
          value: null,
          ...param,
        });

      // Default cell
      if (type === objectType.INVENTORY_MOVE_PRODUCT) {
        // closed movemebt or parent product
        if (movement.state === InventoryMovementState.CLOSED || value.hasVariants) {
          return DisabledCell({
            value: value.quantity,
          });
        }

        // for display bug when editValues during a draft

        if (editValues) {
          if (value.id) {
            if (editValues.subValues && editValues.subValues[value.id] && editValues.subValues[value.id]['transit']) {
              return NumberEditCell({
                value: editValues.subValues[value.id]['transit'],
                disabled: value.isDisabled,
                ...param,
              });
            }
            if (editValues.subValues && editValues.subValues[value.id] && !editValues.subValues[value.id]['transit']) {
              return NumberEditCell({
                value: null,
                ...param,
              });
            }
          }
        }

        return NumberEditCell({
          value: value.quantity,
          disabled: value.isDisabled,
          ...param,
        });
      }

      return NumberEditCell({
        value: null,
        ...param,
      });
    },
  };

  /**
   *
   * This method generates "price" column depending of where the value typed is
   * We can not edit child object
   * We can not edit if destination is "trash"
   *
   * @returns - Price column
   *
   * @author - Arthur Escriou x Jennifer Charlois
   *
   */
  const priceCol = {
    id: 'buyPrice',
    title: 'BUY_PRICE',
    type: 'element',
    active: !movement.from,
    editable: true,
    validator: buyPriceValidator,
    function: ({
      value,
      onChange,
      onBlur,
      onFocusNext,
      error,
      validator,
      currency,
      decimals,
      editValues,
    }: {
      value: any;
      onChange: (val: any) => void;
      onBlur: () => void;
      onFocusNext: () => void;
      error: TypeFormError;
      validator: ValidationFunction;
      currency: string;
      decimals: number;
      editValues?: any;
    }) => {
      // After validation cell
      if (!isEditable) {
        return CurrencyEditCell({ value: value.price, param: { emptyValue: '' }, currency, decimals, editable: false });
      }

      const cellValue = (() => {
        if (isNumber(value) || value == '') return value;

        const editValue = value?.variantID ? (editValues?.subValues ?? {})[value.id] : editValues;
        if (editValue !== undefined) editValue['buyPrice'];

        return toCurrencyNumber(value.buyPrice ?? value.price, decimals);
      })();

      const cellDisabled = value?.hasVariants || value?.variantsCount > 0;

      return CurrencyEditCell({
        value: cellValue,
        disabled: cellDisabled,
        onChange,
        onBlur,
        onFocusNext,
        error,
        icons: getAlertIcon(error),
        validator,
        currency,
        decimals,
      });
    },
  };

  return {
    id: 'inventoryMovementProduct',
    type: 'product',
    // @ts-ignore
    cols: [
      {
        id: 'name',
        title: 'NAME',
        type: 'element',
        active: true,
        function: ({ value, onClick, onIconClick }: any) => {
          const name = value.name;
          if (type === objectType.PRODUCT) {
            return value.variantsCount > 0
              ? ListItemVariant({
                  name: value.name,
                  length: value.allSelected
                    ? value.variantsCount
                    : value.subValues.filter((subValue: { selected: boolean }) => subValue.selected).length,
                  onClick,
                  onIconClick,
                })
              : name;
          } else {
            return value.hasVariants && (value.variantsCount > 0 || value.variantsCountAll)
              ? ListItemVariant({
                  name: value.name,
                  length: value.variantsSelectedAll
                    ? value.variantsCountAll
                    : value.variantsCount === 0
                    ? value.variantsCountAll
                    : value.variantsCount,
                  onClick,
                  onIconClick,
                })
              : name;
          }
        },
      },
      {
        id: 'icon',
        title: '',
        type: 'element',
        fieldType: 'string',
        active: true,
        sortable: false,
        notCustomable: true,
        function: ({ value, onClick }: any) =>
          (type === objectType.PRODUCT && value.variantsCount > 0) ||
          (value.hasVariants && (value.variantsCount > 0 || value.variantsCountAll))
            ? LoadMore({ onClick })
            : undefined,
      },
      fromCol,
      editCol,
      toCol,
      priceCol,
      {
        id: 'category',
        title: 'CATEGORY',
        type: 'element',
        active: true,
        function: ({ value }: any) =>
          type === objectType.INVENTORY_MOVE_PRODUCT ? value.category : value.category ? value.category.name : null,
      },
      {
        id: 'reference',
        title: 'REFERENCE',
        type: 'element',
        active: true,
        function: ({ value }: any) => value.reference,
      },
      {
        id: 'barcode',
        title: 'BARCODE',
        type: 'element',
        active: true,
        function: ({ value }: any) => value.barcode,
      },
    ].filter(a => a),
    editable: true,
    creatable: false,
    clickable: false,
    checkboxes: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    hideFilters: true,
    displaySelectedOnly: true,
    status: movement.state,
    editActions: {
      cancel: ({ dispatch, navigate }: { dispatch: any; navigate: any }) => {
        // Cancel new inventory movement
        if (type === objectType.PRODUCT) {
          clearAllFilters(dispatch);
          openProductList(dispatch);
          drawer(
            {
              type: DrawerType.BULK,
              open: true,
              param: {
                view: TypeQuickAction.INVENTORY_MOVE,
                ...movement,
              },
            },
            dispatch,
          );
        }
        // Or cancel existing one
        else if (type === objectType.INVENTORY_MOVE_PRODUCT) {
          clearAllFilters(dispatch);
          unSelectAllList(dispatch);
          navigate('/catalog/movements');
        }
        toggleMenu(dispatch, { state: MenuState.EXPAND });
        updateList({ type: pluralize(type), editValues: {}, errors: [] }, dispatch);
      },
      // @ts-ignore
      save: ({
        editValues,
        items,
        navigate,
        dispatch,
        decimals,
      }: {
        editValues: any;
        items?: Array<any>;
        navigate: any;
        dispatch: any;
        decimals: number;
      }) => {
        // Create new inventory movement
        if (type === objectType.PRODUCT) {
          updateModal(
            dispatch,
            true,
            () => (
              //@ts-ignore
              <ValidationModal
                inventoryMoveRequest={createInventoryMove({
                  items,
                  state: {
                    from: movement.from,
                    to: movement.to,
                    motive: movement.motive,
                  },
                  dispatch,
                  decimals,
                  //@ts-ignore
                  movementType,
                })}
                editValues={editValues}
                dispatch={dispatch}
                items={items}
                validateDraft={false}
                //@ts-ignore
                movementType={movementType}
              />
            ),
            () => {},
          );
        }
        // Or update existing one
        else if (type === objectType.INVENTORY_MOVE_PRODUCT) {
          updateModal(
            dispatch,
            true,
            () => (
              //@ts-ignore
              <ValidationModal
                inventoryMoveRequest={updateInventoryMove({
                  items,
                  state: {
                    id: movement.id,
                    movements: movement.movements,
                  },
                  dispatch,
                  navigate,
                  decimals,
                  //@ts-ignore
                  movementType,
                })}
                editValues={editValues}
                dispatch={dispatch}
                items={items}
                validateDraft={false}
                //@ts-ignore
                movementType={movementType}
              />
            ),
            () => {},
          );
        }
      },
    },
  };
};

export default generateInventoryListConf;
