import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { DataWithErrors } from '../../../../services/types'
import getExtraInfos from '../../../../services/activity/sale'
import Form from './form'
import { DashboardView, DrawerState } from '../../../../store/types'
import { Route } from '../../../../utils/navigation'
import {
  getDrawerState,
  getDashboardView,
  selectedCompany,
  listPaymentMethodsItemsNextToken,
  isDrawerOpen,
  listProducts,
  listPacks,
} from '../../../../store/selector'
import { updateDrawerState } from '../../../../actions/drawer'
import Loader from '../../../../components/newComponents/loader'
import { stateCashbookEnumToStr, stateSaleToStr } from '../../../../utils/typeToType'
import { TypeDrawer } from '../../../../types/drawer'
import DrawerMenu from '../../../../components/oldComponents/drawer/menu'
import { TypeSale } from '../../../../types'
import DrawerTop from '../../../../components/oldComponents/drawer/top'
import generateDataCy from '../../../../utils/cypress'
import { callService } from '../../../../services'
import { GetSaleQueryVariables } from '../../../../API'
import { getSaleStatsLight as getTSLight } from '../../../../graphql/custom/queries'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'

type Props = {
  id: string
  history: Array<Route>
  addHistory: (route: Route) => void
  close: () => void
}

export const MenuSale = ({ id, history, addHistory, close }: Props) => {
  const initialState: TypeSale = {
    isLoaded: false,
  }
  const company = useSelector(selectedCompany)
  const [state, setState] = useState(initialState)
  const mobileView = useSelector(getDashboardView)
  const drawerState = useSelector(getDrawerState)
  const drawerIsOpen = useSelector(isDrawerOpen);
  const allProducts = useSelector(listProducts);
  const allPacks = useSelector(listPacks);
  const paymentMethods = useSelector(listPaymentMethodsItemsNextToken)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'activity', value: generateDataCy({ scope: 'sale', value: 'drawer' }) })

  const setSaleInState = async (data: DataWithErrors) => {
    if (data.data) {
      const sale = data.data
      const { shopName, target, lines } = await getExtraInfos(allProducts, allPacks, sale, dispatch)
      let newCashbook
      let newCustomer
      let newSeller
      if (sale.cashbook) {
        newCashbook = {
          id: sale.cashbook.id,
          number: sale.cashbook.number,
          status: stateCashbookEnumToStr(sale.cashbook.state, t),
        }
      }
      if (sale.customer) {
        newCustomer = {
          id: sale.customer.id,
          firstName: sale.customer.firstName,
          lastName: sale.customer.lastName,
          color: sale.customer.color,
          iconText: sale.customer.iconText,
        }
      }
      if (sale.seller) {
        newSeller = {
          id: sale.seller.id,
          firstName: sale.seller.firstName,
          lastName: sale.seller.lastName,
          color: sale.seller.color,
          iconText: sale.seller.iconText,
        }
      }
      const stats = [
        {
          title: t('activity.sale.stats.TOTAL_TAX_FREE'),
          value: sale.totalTaxFree ?? 0,
        },
        sale.stats
          ? sale.stats.taxes.map((tax: { id: string; totals: { totalTax: number } }) => ({
            title: t('activity.cashbook.stats.TVA_X', {
              rate: parseInt(tax.id, 10) / 10 ** company.currencyDecimals,
            }),
            value: tax.totals ? tax.totals.totalTax : 0,
          }))
          : undefined,
        {
          title: t('activity.sale.stats.TVA'),
          value: sale.totalTax ?? 0,
        },
        {
          title: t('activity.sale.stats.TOTAL'),
          value: sale.total ?? 0,
        },
      ]
        .flat()
        .filter((_) => _)
      const newPayments = sale.payments.map((payment: any) => {
        const paymentMethod = paymentMethods.items.find((item: any) => item.id === payment.paymentMethodID)
        return {
          id: payment.paymentID,
          amount: payment.amount,
          name: payment.paymentMethodName,
          type: payment.paymentMethodType,
          color: paymentMethod ? paymentMethod.color : '',
        }
      })

      setState({
        ...state,
        id: sale.id,
        number: sale.number,
        status: stateSaleToStr(sale.state, sale.refundedStatus, t),
        closedAt: sale.closedAt,
        shop: {
          id: sale.shopID,
          name: shopName,
        },
        sourceName: sale.sourceName,
        lines,
        refundSales: sale.refundSales,
        target,
        discounts: sale.discounts,
        payments: newPayments,
        total: sale.total,
        margin: sale.stats.margin,
        totalRest: sale.totalRest,
        note: sale.note ?? undefined,
        isLoaded: true,
        cashbook: newCashbook,
        customer: newCustomer,
        seller: newSeller,
        stats,
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    if (id && drawerIsOpen) callService<GetSaleQueryVariables>({ id }, getTSLight, 'getSale').then(setSaleInState)
      }, [id, drawerIsOpen])

  return (
    <>
      <DrawerTop
        history={history}
        name={`${t('activity.sale.SALE')} ${state.number ? `#${state.number}` : ''}`}
        mobileView={mobileView === DashboardView.MOBILE}
        close={close}
        dataCy={cyContext}
      />
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : drawerState === DrawerState.LOADED ? (
        <div
          className={styles.containerForm}
          style={{
            height: `calc(100vh - 61px)`,
          }}
          data-cy={cyContext}
        >
          <Form saleState={state} addHistory={addHistory} dataCy={cyContext} />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const Drawer = ({ selected = [], close }: TypeDrawer) => <DrawerMenu component={MenuSale} />

export default Drawer
