// Format received : "YYYY-MM-DD"
const displayDate = (value: string) => `${value.split('-')[2]}/${value.split('-')[1]}/${value.split('-')[0]}`

// Format date for AWSDateTime
const formatDate = (date: { year: number; month: number; day: number }) =>
  `${date.year}-${('0' + date.month).slice(-2)}-${('0' + date.day).slice(-2)}`

const formatStartDay = (today: Date) =>
  formatDate({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  }) + 'T00:00:00.000Z'

const formatEndDay = (today: Date) =>
  formatDate({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  }) + 'T23:59:59.000Z'

const endDay = (today: Date) => ({
  year: today.getFullYear(),
  month: today.getMonth() + 1,
  day: today.getDate(),
})

const formatStartWeek = (today: Date) => {
  const firstWeekDay = today.setDate(today.getDate() + 1 - today.getDay())
  return formatStartDay(new Date(firstWeekDay))
}

const startWeek = (today: Date) => {
  const firstWeekDay = today.setDate(today.getDate() + 1 - today.getDay())
  return {
    year: new Date(firstWeekDay).getFullYear(),
    month: new Date(firstWeekDay).getMonth() + 1,
    day: new Date(firstWeekDay).getDate(),
  }
}

const endWeek = (today: Date) => {
  const lastWeekDay = today.setDate(today.getDate() - today.getDay() + 7)
  return {
    year: new Date(lastWeekDay).getFullYear(),
    month: new Date(lastWeekDay).getMonth() + 1,
    day: new Date(lastWeekDay).getDate(),
  }
}

const formatEndWeek = (today: Date) => {
  const lastWeekDay = today.setDate(today.getDate() - today.getDay() + 7)
  return formatEndDay(new Date(lastWeekDay))
}

const formatStartMonth = (today: Date) =>
  formatDate({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: 1,
  }) + 'T00:00:00.000Z'

const formatEndMonth = (today: Date) =>
  formatDate({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate(),
  }) + 'T23:59:59.000Z'

const formatStartYear = (today: Date) => formatDate({ year: today.getFullYear(), month: 1, day: 1 }) + 'T00:00:00.000Z'

const formatEndYear = (today: Date) => formatDate({ year: today.getFullYear(), month: 12, day: 31 }) + 'T23:59:59.000Z'

const displayHourDate = (date: string) =>
  new Date(date).toLocaleString('fr-FR', {
    hour: 'numeric',
  })

const displayDayDate = (date: string) =>
  new Date(date).toLocaleString('fr-FR', {
    month: 'short',
    day: 'numeric',
  })

const displayMonthDate = (date: string) =>
  new Date(date).toLocaleString('fr-FR', {
    month: 'short',
  })

const displayDateWithHours = (date: string) =>
  new Date(date).toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

const displayDateLongMonth = (date: string) =>
  new Date(date).toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

const displayDateShortMonthWithHours = (date: string) =>
  new Date(date).toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

const displayDateShortMonth = (date: string) =>
  new Date(date).toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

const displayDateNumeric = () =>
  new Date().toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })

const isValidDate = (dateString: string) => {
  return !isNaN(toDate(dateString).getTime())
}

const toDate = (dateString: string) => {
  const normalizedDateString = replaceAccentedChars(translateMonth(dateString))
  const date = new Date(normalizedDateString)
  return date
}

const toDateISOString = (dateString: string) => {
  return toDate(dateString).toISOString()
}

const translateMonth = (dateString: string) => {
  const months = [
    ['janvier', 'January'],
    ['février', 'February'],
    ['mars', 'March'],
    ['avril', 'April'],
    ['mai', 'May'],
    ['juin', 'June'],
    ['juillet', 'July'],
    ['août', 'August'],
    ['septembre', 'September'],
    ['octobre', 'October'],
    ['novembre', 'November'],
    ['décembre', 'December'],
  ]

  return months.reduce((res, month) => res.replace(month[0], month[1]), dateString)
}

const replaceAccentedChars = (str: string) => {
  const ACCENTED_MAP = {
    é: 'e',
    û: 'u',
  }

  //@ts-ignore
  return str.replace(/./g, (char: string) => ACCENTED_MAP[char] || char)
}

export {
  displayDate,
  formatDate,
  formatStartDay,
  formatEndDay,
  endDay,
  formatStartMonth,
  formatEndMonth,
  formatStartWeek,
  startWeek,
  formatEndWeek,
  endWeek,
  formatStartYear,
  formatEndYear,
  displayDateWithHours,
  displayHourDate,
  displayDayDate,
  displayMonthDate,
  displayDateShortMonth,
  displayDateShortMonthWithHours,
  displayDateLongMonth,
  displayDateNumeric,
  isValidDate,
  toDate,
  toDateISOString,
  replaceAccentedChars,
}
