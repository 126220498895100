import { StateStore, objectType, TypeCol, TypeColumnConfiguration, entities } from './types'

import { pluralize } from '../utils/typeToType'
import { productsColsConf } from './catalogCols/productCols'

/**
 *
 * This method adds new cols inside existing column's configuration
 * We first need to get the configuration where to add new cols
 * We add it to finally delete duplicate cols with a filter method
 *
 * @usedIn - Custom field cols
 *
 * @param confId - Id of column's configuration
 * @param cols - Actual cols to add
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Arthur Escriou
 *
 */
const addCols = ({ confId, cols }: { confId: string; cols: Array<TypeCol> }, state: StateStore): StateStore => {
  const conf = Object.values(state.cols)
    .reduce((acc: Array<any>, val: Array<any>) => acc.concat(val), [])
    .find(({ id }: TypeColumnConfiguration) => id === confId)

  if (conf)
    conf.cols = conf.cols
      .concat(cols)
      .filter(
        (col: { id: string }, index: number, cols: Array<{ id: string }>) =>
          cols.findIndex((ccol) => ccol.id === col.id) === index
      )

  return { ...state }
}

/**
 *
 * This method updates column's cols
 *
 * @usedIn - Change order/appearance of columns
 *
 * @param cols - Updated cols
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Arthur Escriou
 *
 */
const updateCols = ({ cols }: { cols: TypeColumnConfiguration }, state: StateStore): StateStore => {
  if (cols && cols.type) {
    // @ts-ignore
    state[pluralize(cols.type)].cols.cols = [...cols.cols]
    if (cols.type === objectType.PRODUCT) state = updateColsEditableProductList(state, cols)
  }
  return { ...state }
}

const deleteCol = ({ id, type }: { id: string; type: objectType }, state: StateStore): StateStore => {
  if (id && type) {
    // @ts-ignore
    state[pluralize(type)].cols.cols = state[pluralize(type)].cols.cols.filter((col: { id: string }) => col.id !== id)
    if (type === objectType.PRODUCT) {
      const index = state.cols.products.findIndex((col) => col.id === 'editProducts')

      state.cols.products[index] = {
        ...state.cols.products[index],
        cols: state.cols.products[index].cols.filter((col: { id: string }) => {
          const parsedID = col.id.startsWith('cf')
            ? col.id.split('cf')[1]
            : col.id.startsWith('inventory')
            ? col.id.split('inventory')[1]
            : col.id
          return parsedID !== id
        }),
      }
    }
  }

  return { ...state }
}

/**
 *
 * This method changes a column's configuration by a given column's configuration id
 *
 * @usedIn - List / Editable list
 *
 * @param type - Entity's type
 * @param confId - Id of column's configuration
 * @param newConfId - New id of column's configuration
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Arthur Escriou
 *
 */
const changeColsConf = (
  { type, confId: newConfId }: { type: objectType; confId: string },
  state: StateStore
): StateStore => {
  // @ts-ignore
  const newConf = state.cols[pluralize(type)].find(({ id }: { id: string }) => id === newConfId)

  if (newConf) {
    // @ts-ignore
    state[pluralize(type)] = {
      // @ts-ignore
      ...state[pluralize(type)],
      cols: { ...newConf, cols: [...newConf.cols] },
    }
  }
  return { ...state }
}

/**
 *
 * This method updates column's configuration
 *
 * @usedIn - Change order/appearance of columns when reloading view
 *
 * @param type - Entity's type
 * @param conf - New configuration
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Arthur Escriou
 *
 */
const updateColsConf = (
  { type, conf }: { type: objectType; conf: Array<TypeColumnConfiguration> },
  state: StateStore
): StateStore => {
  if (conf) {
    // @ts-ignore
    state[pluralize(type)].cols = conf
  }

  return {
    ...state,
    // @ts-ignore
    [pluralize(type)]: { ...state[pluralize(type)], cols: conf },
  }
}

/**
 *
 * This method updates column's configuration on editProducts
 *
 * @param newCols - Updated cols
 * @param state - The store
 *
 * @returns - A new store
 *
 * @author - Arthur Escriou
 *
 */
const updateColsEditableProductList = (state: StateStore, newCols: TypeColumnConfiguration): StateStore => {
  const index = productsColsConf.findIndex((cols) => cols.id === 'editProducts')
  const editCols = state.cols.products[index]
  const newEditCols = new Array(newCols.cols.length)

  editCols.cols.forEach((eCol) => {
    const index = newCols.cols.findIndex((nCol) => {
      if (eCol.id.startsWith('inventory')) {
        return nCol.id === eCol.id.split('inventory')[1]
      }
      if (eCol.id.startsWith('cf')) {
        return nCol.id === eCol.id.split('cf')[1]
      }
      return nCol.id === eCol.id
    })
    if (index > -1) {
      newEditCols.fill(
        {
          ...eCol,
          active: newCols.cols[index].id === 'name' ? true : newCols.cols[index].active,
        },
        index,
        index + 1
      )
    }
  })

  state.cols.products[index] = {
    ...editCols,
    cols: newEditCols.filter((_) => _),
  }

  return state
}

const resetCustomCols = (state: StateStore): StateStore => {
  entities.forEach((entity) => {
    // @ts-ignore
    state[pluralize(entity)].cols.cols = state[pluralize(entity)].cols?.cols.filter(
      (col: TypeCol) => !col.fieldType || (col.fieldType !== 'customField' && col.fieldType !== 'shop')
    )
    state.cols.products[1].cols = state.cols.products[1].cols.filter(
      (col) => !col.fieldType || (col.fieldType !== 'customField' && col.fieldType !== 'shop')
    )
  })
  return state
}

export { addCols, changeColsConf, updateCols, deleteCol, updateColsConf, resetCustomCols }
