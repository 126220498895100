import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import Template from '../../account/template'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import { getCompanyID } from '../../../services/localStorage'
import Chip from '../../../components/newComponents/chip'
import Button from '../../../components/newComponents/button'
import { callService } from '../../../services'
import {
  ConnectStripeQueryVariables,
  DisconnectStripeQueryVariables,
  GetStripeConnectionStatusQueryVariables,
  GetStripeConnectLinkQueryVariables,
} from '../../../API'
import {
  connectStripe,
  disconnectStripe,
  getStripeConnectionStatus,
  getStripeConnectLink,
} from '../../../graphql/queries'
import { DrawerState } from '../../../store/types'
import Loader, { displayLoader } from '../../../components/newComponents/loader'
import { getDrawerState } from '../../../store/selector'
import { updateDrawerState } from '../../../actions/drawer'
import AttentionBox from '../../../components/newComponents/attentionBox'
import { APIErrorMessageToHumanMessage } from '../../../assets/errorCodes/helper'

import { Warning } from '../../../assets/icons'
import styles from '../../account/Template.module.css'

const Stripe = () => {
  const [state, setState] = useState({
    email: '',
    success: false,
    showSubmit: true,
    error: { value: false, message: '', link: '' },
  })
  const drawerState = useSelector(getDrawerState)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const getStripeConnection = async () => {
    const res = await callService<GetStripeConnectionStatusQueryVariables>(
      {
        companyID: getCompanyID(),
      },
      getStripeConnectionStatus,
      'getStripeConnectionStatus'
    )

    if (!res.errors)
      setState({
        ...state,
        success: !!res.data,
        email: res.data ?? '',
        error: { value: false, message: '', link: '' },
        showSubmit: true,
      })
    else
      setState({
        ...state,
        success: res.errors.errorType === 'g-403',
        email: '',
        error: {
          value: res.errors.errorType === 'g-403',
          message: APIErrorMessageToHumanMessage(res.errors.message, res.errors.errorType),
          link: res.errors.errorInfo ?? '',
        },
        showSubmit: true,
      })
    updateDrawerState(DrawerState.LOADED, dispatch)
  }

  useEffect(() => {
    updateDrawerState(DrawerState.LOADING, dispatch)
    if (window.location.search) {
      if (window.location.search.indexOf('code=') !== -1) {
        const code = window.location.search.substring(window.location.search.indexOf('code=') + 5)
        const parsedCode = code.substring(0, code.indexOf('&') !== -1 ? code.indexOf('&') : code.length)
        callService<ConnectStripeQueryVariables>(
          {
            companyID: getCompanyID(),
            code: parsedCode,
          },
          connectStripe,
          'connectStripe'
        ).then((data) => {
          if (!data.errors && data.data) getStripeConnection()
        })
      } else updateDrawerState(DrawerState.LOADED, dispatch)
    } else getStripeConnection()

      }, [])

  const handleSubmit = async () => {
    setState({ ...state, showSubmit: false })
    if (!state.success) {
      const res = await callService<GetStripeConnectLinkQueryVariables>(
        {
          companyID: getCompanyID(),
          redirectPath: '/settings/stripe',
        },
        getStripeConnectLink,
        'getStripeConnectLink'
      )
      if (!res.errors && res.data) window.location.replace(res.data)
    } else {
      await callService<DisconnectStripeQueryVariables>(
        {
          companyID: getCompanyID(),
        },
        disconnectStripe,
        'disconnectStripe'
      )
      getStripeConnection()
    }
  }

  return (
    <Template>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div className={styles.form}>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <div className={styles.title}>{t('settings.stripe.TITLE')}</div>
            {state.success && (
              <Chip
                status={ComponentStatus.SUCCESS}
                size={ComponentSize.MEDIUM}
                text={t('settings.stripe.CONNECTED')}
              />
            )}
          </div>
          <div className={styles.subTitle}>
            <Trans
              defaults={
                state.email
                  ? 'Stripe est une plateforme de paiements électroniques simple, fiable et polyvalente. Acceptez les cartes bancaires en ligne et en boutique grâce à Stripe Terminal.\nTactill est compatible avec le <bold>Stripe Terminal BBPOS WisePad 3</bold>.\n\nConnecté au compte : {{email}}'
                  : state.success
                  ? 'Stripe est une plateforme de paiements électroniques simple, fiable et polyvalente. Acceptez les cartes bancaires en ligne et en boutique grâce à Stripe Terminal.\nTactill est compatible avec le <bold>Stripe Terminal BBPOS WisePad 3</bold>.'
                  : 'Stripe est une plateforme de paiements électroniques simple, fiable et polyvalente. Acceptez les cartes bancaires en ligne et en boutique grâce à Stripe Terminal.\nTactill est compatible avec le <bold>Stripe Terminal BBPOS WisePad 3</bold>.\n\nConnectez votre compte Stripe à Tactill. Vous pourrez créer un compte si vous n’en avez pas déjà un.'
              }
              values={{ email: state.email }}
              components={{ bold: <strong /> }}
            />
          </div>
          {state.error.value && (
            <AttentionBox
              LeadIcon={Warning}
              status={ComponentStatus.WARNING}
              title={
                <div>
                  {state.error.message}
                  <a className={styles.link} target="_blank" rel="noreferrer" href={state.error.link}>
                    {t('settings.stripe.FILL')}
                  </a>
                </div>
              }
            />
          )}
          <Button
            title={state.success ? t('button.DISCONNECT_STRIPE') : t('button.CONNECT_STRIPE')}
            size={ComponentSize.MEDIUM}
            type={state.success ? ComponentType.SECONDARY : ComponentType.PRIMARY}
            status={state.success ? ComponentStatus.DANGER : ComponentStatus.DEFAULT}
            state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
            onClick={handleSubmit}
          />
          {state.success && <div className={styles.info}>{t('settings.stripe.INFO')}</div>}
        </div>
      ) : (
        <></>
      )}
    </Template>
  )
}

export default Stripe
