import React from 'react'

const Stat = () => (
  <svg width="166" height="78" viewBox="0 0 166 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
        opacity="0.15"
        d="M148.952 2C148.952 0.895433 149.848 0 150.952 0H167.397C168.501 0 169.397 0.895431 169.397 2V80.3175H148.952V2Z"
        fill="#ACACAC"
      />
      <g opacity="0.15">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 14.3174C74 13.2128 74.8954 12.3174 76 12.3174H93C94.1046 12.3174 95 13.2128 95 14.3174V80.3174H74V14.3174ZM101.302 23.365C100.197 23.365 99.3016 24.2604 99.3016 25.365V80.3174H119.746V25.365C119.746 24.2604 118.851 23.365 117.746 23.365H101.302ZM126.127 29.2059C125.022 29.2059 124.127 30.1013 124.127 31.2059V80.317H144.571V31.2059C144.571 30.1013 143.676 29.2059 142.571 29.2059H126.127ZM51.6508 36.5079C50.5462 36.5079 49.6508 37.4033 49.6508 38.5079V80.3174H70.0952V38.5079C70.0952 37.4033 69.1998 36.5079 68.0952 36.5079H51.6508ZM25 55.0005C25 53.8959 25.8954 53.0005 27 53.0005H43C44.1046 53.0005 45 53.8959 45 55.0005V80.0005H25V55.0005ZM2 70.0955C0.895431 70.0955 0 70.9909 0 72.0955V80.3177H20.4444V72.0955C20.4444 70.991 19.549 70.0955 18.4444 70.0955H2Z"
          fill="#ACACAC"
        />
      </g>
    </g>
  </svg>
)

export default Stat
