import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { enhancer } from 'addon-redux';

import {
  Action,
  AppState,
  DrawerType,
  DrawerState,
  ListState,
  StateStore,
  DashboardView,
  MenuState,
  IntervaleType,
} from './types';
import { changeColsConf, addCols, updateColsConf, updateCols, resetCustomCols, deleteCol } from './cols';
import { productsColsConf } from './catalogCols/productCols';
import { categoriesColsConf } from './catalogCols/categoryCols';
import { discountsColsConf } from './catalogCols/discountCols';
import taxesCols from './settingCols/taxCols';
import { packsColsConf } from './catalogCols/packCols';
import { optionsColsConf } from './catalogCols/optionCols';
import { inventoriesColsConf } from './catalogCols/inventoryCols';
import { paymentsColsConf } from './activityCols/paymentCols';
import { cashbooksColsConf } from './activityCols/cashbookCols';
import { customersColsConf } from './customerCols/customerCols';
import { salesColsConf } from './activityCols/saleCols';
import customFieldsCols from './settingCols/customFieldCols';
import tagsCols from './settingCols/tagCols';
import statPaymentsCols from './statCols/paymentCols';
import statTaxesCols from './statCols/taxCols';
import {
  updateListElement,
  deleteListElement,
  updateVariantElementSubscription,
  addVariantInventoryMovement,
  addInventoryMovement,
  addLinesInventoryMovement,
} from './subscriptions';
import {
  sortListElement,
  unSelectAllList,
  unSelectProductsById,
  unSelectVariantById,
  updateElementItems,
  updateAllSelectedItemsAndVariant,
  updateSelectedItemAndVariant,
  updateSelectedItemVariant,
  updateOnlyParentItem,
  updateItemWithoutSubValue,
  updateVariantAfterSubscription,
  updateParentProduct,
  updateInventoryQuantities,
  updateCustomFieldsValue,
  createCustomFieldValue,
  deleteCustomFieldValue,
  updateBuyPrice,
} from './list';
import {
  switchFilteredItemsToItems,
  requestItems,
  requestFilteredItems,
  clearAllFilters,
  toggleFilters,
} from './requester';
import { resetHistory, back, addHistory } from '../utils/navigation';
import { filterCustomFields, updateCustomFieldsAssociations } from '../services/setting/customField';
import {
  filterTags,
  updateTagsAssociations,
  updateTagsData,
  deleteTagsData,
  removeTagFromProduct,
} from '../services/setting/tag';
import { updateCategoriesAssociations } from '../services/catalog/category';
import { removeOptionFromProduct, updateOptionsAssociations } from '../services/catalog/option';
import { updatePacksAssociations } from '../services/catalog/pack';
import statProductsCols from './statCols/productCols';
import statCategoriesCols from './statCols/categoryCols';
import statValuesCols from './statCols/valueCols';
import { companiesColsConf } from './accountCols/companyCols';
import shopsColsConf from './settingCols/shopCols';
import slotsColsConf from './settingCols/slotCols';
import usersColsConf from './settingCols/userCols';
import paymentMethodsColsConf from './settingCols/paymentMethodCols';
import { addElementCompanySettings, deleteElementCompanySettings } from './companySettings';
import importsCols from './settingCols/importCols';
import { cypressInit } from '../services/graphql';
/**
 *
 * This object initialises the store
 *
 * @returns - The actual store
 *
 * @author - Arthur Escriou
 *
 */
const initialState: StateStore = {
  isIOS: !!(
    (/iPhone/i.test(navigator.userAgent) ||
      /iPad/i.test(navigator.userAgent) ||
      /Macintosh/i.test(navigator.userAgent)) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1
  ),
  app: {
    state: AppState.LOADING,
  },
  modal: {
    show: false,
    type: '',
    Element: () => null,
  },
  flatfile: {
    show: false,
    flatfileData: {},
  },
  toaster: {
    show: false,
    param: {},
  },
  cols: {
    products: productsColsConf,
    categories: categoriesColsConf,
    discounts: discountsColsConf,
    packs: packsColsConf,
    options: optionsColsConf,
    inventories: inventoriesColsConf,
    inventoryMoveProducts: [],
    sales: salesColsConf,
    payments: paymentsColsConf,
    cashbooks: cashbooksColsConf,
    customers: customersColsConf,
    companies: companiesColsConf,
    shops: shopsColsConf,
    slots: slotsColsConf,
    users: usersColsConf,
    taxes: taxesCols,
    paymentMethods: paymentMethodsColsConf,
    customFields: customFieldsCols,
    tags: tagsCols,
    imports: importsCols,
    statPayments: statPaymentsCols,
    statTaxes: statTaxesCols,
    statProducts: statProductsCols,
    statCategories: statCategoriesCols,
    statValues: statValuesCols,
  },
  variants: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.EMPTY,
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
    filteredItems: {
      items: [],
    },
  },
  menu: { pathWidth: 0, state: MenuState.EXPAND },
  drawerState: DrawerState.LOADING,
  drawer: {
    type: DrawerType.BULK,
    id: undefined,
    subId: undefined,
    action: undefined,
    navigation: resetHistory(),
    open: false,
  },
  products: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: productsColsConf[0],
    editValues: {},
    errors: [],
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  categories: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: categoriesColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  categoriesAssociations: [],
  discounts: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: discountsColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  inventories: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: inventoriesColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'createdAt',
        direction: 'desc',
      },
    },
  },
  inventoryMoveProducts: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    // @ts-ignore
    cols: [],
    editValues: {},
    errors: [],
    items: { items: [] },
  },
  packs: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: packsColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  packsAssociations: [],
  options: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: optionsColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  optionsAssociations: [],
  sales: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: salesColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'openedAt',
        direction: 'desc',
      },
    },
  },
  payments: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: paymentsColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'date',
        direction: 'desc',
      },
    },
  },
  cashbooks: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: cashbooksColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'openedAt',
        direction: 'desc',
      },
    },
  },
  customers: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: customersColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'firstName',
        direction: 'asc',
      },
    },
  },
  companies: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: companiesColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  shops: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: shopsColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  slots: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: slotsColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  users: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: usersColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'lastName',
        direction: 'asc',
      },
    },
  },
  invitations: [],
  taxes: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: taxesCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'rate',
        direction: 'asc',
      },
    },
  },
  taxesAssociations: [],
  customFields: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: customFieldsCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  customFieldsAssociations: [],
  tags: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: tagsCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  tagsAssociations: [],
  imports: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: importsCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'createdAt',
        direction: 'desc',
      },
    },
  },
  paymentMethods: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: paymentMethodsColsConf[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  statPayments: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: statPaymentsCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'paymentMethod',
        direction: 'asc',
      },
    },
  },
  statTaxes: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: statTaxesCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'rate',
        direction: 'asc',
      },
    },
  },
  statProducts: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: statProductsCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  statCategories: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: statCategoriesCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  statValues: {
    filters: { requesterOpen: false, searchbarOpen: false },
    state: ListState.LOADING,
    cols: statValuesCols[0],
    editValues: {},
    items: {
      items: [],
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  },
  account: {
    companies: {
      items: [],
    },
  },
  companySettings: [],
  openMenu: true,
  dashboardView: DashboardView.DESKTOP,
  interval: null,
  stats: {
    shopIDs: undefined,
    dashboard: { startDateAPI: undefined, endDateAPI: undefined },
    stockValue: { date: undefined },
  },
};

/**
 *
 * This method is the reducer. It initialises every function to be called
 * when we are going to interract with the store
 *
 * @param state - Actual store
 * @param action - A payload with needed values
 *
 * @returns - void
 *
 * @author - Jennifer Charlois
 *
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: any) => {
  if (action)
    switch (action.type) {
      case Action.UPDATE_APP_STATE:
        return { ...state, app: { state: action.payload } };
      case Action.DISCONNECT:
        return { ...initialState };
      case Action.UPDATE_LIST_ELEMENT:
        return { ...updateListElement({ state, ...action.payload }) };
      case Action.ADD_VARIANT_INVENTORY_MOVEMENT:
        return { ...addVariantInventoryMovement({ state, ...action.payload }) };
      case Action.ADD_LINES_INVENTORY_MOVEMENT:
        return { ...addLinesInventoryMovement({ state, ...action.payload }) };
      case Action.ADD_INVENTORY_MOVEMENT:
        return { ...addInventoryMovement({ state, ...action.payload }) };
      case Action.CREATE_CUSTOM_FIELDS_VALUES:
        return { ...createCustomFieldValue(action.payload, state) };
      case Action.UPDATE_CUSTOM_FIELDS_VALUES:
        return { ...updateCustomFieldsValue(action.payload, state) };
      case Action.DELETE_CUSTOM_FIELDS_VALUES:
        return { ...deleteCustomFieldValue(action.payload, state) };
      case Action.UPDATE_VARIANT_ELEMENT:
        return { ...updateVariantElementSubscription({ state, ...action.payload }) };
      case Action.DELETE_LIST_ELEMENT:
        return { ...deleteListElement({ state, ...action.payload }) };
      case Action.SORT_LIST_ELEMENT:
        return { ...sortListElement(action.payload, state) };
      case Action.UPDATE_LIST_STATE: {
        return {
          ...state,
          // @ts-ignore
          [action.payload.type]: { ...state[action.payload.type], state: action.payload.state },
        };
      }
      case Action.UPDATE_LIST: {
        return {
          ...state,
          // @ts-ignore
          [action.payload.type]: { ...state[action.payload.type], ...action.payload },
        };
      }
      case Action.FETCH_LIST_ITEM:
        return {
          ...state,
          [action.payload.type]: {
            // @ts-ignore
            ...state[action.payload.type],
            // @ts-ignore
            cols: state[action.payload.type].cols,
            items: {
              // @ts-ignore
              ...state[action.payload.type].items,
              items: action.payload.items,
              nextToken: action.payload.nextToken,
            },
          },
        };
      case Action.ADD_LIST_ITEM: {
        if (action.payload.productID) {
          //@ts-ignore
          const product = state.products.items.items.find((item: any) => item.id === action.payload.productID);
          const { subValues: elementSubValues } = product;
          //@ts-ignore
          const subValuesToRemove = elementSubValues.length > action.payload.items.length;
          //@ts-ignore
          if (subValuesToRemove) {
            return {
              ...state,
              [action.payload.type]: {
                // @ts-ignore
                ...state[action.payload.type],
                // @ts-ignore
                cols: state[action.payload.type].cols,
                items: {
                  // @ts-ignore
                  ...state[action.payload.type].items,
                  // @ts-ignore
                  items: [...action.payload.items],
                  nextToken: action.payload.nextToken,
                },
                filteredItems: {
                  // @ts-ignore
                  ...state[action.payload.type].filteredItems,
                  // @ts-ignore
                  items: [...state[action.payload.type].filteredItems.items, ...action.payload.items].filter(
                    (e, i, arr) => arr.findIndex((el: any) => el.id === e.id) === i,
                  ),
                  nextToken: action.payload.nextToken,
                },
              },
            };
          }
        }

        // @ts-ignore
        const existingItemIDs = state[action.payload.type].items.items.map(e => e.id);
        const newItems = action.payload.items.filter((e: { id: string }) => !existingItemIDs.includes(e.id));
        // @ts-ignore
        const updatedItems = [...state[action.payload.type].items.items, newItems];

        return {
          ...state,
          [action.payload.type]: {
            // @ts-ignore
            ...state[action.payload.type],
            // @ts-ignore
            cols: state[action.payload.type].cols,
            items: {
              // @ts-ignore
              ...state[action.payload.type].items,
              // @ts-ignore
              items: updatedItems.filter((e, i, arr) => arr.findIndex((el: any) => el.id === e.id) === i),
              nextToken: action.payload.nextToken,
            },
            filteredItems: {
              // @ts-ignore
              ...state[action.payload.type].filteredItems,
              // @ts-ignore
              items: updatedItems.filter((e, i, arr) => arr.findIndex((el: any) => el.id === e.id) === i),
              nextToken: action.payload.nextToken,
            },
          },
        };
      }
      case Action.FETCH_LIST:
        return {
          ...state,
          [action.payload.type]: {
            cols: action.payload.cols,
            items: {
              // @ts-ignore
              items: action.payload.items.items,
              // @ts-ignore
              nextToken: action.payload.items.nextToken,
            },
          },
        };
      case Action.FETCH_STAT_ITEM:
        return {
          ...state,
          [action.payload.type]: {
            // @ts-ignore
            cols: state[action.payload.type].cols,
            items: {
              // @ts-ignore
              ...state[action.payload.type].items,
              items: action.payload.items,
            },
          },
        };
      case Action.FETCH_ITEM:
        return {
          ...state,
          [action.payload.type]: action.payload.items,
        };
      case Action.ADD_COLS:
        return addCols(action.payload, state);
      case Action.UPDATE_COLS:
        return updateCols(action.payload, state);
      case Action.DELETE_COL:
        return deleteCol(action.payload, state);
      case Action.CHANGE_COLS_CONF:
        return changeColsConf(action.payload, state);
      case Action.UPDATE_COLS_CONF:
        return updateColsConf(action.payload, state);
      case Action.RESET_CUSTOM_COLS:
        return resetCustomCols(state);
      case Action.UNSELECT_ALL_LIST:
        return { ...unSelectAllList(state) };
      case Action.UNSELECT_PRODUCTS_BY_ID:
        return { ...unSelectProductsById(action.payload, state) };
      case Action.UNSELECT_VARIANT_BY_ID:
        return { ...unSelectVariantById(action.payload, state) };
      case Action.UPDATE_INVENTORY_QUANTITY:
        return { ...updateInventoryQuantities(action.payload, state) };
      case Action.UPDATE_ELEMENT_ITEMS:
        return { ...updateElementItems(action.payload, state) };
      case Action.UPDATE_VARIANT_AFTER_SUBSCRIPTION:
        return { ...updateVariantAfterSubscription(action.payload, state) };
      case Action.UPDATE_ALL_SELECTED_ITEMS:
        return { ...updateAllSelectedItemsAndVariant(action.payload, state) };
      case Action.UPDATE_SELECTED_SUB_ITEM:
        return { ...updateSelectedItemVariant(action.payload, state) };
      case Action.UPDATE_SELECTED_ITEM:
        return { ...updateSelectedItemAndVariant(action.payload, state) };
      case Action.UPDATE_PARENT_ITEM_ONLY:
        return { ...updateOnlyParentItem(action.payload, state) };
      case Action.UPDATE_PRODUCT_SUBSCRIPTION_ONLY:
        return { ...updateParentProduct(action.payload, state) };
      case Action.UPDATE_ITEM_ONLY:
        return { ...updateItemWithoutSubValue(action.payload, state) };
      case Action.UPDATE_BUY_PRICE:
        return { ...updateBuyPrice(action.payload, state) };
      case Action.UPDATE_MODAL:
        return { ...state, modal: { ...state.modal, ...action.payload } };
      case Action.UPDATE_TOASTER:
        return { ...state, toaster: { ...state.toaster, ...action.payload } };
      case Action.REQUEST_ITEMS:
        return { ...requestItems(action.payload, state) };
      case Action.REQUEST_FILTERERED_ITEMS:
        return { ...requestFilteredItems(action.payload, state) };
      case Action.SWITCH_FILTERED_ITEMS_TO_ITEMS:
        return { ...switchFilteredItemsToItems(action.payload, state) };
      case Action.CLEAR_ALL_FILTERS:
        return { ...clearAllFilters(state) };
      case Action.TOGGLE_FILTERS:
        return { ...toggleFilters(action.payload, state) };
      case Action.FILTER_CUSTOM_FIELD_ITEMS:
        return { ...filterCustomFields(action.payload, state) };
      case Action.FILTER_TAG_ITEMS:
        return { ...filterTags(action.payload, state) };
      case Action.UPDATE_CATEGORIES_ASSOCIATIONS:
        return { ...updateCategoriesAssociations(action.payload, state) };
      case Action.UPDATE_TAGS_ASSOCIATIONS:
        return { ...updateTagsAssociations(action.payload, state) };
      case Action.UPDATE_TAGS_DATA:
        return { ...updateTagsData(action.payload, state) };
      case Action.DELETE_TAGS_DATA:
        return { ...deleteTagsData(action.payload, state) };
      case Action.DELETE_TAG_FROM_PRODUCT:
        return { ...removeTagFromProduct(action.payload, state) };
      case Action.UPDATE_CUSTOM_FIELDS_ASSOCIATIONS:
        return { ...updateCustomFieldsAssociations(action.payload, state) };
      case Action.UPDATE_OPTIONS_ASSOCIATIONS:
        return { ...updateOptionsAssociations(action.payload, state) };
      case Action.DELETE_OPTION_FROM_PRODUCT:
        return { ...removeOptionFromProduct(action.payload, state) };
      case Action.UPDATE_PACKS_ASSOCIATIONS:
        return { ...updatePacksAssociations(action.payload, state) };
      case Action.DRAWER:
        return {
          ...state,
          drawer: {
            ...action.payload,
            navigation: resetHistory(),
            open: Object.prototype.hasOwnProperty.call(action.payload, 'open')
              ? action.payload.open
              : state.drawer.open,
          },
        };
      case Action.TOGGLE_DRAWER: {
        return {
          ...state,
          drawer: { ...state.drawer, open: action.payload ? action.payload.open : !state.drawer.open },
        };
      }
      case Action.TOGGLE_MENU: {
        return {
          ...state,
          menu: { ...state.menu, ...action.payload },
        };
      }
      case Action.UPDATE_DRAWER_STATE: {
        return {
          ...state,
          drawerState: action.payload,
        };
      }
      case Action.UPDATE_DRAWER_ACTION: {
        return {
          ...state,
          drawer: {
            ...state.drawer,
            action: action.payload,
          },
        };
      }
      case Action.DRAWER_BACK: {
        const { drawerState, ...reset } = back(state.drawer.navigation, state.drawerState);
        return {
          ...state,
          drawerState: drawerState ?? state.drawerState,
          drawer: {
            ...state.drawer,
            open: reset.current ? state.drawer.open : false,
            navigation: reset,
          },
        };
      }
      case Action.DRAWER_ADD_HISTORY: {
        return {
          ...state,
          drawer: {
            ...state.drawer,
            navigation: addHistory({
              route: action.payload,
              navigation: state.drawer.navigation,
            }),
          },
        };
      }
      case Action.DRAWER_RESET_HISTORY: {
        return {
          ...state,
          drawer: {
            ...state.drawer,
            navigation: resetHistory(),
          },
        };
      }
      case Action.UPDATE_COMPANY: {
        return {
          ...state,
          account: {
            ...state.account,
            companies: {
              ...state.account.companies,
              items: state.account.companies.items.map((company: any) =>
                company.companyID === action.payload.id
                  ? {
                      ...company,
                      company: {
                        ...company.company,
                        ...action.payload,
                      },
                    }
                  : company,
              ),
            },
          },
          companies: {
            ...state.companies,
            items: {
              ...state.companies.items,
              items: state.companies.items.items.map((company: any) =>
                company.id === action.payload.id ? { ...company, ...action.payload } : company,
              ),
            },
          },
        };
      }
      case Action.ADD_ELEMENT_COMPANY_SETTINGS: {
        return { ...addElementCompanySettings(action.payload.id, action.payload.type, state) };
      }
      case Action.DELETE_ELEMENT_COMPANY_SETTINGS: {
        return { ...deleteElementCompanySettings(action.payload.id, action.payload.type, state) };
      }
      case Action.UPDATE_FLATFILE_INFO: {
        return {
          ...state,
          flatfile: {
            ...action.payload,
          },
        };
      }
      case Action.UPDATE_INTERVAL: {
        return {
          ...state,
          interval: action.payload,
        };
      }
      case Action.UPDATE_STATS_FILTERS: {
        return {
          ...state,
          stats: action.payload,
        };
      }
      default:
        return state;
    }
};

// @ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__ || compose;

// @ts-ignore
const store = createStore(reducer, initialState, composeEnhancer(enhancer, applyMiddleware(thunk)));

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
  cypressInit();
}

export default store;
