const Edit = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16016 17.4613V20.5013C3.16016 20.7813 3.38016 21.0013 3.66016 21.0013H6.70016C6.83016 21.0013 6.96016 20.9513 7.05016 20.8513L17.9702 9.94128L14.2202 6.19128L3.31016 17.1013C3.21016 17.2013 3.16016 17.3213 3.16016 17.4613ZM20.8702 7.04128C21.2602 6.65128 21.2602 6.02128 20.8702 5.63128L18.5302 3.29128C18.1402 2.90128 17.5102 2.90128 17.1202 3.29128L15.2902 5.12128L19.0402 8.87128L20.8702 7.04128Z"
      fill={color}
    />
  </svg>
)

export default Edit
