import { useSpace, ISpace } from '@flatfile/react';
import styles from './Migration.module.css';
import './flatfile.css';
import { useSelector } from 'react-redux';
import { flatFileModal } from '../../../../store/selector';
import { useDispatch } from 'react-redux';
import { updateFlatFile } from '../../../../actions/modal';

const FlatfileImport = () => {
  const flatFile = useSelector(flatFileModal);
  const dispatch = useDispatch();

  const closeSpace = {
    operation: 'submit',
    onClose: () => updateFlatFile(false, {}, dispatch),
  };

  const loading = (
    <div className={styles.containerLoader}>
      <div className={styles.loader}></div>
    </div>
  );

  const spaceProps: ISpace = {
    ...flatFile.flatfileData.spaceProps,
    closeSpace,
    loading,
    // displayAsModal: false,
  };

  const Space = useSpace(spaceProps);
  return (
    <div className="flatfile_iFrameContainer">
      <div>{flatFile.show && Space}</div>
    </div>
  );
};

export default FlatfileImport;
