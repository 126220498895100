const InventoryOut = ({ color }: { color: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 18L6 18L6 6L9 6L9 4L6 4C4.9 4 4 4.9 4 6L4 18C4 19.1 4.9 20 6 20L9 20L9 18ZM15 7L13.59 8.41L16.17 11L8 11L8 13L16.17 13L13.59 15.59L15 17L20 12L15 7Z"
      fill={color}
    />
  </svg>
)

export default InventoryOut
