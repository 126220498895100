import { ComponentSize, ComponentDirection, ComponentType } from '../types'

type Props = {
  size?: ComponentSize
  direction: ComponentDirection
  type: ComponentType
}

/**
 *
 * This element displays a divider
 *
 * @param size - Divider's size
 * @param direction - Divider's direction
 * @param type - Divider's type
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Divider = ({ size, direction, type }: Props): JSX.Element => {
  const getStyle = () => {
    const borderSize = `var(--comp-divider-size-width-sm)`
    let borderDepth = ' solid'
    const borderColor = ' var(--comp-divider-color-default)'

    if (type === ComponentType.DASHED) borderDepth = ' dotted'

    if (direction === ComponentDirection.HORIZONTAL) {
      return { borderTop: borderSize.concat(borderDepth).concat(borderColor) }
    }
    if (direction === ComponentDirection.VERTICAL) {
      return {
        transform: 'rotateZ(90deg)',
        border: borderSize.concat(borderDepth).concat(borderColor),
      }
    }
  }

  return <div style={getStyle()} />
}

export default Divider
