import { Route, Routes } from 'react-router-dom'

import Companies from './companies'
import SellerCode from './sellerCode'
import UserAccount from './userAccount'
import TestMode from './testMode'

const AccountRoutes = () => (
  <Routes>
    <Route path="userAccount" element={<UserAccount />} />
    <Route path="sellerCode" element={<SellerCode />} />
    <Route path="companies" element={<Companies />} />
    <Route path="testMode" element={<TestMode />} />
  </Routes>
)

export default AccountRoutes
