import { createTheme } from '@mui/material'

export const themeRange = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        error: false,
      },
      styleOverrides: {
        root: {
          fontSize: `var(--sys-typography-body-sm-font-size)`,
          textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
          letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
          fontWeight: `var(--sys-typography-body-sm-font-weight)`,
          lineHeight: `var(--sys-typography-body-sm-line-height)`,
          fontFamily: `'Poppins', sans- serif !important`,
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
          '&:hover': {
            borderColor: 'var(--sys-color-status-interactive) !important',
            borderWitdh: '0 !important',
          },
          '&:focus': {
            borderColor: 'var(--sys-color-status-interactive) !important',
            borderWitdh: '0 !important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: `var(--sys-typography-body-sm-font-size)`,
          textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
          letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
          fontWeight: `var(--sys-typography-body-sm-font-weight)`,
          lineHeight: `var(--sys-typography-body-sm-line-height)`,
          fontFamily: `'Poppins', sans- serif !important`,
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
          borderRadius: `var(--sys-size-border-radius-md) !important`,
          borderColor: `var(--sys-color-border-default) !important`,
          '&:hover': {
            borderColor: 'var(--sys-color-status-interactive) !important',
            borderWitdh: '0 !important',
          },
          '&:focus': {
            borderColor: 'var(--sys-color-status-interactive) !important',
            borderWitdh: '0 !important',
          },
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        error: false,
      },
      styleOverrides: {
        root: {
          fontSize: `var(--sys-typography-body-sm-font-size)`,
          textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
          letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
          fontWeight: `var(--sys-typography-body-sm-font-weight)`,
          lineHeight: `var(--sys-typography-body-sm-line-height)`,
          fontFamily: `'Poppins', sans- serif`,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: '100%',
          fontFamily: 'Poppins',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: `var(--sys-size-border-radius-md)`,
          minWidth: '100%',
          padding: '2px !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
        },
      },
    },
  },
})

export const themeRequester = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        error: false,
      },
      styleOverrides: {
        root: {
          fontSize: `var(--sys-typography-body-sm-font-size)`,
          textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
          letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
          fontWeight: `var(--sys-typography-body-sm-font-weight)`,
          lineHeight: `var(--sys-typography-body-sm-line-height)`,
          fontFamily: `'Poppins', sans- serif !important`,
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        error: false,
      },
      styleOverrides: {
        root: {
          fontSize: `var(--sys-typography-body-sm-font-size)`,
          textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
          letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
          fontWeight: `var(--sys-typography-body-sm-font-weight)`,
          lineHeight: `var(--sys-typography-body-sm-line-height)`,
          fontFamily: `'Poppins', sans- serif !important`,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0px 15px',
          width: '233px',
          alignSelf: 'center',
          borderRadius: `var(--sys-size-border-radius-md) !important`,
          border: `var(--sys-size-border-width-sm) solid var(--sys-color-border-default)`,
          borderColor: `var(--sys-color-border-default) !important`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: `var(--sys-size-border-radius-md)`,
          minWidth: '100%',
          fontFamily: `'Poppins', sans- serif !important`,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          backgroundColor: ':hover black',
          fontFamily: `'Poppins', sans- serif !important`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: `'Poppins', sans- serif !important`,
        },
      },
    },
  },
})

export const theme = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        error: false,
      },
      styleOverrides: {
        root: {
          fontSize: `var(--sys-typography-body-sm-font-size)`,
          textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
          letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
          fontWeight: `var(--sys-typography-body-sm-font-weight)`,
          lineHeight: `var(--sys-typography-body-sm-line-height)`,
          fontFamily: `'Poppins', sans- serif !important`,
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        error: false,
      },
      styleOverrides: {
        root: {
          fontSize: `var(--sys-typography-body-sm-font-size)`,
          textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
          letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
          fontWeight: `var(--sys-typography-body-sm-font-weight)`,
          lineHeight: `var(--sys-typography-body-sm-line-height)`,
          fontFamily: `'Poppins', sans- serif !important`,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: '100%',
          borderRadius: `var(--sys-size-border-radius-md) !important`,
          border: `var(--sys-size-border-width-sm) solid var(--sys-color-border-default)`,
          borderColor: `var(--sys-color-border-default) !important`,
          fontFamily: `'Poppins', sans- serif !important`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: `var(--sys-size-border-radius-md)`,
          minWidth: '100%',
          fontFamily: `'Poppins', sans- serif !important`,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          backgroundColor: ':hover black',
          fontFamily: `'Poppins', sans- serif !important`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
        },
      },
    },
  },
})
