import { useState } from 'react'

import DisplayIcon2 from '../../icons'
import Layer from '../../layer'

import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../types'

import styles from './../Button.module.css'

type Props = {
  size: ComponentSize
  type: ComponentType
  state?: ComponentState
  status?: ComponentStatus
  onClick?: () => void
  Icon?: any
  dataCy?: string
}

/**
 *
 * This element displays a button
 *
 * @param size - Button's size
 * @param type - Button's type
 * @param state - Button's state
 * @param status - Button's status
 * @param icon - An icon to display
 * @param dataCy - An unique id
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const IconButton = ({ size, type, state, status, onClick, Icon, dataCy }: Props): JSX.Element => {
  const [iconButtonState, setState] = useState(ComponentState.DEFAULT)

  const getForegroundColor = () =>
    iconButtonState === ComponentState.HOVER || iconButtonState === ComponentState.FOCUSED
      ? 'var(--sys-color-content-interactive)'
      : `var(--comp-icon-button-color-foreground-${state === ComponentState.DISABLED ? 'disabled' : type})`

  const getStyle = () => ({
    background: `var(--comp-icon-button-color-background-${
      state === ComponentState.DISABLED
        ? 'disabled'
        : status === ComponentStatus.DANGER
        ? 'danger-' + type
        : 'default' + type
    })`,
  })

  return (
    <button
      // @ts-ignore
      style={{ ...getStyle() }}
      className={styles.button}
      onClick={() => {
        if (state !== ComponentState.DISABLED) {
          setState(ComponentState.FOCUSED)
          onClick && onClick()
        }
      }}
      onKeyPress={(key) => {
        if (key.key === 'Enter') state !== ComponentState.DISABLED && onClick && onClick()
      }}
      disabled={state === ComponentState.DISABLED}
      onMouseEnter={() => setState(ComponentState.HOVER)}
      onMouseLeave={() => setState(ComponentState.DEFAULT)}
      data-cy={dataCy}
    >
      <Layer
        isDisabled={state === ComponentState.DISABLED}
        style={{
          height: `var(--comp-icon-button-size-height-${size})`,
          width: `var(--comp-icon-button-size-width-${size})`,
          borderRadius: `var(--comp-icon-button-border-radius)`,
        }}
      >
        <DisplayIcon2
          Icon={Icon}
          color={getForegroundColor()}
          width={`20px`}
          height={'20px'}
          //   width={`var(--comp-icon-button-size-icon-${size})`}
          //   height={`var(--comp-icon-button-size-icon-${size})`}
        />
      </Layer>
    </button>
  )
}

export default IconButton
