import { getShop } from '../../getters/setting/shop'
import { getSale as getSaleGet } from '../../getters/activity/sale'
import { List } from '../../store/types'

/**
 *
 * This method calls the API to get informations about an product
 * present inside a sale line
 *
 * @param allProducts - every products from the store
 * @param allPacks - every packs from the store
 * @param lines - Sale lines
 * @param dispatch - A hook to call the store
 *
 * @returns - Product informations
 *
 * @author - both Jennifer Charlois & David Faure
 *
 */
export const getProductLines = (allProducts: List, allPacks: List, lines: any, dispatch?: any) => {
  // instead of doing two call we just return information already in redux store.
  return lines.map((line: any) => {
    if (line.details && line.details.__typename === 'SaleLineProductDetails') {
      const product = allProducts.items.items.find((product) => product.id === line.details.productID)
      return {
        ...line,
        details: {
          ...line.details,
          doesExist: !!product,
        },
      }
    } else if (line.details && line.details.__typename === 'SaleLinePackDetails') {
      const pack = allPacks.items.items.find((pack) => pack.id === line.details.packID)
      return {
        ...line,
        details: {
          ...line.details,
          doesExist: !!pack,
        },
      }
    } else {
      return line
    }
  })
}

/**
 *
 * This method calls the API for extra informations about the current sale
 * It gets shop infos, product infos and if the sale is refunded or is a refunded sale, target sale infos
 *
 * @param allProducts - every products from the store
 * @param allPacks - every packs from the store
 * @param sale - Current sale
 * @param dispatch - A hook to call the store
 *
 * @returns - A promise of shop, product and target sale informations
 *
 * @author - Jennifer Charlois
 *
 */
const getExtraInfos = async (
  allProducts: List,
  allPacks: List,
  sale: any,
  dispatch?: any
): Promise<{
  shopName: string
  lines: Array<any>
  target?: { id: string; total: string; number: number }
}> => {
  const shop = await getShop(sale.shopID, dispatch)
  const productLines = getProductLines(allProducts, allPacks, sale.lines, dispatch)
  if (sale.targetID) {
    const newSale = await getSaleGet(sale.targetID, dispatch)
    return {
      shopName: shop.data.name,
      lines: productLines,
      target: newSale.data
        ? {
            id: newSale.data.id,
            number: newSale.data.number,
            total: newSale.data.total,
          }
        : undefined,
    }
  }
  return {
    shopName: shop.data.name,
    lines: productLines,
  }
}

export default getExtraInfos
