import store from '../store'
import { objectType, TypeCol, TypeColumnConfiguration } from '../store/types'
import { CustomFieldObjectType, CustomFieldValueType } from '../API'
import { addCols, updateCols, deleteCols } from './cols'
import { NumberEditCell, TextEditCell, DateEditCell, DisabledCell } from '../components/list/cell'
import { TypeCustomField } from '../types'
import { getAlertIcon } from '../components/newComponents/icon'
import { TypeFormError, ValidationFunction } from '../format/errors/types'
import { capitalizeFirstLetter } from '../utils/string'
import { displayDateShortMonth } from '../utils/date'
import { displayNumber } from '../utils/number'
import { customFieldNumber as customFieldNumberValidator } from '../format/errors/filters/editableList'
import DateCell from '../components/list/cell/date'

/**
 *
 * This method generates a column for custom fields list
 *
 * @usedIn - List
 *
 * @param customField - A custom field
 *
 * @returns - A custom field's column
 *
 * @author - Arthur Escriou
 *
 */
const generateCustomCol = (customField: any) => {
  const customFunction = ({ customFields }: { customFields: any }) => {
    const parseCustomField = (value: any, valueType: CustomFieldValueType) => {
      if (valueType === CustomFieldValueType.DATE) {
        if (displayDateShortMonth(value) !== 'Invalid Date') return displayDateShortMonth(value)
        return ''
      }
      if (valueType === CustomFieldValueType.NUMBER) return displayNumber(value)
      return value
    }

    if (customField && customFields && customFields.items && customFields.items.length > 0) {
      const cf = customFields.items
        .filter((cf: any) => cf && cf.customField)
        .find((cf: any) => cf.customField.id === customField.id)
      if (cf) return parseCustomField(cf.value, cf.customField.valueType)
    } else if (customField && customFields && customFields.length > 0) {
      const cf = customFields.filter((cf: any) => cf).find((cf: any) => cf.customFieldID === customField.id)
      if (cf) return parseCustomField(cf.value, cf.valueType)
    }
    return null
  }
  return {
    id: customField.id,
    title: customField.name,
    type: 'function',
    active: true,
    fieldType: 'customField',
    function: customFunction,
  }
}

/**
 *
 * This method generates a column for custom field used on editable list
 *
 * @usedIn - Editable list
 *
 * @param customField - A custom field
 *
 * @returns - An editable custom field's column
 *
 * @author - Arthur Escriou
 *
 */
const generateEditableCustomCol = (customField: any) => {
  const customFunction = ({
    value,
    onChange,
    onBlur,
    error,
    editValues,
    validator,
  }: {
    value: any
    onChange: (val: any) => void
    onBlur: () => void
    editValues?: any
    error: TypeFormError
    validator: ValidationFunction
  }) => {
    const colId = 'cf' + customField.id
    const customFields = value && value.customFields ? value.customFields : {}

    /**
     *
     * This method returns the cell's value
     * - If we are in a simple object, we return the value
     * - If we get custom field object inside the simple object (which happens when we first initialise the app)
     * We get the value based on the id of it
     * - If we get editValues (variable used when editing the list), we return the value related inside
     * - If we are in a child object, we can not edit the cell
     *
     * @returns - Cell's value
     *
     * @author - Arthur Escriou
     *
     */
    const valueToDisplay = () => {
      if (!value) {
        return ''
      }
      if (editValues && editValues[colId] !== undefined) {
        return editValues[colId]
      }
      if (customFields && customFields.items && customField) {
        const cf = customFields.items
          .filter((cf: any) => cf)
          .filter((cf: any) => cf.customField)
          .find((cf: any) => cf.customField.id === customField.id)
        if (cf) {
          return cf.value
        }
      }
    }

    const param = { onChange, onBlur, error, icons: getAlertIcon(error), validator }

    if (value && value.variantID)
      return DisabledCell({
        value: '',
      })

    switch (customField.valueType) {
      case 'DATE':
        return DateCell({
          value: valueToDisplay(),
          ...param,
        })
      case 'NUMBER':
        return NumberEditCell({
          value: valueToDisplay(),
          ...param,
        })
      default:
        return TextEditCell({
          value: valueToDisplay(),
          ...param,
        })
    }
  }

  return {
    id: 'cf' + customField.id,
    title: customField.name,
    type: 'element',
    fieldType: 'customField',
    editable: true,
    active: true,
    function: customFunction,
    validator: customField.valueType === CustomFieldValueType.NUMBER ? customFieldNumberValidator : undefined,
  }
}

const getColsEntityCf = (
  listCustomFields: any,
  type: CustomFieldObjectType,
  generateCols: (customField: TypeCustomField) => any
) =>
  listCustomFields
    .filter(({ objectType }: { objectType: CustomFieldObjectType }) => objectType === type)
    .map(generateCols)

const generateCustomFieldColsConfiguration = (listCustomFields: Array<TypeCustomField>, dispatch: any) => {
  addCols(
    dispatch,
    getColsEntityCf(listCustomFields, CustomFieldObjectType.PRODUCT, generateCustomCol),
    'requestProducts'
  )
  addCols(
    dispatch,
    getColsEntityCf(listCustomFields, CustomFieldObjectType.PRODUCT, generateEditableCustomCol),
    'editProducts'
  )
  addCols(
    dispatch,
    getColsEntityCf(listCustomFields, CustomFieldObjectType.CATEGORY, generateCustomCol),
    'requestCategories'
  )
  addCols(dispatch, getColsEntityCf(listCustomFields, CustomFieldObjectType.PACK, generateCustomCol), 'requestPacks')
  addCols(
    dispatch,
    getColsEntityCf(listCustomFields, CustomFieldObjectType.CASHBOOK, generateCustomCol),
    'requestCashbooks'
  )
  addCols(
    dispatch,
    getColsEntityCf(listCustomFields, CustomFieldObjectType.CUSTOMER, generateCustomCol),
    'requestCustomers'
  )
}

/**
 *
 * This method calls the store in a purpose to add new custom field columns
 * when we create new ones
 *
 * @usedIn - Custom field subscription
 *
 * @param customField - A custom field
 * @param dispatch - A hook to call the store
 *
 * @returns - void
 *
 * @author - Arthur Escriou
 *
 */
const createCustomFieldCol = (customField: any, dispatch: any) => {
  const type: CustomFieldObjectType = customField.objectType
  switch (type) {
    case CustomFieldObjectType.PRODUCT:
      addCols(dispatch, [generateCustomCol(customField)], 'requestProducts')
      addCols(dispatch, [generateEditableCustomCol(customField)], 'editProducts')
      break
    case CustomFieldObjectType.CATEGORY:
      addCols(dispatch, [generateCustomCol(customField)], 'requestCategories')
      break
    case CustomFieldObjectType.PACK:
      addCols(dispatch, [generateCustomCol(customField)], 'requestPacks')
      break
    case CustomFieldObjectType.CUSTOMER:
      addCols(dispatch, [generateCustomCol(customField)], 'requestCustomers')
      break
    case CustomFieldObjectType.CASHBOOK:
      addCols(dispatch, [generateCustomCol(customField)], 'requestCashbooks')
      break
    default:
      break
  }
}

const buildNewCustomFieldCol = (element: string, customField: any, dispatch: any) => {
  // @ts-ignore
  const elementCols = store.getState().cols[element]
  const elementCol: TypeCol = generateCustomCol(customField)
  const confIndex: number = elementCols.findIndex(
    (c: TypeColumnConfiguration) => c.id === 'request' + capitalizeFirstLetter(element)
  )
  const conf = elementCols[confIndex]
  if (conf) {
    const TypeCol = conf.cols.find((c: TypeCol) => c.id === customField.id)
    if (TypeCol) {
      TypeCol.title = elementCol.title
      TypeCol.function = elementCol.function
      updateCols(dispatch, elementCols)
    }
  }

  if (element === 'products') {
    const editConfIndex: number = elementCols.findIndex((c: TypeColumnConfiguration) => c.id === 'editProducts')
    const editConf = elementCols[editConfIndex]
    if (editConf) {
      const TypeCol = editConf.cols.find((c: TypeCol) => c.id === 'cf' + customField.id)
      if (TypeCol) {
        TypeCol.title = elementCol.title
        TypeCol.function = elementCol.function
        updateCols(dispatch, elementCols)
      }
    }
  }
}

/**
 *
 * This method calls the store in a purpose to update custom field columns
 * when we update/delete them
 *
 * @usedIn - Custom field subscription
 *
 * @param customField - A custom field
 * @param dispatch - A hook to call the store
 *
 * @returns - void
 *
 * @author - Arthur Escriou
 *
 */
const updateCustomFieldCol = (customField: any, dispatch: any) => {
  const type: CustomFieldObjectType = customField.objectType
  switch (type) {
    case CustomFieldObjectType.PRODUCT:
      buildNewCustomFieldCol('products', customField, dispatch)
      break
    case CustomFieldObjectType.CATEGORY:
      buildNewCustomFieldCol('categories', customField, dispatch)
      break
    case CustomFieldObjectType.PACK:
      buildNewCustomFieldCol('packs', customField, dispatch)
      break
    case CustomFieldObjectType.CASHBOOK:
      buildNewCustomFieldCol('cashbooks', customField, dispatch)
      break
    case CustomFieldObjectType.CUSTOMER:
      buildNewCustomFieldCol('customers', customField, dispatch)
      break
    default:
      break
  }
}

const deleteCustomFieldCol = (id: string, type: CustomFieldObjectType, dispatch: any) => {
  switch (type) {
    case CustomFieldObjectType.PRODUCT:
      deleteCols(dispatch, id, objectType.PRODUCT)
      break
    case CustomFieldObjectType.CATEGORY:
      deleteCols(dispatch, id, objectType.CATEGORY)
      break
    case CustomFieldObjectType.PACK:
      deleteCols(dispatch, id, objectType.PACK)
      break
    case CustomFieldObjectType.CUSTOMER:
      deleteCols(dispatch, id, objectType.CUSTOMER)
      break
    case CustomFieldObjectType.CASHBOOK:
      deleteCols(dispatch, id, objectType.CASHBOOK)
      break
    default:
      break
  }
}

export {
  generateCustomCol,
  generateEditableCustomCol,
  createCustomFieldCol,
  generateCustomFieldColsConfiguration,
  updateCustomFieldCol,
  deleteCustomFieldCol,
}
