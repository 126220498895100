const Info = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1614 2C6.64138 2 2.16138 6.48 2.16138 12C2.16138 17.52 6.64138 22 12.1614 22C17.6814 22 22.1614 17.52 22.1614 12C22.1614 6.48 17.6814 2 12.1614 2ZM12.1614 17C11.6114 17 11.1614 16.55 11.1614 16V12C11.1614 11.45 11.6114 11 12.1614 11C12.7114 11 13.1614 11.45 13.1614 12V16C13.1614 16.55 12.7114 17 12.1614 17ZM13.1614 9H11.1614V7H13.1614V9Z"
      fill={color}
    />
  </svg>
)

export default Info
