import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ChevronRight } from '../../../assets/icons'
import { getMenu } from '../../../store/selector'
import DisplayIcon2 from '../icons'

import styles from './TopBar.module.css'

const Path = () => {
  const menu = useSelector(getMenu)
  const { t } = useTranslation()

  return (
    <div data-cy="topbar-breadcrumbs" className={styles.path}>
      {menu.section && menu.subSection ? (
        <>
          {t('menu.sections.' + menu.section.toUpperCase())}
          <DisplayIcon2
            Icon={ChevronRight}
            color={'var(--sys-color-content-primary)'}
            width="16px"
            height="16px"
            nonEditable
          />
          {t('menu.subSections.' + menu.subSection.toUpperCase())}
        </>
      ) : menu.section ? (
        t('menu.sections.' + menu.section.toUpperCase())
      ) : menu.subSection ? (
        t('menu.subSections.' + menu.subSection.toUpperCase())
      ) : (
        ''
      )}
    </div>
  )
}

export default Path
