import UniqueDropDown from '../../newComponents/dropDown/unique'
import { TypeList } from '../../newComponents/list'
import { ComponentState } from '../../newComponents/types'

const DropDownCell = ({
  fieldID,
  index,
  Element,
  items,
  state,
}: {
  fieldID: string
  index: number
  Element: any
  items: TypeList
  state?: ComponentState
}) => (
  <UniqueDropDown
    eventID="list"
    parentID={'select' + fieldID + index}
    popUpID={'selectPopUp' + fieldID + index}
    Element={Element}
    state={state}
    items={items}
    dataCy={`list-row-${index}-more`}
  />
)

export default DropDownCell
