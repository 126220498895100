const Sellers = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1614 4H14.9814C14.5614 2.84 13.4614 2 12.1614 2C10.8614 2 9.76138 2.84 9.34138 4H5.16138C4.06138 4 3.16138 4.9 3.16138 6V20C3.16138 21.1 4.06138 22 5.16138 22H19.1614C20.2614 22 21.1614 21.1 21.1614 20V6C21.1614 4.9 20.2614 4 19.1614 4ZM12.1614 4C12.7114 4 13.1614 4.45 13.1614 5C13.1614 5.55 12.7114 6 12.1614 6C11.6114 6 11.1614 5.55 11.1614 5C11.1614 4.45 11.6114 4 12.1614 4ZM12.1614 8C13.8214 8 15.1614 9.34 15.1614 11C15.1614 12.66 13.8214 14 12.1614 14C10.5014 14 9.16138 12.66 9.16138 11C9.16138 9.34 10.5014 8 12.1614 8ZM18.1614 20H6.16138V18.6C6.16138 16.6 10.1614 15.5 12.1614 15.5C14.1614 15.5 18.1614 16.6 18.1614 18.6V20Z"
      fill={color}
    />
  </svg>
)

export default Sellers
