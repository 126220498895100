import { ValidationFunction } from '../types'
import { getMessage } from '..'

export const hasAtLeastOneValue: () => ValidationFunction = () => (value: Array<any>) =>
  value && value.length > 0 ? null : { value: true, message: getMessage('hasAtLeastOneValue') }

export const hasUniqueValues: () => ValidationFunction = () => ({
  item,
  items,
}: {
  item: string
  items: Array<string>
}) =>
  item && items.filter((cItem) => cItem === item).length === 1 ? null : { value: true, message: getMessage('unique') }
