import { useTranslation } from 'react-i18next'

import ColorPicker from '../../../../../components/newComponents/colorPicker'
import CustomField from '../../../../../components/oldComponents/catalog/customField'
import generateDataCy from '../../../../../utils/cypress'
import { ComponentErrorText, ComponentSize, ComponentStatus } from '../../../../../components/newComponents/types'
import Input from '../../../../../components/newComponents/input'
import { TypeCategory } from '../../../../../types'
import { enumToStr } from '../../../../../utils/color'
import Thumbnail from '../../../../../components/newComponents/thumbnail'

import styles from '../../../../../components/oldComponents/drawer/Drawer.module.css'

const Form = ({
  handleChange,
  handleBlur,
  handleBlurNested,
  handleSubmit,
  state,
  dataCy,
}: {
  handleChange: (type: string, index?: number) => (event: any) => void
  handleBlur: (field: string) => void
  handleBlurNested: (index: number) => void
  handleSubmit: () => void
  state: TypeCategory
  dataCy?: string
}) => {
  const { t } = useTranslation()

  const errorText = () => {
    if (state.errors?.name?.value) {
      return state.errors.name.message
    }

    if (state.errors.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <div className={styles.overflow}>
      <div className={styles.form}>
        <ColorPicker
          title={t('button.EDIT')}
          Element={
            <Thumbnail
              size={ComponentSize.LARGE}
              color={state.color ? enumToStr(state.color) : state.color}
              iconText={state.iconText}
              image={state.selected === 'color' ? undefined : state.photo}
            />
          }
          iconText={state.iconText}
          color={state.color}
          photo={state.photo}
          resize={600}
          selected={state.selected}
          onSelect={handleChange('color')}
          onChange={handleChange('iconText')}
          onBlur={() => {
            if (state.iconText === '') handleChange('iconText')(state.name?.substring(0, 4))
          }}
          onUpload={handleChange('photo')}
          dataCy={generateDataCy({ scope: dataCy!, value: 'color' })}
          isIconText
          isImage
        />
        <Input
          label={t('catalog.category.form.NAME')}
          value={state.name}
          onChange={handleChange('name')}
          onBlur={() => handleBlur('name')}
          onSubmit={handleSubmit}
          style={{
            status: state.errors.name?.value || state.errors.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={errorText()}
          dataCy={generateDataCy({ scope: dataCy!, value: 'name' })}
        />
        <CustomField
          selectedCustomFields={state.customFields}
          onChange={handleChange}
          onBlur={handleBlurNested}
          onSubmit={handleSubmit}
          errors={state.errors?.customFields}
          cyContext={generateDataCy({ scope: dataCy!, value: 'customField' })}
        />
      </div>
    </div>
  )
}

export default Form
