import { TypeCol, TypeColumnConfiguration } from '../types'
import { displayDateShortMonthWithHours } from '../../utils/date'
import { CurrencyEditCell as CurrencyCell } from '../../components/list/cell'
import { enumToStr } from '../../utils/color'
import { ComponentSize } from '../../components/newComponents/types'
import ListItemAvatar from '../../components/newComponents/listItem/avatar'
import Thumbnail from '../../components/newComponents/thumbnail'

const PACK = 'pack'

export const packCols: Array<TypeCol> = [
  {
    id: 'color',
    title: '',
    type: 'element',
    active: true,
    function: ({ value }: any) =>
      value.color || value.photo
        ? ListItemAvatar({
            children: Thumbnail({
              color: enumToStr(value.color),
              size: ComponentSize.SMALL,
              image: value.photo,
              iconText: value.iconText,
            }),
          })
        : null,
  },
  {
    id: 'name',
    title: 'NAME',
    type: 'string',
    field: 'name',
    fieldType: 'string',
    active: true,
    notCustomable: true,
    sortable: true,
  },
  {
    id: 'reference',
    title: 'REFERENCE',
    type: 'string',
    field: 'reference',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'barcode',
    title: 'BARCODE',
    type: 'string',
    field: 'barcode',
    fieldType: 'string',
    active: true,
    sortable: true,
  },
  {
    id: 'sellPrice',
    title: 'SELL_PRICE',
    type: 'element',
    field: 'sellPrice',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: ({ value, currency, decimals }) =>
      CurrencyCell({
        value,
        param: { emptyValue: 'Variable' },
        editable: false,
        currency,
        decimals,
      }),
  },
  {
    id: 'createdAt',
    title: 'CREATED_AT',
    type: 'function',
    field: 'createdAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: (date) => displayDateShortMonthWithHours(date),
  },
]

export const packsColsConf: Array<TypeColumnConfiguration> = [
  {
    id: 'requestPacks',
    type: PACK,
    cols: packCols,
    creatable: true,
    editable: false,
    exportable: false,
    clickable: true,
    shrinkrable: true,
    customable: true,
    checkboxes: true,
    searchBarPlaceholder: 'searchBar.placeholder.NAME_REFERENCE',
    creatablePlaceholder: 'catalog.pack.form.NEW',
  },
]
