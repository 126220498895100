import { ComponentStatus } from '../types'
import DisplayIcon2 from '../icons'

import { Close } from '../../../assets/icons'
import styles from './AttentionBox.module.css'

type Props = {
  title?: any
  status?: ComponentStatus
  text?: string
  LeadIcon?: any
  clearButton?: boolean
  onClick?: () => void
  dataCy?: string
}

/**
 *
 * This element displays an attention box
 *
 * @param title - Box's title
 * @param status - Box's status
 * @param text - Box's descriptive text
 * @param leadIcon - A boolean to display lead icon
 * @param clearButton - A boolean to display cross icon
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const AttentionBox = ({ title, status, text, LeadIcon, clearButton, onClick, dataCy }: Props): JSX.Element => {
  const getStyle = () => ({
    background: `var(--comp-attention-box-color-background-${status})`,
    color: `var(--comp-attention-box-color-foreground-${status})`,
    border: `var(--comp-attention-box-border-width) solid var(--comp-attention-box-color-border-${status})`,
    padding: `var(--comp-attention - box - padding -default)`
  })

  return (
    <div className={styles.attentionBox} style={getStyle()} onClick={() => onClick && onClick()} data-cy={dataCy}>
      {LeadIcon && <DisplayIcon2 Icon={LeadIcon} color={`var(--comp-attention-box-color-border-${status})`} />}
      {title && <div className={styles.title}>{title}</div>}
      {text && <div className={styles.description}>{text}</div>}
      {clearButton && <DisplayIcon2 Icon={Close} color={`var(--comp-attention-box-color-border-${status})`} />}
    </div>
  )
}

export default AttentionBox
