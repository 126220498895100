import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { List } from '../../../../../components/oldComponents/activity/list'
import getAllLines from './config'
import { drawerOpen } from '../../../../../actions/drawer'
import TextArea from '../../../../../components/newComponents/textArea'
import { selectedCompany } from '../../../../../store/selector'
import { TypeCashbook } from '../../../../../types'
import generateDataCy from '../../../../../utils/cypress'
import { ComponentSize, ComponentState, ComponentStatus } from '../../../../../components/newComponents/types'
import { buildRequester } from '../../../../../components/requester/query'
import { TypeComparator, TypePath } from '../../../../../components/requester/types'
import Chip from '../../../../../components/newComponents/chip'
import toggleMenu from '../../../../../actions/menu'

import styles from '../../../../../components/oldComponents/drawer/Drawer.module.css'

type State = {
  infos: Array<any>
  customFields?: Array<any>
  cashback?: Array<any>
  totals?: Array<any>
  payments?: Array<any>
}

const Detail = ({ cashbookState, dataCy }: { cashbookState: TypeCashbook; dataCy: string }) => {
  const initialeState: State = {
    infos: [],
    customFields: [],
    cashback: [],
    totals: [],
    payments: [],
  }
  const [state, setState] = useState(initialeState)
  const company = useSelector(selectedCompany)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const { infos, customFields, cashback, totals, payments } = getAllLines(t, cashbookState, company)
    setState({ ...state, infos, customFields, cashback, totals, payments })
      }, [cashbookState])

  const goToListEntity = (field: string) => {
    drawerOpen(dispatch, { open: false })
    const link = buildRequester({
      filterID: 'cashbookID',
      field: 'cashbookID',
      type: TypePath.PLAIN,
      path: [],
      filters: [],
      comparator: TypeComparator.EQ,
      value: cashbookState.id,
    })
    toggleMenu(dispatch, { section: 'activity', subSection: field })
    navigate(`/activity/${[field]}?r=` + JSON.stringify(link))
  }

  return (
    <div className={styles.overflow}>
      <div className={styles.form}>
        <Chip
          size={ComponentSize.MEDIUM}
          {...cashbookState.status!}
          dataCy={generateDataCy({ scope: dataCy, value: 'status' })}
        />
        <List datas={state.infos} dataCy={dataCy} />
        {state.customFields && state.customFields.length > 0 && <List datas={state.customFields} dataCy={dataCy} />}
        <div
          className={styles.linkToRequester}
          onClick={() => goToListEntity('sales')}
          data-cy={generateDataCy({ scope: dataCy, value: 'sale' })}
        >
          {t('activity.cashbook.SEE_SALE')}
        </div>
        {state.cashback && state.cashback.length > 0 && (
          <List title={t('activity.cashbook.CASHBACK')} datas={state.cashback} dataCy={dataCy} />
        )}
        {cashbookState.note && (
          <TextArea
            label={t('activity.cashbook.NOTE')}
            value={cashbookState.note}
            style={{ status: ComponentStatus.DEFAULT, state: ComponentState.DISABLED }}
            dataCy={generateDataCy({ scope: dataCy, value: 'note' })}
          />
        )}
        {state.totals && state.totals.length > 0 && (
          <List title={t('activity.cashbook.TOTAL')} datas={state.totals} dataCy={dataCy} />
        )}
        {state.payments && state.payments.length > 0 && (
          <List
            title={`${t('activity.cashbook.PAYMENT')} (${state.payments.length})`}
            datas={state.payments}
            dataCy={dataCy}
          />
        )}
        <div
          data-cy={generateDataCy({ scope: dataCy, value: 'payment' })}
          className={styles.linkToRequester}
          onClick={() => goToListEntity('payments')}
        >
          {t('activity.cashbook.SEE_PAYMENT')}
        </div>
      </div>
    </div>
  )
}

export default Detail
