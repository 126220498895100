import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getModal } from '../../../store/selector'

import styles from './Modal.module.css'

/**
 *
 * This element displays a modal in front of everything
 * When changes are coming from the store
 * It does not handle show boolean, every information is coming from
 * the store
 *
 * @usedIn - Everywhere
 *
 * @param show - A boolean to display the modal or not
 * @param children - The elements to show inside the modal
 *
 * @returns - A JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const Modal = (): JSX.Element => {
  const { show, type, Element } = useSelector(getModal)

  useEffect(() => {
    if (show) document.getElementById('modalID')!.scrollTop = 0
  }, [show])

  return (
    <div className={show ? styles.grid : styles.hidden}>
      <div id="modalID" className={type ? styles.containerChildrenCustom : styles.containerChildren}>
        <Element />
      </div>
      <div className={styles.background} />
    </div>
  )
}

export default Modal
