import { Error } from '../../../assets/icons'
import DisplayIcon2 from '../../newComponents/icons'
import styles from './Error.module.css'

/**
 *
 * This element displays an error message as a label
 *
 * @usedIn - Mainly inside a Drawer form
 *
 * @param text - The error text
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ErrorLabel = ({ text, dataCy }: { text: string; dataCy?: string }): JSX.Element => (
  <div className={styles.errorLabel}>
    <div className={styles.errorIcon}>
      <DisplayIcon2 Icon={Error} color="var(--sys-color-content-danger)" />
    </div>
    <div className={styles.errorMessage} data-cy={dataCy}>
      {text}
    </div>
  </div>
)

export default ErrorLabel
