import DisplayIcon2 from '../../newComponents/icons'
import Title from './title'

const getIconTitleLine = (id: string, icon: { icon: JSX.Element; hoverText?: string }, title: JSX.Element) => ({
  id,
  icon,
  title,
})

const generateIconTitleLine = (id: string, value: string, Icon: any, t: (field: string) => string, path: string) =>
  getIconTitleLine(
    id,
    {
      icon: DisplayIcon2({ Icon: Icon, color: 'var(--sys-color-content-secondary)' }),
    },
    <Title topText={t(path)} mainTexts={[value]} />
  )

const getTitleInfoLine = (id: string, title: JSX.Element, info: JSX.Element) => ({ id, title, info })

const getIconTitleInfoLine = (
  id: string,
  icon: { icon: JSX.Element; hoverText?: string },
  title: JSX.Element,
  info: JSX.Element
) => ({ id, icon, title, info })

const getIconTitleActionLine = (
  id: string,
  icon: { icon: JSX.Element; hoverText?: string },
  title: JSX.Element,
  action: { icon: JSX.Element; bulkToAction: () => void }
) => ({ id, icon, title, action })

const getIconTitleInfoActionLine = (
  id: string,
  icon: { icon: JSX.Element; hoverText?: string },
  title: JSX.Element,
  info: JSX.Element,
  action: { icon: JSX.Element; bulkToAction: () => void }
) => ({
  id,
  icon,
  title,
  info,
  action,
})

const getIconTitleInfoActionDrawerLine = (
  icon: { icon: JSX.Element; hoverText?: string },
  title: JSX.Element,
  info: JSX.Element,
  action: { icon: JSX.Element; drawer: Array<any> }
) => ({
  icon,
  title,
  info,
  action,
})

const getIconTitleMenuLine = (
  icon: { icon: JSX.Element; hoverText?: string },
  title: JSX.Element,
  menu: JSX.Element
) => ({
  icon,
  title,
  menu,
})

const getIconTitleActionDrawerMenuLine = (
  icon: { icon: JSX.Element; hoverText?: string },
  title: JSX.Element,
  action: { icon: JSX.Element; drawer: Array<any> },
  menu: JSX.Element
) => ({
  icon,
  title,
  action,
  menu,
})

const getLineActionLine = (
  id: string,
  title: JSX.Element,
  action: { icon: JSX.Element; bulkToAction: () => void }
) => ({
  id,
  title,
  action,
})

const getLine = (title: JSX.Element) => ({ title })

export {
  getIconTitleLine,
  generateIconTitleLine,
  getTitleInfoLine,
  getIconTitleInfoLine,
  getIconTitleActionLine,
  getIconTitleInfoActionLine,
  getIconTitleInfoActionDrawerLine,
  getIconTitleMenuLine,
  getIconTitleActionDrawerMenuLine,
  getLineActionLine,
  getLine,
}
