import { useTranslation } from 'react-i18next'

import { MovementState } from '.'
import { getFromInventoryMovement, getToInventoryMovement } from '../../../../services/catalog/inventory'
import { displayNumber } from '../../../../utils/number'
import { InventoryMovementState } from '../../../../API'
import UniqueDropDown from '../../../../components/newComponents/dropDown/unique'
import TextArea from '../../../../components/newComponents/textArea'
import { ComponentState, ComponentStatus } from '../../../../components/newComponents/types'
import generateDataCy from '../../../../utils/cypress'
import Input from '../../../../components/newComponents/input'
import SubmitButton from '../../../../components/oldComponents/drawer/submitButton'
import Chip from '../../../../components/newComponents/chip'
import { inventoryMoveStateToStr, inventoryMoveTypeToStr } from '../../../../utils/typeToType'
import { displayDateShortMonthWithHours } from '../../../../utils/date'

import styles from '../InventoryMove.module.css'
import stylesDrawer from '../../../../components/oldComponents/drawer/Drawer.module.css'
import { updateModal } from '../../../../actions/modal'
import { useDispatch } from 'react-redux'
import ExportList from '../../../../components/oldComponents/export'
import { objectType } from '../../../../store/types'

const Form = ({
  state,
  shops,
  openList,
  handleCancel,
  handleSubmit,
  dataCy,
}: {
  state: MovementState
  shops: Array<any>
  openList: () => void
  handleCancel: () => void
  handleSubmit: () => void
  dataCy: string
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const openModalExportList = (type: string) => {
    updateModal(
      dispatch,
      true,
      () => <ExportList type={type} inventoryId={state.id} />,
      () => { },
      "exportList"
    )
  }

  return (
    <>
      <div className={stylesDrawer.overflow}>
        <div className={stylesDrawer.form}>
          <div className={styles.containerCreate}>
            <div data-cy={generateDataCy({ scope: dataCy, value: 'createdAt' })}>
              {t('catalog.inventoryMovement.CREATED_AT', { date: displayDateShortMonthWithHours(state.createdAt!) })}
            </div>
            <div className={styles.quantityText} data-cy={generateDataCy({ scope: dataCy, value: 'quantity' })}>
              {displayNumber(state.quantity)} produit{state.quantity! > 1 ? 's' : ''}
            </div>
            <div data-cy={generateDataCy({ scope: dataCy, value: 'state' })}>
              {Chip(inventoryMoveStateToStr(state.brutState!, t))}
            </div>
          </div>
          <UniqueDropDown
            parentID={'selectType'}
            popUpID={'selectTypePopUp'}
            Element={
              <Input
                label={t('catalog.inventoryMovement.TYPE')}
                value={inventoryMoveTypeToStr(state.origin!, state.destination!, t).text}
                style={{
                  state: ComponentState.DISABLED,
                }}
                dataCy={generateDataCy({ scope: dataCy, value: 'type' })}
              />
            }
            state={ComponentState.DISABLED}
            dataCy={generateDataCy({ scope: dataCy, value: 'type' })}
          />
          {state.originID && (
            <UniqueDropDown
              parentID={'selectOrigin'}
              popUpID={'selectOriginPopUp'}
              Element={
                <Input
                  label={t('catalog.inventoryMovement.ORIGIN')}
                  value={getFromInventoryMovement(shops, state.origin!, state.originID!, t)}
                  style={{
                    state: ComponentState.DISABLED,
                  }}
                  dataCy={generateDataCy({ scope: dataCy, value: 'origin' })}
                />
              }
              state={ComponentState.DISABLED}
              dataCy={generateDataCy({ scope: dataCy, value: 'origin' })}
            />
          )}
          {state.destinationID && (
            <UniqueDropDown
              parentID={'selectDestination'}
              popUpID={'selectDestinationPopUp'}
              Element={
                <Input
                  label={t('catalog.inventoryMovement.DESTINATION')}
                  value={getToInventoryMovement(shops, state.destination!, state.destinationID!, t)}
                  style={{
                    state: ComponentState.DISABLED,
                  }}
                  dataCy={generateDataCy({ scope: dataCy, value: 'destination' })}
                />
              }
              state={ComponentState.DISABLED}
              dataCy={generateDataCy({ scope: dataCy, value: 'destination' })}
            />
          )}
          <TextArea
            label={t('catalog.inventoryMovement.MOTIVE')}
            value={state.motive}
            style={{ state: ComponentState.DISABLED, status: ComponentStatus.DEFAULT }}
            dataCy={generateDataCy({ scope: dataCy, value: 'motive' })}
          />
          <div
            className={stylesDrawer.linkToRequester}
            onClick={() => openModalExportList(objectType.INVENTORY_MOVEMENT)}
            data-cy={generateDataCy({ scope: dataCy, value: 'export' })}
          >
            {t('catalog.inventoryMovement.EXPORT_EXCEL')}
          </div>
          <div
            className={stylesDrawer.linkToRequester}
            onClick={openList}
            data-cy={generateDataCy({ scope: dataCy, value: 'quantities' })}
          >
            {state.brutState === InventoryMovementState.DRAFT
              ? t('catalog.inventoryMovement.EDIT_QUANTITIES')
              : t('catalog.inventoryMovement.SEE_QUANTITIES')}
          </div>
          {state.brutState === InventoryMovementState.DRAFT && (
            <div
              className={stylesDrawer.removeButton}
              onClick={handleCancel}
              data-cy={generateDataCy({ scope: dataCy, value: 'cancel' })}
            >
              {t('catalog.inventoryMovement.CANCEL')}
            </div>
          )}
        </div>
      </div>
      {state.brutState === InventoryMovementState.DRAFT && (
        <SubmitButton showSubmit handleSubmit={handleSubmit} text={t('button.VALIDATE_INVENTORY')} />
      )}
    </>
  )
}

export default Form
