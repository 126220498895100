/* eslint-disable no-use-before-define */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Loader, { displayLoader } from '../../newComponents/loader'
import Selection from '../stat/selection'
import ChartBar from '../stat/chartBar'
import { getDrawerState, interval, listShopsItemsNextToken, selectedCompany } from '../../../store/selector'
import { Stats } from '../../../types'
import { SelectOptions } from '../../newComponents/dropDown/multiple'
import { DataWithErrors } from '../../../services/types'
import { displayPrice } from '../../../utils/number'
import { getDateValues, getExplicitDate, getShopIDs, translateInterval, getDateValuesDayJs } from '../stat/utils'
import { updateDrawerState } from '../../../actions/drawer'
import { DrawerState, objectType } from '../../../store/types'
import { callService } from '../../../services'
import { endWeek, formatDate, startWeek } from '../../../utils/date'
import generateDataCy from '../../../utils/cypress'

import styles from './Drawer.module.css'
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs, locale } from 'dayjs';

const Statistic = ({
  id,
  variantID,
  name,
  query,
  queryName,
  type,
  close,
}: {
  id: string
  variantID?: string
  name: string
  query: string
  queryName: string
  type: objectType
  close: () => void
}) => {
  const { t } = useTranslation()
  const intervalChoosen = useSelector(interval)

  // console.log(intervalChoosen, "INTERVAL ")

  const initialState: Stats = {
    name,
    lastUsed: 'datePicker',
    datePicker: {
      date: {
        from: null,
        to: null,
      },
    },
    dropDown: {
      date: t('drawer.stat.WEEK'),
      interval: '1w',
      dates: [
        { id: 'day', value: t('drawer.stat.TODAY') },
        { id: 'week', value: t('drawer.stat.WEEK') },
        { id: 'month', value: t('drawer.stat.MONTH') },
        { id: 'year', value: t('drawer.stat.YEAR') },
      ],
    },
    shopInput: t('drawer.stat.ALL_SHOP'),
    shops: [],
    stats: [],
    histogram: [],
    interval: null,
  }
  const [state, setState] = useState(initialState)
  const company = useSelector(selectedCompany)
  const drawerState = useSelector(getDrawerState)
  const shops = useSelector(listShopsItemsNextToken)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'tab', value: 'performance' })


  const updateDate = ({ datePicker, dropDown }: { datePicker?: DateRange<Dayjs>; dropDown?: string | null }) => {
    if (dropDown) {
      //@ts-ignore
      state.dropDown.date = state.dropDown.dates.find((sDate) => sDate.id === dropDown)!.value
      const { startDate, endDate, newInterval: interval } = getDateValuesDayJs(dropDown)

      state.lastUsed = 'dropdown'
      callService(
        {
          id,
          startDate,
          endDate,
          interval,
          shopIDs: getShopIDs(state.shops),
        },
        query,
        queryName
      ).then(setElementInState(interval))
    } else if (datePicker) {
      //@ts-ignore
      state.datePicker.date.from = datePicker[0]
      //@ts-ignore
      state.datePicker.date.to = datePicker[1]
      state.lastUsed = 'datePicker'

      callService(
        {
          id,
          startDate: datePicker[0] ? dayjs(datePicker[0]).toISOString() : null,
          endDate: datePicker[1] ? dayjs(datePicker[1]).endOf('day').toISOString() : null,
          shopIDs: getShopIDs(state.shops),
        },
        query,
        queryName
      ).then(setElementInState(state.dropDown.interval))
    }
  }


  const setElementInState = (interval?: string) => (data: DataWithErrors) => {
    let stats = undefined

    if (variantID) {
      stats = data.data.variants.items.find((variant: { variantID: string }) => variant.variantID === variantID)
        ? data.data.variants.items.find((variant: { variantID: string }) => variant.variantID === variantID).stats
        : null
    } else { 
      stats = data.data.stats
    }

    if (stats) {
      setState({
        ...state,
        dropDown: {
          ...state.dropDown,
          interval: interval ?? state.dropDown.interval,
        },
        stats:
          type === objectType.DISCOUNT
            ? [
                {
                  id: 'amount',
                  text: t('catalog.discount.stat.amount.TITLE'),
                  value: `${stats.amount
                      ? displayPrice(stats.amount, company.currency, company.currencyDecimals).toString()
                      : '0'
                  }`,
                  hover: t('catalog.discount.stat.amount.SUBTITLE'),
                },
                {
                  id: 'quantity',
                  text: t('catalog.discount.stat.quantity.TITLE'),
                  value: `${stats.quantity ? stats.quantity.toString() : '0'}`,
                  hover: t('catalog.discount.stat.quantity.SUBTITLE'),
                },
              ]
            : [
                {
                  id: 'total',
                  text: t(`catalog.${type}.stat.total.TITLE`),
                  value: `${stats.totalsDetail
                      ? displayPrice(
                          stats.totalsDetail.totals.total,
                          company.currency,
                          company.currencyDecimals
                        ).toString()
                      : '0'
                  }`,
                },
                {
                  id: 'quantity',
                  text: t(`catalog.${type}.stat.quantity.TITLE`),
                  value: `${stats.quantity ? stats.quantity.toString() : '0'}`,
                  hover: t(`catalog.${type}.stat.quantity.SUBTITLE`),
                },
              ],
        histogram: stats.histogram.map((histo: any) => ({
          date: getExplicitDate(histo.id, '1d'),
          total: type === objectType.DISCOUNT ? histo.amount : histo.totalsDetail.totals.totalTaxFree,
        })),
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    if (id) {
      state.shops = shops.items.map((shop) => ({ id: shop.id, value: shop.name, checked: true }))
      // DatePicker
      const today = dayjs();
      state.datePicker.date = {
        //@ts-ignore
        from: today.startOf('week'),
        //@ts-ignore
        to: today.endOf('week'),
      }
      const { startDate, endDate, newInterval: interval } = getDateValuesDayJs('week')

      callService(
        {
          id,
          startDate,
          endDate,
          interval,
          variantID
        },
        query,
        queryName
      ).then(setElementInState(interval))
    }
      }, [id])

  const updateShops = (options: Array<SelectOptions>, value: string): void => {
    // Because it triggers the useEffect, shouldn't
    // updateDrawerState(DrawerState.LOADING, dispatch)
    state.shops = options
    state.shopInput = value

    const param =
      state.lastUsed === 'datePicker'
        ? {
            startDate: formatDate(state.datePicker.date.from!) + 'T00:00:00.000Z',
            endDate: formatDate(state.datePicker.date.to!) + 'T23:59:59.000Z',
          }
        : {
            startDate: getDateValues(translateInterval(state.dropDown.interval!)).startDate,
            endDate: getDateValues(translateInterval(state.dropDown.interval!)).endDate,
            interval: state.dropDown.interval,
          }

    callService(
      {
        id,
        ...param,
        shopIDs: getShopIDs(state.shops),
        variantID
      },
      query,
      queryName,
    ).then(setElementInState())

  }

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={styles.containerForm}
          style={{ height: `calc(100vh - (61px + var(--comp-tab-list-item-height-lg) + 10px))` }}
        >
          <div id="drawer" className={styles.overflow}>
            <div className={styles.form}>
              <Selection
                state={state}
                allShops={shops.items}
                updateState={setState}
                //@ts-ignore
                updateDate={updateDate}
                updateShops={updateShops}
                dataCy={generateDataCy({ scope: cyContext, value: 'selector' })}
              />
              <ChartBar state={state} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Statistic
