import { sortListElement } from '../../actions/list'
import { getDashboardStats as get, listPaymentMethods, listTaxes } from '../../graphql/custom/queries'
import { Action, objectType } from '../../store/types'
import { pluralize } from '../../utils/typeToType'
import { callService } from '../../services'
import { GetDashboardStatsQueryVariables, ListPaymentMethodsQueryVariables, ListTaxesQueryVariables } from '../../API'
import { getCompanyID } from '../../services/localStorage'

const getCategories = (categories: Array<any>, dispatch: any) => {
  const statCategories = categories.map((category: any) => ({
    name: category.name,
    totalQuantity: category.quantity,
    total: category.totals.total,
    totalTaxFree: category.totals.totalTaxFree,
    totalTax: category.totals.totalTax,
    margin: category.margin,
  }))

  dispatch({
    type: Action.FETCH_STAT_ITEM,
    payload: {
      items: statCategories,
      type: pluralize(objectType.STAT_CATEGORY),
    },
  })

  sortListElement(dispatch, 'name', 'asc', objectType.STAT_CATEGORY)
}

const getProducts = (products: Array<any>, dispatch: any) => {
  const statProducts = products.map((product) => ({
    name: product.name,
    totalQuantity: product.quantity,
    total: product.totals.total,
    totalTaxFree: product.totals.totalTaxFree,
    totalTax: product.totals.totalTax,
    margin: product.margin,
    variantsCount: product.variants && product.variants.length > 0 ? product.variants.length : 0,
    subValues:
      product.variants && product.variants.length > 0
        ? product.variants.map((pDecli: any) => ({
            name: pDecli.name,
            totalQuantity: pDecli.quantity,
            total: pDecli.totals.total,
            totalTaxFree: pDecli.totals.totalTaxFree,
            totalTax: pDecli.totals.totalTax,
            margin: pDecli.margin,
          }))
        : undefined,
  }))

  dispatch({
    type: Action.FETCH_STAT_ITEM,
    payload: {
      items: statProducts,
      type: pluralize(objectType.STAT_PRODUCT),
    },
  })

  sortListElement(dispatch, 'name', 'asc', objectType.STAT_PRODUCT)
}

const getPayments = (payments: Array<any>, totals: any, dispatch: any) => {
  callService<ListPaymentMethodsQueryVariables>(
    { companyID: getCompanyID(), test: false },
    listPaymentMethods,
    'listPaymentMethods'
  ).then((data) => {
    if (data.data) {
      const allPayments = data.data.items

      const statPayments = [
        ...payments.map((payment: any) => ({
          paymentMethod: payment.name,
          nbPositivePayments: payment.totals.nbPositivePayments,
          nbNegativePayments: payment.totals.nbNegativePayments,
          sellTotals: payment.totals.totalsDetail.sellTotals.total,
          totalRefund: payment.totals.totalsDetail.refundTotals.total,
          total: payment.totals.totalsDetail.totals.total,
        })),
        {
          paymentMethod: 'Total',
          nbPositivePayments: totals.nbPositivePayments,
          nbNegativePayments: totals.nbNegativePayments,
          sellTotals: totals.totalsDetail.sellTotals.total,
          totalRefund: totals.totalsDetail.refundTotals.total,
          total: totals.totalsDetail.totals.total,
          excludeFromSort: true,
        },
      ]

      const concatPayments = [
        ...statPayments,
        ...allPayments
          .filter(
            (payment: { name: string }) =>
              !statPayments
                // @ts-ignore
                .filter((sPayment) => !sPayment.excludeFromSort)
                .find((sPayment) => sPayment.paymentMethod === payment.name)
          )
          .map((payment: { name: string }) => ({
            paymentMethod: payment.name,
            nbPositivePayments: 0,
            nbNegativePayments: 0,
            sellTotals: 0,
            totalRefund: 0,
            total: 0,
          })),
      ]

      dispatch({
        type: Action.FETCH_STAT_ITEM,
        payload: {
          items: concatPayments,
          type: pluralize(objectType.STAT_PAYMENT),
        },
      })

      sortListElement(dispatch, 'paymentMethod', 'asc', objectType.STAT_PAYMENT)
    }
  })
}

const getTaxes = (taxes: Array<any>, dispatch: any) => {
  callService<ListTaxesQueryVariables>({ catalogID: getCompanyID(), test: false }, listTaxes, 'listTaxes').then(
    (data) => {
      if (data.data) {
        const allTaxes = data.data.items

        const statTaxes = taxes.map((tax: any) => ({
          rate: parseInt(tax.id, 10),
          total: tax.totals.total,
          totalTaxFree: tax.totals.totalTaxFree,
          totalTax: tax.totals.totalTax,
        }))

        const concatTaxes = [
          ...statTaxes,
          ...allTaxes
            .filter((tax: { rate: number }) => !statTaxes.find((sTax) => sTax.rate === tax.rate))
            .map((tax: { rate: number }) => ({ rate: tax.rate, total: 0, totalTaxFree: 0, totalTax: 0 })),
        ]

        dispatch({
          type: Action.FETCH_STAT_ITEM,
          payload: {
            items: concatTaxes,
            type: pluralize(objectType.STAT_TAX),
          },
        })

        sortListElement(dispatch, 'rate', 'asc', objectType.STAT_TAX)
      }
    }
  )
}

const getDashboardStats = async (
  dispatch: any,
  payload?: { shopIDs?: Array<string>; startDate?: string; endDate?: string }
) => {
  const stats = payload
    ? await callService<GetDashboardStatsQueryVariables>(
        {
          test: false,
          companyID: getCompanyID(),
          shopIDs: payload.shopIDs,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
        get,
        'getDashboardStats'
      )
    : await callService<GetDashboardStatsQueryVariables>(
        { test: false, companyID: getCompanyID() },
        get,
        'getDashboardStats'
      )

  if (stats.data) {
    getPayments(stats.data.content.payments.payments, stats.data.content.payments.totals, dispatch)
    getTaxes(stats.data.content.taxes.taxes, dispatch)
    getProducts(stats.data.content.products.products, dispatch)
    getCategories(stats.data.content.categories.categories, dispatch)
  }
  return stats
}

export default getDashboardStats
