const ForgotPassword = () => (
  <svg width="450" height="334" viewBox="0 0 450 334" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.2656 74.5146H84.8425V92.4506H74.4023V97.8866H187.256V92.4506H175.901V74.5146H162.478V92.4506H156.492V74.5146H143.069V92.4506H137.083V74.5146H123.66V92.4506H117.674V74.5146H104.251V92.4506H98.2656V74.5146Z"
      fill="#DFEDFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.2656 74.5146H84.8425V92.4506H74.4023V97.8866H187.256V92.4506H175.901V74.5146H162.478V92.4506H156.492V74.5146H143.069V92.4506H137.083V74.5146H123.66V92.4506H117.674V74.5146H104.251V92.4506H98.2656V74.5146Z"
      fill="black"
      fillOpacity="0.05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.2656 74.5146H84.8425V92.4506H74.4023V97.8866H187.256V92.4506H175.901V74.5146H162.478V92.4506H156.492V74.5146H143.069V92.4506H137.083V74.5146H123.66V92.4506H117.674V74.5146H104.251V92.4506H98.2656V74.5146Z"
      fill="url(#pattern0)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M254.391 64.6318H240.968V82.5678H230.527V88.0038H343.381V82.5678H332.026V64.6318H318.603V82.5678H312.617V64.6318H299.194V82.5678H293.208V64.6318H279.785V82.5678H273.799V64.6318H260.376V82.5678H254.391V64.6318Z"
      fill="#DFEDFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M254.391 64.6318H240.968V82.5678H230.527V88.0038H343.381V82.5678H332.026V64.6318H318.603V82.5678H312.617V64.6318H299.194V82.5678H293.208V64.6318H279.785V82.5678H273.799V64.6318H260.376V82.5678H254.391V64.6318Z"
      fill="black"
      fillOpacity="0.05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M254.391 64.6318H240.968V82.5678H230.527V88.0038H343.381V82.5678H332.026V64.6318H318.603V82.5678H312.617V64.6318H299.194V82.5678H293.208V64.6318H279.785V82.5678H273.799V64.6318H260.376V82.5678H254.391V64.6318Z"
      fill="url(#pattern1)"
      fillOpacity="0.02"
    />
    <path
      d="M224.824 306.232C326.347 306.232 408.648 300.671 408.648 293.811C408.648 286.951 326.347 281.391 224.824 281.391C123.301 281.391 41 286.951 41 293.811C41 300.671 123.301 306.232 224.824 306.232Z"
      fill="#DFEDFA"
    />
    <path
      d="M224.824 306.232C326.347 306.232 408.648 300.671 408.648 293.811C408.648 286.951 326.347 281.391 224.824 281.391C123.301 281.391 41 286.951 41 293.811C41 300.671 123.301 306.232 224.824 306.232Z"
      fill="url(#pattern2)"
      fillOpacity="0.02"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M60.7109 148.381H335.354V293.474H60.7109V148.381Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.7109 148.381H335.354V293.474H60.7109V148.381Z"
      fill="url(#pattern3)"
      fillOpacity="0.02"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M335.383 148.342H390.311V293.435H335.383V148.342Z" fill="#F1F1F1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M335.383 148.342H390.311V293.435H335.383V148.342Z"
      fill="url(#pattern4)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.7402 147.792H390.311V156.036H60.7402V147.792Z"
      fill="url(#paint0_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.7402 147.792H390.311V156.036H60.7402V147.792Z"
      fill="url(#pattern5)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M335.383 147.792H390.311V156.036H335.383V147.792Z"
      fill="url(#paint1_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M335.383 147.792H390.311V156.036H335.383V147.792Z"
      fill="url(#pattern6)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M329.51 98.3132V122.121C329.51 123.241 328.588 124.15 327.45 124.15H290.512C289.374 124.15 288.452 123.241 288.452 122.121V98.3132C288.452 97.1926 289.374 96.2842 290.512 96.2842H327.45C328.588 96.2842 329.51 97.1926 329.51 98.3132Z"
      fill="#17171D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M329.51 98.3132V122.121C329.51 123.241 328.588 124.15 327.45 124.15H290.512C289.374 124.15 288.452 123.241 288.452 122.121V98.3132C288.452 97.1926 289.374 96.2842 290.512 96.2842H327.45C328.588 96.2842 329.51 97.1926 329.51 98.3132Z"
      fill="url(#pattern7)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M329.51 122.121V98.3132C329.51 97.1926 328.588 96.2842 327.45 96.2842H290.512C289.374 96.2842 288.452 97.1926 288.452 98.3132V122.121C288.452 123.241 289.374 124.15 290.512 124.15H327.45C328.588 124.15 329.51 123.241 329.51 122.121ZM327.45 96.5547C328.436 96.5547 329.235 97.342 329.235 98.3132V122.121C329.235 123.092 328.436 123.879 327.45 123.879H290.512C289.526 123.879 288.727 123.092 288.727 122.121V98.3132C288.727 97.342 289.526 96.5547 290.512 96.5547H327.45Z"
      fill="#777777"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M325.645 97.3896V123.477H292.978V97.3896H325.645Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M325.645 97.3896V123.477H300.47V97.3896H325.645Z" fill="#EEEFEF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M324.264 100.994C324.264 101.293 324.021 101.535 323.722 101.535H321.252C320.953 101.535 320.711 101.293 320.711 100.994V98.4756C320.711 98.1767 320.953 97.9343 321.252 97.9343H323.722C324.021 97.9343 324.264 98.1767 324.264 98.4756V100.994Z"
      fill="#1E8CFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M319.295 100.994C319.295 101.293 319.053 101.535 318.754 101.535H316.283C315.984 101.535 315.742 101.293 315.742 100.994V98.4756C315.742 98.1767 315.984 97.9343 316.283 97.9343H318.754C319.053 97.9343 319.295 98.1767 319.295 98.4756V100.994Z"
      fill="#F44F60"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M309.91 100.994C309.91 101.293 309.668 101.535 309.369 101.535H306.899C306.6 101.535 306.357 101.293 306.357 100.994V98.4756C306.357 98.1767 306.6 97.9343 306.899 97.9343H309.369C309.668 97.9343 309.91 98.1767 309.91 98.4756V100.994Z"
      fill="#6868DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M314.326 100.994C314.326 101.293 314.084 101.535 313.785 101.535H311.315C311.016 101.535 310.773 101.293 310.773 100.994V98.4756C310.773 98.1767 311.016 97.9343 311.315 97.9343H313.785C314.084 97.9343 314.326 98.1767 314.326 98.4756V100.994Z"
      fill="#30BEA5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M304.941 100.994C304.941 101.293 304.699 101.535 304.4 101.535H301.93C301.631 101.535 301.389 101.293 301.389 100.994V98.4756C301.389 98.1767 301.631 97.9343 301.93 97.9343H304.4C304.699 97.9343 304.941 98.1767 304.941 98.4756V100.994Z"
      fill="#57BD47"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M324.264 105.969C324.264 106.268 324.021 106.511 323.722 106.511H321.252C320.953 106.511 320.711 106.268 320.711 105.969V103.451C320.711 103.152 320.953 102.91 321.252 102.91H323.722C324.021 102.91 324.264 103.152 324.264 103.451V105.969Z"
      fill="#9EA09E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M319.295 105.969C319.295 106.268 319.053 106.511 318.754 106.511H316.283C315.984 106.511 315.742 106.268 315.742 105.969V103.451C315.742 103.152 315.984 102.91 316.283 102.91H318.754C319.053 102.91 319.295 103.152 319.295 103.451V105.969Z"
      fill="#A06E58"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M309.91 105.969C309.91 106.268 309.668 106.511 309.369 106.511H306.899C306.6 106.511 306.357 106.268 306.357 105.969V103.451C306.357 103.152 306.6 102.91 306.899 102.91H309.369C309.668 102.91 309.91 103.152 309.91 103.451V105.969Z"
      fill="#B455C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M314.326 105.969C314.326 106.268 314.084 106.511 313.785 106.511H311.315C311.016 106.511 310.773 106.268 310.773 105.969V103.451C310.773 103.152 311.016 102.91 311.315 102.91H313.785C314.084 102.91 314.326 103.152 314.326 103.451V105.969Z"
      fill="#FF6347"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M304.941 105.969C304.941 106.268 304.699 106.511 304.4 106.511H301.93C301.631 106.511 301.389 106.268 301.389 105.969V103.451C301.389 103.152 301.631 102.91 301.93 102.91H304.4C304.699 102.91 304.941 103.152 304.941 103.451V105.969Z"
      fill="#F2BA43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M321.007 110.934H323.968C324.131 110.934 324.264 110.799 324.264 110.634V107.633C324.264 107.467 324.131 107.333 323.968 107.333H321.007C320.843 107.333 320.711 107.467 320.711 107.633V110.634C320.711 110.799 320.843 110.934 321.007 110.934ZM324.116 110.634C324.116 110.716 324.049 110.784 323.968 110.784H321.007C320.925 110.784 320.859 110.716 320.859 110.634V107.633C320.859 107.55 320.925 107.483 321.007 107.483H323.968C324.049 107.483 324.116 107.55 324.116 107.633V110.634Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M316.038 110.934H318.999C319.162 110.934 319.295 110.799 319.295 110.634V107.633C319.295 107.467 319.162 107.333 318.999 107.333H316.038C315.875 107.333 315.742 107.467 315.742 107.633V110.634C315.742 110.799 315.875 110.934 316.038 110.934ZM319.147 110.634C319.147 110.716 319.081 110.784 318.999 110.784H316.038C315.956 110.784 315.89 110.716 315.89 110.634V107.633C315.89 107.55 315.956 107.483 316.038 107.483H318.999C319.081 107.483 319.147 107.55 319.147 107.633V110.634Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M306.653 110.934H309.614C309.778 110.934 309.91 110.799 309.91 110.634V107.633C309.91 107.467 309.778 107.333 309.614 107.333H306.653C306.49 107.333 306.357 107.467 306.357 107.633V110.634C306.357 110.799 306.49 110.934 306.653 110.934ZM309.762 110.634C309.762 110.716 309.696 110.784 309.614 110.784H306.653C306.572 110.784 306.505 110.716 306.505 110.634V107.633C306.505 107.55 306.572 107.483 306.653 107.483H309.614C309.696 107.483 309.762 107.55 309.762 107.633V110.634Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M311.069 110.934H314.03C314.194 110.934 314.326 110.799 314.326 110.634V107.633C314.326 107.467 314.194 107.333 314.03 107.333H311.069C310.906 107.333 310.773 107.467 310.773 107.633V110.634C310.773 110.799 310.906 110.934 311.069 110.934ZM314.178 110.634C314.178 110.716 314.112 110.784 314.03 110.784H311.069C310.988 110.784 310.921 110.716 310.921 110.634V107.633C310.921 107.55 310.988 107.483 311.069 107.483H314.03C314.112 107.483 314.178 107.55 314.178 107.633V110.634Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M304.941 110.392C304.941 110.691 304.699 110.934 304.4 110.934H301.93C301.631 110.934 301.389 110.691 301.389 110.392V107.874C301.389 107.575 301.631 107.333 301.93 107.333H304.4C304.699 107.333 304.941 107.575 304.941 107.874V110.392Z"
      fill="#1E8CFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M321.007 115.908H323.968C324.131 115.908 324.264 115.774 324.264 115.608V112.607C324.264 112.442 324.131 112.307 323.968 112.307H321.007C320.843 112.307 320.711 112.442 320.711 112.607V115.608C320.711 115.774 320.843 115.908 321.007 115.908ZM324.116 115.608C324.116 115.691 324.049 115.758 323.968 115.758H321.007C320.925 115.758 320.859 115.691 320.859 115.608V112.607C320.859 112.525 320.925 112.457 321.007 112.457H323.968C324.049 112.457 324.116 112.525 324.116 112.607V115.608Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M316.038 115.908H318.999C319.162 115.908 319.295 115.774 319.295 115.608V112.607C319.295 112.442 319.162 112.307 318.999 112.307H316.038C315.875 112.307 315.742 112.442 315.742 112.607V115.608C315.742 115.774 315.875 115.908 316.038 115.908ZM319.147 115.608C319.147 115.691 319.081 115.758 318.999 115.758H316.038C315.956 115.758 315.89 115.691 315.89 115.608V112.607C315.89 112.525 315.956 112.457 316.038 112.457H318.999C319.081 112.457 319.147 112.525 319.147 112.607V115.608Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M306.653 115.908H309.614C309.778 115.908 309.91 115.774 309.91 115.608V112.607C309.91 112.442 309.778 112.307 309.614 112.307H306.653C306.49 112.307 306.357 112.442 306.357 112.607V115.608C306.357 115.774 306.49 115.908 306.653 115.908ZM309.762 115.608C309.762 115.691 309.696 115.758 309.614 115.758H306.653C306.572 115.758 306.505 115.691 306.505 115.608V112.607C306.505 112.525 306.572 112.457 306.653 112.457H309.614C309.696 112.457 309.762 112.525 309.762 112.607V115.608Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M311.069 115.908H314.03C314.194 115.908 314.326 115.774 314.326 115.608V112.607C314.326 112.442 314.194 112.307 314.03 112.307H311.069C310.906 112.307 310.773 112.442 310.773 112.607V115.608C310.773 115.774 310.906 115.908 311.069 115.908ZM314.178 115.608C314.178 115.691 314.112 115.758 314.03 115.758H311.069C310.988 115.758 310.921 115.691 310.921 115.608V112.607C310.921 112.525 310.988 112.457 311.069 112.457H314.03C314.112 112.457 314.178 112.525 314.178 112.607V115.608Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M301.685 115.908H304.645C304.809 115.908 304.941 115.774 304.941 115.608V112.607C304.941 112.442 304.809 112.307 304.645 112.307H301.685C301.521 112.307 301.389 112.442 301.389 112.607V115.608C301.389 115.774 301.521 115.908 301.685 115.908ZM304.793 115.608C304.793 115.691 304.727 115.758 304.645 115.758H301.685C301.603 115.758 301.537 115.691 301.537 115.608V112.607C301.537 112.525 301.603 112.457 301.685 112.457H304.645C304.727 112.457 304.793 112.525 304.793 112.607V115.608Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M324.264 119.79C324.264 120.089 324.021 120.331 323.722 120.331H321.252C320.953 120.331 320.711 120.089 320.711 119.79V117.272C320.711 116.973 320.953 116.73 321.252 116.73H323.722C324.021 116.73 324.264 116.973 324.264 117.272V119.79Z"
      fill="#F2BA43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M319.295 119.79C319.295 120.089 319.053 120.331 318.754 120.331H316.283C315.984 120.331 315.742 120.089 315.742 119.79V117.272C315.742 116.973 315.984 116.73 316.283 116.73H318.754C319.053 116.73 319.295 116.973 319.295 117.272V119.79Z"
      fill="#30BEA5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M306.653 120.331H309.614C309.778 120.331 309.91 120.197 309.91 120.031V117.03C309.91 116.865 309.778 116.73 309.614 116.73H306.653C306.49 116.73 306.357 116.865 306.357 117.03V120.031C306.357 120.197 306.49 120.331 306.653 120.331ZM309.762 120.031C309.762 120.114 309.696 120.181 309.614 120.181H306.653C306.572 120.181 306.505 120.114 306.505 120.031V117.03C306.505 116.947 306.572 116.88 306.653 116.88H309.614C309.696 116.88 309.762 116.947 309.762 117.03V120.031Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M311.069 120.331H314.03C314.194 120.331 314.326 120.197 314.326 120.031V117.03C314.326 116.865 314.194 116.73 314.03 116.73H311.069C310.906 116.73 310.773 116.865 310.773 117.03V120.031C310.773 120.197 310.906 120.331 311.069 120.331ZM314.178 120.031C314.178 120.114 314.112 120.181 314.03 120.181H311.069C310.988 120.181 310.921 120.114 310.921 120.031V117.03C310.921 116.947 310.988 116.88 311.069 116.88H314.03C314.112 116.88 314.178 116.947 314.178 117.03V120.031Z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M304.941 119.79C304.941 120.089 304.699 120.331 304.4 120.331H301.93C301.631 120.331 301.389 120.089 301.389 119.79V117.272C301.389 116.973 301.631 116.73 301.93 116.73H304.4C304.699 116.73 304.941 116.973 304.941 117.272V119.79Z"
      fill="#F44F60"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M300.248 97.3896V123.477H292.756V97.3896H300.248Z" fill="white" />
    <mask id="mask0_912_1812" maskUnits="userSpaceOnUse" x="292" y="97" width="9" height="27">
      <path fillRule="evenodd" clipRule="evenodd" d="M300.248 97.3896V123.477H292.756V97.3896H300.248Z" fill="white" />
    </mask>
    <g mask="url(#mask0_912_1812)">
      <path fillRule="evenodd" clipRule="evenodd" d="M299.145 99.0479H294.111V98.746H299.145V99.0479Z" fill="#404040" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.145 101.259H294.111V100.957H299.145V101.259Z"
        fill="#404040"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.145 104.576H294.111V104.274H299.145V104.576Z"
        fill="#57BD47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.346 102.163H294.313V102.013H299.346V102.163Z"
        fill="#404040"
      />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M300.248 121.16V123.532H292.756V121.16H300.248Z" fill="#57BD47" />
    <path fillRule="evenodd" clipRule="evenodd" d="M325.645 121.16V123.532H300.47V121.16H325.645Z" fill="#3A3946" />
    <mask id="mask1_912_1812" maskUnits="userSpaceOnUse" x="300" y="121" width="26" height="3">
      <path fillRule="evenodd" clipRule="evenodd" d="M325.645 121.16V123.532H300.47V121.16H325.645Z" fill="white" />
    </mask>
    <g mask="url(#mask1_912_1812)" />
    <path
      d="M327.557 110.517C327.556 110.517 327.555 110.517 327.554 110.517C327.553 110.517 327.552 110.517 327.551 110.517C327.49 110.513 327.441 110.463 327.441 110.401C327.441 110.339 327.49 110.288 327.551 110.285M327.557 110.517L327.557 110.517L327.557 110.517ZM327.557 110.517C327.618 110.513 327.667 110.463 327.667 110.401C327.667 110.339 327.618 110.288 327.557 110.285C327.556 110.285 327.555 110.285 327.554 110.285C327.553 110.285 327.552 110.285 327.551 110.285M327.551 110.285V110.401V110.285Z"
      fill="url(#paint2_linear_912_1812)"
      stroke="url(#paint3_linear_912_1812)"
      strokeWidth="0.825"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M290.618 108.999C289.873 108.999 289.27 109.596 289.27 110.333C289.27 111.07 289.873 111.667 290.618 111.667C291.363 111.667 291.967 111.07 291.967 110.333C291.967 109.596 291.363 108.999 290.618 108.999ZM290.618 111.527C289.952 111.527 289.412 110.992 289.412 110.333C289.412 109.674 289.952 109.139 290.618 109.139C291.285 109.139 291.825 109.674 291.825 110.333C291.825 110.992 291.285 111.527 290.618 111.527Z"
      fill="#444444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M337.791 124.477H346.708C347.446 124.477 348.044 125.075 348.044 125.813V141.826C348.044 142.564 347.446 143.163 346.708 143.163H337.791V124.477Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M337.791 124.477H346.708C347.446 124.477 348.044 125.075 348.044 125.813V141.826C348.044 142.564 347.446 143.163 346.708 143.163H337.791V124.477Z"
      fill="url(#pattern8)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M276.508 125.813C276.508 125.075 277.106 124.477 277.844 124.477H337.424C338.162 124.477 338.76 125.075 338.76 125.813V141.826C338.76 142.564 338.162 143.163 337.424 143.163H277.844C277.106 143.163 276.508 142.564 276.508 141.826V125.813Z"
      fill="url(#paint4_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M276.508 125.813C276.508 125.075 277.106 124.477 277.844 124.477H337.424C338.162 124.477 338.76 125.075 338.76 125.813V141.826C338.76 142.564 338.162 143.163 337.424 143.163H277.844C277.106 143.163 276.508 142.564 276.508 141.826V125.813Z"
      fill="url(#pattern9)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M279.268 142.719H345.914V146.868C345.914 147.466 345.429 147.951 344.832 147.951H280.35C279.752 147.951 279.268 147.466 279.268 146.868V142.719Z"
      fill="#B8BDC1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M279.268 142.719H345.914V146.868C345.914 147.466 345.429 147.951 344.832 147.951H280.35C279.752 147.951 279.268 147.466 279.268 146.868V142.719Z"
      fill="url(#pattern10)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M336.688 142.719H346.208V146.868C346.208 147.466 345.724 147.951 345.126 147.951H336.688V142.719Z"
      fill="#939393"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M336.688 142.719H346.208V146.868C346.208 147.466 345.724 147.951 345.126 147.951H336.688V142.719Z"
      fill="url(#pattern11)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M299.144 126.688H276.508V127.24H299.144V142.166H299.696V127.24H342.76V142.166H343.312L343.312 127.24V126.688H342.76H299.696H299.144Z"
      fill="black"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M280.924 130.558H294.863V131.772H280.924V130.558Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.2036 108.778C88.911 107.758 89.5011 106.693 90.5215 106.401L91.3131 106.174C92.3335 105.881 93.398 106.471 93.6906 107.492L99.0423 126.155C99.3349 127.176 98.7448 128.24 97.7244 128.533L96.9328 128.76C95.9124 129.052 94.8479 128.462 94.5553 127.442L89.2036 108.778Z"
      fill="url(#paint5_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.2036 108.778C88.911 107.758 89.5011 106.693 90.5215 106.401L91.3131 106.174C92.3335 105.881 93.398 106.471 93.6906 107.492L99.0423 126.155C99.3349 127.176 98.7448 128.24 97.7244 128.533L96.9328 128.76C95.9124 129.052 94.8479 128.462 94.5553 127.442L89.2036 108.778Z"
      fill="url(#pattern12)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.381 116.739C84.8502 115.82 85.1652 114.644 86.0846 114.113L86.7977 113.701C87.7171 113.171 88.8926 113.486 89.4234 114.405L99.1312 131.219C99.662 132.139 99.347 133.314 98.4276 133.845L97.7145 134.257C96.7951 134.788 95.6196 134.473 95.0888 133.553L85.381 116.739Z"
      fill="url(#paint6_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.381 116.739C84.8502 115.82 85.1652 114.644 86.0846 114.113L86.7977 113.701C87.7171 113.171 88.8926 113.486 89.4234 114.405L99.1312 131.219C99.662 132.139 99.347 133.314 98.4276 133.845L97.7145 134.257C96.7951 134.788 95.6196 134.473 95.0888 133.553L85.381 116.739Z"
      fill="url(#pattern13)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.464 108.613C101.501 107.552 102.391 106.722 103.452 106.759L104.275 106.788C105.336 106.825 106.166 107.715 106.129 108.776L105.452 128.18C105.414 129.241 104.524 130.071 103.463 130.034L102.64 130.005C101.58 129.968 100.75 129.078 100.787 128.017L101.464 108.613Z"
      fill="url(#paint7_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.464 108.613C101.501 107.552 102.391 106.722 103.452 106.759L104.275 106.788C105.336 106.825 106.166 107.715 106.129 108.776L105.452 128.18C105.414 129.241 104.524 130.071 103.463 130.034L102.64 130.005C101.58 129.968 100.75 129.078 100.787 128.017L101.464 108.613Z"
      fill="url(#pattern14)"
      fillOpacity="0.02"
    />
    <rect
      x="95.1074"
      y="102.431"
      width="4.66779"
      height="26.051"
      rx="1.92217"
      transform="rotate(-6 95.1074 102.431)"
      fill="url(#paint8_linear_912_1812)"
    />
    <rect
      x="95.1074"
      y="102.431"
      width="4.66779"
      height="26.051"
      rx="1.92217"
      transform="rotate(-6 95.1074 102.431)"
      fill="url(#pattern15)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.463 108.253C111.574 107.198 110.808 106.252 109.752 106.141L108.933 106.055C107.878 105.944 106.932 106.71 106.821 107.766L104.791 127.075C104.68 128.13 105.446 129.076 106.502 129.187L107.321 129.273C108.377 129.384 109.323 128.618 109.434 127.563L111.463 108.253Z"
      fill="url(#paint9_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.463 108.253C111.574 107.198 110.808 106.252 109.752 106.141L108.933 106.055C107.878 105.944 106.932 106.71 106.821 107.766L104.791 127.075C104.68 128.13 105.446 129.076 106.502 129.187L107.321 129.273C108.377 129.384 109.323 128.618 109.434 127.563L111.463 108.253Z"
      fill="url(#pattern16)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.879 107.482C113.136 106.452 114.179 105.825 115.209 106.082L116.008 106.281C117.038 106.538 117.665 107.581 117.408 108.611L112.711 127.45C112.454 128.48 111.411 129.107 110.381 128.85L109.582 128.651C108.552 128.394 107.925 127.351 108.182 126.321L112.879 107.482Z"
      fill="url(#paint10_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.879 107.482C113.136 106.452 114.179 105.825 115.209 106.082L116.008 106.281C117.038 106.538 117.665 107.581 117.408 108.611L112.711 127.45C112.454 128.48 111.411 129.107 110.381 128.85L109.582 128.651C108.552 128.394 107.925 127.351 108.182 126.321L112.879 107.482Z"
      fill="url(#pattern17)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.493 109.905C123.959 108.951 123.562 107.8 122.608 107.335L121.868 106.974C120.914 106.509 119.763 106.905 119.298 107.859L109.563 127.818C109.098 128.773 109.494 129.923 110.448 130.389L111.188 130.75C112.142 131.215 113.293 130.819 113.759 129.865L123.493 109.905Z"
      fill="url(#paint11_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.493 109.905C123.959 108.951 123.562 107.8 122.608 107.335L121.868 106.974C120.914 106.509 119.763 106.905 119.298 107.859L109.563 127.818C109.098 128.773 109.494 129.923 110.448 130.389L111.188 130.75C112.142 131.215 113.293 130.819 113.759 129.865L123.493 109.905Z"
      fill="url(#pattern18)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.797 126.306H102.059L99.2586 147.705H116.996L119.797 126.306Z"
      fill="url(#paint12_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.797 126.306H102.059L99.2586 147.705H116.996L119.797 126.306Z"
      fill="url(#pattern19)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.1211 126.306H104.859L107.659 147.705H89.9218L87.1211 126.306Z"
      fill="url(#paint13_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.1211 126.306H104.859L107.659 147.705H89.9218L87.1211 126.306Z"
      fill="url(#pattern20)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235.267 45.8008H215.234C183.978 45.8008 158.641 71.1197 158.641 102.352V157.903C158.641 158.456 159.089 158.904 159.642 158.904H290.859C291.413 158.904 291.861 158.456 291.861 157.903V102.352C291.861 71.1197 266.523 45.8008 235.267 45.8008ZM280.843 147.894H169.659V102.352C169.659 77.2004 190.064 56.8108 215.234 56.8108H235.267C260.438 56.8108 280.843 77.2004 280.843 102.352V147.894Z"
      fill="url(#paint14_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235.267 45.8008H215.234C183.978 45.8008 158.641 71.1197 158.641 102.352V157.903C158.641 158.456 159.089 158.904 159.642 158.904H290.859C291.413 158.904 291.861 158.456 291.861 157.903V102.352C291.861 71.1197 266.523 45.8008 235.267 45.8008ZM280.843 147.894H169.659V102.352C169.659 77.2004 190.064 56.8108 215.234 56.8108H235.267C260.438 56.8108 280.843 77.2004 280.843 102.352V147.894Z"
      fill="url(#pattern21)"
      fillOpacity="0.02"
    />
    <path
      d="M268.822 128.876H298.495C306.448 128.876 312.895 135.323 312.895 143.276V247.597C312.895 255.55 306.448 261.997 298.495 261.997H268.822V128.876Z"
      fill="url(#paint15_linear_912_1812)"
    />
    <path
      d="M268.822 128.876H298.495C306.448 128.876 312.895 135.323 312.895 143.276V247.597C312.895 255.55 306.448 261.997 298.495 261.997H268.822V128.876Z"
      fill="url(#pattern22)"
      fillOpacity="0.02"
    />
    <rect x="137.605" y="128.876" width="155.257" height="133.121" rx="14.4" fill="url(#paint16_linear_912_1812)" />
    <rect
      x="137.605"
      y="128.876"
      width="155.257"
      height="133.121"
      rx="14.4"
      fill="url(#pattern23)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M224.847 197.164C229.993 194.347 233.431 189.246 233.431 183.419C233.431 174.548 225.458 167.355 215.624 167.355C205.789 167.355 197.816 174.548 197.816 183.419C197.816 189.246 201.255 194.347 206.401 197.164L202.305 225.186H228.942L224.847 197.164Z"
      fill="url(#paint17_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M224.847 197.164C229.993 194.347 233.431 189.246 233.431 183.419C233.431 174.548 225.458 167.355 215.624 167.355C205.789 167.355 197.816 174.548 197.816 183.419C197.816 189.246 201.255 194.347 206.401 197.164L202.305 225.186H228.942L224.847 197.164Z"
      fill="black"
      fillOpacity="0.15"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M224.847 197.164C229.993 194.347 233.431 189.246 233.431 183.419C233.431 174.548 225.458 167.355 215.624 167.355C205.789 167.355 197.816 174.548 197.816 183.419C197.816 189.246 201.255 194.347 206.401 197.164L202.305 225.186H228.942L224.847 197.164Z"
      fill="url(#pattern24)"
      fillOpacity="0.02"
    />
    <path
      d="M145.121 322.378C116.539 322.378 93.3684 319.388 93.3684 315.7C93.3684 312.012 116.539 309.022 145.121 309.022C173.703 309.022 196.873 312.012 196.873 315.7C196.873 319.388 173.703 322.378 145.121 322.378Z"
      fill="#DFEDFA"
    />
    <path
      d="M145.121 322.378C116.539 322.378 93.3684 319.388 93.3684 315.7C93.3684 312.012 116.539 309.022 145.121 309.022C173.703 309.022 196.873 312.012 196.873 315.7C196.873 319.388 173.703 322.378 145.121 322.378Z"
      fill="url(#pattern25)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.011 150.432C175.533 151.949 177.72 154.315 184.211 156.621C186.19 157.751 200.769 164.622 201.981 166.224C204.061 168.961 206.393 172.824 197.46 172.636C191.102 170.892 175.917 163.904 175.917 163.904C160.745 157.74 158.944 158.693 159.325 155.265C158.939 151.801 155.734 122.735 156.799 119.904C161.727 119.65 165.704 118.665 168.726 116.944L171.011 150.432Z"
      fill="#FEC2BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M201.197 157.643C201.197 156.867 200.718 156.17 199.993 155.892L190.11 152.105C188.883 151.635 187.564 152.541 187.564 153.856V181.837C187.564 183.152 188.883 184.059 190.11 183.588L199.993 179.801C200.718 179.523 201.197 178.827 201.197 178.05V157.643Z"
      fill="#E1E1E1"
    />
    <path
      d="M201.274 172.374C202.176 172.192 202.218 171.545 202.218 171.176C202.218 170.807 201.61 171.264 200.503 171.264C199.397 171.264 198.299 171.093 198.299 171.462C198.299 171.83 200.371 172.556 201.274 172.374Z"
      fill="#FEC2BE"
    />
    <path
      d="M200.878 170.794C201.258 170.794 202.061 171.231 202.363 171.17C202.942 171.053 202.882 170.368 202.882 170.126C202.882 169.757 201.985 169.458 200.878 169.458C199.772 169.458 198.211 169.795 198.211 170.164C198.211 170.533 199.772 170.794 200.878 170.794Z"
      fill="#FEC2BE"
    />
    <path
      d="M200.586 168.791C200.992 168.791 201.452 169.51 201.768 169.555C202.312 169.632 202.59 169.024 202.59 168.791C202.59 168.422 201.693 167.455 200.586 167.455C199.48 167.455 198.213 167.859 198.213 168.228C198.213 168.597 199.48 168.791 200.586 168.791Z"
      fill="#FEC2BE"
    />
    <path
      d="M200.398 166.787C200.728 166.787 201.338 167.388 201.613 167.424C202.257 167.509 202.401 167.045 202.401 166.787C202.401 166.543 202.008 165.93 201.422 165.616C201.122 165.455 200.772 165.451 200.398 165.451C199.291 165.451 198.213 165.986 198.213 166.355C198.213 166.723 199.291 166.787 200.398 166.787Z"
      fill="#FEC2BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M194.906 170.675C194.327 170.675 193.858 169.506 193.858 168.063C193.858 166.621 194.327 165.451 194.906 165.451C195.486 165.451 195.955 166.621 195.955 168.063C195.955 169.506 195.486 170.675 194.906 170.675Z"
      fill="#6E6F6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.63 99.6238C130.63 99.6238 130.545 109.648 137.742 109.13C148.546 108.353 147.376 104.883 147.376 104.883C148.134 104.456 150.938 99.5882 149.081 98.9265C147.473 98.3531 147.545 95.7478 147.409 90.7423C146.867 90.4883 133.236 85.9402 131.548 86.626C132.864 97.1291 130.63 99.6238 130.63 99.6238Z"
      fill="#FEC2BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.581 93.4926C131.194 94.065 131.015 89.9322 127.568 80.1506L124.415 69.9034C124.238 49.3 141.709 50.8657 141.709 50.8657C142.683 50.8729 143.643 50.9971 144.576 51.2297C152.22 53.1356 158.078 62.2241 158.079 70.1926C158.079 70.1926 158.081 70.8575 158.085 73.5983C158.087 75.1047 160.302 78.3326 160.125 80.204C160.067 80.8255 157.916 81.2013 157.828 81.8389C157.135 86.8805 157.555 92.8262 153.744 94.3371C153.744 94.3371 150.635 95.0921 145.581 93.4926Z"
      fill="#FEC2BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.259 84.1432C122.842 73.5471 121.084 65.3453 124.41 60.1878C124.909 52.1387 135.748 51.1628 135.748 51.1628C139.801 48.577 145.372 49.5031 147.1 50.383C147.1 50.383 151.18 50.9713 155.189 57.0504C155.189 57.0504 156.668 59.094 155.788 60.1878C154.909 61.2815 153.087 62.4474 148.568 62.14C147.759 62.0867 146.641 61.793 145.455 61.4812C143.57 60.9859 141.511 60.445 140.242 60.7508C135.927 61.8046 136.203 62.9972 138.08 71.0915C138.101 71.1806 138.122 71.2707 138.143 71.3616L138.131 71.3642C140.092 78.0682 145.45 85.2433 149.125 83.5569C152.819 81.8615 157.5 83.5569 157.5 83.5569C159.29 85.8292 156.66 93.8553 156.66 93.8553C156.66 93.8553 156.201 95.2383 149.125 94.984C142.049 94.7297 133.296 84.6268 134.582 83.0109C135.187 82.2499 134.996 80.7438 134.762 78.9018C134.503 76.8589 134.191 74.4028 134.856 72.0922L134.842 72.0953C134.842 72.0953 134.291 69.2081 132.05 69.505C129.505 70.7252 130.184 72.9295 130.992 75.5536C131.802 78.1845 132.742 81.2373 130.693 84.1432H127.259Z"
      fill="url(#paint18_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.259 84.1432C122.842 73.5471 121.084 65.3453 124.41 60.1878C124.909 52.1387 135.748 51.1628 135.748 51.1628C139.801 48.577 145.372 49.5031 147.1 50.383C147.1 50.383 151.18 50.9713 155.189 57.0504C155.189 57.0504 156.668 59.094 155.788 60.1878C154.909 61.2815 153.087 62.4474 148.568 62.14C147.759 62.0867 146.641 61.793 145.455 61.4812C143.57 60.9859 141.511 60.445 140.242 60.7508C135.927 61.8046 136.203 62.9972 138.08 71.0915C138.101 71.1806 138.122 71.2707 138.143 71.3616L138.131 71.3642C140.092 78.0682 145.45 85.2433 149.125 83.5569C152.819 81.8615 157.5 83.5569 157.5 83.5569C159.29 85.8292 156.66 93.8553 156.66 93.8553C156.66 93.8553 156.201 95.2383 149.125 94.984C142.049 94.7297 133.296 84.6268 134.582 83.0109C135.187 82.2499 134.996 80.7438 134.762 78.9018C134.503 76.8589 134.191 74.4028 134.856 72.0922L134.842 72.0953C134.842 72.0953 134.291 69.2081 132.05 69.505C129.505 70.7252 130.184 72.9295 130.992 75.5536C131.802 78.1845 132.742 81.2373 130.693 84.1432H127.259Z"
      fill="url(#pattern26)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.467 303.647V308.183C117.467 308.183 125.923 311.441 129.461 308.183L129.015 302.402L117.467 303.647Z"
      fill="#FEC2BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.012 303.647V308.183C149.012 308.183 157.468 311.441 161.006 308.183L160.56 302.402L149.012 303.647Z"
      fill="#FEC2BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.541 306.556C114.992 306.556 113.758 312.931 115.069 316.468C115.793 317.076 118.036 316.675 121.666 316.798C127.135 316.983 147.903 316.468 147.903 316.468C147.903 316.468 147.995 313.305 146.575 312.215C145.756 311.587 140.903 311.065 139.147 310.572C135.329 309.498 135.709 308.39 129.426 305.814C122.66 309.368 122.536 309.086 117.541 306.556Z"
      fill="#6E6E6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.907 315.893C147.801 315.893 147.198 316.424 146.306 316.424C145.72 316.424 117.492 316.424 116.554 316.424C115.615 316.424 114.866 315.778 114.866 315.778C114.866 315.778 114.407 317.64 115.584 317.668C116.76 317.695 125.899 317.674 126.683 317.668C133.142 317.614 144.639 317.467 146.105 317.447C147.231 317.447 147.835 317.43 148.041 317.194C148.367 316.397 148.013 315.893 147.907 315.893Z"
      fill="#DEDEDE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.084 249.001L161.361 304.046C161.361 304.046 156.869 306.579 147.461 304.046L148.48 250.511L146.423 205.34L141.541 205.177L137.515 249.33L130.25 304.046C128.536 305.189 123.416 306.727 115.698 304.046L119.337 249.531L116.55 187.11H165.698L166.084 249.001Z"
      fill="url(#paint19_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.084 249.001L161.361 304.046C161.361 304.046 156.869 306.579 147.461 304.046L148.48 250.511L146.423 205.34L141.541 205.177L137.515 249.33L130.25 304.046C128.536 305.189 123.416 306.727 115.698 304.046L119.337 249.531L116.55 187.11H165.698L166.084 249.001Z"
      fill="url(#pattern27)"
      fillOpacity="0.02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.088 306.556C146.539 306.556 145.305 312.931 146.616 316.468C147.34 317.076 149.583 316.675 153.213 316.798C158.682 316.983 179.449 316.468 179.449 316.468C179.449 316.468 179.542 313.305 178.122 312.215C177.303 311.587 172.45 311.065 170.694 310.572C166.876 309.498 167.256 308.39 160.973 305.814C154.207 309.368 154.083 309.086 149.088 306.556Z"
      fill="#6E6E6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M179.454 315.893C179.348 315.893 178.745 316.424 177.853 316.424C177.267 316.424 149.039 316.424 148.1 316.424C147.162 316.424 146.413 315.778 146.413 315.778C146.413 315.778 145.954 317.64 147.13 317.668C148.307 317.695 157.446 317.674 158.23 317.668C164.689 317.614 176.186 317.467 177.652 317.447C178.777 317.447 179.382 317.43 179.587 317.194C179.914 316.397 179.56 315.893 179.454 315.893Z"
      fill="#DEDEDE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.146 122.988C109.146 122.988 110.719 122.988 113.567 123.611C115.567 140.349 115.541 191.962 115.541 194.184C115.904 195.093 127.167 197.694 140.637 197.186C153.661 196.693 164.443 195.824 165.967 194.184C167.492 192.543 165.115 124.458 165.115 124.458C165.115 124.458 168.385 124.458 169.923 123.611C169.923 102.032 157.922 98.8576 153.837 98.8576C152.288 98.8576 154.134 98.7803 148.378 98.8576L133.024 99.4925C133.024 99.4925 130.544 99.4925 127.436 99.4925C110.217 100.717 109.146 116.557 109.146 122.988Z"
      fill="url(#paint20_linear_912_1812)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.146 122.988C109.146 122.988 110.719 122.988 113.567 123.611C115.567 140.349 115.541 191.962 115.541 194.184C115.904 195.093 127.167 197.694 140.637 197.186C153.661 196.693 164.443 195.824 165.967 194.184C167.492 192.543 165.115 124.458 165.115 124.458C165.115 124.458 168.385 124.458 169.923 123.611C169.923 102.032 157.922 98.8576 153.837 98.8576C152.288 98.8576 154.134 98.7803 148.378 98.8576L133.024 99.4925C133.024 99.4925 130.544 99.4925 127.436 99.4925C110.217 100.717 109.146 116.557 109.146 122.988Z"
      fill="url(#pattern28)"
      fillOpacity="0.02"
    />
    <mask id="mask2_912_1812" maskUnits="userSpaceOnUse" x="109" y="98" width="61" height="100">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.146 122.988C109.146 122.988 110.719 122.988 113.567 123.611C115.567 140.349 115.541 191.962 115.541 194.184C115.904 195.093 127.167 197.694 140.637 197.186C153.661 196.693 164.443 195.824 165.967 194.184C167.492 192.543 165.115 124.458 165.115 124.458C165.115 124.458 168.385 124.458 169.923 123.611C169.923 102.032 157.922 98.8576 153.837 98.8576C152.288 98.8576 154.134 98.7803 148.378 98.8576L133.024 99.4925C133.024 99.4925 130.544 99.4925 127.436 99.4925C110.217 100.717 109.146 116.557 109.146 122.988Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.146 122.988C109.146 122.988 110.719 122.988 113.567 123.611C115.567 140.349 115.541 191.962 115.541 194.184C115.904 195.093 127.167 197.694 140.637 197.186C153.661 196.693 164.443 195.824 165.967 194.184C167.492 192.543 165.115 124.458 165.115 124.458C165.115 124.458 168.385 124.458 169.923 123.611C169.923 102.032 157.922 98.8576 153.837 98.8576C152.288 98.8576 154.134 98.7803 148.378 98.8576L133.024 99.4925C133.024 99.4925 130.544 99.4925 127.436 99.4925C110.217 100.717 109.146 116.557 109.146 122.988Z"
        stroke="white"
      />
    </mask>
    <g mask="url(#mask2_912_1812)">
      <rect x="147.348" y="116.812" width="11.9362" height="4.65652" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.906 118.506H156.727V119.838H149.906V118.506Z"
        fill="#5A5A5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.78 107.537C135.395 107.693 124.762 101.317 127.739 98.9878C130.628 96.7278 150.161 96.3184 153.511 98.3578C157.181 100.593 148.164 107.38 141.78 107.537Z"
        fill="#FEC2BE"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.99 136.975C125.742 132.585 143.428 95.8243 143.428 95.8243C148.014 92.906 151.277 92.8694 151.43 99.8614C151.43 99.8614 128.884 160.167 114.674 159.399C109.067 159.785 108.596 125.674 109.904 122.947C115.043 123.995 122.601 123.785 122.601 123.785L121.99 136.975Z"
      fill="#FEC2BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.008 11.8643C251.114 11.8643 253.633 14.3827 253.633 17.4893V63.8024C253.633 66.909 251.114 69.4274 248.008 69.4274H201.568L187.416 80.1644C185.933 81.2898 183.893 79.7988 184.516 78.0439L187.572 69.4274H180.82C177.713 69.4274 175.195 66.909 175.195 63.8024V17.4893C175.195 14.3827 177.713 11.8643 180.82 11.8643H248.008Z"
      fill="white"
    />
    <path
      d="M226.099 38.6991C225.156 38.6991 224.305 38.1353 223.94 37.2691C222.435 33.696 218.895 31.1778 214.769 31.1778C212.495 31.1778 210.41 31.9544 208.738 33.2425L210.048 33.4446C210.496 33.5137 210.844 33.8675 210.905 34.3146C210.965 34.7614 210.724 35.195 210.311 35.3797L203.106 38.6093C202.79 38.7506 202.424 38.7234 202.133 38.537C201.842 38.3504 201.666 38.03 201.664 37.6853L201.627 29.7755C201.624 29.3256 201.918 28.9276 202.35 28.7965C202.782 28.6653 203.249 28.8318 203.5 29.2063L204.493 30.6933C207.14 28.0965 210.769 26.4912 214.769 26.4912C220.835 26.4912 226.05 30.1817 228.274 35.4271C228.583 36.1542 228.505 36.9867 228.068 37.6444C227.63 38.3024 226.891 38.6991 226.099 38.6991Z"
      fill="#57BD47"
    />
    <path
      d="M201.471 44.5469C201.908 43.8889 202.648 43.4922 203.44 43.4922C204.382 43.4922 205.234 44.056 205.598 44.9222C207.104 48.4954 210.643 51.0135 214.77 51.0135C217.044 51.0135 219.129 50.2369 220.801 48.9488L219.49 48.7468C219.043 48.6777 218.695 48.3239 218.634 47.8767C218.573 47.4299 218.815 46.9963 219.228 46.8116L226.433 43.582C226.749 43.4408 227.114 43.4678 227.406 43.6543C227.696 43.841 227.873 44.1613 227.875 44.506L227.912 52.4157C227.915 52.8657 227.62 53.2636 227.189 53.3948C226.756 53.526 226.289 53.3594 226.039 52.9848L225.046 51.4978C222.398 54.0948 218.77 55.7001 214.77 55.7001C208.704 55.7001 203.489 52.0096 201.265 46.7642C200.956 46.0372 201.033 45.2047 201.471 44.5469Z"
      fill="#57BD47"
    />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="2.28615" height="11.0388">
        <use transform="scale(0.00886105 0.0427862)" />
      </pattern>
      <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="2.28615" height="11.0388">
        <use transform="scale(0.00886105 0.0427862)" />
      </pattern>
      <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="0.701757" height="10.386">
        <use transform="scale(0.00271999 0.0402559)" />
      </pattern>
      <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="0.939403" height="1.77817">
        <use transform="scale(0.0036411 0.00689212)" />
      </pattern>
      <pattern id="pattern4" patternContentUnits="objectBoundingBox" width="4.69701" height="1.77817">
        <use transform="scale(0.0182055 0.00689212)" />
      </pattern>
      <pattern id="pattern5" patternContentUnits="objectBoundingBox" width="0.782836" height="31.2957">
        <use transform="scale(0.00303425 0.121301)" />
      </pattern>
      <pattern id="pattern6" patternContentUnits="objectBoundingBox" width="4.69701" height="31.2957">
        <use transform="scale(0.0182055 0.121301)" />
      </pattern>
      <pattern id="pattern7" patternContentUnits="objectBoundingBox" width="9.33433" height="6.31493">
        <use transform="scale(0.0361796 0.0244765)" />
      </pattern>
      <pattern id="pattern8" patternContentUnits="objectBoundingBox" width="24.595" height="13.727">
        <use transform="scale(0.0953295 0.0532053)" />
      </pattern>
      <pattern id="pattern9" patternContentUnits="objectBoundingBox" width="4.13544" height="13.727">
        <use transform="scale(0.0160288 0.0532053)" />
      </pattern>
      <pattern id="pattern10" patternContentUnits="objectBoundingBox" width="3.86202" height="51.8574">
        <use transform="scale(0.0149691 0.200998)" />
      </pattern>
      <pattern id="pattern11" patternContentUnits="objectBoundingBox" width="27.4885" height="51.8574">
        <use transform="scale(0.106545 0.200998)" />
      </pattern>
      <pattern id="pattern12" patternContentUnits="objectBoundingBox" width="55.2724" height="11.0921">
        <use transform="scale(0.214234 0.0429925)" />
      </pattern>
      <pattern id="pattern13" patternContentUnits="objectBoundingBox" width="55.2724" height="11.0921">
        <use transform="scale(0.214234 0.0429925)" />
      </pattern>
      <pattern id="pattern14" patternContentUnits="objectBoundingBox" width="55.2724" height="11.0921">
        <use transform="scale(0.214234 0.0429925)" />
      </pattern>
      <pattern id="pattern15" patternContentUnits="objectBoundingBox" width="55.2724" height="9.90364">
        <use transform="scale(0.214234 0.0383862)" />
      </pattern>
      <pattern id="pattern16" patternContentUnits="objectBoundingBox" width="55.2724" height="11.0921">
        <use transform="scale(0.214234 0.0429925)" />
      </pattern>
      <pattern id="pattern17" patternContentUnits="objectBoundingBox" width="55.2724" height="11.0921">
        <use transform="scale(0.214234 0.0429925)" />
      </pattern>
      <pattern id="pattern18" patternContentUnits="objectBoundingBox" width="55.2724" height="9.90364">
        <use transform="scale(0.214234 0.0383862)" />
      </pattern>
      <pattern id="pattern19" patternContentUnits="objectBoundingBox" width="12.5619" height="12.0566">
        <use transform="scale(0.0486896 0.046731)" />
      </pattern>
      <pattern id="pattern20" patternContentUnits="objectBoundingBox" width="12.5619" height="12.0566">
        <use transform="scale(0.0486896 0.046731)" />
      </pattern>
      <pattern id="pattern21" patternContentUnits="objectBoundingBox" width="1.93664" height="2.28111">
        <use transform="scale(0.00750636 0.00884151)" />
      </pattern>
      <pattern id="pattern22" patternContentUnits="objectBoundingBox" width="5.85393" height="1.93809">
        <use transform="scale(0.0226897 0.00751196)" />
      </pattern>
      <pattern id="pattern23" patternContentUnits="objectBoundingBox" width="1.66176" height="1.93809">
        <use transform="scale(0.00644094 0.00751196)" />
      </pattern>
      <pattern id="pattern24" patternContentUnits="objectBoundingBox" width="7.24424" height="4.46132">
        <use transform="scale(0.0280785 0.0172919)" />
      </pattern>
      <pattern id="pattern25" patternContentUnits="objectBoundingBox" width="2.49264" height="19.318">
        <use transform="scale(0.00966141 0.0748759)" />
      </pattern>
      <pattern id="pattern26" patternContentUnits="objectBoundingBox" width="7.27943" height="5.66503">
        <use transform="scale(0.0282149 0.0219575)" />
      </pattern>
      <pattern id="pattern27" patternContentUnits="objectBoundingBox" width="5.12045" height="2.17862">
        <use transform="scale(0.0198467 0.00844425)" />
      </pattern>
      <pattern id="pattern28" patternContentUnits="objectBoundingBox" width="4.24508" height="2.6212">
        <use transform="scale(0.0164538 0.0101597)" />
      </pattern>
      <linearGradient
        id="paint0_linear_912_1812"
        x1="60.7402"
        y1="147.792"
        x2="60.7402"
        y2="156.036"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFB3D9" />
        <stop offset="1" stopColor="#EACDDF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_912_1812"
        x1="338.038"
        y1="148.006"
        x2="338.103"
        y2="156.271"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D2A0C6" />
        <stop offset="1" stopColor="#CDAEC5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_912_1812"
        x1="327.854"
        y1="110.107"
        x2="326.76"
        y2="110.107"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#585656" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_912_1812"
        x1="327.854"
        y1="110.109"
        x2="326.768"
        y2="110.109"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#333232" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_912_1812"
        x1="276.508"
        y1="124.477"
        x2="276.508"
        y2="143.272"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#484848" />
        <stop offset="1" stopColor="#2B2B2B" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_912_1812"
        x1="88.6738"
        y1="106.931"
        x2="95.0851"
        y2="129.289"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FA23C" />
        <stop offset="1" stopColor="#348727" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_912_1812"
        x1="84.2062"
        y1="112.813"
        x2="80.509"
        y2="120.365"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6FD358" />
        <stop offset="1" stopColor="#4AB539" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_912_1812"
        x1="101.531"
        y1="106.692"
        x2="100.719"
        y2="129.938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FA23C" />
        <stop offset="1" stopColor="#348727" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_912_1812"
        x1="96.0529"
        y1="100.118"
        x2="88.6049"
        y2="104.589"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6FD358" />
        <stop offset="1" stopColor="#4AB539" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_912_1812"
        x1="110.94"
        y1="104.189"
        x2="117.389"
        y2="109.584"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6FD358" />
        <stop offset="1" stopColor="#4AB539" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_912_1812"
        x1="113.344"
        y1="105.617"
        x2="107.717"
        y2="128.186"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FA23C" />
        <stop offset="1" stopColor="#348727" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_912_1812"
        x1="124.5"
        y1="105.684"
        x2="129.234"
        y2="112.968"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6FD358" />
        <stop offset="1" stopColor="#4AB539" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_912_1812"
        x1="119.797"
        y1="126.306"
        x2="119.797"
        y2="147.705"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CACACA" />
        <stop offset="1" stopColor="#E3E6EB" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_912_1812"
        x1="87.1211"
        y1="126.306"
        x2="87.1211"
        y2="147.705"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_912_1812"
        x1="158.641"
        y1="45.8008"
        x2="158.641"
        y2="158.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C8C8C8" />
        <stop offset="1" stopColor="#979797" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_912_1812"
        x1="268.822"
        y1="128.876"
        x2="268.822"
        y2="261.997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDBF39" />
        <stop offset="1" stopColor="#A8812E" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_912_1812"
        x1="137.605"
        y1="128.876"
        x2="137.605"
        y2="261.997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEDA5D" />
        <stop offset="1" stopColor="#FBCE49" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_912_1812"
        x1="197.816"
        y1="167.355"
        x2="197.816"
        y2="225.186"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDBF39" />
        <stop offset="1" stopColor="#A8812E" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_912_1812"
        x1="114.671"
        y1="54.6191"
        x2="127.492"
        y2="98.7647"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D29074" />
        <stop offset="1" stopColor="#AA614B" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_912_1812"
        x1="176.711"
        y1="199.599"
        x2="127.49"
        y2="298.891"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A2A2A2" />
        <stop offset="1" stopColor="#A9A9A9" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_912_1812"
        x1="138.105"
        y1="85.5419"
        x2="104.584"
        y2="130.547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#59E4D2" />
        <stop offset="1" stopColor="#2DC4A6" />
      </linearGradient>
      <image id="image0_912_1812" width="258" height="258" />
    </defs>
  </svg>
)

export default ForgotPassword
