import { TypeCustomField } from '../../../types';
import { CustomFieldValueType, CustomFieldObjectType } from '../../../API';
import { customFieldTransformer } from '../../../components/requester/query';
import {
  TypeComparator,
  TypeFilter,
  TypeRequester,
  TypePath,
  TypeRequesterFilters,
} from '../../../components/requester/types';

const getField = (valueType: CustomFieldValueType): string => {
  switch (valueType) {
    case CustomFieldValueType.TEXT:
      return 'textValue';
    case CustomFieldValueType.NUMBER:
      return 'numberValue';
    case CustomFieldValueType.DATE:
      return 'dateValue';
  }
};

const getFilters = (valueType: CustomFieldValueType): Array<TypeRequesterFilters> => {
  switch (valueType) {
    case CustomFieldValueType.TEXT:
      return [
        {
          type: TypeFilter.STRING,
          comparator: TypeComparator.EQ,
        },
        {
          type: TypeFilter.STRING,
          comparator: TypeComparator.NE,
        },
        {
          type: TypeFilter.STRING,
          comparator: TypeComparator.STARTS_WITH,
        },
        {
          type: TypeFilter.STRING,
          comparator: TypeComparator.CONTAINS,
        },
        {
          type: TypeFilter.STRING,
          comparator: TypeComparator.DOES_NOT_CONTAIN,
        },
        {
          type: TypeFilter.BOOLEAN,
          comparator: TypeComparator.EXISTS,
        },
      ];

    case CustomFieldValueType.NUMBER:
      return [
        {
          type: TypeFilter.NUMBER,
          comparator: TypeComparator.EQ,
        },
        {
          type: TypeFilter.NUMBER,
          comparator: TypeComparator.NE,
        },
        {
          type: TypeFilter.NUMBER,
          comparator: TypeComparator.GT,
        },
        {
          type: TypeFilter.NUMBER,
          comparator: TypeComparator.LT,
        },
        {
          type: TypeFilter.BOOLEAN,
          comparator: TypeComparator.EXISTS,
        },
      ];

    case CustomFieldValueType.DATE:
      return [
        { type: TypeFilter.DATE, comparator: TypeComparator.EQ },
        { type: TypeFilter.DATE, comparator: TypeComparator.GT },
        { type: TypeFilter.DATE, comparator: TypeComparator.LT },
      ];
  }
};

const buildCustomFieldFilter = (customField: TypeCustomField, path?: Array<string>): TypeRequester => ({
  type: TypePath.CUSTOM,
  filterID: customField.name,
  field: getField(customField.valueType as CustomFieldValueType),
  transformer: customFieldTransformer({
    customFieldID: customField.id,
    customFieldValueType: customField.valueType as CustomFieldValueType,
  }),
  path: path ?? [],
  filters: getFilters(customField.valueType as CustomFieldValueType),
});

const addCustomFieldsInEntity = (
  customFields: Array<TypeCustomField>,
  type: CustomFieldObjectType,
  path?: Array<string>,
) => customFields.filter(e => e.objectType === type).map(e => buildCustomFieldFilter(e, path));

export default addCustomFieldsInEntity;
