import { TypeComparator, TypeFilter, TypeRequester, TypePath } from '../../../components/requester/types'
import { inventoryQuantitiesTransformer } from '../../../components/requester/query'

export const addInventoryQuantityGlobalFields = (shops: Array<any>, t: (field: string, options: any) => string) => {
  const processShop = (shop: any): TypeRequester => ({
    type: TypePath.CUSTOM,
    filterID: t('list.col.INVENTORY_QUANTITY_IN', { name: shop.name }),
    field: 'quantity',
    transformer: inventoryQuantitiesTransformer({ shopID: shop.id }),
    path: [],
    filters: [
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.EQ,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.NE,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.GT,
      },
      {
        type: TypeFilter.NUMBER,
        comparator: TypeComparator.LT,
      },
    ],
  })

  const productInventoryQuantities: Array<TypeRequester> = shops.map((shop) => processShop(shop))

  return productInventoryQuantities
}

export const addInventoryMovementShopsOriginFields = (shops: Array<any>, t: (field: string) => string) => {
  const originShops: TypeRequester = {
    type: TypePath.PLAIN,
    field: 'originID',
    filterID: 'ORIGIN',
    path: [],
    filters: [
      {
        type: TypeFilter.LIST,
        comparator: TypeComparator.EQ,
        list: shops.map((shop) => ({ id: shop.id, value: shop.name })),
      },
      {
        type: TypeFilter.LIST,
        comparator: TypeComparator.NE,
        list: shops.map((shop) => ({ id: shop.id, value: shop.name })),
      },
    ],
  }

  return originShops
}

export const addInventoryMovementShopsDestinationFields = (shops: Array<any>, t: (field: string) => string) => {
  const destinationShops: TypeRequester = {
    type: TypePath.PLAIN,
    field: 'destinationID',
    filterID: 'DESTINATION',
    path: [],
    filters: [
      {
        type: TypeFilter.LIST,
        comparator: TypeComparator.EQ,
        list: shops.map((shop) => ({ id: shop.id, value: shop.name })),
      },
      {
        type: TypeFilter.LIST,
        comparator: TypeComparator.NE,
        list: shops.map((shop) => ({ id: shop.id, value: shop.name })),
      },
    ],
  }

  return destinationShops
}
