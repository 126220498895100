import { TypeTag, APIOption, TypeProduct, TypeVariation } from '../../../../../../types'
import {
  updateProductCustomField,
  deleteProductCustomField,
  createProductCustomField,
  callCustomFieldsUpdate,
} from '../../../../../../services/setting/customField'
import { DataWithErrors } from '../../../../../../services/types'
import { callTagToCreate, callTagToDetach } from './tag'
import { buildTemporaryErrors, createInput, isNestedDirty } from '../../../../../../utils/dirties'
import { callService } from '../../../../../../services'
import {
  DeleteImageInput,
  ImageModelType,
  UpdateProductInput,
  UpdateProductVariationNameInput,
  UpdateProductVariationOptionInput,
  UploadImageInput,
} from '../../../../../../API'
import {
  createProductOption,
  deleteProductOption,
  updateProduct as update,
  updateProductVariationName,
  updateProductVariationOption,
} from '../../../../../../graphql/custom/mutations'

import { getItemToAddOrRemove } from '../../../../../../utils'
import { deleteImage, uploadImage } from '../../../../../../graphql/mutations'
import { List } from '../../../../../../store/types'

const callOptionCreation = (productID: string) => (option: APIOption) =>
  callService<{ input: any }>({ input: { productID, optionID: option.id } }, createProductOption, 'createProductOption')

const callOptionsCreation = (productID: string, options: Array<APIOption>) => options.map(callOptionCreation(productID))

const callOptionDelete =
  (productID: any) =>
  ({ option }: any) =>
    callService<{ input: any }>(
      { input: { productID, optionID: option.id } },
      deleteProductOption,
      'deleteProductOption'
    )

const callOptionsUpdate = (product: any, options: Array<APIOption>) => {
  const existingOptions = product.options.items.filter((e: any) => e.option && e.option)
  // to get item to add send first array a item from input payload and b array of existing items
  // to get item to remove send first array a existing item and b array of items from input payload
  const optionsToAdd = getItemToAddOrRemove(options, existingOptions)
  const optionsToRemove = getItemToAddOrRemove(existingOptions, options)
  return Promise.all(
    optionsToRemove.map(callOptionDelete(product.id)).concat(callOptionsCreation(product.id, optionsToAdd))
  )
}

const buildValues = (
  acc: Array<any>,
  value: { previousName: string; name: string },
  variation: { previousName: string }
) =>
  value.previousName !== value.name
    ? [
        ...acc,
        {
          name: variation.previousName,
          previousName: value.previousName,
          optionName: value.name,
        },
      ]
    : acc

const getFilteredVariations = (
  variations: Array<TypeVariation>
): Array<{
  id: string
  name: string
  previousName: string
  optionName: string
}> =>
  variations
    .filter(
      (variation) =>
        variation.values.reduce(
          (acc: Array<any>, value: { previousName: string; name: string }) => buildValues(acc, value, variation),
          []
        ).length > 0
    )
    .flatMap((variation) =>
      variation.values.reduce(
        (acc: Array<any>, value: { previousName: string; name: string }) => buildValues(acc, value, variation),
        []
      )
    )

const callUpdateVariationName = (
  productID: string,
  variation: {
    name: string
    previousName: string
  }
): Promise<DataWithErrors> =>
  callService<{ input: UpdateProductVariationNameInput }>(
    { input: { productID, name: variation.previousName, newName: variation.name } },
    updateProductVariationName,
    'updateProductVariationName'
  )

const callUpdateVariationOption = (
  productID: string,
  variation: {
    name: string
    previousName: string
    optionName: string
  }
): Promise<DataWithErrors> =>
  callService<{ input: UpdateProductVariationOptionInput }>(
    { input: { productID, name: variation.name, option: variation.previousName, newOption: variation.optionName } },
    updateProductVariationOption,
    'updateProductVariationOption'
  )

const updateVariations = async (payload: any) => {
  const results = []
  const filteredNames = payload.variations.filter((variation: any) => variation.name !== variation.previousName)
  const filteredValues = getFilteredVariations(payload.variations)
  while (filteredValues.length > 0) {
    const variation = filteredValues.pop()
    const res = await callUpdateVariationOption(payload.productID, variation!)
    results.push(res)
  }
  while (filteredNames.length > 0) {
    const variation = filteredNames.pop()
    const res = await callUpdateVariationName(payload.productID, variation!)
    results.push(res)
  }
  return results
}

const callProductUpdate = async (payload: TypeProduct, decimals: number, products: List) => {
  if (payload.productID) {
    let res: DataWithErrors = {}
    const input: any = createInput(payload, decimals)
    input.id = payload.productID

    if (Object.keys(input).length > 1) {
      res = await callService<{ input: UpdateProductInput }>({ input }, update, 'updateProduct')
      if (res.errors) return res
    }

    const toWait: Array<Promise<any>> = []

    if (isNestedDirty(payload.dirties, 'photo') || isNestedDirty(payload.dirties, 'color')) {
      if (payload.photo && payload.selected === 'photo')
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: payload.productID, type: ImageModelType.PRODUCT, base64Image: payload.photo } },
            uploadImage,
            'uploadImage'
          )
        )
      else
        toWait.push(
          callService<{ input: DeleteImageInput }>(
            { input: { id: payload.productID, type: ImageModelType.PRODUCT } },
            deleteImage,
            'deleteImage'
          )
        )
    }

    if (isNestedDirty(payload.dirties, 'customFields')) {
      toWait.push(
        callCustomFieldsUpdate(payload.customFields, {
          id: { productID: payload.productID },
          create: createProductCustomField,
          update: updateProductCustomField,
          delete: deleteProductCustomField,
        })
      )
    }

    if (isNestedDirty(payload.dirties, 'tags')) {
      const product = products.items.items.find((item) => item.id === payload.productID)
      const existingTags = product.tags.items
        .filter((item: { tag: TypeTag }) => item.tag)
        .map((item: { tag: TypeTag }) => ({
          id: item.tag.id,
          name: item.tag.name,
        }))
      // to get item to add send first array a item from input payload and b array of existing items
      // to get item to remove send first array a existing item and b array of items from input payload
      const tagsToAdd = getItemToAddOrRemove(payload.tags, existingTags)
      const tagsToRemove = getItemToAddOrRemove(existingTags, payload.tags)

      toWait.push(callTagToDetach(payload.productID, tagsToRemove))
      toWait.push(callTagToCreate(payload.productID, tagsToAdd))
    }

    if (isNestedDirty(payload.dirties, 'options')) {
      const product = products.items.items.find((item) => item.id === payload.productID)
      toWait.push(callOptionsUpdate(product, payload.options))
    }

    if (payload.isDeclined && isNestedDirty(payload.dirties, 'variations') && payload.variations) {
      toWait.push(updateVariations(payload))
    }

    res.errors = await buildTemporaryErrors(toWait, res.errors)
    return res
  }
  return null
}

export default callProductUpdate
