import { CustomFieldValueType } from '../../API';
import { convertPrice } from '../../utils/number';
import { TypeComparator, TypePath, TypeRequester } from './types';
import dayjs from 'dayjs';

const transformer = ({
  path,
  field,
  comparator,
  value,
}: {
  path?: Array<string>;
  field: string;
  comparator?: TypeComparator | null;
  value?: string | number | boolean | null;
}): any => {
  if (!path?.length) {
    return { [field]: { [comparator!]: value } };
  }

  const pathField = path[0];

  return { [pathField]: transformer({ path: path.filter((_, index) => index > 0), field, comparator, value }) };
};

const dateEqualTransformer = (requester: any): any => {
  if (requester.comparator !== TypeComparator.EQ) {
    // skip custom logic if not EQ
    return transformer(requester);
  }

  if (!requester.value) return;
  const date = dayjs(requester.value);
  if (!date) return null;

  const startOfDay = requester.value;
  const endOfDay = (() => {
    const date = new Date(startOfDay);
    return new Date(date.setDate(date.getDate() + 1)).toISOString();
  })();

  return {
    and: [
      transformer({ ...requester, comparator: TypeComparator.GT, value: startOfDay }),
      transformer({ ...requester, comparator: TypeComparator.LT, value: endOfDay }),
    ],
  };
};

const currencyTransformer = (requester: any, currencyDecimals: number): any => {
  let value = requester.value;
  if (currencyDecimals) {
    value = convertPrice(currencyDecimals, requester.value);
  }
  return transformer({ ...requester, value });
};

const connectionTransformer = ({
  path,
  connectionID,
  connectionIDName,
  connectionName,
  transformedValue,
}: {
  path?: Array<string>;
  connectionID: string;
  connectionIDName: string;
  connectionName: string;
  transformedValue: any;
}): any => {
  if (!path?.length) {
    return {
      [connectionName]: {
        ...transformedValue,
        ...transformer({ field: connectionIDName, comparator: TypeComparator.EQ, value: connectionID }),
      },
    };
  }

  const pathField = path[0];

  return {
    [pathField]: connectionTransformer({
      path: path.filter((_: any, index: number) => index > 0),
      connectionID,
      connectionIDName,
      connectionName,
      transformedValue,
    }),
  };
};

const constructConnectionTransformer = ({ connectionID, connectionIDName, connectionName }: any) => {
  return (requester: any) => {
    const transformedValue = transformer(requester);
    return connectionTransformer({
      path: requester.path,
      connectionID,
      connectionIDName,
      connectionName,
      transformedValue,
    });
  };
};

const customFieldTransformer =
  ({ customFieldID, customFieldValueType }: { customFieldID: string; customFieldValueType: CustomFieldValueType }) =>
  (requester: any) => {
    const fnConstruct = (transformedValue: any): any =>
      connectionTransformer({
        path: requester.path,
        connectionID: customFieldID,
        connectionIDName: 'customFieldID',
        connectionName: 'customFields',
        transformedValue,
      });

    switch (customFieldValueType) {
      case CustomFieldValueType.DATE:
        // eslint-disable-next-line no-case-declarations
        const { and: rangeFilters } = dateEqualTransformer(requester);
        console.log(rangeFilters);
        return {
          and: [fnConstruct(rangeFilters[0]), fnConstruct(rangeFilters[1])],
        };

      default:
        return fnConstruct(transformer(requester));
    }
  };

const inventoryQuantitiesTransformer = ({ shopID }: { shopID: string }) =>
  constructConnectionTransformer({
    connectionID: shopID,
    connectionIDName: 'shopID',
    connectionName: 'inventoryQuantity',
  });

const buildQuery = (requester: TypeRequester, currencyDecimals: any): any => {
  switch (requester.type) {
    case TypePath.OPERATOR:
      return { [requester.field]: requester.fields?.map(field => buildQuery(field, currencyDecimals)) };
    case TypePath.CUSTOM:
      return requester.transformer && requester.transformer(requester, currencyDecimals);
    default:
      return transformer(requester);
  }
};

const buildRequester = (field: TypeRequester) => ({
  filterID: 'uuid_1',
  type: TypePath.OPERATOR,
  field: 'and',
  fields: [field],
});

export {
  buildRequester,
  buildQuery,
  transformer,
  dateEqualTransformer,
  currencyTransformer,
  inventoryQuantitiesTransformer,
  customFieldTransformer,
};
