const MoreHoriz = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.16138 10C5.06138 10 4.16138 10.9 4.16138 12C4.16138 13.1 5.06138 14 6.16138 14C7.26138 14 8.16138 13.1 8.16138 12C8.16138 10.9 7.26138 10 6.16138 10ZM18.1614 10C17.0614 10 16.1614 10.9 16.1614 12C16.1614 13.1 17.0614 14 18.1614 14C19.2614 14 20.1614 13.1 20.1614 12C20.1614 10.9 19.2614 10 18.1614 10ZM12.1614 10C11.0614 10 10.1614 10.9 10.1614 12C10.1614 13.1 11.0614 14 12.1614 14C13.2614 14 14.1614 13.1 14.1614 12C14.1614 10.9 13.2614 10 12.1614 10Z"
      fill={color}
    />
  </svg>
)

export default MoreHoriz
