const InventoryIn = ({ color }: { color: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 6L18 6L18 18L15 18L15 20L18 20C19.1 20 20 19.1 20 18L20 6C20 4.9 19.1 4 18 4L15 4L15 6ZM11 7L9.59 8.41L12.17 11L4 11L4 13L12.17 13L9.59 15.59L11 17L16 12L11 7Z"
      fill={color}
    />
  </svg>
)

export default InventoryIn
