import generateDataCy from '../../../utils/cypress'
import DisplayIcon2 from '../icons'
import Layer from '../layer'

import { ComponentType, ComponentState, ComponentStatus } from '../types'

import styles from './ListItem.module.css'

type Props = {
  id?: string
  item?: string
  type?: ComponentType
  state?: ComponentState
  LeadIcon?: any
  trailIcons?: Array<any>
  status?: ComponentStatus
  onClick?: (event?: any) => void
  onHover?: (event: any) => void
  dataCy?: string
  isFirst?: boolean
  isLast?: boolean
  width?: string
}

/**
 *
 * This element displays an item
 *
 * @param item - Item to display
 * @param state - Item's state
 * @param LeadIcon - A lead icon to display
 * @param trailIcons - An array of trail icons to display
 * @param onClick - A CTA when we click on an item
 * @param dataCy - An unique id
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ListItem = ({
  id,
  item,
  type = ComponentType.CONTENT,
  state,
  LeadIcon,
  trailIcons,
  status,
  onClick,
  onHover,
  dataCy,
  isFirst,
  isLast,
}: Props): JSX.Element => {
  const getTypography = () => ({
    fontSize: `var(--sys-typography-body-sm-font-size)`,
    textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
    letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
    lineHeight: `var(--sys-typography-body-sm-line-height)`,
    fontWeight: 400,
  })

  const getColor = (type: any) => `var(--comp-list-item-color-foreground-${type})`

  const getStyle = () => ({
    color: getColor(
      state === ComponentState.DISABLED ? 'disabled' : status === ComponentStatus.DANGER || item === 'Supprimer' ? 'danger' : 'text'
    ),
    borderTopLeftRadius: isFirst ? '8px' : '',
    borderTopRightRadius: isFirst ? '8px' : '',
    borderBottomLeftRadius: isLast ? '8px' : '',
    borderBottomRightRadius: isLast ? '8px' : '',
  })

  return (
    <div
      className={styles.listItem}
      style={getStyle()}
      onClick={() => {
        if (onClick) item ? onClick(item) : onClick()
      }}
      onMouseEnter={(event) => {
        onHover && item && onHover(event)
      }}
      onMouseLeave={(event) => {
        onHover && item && onHover(event)
      }}
      data-cy={dataCy}
    >
      <Layer
        id={id}
        isDisabled={state === ComponentState.DISABLED}
        isDanger={status === ComponentStatus.DANGER}
        isFirst={isFirst}
        isLast={isLast}
        style={{
          height: `var(--comp-list-item-size-height-default)`,
          justifyContent: 'space-between',
          padding: '0 var(--comp-list-item-spacing-default)',
          gap: 'var(--comp-list-item-spacing-gap)',
        }}
      >
        <div
          className={styles.lead}
          style={{
            justifyContent: !item ? 'center' : '',
          }}
        >
          {LeadIcon && (
            <DisplayIcon2
              dataCy={generateDataCy({ scope: dataCy!, value: 'icon' })}
              id={'listItemIconID'}
              Icon={LeadIcon}
              height={`var(--comp-list-item-size-icon)`}
              width={`var(--comp-list-item-size-icon)`}
              color={
                state === ComponentState.ACTIVE
                  ? 'var(--sys-color-content-interactive)'
                  : getColor(
                      state === ComponentState.DISABLED
                        ? 'disabled'
                        : status === ComponentStatus.DANGER
                        ? 'danger'
                        : 'icon'
                    )
              }
            />
          )}
          {item && (
            <div
              style={{
                ...getTypography(),
              }}
            >
              {item}
            </div>
          )}
        </div>
        {trailIcons && trailIcons.length > 0 && (
          <div className={styles.trail} id="listItemTrailIconID">
            {trailIcons &&
              trailIcons
                .filter((_) => _)
                .map((TrailIcon, index) => (
                  <DisplayIcon2
                    key={index}
                    Icon={TrailIcon}
                    height={`var(--comp-list-item-size-icon)`}
                    width={`var(--comp-list-item-size-icon)`}
                    color={getColor(
                      state === ComponentState.DISABLED
                        ? 'disabled'
                        : status === ComponentStatus.DANGER
                        ? 'danger'
                        : 'icon'
                    )}
                  />
                ))}
          </div>
        )}
      </Layer>
    </div>
  )
}

export default ListItem
