const Text = ({ color }: { color?: string }) => (
  <svg width="100%" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.16138 16.6498C5.16138 17.1448 5.61138 17.5498 6.16138 17.5498H18.1614C18.7114 17.5498 19.1614 17.1448 19.1614 16.6498C19.1614 16.1548 18.7114 15.7498 18.1614 15.7498H6.16138C5.61138 15.7498 5.16138 16.1548 5.16138 16.6498ZM9.66138 11.9698H14.6614L15.3214 13.4098C15.4714 13.7338 15.8214 13.9498 16.2114 13.9498C16.9014 13.9498 17.3614 13.3108 17.0914 12.7438L13.2114 4.6708C13.0314 4.2928 12.6214 4.0498 12.1614 4.0498C11.7014 4.0498 11.2914 4.2928 11.1114 4.6708L7.23138 12.7438C6.96138 13.3108 7.43138 13.9498 8.12138 13.9498C8.51138 13.9498 8.86138 13.7338 9.01138 13.4098L9.66138 11.9698ZM12.1614 5.8318L14.0314 10.3498H10.2914L12.1614 5.8318Z"
      fill={color}
    />
  </svg>
)

export default Text
