import { ComponentSize, ComponentState } from '../types'

import styles from './Switch.module.css'

const Switch = ({
  selected,
  size,
  onClick,
  state,
}: {
  selected: boolean
  size: ComponentSize
  onClick: () => void
  state?: ComponentState
}) => (
  <label
    className={styles.switch}
    style={{
      height: `var(--comp-switch-size-${size}-background-height)`,
      width: `var(--comp-switch-size-${size}-background-widht)`,
    }}
  >
    <input type="checkbox" checked={selected} onChange={onClick} disabled={state === ComponentState.DISABLED} />
    <span className={`${styles.slider} ${styles.round}`}></span>
  </label>
)

export default Switch
