import { findErrorsInState } from './errors'
import { ValidationFunction } from './errors/types'

const handleChange = (state: any, field: string) => (event: any) => {
  state.errors.global = undefined

  // @ts-ignore
  state.errors[field] = null
  return { ...state, [field]: event, showSubmit: !findErrorsInState(state.errors) }
}

const handleBlur = (state: any, field: string) => {
  // @ts-ignore
  const validator: ValidationFunction = state.validators[field]
  // @ts-ignore
  state.errors[field] = validator ? validator(state[field]) : null

  const { customFields, ...errors } = state.errors

  return { ...state, showSubmit: !findErrorsInState(errors) }
}

const handleBlurNested = (state: any, field: string, index: number, subField?: string) => {
  // @ts-ignore
  const validator: ValidationFunction = state.validators[field]
  // @ts-ignore
  if (subField) state.errors[field][index] = validator ? validator(state[field][index][subField]) : null
  else state.errors[field][index] = validator ? validator(state[field][index].value) : null

  return { ...state }
}

export { handleChange, handleBlur, handleBlurNested }
