import { ValidationFunction } from '../types'
import { getMessage } from '..'

/*
  This regex checks that there is at least :
  - eight characters
  - one uppercase letter
  - one lowercase letter
  - one number
*/
export const validPassword: () => ValidationFunction = () => (value: string) =>
  value && value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
    ? null
    : { value: true, message: getMessage('match') }

export const samePassword: () => ValidationFunction =
  () =>
  ({ first, second }: { first: string; second: string }) =>
    first === second ? null : { value: true, message: getMessage('samePassword') }
