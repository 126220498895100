// tslint:disable
import {
  productGet,
  productGetForMovement,
  productLight,
  discount,
  category,
  pack,
  tag,
  tax,
  saleGet,
  paymentGet,
  paymentMethod,
  cashbookGet,
  customerGet,
  account,
  user,
  customField,
  categoryWithStats,
  packWithStats,
  optionWithStats,
  discountWithStats,
  customerWithStats,
  cashbookWithStats,
  option,
  stat,
  companySettings,
  sellerCode,
  shop,
  salekWithStats,
  slot,
  inventoryStats,
  productStats,
  inventoryMovementList,
  customerList,
  cashbookList,
  paymentList,
  saleList,
  inventoryMovementGet,
  backgroundJob,
  invitation,
  inventoryQuantities,
  saleWithStatsLight,
  paymentGetLight,
  shopName,
  saleGetInfos,
  inventoryMovementProductGet,
} from './content';

export const getDashboardStats = /* GraphQL */ `
  query GetDashboardStats(
    $companyID: ID!
    $shopIDs: [ID]
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $interval: String,
    $test: Boolean!
  ) {
    getDashboardStats(
      companyID: $companyID
      shopIDs: $shopIDs
      startDate: $startDate
      endDate: $endDate
      interval: $interval,
      test: $test
    ) {
      ${stat}
    }
  }
`;

export const getAllProducts = /* GraphQL */ `
query requestProducts(
  $catalogID: ID!
  $limit: Int
  $nextToken: String,
  $test: Boolean!
) {
  requestProducts(
    catalogID: $catalogID,
    nextToken: $nextToken,
    sort: {
      field: name,
      direction: asc
    }
    limit: $limit,
    test: $test
  ){
    items {
      ${productLight}
    }
    nextToken
  }
}
`;

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      ${productGet}
    }
  }
`;

export const getProductForInventoryMovement = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      ${productGetForMovement}
    }
  }
`;

export const getProductVariant = /* GraphQL */ `
  query GetProduct(
    $productID: ID!
    $variantID: ID
  ) {
    getProduct(id: $productID) {
      variants(filter:{
        variantID:{
          eq: $variantID
        }
      }) {
        items {
          id
          productID
          createdAt
          variantID
          options {
            name
            value
          }
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
        }
      }
      id
      name
      categoryID
      taxID
      variations {
        name
        options
      }
      customFields {
        items {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          productID
          customFieldID
          value
          customField {
            ${customField}
          }
        }
      }
      options {
        items {
          option {
            id
            name
            values {
              name
              price
            }
          }
        }
        nextToken
      }
      tags {
        items {
          tag {
            ${tag}
          }
        }
        nextToken
      }
    }
  }
`;

export const getProductVariants = /* GraphQL */ `
  query GetProduct(
    $productID: ID!
    $nextToken: String
    $limit: Int
  ) {
    getProduct(id: $productID) {
      variants(
        nextToken: $nextToken
        limit: $limit
      ){
        items {
          id
          productID
          createdAt
          variantID
          options {
            name
            value
          }
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            ${inventoryQuantities}
          }
        }
        nextToken
      }
    }
  }
`;

export const getProductStats = /* GraphQL */ `
  query GetProduct(
    $id: ID!,
    $shopIDs: [ID]
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $interval: String
  ) {
    getProduct(id: $id) {
      variants {
        items {
          variantID
          ${productStats}
        }
        nextToken
      }
      ${productStats}
    }
  }
`;

export const getProductVariantStats = /* GraphQL */ `
  query GetProduct(
    $id: ID!,
    $shopIDs: [ID]
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $interval: String
    $variantID: ID,
  ) {
    getProduct(id: $id) {
      variants(filter:{
        variantID:{
          eq: $variantID
        }
      }) {
        items {
          variantID
          ${productStats}
        }
        nextToken
      }
      ${productStats}
    }
  }
`;


export const getProductInventoryStats = /* GraphQL */ `
  query GetProduct(
    $id: ID!,
    $shopIDs: [ID],
    $date: AWSDateTime
  ) {
    getProduct(id: $id) {
      ${inventoryStats}
    }
  }
`;

export const getProductVariantInventoryStats = /* GraphQL */ `
  query GetProductVariant(
    $productID: ID!,
    $variantID: ID!,
    $shopIDs: [ID],
    $date: AWSDateTime
  ) {
    getProductVariant(productID: $productID, variantID: $variantID) {
      ${inventoryStats}
    }
  }
`;

export const getProductInventories = /* GraphQL */ `
  query GetProduct(
    $id: ID!
  ) {
    getProduct(id: $id) {
      buyPrice
      inventoryQuantities {
        ${inventoryQuantities}
      }
    }
  }
`;

export const getProductVariantInventories = /* GraphQL */ `
  query GetProductVariant(
    $productID: ID!,
    $variantID: ID!
  ) {
    getProductVariant(productID: $productID, variantID: $variantID) {
      buyPrice
      inventoryQuantities {
        ${inventoryQuantities}
      }
    }
  }
`;

export const exportProducts = /* GraphQL */ `
  query ExportProducts($catalogID: ID!, $filter: RequesterProductFilterInput, $email: AWSEmail, $test: Boolean!) {
    exportProducts(catalogID: $catalogID, filter: $filter, email: $email, test: $test)
  }
`;

export const getAllPacks = /* GraphQL */ `
query requestPacks(
  $catalogID: ID!
  $limit: Int
  $nextToken: String,
  $test: Boolean!
  ) {
  requestPacks(
    catalogID: $catalogID,
    limit: $limit,
    nextToken: $nextToken,
    sort: {
      field: name
      direction: asc
    },
    test: $test
  ){
    items {
      ${pack}
    }
    nextToken
  }
}
`;

export const getPack = /* GraphQL */ `
  query GetPack($id: ID!) {
    getPack(id: $id) {
      ${pack}
    }
  }
`;

export const getPackStats = /* GraphQL */ `
  query GetPack(
    $id: ID!,
    $shopIDs: [ID]
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $interval: String
  ) {
    getPack(id: $id) {
      ${packWithStats}
    }
  }
`;

export const exportPacks = /* GraphQL */ `
  query ExportPacks($catalogID: ID!, $filter: RequesterPackFilterInput, $email: AWSEmail) {
    exportPacks(catalogID: $catalogID, filter: $filter, email: $email)
  }
`;

export const getAllDiscounts = /* GraphQL */ `
  query requestDiscounts(
      $catalogID: ID!
      $limit: Int
      $nextToken: String
      $test: Boolean!
    ) {
      requestDiscounts(
      nextToken: $nextToken,
      catalogID: $catalogID,
      limit: $limit,
      sort: {
        field: name
        direction: asc
      },
      test: $test
    ){
      items {
        ${discount}
      }
      nextToken
    }
  }
`;

export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      ${discount}
    }
  }
`;

export const getDiscountStats = /* GraphQL */ `
  query GetDiscount(
    $id: ID!,
    $shopIDs: [ID]
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $interval: String
  ) {
    getDiscount(id: $id) {
      ${discountWithStats}
    }
  }
`;

export const exportDiscounts = /* GraphQL */ `
  query ExportDiscounts($catalogID: ID!, $filter: RequesterDiscountFilterInput, $email: AWSEmail, $test: Boolean!) {
    exportDiscounts(catalogID: $catalogID, filter: $filter, email: $email, test: $test)
  }
`;

export const getAllCategories = /* GraphQL */ `
  query requestCategories(
    $catalogID: ID!
    $limit: Int
    $nextToken: String,
    $test: Boolean!
    ) {
      requestCategories(
      nextToken: $nextToken,
      catalogID: $catalogID,
      limit: $limit,
      sort: {
        field: name,
        direction: asc
      },
      test: $test
    ){
      items {
        ${category}
      }
      nextToken
    }
  }
`;

export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      ${category}
    }
  }
`;

export const getCategoryStats = /* GraphQL */ `
  query GetCategory(
    $id: ID!,
    $shopIDs: [ID]
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $interval: String
  ) {
    getCategory(id: $id) {
      ${categoryWithStats}
    }
  }
`;

export const exportCategories = /* GraphQL */ `
  query ExportCategories($catalogID: ID!, $filter: RequesterCategoryFilterInput, $email: AWSEmail, $test: Boolean!) {
    exportCategories(catalogID: $catalogID, filter: $filter, email: $email, test: $test)
  }
`;

export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      ${paymentGet}
    }
  }
`;

export const getPaymentLight = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      ${paymentGetLight}
    }
  }
`;

export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      ${paymentMethod}
    }
  }
`;

export const getListCustomers = /* GraphQL */ `
  query requestCustomers($companyID: ID!, $limit: Int, $nextToken: String, $test: Boolean!) {
    requestCustomers(
      companyID: $companyID,
      limit: $limit,
      nextToken: $nextToken
      sort: {
        field: firstName,
        direction: asc
      },
      test: $test
    ){
      items {
        ${customerList}
      }
      nextToken
    }
  }
`;

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      ${customerGet}
    }
  }
`;

export const getCustomerStats = /* GraphQL */ `
  query GetCustomer(
    $id: ID!
  ) {
    getCustomer(id: $id) {
      ${customerWithStats}
    }
  }
`;

export const exportCustomers = /* GraphQL */ `
  query ExportCustomers($companyID: ID!, $filter: RequesterCustomerFilterInput, $email: AWSEmail, $test: Boolean!) {
    exportCustomers(companyID: $companyID, filter: $filter, email: $email, test: $test)
  }
`;

export const getListCashbooks = /* GraphQL */ `
  query requestCashbooks($companyID: ID!, $limit: Int, $nextToken: String, $test: Boolean!) {
    requestCashbooks(
      companyID: $companyID,
      limit: $limit,
      nextToken: $nextToken
      sort: {
        field: openedAt,
        direction: desc
      },
      test: $test
    ){
      items {
        ${cashbookList}
      }
      nextToken
    }
  }
`;

export const getCashbook = /* GraphQL */ `
  query GetCashbook($id: ID!) {
    getCashbook(id: $id) {
      ${cashbookGet}
    }
  }
`;

export const getCashbookStats = /* GraphQL */ `
  query GetCashbook($id: ID!) {
    getCashbook(id: $id) {
      ${cashbookWithStats}
    }
  }
`;

export const exportCashbooks = /* GraphQL */ `
  query ExportCashbooks($companyID: ID!, $filter: RequesterCashbookFilterInput, $email: AWSEmail, $test: Boolean!) {
    exportCashbooks(companyID: $companyID, filter: $filter, email: $email, test: $test)
  }
`;

export const listOptions = /* GraphQL */ `
  query ListOptions(
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String,
    $test: Boolean!
  ) {
    listOptions(
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken,
      test: $test
    ) {
      items {
        ${option}
      }
      nextToken
    }
  }
`;

export const getListOptions = /* GraphQL */ `
  query requestOptions(
    $catalogID: ID!
    $limit: Int
    $nextToken: String,
    $test: Boolean!
  ) {
    requestOptions(
      catalogID: $catalogID,
      limit: $limit,
      nextToken: $nextToken,
      sort: {
        field: name,
        direction: asc
      },
      test: $test
    ){
      items {
        ${option}
      }
      nextToken
    }
  }
`;

export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      ${option}
    }
  }
`;

export const getOptionStats = /* GraphQL */ `
  query GetOption(
    $id: ID!,
    $shopIDs: [ID]
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $interval: String
  ) {
    getOption(id: $id) {
      ${optionWithStats}
    }
  }
`;

export const exportOptions = /* GraphQL */ `
  query ExportOptions($catalogID: ID!, $filter: RequesterOptionFilterInput, $email: AWSEmail, $test: Boolean!) {
    exportOptions(catalogID: $catalogID, filter: $filter, email: $email, test: $test)
  }
`;

export const getCustomField = /* GraphQL */ `
  query GetCustomField($id: ID!) {
    getCustomField(id: $id) {
      ${customField}
    }
  }
`;

export const getTotalProductByTag = /* GraphQL */ `
  query requestTotalProductByTags($catalogID: ID!, $test: Boolean!, $tag: String) {
    requestProducts(catalogID: $catalogID, test: $test, filter: { tags: { name: { eq: $tag } } }) {
      total
    }
  }
`;

export const getAllTags = /* GraphQL */ `
  query listTags(
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String,
    $test: Boolean!
  ) {
    listTags(
      catalogID: $catalogID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken,
      test: $test
  ) {
    items {
      ${tag}
    }
    nextToken
  }
}
`;

export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      ${tag}
    }
  }
`;

export const getTotalProductByTax = /* GraphQL */ `
  query requestTotalProductByTax($catalogID: ID!, $test: Boolean!, $tax: Int) {
    requestProducts(catalogID: $catalogID, test: $test, filter: { tax: { rate: { eq: $tax } } }) {
      total
    }
  }
`;

export const getTotalProductByCategory = /* GraphQL */ `
  query requestTotalProductByCategory($catalogID: ID!, $test: Boolean!, $category: String) {
    requestProducts(catalogID: $catalogID, test: $test, filter: { category: { name: { eq: $category } } }) {
      total
    }
  }
`;

export const getAllTaxes = /* GraphQL */ `
  query listTaxes(
    $catalogID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String,
    $test: Boolean!
  ) {
    listTaxes(
    catalogID: $catalogID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    limit: $limit
    nextToken: $nextToken,
    test: $test
  ) {
    items {
      ${tax}
    }
    nextToken
  }
}
`;

export const getTax = /* GraphQL */ `
  query GetTax($id: ID!) {
    getTax(id: $id) {
     ${tax}
    }
  }
`;

export const listTaxes = /* GraphQL */ `
  query ListTaxes(
    $catalogID: ID!,
    $test: Boolean!
  ) {
    listTaxes(
      catalogID: $catalogID,
      test: $test
    ) {
      items {
       ${tax}
      }
      nextToken
    }
  }
`;

export const getListSales = /* GraphQL */ `
  query requestSales($companyID: ID!, $limit: Int, $nextToken: String, $test: Boolean!) {
    requestSales(
      companyID: $companyID,
      limit: $limit,
      nextToken: $nextToken
      sort: {
        field: openedAt
        direction: desc
      },
      test: $test
    ){
      items {
        ${saleList}
      }
      nextToken
    }
  }
`;

export const getSale = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      ${saleGet}
    }
  }
`;

export const getSaleInfo = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      ${saleGetInfos}
    }
  }
`;

export const getSaleStatsLight = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      ${saleWithStatsLight}
    }
  }
`;

export const getSaleStats = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      ${salekWithStats}
    }
  }
`;

export const exportSales = /* GraphQL */ `
  query ExportSales($companyID: ID!, $filter: RequesterSaleFilterInput, $email: AWSEmail, $test: Boolean!) {
    exportSales(companyID: $companyID, filter: $filter, email: $email, test: $test)
  }
`;

export const exportPayments = /* GraphQL */ `
  query ExportPayments($companyID: ID!, $filter: RequesterPaymentFilterInput, $email: AWSEmail, $test: Boolean!) {
    exportPayments(companyID: $companyID, filter: $filter, email: $email, test: $test)
  }
`;

export const getAccount = /* GraphQL */ `
  query GetAccount {
    getAccount {
      ${account}
    }
  }
`;

export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      ${shop}
    }
  }
`;

export const getShopName = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      ${shopName}
    }
  }
`;

export const getSellerCode = /* GraphQL */ `
  query GetSellerCode($companyID: ID!) {
    getSellerCode(companyID: $companyID) {
      ${sellerCode}
    }
  }
`;

export const listCompanySettings = /* GraphQL */ `
  query ListCompanySettings($companyID: ID!) {
    listCompanySettings(companyID: $companyID) {
      items {
       ${companySettings}
      }
      nextToken
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query listUsers($companyID: ID!) {
    listUsers(companyID: $companyID) {
      ${user}
    }
  }
`;

export const listUsersAndInvitation = /* GraphQL */ `
  query listUsersAndInvitation($companyID: ID!) {
    listUsers(companyID: $companyID) {
      ${user}
    }
    listCompanyInvitations(companyID: $companyID) {
      items {
        ${invitation}
      }
      nextToken
    }
  }
`;

export const getListPayments = /* GraphQL */ `
  query requestPayments($companyID: ID!, $limit: Int, $nextToken: String, $test: Boolean!) {
    requestPayments(
      companyID: $companyID,
      limit: $limit,
      nextToken: $nextToken
      sort: {
        field: date,
        direction: desc
      },
      test: $test
    ){
      items {
        ${paymentList}
      }
      nextToken
    }
  }
`;

export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods(
    $companyID: ID!,
    $test: Boolean!
  ) {
    listPaymentMethods(
      companyID: $companyID,
      test: $test
    ) {
      items {
        ${paymentMethod}
      }
      nextToken
    }
  }
`;

export const listSlotsByCompany = /* GraphQL */ `
  query listSlotsByCompany(
    $companyID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String,
  ) {
    listSlotsByCompany(
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${slot}
      }
      nextToken
    }
  }
`;

export const listSlots = `
  query ListSlots(
    shopID: ID!
  ) {
    listSlots(
      shopID: $shopID
    ) {
      items {
        ${slot}
      }
      nextToken
    }
  }
`;

export const getAllInventoriesMovement = /* GraphQL */ `
  query requestInventoryMovements(
    $companyID: ID!
    $limit: Int
    $nextToken: String,
    $test: Boolean!
  ) {
  requestInventoryMovements(
      companyID: $companyID,
      limit: $limit,
      nextToken: $nextToken,
      sort: {
        field: createdAt
        direction: desc
      },
      test: $test
    ){
      items {
        ${inventoryMovementList}
      },
      nextToken
    }
  }
`;

export const getInventoryMovementVariant = /* GraphQL */ `
  query GetInventoryMovementLineProduct($id: ID!, $nextToken: String) {
    getInventoryMovementLineProduct(id: $id) {
      id
      createdAt
      updatedAt
      inventoryMovementID
      productID
      inventoryQuantities {
        productID
        quantity
        shopID
        id
        shop {
          id
        }
      }
      variantsCount
      variants(limit: 10, nextToken: $nextToken) {
        items {
          createdAt
          details {
            barcode
            nameShort
            reference
            buyPrice
          }
          inventoryQuantities {
            productID
            quantity
            shopID
            id
            shop {
              id
            }
          }
          id
          inventoryMovementProductID
          price
          productID
          quantity
          updatedAt
          variantID
        }
        nextToken
      }
    }
  }
`;

export const getInventoryMovementAllVariants = /* GraphQL */ `
  query GetInventoryMovementLineProduct($id: ID!, $nextToken: String) {
    getInventoryMovementLineProduct(id: $id) {
      createdAt
      hasVariants
      id
      inventoryMovementID
      price
      productID
      quantity
      inventoryQuantities {
        productID
        quantity
        shopID
        id
        shop {
          id
        }
      }
      updatedAt
      variantsCount
      variantsCountAll
      variantsSelectedAll
      variants(limit: 10) {
        nextToken
        items {
          variantID
          quantity
          productID
          price
          inventoryMovementProductID
          id
          details {
            barcode
            nameShort
            reference
            sellPrice
            buyPrice
          }
          inventoryQuantities {
            productID
            quantity
            shopID
            id
            shop {
              id
            }
          }
          createdAt
          updatedAt
        }
      }
      variantsAll(limit: 10, nextToken: $nextToken) {
        nextToken
        items {
          productID
          variantID
          line {
            variantID
            quantity
            updatedAt
            productID
            price
            inventoryMovementProductID
            id
            createdAt
            deprecated
            details {
              barcode
              nameShort
              reference
              sellPrice
              buyPrice
            }
            inventoryQuantities {
              productID
              quantity
              shopID
              id
              shop {
                id
              }
            }
          }
          details {
            sellPrice
            reference
            nameShort
            barcode
            buyPrice
          }
          inventoryQuantities {
            productID
            quantity
            shopID
            id
            shop {
              id
            }
          }
        }
      }
    }
  }
`;

export const getInventoryMovement = /* GraphQL */ `
  query GetInventoryMovement($id: ID!, $nextToken: String) {
    getInventoryMovement(id: $id) {
      id
      test
      companyID
      updatedAt
      createdAt
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements(limit: 100) {
        items {
          id
          updatedAt
          createdAt
          inventoryMovementID
          productID
          variantID
          inventoryQuantities {
            productID
            quantity
            shopID
            id
            shop {
              id
            }
          }
          details {
            product {
              name
              sellPrice
              buyPrice
              category {
                name
              }
              reference
              barcode
            }
            variant {
              nameShort
              sellPrice
              buyPrice
              reference
              barcode
            }
          }
          quantity
          price
        }
        nextToken
      }
      products(nextToken: $nextToken) {
        items {
          id
          productID
          quantity
          variantsCount
          inventoryQuantities {
            productID
            quantity
            shopID
            id
            shop {
              id
            }
          }
          details {
            barcode
            buyPrice
            category {
              name
            }
            name
            reference
          }
          price
          createdAt
          inventoryMovementID
          updatedAt
          variantsCount
          variantsCountAll
          variantsSelectedAll
          hasVariants
          variants(limit: 10) {
            items {
              id
              variantID
              quantity
              inventoryQuantities {
                productID
                quantity
                shopID
                id
                shop {
                  id
                }
              }
              details {
                barcode
                reference
                nameShort
                sellPrice
                buyPrice
              }
              price
              createdAt
              updatedAt
              inventoryMovementProductID
              productID
            }
            nextToken
          }
          variantsAll(limit: 10) {
            nextToken
            items {
              productID
              variantID
              line {
                details {
                  barcode
                  nameShort
                  reference
                  sellPrice
                  buyPrice
                }
                id
                inventoryMovementProductID
                price
                productID
                quantity
                variantID
                inventoryQuantities {
                  productID
                  quantity
                  shopID
                  id
                  shop {
                    id
                  }
                }
              }
              details {
                barcode
                nameShort
                reference
                sellPrice
                buyPrice
              }
              inventoryQuantities {
                productID
                quantity
                shopID
                id
                shop {
                  id
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const listInventoryMovements = /* GraphQL */ `
  query RequestInventoryMovements($companyID: ID!, $test: Boolean!) {
    requestInventoryMovements(companyID: $companyID, test: $test) {
      items {
       ${inventoryMovementGet}
      }
      nextToken
    }
  }
`;
export const requestBackgroundJobs = /* GraphQL */ `
  query requestBackgroundJobs(
    $catalogID: ID!
    $limit: Int
    $nextToken: String,
    $test: Boolean!
  ) {
    requestBackgroundJobs(
        nextToken: $nextToken,
        companyID: $catalogID,
        limit: $limit
        test: $test
      ){
        items {
          ${backgroundJob}
        }
        nextToken
      }
    }
`;

export const initActivity = /* GraphQL */ `
query ListActivity(
  $companyID: ID!
  $limit: Int
  $nextToken: String,
  $test: Boolean!
) {
  requestSales(
    companyID: $companyID,
    limit: $limit,
    nextToken: $nextToken
    sort: {
      field: openedAt
      direction: desc
    },
    test: $test
  ){
    items {
      ${saleList}
    }
    nextToken
  }
  requestPayments(
    companyID: $companyID
    limit: $limit
    nextToken: $nextToken
    sort: {
      field: date,
      direction: desc
    },
    test: $test
  ){
    items {
      ${paymentList}
    }
    nextToken
  }
  requestCashbooks(
    companyID: $companyID
    limit: $limit
    nextToken: $nextToken
    sort: {
      field: openedAt
      direction: desc
    },
    test: $test
  ){
    items {
      ${cashbookList}
    }
    nextToken
  }
  requestCustomers(
    companyID: $companyID
    limit: $limit
    nextToken: $nextToken
    sort: {
      field: firstName
      direction: asc
    },
    test: $test
  ){
    items {
      ${customerList}
    }
    nextToken
  }
}
`;

export const initCatalog = /* GraphQL */ `
query ListCatalog(
  $catalogID: ID!
  $companyID: ID!
  $limit: Int
  $nextToken: String,
  $test: Boolean!
) {
  requestProducts(
    catalogID: $catalogID,
    nextToken: $nextToken,
    sort: {
      field: name,
      direction: asc
    }
    limit: $limit,
    test: $test
  ){
    items {
      ${productLight}
    }
    nextToken
  }
  requestOptions(
    catalogID: $catalogID,
    limit: $limit,
    nextToken: $nextToken,
    sort: {
      field: name,
      direction: asc
    },
    test: $test
  ){
    items {
      ${option}
    }
    nextToken
  }
  requestCategories(
    nextToken: $nextToken,
    catalogID: $catalogID,
    limit: $limit,
    sort: {
      field: name,
      direction: asc
    },
    test: $test
  ){
    items {
      ${category}
    }
    nextToken
  }
  requestDiscounts(
    nextToken: $nextToken,
    catalogID: $catalogID,
    limit: $limit,
    sort: {
      field: name
      direction: asc
    },
    test: $test
  ){
    nextToken
  }
  requestPacks(
    catalogID: $catalogID,
    limit: $limit,
    nextToken: $nextToken,
    sort: {
      field: name
      direction: asc
    },
    test: $test
  ){
    nextToken
  }
  requestInventoryMovements(
    companyID: $companyID,
    limit: $limit,
    nextToken: $nextToken,
    sort: {
      field: createdAt
      direction: desc
    },
    test: $test
  ){
    items {
        ${inventoryMovementList}
      },
    nextToken
  }
}
`;

export const initSettings = /* GraphQL */ `
query ListSettings(
  $catalogID: ID!
  $companyID: ID!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $limit: Int
  $nextToken: String,
  $test: Boolean!
) {
  listSlotsByCompany(
    companyID: $companyID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    limit: $limit
    nextToken: $nextToken
  ) {
    nextToken
  }
  listCustomFields(
    catalogID: $catalogID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    limit: $limit
    nextToken: $nextToken,
    test: $test
  ) {
    items {
      ${customField}
    }
    nextToken
  }
  listTags(
    catalogID: $catalogID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    limit: $limit
    nextToken: $nextToken,
    test: $test
  ) {
    items {
      ${tag}
    }
    nextToken
  }
  listTaxes(
    catalogID: $catalogID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    limit: $limit
    nextToken: $nextToken,
    test: $test
  ) {
    items {
      ${tax}
    }
    nextToken
  }
  listPaymentMethods(
    companyID: $companyID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    limit: $limit
    nextToken: $nextToken,
    test: $test
  ) {
    items {
      ${paymentMethod}
    }
    nextToken
  }
  listCompanySettings(companyID: $companyID) {
    items {
      ${companySettings}
    }
    nextToken
  }
  listUsers(companyID: $companyID) {
      ${user}
    }
  listCompanyInvitations(companyID: $companyID) {
    items {
      ${invitation}
    }
    nextToken
  }
  requestBackgroundJobs(
    nextToken: $nextToken,
    companyID: $catalogID,
    limit: $limit
    test: $test
  ){
    nextToken
  }
}
`;
