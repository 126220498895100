import { CreateCustomerInput, MergeCustomersInput } from '../../../../../API'
import { callService } from '../../../../../services'
import { createCustomerCustomField, callCustomFieldsCreation } from '../../../../../services/setting/customField'
import { getCompanyID } from '../../../../../services/localStorage'
import { DataWithErrors } from '../../../../../services/types'
import { TypeCustomer } from '../../../../../types'
import { buildTemporaryErrors, createInput, isNestedDirty } from '../../../../../utils/dirties'
import { createCustomer as create } from '../../../../../graphql/custom/mutations'
import { mergeCustomers } from '../../../../../graphql/mutations'

const createCustomerInput = (fusionObject: any): MergeCustomersInput => {
  let updatedFusionObject = { ...fusionObject }

  const { address, zipcode, city, country, ...rest } = updatedFusionObject

  updatedFusionObject = {
    ...rest,
    address: {
      address: address || null,
      zipcode: zipcode || null,
      city: city || null,
      country: country || null,
    },
  }

  if (updatedFusionObject.customFields) {
    updatedFusionObject.customFields = updatedFusionObject.customFields.map((field: any) => {
      const { name, valueType, ...remaining } = field
      return remaining
    })
  }

  return updatedFusionObject
}

const callCustomerCreation = async (payload: TypeCustomer) => {
  const input: any = createInput(payload)

  if (input) {
    let res: DataWithErrors = {}
    if (input.phone) input.phone = '+' + payload.phone?.replace('+', '')

    res = await callService<{ input: CreateCustomerInput }>(
      { input: { ...input, companyID: getCompanyID() } },
      create,
      'createCustomer'
    )
    if (res.errors) return res

    if (isNestedDirty(payload.dirties, 'customFields')) {
      const toWait: Array<Promise<any>> = []
      toWait.push(
        callCustomFieldsCreation(payload.customFields, {
          id: { customerID: res.data.id },
          create: createCustomerCustomField,
        })
      )
      res.errors = await buildTemporaryErrors(toWait, res.errors)
    }

    return res
  }
  return null
}

export const mergeCustomer = async (payload: any) => {
  const input: MergeCustomersInput = createCustomerInput(payload)

  const mergedCustomer = await callService<{ input: MergeCustomersInput }>({ input }, mergeCustomers, 'mergeCustomers')
  return mergedCustomer
}

export default callCustomerCreation
