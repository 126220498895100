const Cashbook = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1614 2H6.16138C5.06138 2 4.16138 2.9 4.16138 4V20C4.16138 21.1 5.06138 22 6.16138 22H18.1614C19.2614 22 20.1614 21.1 20.1614 20V4C20.1614 2.9 19.2614 2 18.1614 2ZM6.16138 4H11.1614V12L8.66138 10.5L6.16138 12V4Z"
      fill={color}
    />
  </svg>
)

export default Cashbook
