/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unsafe-optional-chaining */
import { Product } from '../API';
import { displayDateLongMonth } from './date';
import countries from '../assets/countries.json'

const keys: Array<string> = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'companyName',
  'address',
  'zipcode',
  'city',
  'country',
  'fidelityCardNumber',
  'note',
];

type CountryCode = {
  id: number;
  name: string;
  alpha2: string;
  alpha3: string;
};

type TranslationKey =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'companyName'
  | 'address'
  | 'zipcode'
  | 'city'
  | 'country'
  | 'note'
  | 'fidelityCardNumber';

const defaultTranslationMap: Record<TranslationKey, string> = {
  firstName: 'Prénom',
  lastName: 'Nom',
  email: 'Email',
  phone: 'Téléphone',
  companyName: "Nom de l'entreprise",
  address: 'Adresse',
  zipcode: 'Code Postal',
  city: 'Ville',
  country: 'Pays',
  note: 'Note',
  fidelityCardNumber: 'Carte de fidélité',
};

const translationMap = (key: string): string => {
  return defaultTranslationMap[key as TranslationKey] || key;
};

const sortByDate = (selected: Product[]) => {
  //@ts-ignore
  return [...selected].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
};

const getUniqueNonNullValuesExcludingNull = (values: any[]) => {
  const nonNullValues = values.filter(value => value !== null && value !== '(Aucun)');
  //@ts-ignore
  return [...new Set(nonNullValues)];
};

const isCustomKey = (key: string) => !keys.includes(key);
const isCountryKey = (key: string) => key === defaultTranslationMap.country;

const getCustomFieldInfo = (
  key: string,
  selected: Product[],
): { customFieldID: string; customFieldKey: string; valueType: string } | null => {
  for (const obj of selected) {
    if (obj.customFields) {
      //@ts-ignore
      for (const item of obj.customFields?.items) {
        if (item?.customField && item.customField.name === key) {
          return {
            customFieldID: item.customField.id,
            customFieldKey: item.customField.key,
            valueType: item.customField.valueType,
          };
        }
      }
    }
  }
  return null;
};

const gatherAllKeys = (selected: Product[]) => {
  const customFieldKeys = selected.flatMap(element =>
    element.customFields && Array.isArray(element.customFields.items)
      ? element.customFields.items.flatMap((item: any) => (item.customField ? [item.customField.name] : []))
      : [],
  );

  //@ts-ignore
  return [...new Set([...keys, ...customFieldKeys])];
};

const getValue = (obj: any, key: string, countries: CountryCode[]) => {
  if (['address', 'zipcode', 'city', 'country'].includes(key)) {
    if (key === 'country' && obj.address && obj.address[key]) {
      // Find the country name corresponding to the alpha2 code
      const country = countries.find(c => c.alpha2.toUpperCase() === obj.address[key]);
      return country ? country.name : '(Aucun)';
    }
    return obj.address ? obj.address[key] || '(Aucun)' : '(Aucun)';
  }

  if (isCustomKey(key)) {
    const customItem = obj.customFields?.items.find(
      (cfItem: any) => cfItem.customField && cfItem.customField.name === key,
    );
    if (customItem) {
      if (customItem.customField.valueType === 'DATE') {
        return displayDateLongMonth(customItem.value);
      }
      return customItem.value || '(Aucun)';
    }
    return '(Aucun)';
  }

  return obj[key] || '(Aucun)';
};

const translateValueForAPI = (fieldKey: string, value: any) => {
  if (value === 'Aucun') {
    return null
  }

  if (fieldKey === 'country') {
    const countyDefinitions = countries as CountryCode[];
    return countyDefinitions.find((country) => country.name === value)?.alpha2.toUpperCase()
  }

  return value
}

const createUpdatedField = (fieldKey: string, customInfo: any, value: any, oldestId: string) => {
  return {
    customerID: oldestId,
    customFieldID: customInfo?.customFieldID,
    customFieldKey: customInfo?.customFieldKey,
    value: value === '(Aucun)' ? null : value,
    valueType: customInfo?.valueType,
    name: fieldKey,
  };
};

export {
  keys,
  sortByDate,
  getUniqueNonNullValuesExcludingNull,
  isCustomKey,
  isCountryKey,
  getCustomFieldInfo,
  gatherAllKeys,
  translationMap,
  getValue,
  translateValueForAPI,
  createUpdatedField,
};
