import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { closeAndResetModal } from '../../../../actions/modal'
import Button from '../../button'
import AlertBanner from '../../alertBanner'
import { ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../types'

import styles from '../Modal.module.css'
import { InventoryMovementState } from '../../../../API';
import { drawerOpen, updateDrawerState } from '../../../../actions/drawer'
import { DrawerState, InventoryType, objectType } from '../../../../store/types'
import { updateInventoryMovement } from '../../../../getters/catalog/inventory'
import generateDataCy from '../../../../utils/cypress'

type Item = { buyPrice: any, [key: string]: any };
type EditValues = { [key: string]: { buyPrice?: any, [key: string]: any } } | undefined;

const ValidationModal = ({
  editValues,
  inventoryMoveRequest,
  dispatch,
  items,
  validateDraft,
  draftState,
  movementType,
}: {
  editValues?: any
  inventoryMoveRequest: (editValues: any, isDraft: boolean, t: (field: string) => string, buyPriceChange: boolean) => void
  dispatch: any
  items?: Array<any>
  validateDraft: boolean,
  draftState?: any
  movementType: InventoryType
}) => {
  const [state, setState] = useState({ showSubmit: true, showModal: validateDraft && movementType === InventoryType.IN, showLoader: false, })
  const { t } = useTranslation()
  const cyContext = generateDataCy({scope: "inventoryMovement", value: "modal"})

  const checkForBuyPrice = (editValues: EditValues, items?: Item[]) => {
    if (movementType !== InventoryType.IN) { return false }
    console.log(`determine if updated is needed`)

    const editionExists = (val: any) => val !== undefined && val !== null
    const priceExists = (val: any) => val !== undefined && val !== null && val !== ""
    const defaultPriceIfNull = (val: any, defaultValue: any) => priceExists(val) ? val : defaultValue

    const mergedItems = (items || []).map(item => {
      const editedItem = editValues ? editValues[item.id] : null
      // if (!editionExists(editedItem)) return item

      const editedItemSubValues = item.subValues.map((subValue: any) => {
        const editedItemSubValue = editedItem ? editedItem.subValues ? editedItem.subValues[subValue.id] : null : null
        return {
          ...subValue,
          buyPrice: editionExists(editedItemSubValue)
            ? editedItemSubValue.buyPrice
            : defaultPriceIfNull(subValue.inventoryMovementDetails?.buyPrice, subValue.price)
        }
      })

      return {
        ...item,
        buyPrice: editionExists(editedItem)
          ? editedItem?.buyPrice
          : defaultPriceIfNull(item.inventoryMovementDetails?.buyPrice, item.price),
        subValues: editedItemSubValues
      }
    })

    return mergedItems?.some(item =>
      priceExists(item.buyPrice)
      || (item.subValues || []).some((subValue: any) => priceExists(subValue.buyPrice))
    ) || false
  }

  const handleSubmit = (isDraft: boolean) => {
    const buyPriceChange = isDraft ? false : checkForBuyPrice(editValues, items);

    if (isDraft) {
      setState({ ...state, showLoader: true })
      inventoryMoveRequest(editValues, isDraft, t, buyPriceChange ? true : false)
    } else {
      if (movementType === InventoryType.IN) {
        setState({ ...state, showSubmit: false, showModal: buyPriceChange })
        if (!buyPriceChange) {
          setState({ ...state, showLoader: true })
          inventoryMoveRequest(editValues, isDraft, t, buyPriceChange)
        }
      } else if (movementType === InventoryType.TRANSFER || movementType === InventoryType.OUT) {
        setState({ ...state, showLoader: true })
        inventoryMoveRequest(editValues, isDraft, t, false)
      } else {
        if (buyPriceChange) {
          setState({ ...state, showModal: true })
        } else {
          setState({ ...state, showLoader: true })
          inventoryMoveRequest(editValues, isDraft, t, false)
        }
      }
    }
  }

  const handleSubmitWithEditPrice = (updatePrice: boolean) => {
    if (validateDraft) {
      setState({ ...state, showLoader: true })
      updateInventoryMovement(
        {
          id: draftState.id,
          state: InventoryMovementState.CLOSED,
          motive: draftState.motive,
        },
        dispatch,
        updatePrice
      ).then(() => {
        updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
        closeAndResetModal(dispatch)
        drawerOpen(dispatch, { open: false })
      })
    } else {
      setState({ ...state, showLoader: true })
      inventoryMoveRequest(editValues, false, t, updatePrice)
    }
  }

  return (
    <>
      {
        state.showModal || validateDraft ?
          <div className={styles.modal} data-cy={generateDataCy({scope: cyContext, value: "buyPrice"})}>
            {state.showLoader ? <div className={styles.containerLoader}>
              <div data-cy="validate-modal-loader" className={styles.loader}></div>
              <div className={styles.modalInfo}>{t('catalog.inventoryMovement.LOADING')}</div>
            </div>
              :
              <>
                <div className={styles.modalTitle}>{t('catalog.inventoryMovement.BUY_PRICE_UPDATE_TITLE')}</div>
                <div className={styles.modalContent}>
                  <div className={styles.modalInfo}>{t('catalog.inventoryMovement.BUY_PRICE_UPDATE_TEXT')}</div>
                  <AlertBanner
                    title={t('catalog.inventoryMovement.BUY_PRICE_UPDATE_SUB_TITLE')}
                    status={ComponentStatus.INFO}
                  />
                </div>
                <div className={styles.modalButtons}>
                  <Button
                    title={t('button.CANCEL')}
                    size={ComponentSize.MEDIUM}
                    type={ComponentType.TERTIARY}
                    onClick={() => closeAndResetModal(dispatch)}
                    dataCy={generateDataCy({scope:cyContext , value: "cancel"})}
                  />
                  <Button
                    title={t('button.DO_NOT_UPDATE')}
                    size={ComponentSize.MEDIUM}
                    type={ComponentType.TERTIARY}
                    state={state.showModal || validateDraft ? ComponentState.DEFAULT : ComponentState.DISABLED}
                    onClick={() => handleSubmitWithEditPrice(false)}
                    dataCy={generateDataCy({scope:cyContext , value: "notUpdate"})}
                  />
                  <Button
                    title={t('button.UPDATE')}
                    size={ComponentSize.MEDIUM}
                    type={ComponentType.PRIMARY}
                    state={state.showModal || validateDraft ? ComponentState.DEFAULT : ComponentState.DISABLED}
                    onClick={() => handleSubmitWithEditPrice(true)}
                    dataCy={generateDataCy({scope:cyContext , value: "update"})}
                  />
                </div>
              </>
            }
          </div>
          :
          <div className={styles.modal} data-cy={generateDataCy({scope: cyContext, value: "chooseState"})}>
            {state.showLoader ? <div className={styles.containerLoader}>
              <div className={styles.loader}></div>
              <div className={styles.modalInfo}>{t('catalog.inventoryMovement.LOADING')}</div>
            </div> :
              <>
                <div className={styles.modalTitle}>{t('catalog.inventoryMovement.VALIDATE_INVENTORY_MOVE')}</div>
                <div className={styles.modalContent}>
                  <div className={styles.modalInfo}>{t('catalog.inventoryMovement.CONSIDER_DRAFT')}</div>
                </div>
                <div className={styles.modalButtons}>
                  <Button
                    title={t('button.CANCEL')}
                    size={ComponentSize.MEDIUM}
                    type={ComponentType.TERTIARY}
                    onClick={() => closeAndResetModal(dispatch)}
                    dataCy={generateDataCy({scope:cyContext , value: "cancel"})}
                  />
                  {!validateDraft && <Button
                    title={t('button.DRAFT')}
                    size={ComponentSize.MEDIUM}
                    type={ComponentType.TERTIARY}
                    state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
                    onClick={() => handleSubmit(true)}
                    dataCy={generateDataCy({scope:cyContext , value: "draft"})}
                  />}
                  <Button
                    title={t('button.VALIDATE')}
                    size={ComponentSize.MEDIUM}
                    type={ComponentType.PRIMARY}
                    state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
                    onClick={() => handleSubmit(false)}
                    dataCy={generateDataCy({scope:cyContext , value: "validate"})}
                  />
                </div>
              </>
            }

          </div>
      }
    </>
  )
}

export default ValidationModal