import {
  createCustomerCustomField,
  updateCustomerCustomField,
  deleteCustomerCustomField,
  callCustomFieldsUpdate,
} from '../../../../../services/setting/customField'
import { TypeCustomer } from '../../../../../types'
import { DataWithErrors } from '../../../../../services/types'
import { buildTemporaryErrors, createInput, isNestedDirty } from '../../../../../utils/dirties'
import { callService } from '../../../../../services'
import { UpdateCustomerInput } from '../../../../../API'
import { updateCustomer as update } from '../../../../../graphql/custom/mutations'

const callCustomerUpdate = async (payload: TypeCustomer) => {
  if (payload.id) {
    let res: DataWithErrors = {}
    const input: any = createInput(payload)

    if (Object.keys(input).length > 1) {
      if (input.phone) input.phone = '+' + payload.phone?.replace('+', '')

      res = await callService<{ input: UpdateCustomerInput }>({ input }, update, 'updateCustomer')
      if (res.errors) return res
    }

    if (isNestedDirty(payload.dirties, 'customFields')) {
      const toWait: Array<Promise<any>> = []
      toWait.push(
        callCustomFieldsUpdate(payload.customFields, {
          id: { customerID: payload.id },
          create: createCustomerCustomField,
          update: updateCustomerCustomField,
          delete: deleteCustomerCustomField,
        })
      )
      res.errors = await buildTemporaryErrors(toWait, res.errors)
    }

    return res
  }
  return null
}

export default callCustomerUpdate
