const Exit = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9514 16.29C11.3414 16.68 11.9714 16.68 12.3614 16.29L15.9514 12.7C16.3414 12.31 16.3414 11.68 15.9514 11.29L12.3614 7.7C11.9714 7.31 11.3414 7.31 10.9514 7.7C10.5614 8.09 10.5614 8.72 10.9514 9.11L12.8314 11H4.16138C3.61138 11 3.16138 11.45 3.16138 12C3.16138 12.55 3.61138 13 4.16138 13H12.8314L10.9514 14.88C10.5614 15.27 10.5714 15.91 10.9514 16.29ZM19.1614 3H5.16138C4.05138 3 3.16138 3.9 3.16138 5V8C3.16138 8.55 3.61138 9 4.16138 9C4.71138 9 5.16138 8.55 5.16138 8V6C5.16138 5.45 5.61138 5 6.16138 5H18.1614C18.7114 5 19.1614 5.45 19.1614 6V18C19.1614 18.55 18.7114 19 18.1614 19H6.16138C5.61138 19 5.16138 18.55 5.16138 18V16C5.16138 15.45 4.71138 15 4.16138 15C3.61138 15 3.16138 15.45 3.16138 16V19C3.16138 20.1 4.06138 21 5.16138 21H19.1614C20.2614 21 21.1614 20.1 21.1614 19V5C21.1614 3.9 20.2614 3 19.1614 3Z"
      fill={color}
    />
  </svg>
)

export default Exit
