/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { DayRange, DayValue } from 'react-modern-calendar-datepicker'
import dayjs from 'dayjs'

import MultipleDropDown, { SelectOptions } from '../../components/newComponents/dropDown/multiple'
import Loader, { displayLoader } from '../../components/newComponents/loader'
import {
  getDashboardView,
  getDrawerState,
  getMenu,
  listShopsItemsNextToken,
  listStatCategories,
  listStatPayments,
  listStatProducts,
  listStatTaxes,
  listStatValues,
  selectedCompany,
} from '../../store/selector'
import { DashboardView, DrawerState, MenuState, objectType } from '../../store/types'
import { TypeStat } from './types'
import { displayPrice } from '../../utils/number'
import getDashboardStats from '../../getters/stat/stat'
import { getExplicitDate, getShopIDs } from '../../components/oldComponents/stat/utils'
import { getRandomColor } from '../../utils/color'
import DisplayStat from './stat'
import { updateDrawerState } from '../../actions/drawer'
import { updateStatsFilters } from '../../actions/stats'
import StatCard from '../../components/oldComponents/stat/card'
import ChartArea from '../../components/oldComponents/stat/chartArea'
import StatList from '../../components/oldComponents/stat/list'
import Chip from '../../components/newComponents/chip'
import generateDataCy from '../../utils/cypress'
import toggleMenu from '../../actions/menu'
import TopBar from '../../components/newComponents/topBar'
import Path from '../../components/newComponents/topBar/path'
import getStatsInventoryStats from '../../getters/stat/value'
import { env } from '../../services/auth'
import IconButton from '../../components/newComponents/button/iconButton'
import { ComponentSize, ComponentStatus, ComponentType } from '../../components/newComponents/types'
import { updateModal } from '../../actions/modal'
import ExportList from '../../components/oldComponents/export/inventory'

import { Menu, Download } from '../../assets/icons'
import styles from './Stat.module.css'
import DateRangeComp from '../../components/newComponents/datePicker/DateRange';
import { InventoryDatePicker } from '../../components/newComponents/datePicker'

const Statistic = ({ isTesting }: { isTesting?: boolean }) => {
  const payment = useSelector(listStatPayments)
  const tax = useSelector(listStatTaxes)
  const product = useSelector(listStatProducts)
  const category = useSelector(listStatCategories)
  const { t } = useTranslation()
  const value = useSelector(listStatValues)
  const initialState: TypeStat = {
    shopInput: t('drawer.stat.ALL_SHOP'),
    shops: [],
    value: {
      dateInput: null,
      dateAPI: '',
    },
    global: {
      dateInput: null,
      startDateAPI: '',
      endDateAPI: '',
    },
    chart: [],
    generalPath: '',
    generalCards: [],
    taxCols: tax.cols,
    paymentCols: payment.cols,
    productPath: '',
    productCards: [],
    productCols: product.cols,
    categoryPath: '',
    categoryCards: [],
    categoryCols: category.cols,
    valuePath: '',
    valueCols: value.cols,
    valueCards: [],
    isValueView: false,
  }
  const [state, setState] = useState(initialState)
  const drawerState = useSelector(getDrawerState)
  const company = useSelector(selectedCompany)
  const allShops = useSelector(listShopsItemsNextToken)
  const dashboardView = useSelector(getDashboardView)
  const menu = useSelector(getMenu)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'stat', value: 'select' })

  const getCols = () => {
    if (dashboardView === DashboardView.MOBILE) {
      return {
        paymentCols: {
          ...payment.cols!,
          cols: payment.cols?.cols.filter((col) => col.id === 'paymentMethod' || col.id === 'sellTotals')!,
        },
        taxCols: {
          ...tax.cols!,
          cols: tax.cols?.cols.filter((col) => col.id === 'rate' || col.id === 'totalTax')!,
        },
        productCols: {
          ...product.cols!,
          cols: product.cols?.cols.filter((col) => col.id === 'name' || col.id === 'total')!,
        },
        categoryCols: {
          ...category.cols!,
          cols: category.cols?.cols.filter((col) => col.id === 'name' || col.id === 'total')!,
        },
        valueCols: {
          ...category.cols!,
          cols: category.cols?.cols.filter((col) => col.id === 'name' || col.id === 'value')!,
        },
      }
    }
    return {
      paymentCols: payment.cols,
      taxCols: tax.cols,
      productCols: product.cols,
      categoryCols: category.cols,
      valueCols: value.cols,
    }
  }

  const getStatsFilters = (state: TypeStat) => {
    return {
      shopIDs: getShopIDs(state.shops),
      dashboard: {
        startDateAPI: state.global.startDateAPI,
        endDateAPI: state.global.endDateAPI
      },
      stockValue: {
        date: state.value.dateAPI
      }
    }
  }

  const setStatsInState = (data: { stats: any }) => {
    if (data.stats) {
      const stats = data.stats

      let chart = [
        {
          name: t('stat.TOTAL'),
          color: 'var(--sys-color-content-interactive)',
          histo: stats.content.general.histogram.map((histo: any) => ({
            date: getExplicitDate(histo.id, '1d'),
            total: histo.content.totals.total,
          })),
        },
      ]
      if (stats.byShop.length > 1 && state.shopInput !== 'Toutes') {
        const chartDates = stats.content.general.histogram.map((e: { id: string }) => e.id)
        chart = [
          ...chart,
          ...stats.byShop
            .filter((shop: any) => shop.content.general)
            .map((shop: any) => {
              const color = getRandomColor()
              return {
                name: allShops.items.find((aShop) => aShop.id === shop.id)?.name || "Boutique supprimé",
                color,
                histo: chartDates.map((id: string) => ({
                  date: getExplicitDate(id, '1d'),
                  total: shop.content.general.histogram.find((shopHistogram: any) => shopHistogram.id === id)?.content.totals.total || 0,
                })),
              }
            }),
        ]
      }
      state.chart = chart
      state.generalPath = isTesting ? 'statistic/general' : 'general'
      state.generalCards = company
        ? [
          {
            id: 'nbSales',
            text: t('stat.NB_SALES'),
            value: stats.content.general.content.nbSales.toString(),
          },
          {
            id: 'total',
            text: t('stat.TOTAL'),
            value: displayPrice(
              stats.content.general.content.totals.total,
              company.currency,
              company.currencyDecimals
            ),
          },
          {
            id: 'totalTaxFree',
            text: t('stat.TOTAL_TAX_FREE'),
            value: displayPrice(
              stats.content.general.content.totals.totalTaxFree,
              company.currency,
              company.currencyDecimals
            ),
          },
          {
            id: 'average',
            text: t('stat.AVERAGE'),
            value: displayPrice(
              stats.content.general.content.average.total,
              company.currency,
              company.currencyDecimals
            ),
          },
          {
            id: 'index',
            text: t('stat.INDEX'),
            value: stats.content.general.content.index.toString(),
          },
          {
            id: 'margin',
            text: t('stat.MARGIN'),
            value: displayPrice(stats.content.general.content.margin, company.currency, company.currencyDecimals),
          },
        ]
        : []
      state.productPath = isTesting ? 'statistic/products' : 'products'
      state.productCards = company
        ? [
          {
            id: 'total',
            text: t('stat.TOTAL'),
            value: displayPrice(stats.content.products.totals.total, company.currency, company.currencyDecimals),
          },
          {
            id: 'totalTaxFree',
            text: t('stat.TOTAL_TAX_FREE'),
            value: displayPrice(
              stats.content.products.totals.totalTaxFree,
              company.currency,
              company.currencyDecimals
            ),
          },
          {
            id: 'totalTax',
            text: t('stat.TOTAL_TAX'),
            value: displayPrice(stats.content.products.totals.totalTax, company.currency, company.currencyDecimals),
          },
          {
            id: 'quantity',
            text: t('stat.QUANTITY'),
            value: stats.content.products.quantity.toString(),
          },
          {
            id: 'margin',
            text: t('stat.MARGIN'),
            value: displayPrice(stats.content.products.margin, company.currency, company.currencyDecimals),
          },
        ]
        : []
      state.categoryPath = isTesting ? 'statistic/categories' : 'categories'
      state.categoryCards = company
        ? [
          {
            id: 'total',
            text: t('stat.TOTAL'),
            value: displayPrice(stats.content.categories.totals.total, company.currency, company.currencyDecimals),
          },
          {
            id: 'totalTaxFree',
            text: t('stat.TOTAL_TAX_FREE'),
            value: displayPrice(
              stats.content.categories.totals.totalTaxFree,
              company.currency,
              company.currencyDecimals
            ),
          },
          {
            id: 'totalTax',
            text: t('stat.TOTAL_TAX'),
            value: displayPrice(stats.content.categories.totals.totalTax, company.currency, company.currencyDecimals),
          },
          {
            id: 'quantity',
            text: t('stat.QUANTITY'),
            value: stats.content.categories.quantity.toString(),
          },
          {
            id: 'margin',
            text: t('stat.MARGIN'),
            value: displayPrice(stats.content.categories.margin, company.currency, company.currencyDecimals),
          },
        ]
        : []
      updateDrawerState(DrawerState.LOADED, dispatch)
    }

    updateDrawerState(DrawerState.LOADED, dispatch)

    setState({ ...state, ...getCols() })
  }

  const setStockValueStatsInState = (data: { values: any }) => {
    state.valuePath = isTesting ? 'statistic/values' : 'values'
    state.valueCards = company
      ? [
        {
          id: 'value',
          text: t('stat.VALUE'),
          value: data.values.products
            ? displayPrice(data.values.products.value, company.currency, company.currencyDecimals)
            : '0',
        },
      ]
      : []
    updateDrawerState(DrawerState.LOADED, dispatch)

    setState({ ...state, ...getCols() })
  }

  useEffect(() => {
    updateDrawerState(DrawerState.LOADING, dispatch)
    state.shops = [...state.shops, ...allShops.items.map((shop) => ({ id: shop.id, value: shop.name, checked: true }))]

    const today = dayjs();
    const initialRangeDate = [today.startOf('week'), today];

    state.global.dateInput = initialRangeDate
    state.global.startDateAPI = dayjs(initialRangeDate[0]).toISOString();
    state.global.endDateAPI = dayjs(initialRangeDate[1]).toISOString()

    const section = window.location.pathname.split('/')[1]
    const subSection = window.location.pathname.split('/')[2]
    state.isValueView = section === 'statistic' && subSection === 'values'

    state.value.dateInput = dayjs()
    state.value.dateAPI = dayjs().toISOString()

    updateStatsFilters(getStatsFilters(state), dispatch)

    Promise.all([
      getDashboardStats(dispatch, {
        shopIDs: getShopIDs(state.shops),
        startDate: state.global.startDateAPI,
        endDate: state.global.endDateAPI,
      }),
      getStatsInventoryStats(dispatch, {
        shopIDs: getShopIDs(state.shops),
        date: state.value.dateAPI,
      }),
    ]).then(([dashboardStats, stockStats]: any) => {
      setStatsInState({ stats: dashboardStats.data })
      setStockValueStatsInState({ values: stockStats.data })
    })
  }, [])

  useEffect(() => {
    setState({ ...state, ...getCols() })
  }, [dashboardView])

  useEffect(() => {
    const section = window.location.pathname.split('/')[1]
    const subSection = window.location.pathname.split('/')[2]

    setState({ ...state, isValueView: section === 'statistic' && subSection === 'values' })
    toggleMenu(dispatch, {
      section,
      subSection,
    })
  }, [window.location.pathname])

  const updateDateRange = (date: DayRange) => {
    updateDrawerState(DrawerState.LOADING, dispatch)

    //@ts-ignore
    let startDateAPI = dayjs(date[0]).startOf('day');
    //@ts-ignore
    let endDateAPI = dayjs(date[1]).endOf('day');

    // state.global.startDateAPI = dayjs(date[0])
    // state.global.endDateAPI = dayjs(date[1])
    if (startDateAPI.isSame(endDateAPI, 'day')) {
      //@ts-ignore
      startDateAPI = startDateAPI.startOf('day');
      //@ts-ignore
      endDateAPI = endDateAPI.endOf('day');

      //@ts-ignore
      state.global.startDateAPI = startDateAPI.toISOString();
      //@ts-ignore
      state.global.endDateAPI = endDateAPI.toISOString();
    }
     
    //@ts-ignore
    // state.value.dateAPI = startDateAPI.format()

    updateStatsFilters(getStatsFilters(state), dispatch)
    
    getDashboardStats(dispatch, {
      shopIDs: getShopIDs(state.shops),
      //@ts-ignore
      startDate: state.global.dateInput[0].toISOString(),
      //@ts-ignore
      endDate: state.global.dateInput[1].endOf('day').toISOString(),
    })
    .then((dashboardStats) => {
      setStatsInState({ stats: dashboardStats.data })
    })
  }

  const updateStockValueDate = (valueDate: DayValue) => {
    updateDrawerState(DrawerState.LOADING, dispatch)

    state.value.dateInput = valueDate
    //@ts-ignore
    state.value.dateAPI = dayjs(valueDate).endOf('day').toISOString()

    updateStatsFilters(getStatsFilters(state), dispatch)

    getStatsInventoryStats(dispatch, {
      shopIDs: getShopIDs(state.shops),
      date: state.value.dateAPI,
    })
    .then((stockStats) => setStockValueStatsInState({ values: stockStats.data }))
  }

  const updateShops = (options: Array<SelectOptions>, value: string): void => {
    state.shops = options
    state.shopInput = value

    updateStatsFilters(getStatsFilters(state), dispatch)

    Promise.all([
      getDashboardStats(dispatch, {
        shopIDs: getShopIDs(state.shops),
        startDate: state.global.dateInput[0].toISOString(),
        endDate: state.global.dateInput[1].endOf('day').toISOString(),
      }),
      getStatsInventoryStats(dispatch, {
        shopIDs: getShopIDs(state.shops),
        date: state.value.dateAPI,
      }),
    ]).then(([dashboardStats, stockStats]: any) => {
      setStatsInState({ stats: dashboardStats.data })
      setStockValueStatsInState({ values: stockStats.data })
    })
  }

  // const parseDate = (): string =>
  //   (env === 'dev' || env === 'preprod') && state.isValueView
  //     ? displayDateShortMonth(formatDate(state.value.dateInput!))
  //     : state.global.dateInput.from && state.global.dateInput.to
  //       ? displayDateShortMonth(formatDate(state.global.dateInput.from)) +
  //       ' - ' +
  //       displayDateShortMonth(formatDate(state.global.dateInput.to))
  //       : state.global.dateInput.from
  //         ? displayDateShortMonth(formatDate(state.global.dateInput.from))
  //         : state.global.dateInput.to
  //           ? displayDateShortMonth(formatDate(state.global.dateInput.to))
  //           : 'jj/mm/aaaa'

  const openModalExportList = () => {
    updateModal(
      dispatch,
      true,
      () => <ExportList />,
      () => { },
      "exportList"
    )
  }

  return (
    <div className={styles.container}>
      <TopBar
        leftPart={
          <>
            <IconButton
              type={ComponentType.TERTIARY}
              size={ComponentSize.MEDIUM}
              Icon={Menu}
              onClick={() =>
                toggleMenu(dispatch, {
                  state: menu.state === MenuState.EXPAND ? MenuState.SHRINK : MenuState.EXPAND,
                })
              }
            />
            {dashboardView !== DashboardView.MOBILE && (
              <div style={{ marginLeft: 'var(--sys-size-2)' }}>
                <Path />
              </div>
            )}
          </>
        }
        middlePart={
          <>
            <div style={{ width: '50%' }}>
              <MultipleDropDown
                value={state.shopInput}
                options={state.shops}
                entities={allShops.items}
                onChanges={updateShops}
                dataCy={generateDataCy({ scope: cyContext, value: 'shop' })}
              />
            </div>
            <div
              style={{ width: '50%', marginLeft: 'var(--sys-size-2)' }}
              id="rangePickerContainer"
              data-cy={generateDataCy({ scope: cyContext, value: 'date' })}
            >
              {state.isValueView ? (
                <InventoryDatePicker
                  name=''
                  value={state.value.dateInput}
                  onChange={(date) => {
                    updateStockValueDate(date)
                  }}
                />
                // <DateRangeComp
                //   value={state.value.dateInput ? [state.value.dateInput, state.value.dateInput] : [dayjs(), dayjs()]}
                //   onChange={(date) => {
                //     // state.global.dateInput = date
                //     // setState({ ...state })
                //     if (date) updateDate(state.value.dateInput, date)
                //   }}
                // />
              ) : (
                <DateRangeComp
                  value={state.global.dateInput ? state.global.dateInput : [dayjs(), dayjs()]}
                  onChange={(date) => {
                    state.global.dateInput = date
                    setState({ ...state })
                    if (date) updateDateRange(state.value.dateInput)
                  }}

                />
              )}
            </div>
          </>
        }
        rightPart={
          state.isValueView
          ? <IconButton
            type={ComponentType.SECONDARY}
            size={ComponentSize.MEDIUM}
            Icon={Download}
            onClick={() => openModalExportList()}
            dataCy={generateDataCy({ scope: 'topbar', value: 'export' })}
          />
          : undefined
        }
      />
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <Routes>
          <Route
            path={state.generalPath}
            element={
              <DisplayStat
                stats={[
                  <StatCard cards={state.generalCards} />,
                  <ChartArea state={state} />,
                  <StatList
                    lists={[
                      {
                        title: t('stat.PAYMENT'),
                        cols: state.paymentCols,
                        items: payment.items.items,
                        sort: payment.items.sort!,
                        type: objectType.STAT_PAYMENT,
                      },
                      {
                        title: t('stat.TAX'),
                        cols: state.taxCols,
                        items: tax.items.items,
                        sort: tax.items.sort!,
                        type: objectType.STAT_TAX,
                      },
                    ]}
                  />,
                ]}
              />
            }
          />
          <Route
            path={state.productPath}
            element={
              <DisplayStat
                stats={[
                  <StatCard cards={state.productCards} />,
                  <StatList
                    lists={[
                      {
                        title: t('stat.PRODUCT'),
                        cols: state.productCols,
                        items: product.items.items,
                        sort: product.items.sort!,
                        type: objectType.STAT_PRODUCT,
                      },
                    ]}
                  />,
                ]}
              />
            }
          />
          <Route
            path={state.categoryPath}
            element={
              <DisplayStat
                stats={[
                  <StatCard cards={state.categoryCards} />,
                  <StatList
                    lists={[
                      {
                        title: t('stat.CATEGORY'),
                        cols: state.categoryCols,
                        items: category.items.items,
                        sort: category.items.sort!,
                        type: objectType.STAT_CATEGORY,
                      },
                    ]}
                  />,
                ]}
              />
            }
          />
          <Route
            path={state.valuePath}
            element={
              <DisplayStat
              stats={[
                <StatCard cards={state.valueCards} />,
                <div style={{ float: 'right', paddingRight: 20 }}>
                  <Chip 
                    size={ComponentSize.MEDIUM}
                    status={ComponentStatus.DEFAULT}
                    text='Données mises à jour toutes les 30min'
                  />
                </div>,
                <StatList
                  lists={[
                    {
                      title: t('stat.STOCK'),
                      cols: state.valueCols,
                      items: value.items.items,
                      sort: value.items.sort!,
                      type: objectType.STAT_VALUE,
                    },
                  ]}
                />
              ]}
            />
            }
          />
        </Routes>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Statistic
