import { useTranslation } from 'react-i18next'

import Button from '../../newComponents/button'
import Divider from '../../newComponents/divider'
import { ComponentDirection, ComponentSize, ComponentState, ComponentType } from '../../newComponents/types'

import styles from './Button.module.css'

/**
 *
 * This element displays a button asking user to validate
 *
 * @usedIn - Requester filter / Multiple select
 *
 * @param disabled - A boolean to disable the click
 * @param onClick - A CTA when we click in the button
 * @param dataCy - A unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ValidButton = ({
  disabled,
  onClick,
  dataCy,
}: {
  disabled?: boolean
  onClick: (event?: any) => void
  dataCy?: string
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
      <div className={styles.validButton}>
        <Button
          id="submitButton"
          title={t('button.VALIDATE')!}
          onClick={onClick}
          state={disabled ? ComponentState.DISABLED : ComponentState.DEFAULT}
          size={ComponentSize.SMALL}
          type={ComponentType.TERTIARY}
          dataCy={dataCy}
        />
      </div>
    </>
  )
}

export default ValidButton
