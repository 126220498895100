import { Route, Routes } from 'react-router-dom'

import ListSales from './sale/list'
import ListPayments from './payment/list'
import ListCashbooks from './cashbook/list'

export enum TabIdsCashbook {
  DETAIL = 'DETAIL',
  EVENT = 'EVENT',
}

const ActivityRoutes = () => (
  <Routes>
    <Route path="sales" element={<ListSales />} />
    <Route path="payments" element={<ListPayments />} />
    <Route path="cashbooks" element={<ListCashbooks />} />
  </Routes>
)

export default ActivityRoutes
