const Add = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1614 13H13.1614V18C13.1614 18.55 12.7114 19 12.1614 19C11.6114 19 11.1614 18.55 11.1614 18V13H6.16138C5.61138 13 5.16138 12.55 5.16138 12C5.16138 11.45 5.61138 11 6.16138 11H11.1614V6C11.1614 5.45 11.6114 5 12.1614 5C12.7114 5 13.1614 5.45 13.1614 6V11H18.1614C18.7114 11 19.1614 11.45 19.1614 12C19.1614 12.55 18.7114 13 18.1614 13Z"
      fill={color}
    />
  </svg>
)

export default Add
