import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import List from '../../../components/list'
import { getDashboardView, listSaleItemsNextToken, listSales, listSalesItems } from '../../../store/selector'
import { ListState, objectType } from '../../../store/types'
import Drawer from './drawer'
import { buildCols } from '../../../utils'
import { TypeComparator } from '../../../components/requester/types'
import saleFieldsRequester from '../../../requester/activity/sale'
import { getData } from '../../../services'
import { getListSales } from '../../../graphql/custom/queries'
import { fetchListElement, updateListState } from '../../../actions/list'
import { useDispatch } from 'react-redux'
import { pluralize } from '../../../utils/typeToType'
import { subscribeSales, unSubscribeSales } from '../../../getters/activity/sale'

const ListSales = () => {
  const res = useSelector(listSales)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listSalesItems)
  const { items, nextToken, sort } = useSelector(listSaleItemsNextToken)
  const dashboardView = useSelector(getDashboardView)
  const { t } = useTranslation()
  const dispatch = useDispatch();

  useEffect(() => {
    subscribeSales(dispatch)
    const getListSalesItems = async () => {
      const listSalesItems = await getData({ request: getListSales, limit: 100 });
      if (listSalesItems.requestSales && listSalesItems.requestSales.items && listSalesItems.requestSales.items.length > 0) {
        const sales = listSalesItems.requestSales.items.filter((_: any) => _)
        fetchListElement(dispatch, sales, listSalesItems.requestSales.nextToken, pluralize(objectType.SALE))
        updateListState(
          { type: pluralize(objectType.SALE), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.SALE))
        updateListState(
          { type: pluralize(objectType.SALE), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListSalesItems();

    return () => unSubscribeSales();
      }, [])

  useEffect(() => {
    setCols(buildCols(res.cols!, 'number', 'total', dashboardView))
  }, [dashboardView, res.cols])

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={saleFieldsRequester(t)}
      searchBarFields={[{ filter: TypeComparator.EQ, field: 'number' }]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.SALE}
      Drawer={Drawer}
    />
  )
}

export default ListSales
