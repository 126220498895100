import { useState, useEffect, RefObject, useRef, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

import generateDataCy from '../../../utils/cypress'
import DisplayIcon2 from '../../newComponents/icons'
import { isOutsideViewport } from '../../newComponents/dropDown/unique'

import { Add as AddIcon } from '../../../assets/icons'
import stylesInput from '../../newComponents/input/Input.module.css'
import styles from './SearchList.module.css'
import { TypeTag } from '../../../types/setting/tag/index';

export type Entries = {
  id: string
  val: string
}

type Props = {
  label?: string
  eventID?: string
  parentID?: string
  popUpID?: string
  placeholder?: string
  valueId?: string
  disabled?: boolean
  entries: Array<Entries>
  allEntries?: Array<TypeTag>
  onSelect?: (id: string) => void
  onBlur?: () => void
  onSubmit?: (event: any) => void
  onCreate?: (value: string) => void
  dataCy?: string
  error?: boolean
  displayCreate?: boolean
}

type State = {
  res: Array<Entries>
  active: boolean
  value?: string
  currentValue?: string
  cursor: number
  indexOfFirstLetter: number
  placeholder?: string
  displayCreate: boolean
}

export const UniqueDropDown = ({
  cValue,
  sValue,
  popUpID,
  res,
  indexOfFirstLetter,
  displayCreate,
  onCreate,
  onSelect,
  dataCy,
}: {
  cValue?: string
  sValue?: string
  popUpID?: string
  res: Array<Entries>
  indexOfFirstLetter: number
  displayCreate?: boolean
  onCreate: (value: string) => void
  onSelect: (id: string) => void
  dataCy?: string
}) => {
  const { t } = useTranslation()

  return (
    <div tabIndex={0} id={popUpID} className={res.length > 0 || displayCreate ? styles.listItems : ''}>
      {displayCreate && sValue && sValue !== cValue && (
        <div
          className={styles.itemCreate}
          onClick={() => onCreate(sValue)}
          data-cy={generateDataCy({ scope: dataCy ?? '', value: 'create' })}
        >
          <span className={styles.createValue}>{sValue}</span>
          <span className={styles.createButton}>
            <DisplayIcon2 id="createIconID" Icon={AddIcon} color="var(--sys-color-content-interactive)" />
            {t('button.CREATE')}
          </span>
        </div>
      )}
      {res.map((entry, i) => {
        indexOfFirstLetter = -1
        return (
          <div
            key={entry.id}
            className={styles.item}
            onClick={() => onSelect(entry.id)}
            data-cy={generateDataCy({ scope: dataCy ?? '', value: 'option-' + i })}
          >
            {entry.val?.split('').map((letter, index) => {
              if (sValue && entry.val.toLowerCase().substring(index, index + sValue.length) === sValue.toLowerCase()) {
                indexOfFirstLetter = index
              }
              return (
                <span
                  key={index}
                  style={{
                    color:
                      indexOfFirstLetter !== -1 &&
                        index <
                        // @ts-ignore
                        indexOfFirstLetter + sValue.length
                        ? 'var(--sys-color-content-primary)'
                        : 'var(--sys-color-content-secondary)',
                  }}
                >
                  {letter === ' ' ? <>&nbsp;</> : letter}
                </span>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

/**
 *
 * This element displays a select input with its dropdown
 * The user can also type on the input to search inside the list
 *
 * @usedIn - Form
 *
 * @param label - The label's input
 * @param placeholder - The label's placeholder
 * @param valueId - The id of the input's value
 * @param disabled - A boolean to know if it's a multiple select or not
 * @param entries - A list of selectable options
 * @param onSelect - A CTA when we selected a value
 * @param onBlur - A CTA when we leave the select options
 * @param onCreate - A CTA when we create a value
 * @param onSubmit - A CTA when we press "Enter"
 * @param icons - A list of icons to display at the end of the input
 * @param error - An error who changes the css
 * @param dataCy - An unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const SearchList = ({
  label,
  eventID,
  parentID,
  popUpID,
  placeholder,
  valueId,
  disabled,
  entries,
  allEntries,
  onSelect,
  onBlur,
  onCreate,
  onSubmit,
  error,
  dataCy,
}: Props): JSX.Element => {
  const initialState: State = {
    res: [],
    active: false,
    value: '',
    currentValue: '',
    cursor: -1,
    indexOfFirstLetter: -1,
    placeholder,
    displayCreate: true,
  }
  const [state, setState] = useState(initialState)
  const ref: RefObject<any> = useRef()

  const handlePosition = () => {
    if (parentID && popUpID) {
      const popupElement = document.getElementById(popUpID)

      if (popupElement) {
        popupElement.style.top = isOutsideViewport(parentID, popUpID).top
        popupElement.style.width = isOutsideViewport(parentID, popUpID).width

        if (isOutsideViewport(parentID, popUpID).left)
          popupElement.style.left = isOutsideViewport(parentID, popUpID).left
        else popupElement.style.right = isOutsideViewport(parentID, popUpID).right
      }
    }
  }

  useEffect(() => {
    const newValue = entries.find((en) => en.id === valueId)?.val
    if (state.value === newValue) return

    setState({
      ...state,
      res: entries,
      active: false,
      value: newValue,
      currentValue: newValue,
    })
      }, [entries, valueId])

  useLayoutEffect(() => {
    handlePosition()

    if (eventID) document.getElementById(eventID)?.addEventListener('scroll', handlePosition)
    return () => window.removeEventListener('scroll', () => { })
      }, [eventID, state.active])

  const handleFocus = () => {
    if (!disabled) {
      if (ref.current) ref.current.focus()
      setState({ ...state, active: !state.active, placeholder: '' })
    }
  }

  /**
   *
   * This method returns every options who match the valueInput
   *
   * @param valueInput - The value typed
   *
   * @returns - An array of Entries
   *
   * @author - Jennifer Charlois
   *
   */
  const getEntries = (valueInput: string | undefined): Array<Entries> => {
    if (valueInput) {
      const value = valueInput.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
      const regex = new RegExp(value, 'gi')
      return entries.filter((entry) => entry.val?.match(regex))
    }
    return entries
  }

  const handleSelect = (id: string) => {
    onSelect && onSelect(id)
    setState({ ...state, placeholder })
  }

  const hasMatchingValues = (entries: Array<Entries>, inputValues: string) => {
    return entries.some(entry => entry.val === inputValues)
  }

  const alreadyMatchingValues = (entries: Array<TypeTag> | undefined, inputValues: string) => {
    return entries?.some(entry => entry.name === inputValues)
  }

  const handleChange = (event: any) => {
    if (event.key === 'Enter' && state.res.length === 0) {
      onCreate && onCreate(event.target.value)
      setState({ ...state, value: '', active: false })
    } else if (event.key === 'Enter' && state.res[state.cursor]) {
      handleSelect(state.res[state.cursor].id)
    } else {
      setState({
        ...state,
        active: true,
        value: event.target.value,
        res: getEntries(event.target.value),
        displayCreate: !hasMatchingValues(entries, event.target.value) && !alreadyMatchingValues(allEntries, event.target.value)
      })
    }
  }

  const handleCreate = (value: string) => {
    const existingValue = entries.find((en) => en.val === value)
    if (existingValue) onSelect && onSelect(existingValue.id)
    else {
      onCreate && onCreate(value)
      setState({ ...state, placeholder })
    }
  }

  const handleBlur = (event: any) => {
    if (
      !event.relatedTarget ||
      (event.relatedTarget && event.relatedTarget.id !== popUpID && event.relatedTarget.id !== 'createIconID')
    ) {
      const entry = entries.find((entry) => entry.id === valueId)
      const newValue = entry && state.value === entry.val ? entry.val : ''
      setState({
        ...state,
        value: newValue,
        res: entries,
        active: false,
        placeholder,
      })
      onBlur && onBlur()
    }
  }

  const handleKey = (event: any) => {
    if (event.keyCode === 40 && state.cursor < state.res.length - 1) {
      setState({ ...state, cursor: state.cursor + 1 })
    } else if (event.keyCode === 38 && state.cursor > 0) {
      setState({ ...state, cursor: state.cursor - 1 })
    }
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        id={parentID}
        className={styles.container}
        style={{
          border: `var(--sys-size-border-width-sm) solid ${error
            ? 'var(--sys-color-border-danger)'
            : state.active
              ? 'var(--sys-color-border-interactive)'
              : 'var(--sys-color-border-default)'
            }`,
        }}
        onClick={handleFocus}
        onBlur={handleBlur}
        onSubmit={(event: any) => {
          event.preventDefault()
          onSubmit && onSubmit(event)
        }}
        data-cy={generateDataCy({ scope: dataCy ?? '', value: 'container' })}
      >
        {label && (
          <label
            className={stylesInput.label}
            style={{
              color: 'var(--sys-color-content-secondary)',
              transform: state.value === undefined && !state.active ? 'translateY(5px)' : 'translateY(-14px)',
              zIndex: 2,
            }}
          >
            {label}
          </label>
        )}
        <input
          ref={ref}
          value={state.value ?? ''}
          placeholder={state.placeholder}
          disabled={disabled}
          onChange={handleChange}
          onKeyPress={handleChange}
          // onKeyDown={handleKey}
          style={{
            cursor: state.active ? 'text' : disabled ? 'default' : 'pointer',
          }}
          data-cy={dataCy}
        />
      </div>
      {state.active && (state.value || state.res.length > 0) && (
        <UniqueDropDown
          cValue={state.currentValue}
          sValue={state.value}
          res={state.res}
          popUpID={popUpID}
          indexOfFirstLetter={state.indexOfFirstLetter}
          displayCreate={state.displayCreate}
          onCreate={handleCreate}
          onSelect={handleSelect}
          dataCy={dataCy}
        />
      )}
    </div>
  )
}

export default SearchList
