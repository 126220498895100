const Warning = ({ color }: { color?: string }) => (
  <svg width="100%" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.6315 21.1032H19.6915C21.2315 21.1032 22.1915 19.4332 21.4215 18.1032L13.8915 5.0932C13.1215 3.7632 11.2015 3.7632 10.4315 5.0932L2.9015 18.1032C2.1315 19.4332 3.0915 21.1032 4.6315 21.1032ZM12.1615 14.1032C11.6115 14.1032 11.1615 13.6532 11.1615 13.1032V11.1032C11.1615 10.5532 11.6115 10.1032 12.1615 10.1032C12.7115 10.1032 13.1615 10.5532 13.1615 11.1032V13.1032C13.1615 13.6532 12.7115 14.1032 12.1615 14.1032ZM13.1615 18.1032H11.1615V16.1032H13.1615V18.1032Z"
      fill={color}
    />
  </svg>
)

export default Warning
