const Growth = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.65878 17.4987C3.04878 17.8887 3.67878 17.8887 4.06878 17.4987L8.65878 12.9087C9.04878 12.5187 9.67878 12.5187 10.0688 12.9087L11.2388 14.0787C12.4088 15.2487 14.3088 15.2487 15.4788 14.0787L19.6588 9.90874L21.0988 11.3487C21.4088 11.6587 21.9488 11.4387 21.9488 10.9987V6.70874C21.9588 6.42874 21.7388 6.20874 21.4588 6.20874H17.1688C16.7188 6.20874 16.4988 6.74874 16.8188 7.05874L18.2588 8.49874L14.0888 12.6687C13.6988 13.0587 13.0688 13.0587 12.6788 12.6687L11.5088 11.4987C10.3388 10.3287 8.43878 10.3287 7.26878 11.4987L2.65878 16.0887C2.27878 16.4787 2.27878 17.1187 2.65878 17.4987Z"
      fill={color}
    />
  </svg>
)

export default Growth
