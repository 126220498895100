const KeyBoardArrowUp = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.28647 15L12.1665 11.12L16.0465 15C16.4365 15.39 17.0665 15.39 17.4565 15C17.8465 14.61 17.8465 13.98 17.4565 13.59L12.8665 9.00002C12.4765 8.61002 11.8465 8.61002 11.4565 9.00002L6.86647 13.59C6.47647 13.98 6.47647 14.61 6.86647 15C7.25647 15.38 7.89647 15.39 8.28647 15Z"
      fill={color}
    />
  </svg>
)

export default KeyBoardArrowUp
