import { getErrorIcon } from '../../newComponents/icon'
import Input from '../../newComponents/input'
import { ComponentStatus } from '../../newComponents/types'
import { CellProps } from '../../../types/cell'
import generateDataCy from '../../../utils/cypress'

import { Error } from '../../../assets/icons'

const TextCell = ({
  value,
  param,
  editable = true,
  onChange,
  onFocusNext,
  validator,
  error,
  icons,
  dataCy,
}: CellProps): JSX.Element => {
  return editable ? (
    <Input
      type={'text'}
      value={value}
      onChange={onChange}
      onFocusNext={onFocusNext}
      style={{
        status: error?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
        trailIcons: getErrorIcon(Error, error),
      }}
      dataCy={dataCy}
    />
  ) : (
    <div style={param?.style}>{value}</div>
  )
}

export default TextCell
