import { useTranslation } from 'react-i18next'
import { State } from '.'

import { ComponentState, ComponentStatus } from '../../../../../components/newComponents/types'
import Input from '../../../../../components/newComponents/input'
import DisplayIcon2 from '../../../../../components/newComponents/icons'
import generateDataCy from '../../../../../utils/cypress'

import { Shopping } from '../../../../../assets/icons'
import styles from './Inventory.module.css'

const InventoryMovements = ({
  state,
  onChange,
  onBlur,
}: {
  state: State
  onChange: (field: string) => (event: any) => void
  onBlur: (index: number) => void
}) => {
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'tab', value: 'inventory' })

  return (
    <>
      {state.shops.map((shop, index) => (
        <div key={shop.id}>
          <div className={styles.shopName} data-cy={generateDataCy({ scope: cyContext, value: 'shopName' })}>
            <DisplayIcon2 Icon={Shopping} color={'var(--sys-color-content-interactive)'} />
            <span className={styles.name}>{shop.name}</span>
          </div>
          <Input
            type="number"
            label={t('catalog.product.form.inventory.QUANTITY')}
            value={shop.newQuantity}
            onChange={onChange(shop.id)}
            onBlur={() => onBlur(index)}
            style={{
              state: shop.disabled ? ComponentState.DISABLED : ComponentState.DEFAULT,
              //@ts-ignore
              status: state.errors?.shops[index]?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            //@ts-ignore
            helperText={state.errors?.shops[index]?.value ? state.errors.shops[index].message : undefined}
            dataCy={generateDataCy({ scope: cyContext, value: 'shop' })}
          />
        </div>
      ))}
    </>
  )
}

export default InventoryMovements
