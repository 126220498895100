import { DrawerState } from '../../../store/types'
import generateDataCy from '../../../utils/cypress'

import styles from './Loader.module.css'

export const displayLoader = (drawerState: DrawerState) =>
  drawerState === DrawerState.LOADED ||
  drawerState === DrawerState.SEND_FORM ||
  drawerState === DrawerState.RESPONSE_FORM

/**
 *
 * This element displays a loader
 *
 * @usedIn - Loader view
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Loader = (): JSX.Element => (
  <div className={styles.containerLoader}>
    <div data-cy={generateDataCy({ scope: 'login', value: 'loader' })} className={styles.loader}></div>
  </div>
)

export default Loader
