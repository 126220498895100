import { displayNumber } from '../../utils/number'
import { TypeColumnConfiguration } from '../types'
import { CurrencyEditCell as CurrencyCell } from '../../components/list/cell'

const STAT_CATEGORY = 'statCategory'

const statCategoriesCols: TypeColumnConfiguration[] = [
  {
    id: 'statCategoriesCols',
    type: STAT_CATEGORY,
    cols: [
      {
        id: 'name',
        title: 'NAME',
        type: 'text',
        field: 'name',
        fieldType: 'string',
        active: true,
        sortable: true,
      },
      {
        id: 'totalQuantity',
        title: 'QUANTITY',
        type: 'function',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: (value) => (value.totalQuantity !== null ? displayNumber(value.totalQuantity) : ''),
      },
      {
        id: 'total',
        title: 'TOTAL',
        field: 'total',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'totalTaxFree',
        title: 'TOTAL_TAX_FREE',
        field: 'totalTaxFree',
        fieldType: 'number',
        type: 'element',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'totalTax',
        title: 'TOTAL_TAX',
        field: 'totalTax',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
      {
        id: 'margin',
        title: 'MARGIN',
        field: 'margin',
        type: 'element',
        fieldType: 'number',
        active: true,
        sortable: true,
        function: ({ value, currency, decimals }) =>
          CurrencyCell({
            value,
            editable: false,
            currency,
            decimals,
          }),
      },
    ],
    creatable: false,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: true,
    customable: false,
    checkboxes: false,
  },
]

export default statCategoriesCols
