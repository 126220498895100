import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import getAllLines from './config'
import { List } from '../../../../components/oldComponents/activity/list'
import { Route } from '../../../../utils/navigation'
import { selectedCompany } from '../../../../store/selector'
import Type from '../../../../components/oldComponents/activity/type'
import { TypePayment } from '../../../../types'
import generateDataCy from '../../../../utils/cypress'
import Chip from '../../../../components/newComponents/chip'
import { ComponentSize } from '../../../../components/newComponents/types'

import styles from '../../../../components/oldComponents/activity/List.module.css'
import stylesDrawer from '../../../../components/oldComponents/drawer/Drawer.module.css'

type State = {
  infos: Array<any>
}

const Form = ({
  paymentState,
  addHistory,
  dataCy,
}: {
  paymentState: TypePayment
  addHistory: (route: Route) => void
  dataCy: string
}) => {
  const initialeState: State = {
    infos: [],
  }
  const company = useSelector(selectedCompany)
  const [state, setState] = useState(initialeState)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (paymentState.id) {
      const { infos } = getAllLines(t, paymentState, addHistory, dispatch, company)
      setState({ ...state, infos })
    }
      }, [paymentState])

  return (
    <div className={stylesDrawer.overflow}>
      <div className={stylesDrawer.form}>
        {paymentState.status && (
          <div className={styles.type}>
            <Type
              color={paymentState.type!.color}
              text={paymentState.type!.text}
              dataCy={generateDataCy({
                scope: dataCy,
                value: 'type',
              })}
            />
            <Chip
              size={ComponentSize.MEDIUM}
              {...paymentState.status}
              dataCy={generateDataCy({
                scope: dataCy,
                value: 'status',
              })}
            />
          </div>
        )}
        <List datas={state.infos} dataCy={dataCy} />
      </div>
    </div>
  )
}

export default Form
