import { TypeColumnConfiguration, TypeCol } from '../types';
import { InventoryMovementDestination, InventoryMovementOrigin, InventoryMovementState } from '../../API';
import { displayDateShortMonthWithHours } from '../../utils/date';
import { displayNumber } from '../../utils/number';
import { inventoryMoveStateToStr, inventoryMoveTypeToStr } from '../../utils/typeToType';
import Chip from '../../components/newComponents/chip';
import { InventoryTypeCell } from '../../components/list/cell';

const INVENTORY_MOVEMENT = 'inventory';

export const inventoryCols: Array<TypeCol> = [
  {
    id: 'createdAt',
    title: 'CREATED_AT',
    type: 'function',
    field: 'createdAt',
    fieldType: 'date',
    active: true,
    sortable: true,
    notCustomable: true,
    function: date => displayDateShortMonthWithHours(date),
  },
  {
    id: 'type',
    title: 'TYPE',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value, t }: { value: any; t: (field: string) => string }) =>
      InventoryTypeCell(inventoryMoveTypeToStr(value.origin, value.destination, t)),
  },
  {
    id: 'originID',
    title: 'ORIGIN',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value, shops, t }: any) => {
      let shop;
      if (value.origin !== InventoryMovementOrigin.SUPPLIER) {
        shop = shops.items.find((shop: { id: string }) => shop.id === value.originID);
      }

      return shop ? shop.name : '-';
    },
  },
  {
    id: 'totalQuantity',
    title: 'QUANTITY',
    type: 'function',
    fieldType: 'number',
    active: true,
    sortable: true,
    function: value => (value.totalQuantity !== null ? displayNumber(value.totalQuantity) : ''),
  },
  {
    id: 'destinationID',
    title: 'DESTINATION',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value, shops, t }: any) => {
      let shop;
      if (value.destination !== InventoryMovementDestination.TRASH) {
        shop = shops.items.find((shop: { id: string }) => shop.id === value.destinationID);
      }

      return shop ? shop.name : '-';
    },
  },
  {
    id: 'state',
    title: 'STATE',
    type: 'element',
    fieldType: 'string',
    active: true,
    function: ({ value, t }: { value: any; t: (field: string) => string }) =>
      Chip(inventoryMoveStateToStr(value.state, t)),
  },
  {
    id: 'stateDate',
    title: 'VALID_AT',
    type: 'element',
    fieldType: 'date',
    active: true,
    sortable: true,
    function: ({ value }) =>
      value.state === InventoryMovementState.CLOSED ? displayDateShortMonthWithHours(value.stateDate) : '',
  },
  {
    id: 'motive',
    title: 'MOTIVE',
    type: 'string',
    field: 'motive',
    fieldType: 'string',
    active: true,
  },
];

export const inventoriesColsConf: Array<TypeColumnConfiguration> = [
  {
    id: 'requestInventoryMovements',
    type: INVENTORY_MOVEMENT,
    cols: inventoryCols,
    // creatable: false,
    creatable: true,
    editable: false,
    clickable: true,
    exportable: false,
    shrinkrable: true,
    customable: true,
    checkboxes: false,
    noRightToolbar: true,
    searchBarPlaceholder: 'searchBar.placeholder.MOTIVE',
    creatablePlaceholder: 'catalog.inventoryMovement.NEW_EXCEL',
  },
];
