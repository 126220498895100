/* eslint-disable valid-typeof */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loadVariations } from '../../getters/catalog/variant'

import generateDataCy from '../../utils/cypress'
import { ProductWitSubValues, TypeCol, TypeColumnConfiguration, objectType } from '../../store/types'
import { TypeFormError } from '../../format/errors/types'
import { sortListElement, updateOnlySelectedItem, updateParentSelectedItem, updateSelectedItem, updateSelectedSubItem } from '../../actions/list'
import { filterList } from '../../actions/requester'
import {
  listCategories,
  listTagsAssociations,
  listTaxesAssociations,
  listTaxes,
  selectedCompany,
  listOptionsAssociations,
  listCustomFieldsAssociations,
  listUserItemsNextToken,
  listShopsItemsNextToken,
  listPaymentMethodsItemsNextToken,
  isDrawerOpen,
  listInventoryMovementProductItems,
  currentDrawer
} from '../../store/selector'
import { ComponentSize, ComponentState, ComponentType } from '../newComponents/types'
import { TypeTable } from '../../types/list'
import { buildQuery } from '../requester/query'
import ListItemHeader from '../newComponents/listItem/header'
import DisplayIcon2 from '../newComponents/icons'
import ListItemCheckable from '../newComponents/listItem/checkable'
import ListItemContent from '../newComponents/listItem/content'
import ListItemMore from '../newComponents/listItem/more'
import ListItemAvatar from '../newComponents/listItem/avatar'
import ColumnSettings from '../newComponents/columnSettings'
import Button from '../newComponents/button'

import {
  CheckBoxChecked,
  CheckBoxSome,
  CheckBoxUnchecked,
  Close,
  ColumnSettings as CSIcon,
  KeyboardArrowDown,
  SortDown,
  SortNone,
  SortUp,
} from '../../assets/icons'
import styles from './Table.module.css'
import { Product } from '../../API'
import { getInventoryMovementAllVariants, getInventoryMovementVariant } from '../../getters/catalog/inventory'
import IconTouch from '../newComponents/icons/iconTouch'

const safeDisplay = (val: any) => {
  if (val)
    if (val.isValidElement) return <>{val}</>
    else return val.toString()
  return ''
}

/**
 *
 * This element displays a cell
 *
 * @param value - The entity
 * @param col - Cols to display
 * @param onEdit - A CTA when we edit a cell
 * @param editValues - A variable with all edited values (editable list)
 * @param errors - A TypeFormError object
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
export const Cell = ({
  value,
  index,
  col,
  onClick,
  onIconClick,
  onEdit,
  onBlur,
  editValues,
  error,
  hooks,
}: {
  value: any
  index: number
  col: TypeCol
  onClick?: () => void
  onIconClick?: () => void
  onEdit?: (val: any) => void
  onBlur?: () => void
  editValues?: any
  error?: TypeFormError
  hooks: any
}): JSX.Element => {
  const initialState: { val: string; edit?: any } = {
    val: '',
    edit: value,
  }
  const [state, setState] = useState(initialState)

  const handleEdit = (edit: any) => {
    setState({
      ...state,
      edit,
    })
    if (onEdit) onEdit(edit)
  }

  const handleNextFocus = () => {
    const elements = Array.from(document.querySelectorAll('input')).filter(
      (element) => element.getAttribute('disabled') === null
    )
    const index = elements.findIndex((element) => document.activeElement === element)

    if (index > -1 && elements[index + 1]) elements[index + 1].focus()
  }

  useEffect(() => {
    let val
    if (col.type === 'function') {
      if (col.function) {
        const retOrPromise = col.function(value)
        if (retOrPromise instanceof Promise) {
          retOrPromise.then((res) => setState({ val: res }))
        } else {
          val = retOrPromise
        }
      }
    } else {
      val = value
    }
    setState({ edit: value, val })
  }, [col, value])

  if (col.type === 'element' && col.function) {
    if (col.editable) {
            return col.function({
        index,
        onChange: handleEdit,
        onBlur: onBlur,
        onFocusNext: handleNextFocus,
        value: state.edit ?? value,
        editValues,
        validator: col.validator,
        error,
        currency: hooks.company ? hooks.company.currency : undefined,
        decimals: hooks.company ? hooks.company.currencyDecimals : undefined,
        taxes: hooks.taxes,
        categories: hooks.categories,
      })
    }
    return col.function({
      editValues,
      value,
      index,
      onClick: onClick,
      onIconClick: onIconClick,
      currency: hooks.company ? hooks.company.currency : undefined,
      decimals: hooks.company ? hooks.company.currencyDecimals : undefined,
      navigate: hooks.navigate,
      tagsAssociations: hooks.tagsAssociations,
      taxesAssociations: hooks.taxesAssociations,
      optionsAssociations: hooks.optionsAssociations,
      customFieldsAssociations: hooks.customFieldsAssociations,
      users: hooks.users,
      shops: hooks.shops,
      paymentMethods: hooks.paymentMethods,
      t: hooks.t.t,
      dispatch: hooks.dispatch,
    })
  }
  return safeDisplay(state.val)
}

/**
 *
 * This element displays a line
 *
 * @param el - The entity with selected value changed
 * @param val - The entity
 * @param cols - Cols to display
 * @param onCheck - A CTA when we check a line
 * @param onClick - A CTA when we click on a line
 * @param editValues - A variable with all edited values (editable list)
 * @param onEditCell - A CTA when we edit a cell
 * @param dispatch - A hook to call the store
 * @param errors - Errors
 * @param moreList - Elements to display in the actions menu
 * @param unSelectById - A method to call when we unselect entity while on editing mode
 * @param index - Index
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
export const Line = ({
  element,
  index,
  cols,
  onCheck,
  onClick,
  editValues,
  onEditCell,
  onBlurCell,
  errors,
  unSelectById,
  customClassName,
  displayEditableActions,
  hooks,
  type,
}: {
  element: any
  index: number
  cols: TypeColumnConfiguration
  onCheck: (element: Product) => (event: any) => void
  onClick: (subId?: string) => (event: any) => void
  onEditCell?: (colName: string) => (val: any) => void
  onBlurCell?: (colName: string) => () => void
  editValues?: any
  errors?: any
  unSelectById?: (ids: Array<string>, dispatch: any) => void
  customClassName?: string
  displayEditableActions?: boolean
  hooks: any
  type: string,
}): JSX.Element => {
  const [state, setState] = useState(ComponentState.DEFAULT)
  const dispatch = useDispatch();
  const drawerInfo = useSelector(currentDrawer)

  const rowStyle = () => {
    if (customClassName) return styles[customClassName]
  }

  const checkLine = (event: any) => {
    updateOnlySelectedItem(element, type, dispatch)
    onCheck(element)
  }

  const getStyle = (id: string, fieldType: string | undefined) => {
    if (id === 'color') {
      return styles.color
    }

    if (id === 'more') {
      return styles.more
    }

    if (fieldType === 'customField') {
      return styles.customFieldDate
    }

    if (id === '') {
      return '';
    }
  }

  return (
    <tr
      className={rowStyle()}
      tabIndex={0}
      onMouseEnter={() => setState(ComponentState.HOVER)}
      onMouseLeave={() => setState(ComponentState.DEFAULT)}
      style={{
        background: !cols.editable
          ? element.selected || state === ComponentState.FOCUSED || (drawerInfo.id === element.id && drawerInfo.open)
            ? 'var(--st-layer-color-default-focused)'
            : state === ComponentState.HOVER
            ? 'var(--st-layer-color-hover)'
            : undefined
          : undefined,
      }}
    >
      {cols.checkboxes && (
        <td
          className={styles.checkBox}
          onClick={checkLine}
          data-cy={generateDataCy({
            scope: 'list-row-' + index + '-checkbox',
            value: element.id,
          })}
        >
          <ListItemCheckable
            type={ComponentType.CONTENT}
            Icon={element.selected ? CheckBoxChecked : CheckBoxUnchecked}
            isSelected={element.selected}
            dataCy={generateDataCy({
              scope: 'list-row-' + index + '-checkbox-' + element.id,
              value: element.selected ? 'checked' : 'unchecked',
            })}
          />
        </td>
      )}
      {cols.cols &&
        cols.cols
          .filter((col) => col.active)
          .map((col, i) => (
            <td
              key={i}
              id={col.id}
              onClick={(event) => {
                setState(ComponentState.FOCUSED)
                onClick()(event)
              }}
              data-cy={`list-row-${index}-${col.id ?? col.title}`}
              className={col.id === 'color' ? styles.color : col.id === 'more' ? styles.more : ''}
              style={{ overflow: element.sourceID || element.address || col.id === "name" ? 'hidden' : 'auto' }}
            >
              {col.id === 'color' ? (
                <ListItemAvatar>
                  <Cell index={index} value={col.field ? element[col.field] : element} col={col} hooks={hooks} />
                </ListItemAvatar>
              ) : col.id === 'more' ? (
                <ListItemMore>
                  <Cell index={index} value={col.field ? element[col.field] : element} col={col} hooks={hooks} />
                </ListItemMore>
              ) : (
                <ListItemContent>
                  <Cell
                    value={col.field ? element[col.field] : element}
                    index={index}
                    col={col}
                    onEdit={onEditCell && onEditCell(col.id ?? col.title)}
                    onBlur={() => onBlurCell && onBlurCell(col.id ?? col.title)()}
                    editValues={editValues}
                    error={errors && errors.errors.find((e: any) => e.colID === col.id)?.error}
                    hooks={hooks}
                  />
                </ListItemContent>
              )}
            </td>
          ))}
      {cols.editable && displayEditableActions && (
        <td onClick={() => unSelectById && unSelectById([element.id], hooks.dispatch)}>
          <DisplayIcon2 Icon={Close} color={'var(--sys-color-content-interactive)'} />
        </td>
      )}
      {cols.customable && <td />}
    </tr>
  )
}

/**
 *
 * This element displays a drawer line
 *
 * @param el - The entity with selected value changed
 * @param val - The entity
 * @param cols - Cols to display
 * @param onClick - A CTA when we click on a line
 * @param onCheck - A CTA when we check a line
 * @param editValues - A variable with all edited values (editable list)
 * @param onEditCell - A CTA when we edit a cell
 * @param dispatch - A hook to call the store
 * @param unSelectById - A method to call when we unselect entity while on editing mode
 * @param unSelectSubById - A method to call when we unselect entity's sub values while on editing mode
 * @param index - Index
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
export const DrawerLine = ({
  element,
  cols,
  items,
  onClick,
  onCheck,
  errors,
  editValues,
  onEditCell,
  onBlurCell,
  unSelectById,
  unSelectSubById,
  index,
  displayEditableActions,
  hooks,
  type,
}: {
  element: any
  cols: TypeColumnConfiguration
  items?: Array<ProductWitSubValues>
  onClick: (subId?: string, dropdown?: boolean) => (event: any) => void
  onCheck: (element: Product) => (event: any) => void
  onEditCell?: (colName: string) => (val: any) => void
  onBlurCell?: (colName: string) => (variantID?: string) => void
  errors?: any
  editValues?: any
  unSelectById?: (ids: Array<string>, dispatch: any) => void
  unSelectSubById?: (elId: string, subId: string, type: objectType, dispatch: any) => void
  index: number
  displayEditableActions?: boolean
  hooks: any
  type: objectType,
}): JSX.Element => {
  const initialState: {
    dState: ComponentState
    show: boolean,
    editValues: any,
    subValues: Array<any>
    subValueLoaded: boolean
  } = {
    dState: ComponentState.DEFAULT,
    show: false,
    editValues: {},
    subValues: [],
    subValueLoaded: false,
  }
  const [state, setState] = useState(initialState)
  const drawerOpen = useSelector(isDrawerOpen);
  const inventoryMovementItems = useSelector(listInventoryMovementProductItems)
  const dispatch = useDispatch();
  useEffect(
    () =>
      setState({
        ...initialState,
        subValues: element.subValues.map(() => ({ state: ComponentState.DEFAULT })),
      }),
    [cols.editable]
  )


  const checkLine = (element: any) => (event: any) => {
    updateSelectedItem(element, dispatch)
    onCheck(element)
  }

  /**
   *
   * This method is called when we check a sub value checkbox
   * Its goal is to update parent entity, sub values entities
   * Then calling the props onCheck
   *
   * @param element - Parent entity
   * @param event - Event used by the props
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const checkSubline = (subEl: any) => (event: any) => {
    updateSelectedSubItem(subEl, dispatch)
    const count = element.subValues.filter((sub: { selected: boolean }) => sub.selected).length
    updateParentSelectedItem(element, count, dispatch)
    onCheck(element)
  }

  /**
   *
   * This method is called when we edit a cell in the editable list
   * It updates the editValues variable with the related cell id
   * Then call the props onEditCell
   *
   * @param colID - TypeCol id
   * @param elId - Entity id
   * @param val - Value typed in the cell
   *
   * @returns - void
   *
   * @author - Arthur Escriou
   *
   */
  const onEditVariant =
    ({ colID, elID }: { colID: string; elID: string }) =>
    (val: any) => {
      const oldValues = state.editValues
      const colNameValues = oldValues[elID]
      const editValues = {
        ...oldValues,
        [elID]: { ...colNameValues, [colID]: val },
      }
      if (errors && errors.subValues)
        errors.subValues
          .filter((subValue: { subId: string; errors: Array<any> }) => subValue.subId === elID)
          .map((subValue: { subId: string; errors: Array<any> }) =>
            subValue.errors
              .filter((colError: { col: string; error: TypeFormError | null }) => colError.col === colID)
              .map((colError: { col: string; error: TypeFormError | null }) => (colError.error = null))
          )
      setState({
        ...state,
        editValues,
      })
      onEditCell && onEditCell('subValues')(editValues)
    }

  const loadMoreSubvalues = async () => {
    console.log('Loading more values')
    if (type === objectType.PRODUCT) {
      if (element.subvalueNextToken || !element.subValues || element.subValues.length === 0 || element.subValues.length !== element.variantsCount) {
        // check if all subValues are selected to not load more variations already selected
        const allSubValuesSelected = element.selected && element.subValues.filter((item: any) => item.selected).length === element.subValues.length;
        const product = await loadVariations(
          { productID: element.id, selected: element.selected, nextToken: element.subvalueNextToken, limit: 10 },
          hooks.dispatch,
          //@ts-ignore
          items,
          allSubValuesSelected,
          cols.displaySelectedOnly,
        );
        return product
      }
    }
    if (type === objectType.INVENTORY_MOVE_PRODUCT) {
      if (element.variantsSelectedAll) {
        await getInventoryMovementAllVariants(element.id, dispatch, element.subvalueNextToken)
      } else {
        await getInventoryMovementVariant(element.id, dispatch, element.subvalueNextToken)
      }
    }
  }

  return (
    <>
      <tr
        tabIndex={0}
        onMouseEnter={() => setState((state) => ({ ...state, dState: ComponentState.HOVER }))}
        onMouseLeave={() => setState((state) => ({ ...state, dState: ComponentState.DEFAULT }))}
        style={{
          background: !cols.editable
            ? element.selected || state.dState === ComponentState.FOCUSED
              ? 'var(--st-layer-color-default-focused)'
              : state.dState === ComponentState.HOVER
              ? 'var(--st-layer-color-hover)'
              : undefined
            : undefined,
        }}
      >
        {cols.checkboxes && (
          <td
            className={styles.checkBox}
            onClick={async (event) => {
              checkLine(element)(event)
            }}
            data-cy={generateDataCy({
              scope: 'list-row-' + index + '-checkbox',
              value: element.id,
            })}
          >
            <ListItemCheckable
              type={ComponentType.CONTENT}
              Icon={element.selected ? (element.allSelected ? CheckBoxChecked : CheckBoxSome) : CheckBoxUnchecked}
              isSelected={element.selected}
              dataCy={generateDataCy({
                scope: 'list-row-' + index + '-checkbox-' + element.id,
                value: element.selected ? 'checked' : 'unchecked',
              })}
            />
          </td>
        )}
        {cols.cols &&
          cols.cols
            .filter((col) => col.active)
            .map((col, i) => (
              <td
                key={col.id}
                id={element.id + i + col.id ?? col.title}
                onClick={(e: any) => {
                  setState((state) => ({ ...state, dState: ComponentState.FOCUSED }))
                  if (col.id !== 'icon') onClick()({})
                }}
                data-cy={`list-row-${index}-${col.id ? col.id : col.title}`}
                className={col.id === 'color' ? styles.color : ''}
              >
                {col.id === 'color' ? (
                  <ListItemAvatar>
                    <Cell value={col.field ? element[col.field] : element} index={index} col={col} hooks={hooks} />
                  </ListItemAvatar>
                ) : col.id === 'name' ? (
                  <Cell
                    value={col.field ? element[col.field] : element}
                    index={index}
                    col={col}
                    hooks={hooks}
                  />
                ) : (
                  col.id === 'icon' ? <div className={styles.listItemIcon}><Cell value={element}
                    index={index}
                    col={col}
                    hooks={hooks}
                    onClick={() => {
                      setState((state) => ({ ...state, show: !state.show, dState: ComponentState.FOCUSED }))
                      if (element.subValues.length === 0) loadMoreSubvalues()
                    }}
                  /></div> :
                    <ListItemContent>
                      <Cell
                        value={col.field ? element[col.field] : element}
                        index={index}
                        col={col}
                        onEdit={onEditCell && onEditCell(col.id ? col.id : col.title)}
                        onBlur={() => onBlurCell && onBlurCell(col.id ? col.id : col.title)()}
                        error={errors && errors.errors.find((e: any) => e.colID === col.id)?.error}
                        editValues={editValues}
                        hooks={hooks}
                      />
                    </ListItemContent>
                )}
              </td>
            ))}
        {cols.editable && displayEditableActions && (
          <td onClick={() => unSelectById && unSelectById([element.id], hooks.dispatch)}>
            <DisplayIcon2 Icon={Close} color={'var(--sys-color-content-interactive)'} />
          </td>
        )}
        {cols.customable && <td />}
      </tr>
      {state.show &&
        element.subValues
          .filter((subEl: { selected: boolean }) => !cols.displaySelectedOnly || subEl.selected)
          .map((subEl: any, i: number) => (
            <tr
              key={subEl.id}
              className={styles.subLine}
              tabIndex={0}
              onMouseEnter={() => {
                setState({ ...state, subValues: state.subValues.map((item, index) => index === i ? { ...item, state: ComponentState.HOVER } : item) })
              }}
              onMouseLeave={() => {
                setState({ ...state, subValues: state.subValues.map((item, index) => index === i ? { ...item, state: ComponentState.DEFAULT } : item) })
              }}
              style={{
                background: !cols.editable
                  ? subEl.selected || state.subValues[i] === ComponentState.FOCUSED
                    ? 'var(--st-layer-color-default-focused)'
                    : state.subValues[i] === ComponentState.HOVER
                    ? 'var(--st-layer-color-hover)'
                    : undefined
                  : undefined,
              }}
            >
              {cols.checkboxes && (
                <td
                  className={styles.checkBox}
                  onClick={checkSubline(subEl)}
                  data-cy={generateDataCy({
                    scope: 'list-row-' + index + '-subrow-' + i + '-checkbox',
                    value: subEl.id,
                  })}
                >
                  <ListItemCheckable
                    type={ComponentType.CONTENT}
                    Icon={subEl.selected ? CheckBoxChecked : CheckBoxUnchecked}
                    isSelected={subEl.selected}
                    dataCy={generateDataCy({
                      scope: 'list-row-' + index + '-subrow-' + i + '-checkbox-' + subEl.id,
                      value: subEl.selected ? 'checked' : 'unchecked',
                    })}
                  />
                </td>
              )}
              {cols.cols &&
                cols.cols
                  .filter((col) => col.active)
                  .map((col, iCol) => (
                    <td
                      key={col.id}
                      id={subEl.id + iCol + col.id ?? col.title}
                      onClick={(event) => {
                        state.subValues[i] = ComponentState.FOCUSED
                        setState({ ...state })
                        onClick(subEl.id)(event)
                      }}
                      data-cy={`list-subrow-${index}-${i}-${col.id ? col.id : col.title}`}
                      className={col.id === 'color' ? styles.color : ''}
                    >
                      <ListItemContent>
                        <Cell
                          value={col.field ? subEl[col.field] : subEl}
                          index={subEl.id + i}
                          col={col}
                          onEdit={onEditVariant({
                            colID: col.id ? col.id : col.title,
                            elID: subEl.id,
                          })}
                          onBlur={() => onBlurCell && onBlurCell(col.id ? col.id : col.title)(subEl.id)}
                          editValues={editValues}
                          error={
                            errors &&
                            errors.errors
                              .find((e: any) => e.colID === col.id)
                              ?.variants?.find((e: any) => e.variantID === subEl.id)?.error
                          }
                          hooks={hooks}
                        />
                      </ListItemContent>
                    </td>
                  ))}
              {cols.editable && displayEditableActions && (
                <td />
                // <td onClick={() => {
                //   if (unSelectSubById) {
                //     unSelectSubById(element.id, subEl.id, type, hooks.dispatch)
                //   }
                // }}>
                //   <DisplayIcon2 Icon={Close} color={'var(--sys-color-content-interactive)'} />
                // </td>
              )}
              {cols.customable && <td />}
            </tr>
          ))}
      {state.show && type === objectType.INVENTORY_MOVE_PRODUCT && element.subvalueNextToken && (
        <tr className={styles.subLine}>
          <td colSpan={cols.cols.filter((col) => col.active).length + (cols.editable ? 1 : 2)} style={{ paddingRight: drawerOpen ? '35rem' : 0 }}>
            <Button
              dataCy={generateDataCy({ scope: `list-row-${index}`, value: 'loadVariants' })}
              title={hooks.t.t('button.LOAD_VARIANT')}
              onClick={loadMoreSubvalues}
              size={ComponentSize.MEDIUM}
              type={ComponentType.TERTIARY}
            />
          </td>
        </tr>
      )}
      {state.show && type === objectType.PRODUCT && cols.id === objectType.INVENTORY_MOVEMENT_PRODUCT && element.allSelected && element.subValues.length !== element.variantsCount && (
        <tr className={styles.subLine}>
          <td colSpan={cols.cols.filter((col) => col.active).length + (cols.editable ? 1 : 2)} style={{ paddingRight: drawerOpen ? '35rem' : 0 }}>
            <Button
              dataCy={generateDataCy({ scope: `list-row-${index}`, value: 'loadVariants' })}
              title={hooks.t.t('button.LOAD_VARIANT')}
              onClick={loadMoreSubvalues}
              size={ComponentSize.MEDIUM}
              type={ComponentType.TERTIARY}
            />
          </td>
        </tr>
      )}
      {state.show &&
        type === objectType.PRODUCT &&
        cols.id !== objectType.INVENTORY_MOVEMENT_PRODUCT &&
        element.subValues.length > 0 &&
        element.subValues.length !== element.variantsCount &&
        // element.subvalueNextToken &&
        (!cols.editable ||
          (cols.editable &&
            element.subValues.length ===
              element.subValues.filter((subValue: { selected: boolean }) => subValue.selected).length)) && (
          <tr className={styles.subLine}>
            <td colSpan={cols.cols.filter((col) => col.active).length + (cols.editable ? 1 : 2)} style={{ paddingRight: drawerOpen ? '35rem' : 0 }}>
              <Button
                dataCy={generateDataCy({ scope: `list-row-${index}`, value: 'loadVariants' })}
                title={hooks.t.t('button.LOAD_VARIANT')}
                onClick={() => loadMoreSubvalues()}
                size={ComponentSize.MEDIUM}
                type={ComponentType.TERTIARY}
              />
            </td>
          </tr>
        )}
    </>
  )
}

/**
 *
 * This element displays the main list
 *
 * @usedIn - List view
 *
 * @param editValues - A variable with all edited values (editable list)
 * @param onEditCell - A CTA when we edit a cell
 * @param cols - Cols to display
 * @param fullItems - Original list
 * @param items - Entity's items to display
 * @param clickLine A CTA when we click on a line
 * @param checkLine - A CTA when we check a line
 * @param toggleAll - A CTA when we check the "all" checkbox
 * @param selected - An array of boolean when we select entities
 * @param errors - Errors
 * @param moreList - Elements to display in the actions menu
 * @param unSelectById - A method to call when we unselect entity while on editing mode
 * @param unSelectSubById - A method to call when we unselect entity's sub values while on editing mode
 * @param type - Entity's type
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const Table: React.FC<TypeTable> = (props: TypeTable): JSX.Element => {
  const dispatch = useDispatch()
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()

  const hooks = {
    company: useSelector(selectedCompany),
    taxes: useSelector(listTaxes),
    categories: useSelector(listCategories),
    tagsAssociations: useSelector(listTagsAssociations),
    taxesAssociations: useSelector(listTaxesAssociations),
    optionsAssociations: useSelector(listOptionsAssociations),
    customFieldsAssociations: useSelector(listCustomFieldsAssociations),
    users: useSelector(listUserItemsNextToken),
    shops: useSelector(listShopsItemsNextToken),
    paymentMethods: useSelector(listPaymentMethodsItemsNextToken),
    navigate: useNavigate(),
    t: useTranslation(),
    dispatch: useDispatch(),
  }

  const onCheckAll = () => {
    if (props.toggleAll) props.toggleAll(!isPreFilteredList(props.items))
  }

  const onClick = (val: any) => (subId?: string, dropdown?: boolean) => (event: any) => {
    if (props.cols.clickable && props.clickLine) {
      props.clickLine(val, subId, dropdown)
    }
  }

  const onCheck = (val: Product) => (event: any) => {
    if (props.cols.checkboxes && props.checkLine) {
      props.checkLine(val)
    }
  }

  const isPreFilteredList = (items: Array<any>): boolean => items.filter((item) => item.selected).length > 0

  const getSelectedItems = () => props.fullItems!.filter((fItem) => fItem.selected)

  const sortCol = (field: string) => {
    const direction = props.sort?.field !== field ? 'asc' : props.sort?.direction === 'asc' ? 'desc' : 'asc'

    let query = {}
    if (props.requester && props.searchBar)
      query = {
        or: [
          buildQuery(props.requester, company.currencyDecimals),
          buildQuery(props.searchBar, company.currencyDecimals),
        ],
      }
    else if (props.requester) query = buildQuery(props.requester, company.currencyDecimals)
    else if (props.searchBar) query = buildQuery(props.searchBar, company.currencyDecimals)

    props.localSort
      ? sortListElement(dispatch, field, direction, props.type)
      : filterList({
          query,
          isEditable: false,
          items: props.items!,
          selectedItems: getSelectedItems(),
          dispatch,
          type: props.type,
          cols: props.cols,
          sort: {
            field,
            direction,
          },
        })
  }

  const isPartiallySelected = (items: Array<any>): boolean =>
    items.filter((item) => item.selected).length !== items.length

  const getError = (id: string) =>
    props.errors &&
    props.errors.length > 0 &&
    (id === 'name'
      ? props.errors.find((error: { rowID: string }) => error.rowID === 'global')
      : props.errors.find((error: { rowID: string }) => error.rowID === id))

  const getCustomClassName = (items: Array<any>, index: number) => {
    if (props.cols.customClassNames && props.cols.customClassNames.lastLine && index === items.length - 1) {
      return props.cols.customClassNames.lastLine
    }
    return undefined
  }

  return (
    <table className={styles.list} cellSpacing="0">
      <thead>
        <tr
          data-cy={generateDataCy({
            scope: 'list-row',
            value: 'columns',
          })}
        >
          {props.cols.checkboxes && (
            <th className={styles.checkBox} onClick={onCheckAll} data-cy="list-row-toggleAll-checkbox">
              <ListItemCheckable
                type={ComponentType.HEADER}
                Icon={
                  isPreFilteredList(props.items)
                    ? isPartiallySelected(props.items)
                      ? CheckBoxSome
                      : CheckBoxChecked
                    : CheckBoxUnchecked
                }
                isSelected={isPreFilteredList(props.items)}
                dataCy={generateDataCy({
                  scope: 'list-row-toggleAll-checkbox',
                  value: isPreFilteredList(props.items)
                    ? isPartiallySelected(props.items)
                      ? 'partial'
                      : 'checked'
                    : 'unchecked',
                })}
              />
            </th>
          )}
          {props.cols && props.cols.cols ? (
            props.cols.cols
              .filter((col) => col.active)
              .map((col, i) => (
                <th
                  key={col.id}
                  id={col.id + i}
                  className={col.id === 'color' ? styles.color : ''}
                  style={{
                    minWidth: col.editable ? '200px' : '100px',
                  }}
                  onClick={() => {
                    col.sortable && sortCol(col.id)
                  }}
                  data-cy={generateDataCy({
                    scope: 'list-row-all-col-' + i,
                    value: col.id,
                  })}
                >
                  <ListItemHeader
                    item={
                      t('list.col.' + col.title) !== 'list.col.' + col.title ? t('list.col.' + col.title) : col.title
                    }
                    trailIcons={[
                      col.title && col.sortable
                        ? props.sort?.field === col.id
                          ? props.sort.direction === 'asc'
                            ? SortUp
                            : SortDown
                          : SortNone
                        : undefined,
                    ]}
                  />
                </th>
              ))
          ) : (
            <></>
          )}
          {props.cols.customable && (
            <th className={styles.columnSettings} data-cy={generateDataCy({ scope: 'column', value: 'selector' })}>
              <ListItemHeader trailIcons={[CSIcon]} onClick={props.toggleColumnSettings} maxWidth={'48px'} />
              {props.columnSettings && (
                <ColumnSettings
                  cols={props.cols}
                  onChange={(cols: TypeColumnConfiguration) => props.filterColumns && props.filterColumns(cols)}
                  onBlur={props.toggleColumnSettings}
                />
              )}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.items.map(
          (val, i, items) =>
            (!props.cols.displaySelectedOnly || (props.selected && props.selected[i])) &&
            (val.variantsCount > 0 || val.variantsCountAll > 0 ? (
              <DrawerLine
                key={val.id}
                index={i}
                element={val}
                cols={props.cols}
                items={props.items}
                editValues={props.editValues && props.editValues[val.id]}
                onEditCell={props.onEditCell && props.onEditCell(val.id)}
                onBlurCell={props.onBlurCell && props.onBlurCell(val.id)}
                onClick={onClick(val)}
                //@ts-ignore
                onCheck={onCheck(val)}
                updateState={props.updateState}
                unSelectById={props.unSelectById}
                unSelectSubById={props.unSelectSubById}
                errors={getError(val.id)}
                displayEditableActions={props.displayEditableActions}
                hooks={hooks}
                type={props.type}
              />
            ) : (
              <Line
                key={val.id}
                index={i}
                element={val}
                cols={props.cols}
                customClassName={getCustomClassName(items, i)}
                editValues={props.editValues && props.editValues[val.id]}
                onEditCell={props.onEditCell && props.onEditCell(val.id)}
                onBlurCell={props.onBlurCell && props.onBlurCell(val.id)}
                onClick={onClick(val)}
                //@ts-ignore
                onCheck={onCheck(val)}
                unSelectById={props.unSelectById}
                errors={getError(val.id)}
                displayEditableActions={props.displayEditableActions}
                hooks={hooks}
                type={props.type}
              />
            ))
        )}
      </tbody>
    </table>
  )
}

export default Table
