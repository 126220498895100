import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { name as nameValidator, email as emailValidator } from '../../../format/errors/filters/setting/slot'
import { closeAndResetModal } from '../../../actions/modal'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { listShopsItemsNextToken } from '../../../store/selector'
import { ComponentErrorText, ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import Input from '../../../components/newComponents/input'
import { callService } from '../../../services'
import { CreateSlotInput, SendSlotEmailInput, UpdateSlotInput } from '../../../API'
import { createSlot, updateSlot } from '../../../graphql/custom/mutations'
import { handleBlur, handleChange } from '../../../format/form'
import generateDataCy from '../../../utils/cypress'
import { TypeSlot } from '../../../types'
import Button from '../../../components/newComponents/button'
import { DataWithErrors } from '../../../services/types'
import { parseName } from '../../../utils/string'
import { sendSlotEmail } from '../../../graphql/mutations'
import UniqueDropDown from '../../../components/newComponents/dropDown/unique'
import { getSlot } from '../../../getters/setting/slot'

import { KeyboardArrowDown } from '../../../assets/icons'
import styles from '../../../components/newComponents/modal/Modal.module.css'

const SlotModal = ({
  id,
  isCreate,
  isUpdate,
  isEmail,
}: {
  id?: string
  isCreate?: boolean
  isUpdate?: boolean
  isEmail?: boolean
}) => {
  const initialState: TypeSlot = {
    name: '',
    showSubmit: false,
    alreadyCreated: false,
    errors: {},
    validators: {
      name: nameValidator,
      email: emailValidator,
    },
  }
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [state, setState] = useState(initialState)
  const shops = useSelector(listShopsItemsNextToken)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cyContext = generateDataCy({ scope: 'settings', value: 'slot' })

  const errorText = () => {
    if (state.errors?.name?.value) {
      return state.errors.name.message
    }

    if (state.errors?.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  const setSlotInState = (data: DataWithErrors) => {
    if (data.data) {
      const slot = data.data

      setState({
        ...state,
        id: slot.id,
        name: slot.name,
        shopID: slot.shopID,
      })
    }
  }

  useEffect(() => {
    if (id) {
      getSlot(id, dispatch).then(setSlotInState)
    }
      }, [id])

  const handleResult = (res: any, ref: any) => {
    if (ref) {
      ref.current.disabled = false;
    }
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state, alreadyCreated: false, })
    } else {
      setState({ ...state, alreadyCreated: true, })
      closeAndResetModal(dispatch)
    }
  }

  const handleSubmit = (ref?: any) => {
    //@ts-ignore
    if (ref) {
      ref.current.disabled = true;
    }
    if (isCreate && !state.alreadyCreated) {
      state.showSubmit = false
      setState(handleBlur(state, 'shopID'))
      setState(handleBlur(state, 'name'))
      setState(handleBlur(state, 'email'))

      if (!state.shopID) {
        setState({
          ...state, errors: {
            shopID: {
              ...state.errors?.shopID,
              value: true,
              message: "boutique requise"
            }
          }
        })
      }

      if (!findErrorsInState(state.errors) && state.shopID && state.name) {
        callService<{ input: CreateSlotInput }>(
          { input: { shopID: state.shopID, name: parseName(state.name) } },
          createSlot,
          'createSlot'
        ).then((res) => {
          if (res.errors) {
            const newState = setErrorsInState(state, res.errors)
            state.errors = newState.errors
            state.showSubmit = newState.showSubmit
            setState({ ...state })
            if (ref) {
              ref.current.disabled = false;
            }
          }
          if (!res.errors && state.email)
            callService<{ input: SendSlotEmailInput }>(
              { input: { id: res.data.id, email: state.email } },
              sendSlotEmail,
              'sendSlotEmail'
            ).then((res) => handleResult(res, ref))
        })
      }
    } else if (isEmail) {
      setState({ ...handleBlur(state, 'email'), showSubmit: false })

      if (!findErrorsInState(state.errors) && state.id && state.email) {
        callService<{ input: SendSlotEmailInput }>(
          { input: { id: state.id, email: state.email } },
          sendSlotEmail,
          'sendSlotEmail'
        ).then((res) => handleResult(res, ref))
      }
    } else {
      setState({ ...handleBlur(state, 'name'), showSubmit: false })

      if (!findErrorsInState(state.errors) && state.name) {
        callService<{ input: UpdateSlotInput }>(
          { input: { id: state.id!, name: parseName(state.name) } },
          updateSlot,
          'updateSlot'
        ).then((res) => handleResult(res, ref))
      }
    }
  }

  return (
    <div
      className={styles.modal}
      data-cy={generateDataCy({
        scope: cyContext,
        value: isCreate ? 'create' : isUpdate ? 'update' : 'email',
      })}
    >
      <div className={styles.modalTitle}>
        {isEmail
          ? t('settings.slot.modal.SEND')
          : isUpdate
          ? t('settings.slot.modal.EDIT')
          : t('settings.slot.modal.CREATE')}
      </div>
      <div className={styles.modalContent}>
        {!isEmail && (
          <>
            <Input
              label={t('settings.slot.modal.NAME')}
              value={state.name}
              onChange={(event) => setState(handleChange(state, 'name')(event))}
              onBlur={() => setState(handleBlur(state, 'name'))}
              onSubmit={handleSubmit}
              style={{
                status: state.errors?.name?.value || state.errors?.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              }}
              helperText={errorText()}
              dataCy={generateDataCy({ scope: cyContext, value: 'name' })}
            />
            <UniqueDropDown
              parentID={'select'}
              popUpID={'selectPopUp'}
              Element={
                <Input
                  placeholder={'Sélectionner'}
                  value={
                    shops.items.length > 0 && shops.items.find((shop) => shop.id === state.shopID)
                      ? shops.items.find((shop) => shop.id === state.shopID).name
                      : ''
                  }
                  style={{
                    trailIcons: [{ icon: KeyboardArrowDown }],
                    state: isUpdate ? ComponentState.DISABLED : ComponentState.DEFAULT,
                    status: state.errors?.shopID?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                    readOnly: true,
                  }}
                  dataCy={generateDataCy({ scope: cyContext, value: 'shopID' })}
                  helperText={state.errors?.shopID?.value ? ComponentErrorText.IS_NEEDED : undefined}
                />
              }
              items={
                shops.items.length > 0
                  ? shops.items.map((shop) => ({
                      id: shop.id,
                      value: shop.name,
                      onClick: (event: any) => setState(handleChange(state, 'shopID')(event)),
                    }))
                  : []
              }
              state={id ? ComponentState.DISABLED : ComponentState.DEFAULT}
              dataCy={generateDataCy({ scope: cyContext, value: 'shopID' })}
            />
          </>
        )}
        {(isCreate || isEmail) && (
          <div className={styles.modalContent}>
            <div className={styles.modalInfo}>{t('settings.slot.modal.EXPLICATION')}</div>
            <Input
              label={t('settings.slot.modal.EMAIL')}
              value={state.email}
              onChange={(event) => setState(handleChange(state, 'email')(event))}
              onBlur={() => setState(handleBlur(state, 'email'))}
              onSubmit={handleSubmit}
              style={{
                status: state.errors?.email?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
              }}
              helperText={state.errors?.email?.value ? state.errors.email.message : undefined}
              dataCy={generateDataCy({ scope: cyContext, value: 'email' })}
            />
          </div>
        )}
      </div>
      <div className={styles.modalButtons}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
        />
        <Button
          ref={buttonRef}
          title={id && !isEmail ? t('button.SAVE') : t('button.SEND')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={(ref: any) => handleSubmit(ref)}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default SlotModal
