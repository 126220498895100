import { useSelector } from 'react-redux'

import List from '../../../components/list'
import { listTagItems, listTagItemsNextToken, listTags } from '../../../store/selector'
import { ListState, objectType } from '../../../store/types'
import { useDispatch } from 'react-redux'
import { getAllTags } from '../../../graphql/custom/queries'
import { useEffect } from 'react'
import { getData } from '../../../services'
import { fetchListElement, sortListElement, updateListState } from '../../../actions/list'
import { pluralize } from '../../../utils/typeToType'
import { getTotalProductTag, subscribeTags, unSubscribeTags } from '../../../getters/setting/tag'

const ListTags = () => {
  const res = useSelector(listTags)
  const items = useSelector(listTagItems)
  const { nextToken } = useSelector(listTagItemsNextToken)

  const dispatch = useDispatch()

  useEffect(() => {
    subscribeTags(dispatch)
    const getListTags = async () => {
      const listAllTags = await getData({ request: getAllTags, limit: 100 });
      if (listAllTags.listTags && listAllTags.listTags.items && listAllTags.listTags.items.length > 0) {
        const allTags = listAllTags.listTags.items.filter((_: any) => _)
        const updatedTags = await Promise.all(
          allTags.map(async (tag: any) => {
            const updatedTag = await getTotalProductTag(tag.name, tag);
            return updatedTag;
          })
        );
        fetchListElement(dispatch, updatedTags, listAllTags.listTags.nextToken, pluralize(objectType.TAG))
        sortListElement(dispatch, 'name', 'asc', objectType.TAG)
        updateListState(
          { type: pluralize(objectType.TAG), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.TAG))
        updateListState(
          { type: pluralize(objectType.TAG), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListTags();

    return () => unSubscribeTags();

  }, []);

  return <List cols={res.cols!} items={items} fullItems={items} nextToken={nextToken} type={objectType.TAG} />
}

export default ListTags
