const ChevronRight = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.1614 6.71063C8.7714 7.10063 8.7714 7.73063 9.1614 8.12063L13.0414 12.0006L9.1614 15.8806C8.7714 16.2706 8.7714 16.9006 9.1614 17.2906C9.5514 17.6806 10.1814 17.6806 10.5714 17.2906L15.1614 12.7006C15.5514 12.3106 15.5514 11.6806 15.1614 11.2906L10.5714 6.70063C10.1914 6.32063 9.5514 6.32063 9.1614 6.71063Z"
      fill={color}
    />
  </svg>
)

export default ChevronRight
