import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { sections as topSections, TypeSection, settings, account, Subsection } from './types'
import LogoTactill from '../newComponents/logoTactill'
import {
  ComponentType,
  ComponentSize,
  ComponentDirection,
  ComponentStatus,
  ComponentState,
} from '../newComponents/types'
import Divider from '../newComponents/divider'
import { DashboardView, Menu as TypeMenu, MenuState } from '../../store/types'
import ListItem from '../newComponents/listItem'
import {
  currentAccount,
  getDashboardView,
  getMenu,
  listCompaniesItemsNextToken,
  selectedCompany,
} from '../../store/selector'
import { displayConcatIconText } from '../../utils/string'
import generateDataCy from '../../utils/cypress'
import Button from '../newComponents/button'
import { switchCompany } from '../../actions/company'
import { signOut } from '../../services/auth'
import toggleMenu from '../../actions/menu'
import { resetActions } from '../../actions/app'
import DisplayIcon2 from '../newComponents/icons'
import { ColorPalette } from '../././../API'
import Avatar from '../newComponents/avatar'
import { enumToStr } from '../../utils/color'
// import List from '../newComponents/list'
import AlertBanner from '../newComponents/alertBanner'
import TextLink from '../newComponents/textLink'
import { getTestMode } from '../../services/localStorage'
import CompanyModal from '../../views/account/companies/modal'
import { updateModal } from '../../actions/modal'

import {
  Account,
  ChevronRight,
  Company,
  Exit,
  Settings,
  SwitchIcon,
  RadioButtonChecked,
  RadioButtonUnchecked,
  KeyboardArrowDown,
  KeyboardArrowUp,
  AddCircle,
  Check,
  Warning,
} from '../../assets/icons'
import styles from './Menu.module.css'

const getStyle = (id: string, menu: TypeMenu, isOpen: boolean, status?: ComponentStatus) => ({
  background:
    id === menu.subSection ||
    ((menu.state === MenuState.SHRINK || !isOpen) && (id === menu.section || id === menu.tmpSection))
      ? 'var(--st-layer-color-default-focused)'
      : '',
  color:
    id === menu.subSection ||
    ((menu.state === MenuState.SHRINK || !isOpen) && (id === menu.section || id === menu.tmpSection))
      ? 'var(--sys-color-content-interactive)'
      : status === ComponentStatus.DANGER
      ? 'var(--sys-color-content-danger)'
      : 'var(--sys-color-content-secondary)',
})

const UserCard = ({
  color,
  firstName,
  lastName,
  subTitle,
  onClick,
}: {
  color?: ColorPalette
  firstName?: string
  lastName?: string
  subTitle?: string
  onClick?: () => void
}) => {
  const menu = useSelector(getMenu)

  return (
    <div
      data-cy={generateDataCy({ scope: 'menu', value: 'profile' })}
      className={styles.menuProfile}
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        border: menu.state === MenuState.EXPAND && onClick ? '1px solid rgba(135, 146, 161, 0.3)' : 'none',
        padding: menu.state === MenuState.EXPAND ? '8px' : '9px 0',
      }}
    >
      <Avatar
        size={ComponentSize.SMALL}
        color={color ? enumToStr(color) : color}
        iconText={displayConcatIconText(firstName, lastName)}
      />
      <div className={styles.menuInfo} style={{ display: menu.state === MenuState.EXPAND ? 'flex' : 'none' }}>
        <div className={styles.menuName}>
          {firstName} {lastName}
        </div>
        <div className={styles.menuSubtitle}>{subTitle}</div>
      </div>
    </div>
  )
}

const SectionShrink = ({
  Icon,
  section,
  menu,
  dataCy,
}: {
  Icon?: any
  section: TypeSection & { isOpen: boolean; status?: ComponentStatus; TrailIcon?: any }
  menu: TypeMenu
  dataCy?: string
}): JSX.Element => {
  const [state, setState] = useState(ComponentState.DEFAULT)

  return (
    <div
      className={styles.sectionShrink}
      onMouseEnter={() => setState(ComponentState.HOVER)}
      onMouseLeave={() => setState(ComponentState.DEFAULT)}
      onClick={() => {
        setState(ComponentState.FOCUSED)
      }}
      data-cy={dataCy}
    >
      <DisplayIcon2
        Icon={Icon}
        color={
          state === ComponentState.FOCUSED || state === ComponentState.HOVER
            ? 'var(--sys-color-content-interactive)'
            : getStyle(section.id, menu, section.isOpen, section.status).color
        }
        width={`20px`}
        height={'20px'}
      />
    </div>
  )
}

const SectionExpand = ({
  section,
  menu,
}: {
  section: TypeSection & { isOpen: boolean; status?: ComponentStatus; TrailIcon?: any }
  menu: TypeMenu
}) => {
  const [state, setState] = useState(ComponentState.DEFAULT)

  return (
    <div
      className={styles.containerSectionExpand}
      onMouseEnter={() => setState(ComponentState.HOVER)}
      onMouseLeave={() => setState(ComponentState.DEFAULT)}
      onClick={() => {
        setState(ComponentState.FOCUSED)
      }}
    >
      <div className={styles.sectionExpand}>
        {section.LeadIcon && (
          <DisplayIcon2
            Icon={section.LeadIcon}
            color={
              state === ComponentState.HOVER || state === ComponentState.FOCUSED
                ? 'var(--sys-color-content-interactive)'
                : getStyle(section.id, menu, section.isOpen, section.status).color
            }
            width="20px"
            height="20px"
          />
        )}
        {section.title}
      </div>
      {section.subsections && section.TrailIcon && (
        <DisplayIcon2
          Icon={section.TrailIcon}
          color={
            state === ComponentState.HOVER || state === ComponentState.FOCUSED
              ? 'var(--sys-color-content-interactive)'
              : getStyle(section.id, menu, section.isOpen, section.status).color
          }
          width="20px"
          height="20px"
        />
      )}
    </div>
  )
}

const MenuItem = ({
  section,
  onClickSection,
  openView,
  dataCy,
}: {
  section: TypeSection & { isOpen: boolean; status?: ComponentStatus; TrailIcon?: any }
  onClickSection: (section: TypeSection) => void
  openView?: (subsection: any) => void
  dataCy?: string
}) => {
  const menu = useSelector(getMenu)

  return (
    <>
      <div
        tabIndex={0}
        className={styles.menuSection}
        style={getStyle(section.id, menu, section.isOpen, section.status)}
        onClick={() => onClickSection(section)}
        data-cy={dataCy}
      >
        {menu.state === MenuState.SHRINK ? (
          <SectionShrink Icon={section.LeadIcon} section={section} menu={menu} />
        ) : (
          <SectionExpand section={section} menu={menu} />
        )}
      </div>
      {section.subsections && section.isOpen && (
        <div className={styles.menuSubSection}>
          {section.subsections.map((subsection: Subsection) => (
            <div
              tabIndex={0}
              key={subsection.id}
              className={styles.menuSubSectionItem}
              style={{
                background:
                  section.id === menu.section && subsection.id === menu.subSection
                    ? 'var(--st-layer-color-default-focused)'
                    : '',
                color:
                  section.id === menu.section && subsection.id === menu.subSection
                    ? 'var(--sys-color-content-interactive)'
                    : 'var(--sys-color-content-secondary)',
              }}
              onClick={() => {
                openView && openView(subsection)
              }}
              data-cy={generateDataCy({ scope: dataCy!, value: subsection.id })}
            >
              {subsection.title}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

const DisplayMobileMenu = ({ sections }: { sections: Array<TypeSection> }) => {
  const initialState: {
    allSections?: Array<any>
    isNavigate: boolean
  } = {
    allSections: sections,
    isNavigate: false,
  }
  const [state, setState] = useState(initialState)
  const companies = useSelector(listCompaniesItemsNextToken)
  const company = useSelector(selectedCompany)
  const dashboardView = useSelector(getDashboardView)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'sidebar', value: 'menu' })

  const initMobileSections = () => [
    ...sections.map((s) => ({ ...s, TrailIcon: ChevronRight })),
    {
      id: 'company',
      title: company ? company.name : '',
      LeadIcon: Company,
      TrailIcon: SwitchIcon,
      subsections: companies.items.map((c) => ({
        id: c.id,
        title: c.name,
        sectionID: 'company',
        LeadIcon: c.id === (company ? company.id : '') ? RadioButtonChecked : RadioButtonUnchecked,
        action: (id: string) => switchCompany(id, t, dashboardView === DashboardView.MOBILE, dispatch),
      })),
    },
    {
      id: 'disconnect',
      title: t('menu.sections.DISCONNECT'),
      LeadIcon: Exit,
      status: ComponentStatus.DANGER,
      action: () => signOut(dispatch, logout),
    },
  ]

  useEffect(() => {
    setState({ ...state, allSections: initMobileSections() })
      }, [sections])

  const onClickSection = (section: TypeSection) => {
    if (section.subsections) {
      setState({ ...state, allSections: section.subsections, isNavigate: true })
    } else if (section.sectionID === 'company') {
      section.action && section.action(section.id)
    } else {
      toggleMenu(dispatch, {
        state: MenuState.CLOSE,
        section: section.sectionID,
        subSection: section.id,
        tmpSection: undefined,
      })
      section.action && section.action(navigate)
      resetActions(dispatch)
    }
  }

  return (
    <>
      <div className={styles.containerTopMenu}>
        <div className={styles.topMenu}>
          {state.isNavigate ? (
            <Button
              title={t('menu.BACK')}
              onClick={() => setState({ ...state, allSections: initMobileSections(), isNavigate: false })}
              size={ComponentSize.MEDIUM}
              type={ComponentType.TERTIARY}
              state={ComponentState.DEFAULT}
              status={ComponentStatus.DEFAULT}
              dataCy={generateDataCy({ scope: cyContext, value: 'button' })}
            />
          ) : (
            <>
              <LogoTactill type={ComponentType.LOGOICON} />
              <Button
                title={t('menu.CLOSE')}
                onClick={() => toggleMenu(dispatch, { state: MenuState.CLOSE })}
                size={ComponentSize.MEDIUM}
                type={ComponentType.TERTIARY}
                state={ComponentState.DEFAULT}
                status={ComponentStatus.DEFAULT}
                dataCy={generateDataCy({ scope: cyContext, value: 'button' })}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.containerSectionMobile}>
        {state.allSections?.map((section) => (
          <MenuItem key={section.id} section={section} onClickSection={onClickSection} />
        ))}
      </div>
    </>
  )
}

const DisplayMenu = ({
  switchMenu,
  sections,
}: {
  switchMenu: (sections: Array<any>) => void
  sections: Array<TypeSection & { isOpen: boolean }>
}) => {
  const menu = useSelector(getMenu)
  const initialState: {
    allSections: Array<TypeSection & { isOpen: boolean }>
    tmpSection?: TypeSection
  } = {
    allSections: sections,
  }
  const [state, setState] = useState(initialState)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'account', value: 'menu' })

  useEffect(() => {
    setState({
      ...state,
      allSections: sections,
    })
      }, [sections])

  const onClickSection = (section: TypeSection) => {
    if (section.subsections) {
      if (menu.state === MenuState.SHRINK) {
        toggleMenu(dispatch, { state: MenuState.EXPAND, tmpSection: section.id })
      }
    } else {
      section.action && section.action(navigate)
      toggleMenu(dispatch, { section: section.sectionID, subSection: section.id, tmpSection: undefined })
      resetActions(dispatch)
    }

    setState({
      ...state,
      allSections: state.allSections.map((s: any) => ({
        ...s,
        isOpen: s.id === section.id ? !s.isOpen : false,
        TrailIcon: s.id === section.id && s.TrailIcon === KeyboardArrowDown ? KeyboardArrowUp : KeyboardArrowDown,
      })),
    })
  }

  const openView = (subsection: Subsection) => {
    subsection.action(navigate)
    toggleMenu(dispatch, { section: subsection.sectionID, subSection: subsection.id, tmpSection: undefined })
    resetActions(dispatch)
  }

  return (
    <>
      <div className={styles.containerTopMenu}>
        <div className={styles.topMenu}>
          {menu.section === 'settings' || menu.section === 'account' ? (
            <Button
              title={t('menu.CLOSE')}
              onClick={() => {
                switchMenu(topSections(t))
                navigate('/statistic/general')
              }}
              size={ComponentSize.MEDIUM}
              type={ComponentType.TERTIARY}
              dataCy={generateDataCy({ scope: cyContext, value: 'close' })}
            />
          ) : (
            <LogoTactill
              type={menu.state === MenuState.EXPAND ? ComponentType.LOGOICONTEXT : ComponentType.LOGOICON}
              dataCy={generateDataCy({ scope: cyContext, value: 'logo' })}
            />
          )}
        </div>
      </div>
      <div className={styles.containerSection}>
        {state.allSections.map((section) => (
          <MenuItem
            key={section.id}
            section={section}
            onClickSection={onClickSection}
            openView={openView}
            dataCy={generateDataCy({ scope: 'menu', value: section.id || section.sectionID || '' })}
          />
        ))}
      </div>
    </>
  )
}

const BottomMenu = ({
  openSettings,
  openAccount,
  t,
  menu,
}: {
  openSettings: () => void
  openAccount: () => void
  t: (field: string) => string
  menu: TypeMenu
}) => {
  const initialState: {
    isOpen: boolean
    isOpenCompany: boolean
  } = {
    isOpen: false,
    isOpenCompany: false,
  }
  const [state, setState] = useState(initialState)
  const account = useSelector(currentAccount)
  const companies = useSelector(listCompaniesItemsNextToken)
  const company = useSelector(selectedCompany)
  const dashboardView = useSelector(getDashboardView)
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const openPopup = () => {
    toggleMenu(dispatch, { state: MenuState.EXPAND })
    setState({ ...state, isOpen: !state.isOpen })
  }

  const handleBlur = (event: any) => {
    if (
      !event.relatedTarget ||
      (event.relatedTarget &&
        event.relatedTarget.id !== 'bottomMenuID' &&
        event.relatedTarget.id !== 'bottomMenuCompanyListID' &&
        event.relatedTarget.id !== 'listItemIconID')
    )
      setState({ ...state, isOpen: false, isOpenCompany: false })
  }

  return (
    <div tabIndex={0} onBlur={handleBlur} className={styles.containerSectionBottom} style={{ gap: '1em' }}>
      {getTestMode() && (
        <>
          <AlertBanner
            title={menu.state !== MenuState.SHRINK ? t('menu.testMode.ACTIVATE') : undefined}
            LeadIcon={Warning}
            status={ComponentStatus.WARNING}
            dataCy={generateDataCy({"scope": "menu", "value": "testmodeInfo"})}
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TextLink
              text={t('menu.testMode.DESACTIVATE')}
              handleClick={() => {
                toggleMenu(dispatch, {
                  section: 'account',
                  subSection: 'testMode',
                  tmpSection: undefined,
                  state: MenuState.EXPAND,
                })
                navigate('/account/testMode')
              }}
              size={ComponentSize.EXTRASMALL}
            />
          </div>
          <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
        </>
      )}
      <UserCard
        color={account.color}
        firstName={account.firstName}
        lastName={account.lastName}
        subTitle={company ? company.name : ''}
        onClick={openPopup}
      />
      {state.isOpen && (
        <>
          <div id={'bottomMenuID'} className={styles.menuPopup}>
            <UserCard
              color={account.color}
              firstName={account.firstName}
              lastName={account.lastName}
              subTitle={account.email}
            />
            <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
            {[
              {
                id: 'account',
                value: t('menu.sections.ACCOUNT'),
                icon: Account,
                onClick: () => {
                  openAccount()
                  setState({ ...state, isOpen: false, isOpenCompany: false })
                },
              },
              {
                id: 'company',
                value: company ? company.name : '',
                icon: Company,
                trailIcons: [ChevronRight],
                onClick: () => setState({ ...state, isOpenCompany: !state.isOpenCompany }),
                onHover: () => {
                  setState({ ...state, isOpenCompany: true })
                },
              },
              {
                id: 'settings',
                value: t('menu.sections.SETTINGS'),
                icon: Settings,
                onClick: () => {
                  openSettings()
                  setState({ ...state, isOpen: false, isOpenCompany: false })
                },
              },
              {
                id: 'disconnect',
                value: t('menu.sections.DISCONNECT'),
                status: ComponentStatus.DANGER,
                icon: Exit,
                onClick: () => {
                  signOut(dispatch, logout)
                },
              },
            ].map((item, index, tab) => (
              <ListItem
                id={item.id}
                isLast={index === tab.length - 1}
                key={item.id}
                item={item.value}
                LeadIcon={item.icon}
                trailIcons={item.trailIcons}
                onClick={() => item.onClick && item.onClick()}
                onHover={() => item.onHover && item.onHover()}
                status={item.status ?? ComponentStatus.DEFAULT}
                dataCy={generateDataCy({ scope: 'bottom-menu', value: item.id.toLowerCase() })}
              />
            ))}
          </div>
          {state.isOpenCompany && (
            <div
              className={styles.menuCompanyList}
              id="bottomMenuCompanyListID"
              onMouseLeave={() => setState({ ...state, isOpenCompany: false })}
            >
              <ListItem
                item={t('menu.sections.CREATE_COMPANY')}
                LeadIcon={AddCircle}
                onClick={() => {
                  navigate('/account/companies')
                  updateModal(
                    dispatch,
                    true,
                    () => <CompanyModal navigate={navigate} />,
                    () => { }
                  )
                  toggleMenu(dispatch, { section: 'account', subSection: 'companies', tmpSection: undefined })
                  resetActions(dispatch)
                }}
                isFirst
              />
              <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
              <div className={styles.companyList}>
                {companies.items.map((c, index, tab) => (
                  <ListItem
                    key={c.id}
                    isLast={index === tab.length - 1}
                    item={c.name}
                    trailIcons={c.id === (company ? company.id : '') ? [Check] : []}
                    onClick={() => switchCompany(c.id, t, dashboardView === DashboardView.MOBILE, dispatch)}
                    dataCy={generateDataCy({ scope: 'bottom-menu-company', value: c.id.toLowerCase() })}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const Menu = () => {
  const initialState: {
    sections: Array<any>
  } = {
    sections: [],
  }
  const [state, setState] = useState(initialState)
  const menu = useSelector(getMenu)
  const dashboardView = useSelector(getDashboardView)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'sidebar', value: 'menu' })

  useEffect(() => {
    setState({
      ...state,
      sections:
        menu.section === 'settings'
          ? settings(t)
          : menu.section === 'account'
          ? account(t)
          : topSections(t).map((section) => ({
              ...section,
              TrailIcon:
                menu.state === MenuState.EXPAND &&
                ((!menu.tmpSection && section.id === menu.section) || section.id === menu.tmpSection)
                  ? KeyboardArrowUp
                  : KeyboardArrowDown,
              isOpen:
                menu.state === MenuState.SHRINK
                  ? false
                  : (!menu.tmpSection && section.id === menu.section) || section.id === menu.tmpSection,
            })),
    })
      }, [menu, topSections])

  const switchMenu = (sections: Array<any>) => setState({ ...state, sections })

  return (
    <div
      className={styles.menu}
      style={{
        width: dashboardView === DashboardView.MOBILE ? '100vw' : menu.state === MenuState.EXPAND ? '248px' : '4.5em',
        display: menu.state !== MenuState.CLOSE ? 'flex' : 'none',
      }}
      data-cy={generateDataCy({ scope: 'sidebar', value: 'menu' })}
    >
      <div>
        {dashboardView === DashboardView.MOBILE ? (
          <DisplayMobileMenu sections={state.sections} />
        ) : (
          <DisplayMenu switchMenu={switchMenu} sections={state.sections} />
        )}
      </div>
      {menu.section !== 'settings' && menu.section !== 'account' && dashboardView !== DashboardView.MOBILE && (
        <BottomMenu
          t={t}
          menu={menu}
          openSettings={() => {
            switchMenu(settings(t))
            navigate('/settings/shops')
            toggleMenu(dispatch, { section: 'settings', subSection: 'shops', tmpSection: undefined })
            resetActions(dispatch)
          }}
          openAccount={() => {
            switchMenu(account(t))
            navigate('/account/userAccount')
            toggleMenu(dispatch, { section: 'account', subSection: 'userAccount', tmpSection: undefined })
            resetActions(dispatch)
          }}
        />
      )}
    </div>
  )
}

export default Menu
