import { TypePack } from '../../../../../types'
import {
  updatePackCustomField,
  deletePackCustomField,
  createPackCustomField,
  callCustomFieldsUpdate,
} from '../../../../../services/setting/customField'
import { DataWithErrors } from '../../../../../services/types'
import { buildTemporaryErrors, createInput, isNestedDirty } from '../../../../../utils/dirties'
import { DeleteImageInput, ImageModelType, UpdatePackInput, UploadImageInput } from '../../../../../API'
import { callService } from '../../../../../services'
import { updatePack } from '../../../../../graphql/custom/mutations'
import { convertPrice } from '../../../../../utils/number'
import { deleteImage, uploadImage } from '../../../../../graphql/mutations'

const callPackUpdate = async (payload: TypePack, decimals: number) => {
  if (payload.id) {
    let res: DataWithErrors = {}
    const input: any = createInput(payload, decimals)

    if (Object.keys(input).length > 1) {
      if (input.packVariations)
        input.packVariations = input.packVariations.map((packOption: any) => ({
          options: packOption!.options.map((option: any) => ({
            productID: option.productID,
            quantity: parseInt(option.quantity, 10),
            additionalPrice: option.price ? convertPrice(decimals, option.price) : 0,
          })),
        }))
      res = await callService<{ input: UpdatePackInput }>({ input }, updatePack, 'updatePack')
      if (res.errors) return res
    }

    const toWait: Array<Promise<any>> = []

    if (isNestedDirty(payload.dirties, 'photo') || isNestedDirty(payload.dirties, 'color')) {
      if (payload.photo && payload.selected === 'photo')
        toWait.push(
          callService<{ input: UploadImageInput }>(
            { input: { id: payload.id, type: ImageModelType.PACK, base64Image: payload.photo } },
            uploadImage,
            'uploadImage'
          )
        )
      else
        toWait.push(
          callService<{ input: DeleteImageInput }>(
            { input: { id: payload.id, type: ImageModelType.PACK } },
            deleteImage,
            'deleteImage'
          )
        )
    }

    if (isNestedDirty(payload.dirties, 'customFields')) {
      toWait.push(
        callCustomFieldsUpdate(payload.customFields, {
          id: { packID: payload.id },
          create: createPackCustomField,
          update: updatePackCustomField,
          delete: deletePackCustomField,
        })
      )
    }

    res.errors = await buildTemporaryErrors(toWait, res.errors)

    return res
  }
  return null
}

export default callPackUpdate
