import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import List from '../../../components/list'
import { listPayments, listPaymentItems, listPaymentItemsNextToken, getDashboardView } from '../../../store/selector'
import { ListState, objectType } from '../../../store/types'
import { Drawer } from './drawer'
import { buildCols } from '../../../utils'
import { TypeComparator } from '../../../components/requester/types'
import paymentFieldsRequester from '../../../requester/activity/paiement'
import { getData } from '../../../services'
import { getListPayments } from '../../../graphql/custom/queries'
import { useDispatch } from 'react-redux'
import { pluralize } from '../../../utils/typeToType'
import { fetchListElement, updateListState } from '../../../actions/list'
import { subscribePayments, unSubscribePayments } from '../../../getters/activity/payment'

const ListPayments = () => {
  const res = useSelector(listPayments)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listPaymentItems)
  const { items, nextToken, sort } = useSelector(listPaymentItemsNextToken)
  const dashboardView = useSelector(getDashboardView)
  const { t } = useTranslation()
  const dispatch = useDispatch();

  useEffect(() => {
    subscribePayments(dispatch)

    const getListPaymentsItems = async () => {
      const listSalesPayments = await getData({ request: getListPayments, limit: 100 });
      if (listSalesPayments.requestPayments && listSalesPayments.requestPayments.items && listSalesPayments.requestPayments.items.length > 0) {
        const payments = listSalesPayments.requestPayments.items.filter((_: any) => _)
        fetchListElement(dispatch, payments, listSalesPayments.requestPayments.nextToken, pluralize(objectType.PAYMENT))
        updateListState(
          { type: pluralize(objectType.PAYMENT), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.PAYMENT))
        updateListState(
          { type: pluralize(objectType.PAYMENT), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListPaymentsItems();

    return () => unSubscribePayments();
      }, [])

  useEffect(() => setCols(buildCols(res.cols!, 'number', 'amount', dashboardView)), [dashboardView, res.cols])

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={paymentFieldsRequester(t)}
      searchBarFields={[{ filter: TypeComparator.EQ, field: 'number' }]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.PAYMENT}
      Drawer={Drawer}
    />
  )
}

export default ListPayments
