import { useTranslation } from 'react-i18next'

import toggleMenu from '../../../actions/menu'
import TextLink from '../../newComponents/textLink'
import { ComponentSize, ComponentState } from '../../newComponents/types'
import { buildRequester } from '../../requester/query'
import { TypeComparator, TypePath, TypeRequesterFilters } from '../../requester/types'

type Props = {
  filterID: string
  field: string
  path: Array<string>
  filters: Array<TypeRequesterFilters>
  value: string | number
  count: number
  navigate: any
  dispatch: any
}

/**
 *
 * This element displays a link to requester cell
 *
 * @usedIn - Settings taxes/tags list
 *
 * @param field - Field concerned by the requester (tag, tax)
 * @param value - Field's value for the requester (name, rate)
 * @param count - The number of associations to products
 * @param history - A hook to navigate
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const LinkToRequester = ({ filterID, field, path, filters, value, count, navigate, dispatch }: Props): JSX.Element => {
  const { t } = useTranslation()

  const goToListEntity = () => {
    const link = buildRequester({
      filterID,
      field,
      type: TypePath.PLAIN,
      path,
      filters,
      comparator: TypeComparator.EQ,
      value,
    })
    toggleMenu(dispatch, { section: 'catalog', subSection: 'products' })
    navigate(`/catalog/products?r=` + JSON.stringify(link))
  }

  return (
    <TextLink
      text={
        count > 0
          ? count > 1 ? t('settings.tax.LIST_LINK_PLURAL') : t('settings.tax.LIST_LINK_SINGULAR')
          : t('list.cell.NONE')
      }
      state={count === 0 ? ComponentState.DISABLED : ComponentState.DEFAULT}
      handleClick={() => count > 0 && goToListEntity()}
      size={ComponentSize.SMALL}
    />
  )
}

const LinkToRequesterCell = ({ filterID, field, path, filters, value, count, navigate, dispatch }: Props) => (
  <LinkToRequester
    filterID={filterID}
    field={field}
    path={path}
    filters={filters}
    value={value}
    count={count}
    navigate={navigate}
    dispatch={dispatch}
  />
)

export default LinkToRequesterCell
