const ColumnSettings = ({ color }: { color: string }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.67 6.59961V18.5996C14.67 19.1496 14.22 19.5996 13.67 19.5996H10.34C9.79 19.5996 9.34 19.1496 9.34 18.5996V6.59961C9.34 6.04961 9.79 5.59961 10.34 5.59961H13.67C14.22 5.59961 14.67 6.04961 14.67 6.59961ZM16.67 19.5996H20C20.55 19.5996 21 19.1496 21 18.5996V6.59961C21 6.04961 20.55 5.59961 20 5.59961H16.67C16.12 5.59961 15.67 6.04961 15.67 6.59961V18.5996C15.67 19.1496 16.11 19.5996 16.67 19.5996ZM8.33 18.5996V6.59961C8.33 6.04961 7.88 5.59961 7.33 5.59961H4C3.45 5.59961 3 6.04961 3 6.59961V18.5996C3 19.1496 3.45 19.5996 4 19.5996H7.33C7.89 19.5996 8.33 19.1496 8.33 18.5996Z"
      fill={color}
    />
  </svg>
)

export default ColumnSettings
