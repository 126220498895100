import { TypeTab } from '../../../types/drawer'
import generateDataCy from '../../../utils/cypress'
import Tab from '../tab'
import { ComponentSize } from '../types'

import styles from './TabList.module.css'

type Props = {
  tabList: Array<TypeTab>
  onClick: (tab: TypeTab) => void
  size: ComponentSize
  dataCy: string
}

/**
 *
 * This element displays a list of tabs inside drawer form
 *
 * @usedIn - Drawer form
 *
 * @param tabList - An array of tabs (fidelity, account movement..)
 * @param onClick - A CTA when we click on a tab
 * @param size - TabList's size
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const TabList = ({ tabList, onClick, size, dataCy }: Props): JSX.Element => (
  <div className={styles.tabList}>
    {tabList.map((tab) => (
      <Tab
        key={tab.id}
        tab={tab}
        onClick={(tab) => onClick && onClick(tab)}
        size={size}
        state={tab.state}
        dataCy={generateDataCy({ scope: dataCy, value: tab.id })}
      />
    ))}
  </div>
)

export default TabList
