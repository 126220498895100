import { ListState, objectPlural, objectType, StateStore, SubValues } from './types';
import { getCategoriesAssociations } from '../services/catalog/category';
import { getCustomFieldsAssociations } from '../services/setting/customField';
import { getTagsAssociations } from '../services/setting/tag';
import { getTaxesAssociations } from '../services/setting/tax';
import { subscribeTags, unSubscribeTags } from '../getters/setting/tag';
import { subscribeTaxes, unSubscribeTaxes } from '../getters/setting/tax';
import {
  subscribePackCustomFields,
  subscribePacks,
  unSubscribePacks,
  unSubscribePackCustomFields,
} from '../getters/catalog/pack';
import {
  subscribeProductCustomFields,
  subscribeProducts,
  subscribeProductInventoryQuantities,
  subscribeProductTags,
  unSubscribeProducts,
  unSubscribeProductTags,
  unSubscribeProductCustomFields,
  unSubscribeProductInventoryQuantities,
} from '../getters/catalog/product';
import { subscribeDiscounts, unSubscribeDiscounts } from '../getters/catalog/discount';
import {
  subscribeCategories,
  subscribeCategoryCustomFields,
  unSubscribeCategories,
  unSubscribeCategoryCustomFields,
} from '../getters/catalog/category';
import { subscribeCustomFields, unSubscribeCustomFields } from '../getters/setting/customField';
import { subscribeSales, unSubscribeSales } from '../getters/activity/sale';
import { subscribeCashbooks, unSubscribeCashbooks } from '../getters/activity/cashbook';
import {
  subscribeCustomers,
  unSubscribeCustomers,
  subscribeCustomerCustomFields,
  unSubscribeCustomerCustomFields,
} from '../getters/customer/customer';
import { subscribeShops, unSubscribeShops } from '../getters/setting/shop';
import { subscribePayments, unSubscribePayments } from '../getters/activity/payment';
import { subscribeOptions, unSubscribeOptions } from '../getters/catalog/option';
import { pluralize } from '../utils/typeToType';
import { fetchListElement, sortList, updateListState } from '../actions/list';
import { subscribeProductVariants, unSubscribeProductVariants } from '../getters/catalog/variant';
import { subscribeInventoryMovements, unSubscribeInventoryMovements } from '../getters/catalog/inventory';
import { subscribeSlots, unSubscribeSlots } from '../getters/setting/slot';
import { subscribePaymentMethods, unSubscribePaymentMethods } from '../getters/setting/paymentmethod';
import { subscribeBackgroundJobs, unSubscribeBackgroundJobs } from '../getters/setting/import';
import { subscribeInvitations, unSubscribeInvitations } from '../getters/setting/user';
import { getData } from '../services';

const subscribeToAll = (shops: Array<any>, dispatch: any) => {
  // subscribeCategories(dispatch)
  // subscribeDiscounts(dispatch)
  // subscribePacks(dispatch)
  // subscribeSlots(shops, dispatch)
  // subscribeOptions(dispatch)
  subscribeInventoryMovements(shops, dispatch);
  // subscribeCustomers(dispatch)
  // subscribeCashbooks(dispatch)
  // subscribeSales(dispatch)
  // subscribePayments(dispatch)
  // subscribeBackgroundJobs(dispatch)
  // subscribeInvitations(dispatch)
  // subscribeTaxes(dispatch)
  // subscribeTags(dispatch)
  // subscribeProducts(dispatch)
  // subscribeProductTags(dispatch)
  // subscribeProductCustomFields(dispatch)
  // subscribeCustomerCustomFields(dispatch)
  // subscribeCategoryCustomFields(dispatch)
  // subscribePackCustomFields(dispatch)
  // subscribeProductVariants(dispatch)
  // subscribeProductInventoryQuantities(shops, dispatch)

  //! à garder
  subscribeCustomFields(dispatch);
  subscribePaymentMethods(dispatch);
  subscribeShops(dispatch);
};

const unSubscribeToAll = () => {
  // unSubscribeCategories()
  // unSubscribeDiscounts()
  // unSubscribeOptions()
  // unSubscribePacks()
  // unSubscribeSlots()
  unSubscribeInventoryMovements();
  // unSubscribeCustomers()
  // unSubscribeCashbooks()
  // unSubscribeSales()
  // unSubscribePayments()
  // unSubscribeBackgroundJobs()
  // unSubscribeInvitations()
  // unSubscribeTaxes()
  // unSubscribeTags()
  // unSubscribeProducts()
  // unSubscribeProductTags()
  // unSubscribeProductCustomFields()
  // unSubscribeCustomerCustomFields()
  // unSubscribeCategoryCustomFields()
  // unSubscribePackCustomFields()
  // unSubscribeProductVariants()
  // unSubscribeProductInventoryQuantities()

  //! à garder
  unSubscribeCustomFields();
  unSubscribePaymentMethods();
  unSubscribeShops();
};

/**
 *
 * This method recreates a new entity from the store
 *
 * @author - Arthur Escriou
 *
 */
const deepclone = ({ state, pluralType }: { state: any; pluralType: objectPlural }) => {
  state[pluralType].items.items = [...state[pluralType].items.items];
  state[pluralType] = {
    ...state[pluralType],
  };
};

/**
 *
 * This method check if an element added to the store is matching the requester
 *
 * return true or false depending on the requester and element sent
 *
 *
 * @usedIn - Subscriptions
 *
 * @param element - The updated element
 * @param query - the query
 *
 * @returns - a boolean
 *
 * @author - David Faure
 *
 */

export const elementMatchesQuery = (element: any, query: any) => {
  if (!query || !query.or) return false;

  return query.or.some((criteria: any) => {
    if (criteria.name && (element.name || '').includes(criteria.name.contains)) {
      return true;
    }
    if (criteria.reference && (element.reference || '').includes(criteria.reference.contains)) {
      return true;
    }
    return false;
  });
};

/**
 *
 * This method is called when we want to load more variations during a inventory movement process.
 * We get objectPlural (translation of entity from the store)
 * Then we update this parent product inside the existing entity's elements by adding new subValues.
 * We also keep the new subValueNextToken for the next load if needed.
 *
 * @usedIn - Subscriptions
 *
 * @param state - The store
 * @param element - The updated sub values element
 * @param id - The product's id
 * @param type - Its type
 *
 * @returns - A new store
 *
 * @author - David Faure
 *
 */

const addVariantInventoryMovement = ({
  state,
  element,
  id,
  type,
}: {
  state: StateStore;
  element: any;
  id: string;
  type: objectType;
}): StateStore => {
  const objectPlural = pluralize(type);
  return {
    ...state,
    // @ts-ignore
    [objectPlural]: {
      ...state[objectPlural],
      items: {
        ...state[objectPlural].items,
        items: state[objectPlural].items.items.map((item: any) => {
          if (item.id === id) {
            return {
              ...item,
              subValues: [...item.subValues, ...element.items],
              subvalueNextToken: element.nextToken,
            };
          }
          return item;
        }),
      },
    },
  };
};

const addLinesInventoryMovement = ({
  state,
  element,
  id,
  type,
}: {
  state: StateStore;
  element: any;
  id: string;
  type: objectType;
}): StateStore => {
  const objectPlural = pluralize(type);
  const addLines = (currentMovement: any) => {
    //! TU DOIS ARRETER D'UTILISER LE STATE.MOVMENTS ET PRENDRE DANS REDUX

    const currentMovementProductDetail = currentMovement.find((item: any) => item.productID === element.productID);

    const newMovement = element.variantsAll.items.map((variant: any) => {
      return {
        id: null,
        inventoryMovementID: element.inventoryMovementID,
        productID: variant.productID,
        variantID: variant.variantID,
        details: {
          ...currentMovementProductDetail.details,
          variant: {
            ...variant.details,
          },
        },
        quantity: null,
        price: null,
      };
    });

    return [...currentMovement, ...newMovement];
  };

  return {
    ...state,
    // @ts-ignore
    [objectPlural]: {
      ...state[objectPlural],
      items: {
        ...state[objectPlural].items,
        items: state[objectPlural].items.items.map((item: any) => {
          if (item.id === element.inventoryMovementID) {
            return {
              ...item,
              movements: {
                ...item.movements,
                items: addLines(item.movements.items),
              },
            };
          }
          return item;
        }),
      },
    },
  };
};

/**
 *
 * This method is called when we want to load more product during a inventory movement process.
 * We get objectPlural (translation of entity from the store)
 * We check for duplicate if any and add to the inventory movement.
 * We also keep the new NextToken for the next load if needed.
 *
 * @usedIn - Subscriptions
 *
 * @param state - The store
 * @param element - The updated elements + nextToken
 * @param type - Its type
 *
 * @returns - A new store
 *
 * @author - David Faure
 *
 */

const addInventoryMovement = ({
  state,
  element,
  type,
}: {
  state: StateStore;
  element: any;
  type: objectType;
}): StateStore => {
  const objectPlural = pluralize(type);
  const existingItems = state[objectPlural].items.items;
  const newItems = element.items;

  const existingItemIds = new Set(existingItems.map((item: any) => item.id));

  const uniqueNewItems = newItems.filter((item: any) => !existingItemIds.has(item.id));

  return {
    ...state,
    [objectPlural]: {
      ...state[objectPlural],
      items: {
        ...state[objectPlural].items,
        items: [...existingItems, ...uniqueNewItems],
        nextToken: element.nextToken,
      },
    },
  };
};

const checkSubValuesSelected = (items: any[], variantsCount: number) => {
  //@ts-ignore
  return items.subValues && items.subValues.filter((item: any) => item.selected).length === variantsCount;
};

/**
 *
 * This method is called when we create/update an entity's element in the list
 * We get objectPlural (translation of entity from the store)
 * Then we update this element inside the existing entity's elements
 * Finally recounting category, tax, custom field and tag associations (checking how many products has this category..)
 *
 * If we update a custom field, we update it from custom field's filteredItems
 *
 * If we update a tag, we update products related to that tag and filteredItems
 *
 * @usedIn - Subscriptions
 *
 * @param state - The store
 * @param element - The updated element
 * @param type - Its type
 *
 * @returns - A new store
 *
 * @author - Both Arthur Escriou and Jennifer Charlois
 *
 */
const updateListElement = ({
  state,
  element,
  type,
}: {
  state: StateStore;
  element: any;
  type: objectType;
}): StateStore => {
  const objectPlural = pluralize(type);
  // @ts-ignore
  const elementType = state[objectPlural];

  const index = elementType.items.items.findIndex(({ id }: { id: string }) => id === element.id);

  if (index >= 0) {
    elementType.items.items.splice(index, 1, element);
  } else {
    elementType.items.items = [...elementType.items.items, element];
    //! re probleme de sort ? ici !!
    elementType.items.items = sortList(elementType.cols, elementType.items);
  }

  if (elementType.filteredItems) {
    const filteredIndex = elementType.filteredItems.items.findIndex(({ id }: { id: string }) => id === element.id);
    if (elementType.query) {
      const doesMatch = elementMatchesQuery(element, elementType.query);
      if (doesMatch) {
        if (filteredIndex >= 0) {
          elementType.filteredItems.items.splice(filteredIndex, 1, element);
        } else {
          elementType.filteredItems.items = [...elementType.filteredItems.items, element];
        }
        elementType.filteredItems.items = sortList(elementType.cols, elementType.filteredItems);
      } else {
        if (filteredIndex >= 0) {
          elementType.filteredItems.items.splice(filteredIndex, 1, element);
        } else {
          elementType.filteredItems.items = [...elementType.filteredItems.items, element];
        }
        elementType.filteredItems.items = sortList(elementType.cols, elementType.filteredItems);
      }
    } else {
      if (filteredIndex >= 0) {
        elementType.filteredItems.items.splice(filteredIndex, 1, element);
      } else {
        elementType.filteredItems.items = [...elementType.filteredItems.items, element];
      }
      elementType.filteredItems.items = sortList(elementType.cols, elementType.filteredItems);
    }
  }

  if (type === objectType.PRODUCT) {
    const checkUndefinedElement = elementType.items.items.find(item => !item.subValues);
    const elementToCheck = elementType.items.items.find(item => item.id === element.id);
    const allSubValuesSelected = checkSubValuesSelected(elementToCheck, elementToCheck.variantsCount);

    if (allSubValuesSelected) {
      elementType.items.items = elementType.items.items.map(item => {
        if (item.id === element.id) {
          return {
            ...item,
            allSelected: true,
          };
        }
        return item;
      });
    }
    //! re probleme de sort ? ici !!
    // elementType.items.items = sortList(elementType.cols, elementType.items)
    // const ids: Array<any> = []
    // const elementAllSelected = elementType.items.items
    //   .filter((item: any) => item.subValues.length > 0)
    //   .filter((item) => item.subValues.filter((v: any) => v.selected).length === item.subValues.length)
    // elementAllSelected.forEach((item) =>
    //   elementType.items.items.forEach((i) => {
    //     if (i.id === item.id) {
    //       i.selected = true
    //     }
    //   })
    // )
    if (checkUndefinedElement) {
      elementType.items.items = elementType.items.items.filter(item => item.subValues);
      //! WARNING je dois recheck les subValues selected
    }
  }

  if (
    type === objectType.CUSTOM_FIELD &&
    state.customFields.filteredItems &&
    state.customFields.filteredItems.type === element.objectType.toLowerCase()
  ) {
    // state = updateCustomFieldOnFilteredItems(state, element)
  } else if (type === objectType.TAG && state.tags.filteredItems) {
    // state = updateTagOnFilteredItems(state, element)
  }
  state.categoriesAssociations = getCategoriesAssociations(state.products.items.items);
  state.tagsAssociations = getTagsAssociations(state.products.items.items);
  state.customFieldsAssociations = getCustomFieldsAssociations([
    { type: 'products', items: state.products.items.items },
    { type: 'categories', items: state.categories.items.items },
    { type: 'packs', items: state.packs.items.items },
    { type: 'customers', items: state.customers.items.items },
  ]);
  state.taxesAssociations = getTaxesAssociations(state.products.items.items);

  deepclone({ state, pluralType: objectPlural });
  return state;
};

/**
 *
 * This method is called when we delete an entity's element in the list
 * We get objectPlural (translation of entity from the store)
 * Then we delete this element from the existing entity's elements
 * Finally recounting category, tax, custom field and tag associations (checking how many products has this category..)
 *
 * If we delete a custom field, we delete it from custom field's filteredItems
 *
 * If we delete a tag, we delete it from tag's filteredItems
 *
 * @usedIn - Subscriptions
 *
 * @param state - The store
 * @param element - The deleted element
 * @param type - Its type
 *
 * @returns - A new store
 *
 * @author - Both Arthur Escriou and Jennifer Charlois
 *
 */
const deleteListElement = ({
  state,
  element,
  type,
}: {
  state: StateStore;
  element: any;
  type: objectType;
}): StateStore => {
  const objectPlural = pluralize(type);
  // @ts-ignore
  const elementType = state[objectPlural];
  // @ts-ignore
  const index = elementType.items.items.findIndex(({ id }: { id: string }) => id === element.id);
  if (index >= 0) {
    elementType.items = {
      ...elementType.items,
      items: elementType.items.items.filter((_: any, i: number) => i !== index),
    };

    if (elementType.filteredItems) {
      const filteredIndex = elementType.filteredItems.items.findIndex(({ id }: { id: any }) => id === element.id);
      elementType.filteredItems = {
        ...elementType.filteredItems,
        items: elementType.filteredItems.items.filter((_: any, i: number) => i !== filteredIndex),
      };
    }

    if (type === objectType.CUSTOM_FIELD && state.customFields.filteredItems) {
      // state = deleteCustomFieldOnFilteredItems(state, element.id)
    } else if (type === objectType.TAG && state.tags.filteredItems) {
      // state = deleteTagOnFilteredItems(state, element.id)
    }
    state.categoriesAssociations = getCategoriesAssociations(state.products.items.items);
    state.tagsAssociations = getTagsAssociations(state.products.items.items);
    state.taxesAssociations = getTaxesAssociations(state.products.items.items);
    state.customFieldsAssociations = getCustomFieldsAssociations([
      { type: 'products', items: state.products.items.items },
      { type: 'categories', items: state.categories.items.items },
      { type: 'packs', items: state.packs.items.items },
      { type: 'customers', items: state.customers.items.items },
    ]);
    deepclone({ state, pluralType: objectPlural });
  }
  return state;
};

const updateVariantElement = ({ state, updatedElement }: { state: StateStore; updatedElement: any }): StateStore => {
  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: state.products.items.items.map(item => (item.id === updatedElement.id ? { ...updatedElement } : item)),
      },
    },
  };
};

const handleDeletions = (productToUpdate: any, deletions: any) => {
  if (deletions && deletions.length > 0) {
    deletions.forEach((deletion: any) => {
      productToUpdate.subValues = productToUpdate.subValues.filter((v: any) => v.variantID !== deletion.variantID);
    });
    productToUpdate.variantsCount = productToUpdate.subValues.length;
  }

  return productToUpdate;
};

const handleModifications = (productToUpdate: any, modifications: any, state: any) => {
  if (modifications && modifications.length > 0) {
    modifications.forEach((modification: any) => {
      const variantIndex = productToUpdate.subValues.findIndex((v: any) => v.variantID === modification.variantID);

      if (variantIndex !== -1) {
        productToUpdate.subValues[variantIndex] = {
          ...productToUpdate.subValues[variantIndex],
          ...modification,
        };

        if (modification.taxID) {
          const tax = state.taxes.items.items.find((t: any) => t.id === modification.taxID);

          if (tax) {
            productToUpdate.subValues[variantIndex].tax = tax;
          }
        }
      }
    });
  }

  return productToUpdate;
};

const handleVariationsAndInsertions = (productToUpdate: any, variations: any, insertions: any, state: StateStore) => {
  productToUpdate.variations = variations;

  if (insertions && insertions.length > 0) {
    const processedInsertions = insertions.map((insertion: any) => {
      const updatedInsertion = { ...insertion };

      if (insertion.options && insertion.options.length > 0) {
        const name = insertion.options
          .filter((option: any) => option)
          .map((option: any) => option.value)
          .join(', ');

        updatedInsertion.name = name;
      }

      if (insertion.taxID) {
        const taxObject = state.taxes.items.items.find((tax: any) => tax.id === insertion.taxID);
        if (taxObject) {
          updatedInsertion.tax = taxObject;
        }
      }

      return updatedInsertion;
    });

    productToUpdate.variants.items = [...productToUpdate.variants.items, ...processedInsertions];
    productToUpdate.variantsCount = productToUpdate.variants.items.length;
    productToUpdate.subValues = [...productToUpdate.subValues, ...processedInsertions];
  }

  return productToUpdate;
};

const transformProducts = (
  products: any[],
  productID: string,
  variations: any,
  insertions: any,
  modifications: any,
  deletions: any,
  state: any,
): any[] => {
  return products.map(product => {
    if (product.id === productID) {
      let productToUpdate = { ...product };
      productToUpdate = handleVariationsAndInsertions(productToUpdate, variations, insertions, state);
      productToUpdate = handleModifications(productToUpdate, modifications, state);
      productToUpdate = handleDeletions(productToUpdate, deletions);

      return productToUpdate;
    }
    return product;
  });
};

const updateVariantElementSubscription = ({
  state,
  subscriptionResult,
}: {
  state: StateStore;
  subscriptionResult: any;
}): StateStore => {
  const { productID, variations, insertions, modifications, deletions } = subscriptionResult;

  const updatedProducts = transformProducts(
    state.products.items.items,
    productID,
    variations,
    insertions,
    modifications,
    deletions,
    state,
  );

  let updatedFilteredItems;
  if (state.products.filteredItems) {
    updatedFilteredItems = transformProducts(
      state.products.filteredItems.items,
      productID,
      variations,
      insertions,
      modifications,
      deletions,
      state,
    );
  }

  return {
    ...state,
    products: {
      ...state.products,
      items: {
        ...state.products.items,
        items: updatedProducts,
      },
      //@ts-ignore
      filteredItems: state.products.filteredItems
        ? {
            ...state.products.filteredItems,
            items: updatedFilteredItems,
          }
        : undefined,
    },
  };
};

const getListsWithPhoto = async (
  request: any,
  limit: number,
  objectType: string,
  objectRequest: string,
  dispatch: any,
) => {
  const list = await getData({ request, limit });
  if (list[objectRequest] && list[objectRequest].items && list[objectRequest].items.length > 0) {
    const allItems = list[objectRequest].items
      .filter((_: any) => _)
      .map((element: any) => ({
        ...element,
        photo: element.photo ? element.photo + '?' + Date.now() : undefined,
        selected: false,
      }));
    fetchListElement(dispatch, allItems, list[objectRequest].items.nextToken, pluralize(objectType));
    return allItems;
  }

  return [];
};

export {
  subscribeToAll,
  unSubscribeToAll,
  updateListElement,
  updateVariantElement,
  updateVariantElementSubscription,
  deleteListElement,
  getListsWithPhoto,
  addVariantInventoryMovement,
  addInventoryMovement,
  addLinesInventoryMovement,
};
