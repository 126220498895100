const People = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8314 13.13C18.2014 14.06 19.1614 15.32 19.1614 17V20H22.1614C22.7114 20 23.1614 19.55 23.1614 19V17C23.1614 14.82 19.5914 13.53 16.8314 13.13Z"
      fill={color}
    />
    <path
      d="M9.16138 12C11.3705 12 13.1614 10.2091 13.1614 8C13.1614 5.79086 11.3705 4 9.16138 4C6.95224 4 5.16138 5.79086 5.16138 8C5.16138 10.2091 6.95224 12 9.16138 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1614 12C17.3714 12 19.1614 10.21 19.1614 8C19.1614 5.79 17.3714 4 15.1614 4C14.6914 4 14.2514 4.1 13.8314 4.24C14.6614 5.27 15.1614 6.58 15.1614 8C15.1614 9.42 14.6614 10.73 13.8314 11.76C14.2514 11.9 14.6914 12 15.1614 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16138 13C6.49138 13 1.16138 14.34 1.16138 17V19C1.16138 19.55 1.61138 20 2.16138 20H16.1614C16.7114 20 17.1614 19.55 17.1614 19V17C17.1614 14.34 11.8314 13 9.16138 13Z"
      fill={color}
    />
  </svg>
)

export default People
