import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Cashbook from './cashbook'
import { TypeTab, TypeDrawer } from '../../../../types/drawer'
import DrawerMenu from '../../../../components/oldComponents/drawer/menu'
import { GetCashbookQueryVariables } from '../../../../API'
import { Route } from '../../../../utils/navigation'
import Detail from './detail'
import { TabIdsCashbook } from '../..'
import { getPaymentsInfos } from '../../../../services/activity/payment'
import { DataWithErrors } from '../../../../services/types'
import { getDashboardView, isDrawerOpen, selectedCompany, listShops, listPaymentMethods } from '../../../../store/selector'
import Event from './event'
import { updateDrawerState } from '../../../../actions/drawer'
import { stateCashbookEnumToStr } from '../../../../utils/typeToType'
import { DashboardView, DrawerState } from '../../../../store/types'
import TabList from '../../../../components/newComponents/tabList'
import DrawerTop from '../../../../components/oldComponents/drawer/top'
import {
  ComponentDirection,
  ComponentSize,
  ComponentState,
  ComponentType,
} from '../../../../components/newComponents/types'
import { TypeCashbook, TypeCashbookEvent } from '../../../../types'
import { callService } from '../../../../services'
import { getCashbookStats as getWS } from '../../../../graphql/custom/queries'
import generateDataCy from '../../../../utils/cypress'
import Divider from '../../../../components/newComponents/divider'
import Loader from '../../../../components/newComponents/loader'

export const MenuCashbook = ({
  id,
  history,
  addHistory,
  close,
}: {
  id?: string
  history: Array<Route>
  addHistory: (route: Route) => void
  close: () => void
}) => {
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()

  const initialState: {
    tabList: Array<TypeTab>
    detail: TypeCashbook
    event: TypeCashbookEvent
  } = {
    tabList: [
      { id: TabIdsCashbook.DETAIL, name: t('drawer.tabs.DETAIL'), state: ComponentState.ACTIVE },
      { id: TabIdsCashbook.EVENT, name: t('drawer.tabs.EVENT'), state: ComponentState.DEFAULT },
    ],
    detail: {},
    event: {
      movements: [],
    },
  }
  const [state, setState] = useState(initialState)
  const mobileView = useSelector(getDashboardView)
  const drawerIsOpen = useSelector(isDrawerOpen);
  const allShops = useSelector(listShops)
  const allPaymentMethods = useSelector(listPaymentMethods)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'activity', value: generateDataCy({ scope: 'cashbook', value: 'drawer' }) })

  const setCashbookInState = async (data: DataWithErrors) => {
    if (data.data) {
      const cashbook = data.data
      const shop = allShops.items.items.find((s) => s.id === cashbook.shopID)
      let openingSeller
      let closingSeller
      let cashFund: Array<any> = []
      let stats
      let payments
      if (cashbook.openingSeller) {
        openingSeller = {
          id: cashbook.openingSeller.id,
          color: cashbook.openingSeller.color,
          iconText: cashbook.openingSeller.iconText,
        }
      }
      if (cashbook.closingSeller) {
        closingSeller = {
          id: cashbook.closingSeller.id,
          color: cashbook.closingSeller.color,
          iconText: cashbook.closingSeller.iconText,
        }
      }
      if (cashbook.openingCashFund && cashbook.openingCashFund.length > 0) {
        cashFund = [
          {
            text: t('activity.cashbook.OPENING_CASH_FUND'),
            value: cashbook.openingCashFund.reduce(
              (acc: number, cashFund: { total: number }) => (acc += cashFund.total),
              0
            ),
          },
        ]
      }
      if (cashbook.closingCashFund && cashbook.closingCashFund.length > 0) {
        cashFund = [
          ...cashFund,
          {
            text: t('activity.cashbook.TOTAL_EXPECTED'),
            value: cashbook.totalExpected ?? 0,
          },
          {
            text: t('activity.cashbook.CLOSING_CASH_FUND'),
            value: cashbook.closingCashFund.reduce(
              (acc: number, cashFund: { total: number }) => (acc += cashFund.total),
              0
            ),
          },
          {
            text: t('activity.cashbook.TOTAL_DIFFERENCE'),
            value: cashbook.totalDifference ?? 0,
          },
        ]
      }
      if (cashbook.stats) {
        stats = [
          {
            title: t('activity.cashbook.stats.TOTAL_TAX_FREE'),
            value: cashbook.stats.totalsDetail ? cashbook.stats.totalsDetail.totals.totalTaxFree : 0,
          },
          ...cashbook.stats.taxes.map((tax: { id: string; totals: { totalTax: number } }) => ({
            title: t('activity.cashbook.stats.TVA_X', {
              rate: parseInt(tax.id, 10) / 10 ** company.currencyDecimals,
            }),
            value: tax.totals ? tax.totals.totalTax : 0,
          })),
          {
            title: t('activity.cashbook.stats.TVA'),
            value: cashbook.stats.totalsDetail ? cashbook.stats.totalsDetail.totals.totalTax : 0,
          },
          {
            title: t('activity.cashbook.stats.TOTAL'),
            value: cashbook.stats.totalsDetail ? cashbook.stats.totalsDetail.totals.total : 0,
          },
        ]
        payments = getPaymentsInfos(cashbook.stats.payments, allPaymentMethods)
      }
      setState({
        ...state,
        detail: {
          ...state.detail,
          id: cashbook.id,
          number: cashbook.number,
          status: stateCashbookEnumToStr(cashbook.state, t),
          openedAt: cashbook.openedAt,
          closedAt: cashbook.closedAt,
          shop: {
            id: cashbook.shopID,
            name: shop.name,
          },
          sourceName: cashbook.sourceName,
          cashFund,
          note: cashbook.note ?? undefined,
          stats,
          customFields: cashbook.customFields.filter((customField: { value: string }) => customField.value),
          // @ts-ignore
          payments,
          openingSeller,
          closingSeller,
        },
        event: {
          ...state.event,
          openedAt: cashbook.openedAt,
          movements: cashbook.movements,
          closedAt: cashbook.closedAt,
        },
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    if (id && drawerIsOpen) {
      callService<GetCashbookQueryVariables>({ id }, getWS, 'getCashbook').then(setCashbookInState)
    }
  }, [id, drawerIsOpen])

  const ComponentToDisplay = () => {
    switch (state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id) {
      case TabIdsCashbook.DETAIL:
        return (
          <Cashbook dataCy={cyContext}>
            <Detail cashbookState={state.detail} dataCy={cyContext} />
          </Cashbook>
        )
      case TabIdsCashbook.EVENT:
        return (
          <Cashbook dataCy={cyContext}>
            <Event cashbookState={state.event} dataCy={cyContext} />
          </Cashbook>
        )
      default:
        return <></>
    }
  }

  return (
    <>{
      !state.detail.id ? <Loader /> :
        <>
          <DrawerTop
            history={history}
            name={`${t('activity.cashbook.CASHBOOK')} #${state.detail.number}`}
            mobileView={mobileView === DashboardView.MOBILE}
            close={close}
            dataCy={cyContext}
          />
          <>
            <TabList
              tabList={state.tabList}
              onClick={(newTab: TypeTab) => {
                setState({
                  ...state,
                  tabList: state.tabList.map((sTab) => ({
                    ...sTab,
                    state: newTab.id === sTab.id ? ComponentState.ACTIVE : ComponentState.DEFAULT,
                  })),
                })
              }}
              size={ComponentSize.LARGE}
              dataCy={cyContext}
            />
            <Divider type={ComponentType.FILL} direction={ComponentDirection.HORIZONTAL} />
          </>
          <ComponentToDisplay />
        </>
    }
    </>
  )
}

const Drawer = ({ selected = [], close }: TypeDrawer) => <DrawerMenu component={MenuCashbook} />

export default Drawer
