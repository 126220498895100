import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import generateDataCy from '../../../utils/cypress'

import Button from '../../newComponents/button'
import { ComponentSize, ComponentState, ComponentType } from '../../newComponents/types'

import styles from './Drawer.module.css'

const SubmitButton = ({
  showSubmit,
  handleSubmit,
  text,
  helperText,
  width
}: {
  showSubmit: boolean
  handleSubmit: () => void
  text?: string
  helperText?: string | null
  width?: boolean
}) => {
  const [state, setState] = useState(showSubmit)

  useEffect(() => {
    setState(showSubmit)
  }, [showSubmit, helperText])

  const { t } = useTranslation()
  const dataCy = generateDataCy({ scope: 'submit', value: 'button' })

  return (
    <div className={width ? styles.fusionButton : styles.submitButton}>
      <Button
        title={text ?? t('button.SAVE')}
        size={ComponentSize.LARGE}
        type={ComponentType.PRIMARY}
        state={state ? ComponentState.DEFAULT : ComponentState.DISABLED}
        onClick={() => {
          // setState(false)
          handleSubmit()
        }}
        width={width ? '174px' : 'calc(400px - 2em)'}
        dataCy={dataCy}
      />
    </div>
  )
}

export default SubmitButton
