import generateDataCy from '../../../utils/cypress'
import IconTouch from '../icons/iconTouch'
import { ComponentSize, ComponentType } from '../types'

import styles from './ListItem.module.css'

type Props = {
  Icon?: any
  type: ComponentType
  isSelected: boolean
  dataCy?: string
}

/**
 *
 * This element displays an item
 *
 * @param Icon - A component
 * @param type - Component type
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ListItemCheckable = ({ Icon, type, isSelected, dataCy }: Props): JSX.Element => {
  const getStyle = () => ({
    borderTopLeftRadius: type === ComponentType.HEADER ? '8px' : '',
    borderBottom: type === ComponentType.HEADER ? '1px solid var(--sys-color-border-default)' : '',
  })

  return (
    <div className={styles.listItemIcon} style={getStyle()} data-cy={dataCy}>
      <IconTouch
        Icon={Icon}
        color={isSelected ? 'var(--sys-color-content-interactive)' : `var(--comp-icon-touch-color-foreground-default)`}
        size={ComponentSize.MEDIUM}
        dataCy={generateDataCy({ scope: 'checkbox', value: isSelected ? 'selected' : 'default' })}
      />
    </div>
  )
}

export default ListItemCheckable
