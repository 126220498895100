const Settings = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16138 18C3.16138 18.55 3.61138 19 4.16138 19H9.16138V17H4.16138C3.61138 17 3.16138 17.45 3.16138 18ZM3.16138 6C3.16138 6.55 3.61138 7 4.16138 7H13.1614V5H4.16138C3.61138 5 3.16138 5.45 3.16138 6ZM13.1614 20V19H20.1614C20.7114 19 21.1614 18.55 21.1614 18C21.1614 17.45 20.7114 17 20.1614 17H13.1614V16C13.1614 15.45 12.7114 15 12.1614 15C11.6114 15 11.1614 15.45 11.1614 16V20C11.1614 20.55 11.6114 21 12.1614 21C12.7114 21 13.1614 20.55 13.1614 20ZM7.16138 10V11H4.16138C3.61138 11 3.16138 11.45 3.16138 12C3.16138 12.55 3.61138 13 4.16138 13H7.16138V14C7.16138 14.55 7.61138 15 8.16138 15C8.71138 15 9.16138 14.55 9.16138 14V10C9.16138 9.45 8.71138 9 8.16138 9C7.61138 9 7.16138 9.45 7.16138 10ZM21.1614 12C21.1614 11.45 20.7114 11 20.1614 11H11.1614V13H20.1614C20.7114 13 21.1614 12.55 21.1614 12ZM16.1614 9C16.7114 9 17.1614 8.55 17.1614 8V7H20.1614C20.7114 7 21.1614 6.55 21.1614 6C21.1614 5.45 20.7114 5 20.1614 5H17.1614V4C17.1614 3.45 16.7114 3 16.1614 3C15.6114 3 15.1614 3.45 15.1614 4V8C15.1614 8.55 15.6114 9 16.1614 9Z"
      fill={color}
    />
  </svg>
)

export default Settings
