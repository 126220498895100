import { TypeColumnConfiguration, DashboardView } from '../store/types';

const PERCENT_DECIMAL = 2;

const buildCols = (cols: TypeColumnConfiguration, colOne: string, colTwo: string, dashboardView: DashboardView) => {
  // Find index of 'name' column
  const nameColIndex = cols?.cols.findIndex(col => col.id === 'name');
  const iconColIndex = cols?.cols.findIndex(col => col.id === 'icon');

  // If 'icon' column is present and not next to 'name' column, reorder it
  if (iconColIndex !== -1 && iconColIndex !== nameColIndex + 1) {
    const iconCol = cols.cols.splice(iconColIndex, 1)[0];
    cols.cols.splice(nameColIndex + 1, 0, iconCol);
  }

  // For mobile view, filter to include 'name', 'icon', and 'colTwo'
  if (dashboardView === DashboardView.MOBILE) {
    return {
      ...cols,
      cols: cols.cols.filter(col => ['name', 'icon', colTwo].includes(col.id)),
      checkboxes: false,
    };
  }

  return cols;
};

// const buildCols = (cols: TypeColumnConfiguration, colOne: string, colTwo: string, dashboardView: DashboardView) =>
//   dashboardView === DashboardView.MOBILE
//     ? {
//         ...cols!,
//         cols: cols?.cols.filter((col) => col.id === colOne || col.id === colTwo)!,
//         checkboxes: false,
//       }
//     : cols!

const getAExcludeBbyID = (a: Array<any>, b: Array<any>) =>
  a.filter((aID: any) => !b.find(({ id }: { id: string }) => id === aID));

// to get item to add send first array a item from input payload and b array of existing items
// to get item to remove send first array a existing item and b array of items from input payload
const getItemToAddOrRemove = (a: Array<any>, b: Array<any>) =>
  a.filter((aID: any) => !b.some((bID: any) => bID.id === aID.id));

const getDrawerHeight = () =>
  'calc(100vh - (61px + var(--comp-tab-index-item-size-lg) + var(--comp-button-size-height-lg) + 3em + var(--sys-size-4))';

const getDrawerHeightNoTab = () => 'calc(100vh - (61px + var(--comp-button-size-height-lg) + 3em)';

const getDrawerHeightWithDelete = () => 'calc(100vh - (61px + var(--comp-button-size-height-lg) + 3em + 24px + 16px)';

export {
  PERCENT_DECIMAL,
  buildCols,
  getAExcludeBbyID,
  getDrawerHeight,
  getDrawerHeightNoTab,
  getDrawerHeightWithDelete,
  getItemToAddOrRemove,
};
