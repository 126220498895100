import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'
import callDiscountCreation from './service/create'
import callDiscountUpdate from './service/udpate'
import { setErrorsInState, findErrorsInState } from '../../../../format/errors'
import { getDrawerState, selectedCompany } from '../../../../store/selector'
import { drawerResetHistory, updateDrawerState } from '../../../../actions/drawer'
import { DrawerState } from '../../../../store/types'
import { TypeDiscount } from '../../../../types'
import Loader, { displayLoader } from '../../../../components/newComponents/loader'
import { handleBlur } from '../../../../format/form'
import SubmitButton from '../../../../components/oldComponents/drawer/submitButton'
import { getDrawerHeight, getDrawerHeightNoTab } from '../../../../utils'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'
import generateDataCy from '../../../../utils/cypress'

type Props = {
  discountState: TypeDiscount
  updateName: (value: string) => void
  close: () => void
  dataCy: string
}

const Detail = ({ discountState, updateName, close, dataCy }: Props) => {
  const [state, setState] = useState(discountState)
  const drawerState = useSelector(getDrawerState)
  const company = useSelector(selectedCompany)
  const dispatch = useDispatch()

  const handleChange = (field: string) => (event: any) => {
    state.errors.global = undefined
    // @ts-ignore
    state.errors[field] = null
    state.dirties = [...state.dirties, field]

    const showSubmit = !findErrorsInState(state.errors)
    setState({ ...state, [field]: event, showSubmit })
  }

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    } else {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
      drawerResetHistory(dispatch)
      close()
    }
  }

  const handleSubmit = () => {
    setState(handleBlur(state, 'name'))
    setState(handleBlur(state, 'rate'))

    if (!findErrorsInState(state.errors)) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      if (!state.id) {
        callDiscountCreation(state, company.currencyDecimals).then(handleResult)
      } else {
        callDiscountUpdate(state, company.currencyDecimals).then(handleResult)
      }
    }
  }

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={styles.containerForm}
          style={{
            height: state.id ? getDrawerHeight() : getDrawerHeightNoTab(),
          }}
          data-cy={generateDataCy({ scope: dataCy, value: state.id ? 'edit' : 'create' })}
        >
          <Form
            state={state}
            handleChange={handleChange}
            handleBlur={(field) => setState(handleBlur(state, field))}
            handleSubmit={handleSubmit}
            dataCy={dataCy}
          />
          <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Detail
