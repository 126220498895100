import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { APIOption, TypeProduct } from '../../../../../types'
import { TypeTag } from '../../../../../types/setting/tag'
import ColorPicker from '../../../../../components/newComponents/colorPicker'
import Tag from '../../../../../components/oldComponents/catalog/tag'
import { listParsedCategories, listParsedTags, listParsedTaxes, selectedCompany } from '../../../../../store/selector'
import generateDataCy from '../../../../../utils/cypress'
import Divider from '../../../../../components/newComponents/divider'
import {
  ComponentDirection,
  ComponentErrorText,
  ComponentSize,
  ComponentStatus,
  ComponentType,
} from '../../../../../components/newComponents/types'
import Input from '../../../../../components/newComponents/input'
import { displayCurrency } from '../../../../../utils/number'
import UniqueDropDown from '../../../../../components/newComponents/dropDown/unique'
import SearchList from '../../../../../components/oldComponents/searchList'
import CustomField from '../../../../../components/oldComponents/catalog/customField'
import Option from '../../../../../components/oldComponents/catalog/option'
import DraggableList from '../../../../../components/oldComponents/draggableList/draggableList'
import { enumToStr } from '../../../../../utils/color'
import Thumbnail from '../../../../../components/newComponents/thumbnail'

import { KeyboardArrowDown } from '../../../../../assets/icons'
import styles from '../../../../../components/oldComponents/drawer/Drawer.module.css'
import { useState, useEffect } from 'react'

const Form = ({
  state,
  listOptions,
  handleChange,
  handleBlur,
  handleBlurNested,
  handleAddOption,
  handleDeleteOption,
  handleDragEndOption,
  handleDragEnd,
  openVariationOrVariantDrawer,
  openVariationDrawer,
  handleSubmit,
  dataCy,
}: {
  state: TypeProduct
  listOptions: Array<APIOption>
  handleChange: (field: string, index?: number) => (event: any) => void
  handleBlurNested: (index: number) => void
  handleBlur: (field: string) => void
  handleAddOption: (option: APIOption) => void
  handleDeleteOption: (id: string) => void
  handleDragEndOption: (dragStart: number, dragEnd: number) => void
  handleDragEnd: (dragStart: number, dragEnd: number) => void
  openVariationOrVariantDrawer: () => void
  openVariationDrawer: (_: string | undefined, index: number) => void
  handleSubmit: () => void
  dataCy: string
}) => {
  const useSelectorSearchListCategory = useSelector(listParsedCategories(state.categoryToCreate))
  const useSelectorSearchListTaxe = useSelector(listParsedTaxes)
  const useSelectorTag = useSelector(listParsedTags)
  const company = useSelector(selectedCompany)
  const { t } = useTranslation()
  const [displayPrice, setDisplayedPrice] = useState();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getCategoryName = () => {
    const id = state.categoryToCreate !== '' ? state.categoryToCreate : state.categoryID
    return useSelectorSearchListCategory.find((category) => id === category.id)?.value
  }

  useEffect(() => {

    //@ts-ignore
    setDisplayedPrice(state.sellPrice + (state.sellPrice !== '' && company ? ' ' + displayCurrency(company.currency) : ''))
  }, [state.sellPrice]);

  const errorText = () => {
    if (state.errors?.name?.value) {
      return state.errors.name.message
    }

    if (state.errors?.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <>
      <div className={styles.form}>
        <ColorPicker
          title={t('button.EDIT')}
          Element={
            <Thumbnail
              size={ComponentSize.LARGE}
              color={state.color ? enumToStr(state.color) : state.color}
              iconText={state.iconText}
              image={state.selected === 'color' ? undefined : state.photo}
            />
          }
          iconText={state.iconText}
          color={state.color}
          photo={state.photo}
          selected={state.selected}
          resize={600}
          onSelect={handleChange('color')}
          onChange={handleChange('iconText')}
          onBlur={() => {
            if (state.iconText === '') handleChange('iconText')(state.name?.substring(0, 4))
          }}
          onUpload={handleChange('photo')}
          dataCy={generateDataCy({ scope: dataCy, value: 'color' })}
          isIconText
          isImage
        />
        <Input
          label={t('catalog.product.form.NAME')}
          value={state.name}
          onChange={handleChange('name')}
          onBlur={() => handleBlur('name')}
          onSubmit={handleSubmit}
          style={{
            status: state.errors?.name?.value || state.errors?.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={errorText()}
          dataCy={generateDataCy({ scope: dataCy, value: 'name' })}
        />
        <SearchList
          eventID="drawer"
          parentID={'searchList'}
          popUpID={'searchListPopUp'}
          label={t('catalog.category.form.TITLE')}
          valueId={state.categoryToCreate !== '' ? state.categoryToCreate : state.categoryID}
          onSelect={handleChange('categoryID')}
          onCreate={handleChange('categoryToCreate')}
          error={state.errors?.categoryID?.value}
          onBlur={() => handleBlur(state.categoryToCreate !== '' ? 'categoryToCreate' : 'categoryID')}
          entries={useSelectorSearchListCategory.map((e) => ({ id: e.id, val: e.value }))}
          dataCy={generateDataCy({ scope: dataCy, value: 'categoryID' })}
          displayCreate
        />
        <Input
          type={'currency'}
          label={t('catalog.product.form.PRICE')}
          // value={state.sellPrice ? state.sellPrice + (company ? ' ' + displayCurrency(company.currency) : '') : displayPrice}
          value={displayPrice}
          onChange={handleChange('sellPrice')}
          onBlur={() => handleBlur('sellPrice')}
          onSubmit={handleSubmit}
          style={{
            status: state.errors?.sellPrice?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
          }}
          helperText={state.errors?.sellPrice?.value ? state.errors.sellPrice.message : undefined}
          suffix={company ? displayCurrency(company.currency) : undefined}
          dataCy={generateDataCy({ scope: dataCy, value: 'sellPrice' })}
        />
        <div className={styles.oneLine}>
          <UniqueDropDown
            eventID="drawer"
            parentID={'select'}
            popUpID={'selectPopUp'}
            Element={
              <Input
                placeholder={t('catalog.product.form.TAX')}
                value={useSelectorSearchListTaxe.find((tax) => state.taxID === tax.id)?.value}
                style={{
                  status: state.errors?.taxID?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                  trailIcons: [{ icon: KeyboardArrowDown }],
                  readOnly: true,
                }}
                dataCy={generateDataCy({ scope: dataCy, value: 'taxID' })}
              />
            }
            items={useSelectorSearchListTaxe.map((t) => ({ ...t, onClick: handleChange('taxID') }))}
            dataCy={generateDataCy({ scope: dataCy, value: 'taxID' })}
          />
          <Input
            label={t('catalog.product.form.REFERENCE')}
            value={state.reference}
            onChange={handleChange('reference')}
            onSubmit={handleSubmit}
            dataCy={generateDataCy({ scope: dataCy, value: 'reference' })}
          />
        </div>
        <Tag
          selectedTags={state.tags}
          entries={useSelectorTag}
          handleSave={(tag: TypeTag) => handleChange('tags')([...state.tags, tag])}
          handleDelete={(name: string) => handleChange('tags')(state.tags.filter((tag) => tag.name !== name))}
          error={state.errors?.tags}
          dataCy={generateDataCy({ scope: dataCy, value: 'tag' })}
        />
      </div>
      <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
      <div className={styles.form}>
        <div className={styles.oneLine}>
          <Input
            type={'currency'}
            label={t('catalog.product.form.BUY_PRICE')}
            value={state.buyPrice || state.buyPrice === 0 ? state.buyPrice + (company ? ' ' + displayCurrency(company.currency) : '') : ''}
            onChange={handleChange('buyPrice')}
            onBlur={() => handleBlur('buyPrice')}
            onSubmit={handleSubmit}
            style={{
              status: state.errors?.buyPrice?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={state.errors?.buyPrice?.value ? state.errors.buyPrice.message : undefined}
            suffix={company ? displayCurrency(company.currency) : undefined}
            dataCy={generateDataCy({ scope: dataCy, value: 'buyPrice' })}
          />
          <Input
            label={t('catalog.product.form.BARCODE')}
            value={state.barcode}
            onChange={handleChange('barcode')}
            onSubmit={handleSubmit}
            dataCy={generateDataCy({ scope: dataCy, value: 'barcode' })}
          />
        </div>
      </div>
      <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
      <div className={styles.form}>
        <CustomField
          selectedCustomFields={state.customFields}
          onChange={handleChange}
          onBlur={handleBlurNested}
          onSubmit={handleSubmit}
          errors={state.errors?.customFields}
          cyContext={generateDataCy({ scope: dataCy, value: 'customField' })}
        />
        <Option
          options={state.options}
          listOptions={listOptions}
          handleSave={handleAddOption}
          handleDelete={handleDeleteOption}
          handleDragEnd={handleDragEndOption}
          error={state.errors?.options}
          dataCy={generateDataCy({ scope: dataCy, value: 'option' })}
        />
      </div>
      <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
      <div className={styles.form}>
        <DraggableList
          label={`${t('catalog.product.form.variant.TITLE')} (${state.variantsLength})`}
          list={state.variations ?? []}
          onClickIcon={openVariationDrawer}
          onRedirect={openVariationOrVariantDrawer}
          onDragEnd={handleDragEnd}
          iconColor="var(--sys-color-content-interactive)"
          addText={t('catalog.product.form.variant.ADD')}
          dataCy={generateDataCy({ scope: dataCy, value: 'variations' })}
          pen
          draggable={false}
        />
      </div>
    </>
  )
}

export default Form
