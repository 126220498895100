import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import SearchList, { Entries } from '../searchList'
import DraggableList from '../draggableList/draggableList'
import { TypeFormError } from '../../../format/errors/types'
import { APIOption } from '../../../types'

import styles from '../draggableList/DraggableList.module.css'
import stylesInput from '../../newComponents/input/Input.module.css';

import { Add as AddIcon } from '../../../assets/icons'
import DisplayIcon2 from '../../newComponents/icons'

type State = {
  allOptions: Array<APIOption>
  options: Array<APIOption>
  entries: Array<Entries>
}

/**
 *
 * This element displays a list of options
 *
 * @usedIn - Catalog form views
 *
 * @param options - The actual options to display
 * @param handleSave - The CTA triggered when we select an option
 * @param handleDelete - The CTA triggered when we press the cross
 * @param disabled - A boolean who enable or disable changements of the list
 * @param error - A TypeFormError to display if error.value is true
 * @param dataCy - An unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Option = ({
  listOptions,
  options,
  handleSave,
  handleDelete,
  handleDragEnd,
  disabled = false,
  error,
  dataCy,
}: {
  listOptions?: Array<APIOption>
  options: Array<APIOption>
  handleSave?: (option: APIOption) => void
  handleDelete?: (id: string) => void
  handleDragEnd?: (dragStart: number, dragEnd: number) => void
  disabled?: boolean
  error?: TypeFormError
  dataCy?: string
}): JSX.Element => {
  const initialState: State = {
    allOptions: [],
    options: [],
    entries: [],
  }
  const [state, setState] = useState(initialState)
  const { t } = useTranslation()

  /**
   *
   * This useEffect allows us to get missing informations about the options,
   * parse them into a displayable object and separate the list of selectable options
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  useEffect(() => {
    if (listOptions) {
      const newListOptions = listOptions.map((data: any) => ({
        id: data.id,
        name: data.name,
        values: data.values,
      }))
      const listEntries = listOptions
        .filter((o: any) => o)
        .filter(
          (option: { id: string }) =>
            options.filter((o: any) => o).findIndex((opt: { id: string }) => option.id === opt.id) === -1
        )
        .map((opt) => ({ id: opt.id, val: opt.name }))
      setState({
        ...state,
        options,
        allOptions: newListOptions,
        entries: listEntries,
      })
    }
      }, [listOptions, options])

  /**
   *
   * This method calls handleDelete then add the deleted option inside the list of selectable ones
   *
   * @param id - The id of the option to delete
   * @param index - Its index inside the array
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  const onDelete = (id: string | undefined, index: number) => {
    if (id) handleDelete && handleDelete(id)
    const updatedEntry = state.options.find((_, indexOpt) => indexOpt === index)
    if (updatedEntry) {
      setState({
        ...state,
        entries: [...state.entries, { id: updatedEntry.id, val: updatedEntry.name }],
      })
    }
  }

  /**
   *
   * This method calls handleSave then remove the added option from the list of selectable ones
   *
   * @param e - The id of the option to add
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  const handleSelect = (e: any) => {
    const id = e
    const updatedOption: any = state.allOptions.find((lopt: { id: string }) => {
      return lopt.id === id
    })
    handleSave && handleSave(updatedOption)
    setState({
      ...state,
      entries: state.entries.filter((lopt: { id: string }) => lopt.id !== id),
    })
  }

  const onChange = (e: any) => {
    console.log(e, "ON CHANGE")
  }

  return (
    // <div className={styles.addContainer}>
    //   <DisplayIcon2 Icon={AddIcon} color={'var(--sys-color-content-interactive)'} />
    //   <label
    //     className={stylesInput.label}
    //     style={{ color: 'var(--sys-color-content-secondary)', transform: 'translateY(-14px)', zIndex: 2 }}
    //   >
    //     Options
    //   </label>
    //   <SearchList
    //     eventID="drawer"
    //     parentID={'searchListOption'}
    //     popUpID={'searchListPopUpOption'}
    //     entries={state.entries}
    //     allEntries={state.allOptions}
    //     onSelect={handleSelect}
    //     dataCy={dataCy}
    //   />
    // </div>
    <DraggableList
      label={t('catalog.option.component.TITLE')}
      list={state.options}
      entries={state.entries}
      onSelect={handleSelect}
      onClickIcon={onDelete}
      onDragEnd={handleDragEnd!}
      iconColor={'var(--sys-color-content-interactive)'}
      addText={t('catalog.option.component.ADD')}
      disabled={disabled}
      error={error}
      close
      dataCy={dataCy}
      //@ts-ignore
      onChange={onChange}
    />
  )
}

export default Option
