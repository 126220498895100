const MultipleMethodAlt = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1614 16L13.1614 12V8.82C14.3214 8.4 15.1614 7.3 15.1614 6C15.1614 4.34 13.8214 3 12.1614 3C10.5014 3 9.16138 4.34 9.16138 6C9.16138 7.3 10.0014 8.4 11.1614 8.82V12L7.16138 16H3.16138V21H8.16138V17.95L12.1614 13.75L16.1614 17.95V21H21.1614V16H17.1614Z"
      fill={color}
    />
  </svg>
)

export default MultipleMethodAlt
