import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import DrawerMenu from '../../../components/oldComponents/drawer/menu'
import Detail from './detail'
import { TypeDrawer, TypeTab } from '../../../types/drawer'
import { DashboardView, DrawerState, DrawerType } from '../../../store/types'
import { DataWithErrors } from '../../../services/types'
import { customFieldsInState } from '../../../services/setting/customField'
import { getRandomColor } from '../../../utils/color'
import { getCustomer } from '../../../getters/customer/customer'
import Fidelity from './fidelity'
import { currentDrawer, getDashboardView, listCustomFieldItems, listCustomers } from '../../../store/selector'
import { displayFullName } from '../../../utils/string'
import getExtraInfos from '../../../services/customer/customer'
import {
  email as emailValidator,
  customFieldNumber as customFieldNumberValidator,
} from '../../../format/errors/filters/customer/customer'
import { Route } from '../../../utils/navigation'
import { drawerAddHistory, drawerResetHistory, updateDrawerState } from '../../../actions/drawer'
import DrawerTop from '../../../components/oldComponents/drawer/top'
import DrawerBulk, { TypeQuickActions } from '../../../components/oldComponents/drawer/quickAction'
import {
  ComponentDirection,
  ComponentSize,
  ComponentState,
  ComponentType,
} from '../../../components/newComponents/types'
import TabList from '../../../components/newComponents/tabList'
import { deleteCustomer as deleteC } from '../../../graphql/custom/mutations'
import { DeleteCustomerInput } from '../../../API'
import { callService } from '../../../services'
import { createPayload } from '../../../utils/dirties'
import generateDataCy from '../../../utils/cypress'
import { TypeCustomer } from '../../../types'
import Divider from '../../../components/newComponents/divider'

import { Duplicate, DeleteIcon } from '../../../assets/icons'
import { MenuFusion } from './fusion'

export enum TabIdsCustomer {
  DETAIL = 'DETAIL',
  FIDELITY = 'FIDELITY',
  ACCOUNT = 'ACCOUNT',
}

export const MenuCustomer = ({
  id,
  isDuplicate,
  history,
  addHistory,
  close,
}: {
  id?: string
  isDuplicate?: boolean
  history: Array<Route>
  addHistory: (route: Route) => void
  close: () => void
}) => {
  const listCustomFields = useSelector(listCustomFieldItems)
  const { t } = useTranslation()

  const initialState: {
    tabList: Array<TypeTab>
    detail: TypeCustomer
    event?: any
  } = {
    tabList: [
      { id: TabIdsCustomer.DETAIL, name: t('customer.tab.DETAIL'), state: ComponentState.ACTIVE },
      { id: TabIdsCustomer.FIDELITY, name: t('customer.tab.FIDELITY'), state: ComponentState.DEFAULT },
    ],
    detail: {
      actions: [
        {
          id: 'duplicate',
          value: t('drawer.menu.DUPLICATE'),
        },
        { id: 'delete', value: t('drawer.menu.DELETE') },
      ],
      color: getRandomColor(),
      customFields: customFieldsInState('CUSTOMER', listCustomFields),
      address: {},
      showSubmit: false,
      dirties: id && !isDuplicate ? ['id'] : ['color'],
      validators: {
        email: emailValidator,
        customFields: customFieldNumberValidator,
      },
      errors: {
        customFields: customFieldsInState('CUSTOMER', listCustomFields).map((_) => ({
          value: false,
          message: '',
        })),
      },
    },
  }
  const [state, setState] = useState(initialState)
  const mobileView = useSelector(getDashboardView)
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'customer', value: 'drawer' })

  const setCustomerInState = async (data: DataWithErrors) => {
    if (data.data) {
      const customer = data.data
      const customFields = customFieldsInState('CUSTOMER', listCustomFields, customer.customFields)

      let newAccount = state.detail.account
      if (customer.account) {
        newAccount = {
          ...newAccount,
          balance: customer.account.balance,
          movements: await getExtraInfos(customer.account.movements),
        }
      }
      setState({
        ...state,
        detail: {
          ...state.detail,
          id: isDuplicate ? '' : customer.id,
          iconText: customer.iconText,
          color: customer.color,
          firstName: customer.firstName,
          lastName: customer.lastName,
          fullName: displayFullName(customer.firstName, customer.lastName),
          email: customer.email,
          phone: customer.phone,
          companyName: customer.companyName,
          address: customer.address ?? {},
          fidelityCardNumber: customer.fidelityCardNumber,
          customFields,
          account: newAccount,
          note: customer.note,
          dirties: isDuplicate ? createPayload(customer) : state.detail.dirties,
          showSubmit: !!isDuplicate,
        },
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    if (id) {
      getCustomer(id, dispatch).then(setCustomerInState)
    }
      }, [id, isDuplicate, listCustomFields])

  const ComponentToDisplay = () => {
    switch (state.tabList.find((tab) => tab.state === ComponentState.ACTIVE)?.id) {
      case TabIdsCustomer.DETAIL:
        return <Detail customerState={state.detail} close={close} dataCy={cyContext} />
      case TabIdsCustomer.FIDELITY:
        return (
          <Fidelity
            id={state.detail.id!}
            firstName={displayFullName(state.detail.firstName, state.detail.lastName)}
            dataCy={cyContext}
          />
        )
      default:
        return <></>
    }
  }

  const handleClickMenu = (action: string) => {
    switch (action) {
      case 'duplicate': {
        updateDrawerState(DrawerState.LOADING, dispatch)
        addHistory({
          component: MenuCustomer,
          params: { id: state.detail.id!, isDuplicate: true },
        })
        break
      }
      case 'delete': {
        callService<{ input: DeleteCustomerInput }>({ input: { id: state.detail.id! } }, deleteC, 'deleteCustomer')
        drawerResetHistory(dispatch)
        close()
        break
      }
      default:
        break
    }
  }

  return (
    <>
      {state.detail.id ? (
        <DrawerTop
          history={history}
          name={displayFullName(state.detail.firstName, state.detail.lastName)}
          mobileView={mobileView === DashboardView.MOBILE}
          burgerMenu={{ actions: state.detail.actions!, onClick: handleClickMenu }}
          close={close}
          dataCy={cyContext}
        />
      ) : (
        <DrawerTop
          history={history}
          name={
            state.detail.id
              ? displayFullName(state.detail.firstName, state.detail.lastName)
              : t('customer.detail.form.NEW')
          }
          mobileView={mobileView === DashboardView.MOBILE}
          close={close}
          dataCy={cyContext}
        />
      )}
      {id && !isDuplicate && (
        <>
          <TabList
            tabList={state.tabList}
            onClick={(newTab: TypeTab) => {
              if (newTab.id !== TabIdsCustomer.DETAIL) updateDrawerState(DrawerState.LOADING, dispatch)
              setState({
                ...state,
                tabList: state.tabList.map((sTab) => ({
                  ...sTab,
                  state: newTab.id === sTab.id ? ComponentState.ACTIVE : ComponentState.DEFAULT,
                })),
              })
            }}
            size={ComponentSize.LARGE}
            dataCy={cyContext}
          />
          <Divider type={ComponentType.FILL} direction={ComponentDirection.HORIZONTAL} />
        </>
      )}
      <ComponentToDisplay />
    </>
  )
}

const deleteCustomers = (selected: Array<{ id: string }>, close: () => void) => () =>
  Promise.all(
    selected.map(({ id }: { id: string }) =>
      callService<{ input: DeleteCustomerInput }>({ input: { id } }, deleteC, 'deleteCustomer').then(() => close())
    )
  )

const Index = ({ selected = [], close }: TypeDrawer) => {
  const drawer = useSelector(currentDrawer)
  const dispatch = useDispatch()
  const mobileView = useSelector(getDashboardView)
  const { t } = useTranslation()
  const allCustomers = useSelector(listCustomers);


  useEffect(() => {
    selected.length === 0
      && allCustomers.items.items.filter(c => c.selected).length === 0
      && drawer.type === DrawerType.BULK
      && !drawer.navigation.current?.params.isDuplicate
      && close()
    if (selected.length !== allCustomers.items.items.filter(c => c.selected).length) {
            selected = allCustomers.items.items.filter(c => c.selected);
    }
      }, [selected])

  const getBulkActions = () => {
    let actions: TypeQuickActions = []
    if (selected.length === 1)
      actions = [
        {
          id: 'duplicate',
          icon: Duplicate,
          title: t('customer.bulk.duplicate.TITLE'),
          text: t('customer.bulk.duplicate.SUBTITLE'),
          onClick: () => {
            drawerAddHistory(
              {
                component: MenuCustomer,
                params: {
                  id: selected[0].id!,
                  isDuplicate: true,
                },
              },
              dispatch
            )
          },
        },
        {
          id: 'delete',
          icon: DeleteIcon,
          title: t('customer.bulk.DELETE'),
          onClick: deleteCustomers(selected, close),
        },
      ]
    if (selected.length > 1)
      actions = [
        ...actions,
        {
          id: 'fusion',
          title: t('customer.bulk.fusion.TITLE'),
          text: t('customer.bulk.fusion.SUBTITLE'),
          onClick: () => {
            drawerAddHistory({
              component: MenuFusion,
              params: {
                id: 'fusion',
                selected,
              }
            },
              dispatch
            )
          }
        },
        {
          id: 'delete',
          icon: DeleteIcon,
          title: t('customer.bulk.DELETE'),
          onClick: deleteCustomers(selected, close),
        }
      ]
    return actions
  }

  return (
    <DrawerMenu
      component={
        drawer.type === DrawerType.BULK
          ? () =>
            DrawerBulk({
              bulkTitle: t('customer.bulk.BUNDLED_SHARES'),
              title: t('customer.bulk.SELECTED', {
                number: selected.length,
                plural: selected.length > 1 ? 's' : '',
              }),
              actions: getBulkActions(),
              mobileView: mobileView === DashboardView.MOBILE,
              close,
            })
          : MenuCustomer
      }
    />
  )
}

export default Index
