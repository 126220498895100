import styles from './List.module.css'

const DisplayDiscountElement = (isGlobal?: boolean) => (text: string, index: number) =>
  (
    <div
      key={index}
      className={styles.text}
      style={{
        color: 'var(--orange)',
        fontWeight: isGlobal ? 500 : 400,
      }}
    >
      {text}
    </div>
  )

const ProductLine = ({
  name,
  infos,
  discounts,
  note,
  refund,
  isNegative,
}: {
  name?: { name?: { style?: {}; text: string }; quantity?: string; price?: { style?: {}; text: string } }
  infos?: Array<{
    name?: string
    variant?: string
    options?: Array<string>
  }>
  discounts?: { name?: Array<string>; price?: Array<string>; global?: boolean }
  note?: { title: string; text?: string }
  refund?: string
  isNegative?: boolean
}) => {
  const getPaddingLeft = (info: any) => (info.variant ? '10px' : '0px')

  return (
    <div className={styles.containerLine}>
      {name && (
        <div className={styles.containerName}>
          <div className={styles.containerNameQuantity}>
            <div style={name.name?.style}>{name.name?.text}</div>
            {name.quantity && <span>&nbsp;{'x ' + name.quantity}</span>}
          </div>
          <div>
            <span
              style={{
                ...name.name?.style,
                color: isNegative ? 'var(--sys-color-content-danger)' : 'var(--sys-color-content-primary)',
              }}
            >
              {name.price?.text}
            </span>
          </div>
        </div>
      )}
      {infos &&
        infos.map((info, index) => (
          <span key={index}>
            {info.name && <div className={styles.infoName}>{info.name}</div>}
            <div className={styles.infoDecli}>{info.variant}</div>
            <div>
              {info.options &&
                info.options.map((option, index: number) => (
                  <div key={index} className={styles.text} style={{ paddingLeft: getPaddingLeft(info) }}>
                    {option}
                  </div>
                ))}
            </div>
          </span>
        ))}
      {discounts && (
        <div className={styles.discount}>
          <div className={styles.discountName}>{discounts.name?.map(DisplayDiscountElement(discounts.global))}</div>
          <div>{discounts.price?.map(DisplayDiscountElement(discounts.global))}</div>
        </div>
      )}
      {note && (
        <>
          <div className={styles.text}>{note.title}</div>
          {note.text && <div className={styles.noteText}>{note.text}</div>}
        </>
      )}
      {refund && (
        <div
          className={styles.text}
          style={{
            color: 'var(--sys-color-content-danger)',
          }}
        >
          {refund}
        </div>
      )}
    </div>
  )
}

export default ProductLine
