import { DatePicker } from '@mui/x-date-pickers-pro';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

import './datePicker.css';
import { theme, themeRequester } from './datePickerTheme';
import { TypeFormError } from '../../../format/errors/types';
import { useMemo, useState } from 'react';
import { DateValidationError } from '@mui/x-date-pickers/models';

const getCustomTheme = (error: any) => {
  return createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: `var(--sys-typography-body-sm-font-size)`,
            textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
            letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
            fontWeight: `var(--sys-typography-body-sm-font-weight)`,
            lineHeight: `var(--sys-typography-body-sm-line-height)`,
            fontFamily: `'Poppins', sans- serif !important`,
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: `var(--sys-typography-body-sm-font-size)`,
            textDecoration: `var(--sys-typography-body-sm-text-decoration)`,
            letterSpacing: `var(--sys-typography-body-sm-letter-spacing)`,
            fontWeight: `var(--sys-typography-body-sm-font-weight)`,
            lineHeight: `var(--sys-typography-body-sm-line-height)`,
            fontFamily: `'Poppins', sans- serif !important`,
            color: error ? `var(--comp-textfield-color-helper-text-error) !important` : `inherit !important`
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            minWidth: '100%',
            fontFamily: `'Poppins', sans- serif !important`,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: `var(--sys-size-border-radius-md)`,
            minWidth: '100%',
            fontFamily: `'Poppins', sans- serif !important`,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            backgroundColor: ':hover black',
            fontFamily: `'Poppins', sans- serif !important`,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: `'Poppins', sans- serif !important`,
            fontSize: `var(--sys-typography-label-xs-font-size)`,
            textDecoration: `var(--sys-typography-label-xs-text-decoration)`,
            letterSpacing: `var(--sys-typography-label-xs-letter-spacing)`,
            fontWeight: `var(--sys-typography-label-xs-font-weight)`,
            lineHeight: `var(--sys-typography-label-xs-line-height)`,
          }
        }
      }
    },
  })
}

const DatePickers = ({ name, value, index, field, onChange }: {
  name: string, value: any, index?: number, field: string, onChange?: (field: string, index?: number) => (event: any) => void
}) => {
  return (
    <ThemeProvider theme={theme}>
      <DatePicker
        label={name}
        formatDensity='spacious'
        value={value ? value : 'JJ / MM / AAAA'}
        onChange={(date: any) => {
          if (date) {
            onChange && onChange(field, index)(dayjs(date).format())
          }
        }}
      />
    </ThemeProvider>
  )
}

export const DatePickerCustom = ({ value, onChange }: {
  value: any, index?: number, field: string, onChange: (field: string, index?: number) => (event: any) => void
}) => {
  return (
    <ThemeProvider theme={theme}>
      <DatePicker
        formatDensity='spacious'
        value={value ? value : 'JJ / MM / AAAA'}
        onChange={(date: any) => {
          onChange(date)
        }}
      />
    </ThemeProvider>
  )
}

export const DatePickerRequester = ({ name, value, onChange }: { name?: string, value: any, onChange?: (date: any) => void }) => {

  return (
    <ThemeProvider theme={themeRequester}>
      <DatePicker
        value={value}
        onChange={onChange}
      />
    </ThemeProvider>
  )
}

export const InventoryDatePicker = ({ name, value, onChange }: {
  name: string, value: any, onChange?: (date: any) => void
}) => {
  const today = dayjs().endOf('day');

  const [errors, setError] = useState<DateValidationError | null>(null)
  const errorMessage = useMemo(() => {
    switch (errors) {
      case 'maxDate':
      case 'minDate': {
        return "Cette date est dans le futur, l'information de stock ci-dessous est basée sur le stock d'aujourd'hui";
      }

      case 'invalidDate': {
        return 'Date invalide';
      }

      default: {
        return '';
      }
    }
  }, [errors]);

  return (
    <ThemeProvider theme={getCustomTheme(errors)}>
      <DatePicker
        label={name}
        onError={(newError) => setError(newError)}
        formatDensity='spacious'
        value={value}
        slotProps={{
          textField: {
            helperText: errorMessage,
          },
        }}
        onChange={onChange}
        maxDate={today}
      />
    </ThemeProvider>
  )
}

export default DatePickers