import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../newComponents/button'
import { ComponentSize, ComponentState, ComponentType } from '../../newComponents/types'

import styles from './Drawer.module.css'
import generateDataCy from '../../../utils/cypress'

const CancelButton = ({
  cancel,
  text,
  helperText,
  width
}: {
  cancel: () => void
  text?: string
  helperText?: string | null
  width?: boolean
}) => {

  const { t } = useTranslation()
  const dataCy = generateDataCy({scope: "cancel", value: "button"})

  return (
    <div className={styles.fusionButton}>
      <Button
        dataCy={dataCy}
        title={t('button.CANCEL')}
        size={ComponentSize.LARGE}
        type={ComponentType.TERTIARY}
        state={ComponentState.DEFAULT}
        onClick={() => {
          cancel()
        }}
        width={'174px'}
      />
    </div>
  )
}

export default CancelButton
