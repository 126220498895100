import { CellProps } from '../../../types/cell'
import generateDataCy from '../../../utils/cypress'
import Input from '../../newComponents/input'
import { ComponentState } from '../../newComponents/types'

const DisabledCell = ({ value, error, icons, validator }: CellProps): JSX.Element => (
  <Input
    type={'text'}
    value={value}
    style={{
      state: ComponentState.DISABLED,
    }}
    dataCy={generateDataCy({ scope: 'cell', value: 'disabled' })}
  />
)

export default DisabledCell
