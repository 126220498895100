import TopBar from '../../components/newComponents/topBar'
import Path from '../../components/newComponents/topBar/path'

import styles from './Template.module.css'

const Template = ({ children, background }: { children: any; background?: string }) => (
  <div className={styles.container}>
    <TopBar leftPart={<Path />} />
    <div className={styles.containerChildren} style={{ background: background ?? 'var(--sys-color-surface-00)' }}>
      {children}
    </div>
  </div>
)

export default Template
