const Menu = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.16138 18H20.1614C20.7114 18 21.1614 17.55 21.1614 17C21.1614 16.45 20.7114 16 20.1614 16H4.16138C3.61138 16 3.16138 16.45 3.16138 17C3.16138 17.55 3.61138 18 4.16138 18ZM4.16138 13H20.1614C20.7114 13 21.1614 12.55 21.1614 12C21.1614 11.45 20.7114 11 20.1614 11H4.16138C3.61138 11 3.16138 11.45 3.16138 12C3.16138 12.55 3.61138 13 4.16138 13ZM3.16138 7C3.16138 7.55 3.61138 8 4.16138 8H20.1614C20.7114 8 21.1614 7.55 21.1614 7C21.1614 6.45 20.7114 6 20.1614 6H4.16138C3.61138 6 3.16138 6.45 3.16138 7Z"
      fill={color}
    />
  </svg>
)

export default Menu
