import { GetTaxQueryVariables } from '../../API'
import { getTax } from '../../graphql/custom/queries'
import { TaxAssociations } from '../../store/types'
import { callService } from '..'

/**
 *
 * This method calls the API for extra informations about a tax used in a cashbook
 * It gets tax infos and returns them
 *
 * @param t - A method to internationalize tax's name
 * @param taxes - List of taxes
 *
 * @returns - A promise of tax infos
 *
 * @author - Jennifer Charlois
 *
 */
const getTaxesInfos = (
  t: (field: string, extra: { rate: number }) => string,
  taxes: Array<{ id: string; totals: { totalTax: number } }>,
  decimals: number
) =>
  Promise.all(
    taxes.map((tax: { id: string; totals: { totalTax: number } }) =>
      callService<GetTaxQueryVariables>({ id: tax.id }, getTax, 'getTax').then(({ data }) => {
        const rate = data.rate / 10 ** decimals
        const title = t('activity.cashbook.stats.TVA_X', { rate })
        return {
          title,
          value: tax.totals ? tax.totals.totalTax : 0,
        }
      })
    )
  )

const getProductCount = (items: Array<any>, id: string) =>
  items.filter((item: any) => item.taxID === id).map((item) => item.id)

/**
 *
 * This method gets tax associations related to product entity
 *
 * @usedIn - Subscription
 *
 * @param state - Store
 *
 * @returns - New associations
 *
 * @author - Jennifer Charlois
 *
 */
const getTaxesAssociations = (items: Array<any>): Array<TaxAssociations> => {
  const taxIds = items
    .flatMap((product: { taxID: string }) => product.taxID)
    .sort()
    .filter((id: string, index: number, tab) => tab.indexOf(id) === index)
    .map((id: string) => id)

  return taxIds.map((id: string) => ({
    taxID: id,
    products: getProductCount(items, id),
  }))
}

export { getTaxesInfos, getTaxesAssociations }
