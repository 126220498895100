import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Input from '../../../components/newComponents/input'
import { TypeSellerCode } from '../../../types/account/sellerCode'
import sellerCodeValidator from '../../../format/errors/filters/sellerCode'
import Template from '../template'
import { handleBlur, handleChange } from '../../../format/form'
import generateDataCy from '../../../utils/cypress'
import { ComponentErrorText, ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import { GetSellerCodeQueryVariables } from '../../../API'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { updateDrawerState } from '../../../actions/drawer'
import { DrawerState } from '../../../store/types'
import { callService } from '../../../services'
import { DataWithErrors } from '../../../services/types'
import { getCompanyID } from '../../../services/localStorage'
import { getSellerCode } from '../../../graphql/custom/queries'
import callUpdateSellerCode from './service'
import Button from '../../../components/newComponents/button'

import styles from '../Template.module.css'

const SellerCode = () => {
  const initialState: TypeSellerCode = {
    sellerCode: '',
    isExisted: false,
    showSubmit: false,
    validators: {
      sellerCode: sellerCodeValidator,
    },
    errors: {},
  }
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const setSellerCodeInState = (data: DataWithErrors) => {
    if (data.data) {
      const sellerCode = data.data
      setState({
        ...state,
        sellerCode: sellerCode.code,
        isExisted: !!sellerCode.code,
      })
      updateDrawerState(DrawerState.LOADED, dispatch)
    } else updateDrawerState(DrawerState.ERROR, dispatch)
  }

  useEffect(() => {
    callService<GetSellerCodeQueryVariables>({ companyID: getCompanyID() }, getSellerCode, 'getSellerCode').then(
      setSellerCodeInState
    )
      }, [])

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state, isExisted: state.sellerCode.length === 4 })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    } else {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
      setState({ ...state, isExisted: state.sellerCode.length === 4, showSubmit: false })
    }
  }

  const handleSubmit = () => {
    setState({ ...handleBlur(state, 'sellerCode'), showSubmit: false })

    if (state.sellerCode.length !== 4) {
      setState({
        ...state,
        showSubmit: false,
        errors: {
          sellerCode: {
            value: true,
            message: "Doit contenir 4 chiffres",
          }
        }
      })
    }

    if (!findErrorsInState(state.errors)) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      callUpdateSellerCode(state)!.then(handleResult)
    }
  }

  const errorText = () => {
    if (state.errors?.sellerCode?.value) {
      return state.errors.sellerCode.message;
    }

    if (state.errors.global?.errorType === 'acc-013') {
      return ComponentErrorText.FOUR_DIGIT;
    }
  }

  return (
    <Template>
      <div className={styles.form}>
        <div className={styles.title}>{t('account.sellerCode.TITLE')}</div>
        <div className={styles.subTitle}>{t('account.sellerCode.SUBTITLE')}</div>
        <div className={styles.subTitle}>{t('account.sellerCode.INFO')}</div>
        <Input
          max={4}
          label={t('account.sellerCode.SELLER_CODE')}
          value={state.sellerCode}
          onChange={(event) => {
            setState(handleChange(state, 'sellerCode')(event ? event.toString() : ''));
          }}
          onBlur={() => setState(handleBlur(state, 'sellerCode'))}
          style={{
            status: state.errors?.sellerCode?.value || state.errors?.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            width: '12.5em',
          }}
          helperText={errorText()}
          dataCy={generateDataCy({ scope: 'account', value: 'sellerCode' })}
        />
        <Button
          width="99px"
          title={t('button.SAVE')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
        />
      </div>
    </Template>
  )
}

export default SellerCode
