import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'
import callPackUpdate from './service/update'
import callPackCreation from './service/create'
import FormPackOption from './packOption'
import { setErrorsInState, findErrorsInState } from '../../../../format/errors'
import { getDrawerState, selectedCompany } from '../../../../store/selector'
import { drawerResetHistory, updateDrawerState } from '../../../../actions/drawer'
import { TypePack, TypePackVariationOption } from '../../../../types'
import { DrawerState } from '../../../../store/types'
import Loader, { displayLoader } from '../../../../components/newComponents/loader'
import { displayIconText } from '../../../../utils/string'
import { handleBlur, handleBlurNested } from '../../../../format/form'
import { getDrawerHeight, getDrawerHeightNoTab, getDrawerHeightWithDelete } from '../../../../utils'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'

type Props = {
  packState: TypePack
  updateState: (state: any) => void
  close: () => void
  dataCy: string
}

const Detail = ({ packState, updateState, close, dataCy }: Props) => {
  const [state, setState] = useState(packState)
  const drawerState = useSelector(getDrawerState)
  const company = useSelector(selectedCompany)
  const dispatch = useDispatch()

  const handleChange = (field: string, index?: number) => (event: any) => {
    state.errors.global = undefined
    state.dirties = [...state.dirties, field]

    if (field === 'photo') {
      setState({
        ...state,
        selected: !event ? 'color' : 'photo',
        [field]: event,
        showSubmit: !findErrorsInState(state.errors),
      })
    } else if (field === 'color') {
      setState({ ...state, selected: 'color', [field]: event, showSubmit: !findErrorsInState(state.errors) })
    } else if (field === 'iconText') {
      // @ts-ignore
      state.errors[field] = null
      state.isUpdateIconText = true

      setState({
        ...state,
        [field]: event,
        showSubmit: !findErrorsInState(state.errors),
      })
    } else if (field === 'customFields') {
      // @ts-ignore
      state.customFields[index].value = event
      setState({ ...state, showSubmit: !findErrorsInState(state.errors) })
    } else {
      // @ts-ignore
      state.errors[field] = null

      if (field === 'name' && !state.isUpdateIconText) {
        state.iconText = event ? displayIconText(event) : ''
        state.dirties = [...state.dirties, 'iconText']
      }

      setState({ ...state, [field]: event, showSubmit: !findErrorsInState(state.errors) })
    }
  }

  const handleResult = (res: any) => {
    if (!res.errors || res.errors.length === 0) {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
      drawerResetHistory(dispatch)
      close()
    } else {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    }
  }

  const handleSubmit = () => {
    state.showSubmit = false
    setState(handleBlur(state, 'name'))
    setState(handleBlur(state, 'packVariations'))

    const { customFields, ...errors } = state.errors
    if (!findErrorsInState(errors) && !customFields?.find((cf) => cf && cf.value)) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      if (!state.id) {
        callPackCreation(state, company.currencyDecimals).then(handleResult)
      } else {
        callPackUpdate(state, company.currencyDecimals).then(handleResult)
      }
    }
  }

  const openPackOptionView = (_: string | undefined, index: number) => {
    updateState({
      ...state,
      indexOfPack: index,
      packOptionView: true,
    })
  }

  const getPackOptionName = (options: Array<TypePackVariationOption>): string =>
    options.map((option: { name: string }) => option.name).join(', ')

  const savePackOption = (options: Array<TypePackVariationOption>, indexOfPack: number) => {
    state.dirties = [...state.dirties, 'packVariations']
    if (indexOfPack === -1) {
      state.packVariations.push({
        id: '',
        name: getPackOptionName(options),
        options: [],
      })
      state.packVariations[state.packVariations.length - 1].options = options
    } else {
      state.packVariations[indexOfPack].name = getPackOptionName(options)
      state.packVariations[indexOfPack].options = options
    }
    state.errors.global = undefined
    state.errors.packVariations = undefined
    updateState({
      ...state,
      showSubmit: true,
      packOptionView: false,
    })
  }

  const deletePackOption = (indexOfPack: number) => {
    if (indexOfPack !== -1) {
      state.packVariations?.splice(indexOfPack, 1)
    }
    state.errors.packVariations = undefined
    updateState({
      ...state,
      showSubmit: true,
      packOptionView: false,
    })
  }

  const onDragEnd = (dragStart: number, dragEnd: number): void => {
    if (state.packVariations) {
      const packInsert = state.packVariations[dragStart]
      state.packVariations = state.packVariations.filter((_: {}, index: number) => index !== dragStart)
      state.packVariations?.splice(dragEnd, 0, packInsert)
      setState({ ...state, showSubmit: true })
    }
  }

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        !state.packOptionView ? (
          <div
            className={styles.containerForm}
            style={{
              height: state.id ? getDrawerHeight() : getDrawerHeightNoTab(),
            }}
            data-cy={dataCy}
          >
            <Form
              state={state}
              handleChange={handleChange}
              handleBlur={(field) => setState(handleBlur(state, field))}
              handleBlurNested={(index) => setState(handleBlurNested(state, 'customFields', index))}
              handleSubmit={handleSubmit}
              openPackOptionView={openPackOptionView}
              onDragEnd={onDragEnd}
              dataCy={dataCy}
            />
          </div>
        ) : (
          <div
            className={styles.containerForm}
            style={{
              height: getDrawerHeightWithDelete(),
            }}
            data-cy={dataCy}
          >
            <FormPackOption
              packVariations={
                state.packVariations &&
                state.packVariations[state.indexOfPack] &&
                state.packVariations[state.indexOfPack].options
              }
              indexOfPack={state.indexOfPack}
              savePackOption={savePackOption}
              onSubmit={handleSubmit}
              deletePackOption={deletePackOption}
              dataCy={dataCy}
            />
          </div>
        )
      ) : (
        <></>
      )}
    </>
  )
}

export default Detail
