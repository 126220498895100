import { useEffect, useState } from 'react'
import { ComponentState, ComponentStatus } from '../types'

import styles from './Layer.module.css'

const Layer = ({
  id,
  children,
  style,
  isFirst,
  isLast,
  isDanger,
  isSelected,
  isDisabled,
  onClick,
  dataCy,
}: {
  id?: string
  children: any
  style: any
  isFirst?: boolean
  isLast?: boolean
  isDanger?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  onClick?: () => void
  dataCy?: string
}) => {
  const initialState = isDanger ? ComponentStatus.DANGER : isSelected ? ComponentState.FOCUSED : ComponentState.DEFAULT
  const [state, setState] = useState(initialState)

    useEffect(() => setState(initialState), [isDanger, isSelected])

  return (
    <div
      id={id}
      className={styles.layer}
      style={{
        background: `var(--st-layer-color-${isDanger ? 'danger-' : 'default-'}${state})`,
        borderTopLeftRadius: isFirst ? '8px' : '',
        borderTopRightRadius: isFirst ? '8px' : '',
        borderBottomLeftRadius: isLast ? '8px' : '',
        borderBottomRightRadius: isLast ? '8px' : '',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ...style,
      }}
      onMouseEnter={() => !isDisabled && setState(ComponentState.HOVER)}
      onMouseLeave={() => !isDisabled && setState(initialState)}
      onClick={() => {
        !isDisabled && setState(ComponentState.FOCUSED)
        !isDisabled && onClick && onClick()
      }}
      data-cy={dataCy}>
      {children}
    </div>
  )
}

export default Layer
