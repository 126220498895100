import { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ValidButton from '../../oldComponents/buttons/validButton'
import ListItem from '../listItem'
import List from '../list'
import { ComponentState } from '../types'
import Input from '../input'
import { CheckBoxChecked, CheckBoxUnchecked, KeyboardArrowDown } from '../../../assets/icons'
import { isOutsideViewport } from './unique'
import generateDataCy from '../../../utils/cypress'

export type SelectOptions = { id: string; value: string; checked?: boolean }

type State = {
  display: boolean
  value: string
  options: Array<SelectOptions>
  cursor: number
}

/**
 *
 * This element displays a select input with its dropdown
 *
 * @usedIn - Form
 *
 * @param value - The value selected
 * @param options - The list of selectable options
 * @param multiple - A boolean to know if it's a multiple select or not
 * @param onChange - A CTA when we selected a value
 * @param onChanges - A CTA when we selected multiple values
 * @param onBlur - A CTA when we leave the select options
 * @param entities - A list of full entities (mainly shops for now) to get more informations in multiple select
 * @param dataCy - An unique id
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const MultipleDropDown = ({
  parentID,
  popUpID,
  value,
  options,
  state,
  onChanges,
  entities,
  dataCy,
}: {
  parentID?: string
  popUpID?: string
  value?: string
  options: Array<SelectOptions>
  state?: ComponentState
  onChanges?: (options: Array<SelectOptions>, value: string) => void
  entities?: any
  dataCy?: string
}): JSX.Element => {
  const initialeState: State = {
    display: false,
    value: '',
    options: [],
    cursor: -1,
  }
  const [mState, setState] = useState(initialeState)
  const { t } = useTranslation()

  useEffect(() => {
    const newOptions = options.map((option) => ({
      id: option.id,
      value: option.value,
      checked: option.checked,
    }))
    setState({ ...mState, value: value ?? '', options: newOptions })
      }, [value, options])

  useLayoutEffect(() => {
    if (parentID && popUpID) {
      const popupElement = document.getElementById(popUpID)

      if (popupElement) {
        popupElement.style.top = isOutsideViewport(parentID, popUpID).top
        popupElement.style.width = isOutsideViewport(parentID, popUpID).width

        if (isOutsideViewport(parentID, popUpID).left)
          popupElement.style.left = isOutsideViewport(parentID, popUpID).left
        else popupElement.style.right = isOutsideViewport(parentID, popUpID).right
      }
    }
      }, [mState.display])

  const displayOptions = () =>
    (!state || state !== ComponentState.DISABLED) && setState({ ...mState, display: !mState.display })

  const handleBlur = (event: any) => {
    if (
      !event.relatedTarget ||
      (event.relatedTarget &&
        event.relatedTarget.id !== 'selectShopPopUp' &&
        event.relatedTarget.id !== 'listItemIconID')
    ) {
      setState({
        ...mState,
        value: value ?? '',
        options: options.map((option) => ({
          id: option.id,
          value: option.value,
          checked: option.checked,
        })),
        display: false,
      })
    }
  }

  const submitMultiple = () => {
    onChanges &&
      onChanges(
        mState.options.map((option) => ({
          id: option.id,
          value: option.value,
          checked: option.checked,
        })),
        mState.value
      )
    displayOptions()
  }

  const onClick = (id: string, isChecked: boolean) => {
    mState.options = mState.options.map((option) => ({
      ...option,
      checked: option.id === id ? isChecked : option.checked,
    }))
    const nbChecked = mState.options.filter((option) => option.checked).length
    mState.value =
      nbChecked === mState.options.length
        ? t('settings.shop.ALL')
        : nbChecked === 0
        ? t('settings.shop.NONE')
        : nbChecked === 1
        ? entities.find((entity: { id: string }) => entity.id === mState.options.find((option) => option.checked)?.id)
            ?.name
        : t('settings.shop.SOME', { shop: nbChecked, plural: nbChecked > 1 ? 's' : '' })

    setState({ ...mState })
  }

  /**
   *
   * This method handles the selection of one option by pressing arrow up or arrow down
   *
   * @param event - The key code related to the keyboard
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  // const handleKey = (event: any) => {
  //   if (event.keyCode === 40 && state.cursor < state.options.length - 1) {
  //     setState({ ...state, cursor: state.cursor + 1 })
  //   } else if (event.keyCode === 38 && state.cursor > 0) {
  //     setState({ ...state, cursor: state.cursor - 1 })
  //   } else if (event.keyCode === 13 && state.options[state.cursor]) {
  //     if (multiple) onClick(state.options[state.cursor].id, !state.options[state.cursor].checked)
  //     else handleSelect(state.options[state.cursor].id)
  //   }
  // }

  return (
    <div>
      <Input
        id={parentID}
        value={mState.value}
        style={{
          trailIcons: [{ icon: KeyboardArrowDown }],
          state: state ?? ComponentState.DEFAULT,
          readOnly: true,
        }}
        onClick={displayOptions}
        onBlur={handleBlur}
        dataCy={dataCy}
      />
      {mState.display && (
        <List
          id={popUpID}
          items={mState.options.map((option, index, tab) => (
            <>
              <ListItem
                key={option.id}
                item={option.value}
                onClick={() => {
                  onClick(option.id, !option.checked!)
                }}
                LeadIcon={option.checked ? CheckBoxChecked : CheckBoxUnchecked}
                state={option.checked ? ComponentState.ACTIVE : ComponentState.DEFAULT}
                dataCy={generateDataCy({ scope: dataCy!, value: option.value })}
              />
              {index === tab.length - 1 && (
                <ValidButton
                  dataCy={generateDataCy({ scope: dataCy!, value: 'submit' })}
                  onClick={submitMultiple}
                  disabled={mState.options.filter((option) => option.checked).length === 0}
                />
              )}
            </>
          ))}
        />
      )}
    </div>
  )
}

export default MultipleDropDown
