import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import nameValidator from '../../../format/errors/filters/setting/tag'
import { closeAndResetModal } from '../../../actions/modal'
import { findErrorsInState, setErrorsInState } from '../../../format/errors'
import { getSelectedTagAssociations, listTagItems, listUserItemsNextToken } from '../../../store/selector'
import { ComponentErrorText, ComponentSize, ComponentState, ComponentStatus, ComponentType } from '../../../components/newComponents/types'
import Input from '../../../components/newComponents/input'
import { callService } from '../../../services'
import { DeleteTagInput, UpdateTagInput } from '../../../API'
import { deleteTag, updateTag } from '../../../graphql/custom/mutations'
import { handleBlur, handleChange } from '../../../format/form'
import generateDataCy from '../../../utils/cypress'
import { TypeTagState } from '../../../types'
import Button from '../../../components/newComponents/button'
import { getTag } from '../../../getters/setting/tag'
import { DataWithErrors } from '../../../services/types'
import { displayFullName } from '../../../utils/string'
import { displayDateShortMonthWithHours } from '../../../utils/date'

import styles from '../../../components/newComponents/modal/Modal.module.css'

const TagModal = ({ id, isDelete }: { id: string; isDelete?: boolean }) => {
  const initialState: TypeTagState = {
    id: '',
    name: '',
    showSubmit: false,
    errors: {},
    validators: {
      name: nameValidator,
    },
  }
  const [state, setState] = useState(initialState)
  const users = useSelector(listUserItemsNextToken)
  const allTags = useSelector(listTagItems)
  const association = useSelector(getSelectedTagAssociations(id))
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cyContext = generateDataCy({ scope: 'settings', value: 'tag' })

  const setTagInState = (tag: any) => {
    if (tag) {
      const user = users.items.find((user: { id: string }) => user.id === tag.createdByID)

      setState({
        ...state,
        id: tag.id,
        name: tag.name,
        createdBy: user ? displayFullName(user.firstName, user.lastName) : '',
        createdAt: displayDateShortMonthWithHours(tag.createdAt),
        association: tag.productCount,
        showSubmit: !!isDelete,
      })
    }
  }

  useEffect(() => {
    if (id) {
      const tag = allTags.find(tag => tag.id === id);
      setTagInState(tag);
    }
      }, [id])

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
    } else {
      closeAndResetModal(dispatch)
    }
  }

  const handleSubmit = () => {
    if (isDelete)
      callService<{ input: DeleteTagInput }>({ input: { id: state.id } }, deleteTag, 'deleteTag').then(() =>
        closeAndResetModal(dispatch)
      )
    else {
      setState({ ...handleBlur(state, 'name'), showSubmit: false })

      if (!findErrorsInState(state.errors) && state.name) {
        callService<{ input: UpdateTagInput }>(
          { input: { id: state.id, name: state.name } },
          updateTag,
          'updateTag'
        ).then(handleResult)
      }
    }
  }

  const errorText = () => {
    if (state.errors?.name?.value) {
      return state.errors.name.message
    }

    if (state.errors.global?.value) {
      return ComponentErrorText.ALREADY_EXIST;
    }

    return undefined;
  }

  return (
    <div
      className={styles.modal}
      data-cy={generateDataCy({
        scope: cyContext,
        value: isDelete ? 'delete' : id ? 'update' : 'create',
      })}
    >
      <div className={isDelete ? styles.modalDangerTitle : styles.modalTitle}>
        {isDelete ? t('settings.tag.modal.DELETE') : t('settings.tag.modal.EDIT')}
      </div>
      {isDelete ? (
        <div className={styles.modalContent}>
          <div
            className={styles.modalImportantInfo}
            data-cy={generateDataCy({ scope: cyContext, value: 'associations' })}
          >
            {t('settings.tag.modal.ASSOCIATION', {
              tagName: state.name,
              tagAssociations: state.association,
              pluralAssociation: state.association && state.association > 0 ? 's' : '',
            })}
          </div>
          <div className={styles.modalInfo}>{t('settings.tag.modal.CONFIRM')}</div>
        </div>
      ) : (
        <div className={styles.modalContent}>
          <Input
            label={t('settings.tag.modal.NAME')}
            value={state.name}
            onChange={(event) => setState(handleChange(state, 'name')(event))}
            onBlur={() => setState(handleBlur(state, 'name'))}
            onSubmit={handleSubmit}
            style={{
              status: state.errors.name?.value || state.errors.global?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
            }}
            helperText={errorText()}
            dataCy={generateDataCy({ scope: cyContext, value: 'name' })}
          />
          <Input
            label={t('settings.tag.modal.CREATED_BY')}
            value={state.createdBy}
            style={{
              state: ComponentState.DISABLED,
            }}
            dataCy={generateDataCy({ scope: cyContext, value: 'createdBy' })}
          />
          <Input
            label={t('settings.tag.modal.CREATED_AT')}
            value={state.createdAt}
            style={{
              state: ComponentState.DISABLED,
            }}
            dataCy={generateDataCy({ scope: cyContext, value: 'createdAt' })}
          />
          <Input
            label={t('settings.tag.modal.NUMBER_ASSOCIATION')}
            value={state.association}
            style={{
              state: ComponentState.DISABLED,
            }}
            dataCy={generateDataCy({ scope: cyContext, value: 'associations' })}
          />
        </div>
      )}
      <div className={styles.modalButtons}>
        <Button
        dataCy={"cancel"}
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={isDelete ? ComponentType.SECONDARY : ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
        />
        <Button
          title={isDelete ? t('button.DELETE') : t('button.SAVE')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          status={isDelete ? ComponentStatus.DANGER : ComponentStatus.DEFAULT}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleSubmit}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default TagModal
