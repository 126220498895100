const Check = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.95644 15.875L5.48644 12.405C5.09644 12.015 4.46644 12.015 4.07644 12.405C3.68644 12.795 3.68644 13.425 4.07644 13.815L8.25644 17.995C8.64644 18.385 9.27644 18.385 9.66644 17.995L20.2464 7.41502C20.6364 7.02502 20.6364 6.39502 20.2464 6.00502C19.8564 5.61502 19.2264 5.61502 18.8364 6.00502L8.95644 15.875Z"
      fill={color}
    />
  </svg>
)

export default Check
