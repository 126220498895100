import { Action, AppState, ListState, objectType } from '../store/types';
import { getData } from '../services';
import formatWithSubvalues from '../format/product';
import { getCustomFieldsAssociations } from '../services/setting/customField';
import { getCategoriesAssociations } from '../services/catalog/category';
import { fetchCompanySettings } from './company';
import { getAccount } from '../getters/setting/account';
import { fetchListElement, sortListElement, updateListState } from './list';
import { subscribeToAll } from '../store/subscriptions';
import { getTagsAssociations } from '../services/setting/tag';
import { getOptionsAssociations } from '../services/catalog/option';
import { getPacksAssociations } from '../services/catalog/pack';
import { getTaxesAssociations } from '../services/setting/tax';
import { updateAppState } from './app';
import { pluralize } from '../utils/typeToType';
import toggleMenu from './menu';
import { getCompanyID } from '../services/localStorage';
import { generateInventoryColsConfiguration } from './inventory';
import { generateCustomFieldColsConfiguration } from './customField';
import { initCatalog, initSettings } from '../graphql/custom/queries';
import { AccountInvitationStatus } from '../API';

/**
 *
 * This method initialise all application at the beginning
 * It calls all related list
 *
 * @param dispatch - A hook to call the store
 *
 * @returns - void
 *
 * @author - Arthur Escriou
 *
 */
const initApp = async (dispatch: any, t: (field: string) => string) => {
  const account = await getAccount(dispatch);
  const catalog = await getData({ request: initCatalog, limit: 100 });
  const settings = await getData({ request: initSettings, limit: 100 });

  if (account.data) {
    if (account.data.shops && account.data.shops.items && account.data.shops.items.length > 0) {
      const shops = account.data.shops.items
        .filter((_: any) => _)
        .map((shop: any) => shop.shop)
        .filter((shop: any) => shop.companyID === getCompanyID());
      fetchListElement(dispatch, shops, account.data.shops.nextToken, pluralize(objectType.SHOP));
      sortListElement(dispatch, 'name', 'asc', objectType.SHOP);
      generateInventoryColsConfiguration(dispatch, shops);
      updateListState({ type: pluralize(objectType.SHOP), state: ListState.LOADED }, dispatch);
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.SHOP));
      updateListState({ type: pluralize(objectType.SHOP), state: ListState.EMPTY }, dispatch);
    }

    if (account.data.companies && account.data.companies.items && account.data.companies.items.length > 0) {
      const companies = account.data.companies.items
        .filter((_: any) => _)
        .map((company: any) => ({ ...company.company, role: company.role }));
      fetchListElement(dispatch, companies, account.data.companies.nextToken, pluralize(objectType.COMPANY));
      sortListElement(dispatch, 'name', 'asc', objectType.COMPANY);
    } else fetchListElement(dispatch, [], '', pluralize(objectType.COMPANY));

    const shops =
      account.data.shops && account.data.shops.items
        ? account.data.shops.items
            .filter((_: any) => _)
            .filter((shop: any) => shop.companyID === getCompanyID())
            .map((shop: any) => shop.shop)
        : [];
    subscribeToAll(shops, dispatch);
  }

  if (catalog || settings) {
    if (catalog.requestProducts && catalog.requestProducts.items && catalog.requestProducts.items.length > 0) {
      const products = catalog.requestProducts.items
        .filter((_: any) => _)
        .map((item: any) => formatWithSubvalues(false, false)(item));
      fetchListElement(dispatch, products, catalog.requestProducts.nextToken, pluralize(objectType.PRODUCT));
      updateListState({ type: pluralize(objectType.PRODUCT), state: ListState.LOADED }, dispatch);
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.PRODUCT));
      updateListState({ type: pluralize(objectType.PRODUCT), state: ListState.EMPTY }, dispatch);
    }

    if (catalog.requestCategories && catalog.requestCategories.items && catalog.requestCategories.items.length > 0) {
      const categories = catalog.requestCategories.items
        .filter((_: any) => _)
        .map((category: any) => ({
          ...category,
          photo: category.photo ? category.photo + '?' + Date.now() : undefined,
          selected: false,
        }));
      if (catalog.requestProducts && catalog.requestProducts.items && catalog.requestProducts.items.length > 0) {
        const categoriesAssociations = getCategoriesAssociations(
          catalog.requestProducts.items
            .filter((_: any) => _)
            .map((item: any) => formatWithSubvalues(false, false)(item)),
        );

        dispatch({
          type: Action.FETCH_ITEM,
          payload: {
            items: categoriesAssociations,
            type: 'categoriesAssociations',
          },
        });
      }

      fetchListElement(dispatch, categories, catalog.requestCategories.nextToken, pluralize(objectType.CATEGORY));
      updateListState({ type: pluralize(objectType.CATEGORY), state: ListState.LOADED }, dispatch);
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.CATEGORY));
      updateListState({ type: pluralize(objectType.CATEGORY), state: ListState.EMPTY }, dispatch);
    }

    if (catalog.requestOptions && catalog.requestOptions.items && catalog.requestOptions.items.length > 0) {
      const options = catalog.requestOptions.items
        .filter((_: any) => _)
        .map((option: any) => ({
          ...option,
          selected: false,
        }));
      if (catalog.requestProducts && catalog.requestProducts.items && catalog.requestProducts.items.length > 0) {
        const optionsAssociations = getOptionsAssociations(
          catalog.requestProducts.items
            .filter((_: any) => _)
            .map((item: any) => formatWithSubvalues(false, false)(item)),
        );

        dispatch({
          type: Action.FETCH_ITEM,
          payload: {
            items: optionsAssociations,
            type: 'optionsAssociations',
          },
        });
      }

      fetchListElement(dispatch, options, catalog.requestOptions.nextToken, pluralize(objectType.OPTION));
      updateListState({ type: pluralize(objectType.OPTION), state: ListState.LOADED }, dispatch);
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.OPTION));
      updateListState({ type: pluralize(objectType.OPTION), state: ListState.EMPTY }, dispatch);
    }

    if (
      catalog.requestInventoryMovements &&
      catalog.requestInventoryMovements.items &&
      catalog.requestInventoryMovements.items.length > 0
    ) {
      const inventoriesMovement = catalog.requestInventoryMovements.items.filter((_: any) => _);

      fetchListElement(
        dispatch,
        inventoriesMovement,
        catalog.requestInventoryMovements.nextToken,
        pluralize(objectType.INVENTORY_MOVEMENT),
      );
      updateListState({ type: pluralize(objectType.INVENTORY_MOVEMENT), state: ListState.LOADED }, dispatch);
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.INVENTORY_MOVEMENT));
      updateListState({ type: pluralize(objectType.INVENTORY_MOVEMENT), state: ListState.EMPTY }, dispatch);
    }

    // if (catalog.requestPacks && catalog.requestPacks.items && catalog.requestPacks.items.length > 0) {
    //   const packs = catalog.requestPacks.items
    //     .filter((_: any) => _)
    //     .map((pack: any) => ({
    //       ...pack,
    //       photo: pack.photo ? pack.photo + '?' + Date.now() : undefined,
    //       selected: false,
    //     }))
    //   if (catalog.requestProducts && catalog.requestProducts.items && catalog.requestProducts.items.length > 0) {
    //     const packsAssociations = getPacksAssociations(
    //       catalog.requestProducts.items.filter((_: any) => _).map((item: any) => formatWithSubvalues(false)(item)),
    //       packs
    //     )

    //     dispatch({
    //       type: Action.FETCH_ITEM,
    //       payload: {
    //         items: packsAssociations,
    //         type: 'packsAssociations',
    //       },
    //     })
    //   }

    //   fetchListElement(dispatch, packs, catalog.requestPacks.nextToken, pluralize(objectType.PACK))
    //   updateListState({ type: pluralize(objectType.PACK), state: ListState.LOADED }, dispatch)
    // } else {
    //   fetchListElement(dispatch, [], '', pluralize(objectType.PACK))
    //   updateListState({ type: pluralize(objectType.PACK), state: ListState.EMPTY }, dispatch)
    // }

    if (settings.listUsers && settings.listUsers.length > 0) {
      let users = settings.listUsers.filter((_: any) => _);

      if (settings.listCompanyInvitations.items && settings.listCompanyInvitations.items.length > 0) {
        users = [
          ...users,
          ...settings.listCompanyInvitations.items
            .filter((_: any) => _)
            .filter(
              (invitation: { status: AccountInvitationStatus }) => invitation.status === AccountInvitationStatus.SENT,
            )
            .map((invitation: any) => ({
              ...invitation,
              firstName: '-',
              lastName: '-',
              company: { role: invitation.role },
            })),
        ];
      }

      fetchListElement(dispatch, users, settings.listUsers.nextToken, pluralize(objectType.USER));
      sortListElement(dispatch, 'lastName', 'asc', objectType.USER);
      updateListState({ type: pluralize(objectType.USER), state: ListState.LOADED }, dispatch);
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.USER));
      updateListState({ type: pluralize(objectType.USER), state: ListState.EMPTY }, dispatch);
    }

    if (settings.listTaxes && settings.listTaxes.items && settings.listTaxes.items.length > 0) {
      const taxes = settings.listTaxes.items.filter((_: any) => _);
      fetchListElement(dispatch, taxes, settings.listTaxes.nextToken, pluralize(objectType.TAX));
      sortListElement(dispatch, 'rate', 'asc', objectType.TAX);
      updateListState({ type: pluralize(objectType.TAX), state: ListState.LOADED }, dispatch);

      if (catalog.requestProducts && catalog.requestProducts.items && catalog.requestProducts.items.length > 0) {
        const taxesAssociations = await getTaxesAssociations(
          catalog.requestProducts.items
            .filter((_: any) => _)
            .map((item: any) => formatWithSubvalues(false, false)(item)),
        );

        dispatch({
          type: Action.FETCH_ITEM,
          payload: {
            items: taxesAssociations,
            type: 'taxesAssociations',
          },
        });
      }
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.TAX));
      updateListState({ type: pluralize(objectType.TAX), state: ListState.EMPTY }, dispatch);
    }

    if (settings.listCustomFields && settings.listCustomFields.items && settings.listCustomFields.items.length > 0) {
      const customFields = settings.listCustomFields.items.filter((_: any) => _);
      fetchListElement(dispatch, customFields, settings.listCustomFields.nextToken, pluralize(objectType.CUSTOM_FIELD));
      updateListState({ type: pluralize(objectType.CUSTOM_FIELD), state: ListState.LOADED }, dispatch);
      sortListElement(dispatch, 'name', 'asc', objectType.CUSTOM_FIELD);
      generateCustomFieldColsConfiguration(customFields, dispatch);

      let items: Array<any> = [];
      if (catalog.requestProducts && catalog.requestProducts.items)
        items = [
          {
            type: 'products',
            items: catalog.requestProducts.items
              .filter((_: any) => _)
              .map((item: any) => formatWithSubvalues(false, false)(item)),
          },
        ];
      if (catalog.requestCategories && catalog.requestCategories.items)
        items = [...items, { type: 'categories', items: catalog.requestCategories.items.filter((_: any) => _) }];
      if (catalog.requestPacks && catalog.requestPacks.items)
        items = [...items, { type: 'packs', items: catalog.requestPacks.items.filter((_: any) => _) }];
      if (catalog.requestCustomers && catalog.requestCustomers.items)
        items = [...items, { type: 'customers', items: catalog.requestCustomers.items.filter((_: any) => _) }];

      const customFieldsAssociations = await getCustomFieldsAssociations(items);
      dispatch({
        type: Action.FETCH_ITEM,
        payload: {
          items: customFieldsAssociations,
          type: 'customFieldsAssociations',
        },
      });
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.CUSTOM_FIELD));
      updateListState({ type: pluralize(objectType.CUSTOM_FIELD), state: ListState.EMPTY }, dispatch);
    }

    if (settings.listTags && settings.listTags.items && settings.listTags.items.length > 0) {
      const tags = settings.listTags.items.filter((_: any) => _);
      fetchListElement(dispatch, tags, settings.listTags.nextToken, pluralize(objectType.TAG));
      sortListElement(dispatch, 'name', 'asc', objectType.TAG);
      updateListState({ type: pluralize(objectType.TAG), state: ListState.LOADED }, dispatch);

      if (catalog.requestProducts && catalog.requestProducts.items && catalog.requestProducts.items.length > 0) {
        const tagsAssociations = await getTagsAssociations(
          catalog.requestProducts.items
            .filter((_: any) => _)
            .map((item: any) => formatWithSubvalues(false, false)(item)),
        );

        dispatch({
          type: Action.FETCH_ITEM,
          payload: {
            items: tagsAssociations,
            type: 'tagsAssociations',
          },
        });
      }
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.TAG));
      updateListState({ type: pluralize(objectType.TAG), state: ListState.EMPTY }, dispatch);
    }

    if (
      settings.listPaymentMethods &&
      settings.listPaymentMethods.items &&
      settings.listPaymentMethods.items.length > 0
    ) {
      const paymentMethods = settings.listPaymentMethods.items.filter((_: any) => _);
      fetchListElement(
        dispatch,
        paymentMethods,
        settings.listPaymentMethods.nextToken,
        pluralize(objectType.PAYMENT_METHOD),
      );
      updateListState({ type: pluralize(objectType.PAYMENT_METHOD), state: ListState.LOADED }, dispatch);
    } else {
      fetchListElement(dispatch, [], '', pluralize(objectType.PAYMENT_METHOD));
      updateListState({ type: pluralize(objectType.PAYMENT_METHOD), state: ListState.EMPTY }, dispatch);
    }

    if (
      settings.listCompanySettings &&
      settings.listCompanySettings.items &&
      settings.listCompanySettings.items.length > 0
    ) {
      const listCompanySettings = settings.listCompanySettings.items.filter((_: any) => _);

      dispatch({
        type: Action.FETCH_ITEM,
        payload: {
          items: listCompanySettings,
          type: 'companySettings',
        },
      });
      fetchCompanySettings(listCompanySettings, dispatch);
    } else
      dispatch({
        type: Action.FETCH_ITEM,
        payload: {
          items: [],
          type: 'companySettings',
        },
      });

    // if (
    //   settings.requestBackgroundJobs &&
    //   settings.requestBackgroundJobs.items &&
    //   settings.requestBackgroundJobs.items.length > 0
    // ) {
    //   const backgroundJobs = settings.requestBackgroundJobs.items.filter((_: any) => _)
    //   fetchListElement(dispatch, backgroundJobs, settings.requestBackgroundJobs.nextToken, pluralize(objectType.IMPORT))
    //   updateListState({ type: pluralize(objectType.IMPORT), state: ListState.LOADED }, dispatch)
    // } else {
    //   fetchListElement(dispatch, [], '', pluralize(objectType.IMPORT))
    //   updateListState({ type: pluralize(objectType.IMPORT), state: ListState.EMPTY }, dispatch)
    // }

    const section = window.location.pathname.split('/')[1];
    const subSection = window.location.pathname.split('/')[2];
    toggleMenu(dispatch, {
      section: section ?? '',
      subSection: subSection ?? '',
    });
  }
  updateAppState(AppState.LOADED, dispatch);
};

export default initApp;
