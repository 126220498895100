import { isPresent } from './functions'
import match from './functions/string'
import {
  validPercent,
  validCurrency,
  positive,
  greaterThan,
  lowerThan,
  hasMinimumLengthOf,
  isNotDecimal,
  validNumber,
} from './functions/number'
import { validRole } from './functions/enum'
import validEmail from './functions/email'
import validPhoneNumber from './functions/phone'
import { samePassword, validPassword } from './functions/password'
import { hasAtLeastOneValue, hasUniqueValues } from './functions/array'
import { validCustomFieldKey, validWebsite } from './functions/regex'

export type TypeFormError = {
  value: boolean
  message: string
  errorType?: string
}

export type ValidationFunction = (val: any) => TypeFormError | null | undefined

export type Validator = {
  functions: Array<ValidationFunction>
  check: ValidationFunction
  chain: (v: Validator) => Validator
  isPresent: (val?: any) => Validator
  match: (val?: any) => Validator
  validPercent: (val?: any) => Validator
  positive: (val?: any) => Validator
  greaterThan: (val?: any) => Validator
  lowerThan: (val?: any) => Validator
  hasMinimumLengthOf: (val?: any) => Validator
  isNotDecimal: (val?: any) => Validator
  validEmail: (val?: any) => Validator
  validRole: (val?: string) => Validator
  validPhoneNumber: (val?: any) => Validator
  hasAtLeastOneValue: (val?: Array<any>) => Validator
  hasUniqueValues: ({ item, items }?: { item: string; items: Array<string> }) => Validator
  validPassword: (val?: string) => Validator
  validCustomFieldKey: (val?: any) => Validator
  samePassword: ({ first, second }?: { first: string; second: string }) => Validator
  validCurrency: (val?: any) => Validator
  validNumber: (val?: any) => Validator
  validWebsite: (val?: any) => Validator
}

export type FiltersType = {
  [key: string]: (val: any) => ValidationFunction
}

export const filters: FiltersType = {
  isPresent,
  match,
  validPercent,
  positive,
  greaterThan,
  lowerThan,
  hasMinimumLengthOf,
  isNotDecimal,
  validEmail,
  validRole,
  validPhoneNumber,
  hasAtLeastOneValue,
  hasUniqueValues,
  validPassword,
  samePassword,
  validCustomFieldKey,
  validCurrency,
  validNumber,
  validWebsite,
}
