import styles from './ListItem.module.css'

type Props = {
  children: any
  dataCy?: string
}

/**
 *
 * This element displays an item
 *
 * @param item - Item to display
 * @param dataCy - An unique id
 *
 * @returns - JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const ListItemContent = ({ children, dataCy }: Props): JSX.Element => {
  return (
    <div className={styles.listItemContent} data-cy={dataCy}>
      {children}
    </div>
  )
}

export default ListItemContent
