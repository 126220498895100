import { getCompanyID } from '../services/localStorage'
import { getElementAssociations } from '../services'
import { displayPercentage } from '../utils/number'
import {
  CategoryAssociations,
  CustomFieldAssociations,
  objectPlural,
  PackAssociations,
  StateStore,
  TagAssociations,
  TaxAssociations,
} from './types'

/**
 *
 * Those methods are called when we need to get variables from the store
 * It calls the store and returns the needed variable
 *
 * @author - Jennifer Charlois
 *
 */

const flatFileModal = (state: StateStore) => state.flatfile
const interval = (state: StateStore) => state.interval

const isOpenMenu = (state: StateStore) => state.openMenu

const getMenu = (state: StateStore) => state.menu

const actionDrawer = (state: StateStore) => state.drawer.action

const currentDrawer = (state: StateStore) => state.drawer

const currentNavigation = (state: StateStore) => state.drawer.navigation.current

const isDrawerOpen = (state: StateStore) => state.drawer.open

const getDashboardView = (state: StateStore) => state.dashboardView

const getIsIOS = (state: StateStore) => state.isIOS

const getModal = (state: StateStore) => state.modal

const getToaster = (state: StateStore) => state.toaster

const getAppState = (state: StateStore) => state.app.state

// @ts-ignore
const getListState = (type: string) => (state: StateStore) => state[type].state

const getDrawerState = (state: StateStore) => state.drawerState

const currentAccount = (state: StateStore) => state.account

const listCompanies = (state: StateStore) => state.companies

const listCompaniesItemsNextToken = (state: StateStore) => state.companies.items

const selectedCompany = (state: StateStore) => {
  const companyID = getCompanyID()
  return state.companies.items.items.find((company: { id: string }) => company.id === companyID)
}

// Activity

const listCashbooks = (state: StateStore) => state.cashbooks

const listCashbookItemsNextToken = (state: StateStore) => {
  if (state.cashbooks.query) {
    return state.cashbooks.filteredItems ? state.cashbooks.filteredItems : state.cashbooks.items
  }
  return state.cashbooks.items
}

const listCashbookItems = (state: StateStore) => state.cashbooks.items.items

const listPaymentItemsNextToken = (state: StateStore) => {
  if (state.payments.query) {
    return state.payments.filteredItems ? state.payments.filteredItems : state.payments.items
  }
  return state.payments.items
}

const listPayments = (state: StateStore) => state.payments

const listPaymentItems = (state: StateStore) => state.payments.items.items

const listSaleItemsNextToken = (state: StateStore) => {
  if (state.sales.query) {
    return state.sales.filteredItems ? state.sales.filteredItems : state.sales.items
  }
  return state.sales.items
}

const listSalesItems = (state: StateStore) => state.sales.items.items

const listSales = (state: StateStore) => state.sales

const listCustomerItemsNextToken = (state: StateStore) => {
  if (state.customers.query) {
    return state.customers.filteredItems ? state.customers.filteredItems : state.customers.items
  }
  return state.customers.items
}

const listCustomers = (state: StateStore) => state.customers

const listCustomerItems = (state: StateStore) => state.customers.items.items

// Catalog

const listProductItemsNextToken = (state: StateStore) => {
  if (state.products.query) {
    return state.products.filteredItems ? state.products.filteredItems : state.products.items
  }
  return state.products.items
}

const listProductsItems = (state: StateStore) => {
  const res = state.products.items.items
  // res
  //   .map((a, i) => ({ a, i }))
  //   .filter((a) => a.a.variantsCount > 0)
  //   .forEach(({ a, i }) => {
  //     res[i].subValues = state.variants.items.items.filter((v) => v.productID === a.id)
  //   })
  return res
}

const product = (id: string | undefined) => (state: StateStore) => state.products.items.items.find((a) => a.id === id)

const listProducts = (state: StateStore) => state.products

const listCategoryItemsNextToken = (state: StateStore) => {
  if (state.categories.query) {
    return state.categories.filteredItems ? state.categories.filteredItems : state.categories.items
  }
  return state.categories.items
}

const listCategories = (state: StateStore) => state.categories

const listCategoryItems = (state: StateStore) => state.categories.items.items

const listParsedCategories = (categoryToCreate?: string) => (stateStore: StateStore) => {
  const categories = stateStore.categories.items.items

  const entries = categories.map((cat: { name: string; id: string }) => ({
    id: cat.id,
    value: cat.name,
  }))
  if (categoryToCreate)
    return entries.concat({
      id: categoryToCreate,
      value: categoryToCreate,
    })
  return entries
}

const getSelectedCategoryAssociations = (id?: string) => (state: StateStore) =>
  getElementAssociations<CategoryAssociations>(state.categoriesAssociations, 'categoryID', id!)

const listCustomFields = (state: StateStore) => state.customFields

const listCustomFieldCols = (state: StateStore) => state.customFields.cols

const listCustomFieldItems = (state: StateStore) => state.customFields.items.items

const listCustomFieldItemsNextToken = (state: StateStore) => state.customFields.items

const listCustomFieldsAssociations = (state: StateStore) => state.customFieldsAssociations

const getSelectedCustomFieldAssociations = (id?: string) => (state: StateStore) =>
  getElementAssociations<CustomFieldAssociations>(state.customFieldsAssociations, 'customFieldID', id!)

const listDiscountItemsNextToken = (state: StateStore) => {
  if (state.discounts.query) {
    return state.discounts.filteredItems ? state.discounts.filteredItems : state.discounts.items
  }
  return state.discounts.items
}

const listDiscounts = (state: StateStore) => state.discounts

const listDiscountItems = (state: StateStore) => state.discounts.items.items

const listPackItemsNextToken = (state: StateStore) => {
  if (state.packs.query) {
    return state.packs.filteredItems ? state.packs.filteredItems : state.packs.items
  }
  return state.packs.items
}

const listPacks = (state: StateStore) => state.packs

const listPackItems = (state: StateStore) => state.packs.items.items

const getSelectedPackAssociations = (id?: string) => (state: StateStore) =>
  getElementAssociations<PackAssociations>(state.packsAssociations, 'productID', id!)

const listOptions = (state: StateStore) => state.options

const listOptionItems = (state: StateStore) => state.options.items.items

const listOptionItemsNextToken = (state: StateStore) => {
  if (state.options.query) {
    return state.options.filteredItems ? state.options.filteredItems : state.options.items
  }
  return state.options.items
}

const listOptionsAssociations = (state: StateStore) => state.optionsAssociations

/* Settings */

const listShops = (state: StateStore) => state.shops

const listShopsItemsNextToken = (state: StateStore) => state.shops.items

const listSlots = (state: StateStore) => state.slots

const listSlotsItemsNextToken = (state: StateStore) => state.slots.items

const listTaxes = (state: StateStore) => state.taxes

const listTaxItemsNextToken = (state: StateStore) => state.taxes.items

const listParsedTaxes = (state: StateStore) => {
  const taxes = state.taxes.items.items
  return taxes.map((cat: any) => ({
    id: cat.id,
    value: displayPercentage(cat.rate.toString()),
  }))
}

const listTaxesAssociations = (state: StateStore) => state.taxesAssociations

const getSelectedTaxAssociations = (id?: string) => (state: StateStore) =>
  getElementAssociations<TaxAssociations>(state.taxesAssociations, 'taxID', id!)

const listTagItems = (state: StateStore) => state.tags.items.items

const listParsedTags = (state: StateStore) => {
  const tags = state.tags.items.items
  const entries = tags.map((tag: { name: string; id: string }) => ({
    val: tag.name,
    id: tag.id,
  }))
  return entries
}

const listTags = (state: StateStore) => state.tags

const listTagItemsNextToken = (state: StateStore) =>
  state.tags.filteredItems ? state.tags.filteredItems : state.tags.items

const listTagsAssociations = (state: StateStore) => state.tagsAssociations

const getSelectedTagAssociations = (id?: string) => (state: StateStore) =>
  getElementAssociations<TagAssociations>(state.tagsAssociations, 'tagID', id!)

const listPaymentMethods = (state: StateStore) => state.paymentMethods

const listPaymentMethodsItemsNextToken = (state: StateStore) => state.paymentMethods.items

const listUsers = (state: StateStore) => state.users

const listUserItemsNextToken = (state: StateStore) => state.users.items

const getSelectedUser = (state: StateStore) => state.users.items.items.find((user) => user.id === state.account.id)

const listImports = (state: StateStore) => state.imports

const listImportItemsNextToken = (state: StateStore) => state.imports.items

const getBackgroundJobs = (ids: Array<string>) => (state: StateStore) =>
  state.imports.items.items.filter((item) => ids?.find((id) => id === item.id))

const listInventoryMovements = (state: StateStore) => state.inventories

const listInventoryMovementItems = (state: StateStore) => state.inventories.items.items

const inventoryMovementCols = (state: StateStore) => state.inventories.cols

const listInventoryMovementItemsNextToken = (state: StateStore) => {
  if (state.inventories.query) {
    return state.inventories.filteredItems ? state.inventories.filteredItems : state.inventories.items
  }
  return state.inventories.items
}

const listInventoryMovementsProduct = (state: StateStore) => state.inventoryMoveProducts

const listInventoryMovementProductItems = (state: StateStore) => state.inventoryMoveProducts.items.items

const listInventoryMovementProductCols = (state: StateStore) => state.inventoryMoveProducts.cols

const listInventoryMovementProductNextToken = (state: StateStore) => state.inventoryMoveProducts.items.nextToken

// Stats

const listStatPayments = (state: StateStore) => state.statPayments

const listStatTaxes = (state: StateStore) => state.statTaxes

const listStatProducts = (state: StateStore) => state.statProducts

const listStatCategories = (state: StateStore) => state.statCategories

const listStatValues = (state: StateStore) => state.statValues

// @ts-ignore
const getRequesterQuery = (type: objectPlural) => (state: StateStore) => state[type].query

const getStatsFilters = (state: StateStore) => state.stats

// @ts-ignore
const getFiltersState = (type: objectPlural) => (state: StateStore) => state[type].filters

export {
  getIsIOS,
  getModal,
  getToaster,
  isOpenMenu,
  getMenu,
  actionDrawer,
  currentDrawer,
  currentNavigation,
  isDrawerOpen,
  getDashboardView,
  getAppState,
  getListState,
  getDrawerState,
  currentAccount,
  listCompanies,
  listCompaniesItemsNextToken,
  product,
  selectedCompany,
  listCashbooks,
  listCashbookItemsNextToken,
  listCashbookItems,
  listPaymentItemsNextToken,
  listPaymentItems,
  listPayments,
  listSaleItemsNextToken,
  listSalesItems,
  listSales,
  listCustomerItemsNextToken,
  listCustomers,
  listCustomerItems,
  listProductItemsNextToken,
  listProducts,
  listProductsItems,
  listCategoryItemsNextToken,
  listCategories,
  listCategoryItems,
  listParsedCategories,
  getSelectedCategoryAssociations,
  listCustomFields,
  listCustomFieldItems,
  listCustomFieldCols,
  listCustomFieldItemsNextToken,
  listCustomFieldsAssociations,
  getSelectedCustomFieldAssociations,
  listDiscountItemsNextToken,
  listDiscountItems,
  listDiscounts,
  listPackItemsNextToken,
  listPacks,
  listPackItems,
  getSelectedPackAssociations,
  listOptions,
  listOptionItems,
  listOptionItemsNextToken,
  listOptionsAssociations,
  listTaxes,
  listParsedTaxes,
  listTaxItemsNextToken,
  listTaxesAssociations,
  getSelectedTaxAssociations,
  listShops,
  listShopsItemsNextToken,
  listSlots,
  listSlotsItemsNextToken,
  listTagItems,
  listParsedTags,
  listTags,
  listTagItemsNextToken,
  listTagsAssociations,
  getSelectedTagAssociations,
  listUsers,
  listUserItemsNextToken,
  getSelectedUser,
  listImports,
  listImportItemsNextToken,
  getBackgroundJobs,
  listPaymentMethods,
  listPaymentMethodsItemsNextToken,
  listInventoryMovementItems,
  listInventoryMovements,
  inventoryMovementCols,
  listInventoryMovementItemsNextToken,
  listInventoryMovementProductNextToken,
  listInventoryMovementProductCols,
  listInventoryMovementsProduct,
  listInventoryMovementProductItems,
  listStatPayments,
  listStatTaxes,
  listStatProducts,
  listStatCategories,
  listStatValues,
  getRequesterQuery,
  getStatsFilters,
  getFiltersState,
  flatFileModal,
  interval,
}
