import { convertPrice } from './number';
import { parseName } from './string';

const removeNestedDirties = (dirties: Array<any>): Array<string> =>
  dirties.filter(
    dirty =>
      dirty !== 'photo' &&
      dirty !== 'logo' &&
      dirty !== 'categoryToCreate' &&
      dirty !== 'customFields' &&
      dirty !== 'options' &&
      dirty !== 'tags' &&
      dirty !== 'variations',
  );

const isNestedDirty = (dirties: Array<any>, field: string): boolean => !!dirties.find(dirty => dirty === field);

const createInput = (payload: any, decimals?: number) =>
  removeNestedDirties(payload.dirties).reduce((acc, dirty) => {
    let value =
      dirty === 'name' || dirty === 'firstName' || dirty === 'lastName' || dirty === 'brandName'
        ? parseName(payload[dirty])
        : dirty === 'buyPrice' || dirty === 'sellPrice' || dirty === 'rate'
        ? convertPrice(decimals!, payload[dirty])
        : payload[dirty];

    value = value === '' ? null : value;

    return { ...acc, [dirty]: value };
  }, {});

const createPayload = (input: any) =>
  Object.entries(input)
    .reduce((acc: Array<string>, [key, value]) => (value !== null ? [...acc, key] : acc), [])
    .filter(
      value =>
        // Because of duplication
        value !== 'id' &&
        value !== 'updatedAt' &&
        value !== 'createdAt' &&
        value !== 'deprecated' &&
        value !== 'catalogID' &&
        value !== 'companyID' &&
        value !== 'inventoryQuantities' &&
        value !== 'subValues' &&
        value !== 'variants' &&
        value !== 'tax' &&
        value !== 'category',
    );

const buildTemporaryErrors = async (toWait: any, errors: any) => {
  const temporaryResults = await Promise.all(toWait);
  const temporaryErrors = temporaryResults
    .map((r: any) => r.errors)
    .reduce((acc, val) => acc.concat(val), [])
    .filter((a: any) => a);
  temporaryErrors.concat(errors).filter((a: any) => a);
  return temporaryErrors;
};

export { removeNestedDirties, isNestedDirty, createInput, createPayload, buildTemporaryErrors };
