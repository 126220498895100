import { getInventoryStats } from '../catalog/inventory'
import { Action, objectType } from '../../store/types'
import { pluralize } from '../../utils/typeToType'
import { sortListElement } from '../../actions/list'
import { getCompanyID } from '../../services/localStorage'

const getValues = (products: Array<any>, dispatch: any) => {
  const statValues = products.map((product) => ({
    name: product.name,
    value: product.value,
    quantity: product.quantity,
    variantsCount: product.variants && product.variants.length > 0 ? product.variants.length : 0,
    subValues:
      product.variants && product.variants.length > 0
        ? product.variants.map((pDecli: any) => ({
            name: pDecli.name,
            value: pDecli.value,
            quantity: pDecli.quantity
          }))
        : undefined,
  }))

  dispatch({
    type: Action.FETCH_STAT_ITEM,
    payload: {
      items: statValues,
      type: pluralize(objectType.STAT_VALUE),
    },
  })

  sortListElement(dispatch, 'name', 'asc', objectType.STAT_VALUE)
}

const getStatsInventoryStats = async (dispatch: any, input: { shopIDs?: Array<string>; date?: string }) => {
  const values = await getInventoryStats({ ...input, companyID: getCompanyID() })

  if (values.data && values.data.products && values.data.products.products) {
    getValues(values.data.products.products, dispatch)
  }

  return values
}

export default getStatsInventoryStats
