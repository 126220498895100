// API ENV <-> TDB ENV
// develop - dev
// preprod - preprod

import { env } from '../services/auth'

// prod - prod

const ToIpad = () => {
  const suffix = env === 'production' ? '' : env === 'dev' ? '.develop' : `.${env}`
  const APP_URL = `infinite.caisse${suffix}://initialise_slot`

  return <>{window.location.replace(APP_URL + window.location.search)}</>
}

export default ToIpad
