import { List, PackAssociations, StateStore } from '../../store/types'
import { getPack as getP } from '../../getters/catalog/pack'
import { TypePackOption } from '../../types'

const getName = async (id: string, allProducts: List) => {
  const product = allProducts.items.items.find((product) => product.id === id)
  return {
    id,
    name: product ? product.name : '',
  }
}

const getNames = (packOption: any, allProducts: List) =>
  Promise.all(packOption.options.map((option: any) => getName(option.productID, allProducts)))

const buildPackState = (products: Array<any>, indexPack: number, packVariations: Array<any>, decimals: number) => ({
  id: indexPack.toString(),
  name: products.map((product) => product.name).join(', '),
  options: packVariations[indexPack].options.map((option: { productID: string; additionalPrice: number }) => ({
    ...option,
    id: '',
    name: products.find((product) => product.id === option.productID)?.name,
    price: option.additionalPrice ? option.additionalPrice / 10 ** decimals : 0,
  })),
})

const getPackInfos = (
  packVariations: Array<any>,
  decimals: number,
  allProducts: List
): Promise<Array<TypePackOption>> => {
  const productsInfos = packVariations.map((pack) => getNames(pack, allProducts))
  return Promise.all(productsInfos).then((productsInfos: any) => {
    const packInfos = productsInfos.map((product: Array<{ name: string; id: string }>, indexOfPack: number) =>
      buildPackState(product, indexOfPack, packVariations, decimals)
    )
    return packInfos
  })
}

/*
  Used for subscriptions
*/

const updatePacksAssociations = (payload: { productID: string; dispatch: any }, state: StateStore) => {
  const index = state.packsAssociations.findIndex((pack) => pack.productID === payload.productID)
  if (index !== -1) {
    state.packsAssociations[index].packs.map((id) => getP(id, payload.dispatch))
  }
  return { ...state }
}

const getProductPacksIds = (packs: Array<any>, id: string) =>
  packs.reduce((acc: Array<string>, pack) => {
    pack.packVariations.forEach((packOption: any) =>
      packOption.options.forEach((option: any) => {
        if (option.productID === id) acc = [...acc, pack.id]
      })
    )
    return acc
  }, [])

const getPacksAssociations = (items: Array<any>, packs: Array<any>): Array<PackAssociations> => {
  const productIds: any = items
    .reduce((acc: Array<string>, item) => {
      packs
        .map((pack) => pack.packVariations)
        .forEach((options) =>
          options.forEach((option: any) =>
            option.options.forEach((opt: any) => {
              if (opt.productID === item.id) acc = [...acc, item.id]
            })
          )
        )
      return acc
    }, [])
    .filter((id: string, index: number, tab) => tab.indexOf(id) === index)

  return productIds.map((id: string) => ({ productID: id, packs: getProductPacksIds(packs, id) }))
}

export { getPackInfos, getPacksAssociations, updatePacksAssociations }
