import { objectType } from '../../store/types'
import { getCompanyID } from '../../services/localStorage'
import { getObservable } from '../../services/graphql'
import { getPack as get } from '../../graphql/custom/queries'
import {
  onCreatePack,
  onDeletePack,
  onUpdatePack,
  onCreatePackCustomField,
  onUpdatePackCustomField,
  onDeletePackCustomField,
} from '../../graphql/custom/subscriptions'
import {
  updateListElement,
  deleteListElement,
  updateCustomFieldValue,
  createCustomFieldValue,
  deleteCustomFieldValue,
} from '../../actions/list'
import { callService } from '../../services'
import { GetPackQueryVariables } from '../../API'

const getPack = async (id: string, dispatch: any) => {
  const pack = await callService<GetPackQueryVariables>({ id }, get, 'getPack')

  if (pack.data) {
    if (pack.data.photo) pack.data.photo = pack.data.photo + '?' + Date.now()

    updateListElement({ element: pack.data, dispatch, type: objectType.PACK })
  }
  return pack
}

let onCreatePackSubscription: any
let onDeletePackSubscription: any
let onUpdatePackSubscription: any

const subscribePacks = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreatePackSubscription)
    onCreatePackSubscription = getObservable(onCreatePack, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreatePack) {
          if (eventData.data.onCreatePack.photo)
            eventData.data.onCreatePack.photo = eventData.data.onCreatePack.photo + '?' + Date.now()

          updateListElement({ element: eventData.data.onCreatePack, dispatch, type: objectType.PACK })
        }
      },
    })

  if (!onUpdatePackSubscription)
    onUpdatePackSubscription = getObservable(onUpdatePack, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdatePack) {
          if (eventData.data.onUpdatePack.photo)
            eventData.data.onUpdatePack.photo = eventData.data.onUpdatePack.photo + '?' + Date.now()

          updateListElement({ element: eventData.data.onUpdatePack, dispatch, type: objectType.PACK })
        }
      },
    })

  if (!onDeletePackSubscription)
    onDeletePackSubscription = getObservable(onDeletePack, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeletePack,
          dispatch,
          type: objectType.PACK,
        }),
    })
}

const unSubscribePacks = () => {
  if (onCreatePackSubscription) {
    onCreatePackSubscription.unsubscribe()
    onCreatePackSubscription = null
  }

  if (onUpdatePackSubscription) {
    onUpdatePackSubscription.unsubscribe()
    onUpdatePackSubscription = null
  }

  if (onDeletePackSubscription) {
    onDeletePackSubscription.unsubscribe()
    onDeletePackSubscription = null
  }
}

let onCreatePackCustomFieldSubscription: any
let onUpdatePackCustomFieldSubscription: any
let onDeletePackCustomFieldSubscription: any

const subscribePackCustomFields = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreatePackCustomFieldSubscription)
    onCreatePackCustomFieldSubscription = getObservable(onCreatePackCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreatePackCustomField)
          createCustomFieldValue(dispatch, eventData.data.onCreatePackCustomField, objectType.PACK)
      },
    })

  if (!onUpdatePackCustomFieldSubscription)
    onUpdatePackCustomFieldSubscription = getObservable(onUpdatePackCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdatePackCustomField)
          updateCustomFieldValue(dispatch, eventData.data.onUpdatePackCustomField, objectType.PACK)
      },
    })

  if (!onDeletePackCustomFieldSubscription)
    onDeletePackCustomFieldSubscription = getObservable(onDeletePackCustomField, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onDeletePackCustomField)
          deleteCustomFieldValue(dispatch, eventData.data.onDeletePackCustomField, objectType.PACK)
      },
    })
}

const unSubscribePackCustomFields = () => {
  if (onCreatePackCustomFieldSubscription) {
    onCreatePackCustomFieldSubscription.unsubscribe()
    onCreatePackCustomFieldSubscription = null
  }

  if (onUpdatePackCustomFieldSubscription) {
    onUpdatePackCustomFieldSubscription.unsubscribe()
    onUpdatePackCustomFieldSubscription = null
  }

  if (onDeletePackCustomFieldSubscription) {
    onDeletePackCustomFieldSubscription.unsubscribe()
    onDeletePackCustomFieldSubscription = null
  }
}

export { subscribePacks, unSubscribePacks, getPack, subscribePackCustomFields, unSubscribePackCustomFields }
