import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { closeAndResetModal, updateFlatFile } from '../../../../actions/modal'
import Button from '../../button'
import { ComponentSize, ComponentState, ComponentType } from '../../types'
import generateDataCy from '../../../../utils/cypress'
import { listShopsItemsNextToken } from '../../../../store/selector'
import UniqueDropDown from '../../dropDown/unique'
import TextArea from '../../textArea'
import Input from '../../input'
import { clearAllFilters, switchFilteredItemsToItems } from '../../../../actions/requester'
import { MenuState, objectType } from '../../../../store/types'
import toggleMenu from '../../../../actions/menu'
import generateInventoryListConf from '../../../../store/editableCols/inventoryList'
import { updateColsConf } from '../../../../actions/cols'
import { GetFlatfileWorkbookConfigQueryVariables, InventoryMovementState } from '../../../../API'
import { drawerOpen } from '../../../../actions/drawer'

import { KeyboardArrowDown } from '../../../../assets/icons'
import styles from '../Modal.module.css'
import { callService } from '../../../../services'
import { getCompanyID, getTestMode } from '../../../../services/localStorage'
import { getFlatfileWorkbookConfig } from '../../../../graphql/queries'

const CreateModal = ({ navigate, type }: { navigate: any, type?: string }) => {
  const initialState: any = {
    step: 1,
    type: '',
    motive: '',
    from: '',
    to: '',
    showSubmit: false,
    movementType: type,
    loading: false,
  }
  const [state, setState] = useState(initialState)
  const shops = useSelector(listShopsItemsNextToken)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cyContext = generateDataCy({ scope: 'catalog', value: 'inventoryMovement' })

  useEffect(() => {
    if (state.step === 2) {
      if (shops.items.length === 1) {
        setState({ ...state, showSubmit: true })
      }
    }
  }, [state.step])

  const handleShowSubmit = () => {
    if (state.type === 'TRANSFER' && state.to && state.from) return true
    else if (state.type === 'IN' && state.to) return true
    else if (state.type === 'OUT' && state.from) return true
    return false
  }

  const handleNext = () => {
    if (state.step === 2) handleSubmit(type === t('catalog.inventoryMovement.NEW_EXCEL'))
    else {
      if (state.type !== 'TRANSFER' && shops.items.length === 1) {
        if (state.type === 'IN') state.to = shops.items[0].id
        else if (state.type === 'OUT') state.from = shops.items[0].id
      }
      setState({ ...state, step: state.step + 1, showSubmit: false })
    }
  }

  const getFlatfile = async (originID?: string, destinationID?: string) => {
    const { data: spaceProps } = await callService<GetFlatfileWorkbookConfigQueryVariables>(
      {
        companyID: getCompanyID(),
        test: getTestMode(),
        metadata: JSON.stringify({
          originID,
          destinationID,
        }),
      },
      getFlatfileWorkbookConfig,
      'getFlatfileWorkbookConfig',
    );

    const flatFileData = { spaceProps: JSON.parse(spaceProps) };

    updateFlatFile(true, flatFileData, dispatch);
    setState({ ...state, loading: false })
    closeAndResetModal(dispatch);
  }

  const handleSubmit = (excel: boolean) => {

    if (excel) {
      setState({ ...state, loading: true })
      getFlatfile(state.from, state.to);
    } else {
      // Open editable list
      drawerOpen(dispatch, { open: false })
      closeAndResetModal(dispatch)
      navigate('/catalog/products')
      switchFilteredItemsToItems(objectType.INVENTORY_MOVEMENT, dispatch)
      clearAllFilters(dispatch)
      toggleMenu(dispatch, { state: MenuState.CLOSE })
      const conf = generateInventoryListConf({
        type: objectType.PRODUCT,
        movement: { from: state.from, to: state.to, state: InventoryMovementState.DRAFT, motive: state.motive },
        t,
        shops: shops.items,
        isEditable: true,
        movementType: state.type
      })
      updateColsConf(dispatch, objectType.PRODUCT, conf, true)
    }

  }

  if (state.loading) return <div className={styles.modalLoader}><div className={styles.loader}></div><div className={styles.loaderText}>Cette opération peut prendre quelques secondes</div></div>

  return (
    <div className={styles.modal}>
      <div className={styles.modalTitleCustom}>
        {state.step === 2 ? t('catalog.inventoryMovement.' + state.type) :
          type === t('catalog.inventoryMovement.NEW_EXCEL') ? t('catalog.inventoryMovement.NEW_EXCEL') : t('catalog.inventoryMovement.NEW')}
      </div>
      <div className={styles.modalContentCustom}>
        {state.step === 1 && (
          <UniqueDropDown
            parentID={'selectType'}
            popUpID={'selectTypePopUp'}
            Element={
              <Input
                label={t('catalog.inventoryMovement.TYPE')}
                value={state.type ? t('catalog.inventoryMovement.' + state.type) : ''}
                style={{
                  trailIcons: [{ icon: KeyboardArrowDown }],
                  readOnly: true,
                }}
                dataCy={generateDataCy({ scope: cyContext, value: 'type' })}
              />
            }
            items={
              shops.items.length < 2
                ? [
                  {
                    id: 'IN',
                    value: t('catalog.inventoryMovement.IN'),
                    onClick: (id: string) => setState({ ...state, type: id, showSubmit: true }),
                  },
                  {
                    id: 'OUT',
                    value: t('catalog.inventoryMovement.OUT'),
                    onClick: (id: string) => setState({ ...state, type: id, showSubmit: true }),
                  },
                ]
                : [
                  {
                    id: 'IN',
                    value: t('catalog.inventoryMovement.IN'),
                    onClick: (id: string) => setState({ ...state, type: id, showSubmit: true }),
                  },
                  {
                    id: 'TRANSFER',
                    value: t('catalog.inventoryMovement.TRANSFER'),
                    onClick: (id: string) => setState({ ...state, type: id, showSubmit: true }),
                  },
                  {
                    id: 'OUT',
                    value: t('catalog.inventoryMovement.OUT'),
                    onClick: (id: string) => setState({ ...state, type: id, showSubmit: true }),
                  },
                ]
            }
            dataCy={generateDataCy({ scope: cyContext, value: 'type' })}
          />
        )}
        {state.step === 2 && (
          <div className={styles.modalContentInventory}>
            {state.type !== 'IN' && (
              <UniqueDropDown
                parentID={'selectFrom'}
                popUpID={'selectFromPopUp'}
                Element={
                  <Input
                    label={t('catalog.inventoryMovement.ORIGIN')}
                    value={
                      shops.items.find((shop) => shop.id === state.from)
                        ? shops.items.find((shop) => shop.id === state.from)?.name
                        : ''
                    }
                    style={{
                      trailIcons: [{ icon: KeyboardArrowDown }],
                      readOnly: true,
                    }}
                    dataCy={generateDataCy({ scope: cyContext, value: 'from' })}
                  />
                }
                items={shops.items.map((s) => ({
                  id: s.id,
                  value: s.name,
                  onClick: (from: string) => {
                    state.to = ''
                    state.from = from
                    setState({ ...state, showSubmit: handleShowSubmit() })
                  },
                }))}
                dataCy={generateDataCy({ scope: cyContext, value: 'from' })}
              />
            )}
            {state.type !== 'OUT' && (
              <UniqueDropDown
                parentID={'selectTo'}
                popUpID={'selectToPopUp'}
                Element={
                  <Input
                    label={t('catalog.inventoryMovement.DESTINATION')}
                    value={
                      shops.items.find((shop) => shop.id === state.to)
                        ? shops.items.find((shop) => shop.id === state.to)?.name
                        : ''
                    }
                    style={{
                      trailIcons: [{ icon: KeyboardArrowDown }],
                      readOnly: true,
                    }}
                    dataCy={generateDataCy({ scope: cyContext, value: 'to' })}
                  />
                }
                items={shops.items
                  .filter((s) => s.id !== state.from)
                  .map((s) => ({
                    id: s.id,
                    value: s.name,
                    onClick: (to: string) => {
                      state.to = to
                      setState({ ...state, showSubmit: handleShowSubmit() })
                    },
                  }))}
                dataCy={generateDataCy({ scope: cyContext, value: 'to' })}
              />
            )}
            <TextArea
              value={state.motive}
              onChange={(motive) => setState({ ...state, motive, showSubmit: handleShowSubmit() })}
              label={t('catalog.inventoryMovement.MOTIVE')}
              dataCy={generateDataCy({ scope: cyContext, value: 'motive' })}
            />
          </div>
        )}
      </div>
      <div className={styles.modalButtonsCustom}>
        <Button
          title={t('button.CANCEL')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
        />
        <Button
          title={t('button.NEXT')}
          size={ComponentSize.MEDIUM}
          type={ComponentType.PRIMARY}
          state={state.showSubmit ? ComponentState.DEFAULT : ComponentState.DISABLED}
          onClick={handleNext}
          dataCy={'submit'}
        />
      </div>
    </div>
  )
}

export default CreateModal
