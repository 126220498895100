import { useTranslation } from 'react-i18next'

import { State } from './index'
import { displayNumber } from '../../../../../utils/number'
import Stat from '../../../../../components/oldComponents/stat'
import generateDataCy from '../../../../../utils/cypress'
import { InventoryDatePicker } from '../../../../../components/newComponents/datePicker'
import dayjs from 'dayjs';
import "../../../../../components/newComponents/datePicker/datePicker.css";

function debounce<F extends (...args: any[]) => any>(func: F, waitFor: number) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<F>): void => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func(...args), waitFor);
  };
}



const InventoryInfos = ({ state }: { state: State }) => {
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'tab', value: 'inventory' })

  return (
    <>
      <Stat
        dataCy={generateDataCy({ scope: cyContext, value: 'total' })}
        text={t('catalog.product.form.inventory.QUANTITY')}
        value={displayNumber(state.inventoryQuantity)}
      />
    </>
  )
}

export default InventoryInfos
