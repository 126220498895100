import { getIconTitleLine } from '../../../../../components/oldComponents/activity/lines'
import { TypeCompanyCurrency } from '../../../../../types/currency'
import ProductLine from '../../../../../components/oldComponents/activity/productLine'
import { CashbookMovementType } from '../../../../../API'
import { displayDateShortMonthWithHours } from '../../../../../utils/date'
import { displayPrice } from '../../../../../utils/number'
import { movementTypeToStr } from '../../../../../utils/typeToType'
import { TypeCashbookEvent } from '../../../../../types'
import DisplayIcon2 from '../../../../../components/newComponents/icons'

import { Shutdown, ArrowBack } from '../../../../../assets/icons'

const getCloseLine = (t: (value: string) => string, closedAt?: string) =>
  closedAt
    ? getIconTitleLine(
        'closedAt',
        {
          icon: DisplayIcon2({
            Icon: Shutdown,
            color: 'var(--sys-color-content-secondary)',
          }),
        },
        <ProductLine
          name={{ name: { text: t('activity.cashbook.CLOSED_AT') } }}
          infos={[{ options: [displayDateShortMonthWithHours(closedAt)] }]}
        />
      )
    : undefined

const mapEventToLine =
  (t: (field: string) => string, company: TypeCompanyCurrency) => (movement: any, index: number) => {
    const type = movementTypeToStr(movement.type, t)
    const amount = displayPrice(movement.amount, company.currency, company.currencyDecimals)

    return getIconTitleLine(
      'movement-' + index,
      {
        icon: DisplayIcon2({
          Icon: ArrowBack,
          color: 'var(--sys-color-content-secondary)',
        }),
      },
      <ProductLine
        name={{
          name: { text: type.text },
          price: { text: movement.type === CashbookMovementType.OUT ? '- ' + amount : amount },
        }}
        infos={[{ options: [displayDateShortMonthWithHours(movement.createdAt)] }]}
        note={{ title: 'Motif', text: movement.motive }}
        isNegative={movement.type === CashbookMovementType.OUT}
      />
    )
  }

const getOpenLine = (t: (value: string) => string, openedAt?: string) =>
  openedAt
    ? getIconTitleLine(
        'openedAt',
        {
          icon: DisplayIcon2({
            Icon: Shutdown,
            color: 'var(--sys-color-content-secondary)',
          }),
        },
        <ProductLine
          name={{ name: { text: t('activity.cashbook.OPENED_AT') } }}
          infos={[{ options: [displayDateShortMonthWithHours(openedAt)] }]}
        />
      )
    : undefined

const getEventLines = (
  t: (value: string) => string,
  cashbookState: TypeCashbookEvent,
  company: TypeCompanyCurrency
): {
  infos?: Array<any>
} => ({
  infos: [
    getCloseLine(t, cashbookState.closedAt),
    ...cashbookState.movements
      .reverse()
      .map(mapEventToLine(t, company))
      .filter((_) => _),
    getOpenLine(t, cashbookState.openedAt),
  ].filter((_) => _),
})

export default getEventLines
