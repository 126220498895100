import { displayCurrency } from '../../../utils/number'
import { getErrorIcon } from '../../newComponents/icon'
import Input from '../../newComponents/input'
import { ComponentState, ComponentStatus } from '../../newComponents/types'
import { CellProps } from '../../../types/cell'
import DisplayPrice from './price'
import generateDataCy from '../../../utils/cypress'

import { Error } from '../../../assets/icons'

const CurrencyCell = ({
  value,
  param,
  disabled,
  editable = true,
  onChange,
  onFocusNext,
  onBlur,
  validator,
  icons,
  error,
  currency,
  decimals,
}: CellProps): JSX.Element =>
  editable ? (
    <Input
      type={'currency'}
      value={(value !== null && value !== undefined && value !== '' && !isNaN(value)) ? value + (currency ? ' ' + displayCurrency(currency) : '') : ''}
      onChange={onChange}
      onBlur={onBlur}
      onFocusNext={onFocusNext}
      suffix={currency ? displayCurrency(currency) : ''}
      style={{
        state: disabled ? ComponentState.DISABLED : ComponentState.DEFAULT,
        status: error?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
        trailIcons: getErrorIcon(Error, error),
      }}
      dataCy={generateDataCy({ scope: 'cell', value: 'currency' })}
    />
  ) : currency && decimals !== null && decimals !== undefined ? (
    <DisplayPrice {...param} value={value} currency={currency} decimals={decimals} />
  ) : (
    <></>
  )

export default CurrencyCell
