import { ComponentDirection, ComponentSize, ComponentStatus, ComponentType } from '../../newComponents/types'
import DrawerTop from './top'
import generateDataCy from '../../../utils/cypress'
import Chip from '../../newComponents/chip'
import Divider from '../../newComponents/divider'
import Button from '../../newComponents/button'

import styles from './Drawer.module.css'

export type TypeQuickActions = Array<{ id: string; title: string; onClick: () => void; text?: string; icon?: any }>

type Props = {
  bulkTitle: string
  title: string
  actions: TypeQuickActions
  mobileView: boolean
  close: () => void
}

const DrawerBulk = ({ bulkTitle, title, actions, mobileView, close }: Props) => {
  const cyContext = generateDataCy({ scope: 'drawer', value: 'bulk' })

  return (
    <div className={styles.container} data-cy={cyContext}>
      <DrawerTop name={bulkTitle} mobileView={mobileView} close={close} dataCy={cyContext} />
      <div className={styles.form}>
        <Chip
          dataCy={generateDataCy({ scope: cyContext, value: 'selected' })}
          size={ComponentSize.MEDIUM}
          text={title}
          status={ComponentStatus.DEFAULT}
        />
      </div>
      <Divider size={ComponentSize.SMALL} type={ComponentType.FILL} direction={ComponentDirection.HORIZONTAL} />
      <div className={styles.form}>
        {actions.map((action, index) => (
          <div key={index}>
            <Button
              size={ComponentSize.MEDIUM}
              type={action.id === 'delete' ? ComponentType.SECONDARY : ComponentType.PRIMARY}
              status={action.id === 'delete' ? ComponentStatus.DANGER : ComponentStatus.DEFAULT}
              title={action.title}
              onClick={action.onClick}
              dataCy={generateDataCy({
                scope: generateDataCy({ scope: cyContext, value: 'action' }),
                value: action.id,
              })}
            />
            <div className={styles.description}>{action.text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DrawerBulk
