import { ComponentSize } from '../types'

import styles from './Avatar.module.css'

type Props = {
  image?: any
  iconText?: string
  color?: string
  size: ComponentSize
}

/**
 *
 * This element displays an avatar
 *
 * @param image - Avatar's image
 * @param iconText - Avatar's iconText
 * @param color - Avatar's color
 * @param size - Avatar's size
 *
 * @returns - A JSX.Element
 *
 * @author - Jennifer Charlois
 *
 */
const Avatar = ({ image, iconText, color, size }: Props): JSX.Element =>
  image ? (
    <img
      id="avatarID"
      src={image}
      alt=""
      style={{
        width: `var(--comp-avatar-size-${size})`,
        height: `var(--comp-avatar-size-${size})`,
        borderRadius: `var(--comp-avatar-border-radius-circle)`,
      }}
    />
  ) : (
    <div
      id="avatarID"
      className={styles.avatar}
      style={{
        width: `var(--comp-avatar-size-${size})`,
        height: `var(--comp-avatar-size-${size})`,
        fontSize: `var(--sys-typography-body-${size}-font-size)`,
        backgroundColor: color,
      }}
    >
      {iconText}
    </div>
  )

export default Avatar
