import { validator } from '../..'

export const name = validator().isPresent().check
export const categoryID = validator().isPresent().check
export const categoryToCreate = validator().isPresent().check
export const taxID = validator().isPresent().check

export const buyPrice = validator().validCurrency().check
export const sellPrice = validator().validCurrency().check

// Create variant
export const variationName = validator().hasUniqueValues().check
export const variationValues = validator().hasUniqueValues().check

// Update variant
export const oldVariantValues = validator().check
export const variantValues = validator().isPresent().check

export const customFieldNumber = validator().validNumber().check
export const quantity = validator().validNumber().check
