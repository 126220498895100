const Download = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7514 9.5H15.1614V4.5C15.1614 3.95 14.7114 3.5 14.1614 3.5H10.1614C9.61138 3.5 9.16138 3.95 9.16138 4.5V9.5H7.57138C6.68138 9.5 6.23138 10.58 6.86138 11.21L11.4514 15.8C11.8414 16.19 12.4714 16.19 12.8614 15.8L17.4514 11.21C18.0814 10.58 17.6414 9.5 16.7514 9.5ZM5.16138 19.5C5.16138 20.05 5.61138 20.5 6.16138 20.5H18.1614C18.7114 20.5 19.1614 20.05 19.1614 19.5C19.1614 18.95 18.7114 18.5 18.1614 18.5H6.16138C5.61138 18.5 5.16138 18.95 5.16138 19.5Z"
      fill={color}
    />
  </svg>
)

export default Download
