import { Table } from '../../list'
import { TypeList } from '../../../views/stat/types'

import styles from './Stat.module.css'

const StatList = ({ lists }: { lists: Array<TypeList> }) => (
  <div className={styles.containerList}>
    {lists?.map((sList, index) => (
      <div key={index} className={styles.list} data-cy={sList.type}>
        <div className={styles.listName}>{sList.title}</div>
        <Table cols={sList.cols} items={sList.items} type={sList.type} sort={sList.sort} localSort />
      </div>
    ))}
  </div>
)


export default StatList
