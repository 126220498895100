import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getDrawerState, selectedCompany } from '../../../../store/selector'
import { displayDateWithHours } from '../../../../utils/date'
import { DrawerState } from '../../../../store/types'
import { drawerOpen, updateDrawerState } from '../../../../actions/drawer'
import getInfoLines from './config'
import { TypeFidelity } from '../../../../types'
import { GetCustomerQueryVariables } from '../../../../API'
import { callService } from '../../../../services'
import { getCustomerStats } from '../../../../graphql/custom/queries'
import { List } from '../../../../components/oldComponents/activity/list'
import Loader from '../../../../components/newComponents/loader'
import { buildRequester } from '../../../../components/requester/query'
import { TypeComparator, TypePath } from '../../../../components/requester/types'
import generateDataCy from '../../../../utils/cypress'
import toggleMenu from '../../../../actions/menu'

import styles from '../../../../components/oldComponents/drawer/Drawer.module.css'

type Props = {
  id: string
  firstName: string
  dataCy: string
}

const Fidelity = ({ id, firstName, dataCy }: Props) => {
  const initialState: TypeFidelity = {
    createdAt: '',
    lastVisit: '',
    purchasesNumber: 0,
    averageBasket: 0,
    totalSpent: 0,
    infos: [],
  }
  const [state, setState] = useState(initialState)
  const drawerState = useSelector(getDrawerState)
  const company = useSelector(selectedCompany)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    callService<GetCustomerQueryVariables>({ id }, getCustomerStats, 'getCustomer').then(({ data }) => {
      const newState = {
        ...state,
        createdAt: displayDateWithHours(data.createdAt),
        lastVisit: '',
        purchasesNumber: data.stats.nbSales ?? 0,
        averageBasket: data.stats.average.total ?? 0,
        totalSpent: data.stats.totals.total ?? 0,
      }

      getInfoLines(t, newState, company).then((infos) => {
        setState({ ...newState, infos })
        updateDrawerState(DrawerState.LOADED, dispatch)
      })
    })
      }, [])

  const goToListEntity = () => {
    drawerOpen(dispatch, { open: false })
    const link = buildRequester({
      filterID: 'customerID',
      field: 'customerID',
      type: TypePath.PLAIN,
      path: [],
      filters: [],
      comparator: TypeComparator.EQ,
      value: id,
    })
    toggleMenu(dispatch, { section: 'activity', subSection: 'sales' })
    navigate(`/activity/sales?r=` + JSON.stringify(link))
  }

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : drawerState === DrawerState.LOADED ? (
        <div
          className={styles.containerForm}
          style={{
            height: `calc(100vh - (61px + var(--comp-tab-list-item-height-lg) + 10px))`,
          }}
        >
          <div className={styles.overflow}>
            <div className={styles.form}>
              {state.infos.length > 0 && (
                <List
                  datas={state.infos}
                  dataCy={generateDataCy({
                    scope: dataCy,
                    value: 'fidelity',
                  })}
                />
              )}
              <div
                className={styles.linkToRequester}
                onClick={() => goToListEntity()}
                data-cy={generateDataCy({
                  scope: dataCy,
                  value: generateDataCy({ scope: 'fidelity', value: 'sale' }),
                })}
              >
                {t('customer.fidelity.SEE_PURCHASE')} {firstName}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Fidelity
