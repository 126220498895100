const LockOpen = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1614 13.5C11.0614 13.5 10.1614 14.4 10.1614 15.5C10.1614 16.6 11.0614 17.5 12.1614 17.5C13.2614 17.5 14.1614 16.6 14.1614 15.5C14.1614 14.4 13.2614 13.5 12.1614 13.5ZM18.1614 8.5H17.1614V6.5C17.1614 3.74 14.9214 1.5 12.1614 1.5C9.88138 1.5 7.89138 3.04 7.32138 5.25C7.18138 5.79 7.50138 6.33 8.04138 6.47C8.57138 6.61 9.12138 6.29 9.26138 5.75C9.60138 4.43 10.7914 3.5 12.1614 3.5C13.8114 3.5 15.1614 4.85 15.1614 6.5V8.5H6.16138C5.06138 8.5 4.16138 9.4 4.16138 10.5V20.5C4.16138 21.6 5.06138 22.5 6.16138 22.5H18.1614C19.2614 22.5 20.1614 21.6 20.1614 20.5V10.5C20.1614 9.4 19.2614 8.5 18.1614 8.5ZM18.1614 19.5C18.1614 20.05 17.7114 20.5 17.1614 20.5H7.16138C6.61138 20.5 6.16138 20.05 6.16138 19.5V11.5C6.16138 10.95 6.61138 10.5 7.16138 10.5H17.1614C17.7114 10.5 18.1614 10.95 18.1614 11.5V19.5Z"
      fill={color}
    />
  </svg>
)

export default LockOpen
