import { onCreateDiscount, onDeleteDiscount, onUpdateDiscount } from '../../graphql/custom/subscriptions'
import { getCompanyID } from '../../services/localStorage'
import { updateListElement, deleteListElement } from '../../actions/list'
import { objectType } from '../../store/types'
import { getObservable } from '../../services/graphql'
import { getDiscount as get } from '../../graphql/custom/queries'
import { DataWithErrors } from '../../services/types'
import { GetDiscountQueryVariables } from '../../API'
import { callService } from '../../services'

const getDiscount = async (id: string, dispatch: any): Promise<DataWithErrors> => {
  const discount = await callService<GetDiscountQueryVariables>({ id }, get, 'getDiscount')

  if (discount.data) {
    updateListElement({
      element: discount.data,
      dispatch,
      type: objectType.DISCOUNT,
    })
  }
  return discount
}

let onCreateDiscountSubscription: any
let onDeleteDiscountSubscription: any
let onUpdateDiscountSubscription: any

const subscribeDiscounts = (dispatch: any) => {
  const input = {
    catalogID: getCompanyID(),
  }
  if (!onCreateDiscountSubscription)
    onCreateDiscountSubscription = getObservable(onCreateDiscount, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onCreateDiscount) {
          updateListElement({
            element: eventData.data.onCreateDiscount,
            dispatch,
            type: objectType.DISCOUNT,
          })
        }
      },
    })
  if (!onDeleteDiscountSubscription)
    onDeleteDiscountSubscription = getObservable(onDeleteDiscount, input).subscribe({
      next: (eventData: any) =>
        deleteListElement({
          element: eventData.data.onDeleteDiscount,
          dispatch,
          type: objectType.DISCOUNT,
        }),
    })
  if (!onUpdateDiscountSubscription)
    onUpdateDiscountSubscription = getObservable(onUpdateDiscount, input).subscribe({
      next: (eventData: any) => {
        if (eventData.data && eventData.data.onUpdateDiscount) {
          updateListElement({
            element: eventData.data.onUpdateDiscount,
            dispatch,
            type: objectType.DISCOUNT,
          })
        }
      },
    })
}

const unSubscribeDiscounts = () => {
  if (onCreateDiscountSubscription) {
    onCreateDiscountSubscription.unsubscribe()
    onCreateDiscountSubscription = null
  }

  if (onDeleteDiscountSubscription) {
    onDeleteDiscountSubscription.unsubscribe()
    onDeleteDiscountSubscription = null
  }

  if (onUpdateDiscountSubscription) {
    onUpdateDiscountSubscription.unsubscribe()
    onUpdateDiscountSubscription = null
  }
}

export { subscribeDiscounts, unSubscribeDiscounts, getDiscount }
