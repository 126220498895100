import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { closeAndResetModal } from '../../../../../actions/modal'
import { getSelectedPackAssociations, listProducts } from '../../../../../store/selector'
import generateDataCy from '../../../../../utils/cypress'
import { ComponentSize, ComponentType } from '../../../../../components/newComponents/types'
import Button from '../../../../../components/newComponents/button'

import styles from '../../../../../components/newComponents/modal/Modal.module.css'

type State = {
  name: string
}

const Delete = ({ id }: { id: string }) => {
  const initialState: State = {
    name: '',
  }
  const [state, setState] = useState(initialState)
  const products = useSelector(listProducts)
  const association = useSelector(getSelectedPackAssociations(id))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cyContext = generateDataCy({ scope: 'catalog', value: 'product' })

  useEffect(() => {
    const product = products.items.items.find((product: { id: string }) => product.id === id)
    if (product) {
      setState({
        ...state,
        name: product.name,
      })
    }
      }, [association])

  return (
    <div className={styles.modal} data-cy={generateDataCy({ scope: cyContext, value: 'modal' })}>
      <div className={styles.modalTitle}>{t('catalog.product.bulk.DELETE_PRODUCT')}</div>
      <div className={styles.modalContent}>
        <div className={styles.modalImportantInfo}>
          {t('catalog.product.bulk.WARNING_DELETE', {
            name: state.name,
            association: association?.packs.length,
            pluralAssociation: association?.packs && association?.packs.length > 1 ? 's' : '',
          })}
        </div>
        <div className={styles.modalInfo}>{t('catalog.product.bulk.EDIT')}</div>
      </div>
      <Button
        title={t('button.UNDERSTOOD')}
        size={ComponentSize.LARGE}
        type={ComponentType.PRIMARY}
        onClick={() => closeAndResetModal(dispatch)}
      />
    </div>
  )
}

export default Delete
