import { CreateSellerCodeInput, DeleteSellerCodeInput, UpdateSellerCodeInput } from '../../../API'
import { createSellerCode, deleteSellerCode } from '../../../graphql/custom/mutations'
import { updateSellerCode } from '../../../graphql/mutations'
import { callService } from '../../../services'
import { getCompanyID } from '../../../services/localStorage'
import { TypeSellerCode } from '../../../types/account/sellerCode'

const callUpdateSellerCode = (state: TypeSellerCode) => {
  let res
  if (state.isExisted) {
    if (state.sellerCode)
      res = callService<{ input: UpdateSellerCodeInput }>(
        // @ts-ignore
        { input: { companyID: getCompanyID(), code: state.sellerCode } },
        updateSellerCode,
        'updateSellerCode'
      )
    else if (!state.sellerCode)
      res = callService<{ input: DeleteSellerCodeInput }>(
        // @ts-ignore
        { input: { companyID: getCompanyID() } },
        deleteSellerCode,
        'deleteSellerCode'
      )
  } else if (state.sellerCode)
    res = callService<{ input: CreateSellerCodeInput }>(
      // @ts-ignore
      { input: { companyID: getCompanyID(), code: state.sellerCode } },
      createSellerCode,
      'createSellerCode'
    )

  return res
}

export default callUpdateSellerCode
