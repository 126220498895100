import { getPromise } from './graphql'
import { getCompanyID, getTestMode } from './localStorage'
import { DataWithErrors } from './types'

const getData = async ({ request, limit, nextToken }: { request: any; limit?: number; nextToken?: string }) => {
  const input = { catalogID: getCompanyID(), companyID: getCompanyID(), limit, nextToken, test: getTestMode() }
  const res = await getPromise(request, input)
  return res.data
}

const callService = async <Type>(
  input: Type,
  query: any,
  request: string,
  isSignUp?: boolean
): Promise<DataWithErrors> => {
  const res = await getPromise(query, { ...input, test: getTestMode() }, isSignUp)
  const errors = res.errors ? res.errors[0] : null
  return { data: res.data?.[request], errors, payload: input }
}

const getElementAssociations = <Type>(elements: Array<Type>, elementID: string, id: string): Type | undefined =>
  // @ts-ignore
  elements.find((element) => element[elementID] === id)

export { getData, callService, getElementAssociations }
