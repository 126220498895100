import Button from "../../../components/newComponents/button"
import { ComponentSize, ComponentStatus, ComponentType } from "../../../components/newComponents/types"
import styles from "../../../components/newComponents/modal/Modal.module.css"
import generateDataCy from "../../../utils/cypress"
import { useTranslation } from "react-i18next"
import { closeAndResetModal } from "../../../actions/modal"
import { useDispatch } from "react-redux"
import { SlotStatus, UpdateSlotInput } from '../../../API';
import { callService } from "../../../services"
import { updateSlot } from "../../../graphql/custom/mutations"



const SlotValidationModal = ({ id }: { id: string }) => {
  const cyContext = generateDataCy({ scope: 'settings', value: 'slots' })
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const handleSubmit = () => {
    callService<{ input: UpdateSlotInput }>(
      {
        input: {
          id,
          status: SlotStatus.IDLE,
        },
      },
      updateSlot,
      'updateSlot',
    ),
      closeAndResetModal(dispatch)
  }

  return (
    <div className={styles.modal} data-cy={generateDataCy({ scope: cyContext, value: 'modal' })}>
      <div className={styles.modalTitleContainer}>
        <div className={styles.modalTitleCustom}>
          {t('settings.slot.DISCONNECT')}
        </div>
      </div>
      <div className={styles.modalContentCustom}>
        <div className={styles.modalVeryImportantInfo}>
          {t('settings.slot.modal.FIRST_ACTION_TITLE')}
        </div>
        <ul>
          <li className={styles.modalInfo}>{t('settings.slot.modal.FIRST_ACTION_TEXT_ONE')}</li>
          <li className={styles.modalInfo}>{t('settings.slot.modal.FIRST_ACTION_TEXT_TWO')}</li>
        </ul>
        <br />
        <div className={styles.modalVeryImportantInfo}>
          {t('settings.slot.modal.SECOND_ACTION_TITLE')}
        </div>
        <ol>
          <li className={styles.modalInfo}>{t('settings.slot.modal.SECOND_ACTION_TEXT_ONE')}</li>
          <li className={styles.modalInfo}>{t('settings.slot.modal.SECOND_ACTION_TEXT_TWO')}</li>
        </ol>
      </div>
      <div className={styles.modalButtonsSlot}>
        <Button
          title={t('settings.slot.CANCEL')}
          size={ComponentSize.LARGE}
          type={ComponentType.TERTIARY}
          onClick={() => closeAndResetModal(dispatch)}
        />
        <Button
          title={t('settings.slot.DISCONNECT')}
          size={ComponentSize.LARGE}
          type={ComponentType.PRIMARY}
          status={ComponentStatus.DANGER}
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  )
}

export default SlotValidationModal