import React from 'react'

const TactillSmile = ({ color }: { color?: string }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3443 9C35.0928 9 36.5123 10.4194 36.5123 12.1679C36.5123 13.9165 35.0928 15.336 33.3443 15.336C31.5958 15.336 30.1763 13.9165 30.1763 12.1679C30.1763 10.4194 31.5958 9 33.3443 9ZM23.842 32.6519L23.8784 32.6518C27.3432 32.6325 30.1501 29.8538 30.1501 26.434V22.5181C30.1501 20.8022 31.5634 19.4092 33.3042 19.4092C34.3444 19.4092 35.2678 19.9066 35.8422 20.673L39.1723 23.9553C39.8492 24.6226 39.8492 25.7062 39.1723 26.3735L36.0922 29.4094C34.7432 34.8169 29.8016 38.8382 23.9094 38.8696L23.84 38.8698L23.7706 38.8696C17.8784 38.8382 12.9368 34.8169 11.5878 29.4094L8.50775 26.3735C7.83075 25.7062 7.83075 24.6226 8.50775 23.9553L11.8377 20.673C12.4123 19.9066 13.3356 19.4092 14.3758 19.4092C16.1166 19.4092 17.5299 20.8022 17.5299 22.5181V26.434C17.5299 29.8538 20.3368 32.6325 23.8016 32.6518L23.838 32.6519H23.839H23.84H23.841H23.842ZM17.504 12.1679C17.504 10.4194 16.0844 9 14.336 9C12.5875 9 11.168 10.4194 11.168 12.1679C11.168 13.9165 12.5875 15.336 14.336 15.336C16.0844 15.336 17.504 13.9165 17.504 12.1679Z"
      fill={color}
    />
  </svg>
)

export default TactillSmile
