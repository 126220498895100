import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import generateDataCy from '../../../utils/cypress'
import { getFiltersState } from '../../../store/selector'
import Input from '../input'
import { ComponentState } from '../types'
import { objectType } from '../../../store/types'
import { pluralize } from '../../../utils/typeToType'

import { Search } from '../../../assets/icons'

/**
 *
 * This element let the user type in a search bar
 *
 * @usedIn - List
 *
 * @param placeholder - The search bar placeholder
 * @param onChange - A CTA when the user type in the input. Its purpose is to filter the list
 *
 * @returns - JSX.Element
 *
 * @author - Arthur Escriou
 *
 */
const SearchBar = ({
  type,
  placeholder,
  onSubmit,
}: {
  type: objectType
  placeholder: string
  onSubmit: (val: string) => void
}): JSX.Element => {
  const [state, setState] = useState({ value: undefined, timeOut: undefined })
  const filters = useSelector(getFiltersState(pluralize(type)))

  useEffect(() => {
    if (!filters.searchbarOpen) setState({ ...state, value: undefined, timeOut: undefined })

      }, [filters])

  useEffect(() => {
    if (state.value !== undefined) {
      // @ts-ignore
      state.timeOut = setTimeout(() => onSubmit(state.value ?? ''), 500)
      return () => clearTimeout(state.timeOut)
    }
      }, [state.value])

  const handleChange = (event: any) => {
    state.value = event
    setState({ ...state })
  }

  return (
    <Input
      placeholder={placeholder}
      value={state.value}
      onChange={handleChange}
      style={{ state: ComponentState.DEFAULT, LeadIcon: Search }}
      dataCy={generateDataCy({ scope: 'list', value: 'searchbar' })}
    />
  )
}

export default SearchBar
