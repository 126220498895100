import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { APIOption, TypeCustomField, TypeTag } from '../../../../../types'
import Tag from '../../../../../components/oldComponents/catalog/tag'
import CustomField from '../../../../../components/oldComponents/catalog/customField'
import Option from '../../../../../components/oldComponents/catalog/option'
import { listParsedCategories, listParsedTags } from '../../../../../store/selector'
import { ComponentState } from '../../../../../components/newComponents/types'
import UniqueDropDown from '../../../../../components/newComponents/dropDown/unique'
import Input from '../../../../../components/newComponents/input'

const ProductInfos = ({
  state,
}: {
  state: {
    name: string
    categoryID: string
    tags?: Array<TypeTag>
    customFields?: Array<TypeCustomField>
    options?: Array<APIOption>
  }
}) => {
  const tags = useSelector(listParsedTags)
  const useSelectorSearchListCategory = useSelector(listParsedCategories())
  const { t } = useTranslation()

  return (
    <>
      <Input
        label={t('catalog.product.variant.NAME_PARENT')}
        value={state.name}
        style={{
          state: ComponentState.DISABLED,
        }}
        dataCy={'disabled'}
      />
      <UniqueDropDown
        Element={
          <Input
            label={t('catalog.product.variant.CATEGORY_NAME')}
            value={useSelectorSearchListCategory.find((category) => state.categoryID === category.id)?.value}
            dataCy={'category'}
            style={{
              state: ComponentState.DISABLED,
            }}
          />
        }
        state={ComponentState.DISABLED}
        dataCy={'category'}
      />
      <Tag selectedTags={state.tags ?? []} entries={tags} disabled />
      <CustomField selectedCustomFields={state.customFields ?? []} onBlur={() => {}} disabled errors={[]} />
      {state.options && state.options.length > 0 && <Option options={state.options} disabled />}
    </>
  )
}

export default ProductInfos
