import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { ThemeProvider } from '@mui/material/styles';
import { PickersShortcutsItem, PickersShortcutsProps } from '@mui/x-date-pickers/PickersShortcuts';
import dayjs, { Dayjs } from 'dayjs';
import {
  DateRangePickerDay as MuiDateRangePickerDay,
  DateRangePickerDayProps,
} from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { styled } from '@mui/material/styles';

import './datePicker.css';
import { themeRange } from './datePickerTheme';
import { useDispatch } from 'react-redux';
import { updateInterval } from '../../../actions/drawer';
import { IntervaleType } from '../../../store/types';

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Année précédente',
    getValue: () => {
      const today = dayjs();
      const startOfLastYear = today.subtract(1, 'year').startOf('year');
      const endOfLastYear = today.subtract(1, 'year').endOf('year');
      return [startOfLastYear, endOfLastYear];
    },
  },
  {
    label: 'Mois précédent',
    getValue: () => {
      const today = dayjs();
      const startOfLastMonth = today.subtract(1, 'month').startOf('month');
      const endOfLastMonth = today.subtract(1, 'month').endOf('month');
      return [startOfLastMonth, endOfLastMonth];
    },
  },
  {
    label: 'Semaine précédente',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week').add(1, 'day'), prevWeek.endOf('week').add(1, 'day')];
    },
  },
  {
    label: 'Année en cours',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('year'), today.endOf('year')];
    },
  },
  {
    label: 'Mois en cours',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Semaine en cours',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week').add(1, 'day'), today.endOf('week').add(1, 'day')];
    },
  },
  {
    label: 'Réinitialiser',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today];
    },
  },
];

const shortcutsItemsReduced: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Aujourd'hui",
    getValue: () => {
      const today = dayjs();
      return [today.startOf('day'), today.endOf('day')];
    },
  },
  {
    label: 'Année en cours',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('year'), today.endOf('year')];
    },
  },
  {
    label: 'Mois en cours',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Semaine en cours',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week').add(1, 'day'), today.endOf('week').add(1, 'day')];
    },
  },
  {
    label: 'Réinitialiser',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('day'), today.endOf('day')];
    },
  },
];

const CustomRangeShortcuts = (props: PickersShortcutsProps<DateRange<Dayjs>>) => {
  const { items, onChange, isValid } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map(item => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue);
      },
      disabled: item.label === 'Réinitialiser' ? true : !isValid(newValue),
    };
  });

  return (
    <div>
      <div
        className="dateRange__shortcut"
        style={{
          width: '624px',
        }}>
        {resolvedItems.map(item => {
          return (
            <div key={item.label}>
              <div
                {...item}
                className={item.disabled ? 'dateRange__shortcut__item__disabled' : 'dateRange__shortcut__item'}>
                {item.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CustomRangeShortcutsReduced = (props: PickersShortcutsProps<DateRange<Dayjs>>) => {
  const { items, onChange, isValid } = props;
  const dispatch = useDispatch();

  if (items == null || items.length === 0) {
    return null;
  }

  const handleInterval = (interval: string) => {
    switch (interval) {
      case "Aujourd'hui":
        return updateInterval(IntervaleType.DAY, dispatch);
      case 'Année en cours':
        return updateInterval(IntervaleType.YEAR, dispatch);
      case 'Mois en cours':
        return updateInterval(IntervaleType.MONTH, dispatch);
      case 'Semaine en cours':
        return updateInterval(IntervaleType.WEEK, dispatch);
      case 'Réinitialiser':
        return updateInterval(IntervaleType.DAY, dispatch);
      default:
        return updateInterval(null, dispatch);
    }
  };

  const resolvedItems = items.map(item => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        handleInterval(item.label);
        onChange(newValue);
      },
      disabled: item.label === 'Réinitialiser' ? true : !isValid(newValue),
    };
  });

  return (
    <div>
      <div
        className="dateRange__shortcut"
        style={{
          width: '312px',
        }}>
        {resolvedItems.map(item => {
          return (
            <div key={item.label}>
              <div
                {...item}
                className={item.disabled ? 'dateRange__shortcut__item__disabled' : 'dateRange__shortcut__item'}>
                {item.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DateRangePickerDays = styled(MuiDateRangePickerDay)(
  ({ theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting, outsideCurrentMonth, selected, today }) => ({
    ...(!outsideCurrentMonth &&
      isHighlighting && {
        borderRadius: 0,
        backgroundColor: 'var(--st-layer-color-default-hover) !important',
        color: 'var(--comp-button-color-background-default-primary) !important',
        '&:hover, &:focus': {
          backgroundColor: 'var(--comp-input-color-border-hover)',
          color: 'white !important',
        },
      }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      backgroundColor: 'var(--sys-color-status-interactive) !important',
      color: 'var(--comp-button-color-background-default-primary) !important',
      '&:hover, &:focus': {
        borderTopLeftRadius: '50% !important',
        borderBottomLeftRadius: '50% !important',
        backgroundColor: 'black',
        color: 'var(--comp-button-color-background-default-primary) !important',
      },
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      backgroundColor: 'var(--sys-color-status-interactive) !important',
      color: 'var(--comp-button-color-background-default-primary) !important',
      '&:hover, &:focus': {
        borderTopRightRadius: '50% !important',
        borderBottomRightRadius: '50% !important',
        backgroundColor: 'black',
        color: 'var(--comp-button-color-background-default-primary) !important',
      },
    }),
    ...(outsideCurrentMonth &&
      isHighlighting && {
        backgroundColor: 'transparent',
      }),
    // ...(today && {
    //   backgroundColor: 'var(--sys-color-status-interactive) !important',
    //   color: 'var(--comp-button-color-background-default-primary) !important',
    // })
  }),
) as React.ComponentType<DateRangePickerDayProps<Dayjs>>;

const DateRangeComp = ({ name, value, onChange }: { name?: string; value: any; onChange?: (date: any) => void }) => {
  return (
    <ThemeProvider theme={themeRange}>
      <DateRangePicker
        slots={{
          shortcuts: CustomRangeShortcuts,
          field: SingleInputDateRangeField,
          day: DateRangePickerDays,
        }}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          toolbar: {
            hidden: true,
          },
          actionBar: {
            hidden: true,
          },
        }}
        calendars={2}
        value={value}
        onChange={onChange}
      />
    </ThemeProvider>
  );
};

export const DateRangeCompReduced = ({
  name,
  value,
  onChange,
}: {
  name?: string;
  value: any;
  onChange?: (date: any) => void;
}) => {
  return (
    <ThemeProvider theme={themeRange}>
      <DateRangePicker
        slots={{
          shortcuts: CustomRangeShortcutsReduced,
          field: SingleInputDateRangeField,
          day: DateRangePickerDays,
        }}
        slotProps={{
          shortcuts: {
            items: shortcutsItemsReduced,
          },
          toolbar: {
            hidden: true,
          },
          actionBar: {
            hidden: true,
          },
        }}
        calendars={1}
        value={value}
        disableOpenPicker={false}
        onChange={onChange}
      />
    </ThemeProvider>
  );
};

export default DateRangeComp;
