import { TypeCol, TypeColumnConfiguration, objectType, Action } from '../store/types'
import { productCols, productsColsConf } from '../store/catalogCols/productCols'
import { categoriesColsConf, categoryCols } from '../store/catalogCols/categoryCols'
import { discountCols, discountsColsConf } from '../store/catalogCols/discountCols'
import { packCols, packsColsConf } from '../store/catalogCols/packCols'
import { updateColsConf } from './cols'
import { inventoryCols, inventoriesColsConf } from '../store/catalogCols/inventoryCols'
import { optionCols, optionsColsConf } from '../store/catalogCols/optionCols'
import { saleCols, salesColsConf } from '../store/activityCols/saleCols'
import { paymentCols, paymentsColsConf } from '../store/activityCols/paymentCols'
import { cashbookCols, cashbooksColsConf } from '../store/activityCols/cashbookCols'
import { customerCols, customersColsConf } from '../store/customerCols/customerCols'
import { setCompanyID } from '../services/localStorage'
import { resetApp } from './app'

const switchCompany = (id: string, t: (field: string) => string, isMobileView: boolean, dispatch: any) => {
  setCompanyID(id)
  resetApp(t, isMobileView, dispatch)
}

/**
 *
 * This method orders columns from API company settings
 * - First, we get entity configuration
 * - Then, we parse this configuration to get suitable array to use
 * We also add the active value (this is useful to change column appearance)
 * - Replacing actual columns by the new ones and add existing columns that were not added in the API
 *
 * Special case for product : We need to add this new configuration in the editable
 * columns configuration also
 * - For that we create another array of configuration where we put new column based of
 * an index
 *
 * We finally call the store to update column configuration
 *
 * @usedIn - Requester
 *
 * @param settings - Company settings
 * @param conf - Actual entity's configuration
 * @param cols - Actual entity's columns
 * @param key - API's key (productCols, discountCols..)
 * @param type - Entity's name (product, discount..)
 * @param dispatch - A hook to call the store
 *
 * @returns - void
 *
 * @author - Jennifer Charlois
 *
 */
const orderCols = (
  settings: any,
  entity: {
    conf: Array<TypeColumnConfiguration>
    cols: Array<TypeCol>
    key: string
    type: objectType
  },
  dispatch: any
) => {
  const entityConf = settings.find((setting: { key: string }) => setting.key === entity.key)
  if (entityConf) {
    // eslint-disable-next-line no-eval
    const parse = eval(JSON.parse(entityConf.value))
    const cols = parse
      .filter(
        (col: any) => entity.conf[0].cols.findIndex((entityCol: { id: string }) => entityCol.id === col.id) !== -1
      )
      .map((col: any) => {
        const index = entity.conf[0].cols.findIndex((entityCol: { id: string }) => entityCol.id === col.id)
        return { ...entity.conf[0].cols[index], active: col.active }
      })

    // @ts-ignore
    entity.conf[0].cols = [
      ...cols,
      ...entity.conf[0].cols.filter((oCol) => cols.findIndex((nCol: any) => nCol.id === oCol.id) === -1),
    ]

    if (entityConf.key === 'productCols') {
      const editCols = entity.conf[1].cols
      let newEditCols = new Array(cols.length)
      editCols.forEach((eCol) => {
        const index = cols.findIndex((nCol: any) => {
          if (eCol.id.startsWith('inventory')) {
            return nCol.id === eCol.id.split('inventory')[1]
          }
          if (eCol.id.startsWith('cf')) {
            return nCol.id === eCol.id.split('cf')[1]
          }
          return nCol.id === eCol.id
        })
        if (index > -1)
          newEditCols.fill(
            {
              ...eCol,
              active: cols[index].id === 'name' ? true : cols[index].active,
            },
            index,
            index + 1
          )
      })

      newEditCols = newEditCols.filter((_) => _)

      entity.conf[1].cols = [
        ...newEditCols,
        ...entity.conf[1].cols
          .map((eCol) =>
            newEditCols.findIndex((nCol: any) => nCol.id === eCol.id) === -1 ? { ...eCol, active: true } : false
          )
          .filter((_) => _),
      ]
    }
    updateColsConf(dispatch, entity.type, entity.conf[0])
  }
}

const entities = () => [
  {
    conf: productsColsConf,
    cols: productCols,
    key: 'productCols',
    type: objectType.PRODUCT,
  },
  {
    conf: categoriesColsConf,
    cols: categoryCols,
    key: 'categoryCols',
    type: objectType.CATEGORY,
  },
  {
    conf: discountsColsConf,
    cols: discountCols,
    key: 'discountCols',
    type: objectType.DISCOUNT,
  },
  {
    conf: packsColsConf,
    cols: packCols,
    key: 'packCols',
    type: objectType.PACK,
  },
  {
    conf: inventoriesColsConf,
    cols: inventoryCols,
    key: 'inventoryCols',
    type: objectType.INVENTORY_MOVEMENT,
  },
  {
    conf: optionsColsConf,
    cols: optionCols,
    key: 'optionCols',
    type: objectType.OPTION,
  },
  {
    conf: salesColsConf,
    cols: saleCols,
    key: 'saleCols',
    type: objectType.SALE,
  },
  {
    conf: paymentsColsConf,
    cols: paymentCols,
    key: 'paymentCols',
    type: objectType.PAYMENT,
  },
  {
    conf: cashbooksColsConf,
    cols: cashbookCols,
    key: 'cashbookCols',
    type: objectType.CASHBOOK,
  },
  {
    conf: customersColsConf,
    cols: customerCols,
    key: 'customerCols',
    type: objectType.CUSTOMER,
  },
]

const fetchCompanySettings = (companySettings: any, dispatch: any) => {
  entities().map((entity) => orderCols(companySettings, entity, dispatch))
}

const addElementCompanySettings = (payload: { id: string; type: string }, dispatch: any) =>
  dispatch({
    type: Action.ADD_ELEMENT_COMPANY_SETTINGS,
    payload,
  })

const deleteElementCompanySettings = (payload: { id: string; type: string }, dispatch: any) =>
  dispatch({
    type: Action.DELETE_ELEMENT_COMPANY_SETTINGS,
    payload,
  })

const updateCompanyData = (
  payload: {
    address: string
    createdAt: string
    currency: string
    currencyDecimals: number
    id: string
    name: string
    updatedAt: string
  },
  dispatch: any
) =>
  dispatch({
    type: Action.UPDATE_COMPANY,
    payload,
  })

export {
  switchCompany,
  orderCols,
  fetchCompanySettings,
  addElementCompanySettings,
  deleteElementCompanySettings,
  updateCompanyData,
}
