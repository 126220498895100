import { TypeColumnConfiguration } from '../types'
import { DropDownCell } from '../../components/list/cell'
import ShopModal from '../../views/setting/shop/modal'
import { updateModal } from '../../actions/modal'
import Country from '../../components/list/cell/country'
import { MoreHoriz } from '../../assets/icons'
import IconTouch from '../../components/newComponents/icons/iconTouch'

const SHOP = 'shop'

const shopsColsConf: TypeColumnConfiguration[] = [
  {
    id: 'listShops',
    type: SHOP,
    cols: [
      {
        id: 'name',
        title: 'NAME',
        field: 'name',
        type: 'text',
        fieldType: 'string',
        active: true,
      },
      {
        id: 'address',
        title: 'ADDRESS',
        type: 'function',
        fieldType: 'string',
        active: true,
        function: ({ address }: { address: { address: string } }) => (address ? address.address : null),
      },
      {
        id: 'zipcode',
        title: 'ZIP_CODE',
        type: 'function',
        fieldType: 'string',
        active: true,
        function: ({ address }: { address: { zipcode: string } }) => (address ? address.zipcode : null),
      },
      {
        id: 'city',
        title: 'CITY',
        type: 'function',
        fieldType: 'string',
        active: true,
        function: ({ address }: { address: { city: string } }) => (address ? address.city : null),
      },
      {
        id: 'country',
        title: 'COUNTRY',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value }) => (value.address ? Country(value.address) : ''),
      },
      {
        id: 'siret',
        title: 'SIRET',
        field: 'siret',
        type: 'text',
        fieldType: 'string',
        active: true,
      },
      {
        id: 'vat',
        title: 'VAT',
        field: 'vat',
        type: 'text',
        fieldType: 'string',
        active: true,
      },
      {
        id: 'more',
        title: '',
        type: 'element',
        active: true,
        function: ({ value, t, index, dispatch }) =>
          DropDownCell({
            fieldID: 'more',
            index,
            Element: IconTouch({
              Icon: MoreHoriz,
              color: 'var(--sys-color-content-secondary)',
            }),
            items: [
              {
                id: 'edit',
                value: t('settings.shop.EDIT'),
                onClick: () =>
                  updateModal(
                    dispatch,
                    true,
                    () => ShopModal({ id: value.id }),
                    () => {}
                  ),
              },
            ],
          }),
      },
    ],
    creatable: true,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
    creatablePlaceholder: 'settings.shop.NEW',
  },
]

export default shopsColsConf
