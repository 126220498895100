import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ProductInfos from './info'
import { getDrawerState, listParsedTaxes, selectedCompany } from '../../../../../store/selector'
import generateDataCy from '../../../../../utils/cypress'
import UniqueDropDown from '../../../../../components/newComponents/dropDown/unique'
import Divider from '../../../../../components/newComponents/divider'
import { variantValues as variantValuesValidator } from '../../../../../format/errors/filters/catalog/product'
import {
  ComponentDirection,
  ComponentSize,
  ComponentStatus,
  ComponentType,
} from '../../../../../components/newComponents/types'
import { convertPrice, displayCurrency } from '../../../../../utils/number'
import Input from '../../../../../components/newComponents/input'
import { TypeVariant } from '../../../../../types'
import Variants from './variation'
import { ValidationFunction } from '../../../../../format/errors/types'
import { callService } from '../../../../../services'
import { createProductVariant, updateProductVariant } from '../../../../../graphql/custom/mutations'
import { CreateProductVariantInput, UpdateProductVariantInput } from '../../../../../API'
import Loader, { displayLoader } from '../../../../../components/newComponents/loader'
import { DrawerState } from '../../../../../store/types'
import { drawerResetHistory, updateDrawerState } from '../../../../../actions/drawer'
import { setErrorsInState } from '../../../../../format/errors'
import SubmitButton from '../../../../../components/oldComponents/drawer/submitButton'
import Button from '../../../../../components/newComponents/button'
import DisplayIcon2 from '../../../../../components/newComponents/icons'
import { getDrawerHeight, getDrawerHeightNoTab } from '../../../../../utils'

import { KeyboardArrowUp, KeyboardArrowDown, Add } from '../../../../../assets/icons'
import stylesDrawer from '../../../../../components/oldComponents/drawer/Drawer.module.css'
import stylesProduct from '../../Product.module.css'

const ShowArrowAndMessage = ({ up, setState }: { up: boolean; setState: (value: boolean) => void }) => {
  const { t } = useTranslation()
  return (
    <div className={stylesProduct.arrowMessage} onClick={() => setState(!up)}>
      <div style={{ padding: '10px' }}>
        <DisplayIcon2 Icon={up ? KeyboardArrowUp : KeyboardArrowDown} color={'var(--sys-color-content-interactive)'} />
      </div>
      {t('catalog.product.variant.DISPLAY_PARENT_INFO')}
    </div>
  )
}

const Form = ({
  productID,
  productState,
  close,
}: {
  productID: string
  productState: TypeVariant
  close: () => void
}) => {
  const [state, setState] = useState({ ...productState, displayProductInfos: false })
  const useSelectorSearchListTaxe = useSelector(listParsedTaxes)
  const drawerState = useSelector(getDrawerState)
  const company = useSelector(selectedCompany)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cyContext = generateDataCy({ scope: 'drawer', value: 'variant' })

  const handleChange = (field: string, index?: number) => (event: any) => {
    state.errors.global = undefined

    if (field === 'value' || field === 'name') {
      state.variants![index!][field] = event
      state.errors.variations![index!][field] = {
        value: false,
        message: '',
      }
    } else {
      // @ts-ignore
      state.errors[field] = null
      // @ts-ignore
      state[field] = event
    }
    setState({
      ...state,
      showSubmit: true,
    })
  }

  const getVariationValidator = (index: number, field: string) =>
    // @ts-ignore
    state.validators.variations[index][field]

  const buildVariationError = (validator: ValidationFunction, field: string) =>
    validator && validator(field) ? validator(field) : { value: false, message: '' }

  const isVariationError = () => {
    state.variants
      .filter((variant) => !variant.created)
      .forEach((variant, index) => {
        // if (!variant.created) {
        const validatorName: ValidationFunction = getVariationValidator(index, 'name')
        const validatorValue: ValidationFunction = getVariationValidator(index, 'value')
        // @ts-ignore
        state.errors.variations[index] = {
          // @ts-ignore
          name: buildVariationError(validatorName, variant.name),
          // @ts-ignore
          value: buildVariationError(validatorValue, variant.value),
          // }
        }
      })
    return (
      !!state.errors.variations?.find((variation) => variation.name?.value || variation.value?.value) ||
      state.variants.filter((variant) => variant.value).length === 0
    )
  }

  const handleBlurVariant = (field: string, index?: number) => (_: any) => {
    if (field === 'value' || field === 'name') {
      const validator: ValidationFunction = getVariationValidator(index!, field)
      // @ts-ignore
      state.errors.variations[index] = {
        // @ts-ignore
        ...state.errors.variations[index],
        [field]: buildVariationError(
          // @ts-ignore
          validator,
          // @ts-ignore
          state.variants[index][field]
        ),
      }
      setState({ ...state })
    } else {
      setState(handleBlur(state, field))
    }
  }

  const handleBlur = (state: any, field: string) => {
    // @ts-ignore
    const validator: ValidationFunction = state.validators[field]
    // @ts-ignore
    state.errors[field] = validator ? validator(state[field]) : null
    return { ...state, showSubmit: !state.errors[field] }
  }

  const addVariation = () => {
    if (
      state.variants.findIndex((decli: { name: string; value: string; created: boolean }) =>
        decli.created ? decli.name === '' : decli.name === '' || decli.value === ''
      ) === -1
    ) {
      state.errors.global = undefined
      state.errors.variations!.push({
        name: { value: false, message: '' },
        value: { value: false, message: '' },
      })
      state.validators.variations!.push({
        name: variantValuesValidator,
        value: variantValuesValidator,
      })
      setState({
        ...state,
        variants: [...state.variants!, { name: '', value: '', created: false }],
        showSubmit: false,
      })
    }
  }

  const removeVariation = (index: number) => {
    setState({
      ...state,
      variants: state.variants.filter((_, dIndex) => dIndex !== index),
      showSubmit: true,
      errors: {
        ...state.errors,
        global: undefined,
        variations: state.errors.variations!.filter((_, eIndex) => eIndex !== index),
      },
      validators: {
        ...state.validators,
        variations: state.validators.variations!.filter((_, eIndex) => eIndex !== index),
      },
    })
  }

  const handleResult = (res: any) => {
    if (res.errors) {
      const newState = setErrorsInState(state, res.errors)
      state.errors = newState.errors
      state.showSubmit = newState.showSubmit
      setState({ ...state })
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
    } else {
      updateDrawerState(DrawerState.RESPONSE_FORM, dispatch)
      drawerResetHistory(dispatch)
      close()
    }
  }

  const handleSubmit = () => {
    setState(handleBlur(state, 'taxID'))

    if (!isVariationError() && state.showSubmit && state.taxID) {
      updateDrawerState(DrawerState.SEND_FORM, dispatch)
      if (!state.id) {
        const input: CreateProductVariantInput = {
          productID,
          options: state.variants
            .filter((decli: { name: string; value: string }) => decli.value)
            .map((decli: { name: string; value: string }) => ({
              name: decli.name,
              value: decli.value,
            })),
          taxID: state.taxID,
          reference: state.reference,
          barcode: state.barcode,
          sellPrice: convertPrice(company.currencyDecimals, state.sellPrice),
          buyPrice: convertPrice(company.currencyDecimals, state.buyPrice),
        }
        callService<{ input: CreateProductVariantInput }>({ input }, createProductVariant, 'createProductVariant').then(
          handleResult
        )
      } else {
        const input: UpdateProductVariantInput = {
          productID,
          variantID: state.id,
          options: state.variants.map((decli: { name: string; value: string }) => ({
            name: decli.name,
            value: decli.value,
          })),
          taxID: state.taxID,
          reference: state.reference,
          barcode: state.barcode,
          sellPrice: convertPrice(company.currencyDecimals, state.sellPrice),
          buyPrice: convertPrice(company.currencyDecimals, state.buyPrice),
        }
        callService<{ input: UpdateProductVariantInput }>({ input }, updateProductVariant, 'updateProductVariant').then(
          handleResult
        )
      }
    }
  }

  return (
    <>
      {drawerState === DrawerState.LOADING ? (
        <Loader />
      ) : displayLoader(drawerState) ? (
        <div
          className={stylesDrawer.containerForm}
          style={{
            height: state.id ? getDrawerHeight() : getDrawerHeightNoTab(),
          }}
        >
          <div className={stylesDrawer.overflow}>
            <div className={stylesDrawer.form}>
              {state.displayProductInfos && <ProductInfos state={state.productInfos} />}
              <ShowArrowAndMessage
                up={state.displayProductInfos}
                setState={(value) => setState({ ...state, displayProductInfos: value })}
              />
            </div>
            <Divider size={ComponentSize.SMALL} direction={ComponentDirection.HORIZONTAL} type={ComponentType.FILL} />
            <div className={stylesDrawer.form}>
              <div className={stylesProduct.label}>{'VARIATIONS'}</div>
              {state.variants.map(({ name, value, created }, index) => (
                <Variants
                  key={index}
                  index={index}
                  name={name}
                  value={value}
                  declined={!!state.id}
                  created={created}
                  onChange={handleChange}
                  onBlur={handleBlurVariant}
                  onRemove={removeVariation}
                  dataCy={generateDataCy({ scope: cyContext, value: 'variations' })}
                  // @ts-ignore
                  error={state.errors?.variations[index]}
                />
              ))}
              {!state.id && (
                <Button
                  title={t('catalog.product.variant.ADD_VARIATION')}
                  type={ComponentType.TERTIARY}
                  size={ComponentSize.SMALL}
                  onClick={addVariation}
                  LeadIcon={Add}
                />
              )}
              <Input
                type={'currency'}
                label={t('catalog.product.form.PRICE')}
                value={
                  state.sellPrice ? state.sellPrice + (company ? ' ' + displayCurrency(company.currency) : '') : ''
                }
                onChange={handleChange('sellPrice')}
                onBlur={() => setState(handleBlur(state, 'sellPrice'))}
                onSubmit={handleSubmit}
                style={{
                  status: state.errors.sellPrice?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                }}
                helperText={state.errors?.sellPrice?.value ? state.errors.sellPrice.message : undefined}
                suffix={company ? displayCurrency(company.currency) : undefined}
                dataCy={generateDataCy({ scope: cyContext, value: 'sellPrice' })}
              />
              <div className={stylesDrawer.oneLine}>
                <UniqueDropDown
                  parentID={'select'}
                  popUpID={'selectPopUp'}
                  Element={
                    <Input
                      placeholder={useSelectorSearchListTaxe.find((tax) => state.taxID === tax.id)?.value ? t('catalog.tax.form.TITLE') : ''}
                      value={useSelectorSearchListTaxe.find((tax) => state.taxID === tax.id)?.value}
                      style={{
                        trailIcons: [{ icon: KeyboardArrowDown }],
                        readOnly: true,
                      }}
                      dataCy={generateDataCy({ scope: cyContext, value: 'taxID' })}
                    />
                  }
                  items={useSelectorSearchListTaxe.map((t) => ({ ...t, onClick: handleChange('taxID') }))}
                  dataCy={generateDataCy({ scope: cyContext, value: 'taxID' })}
                />
                <Input
                  label={t('catalog.product.form.REFERENCE')}
                  value={state.reference}
                  onChange={handleChange('reference')}
                  onSubmit={handleSubmit}
                  dataCy={generateDataCy({ scope: cyContext, value: 'reference' })}
                />
              </div>
              <div className={stylesDrawer.oneLine}>
                <Input
                  type={'currency'}
                  label={t('catalog.product.form.BUY_PRICE')}
                  value={
                    state.buyPrice ? state.buyPrice + (company ? ' ' + displayCurrency(company.currency) : '') : ''
                  }
                  onChange={handleChange('buyPrice')}
                  onBlur={() => setState(handleBlur(state, 'buyPrice'))}
                  onSubmit={handleSubmit}
                  style={{
                    status: state.errors.buyPrice?.value ? ComponentStatus.ERROR : ComponentStatus.DEFAULT,
                  }}
                  helperText={state.errors?.buyPrice?.value ? state.errors.buyPrice.message : undefined}
                  suffix={company ? displayCurrency(company.currency) : undefined}
                  dataCy={generateDataCy({ scope: cyContext, value: 'buyPrice' })}
                />
                <Input
                  label={t('catalog.product.form.BARCODE')}
                  value={state.barcode}
                  onChange={handleChange('barcode')}
                  onSubmit={handleSubmit}
                  dataCy={generateDataCy({ scope: cyContext, value: 'barcode' })}
                />
              </div>
            </div>
          </div>
          <SubmitButton showSubmit={state.showSubmit} handleSubmit={handleSubmit} />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Form
