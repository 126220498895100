import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import List from '../../../components/list'
import { ListState, objectType } from '../../../store/types'
import Drawer from '.'
import { CustomFieldObjectType, CustomFieldValueType } from '../../../API'
import { TypeCustomField } from '../../../types'
import {
  listCategoryItemsNextToken,
  listCategories,
  listCategoryItems,
  listCustomFieldItems,
  getDashboardView,
} from '../../../store/selector'
import { buildCols } from '../../../utils'
import { TypeComparator } from '../../../components/requester/types'
import categoryFieldsRequester from '../../../requester/catalog/category'
import addCustomFieldsInEntity from '../../../requester/catalog/customField'
import { subscribeCategories, subscribeCategoryCustomFields, unSubscribeCategories, unSubscribeCategoryCustomFields } from '../../../getters/catalog/category'
import { useDispatch } from 'react-redux'
import { getAllCategories } from '../../../graphql/custom/queries';
import { getData } from '../../../services'
import { pluralize } from '../../../utils/typeToType'
import { fetchListElement, updateListState } from '../../../actions/list'

const ListCategories = () => {
  const res = useSelector(listCategories)
  const [cols, setCols] = useState(res.cols)
  const fullItems = useSelector(listCategoryItems)
  const { items, nextToken, sort } = useSelector(listCategoryItemsNextToken)
  const customFields: Array<TypeCustomField> = useSelector(listCustomFieldItems)
  const dashboardView = useSelector(getDashboardView)
  const dispatch = useDispatch();

  useEffect(() => {
    subscribeCategories(dispatch)
    subscribeCategoryCustomFields(dispatch)
    const getListCategories = async () => {
      const listAllCategories = await getData({ request: getAllCategories, limit: 100 });
      if (listAllCategories.requestCategories && listAllCategories.requestCategories.items && listAllCategories.requestCategories.items.length > 0) {
        const allCategories = listAllCategories.requestCategories.items
          .filter((_: any) => _)
          .map((category: any) => ({
            ...category,
            photo: category.photo ? category.photo + '?' + Date.now() : undefined,
            selected: false,
          }))
        fetchListElement(dispatch, allCategories, listAllCategories.requestCategories.nextToken, pluralize(objectType.CATEGORY))
        updateListState(
          { type: pluralize(objectType.CATEGORY), state: ListState.LOADED },
          dispatch
        )
      } else {
        fetchListElement(dispatch, [], '', pluralize(objectType.CATEGORY))
        updateListState(
          { type: pluralize(objectType.CATEGORY), state: ListState.EMPTY },
          dispatch
        )
      }
    }
    getListCategories();

    return () => {
      unSubscribeCategories();
      unSubscribeCategoryCustomFields();
    };

  }, []);

  useEffect(() => setCols(buildCols(res.cols!, 'name', 'createdAt', dashboardView)), [dashboardView, res.cols])

  const getAllFields = () => {
    const sortedCustomFields = [
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.TEXT),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.NUMBER),
      ...customFields.filter((cf) => cf.valueType === CustomFieldValueType.DATE),
    ]
    return [...categoryFieldsRequester, ...addCustomFieldsInEntity(sortedCustomFields, CustomFieldObjectType.CATEGORY)]
  }

  return (
    <List
      cols={cols!}
      items={items}
      fullItems={fullItems}
      requesterFields={getAllFields()}
      searchBarFields={[{ filter: TypeComparator.CONTAINS, field: 'name' }]}
      nextToken={nextToken}
      sort={sort}
      type={objectType.CATEGORY}
      Drawer={Drawer}
    />
  )
}

export default ListCategories
