import Observable from 'zen-observable-ts'
import fetch from 'node-fetch'
import AWSAppSyncClient from 'aws-appsync'
import gql from 'graphql-tag'
import AppSyncConfig from '../aws-exports'
import { getTestMode } from './localStorage'
import { signOut } from './auth'

export let token: string = ''
let getAccessToken: () => Promise<any>
let logout: any
let appsyncClient: any
let dispatch: any

export const cypressInit = () => {
  new AWSAppSyncClient({
    url: AppSyncConfig.aws_appsync_graphqlEndpoint,
    region: AppSyncConfig.aws_project_region,
    auth: {
      type: 'OPENID_CONNECT',
      jwtToken: "",
    },
    disableOffline: true,
  })
    .hydrated()
    .then((c: any) => (appsyncClient = c))
}

export const setToken = (t: any, getAccessTokenSilently: () => Promise<any>, lt: any, d: any) => {
  token = t
  getAccessToken = getAccessTokenSilently
  logout = lt
  dispatch = d
  new AWSAppSyncClient({
    url: AppSyncConfig.aws_appsync_graphqlEndpoint,
    region: AppSyncConfig.aws_project_region,
    auth: {
      type: 'OPENID_CONNECT',
      jwtToken: token,
    },
    disableOffline: true,
  })
    .hydrated()
    .then((c: any) => (appsyncClient = c))
}

const fetchAPI = async ({
  query,
  variables,
  operationName,
}: {
  query: string
  variables: { [key: string]: any }
  operationName: string | null
}) => {
  const graphql = JSON.stringify({
    query,
    variables,
    operationName,
  })
  const requestOptions = {
    method: 'POST',
    body: graphql,
    headers: { Authorization: token },
  }
  return fetch(AppSyncConfig.aws_appsync_graphqlEndpoint, requestOptions).then((r: any) => r.json())
}

const refreshToken = async ({
  query,
  variables,
  operationName,
}: {
  query: string
  variables: { [key: string]: any }
  operationName: string | null
}) => {
  try {
    if (getAccessToken) setToken(await getAccessToken(), getAccessToken, logout, dispatch)
    return fetchAPI({ query, variables, operationName })
  } catch (err: any) {
    console.log(err.error)
    if (err.error && err.error === 'login_required') signOut(dispatch, logout)
  }
}

const getOperationName = (query: any) =>
  gql(query)
    .definitions.filter((definition: any) => definition.kind === 'OperationDefinition' && definition.name)
    .map((x: any) => x!.name!.value)[0] || null

const getPromise = (query: any, variables: any | undefined, isSignUp?: boolean) => {
  const res = !isSignUp
    ? refreshToken({ query, variables, operationName: getOperationName(query) })
    : fetchAPI({ query, variables, operationName: getOperationName(query) })
  if (res instanceof Promise) {
    return res.catch(err => err)
  }
  return Promise.resolve({})
}

const getObservable: (query: any, variables: any | undefined) => Observable<any> = (
  query: any,
  variables: any | undefined,
) => {
  const res = appsyncClient.subscribe({
    query: gql`
      ${query}
    `,
    variables: { ...variables, test: getTestMode() },
  })
  if (res instanceof Observable) {
    return res
  }
  return Observable.of({})
}

const hasOperationName = (req: any, operationName: any) => {
  const { body } = req
  return (
    Object.prototype.hasOwnProperty.call(JSON.parse(body), 'operationName') &&
    JSON.parse(body).operationName.toLowerCase() === operationName.toLowerCase()
  )
}

const aliasQuery = (req: any, operationName: any) => {
  if (hasOperationName(req, operationName)) {
    req.alias = `${operationName}`
  }
}

const aliasMutation = (req: any, operationName: any) => {
  if (hasOperationName(req, operationName)) {
    req.alias = `${operationName}`
  }
}

const interceptSuccess = ({ result, operation, alias, fullResultDefinition }: any) => {
  cy.intercept('POST', AppSyncConfig.aws_appsync_graphqlEndpoint, req => {
    console.log(hasOperationName(req, alias), req, alias)
    if (hasOperationName(req, alias)) {
      const buildResult = fullResultDefinition ? result : { [operation]: result }
      req.alias = alias
      req.reply(res => {
        res.body = {
          data: buildResult,
          errors: null,
        }
      })
    }
  })
}

const interceptError = ({ error, alias }: any) => {
  cy.intercept('POST', AppSyncConfig.aws_appsync_graphqlEndpoint, req => {
    if (hasOperationName(req, alias)) {
      req.alias = alias
      req.reply(res => {
        res.body = {
          data: {
            errors: [error],
          },
          errors: [error],
        }
      })
    }
  })
}

export { getObservable, getPromise, interceptError, interceptSuccess, hasOperationName, aliasMutation, aliasQuery }
