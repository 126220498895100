const CheckBoxUnchecked = ({ color }: { color?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1614 19H6.16138C5.61138 19 5.16138 18.55 5.16138 18V6C5.16138 5.45 5.61138 5 6.16138 5H18.1614C18.7114 5 19.1614 5.45 19.1614 6V18C19.1614 18.55 18.7114 19 18.1614 19ZM19.1614 3H5.16138C4.06138 3 3.16138 3.9 3.16138 5V19C3.16138 20.1 4.06138 21 5.16138 21H19.1614C20.2614 21 21.1614 20.1 21.1614 19V5C21.1614 3.9 20.2614 3 19.1614 3Z"
      fill={color}
    />
  </svg>
)

export default CheckBoxUnchecked
