import { useTranslation } from 'react-i18next';
import countries from '../../../../assets/countries.json';

import ColorPicker from '../../../../components/newComponents/colorPicker';
import CustomField from '../../../../components/oldComponents/catalog/customField';
import Input from '../../../../components/newComponents/input';
import { ComponentSize, ComponentStatus, ComponentStyle } from '../../../../components/newComponents/types';
import TextArea from '../../../../components/newComponents/textArea';
import UniqueDropDown from '../../../../components/newComponents/dropDown/unique';
import { TypeCustomer } from '../../../../types';
import Avatar from '../../../../components/newComponents/avatar';
import { enumToStr } from '../../../../utils/color';
import generateDataCy from '../../../../utils/cypress';

import PhoneInput from '../../../../components/newComponents/phoneInput';
import { KeyboardArrowDown } from '../../../../assets/icons';
import styles from '../../../../components/oldComponents/drawer/Drawer.module.css';
import stylesInput from '../../../../components/newComponents/input/Input.module.css';

const Form = ({
  state,
  handleChange,
  handleBlur,
  handleBlurNested,
  handleSubmit,
  dataCy,
}: {
  state: TypeCustomer;
  handleChange: (field: string, index?: number, subField?: string) => (event: any) => void;
  handleBlur: (field: string) => void;
  handleBlurNested: (index: number) => void;
  handleSubmit: () => void;
  dataCy: string;
}) => {
  const { t } = useTranslation();

  return (
    <div id="drawer" className={styles.overflow} data-cy={generateDataCy({ scope: dataCy, value: 'drawer' })}>
      <div className={styles.form}>
        <ColorPicker
          Element={
            <Avatar
              size={ComponentSize.LARGE}
              color={state.color ? enumToStr(state.color) : state.color}
              iconText={state.iconText}
            />
          }
          iconText={state.iconText}
          color={state.color}
          style={ComponentStyle.CIRCLE}
          notEditable
          isIconText={false}
          isImage={false}
        />
        <Input
          label={t('customer.detail.form.FIRSTNAME')}
          value={state.firstName}
          onChange={handleChange('firstName')}
          onSubmit={handleSubmit}
          style={{ status: state.errors.name ? ComponentStatus.ERROR : ComponentStatus.DEFAULT }}
          helperText={state.errors.name?.message}
          dataCy={generateDataCy({ scope: dataCy, value: 'firstName' })}
        />
        <Input
          label={t('customer.detail.form.LASTNAME')}
          value={state.lastName}
          onChange={handleChange('lastName')}
          onSubmit={handleSubmit}
          style={{ status: state.errors.name ? ComponentStatus.ERROR : ComponentStatus.DEFAULT }}
          helperText={state.errors.name?.message}
          dataCy={generateDataCy({ scope: dataCy, value: 'lastName' })}
        />
        <Input
          label={t('customer.detail.form.MAIL')}
          value={state.email}
          onChange={handleChange('email')}
          onBlur={() => handleBlur('email')}
          onSubmit={handleSubmit}
          style={{ status: state.errors.email || state.errors.name ? ComponentStatus.ERROR : ComponentStatus.DEFAULT }}
          helperText={state.errors.email?.message ?? state.errors.name?.message}
          dataCy={generateDataCy({ scope: dataCy, value: 'email' })}
        />
        <PhoneInput
          phone={state.phone}
          onChange={(phone: any) => handleChange('phone')(phone)}
          status={state.errors.phone || state.errors.name ? ComponentStatus.ERROR : ComponentStatus.DEFAULT}
          helperText={state.errors.phone?.message ?? state.errors.name?.message}
        />
        <Input
          label={t('customer.detail.form.COMPANY_NAME')}
          value={state.companyName}
          onChange={handleChange('companyName')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: dataCy, value: 'companyName' })}
        />
        <Input
          label={t('customer.detail.form.ADDRESS')}
          value={state.address?.address}
          onChange={handleChange('address', undefined, 'address')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: dataCy, value: 'address' })}
        />
        <Input
          label={t('customer.detail.form.CITY')}
          value={state.address?.city}
          onChange={handleChange('address', undefined, 'city')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: dataCy, value: 'city' })}
        />
        <Input
          label={t('customer.detail.form.ZIPCODE')}
          value={state.address?.zipcode}
          onChange={handleChange('address', undefined, 'zipcode')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: dataCy, value: 'zipcode' })}
        />
        <UniqueDropDown
          eventID="drawer"
          parentID={'select'}
          popUpID={'selectPopUp'}
          Element={
            <Input
              placeholder={t('customer.detail.form.COUNTRY')}
              value={countries.find(country => country.alpha2 === state.address?.country?.toLowerCase())?.name}
              style={{
                trailIcons: [{ icon: KeyboardArrowDown }],
                readOnly: true,
              }}
              dataCy={generateDataCy({ scope: dataCy, value: 'country' })}
            />
          }
          items={countries.map(country => ({
            id: country.alpha2.toUpperCase(),
            value: country.name,
            onClick: handleChange('address', undefined, 'country'),
          }))}
          dataCy={generateDataCy({ scope: dataCy, value: 'country' })}
        />
        <Input
          label={t('customer.detail.form.FIDELITY_CARD')}
          value={state.fidelityCardNumber}
          onChange={handleChange('fidelityCardNumber')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: dataCy, value: 'fidelityCard' })}
        />
        <CustomField
          selectedCustomFields={state.customFields}
          onChange={handleChange}
          onBlur={handleBlurNested}
          onSubmit={handleSubmit}
          errors={state.errors?.customFields}
          cyContext={dataCy}
        />
        <TextArea
          label={t('customer.detail.form.NOTE')}
          value={state.note}
          onChange={handleChange('note')}
          onSubmit={handleSubmit}
          dataCy={generateDataCy({ scope: dataCy, value: 'note' })}
        />
      </div>
    </div>
  );
};

export default Form;
