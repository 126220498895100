import { TypeColumnConfiguration } from '../types'
import { DropDownCell } from '../../components/list/cell'
import PaymentMethodModal from '../../views/setting/paymentMethod/modal'
import { updateModal } from '../../actions/modal'
import { MoreHoriz } from '../../assets/icons'
import IconTouch from '../../components/newComponents/icons/iconTouch'
import { paymentMethodToComponent, paymentMethodToStr, statePaymentMethodToStr } from '../../utils/typeToType'
import Chip from '../../components/newComponents/chip'
import DisplayIcon2 from '../../components/newComponents/icons'

const PAYMENT_METHOD = 'paymentMethod'

const paymentMethodsColsConf: TypeColumnConfiguration[] = [
  {
    id: 'listPaymentMethods',
    type: PAYMENT_METHOD,
    cols: [
      {
        id: 'color',
        title: '',
        type: 'element',
        active: true,
        function: ({ value }) =>
          DisplayIcon2({
            Icon: paymentMethodToComponent(value.type, value.color).component,
            color: paymentMethodToComponent(value.type, value.color).color,
          }),
      },
      {
        id: 'name',
        title: 'NAME',
        field: 'name',
        type: 'text',
        fieldType: 'string',
        active: true,
      },
      {
        id: 'type',
        title: 'TYPE',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, t }) => (value.type ? paymentMethodToStr(value.type, t) : null),
      },
      {
        id: 'enabled',
        title: 'SITUATION',
        type: 'element',
        fieldType: 'string',
        active: true,
        function: ({ value, t }) => Chip(statePaymentMethodToStr(value.enabled, t)),
      },
      {
        id: 'more',
        title: '',
        type: 'element',
        active: true,
        function: ({ value, t, index, dispatch }) =>
          DropDownCell({
            fieldID: 'more',
            index,
            Element: IconTouch({
              Icon: MoreHoriz,
              color: 'var(--sys-color-content-secondary)',
            }),
            items: [
              {
                id: 'edit',
                value: t('settings.paymentMethod.EDIT'),
                onClick: () =>
                  updateModal(
                    dispatch,
                    true,
                    () => PaymentMethodModal({ id: value.id }),
                    () => {}
                  ),
              },
            ],
          }),
      },
    ],
    creatable: true,
    editable: false,
    clickable: false,
    exportable: false,
    shrinkrable: false,
    customable: false,
    checkboxes: false,
    creatablePlaceholder: 'settings.paymentMethod.NEW',
  },
]

export default paymentMethodsColsConf
